import { connect } from 'react-redux';

import { getStocksByBussinessUnitandCountryIds, getLateReturnsTransactions, clearLateReturnsTransactions, exportLateReturnsTransactionList } from '../../Statistics.actions';
import DelayedTransactionsTab from './DelayedTransactionsTab.component';
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';

const mapStateToProps = ({ authReducer, languageReducer, statisticsReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
  stocksDelayedTransaction: statisticsReducer.stocksDelayedTransaction,
  lateTransactionsList: statisticsReducer.lateTransactionsList,
});

export default connect(mapStateToProps, {
  getStocksByBussinessUnitandCountryIds,
  getLateReturnsTransactions,
  clearLateReturnsTransactions,
  exportLateReturnsTransactionList,
  toggleActionMessage
})(DelayedTransactionsTab);
