export function sortUsers(array) {
  const sorted = [...array].sort(function(a, b) {
    const aLastNameChar = a.lastName ? a.lastName.toLowerCase() : '';
    const bLastNameChar = b.lastName ? b.lastName.toLowerCase()  : '';
    
    if (aLastNameChar > bLastNameChar) {
      return 1;
    } else if (aLastNameChar < bLastNameChar) {
      return -1;
    }
  });

  return sorted;
};

export function sortDates(array) {
  const sorted = [...array].sort(function(a, b) {
    
    if (a.start > b.start) {
      return 1;
    } else if (a.start < b.start) {
      return -1;
    }
  });

  return sorted;
};
