import React, { PureComponent } from 'react';

import strings from 'resources/locales/Translate';

import Button from '../Button/Button';

export default class CreateStockActionButtons extends PureComponent {
  render() {
    const { onSave, onCancel, isLoading, styleClass } = this.props;

    return (
      <div className='row mt-3 ml-0 mr-0 d-flex align-items-center'>
        {onCancel &&
          <div className='mr-3'>
            <Button value={strings.cancel} bgFill={false} onClick={onCancel} styleClass={styleClass} />
          </div>
        }
        <div className='p-0'>
          <Button
            dataTest='create-kit-save-button'
            value={strings.save}
            onClick={onSave}
            loading={isLoading}
            styleClass={styleClass}
          />
        </div>
      </div>
    );
  }
}

CreateStockActionButtons.defaultProps = {
  onSave: () => {},
  onCancel: () => {},
  buttonStyles: "",
};
