import { batchActions } from 'redux-batched-actions';

import {
  fetchMasterDataService,
  fetchMasterDataServicePost,
  fetchMasterDataServicePut,
  deleteMasterDataService,
} from 'library/api/master-data.service';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { scrollToTop } from 'library/utilities/scrollActions';

import {
  FETCH_INITIAL_DATA_FOR_USER_PERMISSIONS,
  FETCH_ROLES_LIST,
  FETCH_USER_PEMISSIONS,
  CLEAR_USER_PERMISSIONS,
} from './UserPermissions.constants';

export const getInitialData = () => async dispatch => {
  try {
    dispatch(changeLoader(true));

    const { data: businessUnits } = await fetchMasterDataService(URLS.userStockBusinessunits);
    const { data: users } = await fetchMasterDataService(URLS.normalUsers);

    batchActions([
      dispatch({
        type: FETCH_INITIAL_DATA_FOR_USER_PERMISSIONS,
        payload: {
          businessUnits: businessUnits || [],
          users: users || [],
        },
      }),
      dispatch(changeLoader(false)),
    ]);
  } catch (err) {
    batchActions([
      dispatch(changeLoader(false)),
      dispatch({
        type: FETCH_INITIAL_DATA_FOR_USER_PERMISSIONS,
        payload: {
          businessUnits: [],
          users: [],
        },
      }),
    ]);
  }
};

export const getCountriesByBusinessUnit = bu => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.countriesByBusinessUnit(bu));

    dispatch({
      type: FETCH_INITIAL_DATA_FOR_USER_PERMISSIONS,
      payload: {
        countries: data,
      },
    });
  } catch(err) {
    dispatch({
      type: FETCH_INITIAL_DATA_FOR_USER_PERMISSIONS,
      payload: {
        countries: [],
      },
    });
  }
};

export const fetchRolesList = () => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.rolesList);

    dispatch({
      type: FETCH_ROLES_LIST,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_ROLES_LIST,
      payload: [],
    });
  }
};

const getSort = (sortedKey, sort) => {
  if (!sortedKey) return;
  if (sortedKey === 'firstName') return ['warehouseAddresses.firstName', `${sort[sortedKey]}`, 'warehouseAddresses.lastName', `${sort[sortedKey]}`];
  if (sortedKey === 'email') return ['warehouseAddresses.email', `${sort[sortedKey]}` ]
  return [`${sortedKey}`, `${sort[sortedKey]}`];
};

export const handlingSendData = data => {
  const {
    filters: { businessUnit, country },
    sort,
  } = data;
  const sortedKey = Object.keys(sort).find(key => sort[key]);

  const sendData = {
    page: data.page,
    size: data.size,
    filter: [`businessUnit.id:eq:${businessUnit}`, 'and', `country.id:eq:${country}`],
    sort: getSort(sortedKey, sort),
  };

  return sendData;
};

export const fetchUserPermissions = dataToSend => async dispatch => {
  try {
    dispatch(changeLoader(true));

    const handledData = handlingSendData(dataToSend);
    const { data } = await fetchMasterDataServicePost(URLS.userPermissions(dataToSend.filters.user), handledData);

    batchActions([
      dispatch({
        type: FETCH_USER_PEMISSIONS,
        payload: data,
      }),
      dispatch(changeLoader(false)),
    ]);
  } catch (err) {
    batchActions([
      dispatch(changeLoader(false)),
      dispatch({
        type: FETCH_USER_PEMISSIONS,
        payload: {},
      }),
    ]);
  }
};

export const clearUserPermissions = () => dispatch => {
  dispatch({ type: CLEAR_USER_PERMISSIONS });
};

export const deleteAllPermissionsForAllStocks = dataToSend => async dispatch => {
  try {
    await deleteMasterDataService(URLS.deleteAllUserPermissionsForStock(dataToSend.filters.user), {
      filter: [`businessUnit.id:eq:${dataToSend.filters.businessUnit}`, 'and', `country.id:eq:${dataToSend.filters.country}`],
    });

    dispatch(fetchUserPermissions(dataToSend));
    dispatch(toggleActionMessage(true, 'success', 'allChangesAreSaved'));
    scrollToTop(500);
  } catch (err) {
    if (err?.response?.data?.status === 422) {
      dispatch(toggleActionMessage(true, 'error', err.response.data.message, true));
    }else{
      dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    }
    scrollToTop(500);
  }
};

export const updateAllUserPermissionsForStosk = (dataToSend, globalRole) => async dispatch => {
  try {
    await fetchMasterDataServicePut(URLS.updateAllUserPermissions(dataToSend.filters.user, globalRole.id), {
      filter: [`businessUnit.id:eq:${dataToSend.filters.businessUnit}`, 'and', `country.id:eq:${dataToSend.filters.country}`],
    });

    dispatch(fetchUserPermissions(dataToSend));
    dispatch(toggleActionMessage(true, 'success', 'allChangesAreSaved'));
    scrollToTop(500);
  } catch (err) {
    if (err?.response?.data?.status === 422) {
      dispatch(toggleActionMessage(true, 'error', err.response.data.message, true));
    }else{
      dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    }
    scrollToTop(500);
  }
};

export const updateDifferentUserPermissionsForStosk = (updatedRoles, dataToSend) => async dispatch => {
  try {
    await fetchMasterDataServicePut(URLS.updateUserPermission, { list: updatedRoles });

    dispatch(fetchUserPermissions(dataToSend));
    dispatch(toggleActionMessage(true, 'success', 'allChangesAreSaved'));
    scrollToTop(500);
  } catch (err) {
    if (err?.response?.data?.status === 422) {
      dispatch(toggleActionMessage(true, 'error', err.response.data.message, true));
    }else{
      dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    }
    scrollToTop(500);
  }
};
