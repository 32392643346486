import { useEffect } from 'react';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';

import { URLS } from 'library/common/commonConstants/ApiUrlConstants';

let client;

export const useNotifications = ({
  user,
  addNotification,
  fetchAllNotifications,
  language,
}) => {
  const createWebsocketConnection = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/ws`;
    const socket = new SockJS(url);
    client = Stomp.over(socket);
    client.connect({}, onConnected, onError);
  };

  useEffect(() => {
    createWebsocketConnection();
  }, []);

  useEffect(() => {
    fetchAllNotifications();
  }, [language, fetchAllNotifications]);

  const onConnected = () => {
    client.subscribe(
      URLS.subscribeNotifications(user.accountId),
      onMessage
    );
  }

  const onMessage = msg => {
    const notification = JSON.parse(msg.body);
    addNotification(notification);
  }

  const onError = (err) => {
    setTimeout(createWebsocketConnection, 5000);
  };

  return {
    createWebsocketConnection,
  };
}
