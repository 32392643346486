export const statisticsBreadcrumbs = [
  {
    name:'homepage',
    url: '/home',
  },
  {
    name:'demoSale',
    url: '/demo-sale',
  },
  {
    name:'statistics',
  },
];

export const YEAR = 1;
export const MONTH = 2;
