import { useCallback } from 'react';
import $ from 'jquery';

import { regex } from 'library/common/commonConstants/RegexConstants';
import { checkUserRoles } from 'library/utilities/checkRoles';
import { permissions } from 'library/common/commonConstants/permissionsConstants';
import strings from 'resources/locales/Translate';
import { sapCrmNewCient } from 'library/utilities/constants';

const OPPORTUNITY_SEARCH_ID_POPUP = 'opportunitySearchPopup';

export const useOpportunityIds = ({
  setOpportunity,
  removeOpportunity,
  opportunities,
  setIsOpportunityIdCrmError,
  state,
  handleEnteredOpportunityId,
  user,
  descriptionForm,
  selectedKits,
  componentWarehouseForm,
  loanType,
}) => {
  let selectedStockID = [];
  if (loanType === 'kitLoan' || loanType === 'labsLocationType') {
    selectedStockID = selectedKits && selectedKits.length > 0 && selectedKits.map(item => item.kit.stockInformation.id);
  }
  let disableonDifferentStock;
  for (let index = 0; index < selectedStockID.length; index++) {
    disableonDifferentStock = selectedStockID[index] === selectedStockID[0];
  }
  const checkBusinessUnit = () => {
    const businessUnits = ['MED', 'MED-OPT', 'MED-SUR'];
    const singleLoanBusinessUnit =
      componentWarehouseForm && componentWarehouseForm.formData && componentWarehouseForm.formData.stockFullValue;
    const kitLoanBusinessUnit = selectedKits && selectedKits.length > 0 && selectedKits[0] && selectedKits[0].kit;
    const showCustomerGroupField =
      kitLoanBusinessUnit && kitLoanBusinessUnit.stockInformation
        ? businessUnits.includes(kitLoanBusinessUnit.stockInformation?.businessUnit?.name)
        : businessUnits.includes(singleLoanBusinessUnit && singleLoanBusinessUnit.businessUnit?.name);
    return showCustomerGroupField;
  };

  const checkStockCountry = () => {
    const country = ['US'];
    const singleLoanCounty =
      componentWarehouseForm && componentWarehouseForm.formData && componentWarehouseForm.formData.stockFullValue;
    const kitLoanCountry = selectedKits && selectedKits.length > 0 && selectedKits[0] && selectedKits[0].kit;
    const UsCountry =
      kitLoanCountry && kitLoanCountry.stockInformation
        ? country.includes(kitLoanCountry.stockInformation?.country?.shortName)
        : country.includes(singleLoanCounty && singleLoanCounty?.countryName);
    return UsCountry;
  };

  const isBusinessUnitMed = checkBusinessUnit();
  const isUsStock = checkStockCountry();

  const handleInput = value => {
    setIsOpportunityIdCrmError(false);
    const inputValue = value.trim();
    if (user && user.crmSystemId === 'ngcrm' || (isBusinessUnitMed && isUsStock)) {
      if (inputValue.length > 18) return;
      if (inputValue && !regex.alphaNumeric.test(inputValue)) return;
    } else {
       if (inputValue.length > 9) return;
      if (inputValue && !regex.number.test(inputValue)) return;
      if (inputValue.length > 9 && !regex.validCrmCheck.test(inputValue)) return
    }
    handleEnteredOpportunityId({
      value: inputValue,
      error: '',
    });
  };

  const handleSetOpportunity = () => {
    if (user) {
      // Check for 'ngcrm' or business unit conditions
      if (user.crmSystemId === 'ngcrm' || (isBusinessUnitMed && isUsStock)) {
        if (state.value.length < 18) {
          return handleEnteredOpportunityId({
            error: strings.pleaseMindThatFieldNeedsToHave(18),
          });
        }
      } else {
        // For other cases
        if (state.value.length != 9) {
          return handleEnteredOpportunityId({
            error: 'pleaseMindThat',
          });
        }

        // Specific checks for 'sapcrm020'
        if (user.crmSystemId === sapCrmNewCient) {
          if (!regex.validCrmCheckUk.test(state.value) && !regex.validCrmCheck.test(state.value)) {
            return handleEnteredOpportunityId({
              error: 'correctOpportunityMessage',
            });
          }
        } else {
          // General CRM check for other systems
          if (!regex.validCrmCheck.test(state.value)) {
            return handleEnteredOpportunityId({
              error: 'correctOpportunityMessage',
            });
          }
        }
      }

      // Check for duplicate opportunities
      const isSameOpportunities = opportunities.find(
        opportunity => opportunity.opportunityNumber.toString() === state.value,
      );
      if (isSameOpportunities) {
        return handleEnteredOpportunityId({
          error: 'youCanNotEnter',
        });
      }

      // Set the new opportunity
      setOpportunity(state.value);
      handleEnteredOpportunityId({
        value: '',
        error: '',
      });
    }
  };


  const handleRemoveOpportunity = value => {
    removeOpportunity(value);
  };

  const disabled = opportunities.length >= 10;
  const hasOpportunities = opportunities && opportunities.length > 0;
  const isValidReasonForLoan =
    descriptionForm.formData.reasonForLoanId === 2 || descriptionForm.formData.reasonForLoanId === 3;
  const isBorrowerOrAdmin = checkUserRoles(permissions.opportunitySearch, user);
  const isShowSearchButton = isBorrowerOrAdmin && !hasOpportunities && isValidReasonForLoan;

  const openOpportunitySearchPopup = useCallback(() => {
    const popup = $(`#${OPPORTUNITY_SEARCH_ID_POPUP}`);

    if (popup && popup.modal) {
      popup.modal('show');
    }
  }, []);

  return {
    state,
    disabled,
    isShowSearchButton,
    hasOpportunities,
    openOpportunitySearchPopup,
    handleInput,
    handleSetOpportunity,
    handleRemoveOpportunity,
    disableonDifferentStock,
  };
};
