import React, {PureComponent} from 'react';
import cn from 'classnames';

class ItemGallery extends PureComponent {
  setActiveImages = () => {
    const {changeActiveImage, item} = this.props;

    changeActiveImage(item);
  }

  render () {
    const {item, activeImage} = this.props;

    return (
      <div
        className={cn('image', {'active-image': item.kitImagesId === activeImage.kitImagesId})}
        onClick={this.setActiveImages}
      >
        <img 
          src={item.preview}
          width='40px'
          height='40px'
          alt={item.fileName}
        />
      </div>
    );
  }
}

export default ItemGallery;
