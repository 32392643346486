export const subscriptionsBreadcrumbData = [
  {
    name:'homepage',
    url: '/home'
  },
  {
    name:'notificationSettings',
  },
];

export const SET_NOTIFICATION_SETTINGS = 'NOTIFICATION/SET_NOTIFICATION_SETTINGS';
