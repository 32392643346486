import { connect } from 'react-redux';

import Filter from '../Filter';

const mapStateToProps = state => {
  return {
    language: state.languageReducer,
    authentication: state.authReducer,
    masterDataReducer: state.masterDataReducer,
    user: state.authReducer.user,
  }
};

export default connect(mapStateToProps)(Filter);
