import { batchActions } from 'redux-batched-actions';

import * as actionTypes from 'library/common/commonConstants/storeConstants';
import * as masterServices from 'library/api/master-data.service';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { scrollToTop } from 'library/utilities/scrollActions';

export const saveSellOff = (dataToSend, stockId, shopManagers, stockDetails, isEdit) => (dispatch) => {
  const request = isEdit ? masterServices.fetchMasterDataServicePut : masterServices.fetchMasterDataServicePost;
  dispatch(changeLoader(true));
  const url = isEdit ? URLS.updateSellOff(stockId) : URLS.updateSellOffPost(stockId);
  return request(url, dataToSend)
    .then(({ status, data }) => {
      dispatch(changeLoader(false));
      if (status === 200) {
        const updatedStockDetails = {
          ...stockDetails,
          stockSellOffPlatformModel: {
            ...dataToSend,
            shopManagers: shopManagers,
            id: data.id,
          },
        };
        dispatch(
          batchActions([
            dispatch({
              type: actionTypes.FETCH_STOCK_DETAILS,
              payload: updatedStockDetails,
            }),
            dispatch(toggleActionMessage(true, 'success', 'stockInformationSavedSuccessfully')),
            scrollToTop(500),
          ]),
        );
        return { success: true };
      }
      return { success: false };
    })
    .catch(error => {
      dispatch(changeLoader(false));
      dispatch(toggleActionMessage(true, 'error', 'errorOccuredText'));
      scrollToTop(500);
      return { success: false, error };
    });
};