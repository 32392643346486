import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import zeissInstance from 'library/utilities/AxiosInstance';

const uploadZmccAttachmentsService = async (transactionsId, data) => {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    return await zeissInstance.post(URLS.uploadZmccAttachmentAttachment.replace('{transactionsId}', transactionsId), data, config);
};

export default {
    uploadZmccAttachmentsService
};