import React from 'react';

import strings from 'resources/locales/Translate';
import SmallButton from 'library/common/commonComponents/SmallButton';

const DataExpandableTasksTable = props => {
  const { row, openDeleteTaskPopup, openEditTaskPopup, goToListOfStatuses, isShowStatusButton } = props;

  return (
    <>
      <td />
      <td colSpan='6' data-test='tasks-expandable-table'>
        <div className='d-flex my-2'>
          {isShowStatusButton && (
            <SmallButton iconName='edit' value={strings.edit} className='mr-3' onClick={() => openEditTaskPopup(row)} />
          )}
          {isShowStatusButton && (
            <SmallButton
              iconName='delete'
              value={strings.delete}
              className='error-button mr-3'
              onClick={() => openDeleteTaskPopup(row)}
              fill='#ff1a00'
            />
          )}
          <SmallButton value={strings.status} className='mr-3 no-border' onClick={() => goToListOfStatuses(row)} />
        </div>
      </td>
    </>
  );
}

export default DataExpandableTasksTable;
