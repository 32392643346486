import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { images } from 'library/common/commonConstants/ImageConstants';
import strings from 'resources/locales/Translate';
import { NavLink } from 'react-router-dom';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import Button from 'library/common/commonComponents/Button/Button';
import { updateUserCheckedDontShowAgain } from '../HomepageActions';
import { saveToStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';

export const WelcomePopup = ({ onClose, history, user, language }) => {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    $('#wlelcome-popup').modal('show');

    return () => {
      $('#wlelcome-popup').modal('hide');
      onClose();
    };
  }, []);

  const navigate = () => {
    $(`#${'wlelcome-popup'}`).modal('hide');
    onClose();
    history.push('documents/');
  };

  const onCloseClick = () => {
    $(`#${'wlelcome-popup'}`).modal('hide');
    onClose();
    if (selected) {
      updateUserCheckedDontShowAgain();
    }
    if (user?.stockRoles?.length === 0) {
      saveToStorage(identifiers.stockPopup, null);
      setTimeout(() => {
      $('#confirm-partner').modal('show');
      }, 500);
    }
  };

  const handleSelctCheckbox = () => {
    setSelected(!selected);
  };

  return (
    <div
      className='modal fade modal-container'
      id={'wlelcome-popup'}
      tabIndex='-1'
      role='dialog'
      aria-labelledby={'wlelcome-popup'}
      aria-hidden='true'
      data-backdrop='static'
    >
      <div className='modal-dialog welcome-popup' role='document'>
        <div className='modal-content rounded-0 '>
          <div className='modal-body p-0'>
            <div className='content' id='app-scheduler-wrapper'>
              <div className='add-scroll-bar'>
                <img src={images.welcomeImage} className='welcome-image mt-2'></img>
                <p className='mt-4 welcome-heading-text'>
                  {strings.welcomeHeading} <span style={{ color: '#0088D0' }}>ZEISS Demo Management App!</span>
                </p>
                <p className='mt-4 welcome-sub-heading-text adjust-width-para'>{strings.welcomeSubHeadingtext1}</p>
                <p className='welcome-sub-heading-text adjust-width-para'>{strings.welcomeSubHeadingtext2}</p>
                <div className='p-3 what-chnage mt-3 adjust-width-para'>
                  <p className='what-changed-text'>{strings.whatHasChanged}</p>

                  {language === 'de' ? (
                    <ul className='ml-4'>
                      <li className='welcome-sub-heading-text'>
                        <span style={{ fontWeight: '600' }}>{strings.transactions}</span> werden jetzt als{' '}
                        <span style={{ color: '#0088D0', fontWeight: '600' }}>{strings.transaction}</span> bezeichnet.
                      </li>
                      <li className='welcome-sub-heading-text'>
                        <span style={{ fontWeight: '600' }}>{strings.welcomeKits}</span> wurden in{' '}
                        <span style={{ color: '#0088D0', fontWeight: '600' }}>{strings.kits}</span> umbenannt.
                      </li>
                      <li className='welcome-sub-heading-text'>
                        <span style={{ fontWeight: '600' }}>Lager </span> werden nun als{' '}
                        <span style={{ color: '#0088D0', fontWeight: '600' }}>Standorte</span> bezeichnet.
                      </li>
                    </ul>
                  ) : (
                    <ul className='ml-4'>
                      <li className='welcome-sub-heading-text'>
                        <span style={{ fontWeight: '600' }}>{strings.transactions} </span> are now referred to as{' '}
                        <span style={{ color: '#0088D0', fontWeight: '600' }}>{strings.transaction}.</span>
                      </li>
                      <li className='welcome-sub-heading-text'>
                        <span style={{ fontWeight: '600' }}>{strings.welcomeKits} </span> have been renamed{' '}
                        <span style={{ color: '#0088D0', fontWeight: '600' }}>{strings.kits}.</span>
                      </li>
                      <li className='welcome-sub-heading-text'>
                        <span style={{ fontWeight: '600' }}>{strings.warehouses} </span> are now referred to as{' '}
                        <span style={{ color: '#0088D0', fontWeight: '600' }}>{strings.stocks}.</span>
                      </li>
                    </ul>
                  )}
                </div>
                <p className='welcome-sub-heading-text mt-4 adjust-width-para'>
                  {strings.welcomeAboutDma}{' '}
                  <span>
                    <button type='button' class='btn btn-link p-0 welcome-sub-heading-text' onClick={navigate}>
                      {strings.welcomeDma}
                    </button>
                  </span>
                </p>
                <p className='welcome-sub-heading-text mt-4 adjust-width-para'>{strings.welcomeBUMesaage}</p>
                {language === 'de' ? (
                  <p className='welcome-sub-heading-text mt-4'>
                    Falls Sie Fragen haben oder Unterstützung benötigen, zögern Sie nicht, sich an unser{' '}
                    <span className='support-team-text-color'>Support-Team</span> zu wenden.
                  </p>
                ) : (
                  <p className='welcome-sub-heading-text mt-4'>
                    If you have any questions or need assistance, please do not hesitate to contact our{' '}
                    <span className='support-team-text-color'>support team.</span>
                  </p>
                )}
                <p className='welcome-sub-heading-text mt-4'>{strings.thankyouMessage}</p>
              </div>
            </div>
            <hr></hr>
            <div className='d-flex justify-content-between align-items-center p-3'>
              <Checkbox
                label={strings.dontShowMessage}
                field='canBeShipped'
                isRequired={true}
                selected={selected}
                onChange={handleSelctCheckbox}
                newuiVersionStyle={true}
              />
              <Button
                value={strings.close}
                onClick={() => onCloseClick()}
                bgFill={true}
                styleClass='add-button welcomePopup-close-btn'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    language: state.languageReducer.language,
    user: state.authReducer.user,
    homepage: state.homepage,
    appActions: state.appActionsReducer,
  };
};

export default connect(mapStateToProps, {})(WelcomePopup);

