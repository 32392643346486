import React from "react";
import strings from "resources/locales/Translate";

export const IqsResources = () => {
    return(
        <div className="d-flex align-items-center justify-content-center mt-5">
        {strings.noDataFoundText}
    </div>
    )
}

export default IqsResources;