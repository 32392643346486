import React from 'react';
import cn from 'classnames';

const SystemMainComponentItem = ({ item, onClick, isActive }) => {
  const handleClick = () => {
    onClick(item.systemMainComponentId);
  };

  return (
    <li className={cn('list-item interactive', { 'active': isActive })} onClick={handleClick}>
      {item.systemMainComponentName}
    </li>
  );
};

export default SystemMainComponentItem;
