export const statusListBreadcrumbData = [
  {
    name:'homepage',
    url: '/home'
  },
  {
    name:'dusdTask',
    url: '/dusd/dusd-task',
  },
  {
    name:'status',
  },
];

export const itemsPerPage = {
  10: { id: 10, pages: 10 },
  20: { id: 20, pages: 20 },
  50: { id: 50, pages: 50 },
};

export const GET_STATUS_LIST = 'GET_STATUS_LIST';
