export const ADD_REMOVE_KIT_ATTACHMENTS = '@@/KIT_DETAILS/ADD_REMOVE_KIT_ATTACHMENTS';
export const ADD_KIT_DETAILS_ATTACHMENT_ID_FOR_REMOVE = '@@/KIT_DETAILS/ADD_KIT_DETAILS_ATTACHMENT_ID_FOR_REMOVE';
export const FETCH_KIT_DETAILS_ATTACHMENTS = '@@/KIT_DETAILS/FETCH_KIT_DETAILS_ATTACHMENTS';
export const FETCH_KIT_DETAILS_DAMAGE_LOSS_REPORT = '@@/KIT_DETAILS/FETCH_KIT_DETAILS_DAMAGE_LOSS_REPORT';
export const GET_KIT_DETAILS = '@@/KIT_DETAILS/GET_KIT_DETAILS';
export const SAVE_CURRENT_TAB = '@@/KIT_DETAILS/SAVE_CURRENT_TAB';
export const CLEAR_KIT_DETAILS_DATA = '@@/KIT_DETAILS/CLEAR_KIT_DETAILS_DATA';
export const FETCH_KIT_DETAILS_COMMENTS = '@@KIT_DETAILS/FETCH_KIT_DETAILS_COMMENTS';
export const FETCH_KIT_TRANSACTIONS_ATTACHMENTS = '@@KIT_DETAILS/FETCH_KIT_TRANSACTIONS_ATTACHMENTS';
export const FETCH_WORKFLOW_QUESTIONS = '@@KIT_DETAILS/FETCH_WORKFLOW_QUESTIONS';
export const FETCH_TRANSACTION_LOAN_ORDER = 'FETCH_TRANSACTION_LOAN_ORDER';

export const kitsDetailsBreadcrumbData = [
  {
    name: 'homepage',
    url: '/home',
  },
  {
    name: 'kits',
    url: '/kits',
  },
];

export const dustUserKitsDetailsBreadcrumbData = [
  {
    name: 'homepage',
  },
  {
    name: 'kits',
  },
];
