import React, {Component} from 'react';
import $ from 'jquery';

import Button from 'library/common/commonComponents/Button/Button';
import FormComponent from 'library/common/commonComponents/FormComponent/FormComponent';

import strings from 'resources/locales/Translate';

import {cancellationModel} from '../../dataModel';

class CancellationRequestPopup extends Component {

  state = {
    reasonForCancellation: {
      formData: {},
    },
  };

  componentWillUnmount() {
    this.handleInputValueChange({});
  }

  closeModal = () => {
    const {id} = this.props;

    $(`#${id}`).modal('hide');
    this.handleInputValueChange({});
    if (this.props.isRejectCancel) { //Bugfix 5670
      this.props.setRejectCancelRequest(false);
    }
  };

  handleInputValueChange = (value) => {
    this.setState({reasonForCancellation: value});
  }

  handleSave = () => {
    const {reasonForCancellation} = this.state;
    const { transactionsId, saveCancellationRequest, isFinalCancel, isRejectCancel } = this.props;
    const reason = reasonForCancellation.formData ? reasonForCancellation.formData.reason : '';
    saveCancellationRequest(transactionsId, reason, isFinalCancel, isRejectCancel);
    this.closeModal();
  };

  render() {
    const {reasonForCancellation} = this.state;
    const { id, isFinalCancel, isRejectCancel } = this.props;
    let title = strings.requestCancel;
    let confirmText = strings.reasonCancelBooking;

    if (isRejectCancel) {
      title = strings.rejectCancelRequest;
      confirmText = strings.confirmRejectCancel;
    } else if (isFinalCancel) {
      title = strings.cancelBooking
      confirmText = strings.confirmBookingCancel;
    }
    return (
      <div
        className='modal fade'
        role='dialog'
        tabIndex='-1'
        aria-labelledby='cancellationRequestPopup'
        aria-hidden='true'
        id={id}
      >
        <div className={isFinalCancel ? 'modal-dialog modal-lg-cancel' : 'modal-dialog modal-lg'} role='document'>
          <div className='modal-content rounded-0'>
            <div className='modal-body p-3'>
              <div className='content'>
                <h3 className='modal-title'>{title}</h3>
                <h6 className="pt-3 pb-3">{confirmText}</h6>
                {!isFinalCancel &&
                <FormComponent
                  model={cancellationModel}
                  value={reasonForCancellation.formData}
                  doValidate={true}
                  onChange={(data) => this.handleInputValueChange(data)}
                  />}

                {!isFinalCancel && <div className='d-flex mt-3' style={{ paddingLeft: '20rem' }}>
                  <div className='col-md-6'>
                    <Button
                      styleClass='close-button'
                      iconName='cross'
                      bgFill={false}
                      value={strings.cancel}
                      onClick={this.closeModal}
                    />
                  </div>
                  <div className="col-md-6 pr-0">
                    <Button
                      styleClass='confirm-button'
                      iconName='save'
                      value={strings.confirm}
                      onClick={this.handleSave}
                    />
                  </div>
                </div>}
                {isFinalCancel && <div className='d-flex mt-3' style={{ padding: '0 2rem 0 2rem' }}>
                  <div className="col-md-6 pr-0">
                    <Button
                      styleClass='confirm-button'
                      value={strings.yes}
                      onClick={this.handleSave}
                    />
                  </div>
                  <div className='col-md-6'>
                    <Button
                      styleClass='close-button'
                      bgFill={false}
                      value={strings.no}
                      onClick={this.closeModal}
                    />
                  </div>

                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CancellationRequestPopup;
