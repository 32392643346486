import React, { Fragment } from 'react';
import cn from 'classnames';

import strings from 'resources/locales/Translate';
import CreateStockActionButtons from 'library/common/commonComponents/CreateStockActionButtons';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import FileUploader from 'library/common/commonComponents/FileUploader/FileUploader';
import Icon from 'library/common/commonComponents/Icon/Icon';
import Button from 'library/common/commonComponents/Button/Button';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';

import CreateOrEditPopup from './Components/CreateOrEditFolderPopup';
import Folder from './Components/Folder';
import DocumentsBreadcrumb from './Components/DocumentsBreadcrumb';
import { useDocuments } from './Documents.hook';
import './Documents.styles.scss';

const Documents = ({
  user,
  fetchdDocumentsAndFolders,
  uploadDocuments,
  history,
  appActions,
  documentsReducers,
  removeFile,
  addFile,
  createFolder,
  deleteFolder,
  editFolder,
  resetDocumentsAndFolders,
}) => {
  const { files, folders } = documentsReducers;
  const { displayActionMessage, type, message } = appActions;
  const {
    viewType,
    folder,
    foldersNavigation,
    setFolder,
    setFoldersNavigation,
    toggleViewType,
    handleSave,
    cancelFileUploading,
    openCreateFolderPopup,
    handleRemoveFile,
    handleAddFile,
    openEditFolderPopup,
  } = useDocuments({
    uploadDocuments,
    history,
    removeFile,
    addFile,
    resetDocumentsAndFolders,
    fetchdDocumentsAndFolders,
  });
  const folderId = foldersNavigation[foldersNavigation.length - 1].id;

  return (
    <div className='documents-container' data-test='document-component'>
      {displayActionMessage && <ActionMessage type={type} message={strings[message] || message}/>}
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-sm-12 d-flex justify-content-between align-items-center mb-3 mt-5'>
            <h3 className='form-section-title mb-0'>{strings.documentsPageTitleText}</h3>
            <div className='view-type-buttons'>
              {user.admin && (
                <Button
                  value={strings.createFolder}
                  onClick={openCreateFolderPopup}
                  bgFill={false}
                  iconName='plus'
                  styleClass='mr-2'
                />
              )}
              <button onClick={() => toggleViewType('list')}>
                <Icon name='list_view' width={32} height={32} fill={viewType === 'list' ? '#000' : '#e0e1dd'}/>
              </button>
              <button onClick={() => toggleViewType('grid')}>
                <Icon name='grid_view' width={32} height={32} fill={viewType === 'grid' ? '#000' : '#e0e1dd'}/>
              </button>
            </div>
          </div>
        </div>
        <DocumentsBreadcrumb foldersNavigation={foldersNavigation} setFoldersNavigation={setFoldersNavigation} />
        <div>
          <div>
            {!files.length && !folders.length && (
              <div className='d-flex justify-content-center my-4'>
                <strong>{strings.noDataFoundText}</strong>
              </div>
            )}

            {viewType === 'grid' && user.admin && (
              <p className='pt-2 pb-2'>{strings.supportedDocumentsMessage}</p>
            )}

            <div className={cn({ 'row': viewType === 'grid', 'folder-container': !files.length && folders.length })}>
              {folders.length > 0 && folders.map(folder => (
                <Fragment key={folder.id}>
                  <Folder
                    folder={folder}
                    user={user}
                    viewType={viewType}
                    parentFolderId={folderId}
                    openFolder={setFoldersNavigation}
                    deleteFolder={deleteFolder}
                    openEditFolderPopup={openEditFolderPopup}
                    setFolder={setFolder}
                  />
                </Fragment>
              ))}
            </div>

            <FileUploader
              mimeType='.pdf,.doc,.docx,.xls,.xlsx,application/msword,application/vnd.openxm'
              files={files}
              viewType={viewType}
              isDownloadable
              downloadStream
              downloadIdField='id'
              downloadUrl={process.env.REACT_APP_BASE_URL + URLS.downloadDocument}
              isUploadAllowed={user.admin}
              onRemove={handleRemoveFile}
              onFileAdd={handleAddFile}
            />

            {viewType === 'list' && user.admin && (
              <p className='pt-2 pb-2'>{strings.supportedDocumentsMessage}</p>
            )}
          </div>
        </div>

        {user && user.admin && (
          <CreateStockActionButtons onSave={handleSave} onCancel={cancelFileUploading}/>
        )}

      </div>
      <CreateOrEditPopup
        id='create-folder-popup'
        parentFolderId={folderId}
        createFolder={createFolder}
        fetchdDocumentsAndFolders={fetchdDocumentsAndFolders}
        setFolder={setFolder}
      />
      <CreateOrEditPopup
        id='edit-folder-popup'
        parentFolderId={folderId}
        createFolder={editFolder}
        fetchdDocumentsAndFolders={fetchdDocumentsAndFolders}
        folder={folder}
        setFolder={setFolder}
        isEditFolder={true}
      />
    </div>
  );
};

export default Documents;
