import { useState, useEffect, useMemo } from 'react';
import moment from 'moment';

import * as actionTypes from 'library/common/commonConstants/storeConstants';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { getCurrencyFormatForChart } from 'library/utilities/getCurrencyFormat'

import { YEAR } from './Statistics.constants';

const INITIAL_FILTER = {
  country: null,
  countryFullValue: null,
  stock: null,
  stockFullValue: null,
  timePeriod: 1,
  year: [13],
};

export const useStatistics = ({ countries, fetchMasterData, statisticsData, language, }) => {
  document.title = 'Statistics';
  const [isShowFilter, setIsShowFilter] = useState(true);
  const [filter, setFilter] = useState(INITIAL_FILTER);

  useEffect(() => {
    const getCountries = async () => {
      await fetchMasterData(URLS.countryList, actionTypes.FETCH_COUNTRY_LIST);
    };

    if (!(countries && countries.length)) {
      getCountries();
    }
  }, [countries, fetchMasterData]);

  useEffect(() => {
    fetchMasterData(URLS.countryList, actionTypes.FETCH_COUNTRY_LIST);
  }, [language]);

  const chartData = useMemo(() => {
    return statisticsData && statisticsData.reduce((acc, cur) => {
      let label;
      const isYear = filter.timePeriod === YEAR;
      if (isYear) {
        const dateFrom = moment(cur.dateFrom).format('YYYY');
        const dateTo = moment(cur.dateTo).format('YY');
  
        label = `${dateFrom}-${dateTo}`;
      } else {
        label =  moment(cur.dateFrom).format('MMMM');
      }

      if (acc.order) {
        acc.order.push(cur.order);
      } else {
        acc.order = [cur.order];
      }

      if (acc.reserved) {
        acc.reserved.push(cur.reserved);
      } else {
        acc.reserved = [cur.reserved];
      }

      if (acc.labels) {
        acc.labels.push(label);
      } else {
        acc.labels = [label];
      }

      return acc;
    }, {})
  }, [statisticsData, filter.timePeriod]);

  const showOrHideFilter = () => {
    setIsShowFilter(prevState => !prevState);
  };

  const data = {
    labels: chartData.labels,
    datasets: [
      {
        summaryTooltip: 'Summary',
        label: 'Reservations',
        backgroundColor: '#0088d0',
        borderColor: '#0088d0',
        borderWidth: 1,
        stack: 1,
        hoverBackgroundColor: '#0088d0d6',
        hoverBorderColor: '#0088d0d6',
        data: chartData.reserved,
        barPercentage: 0.6,
      },
      {
        summaryTooltip: 'Summary',
        label: 'Orders',
        backgroundColor: '#111e92',
        borderColor: '#111e92',
        borderWidth: 1,
        stack: 1,
        hoverBackgroundColor: '#111e92cc',
        hoverBorderColor: '#111e92cc',
        data: chartData.order,
        barPercentage: 0.6,
      },
    ]
  };

  const options = {
    legend: {
      display: true,
      position: 'right',
      labels: {
        fontColor: '#91929b',
        padding: 20
      }
    },
    scales: {
      xAxes: [{
        stacked: true,
      }],
      yAxes: [{
        ticks: {
          beginAtZero: true,
          callback: value => `${value} EUR`,
        }
      }],
    },
    tooltips: {
      mode: 'index',
      callbacks: {
        title: (tooltipItem, data) => {
          let label = 0;
          const tooltipText = data.datasets[0].summaryTooltip;
          tooltipItem.forEach(item => {
            label += Number(item.value);
          });

          return `${tooltipText}: ${label} EUR`;
        },
        label: (tooltipItem, data) => {
          const label = tooltipItem.yLabel;
          const labelText = data.datasets[tooltipItem.datasetIndex].label;

          return `${labelText}: ${label} EUR`;
        },
      }
    },
    plugins: {
      datalabels: {
        formatter: value => {
          if (value) {
            return getCurrencyFormatForChart(value);
          }
          return null;
        },
        color: '#fff',
      },
    },
  };

  return {
    isShowFilter,
    filter,
    setFilter,
    showOrHideFilter,
    data,
    options,
  };
}
