import React from 'react';

import strings from 'resources/locales/Translate';

const MonthBlock = ({ thisMonthStatistics }) => {
  const {
    onTimeReceivedInStockInPercents,
    onTimeShippingRateInPercents,
    reconditioningTime,
    totalDelayedKits,
  } = thisMonthStatistics || {};

  return (
    <div className='container-fluid month-wrapper'>
      <h3 className='month-title mb-4'>{strings.thisMonth}</h3>
      <div className='row'>
        <div className='col-3'>
          <p className='statistics-text'>{onTimeShippingRateInPercents || 0} %</p>
          <p className='subtitle'>{strings.ontimeShippingRate}</p>
        </div>
        <div className='col-3'>
          <p className='statistics-text'>{onTimeReceivedInStockInPercents || 0} %</p>
          <p className='subtitle'>{strings.ontimeRecievedInStock}</p>
        </div>
        <div className='col-3'>
          <p className='statistics-text'>{totalDelayedKits || 0}</p>
          <p className='subtitle'>{strings.totalDelayedKits}</p>
        </div>
        <div className='col-3'>
          <p className='statistics-text'>{reconditioningTime || 0} {strings.dayOrDayes(reconditioningTime)}</p>
          <p className='subtitle'>{strings.reconditioningTime}</p>
        </div>
      </div>
    </div>
  );
}

export default MonthBlock;
