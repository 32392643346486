export const getSymptomCode = (language, code) => {
  const newCode = code.replace(/\s+/g, '');

  if (language === 'en') {
    switch (newCode) {
      case 'ZS1000110':
        return 'The device suffered physical damage';
      case 'ZS1000120':
        return 'Mechanical Problem';
      case 'ZS1000130':
        return 'The device is not powering up';
        case 'ZS1000140':
        return 'The device is displaying an error message';
      case 'ZS1000150':
        return 'The device is not working as expected';
      case 'ZS1000160':
        return 'The device is returning insufficient results';
        case 'ZS1000170':
        return 'Display/Image';
      case 'ZS1000180':
        return 'Printing Issue';
      case 'ZS1000190':
        return 'Noise';
      case 'ZS1000200':
        return 'Smell';
      case 'ZS1000210':
        return 'Electrical Shock';
      case 'ZS1000220':
        return 'Data Issues';
      case 'ZS1000230':
        return 'Media Issues';
      case 'ZS1000260':
        return 'Optical Problem';
      case 'ZS1000270':
        return 'I have a question about the usage of the device';
      case 'ZS1000810':
        return 'Complaint about Service Performance';
      case 'ZS1000820':
        return 'Complaint about Service Price';
      case 'ZS1000830':
        return 'Marketing Question';
      case 'ZS1000840':
        return 'General Business Request';
      case 'ZS1000850':
        return 'I require assistance for installation/relocation';
      case 'ZS1000999':
        return 'Something else';
      default:
        break;
    }
  } else if (language === 'de') {
    switch (newCode) {
      case 'ZS1000110':
        return 'Das Gerät ist physisch beschädigt.';
      case 'ZS1000120':
        return 'Mechanisches Problem';
      case 'ZS1000130':
        return 'Das Gerät lässt sich nicht anschalten.';
        case 'ZS1000140':
        return 'Das Gerät zeigt eine Fehlermeldung.';
      case 'ZS1000150':
        return 'Das Gerät funktioniert nicht wie erwartet.';
      case 'ZS1000160':
        return 'Das Gerät liefert unschlüssige Ergebnisse.';
        case 'ZS1000170':
        return 'Display/Bild';
      case 'ZS1000180':
        return 'Druckerfehler';
      case 'ZS1000190':
        return 'Lärm';
      case 'ZS1000200':
        return 'Geruch';
      case 'ZS1000210':
        return 'Elektrischer Schlag';
      case 'ZS1000220':
        return 'Datenproblem';
      case 'ZS1000230':
        return 'Datenträgerproblem';
      case 'ZS1000260':
        return 'Optisches Problem';
      case 'ZS1000270':
        return 'Ich habe eine Frage zur Nutzung des Geräts';
      case 'ZS1000810':
        return 'Beanstandung der Serviceperformance';
      case 'ZS1000820':
        return 'Beschwerde über den Servicepreis';
      case 'ZS1000830':
        return 'Marketingfrage';
      case 'ZS1000840':
        return 'Allgemeine Geschäftsanfrage';
      case 'ZS1000850':
        return 'Ich benötige Hilfe bei der Installation/beim Umzug des Geräts.';
      case 'ZS1000999':
        return 'Andere Gründe';
      default:
        break;
    }
  }
};
