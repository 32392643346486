import { deepBlueColor, whiteColor } from 'resources/styles/variables.scss';
import strings from 'resources/locales/Translate';
import { getDateInFormat } from 'library/utilities/getDateInFormat';

export const useWorkingCapitalInStockChart = ({ chartData, language }) => {
  const getMonths = () => {
    return chartData.names.map(item => getDateInFormat('MMM', item, null, language));
  };

  const isMonthPeriod = chartData.type === 'MONTH';
  const labels = isMonthPeriod ? getMonths() : chartData.names;
  const data = {
    labels: labels,
    datasets: [
      {
        backgroundColor: deepBlueColor,
        borderColor: deepBlueColor,
        borderWidth: 1,
        stack: 1,
        hoverBackgroundColor: deepBlueColor,
        hoverBorderColor: deepBlueColor,
        data: chartData.items,
        barThickness: 65,
      },
    ]
  };

  const options = {
    legend: false,
    tooltips: {
      enabled: false
    },
    scales: {
      xAxes: [{
        gridLines: {
            display: false
        },
        scaleLabel: {
          display: true,
          labelString: isMonthPeriod ? strings.months : strings.years,
          fontSize: 14,
        },
        ticks: {
          fontWeight: 'bold',
          fontFamily: 'FrutigerNextBold',
        },
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: strings.valuesInTEUR,
          fontSize: 14,
        },
        ticks: {
          beginAtZero: true,
        },
      }],
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        clamp: true,
        align: context => {
          let bar = context.chart.getDatasetMeta(context.datasetIndex).data[context.dataIndex];
          let barHeight = Math.abs(bar['_view'].y-bar['_view'].base);
          const threshold = 15;
          return barHeight < threshold ? 'end' : 'start'
        },
        color: context => {
          let bar = context.chart.getDatasetMeta(context.datasetIndex).data[context.dataIndex];
          let barHeight = Math.abs(bar['_view'].y-bar['_view'].base);
          const threshold = 15;
          return barHeight < threshold ? deepBlueColor : whiteColor;
        },
        font: {
          weight: 'bold'
       },
      },
    },
  };
  const hasDataForChart = chartData.items && chartData.items.length > 0;

  return {
    data,
    options,
    hasDataForChart,
    getMonths,
  };
}
