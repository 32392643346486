import React from 'react';
import Scheduler from 'react-big-scheduler';
import 'react-big-scheduler/lib/css/style.css';
import withDndContext from 'library/utilities/withDndContext';
import './iqsCalendar.styles.scss';
import SlotItem from './components/SlotItem';
import { useAppSchedular } from './IQSAppSchedular.hook';
import CalendarHeader from './components/CalendarHeader';
// import Scheduler from 'library/common/commonComponents/ReactBigScheduler';
import CalendarLegends from './components/CalendarLegends';

const IQSCalendar = () => {
  const {
    calendarConfig,
    prevClick,
    nextClick,
    onSelectDate,
    onViewChange,
    eventClicked,
    renderDayHeader,
    selectedViewType,
    slotTempletResolver,
    toggleExpandFunc,
    schedulerRef,
    todayClick,
    selectedDate,
    slotItemPopover,
  } = useAppSchedular();
  // Custom Event Template
  const customEventTemplateResolver = (_, event, bgColor, isStart, isEnd, mustAddCssClass, mustBeHeight) => (
    <SlotItem mustAddCssClass={mustAddCssClass} mustBeHeight={mustBeHeight} event={event} />
  );

  return (
    <>
      <CalendarHeader onViewChange={onViewChange} selectedViewType={selectedViewType} prevClick={prevClick} nextClick={nextClick} todayClick={todayClick} onSelectDate={onSelectDate} selectedDate={selectedDate}/>
      {calendarConfig && (
        <div className='iqs-calendar-container' ref={schedulerRef}>
          <Scheduler
            schedulerData={calendarConfig}
            prevClick={prevClick}
            nextClick={nextClick}
            onSelectDate={onSelectDate}
            onViewChange={onViewChange}
            eventItemClick={eventClicked}
            eventItemTemplateResolver={customEventTemplateResolver}
            nonAgendaCellHeaderTemplateResolver={renderDayHeader}
            slotItemTemplateResolver={slotTempletResolver}
            toggleExpandFunc={toggleExpandFunc}
            eventItemPopoverTemplateResolver={slotItemPopover}
          />
        </div>
      )}
      <CalendarLegends />
    </>
  );
};

export default withDndContext(IQSCalendar);
