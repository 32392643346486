import React from 'react';

import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import Button from 'library/common/commonComponents/Button/Button';
import SearchField from 'library/common/commonComponents/SearchField/SearchField';
import strings from 'resources/locales/Translate';

import { maintainStatusBreadcrumbs } from './MaintainStatus.constants';
import { useMaintainStatus } from './MaintainStatus.hook';
import './maintainStatus.styles.scss';

const MaintainStatus = ({ searchKitBySerialNumber, history }) => {
  const {
    value,
    error,
    isLoading,
    handleSearch,
    handleSearchKitBySerialNumber,
  } = useMaintainStatus({ searchKitBySerialNumber, history });

  return (
    <div className='maintainStatus-container container-fluid'>
      <Breadcrumbs data={maintainStatusBreadcrumbs} />
      <h1 className='title mb-3'>{strings.dustMaintainStatus}</h1>
      <div className='d-flex'>
        <div className='text'>{strings.enterSerialNumber}:</div>
        <div className='input-wrapper mr-3'>
          <SearchField onChange={handleSearch} initialValue={value} placeholder='' error={error} />
        </div>
        <div>
          <Button
            bgFill={false}
            onClick={handleSearchKitBySerialNumber}
            value={strings.search}
            styleClass='w-auto px-5'
            loading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default MaintainStatus;
