import React from 'react';

import strings from 'resources/locales/Translate';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import ShowMore from 'library/common/commonComponents/ShowMore';
import ConfirmDeletionPopup from 'library/common/commonComponents/ConfirmationBox';

import AddCardBlock from './components/AddCardBlock';
import AddPurchaseRequestsPopup from './components/AddPurchaseRequestsPopup';
import AddCommentPopup from './components/AddCommentPopup';
import Card from './components/Card';
import { purchaseRequestsBreadcrumb } from './PurchaseRequests.constants';
import { usePurchaseRequests } from './PurchaseRequests.hook';
import './PurchaseRequests.styles.scss';

const PurchaseRequests = ({
  user,
  language,
  appActions,
  addPurchaseRequests,
  resetPurchaseRequests,
  createComment,
  countries,
  rmsSystemClass,
  getInitialPurchaseRequestsAndPopupData,
  getPurchaseRequests,
  purchaseRequests,
  sellOffCurrency,
  totalPurchaseRequestsItems,
  fetchSystemMainComponentData,
  getCountryList,
  updatePurchaseRequests,
  deletePurchaseRequests,
}) => {
  const {
    isLoadingRequests,
    cardInfo,
    isLoading,
    displayActionMessage,
    type,
    message,
    setCardInfo,
    handleOpenAddOrEditCardPopup,
    loadMoreHandler,
    handleClickOnCard,
    isHasMoreItems,
    handleDelete,
    handleCloseConfirmDeletionModal,
    handleOpenConfirmDeletionModal,
    deletionError,
    isLoadingDeletion,
  } = usePurchaseRequests({
    appActions,
    getInitialPurchaseRequestsAndPopupData,
    getPurchaseRequests,
    purchaseRequests,
    totalPurchaseRequestsItems,
    resetPurchaseRequests,
    getCountryList,
    language,
    deletePurchaseRequests,
  });

  return (
    <div className='purchaseRequests-container'>
      {displayActionMessage && <ActionMessage type={type} message={strings[message]} />}
      <div className='container-fluid mb-3'>
        <Breadcrumbs data={purchaseRequestsBreadcrumb} />
        <h1 className='kit-title mb-5'>{strings.purchaseRequests}</h1>
        <div className='row'>
          <div className='col-lg-4 col-md-6 card-container'>
            <AddCardBlock handleClick={handleOpenAddOrEditCardPopup} />
          </div>
          {purchaseRequests && purchaseRequests.length > 0 && (
            purchaseRequests.map(request => (
              <div className='col-lg-4 col-md-6 card-container' key={request.purchaseId} onClick={() => handleClickOnCard(request)}>
                <Card requestInfo={request} />
              </div>
            ))
          )}
          <ShowMore load={loadMoreHandler} hasMoreItems={isHasMoreItems} pending={isLoadingRequests}/>
        </div>
      </div>
      <AddPurchaseRequestsPopup
        id='add-purchase-requests-popup'
        countries={countries}
        rmsSystemClass={rmsSystemClass}
        sellOffCurrency={sellOffCurrency}
        addPurchaseRequests={addPurchaseRequests}
        fetchSystemMainComponentData={fetchSystemMainComponentData}
        cardInfo={cardInfo}
        setCardInfo={setCardInfo}
        updatePurchaseRequests={updatePurchaseRequests}
      />
      <AddCommentPopup
        id='add-comment-popup'
        cardInfo={cardInfo}
        createComment={createComment}
        setCardInfo={setCardInfo}
        onOpenAddOrEditCardPopup={handleOpenAddOrEditCardPopup}
        onOpenConfirmDeletionModal={handleOpenConfirmDeletionModal}
        user={user}
      />
      <ConfirmDeletionPopup
        id='confirm-delete-modal'
        onConfirm={handleDelete}
        onClose={handleCloseConfirmDeletionModal}
        error={deletionError}
        isLoading={isLoadingDeletion}
        title={strings.deletePurchaseRequest}
        subtitle={strings.areYouSureYouWantToDeletePurchaseRequest}
      />
      {isLoading && <div className='loader' />}
    </div>
  );
}

export default PurchaseRequests;
