import React from 'react';

import strings from 'resources/locales/Translate';

import { InputField } from '../InputField';
import './inputGroupStyles.scss';

export default class InputGroup extends InputField {
  render() {
    const {
      label,
      isRequired,
      type,
      extraProps,
      placeholder,
      inputGroupText,
      showExtraInfo,
      infoMessage,
      isAppend,
      disabled,
      error,
      value,
      isInfoOpen,
      neweVersionStyle
    } = this.props;
    return (
      <div className='input-group-field'>
        <div className='label-container d-flex justify-content-between align-items-center'>
          <div className='d-flex position-relative'>
            <p>{label} {isRequired && label && <span className='mandatory-asterik'>*</span>}</p>
            {showExtraInfo && (
              <p className='input-info cursor-pointer' onClick={this.toggleInfoMessage}>
                i
              </p>
            )}
            {isInfoOpen && <div className='info-message position-absolute'>{strings[infoMessage]}</div>}
          </div>
        </div>

        <div className='input-group'>
          {!isAppend && (
            <div className='input-group-prepend rounded-0'>
              <span className={'input-group-text ' + (error && 'input-error') +' ' + (neweVersionStyle ? 'white-background': '')} id='basic-addon2'>
                {inputGroupText}
              </span>
            </div>
          )}
          <input
            type={type}
            className={ (neweVersionStyle && !isAppend ? 'hide-border-left': '') + '' +(neweVersionStyle && isAppend ? 'hide-border-right': '') + ' ' + 'form-control rounded-0 position-relative ' + (error && 'input-error')}
            placeholder={placeholder}
            {...extraProps}
            onChange={this.onChange}
            onBlur={this.onBlur}
            value={(value == 0 || value) ?  value : ''}
            disabled={disabled}
          />
          {isAppend && (
            <div className='input-group-append rounded-0'>
              <span className={'input-group-text ' + (error && 'input-error') +' ' + (neweVersionStyle ? 'white-background': '')} id='basic-addon2'>
                {inputGroupText}
              </span>
            </div>
          )}
        </div>
        {error && <span className='text-danger error-block'>{strings[error]}</span>}
      </div>
    );
  }
}
