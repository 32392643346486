import React, { useRef, forwardRef } from 'react';
import cn from 'classnames';

import { useOutsideClick } from './OutsideClick.hook';
import styles from './Popover.module.scss';

const Popover = (props) => {
    const {
      className,
      ...rest
    } = props;
  
    const popoverRef = useRef(null);
    useOutsideClick(props, popoverRef);
    
    const classes = cn(styles.popover, className);
  
    return (
      <div tabIndex={-1} className={classes} {...rest} ref={popoverRef} />
    );
  };
  
  export default forwardRef(Popover);