import React from 'react';

import strings from 'resources/locales/Translate';
import Button from 'library/common/commonComponents/Button/Button';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';



const SystemClassificationBlock = props => {
  const {
    handleInput,
    copySystemClassificationList,
    isEditMode,
    onEdit,
    selectedSystemClassification,
    copySystemMainComponentList,
    handleFilterChange,
    filter,
    onSave,
    checkedValues,
  } = props;

  let systemClassificationGroupNames = [];
  for (var eachClassification = 0; eachClassification < copySystemClassificationList.length; eachClassification++) {
    systemClassificationGroupNames.push(
      copySystemClassificationList[eachClassification]['systemClassificationGroupName'],
    );
  }

  let lightMicroscopesList = copySystemClassificationList.filter(
    item => item.systemClassificationGroupName === 'light-microscopes',
  );
  let electronMicroscopesList = copySystemClassificationList.filter(
    item => item.systemClassificationGroupName === 'electron-microscopes',
  );

  let latestData = [];
  latestData = copySystemMainComponentList && copySystemMainComponentList['systemClasses'];

  Array.from(new Set(latestData)).sort((a, b) => {
    const systemClassA = a.systemClassName.toUpperCase();
    const systemClassB = b.systemClassName.toUpperCase();

    return systemClassA > systemClassB ? 1 : -1;
  });

  return (
    <div className='mb-5 m-4'>
      {copySystemClassificationList.length > 0 &&
        lightMicroscopesList.length > 0 &&
        lightMicroscopesList.map((item, index) => (
          <>
            {isEditMode && item.systemClassificationName === selectedSystemClassification ? (
              <div className='row'>
                <p className='col-lg-2 ml-4 mt-3'>{item.systemClassificationName}</p>

                <Dropdown
                data={latestData}
                hasSection
                multiSelect
                filter
                field={{ item: `${item.systemClassificationName}`, key: index }}
                idKey='systemMainComponentId'
                displayValue={[{ key: 'systemMainComponentName', separator: '' }]}
                optionsArrayKey='systemMainComponent'
                titleDisplay={[{ key: 'systemClassName', separator: '' }]}
                isRequired={true}
                placeholder={strings.pleaseSelect + '...'}
                onChange={handleFilterChange}
                mainContainerStyle='col-lg-3 mr-0 p-0'
                value={filter[`${item.systemClassificationName}`]}
                  fullValue={filter[`${item.systemClassificationName}FullValue`]}
              />
                <Button
                  dataTest="lightMicroscopesListEdit"
                  bgFill={false}
                  iconName={
                    isEditMode && item.systemClassificationName === selectedSystemClassification ? 'cross' : 'edit'
                  }
                  value={isEditMode ? strings.cancel : strings.edit}
                  onClick={() => {
                    {
                      let data = [];
                      item.systemClassClassification.map(each => <span className='w-20'>{data.push(each)}</span>);
                      onEdit(item.systemClassificationName, isEditMode, data);
                    }
                  }}
                  styleClass='col-lg-1 mb-3 mr-3 ml-3 mt-3'
                />
                <Button
                  dataTest="lightMicroscopesListSave"
                  bgFill={false}
                  iconName='save'
                  value={strings.save}
                  onClick={() =>
                    onSave(
                      filter[`${item.systemClassificationName}FullValue`],
                      item.systemClassificationId,
                      checkedValues,
                      item.systemClassificationName
                    )
                  }
                  styleClass='col-lg-1 mb-3 mt-3'
                />
              </div>
            ) : (
              <div className='row'>
                <p className='col-lg-2 ml-4 mb-3'>{item.systemClassificationName}</p>
                <p className='col-lg-3 Container-Maincomponent'>
                  <span title={item.systemClassClassification.map(each => each.systemMainComponentName).join(", ")}>
                    {
                    item.systemClassClassification.map(each => each.systemMainComponentName).join(", ")
                    }
                  </span>
                </p>
                <Button
                  bgFill={false}
                  iconName={
                    isEditMode && item.systemClassificationName === selectedSystemClassification ? 'cross' : 'edit'
                  }
                  value={
                    isEditMode && item.systemClassificationName === selectedSystemClassification
                      ? strings.cancel
                      : strings.edit
                  }
                  onClick={() => {
                    {
                      let data = [];
                      item.systemClassClassification.map(each => {
                        data.push(each);
                      });
                      onEdit(item.systemClassificationName, isEditMode, data);
                    }
                  }}
                  styleClass='col-lg-1 mb-3 ml-3 h-2'
                />
              </div>
            )}
          </>
        ))}
      {copySystemClassificationList.length > 0 &&
        electronMicroscopesList.length > 0 &&
        electronMicroscopesList.map((item, index) => (
          <>
             {isEditMode && item.systemClassificationName === selectedSystemClassification ? (
              <div className='row'>
                <p className='col-lg-2 ml-4'>{item.systemClassificationName}</p>
                <Dropdown
                data={latestData}
                hasSection
                multiSelect
                filter
                field={{ item: `${item.systemClassificationName}`, key: index }}
                idKey='systemMainComponentId'
                displayValue={[{ key: 'systemMainComponentName', separator: '' }]}
                optionsArrayKey='systemMainComponent'
                titleDisplay={[{ key: 'systemClassName', separator: '' }]}
                isRequired={true}
                placeholder={strings.pleaseSelect + '...'}
                onChange={handleFilterChange}
                mainContainerStyle='col-lg-3 mr-0 p-0'
                value={filter[`${item.systemClassificationName}`]}
                  fullValue={filter[`${item.systemClassificationName}FullValue`]}
              />
                <Button
                  dataTest="electronMicroscopesListEdit"
                  bgFill={false}
                  iconName={
                    isEditMode && item.systemClassificationName === selectedSystemClassification ? 'cross' : 'edit'
                  }
                  value={isEditMode ? strings.cancel : strings.edit}
                  onClick={() => {
                    {
                      let data = [];
                      item.systemClassClassification.map(each => <span className='w-20'>{data.push(each)}</span>);
                      onEdit(item.systemClassificationName, isEditMode, data);
                    }
                  }}
                  styleClass='col-lg-1 mb-3 mr-3 ml-3'
                />
                <Button
                  dataTest="electronMicroscopesListSave"
                  bgFill={false}
                  iconName='save'
                  value={strings.save}
                  onClick={() =>
                    onSave(
                      filter[`${item.systemClassificationName}FullValue`],
                      item.systemClassificationId,
                      checkedValues,
                      item.systemClassificationName
                    )
                  }
                  styleClass='col-lg-1 mb-3'
                />
              </div>
            ) : (
              <div className='row'>
                <p className='col-lg-2 ml-4 mb-3'>{item.systemClassificationName}</p>
                <p className='col-lg-3 Container-Maincomponent'>
                  <span title={item.systemClassClassification.map(each => each.systemMainComponentName).join(", ")}>
                    {
                    item.systemClassClassification.map(each => each.systemMainComponentName).join(", ")
                    }
                  </span>
                </p>
                <Button
                  bgFill={false}
                  iconName={
                    isEditMode && item.systemClassificationName === selectedSystemClassification ? 'cross' : 'edit'
                  }
                  value={
                    isEditMode && item.systemClassificationName === selectedSystemClassification
                      ? strings.cancel
                      : strings.edit
                  }
                  onClick={() => {
                    {
                      let data = [];
                      item.systemClassClassification.map(each => {
                        data.push(each);
                      });
                      onEdit(item.systemClassificationName, isEditMode, data);
                    }
                  }}
                  styleClass='col-lg-1 mb-3 ml-3 h-2'
                />
              </div>
            )}
          </>
        ))}
    </div>
  );
};

export default SystemClassificationBlock;
