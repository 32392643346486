import { saveAs } from 'file-saver';
import axios from 'axios';
import { batchActions } from 'redux-batched-actions';

import strings from 'resources/locales/Translate';
import { logNetworkError } from 'library/utilities/logError';
import { fetchFromStorage } from 'library/utilities/storage';
import {
  fetchMasterDataService,
  fetchMasterDataServicePut,
  fetchMasterDataServicePost,
  deleteMasterDataService,
} from 'library/api/master-data.service';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import * as actionTypes from 'library/common/commonConstants/storeConstants';
import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';
import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { scrollToTop } from 'library/utilities/scrollActions';

import { exportListRequest } from './Shop.service';

const INITIAL_PAGE = 0;
const SIZE = 20;

export const handlingSendData = data => {
  const {
    filter: { country, category, singleItems, searchTerm },
  } = data;
  const filter = [];
  const sort = [];
  const {
    sort: {
      kitName,
      systemClass,
      systemMainComponent,
      condition,
      localSalesPrice,
      transferPrice,
      salesDate,
      deliveryTimes,
      stock,
    },
  } = data;
  const sendData = { ...data };

  filter.push(`kitType:eq:${getSingleItemsType(singleItems)}`);
  if (country) {
    if (filter.length) filter.push('and');
    filter.push(`country:eq:${country}`);
  }
  if (category && category.length) {
    let categoryFilter = [];
    if (filter.length) filter.push('and');

    category.forEach((item, index) => {
      if (index !== 0) categoryFilter.push('or');
      categoryFilter.push(`systemMainComponent.id:eq:${item}`);
    });

    filter.push(categoryFilter);
  }

  if (searchTerm) {
    if (filter.length) filter.push('and');
    let searchArray = [];
    searchArray.push(`id:eqas:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`kitName:cic:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`ibase:cic:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`systemMainComponent.systemMainComponentName:eq:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`localSalePrice:eq:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`transferPrice:eq:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`saleDate:cic:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`deliveryTimes:eq:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`stock.name:cic:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`dateOfManufacture:cic:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`kitCondition.translation:cic:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`systemClass.name:cic:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`serialNumber:cic:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`kitNumber:cic:${searchTerm}`);

    filter.push(searchArray);
  }

  if (kitName) {
    sort.push('kitName');
    sort.push(`${kitName}`);
  }
  if (systemClass) {
    sort.push('systemClassId');
    sort.push(`${systemClass}`);
  }
  if (systemMainComponent) {
    sort.push('systemMainComponentId');
    sort.push(`${systemMainComponent}`);
  }
  if (condition) {
    sort.push('kitCondition.kitConditionId');
    sort.push(`${condition}`);
  }
  if (localSalesPrice) {
    sort.push('sellOffPlatformInformation.localSalePrice');
    sort.push(`${localSalesPrice}`);
  }
  if (transferPrice) {
    sort.push('sellOffPlatformInformation.transferPrice');
    sort.push(`${transferPrice}`);
  }
  if (salesDate) {
    sort.push('sellOffPlatformInformation.saleDate');
    sort.push(`${salesDate}`);
  }
  if (deliveryTimes) {
    sort.push('sellOffPlatformInformation.deliveryTimeInWeeks');
    sort.push(`${deliveryTimes}`);
  }
  if (stock) {
    sort.push('stockInformation.id');
    sort.push(`${stock}`);
  }

  sendData.filter = filter;
  sendData.sort = sort;
  sendData.graphql = `kitNumber,singleItemParent,kitInformationId,isObserve,kitSingleItem,serialNumber,dateOfManufacture,ibase,hideKit,systemMainComponent[systemMainComponentName],kitStatuses[kitStatusId,kitStatusesTranslated[translatedStatus]],kitCondition[kitConditionId,kitConditionTranslated[translatedCondition]],kitName,dateCreated,systemClass[systemClassName],stockInformation[id,locationName,country[name,shortName],userRole[accountId,role[role]]],currentPosition,comment,kitReservation[reservationFrom,reservationTo,crmQuoteId,createdBy[firstName,lastName,id,accountId],createdOn],sellOffPlatformInformation[currency[name,symbol],localSalePrice,transferPrice,saleDate,deliveryTimeInWeeks,sellOffCondition[id,sellOffKitConditionTranslated[name]]],marketPlaceKitViewCountForList,observationCountForList`;
  return sendData;
};

export const fetchShopListData = data => async dispatch => {
  const sendData = handlingSendData(data);

  dispatch(changeLoader(true));

  try {
    const res = await fetchMasterDataServicePost(URLS.fetchShop, sendData);
    if (res.status === 200) {
      dispatch({
        type: actionTypes.FETCH_SHOP_LIST,
        payload: res.data,
      });
    }
    dispatch(changeLoader(false));
  } catch(err) {
    dispatch(changeLoader(false));
    if (err.response && err.response.status === 404) {
      dispatch({
        type: actionTypes.FETCH_SHOP_LIST,
        payload: [],
      });
    }
  }
};

export const fetchObservationsListData = data => async dispatch => {
  const sendData = handlingSendData(data);

  dispatch(changeLoader(true));

  try {
    const res = await fetchMasterDataServicePost(URLS.observationList, sendData);
    if (res.status === 200) {
      dispatch({
        type: actionTypes.FETCH_OBSERVATION_LIST,
        payload: res.data,
      });
    }
    dispatch(changeLoader(false));
  } catch(err) {
    dispatch(changeLoader(false));
    if (err.response && err.response.status === 404) {
      dispatch({
        type: actionTypes.FETCH_OBSERVATION_LIST,
        payload: [],
      });
    }
  }
};

const getSingleItemsType = id => {
  switch(id) {
    case 1:
      return 'demoKits';
    case 2:
      return 'singleItem';
    default:
      return 'all';
  }
};

export const exportShopList = ({
  stock: stockIds,
  category: systemClassIds,
  singleItems: type,
  country: countryIds,
}) => async () => {
  const valuesToSend = { stockIds: stockIds || null, systemClassIds, type: getSingleItemsType(type), countryIds: countryIds || null };
  const token = fetchFromStorage(identifiers.token);

  try {
    const response = await exportListRequest(URLS.kitSellOffListExport, valuesToSend, token);
    if (response.data) {
      const newFile = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(newFile, 'Shop list');
      return { success: true };
    }
  } catch (err) {
    logNetworkError(err);
    return { success: false };
  }
};

export const createSubscription = valuesToSend => async dispatch => {
  Object.keys(valuesToSend).forEach(key => {
    if (key.includes('Error') || key.includes('FullValue')) {
      delete valuesToSend[key];
    }
  });

  try {
    const res = await fetchMasterDataServicePost(URLS.subscribe, valuesToSend);
    if (res.status === 200) {
      await dispatch(fetchMasterData(URLS.subscriptions, actionTypes.FETCH_SHOP_SUBSCRIPTIONS));
      return { success: true };
    }
  } catch (err) {
    if (err.response && err.response.status === 422) {
      return { success: false, error: strings.youCanNotHaveMore };
    }
    return { success: false, error: strings.somethingWentWrongMessage };
  }
};

export const removeSubscription = id => async dispatch => {
  try {
    const res = await deleteMasterDataService(URLS.unsubscribe(id));
    if (res.status === 200) {
      await dispatch(fetchMasterData(URLS.subscriptions, actionTypes.FETCH_SHOP_SUBSCRIPTIONS));
      return { success: true };
    }
  } catch (err) {
    return { success: false };
  }
};

export const addKitToObservationList = kitId => async () => {
  try {
    const res = await fetchMasterDataServicePost(URLS.observe, {kitIds: [kitId]});
    if (res.status === 200) {
      return { success: true };
    }
  } catch (err) {
    return { success: false };
  }
};

export const removeKitFromObservationList = kitId => async () => {
  try {
    const res = await deleteMasterDataService(`${URLS.deleteObservation}/${kitId}`, {kitIds: [kitId]});
    if (res.status === 200) {
      return { success: true };
    }
  } catch (err) {
    return { success: false };
  }
};

export const exportObservationsList = ({
  stock: stockIds,
  category: systemClassIds,
  singleItems: type,
}) => async () => {
  const valuesToSend = { stockIds, systemClassIds, type: getSingleItemsType(type) };
  const token = fetchFromStorage(identifiers.token);
  try {
    const response = await exportListRequest(URLS.observationsListExport, valuesToSend, token);
    if (response.data) {
      const newFile = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(newFile, 'Observations list');
      return { success: true };
    }
  } catch (err) {
    logNetworkError(err);
    return { success: false };
  }
};

export const fetchArchiveListData = data => async dispatch => {
  const sendData = handlingSendData(data);

  dispatch(changeLoader(true));

  try {
    const res = await fetchMasterDataServicePost(URLS.archive, sendData);
    if (res.status === 200) {
      dispatch({
        type: actionTypes.FETCH_ARCHIVE_LIST,
        payload: res.data,
      });
    }
    dispatch(changeLoader(false));
  } catch(err) {
    dispatch(changeLoader(false));
    if (err.response && err.response.status === 404) {
      dispatch({
        type: actionTypes.FETCH_ARCHIVE_LIST,
        payload: [],
      });
    }
  }
};

export const exportArchiveList = ({
  stock: stockIds,
  category: systemClassIds,
  singleItems: type,
  country: countryIds,
}) => async () => {
  const valuesToSend = { stockIds: stockIds || [], countryIds: countryIds || null, systemClassIds, type: getSingleItemsType(type) };
  const token = fetchFromStorage(identifiers.token);

  try {
    const response = await exportListRequest(URLS.archiveListExport, valuesToSend, token);

    if (response.data) {
      const newFile = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(newFile, 'Archive list');
      return { success: true };
    }
  } catch (err) {
    logNetworkError(err);
    return { success: false };
  }
};

export const fetchReservationListData = data => async dispatch => {
  const sendData = handlingSendData(data);

  dispatch(changeLoader(true));

  try {
    const res = await fetchMasterDataServicePost(URLS.reserved, sendData);
    if (res.status === 200) {
      dispatch({
        type: actionTypes.FETCH_RESERVATION_LIST,
        payload: res.data,
      });
    }
    dispatch(changeLoader(false));
  } catch(err) {
    dispatch(changeLoader(false));
    if (err.response && err.response.status === 404) {
      dispatch({
        type: actionTypes.FETCH_RESERVATION_LIST,
        payload: [],
      });
    }
  }
};

export const reserveKit = id => async () => {
  try {
    const response = await fetchMasterDataServicePut(URLS.sellOffReserve(id));

    if (response.status === 204) {
      return { success: true };
    }
  } catch (err) {
    logNetworkError(err);
    return { success: false };
  }
};

export const cancelKitReservation = id => async () => {
  try {
    const response = await fetchMasterDataServicePut(URLS.cancelReservation(id));

    if (response.status === 204) {
      return { success: true };
    }
  } catch (err) {
    logNetworkError(err);
    return { success: false };
  }
};

export const orderKit = (id, crmQuoteId) => async dispatch => {
  try {
    const response = await fetchMasterDataServicePut(URLS.order(id, crmQuoteId));

    if (response.status === 204) {
      scrollToTop(500);
      dispatch(toggleActionMessage(true, 'success', 'kitOrderedSuccessfully'));
      return { success: true };
    }
  } catch (err) {
    logNetworkError(err);
    return { success: false };
  }
};

export const resetStatistics = () => dispatch => {
  dispatch({
    type: actionTypes.SET_STATISTICS,
    payload: [],
  });
};

export const getStatistics = data => async (dispatch, getState) => {
  const { controller } = getState().shopListReducer;
  if (controller) {
    controller.cancel();
  }

  const newController = axios.CancelToken.source();
  dispatch({
    type: actionTypes.SET_CONTROLLER,
    payload: newController,
  })

  try {
    const response = await fetchMasterDataServicePost(URLS.sellOffStatistics, data, { cancelToken: newController.token });

    if (response.status === 200) {
      dispatch({
        type: actionTypes.SET_STATISTICS,
        payload: response.data,
      });
      return { success: true };
    }
  } catch (err) {
    if (axios.isCancel(err)) {
      return { success: false };
    } else {
      logNetworkError(err);
      return { success: false };
    }
  }
};

export const getInitialPurchaseRequestsAndPopupData = () => async dispatch => {
  dispatch(changeLoader(true));
  await dispatch(getPurchaseRequests());
  await dispatch(getDataForPurchaseRequestsPopup());
  dispatch(changeLoader(false));
};

export const getCountryList = () => async dispatch => {
  await dispatch(fetchMasterData(URLS.countryList, actionTypes.FETCH_COUNTRY_LIST));
};

export const getDataForPurchaseRequestsPopup = () => async dispatch => {
  const businessUnitRMSId = 1;
  try {
    await dispatch(fetchMasterData(URLS.sellOffCurrency, actionTypes.FETCH_SELL_OFF_CURRENCY));
    await dispatch(fetchMasterData(URLS.systemClass.replace('{id}', businessUnitRMSId), actionTypes.FETCH_RMS_SYSTEM_CLASS));
  } catch (err) {
    scrollToTop(500);
    dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    logNetworkError(err);
  }
};

export const fetchSystemMainComponentData = systemClassId => async () => {
  try {
    const { data, status } = await fetchMasterDataService(URLS.systemMainComponent.replace('{id}', systemClassId));
    if (status === 200) {
      return data;
    }
  } catch (err) {
    logNetworkError(err);
    return [];
  }
};

export const updatePurchaseAfterChanges = messages => async dispatch => {
  try {
    dispatch(changeLoader(true));
    await dispatch(replacePurchaseRequests());
    dispatch(
      batchActions([
        dispatch(changeLoader(false)),
        dispatch(toggleActionMessage(true, 'success', messages)),
      ]),
    );
    scrollToTop(500);
  } catch (err) {
    dispatch(
      batchActions([
        dispatch(changeLoader(false)),
        dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage')),
      ]),
    );
    scrollToTop(500);
  }
};

export const replacePurchaseRequests = () => async dispatch => {
  try {
    const response = await fetchMasterDataService(URLS.getPurchaseRequests(INITIAL_PAGE, SIZE));

    dispatch({
      type: actionTypes.UPDATE_PURCHASE_REQUESTS,
      payload: response.data,
    });

    return { success: true };
  } catch (err) {
    scrollToTop(500);
    dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    logNetworkError(err);
    return { success: false };
  }
};

export const resetPurchaseRequests = () => dispatch => {
  dispatch({
    type: actionTypes.UPDATE_PURCHASE_REQUESTS,
    payload: {
      purchases: [],
      totalItems: 0,
    },
  });
};

export const addPurchaseRequests = data => async dispatch => {
  Object.keys(data).forEach(key => {
    if (key.includes('Error') || key.includes('FullValue')) {
      delete data[key];
    }
  });

  data.numberOfMonths = data.validity;

  try {
    await fetchMasterDataServicePost(URLS.purchaseKit, data);
    dispatch(updatePurchaseAfterChanges('purchaseRequestsWasAdded'));
    return { success: true };
  } catch (err) {
    logNetworkError(err);
    return { success: false };
  }
};

export const getPurchaseRequests = (page = INITIAL_PAGE, size = SIZE) => async dispatch => {
  try {
    const response = await fetchMasterDataService(URLS.getPurchaseRequests(page, size));

    dispatch({
      type: actionTypes.GET_PURCHASE_REQUESTS,
      payload: response.data,
    });

    return { success: true };
  } catch (err) {
    scrollToTop(500);
    dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    logNetworkError(err);
    return { success: false };
  }
};

export const getPurchaseRequestById = id => async () => {
  try {
    const { data } = await fetchMasterDataService(URLS.getPurchaseRequestById(id));
    return data;
  } catch (err) {
    logNetworkError(err);
    return {};
  }
};

export const createComment = data => async (dispatch, getState) => {
  const { purchaseRequests, totalPurchaseRequestsItems } = getState().shopListReducer;
  try {
    await fetchMasterDataServicePost(URLS.purchaseComment, data);
    const cardInfo = await dispatch(getPurchaseRequestById(data.purchaseId));

    if (cardInfo) {
      const newPurchaseRequests = purchaseRequests.map(item => {
        if (cardInfo.purchaseId === item.purchaseId) {
          return cardInfo;
        }
        return item;
      });
      dispatch({
        type: actionTypes.UPDATE_PURCHASE_REQUESTS,
        payload: {
          purchases: newPurchaseRequests,
          totalItems: totalPurchaseRequestsItems,
        },
      });
      return { success: true, cardInfo };
    }
    return { success: false };
  } catch (err) {
    logNetworkError(err);
    return { success: false };
  }
};

export const updatePurchaseRequests = (data, purchaseId) => async dispatch => {
  Object.keys(data).forEach(key => {
    if (key.includes('Error') || key.includes('FullValue')) {
      delete data[key];
    }
  });

  data.id = purchaseId;
  data.numberOfMonths = data.validity;

  try {
    await fetchMasterDataServicePut(URLS.updatePurchaseRequest, data);
    dispatch(updatePurchaseAfterChanges('purchaseRequestWasUpdated'));
    return { success: true };
  } catch (err) {
    logNetworkError(err);
    return { success: false };
  }
};

export const deletePurchaseRequests = purchaseId => async dispatch => {
  try {
    await deleteMasterDataService(URLS.deletePurchaseRequest(purchaseId));
    dispatch(updatePurchaseAfterChanges('purchaseRequestWasDeleted'));
    return { success: true };
  } catch (err) {
    logNetworkError(err);
    return { success: false };
  }
};

export const fetchOrderListData = data => async dispatch => {
  const sendData = handlingSendData(data);

  dispatch(changeLoader(true));

  try {
    const res = await fetchMasterDataServicePost(URLS.orderList, sendData);

    if (res.status === 200) {
      dispatch({
        type: actionTypes.FETCH_ORDER_LIST,
        payload: res.data,
      });
    }
    dispatch(changeLoader(false));
  } catch(err) {
    dispatch(changeLoader(false));
    if (err.response && err.response.status === 404) {
      dispatch({
        type: actionTypes.FETCH_ORDER_LIST,
        payload: [],
      });
    }
  }
};

export const exportOrderList = ({
  stock: stockIds,
  category: systemClassIds,
  singleItems: type,
}) => async () => {
  const valuesToSend = { stockIds, systemClassIds, type: getSingleItemsType(type) };
  const token = fetchFromStorage(identifiers.token);
  try {
    const response = await exportListRequest(URLS.orderListExport, valuesToSend, token);
    if (response.data) {
      const newFile = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(newFile, 'Order list');
      return { success: true };
    }
  } catch (err) {
    logNetworkError(err);
    return { success: false };
  }
};


export const fetchMarketplaceCountryList = () => async dispatch =>{
    try {
      const { data } = await fetchMasterDataService(URLS.marketPlaceCountry);
      dispatch({
        type: actionTypes.MARKETPLACE_COUNTRY_LIST,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: actionTypes.MARKETPLACE_COUNTRY_LIST,
        payload: [],
      });
    }
}

