import Validators from "library/utilities/Validators";

export const deliveryTimesModel = [
  {
    label: 'targetCountry',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'countryId',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [
      {key: 'name', separator: ''}
    ],
    excludeData: []
  },
  {
    label: 'delivery',
    value: '',
    type: 'inputgroup',
    placeholder: '',
    field: 'deliveryDays',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'},
      {check: Validators.number, message: 'invalidNumberError'}],
    required: true,
    styleClass: 'col-sm-3',
    inputGroupText: 'days',
    isAppend: true
  },
  {
    label: 'returnDelivery',
    value: '',
    type: 'inputgroup',
    placeholder: '',
    field: 'returnDeliveryDays',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'},
      {check: Validators.number, message: 'invalidNumberError'}],
    required: true,
    styleClass: 'col-sm-3',
    inputGroupText: 'days',
    isAppend: true
  },
  {
    label: 'returnDeliveryAddress',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'returnDeliveryAddress',
    required: false,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [
      {key: 'name', separator: ''}
    ],
    excludeData: []
  }
];

export const deliveryTimesAlreadyAddedModel = [
  {
    label: 'targetCountry',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'countryId',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [
      {key: 'name', separator: ''}
    ],
    disabled: true
  },
  {
    label: 'delivery',
    value: '',
    type: 'inputgroup',
    placeholder: '',
    field: 'deliveryDays',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'},
      {check: Validators.number, message: 'invalidNumberError'}],
    required: true,
    styleClass: 'col-sm-3',
    inputGroupText: 'days',
    isAppend: true
  },
  {
    label: 'returnDelivery',
    value: '',
    type: 'inputgroup',
    placeholder: '',
    field: 'returnDeliveryDays',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'},
      {check: Validators.number, message: 'invalidNumberError'}],
    required: true,
    styleClass: 'col-sm-3',
    inputGroupText: 'days',
    isAppend: true
  },
  {
    label: 'returnDeliveryAddress',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'returnDeliveryAddress',
    required: false,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [
      {key: 'name', separator: ''}
    ],
    excludeData: [],
    disabled: false
  }
];


export const provinceDeliveryTimesModel = [
  {
    label: 'targetProvince',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'stateId',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-sm-4',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'stateId',
    displayValue: [
      {key: 'stateName', separator: ''}
    ],
    excludeData: []
  },
  {
    label: 'delivery',
    value: '',
    type: 'inputgroup',
    placeholder: '',
    field: 'deliveryDays',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'},
      {check: Validators.number, message: 'invalidNumberError'}],
    required: true,
    styleClass: 'col-sm-4',
    inputGroupText: 'days',
    isAppend: true
  },
  {
    label: 'returnDelivery',
    value: '',
    type: 'inputgroup',
    placeholder: '',
    field: 'returnDeliveryDays',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'},
      {check: Validators.number, message: 'invalidNumberError'}],
    required: true,
    styleClass: 'col-sm-4',
    inputGroupText: 'days',
    isAppend: true
  }
];


export const deliveryTimesAlreadyAddedProvincesModel = [
  {
    label: 'targetProvince',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'stateId',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-sm-4',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'stateId',
    displayValue: [
      {key: 'stateName', separator: ''}
    ],
    disabled: true
  },
  {
    label: 'delivery',
    value: '',
    type: 'inputgroup',
    placeholder: '',
    field: 'deliveryDays',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'},
      {check: Validators.number, message: 'invalidNumberError'}],
    required: true,
    styleClass: 'col-sm-4',
    inputGroupText: 'days',
    isAppend: true
  },
  {
    label: 'returnDelivery',
    value: '',
    type: 'inputgroup',
    placeholder: '',
    field: 'returnDeliveryDays',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'},
      {check: Validators.number, message: 'invalidNumberError'}],
    required: true,
    styleClass: 'col-sm-4',
    inputGroupText: 'days',
    isAppend: true
  }
];