import React from 'react';
import strings from 'resources/locales/Translate';

export const ZmccTimeManagement = () => {
  return (
    <div className='d-flex justify-content-center mt-4'>
      <strong>{strings.noDataFoundText}</strong>
    </div>
  );
};

export default ZmccTimeManagement;
