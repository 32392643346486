import { useEffect, useCallback } from 'react';
import get from 'lodash/get';

import { refurbishmentPeriodModel } from '../../../CreateKit/Tabs/Information/Information.constants';
import {
  assignmentModel,
  availabilityModel,
  informationFormModel,
  kitConditionFormModel,
  mainComponentModel,
} from './InformationTab.constants';
export const useInformationTab = props => {
  const {
    informationForm,
    kitStatusForm,
    stocks,
    systemClasses,
    systemMainComponent,
    fetchSystemMainComponent,
    conditions,
    activeUntil,
    kitDetails,
    fetchActiveUnitDate,
  } = props;

  useEffect(() => {
    if (informationForm.formData.systemClass) {
      fetchSystemMainComponent(informationForm.formData.systemClass);
    }
  }, [informationForm.formData.systemClass, fetchSystemMainComponent]);

  useEffect(() => {
    if (kitDetails && kitDetails.stockInformation) {
      fetchActiveUnitDate(kitDetails && kitDetails.stockInformation.id);
    }
  }, [kitDetails && kitDetails.kitInformationId]);

  const getKitConditionsModel = useCallback(() => {
    const model = [...kitConditionFormModel];

    if (conditions.length) {
      const unusedСonditionIds = [6, 10, 11, 12, 1, 3, 4, 7, 8, 9, 13, 14];
      const newOptions = conditions.filter(condition => !unusedСonditionIds.includes(condition.kitStatusId));
      const okCondition = conditions.find(condition => condition.kitStatusId === 6);

      newOptions.unshift(okCondition);
      model[0].options = newOptions;
    }

    if (get(kitStatusForm, 'formData.kitCondition') === 5) {
      model.splice(1, 0, ...refurbishmentPeriodModel);
      model[1].minDate = new Date();
      model[0].styleClass = "col-md-4 col-sm-6";
      model[1].styleClass = "col-md-4 col-sm-6";
      model[2].styleClass = "col-md-4 col-sm-6";
      model[2].minDate = kitStatusForm.formData.refurbishmentFrom;
      model[3].styleClass = "col-md-6 col-sm-6";
      model[4].styleClass = "col-md-6 col-sm-6";
    }else{
      model[0].styleClass = "col-sm-3"
      model[1].styleClass = "col-sm-9"
    }

    return model;
  }, [conditions, kitStatusForm]);

  const getAssignmentModel = useCallback(() => {
    const model = [...assignmentModel];
    model[0].options = stocks;

    return model;
  }, [stocks]);

  const getMainComponentModel = useCallback(() => {
    const model = [...mainComponentModel];
    model[0].options = systemClasses;
    model[1].options = systemMainComponent;

    return model;
  }, [systemClasses, systemMainComponent]);

  const getAvailabilityModel = useCallback(() => {
    let model = [...availabilityModel];
    const date = activeUntil ? new Date(activeUntil) : null;
    model[1].maxDate = date;
    model[1].minDate = informationForm.formData.availabilityFrom;
    model[0].maxDate = informationForm.formData.availabilityTo || date;

    return model;
  }, [informationForm, activeUntil]);

  const kitConditionModel = getKitConditionsModel();
  const assignmentsModel = getAssignmentModel();
  const mainComponentsModel = getMainComponentModel();
  const availabilityModelForm = getAvailabilityModel();

  return {
    kitConditionModel,
    getKitConditionsModel,
    assignmentsModel,
    mainComponentsModel,
    getAssignmentModel,
    getMainComponentModel,
    availabilityModelForm,
  };
};
