export const FETCH_TRANSACTIONS_LIST = '@@BOOKING/FETCH_TRANSACTIONS_LIST';
export const FETCH_LABS_LOCATION_TRANSACTIONS_LIST = '@@BOOKING/FETCH_LABS_LOCATION_TRANSACTIONS_LIST';
export const FETCH_TRANSACTION_STATUSES = '@@BOOKING/FETCH_TRANSACTION_STATUSES';
export const FETCH_TRANSACTION_POSITIONS = '@@BOOKING/FETCH_TRANSACTION_POSITIONS';
export const SET_DELAYED_TRANSACTIONS = '@@BOOKING/SET_DELAYED_TRANSACTIONS';
export const SET_CONDITIONS_BOOKING_LIST_FILTER = '@@BOOKING/SET_CONDITIONS_BOOKING_LIST_FILTER';
export const FETCH_TEAM_MEMBERS = '@@BOOKING/FETCH_TEAM_MEMBERS';
export const FETCH_LABS_LOCATION_TRANSACTION_STATUSES = '@@BOOKING/FETCH_LABS_LOCATION_TRANSACTION_STATUSES';
export const FETCH_LABS_LOCATION_COUNTRIES = '@@BOOKING/FETCH_LABS_LOCATION_COUNTRIES';
export const FETCH_LABS_LOCATION_SYSTEM_CLASSES = '@@BOOKING/FETCH_LABS_LOCATION_SYSTEM_CLASSES';
export const FETCH_ROLES_LIST = 'FETCH_ROLES_LIST';
export const FETCH_ZMCC_TRANSACTION_LIST = 'FETCH_ZMCC_TRANSACTION_LIST';
export const FETCH_ZMCC_TRANSACTION_STATUSES = 'FETCH_ZMCC_TRANSACTION_STATUSES';
export const FETCH_ZMCC_TRANSACTION_CATEGORY = 'FETCH_ZMCC_TRANSACTION_CATEGORY';
export const FETCH_ZMCC_TRANSACTION_OPERATORS = 'FETCH_ZMCC_TRANSACTION_OPERATORS';
export const FETCH_SAFE_SEARCH_LIST = 'FETCH_SAFE_SEARCH_LIST';
export const FETCH_SAVED_FILTERS = 'FETCH_SAVED_FILTERS';

export const itemsPerPage = {
  10: { id: 10, pages: 10 },
  20: { id: 20, pages: 20 },
  50: { id: 50, pages: 50 },
};

export const typeOfLLBookings = [
  'myBookings',
  'teamBookings',
  'openProcesses',
  'loanRequests',
  'cancellationApproval',
  'cancelledProcesses',
  'archiveProcesses',
];

export const INITIAL_SORT = {
  name: null,
  serialNumber: null,
  delivery: null,
  begin: null,
  end: null,
  returnDelivery: null,
  country: null,
  stock: null,
  borrower: null,
  createdAt: 'desc',
};

export const INITIAL_FILTER = {
  loanType: null,
  loanTypeFullValue: null,
  stock: null,
  stockFullValue: null,
  systemClass: null,
  systemClassFullValue: null,
  currentPosition: null,
  currentPositionFullValue: null,
  transactionStatuses: null,
  transactionStatusesFullValue: null,
  condition: null,
  conditionFullValue: null,
  dateRange: null,
  dateRangeFullValue: null,
  dateType: null,
  dateTypeFullValue: null,
  searchTerm: null,
};

export const INITIAL_LABS_LOCATION_SORT = {
  name: null,
  serialNumber: null,
  begin: null,
  end: null,
  country: null,
  stock: null,
  customer: null,
  createdAt: 'desc',
};

export const INITIAL_LABS_LOCATION_FILTER = {
  stock: null,
  stockFullValue: null,
  systemClass: null,
  systemClassFullValue: null,
  transactionStatuses: null,
  transactionStatusesFullValue: null,
  searchTerm: null,
};

export const typeZmccBookings = [
  'myBookings',
  'openProcesses',
  'loanRequests',
  'archiveProcesses',
  'cancellationApproval',
  'cancelledProcesses',
];

export const typeOfNonLLNonZmccBookings = [
  'outgoingGoods',
  'sapOrderCreation',
  'listReceiving',
  'incomingGoods',
  'goodReceipt',
  'listReconditioning',
]

export const INITIAL_ZMCC_FILTER = {
  stock: null,
  stockFullValue: null,
  transactionStatuses: null,
  transactionStatusesFullValue: null,
  searchTerm: null,
  dateRange: null,
  dateRangeFullValue: null,
  dateType: null,
  dateTypeFullValue: null,
  category: null,
  categoryFullValue: null,
  operator: null,
  operatorFullValue: null,
  requestType: null,
  requestTypeFullValue: null,
  systemCategoriesId: null,
  systemCategoriesIdFullValue: null,
};


export const queryResultBreadcrumbData = [
  {
    name: 'myQueries',
    url: '/my-queries',
  },
  {
    name: 'queryResult',
  },
];