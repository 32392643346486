import React, {useState} from 'react';
import Modal from 'library/common/commonComponents/Modal';
import strings from 'resources/locales/Translate';
import * as $ from 'jquery';

const CreateLoanOrderDetailsPopup = ({ id, workFlowQuestions, createSapErpLoanOrderForKit, kitDetail }) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    orderType,
    distributionChannel,
    customerIdSap,
    goodsRecipientIdSap,
    billing,
    partner,
    externalWarehouseText,
    shippingInstruction,
    submissionData,
  } = workFlowQuestions;

  const sendrequest = async() => {
    setIsLoading(true);
    const kitId = kitDetail && kitDetail.kitInformationId
    const res = await createSapErpLoanOrderForKit(kitId);
    if (res.status === 200) {
      setIsLoading(false)
      onCloseClick()
    }
  }

  const onCloseClick =() => {
    $(`#${id}`).modal('hide');
  }
  return (
    <Modal
      id={id}
      title={strings.createLoanOrder}
      confirmAction={sendrequest}
      confirmTitle={strings.resendRequest}
      titleOfCancel={strings.cancel}
      disableBackgroundClose
      onCloseClick={onCloseClick}
      className='create-new-loanorder'
      loading={isLoading}
    >
      <div className='col-md-12'>
        <div className='row'>
          <div className='col-md-6 mt-2 font-bold'>{strings.orderType}:</div>
          <div className='col-md-6 mt-2'>{orderType ? orderType.name : ''}</div>
          <div className='col-md-6 mt-2 font-bold'>{strings.distributionChannel}:</div>
          <div className='col-md-6 mt-2'> {distributionChannel ? distributionChannel.name : ''}</div>
          <div className='col-md-6 mt-2 font-bold'>{strings.customerId}:</div>
          <div className='col-md-6 mt-2'>{customerIdSap}</div>
          <div className='col-md-6 mt-2 font-bold'>{strings.goodsRecipientId}:</div>
          <div className='col-md-6 mt-2'>{goodsRecipientIdSap}</div>
          <div className='col-md-6 mt-2 font-bold'>{strings.billing}:</div>
          <div className='col-md-6 mt-2'>{billing ? billing.name : ''}</div>
          <div className='col-md-6 mt-2 font-bold'>{strings.partner}:</div>
          <div className='col-md-6 mt-2'>{partner}</div>
          <div className='col-md-6 mt-2 font-bold'>{strings.formularHeaderText}:</div>
          <div className='col-md-6 mt-2'>{externalWarehouseText}</div>
          <div className='col-md-6 mt-2 font-bold'>{strings.shippingInstructions}:</div>
          <div className='col-md-6 mt-2'>{shippingInstruction}</div>
          <div className='col-md-6 mt-2 font-bold'>{strings.orderData}:</div>
          <div className='col-md-6 mt-2'>{submissionData}</div>
        </div>
      </div>
      <p className='mt-2'>{strings.sapMessage}</p>
      <p className='mt-2'>{strings.sapDetailsChangeMessage}</p>
    </Modal>
  );
};

export default CreateLoanOrderDetailsPopup;
