import React from 'react';

import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import strings from 'resources/locales/Translate';

const NotifyMeVia = ({
  notificationSettings,
  handleNotificationSettings,
}) => {
  return (
    <div className='mb-5'>
      <h6 className='notification-title mb-3'>{strings.notifyMeVia}</h6>
      <Checkbox
        label={strings.email}
        selected={notificationSettings.emailNotification}
        field='emailNotification'
        onChange={handleNotificationSettings}
      />
      <Checkbox
        label={strings.webApp}
        selected={notificationSettings.webNotification}
        field='webNotification'
        onChange={handleNotificationSettings}
      />
      <Checkbox
        label={strings.mobileApp}
        selected={notificationSettings.mobileNotification}
        field='mobileNotification'
        onChange={handleNotificationSettings}
      />
    </div>
  );
};

export default NotifyMeVia;
