export function formatBytes(bytes, decimals = 2) {
  if(typeof bytes !== 'string') {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  } else {
    return bytes;
  }
}

export function getFileType(type) {
  let fileType = '';

  if (type && type.includes('image')) {
    fileType = 'Image';
  } else if (type && type.includes('video')) {
    fileType = 'Video';
  } else if (type && type.includes('audio')) {
    fileType = 'Audio';
  } else if (type && type === 'application/pdf') {
    fileType = 'PDF File';
  } else {
    fileType = 'Document';
  }

  return fileType;
}

export function addNameKey(files) {
  let filesArray = [];

  files.forEach(file => {
    filesArray.push(Object.assign({}, file, {name: file.file}));
  });

  return filesArray;
}

export function updateKeysForSellOffAttachments(files) {
  let filesArray = [];

  files.forEach(file => {
    filesArray.push(Object.assign({}, file, {
      name: file.fileName,
      type: file.fileType,
      preview: file.link,
      kitAttachmentsId: file.sellOffAttachmentsId,
      file: file.fileName,
    }));
  });

  return filesArray;
}

export function updateKeysForSellOffImages(files) {
  let filesArray = [];

  files.forEach(file => {
    filesArray.push(Object.assign({}, file, {
      name: file.fileName,
      type: file.fileType,
      preview: file.link,
      kitImagesId: file.sellOffImageId,
      file: file.fileName,
    }));
  });

  return filesArray;
}