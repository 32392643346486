import { GET_DUSD_TASKS_LIST, GET_DUSD_POPUP_SYSTEM_CLASSES } from './DusdTask.constants';

const initialState = {
  tasksList: [],
  popUpSystemClassData: [],
};

const DusdTaskReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DUSD_TASKS_LIST:
      return {
        ...state,
        tasksList: action.payload
      };
      case GET_DUSD_POPUP_SYSTEM_CLASSES:
        return {
          ...state,
          popUpSystemClassData: action.payload
        }
    default:
      return state;
  }
};

export default DusdTaskReducer;
