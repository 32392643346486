import { connect } from 'react-redux';

import {
  getStocksByBussinessUnitandCountryIds,
  getSystemClassesByBU,
  getLoanReasonStatistic,
  clearStatistics,
  getDemosStatistic,
  getSatisfactionIndexStatistics,
  getDemosChartData,
} from '../../Statistics.actions';
import DemoActivityTab from './DemoActivityTab.component';

const mapStateToProps = ({ authReducer, languageReducer, statisticsReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
  stocksDemoActivity: statisticsReducer.stocksDemoActivity,
  systemClassDemoActivity: statisticsReducer.systemClassDemoActivity,
  loanReasonsStatistic: statisticsReducer.loanReasonsStatistic,
  demosStatistic: statisticsReducer.demosStatistic,
  satisfactionIndexStatistic: statisticsReducer.satisfactionIndexStatistic,
});

export default connect(mapStateToProps, {
  getStocksByBussinessUnitandCountryIds,
  getSystemClassesByBU,
  getLoanReasonStatistic,
  clearStatistics,
  getDemosStatistic,
  getSatisfactionIndexStatistics,
  getDemosChartData,
})(DemoActivityTab);
