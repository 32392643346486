import React, { PureComponent } from 'react';
import { formatBytes, getFileType } from 'library/utilities/fileHelperFunctions';
import Axios from 'axios';
import { saveAs } from 'file-saver';
import { fetchFromStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import Icon from '../Icon/Icon';
import './fileListStyles.scss';
import classNames from 'classnames';
import "../../../../modules/Kits/KitPictureUploader/kitPictureUploader.styles.scss"

export default class SingleFileListView extends PureComponent {
  downloadFile = () => {
    const { isDownloadable, downloadUrl, downloadIdField, downloadStream, file } = this.props;
    if (isDownloadable && file.hasOwnProperty(downloadIdField)) {
      let url = downloadUrl.replace('{id}', file[downloadIdField]);
      if (downloadStream) {
        this.downloadStream(url, file);
      } else {
        window.open(url, '_target');
      }
    }
  }

  downloadStream = (url, file) => {
    const token = fetchFromStorage(identifiers.token);
    Axios(url, {
      method: 'GET',
      responseType: 'blob',
      headers: {
        token: `${token}`,
      },
    })
      .then(response => {
        const newFile = new Blob([response.data], { type: file.type });
        saveAs(newFile, file.name);
      })
      .catch(error => {
        console.log(error);
      });
  };

  onRemove = e => {
    const { index, onRemove } = this.props;

    e.stopPropagation();
    onRemove(index)
  }

  render() {
    const { file, isUploadAllowed, isUploader, isNotRemoved, enhancedUi } = this.props;
    // Enhanced UI is a flag to not to show all info like size and type of image

    return (
      <div
        className={classNames('single-file-list-view d-flex justify-content-between align-items-center cursor-pointer', {
          "minimised-picture-info": enhancedUi,
        })}
        // onClick={this.downloadFile}
      >
        <div className='file-description d-flex align-items-center'>
          {!enhancedUi ? <Icon name='file' height={16} width={16} fill='#000000' className='file-icon' />: null}
          <p className={'file-name'} title={file.name}>{file.name}</p>
        </div>
        {!enhancedUi ? <div className='file-info d-flex align-items-center'>
          <p className='type'>{getFileType(file.type)}</p>
          <p className='size'>{formatBytes(file.size)}</p>
          <button className='delete-file-button d-flex align-items-center' onClick={this.downloadFile}>
              <Icon name='newVersionDownload' height={15} width={20} fill='#0088d0' viewBox={"0 0 16 16"}/>
              <p className={'file-description file-name'} style={{color:'#0088d0'}}>Download</p>
          </button>
          {((isUploadAllowed && !isNotRemoved) || isUploader) && (
            <button className='delete-file-button' onClick={this.onRemove}>
              <Icon name='delete' height={16} width={16} fill='#737678' />
            </button>
          )}
        </div>: null}
        {
          enhancedUi ? <div>
            <button className='delete-file-button' onClick={this.onRemove}>
                <Icon name='deleteOutline' height={14} width={14} viewBox='0 0 14 14' fill='#41464F' />
              </button>
          </div>: null
        }
      </div>
    );
  }
}
