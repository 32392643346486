import React, { useState, useCallback, useEffect } from 'react';
import $ from 'jquery';

import strings from 'resources/locales/Translate';
import { getDateInFormat } from 'library/utilities/getDateInFormat';
import ToggleSwitch from 'library/common/commonComponents/ToggleSwitch';

export const useActiveSystems = props => {
  const { activeSystemsList, fetchActiveSystemList, fetchInitialDate, activateOrDeactivateSystem, user } = props;
  const [state, setState] = useState({
    size: 10,
    page: 0,
  });
  const [isLoadingDeletion, setIsLoadingDeletion] = useState(false);
  const [isLoadingActivation, setIsLoadingActivation] = useState(null);
  const [deletionError, setDeletionError] = useState('');
  const [systemId, selectSystemId] = useState(null);
  const hasActiveSystemsList = activeSystemsList && activeSystemsList.content && activeSystemsList.content.length > 0;

  useEffect(() => {
    fetchInitialDate();
  }, [fetchInitialDate]);

  useEffect(() => {
    fetchActiveSystemList(state.page, state.size);
  }, [state.page, state.size, fetchActiveSystemList]);

  const isDustUser = user && user.globalRoles.includes('DUST_USER');

  const getTableCols = () => [
    {
      title: strings.systemClassText,
      name: 'systemClass',
      isSort: false,
      render: item => {
        return <span>{item.systemClassName}</span>;
      },
    },
    {
      title: strings.systemMainComponent,
      name: 'systemMainComponent',
      isSort: false,
      render: item => {
        return <span>{item.systemMainComponentName}</span>;
      },
    },
    {
      title: strings.createdBy,
      name: 'createdBy',
      isSort: false,
      render: item => {
        const { createdByFirstName, createdByLastName } = item;

        return (
          <span>
            {createdByFirstName} {createdByLastName}
          </span>
        );
      },
    },
    {
      title: strings.dateOfActivation,
      name: 'dateOfActivation',
      isSort: false,
      render: item => {
        const { activatedDate } = item;
        const date = activatedDate ? getDateInFormat('DD.MM.YYYY', activatedDate) : '';

        return <span>{date}</span>;
      },
    },
    {
      title: '',
      name: 'buttons',
      isSort: false,
      render: item => {
        const { activated, id } = item;

        return isDustUser ? (
          <div>
            <ToggleSwitch checked={activated} yes={strings.on} no={strings.off} />
          </div>
        ) : (
          <div onClick={activated ? () => openDeleteActiveSystemPopup(id) : () => openActivationSystemPopup(id)}>
            <ToggleSwitch checked={activated} yes={strings.on} no={strings.off} />
          </div>
        );
      },
    },
  ];

  const openDeleteActiveSystemPopup = useCallback(id => {
    selectSystemId(id);
    $('#confirm-delete-active-system-modal').modal('show');
  }, []);

  const openActivationSystemPopup = useCallback(id => {
    selectSystemId(id);
    $('#confirm-activate-system-modal').modal('show');
  }, []);

  const openAddActiveSystemPopup = useCallback(() => {
    $('#add-active-system-popup').modal('show');
  }, []);

  const hideDeleteActiveSystemPopup = useCallback(() => {
    selectSystemId(null);
    setDeletionError('');
    $('#confirm-delete-active-system-modal').modal('hide');
  }, []);

  const hideActivationSystemPopup = useCallback(() => {
    selectSystemId(null);
    setDeletionError('');
    $('#confirm-activate-system-modal').modal('hide');
  }, []);

  const confirmActiveSystemDeletion = useCallback(async () => {
    const activated = false;

    setIsLoadingDeletion(true);
    const { error } = await activateOrDeactivateSystem(systemId, activated, state.page, state.size);
    setIsLoadingDeletion(false);

    if (error) {
      setDeletionError(strings.somethingWentWrongMessage);
    }

    hideDeleteActiveSystemPopup();
  }, [systemId, state.page, state.size, activateOrDeactivateSystem, hideDeleteActiveSystemPopup]);

  const activateSystem = useCallback(async () => {
    const activated = true;

    setIsLoadingActivation(true);
    const { error } = await activateOrDeactivateSystem(systemId, activated, state.page, state.size);
    setIsLoadingActivation(false);

    if (error) {
      setDeletionError(strings.somethingWentWrongMessage);
    }

    hideActivationSystemPopup();
  }, [systemId, state.page, state.size, activateOrDeactivateSystem, hideActivationSystemPopup]);

  const handleItemsPerPage = useCallback(size => {
    setState(prevState => ({
      ...prevState,
      size,
    }));
  }, []);

  const handlePageChange = useCallback(page => {
    setState(prevState => ({
      ...prevState,
      page,
    }));
  }, []);

  const cols = getTableCols();

  return {
    state,
    hasActiveSystemsList,
    cols,
    deletionError,
    isLoadingDeletion,
    isLoadingActivation,
    getTableCols,
    openAddActiveSystemPopup,
    handleItemsPerPage,
    handlePageChange,
    confirmActiveSystemDeletion,
    hideDeleteActiveSystemPopup,
    openActivationSystemPopup,
    hideActivationSystemPopup,
    activateSystem,
    openDeleteActiveSystemPopup,
    isDustUser,
  };
}
