import React from 'react';

import Modal from 'library/common/commonComponents/Modal';
import strings from 'resources/locales/Translate';

import OpportunityItem from '../OpportunityItem';
import { useOpportunityListPopup } from './OpportunityListPopup.hook';

const OPPORTUNITY_LIST_ID_POPUP = 'opportunityListPopup';

const OpportunityListPopup = ({
  opportunityList,
  setOpportunities,
  updateShortDescription,
  updateCustomerForm,
  handleForm,
}) => {
  const { 
    hasMaxOpportunities,
    noOpportunities,
    selectedOpportunities,
    handleChange,
    onSaveClick,
    onCloseClick,
   } = useOpportunityListPopup({
    opportunityList,
    setOpportunities,
    updateShortDescription,
    updateCustomerForm,
    handleForm,
   });

  return (
    <Modal
      id={OPPORTUNITY_LIST_ID_POPUP}
      className='modal-lg'
      title={strings.opportunityList}
      confirmAction={onSaveClick}
      confirmTitle={strings.add}
      disableBackgroundClose
      onCloseClick={onCloseClick}
      disabledButton={noOpportunities}
    >
      {
        opportunityList.length > 0 ? opportunityList.map(opportunity => (
          <OpportunityItem
            opportunity={opportunity}
            hasMaxOpportunities={hasMaxOpportunities}
            handleChange={handleChange}
            selectedOpportunities={selectedOpportunities}
          />
        )) : (
          <div className='d-flex justify-content-center'>
            <strong>{strings.noDataFoundText}</strong>
          </div>
      )}
    </Modal>
  );
}

export default OpportunityListPopup;
