import React from 'react';
import * as $ from 'jquery';

import Button from 'library/common/commonComponents/Button/Button';
import Icon from 'library/common/commonComponents/Icon/Icon';
import strings from 'resources/locales/Translate';

import './multiSelectKitsPopupStyles.scss';

export default function multiSelectPopup({
  id,
  saveMultiSelectedKits,
  removeSelectedKits,
  onAddNewBooking,
  event,
}) {
	const closeModal = () => {
		$(`#${id}`).modal('hide');
  };
  
  const confirmAction = () => {
    saveMultiSelectedKits();
    removeSelectedKits();
    closeModal();
  };

  const rejectionAction = () => {
    onAddNewBooking(event);
    removeSelectedKits();
    closeModal();
  };

	return (
    <div
      className='modal fade'
      data-backdrop='static'
      data-keyboard='false'
      id={id}
      tabIndex='-1'
      role='dialog'
      aria-labelledby='multiSelectKitsPopup'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-md' role='document'>
        <div className='modal-content rounded-0 '>
          <div className='modal-body p-4'>
            <div className='content'>
              <button
                type='button'
                className='close modal-close position-absolute'
                data-dismiss='modal'
                aria-label='Close'
                onClick={closeModal}
              >
                <Icon name='cross' width={24} height={24} />
              </button>

              <div className='my-3'>
                <h5>{strings.doYouWantToBlockSamePeriodForMarkedKits}</h5>
              </div>
              <div className='row mt-5 col-md-offset-2'>
                <div className='col-md-4'>
                  <Button
                    styleClass='close-button'
                    bgFill={false}
                    value={strings.no}
                    onClick={rejectionAction}
                  />
                </div>
                <div className='col-md-8'>
                  <Button
                    styleClass='confirm-button'
                    iconName='save'
                    value={strings.yes}
                    onClick={confirmAction}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
	);
}
