import { useState } from 'react';
import strings from 'resources/locales/Translate';

export const useZmccTodoList = ({ formValues, updateSEMForm, user, zmccInformationTabData, updateZmccTransaction, formType, isClickedOnAdInTodoTab }) => {
  const [inputText, setInputText] = useState('');
  const [todosList, setTodosList] = useState([]);
  const [todoIdsToDelete, setTodoIdsToDelete] = useState([]);
  const [isCheckboxSelected, setIsCheckboxSelected] = useState(null);
  const { todosListForm, deleteTodoIds } = formValues;
  const [errorMsg, setErrorMsg] = useState('');

  const addIntoTodosList = value => {
    if(value === ''){
      setErrorMsg(strings.requiredErrorMessage);
      return;
    }
    setTodosList(
      todosListForm.formData.zmccTodosListDataList
        ? [
            ...todosListForm.formData.zmccTodosListDataList,
            {
              zmccTodosId: null,
              zmccTodosText: value,
              createdBy: user.accountId,
              checkedBy: null,
              isChecked: false,
            },
          ]
        : [
            {
              zmccTodosId: null,
              zmccTodosText: value,
              createdBy: user.accountId,
              checkedBy: null,
              isChecked: false,
            },
          ],
    );
    setInputText('');
    isClickedOnAdInTodoTab(true);
    handleNonFormComponent(
      todosListForm.formData.zmccTodosListDataList
        ? [
            ...todosListForm.formData.zmccTodosListDataList,
            {
              zmccTodosId: null,
              zmccTodosText: value,
              createdBy: user.accountId,
              checkedBy: null,
              isChecked: false,
            },
          ]
        : [
            {
              zmccTodosId: null,
              zmccTodosText: value,
              createdBy: user.accountId,
              checkedBy: null,
              isChecked: false,
            },
          ],
      'zmccTodosListDataList',
      'todosListForm',
    );
    updateZmccTransaction(formType);
  };

  const handleInputChange = (value, field) => {
    if (field === 'todoText') {
      handleNonFormComponent(value, 'todoText', 'todosListForm');
      setErrorMsg("");
      return setInputText(value);
    }
  };

  const removeATodoFromList = i => {
    var deleteTodoFromDB = todosListForm.formData.zmccTodosListDataList.find((item, index) => i == index);
    if (deleteTodoFromDB.zmccTodosId != null){
      var updatedDelete = (deleteTodoIds.formData &&  deleteTodoIds.formData.deleteTodoIds) ? [...deleteTodoIds.formData.deleteTodoIds,deleteTodoFromDB.zmccTodosId]: [deleteTodoFromDB.zmccTodosId];
      handleNonFormComponent(updatedDelete, 'deleteTodoIds', 'deleteTodoIds');
    }
    setTodosList([...todosListForm.formData.zmccTodosListDataList.filter((item, index) => i != index)]);
    handleNonFormComponent(
      [...todosListForm.formData.zmccTodosListDataList.filter((item, index) => i != index)],
      'zmccTodosListDataList',
      'todosListForm',
    );
    updateZmccTransaction(formType);
  };

  const handleNonFormComponent = (values, field, formName) => {
    updateSEMForm({
      [formName]: {
        ...formValues[formName],
        formData: {
          ...((formValues[formName] && formValues[formName].formData) || {}),
          [field]: values,
          [`${field}Error`]: null,
        },
      },
    });
  };

  const onChangeCheckbox = (value, item, index) => {
    let test = formValues.todosListForm.formData.zmccTodosListDataList.find((item, listIndex) => index === listIndex);
    let filterWithoutCheckone = formValues.todosListForm.formData.zmccTodosListDataList.filter(
      (item, listIndex) => index !== listIndex,
    );
    if (value === true) {
      test = {
        ...test,
        isChecked: value,
        checkedBy: user.accountId,
      };
    } else {
      test = {
        ...test,
        isChecked: value,
        checkedBy: null,
      };
    }
    filterWithoutCheckone.splice(index, 0, test);
    handleNonFormComponent(filterWithoutCheckone, 'zmccTodosListDataList', 'todosListForm');
    updateZmccTransaction(formType);
  };

  const onSaveClick = () => {
    updateZmccTransaction(formType);
  };

  return {
    handleInputChange,
    inputText,
    removeATodoFromList,
    addIntoTodosList,
    todosList,
    onChangeCheckbox,
    isCheckboxSelected,
    onSaveClick,
    errorMsg
  };
};


