import React from 'react';
import Modal from 'library/common/commonComponents/Modal';
import strings from 'resources/locales/Translate';
import Button from 'library/common/commonComponents/Button/Button';

function ReturnDeliveryAddressNotFoundPopup({ id = '', onClickOkay, onClickCancel }) {
  return (
    <Modal onCloseClick={onClickCancel} id={id} confirmAction={onClickOkay} buttonStyles={'d-none'}>
      {strings.returnDeliverAddressNotAddedForCountry}
      <div className='d-flex flex-row justify-content-center'>
        <Button bgFill={true} value={strings.ok} onClick={onClickCancel} styleClass='col-3 mt-2' />
      </div>
    </Modal>
  );
}

export default ReturnDeliveryAddressNotFoundPopup;
