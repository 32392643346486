import { connect } from 'react-redux';

import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';

import Administration from './MasterData.component';
import { saveMasterData, getInitialData } from './MasterData.actions';

const mapStateToProps = ({ languageReducer, masterDataReducer, administrationReducer, appActionsReducer }) => ({
  language: languageReducer,
  masterData: masterDataReducer,
  administrationReducer: administrationReducer,
  appActions: appActionsReducer
});

export default connect(mapStateToProps, {
  saveMasterData,
  toggleActionMessage,
  getInitialData,
})(Administration);
