import Validators from 'library/utilities/Validators';

export const exportModel = ({ categoriesAndSystems, countries }) => [
  {
    label: 'countryLand',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'countryId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    options: countries,
    hasSection: false,
    multiSelect: false,
    filter: false,
    selectAllOption: false,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: '' }],
  },
  {
    label: 'categorySystem',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'systemClassIds',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    options: categoriesAndSystems,
    hasSection: false,
    multiSelect: true,
    filter: true,
    selectAllOption: false,
    idKey: 'systemClassId',
    displayValue: [{ key: 'systemClassName', separator: '' }],
  },
];