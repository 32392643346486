export const masterDataBreadcrumbData = [
  {
    name: 'homepage',
    url: '/home',
  },
  {
    name: 'administration',
  },
];

export const FETCH_MULTIPLE_ADDRESS_LIST = 'FETCH_MULTIPLE_ADDRESS_LIST';
