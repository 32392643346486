import React from "react";
import strings from "resources/locales/Translate";

export const useStockFilter = () => {

    const getCols = () => [
        {
            title: strings.requestId,
            name: 'requestId',
            isSort: false,
            render: (item) => {
              return (
                <span></span>
              )
            },
          },
          {
            title: strings.partId,
            name: 'partId',
            isSort: false,
            render: (item) => {
              return (
                <span></span>
              )
            },
          },
          {
            title: strings.partName,
            name: 'partName',
            isSort: false,
            render: (item) => {
              return (
                <span></span>
              )
            },
          },
          {
            title: strings.customer,
            name: 'customer',
            isSort: false,
            render: (item) => {
              return (
                <span></span>
              )
            },
          },
          {
            title: strings.status,
            name: 'status',
            isSort: false,
            render: (item) => {
              return (
                <span></span>
              )
            },
          },
          {
            title: strings.tPlanned,
            name: 'tPlanned',
            isSort: false,
            render: (item) => {
              return (
                <span></span>
              )
            },
          },
          {
            title: strings.tBookedAe,
            name: 'tBookedAe',
            isSort: false,
            render: (item) => {
              return (
                <span></span>
              )
            },
          },
          {
            title: strings.tBookedMachine,
            name: 'tBookedMachine',
            isSort: false,
            render: (item) => {
              return (
                <span></span>
              )
            },
          },
          {
            title: strings.tTracked,
            name: 'tTracked',
            isSort: false,
            render: (item) => {
              return (
                <span></span>
              )
            },
          },

    ];

    return{
        getCols
    }
}