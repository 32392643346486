import React, { Fragment } from 'react';
import get from 'lodash/get';

import strings from 'resources/locales/Translate';
import { getDateInUTCFormat } from 'library/utilities/getDateInFormat';
import {
  getIconByCondition,
  getIconByLLTransactionStatus,
  getIconByCurrentPosition,
} from 'library/utilities/getStatusAndConditionIcons';
import TransactionStatusAndPositionTooltip from '../TransactionStatusAndPositionTooltip';

export const useLLTransactions = ({ labsLocationTransactionsList }) => {
  const getCols = () => [
    {
      title: '#',
      name: 'number',
      isSort: false,
      render: item => {
        return <span>{item.transactionNumber}</span>;
      },
    },
    {
      title: `${strings.status}`,
      name: 'status',
      isSort: false,
      render: item => {
        const {
          transactionKit,
          transactionStatus,
          transactionPosition,
        } = item;
        const kitCondition = get(transactionKit, 'kitInformation.kitCondition.kitConditionTranslated');
        const hasKitCondition = kitCondition && kitCondition.length > 0;
        const status = get(transactionStatus, 'transactionStatusId') || '';
        const currentPosition = get(transactionPosition, 'transactionPositionTranslated');
        const hasCurrentPosition = currentPosition && currentPosition.length > 0;
        const isExpandable = false;
  
        return (
          <div className='d-flex flex-wrap show-info-onhover'>
            {status && <>{getIconByLLTransactionStatus(status, isExpandable)}</>}
            {hasKitCondition && <>{getIconByCondition(transactionKit.kitInformation.kitCondition, isExpandable)}</>}
            {hasCurrentPosition && (
              <div className='d-flex align-items-center'>
                {currentPosition.map(position => (
                  <Fragment key={position.positionText}>
                    {getIconByCurrentPosition(position, isExpandable)}
                  </Fragment>
                ))}
              </div>
            )}
             <div className='dispaly-status'>
              <TransactionStatusAndPositionTooltip 
                checkStatus={true} 
                transactionStatus={transactionStatus}  
                isZmcc={false} 
                isLabsLocation={true}
                checkCondition={hasKitCondition}
                transactionKit={transactionKit}
                checkCurrentPosition={hasCurrentPosition}
                transactionPosition={transactionPosition}
              />
              </div>
          </div>
        );
      },
    },
    {
      title: strings.name,
      name: 'name',
      isSort: true,
      render: item => {
        const { name } = item;
        return <>{name}</>;
      },
    },
    {
      title: strings.demoKit,
      name: 'demoKit',
      isSort: false,
      render: item => {
        const { transactionKit } = item;
        const name = get(transactionKit, 'kitInformation.kitName', '');
        return <>{name}</>;
      },
    },
    {
      title: strings.serialNumber,
      name: 'serialNumber',
      isSort: false,
      render: item => {
        const { transactionKit } = item;
        const serialNumber = get(transactionKit, 'kitInformation.serialNumber') || '';
        return <>{serialNumber}</>;
      },
    },
    {
      title: strings.begin,
      name: 'begin',
      isSort: true,
      render: item => {
        return (
          <>
            {item.targetReservationFrom ? getDateInUTCFormat('DD.MM.YYYY', item.targetReservationFrom) : ''}
          </>
        );
      },
    },
    {
      title: strings.end,
      name: 'end',
      isSort: true,
      render: item => {
        return (
          <>
            {item.targetReservationTo ? getDateInUTCFormat('DD.MM.YYYY', item.targetReservationTo) : ''}
          </>
        );
      },
    },
    {
      title: strings.partnerSite,
      name: 'stock',
      isSort: false,
      render: item => {
        const stockName = get(item, 'transactionKit.stockInformation.locationName');
        return <span>{stockName}</span>;
      },
    },
    {
      title: strings.countryLand,
      name: 'country',
      isSort: true,
      render: item => {
        const { country } = item;
        return <span>{country && country.name}</span>;
      },
    },
    {
      title: strings.customer,
      name: 'customer',
      isSort: true,
      render: item => {
        return <>{item.customer}</>;
      },
    },
    {
      title: strings.city,
      name: 'city',
      isSort: false,
      render: item => {
        const { city } = item;
        return <>{city}</>;
      },
    },
  ];

  const cols = getCols();
  const hasTransactionList = labsLocationTransactionsList && labsLocationTransactionsList.content && labsLocationTransactionsList.content.length > 0;

  return {
    cols,
    hasTransactionList,
    getCols,
  };
}
