import React, { useEffect, useState } from 'react';
import { get } from 'lodash';

import strings from 'resources/locales/Translate';
import Button from 'library/common/commonComponents/Button/Button';
import { getDateInFormat } from 'library/utilities/getDateInFormat';
import $ from 'jquery';
import KeyValueDetails from 'library/common/commonComponents/KeyValueDetails/KeyValueDetails';
import 'library/common/commonComponents/KeyValueDetails/keyValue.styles.scss';

const IQSInformation = ({ kitDetail, history, user, availabilitySlotList }) => {
  const businessUnit = get(kitDetail, 'businessUnit.name');

  const checkUserPermission = () => {
    const { stockInformation } = kitDetail;
    let isEditable;
    if (stockInformation && stockInformation.userRole && stockInformation.userRole.length > 0){
      isEditable = stockInformation.userRole[0].role.role.toLowerCase() === 'stock controller' ||
      user && user.admin ||
      (stockInformation.userRole[0].role.role.toLowerCase() === 'dispatcher');
    }
    return isEditable;
  };


  const isEditable = checkUserPermission();
  
  const informationDetails =[
    { key: strings.businessUnit, value: businessUnit},
    { key: strings.countryLand, value: (kitDetail.stockInformation && kitDetail.stockInformation.country.name) || '-'},
    { key: strings.location, value: (kitDetail.stockInformation && kitDetail.stockInformation.locationName) || '-'},
    { key: strings.inputRoomLabel, value: kitDetail.warehouseRoom && kitDetail.warehouseRoom.roomName || '-'},
    { key: strings.responsiblePerson, value: (kitDetail.owner && `${kitDetail.owner.lastName}, ${kitDetail.owner.firstName}`) || '-'},
    { key: strings.iqsTypeLabel, value: (kitDetail.resourceType && `${kitDetail.resourceType}`) || '-'},
    { key: strings.category, value: (kitDetail?.productHierarchy && `${kitDetail?.productHierarchy?.name}`) || '-'},    
    { key: strings.iqsSystemClassLabel, value: (kitDetail.systemClass && kitDetail.systemClass.systemClassName) || '-'},
    { key: strings.iqsSystemMainComppoenetLabel, value: (kitDetail.systemMainComponent && kitDetail.systemMainComponent.systemMainComponentName) || '-'},
    { key: strings.ibaseText, value: kitDetail.ibase || '-'},
    { key: strings.isMobileOrExternal, value: kitDetail.isMobileOrExternal ? strings.yes : kitDetail.isMobileOrExternal !== false ? '-' : strings.no},
    { key: strings.marketingMaterialText, value: kitDetail.marketingMaterial ? strings.yes : kitDetail.marketingMaterial !== false ? '-' : strings.no},
    { key: strings.supportingAsset, value: kitDetail.supportingAsset ? strings.yes : kitDetail.supportingAsset !== false ? '-' : strings.no},
    { key: strings.fixedAsset, value: kitDetail.fixedAsset ? strings.yes : kitDetail.fixedAsset !== false ? '-' : strings.no},
  ]

  const identifierDetails =[
    { key: strings.storageLocation, value: kitDetail.storageLocation || '-'},
    { key: strings.sapPlant, value: kitDetail.sapPlant || '-'},
    { key: strings.sapStorageLocation, value: kitDetail.sapStorageLocation || '-'},
    { key: strings.materialNumber, value: kitDetail.materialNumber || '-'},
    { key: strings.serialNumber, value: kitDetail.serialNumber || '-'},
    { key: strings.inventoryNumber, value: kitDetail.inventoryNumber || '-'},
    { key: strings.dateOfManufacture, value: kitDetail.dateOfManufacture ? `${getDateInFormat('DD.MM.YYYY', kitDetail.dateOfManufacture)}` : '-'},
    { key: strings.dateCreated, value: kitDetail.dateCreated ? `${getDateInFormat('DD.MM.YYYY', kitDetail.dateCreated)} ${kitDetail.createdBy ? strings.by +" "+kitDetail.createdBy.firstName + " " + kitDetail.createdBy.lastName  : ""}` : '-'},
    { key: strings.dateModified, value: kitDetail.updatedAt && kitDetail.updatedBy ? `${getDateInFormat('DD.MM.YYYY', kitDetail.updatedAt)} ${kitDetail.updatedBy ? strings.by + " " + kitDetail.updatedBy.firstName + " " + kitDetail.updatedBy.lastName : ""}` : (kitDetail.updatedAt && (getDateInFormat('DD.MM.YYYY', kitDetail.updatedAt) > getDateInFormat('DD.MM.YYYY', kitDetail.dateCreated))) ? `${getDateInFormat('DD.MM.YYYY', kitDetail.updatedAt)} ${strings.by} System` : '-' },
  ]

  const statusDetails =[
    { key: strings.lastMaintenance, value: kitDetail.lastMaintenanceDate ? `${getDateInFormat('DD.MM.YYYY', kitDetail.lastMaintenanceDate)}` : '-'},
    { key: strings.maintenanceIntervalInMonths, value: kitDetail.maintenanceInterval || '-'},
    { key: strings.availableFrom, value: kitDetail.availabilityFrom ? `${getDateInFormat('DD.MM.YYYY', kitDetail.availabilityFrom)}` : '-'},
    { key: strings.availableTo, value: kitDetail.availabilityTo ? `${getDateInFormat('DD.MM.YYYY', kitDetail.availabilityTo)}` : '-'},
    { key: strings.status, value: kitDetail.resourceStatus || '-'},
    { key: strings.purpose, value: kitDetail.purpose || '-', styleColClassForField : 'col-12'},
  ]

  const loanInformationDetails =[
    { key: strings.deliveryDays, value: kitDetail.deliveryDays || '-'},
    { key: strings.returnDeliveryDays, value: kitDetail.returnDeliveryDays || '-'},
    { key: strings.reconditioningDays, value: kitDetail.reconditioningDeliveryDays || '-'},
    { key: strings.maxLoanDuration, value: kitDetail.maxLoanDuration || '-'},
    { key: strings.iqsPackageDimensions, value: kitDetail.packageDimensions || '-'},
  ]


  return (
    <div className='information-container'>
      <div className='container-fluid'>
      <div className='mt-3'>
        <KeyValueDetails details={informationDetails} sectionTitle={strings.information} styleColClass='col-3' >
        </KeyValueDetails>
        <KeyValueDetails details={identifierDetails} sectionTitle={strings.identifyer} styleColClass='col-3' >
        </KeyValueDetails>
        <KeyValueDetails details={statusDetails} sectionTitle={strings.status} styleColClass='col-3' >
        </KeyValueDetails>
        <KeyValueDetails details={loanInformationDetails} sectionTitle={strings.loanInformation} styleColClass='col-3' >
        </KeyValueDetails>
        </div>
        {isEditable && (
          <Button
            data-test='edit-kit-button'
            bgFill={false}
            iconName='edit'
            value={strings.edit}
            styleClass='w-auto'
            onClick={() =>
              history.push({
                pathname: `/edit-zmcc-kit/${kitDetail.kitInformationId}`,
                state: { edit: true, tab: 'information' },
              })
            }
          />
        )}
      </div>
    </div>
  );
};

export default IQSInformation;