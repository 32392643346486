import { connect } from 'react-redux';

import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';
import {
  updateSEMForm,
  getUnexecutedSemTransaction,
  saveSemTransaction,
  fetchLoansIntended,
  fetchBestWayToReach,
  fetchDataWithin,
  fetchDataWithCustomer,
  fetchRelationShipWithZeiss,
  fetchMarketSegment,
  fetchStatusOFSamples,
  fetchPrimaryInstruments,
  fetchSkillLevel,
  cancelSemZmccLoan,
  getListOfUnexecutedSemTransaction,
  fetchEstimatedDays,
  fetchZmccShippingSampleAddress,
  fetchMarketSubSegment,
  downloadZmccDocumentFolder,
  resetFormData,
  deleteAttendee,
  getSystemCategoriesList,
  isClickedOnAddDemoCenter,
  isClickedOnAdInTodoTab,
  fetchSbuTypes
} from './SemBooking.actions';
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import SemBooking from './SemBooking.component';
 import { updateZmccTransaction, getWholeSemTransaction } from 'modules/ZmccTransactions/ZmccTransactionDetails/ZmccTransactionDetails.actions';

const mapStateToProps = ({
  appActionsReducer,
  authReducer,
  createSEMFormReducer,
  languageReducer,
  masterDataReducer,
  zmccTransactionDetailsReducer
}) => ({
  appActions: appActionsReducer,
  formValue: {
    generalInformationForm: createSEMFormReducer.generalInformationForm,
    schedulingInformationForm: createSEMFormReducer.schedulingInformationForm,
    projectDetailsForm: createSEMFormReducer.projectDetailsForm,
    customerDetilsForm: createSEMFormReducer.customerDetilsForm,
    primaryPointOfContactForm: createSEMFormReducer.primaryPointOfContactForm,
    customerBackgroundForm: createSEMFormReducer.customerBackgroundForm,
    mainApplicationChecklistForm: createSEMFormReducer.mainApplicationChecklistForm,
    sampleDeatilsFrom: createSEMFormReducer.sampleDeatilsFrom,
    demoCenterInformationForm: createSEMFormReducer.demoCenterInformationForm,
    additionalInformationForm: createSEMFormReducer.additionalInformationForm,
    logisticsAndCoordinationForm: createSEMFormReducer.logisticsAndCoordinationForm,
    todosListForm: createSEMFormReducer.todosListForm,
    zmccFullInformationId: createSEMFormReducer.zmccFullInformationId,
    transactionsId: createSEMFormReducer.transactionsId,
    kitAttachments: createSEMFormReducer.kitAttachments,
    kitAttachmentsToDelete: createSEMFormReducer.kitAttachmentsToDelete,
    xbMainApplicationChecklistForm: createSEMFormReducer.xbMainApplicationChecklistForm,
    xbSampleDeatilsFrom: createSEMFormReducer.xbSampleDeatilsFrom,
    xrmDemoDetailsForm: createSEMFormReducer.xrmDemoDetailsForm,
    xrmApplicationInformationForm: createSEMFormReducer.xrmApplicationInformationForm,
    xrmSamplesDetailsForm: createSEMFormReducer.xrmSamplesDetailsForm,
    xrmSampleDescriptionForm: createSEMFormReducer.xrmSampleDescriptionForm,
    xrmSampleSpecsForm: createSEMFormReducer.xrmSampleSpecsForm,
    lmApplicationAndSamplesForm: createSEMFormReducer.lmApplicationAndSamplesForm,
    deleteTodoIds: createSEMFormReducer.deleteTodoIds
  },
  language: languageReducer,
  user: authReducer.user,
  loansIntended: createSEMFormReducer.loansIntended,
  bestWayToReach: createSEMFormReducer.bestWayToReachData,
  dataWithin: createSEMFormReducer.dataWithin,
  marketSegments: createSEMFormReducer.marketSegments,
  realtionshipWithZeiss: createSEMFormReducer.realtionshipWithZeiss,
  statusOfSamples: createSEMFormReducer.statusOfSamples,
  dataFromCustomer: createSEMFormReducer.dataFromCustomer,
  primaryInstrument: createSEMFormReducer.primaryInstrument,
  countries: masterDataReducer.countries,
  usersList: masterDataReducer.usersList,
  skillLevel: createSEMFormReducer.skillLevel,
  estimatedays: createSEMFormReducer.estimatedays,
  shippingAddress: createSEMFormReducer.shippingAddress,
  transactionsAddress: createSEMFormReducer.transactionsAddress,
  zmccInformationTabData: zmccTransactionDetailsReducer.zmccInformationTabData,
  marketSubSegments: createSEMFormReducer.marketSubSegments,
  zmccTransactionDetails: zmccTransactionDetailsReducer.zmccTransactionDetails,
  systemCategoriesList: createSEMFormReducer.systemCategoriesList,
  sbuTypes: createSEMFormReducer.sbuTypes,
});

export default connect(mapStateToProps, {
  fetchMasterData,
  updateSEMForm,
  toggleActionMessage,
  getUnexecutedSemTransaction,
  saveSemTransaction,
  fetchLoansIntended,
  fetchBestWayToReach,
  fetchDataWithin,
  fetchDataWithCustomer,
  fetchRelationShipWithZeiss,
  fetchMarketSegment,
  fetchStatusOFSamples,
  fetchPrimaryInstruments,
  fetchSkillLevel,
  cancelSemZmccLoan,
  getListOfUnexecutedSemTransaction,
  fetchEstimatedDays,
  fetchZmccShippingSampleAddress,
  updateZmccTransaction,
  fetchMarketSubSegment,
  downloadZmccDocumentFolder,
  resetFormData,
  deleteAttendee,
  getSystemCategoriesList,
  getWholeSemTransaction,
  isClickedOnAddDemoCenter,
  isClickedOnAdInTodoTab,
  fetchSbuTypes
})(SemBooking);
