import { useEffect, useState } from 'react';
import { mainApplicationChecklist } from './ApplicationSampleDetails.constants';

export const useApplicationSampleDetails = props => {

  const handleForm = ({ values, field, formName }) => {
    props.updateSEMForm({
      [formName]: {
        ...props.formValue[formName],
        formData: {
          ...((props.formValue[formName] && props.formValue[formName].formData) || {}),
          ...values,
        },
      },
    });
    props.setUnsavedChanges(true);

  };

  const handleNonFormComponent = (values, field, formName) => {
    props.updateSEMForm({
      [formName]: {
        ...props.formValue[formName],
        formData: {
          ...((props.formValue[formName] && props.formValue[formName].formData) || {}),
          [field]: values,
          [`${field}Error`]: null,
        },
      },
    });
    props.setUnsavedChanges(true);
  };

  const mainApplicationModel = () => {
    const model = [...mainApplicationChecklist];
    model[18].options = props.dataFromCustomer;

    return model;
  };

  const onEditCompleted = () => {
    const { user, formValue } = props;
    const { sampleDeatilsFrom } = formValue;
    props.setEditCompltedBy(true);
    if (sampleDeatilsFrom.formData.completedByName) {
      handleNonFormComponent(sampleDeatilsFrom.formData.completedByName, 'completedByName', 'sampleDeatilsFrom')
    }else{
      handleNonFormComponent(`${user.firstName} ${user.lastName}`, 'completedByName', 'sampleDeatilsFrom')
    }
  }
  return {
    handleForm,
    handleNonFormComponent,
    mainApplicationModel,
    onEditCompleted,
  };
};
