import React from 'react';

import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import Modal from 'library/common/commonComponents/Modal';

import { createServiceTicketModel } from './CreateServiceTicketPopup.constants';
import { useCreateServiceTicketPopup } from './CreateServiceTicketPopup.hook';
import './CreateServiceTicketPopup.styles.scss';

const CreateServiceTicketPopup = ({
  id,
  kitDetails,
  user,
  problemsList,
  createServiceTicket,
  handleFetch,
}) => {
  const {
    serviceTicketForm,
    error,
    isLoading,
    confirmAction,
    onCloseClick,
    handleForm,
  } = useCreateServiceTicketPopup({
    id,
    kitDetails,
    user,
    problemsList,
    createServiceTicket,
    handleFetch,
  });

  return (
    <Modal
      id={id}
      title={strings.createServiceTicket}
      confirmAction={confirmAction}
      confirmTitle={strings.save}
      onCloseClick={onCloseClick}
      loading={isLoading}
    >
      <FormComponent
        formName='createServiceTicketForm'
        model={createServiceTicketModel}
        formValue={serviceTicketForm.formData}
        onChange={handleForm}
      />
      {error && <div className='error-block '>{strings[error]}</div>}
    </Modal>
  );
};

export default CreateServiceTicketPopup;
