const ModalRoot = (() => {
    let modalRoot = document.getElementById('portal-root');
  
    if (!modalRoot) {
      modalRoot = document.createElement('div');
      modalRoot.id = 'portal-root';
      document.body.append(modalRoot);
    }
  
    return modalRoot;
  })();

  export default ModalRoot;