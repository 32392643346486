import React, { useCallback } from 'react';
import moment from 'moment';

import strings from 'resources/locales/Translate';
import { getDateInFormat } from 'library/utilities/getDateInFormat';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import DateInput from 'library/common/commonComponents/FormComponent/Optimized/DateInput';

const KPIsFilter = ({ filters, setFilters, stocks, continentsList }) => {
  const handleDateChange = useCallback(dateRange => {
    let newDateRange = null;

    if (dateRange) {
      newDateRange = [...dateRange];
      const dateRangeStart = dateRange[0] ? `${getDateInFormat('YYYY-MM-DD', dateRange[0])} 00:00:00` : null;
      const dateRangeEnd = dateRange[1] ? `${getDateInFormat('YYYY-MM-DD', dateRange[1])} 00:00:00` : null;
      newDateRange[0] = dateRangeStart;
      newDateRange[1] = dateRangeEnd;
    }

    setFilters({ ...filters, dateRange });
  }, [filters, setFilters]);

  const handleFilter = useCallback((id, field, value) => {
    const idValue = Array.isArray(id) ? [...id] : id;
    const newFilters = {
      ...filters,
      [field]: idValue,
      [`${field}FullValue`]: value,
    }
    setFilters(newFilters);
  } , [filters, setFilters]);

  return (
    <div>
      <div className='filter-wrapper my-3'>
        <div className='container-fluid'>
          <div className='row'>
            <Dropdown
              data={continentsList}
              idKey='id'
              label={strings.continent}
              field='continent'
              displayValue={[{ key: 'name', separator: '' }]}
              multiSelect={true}
              isRequired={true}
              placeholder={strings.pleaseSelect + '...'}
              onChange={handleFilter}
              mainContainerStyle='col-md-4'
              value={filters.continent}
              fullValue={filters.continentFullValue}
              dataTest="continentsList"
            />
            <Dropdown
              data={stocks}
              hasSection
              filter
              multiSelect={false}
              idKey='id'
              field='stock'
              displayValue={[{ key: 'locationName', separator: '' }]}
              optionsArrayKey='stockInformation'
              titleDisplay={[
                { key: 'shortName', separator: ' ' },
                { key: 'name', separator: '' },
              ]}
              isRequired={false}
              placeholder={strings.pleaseSelect + '...'}
              label={strings.labsLocationPartner}
              onChange={handleFilter}
              mainContainerStyle='col-md-4'
              value={filters.stock}
              fullValue={filters.stockFullValue}
            />
            <DateInput
              label={strings.dateRange}
              field='dateRange'
              placeholder={strings.pleaseSelectDate + '...'}
              onChange={handleDateChange}
              selectedDate={filters.dateRange}
              isRequired={true}
              mainContainerStyle='col-md-4'
              isRange={true}
              clearIcon={true}
              minDate={moment().subtract(10, 'years')}
              maxDate={moment().add(1, 'years')}
              dataTest="dateRange"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default KPIsFilter;
