import Validators from 'library/utilities/Validators';

export const itemsPerPage = {
  10: { id: 10, pages: 10 },
  20: { id: 20, pages: 20 },
  50: { id: 50, pages: 50 },
};
export const FETCH_MED_ADDRESS_LIST = 'FETCH_MED_ADDRESS_LIST';

export const editAddressModel = [
  {
    label: 'salutation',
    value: '',
    type: 'radio-button',
    placeholder: '',
    field: 'salutation',
    validators: [],
    required: false,
    styleClass: 'col-sm-12',
    options: [
      {
        id: 'mr',
        value: 'mr',
      },
      {
        id: 'ms',
        value: 'ms',
      },
    ],
  },
  {
    label: 'firstName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'firstName',
    validators: [],
    required: false,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'lastName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'lastName',
    validators: [],
    required: false,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'organization',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'organization',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-12',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'department',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'department',
    validators: [],
    required: false,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'street',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'street',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 256,
    },
  },
  {
    label: 'houseNumber',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'houseNumber',
    required: false,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 256,
    },
  },
  {
    label: 'postalCode',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'postalCode',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 20,
    },
  },
  {
    label: 'state',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'state',
    validators: [],
    required: false,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'city',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'city',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'phone',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'phone',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'countryLand',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'country',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: '' }],
    excludeData: [],
    styleClass: 'col-sm-6',
  },
  {
    label: 'email',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'email',
    validators: [
      { check: Validators.email, message: 'invalidEmailError' },
      {
        check: Validators.required,
        message: 'requiredErrorMessage',
      },
    ],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50,
    },
  },
];
