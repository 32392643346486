import React from 'react';
import $ from 'jquery'

import strings from 'resources/locales/Translate';

export const useTeamBookings = ({
  id,
  addUsersToTeamBookings,
  removeUserFromTeamBookings,
  data,
  transactionType,
}) => {
  const [selected, setSelected] = React.useState({
    value: [],
    fullValue: [],
    error: ''
  });
  const [isLoading, toggleIsLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  const onCloseClick = () => {
    if ($(`#${id}`) && $(`#${id}`).modal) {
      $(`#${id}`).modal('hide');
    }

    setSelected({
      value: [],
      fullValue: [],
      error: ''
    });
    setError('');
  };

  const handleConfirmAction = async () => {
    if (!selected.value.length) {
      return  setSelected({
        value: [],
        fullValue: [],
        error: 'requiredErrorMessage'
      });
    }
    setError('');
    toggleIsLoading(true);
    const { success } = await addUsersToTeamBookings(selected.value, data, transactionType);
    if (success) {
      toggleIsLoading(false);
      onCloseClick();
    } else {
      toggleIsLoading(false);
      setError(strings.somethingWentWrongMessage);
    }
  };

  const handleValueChange = (value, field, fullValue) => {
    const newValue = value ? [ ...value ] : [];
    const newFullValue = fullValue ? [ ...fullValue ] : [];

    setSelected({
      value: newValue,
      fullValue: newFullValue,
      error: '',
    });
  }

  const handleRemoveUser = async id => {
    setError('');
    toggleIsLoading(true);
    const { success } = await removeUserFromTeamBookings([id], data, transactionType);
    if (success) {
      toggleIsLoading(false);
      onCloseClick();
    } else {
      toggleIsLoading(false);
      setError(strings.somethingWentWrongMessage);
    }
  }

  return {
    isLoading,
    selected,
    error,
    onCloseClick,
    handleConfirmAction,
    handleValueChange,
    handleRemoveUser,
  };
};
