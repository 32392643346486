import { useEffect, useState } from "react";
import { attendeeModel } from "./EditAttendeePopup.constants";
import Validators from "library/utilities/Validators";
import * as $ from 'jquery';
import { validateForm } from 'library/utilities/ValidateForm.util';

export const useEditAttendeePopuo = ({id, data, skillLevelData,handleEditNonFormComponent,todosListForm,user,handleNonFormComponent,editingFiledId,handleEditNonFormComponentForTodoList,saveZmccSemData, deleteTodoIds, isZmcc, zmccXrmBooking, zmccLmBooking, zmccXbBooking, zmccSemBooking, updateZmccTransaction,
  showCheckBoxes = false
}) =>{
    const [editAttendeeFormData, setEditAttendeeFormData] = useState({formData: {}});

    useEffect(() =>{
        settingData()
    },[data])

    const settingData = () =>{
        if (data) {
            setEditAttendeeFormData({
                formData:{
                    attendeeName: data.attendeeName,
                    attendeeEmail: data.attendeeEmail,
                    functionData: data.functionData,
                    skillLevelId: data.skillLevel && data.skillLevel.skillLevelId,
                    accomodationNeeded: data.accomodationNeeded,
                    accomodationNeededData: data.accomodationNeededData,
                    flightNeeded: data.flightNeeded,
                    flightNeededData: data.flightNeededData,
                    visitMuseumOfOptics: data.visitMuseumOfOptics,
                    visitMuseumOfOpticsData: data.visitMuseumOfOpticsData,
                    descisionMaker: data.decisionMaker ? 'yes': data.decisionMaker === false ? 'no' : null,
                    zmccAttendeesId: data.zmccAttendeesId, 
                    todosByAttendees: data.todosByAttendees,
                    isCustomerAttendee: data.isCustomerAttendee,
                    isPOCtAttendee: data.isPOCtAttendee,
                }
            })
           }
    }

    const attendeesModel = () => {
        const model = [...attendeeModel];
        model[3].options = skillLevelData;
        if (editAttendeeFormData.formData.accomodationNeeded) {
           model[5].isDisplayed = false;
           model[5].validators = [{ check: Validators.required, message: 'requiredErrorMessage' }]
           model[5].styleClass = 'col-sm-6'
           model[4].styleClass = 'col-sm-6 mt-4' 
        }else {
            model[5].isDisplayed = true;
           model[5].validators = [];
           model[5].styleClass = 'col-sm-12' 
           model[4].styleClass = 'col-sm-12'  
        }
        if (editAttendeeFormData.formData.flightNeeded) {
            model[7].isDisplayed = false;
            model[7].validators = [{ check: Validators.required, message: 'requiredErrorMessage' }]
            model[7].styleClass = 'col-sm-6'
            model[6].styleClass = 'col-sm-6 mt-4' 
         }else {
             model[7].isDisplayed = true;
            model[7].validators = [];
            model[7].styleClass = 'col-sm-12' 
            model[6].styleClass = 'col-sm-12'  
         }
         if (editAttendeeFormData.formData.visitMuseumOfOptics) {
            model[9].isDisplayed = false;
            model[9].validators = [{ check: Validators.required, message: 'requiredErrorMessage' }]
            model[9].styleClass = 'col-sm-6'
            model[8].styleClass = 'col-sm-6 mt-4' 
         }else {
             model[9].isDisplayed = true;
            model[9].validators = [];
            model[9].styleClass = 'col-sm-12' 
            model[8].styleClass = 'col-sm-12'  
         }
         if(showCheckBoxes === false){
          return model.filter((item) => {
            let removedModels = ['accomodationNeeded', 'flightNeeded', 'visitMuseumOfOptics', 'visitMuseumOfOpticsData',
              'flightNeededData', 'accomodationNeededData'
            ]
            return removedModels.indexOf(item.field) == -1;
          })
         }
        return model;
      };

      const handleForm = ({ values, field }) => {
        setEditAttendeeFormData({
          ...editAttendeeFormData,
          formData: {
            ...editAttendeeFormData.formData,
            ...values,
          },
        });
      };


      const onCloseClick = () =>{
        settingData();
        $(`#${id}`).modal('hide');
      }

      const onSaveClick = () =>{
        const validForm = validateForm({
          form: editAttendeeFormData,
          model: attendeeModel
        });
    
        if (!validForm.isFormValid) {
          return setEditAttendeeFormData(validForm);
        }
        let tempAttendeList = [];
        var updatedDelete = [];
          if(editAttendeeFormData.formData.accomodationNeeded != data.accomodationNeeded && !editAttendeeFormData.formData.accomodationNeeded){
            var deleteTodoFromDB = todosListForm.formData.zmccTodosListDataList.find((item, index) => item.title === 'accomodationNeeded' && data.zmccAttendeesId === item.zmccAttendees.zmccAttendeesId);
            if (deleteTodoFromDB && deleteTodoFromDB.zmccTodosId != null){
              updatedDelete.push(deleteTodoFromDB.zmccTodosId);
            }
          }
          else if (editAttendeeFormData.formData.accomodationNeededData !== data.accomodationNeededData) {
          let editedTodoByAttendee;
          
            editedTodoByAttendee = todosListForm.formData.zmccTodosListDataList.find(
              item =>
                item.zmccAttendees &&
                data.zmccAttendeesId === item.zmccAttendees.zmccAttendeesId &&
                item.title === 'accomodationNeeded',
            );
          tempAttendeList.push({
            zmccTodosId: editedTodoByAttendee ? editedTodoByAttendee.zmccTodosId : null,
            zmccTodosText: editAttendeeFormData.formData.accomodationNeededData,
            createdBy:
              editedTodoByAttendee && editedTodoByAttendee.user ? editedTodoByAttendee.user.accountId : user.accountId,
            checkedBy:
              editedTodoByAttendee && editedTodoByAttendee.checkedUser
                ? editedTodoByAttendee.checkedUser.accountId
                : null,
            isChecked: editedTodoByAttendee ? editedTodoByAttendee.isChecked : false,
            title: 'accomodationNeeded',
          });
        }

        if(editAttendeeFormData.formData.flightNeeded != data.flightNeeded && !editAttendeeFormData.formData.flightNeeded){
          var deleteTodoFromDB = todosListForm.formData.zmccTodosListDataList.find((item, index) => item.title === 'flightNeeded' && data.zmccAttendeesId === item.zmccAttendees.zmccAttendeesId);
          if (deleteTodoFromDB && deleteTodoFromDB.zmccTodosId != null){
            updatedDelete.push(deleteTodoFromDB.zmccTodosId);
          }
        }else if (editAttendeeFormData.formData.flightNeededData !== data.flightNeededData) {
          let editedTodoByAttendee = todosListForm.formData.zmccTodosListDataList.find(
            item =>
              item.zmccAttendees &&
              data.zmccAttendeesId === item.zmccAttendees.zmccAttendeesId &&
              item.title === 'flightNeeded',
          );
          tempAttendeList.push({
            zmccTodosId: editedTodoByAttendee ? editedTodoByAttendee.zmccTodosId : null,
            zmccTodosText: editAttendeeFormData.formData.flightNeededData,
            createdBy:
              editedTodoByAttendee && editedTodoByAttendee.user ? editedTodoByAttendee.user.accountId : user.accountId,
            checkedBy:
              editedTodoByAttendee && editedTodoByAttendee.checkedUser
                ? editedTodoByAttendee.checkedUser.accountId
                : null,
            isChecked: editedTodoByAttendee ? editedTodoByAttendee.isChecked : false,
            title: 'flightNeeded',
          });
        }

        if(editAttendeeFormData.formData.visitMuseumOfOptics != data.flightNeeded && !editAttendeeFormData.formData.visitMuseumOfOptics){
          var deleteTodoFromDB = todosListForm.formData.zmccTodosListDataList.find((item, index) => item.title === 'visitMuseumOfOptics' && data.zmccAttendeesId === item.zmccAttendees.zmccAttendeesId);
          if (deleteTodoFromDB && deleteTodoFromDB.zmccTodosId != null){
            updatedDelete.push(deleteTodoFromDB.zmccTodosId);
          }
        }else if (editAttendeeFormData.formData.visitMuseumOfOpticsData !== data.visitMuseumOfOpticsData) {
          let editedTodoByAttendee = todosListForm.formData.zmccTodosListDataList.find(
            item =>
              item.zmccAttendees &&
              data.zmccAttendeesId === item.zmccAttendees.zmccAttendeesId &&
              item.title === 'visitMuseumOfOptics',
          );
          tempAttendeList.push({
            zmccTodosId: editedTodoByAttendee ? editedTodoByAttendee.zmccTodosId : null,
            zmccTodosText: editAttendeeFormData.formData.visitMuseumOfOpticsData,
            createdBy:
              editedTodoByAttendee && editedTodoByAttendee.user ? editedTodoByAttendee.user.accountId : user.accountId,
            checkedBy:
              editedTodoByAttendee && editedTodoByAttendee.checkedUser
                ? editedTodoByAttendee.checkedUser.accountId
                : null,
            isChecked: editedTodoByAttendee ? editedTodoByAttendee.isChecked : false,
            title: 'visitMuseumOfOptics',
          });
        }
        let updateAttendeedata = editAttendeeFormData.formData;
        updateAttendeedata['todosByAttendees'] = tempAttendeList;
        updateAttendeedata['visitMuseumOfOpticsData'] = editAttendeeFormData.formData.visitMuseumOfOptics ? editAttendeeFormData.formData.visitMuseumOfOpticsData : '';
        updateAttendeedata['accomodationNeededData'] = editAttendeeFormData.formData.accomodationNeeded ? editAttendeeFormData.formData.accomodationNeededData : '';
        updateAttendeedata['flightNeededData'] = editAttendeeFormData.formData.flightNeeded ? editAttendeeFormData.formData.flightNeededData : '';
        updateAttendeedata['decisionMaker'] = editAttendeeFormData.formData.descisionMaker === 'yes' ? true : editAttendeeFormData.formData.descisionMaker === 'no' ? false : null;
        updatedDelete = (deleteTodoIds.formData &&  deleteTodoIds.formData.deleteTodoIds) ? [...deleteTodoIds.formData.deleteTodoIds,...updatedDelete]: [...updatedDelete];
        handleNonFormComponent(updatedDelete, 'deleteTodoIds', 'deleteTodoIds');
        handleEditNonFormComponent(updateAttendeedata, 'logisticsAndCoordinationForm');
        isZmcc ? updateZmccTransaction(zmccLmBooking ? 'LM' : zmccXbBooking ? 'XB' : zmccXrmBooking ? 'XRM' : 'SEM') : saveZmccSemData('save', '6')
        onCloseClick();
      }
return{
    attendeesModel,
    editAttendeeFormData,
    handleForm,
    onCloseClick,
    onSaveClick
}
} 