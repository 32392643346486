import React from 'react';
import './Loader.style.scss';

export const Loader = ({ className, dataTest }) => (
  <div className={className ? `loading-container ${className}` : 'loading-container'} data-test={dataTest}>
    <div className='loading' />
  </div>
);

Loader.defaultProps = {
  dataTest: 'loader-container',
}

export default Loader;
