import Validators from 'library/utilities/Validators';
import strings from 'resources/locales/Translate';

export const FETCH_LOAN_TYPES_OF_JOBS = '@@LABS_LOCATION_KIT_LOAN/FETCH_LOAN_TYPES_OF_JOBS';
export const FETCH_LOAN_REASONS = '@@LABS_LOCATION_KIT_LOAN/FETCH_LOAN_REASONS';
export const FETCH_COUNTRIES = '@@LABS_LOCATION_KIT_LOAN/FETCH_COUNTRIES';
export const UPDATE_LABS_LOCATION_KIT_LOAN_FORM = '@@LABS_LOCATION_KIT_LOAN/UPDATE_LABS_LOCATION_KIT_LOAN_FORM';
export const UPDATE_TYPES_OF_JOBS = '@@LABS_LOCATION_KIT_LOAN/UPDATE_TYPES_OF_JOBS';
export const UPDATE_OPERATOR_ID = '@@LABS_LOCATION_KIT_LOAN/UPDATE_OPERATOR_ID';
export const SET_ADDRESSES = '@@LABS_LOCATION_KIT_LOAN/SET_ADDRESSES';
export const SET_HELP_FOR_BUILDING_AND_DISMANTLING = '@@LABS_LOCATION_KIT_LOAN/SET_HELP_FOR_BUILDING_AND_DISMANTLING';
export const SET_OPPORTUNITY = '@@LABS_LOCATION_KIT_LOAN/SET_OPPORTUNITY';
export const SET_OPPORTUNITIES = '@@LABS_LOCATION_KIT_LOAN/SET_OPPORTUNITIES';
export const SET_ATTACHMENTS = '@@LABS_LOCATION_KIT_LOAN/SET_ATTACHMENTS';
export const CLEAR_FORMS = '@@LABS_LOCATION_KIT_LOAN/CLEAR_FORMS';
export const NEW_TYPES_OF_JOBS = '@@NEW_TYPES_OF_JOBS';

export const descriptionModelKitLoan = [
  {
    label: 'reasonForLLLoan',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'reasonForLoanId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'reasonForLoanId',
    displayValue: [{ key: 'name', separator: '' }],
    disabled: true,
  },
  {
    label: 'commentText',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'comment',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
  },
  {
    label: 'nameShortDescription',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'name',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
  },
];

export const customerModel = [
  {
    label: 'customer',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'customer',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    extraProps: {
      maxLength: 256,
    },
  },
  {
    label: 'customerInstitute',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'customerInstitute',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    extraProps: {
      maxLength: 256,
    },
  },
];

export const taskIdModel = {
  label: 'taskId',
  value: '',
  type: 'text',
  placeholder: '',
  field: 'crmTaskId',
  validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
  required: true,
  styleClass: 'col-12',
};

export const opportunityIdCRMmodel = [
  {
    label: 'opportunityIdCRM',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'opportunityIdCrm',
    validators: [{ check: Validators.number, message: 'invalidNumberError' }],
    required: false,
    styleClass: 'col-12',
  },
];

export const operatorModel = [
  { id: 1, value: 'ZEISS' },
  { id: 2, value: 'Partner' },
];

export const borrowerModel = [
  {
    label: 'requestor',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'borrowerId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    displayValue: [
      { key: 'lastName', separator: ' ' },
      { key: 'firstName', separator: '' },
    ],
    idKey: 'accountId',
  },
];

export const nameOfOperatorModal = [
  {
    label: 'Name of Operator',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'operatorId',
    required: false,
    styleClass: 'col-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    clearOption: true,
    selectAllOption: true,
    selectAllPlaceholder: 'clear',
    displayValue: [
      { key: 'lastName', separator: ' ' },
      { key: 'firstName', separator: '' },
    ],
    idKey: 'accountId',
  },
];

export const typeOfDeliveryAddressModel = [
  {
    label: 'partnerSite',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'deliveryAddress',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    displayValue: [{ key: 'name', separator: '' }],
    idKey: 'id',
  },
];

export const deliveryAddressModel = [
  {
    label: 'salutation',
    value: '',
    type: 'radio-button',
    placeholder: '',
    field: 'salutation',
    validators: [],
    required: false,
    styleClass: 'col-sm-12',
    options: [
      {
        id: 'mr',
        value: 'mr',
      },
      {
        id: 'ms',
        value: 'ms',
      },
    ],
  },
  {
    label: 'firstName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'firstName',
    validators: [],
    required: false,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'lastName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'lastName',
    validators: [],
    required: false,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'organization',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'organization',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-12',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'department',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'department',
    validators: [],
    required: false,
    styleClass: 'col-sm-12',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'street',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'street',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-8',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'number',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'houseNumber',
    validators: [],
    required: false,
    styleClass: 'col-sm-4',
    extraProps: {
      maxLength: 20,
    },
  },
  {
    label: 'postalCode',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'postalCode',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-4',
    extraProps: {
      maxLength: 20,
    },
  },
  {
    label: 'city',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'city',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-8',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'state',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'state',
    validators: [],
    required: false,
    styleClass: 'col-sm-4',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'countryLand',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'countryId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: '' }],
    excludeData: [],
    styleClass: 'col-sm-8',
  },
  {
    label: 'phone',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'phone',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 20,
    },
  },
  {
    label: 'email',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'email',
    validators: [
      { check: Validators.email, message: 'invalidEmailError' },
      {
        check: Validators.required,
        message: 'requiredErrorMessage',
      },
    ],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'buildingAndRoom',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'storageLocation',
    validators: [],
    required: false,
    styleClass: 'col-sm-12',
  },
];

export const addressesForDropdown = () => [
  {
    id: 'new',
    name: strings.otherAddress,
  },
  {
    id: 'customerSite',
    name: strings.stockAddress,
  },
];

export const nameOfPartnerOperatorModel = [
  {
    label: 'name',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'llPartnerName',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
  },
  {
    label: 'email',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'llPartnerEmail',
    validators: [
      { check: Validators.email, message: 'invalidEmailError' },
      {
        check: Validators.required,
        message: 'requiredErrorMessage',
      },
    ],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 150,
    },
  },
];
