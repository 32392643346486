import {
  FETCH_CREATE_KIT_DETAILS,
  UPDATE_CREATE_KIT_FORM,
  RESET_CREATE_KIT,
  DIVISION_LIST,
  ERP_STATUS,
  REFURBISHMENTFEES_STATUS,
} from './CreateKit.constants';

const EMPTY_FORM = {
  formData: {},
  isFormValid: false,
};

export const INITIAL_STATE_CREATE_KIT_REDUCER = {
  kitInformationId: null,
  kitDetails: null,
  generalInformationForm: EMPTY_FORM,
  assignmentForm: EMPTY_FORM,
  mainComponentForm: EMPTY_FORM,
  availabilityForm: EMPTY_FORM,
  defaultPeriodsForm: EMPTY_FORM,
  packageDimensionsForm: EMPTY_FORM,
  kitStatusForm: {
    formData: {
      kitCondition: 6,
      kitConditionFullValue: { kitStatusId: 6, translatedStatus: 'Ok' },
    },
    isFormValid: false,
  },
  furtherImportantComponentForm: EMPTY_FORM,
  salesComponentForm: EMPTY_FORM,
  freeAccessoriesForm: EMPTY_FORM,
  kitForSaleForm: EMPTY_FORM,
  kitImages: [],
  kitAttachments: [],
  kitImagesToDelete: [],
  kitAttachmentsToDelete: [],
  divisionsList: [],
  erpStatus: false,
  stocknformationId: null,
  refurbishmentStatus: false,
  vrDetalisForm: EMPTY_FORM,
};

export const CreateKitReducer = (state = INITIAL_STATE_CREATE_KIT_REDUCER, action) => {
  switch (action.type) {
    case FETCH_CREATE_KIT_DETAILS:
      return { ...state, ...action.payload, kitInformationId: action.payload.kitDetails.kitInformationId };
    case UPDATE_CREATE_KIT_FORM:
      return { ...state, ...action.payload };
    case RESET_CREATE_KIT:
      return { ...INITIAL_STATE_CREATE_KIT_REDUCER };
    case DIVISION_LIST:
      return { ...state, divisionsList: action.payload };
    case ERP_STATUS:
      return { ...state, erpStatus: action.payload };
    case REFURBISHMENTFEES_STATUS:
      return { ...state, refurbishmentStatus: action.payload };
    default:
      return state;
  }
};

export default CreateKitReducer;
