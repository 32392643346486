import { fetchMasterDataService, fetchMasterDataServicePost } from 'library/api/master-data.service';
import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { fetchUserList, fetchBorrowerAddresses } from 'modules/KitLoan/KitLoanActions';
import { uploadLoanAttachment } from 'library/common/commonActions/LoanAttachmentsActions';
import { FETCH_COUNTRY_LIST } from 'library/common/commonConstants/storeConstants';
import { getDateInFormat } from 'library/utilities/getDateInFormat';
import { saveToStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import { removeItemFromCart } from 'modules/LoanList/LoanListActions';
import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { scrollToTop } from 'library/utilities/scrollActions';
import { fetchFromStorage } from 'library/utilities/storage';

import {
  FETCH_LOAN_TYPES_OF_JOBS,
  FETCH_LOAN_REASONS,
  FETCH_COUNTRIES,
  UPDATE_LABS_LOCATION_KIT_LOAN_FORM,
  UPDATE_TYPES_OF_JOBS,
  UPDATE_OPERATOR_ID,
  SET_ADDRESSES,
  SET_HELP_FOR_BUILDING_AND_DISMANTLING,
  SET_OPPORTUNITY,
  SET_OPPORTUNITIES,
  SET_ATTACHMENTS,
  CLEAR_FORMS,
  NEW_TYPES_OF_JOBS,
} from './LLKiLoan.constants';

export const fetchInitialData = (id, user) => async dispatch => {
  dispatch(changeLoader(true));
  await dispatch(fetchLoanTypesOfJobs());
  await dispatch(fetchLoanReasons());
  await dispatch(fetchUserList());
  await dispatch(fetchMasterData(URLS.countryList, FETCH_COUNTRY_LIST));
  await dispatch(fetchRequestors(id, user));
  await dispatch(fetchNewLabsLocationTypesOfJobs());
  dispatch(changeLoader(false));
};

const trimOpportunityId = opportunityNumber => {
  if (!opportunityNumber) return [];

  if (opportunityNumber.length === 10) {
    return [{ opportunityNumber: opportunityNumber.slice(1) }];
  }

  return [{ opportunityNumber }];
};

export const fetchCrmData = () => dispatch => {
  const crmData = fetchFromStorage(identifiers.labslocationCrmParams);

  if (crmData) {
    const { opportunityDescCRM, opportunityIdCRM, taskId, customer, customerinstitute } = crmData;
    const opportunities = trimOpportunityId(opportunityIdCRM);

    dispatch({
      type: SET_OPPORTUNITY,
      payload: opportunities,
    });

    return {
      crmTaskId: taskId,
      name: opportunityDescCRM ? opportunityDescCRM.replaceAll('_', ' ') : '',
      customer: customer ? customer.replaceAll('_', ' ') : '',
      customerInstitute: customerinstitute ? customerinstitute.replaceAll('_', ' ') : '',
    };
  }

  return {};
};

export const fetchRequestors = (id, user) => async dispatch => {
  const { userAddress } = await dispatch(fetchBorrowerAddresses(id));
  const borrowerForm = {
    borrowerForm: {
      formData: {
        userAddress,
        borrowerId: id,
        borrowerIdFullValue: user,
      },
    },
    isFormValid: false,
  };

  dispatch(updateForm({ ...borrowerForm }));
};

export const fetchKitDetails = (kitId, stockId) => async dispatch => {
  const query =
    'stockInformation[id,salutation,firstName,lastName,organization,email,department,phone,number,street,houseNumber,postalCode,city,state,country[name,shortName,id]],storageLocation,country[name],kitInformationId';
  const url = `${URLS.kit}/${kitId}`;
  const addressData = await fetchMasterDataService(URLS.stockAddressList(stockId));
  const { data } = await fetchMasterDataServicePost(url, { graphql: query });
  dispatch({
    type: SET_ADDRESSES,
    payload: {
      ...data.stockInformation,
      ...addressData.data[0],
      storageLocation: data.storageLocation,
      countryId: data.stockInformation.country.id,
      houseNumber: data.stockInformation.number || addressData.data[0]['houseNumber'],
      state: addressData.data[0]['stateName']
    },
  });
};

export const fetchLoanReasons = () => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.reasonForLoans);
    let result = data.find(item => item.fixedCode === 'l');
    if (result && result['name'] && result['name'] == 'labs@location reservation booking') {
      result.name = 'labs@location booking';
    }
    const { crmTaskId, name, customer, customerInstitute } = dispatch(fetchCrmData());
    const descriptionForm = {
      descriptionForm: {
        formData: {
          reasonForLoanId: data.find(item => item.fixedCode === 'l').reasonForLoanId,
          reasonForLoanIdFullValue: data.find(item => item.fixedCode === 'l'),
          crmTaskId,
          name,
        },
      },
      isFormValid: false,
    };
    const customerForm = {
      customerForm: {
        formData: {
          customer,
          customerInstitute,
        },
        isFormValid: false,
      },
    };

    dispatch({
      type: FETCH_LOAN_REASONS,
      payload: data,
    });
    dispatch(updateForm(descriptionForm));
    dispatch(updateForm(customerForm));
  } catch (error) {
    dispatch({
      type: FETCH_LOAN_REASONS,
      payload: [],
    });
  }
};

export const fetchLoanTypesOfJobs = () => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.loansTypesOfJobs);

    dispatch({
      type: FETCH_LOAN_TYPES_OF_JOBS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_LOAN_TYPES_OF_JOBS,
      payload: [],
    });
  }
};

export const fetchCountries = () => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.countryList);

    dispatch({
      type: FETCH_COUNTRIES,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_COUNTRIES,
      payload: [],
    });
  }
};

export const updateForm = form => dispatch => {
  dispatch({
    type: UPDATE_LABS_LOCATION_KIT_LOAN_FORM,
    payload: form,
  });
};

export const updateTypesOfJobs = id => (dispatch, getState) => {
  const { llKitLoanReducer } = getState();
  const { typesOfJobsId } = llKitLoanReducer;
  let newIds;

  if (typesOfJobsId.includes(id)) {
    newIds = typesOfJobsId.filter(item => item !== id);
  } else {
    newIds = [...typesOfJobsId];
    newIds.push(id);
  }

  dispatch({
    type: UPDATE_TYPES_OF_JOBS,
    payload: newIds,
  });
};

export const updateOperatorId = id => dispatch => {
  dispatch({
    type: UPDATE_OPERATOR_ID,
    payload: id,
  });
};

export const setHelpForBuildingAndDismantling = flag => dispatch => {
  dispatch({
    type: SET_HELP_FOR_BUILDING_AND_DISMANTLING,
    payload: flag,
  });
};

export const setOpportunity = value => (dispatch, getState) => {
  const { llKitLoanReducer } = getState();
  const updatedOpportunities = [...llKitLoanReducer.opportunities, { opportunityNumber: value }];

  dispatch({
    type: SET_OPPORTUNITY,
    payload: updatedOpportunities,
  });
};

export const removeOpportunity = key => (dispatch, getState) => {
  const { llKitLoanReducer } = getState();
  const updatedOpportunities = llKitLoanReducer.opportunities.filter((_, index) => index !== key);

  dispatch({
    type: SET_OPPORTUNITY,
    payload: updatedOpportunities,
  });
};

export const setOpportunities = values => dispatch => {
  const updatedOpportunities = values.map(item => ({ opportunityNumber: item }));

  dispatch({
    type: SET_OPPORTUNITIES,
    payload: updatedOpportunities,
  });
};

export const createBooking = (history, loans) => async (dispatch, getState) => {
  try {
    const { llKitLoanReducer, loanList } = getState();
    const {
      opportunities,
      typesOfJobsId,
      attachments,
      operatorId,
      helpForBuildingAndDismantling,
      descriptionForm,
      customerForm,
      borrowerForm,
      typeOfDeliveryAddressForm,
      deliveryAddressForm,
      helpForBuildingAndDismantlingForm,
      nameOfOperatorForm,
      nameOfPartnerOperatorForm,
    } = llKitLoanReducer;
    const { cart } = loanList;

    const selectedKits = cart.filter(item => item.isLabsLocation === true && item.isSelected === true);
    const reservationFrom = getDateInFormat('YYYY-MM-DD', selectedKits[0].start);
    const reservationTo = getDateInFormat('YYYY-MM-DD', selectedKits[0].end);
    let showMessage = '';
    const dataToSend = {
      typesOfJobsId,
      helpForBuildingAndDismantling,
      ...descriptionForm.formData,
      ...customerForm.formData,
      ...borrowerForm.formData,
      ...typeOfDeliveryAddressForm.formData,
      ...deliveryAddressForm.formData,
      ...helpForBuildingAndDismantlingForm.formData,
      ...nameOfOperatorForm.formData,
      ...nameOfPartnerOperatorForm.formData,
      reservationFrom,
      reservationTo,
      twentyFourHourReservation: selectedKits[0].isTwentyFourReservationAllowed,
    };

    dataToSend.opportunitiesNumber = opportunities.map(item => ({
      transactionOpportunityId: 0,
      opportunityNumber: item.opportunityNumber,
      opportunitDescription: null,
    }));

    dataToSend.selectedKits = selectedKits.map(item => ({
      kitId: item.resourceId,
      quantity: 0,
    }));

    if (dataToSend.country) {
      dataToSend.countryId = dataToSend.country.id;
    } else if (dataToSend.countryIdFullValue) {
      dataToSend.countryId = dataToSend.countryIdFullValue.id;
    }

    dataToSend.transactionsId = 0;
    dataToSend.operator = operatorId;
    dataToSend.sapErp = '';

    Object.keys(dataToSend).forEach(key => {
      if (
        key.includes('Error') ||
        key.includes('FullValue') ||
        key.includes('id') ||
        key === 'country' ||
        key.includes('userAddress')
      ) {
        delete dataToSend[key];
      }
    });

    dispatch(changeLoader(true));

    const { data } = await fetchMasterDataServicePost(URLS.loansLabsLocationKitLoans, dataToSend);

    if (attachments && attachments.length) {
      await dispatch(uploadLoanAttachment(data, attachments));
    }

    dispatch(changeLoader(false));
    if (history.location.pathname === '/labs-location-kit-loan') {
      history.push('/my-bookings?type=l@l');
      showMessage = 'labsLocationKitBookedSucessfully';
    } else {
      history.push('/my-bookings?type=demo');
      showMessage = 'kitLoanBookedSuccessfully';
    }

    loans.forEach(item => dispatch(removeItemFromCart(item)));
    saveToStorage(identifiers.labslocationCrmParams, null);
    scrollToTop(500);
    dispatch(toggleActionMessage(true, 'success', showMessage, true));
  } catch (err) {
    const status = err && err.response && err.response.status;
    const message = err && err.response && err.response.data && err.response.data.message;

    scrollToTop(500);
    dispatch(changeLoader(false));

    if (status === 403 || status === 404) {
      return dispatch(toggleActionMessage(true, 'error', err.response.data.message, true));
    }

    if (status === 422 && message) {
      return dispatch(toggleActionMessage(true, 'error', err.response.data.message, true));
    }

    dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
  }
};

export const addAttachmentFile = file => (dispatch, getState) => {
  const { llKitLoanReducer } = getState();
  const { attachments } = llKitLoanReducer;
  const newAttachments = [...attachments];

  dispatch({
    type: SET_ATTACHMENTS,
    payload: [...newAttachments, file[0]],
  });
};

export const removeAttachmentFile = index => (dispatch, getState) => {
  const { llKitLoanReducer } = getState();
  const { attachments } = llKitLoanReducer;
  const newAttachments = [...attachments];
  newAttachments.splice(index, 1);

  dispatch({
    type: SET_ATTACHMENTS,
    payload: newAttachments,
  });
};

export const clearForms = () => dispatch => {
  dispatch({ type: CLEAR_FORMS });
};

export const fetchNewLabsLocationTypesOfJobs = () => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.labLocationLocationTypesOfJobs);

    dispatch({
      type: NEW_TYPES_OF_JOBS,
      payload: data,
    });
    return { success: true };
  } catch (error) {
    dispatch({
      type: NEW_TYPES_OF_JOBS,
      payload: [],
    });
    return { success: false };
  }
}

export const fetchTypeOfJobAssociatedWithKitSystemmainComponent = (kitId) => async (dispatch, getState)  => {

    const url = `${URLS.typeOfJobAssociatedwithKitsSystemMainComponent(kitId)}`;
      try{
        dispatch(changeLoader(true));

        const { data } = await fetchMasterDataService(url);

        if (data != null){

          const { llKitLoanReducer } = getState();
          const { typesOfJobsId } = llKitLoanReducer;
          let newIds;
          if (typesOfJobsId.includes(data.tyesOfJobsId)) {
            newIds = typesOfJobsId.filter(item => item !== data.tyesOfJobsId);
          } else {
            newIds = [...typesOfJobsId];
            newIds.push(data.tyesOfJobsId);
          }
          dispatch({
            type: UPDATE_TYPES_OF_JOBS,
            payload: newIds,
          });
          dispatch(changeLoader(false));

          return { success: true };
        }
      } catch(error){
        scrollToTop(500);
        dispatch(changeLoader(false));
        dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
        return { success: false };
      }

};


