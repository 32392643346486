import React, { Component } from 'react';
import { images } from 'library/common/commonConstants/ImageConstants';
import './carousel.styles.scss';
import strings from 'resources/locales/Translate';
import 'bootstrap/dist/css/bootstrap.min.css'; 

export default class Carousel extends Component {

    componentDidMount(){
        const reloadCountPage = sessionStorage.getItem('reloadCountPage');
        // if(reloadCountPage < 1) {
        //     sessionStorage.setItem('reloadCountPage', String(reloadCountPage + 1));
        //     window.location.reload();
        //   } else {
        //     sessionStorage.removeItem('reloadCountPage');
        //   }
    }
    getImagesForBusinessUnit(unit) {
        const imagesData = {
            'MED': [
                images.MED_1_Image,
                images.MED_2_Image,
                images.MED_3_Image,
                images.MED_4_Image,
                images.MED_5_Image
            ],
            'IQS': [
                images.IQS_1_Image,
                images.IQS_2_Image,
                images.IQS_3_Image,
                images.IQS_4_Image,
            ],
            'RMS': [
                images.RMS_1_Image,
                images.RMS_2_Image,
                images.RMS_3_Image,
                images.RMS_4_Image,
                images.RMS_5_Image
            ],
        };

        return imagesData[unit] || imagesData['RMS'];
    }

    getRandomImages(images, count) {
        const shuffled = images.sort(() => 0.5 - Math.random());
        return shuffled.slice(0, count);
    }

    render() {
        const { uniqueBusinessUnits } = this.props;
        let imagesToShow = [];

        if (uniqueBusinessUnits.length === 1) {
            imagesToShow = this.getRandomImages(this.getImagesForBusinessUnit(uniqueBusinessUnits[0]), 5);
        } else if (uniqueBusinessUnits.length > 1) {
            const unit1Images = this.getRandomImages(this.getImagesForBusinessUnit(uniqueBusinessUnits[0]), 2);
            const unit2Images = this.getRandomImages(this.getImagesForBusinessUnit(uniqueBusinessUnits[1]), 2);
            imagesToShow = [...unit1Images, ...unit2Images];
            
            if (imagesToShow.length < 5) {
                const remainingImages = 5 - imagesToShow.length;
                const additionalImages = this.getRandomImages([...unit1Images, ...unit2Images], remainingImages);
                imagesToShow = [...imagesToShow, ...additionalImages];
            }
        } else{
            imagesToShow = this.getRandomImages(this.getImagesForBusinessUnit("IQS"), 3);
        }

        return (
            <div className="carousel-container">
                <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel" data-interval="3000" data-wrap={true} data-pause={false}>
                    <div className="carousel-inner">
                        {imagesToShow.map((image, index) => (
                            <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                <div className="image-wrapper">
                                    <img className="d-block" src={image} alt={`Slide ${index + 1}`} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='title-container'>
                    <h1>{strings.title}</h1>
                </div>
                <div className='description-homepage-container'>
                    <p className='description mb-3'>{strings.description}</p>
                </div>
            </div>
        );
    }
}
