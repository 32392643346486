import React, { useCallback, useEffect, useState, useRef } from 'react';
import { SchedulerData, ViewTypes } from 'react-big-scheduler';
import { CellUnits } from 'react-big-scheduler';
import { DATE_FORMAT } from 'library/common/commonComponents/ReactBigScheduler';
import moment from 'moment';
import { dayViewConfig, schedularConfig } from './config';
import cn from 'classnames';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import Icon from '../../../library/common/commonComponents/Icon/Icon';
import { scrollToDateOnCalendar } from 'library/utilities/scrollActions';

const PopoverItem = ({ eventItem }) => {
  const { type, commentOnly } = eventItem;
  let bgColor = type === 'type1' ? '#9056A3' : type === 'type2' ? '#CF3652' : '#F4B084';

  if (commentOnly) {
    return (
      <>
        <p style={{ fontSize: 14, fontWeight: 'bold' }} className='mb-2'>
          Gamescon
        </p>
        <b>Duration:</b>
        <p>2024-01-10 00:00 to 2024-01-12 24:00</p>
        <b>Event Information:</b>
        <p>
          Camescon in der Messe Köln. Hotelzimmer schwer zu bekommen und sehr teuer. Trainings & Demos nach Möglichkeit
          außerhalb dieser Zeit veranstalten.
        </p>
        <div className='d-flex align-items-center justify-content-end mt-3'>
          <button className='mt-3 align-self-end edit-button'>Edit</button>
        </div>
      </>
    );
  }

  return (
    <>
      <div className='d-flex align-items-center event-popover-container mb-2'>
        <div className='event-dot align-self-start' style={{ backgroundColor: bgColor }}></div>
        <div>
          <div>Journey & Shipping: 08:00 - 09:00</div>
          <div>Request #518168181684</div>
          <div>Status: Waiting for more information</div>
          <div>Test Customer 123</div>
        </div>
      </div>
      <div className='d-flex align-items-center event-popover-container'>
        <div className='event-dot align-self-start' style={{ backgroundColor: bgColor }}></div>
        <div>
          <div>Journey & Shipping: 08:00 - 09:00</div>
          <div>Request #518168181684</div>
          <div>Status: Waiting for more information</div>
          <div>Test Customer 123</div>
        </div>
      </div>
    </>
  );
};

const resources = [
  { id: 'comments', name: 'Comments', groupOnly: true },
  { id: 'r0', name: 'CC Oberkochen', title: 'CC Oberkochen', parentId: 'comments' },
  { id: 'r1', name: 'QEC Köln', title: 'QEC Köln', parentId: 'comments' },
  { id: 'systems', name: 'Systems', groupOnly: true },
  {
    id: '1234__location',
    name: 'CC Oberkochen',
    groupOnly: true,
    parentId: 'systems',
  },
  { id: 'r2', name: 'CONTURA 7/10/6', title: 'CONTURA 7/10/6', parentId: '1234__location' },
  { id: 'r3', name: 'CONTURA 12/18/10', title: 'CONTURA 12/18/10', parentId: '1234__location' },
  { id: 'r4', name: 'PRISMO 9/15/7', title: 'PRISMO 9/15/7', parentId: '1234__location' },
  { id: 'r5', name: 'O-INSPECT 5/4/3', title: 'O-INSPECT 5/4/3', parentId: '1234__location' },
  { id: 'r6', name: 'DURAMAX 5/5/5 RT', title: 'DURAMAX 5/5/5 RT', parentId: '1234__location' },
  {
    id: '1235__location',
    name: 'QEC Köln',
    groupOnly: true,
    parentId: 'systems',
  },
  { id: 'r7', name: 'PRISMO ultra', title: 'PRISMO ultra', parentId: '1235__location' },
  { id: 'r8', name: 'CONTURA', title: 'CONTURA', parentId: '1235__location' },
  { id: 'r9', name: 'O-INSPECT', title: 'O-INSPECT', parentId: '1235__location' },
  { id: 'equipment', name: 'Equipment', groupOnly: true },
  { id: 'r10', name: 'Demo Koffer Stator', title: 'Demo Koffer Stator', parentId: 'equipment' },
  { id: 'r11', name: 'Demo Koffer Medizin', title: 'Demo Koffer Medizin', parentId: 'equipment' },
  { id: 'other', name: 'Other', groupOnly: true },
  { id: 'r12', name: 'Test-other', title: 'Test Other', parentId: 'other' },
  { id: 'person', name: 'Person', groupOnly: true },
  { id: 'r13', name: 'Test-Person', title: 'Test Person', parentId: 'person' },
];

const events = [
  {
    id: 1,
    start: '2024-12-02 09:00:00',
    end: '2024-12-02 12:00:00',
    resourceId: 'r1',
    title: 'Meeting with Team 1',
    type: 'type1',
  },
  {
    id: 3,
    start: '2024-12-03  08:00:00',
    end: '2024-12-03  11:30:00',
    resourceId: 'r1',
    title: 'Meeting with Team',
    type: 'type2',
  },
  {
    id: 4,
    start: '2024-12-06 08:00:00',
    end: '2024-12-06 11:30:00',
    resourceId: 'r1',
    title: 'Meeting with Team 3',
    type: 'type3',
  },
  {
    id: 5,
    start: '2024-12-06 08:00:00',
    end: '2024-12-06 09:00:00',
    resourceId: 'r2',
    title: 'Meeting with Team 3',
    type: 'type3',
    commentOnly: true,
  },
];

let groupOnlySlots = ['comments', 'systems', 'equipment', 'other', 'person'];

const DAY_VIEW_START = '00:00';
const DAY_VIEW_END = '23:00';
const WEEK_VIEW_START = '06:00';
const WEEK_VIEW_END = '18:00';
const NON_WORKING_HOURS_BEFORE = '07:00';
const NON_WORKING_HOURS_AFTER = '17:00';
const LOCATION_GROUPING_SUFFIX = '__location';

/**
 * Determines the background color for a time slot in the scheduler.
 * This function checks if the slot represents a group header, a location header, or a regular time slot.
 * It then applies different background colors based on these categories and whether the time slot falls within working hours.
 */
const renderNonWorkingHoursBackgroundColor = (_, slotId, header) => {
  if (groupOnlySlots.includes(slotId)) {
    return '#6AB0E2';
  } else if (slotId?.includes(LOCATION_GROUPING_SUFFIX)) {
    return '#C6DAF24D';
  }
  const itemTime = moment(header.time, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
  const nonWorkingStart = moment(NON_WORKING_HOURS_BEFORE, 'HH:mm');
  const nonWorkingEnd = moment(NON_WORKING_HOURS_AFTER, 'HH:mm');
  const currentTime = moment(itemTime, 'HH:mm');
  if (currentTime.isBetween(nonWorkingStart, nonWorkingEnd, 'h', '[]')) {
    return '#fff';
  } else {
    return '#DCE3E9';
  }
};

/**
 *  How ViewTypes are mapped in this schedular?
 *  ViewTypes.Custom: Week View
 *  ViewTypes.Custom1: Day View
 *  Refer: config.js for cell width and other configuations
 */
export const useAppSchedular = () => {
  const [calendarConfig, setcalendarConfig] = useState();
  const [selectedViewType, setSelectedViewType] = useState(ViewTypes.Custom);
  const [hiddenResourceGroups, setHiddenResourceGroups] = useState([]);
  const schedulerRef = useRef();
  const [selectedDate, setSelectedDate] = useState(moment().format(DATE_FORMAT));

  useEffect(() => {
    setCalendarData(selectedViewType);
    return () => {};
  }, [selectedDate, hiddenResourceGroups]);

  useEffect(() => {
    document.body.classList.add('iqs-calendar-active');
    if (resources && resources.length) {
      setTimeout(() => {
        const schedularBgTableRowsRef = document.querySelectorAll('.scheduler-bg-table tbody tr');
        if (schedularBgTableRowsRef && schedularBgTableRowsRef.length) {
          schedularBgTableRowsRef.forEach((row, index) => {
            row.setAttribute('data-id', resources[index]?.id || '');
          });
        }
        scrollToDateOnCalendar(new Date());
      }, 0);
    }
    return () => {
      document.body.classList.remove('iqs-calendar-active');
    };
  }, [resources]);

  const setCalendarData = viewType => {
    let nonHiddenResources = resources.filter(item => !hiddenResourceGroups.includes(item.parentId)); //Should be done recursively
    let config = { ...schedularConfig };
    if (viewType === ViewTypes.Custom1) {
      config = { ...config, ...dayViewConfig };
    }
    let schedulerData1 = new SchedulerData(
      selectedDate,
      viewType,
      false,
      false,
      { ...config },
      {
        getCustomDateFunc: getCustomDate,
        nonAgendaDayCellHeaderRenderer: renderDayHeader,
        getNonAgendaViewBodyCellBgColorFunc: renderNonWorkingHoursBackgroundColor,
        scrollToSpecialMomentEnabled: true,
      },
    );

    schedulerData1.setResources(nonHiddenResources);
    schedulerData1.setEvents(events);
    schedulerData1.setScrollToSpecialMoment(() => {
      return moment().startOf('day').add(6, 'hours');
    });
    setcalendarConfig(schedulerData1);
  };

  const getCustomDate = (schedulerData, num, date = undefined) => {
    const { viewType } = schedulerData;
    let selectDate = schedulerData.startDate;
    if (date != undefined) selectDate = date;

    let startDate =
        num === 0
          ? selectDate
          : schedulerData
              .localeMoment(selectDate)
              .add(1 * num, 'days')
              .format(DATE_FORMAT),
      endDate = schedulerData.localeMoment(startDate).add(1, 'days').format(DATE_FORMAT),
      cellUnit = CellUnits.Hour;

    if (viewType === ViewTypes.Custom) {
      let monday = schedulerData.localeMoment(selectDate).startOf('week').day(1);
      startDate = num === 0 ? monday : monday.clone().add(1 * num, 'weeks');
      endDate = startDate.clone().add(4, 'days');
      cellUnit = CellUnits.Hour;
    } else if (viewType === ViewTypes.Custom1) {
      startDate = schedulerData.localeMoment(selectDate);
      endDate = schedulerData.localeMoment(selectDate);
      cellUnit = CellUnits.Hour;
    }
    return {
      startDate,
      endDate,
      cellUnit,
    };
  };

  /**
   * This function is called once for each header by package
   * Ex: 2024-11-01 06:00, 2024-11-01 06:30 etc.
   * We need to modify how the header render using this function
   * We have used margin and borders and css position to get desired header.
   */
  const renderDayHeader = (schedulerData, item) => {
    const date = moment(item.time).format('dddd, DD MMMM YYYY');
    const itemTime = moment(item.time, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
    const isStart = itemTime === (selectedViewType === ViewTypes.Custom1 ? DAY_VIEW_START : WEEK_VIEW_START);
    const isEnd = itemTime === (selectedViewType === ViewTypes.Custom1 ? DAY_VIEW_END : WEEK_VIEW_END);
    const isToday = moment(item.time).isSame(moment(), 'day');
    const customCellWidth = schedularConfig.customCellWidth;
    const currentTime = moment(itemTime, 'HH:mm');
    const nonWorkingStart = moment(NON_WORKING_HOURS_BEFORE, 'HH:mm');
    const nonWorkingEnd = moment(NON_WORKING_HOURS_AFTER, 'HH:mm');
    const nonWorking = currentTime.isBetween(nonWorkingStart, nonWorkingEnd, 'h', '[]');

    return (
      <th
        className='header3-text'
        style={{
          width: customCellWidth * 2,
          border: 'none',
          borderLeft: isStart ? '1px solid #e0e1dd' : 'none',
        }}
      >
        {isStart && (
          <div className={cn('slot-header-date', { 'header-today-text': isToday })}>
            <span>{date}</span>
          </div>
        )}
        <div
          className='d-flex justify-content-center align-items-center header-week-view-time'
          style={{
            borderRight: !isEnd ? '1px solid #e0e1dd' : 'none',
            backgroundColor: nonWorking ? '#fff' : '#DCE3E9',
          }}
        >
          <span>{moment(item.time, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}</span>
        </div>
      </th>
    );
  };

  const prevClick = e => {
    if (selectedViewType === ViewTypes.Custom1) {
      setSelectedDate(date => moment(date).subtract(1, 'day').format(DATE_FORMAT));
      return;
    }
    setSelectedDate(date => moment(date).subtract(1, 'week').startOf('week').format(DATE_FORMAT));
  };

  const nextClick = e => {
    if (selectedViewType === ViewTypes.Custom1) {
      setSelectedDate(date => moment(date).add(1, 'day').format(DATE_FORMAT));
      return;
    }
    setSelectedDate(date => moment(date).add(1, 'week').startOf('week').format(DATE_FORMAT));
  };

  const todayClick = e => {
    setSelectedDate(moment().format(DATE_FORMAT));
  };

  const onSelectDate = date => {
    if (selectedViewType === ViewTypes.Custom1) {
      setSelectedDate(moment(date).format(DATE_FORMAT));
      return;
    }
    setSelectedDate(moment(date).format(DATE_FORMAT));
  };

  const onViewChange = viewType => {
    setSelectedViewType(viewType);
    setCalendarData(viewType);
  };

  const eventClicked = e => {
    console.log(e);
  };

  const onChangeCheckbox = () => {
    //Todo functionality
  };

  const slotTempletResolver = (schedulerData, slot, slotClickedFunc, width, clsName) => {
    const isGrouping = groupOnlySlots.includes(slot.slotId) || slot?.slotId?.includes(LOCATION_GROUPING_SUFFIX);
    return (
      <div className='d-flex align-items-center w-100'>
        {isGrouping ? (
          <span className='mr-3 cusor-pointer' onClick={() => toggleExpandFunc(schedulerData, slot.slotId)}>
            <Icon
              name={'arrow'}
              width={20}
              height={20}
              fill={'#000'}
              className={cn({
                'chevron-down': !hiddenResourceGroups.includes(slot.slotId),
                'chevron-up': hiddenResourceGroups.includes(slot.slotId),
              })}
            />
          </span>
        ) : null}
        <div className='d-flex justify-content-between align-items-center w-100'>
          <div className='d-flex'>
            {!isGrouping && (
              <Checkbox
                field={slot.slotId}
                onChange={onChangeCheckbox}
                small
                newuiVersionStyle={true}
                className={'resource-table-checkbox'}
              />
            )}
            <p style={{ fontSize: isGrouping ? 14 : 12, fontWeight: isGrouping ? 600 : 'normal' }}>{slot.slotName}</p>
          </div>

          {/* Information Icon */}
          {!isGrouping && (
            <div className='info-icon cursor-pointer mr-3'>
              <span>i</span>
              <div className='tooltip-content'>
                <div class='card'>
                  {/* <h2>PRISMO ultra</h2> */}
                  <div class='details'>
                    <p className='mb-3'>
                      <strong>Type:</strong> System
                    </p>
                    <p className='mb-3'>
                      <strong>Component:</strong> PRISMO 12/18/10 ultra
                    </p>
                    <p className='mb-3'>
                      <strong>Serial Number:</strong> XXX
                    </p>
                    <p className='mb-3'>
                      <strong>Inventory Number:</strong> XXX
                    </p>
                    <p className='mb-3'>
                      <strong>Date of Manufacture:</strong> TT.MM.JJJJ
                    </p>
                    <p className='mb-3'>
                      <strong>Last Maintenance:</strong> TT.MM.JJJJ
                    </p>
                  </div>
                  <div className='d-flex justify-content-end'>
                    <button class='view-resource'>View Resource</button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const hideRecursively = (resources, parentId) => {
    let itemsToHide = [parentId];
    resources.forEach(resource => {
      if (resource.parentId === parentId) {
        itemsToHide = [...itemsToHide, ...hideRecursively(resources, resource.id)];
      }
    });
    return itemsToHide;
  };

  const toggleExpandFunc = useCallback(
    (schedularData, slotId) => {
      const { resources } = schedularData;
      const hiddenSet = new Set(hiddenResourceGroups);
      const resourcesToHide = hideRecursively(resources, slotId);
      if (hiddenSet.has(slotId)) {
        hiddenSet.delete(slotId);
        resourcesToHide.forEach(resourceId => hiddenSet.delete(resourceId));
      } else {
        hiddenSet.add(slotId);
        resourcesToHide.forEach(resourceId => hiddenSet.add(resourceId));
      }
      setHiddenResourceGroups([...hiddenSet]);
      setCalendarData(selectedViewType);
    },
    [hiddenResourceGroups, setHiddenResourceGroups, setCalendarData],
  );

  const slotItemPopover = (schedulerData, eventItem, title, start, end, statusColor) => {
    return <PopoverItem eventItem={eventItem} />;
  };

  return {
    calendarConfig,
    prevClick,
    nextClick,
    onSelectDate,
    onViewChange,
    eventClicked,
    renderDayHeader,
    selectedViewType,
    slotTempletResolver,
    toggleExpandFunc,
    schedulerRef,
    todayClick,
    selectedDate,
    slotItemPopover,
  };
};
