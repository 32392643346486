import { connect } from 'react-redux';

import MonthBlock from './MonthBlock.component';

const mapStateToProps = ({ languageReducer, stockDashboardReducer }) => ({
  language: languageReducer,
  thisMonthStatistics: stockDashboardReducer.thisMonthStatistics,
});

export default connect(mapStateToProps)(MonthBlock);
