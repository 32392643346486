import { connect } from 'react-redux';

import { getStockByCountry, getStatistics } from '../../StockDashboard.actions';
import StatisticsTab from './StatisticsTab.component';

const mapStateToProps = ({ authReducer, languageReducer, stockDashboardReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
  outgoingStatistics: stockDashboardReducer.outgoingStatistics,
  incomingStatistics: stockDashboardReducer.incomingStatistics,
  loanAvailabilityByStocks: stockDashboardReducer.loanAvailabilityByStocks,
});

export default connect(mapStateToProps, { getStatistics, getStockByCountry })(StatisticsTab);
