import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import * as actionTypes from 'library/common/commonConstants/storeConstants';
import {
  fetchMasterDataService,
  fetchMasterDataServicePost,
  fetchMasterDataServicePut,
  deleteMasterDataService,
} from 'library/api/master-data.service';
import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { categoriesListRequestJson } from 'library/api/endpoint-request-json';
import { scrollToTop } from 'library/utilities/scrollActions';
import { FETCH_MULTIPLE_ADDRESS_LIST } from './Components/AddressConfigration/AddressConfigurationList.constants';
import { FETCH_MED_ADDRESS_LIST } from './Components/MedUsWarehouseAddress/MedUsWarehouseAddress.constants';
import { batchActions } from 'redux-batched-actions';

export const getInitialData = businessGroup => async (dispatch, getState) => {
  const { businessUnit } = getState().masterDataReducer;

  if (businessUnit && !businessUnit.length) {
    await dispatch(fetchMasterData(URLS.businessUnitList, actionTypes.FETCH_BUSINESS_UNIT_LIST));
  }

  await dispatch(fetchMasterData(URLS.categoryList, actionTypes.FETCH_CATEGORY_LIST, categoriesListRequestJson));
  await dispatch(fetchMasterData(URLS.countryList, actionTypes.FETCH_COUNTRY_LIST));

  if (businessGroup) {
    await dispatch(fetchMasterData(URLS.productHierarchy(businessGroup), actionTypes.FETCH_PRODUCT_HIERARCHY));
  }
};

export const saveMasterData = (url, data, type) => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const res = await fetchMasterDataServicePost(url, data);

    if (res.status === 201 || res.status === 200) {
      dispatch(toggleActionMessage(true, 'success', 'masterDataSavedSuccessfully'));
    }

    dispatch(changeLoader(false));
  } catch (err) {
    dispatch(changeLoader(false));

    if (err && err.response && err.response.status !== 404) {
      return dispatch(toggleActionMessage(true, 'error', 'errorOccuredText'));
    }

    if (type === 'systemClass') {
      dispatch(toggleActionMessage(true, 'error', 'systemClassAlreadyExist'));
    } else if (type === 'systemMainComponent') {
      dispatch(toggleActionMessage(true, 'error', 'systemComponentAlreadyExist'));
    } else if (type === 'loanType') {
      dispatch(toggleActionMessage(true, 'error', 'loanTypeAlreadyExist'));
    } else {
      dispatch(toggleActionMessage(true, 'error', 'countryAlreadyExist'));
    }
  }
};

export const saveAddressConfigration = dataToSend => async dispatch => {
  try {
    await fetchMasterDataServicePost(URLS.addAddressConfigration, dataToSend);

    scrollToTop(500);
    dispatch(toggleActionMessage(true, 'success', 'valuesSavedSuccessMessage'));
    dispatch(addAddressconfigrationList());
    return { success: true };
  } catch (err) {
    return { success: false, err };
  }
};

export const addAddressconfigrationList = () => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.addressConfigrationList);

    dispatch({
      type: FETCH_MULTIPLE_ADDRESS_LIST,
      payload: data,
    });
    return { success: true };
  } catch (error) {
    return { success: false, error };
  }
};

export const enableAndDisableConfig = (id, activated) => async dispatch => {
  try {
    const dataToSend = {
      id: id,
      activated: !activated,
    };
    dispatch(changeLoader(true));
    const { status } = await fetchMasterDataServicePut(URLS.enablingAddAddress(id), dataToSend);
    if (status === 204) {
      dispatch(addAddressconfigrationList());
    }
    dispatch(changeLoader(false));
    return { success: true };
  } catch (error) {
    dispatch(changeLoader(false));
    return { success: false };
  }
};

export const deletingConfigration = id => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const { status } = await deleteMasterDataService(URLS.deleteConfigration(id));
    if (status === 204) {
      dispatch(addAddressconfigrationList());
    }
    dispatch(changeLoader(false));
    return { success: true };
  } catch (err) {
    dispatch(changeLoader(false));
    scrollToTop(500);
    if (err && err.response && err.response.data && err.response.data.status === 422) {
      dispatch(toggleActionMessage(true, 'error', err.response.data.message));
    }
    return { success: false };
  }
};

export const handlingDataToSend = data => {
  const {
    filter: { searchTerm },
  } = data;
  const filter = [];
  const sort = ['meditechUsAddressId', 'desc'];
  const sendData = { ...data };

  if (searchTerm) {
    let searchArray = [];
    searchArray.push(`salutation:cic:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`firstName:cic:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`lastName:cic:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`organization:cic:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`country.name:cic:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`department:cic:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`postalCode:eq:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`houseNumber:eq:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`street:cic:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`city:cic:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`state:cic:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`phone:cic:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`email:cic:${searchTerm}`);
    filter.push(searchArray);
  }

  sendData.filter = filter;
  sendData.sort = sort;
  return sendData;
};

export const medUsAddress = data => async dispatch => {
  const dataToSend = handlingDataToSend(data);
  try {
    dispatch(changeLoader(true));
    const { data } = await fetchMasterDataServicePost(URLS.medUsWarehouseAddress, dataToSend);
    dispatch({
      type: FETCH_MED_ADDRESS_LIST,
      payload: data,
    });
    dispatch(changeLoader(false));
    return { success: true };
  } catch (error) {
    dispatch(changeLoader(false));
    dispatch({
      type: FETCH_MED_ADDRESS_LIST,
      payload: {},
    });
    return { success: false };
  }
};

export const editMedUsAddress = (dataToSend, warehouseAddressId, data) => async dispatch => {
  try {
    const { status } = await fetchMasterDataServicePut(URLS.editOrDeleteMedAddress(warehouseAddressId), dataToSend);
    if (status === 200) {
      dispatch(
        batchActions([
          dispatch(medUsAddress(data)),
          dispatch(toggleActionMessage(true, 'success', 'valuesSavedSuccessMessage')),
        ]),
      );
    }
    return { success: true };
  } catch (error) {
    dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    return { success: false };
  }
};

export const deleteMedAddress = (warehouseAddressId, data) => async dispatch => {
  try {
    dispatch(changeLoader(true));
    await deleteMasterDataService(URLS.editOrDeleteMedAddress(warehouseAddressId));
    dispatch(changeLoader(false));
    dispatch(
      batchActions([
        dispatch(medUsAddress(data)),
        dispatch(toggleActionMessage(true, 'success', 'addressDeletedSuccessfully')),
      ]),
    );
    return { success: true };
  } catch (err) {
    dispatch(changeLoader(false));
    dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    scrollToTop(500);
    return { success: false };
  }
};
