import { connect } from 'react-redux';

import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';

import { getStatistics, resetStatistics } from '../../Shop.actions';
import Statistics from './Statistics.component';

const mapStateToProps = ({ authReducer, languageReducer, masterDataReducer, appActionsReducer, shopListReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
  appActions: appActionsReducer,
  countries: masterDataReducer.countries,
  statisticsData: shopListReducer.statisticsData,
});

export default connect(mapStateToProps, { fetchMasterData, getStatistics, resetStatistics })(Statistics);
