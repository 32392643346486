import {DELIVERY_ADDRESSES_FETCH_LIST, POPULATE_DELIVERY_ADDRESS_FORM} from "./deliveryAddressesConstants";
import {AddressFormModel} from "modules/DeliveryAddresses/components/CreateDeliveryAddressForm/address-form-model";

const initialState = {
  addresses: [],
  addressForm: AddressFormModel
};

const DeliveryAddressesReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELIVERY_ADDRESSES_FETCH_LIST:
      return {
        ...state,
        addresses: action.payload
      };
    case POPULATE_DELIVERY_ADDRESS_FORM:
      return {
        ...state,
        addressForm: action.payload
      };
    default:
      return state;
  }
};

export default DeliveryAddressesReducer;
