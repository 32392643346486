import React from 'react';
import cn from 'classnames';

import strings from 'resources/locales/Translate';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';

import { MONTH } from '../../Statistics.constants';
import { useStatisticsFilter } from './StatisticsFilter.hook';

const StatisticsFilter = ({
  isShowFilter,
  countries,
  filter,
  setFilter,
  getStatistics,
  resetStatistics,
}) => {
  const isMonthsTimePeriod = filter.timePeriod === MONTH;
  const {
    timePeriodOptions,
    yearsOptions,
    monthsOptions,
    handleFilter,
  } = useStatisticsFilter({ setFilter, filter, getStatistics, resetStatistics });

  return (
    <div>
      <div className={cn('filter-wrapper', { 'd-block': isShowFilter, 'd-none': !isShowFilter })}>
        <div className='container-fluid'>
          <div className='row'>
            <Dropdown
              data={countries}
              hasSection
              filter
              selectAllOption
              multiSelect={true}
              selectAllPlaceholder='selectAll'
              idKey='id'
              field='stock'
              displayValue={[{ key: 'locationName', separator: '' }]}
              optionsArrayKey='stockInformation'
              titleDisplay={[
                { key: 'shortName', separator: ' ' },
                { key: 'name', separator: '' },
              ]}
              isRequired={true}
              placeholder={strings.pleaseSelect + '...'}
              label={strings.location}
              onChange={handleFilter}
              mainContainerStyle='col-md'
              value={filter.stock}
              fullValue={filter.stockFullValue}
            />
            <Dropdown
              data={countries}
              filter
              selectAllOption
              multiSelect={true}
              selectAllPlaceholder='selectAll'
              idKey='id'
              field='country'
              displayValue={[{ key: 'name', separator: '' }]}
              isRequired={true}
              placeholder={strings.pleaseSelect + '...'}
              label={strings.countryLand}
              onChange={handleFilter}
              mainContainerStyle='col-md'
              value={filter.country}
              fullValue={filter.countryFullValue}
            />
            <Dropdown
              data={timePeriodOptions}
              idKey='id'
              field='timePeriod'
              displayValue={[{ key: 'value', separator: '' }]}
              isRequired={true}
              placeholder={strings.pleaseSelect + '...'}
              label={strings.timePeriod}
              onChange={handleFilter}
              mainContainerStyle='col-md'
              value={filter.timePeriod}
              fullValue={filter.timePeriodFullValue}
            />
            <Dropdown
              data={yearsOptions}
              idKey='id'
              field='year'
              multiSelect={!isMonthsTimePeriod}
              displayValue={[{ key: 'value', separator: '' }]}
              isRequired={true}
              placeholder={strings.pleaseSelect + '...'}
              label={strings.years}
              onChange={handleFilter}
              mainContainerStyle='col-md'
              value={filter.year}
              fullValue={filter.yearFullValue}
              error={filter.yearError}
              disabled={!filter.timePeriod}
            />
            {isMonthsTimePeriod && (
              <Dropdown
                data={monthsOptions}
                idKey='id'
                field='month'
                multiSelect={true}
                displayValue={[{ key: 'value', separator: '' }]}
                isRequired={true}
                placeholder={strings.pleaseSelect + '...'}
                label={strings.months}
                onChange={handleFilter}
                mainContainerStyle='col-md'
                value={filter.month}
                fullValue={filter.monthFullValue}
                error={filter.monthError}
                disabled={!filter.year}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatisticsFilter;
