import { combineReducers } from 'redux';

import AuthReducer from '../library/common/commonReducers/AuthReducer';
import LanguageReducer from '../library/common/commonReducers/LanguageReducer';
import MasterDataReducer from '../library/common/commonReducers/MasterDataReducer';
import CreateStockReducer from 'modules/Stock/CreateStock/CreateStock.reducer';
import AppActionsReducer from '../library/common/commonReducers/AppActionsReducer';
import NotificationsReducer from '../library/common/commonReducers/NotificationsReducer';
import CreateKitReducer from 'modules/Kits/CreateKit/CreateKit.reducer';
import DocumentsReducer from 'modules/Documents/Documents.reducer';
import RequestStockPermissionReducer from 'modules/Stock/StocksList/StocksListFrames/RequestStockPermission/RequestStockPermissionReducers';
import StocksListReducer from 'modules/Stock/StocksList/StocksListReducer';
import KitsListReducer from 'modules/Kits/KitsList/KitsList.reducer';
import KitDetailsReducer from 'modules/Kits/KitDetails/KitDetailsReducer';
import HomepageReducer from 'modules/Homepage/HomepageReducer';
import CalendarReducer from 'modules/Calendar/CalendarReducer';
import LoanListReducer from 'modules/LoanList/LoanListReducer';
import LoanAttachmentsReducer from 'library/common/commonReducers/LoanAttachmentsReducer';
import DeliveryAddressesReducer from 'modules/DeliveryAddresses/DeliveryAddressesReducer';
import transactionDetailsReducer from 'modules/Transactions/TransactionDetails/TransactionDetailsReducer';
import BookingReducer from 'modules/Booking/BookingReducer';
import AdministrationReducer from 'modules/Administration/MasterData';
import c2cLoansReducer from 'modules/Transactions/C2CLoans/C2CLoansReducer';
import ShopListReducer from 'modules/DemoSale/Shop/Shop.reducer';
import KitForSaleDetailsReducer from 'modules/DemoSale/KitForSaleDetails/KitForSaleDetails.reducer';
import CreateKitForSaleReducer from 'modules/DemoSale/CreateKitForSale/CreateKitForSale.reducer';
import stockDashboardReducer from 'modules/Administration/StockDashboard/StockDashboard.reducer';
import statisticsReducer from 'modules/Administration/Statistics/Statistics.reducer';
import KitLoanReducer from 'modules/KitLoan/KitLoan.reducer';
import NotificationSettingsReducer from 'modules/NotificationSettings/NotificationSettings.reducer';
import PermissionsReducer from 'modules/DUSD/Permissions/Permissions.reducer';
import DusdTaskReducer from 'modules/DUSD/DusdTask/DusdTask.reducer';
import SubscriptionsReducer from 'modules/DUSD/Subscriptions/Subscriptions.reducer';
import StatusListReducer from 'modules/Administration/StatusList/StatusList.reducer';
import interfaceMonitoringReducer from 'modules/Administration/InterfaceMonitoring/InterfaceMonitoring.reducer';
import userPermissionsReducer from 'modules/Administration/UserPermissions/UserPermissions.reducer';
import kitPicturesReducer from 'modules/Administration/ManageKitPictures/ManageKitPictures.reducer';
import createLLKitReducer from 'modules/Kits/CreateLLKit/CreateLLKit.reducer';
import createZmccKitReducer from 'modules/Kits/CreateZmccKit/CreateZmccKit.reducer';
import llKitLoanReducer from 'modules/LLKiLoan/LLKiLoan.reducer';
import llManagementReducer from 'modules/Administration/LLManagement/LLManagement.reducer';
import activeSystemsReducer from 'modules/DUSD/ActiveSystems/ActiveSystems.reducer';
import SapErpMappingReducer from 'modules/Administration/SapErpMappingTable/SapErpMapping.reducer';
import RefurbushmentFeesReducer from 'modules/Administration/RefurbishmentFees/RefurbishmentFees.reducer';
import MultipleAddressConfigurationList from 'modules/Administration/MasterData/Components/AddressConfigration/AddressConfigurationList.reducer';
import CreateSEMFormReducer from 'modules/ZmccRequest/SEMBooking/SemBooking.reducer';
import ZmccTransactionDetailsReducer from 'modules/ZmccTransactions/ZmccTransactionDetails/ZmccTransactionDetails.reducer';
import MedUsWarehouseAddressReducer from 'modules/Administration/MasterData/Components/MedUsWarehouseAddress/MedUsWarehouseAddress.reducer';

export default combineReducers({
  authReducer: AuthReducer,
  languageReducer: LanguageReducer,
  masterDataReducer: MasterDataReducer,
  createStockReducer: CreateStockReducer,
  appActionsReducer: AppActionsReducer,
  createKitReducer: CreateKitReducer,
  stocksListReducer: StocksListReducer,
  kitsListReducer: KitsListReducer,
  documentsReducers: DocumentsReducer,
  requestStockPermissionReducer: RequestStockPermissionReducer,
  kitDetailsReducer: KitDetailsReducer,
  homepage: HomepageReducer,
  calendar: CalendarReducer,
  loanList: LoanListReducer,
  deliveryAddresses: DeliveryAddressesReducer,
  loanAttachmentsReducer: LoanAttachmentsReducer,
  transactionDetailsReducer,
  bookingReducer: BookingReducer,
  administrationReducer: AdministrationReducer,
  shopListReducer: ShopListReducer,
  kitForSaleDetailsReducer: KitForSaleDetailsReducer,
  createKitForSaleReducer: CreateKitForSaleReducer,
  kitLoanReducer: KitLoanReducer,
  stockDashboardReducer,
  notificationSettingsReducer: NotificationSettingsReducer,
  permissionsReducer: PermissionsReducer,
  dusdTaskReducer: DusdTaskReducer,
  subscriptionsReducer: SubscriptionsReducer,
  notificationsReducer: NotificationsReducer,
  statusListReducer: StatusListReducer,
  statisticsReducer,
  interfaceMonitoringReducer,
  userPermissionsReducer,
  kitPicturesReducer,
  createLLKitReducer,
  createZmccKitReducer,
  llKitLoanReducer,
  llManagementReducer,
  c2cLoansReducer,
  activeSystemsReducer,
  sapErpMappingReducer: SapErpMappingReducer,
  refurbushmentFeesReducer: RefurbushmentFeesReducer,
  multipleAddressConfigurationList: MultipleAddressConfigurationList,
  createSEMFormReducer : CreateSEMFormReducer,
  zmccTransactionDetailsReducer: ZmccTransactionDetailsReducer,
  medUsAddressReducer: MedUsWarehouseAddressReducer,
});
