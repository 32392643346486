export const cancellationModel = [
  {
    label: 'why',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'reason',
    validators: [],
    required: false,
    styleClass: 'col-12',
  },
];
