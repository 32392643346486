import { connect } from 'react-redux';

import { updateDateOfCartItem } from '../../../LoanList/LoanListActions';
import {
  updateForm,
  setHelpForBuildingAndDismantling,
  setOpportunity,
  removeOpportunity,
  setOpportunities,
  createBooking,
  updateOperatorId,
  updateTypesOfJobs,
  fetchRequestors,
  addAttachmentFile,
  removeAttachmentFile,
} from '../../LLKiLoan.actions';
import Confirmation from './Confirmation.component';
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';

const mapStateToProps = ({ authReducer, languageReducer, loanList, llKitLoanReducer, masterDataReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
  selectedKits: loanList.cart.filter(item => (item.isLabsLocation === true) && (item.isSelected === true)),
  loanReasons: llKitLoanReducer.loanReasons,
  descriptionForm: llKitLoanReducer.descriptionForm,
  loansTypesOfJobs: llKitLoanReducer.loansTypesOfJobs,
  typesOfJobsId: llKitLoanReducer.typesOfJobsId,
  operatorId: llKitLoanReducer.operatorId,
  borrowerForm: llKitLoanReducer.borrowerForm,
  typeOfDeliveryAddressForm: llKitLoanReducer.typeOfDeliveryAddressForm,
  deliveryAddressForm: llKitLoanReducer.deliveryAddressForm,
  userList: masterDataReducer.usersList,
  countries: masterDataReducer.countries,
  addresses: llKitLoanReducer.addresses,
  opportunities: llKitLoanReducer.opportunities,
  helpForBuildingAndDismantling: llKitLoanReducer.helpForBuildingAndDismantling,
  customerForm: llKitLoanReducer.customerForm,
  attachments: llKitLoanReducer.attachments,
  nameOfOperatorForm:llKitLoanReducer.nameOfOperatorForm,
  nameOfPartnerOperatorForm: llKitLoanReducer.nameOfPartnerOperatorForm,
  newLabsLocationTypesOfJob: llKitLoanReducer.newLabsLocationTypesOfJob,
});
export default connect(mapStateToProps, {
  updateDateOfCartItem,
  setHelpForBuildingAndDismantling,
  setOpportunity,
  removeOpportunity,
  setOpportunities,
  updateForm,
  createBooking,
  updateOperatorId,
  updateTypesOfJobs,
  fetchRequestors,
  addAttachmentFile,
  removeAttachmentFile,
  toggleActionMessage
})(Confirmation);
