import React, {PureComponent} from 'react';
import {connect} from 'react-redux';

import Icon from "library/common/commonComponents/Icon/Icon";
import strings from 'resources/locales/Translate';

import './alternateSlotMessageStyles.scss';

const colors = {
  error: '#cc0000',
  success: '#4ab300',
  info: '#0088d0'
};

class AlternateSlotMessage extends PureComponent {
  render() {
    const {from, to, onClose} = this.props;

    return (
      <div className="action-message-container" style={{backgroundColor: colors['info']}}>
        <div className="container-fluid text-white d-flex justify-content-between align-items-center">
          <div>
            <Icon name='info' width={16} height={16} fill='#fff' className='action-icon'/>
            <span>{strings.formatString(strings.suggestAlternateMessage, {from: from, to: to})}</span>
          </div>
          <div>
            <button className="close-message p-0" onClick={() => onClose()}>
              <Icon name='cross' width={20} height={20} fill='#fff' className='action-icon p-0'/>
            </button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    language: state.languageReducer
  }
};

export default connect(mapStateToProps)(AlternateSlotMessage);
