import React from 'react';

import Icon from 'library/common/commonComponents/Icon/Icon';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';

import './RequestAccess.styles.scss';
import { requestAccessModel } from './RequestAccess.constants';

export const RequestAccess = ({ canEditStock, onChange, onAcceptReject, roles, value }) => {
  const requestModel = () => {
    const model = [...requestAccessModel];
    model[1].options = roles;
    return model;
  };

  return (
    <div className='request-access-form row' data-test='request-access-form'>
      <div className={canEditStock ? 'col-md-8' : 'col-md-12'}>
        <FormComponent disableForm={!canEditStock} model={requestModel()} formValue={value} onChange={onChange} />
      </div>
      {canEditStock && value.requestAccessStatus === 'pending' && (
        <div className='col-sm-4'>
          <div className='accept-reject-buttons'>
            <button className='accept-button' onClick={onAcceptReject('approved')}>
              <Icon name='check' width={24} height={24} fill='#fff' />
            </button>
            <button className='reject-button' onClick={onAcceptReject('rejected')}>
              <Icon name='cross' width={24} height={24} fill='#0088d0' />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RequestAccess;
