import React, { useState } from 'react';

import strings from 'resources/locales/Translate';
import Button from 'library/common/commonComponents/Button/Button';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import IconButton from 'library/common/commonComponents/IconButton';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import FilesListView from 'library/common/commonComponents/FileList/FilesListView';
import FileUploader from 'library/common/commonComponents/FileUploader/FileUploader';
import AddressInfo from 'library/common/commonComponents/AddressInfo/AddressInfo';
import { getSelectedCountry } from 'library/utilities/getSelectedValues';

import OpportunityForm from '../../../KitLoan/Components/OpportunityIds';
import SelectedKits from '../../../KitLoan/Components/SelectedKits';
import { customerModel, nameOfOperatorModal, operatorModel } from '../../LLKiLoan.constants';
import { useLLKitLoanModel } from '../../LLKitLoanModel.hook';

const Confirmation = props => {
  const {
    borrowerForm,
    typeOfDeliveryAddressForm,
    descriptionForm,
    selectedKits,
    updateDateOfCartItem,
    deliveryAddressForm,
    countries,
    opportunities,
    enteredOpportunityId,
    handleEnteredOpportunityId,
    setOpportunity,
    removeOpportunity,
    setOpportunities,
    isOpportunityIdCrmError,
    setIsOpportunityIdCrmError,
    customerForm,
    updateOperatorId,
    helpForBuildingAndDismantling,
    typesOfJobsId,
    operatorId,
    updateTypesOfJobs,
    attachments,
    addAttachmentFile,
    removeAttachmentFile,
    isShowCheckbox,
    loansTypesOfJobs,
    nameOfOperatorForm,
    nameOfPartnerOperatorForm,
    newLabsLocationTypesOfJob
  } = props;
  const {
    descriptionModel,
    requestorModel,
    typeDeliveryAddressModel,
    customerSiteModel,
    updateShortDescription,
    operatorNameModel,
    nameOfPartnerOperatorModel,
    handleForm,
    goToPrevStep,
    handleHelpForBuildingAndDismantling,
    handleBookingProccess,
    goToCalendar,
  } = useLLKitLoanModel({ ...props });
  const [isEditDescription, setIsEditDescription] = useState(false);
  const [isEditAttachments, setIsEditAttachments] = useState(false);
  const [isEditRequestor, setIsEditRequestor] = useState(false);
  const [isEditTypeOfDeliveryAddress, setIsEditTypeOfDeliveryAddress] = useState(false);
  const [isEditDeliveryAddress, setIsEditDeliveryAddress] = useState(false);
  const selectedReason = descriptionForm.formData.reasonForLoanIdFullValue && descriptionForm.formData.reasonForLoanIdFullValue;

  const renderDescription = () => {
    const operator = operatorModel.find(item => item.id === operatorId);

    const selectednameOfOperator = nameOfOperatorForm.formData.operatorId &&
    nameOfOperatorForm.formData.operatorIdFullValue &&
    nameOfOperatorForm.formData.operatorIdFullValue;

    const selectedPartnerNameAndEmail = nameOfPartnerOperatorForm && nameOfPartnerOperatorForm.formData;
    return (
      <div className='mb-3'>
        <div className='d-flex justify-content-between align-items-start mb-3'>
          <h4 className='subtitle-loan mb-3'>{strings.descriptionTextStep3}</h4>
          {!isEditDescription && (
            <IconButton iconName='edit' value={strings.edit} onClick={() => setIsEditDescription(true)} dataTest="editDescriptionIcon" />
          )}
        </div>
        {isEditDescription ? (
          <>
            <FormComponent
              formName='descriptionForm'
              model={descriptionModel}
              formValue={descriptionForm.formData}
              onChange={handleForm}
            />
            <OpportunityForm
              setOpportunity={setOpportunity}
              setOpportunities={setOpportunities}
              removeOpportunity={removeOpportunity}
              opportunities={opportunities}
              isRequired={true}
              setIsOpportunityIdCrmError={setIsOpportunityIdCrmError}
              isOpportunityIdCrmError={isOpportunityIdCrmError}
              state={enteredOpportunityId}
              handleEnteredOpportunityId={handleEnteredOpportunityId}
              descriptionForm={descriptionForm}
              updateShortDescription={updateShortDescription}
              hasShow={true}
            />
            {isShowCheckbox && (
              <Checkbox
                label={strings.helpForBuildingAndDismantling}
                selected={helpForBuildingAndDismantling}
                onChange={handleHelpForBuildingAndDismantling}
                className='mb-3'
              />
            )}
            <h5 className='mb-3'>{strings.typesOfJobs}</h5>
            {newLabsLocationTypesOfJob.map(item => (
              <Checkbox
                key={item.name}
                label={item.name}
                selected={typesOfJobsId.includes(item.tyesOfJobsId)}
                onChange={() => updateTypesOfJobs(item.tyesOfJobsId)}
                className='mb-3'
                dataTest={`updateTypesOfJobs${item.typesOfJobsId}`}
              />
            ))}
            <FormComponent
              formName='customerForm'
              model={customerModel}
              formValue={customerForm.formData}
              onChange={handleForm}
            />
            <div className='d-flex'>
              <h5 className='mr-3'>{strings.operator}:</h5>
              {operatorModel.map(item => (
                <Checkbox
                  label={item.value}
                  selected={operatorId === item.id}
                  onChange={() => updateOperatorId(item.id)}
                  className='mb-3 mr-3'
                  dataTest={`updateOperatorId${item.id}`}
                />
              ))}
            </div>

            {
            operatorId === 1 ?
              <div className='d-flex'>
                  <h5 className='mr-3'>{strings.nameOfOperator}:</h5>
                  <FormComponent
                    formName='nameOfOperatorForm'
                    model={operatorNameModel}
                    formValue={nameOfOperatorForm.formData}
                    onChange={handleForm}
                  />
              </div>
              :
              operatorId === 2 &&
                <div className='d-flex'>
                  <FormComponent
                    formName='nameOfPartnerOperatorForm'
                    model={nameOfPartnerOperatorModel}
                    formValue={nameOfPartnerOperatorForm.formData}
                    onChange={handleForm}
                  />
                </div>
          }
          </>
        ) : (
          <div className='row mb-3'>
            <div className='col-12'>
              <div className='label-field'>{strings.reasonForLoan}</div>
              <div className='mb-3'>{(selectedReason && selectedReason.name) || 'No reason for loan'}</div>
            </div>
            <div className='col-12'>
              <div className='label-field'>{strings.commentText}</div>
              <div className='mb-3'>
                {descriptionForm.formData && descriptionForm.formData.comment
                  ? descriptionForm.formData.comment
                  : 'No comment'}
              </div>
            </div>

            <div className='col-12'>
              <div className='label-field'>{strings.nameShortDescription}</div>
              <div className='mb-3'>
                {descriptionForm.formData && descriptionForm.formData.name ? descriptionForm.formData.name : 'No name'}
              </div>
            </div>

            <div className='col-12'>
              <div className='label-field'>{strings.opportunityIdCRM}</div>
              <div className='mb-3'>
                {opportunities && opportunities.length
                  ? opportunities.map(opportunity => (
                    <div className='mb-1'>{opportunity.opportunityNumber}</div>
                  ))
                  : 'No opportunity ID CRM'}
              </div>
            </div>

            <div className='col-12'>
              <div className='label-field'>{strings.typesOfJobs}</div>
              <div className='mb-3'>
                {newLabsLocationTypesOfJob.filter(item => typesOfJobsId.includes(item.tyesOfJobsId)).length
                  ? newLabsLocationTypesOfJob.filter(item => typesOfJobsId.includes(item.tyesOfJobsId)).map(item => (
                    <div className='mb-1'>{item.name}</div>
                  ))
                  : 'No Loans Types Of Jobs'}
              </div>
            </div>

            <div className='col-12'>
              <div className='label-field'>{strings.customer}</div>
              <div className='mb-3'>
                {customerForm.formData.customer ? customerForm.formData.customer : 'No customer'}
              </div>
            </div>

            <div className='col-12'>
              <div className='label-field'>{strings.customerInstitute}</div>
              <div className='mb-3'>
                {customerForm.formData.customerInstitute ? customerForm.formData.customerInstitute : `No ${strings.customerInstitute}`}
              </div>
            </div>

            <div className='col-12'>
              <div className='label-field'>{strings.operator}</div>
              <div className='mb-3'>
                {operator ? operator.value : ''}
              </div>
            </div>
            {
              operatorId == 1 ?
                <div className='col-12'>
                  <div className='label-field'>{strings.nameOfOperator}</div>
                  <div className='mb-3'>
                  {selectednameOfOperator ? `${selectednameOfOperator.lastName} ${selectednameOfOperator.firstName}` : 'No name of operator'}
                  </div>
                </div>
            :
            operatorId ==2 &&
              <div className='col-12'>
                  <div className='label-field'>{strings.name}</div>
                  <div className='mb-3'> {selectedPartnerNameAndEmail && selectedPartnerNameAndEmail.llPartnerName} </div>
                  <div className='label-field'>{strings.email}</div>
                  <div className='mb-3'> {selectedPartnerNameAndEmail && selectedPartnerNameAndEmail.llPartnerEmail} </div>
              </div>
            }
          </div>
        )}
      </div>
    )
  };

  const renderAttachments = () => {
    return (
      <div className='mb-3'>
        <div className='d-flex justify-content-between align-items-start'>
          <h4 className='subtitle-loan mb-3'>{strings.attachments}</h4>
          {!isEditAttachments && (
            <IconButton iconName='edit' value={strings.edit} onClick={() => setIsEditAttachments(true)} />
          )}
        </div>
        {isEditAttachments ? (
          <div className='mb-3'>
            <FileUploader
              mimeType='image/*,.pdf,.doc,.docx,.xls,.xlsx'
              files={attachments}
              onRemove={removeAttachmentFile}
              onFileAdd={addAttachmentFile}
              errorMessage={strings.allowedFormats}
            />
          </div>
        ) : (
          <div className='mb-3'>
            {attachments.length ? (
              <FilesListView
                mimeType='image/*,.pdf,.doc,.docx,.xls,.xlsx'
                files={attachments}
                onRemove={removeAttachmentFile}
                isUploader={false}
              />
            ) : (
              'No attachments'
            )}
          </div>
        )}
      </div>
    );
  };

  const renderRequestor = () => {
    const borrowerAddressCountry = borrowerForm.formData.userAddress && borrowerForm.formData.userAddress.countryId
    ? getSelectedCountry(borrowerForm.formData.userAddress.countryId, countries)
    : '';
    const selectedBorrower = borrowerForm.formData.borrowerId &&
      borrowerForm.formData.borrowerIdFullValue &&
      borrowerForm.formData.borrowerIdFullValue;

    return (
      <div className='mb-3'>
        <div className='d-flex justify-content-between align-items-start'>
          <h4 className='subtitle-loan mb-3'>{strings.requestor}</h4>
          {!isEditRequestor && (
            <IconButton iconName='edit' value={strings.edit} onClick={() => setIsEditRequestor(true)} />
          )}
        </div>
        <div className='mb-3'>
          {isEditRequestor ? (
            <FormComponent
              formName='borrowerForm'
              model={requestorModel}
              formValue={borrowerForm.formData}
              onChange={handleForm}
            />
          ) : (
            <div>{selectedBorrower ? `${selectedBorrower.lastName} ${selectedBorrower.firstName}` : 'No requestor'}</div>
          )}
          {borrowerForm && borrowerForm.formData && borrowerForm.formData.userAddress && (
            <AddressInfo
              formData={borrowerForm.formData.userAddress}
              addressCountry={borrowerAddressCountry}
              flag='UserAddress'
              isEditable={false}
            />
          )}
        </div>
      </div>
    );
  };

  const changeEditView = () => {
    if (isEditTypeOfDeliveryAddress) {
      return setIsEditDeliveryAddress(true);
    }

    setIsEditTypeOfDeliveryAddress(true);
  };

  const renderDeliveryAddress = () => {
    const deliveryAddressCountry = deliveryAddressForm.formData.country && deliveryAddressForm.formData.country.name;


    return (
      <AddressInfo
        formData={deliveryAddressForm.formData}
        changeIsEditTypeFlag={changeEditView}
        addressCountry={deliveryAddressCountry}
        flag='isEditDeliveryAddress'
        isEditable
      />
    );
  };

  const renderDeliveryAddressBlock = () => {
    const selectedDeliveryAddress = (typeOfDeliveryAddressForm.formData.deliveryAddressFullValue ||
        typeOfDeliveryAddressForm.formData.deliveryAddress);

    return (
      <div className='mb-3'>
        <div className='d-flex justify-content-between align-items-start'>
          <h4 className='subtitle-loan mb-3'>{strings.partnerSite}</h4>
        </div>
        {isEditTypeOfDeliveryAddress ? (
          <FormComponent
            formName='typeOfDeliveryAddressForm'
            model={typeDeliveryAddressModel}
            formValue={typeOfDeliveryAddressForm.formData}
            onChange={handleForm}
          />
        ) : (
          <>
            <div className='d-flex justify-content-between align-items-start'>
              <div className='mb-3'>
                <p>{selectedDeliveryAddress ? strings.stockAddress : 'No delivery address'}</p>
              </div>
              {isEditTypeOfDeliveryAddress && (
                <IconButton iconName='edit' value={strings.edit} onClick={() => setIsEditTypeOfDeliveryAddress(true)} />
              )}
            </div>
          </>
        )}
        {isEditTypeOfDeliveryAddress && isEditDeliveryAddress ? (
          <FormComponent
            formName='deliveryAddressForm'
            model={customerSiteModel}
            formValue={deliveryAddressForm.formData}
            onChange={handleForm}
          />
        ) : (
          renderDeliveryAddress()
        )}
      </div>
    );
  };

  return (
    <>
      <h2 className='title-loan mb-4'>{strings.confirmationStep3}</h2>
      <div className='row'>
        <div className='col-12 col-md-6'>
          {renderDescription()}
          {renderAttachments()}
          {renderRequestor()}
          {renderDeliveryAddressBlock()}
        </div>
        <div className='col-12 col-md-6'>
          <SelectedKits
            selectedKits={selectedKits}
            goToCalendar={goToCalendar}
            handleDateChange={updateDateOfCartItem}
          />
        </div>
        <div className='col-md-6'>
          <div className='d-flex justify-content-between'>
            <Button
              bgFill={false}
              isGhost={true}
              iconName='arrow'
              iconPosition='left'
              iconStyle='arrow-left'
              styleClass='w-auto'
              value={strings.backToCustomerData}
              onClick={goToPrevStep}
            />
            <Button
              data-test='go-to-next-step-button'
              bgFill={true}
              iconName='arrow'
              styleClass='w-auto'
              value={strings.executeBooking}
              onClick={handleBookingProccess}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Confirmation;
