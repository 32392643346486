import React, { useCallback } from 'react';

import DoughnutChart from 'library/common/commonComponents/DoughnutChart';

const DoughnutChartWithTextInside = ({ total, delivered, legend }) => {
  const getDataForChart = useCallback(() => {
    if (total) {
      const data = {
        datasets: [
          {
            data: [(total-delivered), delivered],
            backgroundColor: ['#9A9B9C', '#111E92'],
            hoverBackgroundColor: ['#9A9B9C', '#111E92'],
            order: 2,
          },
        ],
      };
  
      return data;
    }

    const defaultData = {
      datasets: [
        {
          data: [10],
          backgroundColor: ['#9A9B9C'],
          hoverBackgroundColor: ['#9A9B9C'],
          order: 1,
        },
      ],
    }

    return defaultData;
  }, [total, delivered]);

  const data = getDataForChart();
  const options = { cutoutPercentage: 70 };

  return (
    <div className='doughnut-chart-container'>
      <div className='chart-wrapper'>
        <DoughnutChart data={data} height={120} hideLegend={true} additionalOptions={options} />
        <div className='text-inside'>
          <span className='delivered'>{delivered}</span><span className='total'>/{total}</span>
        </div>
      </div>
      <div className='custom-legend'>
        <div className='palette' />
        <span>{legend}</span>
      </div>
    </div>
  );
}

export default DoughnutChartWithTextInside;
