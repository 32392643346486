import { batchActions } from 'redux-batched-actions';

import { fetchMasterDataService, fetchMasterDataServicePost } from 'library/api/master-data.service';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { changeLoader } from 'library/common/commonActions/AppActionsActions';

import {
  FETCH_INITIAL_DATA_FOR_STOCK_DASHBOARD,
  FETCH_STOCK_BY_COUNTRY,
  FETCH_OUTGOING_STATISTICS,
  FETCH_INCOMING_STATISTICS,
  FETCH_LOAN_AVAILABILITY_BY_STOCKS,
  FETCH_THIS_MONTH_STATISTICS,
} from './StockDashboard.constants';

export const getInitialData = () => async dispatch => {
  try {
    dispatch(changeLoader(true));

    const { data: businessUnits } = await fetchMasterDataService(URLS.userBusinessunits);
    const { data: countries } = await fetchMasterDataService(URLS.userCountries);
    const idFirstCountry = countries && countries.length ? countries[0].id : '';
    const { data: stocks } = await fetchMasterDataServicePost(URLS.userStocks, { countryIds: [idFirstCountry] });

    batchActions([
      dispatch({
        type: FETCH_INITIAL_DATA_FOR_STOCK_DASHBOARD,
        payload: {
          businessUnits: businessUnits || [],
          countries: countries || [],
          stocks: stocks || [],
        },
      }),
      dispatch(changeLoader(false)),
    ]);
  } catch (err) {
    batchActions([
      dispatch(changeLoader(false)),
      dispatch({
        type: FETCH_INITIAL_DATA_FOR_STOCK_DASHBOARD,
        payload: {
          businessUnits: [],
          countries: [],
          stocks: [],
        },
      }),
    ]);
  }
};

export const getStockByCountry = countryId => async dispatch => {
  try {
    const { data: stocks } = await fetchMasterDataServicePost(URLS.userStocks, { countryIds: [countryId] });

    dispatch({
      type: FETCH_STOCK_BY_COUNTRY,
      payload: stocks || [],
    });

  } catch (err) {
    dispatch({
      type: FETCH_STOCK_BY_COUNTRY,
      payload: [],
    });
  }
};

export const getStockConfigurationForLoand = stockIds => async dispatch => {
  try {
    const { data } = await fetchMasterDataServicePost(URLS.stocksLoanAvailability, { ids: stockIds });

    dispatch({
      type: FETCH_LOAN_AVAILABILITY_BY_STOCKS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_LOAN_AVAILABILITY_BY_STOCKS,
      payload: [],
    });
  }
};

export const getIncomingStatistics = (countryId, businessUnitId, stockIds = [], type) => async dispatch => {
  try {
    const { data: statistics } = await fetchMasterDataServicePost(URLS.statisticIncomingGoods, {
      countryId,
      businessUnitId,
      stockIds,
      type,
    });

    dispatch({
      type: FETCH_INCOMING_STATISTICS,
      payload: statistics,
    });
  } catch (err) {
    dispatch({
      type: FETCH_INCOMING_STATISTICS,
      payload: {},
    });
  }
};

export const getOutgoingStatistics = (countryId, businessUnitId, stockIds = [], type) => async dispatch => {
  try {
    const { data: statistics } = await fetchMasterDataServicePost(URLS.statisticOutgoingGoods, {
      countryId,
      businessUnitId,
      stockIds,
      type,
    });

    if (stockIds && stockIds.length) {
      await dispatch(getStockConfigurationForLoand(stockIds));
    }

    dispatch({
      type: FETCH_OUTGOING_STATISTICS,
      payload: statistics,
    });
  } catch (err) {
    dispatch({
      type: FETCH_OUTGOING_STATISTICS,
      payload: {},
    });
  }
};

export const getStatistics = (countryId, businessUnitId, stockIds = [], type) => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const outgoingStatistics = dispatch(getOutgoingStatistics(countryId, businessUnitId, stockIds, type));
    const incomingStatistics = dispatch(getIncomingStatistics(countryId, businessUnitId, stockIds, type));

    await Promise.all([outgoingStatistics, incomingStatistics]);
    dispatch(changeLoader(false));
  } catch (err) {
    dispatch(changeLoader(false));
  }
}

export const getStatisticsForThisMonth = dataToSend => async dispatch => {
  try {
    const { data } = await fetchMasterDataServicePost(URLS.statisticGlobalDashboard, dataToSend);
    dispatch({
      type: FETCH_THIS_MONTH_STATISTICS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_THIS_MONTH_STATISTICS,
      payload: {},
    });
  }
}

export const fetchCountries = () => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const { data: countries } = await fetchMasterDataService(URLS.userCountries);
    batchActions([
      dispatch({
        type: FETCH_INITIAL_DATA_FOR_STOCK_DASHBOARD,
        payload: {
          countries: countries || [],
        },
      }),
      dispatch(changeLoader(false)),
    ]);
  } catch (err) {
    batchActions([
      dispatch(changeLoader(false)),
    ]);
  }
};
