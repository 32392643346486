import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';

import {
  fetchShopListData,
  exportShopList,
  createSubscription,
  removeSubscription,
  addKitToObservationList,
  removeKitFromObservationList,
  cancelKitReservation,
  reserveKit,
  orderKit,
  fetchMarketplaceCountryList,
} from '../../Shop.actions';
import SalesOffers from './SalesOffers.components';

const mapStateToProps = ({ authReducer, languageReducer, masterDataReducer, appActionsReducer, shopListReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
  appActions: appActionsReducer,
  countries: masterDataReducer.countries,
  categoriesAndSystems: masterDataReducer.systemClassWithStock,
  shopList: shopListReducer.shopList,
  subscriptions: shopListReducer.subscriptions,
  marketplaceCountry: shopListReducer.marketplaceCountry,
});

export default withRouter(connect(mapStateToProps, {
  fetchMasterData,
  fetchShopListData,
  exportShopList,
  createSubscription,
  removeSubscription,
  addKitToObservationList,
  removeKitFromObservationList,
  cancelKitReservation,
  reserveKit,
  orderKit,
  fetchMarketplaceCountryList
})(SalesOffers));
