import React, { useState, useCallback } from 'react';
import * as $ from 'jquery';
import strings from 'resources/locales/Translate';

export const useDeleteRoomPopup = ({ id, deleteRoom, stockId, singleRoom }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const handleConfirm = async () => {
    const dataToSend = {
      roomName: singleRoom.roomName,
      warehouseId: stockId,
      warehouseRoomId: singleRoom.warehouseRoomId,
    };
    setLoading(true);
    const { success, error } = await deleteRoom(dataToSend, singleRoom.warehouseRoomId, stockId);
    setLoading(false);
    if (!success) {
      if (error && error.response && error.response.data.status === 422) {
        setError(error.response.data.message);
      } else {
        setError(strings.somethingWentWrongMessage);
      }
    } else {
      handleCancel();
    }
  };

  const handleCancel = () => {
    if ($(`#${id}`) && $(`#${id}`).modal) {
      $(`#${id}`).modal('hide');
      setError('');
    }
  };
  return {
    handleConfirm,
    handleCancel,
    loading,
    error,
  };
};
