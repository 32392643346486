import { batchActions } from 'redux-batched-actions';

import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { fetchQuestionsList } from 'library/common/commonActions/QuestionsActions';
import { fetchKitTransactionAttachments } from 'modules/Kits/KitDetails/KitDetailsAction';
import { logNetworkError } from 'library/utilities/logError';
import { updateKeysForSellOffAttachments } from 'library/utilities/fileHelperFunctions';
import { fetchMasterDataService } from 'library/api/master-data.service';
import { deleteMasterDataService } from 'library/api/master-data.service';
import { marketplaceKitDetailsRequestJson } from 'library/api/endpoint-request-json';
import zeissInstance from "library/utilities/AxiosInstance";

import * as actionTypes from './KitForSaleDetails.constants';

export const fetchKitForSaleDetail = id => async dispatch => {
  dispatch(changeLoader(true));

  try {
    const res = await zeissInstance.post(`${URLS.kit}/${id}`, { graphql: marketplaceKitDetailsRequestJson });
    if (res.status === 200) {
      const attachments = res.data.sellOffPlatformInformation.sellOffPlatformAttachments;
      const isDLAKit = !res.data.kitManualCreated;

      await dispatch(
        batchActions([
          dispatch({
            type: actionTypes.GET_KIT_FOR_SALE_DETAILS,
            payload: res.data,
          }),
          dispatch(fetchKitAttachmentsAndSave(attachments)),
          dispatch(fetchQuestionsList(res.data.kitInformationId, actionTypes)),

        ]),
      );

      if (isDLAKit) {
        dispatch(fetchKitTransactionAttachments(id, actionTypes.FETCH_KIT_TRANSACTION_ATTACHMENTS));
      }
    }
    dispatch(changeLoader(false));
  } catch (err) {
    logNetworkError(err);
    dispatch(changeLoader(false));
  }
};

export const fetchKitAttachmentsAndSave = files => dispatch => {
  const kitDetailsAttachments = updateKeysForSellOffAttachments(files);
  dispatch({
    type: actionTypes.FETCH_KIT_DETAILS_ATTACHMENTS,
    payload: kitDetailsAttachments,
  });
};

export const saveCurrentTab = tab => dispatch => {
  dispatch({
    type: actionTypes.SAVE_CURRENT_TAB,
    payload: tab,
  });
};

export const clearKitDetailsData = () => dispatch => {
  dispatch({
    type: actionTypes.CLEAR_KIT_FOR_SALE_DETAILS_DATA,
  });
  dispatch(fetchKitAttachmentsAndSave([]));
};

export const deleteKitDetails = id => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const { status } = await deleteMasterDataService(`${URLS.deleteKit}/${id}`);
    if (status === 200) {
      dispatch(
        batchActions([
          dispatch(changeLoader(false)),
          dispatch(toggleActionMessage(true, 'success', 'kitDeletedSuccessfully')),
        ]),
      );

      return { success: true };
    }
  } catch (err) {
    let errorMessage = 'somethingWentWrongMessage';
    if (err.response.status === 422 && err.response.data.message) {
      errorMessage = err.response.data.message;
    }
    dispatch(
      batchActions([
        dispatch(changeLoader(false)),
        dispatch(toggleActionMessage(true, 'error', errorMessage)),
      ]),
    );
    logNetworkError(err);
    return { success: false };
  }
};

export const fetchViewsCount = kitId => async dispatch => {
  try {
    await zeissInstance.get(URLS.kitViews(kitId))
    dispatch(fetchKitForSaleDetail(kitId));
    return ({ success: true })
  } catch (err) {
    return ({ success: false })
  }
}
