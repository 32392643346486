import React, { PureComponent } from 'react';
import { isEqual } from 'lodash';

import strings from 'resources/locales/Translate';

import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import Dropdown from '../FormComponent/Optimized/Dropdown';

import './addStockControllerStyles.scss';
import { StockControllerDelete } from '../Icon/SvgIcons/Icons';

const attachNamesToController = (added, userList) => {
  const addedStockControllersIds = [];
  const addedStockControllers = [];
  added.forEach((item) => {
    const user = userList.find((user) => user.accountId === item.accountId);
    addedStockControllersIds.push(item.accountId);
    if (item.roleId === 1) {
      addedStockControllers.push({ ...item, ...user });
    }
  });
  return { addedStockControllers, addedStockControllersIds };
};

export default class AddStockController extends PureComponent {
  static getDerivedStateFromProps(props, state) {
    if (
      (!state.existingStockControllers && props.existingStockControllers && props.existingStockControllers.length) ||
      (state.existingStockControllers && !isEqual(props.existingStockControllers, state.existingStockControllers))
    ) {
      const { addedStockControllers, addedStockControllersIds } = attachNamesToController(
        props.existingStockControllers,
        props.dropdownData,
      );
      return {
        addedStockControllers,
        addedStockControllersIds,
        existingStockControllers: props.existingStockControllers,
        initialStockControllersIds: addedStockControllersIds,
        deletedStockControllers: [],
        deletedStockControllersIds: [],
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      selectedFullValue: null,
      addedStockControllers: null,
      addedStockControllersIds: null,
      entitlements: null,
      existingStockControllers: null,
      deletedStockControllers: [],
      deletedStockControllersIds: [],
      newStockControllers: [],
      isClear: false,
      error: '',
    };
  }

  resetDeletedControllers = () => {
    this.setState({ deletedStockControllers: [], deletedStockControllersIds: [] });
  };

  getData = () => {
    const {
      addedStockControllers,
      addedStockControllersIds,
      initialStockControllersIds,
      deletedStockControllers,
      deletedStockControllersIds,
    } = this.state;
    return {
      addedStockControllers,
      addedStockControllersIds:
        addedStockControllersIds &&
        addedStockControllersIds.filter(
          (item) =>
            !initialStockControllersIds || (initialStockControllersIds && !initialStockControllersIds.includes(item)),
        ),
      initialStockControllersIds,
      deletedStockControllers,
      deletedStockControllersIds,
    };
  };

  handleValueChange = (value, field, fullValue) => {
    this.setState({ selected: value, selectedFullValue: fullValue, error: '' });
  };

  addStockController = () => {
    const { selected, selectedFullValue } = this.state;
    if (selected) {
      this.setState((prevState) => ({
        newStockControllers: [...(prevState.newStockControllers || []), selected],
        addedStockControllers: [...(prevState.addedStockControllers || []), selectedFullValue],
        addedStockControllersIds: [...(prevState.addedStockControllersIds || []), selected],
        selected: null,
        selectedFullValue: null,
        deletedStockControllers: prevState.deletedStockControllers.filter((item) => item !== selected),
        error: '',
      }));
    } else {
      this.setState({
        error: 'pleaseSelectUser',
      });
    }
  };

  removeStockController = (stockController, accountId) => () => {
    this.setState((prevState) => {
      const deletedStockControllers = prevState.deletedStockControllers;
      const deletedStockControllersIds = prevState.deletedStockControllersIds;
      if (prevState.initialStockControllersIds && prevState.initialStockControllersIds.includes(accountId)) {
        deletedStockControllers.push(stockController);
        deletedStockControllersIds.push(stockController.id);
      }
      return {
        newStockControllers: prevState.newStockControllers.filter((item) => item !== accountId),
        addedStockControllers: prevState.addedStockControllers.filter((item) => item.accountId !== accountId),
        addedStockControllersIds: prevState.addedStockControllersIds.filter((item) => item !== accountId),
        deletedStockControllers,
        deletedStockControllersIds,
      };
    });
  };

  sendValuesToContainer = () => {};

  dropdownData = () => {
    let { dropdownData } = this.props;
    const { addedStockControllersIds } = this.state;
    const newData = dropdownData.filter(
      (item) =>
        !addedStockControllersIds || (addedStockControllersIds && !addedStockControllersIds.includes(item.accountId)),
    );
    return newData;
  };

  render() {
    const { canEditStock,isRMSZMCCStock } = this.props;
    const { addedStockControllers, selected, selectedFullValue, error } = this.state;
    return (
      <div className='add-stock-controller'>
        {canEditStock && (
          <div className='form'>
            <div className='row'>
              <div className='col-sm-10'>
                <Dropdown
                  data={this.dropdownData()}
                  displayValue={[
                    { key: 'lastName', separator: ', ' },
                    { key: 'firstName', separator: ' (' },
                    { key: 'email', separator: ')' },
                  ]}
                  filter
                  isRequired
                  idKey='accountId'
                  field='stockController'
                  label={isRMSZMCCStock ? strings.zmccManager : strings.stockController}
                  placeholder={strings.pleaseSelect}
                  onChange={this.handleValueChange}
                  value={selected}
                  fullValue={selectedFullValue}
                  error={error}
                />
              </div>
              <div className='col-sm-2'>
                <Button
                  value={strings.addButtonText}
                  onClick={this.addStockController}
                  bgFill={false}
                  styleClass='add-button adjust-add-location-manager-btn'
                  iconName='plus'
                  newVersionUi={true}
                />
              </div>
            </div>
          </div>
        )}

        {addedStockControllers && !!addedStockControllers.length && (
          <div className='stock-controllers-list'>
            {addedStockControllers.map((stockController, key) => (
              <div key={key} className='stock-controller-list-item d-flex justify-content-between align-items-center mb-2'>
                <p>
                  {stockController.lastName}, {stockController.firstName} ({stockController.email})
                </p>
                {canEditStock && (
                  <button
                    type='button'
                    className='delete-button'
                    onClick={this.removeStockController(stockController, stockController.accountId)}
                  >
                    <StockControllerDelete/>
                  </button>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}
