import React, { Component } from 'react';
import moment from 'moment';
import { isEqual } from 'lodash';

import strings from 'resources/locales/Translate';
import Button from 'library/common/commonComponents/Button/Button';
import { logError } from 'library/utilities/logError';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      hasError: false,
      lastError: null,
    };
  }

  componentDidCatch(error, info) {
    if (process.env.NODE_ENV !== 'development') {
      if (this.props.location.pathname === '/calendar') {
        this.props.history.replace({
          pathname: '/home',
          state: {
            loanType: 1,
            stocks: this.props.location.state && this.props.location.state.stocks,
            stocksFullValue: this.props.location.state && this.props.location.state.stocksFullValue,
            systemClass: this.props.location.state && this.props.location.state.systemClass,
            systemClassFullValue: this.props.location.state && this.props.location.state.systemClassFullValue,
          },
        });
        setTimeout(() => {
          this.props.history.replace({
            pathname: '/calendar',
            state: {
              loanType: 1,
              stocks: this.props.location.state && this.props.location.state.stocks,
              stocksFullValue: this.props.location.state && this.props.location.state.stocksFullValue,
              systemClass: this.props.location.state && this.props.location.state.systemClass,
              systemClassFullValue: this.props.location.state && this.props.location.state.systemClassFullValue,
            },
          });
        }, 0);
      } else {
        this.setState({ error: error.toString(), hasError: true, errorTime: moment() }, () => {
          if (!isEqual(this.state.lastError, error)) {
            logError(`${error}; INFO: ${info && info.componentStack}`);
          }
          this.interval = setInterval(() => {
            if (Math.ceil(5 - moment().diff(this.state.errorTime) / 1000)) {
              this.forceUpdate();
            } else {
              this.onRefreshClick();
            }
          }, 1000);
        });
      }
    }
  }

  onRefreshClick = () => {
    clearInterval(this.interval);
    if (this.state.lastError && this.state.lastError === this.state.error) {
      this.setState(prevState => ({
        error: null,
        hasError: false,
        lastError: null,
      }));
    } else {
      this.setState(prevState => ({
        error: null,
        hasError: false,
        lastError: prevState.error,
      }));
    }
  };

  getErrorMessage = () => {
    if (this.state.lastError && this.state.lastError === this.state.error) {
      return `You will be redirected to home page ${Math.ceil(
        5 - moment().diff(this.state.errorTime) / 1000,
      )}s. Or click on the button to
          refresh it manually`;
    }
    if (this.props.location.pathname !== '/' || this.props.location.pathname !== '/') {
      return `Page will refresh in ${Math.ceil(
        5 - moment().diff(this.state.errorTime) / 1000,
      )}s. Or click on the button to
              refresh it manually`;
    }
    return '';
  };

  renderError = () => {
    return (
      <div
        className='container d-flex flex-column align-items-center justify-content-center'
        style={{ minHeight: '30vh' }}
      >
        <h4 className='mb-3'>{strings.somethingWentWrongMessage}</h4>
        <p>{this.getErrorMessage()}</p>
        <div>
          <Button bgFill={false} value={strings.refresh} styleClass='mt-3' onClick={this.onRefreshClick} />
        </div>
      </div>
    );
  };

  render() {
    if (this.state.hasError) {
      return this.renderError();
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
