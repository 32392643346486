import React from 'react';
import { Fragment } from 'react';

function KitStatusAndConditionTooltip({
  kitStatuses,
  doNotShowInReparation,
  kitCondition,
  getIconByCondition,
  getIconByStatus,
}) {
  return (
    <div className='kit-status-condition-tooltip'>
      {kitStatuses && kitStatuses.length ? (
        <div className='d-flex flex-column'>
          {kitStatuses.map(item => {
            if (item && item.kitStatusesTranslated && item.kitStatusesTranslated.length) {
              if (doNotShowInReparation && item.kitStatusId == 13) {
                return null;
              } else {
                return <Fragment key={item.kitStatusId}>{getIconByStatus(item, true)}</Fragment>;
              }
            }
            return null;
          })}
        </div>
      ) : null}
      {kitCondition && kitCondition.kitConditionTranslated && kitCondition.kitConditionTranslated.length ? (
        <>
          {kitCondition.kitConditionId == 5 && !doNotShowInReparation ? null : getIconByCondition(kitCondition, true)}
        </>
      ) : null}
    </div>
  );
}

export default KitStatusAndConditionTooltip;
