import * as actionTypes from 'library/common/commonConstants/storeConstants';

const initialState = {
  controller: null,
  shopList: [],
  subscriptions: [],
  observationsList: [],
  archiveList: [],
  reservationList: [],
  statisticsData: [],
  purchaseRequests: [],
  totalPurchaseRequestsItems: 0,
  rmsSystemClass: [],
  orderList: [],
  marketplaceCountry: []
};

const ShopListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SHOP_LIST:
      return {
        ...state,
        shopList: action.payload
      };
    case actionTypes.FETCH_SHOP_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: action.payload
      };
    case actionTypes.FETCH_OBSERVATION_LIST:
      return {
        ...state,
        observationsList: action.payload,
      };
    case actionTypes.FETCH_ARCHIVE_LIST:
      return {
        ...state,
        archiveList: action.payload,
      };
    case actionTypes.FETCH_RESERVATION_LIST:
      return {
        ...state,
        reservationList: action.payload,
      };
    case actionTypes.SET_STATISTICS:
      return {
        ...state,
        statisticsData: action.payload,
      };
    case actionTypes.SET_CONTROLLER:
      return {
        ...state,
        controller: action.payload,
      };
    case actionTypes.GET_PURCHASE_REQUESTS:
      return {
        ...state,
        purchaseRequests: [...state.purchaseRequests, ...action.payload.purchases],
        totalPurchaseRequestsItems: action.payload.totalItems,
      };
    case actionTypes.UPDATE_PURCHASE_REQUESTS: 
      return {
        ...state,
        purchaseRequests: action.payload.purchases,
        totalPurchaseRequestsItems: action.payload.totalItems,
      };
    case actionTypes.FETCH_RMS_SYSTEM_CLASS:
      return {
        ...state,
        rmsSystemClass: action.payload,
      };
    case actionTypes.FETCH_ORDER_LIST:
      return {
        ...state,
        orderList: action.payload,
      };
      case actionTypes.MARKETPLACE_COUNTRY_LIST:
      return {
        ...state,
        marketplaceCountry: action.payload,
      };
    default:
      return state;
  }
};

export default ShopListReducer;
