import React from 'react';
import get from 'lodash/get';

import strings from 'resources/locales/Translate';
import Button from 'library/common/commonComponents/Button/Button';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';

import CalculationMethodPopup from '../CalculationMethodPopup';
import CalculationBlock from '../CalculationBlock';
import { useCalculationMethodTab } from './CalculationMethodTab.hook';
import './CalculationMethodTab.styles.scss';

const CalculationMethodTab = props => {
  const {
    language,
    continentsList,
  } = props;
  const {
    isEditMode,
    isLoading,
    disabledSaveBtn,
    selectedContinent,
    copyCalculationList,
    onEdit,
    handleInput,
    handleDropdown,
    onSave,
    onOpenPopup,
  } = useCalculationMethodTab(props);

  return (
    <div className='calculation-container container-fluid'>
      <div className='d-flex align-items-center my-5'>
        <p className='text-bold'>{strings.selectContinent} <span className='mandatory-asterik'>*</span></p> <p className='ml-2 text-bold'>:</p> 
        <Dropdown
          data={continentsList}
          idKey='id'
          field='continent'
          displayValue={[{ key: 'name', separator: '' }]}
          isRequired={false}
          placeholder={strings.pleaseSelect + '...'}
          onChange={handleDropdown}
          mainContainerStyle='w-25 mx-3'
          value={selectedContinent.value}
          fullValue={selectedContinent.fullValue}
        />
      </div>
      {selectedContinent.value ? (
        <div className='calculation-wrapper w-75'>
          <CalculationBlock
            handleInput={handleInput}
            copyCalculationList={copyCalculationList}
            isEditMode={isEditMode}
          />
          <div className='d-flex justify-content-end'>
            <Button
              bgFill={false}
              iconName={isEditMode ? 'cross' : 'edit'}
              value={isEditMode ? strings.cancel : strings.edit}
              onClick={onEdit}
              styleClass='w-auto mr-3'
            />
            <Button
              bgFill={false}
              iconName='save'
              value={strings.save}
              onClick={onOpenPopup}
              disabled={disabledSaveBtn}
              styleClass='w-auto'
            />
          </div>
        </div>
      ) : (
        <div className='d-flex justify-content-center my-3'>
          <strong>{strings.pleaseSelectValueFromDropdown}</strong>
        </div>
      )}
      <CalculationMethodPopup
        id='calculationMethodPopup'
        language={language}
        continent={get(selectedContinent, 'fullValue.id')}
        onSave={onSave}
        isLoadin={isLoading}
      />
    </div>
  );
};

export default CalculationMethodTab;
