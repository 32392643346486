import React from 'react';
import Modal from 'library/common/commonComponents/Modal';
import strings from 'resources/locales/Translate';
import Validators from 'library/utilities/Validators';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import ToggleSwitch from 'library/common/commonComponents/ToggleSwitch';
import DateInput from 'library/common/commonComponents/FormComponent/Optimized/DateInput';
import { useAddEditSlotPopup } from './AddEditSlotPopup.hook';

export const AddEditSlotPopup = ({
  id,
  stockId,
  kitsListByWarehouse,
  saveCreateAvailabilty,
  editingAvailabilitySlotData,
  editAvailabiltySlot,
  isEditMode,
  onEditClose,
  isPopupFromKit,
  kitData,
  warehouseId,
  editKitData
}) => {
  const {
    onChangeDropdown,
    selectedKits,
    onChangeDate,
    startDate,
    endDate,
    onChangeRecurring,
    recurring,
    onSaveClick,
    handleOnDayClick,
    selectedDays,
    onCloseClick,
    selectedKitsError,
    startDateError,
    endDateError,
    selectedDaysError
  } = useAddEditSlotPopup({ id, saveCreateAvailabilty, stockId, editingAvailabilitySlotData, editAvailabiltySlot,isEditMode, onEditClose, isPopupFromKit, kitData, warehouseId,editKitData});
 
  return (
    <Modal
      id={id}
      confirmAction={onSaveClick}
      title={isEditMode ? strings.editSlot : strings.addNewSlot}
      disableBackgroundClose
      onCloseClick={onCloseClick}
      confirmTitle={strings.save}
      titleOfCancel={strings.cancel}
    >
      <div className='col-12 p-0'>
        <div className='col-12 p-0'>
          <Dropdown
            data={kitsListByWarehouse}
            hasSection={false}
            multiSelect={true}
            filter={true}
            idKey='kitInformationId'
            field='selectedKits'
            displayValue={[{ key: 'kitName', separator: ' ' }]}
            label={strings.zmccSelectKits}
            isRequired
            validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
            placeholder={strings.pleaseSelect}
            onChange={onChangeDropdown}
            value={selectedKits.value}
            fullValue={selectedKits.fullValue}
            error={selectedKitsError}
            disabled={isPopupFromKit}
          />
        </div>
        <div className='row mt-3'>
          <div className='col-4'>{strings.recurring}</div>
          <div className='col-2' onClick={onChangeRecurring}>
            <ToggleSwitch checked={recurring} />
          </div>
        </div>

        {recurring && (
          <div className='d-flex mt-2'>
            <div className={selectedDays.Monday ? 'selected-days' : 'days'} onClick={() => handleOnDayClick('Monday')}>
              M
            </div>
            <div
              className={selectedDays.Tuesday ? 'selected-days' : 'days'}
              onClick={() => handleOnDayClick('Tuesday')}
            >
              T
            </div>
            <div
              className={selectedDays.Wednesday ? 'selected-days' : 'days'}
              onClick={() => handleOnDayClick('Wednesday')}
            >
              W
            </div>
            <div
              className={selectedDays.Thursday ? 'selected-days' : 'days'}
              onClick={() => handleOnDayClick('Thursday')}
            >
              TH
            </div>
            <div className={selectedDays.Friday ? 'selected-days' : 'days'} onClick={() => handleOnDayClick('Friday')}>
              F
            </div>
          </div>
        )}

        <div className='row mt-3'>
          <div className='col-6'>
            <DateInput
              field='dateRange'
              label={strings.startDate}
              placeholder={strings.pleaseSelectDate}
              selectedDate={startDate}
              onChange={e => onChangeDate(e, 'start')}
              isRequired={true}
              isRange={false}
              minDate={new Date()}
              error={startDateError}
            />
          </div>
          <div className='col-6'>
            <DateInput
              field='dateRange'
              label={strings.endDate}
              placeholder={strings.pleaseSelectDate}
              selectedDate={endDate}
              onChange={onChangeDate}
              isRequired={true}
              isRange={false}
              error={endDateError}
              minDate={new Date()}
            />
          </div>
        </div>
        {selectedDaysError && <div className='error-block mt-2'>{selectedDaysError}</div>}
      </div>
    </Modal>
  );
};
AddEditSlotPopup.defaultProps = {
  onEditClose : () => {}
}

export default AddEditSlotPopup;
