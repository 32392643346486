import { connect } from 'react-redux';

import OpportunityIds from './OpportunityIds.component';

const mapStateToProps = ({ authReducer, languageReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
});

export default connect(mapStateToProps)(OpportunityIds);
