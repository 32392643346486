import Modal from 'library/common/commonComponents/Modal';
import React from 'react';
import strings from 'resources/locales/Translate';
import { useDeleteRoomPopup } from './DeleteRoomPopup.hooks';

export const DeleteRoomPopup = ({ id, deleteRoom, stockId, singleRoom, children }) => {
  const { handleConfirm, handleCancel, loading, error } = useDeleteRoomPopup({
    id,
    deleteRoom,
    stockId,
    singleRoom,
  });
  return (
    <Modal
      id={id}
      title={strings.deleteRoomTitle}
      confirmAction={handleConfirm}
      confirmTitle={strings.confirm}
      loading={loading}
      onCloseClick={handleCancel}
    >
      {children}
      <h6 className='mb-5'>{strings.deleteRommWarningMessage}</h6>
      {error && <div className='error-block'>{error}</div>}
    </Modal>
  );
};

export default DeleteRoomPopup;
