import zeissInstance from 'library/utilities/AxiosInstance';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';

async function fetchKitListForHomepageService(data) {
  return await zeissInstance.post(URLS.kitsHome, data);
}

async function fetchFrequentlyBorrowedKitsService(data) {
  const url = URLS.borrowedFrequentlyKits.replace('{graphql}', data.graphql).replace('{limit}', data.limit);
  return await zeissInstance.get(url);
}

export default {
  fetchKitListForHomepageService,
  fetchFrequentlyBorrowedKitsService,
};
