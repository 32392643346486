import React from 'react';

import strings from 'resources/locales/Translate';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import Button from 'library/common/commonComponents/Button/Button';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import FileUploader from 'library/common/commonComponents/FileUploader/FileUploader';

import OpportunityForm from '../../../KitLoan/Components/OpportunityIds';
import SelectedKits from '../../../KitLoan/Components/SelectedKits';
import { customerModel, operatorModel} from '../../LLKiLoan.constants';
import { useLLKitLoanModel } from '../../LLKitLoanModel.hook';
import Tooltip from 'library/common/commonComponents/Tooltip';

const Assignment = props => {
  const {
    operatorId,
    typesOfJobsId,
    selectedKits,
    descriptionForm,
    customerForm,
    loansTypesOfJobs,
    updateDateOfCartItem,
    updateTypesOfJobs,
    updateOperatorId,
    helpForBuildingAndDismantling,
    isShowCheckbox,
    opportunities,
    enteredOpportunityId,
    handleEnteredOpportunityId,
    setOpportunity,
    removeOpportunity,
    setOpportunities,
    isOpportunityIdCrmError,
    setIsOpportunityIdCrmError,
    addAttachmentFile,
    removeAttachmentFile,
    attachments,
    nameOfOperatorForm,
    nameOfPartnerOperatorForm,
    newLabsLocationTypesOfJob,
  } = props;
  const {
    descriptionModel,
    operatorNameModel,
    nameOfPartnerOperatorModel,
    gotToCustomerStep,
    handleForm,
    handleHelpForBuildingAndDismantling,
    updateDescriptionForm,
    updateCustomerForm,
    goToLoanListPage,
    goToCalendar,
  } = useLLKitLoanModel({ ...props });
  const test = {}
  return (
    <div className='row'>
      <div className='col-12 col-md-6'>
        <div className='mb-3'>
          <h4 className='subtitle-loan mb-3'>{strings.descriptionText}</h4>
          <FormComponent
            formName='descriptionForm'
            model={descriptionModel}
            formValue={descriptionForm.formData}
            onChange={handleForm}
          />
          <OpportunityForm
            setOpportunity={setOpportunity}
            setOpportunities={setOpportunities}
            removeOpportunity={removeOpportunity}
            opportunities={opportunities}
            isRequired={true}
            setIsOpportunityIdCrmError={setIsOpportunityIdCrmError}
            isOpportunityIdCrmError={isOpportunityIdCrmError}
            state={enteredOpportunityId}
            handleEnteredOpportunityId={handleEnteredOpportunityId}
            descriptionForm={descriptionForm}
            updateShortDescription={updateDescriptionForm}
            customerForm={customerForm}
            updateCustomerForm={updateCustomerForm}
            hasShow={true}
            selectedKits={selectedKits}
            loanType={'labsLocationType'}
          />
          {isShowCheckbox && (
            <Checkbox
              label={strings.helpForBuildingAndDismantling}
              selected={helpForBuildingAndDismantling}
              onChange={handleHelpForBuildingAndDismantling}
              className='mb-3'
            />
          )}
          <h5 className='mb-3'>{strings.typesOfJobs}</h5>
          {newLabsLocationTypesOfJob.map(item => (
            <div title={item.toolTipValue !== null ? item.toolTipValue : ''}>
            <Checkbox
              key={item.name}
              label={item.name}
              selected={typesOfJobsId.includes(item.tyesOfJobsId)}
              onChange={() => updateTypesOfJobs(item.tyesOfJobsId)}
              className='mb-3'
              dataTest={`updateTypesOfJobs${item.tyesOfJobsId}`}
            />
            </div>
          ))}
          <FormComponent
            formName='customerForm'
            model={customerModel}
            formValue={customerForm.formData}
            onChange={handleForm}
          />
          <div className='d-flex'>
            <h5 className='mr-3'>{strings.operator}:</h5>
            {operatorModel.map(item => (
              <Checkbox
                label={item.value}
                selected={operatorId === item.id}
                onChange={() => updateOperatorId(item.id)}
                className='mb-3 mr-3'
                dataTest={`updateOperatorId${item.id}`}
              />
            ))}
          </div>
          {
            operatorId === 1 ?
              <div className='my-2'>
                  <h5 className=''>{strings.nameOfOperator}:</h5>
                  <FormComponent
                    formName='nameOfOperatorForm'
                    model={operatorNameModel}
                    formValue={nameOfOperatorForm.formData}
                    onChange={handleForm}
                  />
              </div>
              :
              operatorId === 2 &&
                <div className='my-2'>
                  <FormComponent
                    formName='nameOfPartnerOperatorForm'
                    model={nameOfPartnerOperatorModel}
                    formValue={nameOfPartnerOperatorForm.formData}
                    onChange={handleForm}
                  />
                </div>
          }


        </div>

        <div className='mb-4'>
          <h4 className='subtitle-loan mb-3'>{strings.attachments}</h4>
          <FileUploader
            mimeType='image/*,.pdf,.doc,.docx,.xls,.xlsx'
            files={attachments}
            onFileAdd={addAttachmentFile}
            onRemove={removeAttachmentFile}
            errorMessage={strings.allowedFormats}
          />
        </div>
      </div>
      <div className='col-12 col-md-6'>
        <SelectedKits selectedKits={selectedKits} goToCalendar={goToCalendar} handleDateChange={updateDateOfCartItem} />
      </div>
      <div className='col-md-6'>
        <div className='d-flex justify-content-between'>
          <Button
            bgFill={false}
            isGhost={true}
            iconName='arrow'
            iconPosition='left'
            iconStyle='arrow-left'
            styleClass='w-auto'
            value={strings.backToCalendar}
            onClick={goToLoanListPage}
          />
          <Button
            data-test='go-to-next-step-button'
            bgFill={true}
            iconName='arrow'
            styleClass='w-auto'
            value={strings.continueBooking}
            onClick={gotToCustomerStep}
          />
        </div>
      </div>
    </div>
  );
}

export default Assignment;
