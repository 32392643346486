import React, { useEffect, useMemo } from 'react';

import strings from 'resources/locales/Translate';

import Counter from '../Counter';
import StatisticsBlock from '../StatisticsBlock';

const StatisticsTab = ({
  outgoingStatistics,
  incomingStatistics,
  loanAvailabilityByStocks,
  country,
  businessGroup,
  stockArr,
  getStatistics,
  isActiveTab,
  period,
  counter,
}) => {
  const courseLoanAvailability = useMemo(() => loanAvailabilityByStocks.find(item => item.courseLoanAvailability), [loanAvailabilityByStocks]);
  const singleLoanAvailability = useMemo(() => loanAvailabilityByStocks.find(item => item.singleLoanAvailability), [loanAvailabilityByStocks]);
  const hasNoSelectedStock = stockArr.length === 0;
  const isShowCourseLoan = courseLoanAvailability || hasNoSelectedStock;
  const isShowSingleLoan = singleLoanAvailability || hasNoSelectedStock;

  useEffect(() => {
    if (!country || !businessGroup || !isActiveTab) return;

    getStatistics(country, businessGroup, stockArr, period);
  }, [country, stockArr, businessGroup, isActiveTab, period, getStatistics]);

  if (!isActiveTab) return null;

  return (
    <div className='container-fluid'>
      <div className='stock-dashboard-container'>
        <Counter counter={counter} />
        <div className='row'>
          <StatisticsBlock
            statistics={outgoingStatistics}
            isShowCourseLoan={isShowCourseLoan}
            isShowSingleLoan={isShowSingleLoan}
            title={strings.outgoingGoods}
            legend={strings.goodsDelivered}
          />
          <StatisticsBlock
            statistics={incomingStatistics}
            isShowCourseLoan={isShowCourseLoan}
            isShowSingleLoan={isShowSingleLoan}
            title={strings.incomingGoods}
            legend={strings.goodsConfirmed}
          />
        </div>
      </div>
    </div>
  );
}

export default StatisticsTab;
