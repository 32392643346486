import { useState, useEffect } from "react"
import { dismatleModel } from "./DismantleCheckListPopup.constants";
import Validators from "library/utilities/Validators";
import { validateForm } from 'library/utilities/ValidateForm.util';
import $ from 'jquery';

export const useDismantleCheckListPopup = ({
  id,
  user,
  usersList,
  saveMaintenanceNewCheckListForm,
  transactionId,
  maintenanceCheckListData,
  saveMantenanceCheckListData,
}) => {
  const { maintenanceChecklistId } = maintenanceCheckListData;
  const [dismantleForm, setDismantleForm] = useState({ formData: {} });
  const [isDismantleDemoKitConfirmation, setIsDismantleDemoKitConfirmation] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setDefaultData();
  }, [usersList]);

  const setDefaultData = () => {
    if (user && usersList && usersList.length > 0) {
      const loggedInUser = usersList.find(item => item.accountId === user.accountId);
      if (loggedInUser) {
        setDismantleForm({
          formData: {
            dismantleBy: loggedInUser.userId,
            dismantleByFullValue: loggedInUser,
          },
        });
      }
    }
  };

  const dismantleFormModel = () => {
    const model = [...dismatleModel];
    model[0].options = usersList;
    if (dismantleForm.formData.dismantleCompleteness === 'no') {
      model[2].validators = [{ check: Validators.required, message: 'requiredErrorMessage' }];
      model[2].isDisplayed = false;
      model[2].required = true;
    } else {
      model[2].validators = [];
      model[2].isDisplayed = true;
      model[2].required = false;
    }

    if (dismantleForm.formData.dismantleLaserSafety === 'no') {
      model[4].validators = [{ check: Validators.required, message: 'requiredErrorMessage' }];
      model[4].isDisplayed = false;
      model[4].required = true;
    } else {
      model[4].validators = [];
      model[4].isDisplayed = true;
      model[4].required = false;
    }

    if (dismantleForm.formData.dismantleElectronicSecurity === 'no') {
      model[6].validators = [{ check: Validators.required, message: 'requiredErrorMessage' }];
      model[6].isDisplayed = false;
      model[6].required = true;
    } else {
      model[6].validators = [];
      model[6].isDisplayed = true;
      model[6].required = false;
    }

    if (dismantleForm.formData.dismantleFileDeleted === 'no') {
      model[8].validators = [{ check: Validators.required, message: 'requiredErrorMessage' }];
      model[8].isDisplayed = false;
      model[8].required = true;
    } else {
      model[8].validators = [];
      model[8].isDisplayed = true;
      model[8].required = false;
    }

    return model;
  };

  const handleForm = ({ values, field, formName }) => {
    setDismantleForm({
      ...dismantleForm,
      formData: {
        ...dismantleForm.formData,
        ...values,
      },
    });
  };

  const onChangeCheckbox = (value, field) => {
    if (field === 'canBeShipped') {
      return setIsDismantleDemoKitConfirmation('CAN_BE_SHIPPED');
    }
    if (field === 'mustBeShipped') {
      return setIsDismantleDemoKitConfirmation('MUST_BE_SHIPPED');
    }
    if (field === 'discussionWithProdCenter') {
      return setIsDismantleDemoKitConfirmation('DISCUSSION_WITH_PRODUCTION_CENTER');
    }
  };

  const onSaveClick = async () => {
    const validForm = validateForm({
      form: dismantleForm,
      model: dismatleModel,
    });
    if (!validForm.isFormValid) {
      return setDismantleForm(validForm);
    }

    const {
      dismantleBy,
      dismantleCompleteness,
      dismantleCompletenessComment,
      dismantleLaserSafety,
      dismantleLaserSafetyComment,
      dismantleElectronicSecurity,
      dismantleElectronicSecurityComment,
      dismantleFileDeleted,
      dismantleFileDeletedComment,
    } = validForm.formData;

    const dataToSend = {
      dismantleBy: dismantleBy,
      dismantleCompleteness: dismantleCompleteness === 'yes' ? true : false,
      dismantleCompletenessComment: dismantleCompletenessComment,
      dismantleLaserSafety: dismantleLaserSafety === 'yes' ? true : false,
      dismantleLaserSafetyComment: dismantleLaserSafetyComment,
      dismantleElectronicSecurity: dismantleElectronicSecurity === 'yes' ? true : false,
      dismantleElectronicSecurityComment: dismantleElectronicSecurityComment,
      dismantleFileDeleted: dismantleFileDeleted === 'yes' ? true : false,
      dismantleFileDeletedComment: dismantleFileDeletedComment,
      dismantleDemoKitConfirmation: isDismantleDemoKitConfirmation,
    };
    setLoading(true);
    const res = await maintenanceChecklistId
      ? saveMantenanceCheckListData(dataToSend, 'dismantle', maintenanceChecklistId, transactionId)
      : saveMaintenanceNewCheckListForm(dataToSend, transactionId, 'dismantle');

    setLoading(false);
    closeClick();
  };

  const closeClick = () => {
    $(`#${id}`).modal('hide');
    setDefaultData();
  };

  return {
    dismantleForm,
    dismantleFormModel,
    handleForm,
    onChangeCheckbox,
    isDismantleDemoKitConfirmation,
    onSaveClick,
    closeClick,
    loading,
  };
};