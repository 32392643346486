import {
  FETCH_LOAN_TYPES_OF_JOBS,
  FETCH_LOAN_REASONS,
  FETCH_COUNTRIES,
  UPDATE_TYPES_OF_JOBS,
  UPDATE_OPERATOR_ID,
  UPDATE_LABS_LOCATION_KIT_LOAN_FORM,
  SET_ADDRESSES,
  SET_HELP_FOR_BUILDING_AND_DISMANTLING,
  SET_OPPORTUNITY,
  SET_OPPORTUNITIES,
  SET_ATTACHMENTS,
  CLEAR_FORMS,
  NEW_TYPES_OF_JOBS,
} from './LLKiLoan.constants';

const initialState = {
  loansTypesOfJobs: [],
  loanReasons: [],
  countries: [],
  opportunities: [],
  attachments: [],
  typesOfJobsId: [],
  operatorId: null,
  addresses: {},
  helpForBuildingAndDismantling: false,
  descriptionForm: {
    formData:{},
    isFormValid: false,
  },
  customerForm: {
    formData: {},
    isFormValid: false,
  },
  borrowerForm: {
    formData: {},
    isFormValid: false,
  },
  typeOfDeliveryAddressForm: {
    formData: {},
    isFormValid: false,
  },
  deliveryAddressForm: {
    formData: {},
    isFormValid: false,
  },
  helpForBuildingAndDismantlingForm: {
    formData: {},
    isFormValid: false,
  },
  nameOfOperatorForm: {
    formData: {},
    isFormValid: false,
  },
  nameOfPartnerOperatorForm: {
    formData: {},
    isFormValid: false,
  },
  newLabsLocationTypesOfJob: [],
};

const LLKitLoanReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOAN_TYPES_OF_JOBS:
      return {
        ...state,
        loansTypesOfJobs: action.payload
      };
    case FETCH_LOAN_REASONS:
      return {
        ...state,
        loanReasons: action.payload
      };
    case FETCH_COUNTRIES:
      return {
        ...state,
        countries: action.payload
      };
    case UPDATE_TYPES_OF_JOBS:
      return {
        ...state,
        typesOfJobsId: action.payload
      };
    case UPDATE_OPERATOR_ID:
      return {
        ...state,
        operatorId: action.payload,
      };
    case UPDATE_LABS_LOCATION_KIT_LOAN_FORM:
      return {
        ...state,
        ...action.payload,
      };
    case SET_ADDRESSES:
      return {
        ...state,
        addresses: action.payload,
      };
    case SET_HELP_FOR_BUILDING_AND_DISMANTLING:
      return {
        ...state,
        helpForBuildingAndDismantling: action.payload,
      }
    case SET_OPPORTUNITY:
      return {
        ...state,
        opportunities: action.payload,
      };
    case SET_OPPORTUNITIES:
      return {
        ...state,
        opportunities: action.payload,
      };
    case SET_ATTACHMENTS:
      return {
        ...state,
        attachments: action.payload,
      };
    case CLEAR_FORMS:
      return {
        ...initialState,
      };
      case NEW_TYPES_OF_JOBS:
        return {
          ...state,
          newLabsLocationTypesOfJob: action.payload
        };
    default:
      return state;
  }
};

export default LLKitLoanReducer;
