import { connect } from 'react-redux';
import Information from './Information.component';
import { updateZmccTransaction, fetchConnectedDemoLoans, updateZmccTransactionStatus, updateZmccInformationTab } from '../../ZmccTransactionDetails.actions'; 
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { fetchOperatorScheduleOfStockOnGivenDate } from '../../ZmccTransactionDetails.actions';
import { changeLoader } from 'library/common/commonActions/AppActionsActions';

const mapStateToProps = ({
  languageReducer,
  authReducer,
  zmccTransactionDetailsReducer,
  createSEMFormReducer,
  transactionDetailsReducer,
  bookingReducer,
}) => ({
  user: authReducer.user,
  language: languageReducer.language,
  zmmctransactionDetails: zmccTransactionDetailsReducer.zmccTransactionDetails,
  loansIntended: createSEMFormReducer.loansIntended,
  rmsZmccUser: zmccTransactionDetailsReducer.rmsZmccUser,
  zmccPrioritesArray: zmccTransactionDetailsReducer.zmccPrioritesArray,
  typesOfJobsList: transactionDetailsReducer.typesOfJobsList,
  zmccTransactionDetailsStatuses: zmccTransactionDetailsReducer.zmccTransactionDetailsStatuses,
  connectedTransactionArray: zmccTransactionDetailsReducer.connectedTransactionArray,
  zmccStockOperators: zmccTransactionDetailsReducer.zmccOperators,
  zmccSubscriberUsers: zmccTransactionDetailsReducer.zmccSubscriberUsers,
  systemCategoriesList: createSEMFormReducer.systemCategoriesList,
  sampleAt: zmccTransactionDetailsReducer.sampleAt
});

export default connect(mapStateToProps, {
  toggleActionMessage,
  updateZmccTransaction,
  fetchConnectedDemoLoans,
  updateZmccTransactionStatus,
  updateZmccInformationTab,
  fetchOperatorScheduleOfStockOnGivenDate,
  changeLoader
})(Information);
