import { connect } from 'react-redux';

import { fetchMasterData, fetchMasterDataPost } from 'library/common/commonActions/MasterDataActions';
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { updateLabsLocationTransactionDetails, updateTransactionStatus } from '../../TransactionDetailsActions';
import LLInformation from './LLInformation.component';

const mapStateToProps = ({ authReducer, languageReducer, masterDataReducer, transactionDetailsReducer }) => ({
  language: languageReducer,
  transactionDetails: transactionDetailsReducer.transactionDetails,
  countries: masterDataReducer.countries,
  reasonForLoan: masterDataReducer.reasonForLoan,
  user: authReducer.user,
  metadata: transactionDetailsReducer.metadata,
  returnGoodsBookingDetail: transactionDetailsReducer.returnGoodsBookingDetail,
  usersList: masterDataReducer.usersList,
  labsLocationCountries: transactionDetailsReducer.labsLocationCountries,
  typesOfJobsList: transactionDetailsReducer.typesOfJobsList,
  newTypesOfJobsList: transactionDetailsReducer.newTypesOfJobsList,
});

export default connect(mapStateToProps, {
  fetchMasterData,
  fetchMasterDataPost,
  updateLabsLocationTransactionDetails,
  updateTransactionStatus,
  toggleActionMessage,
})(LLInformation);
