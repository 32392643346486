import Validators from "library/utilities/Validators";

export const model = [
  {
    label: 'name',
    value: '',
    type: 'inputgroup',
    field: 'searchName',
    styleClass: 'col-12',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    inputGroupText: '',
    isAppend: false,
    validateOnBlur: false,
  },
  {
    label: 'descriptionText',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'searchDescription',
    validators: [],
    required: false,
    styleClass: 'col-12',
  },
];

export const transactionTypes = {
  MY_BOOKINGS: 'myBookings',
  OPEN_PROCESS: 'openProcesses',
  DEMO_REQUEST: 'loanRequests',
  CANCELLATION_PROCESS: 'cancelledProcesses',
  CANCELLATION_REQUESTS: 'cancellationRequest',
  ARCHIVED_REQUESTS: 'archiveProcessess',
};