import { connect } from 'react-redux';

import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';

import Administration from './LLPermissions.component';
import { saveData } from './LLPermissions.actions';

const mapStateToProps = ({ languageReducer, administrationReducer, appActionsReducer }) => ({
  language: languageReducer,
  administrationReducer: administrationReducer,
  appActions: appActionsReducer
});

export default connect(mapStateToProps, {
  saveData,
  toggleActionMessage
})(Administration);
