import { connect } from 'react-redux';

import {
  getBusinessUnits,
  getSystemClassesByBU,
  fetchKitsPictures,
  uploadKitsPicture,
  deleteKitsPicture,
  clearKitPictures,
} from './ManageKitPictures.actions';
import ManageKitPictures from './ManageKitPictures.component';

const mapStateToProps = ({ authReducer, languageReducer, appActionsReducer, kitPicturesReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
  appActions: appActionsReducer,
  businessUnits: kitPicturesReducer.businessUnits,
  systemClasses: kitPicturesReducer.systemClasses,
  kitImages: kitPicturesReducer.kitImages,
});

export default connect(mapStateToProps, {
  getBusinessUnits,
  getSystemClassesByBU,
  fetchKitsPictures,
  uploadKitsPicture,
  deleteKitsPicture,
  clearKitPictures,
})(ManageKitPictures);
