import Validators from 'library/utilities/Validators';

export const activeSystemModel = [
  {
    label: 'categorySystem',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'systemClassId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    selectAllOption: false,
    idKey: 'systemClassId',
    displayValue: [{ key: 'systemClassName', separator: '' }],
  },
  {
    label: 'systemMainComponent',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'systemMainComponentId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'systemMainComponentId',
    displayValue: [{ key: 'systemMainComponentName', separator: '' }],
  },
];

export const FETCH_ACTIVE_SYSTEM_LIST = 'FETCH_ACTIVE_SYSTEM_LIST';

export const itemsPerPage = {
  10: { id: 10, pages: 10 },
  20: { id: 20, pages: 20 },
  50: { id: 50, pages: 50 },
};

export const activeSystemsBreadcrumbData = [
  {
    name:'homepage',
    url: '/home'
  },
  {
    name:'activeSystems',
  },
];
