import React, { useEffect, useState } from 'react';
import get from 'lodash/get';

import { checkStockRoles } from 'library/utilities/checkRoles';
import { scrollToTop } from 'library/utilities/scrollActions';

export const useAttachments = ({
  user,
  attachments,
  deletedAttachmentIds,
  deleteAttachments,
  addAttachments,
  transactionDetails,
  uploadLoanAttachment,
  deleteLoansAttachments,
  fetchTransactionDetails,
  clearAttachments,
  toggleActionMessage,
}) => {
  const { transactionsId, borrower, stockInformation, transactionKit } = transactionDetails || {};
  const isAdmin = user && user.admin;
  const roles = ['Stock Controller', 'Dispatcher', 'Reconditioning'];
  const stockForDemoLoan = get(transactionKit, 'kitInformation.stockInformation');
  const stockForCourseOrSingleLoan = stockInformation || stockForDemoLoan;
  const stock = stockForCourseOrSingleLoan;
  const hasRoles = user && stock && checkStockRoles(roles, user, stock);
  const isBorrower = user && user.accountId === (borrower && borrower.accountId);
  const hasPermissions = isAdmin || hasRoles || isBorrower || true;
  const [viewType, setViewType] = React.useState('list');
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    return () => clearAttachments();
  }, [clearAttachments]);

  const toggleToListViewType = () => setViewType('list');
  const toggleToGridViewType = () => setViewType('grid');

  const addAttachmentFile = file => {
    const newAttachments = [...attachments];
    newAttachments.push(file[0]);
    addAttachments(newAttachments);
  };

  const deleteAttachmentFile = index => {
    const fileToRemove = attachments[index];
    const newDeletedAttachmentIds = [...deletedAttachmentIds];
    const newAttachments = attachments.filter((_, i) => i !== index);

    if (fileToRemove.transactionAttachmentsId) {
      newDeletedAttachmentIds.push(fileToRemove.transactionAttachmentsId);
    }

    deleteAttachments({
      attachments: newAttachments,
      deletedAttachmentIds: newDeletedAttachmentIds,
    });
  };

  const handleSaveAttachments = async () => {
    const currentAttachmentsLength = deletedAttachmentIds.length + attachments.length;
    const initialAttachmentsLength = transactionDetails.transactionAttachments && transactionDetails.transactionAttachments.length;
    if (currentAttachmentsLength === initialAttachmentsLength) return;

    setIsLoading(true);
    await uploadLoanAttachment(transactionsId);
    await fetchTransactionDetails();
    scrollToTop(500);
    toggleActionMessage(true, 'success', 'transactionAttachmentsUploadedSuccessfully');
    setIsLoading(false);
  };

  const handleDeleteAttachments = async () => {
    if (!deletedAttachmentIds.length) return;

    setIsLoading(true);
    await deleteLoansAttachments({
      transactionId: transactionsId,
      loanAttachmentIds: deletedAttachmentIds,
    });
    await fetchTransactionDetails();
    scrollToTop(500);
    toggleActionMessage(true, 'success', 'transactionAttachmentsDeletedSuccessfully');
    setIsLoading(false);
  };

  return {
    hasPermissions,
    viewType,
    isLoading,
    handleSaveAttachments,
    handleDeleteAttachments,
    addAttachmentFile,
    deleteAttachmentFile,
    toggleToListViewType,
    toggleToGridViewType,
  };
}
