import React from 'react';
import strings from 'resources/locales/Translate';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import { useStockFilter } from './StocksFilter.hook';
import ExpandableTable from 'library/common/commonComponents/Table/AppExpandableTable';

export const StocksFilter = () => {
    const { getCols } = useStockFilter()
    const onChange = () => {
        // Should be impleted in future
    }
    const stocksPartList = []
  return (
    <div className='container-fluid mt-3'>
      <div className='new-filter-body'>
        <div className='row col-lg-12'>
          <Dropdown
            label={strings.status}
            data={[]}
            field='status'
            idKey='id'
            displayValue={[{ key: 'value', separator: '' }]}
            isRequired={true}
            placeholder={strings.pleaseSelect}
            onChange={onChange}
            mainContainerStyle='col-lg-3 mb-3'
            // value={filters.year}
            // fullValue={filters.yearFullValue}
            newDropdownStyle={true}
            // error={filters.yearError}
          />
          <div className='col-lg-3'>
          <InputField
              label={strings.partName}
              field='partName'
              placeholder={strings.searchHere}
              isRequired={true}
              type='text'
              validators={[]}
            //   value={}
            //   error={}
              onChange={onChange}
              icon='search'
              appendIcon={false}
            />
          </div>
          <div className='col-lg-3'>
          <InputField
              label={strings.customerName}
              field='partName'
              placeholder={strings.searchHere}
              isRequired={true}
              type='text'
              validators={[]}
            //   value={}
            //   error={}
              onChange={onChange}
              icon='search'
              appendIcon={false}
            />
          </div>
          <div className='col-lg-3'>
          <InputField
              label={strings.requestId}
              field='partName'
              placeholder={strings.searchHere}
              isRequired={true}
              type='text'
              validators={[]}
            //   value={}
            //   error={}
              onChange={onChange}
              icon='search'
              appendIcon={false}
            />
          </div>
        </div>
      </div>
      {/* {stocksPartList && stocksPartList.length > 0 ? ( */}
          <div>
            <ExpandableTable
                cols={getCols()}
                rows={stocksPartList}
                dontShowExpandArrow ={true}
        />
          </div>
        {/* ) : (
          <div className='d-flex justify-content-center'>
            <strong>{strings.noActiveSystemsAvailable}</strong>
          </div> */}
        {/* )} */}
    </div>
  );
};

export default StocksFilter;
