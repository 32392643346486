import React from 'react';

import Attachments from 'library/common/commonComponents/KitAttachments';
import Accessories from 'library/common/commonComponents/Accessories';
import Components from 'library/common/commonComponents/Components';
import Questions from 'library/common/commonComponents/Questions';
import Messages from 'library/common/commonComponents/KitMessages';
import ServiceTicket from 'library/common/commonComponents/ServiceTicket';

import Information from './Components/Information';
import SalesDetails from './Components/SalesDetails';
import * as actionTypes from './KitForSaleDetails.constants';

export const manuallyKitDetailsTabs = ({
  kitDetails,
  history,
  isEditable,
  match,
  count,
  kitDetailsAttachments,
  questions,
  isShowSalesDetailsTab,
  transactionAttachments,
}) => {
  const editingKitURL = `/edit-manually-kit/${kitDetails.kitInformationId}`;

  let tabs = [
    {
      id: 1,
      tabTitle: 'information',
      className: '',
      render: () => {
        return (
          <Information kitDetail={kitDetails} history={history} isEditable={isEditable} editingKitURL={editingKitURL} />
        );
      },
    },
    {
      id: 2,
      tabTitle: 'components',
      className: '',
      render: () => {
        return (
          <Components kitDetail={kitDetails} history={history} isEditable={isEditable} editingKitURL={editingKitURL} />
        );
      },
    },
    {
      id: 3,
      tabTitle: 'accessories',
      className: '',
      render: () => {
        return (
          <Accessories kitDetail={kitDetails} history={history} isEditable={isEditable} editingKitURL={editingKitURL} />
        );
      },
    },
    {
      id: 4,
      tabTitle: 'messages',
      className: '',
      count,
      render: () => {
        return <Messages kitDetail={kitDetails} isEditable={isEditable} actionTypes={actionTypes} match={match} />;
      },
    },
    {
      id: 5,
      tabTitle: 'attachments',
      className: '',
      render: () => {
        return (
          <Attachments
            kitDetail={kitDetails}
            kitDetailsAttachments={kitDetailsAttachments}
            transactionAttachments={transactionAttachments}
            hasTransactionAttachmentsSection={!kitDetails.kitManualCreated}
          />
        );
      },
    },
    {
      id: 6,
      tabTitle: 'serviceTicket',
      className: '',
      render: () => {
        return <ServiceTicket kitDetail={kitDetails} />;
      },
    },
    {
      id: 7,
      tabTitle: 'questions',
      className: '',
      render: () => {
        return <Questions kitDetail={kitDetails} questions={questions} actionTypes={actionTypes} />;
      },
    },
  ];

  if (isShowSalesDetailsTab) {
    tabs.push({
      id: 8,
      tabTitle: 'salesDetails',
      className: '',
      render: () => {
        return (
          <SalesDetails
            kitDetail={kitDetails}
            history={history}
            isEditable={isEditable}
            editingKitURL={editingKitURL}
          />
        );
      },
    });
  }

  return tabs;
};

export const singleItemDetailsTabs = ({
  kitDetails,
  questions,
  history,
  isEditable,
  kitDetailsAttachments,
  isShowSalesDetailsTab,
}) => {
  const editingKitURL = `/edit-single-item/${kitDetails.kitInformationId}`;

  const tabs = [
    {
      id: 1,
      tabTitle: 'information',
      className: '',
      render: () => {
        return (
          <Information kitDetail={kitDetails} history={history} isEditable={isEditable} editingKitURL={editingKitURL} />
        );
      },
    },
    {
      id: 2,
      tabTitle: 'attachments',
      className: '',
      render: () => {
        return <Attachments kitDetail={kitDetails} kitDetailsAttachments={kitDetailsAttachments} />;
      },
    },
    {
      id: 3,
      tabTitle: 'questions',
      className: '',
      render: () => {
        return <Questions kitDetail={kitDetails} questions={questions} actionTypes={actionTypes} />;
      },
    },
  ];

  if (isShowSalesDetailsTab) {
    tabs.push({
      id: 4,
      tabTitle: 'salesDetails',
      className: '',
      render: () => {
        return (
          <SalesDetails
            kitDetail={kitDetails}
            history={history}
            isEditable={isEditable}
            editingKitURL={editingKitURL}
          />
        );
      },
    });
  }

  return tabs;
};
