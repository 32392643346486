import { NavLink } from 'react-router-dom';
import React, { Component } from 'react';

import { ReactComponent as Logo } from 'resources/images/zeiss-logo.svg';
import strings from 'resources/locales/Translate';
import Button from 'library/common/commonComponents/Button/Button';
import Icon from 'library/common/commonComponents/Icon/Icon';

import OffCanvasMenu from './Components/index';
import './mobileMenu.styles.scss';

export default class MobileMenu extends Component {
  state = {
    isDrawerOpen: false
  };

  openDrawerMenu = () => {
    this.setState(prevState => ({isDrawerOpen: !prevState.isDrawerOpen}));
  };

  openNotificationsBar = () => {
    const { openOrHideNotificationsBar } = this.props;

    openOrHideNotificationsBar(true);
  };

  render() {
    const { notifications } = this.props;
    const { isLoggedIn, user } = this.props.authentication;
    const { isDrawerOpen } = this.state;
    const isShowNotificationsCount = isLoggedIn && notifications.length > 0;
    const notificationsCount = notifications.filter(notification => notification.status === 'UNREAD').length; 

    return (
      <div className='mobile-menu d-flex justify-content-between align-items-center'>

        <OffCanvasMenu marginLeft={isDrawerOpen ? 0 : -320} onMenuClose={this.openDrawerMenu}/>

        <Logo width={48} height={48}/>
        <div className='menu-options user-menu'>
          <ul className='nav'>
            <li className='nav-item cursor-pointer dropdown'>
              {isLoggedIn ? (
                <p className='cursor-pointer dropdown-toggle' id='dropdownMenuButton'
                   data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                  <Icon name='person' width={32} height={32} fill={'#0088d0'}/>
                </p>
              ) : (
                <p className='cursor-pointer' data-toggle='modal' data-target='#mobileModal'>
                  <Icon name='person' width={32} height={32} fill={'#0088d0'}/>
                </p>
              )}

              {user && (
                <div className='dropdown-menu shadow rounded-0' aria-labelledby='dropdownMenuButton'>
                  <a href='https://id-test.zeiss.com/Profile/Index' target='_blank' rel='noopener noreferrer'
                     className='dropdown-item'>{strings.zeissIdPortal}</a>
                  <NavLink exact={true} to={'/delivery-addresses'}
                           className='dropdown-item'>{strings.deliveryAddresses}</NavLink>
                  <Button
                    bgFill={false}
                    value={strings.logout}
                    styleClass='mt-3'
                    onClick={() => this.props.logoutUser(this.props.history)}/>
                </div>
              )}

            </li>
            <li className='nav-item cursor-pointer'><Icon name='cart' width={32} height={32} fill={'#0088d0'}/></li>
            <li className='nav-item'>
              <div className='cursor-pointer position-relative' onClick={isLoggedIn ? this.openNotificationsBar : null}>
                <Icon name='bell' width={24} height={24} viewBox='0 0 18 24' fill={'#0088d0'} />
                {isShowNotificationsCount && <div className='notifications-badge'>{notificationsCount}</div>}
              </div>
            </li>
            <li className='nav-item cursor-pointer' onClick={this.openDrawerMenu}>
              <Icon name='burgerMenu' width={32} height={32} fill={'#0088d0'}/>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}
