import { useEffect, useState, useCallback } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { fetchFromStorage } from 'library/utilities/storage';

import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import * as actionTypes from 'library/common/commonConstants/storeConstants';
import { validateForm } from 'library/utilities/ValidateForm.util';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import Validators from 'library/utilities/Validators';
import { exportDataModel } from './ExportData.constants';

export const useExportData = ({
  businessUnit,
  fetchMasterData,
  exportTransactionList,
  exportKitList,
  exportKitListWithCountry,
  fetchMasterDataService,
  exportTransactionListWithCountry,
  language
}) => {
  const [exportDataForm, setFormData] = useState({
    formData: {},
    isFormValid: false,
  });
  const [isTransactionLoading, setIsTransactionLoading] = useState(false);
  const [isKitLoading, setIsKitLoading] = useState(false);
  const [userCountries, setUserCountries] = useState([]);

  const user = fetchFromStorage(identifiers.user);

  useEffect(() => {
    if (businessUnit && !businessUnit.length) {
      fetchMasterData(URLS.businessUnitList, actionTypes.FETCH_BUSINESS_UNIT_LIST);
    }
  }, [businessUnit, fetchMasterData]);
  const handleFetchCountries = async () => {
    const {data} = await fetchMasterDataService(URLS.userCountries);
    setUserCountries(data);
  }

  useEffect(() => {
    handleFetchCountries()
  }, [language]);

  const handleForm = useCallback(({ values }) => {
    setFormData({
      ...exportDataForm,
      formData: {
        ...exportDataForm.formData,
        ...values,
      },
    });
  }, [exportDataForm]);

  const handleExportTransactionData = useCallback(async () => {
    const validForm = validateForm({ form: exportDataForm, model: exportDataModel });

    if (!validForm.isFormValid) {
      return setFormData(validForm);
    }

    setIsTransactionLoading(true);
    let buNames = [];
    if (exportDataForm.formData && exportDataForm.formData.businessUnitFullValue instanceof Array) {
      for (let index = 0; index < exportDataForm.formData.businessUnitFullValue.length; index++) {
        const buUnit = exportDataForm.formData.businessUnitFullValue[index];
        buNames[index] = buUnit.name;
      }
    } else {
      buNames[0] = exportDataForm.formData.businessUnitFullValue.name;
    }
    let buName = buNames.join(",");
    if ((exportDataForm.formData.businessUnit != undefined  && exportDataForm.formData.businessUnit != '')  && (exportDataForm.formData.country != undefined && exportDataForm.formData.country != '')){
      await exportTransactionListWithCountry(buName,exportDataForm.formData.country)
     } else{
      await exportTransactionList(buName);
    }
    setIsTransactionLoading(false);
  }, [exportDataForm, exportTransactionList]);

  const handleExportKitData = useCallback(async () => {
    const validForm = validateForm({ form: exportDataForm, model: exportDataModel });
    if (!validForm.isFormValid) {
      return setFormData(validForm);
    }

    setIsKitLoading(true);
    let buNames = [];
    if (exportDataForm.formData && exportDataForm.formData.businessUnitFullValue instanceof Array) {
      for (let index = 0; index < exportDataForm.formData.businessUnitFullValue.length; index++) {
        const buUnit = exportDataForm.formData.businessUnitFullValue[index];
        buNames[index] = buUnit.name;
      }

    } else {
      buNames[0] = exportDataForm.formData.businessUnitFullValue.name;
    }
    let buName = buNames.join(",");
    if ((exportDataForm.formData.businessUnit != undefined  && exportDataForm.formData.businessUnit != '')  && (exportDataForm.formData.country != undefined && exportDataForm.formData.country != '')){
      await exportKitListWithCountry(buName,exportDataForm.formData.country)
    } else{
      await exportKitList(buName);
    }
    setIsKitLoading(false);
  }, [exportDataForm, exportKitList]);

  const getExportDataModel = useCallback(() => {
    const newExportDataModel = [...exportDataModel];

    if (user && user.admin){
      newExportDataModel[1].required = false;
      newExportDataModel[1].validators =[];
    } else{
      newExportDataModel[1].required = true;
      newExportDataModel[1].validators =[{ check: Validators.required, message: 'requiredErrorMessage' }];
    }

    if (businessUnit && businessUnit.length) {
      newExportDataModel[0].options = businessUnit;
    }

    if(userCountries && userCountries.length){
      newExportDataModel[1].options = userCountries;
    }

    return newExportDataModel;
  }, [businessUnit,userCountries]);

  const model = getExportDataModel();

  return {
    exportDataForm,
    model,
    isTransactionLoading,
    isKitLoading,
    handleForm,
    handleExportTransactionData,
    handleExportKitData,
    getExportDataModel,
  };
}
