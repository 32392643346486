import React from 'react';
import strings from 'resources/locales/Translate';
import './EnhancedCircleRadioButton.styles.scss';

export const EnhancedCircleRadioButton = ({ data, dataTest, disabled, error, field, label, onChange, isRequired, value}) => {
  const onValueChange = value => () => {
    onChange(value, field);
  };

  return (
    <div className='' data-test={dataTest}>
      <div className='label-container'>
      
      <div className='mb-2'>{label} {isRequired && label && <span className='mandatory-asterik'>*</span>} </div>
      <div className='btn-group' role='group' aria-label='Basic example'>
        {data.map((item, key) => (
          <button
            disabled={disabled}
            key={key}
            type='button'
            className={`btn text-left rounded-0  ${error && 'input-error'}`}
            onClick={onValueChange(item.id)}
        >
         <div className='radio-button-flex'><div className={`circle-before ${value === item.id ? 'active' : ''}`}>
          {
            value === item.id &&
            <div className='tick-mark'></div>
          }
          </div> {strings[item.value]}</div>
        </button>
        ))}
      </div>
      </div>
    </div>
  );
};

EnhancedCircleRadioButton.defaultProps = {
  value: '',
  disabled: false,
  labelPosition: 'top'
};

export default EnhancedCircleRadioButton;