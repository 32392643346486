import React from 'react';

import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import CreateStockActionButtons from 'library/common/commonComponents/CreateStockActionButtons';

import { freeAccessoriesModel } from './Accessories.constants';
import Section from '../Section/Section';

const TAB_ID = 3;

export const CreateKitAccessories = (props) => {
  const { formValue, handleForm, saveKit, cancelCreateKit } = props;
  const { freeAccessoriesForm } = formValue;

  const handleSaveKit = () => {
    saveKit(TAB_ID);
  };

  return (
    <div className='information-container mb-5'>
      <div className='container-fluid form-container'>
        <div className='row'>
          <div className='col-sm-12'>
            <Section sectionTitle={strings.accessories} containerClass={"pt-4"} >
              <FormComponent
                model={freeAccessoriesModel}
                formName='freeAccessoriesForm'
                formValue={freeAccessoriesForm.formData}
                onChange={handleForm}
              />
            </Section>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <CreateStockActionButtons onSave={handleSaveKit} onCancel={cancelCreateKit} styleClass={'create-kit-button mt-3'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateKitAccessories;
