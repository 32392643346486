import React from 'react';
import toArray from 'lodash/toArray';

import strings from 'resources/locales/Translate';
import ItemsPerPage from 'library/common/commonComponents/ItemsPerPage/ItemsPerPage';
import ExpandableTable from 'library/common/commonComponents/Table/ExpandableTable';
import SearchField from 'library/common/commonComponents/SearchField/SearchField';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import Pagination from 'library/common/commonComponents/Pagination/Pagination';
import Button from 'library/common/commonComponents/Button/Button';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import ConfirmDeletionPopup from 'library/common/commonComponents/ConfirmationBox';

import { itemsPerPage, dusdTaskListBreadcrumbData  } from './DusdTask.constants';
import DataExpandableTasksTable from './Components/DataExpandableTasksTable';
import CreateOrEditTaskPopup from './Components/CreateOrEditTaskPopup';
import TasksFilter from './Components/TasksFilter';
import { useDusdTask } from './DusdTask.hook';
import './DusdTask.styles.scss';
const DusdTask = ({
  user,
  categoriesAndSystems,
  fetchMasterData,
  appActions,
  fetchTaskListData,
  tasksList,
  createOrEditDudsTask,
  removeDudsTask,
  history,
  fetchSystemClassDataForPopup,
  popUpSystemClassData,
}) => {
  document.title = 'DUSD Task';
  const { isLoading, displayActionMessage, type, message } = appActions;
  const {
    isShowFilter,
    data,
    handleSearchChange,
    showOrHideFilter,
    handleFilterChange,
    resetFilters,
    handleSort,
    handleItemsPerPage,
    handlePageChange,
    systemsClass,
    getTableRows,
    deletionError,
    isLoadingDeletion,
    openDeleteTaskPopup,
    hideDeleteTaskPopup,
    confirmTaskDeletion,
    selectedTask,
    setSelectedTask,
    openCreateTaskPopup,
    openEditTaskPopup,
    goToListOfStatuses,
    isShowStatusButton,
    popUpSystemData,
  } = useDusdTask({
    fetchMasterData,
    categoriesAndSystems,
    fetchTaskListData,
    removeDudsTask,
    history,
    user,
    fetchSystemClassDataForPopup,
    popUpSystemClassData,
  });

  const isTasksList = tasksList && tasksList.content && tasksList.content.length > 0;

  return (
    <div>
      {displayActionMessage && <ActionMessage type={type} message={strings[message]} />}
      <div className='container-fluid mb-3'>
        <Breadcrumbs data={dusdTaskListBreadcrumbData} />
        <div className='row no-gutters align-items-center'>
          <h1 className='col-md col-12 kit-title'>{strings.dusdTask}</h1>
          <div className='col-md-auto col mr-3'>
            <div className='input-wrapper'>
              <SearchField onChange={handleSearchChange} initialValue={data.filter.searchTerm} />
            </div>
          </div>
          <div className='col-auto'>
            <Button
              dataTest='shop-list-filter-button'
              bgFill={false}
              iconName='filter'
              onClick={showOrHideFilter}
              value={isShowFilter ? strings.hideFilter : strings.showFilter}
              styleClass='filter-button'
            />
          </div>
        </div>
      </div>
      <TasksFilter
        isShowFilter={isShowFilter}
        systemsClass={systemsClass}
        handleFilterChange={handleFilterChange}
        selected={data.filter}
        resetFilters={resetFilters}
      />
      <div className='task-table'>
        <div className='container-fluid mb-3'>
          <div className='d-flex justify-content-between mb-3'>
            <h5 className='font-bold'>{strings.tasks}</h5>
            {isShowStatusButton && (
              <Button
                bgFill={false}
                iconName='plus'
                onClick={openCreateTaskPopup}
                value={strings.addTask}
                styleClass='w-auto'
              />
            )}
          </div>
          {isTasksList ? (
            <ExpandableTable
              user={user}
              cols={getTableRows()}
              rows={tasksList.content}
              sortFields={data.sort}
              handleSort={handleSort}
            >
              <DataExpandableTasksTable
                openDeleteTaskPopup={openDeleteTaskPopup}
                openEditTaskPopup={openEditTaskPopup}
                goToListOfStatuses={goToListOfStatuses}
                isShowStatusButton={isShowStatusButton}
              />
            </ExpandableTable>
          ) : (
            <div className='d-flex justify-content-center'>
              <strong>{strings.noDataFoundText}</strong>
            </div>
          )}
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row no-gutters'>
          {isTasksList ? (
            <div className='col d-flex justify-content-end mb-3'>
              <ItemsPerPage
                itemsPerPage={toArray(itemsPerPage)}
                handleItemsPerPage={handleItemsPerPage}
                value={itemsPerPage[data.size]}
              />
              <Pagination
                currentPage={data.page - 1}
                totalPages={tasksList.totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          ) : null}
        </div>
      </div>
      <ConfirmDeletionPopup
        id='confirm-delete-task-modal'
        onConfirm={confirmTaskDeletion}
        onClose={hideDeleteTaskPopup}
        error={deletionError}
        isLoading={isLoadingDeletion}
        title={strings.deleteTask}
        subtitle={strings.areYouSureYouWantToDeleteTask}
      />
      <CreateOrEditTaskPopup
        id='create-task-modal'
        systemClasses={popUpSystemData}
        selectedTask={selectedTask}
        createOrEditDudsTask={createOrEditDudsTask}
        setSelectedTask={setSelectedTask}
        filterData={data}
      />
      <CreateOrEditTaskPopup
        id='edit-task-modal'
        systemClasses={systemsClass}
        selectedTask={selectedTask}
        createOrEditDudsTask={createOrEditDudsTask}
        setSelectedTask={setSelectedTask}
        filterData={data}
        hasEditMode={true}
      />
      {isLoading && <div className='loader' />}
    </div>
  );
}

export default DusdTask;
