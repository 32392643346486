import React from 'react';

import strings from 'resources/locales/Translate';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import Table from 'library/common/commonComponents/Table/Table';
import Pagination from 'library/common/commonComponents/Pagination/Pagination';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import Button from 'library/common/commonComponents/Button/Button';

import Filters from './Components/Filters';
import DeleteButton from './Components/DeleteButton';
import SetAllPermissionsDropdown from './Components/SetAllPermissionsDropdown';
import SaveChangesPopup from './Components/SaveChangesPopup';
import { userPermissionsBreadcrumbData } from './UserPermissions.constants';
import { useUserPermissions } from './UserPermissions.hook';
import './userPermissions.styles.scss';
import { getIQSSscDeRoles, getllStockRoles, getRMSZMCCStockRoles } from 'library/common/commonConstants/roles';

const UserPermissions = ({
  userPermissions,
  getInitialData,
  fetchRolesList,
  fetchUserPermissions,
  deleteAllPermissionsForAllStocks,
  updateAllUserPermissionsForStosk,
  updateDifferentUserPermissionsForStosk,
  businessUnits,
  countries,
  users,
  roles,
  language,
  appActions,
  clearUserPermissions,
  getCountriesByBusinessUnit,
}) => {
  const {
    state,
    tableCols,
    isShowFilter,
    hasUserPermissions,
    isDisabledUpdates,
    rolesWithoutAdmin,
    permissionList,
    isDisabledSaveCancelButtons,
    globalRole,
    loading,
    handleSavePermissionForStock,
    setGlobalRole,
    toggleFilterVisibility,
    handleSort,
    handlePageChange,
    handleFilter,
    handleCancelButton,
    handleCancelButtonOnPopup,
    updatePage,
    selectedBusinessUnitType
  } = useUserPermissions({
    userPermissions,
    getInitialData,
    fetchRolesList,
    fetchUserPermissions,
    roles,
    language,
    updateDifferentUserPermissionsForStosk,
    clearUserPermissions,
    getCountriesByBusinessUnit,
  });
  const { displayActionMessage, type, message, showAsIs } = appActions;

  return (
    <div className='userPermissions-container'>
      {displayActionMessage && <ActionMessage type={type} message={showAsIs ? message : strings[message]} />}
      <div className='container-fluid mb-3'>
        <Breadcrumbs data={userPermissionsBreadcrumbData} />
        <div className='row no-gutters align-items-center'>
          <h1 className='col-sm kit-title'>{strings.userPermissions}</h1>
          <div className='col-sm-auto mt-3 mt-sm-0'>
            <Button
              dataTest='permissions-filter-button'
              bgFill={false}
              iconName='filter'
              onClick={toggleFilterVisibility}
              value={isShowFilter ? strings.hideFilter : strings.showFilter}
              styleClass='filter-button'
            />
          </div>
        </div>
      </div>
      <Filters
        isShowFilter={isShowFilter}
        handleFilter={handleFilter}
        filters={state.filters}
        businessUnits={businessUnits}
        countries={countries}
        users={users}
      />
      <div className='container-fluid'>
        <div className='d-flex justify-content-between mb-5'>
          <DeleteButton
            disabled={isDisabledUpdates}
            state={state}
            deleteAllPermissionsForAllStocks={deleteAllPermissionsForAllStocks}
            setGlobalRole={setGlobalRole}
          />
          <SetAllPermissionsDropdown
            rolesWithoutAdmin={selectedBusinessUnitType === "RMS_ZMCC" ? getRMSZMCCStockRoles() : selectedBusinessUnitType === 'IQS_SSC_DE' ? getIQSSscDeRoles() : selectedBusinessUnitType === 'SPECIAL'? getllStockRoles() :rolesWithoutAdmin}
            isDisabledUpdates={isDisabledUpdates}
            state={state}
            globalRole={globalRole}
            setGlobalRole={setGlobalRole}
            updateAllUserPermissionsForStosk={updateAllUserPermissionsForStosk}
          />
        </div>
        {hasUserPermissions && (
          <>
            <Table
              cols={tableCols}
              rows={permissionList}
              handleSort={handleSort}
              sortFields={state.sort}
            />
            <div className='d-flex justify-content-between mb-3'>
              <div className='d-flex'>
                <Button
                  bgFill={false}
                  iconName='cross'
                  onClick={handleCancelButton}
                  value={strings.cancel}
                  disabled={isDisabledSaveCancelButtons}
                  styleClass='w-auto mr-3'
                />
                <Button
                  bgFill={true}
                  iconName='save'
                  onClick={handleSavePermissionForStock}
                  value={strings.save}
                  disabled={isDisabledSaveCancelButtons}
                  loading={loading}
                  styleClass='w-auto'
                />
              </div>
              <Pagination
                currentPage={state.page - 1}
                totalPages={userPermissions.totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </>
        )}
      </div>
      <SaveChangesPopup
        id='saveChangesPopup'
        handleSavePermissionForStock={handleSavePermissionForStock}
        handleCancelButton={handleCancelButtonOnPopup}
        loading={loading}
        updatePage={updatePage}
      />
    </div>
  );
}

export default UserPermissions;
