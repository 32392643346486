import { useState, useEffect, useCallback } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import $ from 'jquery';

export const useCalculationMethodTab = props => {
  const {
    getContinentsList,
    getCalculationList,
    language,
    calculationList,
    updateCalculations,
    clearCalculationList,
  } = props;
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedContinent, setSelectedContinent] = useState({});
  const [copyCalculationList, setCopyCalculationList] = useState([]);
  const [listOfChangedValues, setListOfChangedValues] = useState([]);

  const disabledSaveBtn = !listOfChangedValues.length;
  const popupId = '#calculationMethodPopup';

  useEffect(() => {
    return () => clearCalculationList();
  }, [clearCalculationList]);

  useEffect(() => {
    getContinentsList();
  }, [getContinentsList, language]);

  useEffect(() => {
    if (selectedContinent.value) {
      getCalculationList(selectedContinent.value);
    }
  }, [selectedContinent, getCalculationList, language]);

  useEffect(() => {
    if (calculationList.length) {
      setCopyCalculationList(calculationList);
    }
  }, [calculationList]);

  const onEdit = () => {
    setIsEditMode(prevState => !prevState);
    setListOfChangedValues([]);
    setCopyCalculationList(calculationList);
  };

  const updateCalculationList = useCallback((index, inputValue, operatorKey) => {
    const newCalculationList = cloneDeep(copyCalculationList);

    newCalculationList[index] = {
      ...newCalculationList[index],
      [operatorKey]: {
        ...newCalculationList[index][operatorKey],
        value: inputValue,
      },
    };

    setCopyCalculationList(newCalculationList);
  }, [copyCalculationList]);

  const updateListOfChangedValues = useCallback((index, inputValue, operatorKey) => {
    const newListOfChangedValues = cloneDeep(listOfChangedValues);
    const typesOfJobsId = copyCalculationList[index].typesOfJobsResponseDTO.tyesOfJobsId;
    const systemClassificationId = copyCalculationList[index].typesOfJobsResponseDTO.systemClassificationId;
    const operator = copyCalculationList[index][operatorKey].operator;
    if (systemClassificationId) {
      const hasValues = newListOfChangedValues.find(
        item => (typesOfJobsId === item.typeOfJobs) && (operator === item.operator) &&  (systemClassificationId === item.systemClassificationId),
      );
  
      if (hasValues) {
        newListOfChangedValues.forEach(item => {
          if ((typesOfJobsId === item.typeOfJobs) && (operator === item.operator)  &&  (systemClassificationId === item.systemClassificationId)) {
            item.value = inputValue || 0;
          }
        });
      } else {
        newListOfChangedValues.push({
          typeOfJobs: copyCalculationList[index].typesOfJobsResponseDTO.tyesOfJobsId,
          operator: copyCalculationList[index][operatorKey].operator,
          value: inputValue || 0,
          systemClassificationId: copyCalculationList[index].typesOfJobsResponseDTO.systemClassificationId
        });
      }
    } else {
      const hasValues = newListOfChangedValues.find(
        item => (typesOfJobsId === item.typeOfJobs) && (operator === item.operator),
      );
  
      if (hasValues) {
        newListOfChangedValues.forEach(item => {
          if ((typesOfJobsId === item.typeOfJobs) && (operator === item.operator)) {
            item.value = inputValue || 0;
          }
        });
      } else {
        newListOfChangedValues.push({
          typeOfJobs: copyCalculationList[index].typesOfJobsResponseDTO.tyesOfJobsId,
          operator: copyCalculationList[index][operatorKey].operator,
          value: inputValue || 0,
          systemClassificationId: copyCalculationList[index].typesOfJobsResponseDTO.systemClassificationId
        });
      }
    }
    

    setListOfChangedValues(newListOfChangedValues);
  }, [copyCalculationList, listOfChangedValues]);

  const handleInput = useCallback((index, inputValue, operatorKey) => {
    const regex = /^(\d+(\.\d{0,3})?|\.?\d{1,2})?$/; // contains a positive integer or floating point number up to three characters after the decimal point. Example: 1, 1.1, 1.11, 1.111
    const isValid = regex.test(inputValue);

    if (isValid) {
      updateCalculationList(index, inputValue, operatorKey);
      updateListOfChangedValues(index, inputValue, operatorKey);
    }
  }, [updateCalculationList, updateListOfChangedValues]);

  const handleDropdown = useCallback((value, _, fullValue) => {
    setSelectedContinent({
      value,
      fullValue,
    });

    if (listOfChangedValues.length) {
      setListOfChangedValues([]);
    }
  }, [listOfChangedValues]);

  const onSave = useCallback(async (continent, isForAll) => {
    setIsLoading(true);
    const { success } = await updateCalculations(listOfChangedValues, continent, isForAll);
    setIsLoading(false);

    if (success) {
      setListOfChangedValues([]);
      setIsEditMode(false);
      onClosePopup();
    }
  }, [listOfChangedValues, updateCalculations]);

  const onOpenPopup = () => {
    if ($(popupId) && $(popupId.modal)) {
      $(popupId).modal('show');
    }
  };

  const onClosePopup = () => {
    if ($(popupId) && $(popupId.modal)) {
      $(popupId).modal('hide');
    }
  };

  return {
    isEditMode,
    isLoading,
    disabledSaveBtn,
    selectedContinent,
    copyCalculationList,
    listOfChangedValues,
    updateCalculationList,
    updateListOfChangedValues,
    onEdit,
    handleInput,
    handleDropdown,
    onSave,
    onOpenPopup,
    onClosePopup
  };
}
