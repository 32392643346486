import React, { useEffect } from 'react';
import $ from 'jquery';
import strings from 'resources/locales/Translate';
import { saveToStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import Button from 'library/common/commonComponents/Button/Button';
import { images } from 'library/common/commonConstants/ImageConstants';

export const ZmccCrmRequestPopup = ({history}) => {
  useEffect(() => {
      $('#zmcc-Crm').modal('show');
  }, []);

 
  const navigateToForm =(formname) =>{
    $(`#${'zmcc-Crm'}`).modal('hide');
    saveToStorage(identifiers.showCrmPopup, false);
    history.push(`/${formname}`);
    window.location.reload();
  }

  return (
    <div
      className='modal fade modal-container'
      id={'zmcc-Crm'}
      tabIndex='-1'
      role='dialog'
      aria-labelledby={'zmcc-Crm'}
      aria-hidden='true'
      data-backdrop='static'
    >
      <div className='modal-dialog zmcc-crm-popup-width' role='document'>
        <div className='modal-content rounded-0 '>
          <div className='modal-body p-0'>
            <div className='content' id='app-scheduler-wrapper'>
                <p className='mb-3'>{strings.selectCategory}</p>
                <div className='image-button-align mb-4'>
                <img src={images.lmImage} className='category-image-size'></img>
                <Button bgFill={true} value='LM' iconName='' onClick={() =>navigateToForm('LM')} styleClass='category-button-size'/>
                </div>
                <div className='image-button-align mb-4'>
                <img src={images.semImage}  className='category-image-size'></img>
                <Button bgFill={true} value='SEM' iconName='' onClick={() =>navigateToForm('SEM')} styleClass='category-button-size'/>
                </div>
                <div className='image-button-align mb-4'>
                <img src={images.xbImage} className='category-image-size'></img>
                <Button bgFill={true} value='XB' iconName='' onClick={() =>navigateToForm('XB')} styleClass='category-button-size'/>
                </div>
                <div className='image-button-align mb-4'>
                <img src={images.xrmImage}  className='category-image-size'></img>
                <Button bgFill={true} value='XRM' iconName='' onClick={() =>navigateToForm('XRM')} styleClass='category-button-size'/>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZmccCrmRequestPopup;