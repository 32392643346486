import strings from 'resources/locales/Translate';
import React from 'react';
import Modal from 'library/common/commonComponents/Modal';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import { useLoanOrderPopup } from './CreateNewLoanOrderPopup.hook';
import { connect } from 'react-redux';
import {
  saveCreateLoanorderData,
  getSapErpMappingData,
  getSapErpCustomerAccounts,
  getMedUsSearchCusmoter,
} from '../../TransactionDetailsActions';
import Button from 'library/common/commonComponents/Button/Button';
import { emailModel, searchCustomerFeildsModel } from './CreateNewLoanOrderPopup.constants';
import Table from 'library/common/commonComponents/Table/Table';

const CreateNewLoanOrderPopup = props => {
  const { id, showReturnLoanOrderPopup, transactionMaterialNumber, isEditView } = props;
  const {
    model,
    handleForm,
    onSave,
    loanOrderForm,
    isLoading,
    onCloseClick,
    sapErpMappingData,
    emailForm,
    searchWithCustomer,
    showEmailField,
    searchForSapCustomerAccounts,
    getTableRows,
    searchLoading,
    error,
    showExtraSearchCustomerFileds,
    serachCustomerFeildsForm,
    enableButton,
    medUsSearchCustomer,
    getmedUsCustomerDataRows,
    medUsCustomerData,
  } = useLoanOrderPopup(props);
  let showTableData = sapErpMappingData && sapErpMappingData.length > 1;
  return (
    <Modal
      id={id}
      title={showReturnLoanOrderPopup ? strings.createReturnLoanOrder : strings.createLoanOrder}
      confirmAction={onSave}
      confirmTitle={isEditView ? strings.resendRequest : strings.saveToSap}
      titleOfCancel={strings.cancel}
      loading={isLoading}
      disableBackgroundClose
      onCloseClick={onCloseClick}
      className='create-new-loanorder'
    >
      <FormComponent model={model} formName='loanOrderForm' formValue={loanOrderForm.formData} onChange={handleForm} />
      {showEmailField && !showExtraSearchCustomerFileds && (
        <>
          <Button
            styleClass='col-2'
            bgFill={false}
            value={strings.search}
            iconName='search'
            onClick={searchForSapCustomerAccounts}
            loading={searchLoading}
          />
          <div className='mt-3'>
            <FormComponent
              model={emailModel}
              formName='emailForm'
              formValue={emailForm.formData}
              onChange={handleForm}
            />
          </div>
        </>
      )}
      {!showEmailField && (
        <Button
          styleClass='col-3 mt-2'
          bgFill={false}
          value={strings.searchCustomer}
          iconName='search'
          onClick={searchWithCustomer}
        />
      )}

      {showEmailField && showExtraSearchCustomerFileds && (
        <>
          <div className='d-flex g-2 align-items-center'>
            <Button
              styleClass='col-2 mr-2'
              bgFill={false}
              value={strings.search}
              iconName='search'
              loading={searchLoading}
              disabled={enableButton}
              onClick={medUsSearchCustomer}
            />
            {enableButton && strings.enableSerchText}
          </div>
          <div className='mt-3'>
            <FormComponent
              model={searchCustomerFeildsModel}
              formName='searchCustomerFeildsForm'
              formValue={serachCustomerFeildsForm.formData}
              onChange={handleForm}
            />
          </div>
        </>
      )}
      {error && <div className='error-block'>{error}</div>}

      <div className='mt-2'>
        {showTableData && (
          <>
            <p>Please select an account ID:</p>
            <Table cols={getTableRows()} rows={sapErpMappingData} rowId='accountId' className='sap-erp-table' />
          </>
        )}
      </div>
      {medUsCustomerData && (
        <Table cols={getmedUsCustomerDataRows()} rows={medUsCustomerData} rowId='accountId' className='medUsCutomers' />
      )}
      {isEditView && <p className='mt-3'>{strings.sapMessage}</p>}
    </Modal>
  );
};

const mapStateToProps = ({ transactionDetailsReducer, authReducer }) => ({
  odertypeList: transactionDetailsReducer.odertypeList,
  billingList: transactionDetailsReducer.billingList,
  distributionChannelList: transactionDetailsReducer.distributionChannelList,
  divisionsList: transactionDetailsReducer.divisionsList,
  transactionDetails: transactionDetailsReducer.transactionDetails,
  deliveryBlockList: transactionDetailsReducer.deliveryBlockList,
  sapErpMappingData: transactionDetailsReducer.sapErpMappingData,
  customerGroupList: transactionDetailsReducer.customerGroupList,
  user: authReducer.user,
});

export default connect(mapStateToProps, {
  saveCreateLoanorderData,
  getSapErpMappingData,
  getSapErpCustomerAccounts,
  getMedUsSearchCusmoter,
})(CreateNewLoanOrderPopup);
