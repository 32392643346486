import { connect } from 'react-redux';

import { getAccountStatement, getStocks, exportList, fetchUserList } from '../../LLManagement.actions';
import AccountStatementTab from './AccountStatementTab.component';

const mapStateToProps = ({ authReducer, languageReducer, llManagementReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
  stocks: llManagementReducer.stocks,
  accountStatementList: llManagementReducer.accountStatementList,
  userList: llManagementReducer.userList,
});

export default connect(mapStateToProps, { getAccountStatement, getStocks, exportList, fetchUserList })(AccountStatementTab);
