import React, { Component } from 'react';
import { Map, Marker, InfoWindow, GoogleApiWrapper } from 'google-maps-react';
import strings from 'resources/locales/Translate';
import { ICON_URL } from 'modules/Calendar/calendarConstants';
import zeissInstance from 'library/utilities/AxiosInstance';
import moment from 'moment';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { connect } from 'react-redux';
import { checkCalendarFilters } from '../../CalendarActions';
import Icon from 'library/common/commonComponents/Icon/Icon';

const mapStyle = [
  {
    height: '50vh',
    width: '95%',
  },
];

const mountedStyle = { animation: 'inAnimation 250ms ease-in' };
const unmountedStyle = {
  animation: 'outAnimation 270ms ease-out',
  animationFillMode: 'forwards',
};

export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasPosition: false,
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      initialCenter: {
        lat: 0,
        lng: 0,
      },
      kitList: [],
    };
  }
  componentDidMount() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.setState({
            initialCenter: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
            hasPosition: true,
          });
        },
        () => {
          this.setState({
            hasPosition: true,
          });
        },
      );
    } else {
      this.setState({
        hasPosition: true,
      });
    }
  }

  _mapLoaded(mapProps, map) {
    map.setOptions({
      styles: mapStyle,
    });
  }

  onMarkerClick = (props, marker, e) => {
    console.log(this.props);
    this.props.selectedPartner(props.locationData);
    this.setState(
      {
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: true,
      },
      () => {
        this.fetchAvailableKits(
          {
            ...this.props.calenderState,
            filter: {
              ...this.props.calenderState.filter,
              stock: [props.locationData.id],
              stockFullValue: [props.locationData],
            },
          },
          { pathname: 'labs-location-calendar' },
          'isInitial',
        );
      },
    );
  };

  fetchAvailableKits = async (data, location, isInitial) => {
    this.setState({ kitList: [] });
    const sendData = await this.props.checkCalendarFilters(data, location);
    zeissInstance
      .post(
        `${URLS.labLocationСalendar}?calendarSearch=true&startRange=${moment()
          .subtract(12, 'months')
          .valueOf()}&endRange=${moment().add(30, 'months').valueOf()}`,
        { ...sendData, graphql: 'kitName' },
      )
      .then(res => {
        if (res.status === 200) {
          console.log(res, 'resonse');
          const kitNames = res.data.content.map(kit => kit.kitName);
          this.setState({ kitList: kitNames.length > 0 ? kitNames : [strings.noDataFoundText] });
        } else {
          this.setState({ kitList: [strings.noDataFoundText] });
        }
      })
      .catch(err => {
        this.setState({ kitList: [strings.noDataFoundText] });
      });
  };

  handleOnClose = () => {
    this.setState({ kitList: [], showingInfoWindow: false });
  };

  render() {
    const { locations } = this.props;
    const icon = {
      url: ICON_URL, // url
      scaledSize: new this.props.google.maps.Size(20, 30), // scaled size
    };
    return this.state.hasPosition ? (
      <div style={{ display: 'flex' }}>
        <div className='map-side-panel' style={this.state.showingInfoWindow ? mountedStyle : unmountedStyle}>
          <div className='map-side-panel-header'>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
              <span>{this.state.selectedPlace.name}</span>
              <button
                type='button'
                className='close'
                aria-label='Close'
                style={{ color: 'white', marginLeft: '16px' }}
                onClick={this.handleOnClose}
              >
                <Icon name='cross' width={24} height={24} style={{ fill: 'white' }} />
              </button>
            </div>
          </div>
          <ul id='kits' style={{ listStyle: 'none' }}>
            <li style={{ padding: '6px 8px 0 8px', color: '#0088d0' }}>Kits:</li>
            {this.state.kitList.map(name => (
              <li style={{ padding: '0 8px' }}>{name}</li>
            ))}
          </ul>
          {this.state.kitList.length === 0 && <span>Loading ....</span>}
        </div>
        )
        <Map
          style={{
            height: '55vh',
            width: '97%',
          }}
          google={this.props.google}
          zoom={3}
          initialCenter={this.state.initialCenter}
          onReady={(mapProps, map) => this._mapLoaded(mapProps, map)}
        >
          <Marker
            position={this.state.initialCenter}
            name={strings.myLocation}
            title={strings.myLocation}
            icon={icon}
          />
          {locations.map((location, index) => (
            <Marker
              key={index}
              position={{ lat: location.latitude, lng: location.longitude }}
              name={location.locationName}
              title={location.locationName}
              onClick={this.onMarkerClick}
              locationData={location}
            />
          ))}
        </Map>
      </div>
    ) : null;
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAPS_API_KEY,
})(connect(null, { checkCalendarFilters })(MapContainer));
