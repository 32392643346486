import { useEffect, useCallback } from 'react';

export const useStockDashboardFilterHook = ({
  filters,
  businessUnits,
  countries,
  stocks,
  setFilters,
  getStockByCountry,
}) => {

  const initFilters = useCallback(() => {
    setFilters(prevState => ({
      ...prevState,
      businessGroup: businessUnits.length ? businessUnits[0].id : null,
      businessGroupFullValue: businessUnits.length ? businessUnits[0] : null,
      country: countries.length ? countries[0].id : null,
      countryFullValue: countries.length ? countries[0] : null,
    }));
  }, [businessUnits, countries, setFilters]);

  const initStocks = useCallback(() => {
    setFilters(prevState => ({
      ...prevState,
      stock: stocks.length === 1 ? [stocks[0].id] : null,
      stockFullValue: stocks.length === 1 ? [stocks[0]] : null,
    }));
  }, [stocks, setFilters])

  useEffect(() => {
    initFilters();
  }, [initFilters]);


  useEffect(() => {
    initStocks();
  }, [initStocks]);

  const handleFilter = useCallback(async (id, field, value) => {
    if (field === 'country') {
      await getStockByCountry(id);
    }

    const idValue = Array.isArray(id) ? [...id] : id;
    const newFilters = {
      ...filters,
      [field]: idValue,
      [`${field}FullValue`]: value,
    }
    setFilters(newFilters);
  } , [filters, setFilters, getStockByCountry]);

  return { handleFilter };
}
