import Validators from 'library/utilities/Validators';

export const xbMainApplicationChecklist = [
  {
    label: 'crossSectioning',
    selected: false,
    type: 'checkbox',
    field: 'crossSectioning',
    styleClass: 'col-sm-6',
    newuiVersionStyle: true,
  },
  {
    label: 'stemImaging',
    selected: false,
    type: 'checkbox',
    field: 'stemImaging',
    styleClass: 'col-sm-6',
    newuiVersionStyle: true,
  },
  {
    label: 'temLamellaPrep',
    selected: false,
    type: 'checkbox',
    field: 'temLamellaPrep',
    styleClass: 'col-sm-6',
    newuiVersionStyle: true,
  },
  
  {
    label: 'eds',
    selected: false,
    type: 'checkbox',
    field: 'eds',
    styleClass: 'col-sm-6',
    newuiVersionStyle: true,
  },
  {
    label: 'fibTomography',
    selected: false,
    type: 'checkbox',
    field: 'fibTomography',
    styleClass: 'col-sm-6',
    newuiVersionStyle: true,
  },
  {
    label: 'ebsd',
    selected: false,
    type: 'checkbox',
    field: 'ebsd',
    styleClass: 'col-sm-6',
    newuiVersionStyle: true,
  },
  {
    label: 'nanoPatterning',
    selected: false,
    type: 'checkbox',
    field: 'nanoPatterning',
    styleClass: 'col-sm-6',
    newuiVersionStyle: true,
  },
  {
    label: 'threeDEds',
    selected: false,
    type: 'checkbox',
    field: 'threeDEds',
    styleClass: 'col-sm-6',
    newuiVersionStyle: true,
  },
  {
    label: 'highResolutionImaging',
    selected: false,
    type: 'checkbox',
    field: 'highResolutionImaging',
    styleClass: 'col-sm-6',
    newuiVersionStyle: true,
  },
  {
    label: 'threeDEbsd',
    selected: false,
    type: 'checkbox',
    field: 'threeDEbsd',
    styleClass: 'col-sm-6',
    newuiVersionStyle: true,
  },
  {
    label: 'largeAreaMapping',
    selected: false,
    type: 'checkbox',
    field: 'largeAreaMapping',
    styleClass: 'col-sm-6',
    newuiVersionStyle: true,
  },
  {
    label: 'correlativeMicroscopy',
    selected: false,
    type: 'checkbox',
    field: 'correlativeMicroscopy',
    styleClass: 'col-sm-6',
    newuiVersionStyle: true,
  },
  {
    label: 'fsLaser',
    selected: false,
    type: 'checkbox',
    field: 'fsLaser',
    styleClass: 'col-sm-6',
    newuiVersionStyle: true,
  },
  {
    label: 'addDetails',
    selected: false,
    type: 'checkbox',
    field: 'othersCheckbox',
    styleClass: 'col-sm-6',
    newuiVersionStyle: true,
  },
  {
    label: 'tofSims',
    selected: false,
    type: 'checkbox',
    field: 'tofSims',
    styleClass: 'col-sm-6',
    newuiVersionStyle: true,
  },

  {
    label: 'detailedCompetitionMicroscopeModels',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'xbDetailedCompetitionMicroscopeModels',
    validators: [],
    required: false,
    styleClass: 'col-sm-12',
    disabled: false,
    bigSizeInput: 'bigger-input-field',
  },
  {
    label: 'xbDetailsForCustomer',
    value: '',
    type: 'enhanced-RadioButton',
    placeholder: '',
    field: 'xbDoYouHaveDataFromCustomer',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-4 mt-3',
    options: [
      {
        id: 'yes',
        value: 'yes',
      },
      {
        id: 'no',
        value: 'no',
      },
    ],
  },
];
