import React from 'react';

import Modal from 'library/common/commonComponents/Modal';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import Icon from 'library/common/commonComponents/Icon/Icon';
import { sortUsers } from 'library/utilities/sort';
import strings from 'resources/locales/Translate';

import { useTeamBookings } from './useTeamBookings';

export default function TeamBookings({
  id,
  usersList,
  addUsersToTeamBookings,
  members,
  removeUserFromTeamBookings,
  data,
  transactionType,
}) {
  const countOfMembers = (members && members.length) || 0; 
  const filteredUsersList = usersList.filter(user => !members.find(member => member.accountId === user.accountId ));
  const sortedUsersList = sortUsers(filteredUsersList);

  const {
    isLoading,
    selected,
    error,
    onCloseClick,
    handleConfirmAction,
    handleValueChange,
    handleRemoveUser,
  } = useTeamBookings({
    id,
    addUsersToTeamBookings,
    removeUserFromTeamBookings,
    data,
    transactionType,
  });

  return (
    <Modal
      id={id}
      className='mt-5'
      title={`${strings.members} (${countOfMembers})`}
      confirmAction={handleConfirmAction}
      confirmDataTest='related-transactions-popup'
      titleOfCancel={strings.cancel}
      confirmTitle={strings.save}
      loading={isLoading}
      onCloseClick={onCloseClick}
      disableBackgroundClose
    >
      <Dropdown
        data={sortedUsersList}
        displayValue={[
          { key: 'lastName', separator: ', ' },
          { key: 'firstName', separator: ' (' },
          { key: 'email', separator: ')' },
        ]}
        filter
        isRequired
        multiSelect
        // selectAllOption
        idKey='accountId'
        field='stockController'
        label={strings.users}
        placeholder={strings.pleaseSelect}
        onChange={handleValueChange}
        value={selected.value}
        fullValue={selected.fullValue}
        error={selected.error}
      />
      {members && members.length > 0 && (
        <div className='members-list'>
          {members.map(member => (
            <div className='member-item' key={member.accountId}>
              <div className='d-flex'>
                <div className='member-gray-block'>
                  {`${(member.firstName && member.firstName[0]) || ''}${(member.lastName && member.lastName[0]) || ''}`}
                </div>
                <div className='name-container'>
                  <p className='name-text'>{`${member.firstName || ''} ${member.lastName || ''}`}</p>
                  <p className='email-text'>{member.email}</p>
                </div>
              </div>
              <div className='delete-button' onClick={() => handleRemoveUser(member.accountId)} data-test='team-bookings-delete-button'>
                <Icon
                  className=''
                  name='delete'
                  width={24}
                  height={24}
                  fill='#0088d0'
                />
              </div>
            </div>
          ))}
        </div>
      )}
      {error && <span className='text-danger error-block'>{error}</span>}
    </Modal>
  );
};
