import React from 'react';

import strings from 'resources/locales/Translate';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import FileUploader from 'library/common/commonComponents/FileUploader/FileUploader';
import Tabs from 'library/common/commonComponents/Tabs/container/TabsContainer';

import { createKitBreadcrumbsForSale } from './CreateKitForSale.constants';
import { createKitForSaleTabs } from './CreateKitForSale.tabs';
import { useCreateKitForSale } from './CreateKitForSale.hook';
import './CreateKitForSale.styles.scss';

const CreateKitForSale = ({
  user,
  history,
  match,
  kitDetails,
  fetchMasterData,
  resetCreateKit,
  updateKitForSaleForm,
  formValue,
  sellOffCurrency,
  appActions,
  saveCreateKitManually,
  saveCreateSingleItem,
  fetchKitDetail,
  language,
}) => {
  const { displayActionMessage, type, message, isLoading, showAsIs } = appActions;
  const {
    handleForm,
    addKitImageFile,
    removeKitImageFile,
    getTitle,
    getModel,
    isEditView,
    generalInformationKitForSaleForm,
    kitImages,
    isCopyKit,
    isSingleItemForSale,
    saveKit,
    cancelCreateKit,
    isShowSalesDetailsTab,
    currentTab,
  } = useCreateKitForSale({
    user,
    history,
    match,
    kitDetails,
    fetchMasterData,
    resetCreateKit,
    updateKitForSaleForm,
    formValue,
    sellOffCurrency,
    saveCreateKitManually,
    saveCreateSingleItem,
    fetchKitDetail,
    language,
  });

  return (
    <div className='create-kit-container'>
      {displayActionMessage && <ActionMessage type={type} message={showAsIs ? message : strings[message]} />}
      <div className='container-fluid'>
        <Breadcrumbs data={createKitBreadcrumbsForSale(isEditView)} />
        <h1 className='page-title'>{getTitle()}</h1>

        <div className='row'>
          <div className='col-sm-5 mb-3'>
            <h4 className='header-form-title'>{strings.imagesText}</h4>
            <FileUploader
              mimeType='image/*'
              files={kitImages}
              onFileAdd={addKitImageFile}
              onRemove={removeKitImageFile}
            />
          </div>
          <div className='col-sm-7 mb-3'>
            <h4 className='header-form-title'>{strings.generalInformation}</h4>
            <FormComponent
              dataTest={'create-kit-form-component'}
              model={getModel()}
              formName='generalInformationKitForSaleForm'
              formValue={generalInformationKitForSaleForm.formData}
              onChange={handleForm}
            />
          </div>
        </div>
      </div>

      <Tabs
        currentTab={currentTab}
        tabs={createKitForSaleTabs({
          user,
          history,
          isCopyKit,
          kitDetails,
          isSingleItemForSale,
          formValue,
          saveKit,
          cancelCreateKit,
          handleForm,
          kitInformationId: match.params.id,
          updateKitForSaleForm,
          isShowSalesDetailsTab,
        })}
      />

      {isLoading && <div className='loader' />}
    </div>
  );
};

export default CreateKitForSale;
