import { connect } from 'react-redux';

import { fetchCartDataFromLocalStorage } from 'modules/LoanList/LoanListActions';
import { fetchWorkflowData } from 'modules/Stock/CreateStock/Tabs/Workflows/Workflows.actions';

import { fetchInitialData, fetchKitDetails, clearForms, fetchTypeOfJobAssociatedWithKitSystemmainComponent } from './LLKiLoan.actions';
import LLKiLoan from './LLKiLoan.component';

const mapStateToProps = ({ appActionsReducer, authReducer, languageReducer, loanList }) => ({
  appActions: appActionsReducer,
  user: authReducer.user,
  language: languageReducer,
  selectedKits: loanList.cart,
});

export default connect(mapStateToProps, {
  fetchInitialData,
  fetchCartDataFromLocalStorage,
  fetchKitDetails,
  fetchWorkflowData,
  clearForms,
  fetchTypeOfJobAssociatedWithKitSystemmainComponent,
})(LLKiLoan);
