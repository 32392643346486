import Button from 'library/common/commonComponents/Button/Button';
import React from 'react';

const Legend = ({ title = '', color = '', borderColor = '', stripColor = '' }) => {
  if (!title) return;
  const style = {};
  if (color) {
    style.backgroundColor = color;
  }
  if (borderColor) {
    style.border = `3px solid ${borderColor}`;
  }
  if (stripColor) {
    style[
      'background'
    ] = `repeating-linear-gradient(-45deg, ${stripColor}, ${stripColor} 2px, #fff 2px,  #fff 5px)`;
  }

  return (
    <div className='d-flex align-items-center mt-3'>
      <div className='legend-box' style={{ ...style }}></div>
      <span className='legend-title'>{title}</span>
    </div>
  );
};

function CalendarLegends() {
  return (
    <div className='calendar-legends-container mt-5'>
      <div>
        <p className='container-title'>Request Type</p>
        <Legend title='Application Support' color='#DA9694' />
        <Legend title='Contract Measurement' color='#F4B084' />
        <Legend title='Training' color='#00B0F0' />
        <Legend title='Product Demonstration' color='#FFFF99' />
        <Legend title='Software Support' color='#FFC000' />
        <Legend title='IT Services' color='#A7FFEE' />
        <Legend title='Qualification' color='#F2BDFF' />
        <Legend title='Fair' color='#C1C1C1' />
        <Legend title='Not available' color='#616161' />
        <Legend title='Other' color='#000000' />
      </div>
      <div>
        <p className='container-title'>Request Execution Location</p>
        <Legend title='ZEISS Site (with customer)' borderColor='#FF0000' />
        <Legend title='ZEISS Site (without customer)' borderColor='#00B050' />
        <Legend title='Customer Site / Fair' borderColor='#FFC000' />
        <Legend title='ZEISS Site (other)' borderColor='#0070C0' />
        <Legend title='Mobile' borderColor='#7030A0' />
        <Legend title='to be defined' borderColor='#000000' />
        <p className='container-title mt-3'>Request Status</p>
        <Legend title='pre planning status' borderColor='#000000' stripColor='#C1C1C1' />
        <Legend title='planning / execution / billing' borderColor='#000000' color='#C4C4C4' />
        <Legend title='Completed' borderColor='#000000' color='#C9FFE1' />
      </div>
      <div className='align-self-start d-flex flex-column'>
        <Button value='Add Booking' bgFill={false} iconName='plus' newVersionUi styleClass='calendar-add-button' />
        <Button value='Add Location Comment' bgFill={false} iconName='plus' newVersionUi styleClass='mt-3'/>
      </div>
    </div>
  );
}

export default CalendarLegends;
