import React, { useState } from 'react';
import Icon from '../Icon/Icon';
import './Message.style.scss';
import moment from 'moment';
import strings from 'resources/locales/Translate';

export const Message = ({ className, dataTest, message }) => {
  const [opened, setOpened] = useState(false);

  const onMessageClick = () => {
    setOpened(!opened);
  };

  const makeBoldToHeaders = (text) => {

    if (!text) {
      return ''; 
    }
    const lines = text.split('\n');
    
    const updatedLines = lines.map(line => {
        if (line.startsWith('**')) {
            line = line.replace('**', '<b>').replace('*', '</b>');
        }
        return line;
    });
    
    return updatedLines.join('\n');
}

  return (
    <div
      className={className ? `message-container ${className}` : 'message-container'}
      onClick={onMessageClick}
      data-test={dataTest}
    >
      <div className='d-flex flex-row justify-content-between'>
        <div>
          {message.name && <div className='title-text'>{message.name}</div>}
          {message.createdBy ? 
            <div>
              {message.createdBy.firstName} {message.createdBy.lastName}
            </div>
            :
            <div>
              {strings.updatedBySystem}
            </div>
          }
        </div>
        <div>
          <span className='date-text mr-2'>
            {message.createdAt &&
              moment(message.createdAt).isValid() &&
              moment(message.createdAt).format('DD.MM.YYYY HH:mm')}
          </span>
          <Icon
            className={`arrow-icon ${opened && 'opened'}`}
            name='arrow'
            width={20}
            height={20}
            fill='#9a9b9c'
          />
        </div>
      </div>
      <div className='d-flex flex-column flex-lg-row justify-content-start align-items-start'>
        {message.createdBy &&
          message.createdBy.userAddresses &&
          message.createdBy.userAddresses[0] &&
          message.createdBy.userAddresses[0].phone && (
            <div className='d-flex flex-row align-items-center mr-3'>
              <Icon name='phone' width={20} height={20} fill='#0088d0' />
              <span className='phone-email-text mt-1 ml-1'>
                {message.createdBy.userAddresses[0].phone}
              </span>
            </div>
          )}
        <div className='d-flex flex-row align-items-center'>
          {message.createdBy && <Icon name='message' width={22} height={20} fill='#0088d0' />}
          <span className='phone-email-text mt-1 ml-1'>
            {message.createdBy && message.createdBy.email}
          </span>
        </div>
      </div>
      <div className={`message-text ${opened && 'opened'}`} dangerouslySetInnerHTML={{ __html: makeBoldToHeaders(message.text) }} />
    </div>
  );
};

export default Message;
