import React, { useEffect, useRef, useState, useCallback } from 'react';
import $ from 'jquery';
import moment from 'moment';

import strings from 'resources/locales/Translate';
import AppScheduler from 'library/common/commonComponents/AppSchedular/AppSchedular';
import FormComponent from 'library/common/commonComponents/FormComponent/FormComponent';
import Modal from 'library/common/commonComponents/Modal';
import { fetchBookingsForKit } from 'modules/Calendar/calendar-services';
import { adjustDatesFormModel } from './AdjustDatesConstants';
import './adjustDates.style.scss';

export const generateCart = (
  {
    targetDeliveryFrom,
    targetReservationFrom,
    targetReservationTo,
    targetReconditioningFrom,
    targetReconditioningTo,
    targetCheckedDate,
    targetReturnDeliveryTo,
    targetReturnDeliveryDate,
    targetReturnDeliveryFrom,
    originalTargetReservationTo
  },
  type,
  transactionsId,
  twentyFourHourReservation,
) => {
  let deliveryDays = 0;
  let returnDeliveryDays = 0;
  let reconditioningDeliveryDays = 0;
  let reconditioningDays = 0;
  let reconditioningFrom = 0;
  let reconditioningTo = 0;
  let endVar = type === 'kit loan' ? targetReconditioningTo : targetCheckedDate;
  if (
    targetReservationFrom &&
    moment(targetReservationFrom).isValid() &&
    targetDeliveryFrom &&
    moment(targetDeliveryFrom).isValid()
  ) {
    deliveryDays = moment(`${moment(targetReservationFrom).format('YYYY-MM-DD')} 12:00`).diff(
      moment(`${moment(targetDeliveryFrom).format('YYYY-MM-DD')} 12:00`),
      'days',
    );
  }
  if (endVar && moment(endVar).isValid() && originalTargetReservationTo && moment(originalTargetReservationTo).isValid()) {
    returnDeliveryDays = moment(`${moment(endVar).format('YYYY-MM-DD')} 12:00`).diff(
      moment(`${moment(originalTargetReservationTo).format('YYYY-MM-DD')} 12:00`),
      'days',
    );
  }
  if (endVar && moment(endVar).isValid() && targetReservationTo && moment(targetReservationTo).isValid()) {
    returnDeliveryDays = moment(`${moment(endVar).format('YYYY-MM-DD')} 12:00`).diff(
      moment(`${moment(targetReservationTo).format('YYYY-MM-DD')} 12:00`),
      'days',
    );
  }
  if (endVar && moment(endVar).isValid() && targetReconditioningFrom && moment(targetReconditioningFrom).isValid()) {
    reconditioningDeliveryDays =
      moment(`${moment(endVar).format('YYYY-MM-DD')} 12:00`).diff(
        moment(`${moment(targetReconditioningFrom).format('YYYY-MM-DD')} 12:00`),
        'days',
      ) + 1;
  }
  if (targetReturnDeliveryTo && moment(targetReturnDeliveryTo).isValid() && targetReturnDeliveryTo && moment(targetReturnDeliveryTo).isValid()) {
    reconditioningDays = moment(`${moment(targetReconditioningTo).format('YYYY-MM-DD')} 12:00`).diff(
      moment(`${moment(targetReturnDeliveryTo).format('YYYY-MM-DD')} 12:00`),
      'days',
    );
  }
  if (targetReconditioningFrom && moment(targetReconditioningFrom).isValid()) {
    reconditioningFrom = `${moment(targetReconditioningFrom).format('YYYY-MM-DD')} 00:00:00`;
  }
  if (targetReconditioningTo && moment(targetReconditioningTo).isValid()) {
    reconditioningTo = `${moment(targetReconditioningTo).format('YYYY-MM-DD')} 23:59:00`;
  }
  const alreadyBooked = [];
  if (targetDeliveryFrom && endVar) {
    alreadyBooked.push({
      id: 1,
      resourceId: 'r0',
      isBooked: true,
      kit: { kitInformationId: 1, kitName: '' },
      start: targetDeliveryFrom,
      title: '',
      deliveryDays,
      returnDeliveryDays,
      reconditioningDeliveryDays,
      end: endVar && `${moment(endVar).format('YYYY-MM-DD')} 23:59:59`,
      transactionsId,
      reconditioningDays,
      targetReconditioningFrom: reconditioningFrom,
      targetReconditioningTo: reconditioningTo,
    });
  }
  if (twentyFourHourReservation && targetReservationFrom && targetReservationTo) {
    alreadyBooked.push({
      id: 1,
      resourceId: 'r0',
      isBooked: true,
      isTwentyFourHourReservation: true,
      kit: { kitInformationId: 1, kitName: '' },
      start: targetReservationFrom,
      title: '',
      end: `${moment(targetReservationTo).format('YYYY-MM-DD')} 23:59:59`,
    });
  }
  return { alreadyBooked };
};

const getTransactionDates = (transactionDetails, propsTransactionDetails) => {
  const isCurrentTransaction = propsTransactionDetails && propsTransactionDetails.transactionsId !== transactionDetails.transactionsId;
  if (transactionDetails.reservationFrom && transactionDetails.reservationTo && transactionDetails.returnDelivery && transactionDetails.deliveryDate && isCurrentTransaction) {
    return {
      targetDeliveryFrom:
        (transactionDetails.deliveryDate &&
          moment(transactionDetails.deliveryDate).isValid() &&
          `${moment.utc(transactionDetails.deliveryDate).format('YYYY-MM-DD')} 00:00:00`),
      targetDeliveryTo:
        transactionDetails.returnDelivery &&
        moment(transactionDetails.returnDelivery).isValid() &&
        `${moment.utc(transactionDetails.returnDelivery).format('YYYY-MM-DD')} 00:00:00`,
      targetReservationFrom:
        moment(transactionDetails.reservationFrom).isValid() &&
        `${moment.utc(transactionDetails.reservationFrom).format('YYYY-MM-DD')} 00:00:00`,
      targetReservationTo:
        moment(transactionDetails.reservationTo).isValid() &&
        `${moment.utc(transactionDetails.reservationTo).format('YYYY-MM-DD')} 00:00:00`,
      targetReturnDeliveryFrom:
        (transactionDetails.reservationTo &&
          moment(transactionDetails.reservationTo).isValid() &&
          `${moment.utc(transactionDetails.reservationTo).format('YYYY-MM-DD')} 00:00:00`),
      targetReturnDeliveryTo:
        transactionDetails.returnDelivery &&
        moment(transactionDetails.returnDelivery).isValid() &&
        `${moment.utc(transactionDetails.returnDelivery).format('YYYY-MM-DD')} 00:00:00`,
  
      targetReconditioningFrom:
        transactionDetails.returnDelivery &&
        moment(transactionDetails.returnDelivery).isValid() &&
        `${moment.utc(transactionDetails.returnDelivery).format('YYYY-MM-DD')} 00:00:00`,
      targetReconditioningTo:
        transactionDetails.checkedDate &&
        moment(transactionDetails.checkedDate).isValid() &&
        `${moment.utc(transactionDetails.checkedDate).format('YYYY-MM-DD')} 00:00:00`,
      targetCheckedDate:
        transactionDetails.checkedDate &&
        moment(transactionDetails.checkedDate).isValid() &&
        `${moment.utc(transactionDetails.checkedDate).format('YYYY-MM-DD')} 00:00:00`,
    };
  }else{
    return {
      targetDeliveryFrom:
      (transactionDetails.targetDeliveryFrom &&
        moment(transactionDetails.targetDeliveryFrom).isValid() &&
        `${moment.utc(transactionDetails.targetDeliveryFrom).format('YYYY-MM-DD')} 00:00:00`) ||
      (transactionDetails.targetDeliveryDate &&
        moment(transactionDetails.targetDeliveryDate).isValid() &&
        `${moment.utc(transactionDetails.targetDeliveryDate).format('YYYY-MM-DD')} 00:00:00`),
      targetDeliveryTo:
        transactionDetails.targetDeliveryTo &&
        moment(transactionDetails.targetDeliveryTo).isValid() &&
        `${moment.utc(transactionDetails.targetDeliveryTo).format('YYYY-MM-DD')} 00:00:00`,
      targetReservationFrom:
        moment(transactionDetails.targetReservationFrom).isValid() &&
        `${moment.utc(transactionDetails.targetReservationFrom).format('YYYY-MM-DD')} 00:00:00`,
      targetReservationTo:
      (transactionDetails.targetReturnDeliveryFrom &&
        moment(transactionDetails.targetReturnDeliveryFrom).isValid() &&
        `${moment.utc(transactionDetails.targetReturnDeliveryFrom).format('YYYY-MM-DD')} 00:00:00`),
      originalTargetReservationTo:
        (transactionDetails.targetReservationTo &&
          moment(transactionDetails.targetReservationTo).isValid() &&
          `${moment.utc(transactionDetails.targetReservationTo).format('YYYY-MM-DD')} 00:00:00`),
      targetReturnDeliveryFrom:
        (transactionDetails.targetReturnDeliveryFrom &&
          moment(transactionDetails.targetReturnDeliveryFrom).isValid() &&
          `${moment.utc(transactionDetails.targetReturnDeliveryFrom).format('YYYY-MM-DD')} 00:00:00`) ||
        (transactionDetails.targetReturnDeliveryDate &&
          moment(transactionDetails.targetReturnDeliveryDate).isValid() &&
          `${moment.utc(transactionDetails.targetReturnDeliveryDate).format('YYYY-MM-DD')} 00:00:00`),
      targetReturnDeliveryTo:
        transactionDetails.targetReturnDeliveryTo &&
        moment(transactionDetails.targetReturnDeliveryTo).isValid() &&
        `${moment.utc(transactionDetails.targetReturnDeliveryTo).format('YYYY-MM-DD')} 00:00:00`,
  
      targetReconditioningFrom:
        transactionDetails.targetReconditioningFrom &&
        moment(transactionDetails.targetReconditioningFrom).isValid() &&
        `${moment.utc(transactionDetails.targetReconditioningFrom).format('YYYY-MM-DD')} 00:00:00`,
      targetReconditioningTo:
        transactionDetails.targetReconditioningTo &&
        moment(transactionDetails.targetReconditioningTo).isValid() &&
        `${moment.utc(transactionDetails.targetReconditioningTo).format('YYYY-MM-DD')} 00:00:00`,
      targetCheckedDate:
        transactionDetails.targetCheckedDate &&
        moment(transactionDetails.targetCheckedDate).isValid() &&
        `${moment.utc(transactionDetails.targetCheckedDate).format('YYYY-MM-DD')} 00:00:00`,
    };
  }
  
};

export const AdjustDates = ({
  adjustTransactionDates,
  enableSave,
  id,
  showScheduler,
  transactionDetails,
  changeVisible,
  visible,
  openRelatedTransactionsModal,
  isRelatedTransactions,
}) => {
  const formRef = useRef();
  const [adjustDatesFormData, setAdjustDatesFormData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alreadyBooked, setAlreadyBooked] = useState([]);
  const [doValidate, setDoValidate] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const getAllBookedForKitLoan = useCallback(async () => {
    if (!transactionDetails) return;
    const startRangeDate = transactionDetails.targetDeliveryFrom;
    const { data } = await fetchBookingsForKit({filter: { kitId : transactionDetails.transactionKit.kitInformation.kitInformationId } }, startRangeDate);
    if (data && data.content[0] && data.content[0].transactionsForCalendar && data.content[0].transactionsForCalendar.length) {
      let modelDate = data.content[0].transactionsForCalendar.map(item => { 
        const targetData = getTransactionDates(item, transactionDetails);
        return generateCart(targetData, transactionDetails.type, item.transactionsId, item.twentyFourHourReservation).alreadyBooked[0];
      }).filter(item => item);
      setAlreadyBooked(modelDate);
    }
  }, [transactionDetails]);

  useEffect(() => {
    const targetData = getTransactionDates(transactionDetails);
    setAdjustDatesFormData(targetData);
    if (transactionDetails && transactionDetails.type === 'kit loan') {
      getAllBookedForKitLoan();
    } else {
      let modelDate = generateCart(targetData, transactionDetails.type, transactionDetails.transactionsId);
      setAlreadyBooked(modelDate.alreadyBooked);
    }
  }, [transactionDetails, getAllBookedForKitLoan]);

  const onCloseClick = reset => () => {
    if ($(`#${id}`) && $(`#${id}`).modal) {
      $(`#${id}`).modal('hide');
    }
    if (reset) {
      setDataFromTransaction();
    }

    changeVisible();
  };

  const setDataFromTransaction = () => {
    const targetData = getTransactionDates(transactionDetails);
    if (formRef && formRef.current && formRef.current.dates && Object.keys(formRef.current.dates).length) {
      Object.keys(formRef.current.dates).map(key => {
        formRef.current.dates[key].setState({
          date:
            formRef.current.dates[key].props && targetData[formRef.current.dates[key].props.field]
              ? new Date(targetData[formRef.current.dates[key].props.field])
              : '',
        });
      });
    }
    setErrorMessage('');
    setAdjustDatesFormData(targetData);
    if (transactionDetails && transactionDetails.type === 'kit loan') {
      const updatedAlreadyBooked = alreadyBooked.map(item => {
        if (item.transactionsId === transactionDetails.transactionsId) {
          return generateCart(targetData, transactionDetails.type, transactionDetails.transactionsId, item.twentyFourHourReservation).alreadyBooked[0];
        }
        return item;
      })
      setAlreadyBooked(updatedAlreadyBooked);
    } else {
      setAlreadyBooked(generateCart(targetData, transactionDetails.type, transactionDetails.transactionsId).alreadyBooked);
    }
  };

  const checkForChanges = formData => {
    let changed = false;
    const fieldsToCheck = [
      'targetDeliveryFrom',
      'targetReservationFrom',
      'targetReservationTo',
      'targetReturnDeliveryTo',
      'targetReconditioningTo',
      'targetCheckedDate',
    ];
    fieldsToCheck.forEach(key => {
      if (
        (!transactionDetails[key] && formData[key]) ||
        (transactionDetails[key] &&
          formData[key] &&
          moment(transactionDetails[key]).format('YYYY-MM-DD') !== moment(formData[key]).format('YYYY-MM-DD'))
      ) {
        changed = true;
      }
    });
    return changed;
  };

  const onSaveClick = async () => {
    const validateForm = formRef.current.getFormData();
    if (enableSave) {
      if (validateForm.isValid && checkForChanges(validateForm.formData)) {
        const { formData: newFormData } = validateForm;
        const newError = false;
        if (newError) {
          setErrorMessage(newError);
        } else {
          if (errorMessage) {
            setErrorMessage('');
          }
          let dataToSend = {
            targetDeliveryFrom: moment(newFormData.targetDeliveryFrom).format('YYYY-MM-DD'),
            targetReservationFrom: moment(newFormData.targetReservationFrom).format('YYYY-MM-DD'),
            targetReservationTo: moment(newFormData.targetReservationTo).format('YYYY-MM-DD'),
            targetReturnDeliveryTo: moment(newFormData.targetReturnDeliveryTo).format('YYYY-MM-DD'),
          };
          if (transactionDetails.type === 'single loan' || transactionDetails.type === 'course loan') {
            dataToSend = {
              ...dataToSend,
              targetReconditioningTo: moment(newFormData.targetCheckedDate).format('YYYY-MM-DD'),
            };
          }
          if (transactionDetails && transactionDetails.type === 'kit loan') {
            dataToSend = {
              ...dataToSend,
              targetReconditioningTo: moment(newFormData.targetReconditioningTo).format('YYYY-MM-DD'),
            };
          }
          if (isRelatedTransactions) {
            openRelatedTransactionsModal(dataToSend, 'isAdjustDates');
            onCloseClick(false)();
            return;
          }
          setLoading(true);
          const { success, error } = await adjustTransactionDates(dataToSend, transactionDetails.transactionsId, transactionDetails.type);
          if (success) {
            onCloseClick(false)();
          } else {
            if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              setErrorMessage(error.response.data.message);
            } else if (
              error &&
              error.response &&
              error.response.status === 422
            ){
              setErrorMessage(strings.youCanNotCreateOverlappingBookings);
            } else {
              setErrorMessage(strings.somethingWentWrongMessage);
            }
            setTimeout(() => {
              setErrorMessage('');
            }, 5000);
          }
          setLoading(false);
        }
      } else {
        setDoValidate(true);
      }
    }
  };

  const checkTypeError = ({ fieldOne, fieldTwo, fieldOneString, fieldTwoString, data }) => {
    if (data[fieldOne] && data[fieldTwo] && moment(data[fieldOne]).isAfter(moment(data[fieldTwo]))) {
      return strings.hasToBeBefore(fieldOneString, fieldTwoString);
    }
    return '';
  };

  const checkError = data => {
    const checkArrays = [
      {
        fieldOne: 'targetDeliveryFrom',
        fieldTwo: 'targetReservationFrom',
        fieldOneString: strings.shipped,
        fieldTwoString: strings.receiptAtTheCustomer,
      },
      {
        fieldOne: 'targetReservationFrom',
        fieldTwo: 'targetReservationTo',
        fieldOneString: strings.receiptAtTheCustomer,
        fieldTwoString: strings.returnDelivery,
      },
      {
        fieldOne: 'targetReservationTo',
        fieldTwo: 'targetReturnDeliveryTo',
        fieldOneString: strings.returnDelivery,
        fieldTwoString: strings.receiptInStock,
      },
    ];
    if (transactionDetails.type === 'single loan' || transactionDetails.type === 'course loan') {
      checkArrays.push({
        fieldOne: 'targetReturnDeliveryTo',
        fieldTwo: 'targetCheckedDate',
        fieldOneString: strings.receiptInStock,
        fieldTwoString: strings.returnGoodsBooking,
      },);
    }
    if (transactionDetails.type === 'kit loan') {
      checkArrays.push({
        fieldOne: 'targetReturnDeliveryTo',
        fieldTwo: 'targetReconditioningTo',
        fieldOneString: strings.receiptInStock,
        fieldTwoString: strings.checked,
      },);
    }
    let error = '';
    checkArrays.forEach(item => {
      if (!error) {
        error = checkTypeError({ ...item, data: data });
      }
    });
    return error;
  };

  const onFormChange = data => {
    let targetData = {
      ...data.formData,
      targetDeliveryDate: data.formData.targetDeliveryFrom,
      targetReconditioningFrom: transactionDetails.targetReconditioningFrom
    };
    let modelDate = generateCart(targetData, transactionDetails.type, transactionDetails.transactionsId);
    if (transactionDetails && transactionDetails.type === 'kit loan') {
      const updatedAlreadyBooked = alreadyBooked.map(item => {
        if (item.transactionsId === transactionDetails.transactionsId) return modelDate.alreadyBooked[0];
        return item;
      })
      setAlreadyBooked(updatedAlreadyBooked);
    } else {
      setAlreadyBooked(modelDate.alreadyBooked);
    }
    if (errorMessage) {
      const newError = checkError(targetData);
      if (newError) {
        setErrorMessage(newError);
      } else {
        setErrorMessage('');
      }
    }
  };
  return (
    <Modal
      id={id}
      className='modal-lg'
      title={strings.adjustDates}
      confirmAction={onSaveClick}
      confirmDataTest='adjust-dates-button-save'
      loading={loading}
      onCloseClick={onCloseClick(true)}
    >
      <div className='adjust-dates-content-container'>
        {adjustDatesFormData && (
          <FormComponent
            ref={formRef}
            className='d-flex'
            rowStyle='adjust-dates-transaction-form-row'
            model={adjustDatesFormModel(transactionDetails && transactionDetails.type)}
            value={adjustDatesFormData}
            doValidate={doValidate}
            onChange={onFormChange}
          />
        )}
        {!!errorMessage && <span className='text-danger error-block'>{errorMessage}</span>}
        {visible && (
          <AppScheduler
            resources={[
              {
                id: 'r0',
                name: 'Resource0',
                groupOnly: true,
              },
            ]}
            updateEventsOnChange
            disableClick
            resizeable={false}
            dragable={false}
            schedulerWrapperClassName='mr-3'
            hideOptions
            startDate={
              transactionDetails &&
              (transactionDetails.targetDeliveryFrom || transactionDetails.targetDeliveryDate) &&
              moment(transactionDetails.targetDeliveryFrom || transactionDetails.targetDeliveryDate).format(
                'YYYY-MM-DD',
              )
            }
            alreadyBooked={alreadyBooked}
            isCalenderFromAdjustDates={true}
            events={[]}
            cart={[]}
          />
        )}
        {!enableSave && <div className='adjust-dates-disable-overlay' />}
      </div>
      {!enableSave && <div className='help-text'>{strings.viewOnlyPermissions}</div>}
    </Modal>
  );
};

AdjustDates.defaultProps = {
  showScheduler: true,
};

export default AdjustDates;
