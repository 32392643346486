import {
  ADD_TRANSACTIONS_COMMENTS,
  ADD_TRANSACTIONS_DAMAGE_LOSS_REPORT,
  FETCH_TRANSACTION_DETAILS,
  UPDATE_TRANSACTION_DETAILS,
  FETCH_TRANSACTIONS_COMMENTS,
  FETCH_TRANSACTIONS_DAMAGE_LOSS_REPORT,
  FETCH_TRANSACTIONS_CHANGE_HISTORY,
  SET_DELIVERY_RETURN_DELAY,
  SET_TRANSACTION_RETURN_GOODS_BOOKING,
  FETCH_POSSIBLE_C2C_LOANS,
  FETCH_USER_TRANSACTIONS,
  ADD_LIST_OF_CONNECTED_TRANSACTION,
  ADD_TRANSACTION_ATTACHMENTS,
  DELETE_TRANSACTION_ATTACHMENTS,
  CLEAR_TRANSACTION_ATTACHMENTS,
  FETCH_LABS_LOCATION_COUNTRIES,
  FETCH_TYPES_OF_JOBS,
  FETCH_TRANSACTIONS_PICKUP_REQUESTS,
  ADD_TRANSACTIONS_PICKUP_REQUESTS,
} from 'library/common/commonConstants/storeConstants';

import {
  FETCH_ORDER_TYPES_LIST,
  FETCH_BILLINGS_LIST,
  FETCH_DISTRIBUTION_CHANNEL,
  DIVISION_LIST,
  FETCH_DELIVERY_BLOCK_LIST,
  FETCH_SAP_ERP_MAPPING_DATA,
  FETCH_CUSTOMER_GROUP_LIST,
  FETCH_TRANSACTIONS_MAINTENANCE_CHECKLIST_DETAILS,
  FETCH_ACTIVE_TRANSACTION,
} from './TransactionDetailsConstants';
import { NEW_TYPES_OF_JOBS } from 'modules/LLKiLoan/LLKiLoan.constants';

export const TRANSACTION_DETAILS_INITIAL_STATE = {
  damageLossReportData: {},
  commentsData: {},
  changeLog: {},
  possibleC2CLoans: null,
  loading: false,
  transactionDetails: null,
  delayReturnTransactions: [],
  returnGoodsBookingDetail: null,
  userTransactions: null,
  metadata: null,
  first: false,
  last: false,
  bundleLoans: [],
  attachments: [],
  deletedAttachmentIds: [],
  labsLocationCountries: [],
  odertypeList: [],
  billingList: [],
  distributionChannelList: [],
  divisionsList: [],
  deliveryBlockList: [],
  sapErpMappingData: [],
  customerGroupList: [],
  pickupRequestsData: {},
  maintenanceCheckListData: {},
  activeTranction: null,
  newTypesOfJobsList: [],
};

const TransactionDetailsReducer = (state = TRANSACTION_DETAILS_INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_TRANSACTION_DETAILS:
      return {
        ...state,
        transactionDetails: action.payload.transactionDetail,
        metadata: action.payload.metadata,
        damageLossReportData: {},
        commentsData: {},
        loading: false,
        first: action.payload.first,
        last: action.payload.last,
      };
    case UPDATE_TRANSACTION_DETAILS:
      return {
        ...state,
        transactionDetails: action.payload,
      };
    case FETCH_USER_TRANSACTIONS:
      return {
        ...state,
        userTransactions: action.payload,
      };
    case FETCH_TRANSACTIONS_COMMENTS:
      return {
        ...state,
        commentsData: action.payload,
      };
    case FETCH_TRANSACTIONS_DAMAGE_LOSS_REPORT:
      return {
        ...state,
        damageLossReportData: action.payload,
      };
    case ADD_TRANSACTIONS_COMMENTS:
      return {
        ...state,
        commentsData: action.payload,
      };
    case ADD_TRANSACTIONS_DAMAGE_LOSS_REPORT:
      return {
        ...state,
        damageLossReportData: action.payload,
      };
    case FETCH_TRANSACTIONS_CHANGE_HISTORY:
      return {
        ...state,
        changeLog: action.payload,
      };
    case SET_DELIVERY_RETURN_DELAY:
      return {
        ...state,
        delayReturnTransactions: action.payload,
      };
    case SET_TRANSACTION_RETURN_GOODS_BOOKING:
      return {
        ...state,
        returnGoodsBookingDetail: action.payload,
      };
    case FETCH_POSSIBLE_C2C_LOANS:
      return {
        ...state,
        possibleC2CLoans: action.payload,
      };
    case ADD_LIST_OF_CONNECTED_TRANSACTION: {
      return {
        ...state,
        bundleLoans: action.payload,
      };
    }
    case ADD_TRANSACTION_ATTACHMENTS: {
      return {
        ...state,
        attachments: action.payload,
      };
    }
    case DELETE_TRANSACTION_ATTACHMENTS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case CLEAR_TRANSACTION_ATTACHMENTS: {
      return {
        ...state,
        deletedAttachmentIds: [],
      };
    }
    case FETCH_LABS_LOCATION_COUNTRIES: {
      return {
        ...state,
        labsLocationCountries: action.payload,
      };
    }
    case FETCH_TYPES_OF_JOBS:
      return {
        ...state,
        typesOfJobsList: action.payload,
      };
    case FETCH_ORDER_TYPES_LIST:
      return {
        ...state,
        odertypeList: action.payload,
      };
    case FETCH_BILLINGS_LIST:
      return {
        ...state,
        billingList: action.payload,
      };
    case FETCH_DISTRIBUTION_CHANNEL:
      return {
        ...state,
        distributionChannelList: action.payload,
      };
    case DIVISION_LIST:
      return {
        ...state,
        divisionsList: action.payload,
      };
    case FETCH_DELIVERY_BLOCK_LIST:
      return {
        ...state,
        deliveryBlockList: action.payload,
      };
    case FETCH_SAP_ERP_MAPPING_DATA:
      return {
        ...state,
        sapErpMappingData: action.payload,
      };
    case FETCH_CUSTOMER_GROUP_LIST:
      return {
        ...state,
        customerGroupList: action.payload,
      };
    case FETCH_TRANSACTIONS_PICKUP_REQUESTS:
      return {
        ...state,
        pickupRequestsData: action.payload,
      };
    case ADD_TRANSACTIONS_PICKUP_REQUESTS:
      return {
        ...state,
        pickupRequestsData: action.payload,
      };
    case FETCH_TRANSACTIONS_MAINTENANCE_CHECKLIST_DETAILS:
      return {
        ...state,
        maintenanceCheckListData: action.payload,
      };
      case FETCH_ACTIVE_TRANSACTION:
        return {
          ...state,
          activeTranction: action.payload,
        };
        case NEW_TYPES_OF_JOBS:
          return {
            ...state,
            newTypesOfJobsList: action.payload,
          };
    default:
      return state;
  }
};

export default TransactionDetailsReducer;
