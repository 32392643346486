import zeissInstance from 'library/utilities/AxiosInstance';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { logNetworkError } from 'library/utilities/logError';
import { mergeMessages } from 'modules/Transactions/TransactionDetails/TransactionDetailsActions';

export const fetchKitDetailsMessages = ({ kitId, page, actionTypes }) => (dispatch, getState) => {
  const dataToSend = {
    page: page ? page : 1,
    filter: [`kitIdForDamageLossReport:eq:${kitId}`],
    sort: ['createdAt', 'desc'],
    size: 10,
    graphql: 'id,text,createdAt,createdBy[id,accountId,email,firstName,lastName,userAddresses[id,phone]]',
  };
  if (page === 1) {
    dispatch({
      type: actionTypes.FETCH_KIT_DETAILS_DAMAGE_LOSS_REPORT,
      payload: {},
    });
  }
  return zeissInstance
    .post(URLS.userMessages, dataToSend)
    .then(({ data, status }) => {
      if (status === 200) {
        if (data.content && data.content.length) {
          let payload = data;
          if (page !== 1) {
            let oldData = getState().kitDetailsReducer.damageLossReportData;
            payload = {
              ...data,
              content: mergeMessages({
                newMessages: data.content,
                oldMessages: oldData.content && oldData.content.length ? oldData.content : [],
              }),
            };
          }
          dispatch({
            type: actionTypes.FETCH_KIT_DETAILS_DAMAGE_LOSS_REPORT,
            payload,
          });
        }
        return { success: true };
      }
      return { success: false };
    })
    .catch(err => {
      logNetworkError(err);
      return { success: false };
    });
};

export const fetchKitDetailsComments = ({ kitId, page, actionTypes }) => (dispatch, getState) => {
  const dataToSend = {
    page: page ? page : 1,
    filter: [`kitIdForComment:eq:${kitId}`, 'or', `kitReturnLoanComments:eq:${kitId}`],
    sort: ['createdAt', 'desc'],
    size: 10,
    graphql: 'id,text,createdAt,createdBy[id,accountId,email,firstName,lastName,userAddresses[id,phone]]',
  };
  if (page === 1) {
    dispatch({
      type: actionTypes.FETCH_KIT_DETAILS_COMMENTS,
      payload: {},
    });
  }
  return zeissInstance
    .post(URLS.userMessages, dataToSend)
    .then(({ data, status }) => {
      if (status === 200) {
        if (data.content && data.content.length) {
          let payload = data;
          if (page !== 1) {
            let oldData = getState().kitDetailsReducer.commentsData;
            payload = {
              ...data,
              content: mergeMessages({
                newMessages: data.content,
                oldMessages: oldData.content && oldData.content.length ? oldData.content : [],
              }),
            };
          }
          dispatch({
            type: actionTypes.FETCH_KIT_DETAILS_COMMENTS,
            payload,
          });
        }
        return { success: true };
      }
      return { success: false };
    })
    .catch(err => {
      logNetworkError(err);
      return { success: false };
    });
};
