import { connect } from 'react-redux';

import InformationTab from './InformationTab.component';
import { fetchSystemMainComponent, fetchActiveUnitDate } from '../../CreateLLKit.actions';

const mapStateToProps = ({ authReducer, createLLKitReducer, languageReducer }) => ({
  informationForm: createLLKitReducer.informationForm,
  kitStatusForm: createLLKitReducer.kitStatusForm,
  stocks: createLLKitReducer.stocks,
  systemClasses: createLLKitReducer.systemClasses,
  systemMainComponent: createLLKitReducer.systemMainComponent,
  conditions: createLLKitReducer.conditions,
  activeUntil: createLLKitReducer.activeUntil,
  language: languageReducer,
  user: authReducer.user,
  kitDetails: createLLKitReducer.kitDetails,
});

export default connect(mapStateToProps, {
  fetchSystemMainComponent,
  fetchActiveUnitDate,
})(InformationTab);
