import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';

import {
  fetchObservationsListData,
  exportObservationsList,
  addKitToObservationList,
  removeKitFromObservationList,
  reserveKit,
  orderKit,
  cancelKitReservation,
  fetchMarketplaceCountryList
} from '../../Shop.actions';
import Observations from './Observations.component';

const mapStateToProps = ({ authReducer, languageReducer, masterDataReducer, appActionsReducer, shopListReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
  appActions: appActionsReducer,
  countries: masterDataReducer.countries,
  categoriesAndSystems: masterDataReducer.systemClassWithStock,
  observationsList: shopListReducer.observationsList,
  marketplaceCountry: shopListReducer.marketplaceCountry,
});

export default withRouter(connect(mapStateToProps, {
  fetchMasterData,
  fetchObservationsListData,
  exportObservationsList,
  addKitToObservationList,
  removeKitFromObservationList,
  reserveKit,
  orderKit,
  cancelKitReservation,
  fetchMarketplaceCountryList
})(Observations));
