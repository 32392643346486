import {connect} from "react-redux";

import Breadcrumbs from '../Breadcrumbs';

const mapStateToProps = state => {
  return {
    language: state.languageReducer,
  }
};

export default connect(mapStateToProps)(Breadcrumbs);
