import strings from 'resources/locales/Translate';

export const singleItemsOptions = () => [
  {
    id: 1,
    value: strings.demoKits,
  },
  {
    id: 2,
    value: strings.singleItems,
  },
];

export const itemsPerPage = {
  10: { id: 10, pages: 10 },
  20: { id: 20, pages: 20 },
  50: { id: 50, pages: 50 },
};
