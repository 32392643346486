export const myQueryBreadcrumbData = [
  {
    name: 'homepage',
    url: '/home',
  },
  {
    name: 'myQueries',
  },
];

export const itemsPerPage = {
  10: { id: 10, pages: 10 },
  20: { id: 20, pages: 20 },
  50: { id: 50, pages: 50 },
};


export const paths = {
  OPEN_PROCESS: 'open-processes',
  MY_BOOKINGS: 'my-bookings',
  DEMO_REQUEST: 'loan-requests',
  CANCELLATION_REQUESTS: 'cancellation-approval',
  CANCELLATION_PROCESS: 'cancelled-processes',
  ARCHIVED_REQUESTS: 'archive-processes',
}
