import React from 'react';
import AdditionalInformation from './Tabs/AdditionalInformation/AdditionalInformation.component';
import ApplicationSampleDetails from './Tabs/ApplicationSampleDetails/ApplicationSampleDetails.component';
import CustomerInformation from './Tabs/CustomerInformation/CustomerInformation.component';
import DemoCenterInformation from './Tabs/DemoCenterInformation/DemoCenterInformation.component';
import GeneralInformation from './Tabs/GeneralInformation/GeneralInformation.component';
import LogiscticAndCordination from './Tabs/LogiscticAndCordination/LogiscticAndCordination.component';
import ToDoList from './Tabs/ToDoList/ToDoList.component';
import XBApplicationSamplesDetails from './Tabs/XBApplicationSamplesDetails/XBApplicationSampleDetails.component';
import XRMApplicationSamplesDetails from './Tabs/XRMApplicationSamplesDetails/XRMApplicationSamplesDetails.component';
import LMApplicationDetails from './Tabs/LMApplicationSamplesDetails/LMApplicationSamplesDetails.component';
import { NoTypeFormData } from './Tabs/NoTypeFormData/NoTypeFormData.component';

export const  createSemTabsConstant =[
  {
    id: 1,
    tabTitle: 'generalInformation',
    className: '',
    render: (isActive, props) => {
      return <GeneralInformation isActive={isActive} {...props} />;
    },
  },
  {
    id: 2,
    tabTitle: 'customerInformation',
    className: '',
    render: (isActive, props) => {
      return <CustomerInformation isActive={isActive} {...props} />;
    },
  },
  {
    id: 3,
    tabTitle: 'applicationSampleDetails',
    className: '',
    render: (isActive, props) => {
      return <ApplicationSampleDetails isActive={isActive}  {...props}  />;
    },
  },
  {
    id: 4,
    tabTitle: 'demoCenterInformation',
    className: '',
    render: (isActive, props) => {
      return <DemoCenterInformation isActive={isActive} {...props} />;
    },
  },
  {
    id: 6,
    tabTitle: 'logisticsCoordination',
    className: '',
    render: (isActive, props) => {
      return <LogiscticAndCordination isActive={isActive} {...props} />;
    },
  },
  {
    id: 7,
    tabTitle: 'toDoList',
    className: '',
    render: (isActive, props) => {
      return <ToDoList isActive={isActive} {...props} />;
    },
  },
]


export const createXbTabsConstants = [
  {
    id: 1,
    tabTitle: 'generalInformation',
    className: '',
    render: (isActive, props) => {
      return <GeneralInformation isActive={isActive} {...props} />;
    },
  },
  {
    id: 2,
    tabTitle: 'customerInformation',
    className: '',
    render: (isActive, props) => {
      return <CustomerInformation isActive={isActive} {...props} />;
    },
  },
  {
    id: 3,
    tabTitle: 'applicationSampleDetails',
    className: '',
    render: (isActive, props) => {
      return <XBApplicationSamplesDetails isActive={isActive}  {...props}  />;
    },
  },
  {
    id: 4,
    tabTitle: 'demoCenterInformation',
    className: '',
    render: (isActive, props) => {
      return <DemoCenterInformation isActive={isActive} {...props} />;
    },
  },
  {
    id: 6,
    tabTitle: 'logisticsCoordination',
    className: '',
    render: (isActive, props) => {
      return <LogiscticAndCordination isActive={isActive} {...props} />;
    },
  },
  {
    id: 7,
    tabTitle: 'toDoList',
    className: '',
    render: (isActive, props) => {
      return <ToDoList isActive={isActive} {...props} />;
    },
  },
];

export const createXRMTabsConstants = [
  {
    id: 1,
    tabTitle: 'generalInformation',
    className: '',
    render: (isActive, props) => {
      return <GeneralInformation isActive={isActive} {...props} />;
    },
  },
  {
    id: 2,
    tabTitle: 'customerInformation',
    className: '',
    render: (isActive, props) => {
      return <CustomerInformation isActive={isActive} {...props} />;
    },
  },
  {
    id: 3,
    tabTitle: 'applicationSampleDetails',
    className: '',
    render: (isActive, props) => {
      return <XRMApplicationSamplesDetails isActive={isActive}  {...props}  />;
    },
  },
  {
    id: 4,
    tabTitle: 'demoCenterInformation',
    className: '',
    render: (isActive, props) => {
      return <DemoCenterInformation isActive={isActive} {...props} />;
    },
  },
  {
    id: 6,
    tabTitle: 'logisticsCoordination',
    className: '',
    render: (isActive, props) => {
      return <LogiscticAndCordination isActive={isActive} {...props} />;
    },
  },
  {
    id: 7,
    tabTitle: 'toDoList',
    className: '',
    render: (isActive, props) => {
      return <ToDoList isActive={isActive} {...props} />;
    },
  },
];

export const createLmTabsConstants = [
  {
    id: 1,
    tabTitle: 'generalInformation',
    className: '',
    render: (isActive, props) => {
      return <GeneralInformation isActive={isActive} {...props} />;
    },
  },
  {
    id: 2,
    tabTitle: 'customerInformation',
    className: '',
    render: (isActive, props) => {
      return <CustomerInformation isActive={isActive} {...props} />;
    },
  },
  {
    id: 3,
    tabTitle: 'applicationSampleDetails',
    className: '',
    render: (isActive, props) => {
      return <LMApplicationDetails isActive={isActive}  {...props}  />;
    },
  },
  {
    id: 4,
    tabTitle: 'demoCenterInformation',
    className: '',
    render: (isActive, props) => {
      return <DemoCenterInformation isActive={isActive} {...props} />;
    },
  },
  {
    id: 6,
    tabTitle: 'logisticsCoordination',
    className: '',
    render: (isActive, props) => {
      return <LogiscticAndCordination isActive={isActive} {...props} />;
    },
  },
  {
    id: 7,
    tabTitle: 'toDoList',
    className: '',
    render: (isActive, props) => {
      return <ToDoList isActive={isActive} {...props} />;
    },
  },
];


export const noTypeTab = [
  {
    id: 1,
    tabTitle: '',
    className: '',
    render: (isActive, props) => {
      return <NoTypeFormData isActive={isActive} {...props} />;
    },
  },
]
