import React from 'react';
import strings from 'resources/locales/Translate';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import { refurbishmentFeesBreadcrumbData, refurbishmentFeesModel } from './RefurbishmenFess.constants';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import Button from 'library/common/commonComponents/Button/Button';
import { FormComponent } from 'library/common/commonComponents/FormComponent/Optimized';
import { useRefurbishmentFeesHook } from './RefurbishmenFess.hook';

const RefurbishmentFees = props => {
  const {
    countries,
    fetchMasterData,
    exportRefurbishmentFess,
    language,
    refurbushmentFeesReducer,
    fetchRefurbishmentStocks,
  } = props;
  const { refurbishmentFeesForm, model, handleForm, onExport, isLoading } = useRefurbishmentFeesHook(props);
  return (
    <>
      <div>
        <div className='container-fluid mb-3'>
          <Breadcrumbs data={refurbishmentFeesBreadcrumbData} />
          <div className='row no-gutters align-items-center'>
            <h1 className='col-md col-12 kit-title'>{strings.refurbishmentFees}</h1>
          </div>
        </div>

        <div className='container-fluid filter-wrapper my-3 d-block'>
          <div>
            <FormComponent
              formName='refurbishmentFeesForm'
              model={model}
              formValue={refurbishmentFeesForm.formData}
              onChange={handleForm}
            />
            <Button
              dataTest='export-list-button'
              bgFill={false}
              iconName='download'
              value={strings.exportList}
              styleClass='col-sm-2 mt-3'
              onClick={() => onExport(refurbishmentFeesForm)}
              loading={isLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RefurbishmentFees;
