import React from 'react';

import Modal from 'library/common/commonComponents/Modal';
import strings from 'resources/locales/Translate';

export default function CancelReservationPopup({
  id,
  error,
  isLoading,
  handleConfirmation,
  closePopup,
}) {
  return (
    <Modal
      id={id}
      confirmAction={handleConfirmation}
      confirmTitle={strings.confirm}
      loading={isLoading}
      disableBackgroundClose
      onCloseClick={closePopup}
    >
      <h3>{strings.cancelReservationPopupTitle}</h3>
      {error && <div className='error-block'>{strings[error]}</div>}
    </Modal>
  );
}
