import React from 'react';

import strings from 'resources/locales/Translate';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import FileUploader from 'library/common/commonComponents/FileUploader/FileUploader';
import Tabs from 'library/common/commonComponents/Tabs/container/TabsContainer';

import { createKitBreadcrumbs } from '../CreateKit/CreateKit.constants';
import { generalInformationZMCCModel, generalInformationIQSModel } from './CreateZmccKit.constants';
import { useCreateZmccKit } from './CreateZmccKit.hook';
import KitPictureUploader from '../KitPictureUploader/KitPictureUploader.component';
import { IQS_SSC_DE_DESIGNATION } from 'library/utilities/constants';

const CreateZmccKit = props => {
  const {
    appActions,
    generalInformationForm,
    informationForm,
    updateForm,
    location,
    fetchStocks,
    createZmccKit,
    freeAccessoriesForm,
    furtherImportantComponentForm,
    salesComponentForm,
    kitStatusForm,
    kitDetails,
    kitInformationId,
    kitAttachments,
    kitAttachmentsToDelete,
    kitImages,
    kitImagesToDelete,
    fetchMasterData,
    masterData,
    fetchActiveUnitDate,
    fetchZMCCKitDetails,
    resetForm,
    fetchSystemClasses,
    history,
    match,
    fetchRoomsListForWarehouse,
    fetchUsersOfBusinessUnit,
    rooms,
    zmccUsers,
    language,
    vrDetalisForm,
    fetchFunctionDropdownValues,
    resourceFunctionValues,
    iqsIdentifierForm,
    iqsStatusForm,
    iqsLoanInformationForm,
    newComponentsForm,
    addUpdateNewComponent,
    getAttachmentCategory,
    kitResourceAttachmentCategoriesList,
    fetchKitDetailsMessages,
    fetchKitDetailsComments,
    fetchNewComponentsList,
    newComponentsList,
    toggleActionMessage,
    deleteNewComponent,
    createKitMessage,
    fetchKitMessagesList,
    kitMessagesList,
    fetchKitResourceAttachmentCategories,
    kitNewAttachments,
    getKitAttachmentList,
    changeLoader,
    deleteKitAttachment,
    fetchUserList,
    marketplaceInformation,
    marketplacePrice,
    getIsMarketplaceActive,
    isMarketplaceActiveInLocation,
    componentsFetchedFromIbase,
    getSystemClassByProductHierarchy,
    ibaseComponentsList
  } = props;
  const { displayActionMessage, type, message, isLoading } = appActions;

  const businessUnitsData = masterData.businessUnit.filter(item =>
    item.designation === "RMS_ZMCC" || item.designation === "IQS_SSC_DE"
  );
  const rmsBuData = masterData.businessUnit.filter(item => item.designation === "RMS")[0];
  const rmsIQSData = masterData.businessUnit.filter(item => item.designation === "IQS")[0];

  const {
    title,
    tabs,
    addKitImageFile,
    removeKitImageFile,
    handleForm,
    onMaxFileReached,
    maxFilesError,
    gernalInformationModelEX,
    getTabId
  } = useCreateZmccKit({
    history,
    location,
    updateForm,
    fetchStocks,
    generalInformationForm,
    createZmccKit,
    freeAccessoriesForm,
    furtherImportantComponentForm,
    salesComponentForm,
    kitDetails,
    kitInformationId,
    kitAttachments,
    kitAttachmentsToDelete,
    kitImages,
    kitImagesToDelete,
    fetchMasterData,
    informationForm,
    fetchActiveUnitDate,
    kitStatusForm,
    fetchZMCCKitDetails,
    resetForm,
    fetchSystemClasses,
    match,
    masterData,
    businessUnitsData,
    rmsBuData,
    rmsIQSData,
    fetchRoomsListForWarehouse,
    rooms,
    fetchUsersOfBusinessUnit,
    zmccUsers,
    language,
    vrDetalisForm,
    generalInformationIQSModel,
    generalInformationZMCCModel,
    fetchFunctionDropdownValues,
    resourceFunctionValues,
    iqsIdentifierForm,
    iqsStatusForm,
    iqsLoanInformationForm,
    newComponentsForm,
    addUpdateNewComponent,
    getAttachmentCategory,
    kitResourceAttachmentCategoriesList,
    fetchKitDetailsMessages,
    fetchKitDetailsComments,
    fetchNewComponentsList,
    newComponentsList,
    toggleActionMessage,
    deleteNewComponent,
    createKitMessage,
    fetchKitMessagesList,
    kitMessagesList,
    fetchKitResourceAttachmentCategories,
    kitNewAttachments,
    getKitAttachmentList,
    changeLoader,
    deleteKitAttachment,
    fetchUserList,
    marketplaceInformation,
    marketplacePrice,
    getIsMarketplaceActive,
    isMarketplaceActiveInLocation,
    componentsFetchedFromIbase,
    getSystemClassByProductHierarchy,
    ibaseComponentsList
  });
  const isKitCreating = location.pathname.includes('create');
  const buIqsSscDeInformationSelected = informationForm.formData?.businessUnitFullValue?.designation === IQS_SSC_DE_DESIGNATION;
  return (
    <div className='create-kit-container'>
      {displayActionMessage && <ActionMessage type={type} message={strings[message] || message} />}
      <div className='container-fluid'>
        <Breadcrumbs data={createKitBreadcrumbs()} />
        <h1 className='page-title'>{title}</h1>

        <div className='row mb-3 images-general-info'>
          <div className={`${kitImages.length > 0 ? 'col-sm-5' : maxFilesError ? 'col-sm-5' :'col-sm-3'}`}>
            <KitPictureUploader
              mimeType='image/*,.czi'
              files={kitImages}
              maxSize={50}
              errorMessage={strings.maxFileSize}
              onFileAdd={addKitImageFile}
              onRemove={removeKitImageFile}
              newVersionUi={true}
              isUploadAllowed={true}
              uploaderStyle={"kit-attachments-bordered kits-picture-upload"}
              maxFiles={6}
              onMaxFileReached={onMaxFileReached}
              maxFilesError={maxFilesError}
              availableTypes={strings.availableTypesOnlyImagesOnKit}
            />
          </div>
          <div className= {`${kitImages.length > 0 ? 'col-sm-7' : maxFilesError ? 'col-sm-7' : 'col-sm-8'}`}>
            <FormComponent
              model={gernalInformationModelEX}
              formName='generalInformationForm'
              formValue={generalInformationForm.formData}
              onChange={handleForm}
            />
          </div>
        </div>
      </div>
      <div style={{marginTop:'30px'}}>
        {
          (buIqsSscDeInformationSelected && isKitCreating) ?
            <Tabs
            tabs={tabs}
            newUiVersion={true}
            toggleTab={true}
            switchableTabs={[1,2]}
            multiTabSwitch={true}
            getTabId={id => getTabId(id)}
          /> :
          <Tabs tabs={tabs} newUiVersion={true} getTabId={id => getTabId(id)} />
        }

      </div>

      {isLoading && <div className='loader' />}
    </div>
  );
}

export default CreateZmccKit;
