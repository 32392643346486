import DatePicker from 'react-date-picker';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import strings from 'resources/locales/Translate';

import Icon from '../Icon/Icon';
import './dateInput.styles.scss';
import { getLocale } from 'library/utilities/getLocale';

class DateInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: props.selectedDate ? new Date(props.selectedDate) : '',
      isCalendarVisible: false,
      error: false,
      errorMessage: null,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.clear) {
      if (prevProps.clear !== this.props.clear) {
        this.setState({ value: '' });
      }
    }

    if (this.props.selectedDate && this.props.selectedDate !== prevProps.selectedDate) {
      if (Array.isArray(this.props.selectedDate)) {
        this.setState({ date: this.props.selectedDate }, () => {
          this.validateValue();
        });
      } else {
        this.setState({ date: new Date(this.props.selectedDate) }, () => {
          this.validateValue();
        });
      }
    }

    if (this.props.doValidateForm && this.props.doValidateForm !== prevProps.doValidateForm) {
      if (Array.isArray(this.props.selectedDate)) {
        this.setState({ date: this.props.selectedDate }, () => {
          this.validateValue();
        });
      } else {
        this.setState({ date: this.props.selectedDate ? new Date(this.props.selectedDate) : '' }, () => {
          this.validateValue();
        });
      }
    }

    if (this.props.doValidateForm && this.props.doValidateForm !== prevProps.doValidateForm) {
      this.validateValue();
    }

    if (this.props.resetValidation && this.props.resetValidation !== prevProps.resetValidation) {
      this.setState({ error: false, errorMessage: null });
    }
  }

  setError = error => {
    const { field, onError } = this.props;
    if (error.error) {
      this.setState({ error: true, errorMessage: error.message });
      onError(error, field);
    } else {
      this.setState({ error: false });
      onError({ error: false, message: null }, field);
    }
  };

  validate = value => {
    const { validators } = this.props;

    for (let i = 0; i < validators.length; i++) {
      let error = validators[i].check(value, validators[i].message, validators[i].minDate);
      if (error) {
        return error;
      }
    }

    return false;
  };

  validateValue = () => {
    this.setError(this.validate(this.state.date));
    if (this.props.onChangeDate) {
      this.props.onChangeDate(this.state.date, this.props.field);
    }
  };

  validateOnBlur = () => {
    if(!this.props.validateOnBlur){
      return;
    }
    this.validateValue();
  }

  onChange = date => {
    const newDate = date ? date : '';
    this.setState({ date: newDate });
    this.props.onChangeDate(newDate, this.props.field);
  };

  onChange = date => {
    const newDate = date ? date : '';
    this.setState({ date: newDate });
    this.props.onChangeDate(newDate);
  };

  toggleCalendar = isVisible => {
    const { isOpenDateModal } = this.props;

    if (isOpenDateModal) {
      this.setState({
        isCalendarVisible: isVisible,
      });
    }
  };

  handleClick = e => {
    const { isOpenDateModal, disabled } = this.props;

    if (!isOpenDateModal && !disabled) {
      this.props.onClick(e);
    }
  };
  onClear = (e) => {
    e.stopPropagation();
    this.onChange(null, this.props.field)
  }

  render() {
    const { isCalendarVisible, date, error, errorMessage } = this.state;
    const {
      datePickerFieldStyle,
      placeholder,
      dateFormat,
      dataTest,
      label,
      additionalLabel,
      footerLabel,
      datePickerLabelStyle,
      mainContainerStyle,
      isRequired,
      hideOptionalText,
      minDate,
      isRange,
      disabled,
      errorToShow,
      enableClearDate,
      newDropdownStyle,
      locale,
      language
    } = this.props;

    const displayDate = date ? moment(date).format(dateFormat) : placeholder;
    const displayDateRange =
      date.length === 1
        ? moment(date[0]).format(dateFormat)
        : `${moment(date[0]).format(dateFormat)} - ${moment(date[1]).format(dateFormat)}`;
    const datePlaceholder = isRange && Array.isArray(date) ? displayDateRange : displayDate;
    return (
      <div className={`date-input-container ${mainContainerStyle}`} data-test={dataTest} onClick={this.handleClick}>
        <p className={'date-picker-label ' + datePickerLabelStyle}>{label} {isRequired && label && <span className='mandatory-asterik'>*</span>}</p>
        {!!additionalLabel && <p className='date-picker-additional-label'>{additionalLabel}</p>}
        <div
          className={`app-datepicker cursor-pointer ${datePickerFieldStyle} ${(error || errorToShow) && 'input-error'}`}
        >
          <div
            className={
              'd-flex datepicker-view justify-content-between align-items-center text-overflow-ellipsis ' +
              (isCalendarVisible && 'calendar-shadow') +
              (disabled && ' disabled') +' '+ (newDropdownStyle && (datePlaceholder.includes(strings.pleaseSelectDate) ? 'new-dropdown-placeholder' : 'slected-value-placeholder'))
            }
            onClick={() => {
              !disabled && this.toggleCalendar(!isCalendarVisible);
            }}
          >
            <span>{datePlaceholder}</span>
            <div>
              {enableClearDate && date && !disabled ? (
                <span className='mr-1' onClick={this.onClear}>
                  &times;
                </span>
              ) : null}
            <Icon name='calendar' width={24} height={24} fill={'rgba(0,0,0,0.5)'} />
            </div>
          </div>
          <DatePicker
            data-test={dataTest}
            {...this.props}
            minDetail={'decade'}
            className={'datepicker-component-container'}
            calendarClassName={'datepicker-calendar-container calendar-shadow'}
            calendarIcon={<Icon name='calendar' width={24} height={24} />}
            clearIcon={date ? <span>&times;</span> : null}
            showLeadingZeros={true}
            isOpen={isCalendarVisible}
            onChange={this.onChange}
            onBlur={this.validateOnBlur}
            value={date}
            minDate={minDate && moment(minDate).isValid() ? new Date(minDate) : null}
            selectRange={isRange ? true : false}
            locale={getLocale(language.locale)} // "en-GB": English (Great Britain) – Week starts on Monday, and date format is DD/MM/YYYY
          />
        </div>
        {!!footerLabel && <p className='date-picker-footer-label'>{footerLabel}</p>}
        {(error || errorToShow) && (
          <span className='text-danger error-block'>{strings[errorMessage] || errorMessage || errorToShow}</span>
        )}
      </div>
    );
  }
}

DateInput.propTypes = {
  onChangeDate: PropTypes.func,
  datePickerFieldStyle: PropTypes.string,
  placeholder: PropTypes.string,
  dateFormat: PropTypes.string,
  label: PropTypes.string,
  datePickerLabelStyle: PropTypes.string,
  mainContainerStyle: PropTypes.string,
  selectedDate: PropTypes.any,
  isRange: PropTypes.bool,
};

DateInput.defaultProps = {
  placeholder: strings.pleaseSelectDate + ' ...',
  onChangeDate: () => {},
  datePickerFieldStyle: '',
  minDate: null,
  dateFormat: 'DD.MM.YYYY',
  label: strings.selectDate,
  mainContainerStyle: '',
  isRequired: false,
  selectedDate: null,
  validators: [],
  onError: () => {},
  doValidateForm: false,
  isRange: false,
  isOpenDateModal: true,
  onClick: () => {},
  newDropdownStyle: false,
  validateOnBlur: true,
  locale: "en-GB"
};

const mapStateToProps = state => {
  return {
    language: state.languageReducer,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(DateInput);
