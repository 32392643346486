import { connect } from 'react-redux';

import SystemClassificationTab from './SystemClassificationTab.component';
import { getSystemClassificationList, getSystemMainComponentList, saveOrDeleteSystemMainComponent } from '../../LLManagement.actions';

const mapStateToProps = ({ authReducer, languageReducer, llManagementReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
  systemClassificationList: llManagementReducer.systemClassificationList,
  systemMainComponentList: llManagementReducer.systemMainComponentList,
});

export default connect(mapStateToProps, {
    getSystemClassificationList,
    getSystemMainComponentList,
    saveOrDeleteSystemMainComponent
})(SystemClassificationTab);
