export const countries_En = [{
  country: "Afghanistan",
  shortName: "AF"
}, {
  country: "Åland Islands",
  shortName: "AX"
}, {
  country: "Albania",
  shortName: "AL"
}, {
  country: "Algeria",
  shortName: "DZ"
}, {
  country: "American Samoa",
  shortName: "AS"
}, {
  country: "Andorra",
  shortName: "AD"
}, {
  country: "Angola",
  shortName: "AO"
}, {
  country: "Anguilla",
  shortName: "AI"
}, {
  country: "Antarctica",
  shortName: "AQ"
}, {
  country: "Antigua and Barbuda",
  shortName: "AG"
}, {
  country: "Argentina",
  shortName: "AR"
}, {
  country: "Armenia",
  shortName: "AM"
}, {
  country: "Aruba",
  shortName: "AW"
}, {
  country: "Australia",
  shortName: "AU"
}, {
  country: "Austria",
  shortName: "AT"
}, {
  country: "Azerbaijan",
  shortName: "AZ"
}, {
  country: "Bahamas",
  shortName: "BS"
}, {
  country: "Bahrain",
  shortName: "BH"
}, {
  country: "Bangladesh",
  shortName: "BD"
}, {
  country: "Barbados",
  shortName: "BB"
}, {
  country: "Belarus",
  shortName: "BY"
}, {
  country: "Belgium",
  shortName: "BE"
}, {
  country: "Belize",
  shortName: "BZ"
}, {
  country: "Benin",
  shortName: "BJ"
}, {
  country: "Bermuda",
  shortName: "BM"
}, {
  country: "Bhutan",
  shortName: "BT"
}, {
  country: "Bolivia (Plurinational State of)",
  shortName: "BO"
}, {
  country: "Bonaire, Sint Eustatius and Saba",
  shortName: "BQ"
}, {
  country: "Bosnia and Herzegovina",
  shortName: "BA"
}, {
  country: "Botswana",
  shortName: "BW"
}, {
  country: "Bouvet Island",
  shortName: "BV"
}, {
  country: "Brazil",
  shortName: "BR"
}, {
  country: "British Indian Ocean Territory",
  shortName: "IO"
}, {
  country: "United States Minor Outlying Islands",
  shortName: "UM"
}, {
  country: "Virgin Islands (British)",
  shortName: "VG"
}, {
  country: "Virgin Islands (U.S.)",
  shortName: "VI"
}, {
  country: "Brunei Darussalam",
  shortName: "BN"
}, {
  country: "Bulgaria",
  shortName: "BG"
}, {
  country: "Burkina Faso",
  shortName: "BF"
}, {
  country: "Burundi",
  shortName: "BI"
}, {
  country: "Cambodia",
  shortName: "KH"
}, {
  country: "Cameroon",
  shortName: "CM"
}, {
  country: "Canada",
  shortName: "CA"
}, {
  country: "Cabo Verde",
  shortName: "CV"
}, {
  country: "Cayman Islands",
  shortName: "KY"
}, {
  country: "Central African Republic",
  shortName: "CF"
}, {
  country: "Chad",
  shortName: "TD"
}, {
  country: "Chile",
  shortName: "CL"
}, {
  country: "China",
  shortName: "CN"
}, {
  country: "Christmas Island",
  shortName: "CX"
}, {
  country: "Cocos (Keeling) Islands",
  shortName: "CC"
}, {
  country: "Colombia",
  shortName: "CO"
}, {
  country: "Comoros",
  shortName: "KM"
}, {
  country: "Congo",
  shortName: "CG"
}, {
  country: "Congo (Democratic Republic of the)",
  shortName: "CD"
}, {
  country: "Cook Islands",
  shortName: "CK"
}, {
  country: "Costa Rica",
  shortName: "CR"
}, {
  country: "Croatia",
  shortName: "HR"
}, {
  country: "Cuba",
  shortName: "CU"
}, {
  country: "Curaçao",
  shortName: "CW"
}, {
  country: "Cyprus",
  shortName: "CY"
}, {
  country: "Czech Republic",
  shortName: "CZ"
}, {
  country: "Denmark",
  shortName: "DK"
}, {
  country: "Djibouti",
  shortName: "DJ"
}, {
  country: "Dominica",
  shortName: "DM"
}, {
  country: "Dominican Republic",
  shortName: "DO"
}, {
  country: "Ecuador",
  shortName: "EC"
}, {
  country: "Egypt",
  shortName: "EG"
}, {
  country: "El Salvador",
  shortName: "SV"
}, {
  country: "Equatorial Guinea",
  shortName: "GQ"
}, {
  country: "Eritrea",
  shortName: "ER"
}, {
  country: "Estonia",
  shortName: "EE"
}, {
  country: "Ethiopia",
  shortName: "ET"
}, {
  country: "Falkland Islands (Malvinas)",
  shortName: "FK"
}, {
  country: "Faroe Islands",
  shortName: "FO"
}, {
  country: "Fiji",
  shortName: "FJ"
}, {
  country: "Finland",
  shortName: "FI"
}, {
  country: "France",
  shortName: "FR"
}, {
  country: "French Guiana",
  shortName: "GF"
}, {
  country: "French Polynesia",
  shortName: "PF"
}, {
  country: "French Southern Territories",
  shortName: "TF"
}, {
  country: "Gabon",
  shortName: "GA"
}, {
  country: "Gambia",
  shortName: "GM"
}, {
  country: "Georgia",
  shortName: "GE"
}, {
  country: "Germany",
  shortName: "DE"
}, {
  country: "Ghana",
  shortName: "GH"
}, {
  country: "Gibraltar",
  shortName: "GI"
}, {
  country: "Greece",
  shortName: "GR"
}, {
  country: "Greenland",
  shortName: "GL"
}, {
  country: "Grenada",
  shortName: "GD"
}, {
  country: "Guadeloupe",
  shortName: "GP"
}, {
  country: "Guam",
  shortName: "GU"
}, {
  country: "Guatemala",
  shortName: "GT"
}, {
  country: "Guernsey",
  shortName: "GG"
}, {
  country: "Guinea",
  shortName: "GN"
}, {
  country: "Guinea-Bissau",
  shortName: "GW"
}, {
  country: "Guyana",
  shortName: "GY"
}, {
  country: "Haiti",
  shortName: "HT"
}, {
  country: "Heard Island and McDonald Islands",
  shortName: "HM"
}, {
  country: "Holy See",
  shortName: "VA"
}, {
  country: "Honduras",
  shortName: "HN"
}, {
  country: "Hong Kong",
  shortName: "HK"
}, {
  country: "Hungary",
  shortName: "HU"
}, {
  country: "Iceland",
  shortName: "IS"
}, {
  country: "India",
  shortName: "IN"
}, {
  country: "Indonesia",
  shortName: "ID"
}, {
  country: "Côte d'Ivoire",
  shortName: "CI"
}, {
  country: "Iran (Islamic Republic of)",
  shortName: "IR"
}, {
  country: "Iraq",
  shortName: "IQ"
}, {
  country: "Ireland",
  shortName: "IE"
}, {
  country: "Isle of Man",
  shortName: "IM"
}, {
  country: "Israel",
  shortName: "IL"
}, {
  country: "Italy",
  shortName: "IT"
}, {
  country: "Jamaica",
  shortName: "JM"
}, {
  country: "Japan",
  shortName: "JP"
}, {
  country: "Jersey",
  shortName: "JE"
}, {
  country: "Jordan",
  shortName: "JO"
}, {
  country: "Kazakhstan",
  shortName: "KZ"
}, {
  country: "Kenya",
  shortName: "KE"
}, {
  country: "Kiribati",
  shortName: "KI"
}, {
  country: "Kuwait",
  shortName: "KW"
}, {
  country: "Kyrgyzstan",
  shortName: "KG"
}, {
  country: "Lao People's Democratic Republic",
  shortName: "LA"
}, {
  country: "Latvia",
  shortName: "LV"
}, {
  country: "Lebanon",
  shortName: "LB"
}, {
  country: "Lesotho",
  shortName: "LS"
}, {
  country: "Liberia",
  shortName: "LR"
}, {
  country: "Libya",
  shortName: "LY"
}, {
  country: "Liechtenstein",
  shortName: "LI"
}, {
  country: "Lithuania",
  shortName: "LT"
}, {
  country: "Luxembourg",
  shortName: "LU"
}, {
  country: "Macao",
  shortName: "MO"
}, {
  country: "Macedonia (the former Yugoslav Republic of)",
  shortName: "MK"
}, {
  country: "Madagascar",
  shortName: "MG"
}, {
  country: "Malawi",
  shortName: "MW"
}, {
  country: "Malaysia",
  shortName: "MY"
}, {
  country: "Maldives",
  shortName: "MV"
}, {
  country: "Mali",
  shortName: "ML"
}, {
  country: "Malta",
  shortName: "MT"
}, {
  country: "Marshall Islands",
  shortName: "MH"
}, {
  country: "Martinique",
  shortName: "MQ"
}, {
  country: "Mauritania",
  shortName: "MR"
}, {
  country: "Mauritius",
  shortName: "MU"
}, {
  country: "Mayotte",
  shortName: "YT"
}, {
  country: "Mexico",
  shortName: "MX"
}, {
  country: "Micronesia (Federated States of)",
  shortName: "FM"
}, {
  country: "Moldova (Republic of)",
  shortName: "MD"
}, {
  country: "Monaco",
  shortName: "MC"
}, {
  country: "Mongolia",
  shortName: "MN"
}, {
  country: "Montenegro",
  shortName: "ME"
}, {
  country: "Montserrat",
  shortName: "MS"
}, {
  country: "Morocco",
  shortName: "MA"
}, {
  country: "Mozambique",
  shortName: "MZ"
}, {
  country: "Myanmar",
  shortName: "MM"
}, {
  country: "Namibia",
  shortName: "NA"
}, {
  country: "Nauru",
  shortName: "NR"
}, {
  country: "Nepal",
  shortName: "NP"
}, {
  country: "Netherlands",
  shortName: "NL"
}, {
  country: "New Caledonia",
  shortName: "NC"
}, {
  country: "New Zealand",
  shortName: "NZ"
}, {
  country: "Nicaragua",
  shortName: "NI"
}, {
  country: "Niger",
  shortName: "NE"
}, {
  country: "Nigeria",
  shortName: "NG"
}, {
  country: "Niue",
  shortName: "NU"
}, {
  country: "Norfolk Island",
  shortName: "NF"
}, {
  country: "Korea (Democratic People's Republic of)",
  shortName: "KP"
}, {
  country: "Northern Mariana Islands",
  shortName: "MP"
}, {
  country: "Norway",
  shortName: "NO"
}, {
  country: "Oman",
  shortName: "OM"
}, {
  country: "Pakistan",
  shortName: "PK"
}, {
  country: "Palau",
  shortName: "PW"
}, {
  country: "Palestine, State of",
  shortName: "PS"
}, {
  country: "Panama",
  shortName: "PA"
}, {
  country: "Papua New Guinea",
  shortName: "PG"
}, {
  country: "Paraguay",
  shortName: "PY"
}, {
  country: "Peru",
  shortName: "PE"
}, {
  country: "Philippines",
  shortName: "PH"
}, {
  country: "Pitcairn",
  shortName: "PN"
}, {
  country: "Poland",
  shortName: "PL"
}, {
  country: "Portugal",
  shortName: "PT"
}, {
  country: "Puerto Rico",
  shortName: "PR"
}, {
  country: "Qatar",
  shortName: "QA"
}, {
  country: "Republic of Kosovo",
  shortName: "XK"
}, {
  country: "Réunion",
  shortName: "RE"
}, {
  country: "Romania",
  shortName: "RO"
}, {
  country: "Russian Federation",
  shortName: "RU"
}, {
  country: "Rwanda",
  shortName: "RW"
}, {
  country: "Saint Barthélemy",
  shortName: "BL"
}, {
  country: "Saint Helena, Ascension and Tristan da Cunha",
  shortName: "SH"
}, {
  country: "Saint Kitts and Nevis",
  shortName: "KN"
}, {
  country: "Saint Lucia",
  shortName: "LC"
}, {
  country: "Saint Martin (French part)",
  shortName: "MF"
}, {
  country: "Saint Pierre and Miquelon",
  shortName: "PM"
}, {
  country: "Saint Vincent and the Grenadines",
  shortName: "VC"
}, {
  country: "Samoa",
  shortName: "WS"
}, {
  country: "San Marino",
  shortName: "SM"
}, {
  country: "Sao Tome and Principe",
  shortName: "ST"
}, {
  country: "Saudi Arabia",
  shortName: "SA"
}, {
  country: "Senegal",
  shortName: "SN"
}, {
  country: "Serbia",
  shortName: "RS"
}, {
  country: "Seychelles",
  shortName: "SC"
}, {
  country: "Sierra Leone",
  shortName: "SL"
}, {
  country: "Singapore",
  shortName: "SG"
}, {
  country: "Sint Maarten (Dutch part)",
  shortName: "SX"
}, {
  country: "Slovakia",
  shortName: "SK"
}, {
  country: "Slovenia",
  shortName: "SI"
}, {
  country: "Solomon Islands",
  shortName: "SB"
}, {
  country: "Somalia",
  shortName: "SO"
}, {
  country: "South Africa",
  shortName: "ZA"
}, {
  country: "South Georgia and the South Sandwich Islands",
  shortName: "GS"
}, {
  country: "Korea (Republic of)",
  shortName: "KR"
}, {
  country: "South Sudan",
  shortName: "SS"
}, {
  country: "Spain",
  shortName: "ES"
}, {
  country: "Sri Lanka",
  shortName: "LK"
}, {
  country: "Sudan",
  shortName: "SD"
}, {
  country: "Suricountry",
  shortName: "SR"
}, {
  country: "Svalbard and Jan Mayen",
  shortName: "SJ"
}, {
  country: "Swaziland",
  shortName: "SZ"
}, {
  country: "Sweden",
  shortName: "SE"
}, {
  country: "Switzerland",
  shortName: "CH"
}, {
  country: "Syrian Arab Republic",
  shortName: "SY"
}, {
  country: "Taiwan",
  shortName: "TW"
}, {
  country: "Tajikistan",
  shortName: "TJ"
}, {
  country: "Tanzania, United Republic of",
  shortName: "TZ"
}, {
  country: "Thailand",
  shortName: "TH"
}, {
  country: "Timor-Leste",
  shortName: "TL"
}, {
  country: "Togo",
  shortName: "TG"
}, {
  country: "Tokelau",
  shortName: "TK"
}, {
  country: "Tonga",
  shortName: "TO"
}, {
  country: "Trinidad and Tobago",
  shortName: "TT"
}, {
  country: "Tunisia",
  shortName: "TN"
}, {
  country: "Turkey",
  shortName: "TR"
}, {
  country: "Turkmenistan",
  shortName: "TM"
}, {
  country: "Turks and Caicos Islands",
  shortName: "TC"
}, {
  country: "Tuvalu",
  shortName: "TV"
}, {
  country: "Uganda",
  shortName: "UG"
}, {
  country: "Ukraine",
  shortName: "UA"
}, {
  country: "United Arab Emirates",
  shortName: "AE"
}, {
  country: "United Kingdom of Great Britain and Northern Ireland",
  shortName: "GB"
}, {
  country: "United States of America",
  shortName: "US"
}, {
  country: "Uruguay",
  shortName: "UY"
}, {
  country: "Uzbekistan",
  shortName: "UZ"
}, {
  country: "Vanuatu",
  shortName: "VU"
}, {
  country: "Venezuela (Bolivarian Republic of)",
  shortName: "VE"
}, {
  country: "Viet Nam",
  shortName: "VN"
}, {
  country: "Wallis and Futuna",
  shortName: "WF"
}, {
  country: "Western Sahara",
  shortName: "EH"
}, {
  country: "Yemen",
  shortName: "YE"
}, {
  country: "Zambia",
  shortName: "ZM"
}, {
  country: "Zimbabwe",
  shortName: "ZW"
}];
export const countries_De = [{
  country: "Afghanistan",
  shortName: "AF"
}, {
  country: "Åland Islands",
  shortName: "AX"
}, {
  country: "Albanien",
  shortName: "AL"
}, {
  country: "Algeria",
  shortName: "DZ"
}, {
  country: "American Samoa",
  shortName: "AS"
}, {
  country: "Andorra",
  shortName: "AD"
}, {
  country: "Angola",
  shortName: "AO"
}, {
  country: "Anguilla",
  shortName: "AI"
}, {
  country: "Antarktis",
  shortName: "AQ"
}, {
  country: "Antigua and Barbuda",
  shortName: "AG"
}, {
  country: "Argentinien",
  shortName: "AR"
}, {
  country: "Armenia",
  shortName: "AM"
}, {
  country: "Aruba",
  shortName: "AW"
}, {
  country: "Australien",
  shortName: "AU"
}, {
  country: "Österreich",
  shortName: "AT"
}, {
  country: "Azerbaijan",
  shortName: "AZ"
}, {
  country: "Bahamas",
  shortName: "BS"
}, {
  country: "Bahrain",
  shortName: "BH"
}, {
  country: "Bangladesh",
  shortName: "BD"
}, {
  country: "Barbados",
  shortName: "BB"
}, {
  country: "Belarus",
  shortName: "BY"
}, {
  country: "Belgien",
  shortName: "BE"
}, {
  country: "Belize",
  shortName: "BZ"
}, {
  country: "Benin",
  shortName: "BJ"
}, {
  country: "Bermuda",
  shortName: "BM"
}, {
  country: "Bhutan",
  shortName: "BT"
}, {
  country: "Bolivia (Plurinational State of)",
  shortName: "BO"
}, {
  country: "Bonaire, Sint Eustatius and Saba",
  shortName: "BQ"
}, {
  country: "Bosnien und Herzegowina",
  shortName: "BA"
}, {
  country: "Botswana",
  shortName: "BW"
}, {
  country: "Bouvet Island",
  shortName: "BV"
}, {
  country: "Brasilien",
  shortName: "BR"
}, {
  country: "British Indian Ocean Territory",
  shortName: "IO"
}, {
  country: "United States Minor Outlying Islands",
  shortName: "UM"
}, {
  country: "Virgin Islands (British)",
  shortName: "VG"
}, {
  country: "Virgin Islands (U.S.)",
  shortName: "VI"
}, {
  country: "Brunei Darussalam",
  shortName: "BN"
}, {
  country: "Bulgarien",
  shortName: "BG"
}, {
  country: "Burkna Faso",
  shortName: "BF"
}, {
  country: "Burundi",
  shortName: "BI"
}, {
  country: "Cambodia",
  shortName: "KH"
}, {
  country: "Cameroon",
  shortName: "CM"
}, {
  country: "Kanada",
  shortName: "CA"
}, {
  country: "Cabo Verde",
  shortName: "CV"
}, {
  country: "Cayman Islands",
  shortName: "KY"
}, {
  country: "Central African Republic",
  shortName: "CF"
}, {
  country: "Chad",
  shortName: "TD"
}, {
  country: "Chile",
  shortName: "CL"
}, {
  country: "China",
  shortName: "CN"
}, {
  country: "Christmas Island",
  shortName: "CX"
}, {
  country: "Cocos (Keeling) Islands",
  shortName: "CC"
}, {
  country: "Colombia",
  shortName: "CO"
}, {
  country: "Comoros",
  shortName: "KM"
}, {
  country: "Congo",
  shortName: "CG"
}, {
  country: "Congo (Democratic Republic of the)",
  shortName: "CD"
}, {
  country: "Cook Islands",
  shortName: "CK"
}, {
  country: "Costa Rica",
  shortName: "CR"
}, {
  country: "Kroatien",
  shortName: "HR"
}, {
  country: "Cuba",
  shortName: "CU"
}, {
  country: "Curaçao",
  shortName: "CW"
}, {
  country: "Cyprus",
  shortName: "CY"
}, {
  country: "Czech Republic",
  shortName: "CZ"
}, {
  country: "Dänemark",
  shortName: "DK"
}, {
  country: "Djibouti",
  shortName: "DJ"
}, {
  country: "Dominica",
  shortName: "DM"
}, {
  country: "Dominican Republic",
  shortName: "DO"
}, {
  country: "Ecuador",
  shortName: "EC"
}, {
  country: "Egypt",
  shortName: "EG"
}, {
  country: "El Salvador",
  shortName: "SV"
}, {
  country: "Equatorial Guinea",
  shortName: "GQ"
}, {
  country: "Eritrea",
  shortName: "ER"
}, {
  country: "Estland",
  shortName: "EE"
}, {
  country: "Ethiopia",
  shortName: "ET"
}, {
  country: "Falkland Islands (Malvinas)",
  shortName: "FK"
}, {
  country: "Faroe Islands",
  shortName: "FO"
}, {
  country: "Fiji",
  shortName: "FJ"
}, {
  country: "Finnland",
  shortName: "FI"
}, {
  country: "Frankreich",
  shortName: "FR"
}, {
  country: "French Guiana",
  shortName: "GF"
}, {
  country: "French Polynesia",
  shortName: "PF"
}, {
  country: "French Southern Territories",
  shortName: "TF"
}, {
  country: "Gabon",
  shortName: "GA"
}, {
  country: "Gambia",
  shortName: "GM"
}, {
  country: "Georgia",
  shortName: "GE"
}, {
  country: "Deutschland",
  shortName: "DE"
}, {
  country: "Ghana",
  shortName: "GH"
}, {
  country: "Gibraltar",
  shortName: "GI"
}, {
  country: "Griechenland",
  shortName: "GR"
}, {
  country: "Greenland",
  shortName: "GL"
}, {
  country: "Grenada",
  shortName: "GD"
}, {
  country: "Guadeloupe",
  shortName: "GP"
}, {
  country: "Guam",
  shortName: "GU"
}, {
  country: "Guatemala",
  shortName: "GT"
}, {
  country: "Guernsey",
  shortName: "GG"
}, {
  country: "Guinea",
  shortName: "GN"
}, {
  country: "Guinea-Bissau",
  shortName: "GW"
}, {
  country: "Guyana",
  shortName: "GY"
}, {
  country: "Haiti",
  shortName: "HT"
}, {
  country: "Heard Island and McDonald Islands",
  shortName: "HM"
}, {
  country: "Holy See",
  shortName: "VA"
}, {
  country: "Honduras",
  shortName: "HN"
}, {
  country: "Hong Kong",
  shortName: "HK"
}, {
  country: "Ungarn",
  shortName: "HU"
}, {
  country: "Iceland",
  shortName: "IS"
}, {
  country: "Indien",
  shortName: "IN"
}, {
  country: "Indonesia",
  shortName: "ID"
}, {
  country: "Côte d'Ivoire",
  shortName: "CI"
}, {
  country: "Iran (Islamic Republic of)",
  shortName: "IR"
}, {
  country: "Iraq",
  shortName: "IQ"
}, {
  country: "Irland",
  shortName: "IE"
}, {
  country: "Isle of Man",
  shortName: "IM"
}, {
  country: "Israel",
  shortName: "IL"
}, {
  country: "Italien",
  shortName: "IT"
}, {
  country: "Jamaica",
  shortName: "JM"
}, {
  country: "Japan",
  shortName: "JP"
}, {
  country: "Jersey",
  shortName: "JE"
}, {
  country: "Jordan",
  shortName: "JO"
}, {
  country: "Kazakhstan",
  shortName: "KZ"
}, {
  country: "Kenya",
  shortName: "KE"
}, {
  country: "Kiribati",
  shortName: "KI"
}, {
  country: "Kuwait",
  shortName: "KW"
}, {
  country: "Kyrgyzstan",
  shortName: "KG"
}, {
  country: "Lao People's Democratic Republic",
  shortName: "LA"
}, {
  country: "Lettland",
  shortName: "LV"
}, {
  country: "Lebanon",
  shortName: "LB"
}, {
  country: "Lesotho",
  shortName: "LS"
}, {
  country: "Liberia",
  shortName: "LR"
}, {
  country: "Libya",
  shortName: "LY"
}, {
  country: "Liechtenstein",
  shortName: "LI"
}, {
  country: "Litauen",
  shortName: "LT"
}, {
  country: "Luxemburg",
  shortName: "LU"
}, {
  country: "Macao",
  shortName: "MO"
}, {
  country: "Macedonia (the former Yugoslav Republic of)",
  shortName: "MK"
}, {
  country: "Madagascar",
  shortName: "MG"
}, {
  country: "Malawi",
  shortName: "MW"
}, {
  country: "Malaysia",
  shortName: "MY"
}, {
  country: "Maldives",
  shortName: "MV"
}, {
  country: "Mali",
  shortName: "ML"
}, {
  country: "Malta",
  shortName: "MT"
}, {
  country: "Marshall Islands",
  shortName: "MH"
}, {
  country: "Martinique",
  shortName: "MQ"
}, {
  country: "Mauritania",
  shortName: "MR"
}, {
  country: "Mauritius",
  shortName: "MU"
}, {
  country: "Mayotte",
  shortName: "YT"
}, {
  country: "Mexiko",
  shortName: "MX"
}, {
  country: "Micronesia (Federated States of)",
  shortName: "FM"
}, {
  country: "Moldova (Republic of)",
  shortName: "MD"
}, {
  country: "Monaco",
  shortName: "MC"
}, {
  country: "Mongolia",
  shortName: "MN"
}, {
  country: "Montenegro",
  shortName: "ME"
}, {
  country: "Montserrat",
  shortName: "MS"
}, {
  country: "Morocco",
  shortName: "MA"
}, {
  country: "Mozambique",
  shortName: "MZ"
}, {
  country: "Myanmar",
  shortName: "MM"
}, {
  country: "Namibia",
  shortName: "NA"
}, {
  country: "Nauru",
  shortName: "NR"
}, {
  country: "Nepal",
  shortName: "NP"
}, {
  country: "Niederlande",
  shortName: "NL"
}, {
  country: "New Caledonia",
  shortName: "NC"
}, {
  country: "Neuseeland",
  shortName: "NZ"
}, {
  country: "Nicaragua",
  shortName: "NI"
}, {
  country: "Niger",
  shortName: "NE"
}, {
  country: "Nigeria",
  shortName: "NG"
}, {
  country: "Niue",
  shortName: "NU"
}, {
  country: "Norfolk Island",
  shortName: "NF"
}, {
  country: "Korea (Democratic People's Republic of)",
  shortName: "KP"
}, {
  country: "Northern Mariana Islands",
  shortName: "MP"
}, {
  country: "Norwegen",
  shortName: "NO"
}, {
  country: "Oman",
  shortName: "OM"
}, {
  country: "Pakistan",
  shortName: "PK"
}, {
  country: "Palau",
  shortName: "PW"
}, {
  country: "Palestine, State of",
  shortName: "PS"
}, {
  country: "Panama",
  shortName: "PA"
}, {
  country: "Papua Neu-Guinea",
  shortName: "PG"
}, {
  country: "Paraguay",
  shortName: "PY"
}, {
  country: "Peru",
  shortName: "PE"
}, {
  country: "Philippinen",
  shortName: "PH"
}, {
  country: "Pitcairn",
  shortName: "PN"
}, {
  country: "Polen",
  shortName: "PL"
}, {
  country: "Portugal",
  shortName: "PT"
}, {
  country: "Puerto Rico",
  shortName: "PR"
}, {
  country: "Katar",
  shortName: "QA"
}, {
  country: "Republic of Kosovo",
  shortName: "XK"
}, {
  country: "Réunion",
  shortName: "RE"
}, {
  country: "Rumänien",
  shortName: "RO"
}, {
  country: "Russian Federation",
  shortName: "RU"
}, {
  country: "Rwanda",
  shortName: "RW"
}, {
  country: "Saint Barthélemy",
  shortName: "BL"
}, {
  country: "Saint Helena, Ascension and Tristan da Cunha",
  shortName: "SH"
}, {
  country: "Saint Kitts and Nevis",
  shortName: "KN"
}, {
  country: "Saint Lucia",
  shortName: "LC"
}, {
  country: "Saint Martin (French part)",
  shortName: "MF"
}, {
  country: "Saint Pierre and Miquelon",
  shortName: "PM"
}, {
  country: "Saint Vincent and the Grenadines",
  shortName: "VC"
}, {
  country: "Samoa",
  shortName: "WS"
}, {
  country: "San Marino",
  shortName: "SM"
}, {
  country: "Sao Tome and Principe",
  shortName: "ST"
}, {
  country: "Saudi Arabia",
  shortName: "SA"
}, {
  country: "Senegal",
  shortName: "SN"
}, {
  country: "Serbien",
  shortName: "RS"
}, {
  country: "Seychelles",
  shortName: "SC"
}, {
  country: "Sierra Leone",
  shortName: "SL"
}, {
  country: "Singapur",
  shortName: "SG"
}, {
  country: "Sint Maarten (Dutch part)",
  shortName: "SX"
}, {
  country: "Slovakei",
  shortName: "SK"
}, {
  country: "Slowenien",
  shortName: "SI"
}, {
  country: "Solomon Islands",
  shortName: "SB"
}, {
  country: "Somalia",
  shortName: "SO"
}, {
  country: "Südafrika",
  shortName: "ZA"
}, {
  country: "South Georgia and the South Sandwich Islands",
  shortName: "GS"
}, {
  country: "Korea (Republic of)",
  shortName: "KR"
}, {
  country: "South Sudan",
  shortName: "SS"
}, {
  country: "Spanien",
  shortName: "ES"
}, {
  country: "Sri Lanka",
  shortName: "LK"
}, {
  country: "Sudan",
  shortName: "SD"
}, {
  country: "Suricountry",
  shortName: "SR"
}, {
  country: "Svalbard and Jan Mayen",
  shortName: "SJ"
}, {
  country: "Swaziland",
  shortName: "SZ"
}, {
  country: "Schweden",
  shortName: "SE"
}, {
  country: "Schweiz",
  shortName: "CH"
}, {
  country: "Syrian Arab Republic",
  shortName: "SY"
}, {
  country: "Taiwan",
  shortName: "TW"
}, {
  country: "Tajikistan",
  shortName: "TJ"
}, {
  country: "Tanzania, United Republic of",
  shortName: "TZ"
}, {
  country: "Thailand",
  shortName: "TH"
}, {
  country: "Timor-Leste",
  shortName: "TL"
}, {
  country: "Togo",
  shortName: "TG"
}, {
  country: "Tokelau",
  shortName: "TK"
}, {
  country: "Tonga",
  shortName: "TO"
}, {
  country: "Trinidad and Tobago",
  shortName: "TT"
}, {
  country: "Tunisia",
  shortName: "TN"
}, {
  country: "Türkei",
  shortName: "TR"
}, {
  country: "Turkmenistan",
  shortName: "TM"
}, {
  country: "Turks and Caicos Islands",
  shortName: "TC"
}, {
  country: "Tuvalu",
  shortName: "TV"
}, {
  country: "Uganda",
  shortName: "UG"
}, {
  country: "Ukraine",
  shortName: "UA"
}, {
  country: "Vereinte Arabische Emirate",
  shortName: "AE"
}, {
  country: "United Kingdom of Great Britain and Northern Ireland",
  shortName: "GB"
}, {
  country: "USA",
  shortName: "US"
}, {
  country: "Uruguay",
  shortName: "UY"
}, {
  country: "Uzbekistan",
  shortName: "UZ"
}, {
  country: "Vanuatu",
  shortName: "VU"
}, {
  country: "Venezuela (Bolivarian Republic of)",
  shortName: "VE"
}, {
  country: "Vietnam",
  shortName: "VN"
}, {
  country: "Wallis and Futuna",
  shortName: "WF"
}, {
  country: "Western Sahara",
  shortName: "EH"
}, {
  country: "Yemen",
  shortName: "YE"
}, {
  country: "Zambia",
  shortName: "ZM"
}, {
  country: "Zimbabwe",
  shortName: "ZW"
}];
