import React from 'react';
import strings from 'resources/locales/Translate';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import Icon from 'library/common/commonComponents/Icon/Icon';
import Button from 'library/common/commonComponents/Button/Button';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import { useZmccTodoList } from './ZmccTodoList.hook';
import moment from 'moment';

export const ZmccTodoList = ({ formValues, updateSEMForm, user, zmccInformationTabData, updateZmccTransaction, formType,isClickedOnAdInTodoTab, showAllButton }) => {
  const {
    handleInputChange,
    inputText,
    removeATodoFromList,
    addIntoTodosList,
    onChangeCheckbox,
    isCheckboxSelected,
    onSaveClick,
    todosList,
    errorMsg
  } = useZmccTodoList({ formValues, updateSEMForm, user, zmccInformationTabData, updateZmccTransaction, formType, isClickedOnAdInTodoTab });
  return (
    <>
      <div className='mt-5 container-fluid'>
        {formValues.todosListForm.formData.zmccTodosListDataList &&
          formValues.todosListForm.formData.zmccTodosListDataList.map((item, index) => (
            <>
              <div className={`row m-0 ${item.checkedAt ? '':' mb-4'}`} key={index}>
                <Checkbox
                  label=''
                  field='isEditedBy'
                  isRequired={false}
                  selected={item.isChecked}
                  onChange={i => onChangeCheckbox(i, item, index)}
                  newuiVersionStyle={true}
                  style={{marginTop:'3px'}}
                />
                <div className='col-sm-3'>{item.zmccTodosText}</div>
                <div className='col-sm-1'>
                  <button
                    type='button'
                    className='delete-button delete-icon-name'
                    onClick={() => removeATodoFromList(index)}
                  >
                    <Icon name='close' width={20} height={20} fill='#C91803' className='mr-2' />
                    {/* {strings.delete} */}
                  </button>
                </div>
              </div>
              {item.checkedAt &&<div className='mb-4  ml-5 mt-2' style={{fontSize: '14px'}}>{`${strings.editedBy}: ${item.checkedUser ? item.checkedUser.firstName: '' } ${item.checkedUser ? item.checkedUser.lastName: ''}  ${moment.utc(item.checkedAt).format('YYYY-MM-DD')}`}</div>}
              <div className='adjust-border-for-todo-list-item'></div>
            </>
          ))}
      </div>

      <div className='mt-5 pl-3'>
        <div className='col-sm-4 mt-4'>
          <InputField
            label={''}
            field='todoText'
            placeholder={strings.addDescription}
            isRequired={false}
            type='text'
            validators={[]}
            value={inputText}
            error={errorMsg}
            onChange={handleInputChange}
            // className={'mb-3'}
          />
        </div>
        <div className='col-sm-2 mt-2 p-0'>
          <Button
            value={strings.add}
            onClick={() => addIntoTodosList(inputText)}
            bgFill={false}
            iconName='plus'
            disabled={
              formValues.todosListForm.formData.zmccTodosListDataList &&
              formValues.todosListForm.formData.zmccTodosListDataList.length === 20
            }
            newVersionUi={true}
            styleClass="button-without-border"
          />
        </div>
      </div>
    </>
  );
};

export default ZmccTodoList;
