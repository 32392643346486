import { connect } from 'react-redux';

import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';

import MeusWarehousAddress from './MedUsWarehouseAddress.component';
import { medUsAddress, editMedUsAddress, deleteMedAddress } from '../../MasterData.actions';

const mapStateToProps = ({ languageReducer, appActionsReducer, medUsAddressReducer, masterDataReducer }) => ({
  language: languageReducer,
  medAddressArray: medUsAddressReducer.medAddressArray,
  masterData: masterDataReducer,
});

export default connect(mapStateToProps, {
  toggleActionMessage,
  medUsAddress,
  editMedUsAddress,
  deleteMedAddress,
})(MeusWarehousAddress);