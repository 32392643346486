import React from 'react';
import cn from 'classnames';

import './enhancedCheckboxStyles.scss';

export const EnhancedCheckbox = ({ 
  className, 
  dataTest, 
  disabled, 
  field, 
  label, 
  onChange, 
  selected, 
  style, 
  small, 
  newuiVersionStyle, 
  customeStyledClass, 
  labelTopPosition 
}) => {
  const handleChange = event => {
    const { checked } = event.target;
    onChange(checked, field);
  };

  return (
    <label 
      className={cn(
        'checkbox-container', 
        className
      )} 
      data-test={dataTest} 
      style={style}
    >
      <p className={labelTopPosition ? 'name-label-top label' :'label'}>{label}</p>
      <input 
        type='checkbox' 
        checked={!!selected} 
        onChange={handleChange} 
        disabled={disabled} 
      />
      <span
        className={cn(
          { checkmark: !small && !newuiVersionStyle, 'small-checkmark': small && !newuiVersionStyle },
          { 'newui-checkmark': newuiVersionStyle && !small, 'newui-small-checkmark': newuiVersionStyle && small },
          { 'label-top': labelTopPosition },
          customeStyledClass
        )}
      >
      </span>
    </label>
  );
};

EnhancedCheckbox.defaultProps = {
  label: '',
  selected: false,
  field: '',
  disabled: false,
  style: {},
  onChange: () => {},
  newuiVersionStyle: false,
  labelTopPosition: false
};

export default EnhancedCheckbox;
