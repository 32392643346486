import moment from 'moment';

import strings from 'resources/locales/Translate';

export const getDateFormatForNotification = date => {
  return moment(date).calendar(null, {
    sameDay: `[${strings.todayText}]`,
    lastDay: `[${strings.yesterday}]`,
    nextWeek: 'DD MMM, YYYY',
    nextDay: 'DD MMM, YYYY',
    lastWeek: 'DD MMM, YYYY',
    sameElse: 'DD MMM, YYYY'
  });
};
