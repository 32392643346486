import Modal from 'library/common/commonComponents/Modal';
import React from 'react';
import strings from 'resources/locales/Translate';

export  const AssignmentCancelPopup = (props) =>{
    return(
        <Modal 
            id={props.id}
            confirmAction={() => props.onCancelClick('cancel-zmcc-loan-data-modal')}
            confirmTitle={strings.continueBooking }
            titleOfCancel={strings.cancel}
            loading={props.isLoading}
            onCloseClick={() => props.onContinueBooking('cancel-zmcc-loan-data-modal')}
        >
            <p>
                {strings.zmcccancelWarningMessage}
            </p>
        </Modal>
    )
}

export default AssignmentCancelPopup;