import { useState } from "react";
import $ from 'jquery';

export const useSelectTypePopup = ({id, setFormType, setShowModal, onSubmit}) => {
    const [type, setType] = useState('');
    const onChange = (type) => {
        setType(type);
        setFormType(type);
    }

    const onCloseClick = () => {
        setType('');
        setFormType('');
        if(setShowModal !== null && setShowModal !== undefined )
            setShowModal(false)
        $(`#${id}`).modal('hide');
    }

    const onSaveClick = () =>{
        if(onSubmit !== null && onSubmit !== undefined && onSubmit instanceof Function){
            onSubmit()
            return;
        }
        $(`#${id}`).modal('hide');
        setType('');
        setShowModal(false)
    }

    return{
        onChange,
        type,
        onCloseClick,
        onSaveClick,
    }
}
