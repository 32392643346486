import React from 'react';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import Modal from 'library/common/commonComponents/Modal';
import strings from 'resources/locales/Translate';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import { useDismantleCheckListPopup } from './DismantleCheckListPopup.hook';

export const DismantleCheckListPopup = ({
  id,
  user,
  usersList,
  saveMaintenanceNewCheckListForm,
  transactionId,
  maintenanceCheckListData,
  saveMantenanceCheckListData,
}) => {
  const {
    dismantleForm,
    dismantleFormModel,
    handleForm,
    onChangeCheckbox,
    isDismantleDemoKitConfirmation,
    onSaveClick,
    closeClick,
    loading,
  } = useDismantleCheckListPopup({
    id,
    user,
    usersList,
    saveMaintenanceNewCheckListForm,
    transactionId,
    maintenanceCheckListData,
    saveMantenanceCheckListData,
  });
  return (
    <Modal id={id} confirmAction={onSaveClick} loading={loading} disableBackgroundClose onCloseClick={closeClick} title={strings.maitenanceCheckList}>
      <p className='checkList-form-lable'>{strings.dismantling}</p>
      <div className='mt-2'>
        <FormComponent
          model={dismantleFormModel()}
          formName='DismantleForm'
          formValue={dismantleForm.formData}
          onChange={handleForm}
        />
      </div>
      <div>
        <Checkbox
          label={strings.canBeShipped}
          field='canBeShipped'
          isRequired={true}
          selected={isDismantleDemoKitConfirmation === 'CAN_BE_SHIPPED'}
          onChange={onChangeCheckbox}
        />
        <Checkbox
          label={strings.mustBeShipped}
          field='mustBeShipped'
          isRequired={true}
          selected={isDismantleDemoKitConfirmation === 'MUST_BE_SHIPPED'}
          onChange={onChangeCheckbox}
        />
        <Checkbox
          label={strings.discussionWithProdCenter}
          field='discussionWithProdCenter'
          isRequired={true}
          selected={isDismantleDemoKitConfirmation === 'DISCUSSION_WITH_PRODUCTION_CENTER'}
          onChange={onChangeCheckbox}
        />
      </div>
    </Modal>
  );
};
export default DismantleCheckListPopup;