import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { fetchMasterDataServicePost } from 'library/api/master-data.service';

export const createServiceTicket = (url, body) => dispatch => {
  return fetchMasterDataServicePost(url, body)
    .then((response) => {
      if (response.status === 200) {
        dispatch(toggleActionMessage(true, 'success', 'createServiceTicketSuccessfully'));
        return { success: true };
      }
    })
    .catch(err => {
      if (err.response.status === 422) {
        return { success: false, status: 422 };
      }
      return { success: false };
    });
};
