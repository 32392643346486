import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setLanguage } from 'library/common/commonActions/LanguageActions';
import { logoutUser, setAuthentication } from 'library/common/commonActions/AuthActions';
import { openOrHideNotificationsBar } from 'library/common/commonActions/NotificationsActions';

import MobileMenu from './MobileMenu.component';

const mapStateToProps = ({ languageReducer, authReducer, cartReducer, notificationsReducer }) => ({
  language: languageReducer,
  authentication: authReducer,
  cartReducer: cartReducer,
  notifications: notificationsReducer.notifications,
});

export default withRouter(connect(mapStateToProps, {
  setLanguage,
  setAuthentication,
  logoutUser,
  openOrHideNotificationsBar,
})(MobileMenu));
