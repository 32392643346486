import { connect } from 'react-redux';

import SystemClassesSubscriptions from './NotificationSettings.component';
import {
  getInitialData,
  changetNotificationSettings,
} from './NotificationSettings.actions';

const mapStateToProps = ({ appActionsReducer, masterDataReducer, authReducer, languageReducer, notificationSettingsReducer }) => ({
  appActions: appActionsReducer,
  user: authReducer.user,
  language: languageReducer,
  notificationSettings: notificationSettingsReducer.notificationSettings,
  systemClasses: masterDataReducer.categories,
});

export default connect(mapStateToProps, {
  getInitialData,
  changetNotificationSettings,
})(SystemClassesSubscriptions);
