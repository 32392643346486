import React from 'react';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import { attendeeModel } from './EditAttendeePopup.constants';
import Modal from 'library/common/commonComponents/Modal';
import strings from 'resources/locales/Translate';
import { useEditAttendeePopuo } from './EditAttendeePopup.hook';

export const EditAddttendeePopup = ({id, data, skillLevelData,handleEditNonFormComponent,todosListForm,user,handleNonFormComponent,editingFiledId,handleEditNonFormComponentForTodoList,saveZmccSemData, deleteTodoIds,isZmcc, zmccXrmBooking, zmccLmBooking, zmccXbBooking, zmccSemBooking, updateZmccTransaction, 
  showCheckBoxes = false 
}) =>{
   const {attendeesModel,editAttendeeFormData,handleForm,onCloseClick,onSaveClick} = useEditAttendeePopuo({id, data, skillLevelData,handleEditNonFormComponent,todosListForm,user,handleNonFormComponent,editingFiledId,handleEditNonFormComponentForTodoList,saveZmccSemData, deleteTodoIds, isZmcc, zmccXrmBooking, zmccLmBooking, zmccXbBooking, zmccSemBooking, updateZmccTransaction, showCheckBoxes});
    return(
        <div>
             <Modal
        id={id}
        title={strings.edit}
        confirmAction={onSaveClick}
        confirmTitle={strings.save}
        titleOfCancel={strings.cancel}
        // loading={isLoading}
        disableBackgroundClose
        onCloseClick={onCloseClick}
        // className='modal-width-for-editMaterial'
      >
            <div className='col-12'>
             <FormComponent
           model={attendeesModel()}
           formName='logisticsAndCoordinationForm'
           formValue={editAttendeeFormData.formData}
           onChange={handleForm}
         />
        </div>
      </Modal>
        </div>
        
    )
}

export default EditAddttendeePopup;