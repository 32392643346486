import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

const ChartComponent = ({ data, height, hideLegend, additionalOptions, tooltip }) => {
  const options = {
    ...additionalOptions,
    animationEnabled: true,
    legend: {
      position: 'right',
      display: !hideLegend,
    },
    tooltips: {
      mode: 'label',
      enabled: tooltip ? tooltip :!hideLegend,
      callbacks: {
        label: (tooltipItem, data) => {
          const value = data.datasets[0].data[tooltipItem.index];
          let sum = 0;
          let dataArr = data.datasets[0].data;
          dataArr.forEach(data => {
            sum += data;
          });
          const percentage = ((value * 100) / sum).toFixed(2);
          return `${data.labels[tooltipItem.index]} - ${percentage}%`;
        },
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  return <Doughnut data={data} height={height} options={options} />;
};

ChartComponent.defaultProps = {
  height: 160,
  hideLegend: false,
};

export default ChartComponent;
