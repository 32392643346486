import React from 'react';

import strings from 'resources/locales/Translate';
import Table from 'library/common/commonComponents/Table/Table';

import KPIsFilter from '../KPIsFilter';
import { useKPIsTab } from './KPIsTab.hook';

const KPIsTab = props => {
  const {
    continentsList,
    stocks,
    getKPI,
    kpi,
    mainUtilizedMainComponents,
    getMainUtilizedMainComponents,
    typesOfJobs,
    getTypesOfJobs,
    clearTables,
    language,
  } = props;
  const {
    filters,
    kpiCols,
    typesOfJobsCols,
    isShowKPITable,
    isShowMainUtilizedMainComponents,
    isShowTypesOfJobsTable,
    mainUtilizedMainComponentsCols,
    setFilters,
  } = useKPIsTab({
    getKPI,
    kpi,
    typesOfJobs,
    getMainUtilizedMainComponents,
    mainUtilizedMainComponents,
    getTypesOfJobs,
    clearTables,
    language,
  });

  return (
    <>
      <KPIsFilter filters={filters} setFilters={setFilters} continentsList={continentsList} stocks={stocks} />
      <div className='container-fluid mb-3'>
        {isShowKPITable ? (
          <div className='kpi-table-container mb-4'>
            <Table cols={kpiCols} rows={kpi} />
          </div>
        ) : (
          <div className='d-flex justify-content-center my-5'>
            <strong>{strings.noDataFoundText}</strong>
          </div>
        )}
        <h4 className='mb-3'>{strings.mainUtilizedMainComponents}</h4>
        {isShowMainUtilizedMainComponents ? (
          <div className='kpi-table-container w-50 mb-4'>
            <Table cols={mainUtilizedMainComponentsCols} rows={mainUtilizedMainComponents} />
          </div>
        ) : (
          <div className='d-flex justify-content-center my-5'>
            <strong>{strings.noDataFoundText}</strong>
          </div>
        )}
        <h4 className='mb-3'>{strings.typesOfJobs}</h4>
        {isShowTypesOfJobsTable ? (
          <div className='kpi-table-container  mb-4'>
            <Table cols={typesOfJobsCols} rows={typesOfJobs} />
          </div>
        ) : (
          <div className='d-flex justify-content-center my-5'>
            <strong>{strings.noDataFoundText}</strong>
          </div>
        )}
      </div>
    </>
  );
};

export default KPIsTab;
