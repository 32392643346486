import Validators from 'library/utilities/Validators';
import strings from 'resources/locales/Translate';

const options = [
  {
    dataTest: 'create-c2c-target-delivery-from',
    dateFormat: 'DD.MM.YYYY',
    field: 'targetDeliveryFrom',
    label: 'shipped',
    placeholder: '',
    required: true,
    type: 'date',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    value: '',
    styleClass: 'w-100',
  },
  {
    dataTest: 'create-c2c-target-reservation-from',
    dateFormat: 'DD.MM.YYYY',
    field: 'targetReservationFrom',
    label: 'receiptAtTheCustomer',
    placeholder: '',
    required: true,
    type: 'date',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    value: '',
  },
  {
    dataTest: 'create-c2c-target-reservation-to',
    dateFormat: 'DD.MM.YYYY',
    field: 'targetCheckBeforeReturn',
    label: 'checkBeforeReturn',
    placeholder: '',
    required: true,
    type: 'date',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    value: '',
  },
  {
    dataTest: 'create-c2c-target-return-delivery-from',
    dateFormat: 'DD.MM.YYYY',
    field: 'targetReservationTo',
    label: 'returnDelivery',
    placeholder: '',
    required: true,
    type: 'date',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    value: '',
    styleClass: 'w-100',
  },
  {
    dataTest: 'create-c2c-target-next-reservation-from',
    dateFormat: 'DD.MM.YYYY',
    field: 'targetReturnDeliveryDate',
    label: `${strings.receivedNextCustomer} / ${strings.atStock}`,
    placeholder: '',
    required: true,
    type: 'date',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    value: '',
    styleClass: 'w-100',
  },
  {
    dataTest: 'create-c2c-target-reconditiong-to',
    dateFormat: 'DD.MM.YYYY',
    field: 'targetReconditioningTo',
    label: 'checked',
    placeholder: '',
    required: true,
    type: 'date',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    value: '',
    styleClass: 'w-100',
  },
];

export const adjustDatesFormModel = (nextC2C, transactionDetails) => {
  const newForm = [];
  options.forEach((item) => {
    if (transactionDetails && transactionDetails.allNextC2c && transactionDetails.allNextC2c.length >0){
      if (item.field === 'targetReconditioningTo'){
        return;
      } else {
        if (item.field === 'targetReturnDeliveryDate') {
          item.label = strings.receiptNextCustomer
        }
        newForm.push(item);
      }
    }
    else {
      if (item.field === 'targetReturnDeliveryDate') {
        item.label = strings.receiptInStock;
        newForm.push(item);
      } else if (item.field === 'targetReconditioningTo'){
        newForm.push(item);
      }
      else{
        newForm.push(item);
      }
    }
  });
  return newForm;
}