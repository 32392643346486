import { connect } from 'react-redux';

import SalesDetails from './SalesDetails.component';

const mapStateToProps = ({ createKitForSaleReducer }) => ({
  formValue: {
    salesDetailsForm: createKitForSaleReducer.salesDetailsForm,
  },
  customers: createKitForSaleReducer.customers,
});

export default connect(mapStateToProps)(SalesDetails);
