import React from 'react';
import './leftHeaderStyles.scss';

export const LeftHeader = ({ onChangeDate, todayText }) => {
  const onButtonClick = () => {
    onChangeDate(new Date());
  };

  return (
    <div className='left-header d-flex justify-content-end'>
      <div className='d-flex justify-content-between align-items-center'>
        <button onClick={onButtonClick}>{todayText}</button>
      </div>
    </div>
  );
};

export default LeftHeader;
