import React from 'react';

import strings from 'resources/locales/Translate';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import Button from 'library/common/commonComponents/Button/Button';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import AddMoreUsers from 'library/common/commonComponents/AddMoreUsers/AddMoreUsers';
import { DUST_MANAGER } from 'library/common/commonConstants/roles';

import Filters from './Components/Filters';
import { permissionstBreadcrumbData } from './Permissions.constants';
import { usePermissions } from './Permissions.hook';
import './Permissions.styles.scss';

const Permissions = ({
  user,
  appActions,
  getInitialPermissionsData,
  updateDemoUnitPermission,
  permissionsList,
  usersList,
}) => {
  document.title = 'Administration';
  const isServiceManager = !user.admin && user.globalRoles && user.globalRoles.includes(DUST_MANAGER);
  const { isLoading, displayActionMessage, type, message } = appActions;
  const {
    isShowFilter,
    isSavingPermissionsLoading,
    handleFilterChange,
    toggleFilterVisibility,
    changeUserRole,
    removeUserRole,
    changeNewUserRole,
    addNewUserRole,
    savePermissions,
    modelOfFilters,
    modelOfPermissions,
    modelOfPermissionsForServiceManager,
    modelOfNewPemission,
    filteredPermissions,
    filters,
    newPermission,
  } = usePermissions({
    getInitialPermissionsData,
    updateDemoUnitPermission,
    permissionsList,
    usersList,
    isServiceManager,
    user,
  });
  
  return (
    <div className='permissions-container'>
      {displayActionMessage && <ActionMessage type={type} message={strings[message]} />}
      <div className='container-fluid mb-3'>
        <Breadcrumbs data={permissionstBreadcrumbData} />
        <div className='row no-gutters align-items-center'>
          <h1 className='col-sm kit-title'>{strings.dusdPermissions}</h1>
          <div className='col-sm-auto mt-3 mt-sm-0'>
            <Button
              dataTest='permissions-filter-button'
              bgFill={false}
              iconName='filter'
              onClick={toggleFilterVisibility}
              value={isShowFilter ? strings.hideFilter : strings.showFilter}
              styleClass='filter-button'
            />
          </div>
        </div>
      </div>
      <Filters
        isShowFilter={isShowFilter}
        model={modelOfFilters}
        handleFilterChange={handleFilterChange}
        data={filters}
      />
      <div className='container-fluid mb-3'>
        <div className='mb-3'>
          {filteredPermissions.map(form => (
            <AddMoreUsers
              key={`${form.email}-${form.accountId}`}
              value={form}
              model={(form.role === DUST_MANAGER) && isServiceManager ?
                modelOfPermissionsForServiceManager :
                modelOfPermissions
              }
              buttonText={strings.delete}
              hideDeleteIcon={(form.role === DUST_MANAGER) && isServiceManager}
              valueAlreadyAdded
              onRemove={removeUserRole(form.accountId, form.role)}
              onChange={changeUserRole(form.accountId)}
            />
          ))}
          <AddMoreUsers
            key='new'
            model={modelOfNewPemission}
            formName='newPermission'
            value={newPermission}
            buttonText={strings.addButtonText}
            onChange={changeNewUserRole}
            onAdd={addNewUserRole}
          />
        </div>
        <div className='row'>
          <div className='col-sm-2 ml-auto'>
            <Button
              styleClass='d-flex justify-content-around'
              bgFill={true}
              iconName='save'
              value={strings.save}
              loading={isSavingPermissionsLoading}
              onClick={savePermissions}
            />
          </div>
        </div>
      </div>
      {isLoading && <div className='loader' />}
    </div>
  );
}

export default Permissions;
