import { NavLink } from 'react-router-dom';
import React, { Component } from 'react';

import strings from 'resources/locales/Translate';
import Icon from 'library/common/commonComponents/Icon/Icon';

import { menu, menuWithTeamBookings } from './OffCanvasMenu.constants';
import './offCanvasMenu.styles.scss';

export default class OffCanvasMenu extends Component {
  menu = React.createRef();

  state = {
    isChildOpened: false,
    childMenu: {},
  };

  openChildMenu(childMenu) {
    const { user } = this.props.authentication;
    const isShowBookingTeams = (childMenu.name === 'Transaction') && ((user && user.admin) || this.isHavingRole(['Stock Controller', 'Manager']));
    const newMenu = isShowBookingTeams ? menuWithTeamBookings : childMenu;
    this.setState({
      childMenu: newMenu,
      isChildOpen: true,
    });
  }

  goBackToMainMenu() {
    this.setState({
      childMenu: {},
      isChildOpen: false,
    });
  }

  handleLanguageChange = () => {
    const { language } = this.props.language;
    if (language === 'en') {
      this.props.setLanguage('de');
    } else {
      this.props.setLanguage('en');
    }
  };

  isHavingRole = roles => {
    const { user } = this.props.authentication;
    if (user && user.stockRoles && user.stockRoles.length > 0) {
      return roles.some(role => {
        return user.stockRoles.some(stockRole => stockRole.roleName === role);
      });
    }
    return false;
  };

  render() {
    const { childMenu, isChildOpen } = this.state;
    const { marginLeft, onMenuClose, language } = this.props;
    let displayLanguage = language.language.includes('de') ? 'English' : 'German';
    return (
      <div className='off-canvas-menu' ref={el => (this.menu = el)} style={{ marginLeft: `${marginLeft}px` }}>
        <div className='menu-header position-relative'>
          {isChildOpen ? (
            <button className='back-button' onClick={() => this.goBackToMainMenu()}>
              <Icon name={'arrow'} width={24} height={24} fill={'#ffffff'} />
              {strings.back}
            </button>
          ) : (
            <h2>{strings.menu}</h2>
          )}

          <button
            type='button'
            onClick={() => onMenuClose()}
            className='close modal-close position-absolute d-block d-xl-none d-lg-none'
            data-dismiss='modal'
            aria-label='Close'
          >
            <Icon name='cross' width={24} height={24} fill={'#ffffff'} />
          </button>
        </div>

        <div className='menu-content'>
          {Object.keys(childMenu).length === 0 ? (
            <ul className={`list-unstyled ${isChildOpen ? 'd-none' : 'd-block'}`}>
              {menu.map((item, key) => (
                <li key={key} className='menu-item'>
                  {item.child ? (
                    <p
                      className='menu-link menu-with-child d-flex justify-content-between align-items-center'
                      onClick={() => this.openChildMenu(item)}
                    >
                      {strings[item.languageKey]}
                      <Icon name={'arrow'} width={24} height={24} fill={'#ffffff'} />
                    </p>
                  ) : (
                    <NavLink to={item.path} exact={true} className='menu-link' activeClassName='active'>
                      {strings[item.languageKey]}
                    </NavLink>
                  )}
                </li>
              ))}
            </ul>
          ) : null}

          {Object.keys(childMenu).length > 0 ? (
            <ul className={`list-unstyled ${!isChildOpen ? 'd-none' : 'd-block'}`}>
              <li className='menu-item child-menu-title'>{strings[childMenu.languageKey]}</li>
              {childMenu.children.map((item, key) => (
                <li key={key} className='menu-item'>
                  {item.child ? (
                    <p className='menu-link' onClick={() => this.openChildMenu(item)}>
                      {strings[item.languageKey]}
                    </p>
                  ) : (
                    <NavLink
                      to={{ pathname: item.path, prevLocation: this.props.location }}
                      exact={true}
                      className='menu-link'
                      activeClassName='active'
                    >
                      {strings[item.languageKey]}
                    </NavLink>
                  )}
                </li>
              ))}
            </ul>
          ) : null}
        </div>

        <div className={'menu-footer fixed-bottom ' + (marginLeft === 0 ? 'd-flex' : 'd-none')}>
          <ul className='nav'>
            <li className='nav-item'>
              <NavLink to={'/documents'}>{strings.document}</NavLink>
            </li>
            <li className='nav-item'>
              <NavLink to={'/contact'}>{strings.contact}</NavLink>
            </li>
            <li className='nav-item'>
              <p className='cursor-pointer' onClick={this.handleLanguageChange}>
                {displayLanguage}
              </p>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
