import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toArray } from 'lodash';

import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/Breadcrumbs';
import Button from 'library/common/commonComponents/Button/Button';
import ExpandableTable from 'library/common/commonComponents/Table/ExpandableTable';
import ItemsPerPage from 'library/common/commonComponents/ItemsPerPage/ItemsPerPage';
import Pagination from 'library/common/commonComponents/Pagination/Pagination';
import SearchField from 'library/common/commonComponents/SearchField/SearchField';
import Table from 'library/common/commonComponents/Table/Table';
import strings from 'resources/locales/Translate';
import { fetchMasterData, fetchMasterDataPost } from 'library/common/commonActions/MasterDataActions';
import { adminUserCountryListRequestJson, afterLoginCountryListRequestJson } from 'library/api/endpoint-request-json';
import * as masterActionTypes from 'library/common/commonConstants/storeConstants';
import * as bookingActionTypes from 'modules/Booking/BookingConstants';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';

import Filter from './C2CLoansFrames/Filter';
import { breadcrumbsData, dataTableC2CLoans, dataTableChildTable } from './C2CLoansConstants';
import { fetchC2CTransactionList } from './C2CLoansActions';
import './C2CLoans.style.scss';

export const c2CLoansItemsPerPage = {
  10: { id: 10, pages: 10 },
  20: { id: 20, pages: 20 },
  50: { id: 50, pages: 50 },
};
export const C2C_INITIAL_DATA = {
  page: 1,
  size: 10,
  sort: {
    transactionsId: null,
    mainComponent: null,
    serialNumber: null,
    targetReservationFrom: null,
    targetReservationTo: null,
    transactionPositionId: null,
  },
  filter: {
    stock: null,
    systemClass: null,
    currentPosition: null,
  },
  search: '',
};

export class C2CLoans extends Component {
  constructor(props) {
    super(props);
    this.dropdownRef = null;
    this.state = {
      isShowFilter: true,
      data: C2C_INITIAL_DATA,
    };
  }

  componentDidMount() {
    document.title = 'C2C Loans';
    const { categoriesAndSystems, countries, transactionPositions, user } = this.props;
    if (!countries.length) {
      const query = user && user.admin ? adminUserCountryListRequestJson : afterLoginCountryListRequestJson;
      this.props.fetchMasterDataPost(URLS.countryList, masterActionTypes.FETCH_COUNTRY_LIST, query);
    }
    if (!categoriesAndSystems.length) {
      this.props.fetchMasterData(URLS.categoryList, masterActionTypes.FETCH_CATEGORY_LIST);
    }
    if (!transactionPositions.length) {
      this.props.fetchMasterData(URLS.transactionPositions, bookingActionTypes.FETCH_TRANSACTION_POSITIONS);
    }
    this.props.fetchC2CTransactionList(this.state.data, this.props.match.url.slice(1));
  }

  showOrHideFilter = () => {
    this.setState(prevState => ({ isShowFilter: !prevState.isShowFilter }));
  };

  handleSort = name => {
    this.setState(
      prevState => {
        const newData = { ...prevState.data };
        newData.sort[name] = prevState.data.sort[name] ? (prevState.data.sort[name] === 'asc' ? 'desc' : null) : 'asc';
        return { data: newData };
      },
      () => this.props.fetchC2CTransactionList(this.state.data, this.props.match.url.slice(1)),
    );
  };

  handleFilterChange = (key, value) => {
    this.setState(
      prevState => {
        const newData = { ...prevState.data };
        newData.filter[key] = value;
        return {
          data: newData,
        };
      },
      () => {
        this.props.fetchC2CTransactionList(this.state.data, this.props.match.url.slice(1));
      },
    );
  };

  handleItemsPerPage = size => {
    this.setState(
      prevState => {
        const newData = { ...prevState.data };
        newData.size = size;
        return { data: newData };
      },
      () => this.props.fetchC2CTransactionList(this.state.data, this.props.match.url.slice(1)),
    );
  };

  handlePageChange = page => {
    this.setState(
      prevState => {
        const newData = { ...prevState.data };
        newData.page = page + 1;
        return { data: newData };
      },
      () => this.props.fetchC2CTransactionList(this.state.data, this.props.match.url.slice(1)),
    );
  };

  handleSearchChange = e => {
    const { value } = e.target;
    this.setState(
      prevState => {
        const newData = { ...prevState.data, page: 1, search: value };
        return { data: newData };
      },
      () => this.props.fetchC2CTransactionList(this.state.data, this.props.match.url.slice(1)),
    );
  };

  dataExpandableTable = (data, _, user) => {
    const rows = [...data.allNextC2c, ...data.allPrevC2c];
    if (rows.length === 0) {
      return null;
    }
    return (
      <>
        <td />
        <td colSpan='8'>
          <Table
            className='child-table-width'
            user={user}
            rowId='transactionsId'
            cols={dataTableChildTable(user, rows[rows.length - 1].transactionsId)}
            childTable
            rows={[...data.allNextC2c, ...data.allPrevC2c]}
            sortFields={this.state.data.sort}
          />
        </td>
      </>
    );
  };

  render() {
    const { data, isShowFilter } = this.state;
    const { categoriesAndSystems, transactionPositions, countries, isLoading, c2cLoans, user } = this.props;
    return (
      <>
        <div className='container-fluid mb-3'>
          <Breadcrumbs data={breadcrumbsData} />
          <div className='row no-gutters justify-content-between align-items-center mb-3'>
            <h1 className='page-title mb-0'>{strings.c2cLoans}</h1>
            <div className='d-flex flex-row'>
              <div className='input-wrapper'>
                <SearchField onChange={this.handleSearchChange} />
              </div>
              <div className='col-auto'>
                <Button
                  dataTest='c2c-loans-filter-button'
                  bgFill={false}
                  iconName='filter'
                  onClick={this.showOrHideFilter}
                  value={isShowFilter ? strings.hideFilter : strings.showFilter}
                  styleClass='filter-button'
                />
              </div>
            </div>
          </div>
        </div>
        <Filter
          isShowFilter={isShowFilter}
          countries={countries}
          categoriesAndSystems={categoriesAndSystems}
          transactionPositions={transactionPositions}
          handleFilterChange={this.handleFilterChange}
        />
        <div className='container-fluid mb-3'>
          {c2cLoans && c2cLoans.content && c2cLoans.content.length ? (
            <ExpandableTable
              user={user}
              cols={dataTableC2CLoans(user)}
              rows={c2cLoans && c2cLoans.content && c2cLoans.content.length ? c2cLoans.content : []}
              rowId='transactionsId'
              dataExpandableTable={this.dataExpandableTable}
              sortFields={data.sort}
              handleSort={this.handleSort}
              handleDelete={this.handleDelete}
            />
          ) : (
            <div className='d-flex justify-content-center'>
              <strong>{strings.noDataFoundText}</strong>
            </div>
          )}
        </div>
        <div className='container-fluid'>
          {c2cLoans && c2cLoans.content && c2cLoans.content.length ? (
            <div className='col d-flex justify-content-end mb-3'>
              <ItemsPerPage
                itemsPerPage={toArray(c2CLoansItemsPerPage)}
                handleItemsPerPage={this.handleItemsPerPage}
                value={c2CLoansItemsPerPage[data.size]}
              />
              <Pagination
                currentPage={data.page - 1}
                totalPages={c2cLoans.totalPages}
                onPageChange={this.handlePageChange}
              />
            </div>
          ) : null}
        </div>
        {isLoading && <div className='loader' />}
      </>
    );
  }
}

const mapStateToProps = ({
  appActionsReducer,
  authReducer,
  bookingReducer,
  c2cLoansReducer,
  languageReducer,
  masterDataReducer,
}) => ({
  c2cLoans: c2cLoansReducer.c2cLoans,
  user: authReducer.user,
  isLoading: appActionsReducer.isLoading,
  language: languageReducer,
  countries: masterDataReducer.countries,
  categoriesAndSystems: masterDataReducer.categories,
  transactionPositions: bookingReducer.transactionPositions,
});

export default connect(mapStateToProps, { fetchC2CTransactionList, fetchMasterData, fetchMasterDataPost })(C2CLoans);
