
import strings from 'resources/locales/Translate';

export const getDUSTColor = status => {
  switch(status) {
    case 'NO_ISSUES':
      return 'green-round';
    case 'SYSTEM_RESTRICTED':
      return 'yellow-round';
    case 'SYSTEM_DOWN':
      return 'red-round';
    default:
      break;
  }
};

export const getDUSTTitle = status => {
  switch(status) {
    case 'NO_ISSUES':
      return strings.noIssues;
    case 'SYSTEM_RESTRICTED':
      return strings.sistemRestricted;
    case 'SYSTEM_DOWN':
      return strings.systemDown;
    default:
      break;
  }
};
