import { useEffect, useState, useCallback } from 'react';
import $ from 'jquery';

import { validateForm } from 'library/utilities/ValidateForm.util';

import { taskModel } from './CreateOrEditTaskPopup.constants';

export const useCreateOrEditTaskPopup = ({
  id,
  systemClasses,
  hasEditMode,
  selectedTask,
  createOrEditDudsTask,
  setSelectedTask,
  filterData,
}) => {
  const [loading, setLoading] = useState(false);
  const [taskForm, setTaskForm] = useState({ formData: {}, isFormValid: false });
  const [error, setError] = useState('');

  useEffect(() => {
    if (hasEditMode && selectedTask) {
      setTaskForm({
        formData: {
          systemClassId: selectedTask.systemClass.systemClassId,
          systemMainComponentId: selectedTask.systemMainComponent.systemMainComponentId,
          description: selectedTask.description,
        },
      });
    }
  }, [hasEditMode, selectedTask]);

  const getModel = useCallback(() => {
    const newModel = [ ...taskModel ];
    newModel[0].options = systemClasses;

    if (taskForm.formData && taskForm.formData.systemClassId) {
      const selectedSystemClass = systemClasses.find(item => taskForm.formData.systemClassId === item.systemClassId) || {};
      newModel[1].options = selectedSystemClass.systemMainComponent;
    }

    return newModel;
  }, [systemClasses, taskForm.formData]);

  const model = getModel();

  const onCloseClick = () => {
    const popup = $(`#${id}`);
    if (popup && popup.modal) {
      popup.modal('hide');
    }

    setError('');
    setTaskForm({ formData: {}, isFormValid: false });

    if (hasEditMode) {
      setSelectedTask(null);
    }
  };

  const onSaveClick = async () => {
    setError('');
    const validForm = validateForm({
      form: taskForm,
      model: taskModel,
    });

    if (!validForm.isFormValid) {
      return setTaskForm(validForm);
    } 

    setLoading(true);
    const { systemClassId, systemMainComponentId, description } = validForm.formData;
    const dataToSend = {
      systemClassId,
      systemMainComponentId,
      description,
    };
  
    if (hasEditMode) {
      dataToSend.demoUnitTaskId = selectedTask.id;
    }

    const { success, error } = await createOrEditDudsTask(dataToSend, filterData, hasEditMode);
    setLoading(false);

    if (!success) {
      setError(error);
    } else {
      onCloseClick();
    }
  };

  const handleForm = ({ values, field }) => {
    if (field === 'systemClassId') {
      setTaskForm({
        ...taskForm,
        formData: {
          ...values,
        },
      });
    } else {
      setTaskForm({
        ...taskForm,
        formData: {
          ...taskForm.formData,
          ...values,
        },
      });
    }
  };

  return {
    taskForm,
    loading,
    error,
    onSaveClick,
    handleForm,
    onCloseClick,
    model,
    getModel,
  };
};
