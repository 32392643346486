import Validators from 'library/utilities/Validators';
import strings from 'resources/locales/Translate';

export const getDemoUnitStatusOptions = () => [
  {
    id: 'NO_ISSUES',
    name: strings.noIssues,
    icon: 'green-round',
  },
  {
    id: 'SYSTEM_RESTRICTED',
    name: strings.sistemRestricted,
    icon: 'yellow-round',
  },
  {
    id: 'SYSTEM_DOWN',
    name: strings.systemDown,
    icon: 'red-round',
  },
];

export const commentModel = [
  {
    label: 'yourName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'yourName',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage '}],
    required: true,
    styleClass: 'col-sm-6',
    disabled: true,
  },
  {
    label: 'date',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'date',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    dateFormat: 'DD.MM.YYYY',
    disabled: true,
  },
  {
    label: 'commentText',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'text',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 1000,
    },
  },
  {
    label: 'demoUnitStatus',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'status',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-4',
    options: [],
    idKey: 'id',
    displayValue: [{ key: 'name', separator: '' }],
  },
];
