import { connect } from 'react-redux';

import LLManagement from './LLManagement.component';

const mapStateToProps = ({ authReducer, languageReducer, appActionsReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
  appActions: appActionsReducer,
});

export default connect(mapStateToProps)(LLManagement);
