import Button from 'library/common/commonComponents/Button/Button';
import React from 'react';
import strings from 'resources/locales/Translate';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import Icon from 'library/common/commonComponents/Icon/Icon';
import { useToDoList } from './ToDoList.hook';

export const ToDoList = (props) => {
  const { formValue } = props;
  const { todosListForm } = formValue;
  const {
    todosList,
    todoText,
    onChangeInput,
    addIntoTodosList,
    removeATodoFromList,
    errorMsg
  } = useToDoList(props);
  
    return(
        <>
        <h1 className='mt-4 zmcc-title-heading'>{strings.toDoList.toUpperCase()}</h1>
        <div className='section-border mt-3'>
        {todosListForm.formData.zmccTodosListDataList && todosListForm.formData.zmccTodosListDataList.map((item,index) => (
          <>
            <div className='row m-0 mb-2' key={index}>
               
                <div className='col-sm-4'>
                <p className='zmcc-todo-list-font-style'>
                  {item.title === 'accomodationNeeded' ? strings.bookAccomodation: ''}
                </p>
                <p className='zmcc-todo-list-font-style'>
                  {item.title  === 'flightNeeded'? strings.bookflight : ''}
                </p>
                <p className='zmcc-todo-list-font-style'>
                  {item.title === 'visitMuseumOfOptics' ? strings.visitMuseum:'' }
                </p>

                  {item.zmccTodosText}
                  </div>
                <div className='col-sm-1'>
                <button
                    type='button'
                    className='delete-button delete-icon-name'
                    onClick={() => removeATodoFromList(index)}
                  >
                    <Icon name='close' width={20} height={20} fill='#C91803' className='mr-2' />
                  </button>
                </div>
            </div>
            <div className='border-for-todo ml-3'></div>
            </>
        ))}
         <div className='col-sm-4 mt-4'>
        <InputField
          label={''}
          field='todoText'
          placeholder=''
          isRequired={false}
          type='text'
          validators={[]}
          value={todoText}
          error={errorMsg}
          onChange={onChangeInput}
        />
      </div>
      <div className='col-auto mt-2'>
        <Button
          value={strings.addMoreButtonText}
          onClick={() => addIntoTodosList(todoText)}
          bgFill={false}
          iconName='plus'
          disabled={todosListForm.formData.zmccTodosListDataList && todosListForm.formData.zmccTodosListDataList.length === 20}
          newVersionUi={true}
          styleClass="button-without-border mt-3 p-0"
        />
      </div>
        </div>
      </>
    )
}

export default ToDoList;