import * as actionTypes from 'library/common/commonConstants/storeConstants';

const initialState = {
  stocksList: null,
  countriesWithActiveStocks: [],
};

const StocksListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_STOCKS_LIST:
      return {
        ...state,
        stocksList: action.payload,
      };
    case actionTypes.FETCH_COUNTRIES_WITH_ACTIVE_STOCKS:
      return {
        ...state,
        countriesWithActiveStocks: action.payload,
      };
    default:
      return state;
  }
};

export default StocksListReducer;
