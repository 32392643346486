import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import cn from 'classnames';

import Popover from '../Popover';
import styles from './PopupMenu.module.scss';

const PopupMenu = (props, ref) => {
  const { className, onKeyDown, onMouseMove, children, ...rest } = props;
  const classes = cn(styles.menu, className);

  // sync refs
  const popupMenuRef = useRef(null);
  useImperativeHandle(ref, () => popupMenuRef.current);

  return (
    <Popover className={classes} {...rest} ref={popupMenuRef}>
      {children}
    </Popover>
  );
};

export default forwardRef(PopupMenu);
