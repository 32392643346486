import Validators from "library/utilities/Validators";

export const salesComponentModel = [
  {
    label: 'salesComponentLabel',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'salesComponents',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-sm-12',
    extraProps: {
      className: 'form-control rounded-0 position-relative components-textarea'
    },
    rows: 3,
  }
];

export const optionalSalesComponentModel = [
  {
    label: 'salesComponentLabel',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'salesComponents',
    validators: [],
    required: false,
    styleClass: 'col-sm-12',
    extraProps: {
      className: 'form-control rounded-0 position-relative components-textarea'
    },
  }
];

export const furtherImportantComponentModel = [
  {
    label: 'furtherImportantComponentLabel',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'furtherImportantComponents',
    validators: [],
    required: false,
    styleClass: 'col-sm-12',
    extraProps: {
      className: 'form-control rounded-0 components-textarea'
    },
  }
];
