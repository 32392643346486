import { useState } from 'react';

export const useDataExpandableTable = ({
  kitInformationId,
  handleClickOnStopObserve,
  handleClickOnObserve,
  handleClickOnReserveKit,
  handleClickOnCancelReservation,
  openOrderPopup,
  user,
  stockInformation,
  kitCondition,
}) => {
  const [isObservationLoading, setIsObservationLoading] = useState(false);
  const [isReservationLoading, setIsReservationLoading] = useState(false);

  const isReserved = kitCondition && (kitCondition.kitConditionId === 12);
  const isOrdered = kitCondition && (kitCondition.kitConditionId === 11);

  const handleRemoveFromObservationList = async () => {
    setIsObservationLoading(true);
    await handleClickOnStopObserve(kitInformationId);
    setIsObservationLoading(false);
  };

  const handleAddToObservationList = async () => {
    setIsObservationLoading(true);
    await handleClickOnObserve(kitInformationId);
    setIsObservationLoading(false);
  };

  const checkUserRole = roles => {
    if (stockInformation && stockInformation.id) {
      return user.stockRoles.find(role => (role.stockId === stockInformation.id) && roles.includes(role.roleName));
    }
    return false;
  };

  const handleReserveKit = async () => {
    setIsReservationLoading(true);
    await handleClickOnReserveKit(kitInformationId);
    setIsReservationLoading(false);
  };

  const handleCancelReserveKit = () => {
    handleClickOnCancelReservation(kitInformationId);
  };

  const handleClickOnOrder = () => {
    openOrderPopup(kitInformationId, isReserved)
  };

  return {
    isObservationLoading,
    isReservationLoading,
    handleRemoveFromObservationList,
    handleAddToObservationList,
    handleReserveKit,
    handleCancelReserveKit,
    handleClickOnOrder,
    checkUserRole,
    isReserved,
    isOrdered,
  }
};
