import { useCallback, useEffect, useState } from 'react';
import $ from 'jquery';

import { scrollToTop } from 'library/utilities/scrollActions';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { validateForm } from 'library/utilities/ValidateForm.util';

import { createServiceTicketModel } from './CreateServiceTicketPopup.constants';

export const useCreateServiceTicketPopup = ({
  id,
  kitDetails,
  user,
  problemsList,
  createServiceTicket,
  handleFetch,
}) => {
  const [serviceTicketForm, setServiceTicketForm] = useState({
    formData: {},
    isFormValid: false,
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    createServiceTicketModel[4].options = problemsList;
  }, [problemsList]);

  const setDefaultValuesForForm = useCallback(() => {
    const { stockInformation, systemMainComponent, serialNumber } = kitDetails;
    const hasStockInformation = stockInformation && (stockInformation.locationName || stockInformation.department);
    const hasSystemMainComponent = systemMainComponent && systemMainComponent.systemMainComponentName;

    setServiceTicketForm({
      formData: {
        location: hasStockInformation ? stockInformation.locationName : null,
        nameDepartment: hasStockInformation ? `${user.firstName} ${user.lastName}` : null,
        systemMainComponent: hasSystemMainComponent ? systemMainComponent.systemMainComponentName : null,
        serialNumber: serialNumber || null,
      },
      isFormValid: false,
    });
  }, [kitDetails, setServiceTicketForm, user]);

  useEffect(() => {
    setDefaultValuesForForm();
  }, [kitDetails, setDefaultValuesForForm]);

  const onCloseClick = () => {
    if ($(`#${id}`) && $(`#${id}`).modal) {
      setServiceTicketForm({
        formData: {},
        isFormValid: false,
      });
      setDefaultValuesForForm();
      setError('');
      $(`#${id}`).modal('hide');
    }
  };

  const confirmAction = async () => {
    const validated = validateForm({ form: serviceTicketForm, model: createServiceTicketModel });
    const { kitInformationId } = kitDetails;
    const url = URLS.serviceTicket(kitInformationId);

    if (!validated.isFormValid) {
      return setServiceTicketForm(validated);
    }
    
    const body = {
      comment: serviceTicketForm.formData.providingMoreDetails,
      serviceTicketProblemId: serviceTicketForm.formData.selectProblem,
    };
    setIsLoading(true);
    const { success, status } = await createServiceTicket(url, body);

    if (success) {
      setIsLoading(false);
      onCloseClick();
      handleFetch();
      scrollToTop(500);
    } else {
      setIsLoading(false);
      if (status === 422) {
        setError('pleaseLoginAgain');
      } else {
        setError('somethingWentWrongMessage');
      }
    }
  };

  const handleForm = ({ values }) => {
    setServiceTicketForm({
      ...serviceTicketForm,
      formData: {
        ...serviceTicketForm.formData,
        ...values,
      },
    });
  };

  return {
    serviceTicketForm,
    error,
    isLoading,
    confirmAction,
    onCloseClick,
    handleForm,
  };
};
