import Validators from 'library/utilities/Validators';

export const WORKFLOW_CATEGORY_QUESTION_IDS = {
  adaptLoanReason: 38,
  enableDifferentDeliveryAddress: 40,
}

export const WORKFLOW_CATEGORY_QUESTION = "STOCK/FETCH_WORKFLOW_QUESTIONS"

export const externallyManagedStockModel = [
  {
    label: 'orderType',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'orderType',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: '' }],
  },
  {
    label: 'distributionChannel',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'distributionChannel',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: '' }],
  },
  {
    label: 'customerId',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'customerIdSap',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
  },
  {
    label: 'goodsRecipientId',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'goodsRecipientIdSap',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
  },
  {
    label: 'billing',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'billing',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    styleClass: 'col-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    required: true,
    displayValue: [{ key: 'name', separator: '' }],
  },
  {
    label: 'partner',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'partner',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
  },
  {
    label: 'formularHeaderText',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'text',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    extraProps: {
      maxLength: 132,
    },
  },
  {
    label: 'shippingInstructions',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'shippingInstruction',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    extraProps: {
      maxLength: 132,
    },
  },
  {
    label: 'orderData',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'submissionData',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
  },
];

export const refurbishmentForDemoKitModel = [
  {
    label: 'costFactorGrossListPricePerLoan',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'costFactorPricePerLoanForKit',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
  },
  {
    label: 'costFactorGrossListPricePerDayDelay',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'costFactorPricePerDayDelayForKit',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
  },
];

export const refurbishmentForSingleLoanModel = [
  {
    label: 'costFactorLoanValuePerLoan',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'costFactorPerLoanForSingleLoan',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
  },
  {
    label: 'numberOfDaysWhereLoanIsFree',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'numberOfDaysSingleLoanFree',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' },
    { check: Validators.number, message: 'invalidNumberError' }
  ],
    required: true,
    styleClass: 'col-12',
  },
];


export const additionalFilesModel = [
  {
    label: 'pickupComment',
    selected: true,
    type: 'checkbox',
    field: 'pickUpComment',
    required: false,
    styleClass: 'col-sm-12 mb-1 mt-1',
  },
  {
    label: 'trackingNumber',
    selected: true,
    type: 'checkbox',
    field: 'trackingNumber',
    required: false,
    styleClass: 'col-sm-12 mb-1',
  },
  {
    label: 'crateId',
    selected: true,
    type: 'checkbox',
    field: 'crateId',
    required: false,
    styleClass: 'col-sm-12 mb-1',
  },
]

//Make a list of all loan reason ids
export const adaptedLoanReasonsModel = [
  {
    label: 'reasonCourse',
    type: 'checkbox',
    field: 'Course',
    required: false,
    styleClass: 'col-sm-12 mb-1',
    idKey: 1,
    reasonForLoanId: 1,
  },
  {
    label: 'reasonDemoAtTheCustomers',
    type: 'checkbox',
    field: 'Demo at the customer`s',
    required: false,
    styleClass: 'col-sm-12 mb-1',
    idKey: 1,
    reasonForLoanId: 2,
  },
  {
    label: 'reasonDemoInSscShowroom',
    type: 'checkbox',
    field: 'Demo in the SSC Showroom',
    required: false,
    styleClass: 'col-sm-12 mb-1',
    idKey: 1,
    reasonForLoanId: 3,
  },
  {
    label: 'reasonInHouseLoan',
    type: 'checkbox',
    field: 'In-house loan',
    required: false,
    styleClass: 'col-sm-12 mb-1',
    idKey: 1,
    reasonForLoanId: 4,
  },
  {
    label: 'reasonLoanToDealer',
    type: 'checkbox',
    field: 'Loan to dealer',
    required: false,
    styleClass: 'col-sm-12 mb-1',
    idKey: 1,
    reasonForLoanId: 14,
  },
  {
    label: 'reasonLoanToOemPartner',
    type: 'checkbox',
    field: 'Loan to OEM Partner',
    required: false,
    styleClass: 'col-sm-12 mb-1',
    idKey: 1,
    reasonForLoanId: 5,
  },
  {
    label: 'reasonLoanBeforeUnitDelivery',
    type: 'checkbox',
    field: 'Loan unit before delivery',
    required: false,
    styleClass: 'col-sm-12 mb-1',
    idKey: 1,
    reasonForLoanId: 9,
  },
  {
    label: 'reasonLoanUnitDurationRepair',
    type: 'checkbox',
    field: 'Loan unit for the duration of repair',
    required: false,
    styleClass: 'col-sm-12 mb-1',
    idKey: 1,
    reasonForLoanId: 10,
  },
  {
    label: 'reasonStrategicLongTermLoan',
    type: 'checkbox',
    field: 'Strategic long term loan',
    required: false,
    styleClass: 'col-sm-12 mb-1',
    idKey: 1,
    reasonForLoanId: 11,
  },
  {
    label: 'reasonTradeFair',
    type: 'checkbox',
    field: 'Trade fair',
    required: false,
    styleClass: 'col-sm-12 mb-1',
    idKey: 1,
    reasonForLoanId: 12,
  },
  {
    label: 'reasonWorkshop',
    type: 'checkbox',
    field: 'Workshop',
    required: false,
    styleClass: 'col-sm-12 mb-1',
    idKey: 1,
    reasonForLoanId: 13,
  },
]

export const adaptLoanReaonsForMED = [
  {
    label: 'reasonAvailableNoDemo',
    type: 'checkbox',
    field: 'Available/No Demo',
    required: false,
    styleClass: 'col-sm-12 mb-1',
    idKey: 1,
    reasonForLoanId: 17,
  },
  {
    label: 'reasonDemoAtTheCustomers',
    type: 'checkbox',
    field: 'Demo at the customer`s',
    required: false,
    styleClass: 'col-sm-12 mb-1',
    idKey: 1,
    reasonForLoanId: 2,
  },
  {
    label: 'reasonDemoInSscShowroom',
    type: 'checkbox',
    field: 'Demo in the SSC Showroom',
    required: false,
    styleClass: 'col-sm-12 mb-1',
    idKey: 1,
    reasonForLoanId: 3,
  },
  {
    label: 'reasonInHouseLoan',
    type: 'checkbox',
    field: 'In-house loan',
    required: false,
    styleClass: 'col-sm-12 mb-1',
    idKey: 1,
    reasonForLoanId: 4,
  },
  {
    label: 'reasonLoanUnitDurationRepair',
    type: 'checkbox',
    field: 'Loan unit for the duration of repair',
    required: false,
    styleClass: 'col-sm-12 mb-1',
    idKey: 1,
    reasonForLoanId: 10,
  },
  {
    label: 'reasonRMAReturn',
    type: 'checkbox',
    field: 'RMA Return',
    required: false,
    styleClass: 'col-sm-12 mb-1',
    idKey: 1,
    reasonForLoanId: 18,
  },
  {
    label: 'reasonStrategicLongTermLoan',
    type: 'checkbox',
    field: 'Strategic long term loan',
    required: false,
    styleClass: 'col-sm-12 mb-1',
    idKey: 1,
    reasonForLoanId: 11,
  },
  {
    label: 'reasonTradeFair',
    type: 'checkbox',
    field: 'Trade fair',
    required: false,
    styleClass: 'col-sm-12 mb-1',
    idKey: 1,
    reasonForLoanId: 12,
  },
  {
    label: 'reasonWalkOff',
    type: 'checkbox',
    field: 'Walk Off',
    required: false,
    styleClass: 'col-sm-12 mb-1',
    idKey: 1,
    reasonForLoanId: 16,
  },
  {
    label: 'reasonWorkshop',
    type: 'checkbox',
    field: 'Workshop',
    required: false,
    styleClass: 'col-sm-12 mb-1',
    idKey: 1,
    reasonForLoanId: 13,
  },
]