import Axios from 'axios';
import { batchActions } from 'redux-batched-actions';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';

import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import {
  deleteMasterDataService,
  fetchMasterDataService,
  fetchMasterDataServicePost,
  fetchMasterDataServicePut,
} from 'library/api/master-data.service';
import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import {
  FETCH_CONDITIONS,
  FETCH_LOAN_TYPE_LIST,
  FETCH_USERS_LIST,
  FETCH_SYSTEM_CLASS_WITH_STOCKS,
  FETCH_COUNTRY_LIST,
  FETCH_RMS_ZMCC_COUNTRIES,
  ADD_LIST_OF_CONNECTED_TRANSACTION,
} from 'library/common/commonConstants/storeConstants';
import zeissInstance from 'library/utilities/AxiosInstance';
import { transactionListRequestJson, zmccTransactionListRequestJson } from 'library/api/endpoint-request-json';
import * as actionTypes from 'modules/Booking/BookingConstants';
import { fetchFromStorage } from 'library/utilities/storage';
import { getSystemCategoriesList } from 'modules/ZmccRequest/SEMBooking/SemBooking.actions';
import store from 'main/store/configureStore';

export const handlingSendData = (data, transactionType) => {
  const {
    sort,
    filter: {
      condition,
      currentPosition,
      dateRange,
      dateType,
      loanType,
      searchTerm,
      stock,
      systemClass,
      transactionStatuses,
    },
  } = data;
  let sortField = null;
  let sortDirection = null;
  Object.keys(sort).forEach(item => {
    if (sort[item]) {
      sortField = item;
      sortDirection = sort[item];
    }
  });
  const loanTypes = { 1: 'kit loan', 2: 'single loan', 3: 'course loan' };
  const dateTypes = { 1: 'delivery date', 2: 'begin of loan', 3: 'end of loan' };
  const sendData = {
    graph: transactionListRequestJson,
    searchType: transactionType,
    search: searchTerm,
    transactionType: loanType ? loanTypes[loanType] : null,
    stockIds: stock ? stock : [],
    systemMainComponentIds: systemClass ? systemClass : [],
    currentPositionIds: currentPosition ? currentPosition : [],
    transactionStatusIds: transactionStatuses ? transactionStatuses : [],
    kitConditionIds: condition ? [condition] : [],
    dateType: dateType ? dateTypes[dateType] : null,
    timestampStart: dateRange && dateRange[0] ? moment(dateRange[0]).valueOf() : null,
    timestampEnd: dateRange && dateRange[1] ? moment(dateRange[1]).valueOf() : null,
    sortField,
    sortDirection,
    page: data.page,
    size: data.size,
  };
  return sendData;
};

export const fetchInitialData = (type) => async dispatch => {
  
  try {
    const { data: kitCondition } = await  fetchMasterDataService(URLS.kitCondition);
    const conditionIds = [1, 2, 6]; // Conditions Ids to display on dropdown
    let filteredConditions = [];
  
    if (kitCondition && kitCondition.length) {
      filteredConditions = kitCondition.filter(item => conditionIds.includes(item.kitStatusId));
    }
    const masterData = store.getState().masterDataReducer;
    const bookingReducer = store.getState().bookingReducer;

    if (type === 'dla') {
      if (bookingReducer?.transactionStatuses?.length === 0) {
         dispatch(fetchMasterData(`${URLS.transactionStatuses}?type=GENERAL`, actionTypes.FETCH_TRANSACTION_STATUSES));
      }
      if (bookingReducer?.transactionPositions?.length == 0) {
        dispatch(fetchMasterData(URLS.transactionPositions, actionTypes.FETCH_TRANSACTION_POSITIONS));
      }
        dispatch(fetchMasterData(URLS.loanTypesList, FETCH_LOAN_TYPE_LIST));
         dispatch(fetchMasterData(`${URLS.countryList}?loantype=dla-stocks`, FETCH_COUNTRY_LIST)); 
      if (!!masterData.systemClassWithStock && masterData.systemClassWithStock.length === 0) {
        dispatch(fetchMasterData(URLS.systemClassWithStock, FETCH_SYSTEM_CLASS_WITH_STOCKS));  
      }
    }

    if(type === 'l@l'){
      if (bookingReducer?.labsLocationTransactionStatuses?.length === 0) {
        dispatch(fetchMasterData(`${URLS.transactionStatuses}?type=PARTNER_LABS_LOCATION`, actionTypes.FETCH_LABS_LOCATION_TRANSACTION_STATUSES)); 
      }
      if (bookingReducer.labsLocationCountries.length === 0) {
        dispatch(fetchMasterData(URLS.userCountriesLabLocation, actionTypes.FETCH_LABS_LOCATION_COUNTRIES));
      }
      if (bookingReducer?.labsLocationSystemClasses?.length === 0) {
        dispatch(fetchMasterData(URLS.systemclassesLabsLocation, actionTypes.FETCH_LABS_LOCATION_SYSTEM_CLASSES));
      }
    }

    if (type === 'zmcc') {   
        if (bookingReducer?.zmccTransactionStatuses?.length === 0) {
        dispatch(fetchMasterData(`${URLS.transactionStatuses}?type=ZMCC`, actionTypes.FETCH_ZMCC_TRANSACTION_STATUSES));
      }
      if (bookingReducer?.zmccCountries?.length === 0) {
        dispatch(fetchMasterData(URLS.zmccCountryAndStocksList, FETCH_RMS_ZMCC_COUNTRIES));
      }
      if (bookingReducer?.zmccCategory?.length === 0) {
       dispatch(fetchMasterData(URLS.zmccTransactionCategory, actionTypes.FETCH_ZMCC_TRANSACTION_CATEGORY));
      }
       dispatch(getSystemCategoriesList());
       const res = await fetchMasterDataService(URLS.zmccOperators);
       dispatch({ type: actionTypes.FETCH_ZMCC_TRANSACTION_OPERATORS, payload: res.data.body });
    }
    await dispatch(fetchMasterData(URLS.userList, FETCH_USERS_LIST));
  
    dispatch(
      batchActions([
        dispatch({ type: actionTypes.SET_CONDITIONS_BOOKING_LIST_FILTER, payload: filteredConditions }),
        dispatch({ type: FETCH_CONDITIONS, payload: kitCondition }),
      ]),
    );
  } catch (err) {
    dispatch(
      batchActions([
        dispatch({ type: actionTypes.SET_CONDITIONS_BOOKING_LIST_FILTER, payload: [] }),
        dispatch({ type: FETCH_CONDITIONS, payload: [] }),
        dispatch({ type:actionTypes.FETCH_ZMCC_TRANSACTION_OPERATORS, payload: [] }),
      ]),
    );
  }
};

export const fetchTransactionListData = (data, typeOfList, transactionType, fetchMasterData = false) => async dispatch => {
  
  try {
    const sendData = handlingSendData(data, transactionType);
    const url = typeOfList === 'l@l' ? URLS.transactionsLabsLocationList : URLS.transactionsListNew;
    const actionType = typeOfList === 'l@l' ? actionTypes.FETCH_LABS_LOCATION_TRANSACTIONS_LIST : actionTypes.FETCH_TRANSACTIONS_LIST;

    dispatch(
      batchActions([
        dispatch(changeLoader(true)),
        dispatch({
          type: actionTypes.SET_DELAYED_TRANSACTIONS,
          payload: [],
        }),
      ]),
    );
    const typeOfTranasaction = typeOfList === 'l@l' ? 'l@l' : 'dla';
    if (fetchMasterData) {
      await dispatch(fetchInitialData(typeOfTranasaction));
    }

    const { data: transactionsList } = await fetchMasterDataServicePost(url, sendData);

    dispatch(
      batchActions([
        dispatch({ type: actionType, payload: transactionsList }),
        dispatch(changeLoader(false)),
      ]),
    );

    if (
      transactionType === 'my-bookings' ||
      transactionType === 'open-processes' ||
      transactionType === 'list-receiving'
    ) {
      setReturnDelayedTransactions(transactionsList.content, dispatch);
    }
  } catch (e) {
    const actionType = typeOfList === 'l@l' ? actionTypes.FETCH_LABS_LOCATION_TRANSACTIONS_LIST : actionTypes.FETCH_TRANSACTIONS_LIST;

    dispatch(
      batchActions([
        dispatch(changeLoader(false)),
        dispatch({
          type: actionType,
          payload: [],
        }),
      ]),
    );
  }
};

export const fetchMasterDataOnLanguageChange = () => async dispatch => {
  const response = await Promise.all([
    fetchMasterDataService(URLS.kitCondition),
    fetchMasterDataService(`${URLS.transactionStatuses}?type=GENERAL`),
    fetchMasterDataService(`${URLS.transactionStatuses}?type=PARTNER_LABS_LOCATION`),
    fetchMasterDataService(URLS.transactionPositions),
    fetchMasterDataService(URLS.loanTypesList),
    fetchMasterDataService(URLS.countryList),
    fetchMasterDataService(URLS.userCountriesLabLocation),
    fetchMasterDataService(URLS.rolesList),
    fetchMasterDataService(`${URLS.transactionStatuses}?type=ZMCC`, actionTypes.FETCH_ZMCC_TRANSACTION_STATUSES),
    fetchMasterDataService(URLS.zmccTransactionCategory, actionTypes.FETCH_ZMCC_TRANSACTION_CATEGORY),
   
  ]);
  const [
    { data: kitCondition },
    { data: transactionStatuses },
    { data: labsLocationTransactionStatuses },
    { data: transactionPositions },
    { data: loanTypesList },
    { data: countries },
    { data: labsLocationCountries },
    { data: rolesList },
    { data: zmccTransactionStatuses },
    { data: zmccCategory },
  ] = response;
  const fetchConditionsToShow = [1, 2, 6]; // Conditions Ids to display on dropdown
  let filteredConditions = [];
  if (kitCondition && kitCondition.length) {
    fetchConditionsToShow.forEach(toShow => {
      filteredConditions.push(kitCondition.find(condition => condition.kitStatusId === toShow));
    });
  }


  dispatch(
    batchActions([
      dispatch({ type: actionTypes.FETCH_TRANSACTION_STATUSES, payload: transactionStatuses }),
      dispatch({
        type: actionTypes.FETCH_LABS_LOCATION_TRANSACTION_STATUSES,
        payload: labsLocationTransactionStatuses,
      }),
      dispatch({ type: actionTypes.FETCH_TRANSACTION_POSITIONS, payload: transactionPositions }),
      dispatch({ type: actionTypes.SET_CONDITIONS_BOOKING_LIST_FILTER, payload: filteredConditions }),
      dispatch({ type: FETCH_CONDITIONS, payload: kitCondition }),
      dispatch({ type: FETCH_LOAN_TYPE_LIST, payload: loanTypesList }),
      dispatch({ type: FETCH_COUNTRY_LIST, payload: countries }),
      dispatch({ type: actionTypes.FETCH_LABS_LOCATION_COUNTRIES, payload: labsLocationCountries }),
      dispatch({ type: actionTypes.FETCH_ROLES_LIST, payload: rolesList }),
      dispatch({ type: actionTypes.FETCH_ZMCC_TRANSACTION_STATUSES, payload: zmccTransactionStatuses }),
      dispatch({ type: actionTypes.FETCH_ZMCC_TRANSACTION_CATEGORY, payload: zmccCategory }),
      dispatch(changeLoader(false)),
    ]),
  );
  return { kitCondition, transactionStatuses, transactionPositions, loanTypesList, rolesList };
};

const setReturnDelayedTransactions = (transactions, dispatch) => {
  let delayedTransactions = [];
  transactions.forEach(transaction => {
    const { transactionsId, checkedDate, transactionNumber, mileStoneDelayForReturnDelivery } = transaction;
    if (mileStoneDelayForReturnDelivery && checkedDate) {
      delayedTransactions.push({ transactionsId, transactionNumber });
    }
  });
  dispatch({
    type: actionTypes.SET_DELAYED_TRANSACTIONS,
    payload: delayedTransactions,
  });
};

export const fetchCancellationRequest = url => dispatch => {
  dispatch(changeLoader(true));

  return new Promise(resolve => {
    fetchMasterDataServicePut(url, {})
      .then(res => {
        if (res.status === 204) {
          if (url.includes('reject-cancel-request')) {
            dispatch(toggleActionMessage(true, 'success', 'rejectRequestCancellationRequestSubmitted'));
            resolve({ success: true });
          } else {
            dispatch(toggleActionMessage(true, 'success', 'cancellationRequestSubmitted'));
            resolve({ success: true });
          }
        }
        dispatch(changeLoader(false));
      })
      .catch(err => {
        dispatch(changeLoader(false));
        if (err.response.status === 422) {
          dispatch(toggleActionMessage(false, 'info', err.response.message));
          resolve({ success: false });
        } else {
          dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
          resolve({ success: false });
        }
      });
  });
};

export const saveAdjustTransactionDates = (dataToSend, transactionsId) => () => {
  const objectToSend = { transactionsId: [transactionsId] };
  if (dataToSend.targetDeliveryDate || dataToSend.targetDeliveryFrom) {
    objectToSend['targetDeliveryFrom'] = dataToSend.targetDeliveryDate || dataToSend.targetDeliveryFrom;
  }
  if (dataToSend.targetDeliveryTo) {
    objectToSend['targetDeliveryTo'] = dataToSend.targetDeliveryTo;
  }
  if (dataToSend.targetReservationFrom) {
    objectToSend['targetReservationFrom'] = dataToSend.targetReservationFrom;
  }
  if (dataToSend.targetReservationTo) {
    objectToSend['targetReservationTo'] = dataToSend.targetReservationTo;
  }
  if (dataToSend.targetReturnDeliveryDate || dataToSend.targetReturnDeliveryFrom) {
    objectToSend['targetReturnDeliveryDate'] =
      dataToSend.targetReturnDeliveryDate || dataToSend.targetReturnDeliveryFrom;
    objectToSend['targetReturnDeliveryFrom'] =
      dataToSend.targetReturnDeliveryDate || dataToSend.targetReturnDeliveryFrom;
  }
  if (dataToSend.targetReturnDeliveryTo) {
    objectToSend['targetReturnDeliveryTo'] = dataToSend.targetReturnDeliveryTo;
  }
  if (dataToSend.targetCheckedDate) {
    objectToSend['checkedDate'] = dataToSend.targetCheckedDate;
  }
  if (dataToSend.targetReconditioningFrom) {
    objectToSend['targetReconditioningFrom'] = dataToSend.targetReconditioningFrom;
  }
  if (dataToSend.targetReconditioningTo) {
    objectToSend['targetCheckedDate'] = dataToSend.targetReconditioningTo;
    objectToSend['targetReconditioningTo'] = dataToSend.targetReconditioningTo;
  }
  return zeissInstance
    .put(URLS.updateTransactionTargetDate(transactionsId), objectToSend)
    .then(() => (
      window.location.reload(),
      { success: true }
      ))
    .catch(error => ({ success: false, error }));
};

export const exportTransactionList = (valuesToSend, language) => (dispatch) => {
  const token = fetchFromStorage(identifiers.token);
  return Axios(process.env.REACT_APP_BASE_URL + URLS.exportTransactionList, {
    method: 'POST',
    responseType: 'blob',
    headers: {
      token: `${token}`,
      'Content-Type': 'application/json',
      'Accept-Language': language !== null ? language : 'en',
    },
    data: valuesToSend,
  })
    .then((response) => {
      try {
        const newFile = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const dispositionHeader = response.headers['content-disposition'] && response.headers['content-disposition'].split('filename=');
        const filename = dispositionHeader[1] || 'Transaction list';
        saveAs(newFile, filename);
        dispatch(changeLoader(false));
        dispatch(toggleActionMessage(true, 'success', 'successMessageForExportListTransaction'));
        return { success: true };
      } catch (e) {
        dispatch(toggleActionMessage(true, 'error', 'errorMessageForExportListTransaction'));
        dispatch(changeLoader(false));
        return { success: false };
      }
    })
    .catch(() => {
      return { success: false };
    });
};

export const getTeam = () => async () => {
  try {
    const { data } = await fetchMasterDataService(URLS.team);

    if (data && data.id) {
      return data.id;
    }
  } catch (error) {
    if (error && error.response && error.response.status === 404) {
      return null;
    }
  }
}

export const getTeamMembers = () => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.teamMembers);

    if (data) {
      dispatch({ type: actionTypes.FETCH_TEAM_MEMBERS, payload: data });
    }
  } catch (error) {
    dispatch({ type: actionTypes.FETCH_TEAM_MEMBERS, payload: [] });
  }
};

export const addUsersToTeamBookings = (userIds, data, transactionType) => async dispatch => {
  try {
    const teamId = await dispatch(getTeam());
    await fetchMasterDataServicePost(URLS.teamMembers, { teamId, userIds });
    await dispatch(getTeamMembers());
    await dispatch(fetchTransactionListData(data, transactionType));
    return { success: true };
  } catch (error) {
    return { success: false };
  }
};

export const removeUserFromTeamBookings = (userIds, data, transactionType) => async dispatch => {
  try {
    const teamId = await dispatch(getTeam());

    await fetchMasterDataServicePut(URLS.teamMembers, { teamId, userIds });
    await dispatch(getTeamMembers());
    await dispatch(fetchTransactionListData(data, transactionType));
    dispatch(toggleActionMessage(true, 'success', 'userRemoveSuccessfully'));
    return { success: true };
  } catch (error) {
    return { success: false };
  }
};


export const fetchZmccTransactionList = (data, typeOfList, transactionType, fetchMasterData = false) => async dispatch =>{

try {
  const dataToSend = handlingZmccSendData(data, transactionType);
  dispatch(changeLoader(true));
  const res = await fetchMasterDataServicePost(URLS.fetchZmccTransactionList, dataToSend);
  if (fetchMasterData) {
    await dispatch(fetchInitialData('zmcc'));
  }
  dispatch(
    batchActions([
      dispatch({ type: actionTypes.FETCH_ZMCC_TRANSACTION_LIST, payload: res.data }),
      dispatch(changeLoader(false)),
    ]),
  );
  
  return { success: true };
} catch (e) {
  dispatch(changeLoader(false));
  return { success: false };
}

}

export const handlingZmccSendData = (data, transactionType) => {
  const {
    sort,
    filter: {
      condition,
      currentPosition,
      dateRange,
      dateType,
      loanType,
      searchTerm,
      stock,
      systemClass,
      transactionStatuses,
      category,
      operator,
      requestType,
      systemCategoriesId,
    },
  } = data;
  let sortField = null;
  let sortDirection = null;
  Object.keys(sort).forEach(item => {
    if (sort[item]) {
      sortField = item;
      sortDirection = sort[item];
    }
  });
  const loanTypes = { 1: 'kit loan', 2: 'single loan', 3: 'course loan' };
  // const dateTypes = { 1: 'delivery date', 2: 'begin of loan', 3: 'end of loan' };
  const sendData = {
    graph: zmccTransactionListRequestJson,
    searchType: transactionType,
    search: searchTerm,
    transactionType: loanType ? loanTypes[loanType] : null,
    stockIds: stock ? stock : [],
    systemMainComponentIds: systemClass ? systemClass : [],
    currentPositionIds: currentPosition ? currentPosition : [],
    transactionStatusIds: transactionStatuses ? transactionStatuses : [],
    kitConditionIds: condition ? condition : [],
    dateType: dateType ? dateType : null,
    timestampStart: dateRange && dateRange[0] ? moment(dateRange[0]).format('YYYY-MM-DD') : null,
    timestampEnd: dateRange && dateRange[1] ? moment(dateRange[1]).format('YYYY-MM-DD') : null,
    sortField: 'createdAt',
    sortDirection: 'desc',
    page: data.page,
    size: data.size,
    categoryIds: category ? category :[],
    operatorIds: operator ? operator :[],
    requestType: requestType ? requestType: '',
    systemCategoriesId: systemCategoriesId ? systemCategoriesId : [],
  };
  return sendData;
};

export const fetchConnectTransactionData = (transactionsId) => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const { data } = await fetchMasterDataService(URLS.getListOfConnectedTransaction(transactionsId));
    dispatch(changeLoader(false));
    dispatch({
      type: ADD_LIST_OF_CONNECTED_TRANSACTION,
      payload: data,
    });
    return { success: true, data };
  } catch (error) {
    dispatch(changeLoader(false));
    dispatch({
      type: ADD_LIST_OF_CONNECTED_TRANSACTION,
      payload: [],
    });
    return { success: false };
  }
}

export const cancelBulkResevations = (dataToSend, data,transactionType) => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const res = await fetchMasterDataServicePut(URLS.bulkCancelTwentyFourHoursResevation, dataToSend);
    dispatch(changeLoader(false));
    dispatch(fetchTransactionListData(data, 'dla', transactionType));
    return { success: true }
  } catch (error) {
    dispatch(changeLoader(true));
    return { success: false };
  }
}

export const fetchMyQueriesList = (dataToSend) => async dispatch => {
  let createDataToSend = {}
  let sortField = '';
  let sortDirection = '';
  createDataToSend.page = dataToSend.page;
  createDataToSend.size = dataToSend.size;
  createDataToSend.searchKey = dataToSend.searchKey;

  Object.keys(dataToSend.sort).forEach(item => {
    if (dataToSend.sort[item]) {
     sortField = item;
     sortDirection = dataToSend.sort[item] === 'asc' ? 'ASC' : 'DESC';
    }
  });
  createDataToSend.sortField = sortField;
  createDataToSend.sortDirection = sortDirection ;
  try {
    dispatch(changeLoader(true));
    const res = await fetchMasterDataServicePost(URLS.safeSearchList, createDataToSend);
    dispatch(changeLoader(false));
    dispatch({
      type: actionTypes.FETCH_SAFE_SEARCH_LIST,
      payload: res?.data,
    });
    return { success: true }
  } catch (error) {
    dispatch(changeLoader(false));
    dispatch({
      type: actionTypes.FETCH_SAFE_SEARCH_LIST,
      payload: [],
    });
    return { success: false };
  }
}

export const createMyQuery = (dataToSend, saveEditingData, safeSearchId) => async dispatch => {
  try {
   
    if (saveEditingData === true) {
      const res = await fetchMasterDataServicePut(URLS.editQuery(safeSearchId), dataToSend)
    }else{
      const res = await fetchMasterDataServicePost(URLS.saveQuery, dataToSend)
    }
    dispatch(toggleActionMessage(true, 'success', 'valuesSavedSuccessMessage'))
    const listDataToSend = {
      page: 0,
      searchKey: '',
      size: 10,
      sort: { createdAt: 'desc' },
    }
    dispatch(fetchMyQueriesList(listDataToSend));
    return { success: true }
  } catch (error) {
    return { success: false };
  }
}

export const deleteMyQuery = (id) => async dispatch => {
  try {
    dispatch(changeLoader(true));
    await deleteMasterDataService(URLS.deleteMyQuery(id));
    dispatch(changeLoader(false));
    const listDataToSend = {
      page: 0,
      searchKey: '',
      size: 10,
      sort: {createdAt:'desc'},
    }
    dispatch(fetchMyQueriesList(listDataToSend));
    return {success: true}
  } catch (error) {
    dispatch(changeLoader(false));
    return {success: false};
  }
}

export const getSaveFilterData = (id) => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const res = await fetchMasterDataService(URLS.editQuery(id));
    dispatch(changeLoader(false));
    dispatch({
      type: actionTypes.FETCH_SAVED_FILTERS,
      payload: res?.data,
    })
    return {success: true, data: res?.data}
  } catch (error) {
    dispatch(changeLoader(false));
    return {success: false};
  }
}




