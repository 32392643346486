import React from 'react';
import { NavLink } from 'react-router-dom';

import Icon from 'library/common/commonComponents/Icon/Icon';
import strings from 'resources/locales/Translate';
import { ReactComponent as Logo } from 'resources/images/zeiss-logo.svg';
import { images } from 'library/common/commonConstants/ImageConstants';

const UnloggedMenu = ({ language, cart, handleLanguageChange }) => {

  return (
    <div className='d-flex align-item-center justify-content-between' data-toggle='modal' data-target='#loginModal'>
    
    <div className='new-navbar cursor-pointer'>
        <Icon name='burgerMenu' width={32} height={32} fill={'#fff'}/>
        <img src={images.updateZEISSLogo}  style={{width:'40px', height:'40px'}} className='ml-3'></img>
    </div>
    <div className='right-header-adjusment'>
    <div>
            <NavLink to='/documents' exact={true} style={{color: 'white'}} >
              {strings.aboutDma}
           </NavLink>
         </div>
          <div className=' cursor-pointer'>
           {strings.contact}
          </div>
          <div className='cursor-pointer' onClick={handleLanguageChange}>
             {strings[language]}
          </div>
       <div className='user-menu'>
        <ul className='nav'>
          <li className='nav-item dropdown cart'>
              <Icon name= 'newCart' width="18" height="18" viewBox="0 0 17 16" fill={'white'}/>
          </li>
          <li className='nav-item'>
            <div
              className='cursor-pointer position-relative'
            >
              <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.69668 14.5996V7.39961C2.69668 6.12657 3.20239 4.90567 4.10257 4.0055C5.00274 3.10532 6.22364 2.59961 7.49668 2.59961C8.76972 2.59961 9.99062 3.10532 10.8908 4.0055C11.791 4.90567 12.2967 6.12657 12.2967 7.39961V14.5996M2.69668 14.5996H12.2967M2.69668 14.5996H1.09668M12.2967 14.5996H13.8967M6.69668 16.9996H8.29668" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M7.49678 2.6C7.93861 2.6 8.29678 2.24183 8.29678 1.8C8.29678 1.35817 7.93861 1 7.49678 1C7.05495 1 6.69678 1.35817 6.69678 1.8C6.69678 2.24183 7.05495 2.6 7.49678 2.6Z" stroke="white" stroke-width="1.5"/>
              </svg>
            </div>
          </li>
          <li className='nav-item dropdown user'>
            <p
              className='cursor-pointer dropdown-toggle'
              id='dropdownMenuButton'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
             <Icon name= 'newPerson' width="18" height="16" viewBox="0 0 18 16" fill={'white'}/>
            </p>
          </li>
        </ul>
      </div>
    </div>
    
  </div>
  );
};

export default UnloggedMenu;
