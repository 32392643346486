import LocalizedStrings from 'react-localization';

import {en} from "./Languages/en";
import {de} from "./Languages/de";

let strings = new LocalizedStrings({
  en: en,
  de: de
});

export default strings;
