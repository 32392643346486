import * as actionTypes from './KitDetailsConstants';

export const INITIAL_STATE_KIT_DETAILS = {
  kitDetails: {},
  kitDetailsAttachments: [],
  commentsData: {},
  damageLossReportData: {},
  currentTab: null,
  kitAttachments: [],
  transactionAttachments: [],
  workFlowQuestions: [],
  activeTransactionLoanOrderData: {},
};

const KitDetailsReducer = (state = INITIAL_STATE_KIT_DETAILS, action) => {
  switch (action.type) {
    case actionTypes.GET_KIT_DETAILS:
      return {
        ...state,
        kitDetails: action.payload,
      };
    case actionTypes.ADD_REMOVE_KIT_ATTACHMENTS:
      return {
        ...state,
        kitAttachments: action.payload,
      };
    case actionTypes.FETCH_KIT_DETAILS_ATTACHMENTS:
      return {
        ...state,
        kitDetailsAttachments: action.payload,
        kitAttachments: action.payload,
      };
    case actionTypes.FETCH_KIT_DETAILS_DAMAGE_LOSS_REPORT:
      return {
        ...state,
        damageLossReportData: action.payload,
      };
    case actionTypes.SAVE_CURRENT_TAB:
      return {
        ...state,
        currentTab: action.payload,
      };
    case actionTypes.CLEAR_KIT_DETAILS_DATA:
      return {
        ...state,
        kitDetails: {},
      };
    case actionTypes.FETCH_KIT_DETAILS_COMMENTS: {
      return {
        ...state,
        commentsData: action.payload,
      };
    }
    case actionTypes.FETCH_KIT_TRANSACTIONS_ATTACHMENTS: {
      return {
        ...state,
        transactionAttachments: action.payload,
      };
    }
    case actionTypes.FETCH_WORKFLOW_QUESTIONS: {
      return {
        ...state,
        workFlowQuestions: action.payload,
      };
    }
    case actionTypes.FETCH_TRANSACTION_LOAN_ORDER: {
      return {
        ...state,
        activeTransactionLoanOrderData: action.payload,
      };
    }
    default:
      return state;
  }
};

export default KitDetailsReducer;
