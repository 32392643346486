import React from 'react';

import Modal from 'library/common/commonComponents/Modal';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import FileUploader from 'library/common/commonComponents/FileUploader/FileUploader';
import strings from 'resources/locales/Translate';

import { useKitForSalePopup } from './useKitForSalePopup';

export default function KitForSalePopup({
  id,
  sellOffConditions,
  sellOffVisibility,
  sellOffCurrency,
  updateFormCreateKit,
  saveSaleKit,
  kitInformationId,
  kitStatusForm,
  conditions,
  availabilityTo,
  systemClassData,
  assignmentForm,
  saveKit,
  mainComponentForm,
}) {
  const {
    kitForSaleForm,
    handleSave,
    closeModal,
    isLoading,
    handleForm,
    removeAttachments,
    addAttachments,
    error,
    attachmentFiles,
    getKitForSaleModel,
  } = useKitForSalePopup({
    id,
    sellOffConditions,
    sellOffVisibility,
    sellOffCurrency,
    updateFormCreateKit,
    saveSaleKit,
    kitInformationId,
    kitStatusForm,
    conditions,
    availabilityTo,
    systemClassData,
    assignmentForm,
    mainComponentForm,
    saveKit,
  });

  return (
    <Modal
      id={id}
      confirmAction={handleSave}
      onCloseClick={closeModal}
      confirmTitle={strings.save}
      disableBackgroundClose
      loading={isLoading}
    >
      <div className='kitForSale-popup'>
        <h2 className='mb-3'>{strings.kitForSale}</h2>
        <FormComponent
          model={getKitForSaleModel()}
          formName='kitForSaleFormForm'
          formValue={kitForSaleForm.formData}
          onChange={handleForm}
        />

        <div className='mb-4 mt-2'>
          <h4 className='mb-3'>{strings.attachments}</h4>
          <FileUploader
            maxSize={10}
            mimeType='image/*,.pdf,.doc,.docx,.xls,.xlsx,application/msword,application/vnd.openxm'
            files={attachmentFiles}
            maxFiles={30}
            onRemove={removeAttachments}
            onFileAdd={addAttachments}
            errorMessage={strings.allowedFormats}
          />
        </div>
        <div className='error-message'>{error}</div>
      </div>
    </Modal>
  );
};
