import { connect } from 'react-redux';

import {
  getContinentsList,
  getCalculationList,
  updateCalculations,
  clearCalculationList,
} from '../../LLManagement.actions';
import CalculationMethodTab from './CalculationMethodTab.component';

const mapStateToProps = ({ authReducer, languageReducer, llManagementReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
  continentsList: llManagementReducer.continentsList,
  calculationList: llManagementReducer.calculationList,
});

export default connect(mapStateToProps, {
  getContinentsList,
  getCalculationList,
  updateCalculations,
  clearCalculationList,
})(CalculationMethodTab);
