import Axios from 'axios';

export const exportListRequest = (url, valuesToSend, token,language) => {
  return Axios(process.env.REACT_APP_BASE_URL + url, {
    method: 'POST',
    responseType: 'blob',
    headers: {
      token: `${token}`,
      'Content-Type': 'application/json',
      'Accept-Language': language,
    },
    data: valuesToSend,
  });
}
