import moment from 'moment';

import Validators from 'library/utilities/Validators';

export const formReturnGoodsBookingModel = [
  {
    dataTest: 'return-goods-entered-dropdown',
    label: 'editor',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'user',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'accountId',
    displayValue: [
      {key: 'lastName', separator: ', '},
      {key: 'firstName', separator: ''}
    ],
  },
  {
    label: 'receiptInStock',
    value: '',
    type: 'date',
    placeholder: 'date',
    field: 'date',
    validators: [
      { check: Validators.required, message: 'requiredErrorMessage' },
    ],
    required: true,
    styleClass: 'col-12',
    dateFormat: 'DD.MM.YYYY',
    validateOnChange: true,
    maxDate: new Date(moment().add(10, 'years')),
  },
  {
    dataTest: 'return-goods-storage-location',
    label: 'storageLocation',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'storageLocation',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-12',
  },
  {
    label: 'completeness',
    value: '',
    type: 'radio-button',
    placeholder: '',
    field: 'completeness',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-12',
    options: [
      {
        id: 'true',
        value: 'yes'
      },
      {
        id: 'false',
        value: 'no'
      }
    ]
  },
  {
    dataTest: 'return-goods-condition-dropdown',
    label: 'conditionReturnGoodBooking',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'condition',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'kitStatusId',
    displayValue: [{key: 'translatedStatus', separator: ''}],
  },
  {
    dataTest: 'return-goods-comment',
    label: 'commentReturnGoodBooking',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'comment',
    validators: [],
    required: false,
    styleClass: 'col-12',
  },
  {
    dataTest: 'return-goods-delivery-note-number',
    label: 'deliveryNoteNumber',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'deliveryNoteNumber',
    validators: [],
    required: false,
    styleClass: 'col-12',
  },
  {
    label: 'returnBookingDate',
    value: '',
    type: 'date',
    placeholder: 'date',
    field: 'returnBookingDate',
    validators: [],
    required: false,
    styleClass: 'col-12',
    dateFormat: 'DD.MM.YYYY',
    validateOnChange: true,
    maxDate: new Date(moment().add(10, 'years')),
  },
];
