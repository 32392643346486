import { xbMainApplicationChecklist } from "./XBApplicationSampleDetails.constants";


export const useXBApplicationSampleDetails = (props) =>{
    const handleForm = ({ values, field, formName }) => {
        props.updateSEMForm({
          [formName]: {
            ...props.formValue[formName],
            formData: {
              ...((props.formValue[formName] && props.formValue[formName].formData) || {}),
              ...values,
            },
          },
        });
        props.setUnsavedChanges(true);
      };
    
      const handleNonFormComponent = (values, field, formName) => {
        props.updateSEMForm({
          [formName]: {
            ...props.formValue[formName],
            formData: {
              ...((props.formValue[formName] && props.formValue[formName].formData) || {}),
              [field]: values,
              [`${field}Error`]: null,
            },
          },
        });
      };

      const xbMainApplicationModel = () => {
        const model = [...xbMainApplicationChecklist];
        return model;
      };

      const onEditCompleted = () => {
        const { user, formValue } = props;
        const { xbSampleDeatilsFrom } = formValue;
        props.setEditCompltedBy(true);
        if (xbSampleDeatilsFrom.formData.completedByNameXb) {
          handleNonFormComponent(xbSampleDeatilsFrom.formData.completedByNameXb, 'completedByNameXb', 'xbSampleDeatilsFrom')
        }else{
          handleNonFormComponent(`${user.firstName} ${user.lastName}`, 'completedByNameXb', 'xbSampleDeatilsFrom')
        }
      }
    
      return {
        handleForm,
        handleNonFormComponent,
        xbMainApplicationModel,
        onEditCompleted
      };
}