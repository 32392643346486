import moment from 'moment';
import {isEqual} from 'lodash';

import store from 'main/store/configureStore';
import {fetchFromStorage, saveToStorage} from "library/utilities/storage";
import {ADD_KIT_TO_CART, LOAN_LIST_ALTERNATE_DATE_MESSAGE} from "library/common/commonConstants/storeConstants";
import {identifiers} from "library/common/commonConstants/IdentifiersConstants";
import {changeLoader, toggleActionMessage} from "library/common/commonActions/AppActionsActions";
import {checkBookingDatesAvailabilityService} from "modules/Calendar/calendar-services";
import {scrollToTop} from "library/utilities/scrollActions";

export const addItemToCart = items => dispatch => {
  const loanListState = store.getState().loanList;
  const updatedCart = [...loanListState.cart];
  
  items.forEach(item => {
    const index = findElement(updatedCart, item);
    const isExistingItem = checkExistingItem(updatedCart, item);

    if (item.isZMCCCalendar && isExistingItem){
      scrollToTop(500);
      dispatch(toggleActionMessage(true, 'error', 'alreadySlotReadyToBookOnThatRoom'));
    }
    if ((index === -1) && !isExistingItem) {
      if (item.kit && item.kit.stockInformation
        && item.kit.stockInformation.workflowCategoryAnswers
        && item.kit.stockInformation.workflowCategoryAnswers.length > 0) {
        const allowTwentyFourHourReservation =
          item.kit.stockInformation.workflowCategoryAnswers.find((question) => question.workflowCategoryQuestions.workflowCategoryQuestionsId === 6);
        if (allowTwentyFourHourReservation) item.isTwentyFourReservationAllowed = allowTwentyFourHourReservation.value === 'yes';
      }
      if (!item.transactionsId) item.transactionsId = 0;
      updatedCart.push({...item, addedAtTimeStamp: new Date().getTime()});
    }
  });

  saveToStorage(identifiers.cart, updatedCart);
  dispatch({
    type: ADD_KIT_TO_CART,
    payload: updatedCart
  });
};

const checkExistingItem = (updatedCart, item) => {
  const kits =  item.isZMCCCalendar ? updatedCart.filter(element => element['kit']['roomGroupName'] === item['kit']['roomGroupName']) : updatedCart.filter(element => element.resourceId === item.resourceId);
  let isExistingItem = false;


  if (kits.length) {
    kits.forEach(kit => {
      if ((kit.start === item.start) && (kit.end === item.end)) {
        isExistingItem = true
      }
    })
  }

  return isExistingItem;
};

export const removeItemFromCart = (item) => dispatch => {
  const loanListState = store.getState().loanList;
  const updatedCart = [...loanListState.cart];
  const index = findElement(updatedCart, item);

  if (index !== -1) {
    updatedCart.splice(index, 1);
    saveToStorage(identifiers.cart, updatedCart);

    dispatch({
      type: ADD_KIT_TO_CART,
      payload: updatedCart
    });
  }
};

export const updateItem = (item) => dispatch => {
  const loanListState = store.getState().loanList;
  const updatedCart = [...loanListState.cart];
  const index = findElement(updatedCart, item);
  updatedCart[index] = item;

  saveToStorage(identifiers.cart, updatedCart);

  dispatch({
    type: ADD_KIT_TO_CART,
    payload: updatedCart
  });

};

export const toggleSelectionForKit = (item, checked) => dispatch => {
  const loanListState = store.getState().loanList;
  const updatedCart = [...loanListState.cart];
  const index = findElement(updatedCart, item);
  const selectedItem = updatedCart[index];
  selectedItem.isSelected = checked;
  updatedCart[index] = selectedItem;

  saveToStorage(identifiers.cart, updatedCart);

  dispatch({
    type: ADD_KIT_TO_CART,
    payload: updatedCart
  });

};

export const updateDateOfCartItem = (item, date, field) => async dispatch => {
  const loanListState = store.getState().loanList;
  const updatedCart = [...loanListState.cart];
  const index = findElement(updatedCart, item);
  const selectedItem = updatedCart[index];
  selectedItem[field] = field === 'end' ? moment(date).format('YYYY-MM-DD') + ' 23:59:59' : moment(date).format('YYYY-MM-DD HH:mm:ss');
  selectedItem['isIncomplete'] = false;
  updatedCart[index] = selectedItem;

  saveToStorage(identifiers.cart, updatedCart);
  if (!selectedItem.isLabsLocation && selectedItem.start && selectedItem.end) {
    dispatch(checkAvailabilityOfKitAndDisplayMessage(selectedItem));
  }
  dispatch({
    type: ADD_KIT_TO_CART,
    payload: updatedCart
  });
  return updatedCart.filter((item) => item.isSelected);
};

export const fetchCartDataFromLocalStorage = () => dispatch => {
  const loanListState = store.getState().loanList;
  const cartFromStorage = fetchFromStorage(identifiers.cart);

  if (cartFromStorage && !isEqual(loanListState.cart, cartFromStorage)) {
    dispatch({
      type: ADD_KIT_TO_CART,
      payload: cartFromStorage
    });
  }
};

function findElement(cart, item) {
  return cart.findIndex(cartItem => cartItem.id === item.id);
}

function findElementByTransId(cart, item) {
  return cart.findIndex(cartItem => cartItem.transactionsId === item[0].transactionsId);
}

export const bookNowHandler = (kit) => dispatch => {
  const cart = store.getState().loanList.cart;

  let newBooking = {
    id: cart.length + 1,
    start: null,
    end: null,
    title: '',
    resourceId: kit.kitInformationId,
    kit: kit,
    isBooked: false,
    isAvailabilityChecked: false,
    isSelected: true, // As per new requirement isSelected is changed into true because when clicking on book now from homepage or kit detail marked reservastion/booking checkbox should byDefault selected
    isIncomplete: true, // this is to show message to update all unfilled data when user books from `book now` button
    isLabsLocation: kit.isLabsLocationPartner,
    isZMCCCalendar: false
  };
  dispatch(addItemToCart([newBooking]));
};

const checkAvailabilityOfKitAndDisplayMessage = (item) => dispatch => {
  const loanListState = store.getState().loanList;
  const updatedCart = [...loanListState.cart];
  const index = findElement(updatedCart, item);
  const selectedItem = updatedCart[index];

  if (moment(item.end).isAfter(item.start)) {
    const dataToSend = {
      kitIds: [item.resourceId],
      from: moment(item.start).format('YYYY-MM-DD'),
      to: moment(item.end).format('YYYY-MM-DD'),
      shipToCountryId: item.hasOwnProperty('country') ? item.country : null,
      shipToStateId: 0
    };

    dispatch(changeLoader(true));

    checkBookingDatesAvailabilityService(dataToSend)
      .then(res => {
        if (!res.data.requestedPeriodValid) {
          if (res.data.suggestions[0]) {
          dispatch({
            type: LOAN_LIST_ALTERNATE_DATE_MESSAGE,
            payload: {
              displayMessage: true,
              to: res.data.suggestions[0].reservationTo,
              from: res.data.suggestions[0].reservationFrom,
            }
          });
          } else if (res.data.maxLoanDurationFound) {
            dispatch(toggleActionMessage(true, 'info', res.data.kitAvailableToCheckMsg, true));
          }
          setTimeout(() => {
            dispatch({
              type: LOAN_LIST_ALTERNATE_DATE_MESSAGE,
              payload: {
                displayMessage: false,
                to: '',
                from: '',
              }
            });
          }, 1000 * 15);
          scrollToTop(500);
          selectedItem['isAvailabilityChecked'] = false;
        } else {
          selectedItem['isAvailabilityChecked'] = true;
        }

        updatedCart[index] = selectedItem;
        dispatch({
          type: ADD_KIT_TO_CART,
          payload: updatedCart
        });
        dispatch(changeLoader(false));
      })
      .catch(err => {
        dispatch(changeLoader(false));
        dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
      });
  } else {
    scrollToTop(500);
    dispatch(toggleActionMessage(true, 'error', 'startDateGreaterThanEndDate'));
  }
};

export const closeAlternateDateSuggestionMessage = () => dispatch => {
  dispatch({
    type: LOAN_LIST_ALTERNATE_DATE_MESSAGE,
    payload: {
      displayMessage: false,
      from: '',
      to: '',
    }
  });
};


export const removeItemFromCartForBulk = (item) => dispatch => {
  const loanListState = store.getState().loanList;
  const updatedCart = [...loanListState.cart];
  const index = findElementByTransId(updatedCart, item);
  if (index !== -1) {
    updatedCart.splice(index, 1);
    saveToStorage(identifiers.cart, updatedCart);

    dispatch({
      type: ADD_KIT_TO_CART,
      payload: updatedCart
    });
  }
};
