import Validators from 'library/utilities/Validators';

export const editionalCommentModel = [
  {
    label: 'yourName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'yourName',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage '}],
    required: true,
    styleClass: 'col-sm-6',
    disabled: true,
  },
  {
    label: 'date',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'date',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    dateFormat: 'DD.MM.YYYY',
    disabled: true,
  },
  {
    label: 'commentText',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'text',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 1000,
    },
  },
];

