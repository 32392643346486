import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as $ from 'jquery';
import { get } from 'lodash';
import strings from 'resources/locales/Translate';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import Button from 'library/common/commonComponents/Button/Button';
import Checkbox from 'library/common/commonComponents/Checkbox/Checkbox';
import Dropdown from 'library/common/commonComponents/Dropdown/Dropdown';
import FormComponent from 'library/common/commonComponents/FormComponent/FormComponent';
import Icon from 'library/common/commonComponents/Icon/Icon';
import InputField from 'library/common/commonComponents/InputField/InputField';
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { camelCase } from 'library/utilities/stringUtils';
import { getDateInUTCFormat } from 'library/utilities/getDateInFormat';
import { deliveryAddressModel, changeReturnDeliveryAddressModel } from './InformationConstants';
import { transactionInformationModel } from '../../TransactionDetailsConstants';
import {
  saveTransactionInformationDates,
  updateTransactionDetails,
  updateTransactionStatus,
  confirmConsignment,
  erpStatus,
  saveChangeRetuenDeliverAdress,
  ordertype,
  billing,
  distributionChannel,
  getdivisionList,
  saveCreateLoanorderData,
  saveMaterialNumberAndDescription,
  getDeliveryBlockList,
  getCustomeGroup,
  returnSapErpLoanOrderForTransaction,
  checkActiveLoanorder,
  getUserIdFromBorrowerMail,
  getCustomerIDWithOpportunityID,
  createSingleLoanUsingExistingKitLoan
} from '../../TransactionDetailsActions';
import { FETCH_COUNTRY_LIST, FETCH_LABS_LOCATION_COUNTRIES } from 'library/common/commonConstants/storeConstants';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { fetchMasterData, fetchMasterDataPost } from 'library/common/commonActions/MasterDataActions';
import Validators from 'library/utilities/Validators';
import { scrollToTop } from 'library/utilities/scrollActions';
import TextArea from 'library/common/commonComponents/TextArea/TextArea';
import OpportunityForm from 'library/common/commonComponents/OpportunityForm';
import { regex } from 'library/common/commonConstants/RegexConstants';
import CreateNewLoanOrderPopup from '../CreateNewLoanOrderPopup/CreateNewLoanOrderPopup.component';
import EditMaterialNumberAndDescriptionPopup from '../EditMaterialNumberAndDescriptionPopup/EditMaterialNumberAndDescriptionPopup.component';
import { fetchStockAddress } from 'modules/Stock/CreateStock/CreateStock.actions';
import { item } from 'modules/Calendar/__mocks__/calendarData';
import AdditionalSingleLoanPopup from '../AdditionalSingleLoanPopup/AdditionalSingleLoanPopup.component';
import BlockingCalendarMessagPopup from 'modules/Calendar/component/BlockingCalendarMessagPopup/BlockingCalendarMessagPopup.component';
import InputGroup from 'library/common/commonComponents/InputGroup/InputGroup';
import { LOAN_TYPES } from 'modules/KitLoan/KitLoan.constants';
import { fetchAdaptedLoanReasonForStock } from 'modules/KitLoan/KitLoanActions';
import ReturnDeliveryAddressNotFoundPopup from './ReturnDeliveryAddressPopup/ReturnDeliveryAddressNotFoundPopup.component';
import { sapCrmNewCient } from 'library/utilities/constants';
import { getProvincesOfCountry } from 'modules/Stock/CreateStock/Tabs/DeliveryTimes/DeliveryTimes.actions';
import { getAllActiveProvinceCountries } from 'modules/Stock/CreateStock/CreateStock.actions';

const RETURN_DELIVER_ADDRESS_NOT_FOUND_POPUP_ID = 'return-delivery-address'

export const checkDatesToSave = (newFormData, transactionDetails) => {
  const dataToSend = {};
  if (checkDateToSave(newFormData.checkedDate, transactionDetails.checkedDate)) {
    if ( moment(newFormData.checkedDate).isValid()) {
    dataToSend['checkedDate'] = moment(newFormData.checkedDate).format('YYYY-MM-DD');
  }
}else {
  dataToSend['checkedDate'] = null;
}
  if (checkDateToSave(newFormData.deliveryDate, transactionDetails.deliveryDate)) {
    if (moment(newFormData.deliveryDate).isValid()){
    dataToSend['deliveryDate'] = moment(newFormData.deliveryDate).format('YYYY-MM-DD');
  }
}else{
  dataToSend['deliveryDate'] = null;
}
  if (checkDateToSave(newFormData.reservationFrom, transactionDetails.reservationFrom)) {
    if(moment(newFormData.reservationFrom).isValid()){
    dataToSend['reservationFrom'] = moment(newFormData.reservationFrom).format('YYYY-MM-DD');
  }
}else{
  dataToSend['reservationFrom'] = null;
}
  if (checkDateToSave(newFormData.reservationTo, transactionDetails.reservationTo)) {
    if(moment(newFormData.reservationTo).isValid()){
    dataToSend['reservationTo'] = moment(newFormData.reservationTo).format('YYYY-MM-DD');
  }
}else{
  dataToSend['reservationTo'] = null;
}
  if (checkDateToSave(newFormData.returnDeliveryDate, transactionDetails.returnDelivery)) {
    if(moment(newFormData.returnDeliveryDate).isValid()){
    dataToSend['returnDeliveryDate'] = moment(newFormData.returnDeliveryDate).format('YYYY-MM-DD');
  }
}else{
  dataToSend['returnDeliveryDate'] = null;
}
  if (checkDateToSave(newFormData.checkBeforeReturn, transactionDetails.checkBeforeReturn)) {
    if (moment(newFormData.checkBeforeReturn).isValid()){
    dataToSend['checkBeforeReturn'] = moment(newFormData.checkBeforeReturn).format('YYYY-MM-DD');
  }
}else{
  dataToSend['checkBeforeReturn'] = null;
}
  return dataToSend;
};

const checkDateToSave = (newDate, oldDate) => {
  return (
    newDate &&
    (!oldDate ||
      (moment(newDate).isValid() &&
      moment(oldDate).isValid()))
  );
};

export class Information extends Component {
  static getDerivedStateFromProps(props, state) {

    if (
      state.deliveryModel &&
      state.countryProvinces &&
      state.countryProvinces.length
    ) {
      const deliveryModel = [...deliveryAddressModel];
      if (props.activeProvinceCountries.includes(state.deliveryForm.countryId)){
        deliveryModel[9] ={
          label: 'stateOrProvince',
          value: '',
          type: 'dropdown',
          placeholder: '',
          field: 'state',
          validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
          required: true,
          options: [],
          hasSection: false,
          multiSelect: false,
          filter: true,
          idKey: 'stateName',
          displayValue: [{ key: 'stateName', separator: '' }],
          excludeData: [],
          styleClass: 'col-sm-4',
          extraProps: {
            maxLength: 50,
          },
        }
        deliveryModel[9].options = state.countryProvinces;
        if (state.deliveryForm.state && !state.countryProvinces.some(option => option.stateName === state.deliveryForm.state)) {
          deliveryModel[9].options.push({ stateName: state.deliveryForm.state });
        }
      }
      return { deliveryModel };

    }
    if (
      state.deliveryModel &&
      state.deliveryModel[10].options &&
      !state.deliveryModel[10].options.length &&
      props.countries &&
      props.countries.length
    ) {
      const deliveryModel = [...deliveryAddressModel];
      deliveryModel[10].options = props.countries;
      return { deliveryModel };
    }
    if (
      state.changeReturnDeliveryModel &&
      state.changeReturnDeliveryModel[10].options &&
      !state.changeReturnDeliveryModel[10].options.length &&
      props.countries &&
      props.countries.length
    ) {
      const changeReturnDeliveryAddress = [...changeReturnDeliveryAddressModel];
      changeReturnDeliveryAddress[10].options = props.countries;
      return { changeReturnDeliveryAddress };
    }
    if (props.transactionDetails) {
      return {
        transactionInformationFormData: {
          ...state.transactionInformationFormData,
          returnDeliveryDate:
          props.transactionDetails.returnDelivery && moment(props.transactionDetails.returnDelivery).isValid() &&
            `${moment.utc(props.transactionDetails.returnDelivery).format('YYYY-MM-DD')} 00:00:00`,
          checkedDate:
            moment(props.transactionDetails.checkedDate).isValid() &&
            `${moment.utc(props.transactionDetails.checkedDate).format('YYYY-MM-DD')} 00:00:00`,
          deliveryDate:
            moment(props.transactionDetails.deliveryDate).isValid() &&
            `${moment.utc(props.transactionDetails.deliveryDate).format('YYYY-MM-DD')} 00:00:00`,
          reservationFrom:
            moment(props.transactionDetails.reservationFrom).isValid() &&
            `${moment.utc(props.transactionDetails.reservationFrom).format('YYYY-MM-DD')} 00:00:00`,
          reservationTo:
            moment(props.transactionDetails.reservationTo).isValid() &&
            `${moment.utc(props.transactionDetails.reservationTo).format('YYYY-MM-DD')} 00:00:00`,
          checkBeforeReturn:
            moment(props.transactionDetails.checkBeforeReturn).isValid() &&
            `${moment.utc(props.transactionDetails.checkBeforeReturn).format('YYYY-MM-DD')} 00:00:00`,
        },
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.deliveryAddressFromRef = null;
    this.approveConfirmDropdownRef = null;
    this.milestoneDatesFormRef = null;
    this.reasonForLoanDropdownRef = null;
    this.borrowerDropdownRef = null;
    const { transactionDetails} = this.props;
    let transactionInformationFormData = {};
    let deliveryForm = {};
    let opportunities = [];
   let changeReturnDeliveryAddressForm = {};
     this.changeReturnDeliveryAddressFromRef = null;
    if (transactionDetails) {
      transactionInformationFormData = {
        checkedDate:
          moment(transactionDetails.checkedDate).isValid() &&
          `${moment.utc(transactionDetails.checkedDate).format('YYYY-MM-DD')} 00:00:00`,
        deliveryDate:
          moment(transactionDetails.deliveryDate).isValid() &&
          `${moment.utc(transactionDetails.deliveryDate).format('YYYY-MM-DD')} 00:00:00`,
        reservationFrom:
          moment(transactionDetails.reservationFrom).isValid() &&
          `${moment.utc(transactionDetails.reservationFrom).format('YYYY-MM-DD')} 00:00:00`,
        reservationTo:
          moment(transactionDetails.reservationTo).isValid() &&
          `${moment.utc(transactionDetails.reservationTo).format('YYYY-MM-DD')} 00:00:00`,
        returnDeliveryDate:
        transactionDetails.returnDelivery && moment(transactionDetails.returnDelivery).isValid() &&
        `${moment.utc(transactionDetails.returnDelivery).format('YYYY-MM-DD')} 00:00:00`,
        checkBeforeReturn:
          moment(props.transactionDetails.checkBeforeReturn).isValid() &&
          `${moment.utc(props.transactionDetails.checkBeforeReturn).format('YYYY-MM-DD')} 00:00:00`,
      };

      deliveryForm = {
        city: transactionDetails.city,
        countryId: transactionDetails.country && transactionDetails.country.id,
        department: transactionDetails.department,
        email: transactionDetails.email,
        firstName: transactionDetails.firstName,
        houseNumber: transactionDetails.houseNumber,
        lastName: transactionDetails.lastName,
        organization: transactionDetails.organization,
        phone: transactionDetails.phone,
        postalCode: transactionDetails.postalCode,
        salutation: transactionDetails.salutation,
        state: transactionDetails.state,
        street: transactionDetails.street,
      };
      opportunities = transactionDetails.transactionOpportunities;
    }
    this.inputFieldRef = {};
    this.state = {
      deliveryModel: deliveryAddressModel,
      deliveryForm,
      transactionInformationFormData,
      savingDates: false,
      savingApproveConfirm: false,
      isHelpForBuildingAndDismantling: false,
      // enableReturnDeliveryAddress: transactionDetails?transactionDetails.enableReturnDeliveryAddress:false, // incase use in future
      enableReturnDeliveryAddress: false,
      defaultDeliveryFormView: false,
      opportunities,
      deletedTransactionOpportunityIds: [],
      isOpportunityIdCrmError: false,
      enteredOpportunityId: {
        value: '',
        error: '',
      },
      changeReturnDeliveryAddressForm,
      changeReturnDeliveryModel: changeReturnDeliveryAddressModel,
      showReturnLoanOrderPopup: false,
      accountIdFromOppInterface: '',
      changeReturenDeliveryDropdownSelected: '',
      showCalenderBlockPopup: false,
      blockTransactionList: [],
      showCampaignId: false,
      showCampaignIdBasedOnReason: false,
      showCampaignIdBasedOnWorkflow: false,
      adaptedLoanReasonForStock: [],
      showReturnDeliveryPopup: false,
      countryProvinces:[]
    };
  }

  componentDidMount() {
    const {
      transactionDetails,
      countries,
      ordertype,
      billing,
      distributionChannel,
      getdivisionList,
      getDeliveryBlockList,
      getCustomeGroup,
      fetchStockAddress,
      activeProvinceCountries
    } = this.props;

    if (!activeProvinceCountries.length){
      this.props.getAllActiveProvinceCountries();
    }
    if (!countries || !countries.length) {
      this.props.fetchMasterData(URLS.countryList, FETCH_COUNTRY_LIST);
    }
    if (transactionDetails && transactionDetails.helpForBuildingAndDismantling) {
      this.setState({ isHelpForBuildingAndDismantling: true });
    }
    const stockInformation = get(transactionDetails, 'transactionKit.stockInformation', '');
    if (stockInformation) {
      fetchStockAddress(stockInformation.id)
    }
    if (transactionDetails&& transactionDetails.stockInformation && transactionDetails.stockInformation.id) {
      fetchStockAddress(transactionDetails.stockInformation.id)
    }
    ordertype();
    billing();
    distributionChannel();
    getdivisionList();
    getDeliveryBlockList();
    getCustomeGroup();
    if(this.checkToShowCampaignId(false, null)){
      this.setState((prev) => ({...prev,  showCampaignId: true}))
    }
    if(transactionDetails){
      this.getAdaptedLoanReasonsForStock()
    }

    if (transactionDetails && transactionDetails.country && transactionDetails.country.id){
      this.setProvincesOfCountry(transactionDetails.country.id);
    }
  }

  fetchDataOnLanguageChange = async () => {
    const { ordertype, billing, distributionChannel, getdivisionList, getDeliveryBlockList, getCustomeGroup } =
      this.props;
    ordertype();
    billing();
    distributionChannel();
    getdivisionList();
    getDeliveryBlockList();
    getCustomeGroup();
    await this.props.fetchMasterData(URLS.countriesLabsLocation, FETCH_LABS_LOCATION_COUNTRIES);
    const deliveryModel = this.state.deliveryModel;
    deliveryModel[10].options = this.props.countries;
    this.setState({ deliveryModel });
  };

  componentDidUpdate(prewProps) {
    const { transactionDetails, editing, language } = this.props;
    if (transactionDetails && prewProps.transactionDetails !== transactionDetails) {
      this.setState({
        opportunities: transactionDetails.transactionOpportunities,
      });
    }

    if (editing !== prewProps.editing) {
      this.handleEnteredOpportunityId({ value: '', error: '' });
    }
    if (language !== prewProps.language) {
      this.fetchDataOnLanguageChange();
    }
  }

  getAdaptedLoanReasonsForStock = async () => {
    const {transactionDetails} = this.props
    let stockId = transactionDetails?.transactionKit?.stockInformation?.id || transactionDetails?.stockInformation?.id
    if(stockId){
      const {fetchAdaptedLoanReasonForStock} = this.props
      const result = await fetchAdaptedLoanReasonForStock(stockId);
      if(result?.success){
        this.setState({adaptedLoanReasonForStock: [...result?.data]})
      }
    }
  }


  setProvincesOfCountry = async (countryId) => {
    if(countryId){
      const {getProvincesOfCountry} = this.props
      const result = await getProvincesOfCountry(countryId);
      if(result?.success){
        this.setState({countryProvinces: [...result?.data]})
      }
    }
  }

  checkTypeError = ({ fieldOne, fieldTwo, fieldOneString, fieldTwoString, data }) => {
    if (data[fieldOne] && data[fieldTwo] && moment(data[fieldOne]).isAfter(moment(data[fieldTwo]))) {
      return strings.hasToBeBeforeOrEqual(fieldOneString, fieldTwoString);
    }
    return '';
  };

  checkError = data => {
    const checkArrays = [
      {
        fieldOne: 'deliveryDate',
        fieldTwo: 'reservationFrom',
        fieldOneString: strings.shipped,
        fieldTwoString: strings.receiptAtTheCustomer,
      },
      {
        fieldOne: 'reservationFrom',
        fieldTwo: 'checkBeforeReturn',
        fieldOneString: strings.receiptAtTheCustomer,
        fieldTwoString: strings.checkBeforeReturn,
      },
      {
        fieldOne: 'checkBeforeReturn',
        fieldTwo: 'reservationTo',
        fieldOneString: strings.checkBeforeReturn,
        fieldTwoString: strings.returnDelivery,
      },
      {
        fieldOne: 'reservationTo',
        fieldTwo: 'returnDeliveryDate',
        fieldOneString: strings.returnDelivery,
        fieldTwoString: strings.receiptInStock,
      },
    ];
    let error = '';
    checkArrays.forEach(item => {
      if (!error) {
        error = this.checkTypeError({ ...item, data: data });
      }
    });
    return error;
  };

  onSaveDatesClick = async () => {
    const {
      transactionsType,
      detailPage,
      bundleLoansWithSameStock,
      openRelatedTransactionsModal,
      toggleActionMessage,
    } = this.props;
    const validateForm = this.milestoneDatesFormRef.getFormData();
    const { formData: newFormData } = validateForm;
    const errorSavingDates = this.checkError(newFormData);
    if (errorSavingDates) {
      toggleActionMessage(true, 'error', errorSavingDates, true);
      scrollToTop(500);
      return;
    }
    const dataToSend = checkDatesToSave(newFormData, this.props.transactionDetails);
    if (Object.keys(dataToSend).length) {
      if (bundleLoansWithSameStock && bundleLoansWithSameStock.length) {
        openRelatedTransactionsModal(dataToSend, 'isActualDates');
      } else {
        this.setState({ savingDates: true }, async () => {
          const response = await this.props.saveTransactionInformationDates(
            dataToSend,
            this.props.transactionDetails.transactionsId,
            detailPage,
            transactionsType,
          );
          if (response && response.message) {
            scrollToTop(500);
            toggleActionMessage(true, 'error', response.message);
            return;
          }
          this.setState({ savingDates: false });
        });
      }
    }
  };

  onFormComponentChange = (formData, fieldName) => {
    if(fieldName !== null){
      let field = fieldName === "returnDeliveryDate" ? "returnDelivery"  : fieldName
      let {formData: newFormData} = formData
      if((newFormData[fieldName] === "" || newFormData[fieldName] === undefined || newFormData[fieldName] == null)
        && (this.props.transactionDetails[field] && moment(this.props.transactionDetails[field]).isValid())

      )
      this.onSaveDatesClick()
    }
  };

  setIsOpportunityIdCrmError = isError => {
    this.setState({ isOpportunityIdCrmError: isError });
  };

  validateOpportunities = () => {

    const { opportunities } = this.state;
    const { transactionDetails } = this.props;
    const loanType = camelCase(transactionDetails.type);
    const isMultyOpportunities = loanType !== 'courseLoan';
    let isValidOpportunities = true;

    if (isMultyOpportunities && this.checkOpportunityMandatory() &&  this.state.opportunities.length === 0
        && !this.checkBusinessUnit() && !this.checkStockCountry()) {
      isValidOpportunities = false;
      this.props.toggleActionMessage(true, 'error', strings.formIsInvalid, true);
      scrollToTop(500)
      this.setState({enteredOpportunityId:{value: '', error:'requiredErrorMessage'}})
      return
    }

    if (isMultyOpportunities && opportunities && opportunities.length) {

      const updatedOpportunities = opportunities.map((item, key) => {
        if (!item.opportunityNumber) {
          isValidOpportunities = false;
          item.error = 'requiredErrorMessage';
        }

        if (item.opportunityNumber.length != 9) {
          isValidOpportunities = false;
          item.error = 'pleaseMindThat';
        }
        if (this.props.user && this.props.user.crmSystemId === 'ngcrm'|| (this.checkBusinessUnit() && this.checkStockCountry())) {
          if (item.opportunityNumber && !regex.alphaNumeric.test(item.opportunityNumber)) {
            isValidOpportunities = false;
            item.error = 'invalidNumberError';
          }
        } else {
          if (item.opportunityNumber && !regex.number.test(item.opportunityNumber)) {
            isValidOpportunities = false;
            item.error = 'invalidNumberError';
          }
        }
        const isSameOpportunities = opportunities.find(
          (opportunity, index) => key !== index && opportunity.opportunityNumber === item.opportunityNumber,
        );
        if (isSameOpportunities) {
          isValidOpportunities = false;
          item.error = 'youCanNotEnter';
        }
        if (this.props.user.crmSystemId === sapCrmNewCient) {
          if (!regex.validCrmCheckUk.test(item.opportunityNumber) && !regex.validCrmCheck.test(item.opportunityNumber)) {
            isValidOpportunities = false;
            item.error = 'correctOpportunityMessage';
          }
        }else if ((this.props.user.crmSystemId !== 'ngcrm' || !this.checkBusinessUnit() && !this.checkStockCountry())) {
          if (!regex.validCrmCheck.test(item.opportunityNumber)) {
            isValidOpportunities = false;
          item.error = 'correctOpportunityMessage';
          }

        }
        return item;
      });

      if (!isValidOpportunities) {
        this.setState({ opportunities: updatedOpportunities });
      }
    }

    return isValidOpportunities;
  };

  onSaveDropdown = async () => {
    const { enteredOpportunityId } = this.state;
    const { bundleLoansWithSameStock, transactionDetails, openRelatedTransactionsModal, updateTransactionStatus } =
      this.props;
    let approveConfirmValue = null;
    let deliveryAddressForm = null;
    let reasonForLoanValue = null;
    let borrowerValue = null;

    if (this.approveConfirmDropdownRef && this.approveConfirmDropdownRef.getSelected) {
      approveConfirmValue = this.approveConfirmDropdownRef.getSelected().selectedSingleValue;
    }
    if (this.reasonForLoanDropdownRef && this.reasonForLoanDropdownRef.getSelected) {
      reasonForLoanValue = this.reasonForLoanDropdownRef.getSelected().selectedSingleValue;
    }
    if (this.deliveryAddressFromRef && this.deliveryAddressFromRef.getFormData) {
      deliveryAddressForm = this.deliveryAddressFromRef.getFormData();
    }
    if (this.borrowerDropdownRef) {
      borrowerValue = this.borrowerDropdownRef.getSelected().selectedSingleValue;
    }
    if (enteredOpportunityId.value) {
      this.handleEnteredOpportunityId({
        error:
          this.props.user.crmSystemId === 'ngcrm' || this.checkBusinessUnit() && this.checkStockCountry()
            ? `${strings.pleaseMindThatFieldNeedsToHave(18)}  ${strings.confirmTheId()}`
            : 'pleaseMindThat',
      });
      return;
    }
    if (approveConfirmValue) {
      this.setState({ savingApproveConfirm: true }, async () => {
        const transactionStatus = approveConfirmValue.value;
        if (bundleLoansWithSameStock && bundleLoansWithSameStock.length && !deliveryAddressForm) {
          openRelatedTransactionsModal(
            {
              transactionStatus,
              showMessage: !deliveryAddressForm,
            },
            'isTransactionStatus',
          );
        } else if (bundleLoansWithSameStock && !bundleLoansWithSameStock.length && transactionDetails.transactionStatus.transactionStatusId !== approveConfirmValue.id) {
          await updateTransactionStatus(transactionDetails.transactionsId, transactionStatus, !deliveryAddressForm, null, this.state.isHelpForBuildingAndDismantling);
        }
        if (deliveryAddressForm) {
          await this.saveEditedData(deliveryAddressForm, reasonForLoanValue, borrowerValue, 'multi', transactionStatus, approveConfirmValue);
        }
        this.setState({ savingApproveConfirm: false });
        if (deliveryAddressForm) {
          this.setState({ deliveryForm: deliveryAddressForm.formData })
        }
      });
    } else if (deliveryAddressForm && deliveryAddressForm.formData) {
      await this.saveEditedData(deliveryAddressForm, reasonForLoanValue, borrowerValue, 'single', approveConfirmValue);
      this.setState({ deliveryForm: deliveryAddressForm.formData });
    }
  };

  saveEditedData = async (deliveryAddressForm, reasonForLoanValue, borrowerValue, type, transactionStatus, approveConfirmValue) => {
    const {
      transactionDetails,
      bundleLoansWithSameStock,
      openRelatedTransactionsModal,
      onEditPress,
      updateTransactionDetails,
      toggleActionMessage,
      countries,
    } = this.props;
    const { opportunities, deletedTransactionOpportunityIds } = this.state;
    const textFields = [
      'name',
      'sapErp',
      'customerOrderNumber',
      'opportunityIdCrm',
      'opportunityStatus',
      'comment',
      'deliveryComment',
      'quantity',
      'loanValue',
      'productId',
      'deliveryNote',
      'returnDeliveryNote',
      'pickUpComment',
      'trackingNumber',
      'crateId',
      'campaignId'
    ];
    const loanType = camelCase(transactionDetails.type);
    const isMultyOpportunities = loanType !== 'courseLoan';
    let valid = true;
    let dataToSend = {};
    let dataToSave = {};
    dataToSend = {
      helpForBuildingAndDismantling: this.state.isHelpForBuildingAndDismantling,
    };
    textFields.forEach(item => {
      if (this.inputFieldRef[item]) {
        const data = this.inputFieldRef[item].getData();
        if (data.error) {
          valid = false;
        }
        dataToSend[item] = data.value ? data.value : '';
        dataToSave[item] = data.value ? data.value : '';
      }
    });

    if (
      !dataToSend.hasOwnProperty('sapErp') &&
      transactionDetails &&
      (transactionDetails.sapErp || transactionDetails.sapCorrelationId)
    ) {
      dataToSend['sapErp'] = transactionDetails.sapErp ? transactionDetails.sapErp : '';
      dataToSave['sapErp'] = transactionDetails.sapErp ? transactionDetails.sapErp : '';
    }

    if (reasonForLoanValue && reasonForLoanValue.reasonForLoanId) {
      dataToSend = {
        ...dataToSend,
        reasonForLoanId: reasonForLoanValue.reasonForLoanId,
      };
    } else if (
      transactionDetails &&
      transactionDetails.reasonForLoan &&
      transactionDetails.reasonForLoan.reasonForLoanId
    ) {
      dataToSend = {
        ...dataToSend,
        reasonForLoanId: transactionDetails.reasonForLoan.reasonForLoanId,
      };
    }
    if (borrowerValue && borrowerValue.accountId) {
      dataToSend = {
        ...dataToSend,
        borrowerId: borrowerValue.accountId,
      };
    }
    if (isMultyOpportunities && opportunities && opportunities.length) {
      dataToSend = {
        ...dataToSend,
        opportunitiesNumber: opportunities.map(item => ({
          transactionOpportunityId: item.transactionOpportunityId,
          opportunityNumber: item.opportunityNumber,
        })),
      };
    } else if (isMultyOpportunities) {
      dataToSend = {
        ...dataToSend,
        opportunitiesNumber: null,
      };
    }
    if (isMultyOpportunities && deletedTransactionOpportunityIds && deletedTransactionOpportunityIds.length) {
      dataToSend = {
        ...dataToSend,
        deletedTransactionOpportunityIds,
      };
    } else if (isMultyOpportunities) {
      dataToSend = {
        ...dataToSend,
        deletedTransactionOpportunityIds: [],
      };
    }
    Object.keys(deliveryAddressForm.formData).forEach(key => {
      if (!key.includes('Error')) {
        if (!deliveryAddressForm.isFormValid) {
          valid = false;
        }
        dataToSend[key] = deliveryAddressForm.formData[key];
        dataToSave[key] = deliveryAddressForm.formData[key];
      }
    });
    if (dataToSend.countryId) {
      const country = countries.find(item => item.id === dataToSend.countryId);
      dataToSave = { ...dataToSave, country };
    }
    if (reasonForLoanValue) {
      dataToSave = { ...dataToSave, reasonForLoanValue };
    }
    if (valid) {
      if (bundleLoansWithSameStock && bundleLoansWithSameStock.length) {
        if (transactionStatus && approveConfirmValue && approveConfirmValue.id !== transactionDetails.transactionStatus.transactionStatusId) {
          openRelatedTransactionsModal(
            {
              transactionStatus,
              showMessage: !deliveryAddressForm,
              dataToSend,
              dataToSave,
            },
            'isUpdatingTransactionWithTransactionStatus',
          );
        } else {
          openRelatedTransactionsModal(
            {
              dataToSend,
              dataToSave,
            },
            'isTransactionDetails',
          );
        }
      } else {
        this.setState({ savingApproveConfirm: true }, async () => {
          const res = await updateTransactionDetails(transactionDetails.transactionsId, dataToSend, dataToSave);
          if (res && res.error && res.error.response && res.error.response.data && res.error.response.data.length >0 && res.error.response.data[0].name) {
            this.setState({showCalenderBlockPopup: true, blockTransactionList: res.error.response.data, savingApproveConfirm: false,});
              $('#calendar-block').modal('show');
              return;
          }
          if (res.success) {
            onEditPress();
          }
          this.setState({
            savingApproveConfirm: false,
            deletedTransactionOpportunityIds: [],
          });
        });
      }
    } else {
      
      if (deliveryAddressForm.formData.stateError === true) {
        toggleActionMessage(true, 'error', 'stateErrorMesage');
        scrollToTop(500);
      }else{
        toggleActionMessage(true, 'error', 'formIsInvalid');
        scrollToTop(500);
      }      
    }
  };

  onShowAllMessagesPress = () => {
    this.props.setCurrentTab(2);
  };

  onError = field => value => {};

  onChange = field => value => {};

  handleFormClick = (e, item) => {
    // Please do not comment/remove this.
    // This is used to open returns good booking modal when date picker for return goods booking is clicked instead of opening datepicker
    const { transactionDetails } = this.props;
    if (
      (item.label === 'returnGoodsBooking' ||
        item.label === 'receiptInStock' ||
        item.label === 'Received next customer / at stock') &&
      transactionDetails.showReturnGoodsBooking
    ) {
      $('#returnGoodsBookingModal').modal('show');
    }
  };

  onSaveChangeReturnDeliveryAdress = async () => {
     let changeDeliveryAddressForm = null;
     let addressType = null
    if (this.changeReturnDeliveryAddressFromRef && this.changeReturnDeliveryAddressFromRef.getFormData) {
      changeDeliveryAddressForm = this.changeReturnDeliveryAddressFromRef.getFormData();
    }
    if (this.selectedAddressRef && this.selectedAddressRef.getSelected) {
        addressType = this.selectedAddressRef.getSelected().selectedSingleValue
    }else{
      addressType = this.state.changeReturenDeliveryDropdownSelected
    }
    if (changeDeliveryAddressForm.isFormValid) {
      let dataToSend = {};
      dataToSend.firstName = changeDeliveryAddressForm.formData.firstName;
      dataToSend.lastName = changeDeliveryAddressForm.formData.lastName;
      dataToSend.salutation = changeDeliveryAddressForm.formData.salutation;
      dataToSend.organization = changeDeliveryAddressForm.formData.organization;
      dataToSend.department = changeDeliveryAddressForm.formData.department;
      dataToSend.street = changeDeliveryAddressForm.formData.street;
      dataToSend.houseNumber = changeDeliveryAddressForm.formData.houseNumber;
      dataToSend.postalCode = changeDeliveryAddressForm.formData.postalCode;
      dataToSend.city = changeDeliveryAddressForm.formData.city;
      dataToSend.countryId = changeDeliveryAddressForm.formData.countryId;
      dataToSend.phone = changeDeliveryAddressForm.formData.phone;
      dataToSend.state = changeDeliveryAddressForm.formData.state;
      dataToSend.email = changeDeliveryAddressForm.formData.email;
      dataToSend.addressType = addressType.addressType;
      const { saveChangeRetuenDeliverAdress, transactionsType, detailPage } = this.props;

      let response = await saveChangeRetuenDeliverAdress(
        dataToSend,
        this.props.transactionDetails.transactionsId,
        detailPage,
        transactionsType,
      );
      if (response && response == 200) {
        this.setState(prevState => ({
          defaultDeliveryFormView: !prevState.defaultDeliveryFormView,
        }));
        this.setState(prevState => ({ changeReturnDeliveryAddressForm: changeDeliveryAddressForm.formData }));
        this.setState(prevState => ({ enableReturnDeliveryAddress: !prevState.enableReturnDeliveryAddress }));
      }
    } else {
      this.props.toggleActionMessage(true, 'error', 'formIsInvalid');
      scrollToTop(500);
    }
  };

  handleHelpForBuildingAndDismantling = () => {
    this.setState(prevState => ({ isHelpForBuildingAndDismantling: !prevState.isHelpForBuildingAndDismantling }));
  };

  openAdditionalSingleLoanPopup = () => {
    $('#additional-singleloan-modal').modal('show');
  }

  renderKitLoanName() {
    const { transactionDetails, workFlowQuestions, detailPage, transactionsType, user } = this.props;
    const isAdditionalSingleLoanActive = workFlowQuestions && workFlowQuestions.stockWorkflowDTOS &&  workFlowQuestions.stockWorkflowDTOS[1] && workFlowQuestions.stockWorkflowDTOS[1].stockWorkflowSettingDTO && workFlowQuestions.stockWorkflowDTOS[1].stockWorkflowSettingDTO[0].categoryAnswer == 'yes';
    const stockId = get(transactionDetails, 'transactionKit.stockInformation.id', '');
    const showAddSingleLoanBtn = transactionDetails && transactionDetails.connectingSingleLoan === null && isAdditionalSingleLoanActive && transactionDetails.type == 'kit loan' && this.hasPermissionToEditMaterialNumber(user,stockId);
    let kitName = '';
    let ibase = '';
    let serialNumber = '';
    let kitId = '';
    if (transactionDetails.transactionKit && transactionDetails.transactionKit.kitInformation) {
      kitName = transactionDetails.transactionKit.kitInformation.kitName;
      kitId = transactionDetails.transactionKit.kitInformation.kitInformationId;
      ibase = transactionDetails.transactionKit.kitInformation.ibase;
      serialNumber = transactionDetails.transactionKit.kitInformation.serialNumber;
    }
    const editMaterialNumberPermissionForKitloan = this.hasPermissionToEditMaterialNumber(user, stockId);
    return (
      <>
        {kitName && (
          <div className=''>
            <div className='row small-height'>
              <div className='col-6 font-bold'>{strings.borrowedKit}</div>
              <div className='col-6 clearfix'>
                <NavLink to={`/kit_details/${kitId}`}>{kitName}</NavLink>
              </div>
            <div className='col-6'></div>
            {showAddSingleLoanBtn &&
            <div style={{height:'60px'}} className='col-6 p-0'>
              <Button
                 bgFill={false}
                 value={strings.addSingleLoan}
                 iconName='plus'
                 onClick={this.openAdditionalSingleLoanPopup}
                 newVersionUi={true}
                 styleClass="button-without-border"
              />

              <AdditionalSingleLoanPopup
                id ='additional-singleloan-modal'
                createSingleLoanUsingExistingKitLoan={this.props.createSingleLoanUsingExistingKitLoan}
                transactionDetails={transactionDetails}
                transactionsType= {transactionsType}
                detailPage={detailPage}
                editMaterialNumberPermission={editMaterialNumberPermissionForKitloan}
              />
            </div>
            }
            </div>
          </div>
        )}
        {ibase && (
          <div className='row small-height'>
            <div className='col-6 font-bold'>{strings.crmiBase}</div>
            <div className='col-6'>{ibase}</div>
          </div>
        )}
        {serialNumber && (
          <div className='row small-height'>
            <div className='col-6 font-bold'>{strings.serialNumber}</div>
            <div className='col-6'>{serialNumber}</div>
          </div>
        )}
      </>
    );
  }

  renderCampignId = () => {
    const {editing} = this.props
    const {showCampaignId} = this.state
    if(!showCampaignId){
      return null;
    }
    const {transactionDetails} = this.props
    return(
      <div className='row mb-3'>
        <div className='col-6 font-bold'>{strings.campaignId}</div>
        <div className='col-6'>
          {editing ? (
            <InputGroup
              label={strings.campaignId}
              ref={ref => {
                this.inputFieldRef['campaignId'] = ref
              }}
              type={'inputgroup'}
              placeholder={''}
              value={transactionDetails?.campaignId ? transactionDetails?.campaignId: ""}
              validators={[
                { check: Validators.campaignIdValidation, message: 'campaignIdErrorMessage' },
                { check: Validators.campaignIdShouldBeNumeric, message: 'campaignShouldBeNumeric' }
              ]}
              doValidateForm
              isRequired={false}
              onError={this.onError('campaignIdNote')}
              onChange={this.onChange('campaignIdNote')}
              extraProps={{ maxLength: 10 }}
              inputGroupText= "C -"
              isAppend = { false }
              validateOnblur = {true}
            />
            ) :(
              <>
                {transactionDetails.campaignId ? `C-${transactionDetails.campaignId}` : "-"}
              </>
            )
          }
      </div>
    </div>
    )
  }

  editMaterialNumber = () => {
    $('#edit-materialNumber-modal').modal('show');
  };

  renderSingleLoanName() {
    const { components, transactionMaterialNumber } = this.props.transactionDetails;
    const stockId = get(this.props.transactionDetails, 'stockInformation.id', '');
    const { transactionsType, detailPage, user } = this.props;
    const editMaterialNumberPermission = this.hasPermissionToEditMaterialNumber(user, stockId);
    return (
      <>
      <div className='row'>
        <div className='col-6 font-bold'>{strings.materialNumber}</div>
        <div className='col-6'>
          {transactionMaterialNumber && transactionMaterialNumber.length > 0 && (
            <>
              {transactionMaterialNumber.map(
                (item, index) =>
                  index < 3 && (
                    <p className='components-text mb-2 text-break'>{`${item.materialNumber}  ${
                      item.description ? `${item.description}` : ''
                    }`}</p>
                  ),
              )}
            </>
          )}
          <div>
            <Button
              isGhost={true}
              bgFill={false}
              styleClass='show-all-messages-button'
              value={transactionMaterialNumber.length > 0 ? strings.more : strings.edit}
              onClick={this.editMaterialNumber}
            />
          </div>
          <EditMaterialNumberAndDescriptionPopup
            id='edit-materialNumber-modal'
            transactionMaterialNumber={transactionMaterialNumber}
            saveMaterialNumberAndDescription={this.props.saveMaterialNumberAndDescription}
            transactionDetails={this.props.transactionDetails}
            editMaterialNumberPermission={editMaterialNumberPermission}
            detailPage={detailPage}
            transactionsType={transactionsType}
          />
        </div>
      </div>
      <div className='row'>
        {
          components != null &&
          <>
            <div className='col-6 font-bold'>{strings.components}</div>
            <div className='col-6'>
              <p className='components-text mb-2 text-break p-2' style={{height:'150px',overflow:'scroll',border:'lightgrey 1px solid',backgroundColor:'lightgrey'}}> {components}</p>
            </div>
          </>
        }

      </div>
      </>
    );
  }

  renderReturnGoodBooking(returnGoodsBookingDetail) {
    return (
      <div className='return-goods-container mt-4'>
        <div className='d-flex flex-direction-row justify-content-between align-items-center'>
          <span className='transaction-details-title'>{strings.returnGoodsBooking}</span>
          <div
            className='btn-no-border btn-lighter mt-0 mr-3 pl-0 cursor-pointer'
            data-toggle='modal'
            data-target='#returnGoodsBookingModal'
            data-test='transaction-edit-return-goods'
            data-backdrop='static'
            data-keyboard='false'
          >
            <Icon name='notification' width={24} height={24} fill='#0088d0' className='plus-icon' />
            <span className='ml-2 mt-2 color-blue'>{strings.edit}</span>
          </div>
        </div>
        <table className='table transaction-details-table table-borderless'>
          <tbody>
            <tr className='smaller-height'>
              <td>{strings.editor}</td>
              <td>{returnGoodsBookingDetail.userName}</td>
            </tr>
            <tr className='smaller-height'>
              <td>{strings.receiptInStock}</td>
              <td>{getDateInUTCFormat('DD.MM.YYYY', returnGoodsBookingDetail.date)}</td>
            </tr>
            <tr className='smaller-height'>
              <td>{strings.storageLocation}</td>
              <td>{returnGoodsBookingDetail.storageLocation}</td>
            </tr>
            <tr className='smaller-height'>
              <td>{strings.completeness}</td>
              <td>{returnGoodsBookingDetail.completeness === 'true' ? strings.yes : strings.no}</td>
            </tr>
            <tr className='smaller-height'>
              <td>{strings.conditionReturnGoodBooking}</td>
              <td>{returnGoodsBookingDetail.conditionName}</td>
            </tr>
            <tr className='smaller-height'>
              <td>{strings.commentReturnGoodBooking}</td>
              <td>{returnGoodsBookingDetail.comment}</td>
            </tr>
            <tr className='smaller-height'>
              <td>{strings.deliveryNoteNumber}</td>
              <td>{returnGoodsBookingDetail.deliveryNoteNumber || '-'}</td>
            </tr>
            <tr className='smaller-height'>
              <td>{strings.returnBookingDate}</td>
              <td>
                {returnGoodsBookingDetail.returnBookingDate
                  ? getDateInUTCFormat('DD.MM.YYYY', returnGoodsBookingDetail.returnBookingDate)
                  : '-'}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  handleConfirmConsignment = value => {
    const { confirmConsignment, transactionDetails, transactionsType, detailPage } = this.props;
    if (value && transactionDetails.consignment === null) {
      confirmConsignment(transactionDetails.transactionsId, detailPage, transactionsType);
    }
  };

  checkRolesForPermissionOnConfirmConsigment = () => {
    const { user } = this.props;
    const { stockInformation } = this.props.transactionDetails;

    let hasPermission = false;

    if (user.admin) {
      hasPermission = true;
    }

    if (stockInformation && stockInformation.userRole && stockInformation.userRole.length) {
      const { userRole } = stockInformation;
      const { role } = userRole[0].role;
      if (role === 'Stock Controller' || role === 'Dispatcher' || role === 'Outbound staff') {
        hasPermission = true;
      }
    }
    return hasPermission;
  };
  hasPermissionToEditProductId = (user, stockId) => {
    let isPermission = false;
    if (user.admin) {
      return true;
    }
    if (user && user.stockRoles && user.stockRoles.length && stockId) {
      user.stockRoles.forEach(role => {
        if (
          (role.roleName.toLowerCase() === 'dispatcher' || role.roleName.toLowerCase() === 'stock controller') &&
          stockId === role.stockId
        ) {
          isPermission = true;
        }
      });
    }
    return isPermission;
  };

  isHavingRole = (roles, stockId) => {
    const { user } = this.props;
    if (user && user.stockRoles && user.stockRoles.length > 0) {
      return roles.some(role => {
        return user.stockRoles.some(stockRole => stockRole.roleName === role && stockId === stockRole.stockId);
      });
    }
    return false;
  };

  canEditOwnTransaction = (roles, stockId) =>{
    const { user, transactionDetails } = this.props;
    if (user && user.stockRoles && user.stockRoles.length > 0) {
      return roles.some(role => {
        return user.stockRoles.some(stockRole => stockRole.roleName === role && stockId === stockRole.stockId)
        && user.accountId === transactionDetails.user.accountId;
      });
    }
    return false;
  }

  checkPermissionsForTransactionStatus(user, stockInformation) {
    let isPermission = true;
    if (user && user.stockRoles && user.stockRoles.length) {
      let stockId = stockInformation && stockInformation.id;
      if (stockId) {
        user.stockRoles.forEach(role => {
          if (
            (role.roleName.toLowerCase() === 'borrower' || role.roleName.toLowerCase() === 'external borrower') &&
            stockId === role.stockId
          ) {
            isPermission = false;
          }
        });
      }
    }
    return isPermission;
  }

  hasPermissionToEditLoanValue = (user, stockId) => {
    let isPermission = false;
    if (user.admin) {
      return true;
    }
    if (user && user.stockRoles && user.stockRoles.length && stockId) {
      user.stockRoles.forEach(role => {
        if (
          (role.roleName.toLowerCase() === 'dispatcher' || role.roleName.toLowerCase() === 'stock controller') &&
          stockId === role.stockId
        ) {
          isPermission = true;
        }
      });
    }
    return isPermission;
  };

  hasPermissionToChangeReturnDeliveryAdress = (user, stockId) => {
    let isPermission = false;
    if (user.admin) {
      return true;
    }
    if (user && user.stockRoles && user.stockRoles.length && stockId) {
      user.stockRoles.forEach(role => {
        if (
          (role.roleName.toLowerCase() === 'dispatcher' || role.roleName.toLowerCase() === 'stock controller') &&
          stockId === role.stockId
        ) {
          isPermission = true;
        }
      });
    }
    return isPermission;
  };
  hasPermissionToEditMaterialNumber = (user, stockId) => {
    let isPermission = false;
    if (user.admin) {
      return true;
    }
    if (user && user.stockRoles && user.stockRoles.length && stockId) {
      user.stockRoles.forEach(role => {
        if (
          (role.roleName.toLowerCase() === 'dispatcher' || role.roleName.toLowerCase() === 'stock controller' ||  role.roleName.toLowerCase() === 'reconditioning'|| role.roleName.toLowerCase() === 'inbound staff') &&
          stockId === role.stockId
        ) {
          isPermission = true;
        }
      });
    }
    return isPermission;
  };

  handleChangeReturnDeleveryAdress = async boolValue => {

    const { erpStatus } = this.props;

    let response = await erpStatus(this.props.transactionDetails.transactionsId, boolValue ? 1 : 0);
    if (response && response == 204) {
      this.setDefaultAddress();
      this.setState(prevState => ({
        enableReturnDeliveryAddress: !prevState.enableReturnDeliveryAddress,
        defaultDeliveryFormView: !prevState.enableReturnDeliveryAddress,
      }));
    }
  };

  setOpportunity = value => {
    const { opportunities } = this.state;
    const updatedOpportunities = [
      ...opportunities,
      {
        opportunityNumber: value,
        transactionOpportunityId: 0,
      },
    ];
    this.setState({ opportunities: updatedOpportunities });
  };

  removeOpportunity = key => {
    const { opportunities, deletedTransactionOpportunityIds } = this.state;
    const updatedOpportunities = opportunities.filter((item, index) => index !== key);
    const deletedOpportunityId = opportunities.find((item, index) => index === key).transactionOpportunityId;
    let arrayIds = [];
    if (deletedOpportunityId) {
      arrayIds = [...deletedTransactionOpportunityIds, deletedOpportunityId];
    }
    this.setState({ opportunities: updatedOpportunities, deletedTransactionOpportunityIds: arrayIds });
  };

  updateOpportunities = (value, key) => {
    const { opportunities } = this.state;
    const updatedOpportunities = [...opportunities];
    updatedOpportunities[key].opportunityNumber = value;
    updatedOpportunities[key].error = '';
    this.setState({ opportunities: updatedOpportunities });
  };

  handleEnteredOpportunityId = value => {
    this.setState(prevState => ({
      enteredOpportunityId: { ...prevState.enteredOpportunityId, ...value },
    }));
  };

   checkOpportunityMandatory = () =>{
    const {reasonForLoan} = this.props.transactionDetails;
   const warehouseCountry = get(this.props.transactionDetails, 'transactionKit.stockInformation.country.shortName', '');
    const code = ['Demonstration in the Showroom', 'Demonstration at the customer`s']
    const reasonForLoanValue = this.reasonForLoanDropdownRef && this.reasonForLoanDropdownRef.getSelected().selectedSingleValue;
    const checkIsZeissEmail = this.props.user && this.props.user.email && this.props.user.email.split('@')

    const showLabel = code.includes(reasonForLoanValue && reasonForLoanValue.name ? reasonForLoanValue.name : reasonForLoan && reasonForLoan.name)
                       && checkIsZeissEmail[1].includes('zeiss.com') && warehouseCountry !== 'ZA';
    return showLabel;
  }
  getOpportunityForm(loanType) {
    const { enteredOpportunityId, opportunities, isOpportunityIdCrmError } = this.state;
    const { transactionDetails } = this.props;
    const zeissUser = this.checkOpportunityMandatory();

    if (loanType === 'courseLoan') {
      return (
        <InputField
          ref={ref => {
            this.inputFieldRef['opportunityIdCrm'] = ref;
          }}
          dataTest={'information-opportunityIdCrm'}
          label={strings.crmOpportunityId}
          type={'text'}
          value={transactionDetails.opportunityIdCrm}
          placeholder={''}
          validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
          doValidateForm
          isRequired={false}
          resetValidation={false}
          onError={this.onError('opportunityIdCrm')}
          onChange={this.onChange('opportunityIdCrm')}
        />
      );
    }
    return (
      <OpportunityForm
        setOpportunity={this.setOpportunity}
        removeOpportunity={this.removeOpportunity}
        updateOpportunities={this.updateOpportunities}
        opportunities={opportunities}
        isEditableField
        isRequired={zeissUser}
        setIsOpportunityIdCrmError={this.setIsOpportunityIdCrmError}
        isOpportunityIdCrmError={isOpportunityIdCrmError}
        state={enteredOpportunityId}
        handleEnteredOpportunityId={this.handleEnteredOpportunityId}
        isBusinessUnitMed={this.checkBusinessUnit}
        usCountryStock={this.checkStockCountry}
        user={this.props.user}
      />
    );
  }

  hasERPActiavted = (transactionDetails, loanType) => {
    const workFlowCategoryAnswers =
      loanType === 'kit loan' || loanType === 'kitLoan'
        ? get(transactionDetails, 'transactionKit.stockInformation.workflowCategoryAnswers', '')
        : get(transactionDetails, 'stockInformation.workflowCategoryAnswers', '');
    const workflow = workFlowCategoryAnswers.find(
      item => item.workflowCategoryQuestions.workflowCategoryQuestionsId === 21,
    );
    return workflow && workflow.value === 'yes';
  };

  getAccounIdwithOpportunityInterface = async (transactionDetails) => {
    const { getUserIdFromBorrowerMail, getCustomerIDWithOpportunityID } = this.props;
    const { data } = await getUserIdFromBorrowerMail(transactionDetails && transactionDetails.borrower && transactionDetails.borrower.email);
    const opportunityIdtoSend = transactionDetails && transactionDetails.transactionOpportunities && transactionDetails.transactionOpportunities.length ===1 &&  transactionDetails.transactionOpportunities[0].opportunityNumber ;
    const dataForAccountId = data && opportunityIdtoSend && await getCustomerIDWithOpportunityID(opportunityIdtoSend, data.userId);
    if (dataForAccountId && dataForAccountId.data && dataForAccountId.data.opportunities && dataForAccountId.data.opportunities[0].customerId){
      this.setState({accountIdFromOppInterface: dataForAccountId.data.opportunities[0].customerId});
    }
    else {
      this.setState({accountIdFromOppInterface: ''});
    }
  }

  createLoanOrder = async (loanType, transactionDetails) => {
    const { checkActiveLoanorder } = this.props;
    const kitInformation = get(transactionDetails, 'transactionKit.kitInformation', '');
    const divisionsMissing = kitInformation && !!kitInformation.divisions;
    const itemNumberMissing = kitInformation && !!kitInformation.itemNumber;
    const productIdMissing = transactionDetails && transactionDetails.productId;
    const kitId = kitInformation && kitInformation.kitInformationId;
    const { res } = await checkActiveLoanorder(kitId);
    transactionDetails && await this.getAccounIdwithOpportunityInterface(transactionDetails);
    if ((loanType === 'kit loan' || loanType === 'kitLoan') && (!divisionsMissing || !itemNumberMissing)) {
      this.props.toggleActionMessage(true, 'error', 'divisionOrItemNumberMissing');
      scrollToTop(500);
      return;
    } else if (loanType === 'courseLoan' && !productIdMissing) {
      this.props.toggleActionMessage(true, 'error', 'productIdMissing');
      scrollToTop(500);
      return;
    } else if ((loanType === 'kit loan' || loanType === 'kitLoan') && (res.data && ((res.data.sapErp !== '' && res.data.sapErp !== null) && res.data.sapReturnLoanNumber == null))) {
      const link = (
        <span>
          {strings.activeLoanOrderMessage1}
          <NavLink to={`/kit_details/${kitId}`}> {kitId} </NavLink>
          {strings.activeLoanOrderMessage2}
        </span>
      );
      this.props.toggleActionMessage(true, 'error', link);
      scrollToTop(500);
      return;
    }
    $('#load-order-modal').modal('show');
  };
  viewDetails = () =>{
    $('#load-order-modal').modal('show');
  }
  returnLoanOrder = async (transactionId, loanType, detailPage, transactionsType) => {
    const { returnSapErpLoanOrderForTransaction, transactionDetails } = this.props;
    const kitInformation = get(transactionDetails, 'transactionKit.kitInformation', '');
    const divisionsMissing = kitInformation && !!kitInformation.divisions;
    const itemNumberMissing = kitInformation && !!kitInformation.itemNumber;
    transactionDetails && await this.getAccounIdwithOpportunityInterface(transactionDetails);
    if ((loanType === 'kit loan' || loanType === 'kitLoan') && (!divisionsMissing || !itemNumberMissing)) {
      this.props.toggleActionMessage(true, 'error', 'returnLoanOrderDivisonMissing');
      scrollToTop(500);
      return;
    } else if (!transactionDetails.sapLoanOrderStatus && !transactionDetails.sapCorrelationId) {
      this.setState({ showReturnLoanOrderPopup: true });
      $('#load-order-modal').modal('show');
    } else {
      returnSapErpLoanOrderForTransaction(
        transactionId,
        transactionDetails && transactionDetails.transactionMaterialNumber,
        detailPage,
        transactionsType,
      );
    }
  };

  validateLoanOrder = loanOrderNumber => {
    if (
      loanOrderNumber &&
      loanOrderNumber.length == 10 &&
      loanOrderNumber.startsWith('1') &&
      /^\d+$/.test(loanOrderNumber)
    ) {
      return true;
    }
    return false;
  };

  checkBusinessUnit = () => {
    const { transactionDetails } = this.props;
    const businessUnits = ['MED', 'MED-OPT', 'MED-SUR'];
    const singleAndCourseLoanBusinessUnit = transactionDetails && transactionDetails.stockInformation;
    const kitLoanBusinessUnit =
      transactionDetails && transactionDetails.transactionKit && transactionDetails.transactionKit;
    const showCustomerGroupField =
      kitLoanBusinessUnit && kitLoanBusinessUnit.stockInformation
        ? businessUnits.includes(kitLoanBusinessUnit.stockInformation.businessUnit.name)
        : businessUnits.includes(singleAndCourseLoanBusinessUnit && singleAndCourseLoanBusinessUnit.businessUnit.name);
    return showCustomerGroupField;
  };

  checkStockCountry = () => {
    const { transactionDetails } = this.props;
    const country = ['United States'];
    const singleAndCouserLoanCounty = transactionDetails && transactionDetails.stockInformation;
    const kitLoanCountry =
      transactionDetails && transactionDetails.transactionKit && transactionDetails.transactionKit;
    const UsCountry =
      kitLoanCountry && kitLoanCountry.stockInformation
        ? country.includes(kitLoanCountry.stockInformation.country.name)
        : country.includes(singleAndCouserLoanCounty && singleAndCouserLoanCounty.country.name);
    return UsCountry;
  };

  isAdditionalProcessActiavted = transactionDetails => {
    if (transactionDetails && (transactionDetails.type === 'kit loan' || transactionDetails.type === 'kitLoan')) {
      const workFlowCategoryAnswers = get(
        transactionDetails,
        'transactionKit.stockInformation.workflowCategoryAnswers',
        '',
      );
      const workflow = workFlowCategoryAnswers.find(
        item => item.workflowCategoryQuestions.workflowCategoryQuestionsId === 19,
      );
      return workflow && workflow.value === 'yes';
    }
  };
setDefaultAddress= () =>{
  const {addressList,transactionDetails} = this.props;
  let changeReturnDeliveryForm1 =null
  const countryId = transactionDetails && transactionDetails.type === 'kit loan' || transactionDetails.type === 'kitLoan'
        ? get(transactionDetails, 'transactionKit.stockInformation.country.id', '')
        : get(transactionDetails, 'stockInformation.country.id', '');
  if (addressList && addressList.length > 0 && transactionDetails && transactionDetails.tranReturnDeliveryAddress === null) {
    changeReturnDeliveryForm1 = {
          city: addressList[0].city,
          countryId: countryId,
          department: addressList[0].department,
          email: addressList[0].email,
          firstName: addressList[0].firstName,
          houseNumber: addressList[0].houseNumber,
          lastName: addressList[0].lastName,
          organization: addressList[0].organization,
          phone: addressList[0].phone,
          postalCode: addressList[0].postalCode,
          salutation: addressList[0].salutation,
          state: addressList[0].stateName,
          street: addressList[0].street,
    }
    this.setState(prevState =>({changeReturnDeliveryAddressForm:changeReturnDeliveryForm1}));
  } else{
    changeReturnDeliveryForm1 = {
      city: transactionDetails.tranReturnDeliveryAddress.city,
       countryId: transactionDetails.tranReturnDeliveryAddress.country && transactionDetails.tranReturnDeliveryAddress.country.id,
      department: transactionDetails.tranReturnDeliveryAddress.department,
      email: transactionDetails.tranReturnDeliveryAddress.email,
      firstName: transactionDetails.tranReturnDeliveryAddress.firstName,
      houseNumber: transactionDetails.tranReturnDeliveryAddress.houseNumber,
      lastName: transactionDetails.tranReturnDeliveryAddress.lastName,
      organization: transactionDetails.tranReturnDeliveryAddress.organization,
      phone: transactionDetails.tranReturnDeliveryAddress.phone,
      postalCode: transactionDetails.tranReturnDeliveryAddress.postalCode,
      salutation: transactionDetails.tranReturnDeliveryAddress.salutation,
      state: transactionDetails.tranReturnDeliveryAddress.state,
      street: transactionDetails.tranReturnDeliveryAddress.street,
}
this.setState(prevState =>({changeReturnDeliveryAddressForm:changeReturnDeliveryForm1}));
  }

}

  handleDropdownChangeSelected = (idKey, field, value) =>{
    const {changeReturenDeliveryDropdownSelected, updateAddressList} = this.state
    if (idKey !== changeReturenDeliveryDropdownSelected) {
      this.setState({changeReturenDeliveryDropdownSelected:idKey})
      this.setOnChangedDropdownAddress();
    }
    this.checkOpportunityMandatory();
    const showCampignId = this.checkToShowCampaignId(true, value)
    this.setState((prev) => ({...prev,  showCampaignId: showCampignId}))
  }

  checkToShowCampaignId = (reasonForLoanChanged = false, reasonForLoanValue = null) => {
    const {transactionDetails} = this.props
    const loanType = transactionDetails?.type

    let isWorkflowEnabled = false;
    const workFlowCategoryAnswers =
      loanType === 'kit loan' || loanType === 'kitLoan'
        ? get(transactionDetails, 'transactionKit.stockInformation.workflowCategoryAnswers', '')
        : get(transactionDetails, 'stockInformation.workflowCategoryAnswers', '');

    const value = workFlowCategoryAnswers && workFlowCategoryAnswers.forEach((workflow, index) => {
      const {value} = workflow;
      if(workflow && workflow?.workflowCategoryQuestions?.workflowCategoryQuestionsId === 37){
        if(value === "yes"){
          isWorkflowEnabled = isWorkflowEnabled || true
        }
      }
    })

    const laonTypeTrans = transactionDetails && camelCase(transactionDetails.type);

    let isValidLoanType = laonTypeTrans === LOAN_TYPES.KIT_LOAN || laonTypeTrans === LOAN_TYPES.COURSE_LOAN
    let haveValidLoanReason = false;
    if(reasonForLoanChanged === false && transactionDetails && transactionDetails?.reasonForLoan){
        const {reasonForLoanId} = transactionDetails.reasonForLoan
        const allowedReasonForLoanIds = [12, 1, 13, 3]
        if(allowedReasonForLoanIds.indexOf(reasonForLoanId) != -1){
          haveValidLoanReason = true
        }
    }
    if(reasonForLoanChanged){
      const {reasonForLoanId, fixedCode} = reasonForLoanValue;
        const allowedReasonForLoanIds = [12, 1, 13, 3]
        if(allowedReasonForLoanIds.indexOf(reasonForLoanId) != -1){
          haveValidLoanReason = true
        }
    }
    //Bascially if all three conditions are true we can say that is valid to show
    return isWorkflowEnabled && isValidLoanType && haveValidLoanReason;
  }

  setOnChangedDropdownAddress =() =>{
    const {transactionDetails, addressList} = this.props
    const countryId = transactionDetails && transactionDetails.type === 'kit loan' || transactionDetails.type === 'kitLoan'
        ? get(transactionDetails, 'transactionKit.stockInformation.country.id', '')
        : get(transactionDetails, 'stockInformation.country.id', '');
    if (this.selectedAddressRef && this.selectedAddressRef.getSelected) {
      const value = this.selectedAddressRef && this.selectedAddressRef.getSelected().selectedSingleValue;
      let formData = null;
        if (value) {
          formData = {
            city: value.city,
            countryId: countryId,
            department: value.department,
            email: value.email,
            firstName: value.firstName,
            houseNumber: value.houseNumber,
            lastName: value.lastName,
            organization: value.organization,
            phone: value.phone,
            postalCode: value.postalCode,
            salutation: value.salutation,
            state: value.stateName,
            street: value.street,
      }
      this.setState(prevState =>({changeReturnDeliveryAddressForm:formData}));
        }
        return
    }
  }
  checkOpportunityRequired = () => {
    const {user} = this.props
    const checkIsZeissEmail = user && user.email && user.email.split('@')
    const zeissUser = checkIsZeissEmail && checkIsZeissEmail[1].includes('zeiss.com');

    return zeissUser;
  }

  checkForMedUs = () => {
    const {transactionDetails} = this.props;
    if (transactionDetails) {
      const stockInformation = transactionDetails.type === 'kit loan' || transactionDetails.type === 'kitLoan'
      ? get(transactionDetails, 'transactionKit.stockInformation', '')
      : get(transactionDetails, 'stockInformation', '');
      const isMed = stockInformation && stockInformation.businessUnit.designation === "MED" && stockInformation.country.shortName === 'US';
      return transactionDetails.type === 'course loan' ? false:isMed;
    }

  }

  filterReasonForLoan = () => {
    const {reasonForLoan} = this.props;
    const {transactionDetails} = this.props
    const {adaptedLoanReasonForStock} = this.state
    if (adaptedLoanReasonForStock && adaptedLoanReasonForStock.length ) {
      if (this.checkForMedUs()) {
        return adaptedLoanReasonForStock.filter(item => item.fixedCode !== 'l' && item.fixedCode !== 'k' && item.fixedCode !== 'e' && item.fixedCode !== 'f' && item.fixedCode !== 'a');
      }else{
        return adaptedLoanReasonForStock.filter(item => item.fixedCode !== 'l' && item.fixedCode !== 'm' && item.fixedCode !== 'n' && item.fixedCode !== 'o');
      }
    }

    if (reasonForLoan && reasonForLoan.length ) {
      if (this.checkForMedUs()) {
        return reasonForLoan.filter(item => item.fixedCode !== 'l' && item.fixedCode !== 'k' && item.fixedCode !== 'e' && item.fixedCode !== 'f' && item.fixedCode !== 'a');
      }else{
        return reasonForLoan.filter(item => item.fixedCode !== 'l' && item.fixedCode !== 'm' && item.fixedCode !== 'n' && item.fixedCode !== 'o');
      }
    }

  }

  checkForWorkFlowActivation = () => {
    const {workFlowQuestions} = this.props;
    if (workFlowQuestions && workFlowQuestions.stockWorkflowDTOS && workFlowQuestions.stockWorkflowDTOS.length > 0) {
      if (workFlowQuestions.stockWorkflowDTOS[0].stockWorkflowSettingDTO && workFlowQuestions.stockWorkflowDTOS[0].stockWorkflowSettingDTO.length > 0 &&
        workFlowQuestions.stockWorkflowDTOS[0].stockWorkflowSettingDTO[5].categoryAnswer === 'yes') {
        return true;
      }
    }
    return false;
  }

  handleOnClick = () => {
    $(`#${RETURN_DELIVER_ADDRESS_NOT_FOUND_POPUP_ID}`).modal('hide')
    this.setState({showReturnDeliveryPopup: false})
  }

  onCancelClick = () => {
    this.props.onEditPress();
    this.setState({opportunities: this.props.transactionDetails.transactionOpportunities})
  }

  handleDeliveryForm = (values, field, formName ) => {
    if (field  === 'countryId'){
      if (this.props.activeProvinceCountries.includes(values.formData.countryId)){
        this.setState({deliveryForm : values.formData});
        this.setProvincesOfCountry(values.formData.countryId);
      } else{
        let newDeliveryFormData = values.formData;
        this.setState(prevState => (this.props.activeProvinceCountries.includes(prevState.deliveryForm.countryId) ? {
          deliveryForm: {
              ...prevState.deliveryForm.formData,
              state: ''
            }
        } : { deliveryForm : values.formData  }))
        this.setState({deliveryForm : newDeliveryFormData});
      }
    } else{
      this.setState({deliveryForm : values.formData});
    }
  }

  render() {
    const {
      editing,
      hasPermissions,
      transactionDetails,
      reasonForLoan,
      returnGoodsBookingDetail,
      user,
      metadata,
      transactionType,
      usersList,
      addressList,
      hasManagerPermission,
      workFlowQuestions,
      onEditPress,
      bundleLoans
    } = this.props;
    const {
      deliveryModel,
      deliveryForm,
      transactionInformationFormData,
      savingApproveConfirm,
      savingDates,
      isHelpForBuildingAndDismantling,
      changeReturnDeliveryModel,
      changeReturnDeliveryAddressForm,
      showReturnLoanOrderPopup,
      showCalenderBlockPopup,
      blockTransactionList
    } = this.state;
    const isBusinessUnitMed = this.checkBusinessUnit();
    const usCountryStock = this.checkStockCountry();
    const isMedStock = this.isAdditionalProcessActiavted(transactionDetails) && isBusinessUnitMed && usCountryStock;
    const med = this.checkForMedUs();
    const dropdownOptions = {
      2: { id: 2, value: 'approved', name: strings.loanApproved },
      3: { id: 3, value: 'deliver', name: strings.deliver },
      5: { id: 5, value: 'cancelled', name: strings.cancelTransaction },
      9: { id: 9, value: 'delivered', name: strings.delivered },
    };
    const dropdownOptionsArray = [
      { id: 2, value: 'approved', name: strings.loanApproved },
      { id: 3, value: 'deliver', name: strings.deliver },
      { id: 5, value: 'cancelled', name: strings.cancelTransaction },
    ];
    const dropdownOptionsKitArray = [
      { id: 3, value: 'deliver', name: strings.deliver },
      { id: 5, value: 'cancelled', name: strings.cancelTransaction },
    ];

    const dropdownOptions24HoursBookingsArray = [{ id: 5, value: 'cancelled', name: strings.cancel }];

    const dropdownOptionsMedStocks = [
      { id: 2, value: 'approved', name: strings.loanApproved },
      { id: 3, value: 'deliver', name: strings.confirmed },
      { id: 9, value: 'delivered', name: strings.delivered },
      { id: 5, value: 'cancelled', name: strings.cancelTransaction },
    ];

    if (!transactionDetails) return null;

    const {
      type,
      consignmentApprovedBy,
      showLoanApproved,
      c2cLoan,
      allPrevC2c,
      transactionsId,
      transactionOpportunities,
      c2cId,
      targetReservationFrom,
      targetReservationTo,
      twentyFourHourReservation,
      name,
      systemMainComponent,
      quantity,
      productId,
      sapErp,
      consignment,
      showHelpForDismantling,
      borrower,
      transactionStatus,
      transactionKit,
      createdAt,
      firstName,
      lastName,
      organization,
      department,
      street,
      houseNumber,
      postalCode,
      city,
      state,
      country,
      phone,
      email,
      comment,
      showReturnGoodsBooking,
      deliveryComment,
      loanValue,
      enableReturnDeliveryAddress,
      tranReturnDeliveryAddress,
      sapCorrelationId,
      sapLoanOrderStatus,
      sapOrderType,
      sapReturnLoanNumber,
      deliveryNoteNumber,
      returnDeliveryNoteNumber,
      transactionMaterialNumber,
      pickUpComment,
      trackingNumber,
      crateId,
    } = transactionDetails || {};
    const loanType = type ? camelCase(type) : '';
    const consignmentConfirmedBy = consignmentApprovedBy
      ? `by ${consignmentApprovedBy && consignmentApprovedBy.firstName} ${
          consignmentApprovedBy && consignmentApprovedBy.lastName
        }`
      : '';
    const kitStatus = get(
      transactionDetails,
      'transactionPosition.transactionPositionTranslated[0].positionText',
      null,
    );
    let canEditRequested = true;

    if (metadata && metadata.canEditRequested) {
      canEditRequested = true;
    } else if (metadata && !metadata.canEditRequested && get(transactionDetails, 'checkedDate') === null) {
      canEditRequested = false;
    }

    let transactionStatusOptions = [];

    if (loanType === 'kit loan' || loanType === 'kitLoan') {
      const stockInformation = get(transactionDetails, 'transactionKit.stockInformation', '');
      const isPermissions = this.checkPermissionsForTransactionStatus(user, stockInformation);
      if (isMedStock) {
        transactionStatusOptions = dropdownOptionsMedStocks;
      } else {
        transactionStatusOptions =
          showLoanApproved === 'yes' && isPermissions ? dropdownOptionsArray : dropdownOptionsKitArray;
      }
    } else {
      transactionStatusOptions = dropdownOptionsArray;
    }

    if (twentyFourHourReservation) {
      transactionStatusOptions = dropdownOptions24HoursBookingsArray;
    }
    const medBorrower = [
      'Borrower ODX',
      'Borrower MCS',
      'Borrower SUR',
    ];
    const stockEmployees = [
      'Stock Controller',
      'Inbound staff',
      'Outbound staff',
      'Reconditioning',
      'Dispatcher',
      'Borrower',
      'Borrower ODX',
      'Borrower MCS',
      'Borrower SUR',
      'External Borrower',
    ];
    const employeeForLoanOrder = ['Stock Controller', 'Inbound staff', 'Outbound staff', 'Dispatcher'];
    const stockId =
      loanType === 'kit loan' || loanType === 'kitLoan'
        ? get(transactionDetails, 'transactionKit.stockInformation.id', '')
        : get(transactionDetails, 'stockInformation.id', '');
    const isAdminOrStockEmployee = (user && user.admin) || this.isHavingRole(stockEmployees, stockId) || this.canEditOwnTransaction(medBorrower, stockId);
    const isAdminOrHasLoanOrderAccess = (user && user.admin) || this.isHavingRole(employeeForLoanOrder, stockId);
    const isLoanValueEditable = this.hasPermissionToEditLoanValue(user, stockId);
    const showreturnDeliveryAdressCheckbox = this.hasPermissionToChangeReturnDeliveryAdress(user, stockId);
    const hasLoanOrderValue = !!sapCorrelationId;
    const isloanOrderNumberValid = this.validateLoanOrder(sapErp);
    const showLoanOrderButton =
      this.hasERPActiavted(transactionDetails, loanType) &&
      !hasLoanOrderValue &&
      isAdminOrHasLoanOrderAccess &&
      (!!!sapErp || sapLoanOrderStatus === 'RETURNED');
    const hidingCreateLoanOrder = sapLoanOrderStatus === 'SUBMITTED_FOR_CREATE' || sapLoanOrderStatus === 'CREATED';
    const showReturnLoanOrderButton =
    (sapLoanOrderStatus === 'CREATED' && sapOrderType && sapOrderType.staticCode === 'ZLEI' && this.hasERPActiavted(transactionDetails, loanType)) ||
    (isloanOrderNumberValid && sapLoanOrderStatus === null && this.hasERPActiavted(transactionDetails, loanType));
    let firstC2c = null;
    if (c2cLoan) {
      if (allPrevC2c && allPrevC2c.length) {
        firstC2c = allPrevC2c[0].transactionsId;
      } else {
        firstC2c = transactionsId;
      }
    }
    const opportunityIdCrm = transactionOpportunities.map(item => <div>{item.opportunityNumber}</div>);
    const opportunityStatus =
      transactionOpportunities.length > 0 ? transactionOpportunities.find(item => item.crmStatus) : {};
    const opportunityStatusName = get(opportunityStatus, 'crmStatus.crmStatusTranslated[0].name');
    const currency = transactionDetails.transactionKit
      ? get(transactionDetails, 'transactionKit.stockInformation.currency.name')
      : get(transactionDetails, 'stockInformation.currency.name');

    const { transactionsType, detailPage } = this.props;
    const editProductIdPermission = this.hasPermissionToEditProductId(user, stockId);
    const showOptionalTagForoppournityId = user && this.checkOpportunityRequired();
    const isShowAdditionalFieldActivated = this.checkForWorkFlowActivation();
    const showPicUpComment = isShowAdditionalFieldActivated && workFlowQuestions && workFlowQuestions.pickUpComment;
    const showTrackingNumber = isShowAdditionalFieldActivated && workFlowQuestions && workFlowQuestions.trackingNumber;
    const showCrateId = isShowAdditionalFieldActivated && workFlowQuestions && workFlowQuestions.crateId;
    const hideTransactionDropdowm = (transactionStatus &&
    transactionStatus.name === strings.transactionCancelled)

    return (
      <>
        {c2cLoan && (
          <div className={'blue-bg-message'}>
            <div className='container-fluid'>
              {strings.c2cLoanProcessText(c2cId)}
              <NavLink to={`/c2c/${firstC2c}/view-c2c-loan`}>#{c2cId}</NavLink>
            </div>
          </div>
        )}
        <div className='container-fluid pt-4 pb-4 transaction-details-form-container'>
          <div className='d-flex flex-column flex-md-row align-items-md-end date-shadow-effect pt-3 pb-3 pr-3 pl-3'>
            <div className='d-flex flex-grow-1 flex-column flex-md-row align-items-md-end position-relative'>
              <FormComponent
                key={transactionsId}
                ref={ref => {
                  this.milestoneDatesFormRef = ref;
                }}
                className='d-flex transaction-details-form'
                rowStyle='transaction-details-form flex-column flex-md-row mr-0 mr-md-3 ml-0'
                model={transactionInformationModel(
                  transactionDetails,
                  canEditRequested,
                  transactionDetails && transactionDetails.c2cLoan,
                  user,
                  stockId
                )}
                value={transactionInformationFormData}
                doValidate={false}
                onChange={this.onFormComponentChange}
                onClick={this.handleFormClick}
              />
              {twentyFourHourReservation && <div className='disable-overlay' />}
            </div>
            <div className='adjust-save-dates-button-container'>
              <Button
                dataTest='transaction-details-save-dates'
                disabled={twentyFourHourReservation || !canEditRequested || !isAdminOrStockEmployee}
                bgFill={true}
                loading={savingDates}
                value={strings.saveDates}
                onClick={this.onSaveDatesClick}
              />
            </div>
          </div>
          {!canEditRequested && (
            <div className='error-block pt-3'>
              {strings.currentPositionDateDisabledMessageFirstPart(
                metadata.requestedTransactionNumber || metadata.requestedTransactionId,
              )}
              <u>
                <NavLink className='error-block pt-3' to={`/transactions/${metadata.activeTransactionId}`}>
                  {`#${metadata.activeTransactionNumber || metadata.activeTransactionId}`}
                </NavLink>
              </u>
              {strings.currentPositionDateDisabledMessageSecondPart}
            </div>
          )}
        </div>

        <div className='container-fluid mt-3'>
        {(type === 'kit loan' || type === 'single loan') &&
            <div className='row mb-3 ml-0'>
              {bundleLoans && bundleLoans.length > 0 && (
                  <div>
                    <span className='font-bold'>{`${strings.linkedLoans}: `}</span>
                    {bundleLoans.map((link, index) => (
                      <>
                        <NavLink
                          to={`/${transactionsType}/${link.transactionsId}?detailPage=1?islabordla=dla`}
                          data-test={'transaction-details-linked-loan'}
                        >
                          {`#${link.transactionNumber} `}
                        </NavLink>
                        {index !== bundleLoans.length - 1 && <span>, </span>}
                      </>
                    ))}
                  </div>
                )}
                </div>}
          <div className='row align-items-start'>
            <div className='col col-lg-6 col-12'>
              <div className='row mb-3'>
                <div className='col-6 font-bold'>{strings.period}</div>
                <div className='col-6'>
                  {targetReservationFrom &&
                    getDateInUTCFormat('DD.MM.YYYY', targetReservationFrom, targetReservationTo)}
                </div>
              </div>
              {loanType === 'kitLoan' && this.renderKitLoanName()}
              {loanType === 'singleLoan' && this.renderSingleLoanName()}
              <div className='row mb-3'>
                <div className={`col-6 font-bold ${editing ? 'label-important': ""}`}>{strings.nameShortDescription}</div>
                <div className='col-6'>
                  {editing ? (
                    <InputField
                      ref={ref => {
                        this.inputFieldRef['name'] = ref;
                      }}
                      dataTest={'information-name'}
                      // label={strings.nameShortDescription}
                      type={'text'}
                      value={name}
                      placeholder={''}
                      doValidateForm={true}
                      isRequired={true}
                      validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                      resetValidation={false}
                      onError={this.onError('name')}
                      onChange={this.onChange('name')}
                    />
                  ) : (
                    name
                  )}
                </div>
              </div>
              <div className='row mb-3'>
                <div className={`col-6 font-bold ${editing ? 'label-important': ""}`}>{strings.reasonForLoan}</div>
                <div className='col-6'>
                  {editing ? (
                    <Dropdown
                      dataTest='transaction-details-reason-for-loan-dropdown'
                      ref={ref => {
                        this.reasonForLoanDropdownRef = ref;
                      }}
                      data={this.filterReasonForLoan()}
                      disabled={loanType === 'courseLoan'}
                      hasSection={false}
                      multiSelect={false}
                      filter={false}
                      clearOption={false}
                      idKey='reasonForLoanId'
                      displayValue={[{ key: 'name', separator: '' }]}
                      isRequired={loanType === 'courseLoan' ? false : true}
                      validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                      placeholder={strings.reasonForLoan}
                      value={
                        loanType === 'courseLoan'
                          ? (reasonForLoan && reasonForLoan[1]) || {}
                          : transactionDetails.reasonForLoan
                      }
                      onChangeValue={this.handleDropdownChangeSelected}
                    />
                  ) : (
                    transactionDetails.reasonForLoan && transactionDetails.reasonForLoan.name
                  )}
                </div>
              </div>
              {systemMainComponent !== null && (
                <div className='row mb-3'>
                  <div className='col-6 font-bold'>{strings.categorySystem}</div>
                  <div className='col-6'>{systemMainComponent && systemMainComponent.systemMainComponentName}</div>
                </div>
              )}
              {!editing && quantity !== null && (
                <div className='row mb-3'>
                  <div className='col-6 font-bold'>{strings.quantity}</div>
                  <div className='col-6'>{quantity}</div>
                </div>
              )}
              {editing && loanType === 'courseLoan' && this.checkRolesForPermissionOnConfirmConsigment() && (
                <div className='row mb-3'>
                  <div className='col-6 font-bold'>{strings.quantity}</div>
                  <div className='col-6'>
                    <InputField
                      ref={ref => {
                        this.inputFieldRef['quantity'] = ref;
                      }}
                      dataTest='quantity'
                      label={strings.quantity}
                      type='text'
                      value={transactionDetails.quantity}
                      placeholder=''
                      validators={[{ check: Validators.number, message: 'invalidNumberError' }]}
                      doValidateForm
                      isRequired={false}
                      resetValidation={false}
                      onChange={this.onChange('quantity')}
                    />
                  </div>
                </div>
              )}
              {!editing && loanType === 'courseLoan' && (
                <div className='row mb-3'>
                  <div className='col-6 font-bold'>{strings.productId}</div>
                  <div className='col-6'>{productId}</div>
                </div>
              )}
              {editing && loanType === 'courseLoan' && editProductIdPermission && (
                <div className='row mb-3'>
                  <div className='col-6 font-bold'>{strings.productId}</div>
                  <div className='col-6'>
                    <InputField
                      ref={ref => {
                        this.inputFieldRef['productId'] = ref;
                      }}
                      dataTest='productId'
                      label={strings.productId}
                      type='text'
                      value={transactionDetails.productId}
                      placeholder=''
                      validators={[]}
                      doValidateForm
                      isRequired={false}
                      resetValidation={false}
                      onChange={this.onChange('productId')}
                    />
                  </div>
                </div>
              )}
              <div className='row mb-3'>
                <div className='col-6 font-bold'>{strings.kitLoanOrderText}</div>
                <div className='col'>
                  {editing && !sapCorrelationId ? (
                    <InputField
                      ref={ref => {
                        this.inputFieldRef['sapErp'] = ref;
                      }}
                      dataTest={'information-referenceToSapERP'}
                      label={strings.loanOrder}
                      type={'text'}
                      value={sapErp}
                      placeholder={''}
                      validators={[]}
                      doValidateForm
                      isRequired={false}
                      resetValidation={false}
                      onError={this.onError('sapErp')}
                      onChange={this.onChange('sapErp')}
                    />
                  ) : sapReturnLoanNumber ? (
                    `${sapErp} (ZLA Number : ${sapReturnLoanNumber})`
                  ) : (
                    `${sapErp || '-'}`
                  )}
                </div>
                <div className='col-6'></div>
                <div className='col-6 p-0'>
                  {showLoanOrderButton && !hidingCreateLoanOrder && (
                    <div>
                      <Button
                        styleClass='col-12 mt-3 button-without-border'
                        bgFill={false}
                        value={strings.createLoanOrder}
                        iconName='plus'
                        onClick={() => this.createLoanOrder(loanType, transactionDetails)}
                        newVersionUi={true}
                      />

                      <CreateNewLoanOrderPopup
                        id='load-order-modal'
                        loanType={loanType}
                        detailPage={detailPage}
                        transactionsType={transactionsType}
                        isEditView={false}
                        accountIdFromOppInterface={this.state.accountIdFromOppInterface}
                      />
                    </div>
                  )}
                  {sapLoanOrderStatus === 'SUBMITTED_FOR_CREATE' && (
                    <>
                      <p>{strings.waitingForLoanOrder}</p>
                      <Button
                        styleClass='col-12 mt-3'
                        bgFill={false}
                        value={strings.viewDetails}
                        iconName='save'
                        onClick={() => this.viewDetails()}
                      />
                      <CreateNewLoanOrderPopup
                        id='load-order-modal'
                        loanType={loanType}
                        detailPage={detailPage}
                        transactionsType={transactionsType}
                        isEditView={true}
                      />
                    </>
                  )}
                  {sapLoanOrderStatus === 'WAITING_FOR_ZLA' && <p>WAITING_FOR_ZLA</p>}
                  {showReturnLoanOrderButton && (
                    <>
                      <Button
                        styleClass='col-12 mt-3'
                        bgFill={false}
                        value={strings.returnLoanOrder}
                        iconName='save'
                        onClick={() =>
                          this.returnLoanOrder(
                            transactionDetails.transactionsId,
                            loanType,
                            detailPage,
                            transactionsType,
                          )
                        }
                      />
                      <CreateNewLoanOrderPopup
                        id='load-order-modal'
                        showReturnLoanOrderPopup={showReturnLoanOrderPopup}
                        loanType={loanType}
                        detailPage={detailPage}
                        transactionsType={transactionsType}
                        transactionMaterialNumber={transactionMaterialNumber}
                        isEditView={false}
                        accountIdFromOppInterface={this.state.accountIdFromOppInterface}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-6 font-bold'>{strings.deliveryNote}</div>
                <div className='col-6'>
                  {editing ? (
                    <InputField
                      ref={ref => {
                        this.inputFieldRef['deliveryNote'] = ref;
                      }}
                      dataTest={'information-referenceToDeliveryNote'}
                      label={strings.deliveryNote}
                      type={'text'}
                      value={deliveryNoteNumber}
                      placeholder={''}
                      validators={[{ check: Validators.number, message: 'invalidNumberError' }]}
                      isRequired={false}
                      resetValidation={true}
                      onError={this.onError('deliveryNote')}
                      onChange={this.onChange('deliveryNote')}
                      extraProps={{ maxLength: 10 }}
                    />
                  ) : deliveryNoteNumber ? (
                    deliveryNoteNumber
                  ) : (
                    '-'
                  )}
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-6 font-bold'>{strings.returnDeliveryNote}</div>
                <div className='col-6'>
                  {editing ? (
                    <InputField
                      ref={ref => {
                        this.inputFieldRef['returnDeliveryNote'] = ref;
                      }}
                      dataTest={'information-referenceToreturnDeliveryNote'}
                      label={strings.returnDeliveryNote}
                      type={'text'}
                      value={returnDeliveryNoteNumber}
                      placeholder={''}
                      validators={[{ check: Validators.number, message: 'invalidNumberError' }]}
                      doValidateForm
                      isRequired={false}
                      onError={this.onError('returnDeliveryNote')}
                      onChange={this.onChange('returnDeliveryNote')}
                      extraProps={{ maxLength: 10 }}
                    />
                  ) : returnDeliveryNoteNumber ? (
                    returnDeliveryNoteNumber
                  ) : (
                    '-'
                  )}
                </div>
              </div>
              {loanType !== 'kitLoan' && (
                <div className='row mb-3'>
                  <div className='col-6 font-bold'>{strings.consignment}</div>
                  <div className='col-6'>
                    <Checkbox
                      dataTest='confirm-consignment-checkbox'
                      field='confirmConsignment'
                      label={consignmentConfirmedBy}
                      selected={!!consignment}
                      onClick={this.handleConfirmConsignment}
                      disabled={!this.checkRolesForPermissionOnConfirmConsigment() || consignment !== null}
                      newuiVersionStyle={true}
                    />
                  </div>
                </div>
              )}
              {showHelpForDismantling && (
                <div className='row mb-3'>
                  <div className='col-6 font-bold'>{strings.helpForBuildingAndDismantling}</div>
                  <div className='col-6'>
                    <Checkbox
                      field='helpForBuildingAndDismantling'
                      label=''
                      isRequired={false}
                      selected={isHelpForBuildingAndDismantling}
                      onClick={this.handleHelpForBuildingAndDismantling}
                      disabled={!editing}
                      newuiVersionStyle={true}
                    />
                  </div>
                </div>
              )}
              {loanType !== 'courseLoan' && (
                <div className='row small-height mb-3'>
                  <div className='col-6 font-bold'>{strings.crmOpportunityId}</div>
                  <div className='col-6'>{editing ? this.getOpportunityForm(loanType) : opportunityIdCrm}</div>
                </div>
              )}
              {loanType !== 'courseLoan' && (
                <div className='row small-height mb-3'>
                  <div className='col-6 font-bold'>{strings.crmOpportunityStatus}</div>
                  <div className='col-6'>{opportunityStatusName}</div>
                </div>
              )}
              {this.renderCampignId()}
              {currency && (
                <div className='row small-height mb-3'>
                  <div className='col-6 font-bold'>{`${strings.loanValue} [${currency}]`}</div>
                  <div className='col-6'>
                    {editing && isLoanValueEditable && !sapCorrelationId ? (
                      <InputField
                        ref={ref => {
                          this.inputFieldRef['loanValue'] = ref;
                        }}
                        type={'text'}
                        value={loanValue}
                        placeholder={''}
                        validators={[{ check: Validators.number, message: 'invalidNumberError' }]}
                        doValidateForm
                        isRequired={false}
                        resetValidation={false}
                        onError={this.onError('loanValue')}
                        onChange={this.onChange('loanValue')}
                        extraProps={{ maxLength: 20 }}
                      />
                    ) : (
                      loanValue
                    )}
                  </div>
                </div>
              )}
              <div className='row mb-3'>
                <td className={`col-6 column-vertical-top font-bold ${editing ? 'label-important': ""}`}>{strings.borrower}</td>
                {editing ? (
                  <div className='col-6'>
                    <Dropdown
                      dataTest='transaction-details-reason-for-loan-dropdown'
                      ref={ref => {
                        this.borrowerDropdownRef = ref;
                      }}
                      data={usersList ? usersList : []}
                      hasSection={false}
                      multiSelect={false}
                      filter={true}
                      clearOption={false}
                      idKey='accountId'
                      displayValue={[
                        { key: 'lastName', separator: ', ' },
                        { key: 'firstName', separator: ' (' },
                        { key: 'email', separator: ')' },
                      ]}
                      isRequired
                      validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                      placeholder={strings.pleaseSelect}
                      value={borrower ? borrower.accountId : ''}
                    />
                  </div>
                ) : (
                  <div className='col-6 mb-3'>
                    {!!borrower && (
                      <div>
                        {borrower.firstName} {borrower.lastName}
                      </div>
                    )}
                    {!!borrower && !!borrower.email && <div>{borrower.email}</div>}
                    {!!borrower &&
                      !!borrower.userAddresses &&
                      !!borrower.userAddresses.length &&
                      !!borrower.userAddresses[0].phone && <div>{borrower.userAddresses[0].phone}</div>}
                  </div>
                )}
              </div>
              <div className='row mb-3'>
                <div className='col-6 font-bold vertical-middle'>{strings.transactionStatus}</div>
                <div className='col-3'>
                  {(hasPermissions || hasManagerPermission) && !hideTransactionDropdowm ? (
                    <Dropdown
                      dataTest='transaction-details-approve-confirm-dropdown'
                      ref={ref => {
                        this.approveConfirmDropdownRef = ref;
                      }}
                      data={transactionStatusOptions}
                      hasSection={false}
                      multiSelect={false}
                      filter={false}
                      clearOption={false}
                      idKey='id'
                      displayValue={[{ key: 'name', separator: '' }]}
                      isRequired={false}
                      placeholder={strings.requested}
                      value={dropdownOptions[transactionStatus && transactionStatus.transactionStatusId]}
                    />
                  ) : (
                    transactionStatus && transactionStatus.name
                  )}
                </div>
              </div>
              {transactionKit ? (
                transactionKit.stockInformation &&
                transactionKit.stockInformation.locationName && (
                  <div className='row mb-3'>
                    <div className='col-6 font-bold'>{strings.location}</div>
                    <div className='col-6'>{transactionKit.stockInformation.locationName}</div>
                  </div>
                )
              ) : (
                <div className='row mb-3'>
                  <div className='col-6 font-bold'>{strings.location}</div>
                  <div className='col-6'>{transactionDetails.stockInformation.locationName}</div>
                </div>
              )}
              <div className='row mb-3'>
                <div className='col-6 font-bold'>{strings.loanCreatedBy}</div>
                <div className='col-6'>
                  {transactionDetails.user && transactionDetails.user.firstName}{' '}
                  {transactionDetails.user && transactionDetails.user.lastName}{' '}
                  {moment(createdAt).isValid() && `, ${moment(createdAt).format('DD.MM.YYYY')}`}
                </div>
              </div>
              {(hasPermissions || editing || hasManagerPermission) && (
                <div className='mt-3 d-flex'>
                  <div className='save-dates-button-container '>
                  <Button
                    dataTest='transaction-details-save-dropdown'
                    disabled={!hasPermissions && !hasManagerPermission}
                    bgFill={true}
                    loading={savingApproveConfirm}
                    value={strings.save}
                    onClick={this.onSaveDropdown}
                  />
                  </div>
                  { editing && <div className='save-dates-button-container'>
                   <Button
                    dataTest='transaction-details-save-dropdown'
                    disabled={!hasPermissions && !hasManagerPermission}
                    bgFill={true}
                    value={strings.cancel}
                    onClick={this.onCancelClick}
                    styleClass="ml-4"
                  />
                  </div>}
                </div>
              )}
            </div>
            <div className='col col-lg-6 col-12'>
              {kitStatus && (
                <div className='row mb-3'>
                  <div className='col-6 font-bold'>{strings.status}</div>
                  <div className='col-6'>{kitStatus ? kitStatus.replace(/warehouse|Lager|DemoLager/gi, strings.emailMessageCategoryHedaReplace).replace('Kit', 'System') : kitStatus }</div>
                </div>
              )}
              {!editing && (
                <div className='row'>
                  <div className='col-6 font-bold'>{strings.deliveryAddress}</div>
                  <div className='col-6'>
                    <>
                      {(firstName || lastName) && (
                        <div>
                          {firstName}
                          {firstName && lastName && ' '}
                          {lastName}
                        </div>
                      )}
                      {organization && <div>{organization}</div>}
                      {department && <div>{department}</div>}
                      {street && (
                        <div>
                          {street} {houseNumber}
                        </div>
                      )}
                      {(postalCode || city) && (
                        <div>
                          {postalCode} {city}
                        </div>
                      )}
                      {state && <div>{state}</div>}
                      {country && <div>{country.name}</div>}
                      {phone && (
                        <div>
                          {strings.phone}: {phone}
                        </div>
                      )}
                      {email && (
                        <div>
                          {strings.email}: {email}
                        </div>
                      )}
                    </>
                  </div>
                </div>
              )}
              {editing && (
                <div style={{ marginTop: '18px' }}>
                  <FormComponent
                    ref={ref => {
                      this.deliveryAddressFromRef = ref;
                    }}
                    model={deliveryModel}
                    value={deliveryForm}
                    doValidate={false}
                    onChange={this.handleDeliveryForm}
                  />
                  <br />
                </div>
              )}
              <>
                <div className='font-bold'>{strings.commentText}</div>
                {editing ? (
                  <TextArea
                    ref={ref => {
                      this.inputFieldRef['comment'] = ref;
                    }}
                    dataTest={'information-comment'}
                    label={strings.commentText}
                    type={'textarea'}
                    validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                    isRequired={true}
                    doValidateForm
                    value={comment}
                    placeholder={''}
                    onError={this.onError('comment')}
                    onChange={this.onChange('comment')}
                  />
                ) : (
                  <div style={{ whiteSpace: 'pre-line' }}>{comment}</div>
                )}
              </>
              <div className='mt-3'>
                <div className='font-bold'>{strings.deliveryComment}</div>
                {editing ? (
                  <TextArea
                    ref={ref => {
                      this.inputFieldRef['deliveryComment'] = ref;
                    }}
                    dataTest={'information-delivery-comment'}
                    label={strings.deliveryComment}
                    type={'textarea'}
                    validators={[]}
                    isRequired={false}
                    doValidateForm
                    value={deliveryComment}
                    placeholder={''}
                    onError={this.onError('deliveryComment')}
                    onChange={this.onChange('deliveryComment')}
                  />
                ) : (
                  <div>{deliveryComment}</div>
                )}
              </div>
              {showPicUpComment && <div className='mt-3'>
                <div className='font-bold'>{strings.pickupComment}</div>
                {editing ? (
                  <InputField
                  ref={ref => {
                    this.inputFieldRef['pickUpComment'] = ref;
                  }}
                  type={'text'}
                  value={pickUpComment !== null?pickUpComment: '' }
                  placeholder={''}
                  validators={[]}
                  doValidateForm
                  isRequired={false}
                  resetValidation={false}
                  onError={this.onError('pickUpComment')}
                  onChange={this.onChange('pickUpComment')}
                />
                ) : (
                  <div>{pickUpComment || ''}</div>
                )}
              </div>}
              {showTrackingNumber &&
              <div className='mt-3'>
                <div className='font-bold'>{strings.trackingNumber}</div>
                {editing ? (
                  <InputField
                  ref={ref => {
                    this.inputFieldRef['trackingNumber'] = ref;
                  }}
                  type={'text'}
                  value={trackingNumber!== null ? trackingNumber : ''}
                  placeholder={''}
                  validators={[]}
                  doValidateForm
                  isRequired={false}
                  resetValidation={false}
                  onError={this.onError('trackingNumber')}
                  onChange={this.onChange('trackingNumber')}
                />
                ) : (
                  <div>{trackingNumber || ''}</div>
                )}
              </div>}
              {showCrateId &&
              <div className='mt-3'>
                <div className='font-bold'>{strings.crateId}</div>
                {editing ? (
                  <InputField
                  ref={ref => {
                    this.inputFieldRef['crateId'] = ref;
                  }}
                  type={'text'}
                  value={crateId !== null ? crateId: ''}
                  placeholder={''}
                  validators={[{ check: Validators.alphaNumericWithSpace, message: 'alphanumericError' }]}
                  doValidateForm
                  isRequired={false}
                  resetValidation={false}
                  onError={this.onError('crateId')}
                  onChange={this.onChange('crateId')}
                />
                ) : (
                  <div>{crateId || ''}</div>
                )}
              </div>}
              {loanType !== 'courseLoan' && showreturnDeliveryAdressCheckbox && (
                <div className='row mt-3'>
                  <div className='col-6 font-bold'>{strings.returnDeliveryAddress}</div>
                  <div className='col-6'>
                    <Checkbox
                      field='returnDeliveryAddress'
                      label=''
                      isRequired={false}
                      selected={this.state.enableReturnDeliveryAddress}
                      onClick={this.handleChangeReturnDeleveryAdress}
                      newuiVersionStyle={true}
                    />
                  </div>
                  {this.state.defaultDeliveryFormView && (
                    <>
                    <div className='col-md-6 mt-2 p-0'>
                    <Dropdown
                      ref={ref => {
                        this.selectedAddressRef = ref;
                      }}
                      data={addressList}
                      hasSection={false}
                      multiSelect={false}
                      filter={false}
                      clearOption={false}
                      idKey='addressType'
                      displayValue={[{ key: 'addressType', separator: '' }]}
                      isRequired
                      validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                      placeholder={strings.pleaseSelect}
                      value={
                        tranReturnDeliveryAddress?tranReturnDeliveryAddress:addressList.find(item => item.activeAddress === true)
                      }
                      onChangeValue={this.handleDropdownChangeSelected}
                    />
                    </div>
                      <div style={{ marginTop: '18px' }}>
                        <FormComponent
                          ref={ref => {
                            this.changeReturnDeliveryAddressFromRef = ref;
                          }}
                          model={changeReturnDeliveryModel}
                          value={changeReturnDeliveryAddressForm}
                          doValidate={false}
                          onChange={() => {}}
                        />
                        <br />
                      </div>
                      <div className='save-dates-button-container mt-3'>
                        <Button
                          dataTest='transaction-details-save-dropdown'
                          disabled={!hasPermissions}
                          bgFill={true}
                          value={strings.save}
                          onClick={this.onSaveChangeReturnDeliveryAdress}
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
              <ReturnDeliveryAddressNotFoundPopup id={RETURN_DELIVER_ADDRESS_NOT_FOUND_POPUP_ID} onClickCancel={this.handleOnClick} onClickOkay={this.handleOnClick}  />
              {enableReturnDeliveryAddress && !this.state.defaultDeliveryFormView && tranReturnDeliveryAddress && (
                <div className='row mt-3'>
                  <div className='col-6  font-bold'>{strings.newReturnDeliveryAddress}</div>
                  <div className='col-6' style={{overflowWrap: 'break-word'}}>
                    <>
                      {(tranReturnDeliveryAddress.firstName || tranReturnDeliveryAddress.lastName) && (
                        <div>
                          {tranReturnDeliveryAddress.firstName}
                          {tranReturnDeliveryAddress.firstName && tranReturnDeliveryAddress.lastName && ' '}
                          {tranReturnDeliveryAddress.lastName}
                        </div>
                      )}
                      {tranReturnDeliveryAddress.organization && <div>{tranReturnDeliveryAddress.organization}</div>}
                      {tranReturnDeliveryAddress.department && <div>{tranReturnDeliveryAddress.department}</div>}
                      {tranReturnDeliveryAddress.street && (
                        <div>
                          {tranReturnDeliveryAddress.street} {tranReturnDeliveryAddress.houseNumber}
                        </div>
                      )}
                      {(tranReturnDeliveryAddress.postalCode || tranReturnDeliveryAddress.city) && (
                        <div>
                          {tranReturnDeliveryAddress.postalCode} {tranReturnDeliveryAddress.city}
                        </div>
                      )}
                      {tranReturnDeliveryAddress.state && <div>{tranReturnDeliveryAddress.state}</div>}
                      {tranReturnDeliveryAddress.country && <div>{tranReturnDeliveryAddress.country.name}</div>}
                      {tranReturnDeliveryAddress.phone && (
                        <div>
                          {strings.phone}: {tranReturnDeliveryAddress.phone}
                        </div>
                      )}
                      {tranReturnDeliveryAddress.email && (
                        <div>
                          {strings.email}: {tranReturnDeliveryAddress.email}
                        </div>
                      )}
                    </>
                  </div>
                </div>
              )}

              <Button
                isGhost={true}
                bgFill={false}
                dataTest='transaction-details-show-messages'
                styleClass='show-all-messages-button'
                iconName='notification'
                iconPosition='left'
                onClick={this.onShowAllMessagesPress}
                value={strings.showAllMessages}
              />
              {returnGoodsBookingDetail &&
                showReturnGoodsBooking &&
                returnGoodsBookingDetail.date &&
                returnGoodsBookingDetail.condition &&
                this.renderReturnGoodBooking(returnGoodsBookingDetail)}

                {showCalenderBlockPopup &&
                <BlockingCalendarMessagPopup
                    id = 'calendar-block'
                    tranactionDelayedList={blockTransactionList}
                />}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ authReducer, languageReducer, masterDataReducer, transactionDetailsReducer, createStockReducer, kitDetailsReducer }) => {
  return {
    countries: masterDataReducer.countries,
    language: languageReducer,
    reasonForLoan: masterDataReducer.reasonForLoan,
    user: authReducer.user,
    transactionDetails: transactionDetailsReducer.transactionDetails,
    metadata: transactionDetailsReducer.metadata,
    returnGoodsBookingDetail: transactionDetailsReducer.returnGoodsBookingDetail,
    addressList: createStockReducer.addressList,
    workFlowQuestions: kitDetailsReducer.workFlowQuestions,
    bundleLoans: transactionDetailsReducer.bundleLoans,
    activeProvinceCountries: masterDataReducer.activeProvinceCountries
  };
};

export default connect(mapStateToProps, {
  fetchMasterData,
  fetchMasterDataPost,
  saveTransactionInformationDates,
  updateTransactionDetails,
  updateTransactionStatus,
  confirmConsignment,
  toggleActionMessage,
  erpStatus,
  saveChangeRetuenDeliverAdress,
  ordertype,
  billing,
  distributionChannel,
  getdivisionList,
  saveCreateLoanorderData,
  saveMaterialNumberAndDescription,
  getDeliveryBlockList,
  getCustomeGroup,
  returnSapErpLoanOrderForTransaction,
  checkActiveLoanorder,
  getUserIdFromBorrowerMail,
  getCustomerIDWithOpportunityID,
  fetchStockAddress,
  createSingleLoanUsingExistingKitLoan,
  fetchAdaptedLoanReasonForStock,
  getProvincesOfCountry,
  getAllActiveProvinceCountries
})(Information);
