import { connect } from 'react-redux';

import { createQuestion, createAnswer, editAnswer  } from 'library/common/commonActions/QuestionsActions';

import Questions from './Questions.component';

const mapStateToProps = ({ languageReducer, authReducer }) => {
  return {
    user: authReducer.user,
    language: languageReducer,
  }
};

export default connect(mapStateToProps, {
  createQuestion,
  createAnswer,
  editAnswer,
})(Questions);
