export const loanListBreadcrumbData = [
    {
      name:'homepage',
      url: '/home'
    },
    {
      name:'loanListLabel',
    },
  ];

  export const labsLocationLoanListBreadcrumbData = [
    {
      name:'homepage',
      url: '/home'
    },
    {
      name:'demoRequestList',
    },
  ];
