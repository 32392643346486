import { batchActions } from 'redux-batched-actions';


import { fetchMasterDataService } from 'library/api/master-data.service';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { deleteMasterDataService, fetchMasterDataServicePut } from 'library/api/master-data.service';
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';

import {
  SET_IS_OPEN_NOTIFICATIONS_BAR,
  ADD_NOTIFICATION,
  FETCH_ALL_NOTIFICATIONS,
  SET_IS_NOTIFICATIONS_LOADING,
} from '../commonConstants/storeConstants';

export const fetchAllNotifications = () => async dispatch => {
  try {
    dispatch({
      type: SET_IS_NOTIFICATIONS_LOADING,
      payload: true,
    });
    const res = await fetchMasterDataService(URLS.notificationList);
    dispatch(
      batchActions([
        dispatch({
          type: FETCH_ALL_NOTIFICATIONS,
          payload: res.data,
        }),
        dispatch({
          type: SET_IS_NOTIFICATIONS_LOADING,
          payload: false,
        }),
      ]),
    );
  } catch (err) {
    dispatch(
      batchActions([
        dispatch({
          type: FETCH_ALL_NOTIFICATIONS,
          payload: [],
        }),
        dispatch({
          type: SET_IS_NOTIFICATIONS_LOADING,
          payload: false,
        }),
      ]),
    );
  }
};

export const openOrHideNotificationsBar = isOpen => dispatch => {
  dispatch({
    type: SET_IS_OPEN_NOTIFICATIONS_BAR,
    payload: isOpen,
  });
};

export const addNotification = notification => dispatch => {
  dispatch({
    type: ADD_NOTIFICATION,
    payload: notification,
  });
};

export const deleteNotification = id => async dispatch => {
  try {
    const res = await deleteMasterDataService(URLS.notification(id));

    if (res.status === 200) {
      await dispatch(fetchAllNotifications());
    }
  } catch (err) {
    dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
  }
};

export const deleteAllNotifications = () => async dispatch => {
  try {
    const res = await deleteMasterDataService(URLS.deleteAllNotifications);

    if (res.status === 200) {
      await dispatch(fetchAllNotifications());
    }
  } catch (err) {
    dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
  }
};

export const changeStatusNotification = id => async dispatch => {
  try {
    const dataToSend = {
      id,
      status: 'READ',
    };
    const res = await fetchMasterDataServicePut(URLS.notificationStatus, dataToSend);

    if (res.status === 200) {
      await dispatch(fetchAllNotifications());
    }
  } catch (err) {
    dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
  }
};
