
/**
 * General usage: When we want update options, or some conditional logic need to be done in model data
 * instead of using indexes of array we are using field to indefiy where the change need to be done.
 * If we indexes removing and adding new field causes lot of adjustments to avoid use this util
 * @param {*} model Array of objects representing FormComponent
 * @param {*} field identifier to catch the object to modfiy
 * @param {*} attribute Which field we want to change   
 * @param {*} value With what field we want to update
 * @returns Updated model
 */
export const mapFormFieldAndValue = (model = [], field = '', attribute = '', value = '') => {
    return model.map((fieldItems) => {
        if(fieldItems.field === field){
            return {...fieldItems, [attribute]: value}
        }
        return {...fieldItems};
    })
}