import React from 'react';

import Icon from 'library/common/commonComponents/Icon/Icon';

const FolderListView = ({ handleOpenFolder, handleDelete, handleOpenEditFolderPopup, folder, user }) => (
  <div className='single-folder-list-view' onClick={handleOpenFolder}>
    <div className='d-flex'>
      <Icon name='folder' viewBox = '0 0 408 408' height={16} width={16} fill='#0088d0' className='folder-icon' />
      <p className='folder-name'>{folder.name}</p>
    </div>
    <div>
      {user.admin && (
        <>
          <button className='edit-folder-button' onClick={handleOpenEditFolderPopup}>
            <Icon name='edit' height={16} width={16} fill='#0088d0' />
          </button>
          <button className='delete-folder-button' onClick={handleDelete}>
            <Icon name='delete' height={16} width={16} fill='#737678' />
          </button>
        </>
      )}
    </div>
  </div>
);

export default FolderListView;
