import * as $ from 'jquery';
import { isEqual } from 'lodash';
import React, { Component } from 'react';

import strings from 'resources/locales/Translate';
import Button from 'library/common/commonComponents/Button/Button';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { FETCH_COUNTRY_LIST } from 'library/common/commonConstants/storeConstants';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';

import './deliveryAddressesStyles.scss';
import {deliveryAddressesBreadcrumbData} from './deliveryAddressesBreadcrumbs';
import CreateDeliveryAddressForm
  from 'modules/DeliveryAddresses/components/CreateDeliveryAddressForm/CreateDeliveryAddressForm';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import AddressItem from 'modules/DeliveryAddresses/components/AddressItem/AddressItem';

export default class DeliveryAddresses extends Component {

  state = {
    selectedAddress: {},
    doClearForm: false,
    isEditView: false,
    isModalOpen: false
  };

  componentDidMount() {
    document.title = strings.deliveryAddresses;
    const { fetchDeliveryAddress, populateDropdownValue, fetchMasterData, masterData } = this.props;

    if (masterData.countries.length === 0) {
      fetchMasterData(URLS.countryList, FETCH_COUNTRY_LIST);
    } else {
      populateDropdownValue();
    }

    fetchDeliveryAddress();
  }

  componentDidUpdate(prevProps) {
    const { populateDropdownValue, fetchDeliveryAddress, masterData, language, fetchMasterData } = this.props;

    if (!isEqual(masterData.countries, prevProps.masterData.countries)) {
      populateDropdownValue();
      fetchDeliveryAddress();
    }
    if (language !== prevProps.language) {
      fetchMasterData(URLS.countryList, FETCH_COUNTRY_LIST);
    }
  }

  handleSaveAddress = (value) => {
    const {saveDeliveryAddress} = this.props;
    const {isEditView, selectedAddress} = this.state;

    const url = isEditView ? `${URLS.postNewAddress}/${selectedAddress.userAddressId}` : URLS.postNewAddress;
    const method = isEditView ? 'put' : 'post';
    saveDeliveryAddress(url, value, method);
    this.setState({selectedAddress: {}, isEditView: false});
  };

  handleEditAddress = (address) => {
    this.setState({selectedAddress: address, isEditView: true}, () => {
      this.openModel();
    });
  };

  openModel = () => {
    this.setState({doClearForm: false, isModalOpen: true});
    $('#create-address-modal').modal({
      keyboard: false,
      show: true,
      backdrop: 'static'
    });
  };

  handleModalClose = () => {
    this.setState({doClearForm: true, isEditView: false, isModalOpen: false, selectedAddress: {}});
  };

  openAddressDeleteModel = (id) => {
    $(`#confirmAddressDeleteModal${id}`).modal({
      keyboard: false,
      show: true,
      backdrop: 'static'
    });
  };

  deleteAddress = (id) => {
    const {deleteDeliveryAddress} = this.props;

    deleteDeliveryAddress(id);
  };

  render() {
    const {addressForm, addresses} = this.props.deliveryAddresses;
    const {selectedAddress, doClearForm, isEditView, isModalOpen} = this.state;
    const {displayActionMessage, type, message, isLoading} = this.props.appActions;

    return (
      <div className='delivery-address' data-test='delivery-address-container'>
        {displayActionMessage && <ActionMessage type={type} message={strings[message]} data-test='action-message'/>}

        <div className="container-fluid">
          <Breadcrumbs data={deliveryAddressesBreadcrumbData}/>

          <h2 className='page-title' data-test='delivery-header-title'>{strings.deliveryAddresses}</h2>

          <p className='page-sub-header'>{strings.deliveryAddressesSubHeader}</p>

          <div className="row">
            <div className="col-md-3" data-toggle='modal' data-target='#create-address-modal' data-backdrop="static"
                 data-keyboard="false">
              <Button
                data-test='create-new-address-button'
                value={strings.createNewDeliveryAddresses}
                styleClass='create-new-button'
                iconName='plus'
                bgFill={false}/>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <h4 className='my-delivery-title'>{strings.myDeliveryAddresses}</h4>

              <div className="row">
                {addresses.map((address, key) => (
                  <AddressItem
                    key={key}
                    address={address}
                    editAddressText={strings.editAddress}
                    onEditAddress={this.handleEditAddress}
                    deleteAddress={this.deleteAddress}
                    openAddressDeleteModel={this.openAddressDeleteModel}
                    containerClass='col-md-3'
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        <CreateDeliveryAddressForm
          clear={doClearForm}
          isModalOpen={isModalOpen}
          isEditView={isEditView}
          formModel={addressForm}
          value={selectedAddress}
          onClose={this.handleModalClose}
          onSave={this.handleSaveAddress}/>

        {isLoading && <div className='loader'/>}

      </div>
    );
  }
}
