import Axios from 'axios';

export async function getUserLocationInfo() {
  const URL = 'https://extreme-ip-lookup.com/json/';

  try {
    const response = await Axios(URL, { method: 'GET' });

    if (response && response.data) {
      return {
        loggedInCountry: response.data.country,
        loggedInContinent: response.data.continent,
        loggedInCity: response.data.city,
      };
    }

    return {};
  } catch (error) {
    return {};
  }
}
