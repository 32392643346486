import React, { useCallback } from 'react';
import moment from 'moment';
import $ from 'jquery';

import strings from 'resources/locales/Translate';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import Button from 'library/common/commonComponents/Button/Button';

const AccountStatementFilter = ({ filter, stocks, onChange, user }) => {
  const getYearsOptions = useCallback(() => {
    const yearsArr = [];
    const currentDate = moment();
    const startYear = moment(currentDate).subtract(1, 'years');

    for (let i = 0; i < 2; i++) {
      const start = startYear.format('YY');
      const startFullYear = startYear.format('YYYY');
      const end = startYear.add(1, 'year').format('YY');
      const endFullYear = startYear.format('YYYY');

      yearsArr.push({
        id: i + 1,
        value: `FY${start}/${end}`,
        dateFrom: `${startFullYear}-10-01`,
        dateTo: `${endFullYear}-09-30`,
      });
    }

    return yearsArr;
  }, []);

  const isLALManager = user.stockRoles.some(
    ({ stockId, roleKey }) => stockId === filter.stock && roleKey === 'STOCK_CONTROLLER'
  );

  const yearsOptions = getYearsOptions();

  const openDeductPointsPopup = useCallback(() => {
    $('#deduct-points-modal').modal('show');
  }, []);

  return (
    <div>
      <div className='filter-wrapper my-3'>
        <div className='container-fluid'>
          <div className='row'>
            <Dropdown
              data={stocks}
              hasSection
              filter
              multiSelect={false}
              idKey='id'
              field='stock'
              displayValue={[{ key: 'locationName', separator: '' }]}
              optionsArrayKey='stockInformation'
              titleDisplay={[
                { key: 'shortName', separator: ' ' },
                { key: 'name', separator: '' },
              ]}
              isRequired={true}
              placeholder={strings.pleaseSelect + '...'}
              label={strings.labsLocationPartner}
              onChange={onChange}
              mainContainerStyle='col-md-5'
              value={filter.stock}
              fullValue={filter.stockFullValue}
            />
            <Dropdown
              data={yearsOptions}
              idKey='id'
              field='years'
              multiSelect={true}
              displayValue={[{ key: 'value', separator: '' }]}
              isRequired={true}
              placeholder={strings.pleaseSelect + '...'}
              label={strings.fiscalYear}
              onChange={onChange}
              mainContainerStyle='col-md-5'
              value={filter.years}
              fullValue={filter.yearsFullValue}
              error={filter.yearsError}
            />
            <div className='col-md-2 d-flex align-items-end'>
              <Button
                dataTest='deduct-point-button'
                bgFill={false}
                value={strings.deductPoints}
                styleClass='mt-auto'
                onClick={openDeductPointsPopup}
                disabled={!filter.stock || (!isLALManager && !user?.admin)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountStatementFilter;
