import moment from "moment";

export const scrollToTop = (scrollDuration) => {
  const scrollHeight = window.scrollY,
    scrollStep = Math.PI / (scrollDuration / 15),
    cosParameter = scrollHeight / 2;
  let scrollCount = 0,
    scrollMargin,
    scrollInterval = setInterval(function () {
      if (window.scrollY !== 0) {
        scrollCount = scrollCount + 1;
        scrollMargin = cosParameter - cosParameter * Math.cos(scrollCount * scrollStep);
        window.scrollTo(0, (scrollHeight - scrollMargin));
      } else clearInterval(scrollInterval);
    }, 15);
};

/**
 * Scrolls the calendar automatically to a specified date.
 * @param {string | Date} toDate  The date to which the calendar should scroll.
 * 
 * 150, 125, 100, 30 represent the cell widths in the calendar, set based on
 * the window width
 * (((cellWidth * 2) + 0.1) * 11): Each hour spans 2 blocks; since each day has 11 hours, 
 * we multiply to get the scroll position for the weekday. 
 */
export const scrollToDateOnCalendar = (toDate = undefined) => {
  try {
    let calendarContentRef = document.querySelectorAll('#content-wrapper');
    let calendarHeadersRef = document.querySelectorAll('#header-wrapper');
    let windowWidth = window.innerWidth;
    let cellWidth;

    if (windowWidth > 5000) {
      cellWidth = 150;
    } else if (windowWidth > 4000) {
      cellWidth = 125;
    } else if (windowWidth > 1990) {
      cellWidth = 100;
    } else {
      cellWidth = 30;
    }

    if (calendarContentRef.length && calendarHeadersRef.length && toDate) {
      let scrollToDate = moment(toDate);
      let weekdayNumber = scrollToDate.isoWeekday();
      let scrollTo = (weekdayNumber - 1) * (((cellWidth * 2) + 0.1) * 11);
      calendarContentRef.forEach(node => {
        node.scrollLeft = scrollTo;
      });
      calendarHeadersRef.forEach(node => {
        node.scrollLeft = scrollTo;
      });
    }
  } catch (err) {
    console.error("Error during auto scroll to calendar date:", toDate, err);
  }
};
