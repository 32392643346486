import { GET_STATUS_LIST } from './StatusList.constants';

const initialState = {
  statusList: {},
};

const StatusListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STATUS_LIST:
      return {
        ...state,
        statusList: action.payload
      };
    default:
      return state;
  }
};

export default StatusListReducer;
