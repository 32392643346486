import { connect } from 'react-redux';

import AddActiveSystemPopup from './AddActiveSystemPopup.component';
import { saveActiveSystem } from '../../ActiveSystems.actions';

const mapStateToProps = ({ languageReducer, masterDataReducer }) => ({
  language: languageReducer,
  systemClasses: masterDataReducer.categories,
});

export default connect(mapStateToProps, { saveActiveSystem })(AddActiveSystemPopup);
