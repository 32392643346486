import React, { useEffect, useState } from 'react';
import * as $ from 'jquery';
import { connect, useSelector } from 'react-redux';

import Icon from 'library/common/commonComponents/Icon/Icon';
import strings from 'resources/locales/Translate';
import Button from 'library/common/commonComponents/Button/Button';
import FormComponent from 'library/common/commonComponents/FormComponent/FormComponent';
import { formModel } from 'modules/ContactUs/ContactUsConstants';
import axiosInstance from 'library/utilities/AxiosInstance';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';

const ContactUs = ({ contactOpened, onContactChange }) => {
  const [clear, setClear] = useState(false);
  const [actionMessage, setActionMessage] = useState({ display: false, type: '', message: '' });
  const authentication = useSelector(state => state.authReducer);
  const [formValue, setFormValue] = useState({ email: '' });
  const [loading, setLoading] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(false);

  const formRef = React.createRef();

  useEffect(() => {
    if (contactOpened && authentication.isLoggedIn) {
      setFormValue({ email: authentication.user.email });
    }
  }, [authentication, contactOpened]);

  const handleFormData = () => {
    setIsDisabledButton(false);
    setClear(false);
  };

  const handleSave = () => {
    const { formData, isFormValid } = formRef.current.getFormData();
    if (isFormValid) {
      const { comment, email, subject } = formData;
      setLoading(true);
      axiosInstance
        .post(URLS.contactMessage, { comment, email, subject })
        .then(({ status }) => {
          if (status === 200) {
            setActionMessage({ display: true, type: 'success', message: 'contactMessageSentSuccessfully' });
            setIsDisabledButton(true);
          } else {
            setActionMessage({ display: true, type: 'error', message: 'somethingWentWrongMessage' });
            setTimeout(() => {
              setActionMessage({ display: false, type: '', message: '' });
            }, 4000);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setActionMessage({ display: true, type: 'error', message: 'somethingWentWrongMessage' });
          setTimeout(() => {
            setActionMessage({ display: false, type: '', message: '' });
          }, 4000);
        })
    } else {
      setActionMessage({ display: true, type: 'error', message: 'formIsInvalid' });
      setTimeout(() => {
        setActionMessage({ display: false, type: '', message: '' });
      }, 4000);
    }
  };

  const closeModal = () => {
    onContactChange();
    setClear(true);
    setIsDisabledButton(false);
    setActionMessage({ display: false, type: '', message: '' });
    $('#contact-us').modal('hide');
  };

  return (
    <div
      className='modal fade loginModal'
      id='contact-us'
      tabIndex='-1'
      role='dialog'
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'
      data-backdrop='static'
      data-keyboard='false'
    >
      <div className='modal-dialog modal-md' role='document'>
        <div className='modal-content rounded-0 '>
          <div className='modal-body p-0'>
            <div className='row'>
              <div className='col-sm-12'>
                <div className='content position-relative'>
                  <button type='button' className='close modal-close position-absolute' onClick={closeModal}>
                    <Icon name='cross' width={24} height={24} />
                  </button>

                  <h3 className='login-title mb-2'>{strings.contactUsTitle}</h3>
                  <p className='mb-3 text-small'>{strings.contactUsText}</p>
                  {contactOpened && (
                    <FormComponent
                      ref={formRef}
                      clear={clear}
                      model={formModel}
                      value={formValue}
                      onChange={handleFormData}
                    />
                  )}
                  {actionMessage.display && (
                    <p
                      style={{
                        color: actionMessage.type === 'error' ? '#cc0000' : '#4ab300',
                      }}
                    >
                      {strings[actionMessage.message]}
                    </p>
                  )}
                  <div className='row mt-3'>
                    <div className='col-md-4' data-dismiss='modal' aria-label='Close'>
                      <Button
                        iconName='cross'
                        bgFill={false}
                        value={strings.cancel}
                        onClick={closeModal}
                      />
                    </div>
                    <div className='col-md-8'>
                      <Button
                        loading={loading}
                        iconName='save' 
                        value={strings.save}
                        onClick={handleSave}
                        disabled={isDisabledButton}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ languageReducer }) => {
  return {
    language: languageReducer.language,
  };
};

export default connect(mapStateToProps)(ContactUs);
