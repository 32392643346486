import DateInput from 'library/common/commonComponents/FormComponent/Optimized/DateInput';
import Table from 'library/common/commonComponents/Table/Table';
import React from 'react'
import strings from 'resources/locales/Translate';
import { useControllingReportTab } from './ControllingReportTab.hook';
import ItemsPerPage from 'library/common/commonComponents/ItemsPerPage/ItemsPerPage';
import Pagination from 'library/common/commonComponents/Pagination/Pagination';
import { toArray } from 'lodash';
import {itemsPerPage} from '../../LLManagement.constants'
import Button from 'library/common/commonComponents/Button/Button';


 const ControllingReportTab = props => {
  const {
    controllingReport,
    // language,
    // getControllingReport
  } = props;
  const {
    data,
    isShowTable,
    controllingReportCols,
    isLoading,
    exportList,
    handleDateChange,
    handleSort,
    handleItemsPerPage,
    handlePageChange
  } = useControllingReportTab(props);

  return (
    <>
      <div className='filter-wrapper my-3'>
      <DateInput
              label={strings.date}
              field='date'
              placeholder={strings.pleaseSelectDate + '...'}
              onChange={handleDateChange}
              isRequired={false}
              mainContainerStyle='col-md-4'
              isRange={false}
              clearIcon={false}
              selectedDate={data.date}
            />
      </div>
      <div className='container-fluid mb-3'>
        {isShowTable ? (
          <div className='controlling-report-table'>
          <Table cols={controllingReportCols} rows={controllingReport.content} handleSort={handleSort}
            sortFields={data.sort} />
        </div>
        ) : (
          <div className='d-flex justify-content-center my-5'>
            <strong>{strings.noDataFoundText}</strong>
          </div>
        )}
        <div className='row no-gutters mt-3'>
          {isShowTable && (
            <>
              <div className='col-md-auto'>
              <Button
                  dataTest='export-list-button'
                  bgFill={false}
                  iconName='download'
                  value={strings.exportList}
                  styleClass='mt-0'
                  onClick={exportList}
                  loading={isLoading}
                />
              </div>
              <div className='col d-flex justify-content-end'>
              <ItemsPerPage
                  itemsPerPage={toArray(itemsPerPage)}
                  handleItemsPerPage={handleItemsPerPage}
                  value={itemsPerPage[data.size]}
                />
                <Pagination
                  currentPage={data.page}
                  totalPages={controllingReport.totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ControllingReportTab;
