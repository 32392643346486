import {
  UPDATE_L_L_KIT_FORM,
  FETCH_STOCKS_FOR_LAB_LOCATION_BU,
  FETCH_SYSTEM_CLASSES_LAB_LOCATION_BU,
  FETCH_SYSTEM_MAIN_COMPONENT_LAB_LOCATION_BU,
  FETCH_LABS_LOCATION_CONDITIONS,
  FETCH_ACTIVE_UNTIL_DATE,
  FETCH_LAB_LOCATION_KIT_DETAILS,
  RESET_LAB_LOCATION_KIT_FORM,
} from './CreateLLKit.constants';

const EMPTY_FORM = {
  formData: {},
  isFormValid: false,
};

export const INITIAL_STATE = {
  kitInformationId: null,
  kitDetails: null,
  generalInformationForm: EMPTY_FORM,
  informationForm: {
    formData: {
      businessUnit: 9,
      businessUnitFullValue: { id: 9, name: 'RMS labs@location Partner' },
    },
    isFormValid: false,
  },
  kitStatusForm: {
    formData: {
      kitCondition: 6,
      kitConditionFullValue: { kitStatusId: 6, translatedStatus: 'Ok' },
    },
    isFormValid: false,
  },
  furtherImportantComponentForm: EMPTY_FORM,
  salesComponentForm: EMPTY_FORM,
  freeAccessoriesForm: EMPTY_FORM,
  kitImages: [],
  kitAttachments: [],
  kitImagesToDelete: [],
  kitAttachmentsToDelete: [],
  stocks: [],
  systemClasses: [],
  systemMainComponent: [],
  conditions: [],
  activeUntil: null,
};

export const CreateLLKitReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_L_L_KIT_FORM:
      return { ...state, ...action.payload };
    case FETCH_STOCKS_FOR_LAB_LOCATION_BU:
      return { ...state, stocks: action.payload };
    case FETCH_SYSTEM_CLASSES_LAB_LOCATION_BU:
      return { ...state, systemClasses: action.payload };
    case FETCH_SYSTEM_MAIN_COMPONENT_LAB_LOCATION_BU:
      return { ...state, systemMainComponent: action.payload };
    case FETCH_LABS_LOCATION_CONDITIONS:
      return { ...state, conditions: action.payload };
    case FETCH_ACTIVE_UNTIL_DATE:
      return { ...state, activeUntil: action.payload };
    case FETCH_LAB_LOCATION_KIT_DETAILS:
      return { ...state, ...action.payload, kitInformationId: action.payload.kitDetails.kitInformationId };
    case RESET_LAB_LOCATION_KIT_FORM:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export default CreateLLKitReducer;
