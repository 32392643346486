import React from 'react';

import Modal from 'library/common/commonComponents/Modal';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import strings from 'resources/locales/Translate';

import { useRelatedTransactions } from './useRelatedTransactions';

export default function RelatedTransactions({
  id,
  type,
  bundleLoans,
  transactionDetails,
  onEditPress,
  setConflictedTransactions,
  handleSaveTransactionDetails,
}) {
  const {
    isLoading,
    error,
    handleSelect,
    handleSelectAll,
    handleConfirmAction,
    onCloseClick,
    isSelectedAll,
    transactionsId,
  } = useRelatedTransactions({
    id,
    type,
    onEditPress,
    setConflictedTransactions,
    handleSaveTransactionDetails,
    transactionDetails,
    bundleLoans,
  });

  return (
    <Modal
      id={id}
      className='mt-5 modal-lg'
      title={strings.adoptChangeForSubsequentTransactions}
      confirmAction={handleConfirmAction}
      confirmDataTest='related-transactions-popup'
      titleOfCancel={strings.relatedTransactionsCancel}
      confirmTitle={strings.relatedTransactionsConfirm}
      loading={isLoading}
      onCloseClick={onCloseClick}
    >
      <Checkbox
        selected={isSelectedAll}
        field='0'
        onChange={handleSelectAll}
        label={strings.selectAll}
      />
      <Checkbox
        selected={transactionsId.includes(transactionDetails.transactionsId)}
        field={transactionDetails.transactionsId}
        onChange={handleSelect(transactionDetails.transactionsId)}
        label={`#${transactionDetails.transactionNumber} (${strings.currentTransaction})`}
        disabled={true}
      />
      {
        bundleLoans && bundleLoans.map(loans => (
          <Checkbox
            selected={transactionsId.includes(loans.transactionsId)}
            field={loans.transactionsId}
            onChange={handleSelect(loans.transactionsId)}
            label={`#${loans.transactionNumber}`}
          />
        ))
      }
      {error && <span className='text-danger error-block'>{error}</span>}
    </Modal>
  );
};
