import Modal from 'library/common/commonComponents/Modal';
import React, { useEffect } from 'react';
import strings from 'resources/locales/Translate';
import Validators from 'library/utilities/Validators';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import ToggleSwitch from 'library/common/commonComponents/ToggleSwitch';
import DateInput from 'library/common/commonComponents/FormComponent/Optimized/DateInput';
import { useAddSchedulePopup } from './AddSchedulePopup.hook';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import { connect } from 'react-redux';
import { getScheludeTypeList, addSchedule, getAllScheduledEventList, editSchedule } from 'modules/Homepage/HomepageActions';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { RecurringEventOptionsPopup } from '../RecurringEventsOptions/RecurringEventsPopup.component';

export const AddSchedulePupup = ({
  getScheludeTypeList,
  scheludeTypeList,
  addSchedule,
  user,
  getAllScheduledEventList,
  id,
  toggleActionMessage,
  language,
  editing,
  onEditClick,
  scheduledDetails,
  convetTime,
  editSchedule,
  setPayloadDataForEventPopup,
  setEditing,
}) => {
  const {
    handleOnDayClick,
    selectedDays,
    onChangeRecurring,
    recurring,
    onChangeDate,
    date,
    startDate,
    endDate,
    timeSlotList,
    onChangeDropdown,
    scheduleType,
    startTime,
    endTime,
    allDay,
    onChangeCheckbox,
    onInputChange,
    title,
    onSaveClick,
    scheduleTypeError,
    dateError,
    startDateError,
    endDateError,
    startTimeError,
    endTimeError,
    selectedDaysError,
    onCloseClick,
    loading,
  } = useAddSchedulePopup({
    id,
    getScheludeTypeList,
    addSchedule,
    user,
    getAllScheduledEventList,
    toggleActionMessage,
    language,
    editing,
    onEditClick,
    scheduledDetails,
    convetTime,
    editSchedule,
    setPayloadDataForEventPopup,
    setEditing,
  });
  return (
    <Modal
      id={id}
      confirmAction={onSaveClick}
      title={editing ?strings.editSchedule :strings.addSchedule}
      disableBackgroundClose
      onCloseClick={onCloseClick}
      loading={loading}
      titleOfCancel={strings.cancel}
      confirmTitle={strings.save}
    >
      <div className='col-12 p-0'>
        <div>
          <InputField
            label={strings.scheduleInputTitle}
            field='title'
            placeholder=''
            isRequired={true}
            type='text'
            validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
            value={title.value}
            error={title.error}
            onChange={onInputChange}
            // bigSizeInput={'bigger-input-field'}
          />
        </div>
        <div className='col-12 p-0 mt-3'>
          <Dropdown
            data={scheludeTypeList}
            hasSection={false}
            multiSelect={false}
            filter={false}
            idKey='id'
            field='scheduleType'
            displayValue={[{ key: 'name', separator: ' ' }]}
            label={strings.selectType}
            isRequired
            validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
            placeholder={strings.pleaseSelect}
            onChange={onChangeDropdown}
            value={scheduleType.value}
            fullValue={scheduleType.fullValue}
            error={scheduleTypeError}
          />
        </div>
        <div className='row mt-3'>
          <div className='col-4'>{strings.recurring}</div>
          <div className='col-2' onClick={onChangeRecurring}>
            <ToggleSwitch checked={recurring} />
          </div>
        </div>

        {recurring && (
          <div className='d-flex mt-2'>
            <div className={selectedDays.Monday ? 'selected-days' : 'days'} onClick={() => handleOnDayClick('Monday')}>
              M
            </div>
            <div
              className={selectedDays.Tuesday ? 'selected-days' : 'days'}
              onClick={() => handleOnDayClick('Tuesday')}
            >
              T
            </div>
            <div
              className={selectedDays.Wednesday ? 'selected-days' : 'days'}
              onClick={() => handleOnDayClick('Wednesday')}
            >
              W
            </div>
            <div
              className={selectedDays.Thursday ? 'selected-days' : 'days'}
              onClick={() => handleOnDayClick('Thursday')}
            >
              TH
            </div>
            <div className={selectedDays.Friday ? 'selected-days' : 'days'} onClick={() => handleOnDayClick('Friday')}>
              F
            </div>
          </div>
        )}
        {!recurring && (
          <div className='col-6 p-0 mt-3'>
            <DateInput
              field='dateRange'
              label={strings.date}
              placeholder={strings.pleaseSelectDate}
              selectedDate={date}
              onChange={e => onChangeDate(e, 'date')}
              isRequired={true}
              isRange={false}
              minDate={new Date()}
              error={dateError}
            />
          </div>
        )}
        {recurring && (
          <div className='row mt-3'>
            <div className='col-6'>
              <DateInput
                field='dateRange'
                label={strings.startDate}
                placeholder={strings.pleaseSelectDate}
                selectedDate={startDate}
                onChange={e => onChangeDate(e, 'start')}
                isRequired={true}
                isRange={false}
                minDate={new Date()}
                error={startDateError}
              />
            </div>
            <div className='col-6'>
              <DateInput
                field='dateRange'
                label={strings.endDate}
                placeholder={strings.pleaseSelectDate}
                selectedDate={endDate}
                onChange={onChangeDate}
                isRequired={true}
                isRange={false}
                error={endDateError}
                minDate={new Date()}
              />
            </div>
          </div>
        )}

        <div className='row mt-3'>
          <div className='col-6'>
            <Dropdown
              data={timeSlotList}
              hasSection={false}
              multiSelect={false}
              filter={false}
              idKey='id'
              field='startTime'
              displayValue={[{ key: 'value', separator: ' ' }]}
              label={strings.startTime}
              isRequired
              validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
              placeholder={strings.pleaseSelect}
              onChange={onChangeDropdown}
              value={startTime.value}
              fullValue={startTime.fullValue}
              error={startTimeError}
              disabled={allDay}
            />
          </div>
          <div className='col-6'>
            <Dropdown
              data={timeSlotList}
              hasSection={false}
              multiSelect={false}
              filter={false}
              idKey='id'
              field='endTime'
              displayValue={[{ key: 'value', separator: ' ' }]}
              label={strings.endTime}
              isRequired
              validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
              placeholder={strings.pleaseSelect}
              onChange={onChangeDropdown}
              value={endTime.value}
              fullValue={endTime.fullValue}
              error={endTimeError}
              disabled={allDay}
            />
          </div>
        </div>
        <div className='mt-3'>
          <Checkbox label={strings.allDay} field='allDay' selected={allDay} onChange={onChangeCheckbox} />
        </div>
        {selectedDaysError && <div className='error-block mt-2'>{selectedDaysError}</div>}
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ authReducer, homepage, languageReducer }) => ({
  user: authReducer.user,
  scheludeTypeList: homepage.scheludeTypeList,
  language: languageReducer.language,
  scheduledDetails: homepage.scheduledDetails,
});

export default connect(mapStateToProps, {
  getScheludeTypeList,
  addSchedule,
  getAllScheduledEventList,
  toggleActionMessage,
  editSchedule
})(AddSchedulePupup);
