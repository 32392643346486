import { connect } from 'react-redux';

import { fetchKitDetailsMessages, fetchKitDetailsComments } from 'library/common/commonActions/MessagesCommentsActions';

import Messages from './KitMessages.component';

const mapStateToProps = ({ kitDetailsReducer }) => ({
  damageLossReportData: kitDetailsReducer.damageLossReportData,
  commentsData: kitDetailsReducer.commentsData,
});

export default connect(mapStateToProps, {
  fetchKitDetailsMessages,
  fetchKitDetailsComments,
})(Messages);
