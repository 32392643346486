import { batchActions } from 'redux-batched-actions';
import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { logNetworkError } from 'library/utilities/logError';

import { createStockDeliveryTimeService, getStockDeliveryTimeService, getStockProvincesDeliveryTimeService, getStates, getStatesSpecialDeliveryTime } from '../../CreateStock.services';

export const createStockDeliveryTime = (data, idsToDelete, stockId) => dispatch => {
  dispatch(changeLoader(true));
  return createStockDeliveryTimeService(
    {
      deletedIds: idsToDelete,
      deliveryDays: data,
    },
    stockId,
  )
    .then(async ({ status }) => {
      if (status === 200) {
        dispatch(toggleActionMessage(true, 'success', 'stockInformationSavedSuccessfully'));
        const res = await dispatch(fetchStockDeliveryTime(stockId))
        dispatch(changeLoader(false));
        return res;
      } 
      dispatch(changeLoader(false));
    })
    .catch(err => {
      logNetworkError(err);
      dispatch(changeLoader(false));
      if (err.response.status === 500) {
        dispatch(toggleActionMessage(true, 'error', 'errorOccuredText'));
      }
      return { success: false };
    });
};

export const fetchStockDeliveryTime = stockId => dispatch => {
  dispatch(changeLoader(true));
  return getStockDeliveryTimeService(stockId)
    .then(({ status, data }) => {
      dispatch(changeLoader(false));
      if (status === 200) {
        return { success: true, data };
      }
      return { success: false };
    })
    .catch(err => {
      logNetworkError(err);
      dispatch(changeLoader(false));
      if (err.response.status === 500) {
        dispatch(toggleActionMessage(true, 'error', 'errorOccuredText'));
      }
      return { success: false };
    });
};

export const fetchStockProvincesDeliveryTime = stockId => dispatch => {
  dispatch(changeLoader(true));
  return getStockProvincesDeliveryTimeService(stockId)
    .then(({ status, data }) => {
      dispatch(changeLoader(false));
      if (status === 200) {
        return { success: true, data };
      }
      return { success: false };
    })
    .catch(err => {
      logNetworkError(err);
      dispatch(changeLoader(false));
      if (err.response.status === 500) {
        dispatch(toggleActionMessage(true, 'error', 'errorOccuredText'));
      }
      return { success: false };
    });
};

export const getProvincesOfCountry = countryId => async dispatch => {
  if (countryId) {
    dispatch(changeLoader(true));
    return getStates(countryId)
      .then(({ data, status }) => {
        dispatch(changeLoader(false));
        if (status === 200 && data) {
          return {success: true, data};
        }
        return { success: false };
      })
      .catch(err => {
        logNetworkError(err);
        batchActions([
          dispatch(changeLoader(false)),
          dispatch(toggleActionMessage(true, 'error', 'errorOccuredText')),
        ]);
        return { success: false };
      });
  }
  return null;
};

export const fetchStateHasSpecialDeliveryTime = (stockId, countryId , stateId)=> async dispatch => {
  if (stockId && countryId && stateId) {
    dispatch(changeLoader(true));
    return getStatesSpecialDeliveryTime(stockId, countryId , stateId)
      .then(({ data, status }) => {
        dispatch(changeLoader(false));
        if (status === 200 && data) {
          return {success: true, data};
        }
        return { success: false };
      })
      .catch(err => {
        logNetworkError(err);
        batchActions([
          dispatch(changeLoader(false)),
          dispatch(toggleActionMessage(true, 'error', 'errorOccuredText')),
        ]);
        return { success: false };
      });
  }
  return null;
};