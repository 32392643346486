import $ from 'jquery';
import useTitle from 'library/common/commonHooks/useTitle.hook';
import { useState, useCallback } from 'react';
import strings from 'resources/locales/Translate';

export const useMySchedule = ({ getScheduledDetailes }) => {
  useTitle(strings.mySchedule)
  const [editing, setEditing] = useState(false);
  const openMyShedule = () => {
    $('#add-my-schedule').modal('show');
  };

  const eventPropGetter = useCallback(
    (event, start, end, isSelected) => ({
      ...(event?.isDemo && {
        className: 'demo-slot-booking',
      }),
    }),
    []
  )

  const onClickEvent = async (event) => {
    if(event?.isDemo){
      window.location.href = `/#/transactions/${event.transactionId}/isZmcc?detailPage=1&edit=false`
      return
    }
   const res = await getScheduledDetailes(event.id);
    $('#view-edit-schedule').modal('show');
  };

  const onEditClick = value => {
    setEditing(value);
    $('#view-edit-schedule').modal('hide');
    setTimeout(() => {
      $('#add-my-schedule').modal('show');
    }, 500);
  };

  const convetTime = time => {
    var timeArray = time.split(':');
    var hours = parseInt(timeArray[0], 10);
    var minutes = timeArray[1];
    var period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    var time12 = hours + ':' + minutes + ' ' + period;
    return time12;
  };

  return {
    openMyShedule,
    onClickEvent,
    onEditClick,
    editing,
    convetTime,
    setEditing,
    eventPropGetter
  };
};
