import {ADD_KIT_TO_CART, LOAN_LIST_ALTERNATE_DATE_MESSAGE} from "library/common/commonConstants/storeConstants";

const initialState = {
  cart: [],
  alternateMessageData: {
    displayMessage: false,
    from: '',
    to: '',
  }
};

const LoanListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_KIT_TO_CART:
      return {
        ...state,
        cart: action.payload
      };
    case LOAN_LIST_ALTERNATE_DATE_MESSAGE:
      return {
        ...state,
        alternateMessageData: action.payload
      };
    default:
      return state;
  }
};

export default LoanListReducer;
