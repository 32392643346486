import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import Modal from 'library/common/commonComponents/Modal';
import * as $ from 'jquery';
import strings from 'resources/locales/Translate';

export function RouterCustomPrompt(props) {
  const { when, onOK, onCancel, title, okText, cancelText } = props;

  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");
  
  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        $('#leave-zmcc-form').modal('show');
        setShowPrompt(true);
        return "true";
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, when]);

  const handleOK = useCallback(async () => {
    if (onOK) {
      $('#leave-zmcc-form').modal('hide');
      const canRoute = await Promise.resolve(onOK());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }

  }, [currentPath, history, onOK]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      $('#leave-zmcc-form').modal('hide');
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  return showPrompt ? (
    <>
        <Modal
            id="leave-zmcc-form"
            title={title}
            visible={showPrompt}
            confirmAction={handleOK}
            confirmTitle={okText}
            onCloseClick={handleCancel}
            titleOfCancel={cancelText}
            closable={true}
            confirmIcon=""
        >
            {strings.leaveWarningMessage}
        </Modal>
    </>
  ) : null;
}

