
export const useXRMApplicationSampleDetails = (props) =>{
    const handleForm = ({ values, field, formName }) => {
        props.updateSEMForm({
          [formName]: {
            ...props.formValue[formName],
            formData: {
              ...((props.formValue[formName] && props.formValue[formName].formData) || {}),
              ...values,
            },
          },
        });
        props.setUnsavedChanges(true);
      };
    
      const handleNonFormComponent = (values, field, formName) => {
        props.updateSEMForm({
          [formName]: {
            ...props.formValue[formName],
            formData: {
              ...((props.formValue[formName] && props.formValue[formName].formData) || {}),
              [field]: values,
              [`${field}Error`]: null,
            },
          },
        });
      };

      return {
        handleForm,
        handleNonFormComponent,
      };
}