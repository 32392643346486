import React, {PureComponent} from 'react';

import './actionMessageStyles.scss';
import Icon from "../Icon/Icon";

const colors = {
  error: '#cc0000',
  success: '#4ab300',
  info: '#0088d0'
};

export class ActionMessage extends PureComponent{
  render() {
    const {message, type} = this.props;
    return(
      <div className="action-message-container" style={{backgroundColor: colors[type]}}>
        <div className="container-fluid text-white d-flex justify-content-between align-items-center">
          <div>
            <Icon name='info' width={16} height={16} fill='#fff' className='action-icon' />
            <span>{message}</span>
          </div>
          <div>
            <button className="close-message p-0" onClick={() => this.props.toggleActionMessage(false)}>
              <Icon name='cross' width={20} height={20} fill='#fff' className='action-icon p-0' />
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default ActionMessage;
