import React from 'react';

import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import Modal from 'library/common/commonComponents/Modal';
import strings from 'resources/locales/Translate';
import { useCreateOrEditMapping } from './CreateOrEditMapping.hook';

const CreateOrEditMapping = (props) => {
    const {
        id,
        selectedMapping,
        setSelectedMapping,
        hasEditMode,
        data,
        createOrEditMapping,
        countries,
    } = props;
    const { mappingForm, loading,
        error,
        onSaveClick,
        handleForm,
        onCloseClick,
        model, } = useCreateOrEditMapping({
            id,
            selectedMapping,
            setSelectedMapping,
            hasEditMode,
            data,
            countries,
            createOrEditMapping,
        });

    return (
        <Modal
            id={id}
            title={strings.addMapping}
            confirmAction={onSaveClick}
            confirmTitle={strings.save}
            loading={loading}
            titleOfCancel={strings.cancel}
            disableBackgroundClose
            onCloseClick={onCloseClick}>
            <FormComponent
                model={model}
                formName='mappingForm'
                formValue={mappingForm.formData}
                onChange={handleForm} />
            {error && <div className='error-block'>{strings[error]}</div>}
        </Modal>
    );

};

export default CreateOrEditMapping;
