import React from 'react';
import StocksFilter from '../StocksFilter/StocksFilter.component';

export const Stocks = () => {
  return (
    <div>
      <StocksFilter />
    </div>
  );
};

export default Stocks;
