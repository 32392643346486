import { connect } from 'react-redux';

import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';

import {
  deleteLoansAttachments,
  uploadLoanAttachment,
  addAttachments,
  deleteAttachments,
  clearAttachments,
} from '../../TransactionDetailsActions';
import Attachments from './Attachments.component';

const mapStateToProps = ({ authReducer, languageReducer, transactionDetailsReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
  transactionDetails: transactionDetailsReducer.transactionDetails,
  attachments: transactionDetailsReducer.attachments,
  deletedAttachmentIds: transactionDetailsReducer.deletedAttachmentIds,
});

export default connect(mapStateToProps, {
  uploadLoanAttachment,
  deleteLoansAttachments,
  deleteAttachments,
  addAttachments,
  clearAttachments,
  toggleActionMessage,
})(Attachments);
