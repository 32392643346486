import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

class BodyView extends Component {
  static propTypes = {
    schedulerData: PropTypes.object.isRequired,
  };

  render() {
    const { schedulerData, isZMCCCalendar } = this.props;
    const { renderData, headers, config, behaviors } = schedulerData;
    let cellWidth = schedulerData.getContentCellWidth();
    let tableRows = renderData.map(item => {
      let rowCells = headers.map((header, index) => {
        let key = item.slotId + '_' + header.time;
        let style = index === headers.length - 1 ? {} : { width: cellWidth };
        if (!!header.nonWorkingTime) style = { ...style, backgroundColor: config.nonWorkingTimeBodyBgColor };
        // if (item.groupOnly) style = { ...style, backgroundColor: 'rgba(0, 0, 0, 0.08)' };
        if (!!behaviors.getNonAgendaViewBodyCellBgColorFunc) {
          let cellBgColor = behaviors.getNonAgendaViewBodyCellBgColorFunc(schedulerData, item.slotId, header);
          if (!!cellBgColor) style = { ...style, backgroundColor: cellBgColor };
        }
        return (
          <td key={key} style={style}>
            <div />
          </td>
        );
      });

      return (
        <tr key={item.slotId} style={{ height: item.rowHeight }}>
          {rowCells}
        </tr>
      );
    });

    return <tbody>{tableRows}</tbody>;
  }
}

export default BodyView;
