import React, { useRef } from 'react';

import { useTooltip } from './Tooltip.hook';
import './Tooltip.styles.scss';

const Tooltip = () => {
  const tooltipRef = useRef(null);
  const { tooltipText, config } = useTooltip(tooltipRef);

  if (!tooltipText) return null;

  return (
  <div className='customTooltip' ref={tooltipRef}>
    {config && config.arrow && <div className='tooltipArrow' />}
    <div className='tooltipContent'>{tooltipText}</div>
  </div>
  )
};

export default Tooltip;
