import React, { useState, useEffect } from "react";
import strings from 'resources/locales/Translate';
import cloneDeep from 'lodash/cloneDeep';
import Icon from "library/common/commonComponents/Icon/Icon";
import Button from 'library/common/commonComponents/Button/Button';
import ConfirmDeletionPopup from 'library/common/commonComponents/ConfirmationBox/ConfirmationBox.component';
import debounce from 'lodash/debounce';
import $ from 'jquery';
import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { FETCH_COUNTRY_LIST } from 'library/common/commonConstants/storeConstants';

const INITIAL_FILTER = {
    searchTerm: null,
}

export const useMedUsWarehouseAddress = (props) =>{

    const { masterData, deleteMedAddress } = props;
    const filter = { ...INITIAL_FILTER };
    const [data, setData] = useState({
        page: 1,
        size: 10,
        sort:{},
        filter,
    });

    const [selectedAddress, setSelectedAddress] = useState(null);

    useEffect(() =>{
        props.medUsAddress(data);
        if (masterData && !masterData.countries && !masterData.countries.length) {
          fetchMasterData(URLS.countryList, FETCH_COUNTRY_LIST);
        }
    },[data]);

    const getTableRows = () => [
      {
        title: strings.salutation,
        name: 'salutation',
        isSort: false,
        render: item => {
          return <span>{item.salutation || '-'}</span>;
        },
      },
      {
        title: strings.firstName,
        name: 'firstName',
        isSort: false,
        render: item => {
          return <span>{item.firstName || '-'}</span>;
        },
      },
      {
        title: strings.lastName,
        name: 'lastName',
        isSort: false,
        render: item => {
          return <span>{item.lastName || '-'}</span>;
        },
      },
      {
        title: strings.organization,
        name: 'organization',
        isSort: false,
        render: item => {
          return <span>{item.organization || '-'}</span>;
        },
      },
      {
        title: strings.department,
        name: 'department',
        isSort: false,
        render: item => {
          return <span>{item.department || '-'}</span>;
        },
      },
      {
        title: strings.street,
        name: 'street',
        isSort: false,
        render: item => {
          return <span>{item.street}</span>;
        },
      },
      {
        title: strings.houseNumber,
        name: 'number',
        isSort: false,
        render: item => {
          return <span>{item.houseNumber || '-'}</span>;
        },
      },
      {
        title: strings.postalCode,
        name: 'postalCode',
        isSort: false,
        render: item => {
          return <span>{item.postalCode}</span>;
        },
      },
      {
        title: strings.state,
        name: 'state',
        isSort: false,
        render: item => {
          return <span>{item.state || '-'}</span>;
        },
      },
      {
        title: strings.city,
        name: 'city',
        isSort: false,
        render: item => {
          return <span>{item.city}</span>;
        },
      },
      {
        title: strings.phone,
        name: 'phone',
        isSort: false,
        render: item => {
          return <span>{item.phone}</span>;
        },
      },
      {
        title: strings.countryLand,
        name: 'country',
        isSort: false,
        render: item => {
          return <span>{item.country.name}</span>;
        },
      },
      {
        title: strings.email,
        name: 'email',
        isSort: false,
        render: item => {
          return <span>{item.email}</span>;
        },
      },
      {
        title: '',
        name: 'edit',
        render: item => {
          return (
            <button className='button-blue' onClick={() => openEditPopup(item)}>
              <Icon name='edit' width={16} height={16} fill='#0088d0' />
              <span className='ml-1'>{strings.edit}</span>
            </button>
          );
        },
      },
      {
        title: '',
        name: 'delete',
        render: item => {
          return (
            <div>
              <Button
                bgFill={false}
                isGhost={true}
                iconWidth={16}
                iconHeight={16}
                iconPosition='left'
                iconStyle='mr-1'
                iconName='delete'
                onClick={() => openPopup(item.meditechUsAddressId)}
                value={strings.delete}
                styleClass='small-blue-button'
              />
              <ConfirmDeletionPopup
                id={`confirmDeleteModal${item.meditechUsAddressId}`}
                onConfirm={() => deleteMedAddress(item.meditechUsAddressId, data)}
                title={strings.deleteAddress}
                subtitle={strings.deleteTheAddress}
              />
            </div>
          );
        },
      },
    ];

    const handleSearchChange = e => {
        const { value } = e.target;
        debounceUpdateData({ value });
    };

    const debounceUpdateData = debounce(({ value }) => {
        setData(
            prevState => {
                const newData = { ...prevState };
                newData.page = 1;
                newData.filter.searchTerm = value;
                return { ...newData };
            });
    }, 1000);

    const handleItemsPerPage = size => {
        setData(prevState => {
            const newData = { ...prevState };
            newData.size = size;

            return newData;
        });
    };

    const handlePageChange = page => {
        setData(prevState => {
            const newData = { ...prevState };
            newData.page = page + 1;

            return newData;
        });
    };

    const openPopup = id => {
      if ($(`confirmDeleteModal${id}`) && $(`confirmDeleteModal${id}`).modal) {
        $(`#confirmDeleteModal${id}`).modal('show');
      }
    };

    const openEditPopup = item => {
      setSelectedAddress(item);
      $('#edit-med-address').modal('show');
    };
    return {
      getTableRows,
      data,
      handleSearchChange,
      handleItemsPerPage,
      handlePageChange,
      selectedAddress,
    };
}