import Validators from 'library/utilities/Validators';

export const taskModel = [
  {
    label: 'systemClassText',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'systemClassId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    selectAllOption: false,
    idKey: 'systemClassId',
    displayValue: [{ key: 'systemClassName', separator: '' }],
  },
  {
    label: 'systemMain',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'systemMainComponentId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'systemMainComponentId',
    displayValue: [{ key: 'systemMainComponentName', separator: '' }],
  },
  {
    label: 'descriptionText',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'description',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
  },
];
