import { FETCH_INTERFACE_MONITORING, CLEAR_INTERFACE_MONITORING } from './InterfaceMonitoring.constants';

const initialState = {
  interfaceMonitoring: {},
};

const InterfaceMonitoringReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INTERFACE_MONITORING:
      return {
        ...state,
        interfaceMonitoring: action.payload,
      };
    case CLEAR_INTERFACE_MONITORING:
      return {
        ...state,
        interfaceMonitoring: {},
      };
    default:
      return state;
  }
};

export default InterfaceMonitoringReducer;
