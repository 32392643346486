import Validators from "library/utilities/Validators";

export const RequestModal = [
  {
    label: 'stocks',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'stockId',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-sm-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [
      {key: 'locationName', separator: ''}
    ],
    titleDisplay: [
      {key: 'shortName', separator: ' '},
      {key: 'name', separator: ''}
    ],
      optionsArrayKey: 'stockInformation',
      titleTextKey: 'name'
  },
  {
    label: 'roleLabelText',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'roleId',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-sm-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [
      {key: 'role', separator: ''}
    ]
  },
];
