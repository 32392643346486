import KeyValueDetails from 'library/common/commonComponents/KeyValueDetails/KeyValueDetails';
import moment from 'moment';
import React from 'react';
import strings from 'resources/locales/Translate';

export const KitMarketplaceDetails = ({ kitDetail }) => {
  const { sellOffPlatformInformation } = kitDetail;

  const information = [
    {
      key: strings.kitMarketplaceAvaialble,
      value:
        sellOffPlatformInformation?.availableOnMarketplace === true
          ? strings.yes
          : sellOffPlatformInformation?.availableOnMarketplace === false
          ? strings.no
          : '-',
    },
    {
      key: strings.kitFirstSalesOrder,
      value: sellOffPlatformInformation?.firstSalesOrder ? sellOffPlatformInformation?.firstSalesOrder : '',
    },
    {
      key: strings.kitMarketplacelLoanOrder,
      value: kitDetail?.sapERP? kitDetail?.sapERP : '',
    },
    {
      key: strings.conditionText,
      value: sellOffPlatformInformation?.sellOffCondition
        ? sellOffPlatformInformation?.sellOffCondition?.sellOffKitConditionTranslated[0]?.name
        : '',
    },
    {
      key: strings.salesDate,
      value: sellOffPlatformInformation?.saleDate
        ? moment(sellOffPlatformInformation?.saleDate).format('YYYY-MM-DD')
        : '',
    },
    {
      key: strings.marketplaceDeliveryTimes,
      value: sellOffPlatformInformation?.deliveryTimeInWeeks ? sellOffPlatformInformation?.deliveryTimeInWeeks : '',
    },
    {
      key: strings.kitMarketplaceSalesComment,
      value: sellOffPlatformInformation?.saleComment ? sellOffPlatformInformation?.saleComment : '',
    },
  ];

  const kitMarketplaceprice = [
    {
      key: strings.kitMarketplaceGrossPrice,
      value: sellOffPlatformInformation?.localSalePrice ? sellOffPlatformInformation?.localSalePrice : '',
    },
    {
      key: strings.currency,
      value: sellOffPlatformInformation?.currency ? sellOffPlatformInformation?.currency?.name : '',
    },
    {
      key: strings.priceType,
      value:
        sellOffPlatformInformation?.priceTypeDynamic === true
          ? strings.dynamicPrice
          : sellOffPlatformInformation?.priceTypeDynamic === false
          ? strings.staticPirce
          : '',
    },
    {
      key: strings.priceFactor,
      value: sellOffPlatformInformation?.priceFactor ? sellOffPlatformInformation?.priceFactor : '',
    },
    {
      key: strings.kitMarketplaceTransferPrice,
      value: sellOffPlatformInformation?.transferPrice ? sellOffPlatformInformation?.transferPrice : '',
    },
  ];
  return (
    <div className='information-container'>
      <div className='container-fluid'>
        <div className='mt-3'>
          <KeyValueDetails details={information} sectionTitle={strings.information} styleColClass='col-3' />
          <KeyValueDetails details={kitMarketplaceprice} sectionTitle={strings.price} styleColClass='col-3' />
        </div>
      </div>
    </div>
  );
};

export default KitMarketplaceDetails;
