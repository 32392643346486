import React from 'react';
import DmaReportingFilters from '../DmaReportingFilters/DmaReportingFilter.compoent';
import { useDmaReporting } from './DmaReporting.hook';
import DoughnutChart from 'library/common/commonComponents/DoughnutChart';
import BarChart from 'library/common/commonComponents/BarChart';
import strings from 'resources/locales/Translate';

export const DmaReporting = ({
  isShowFilter,
  fetchDmaReportingData,
  dmaReporting,
  fetchSixMonthTrafficData,
  sixMonthsTrafficData,
}) => {
  const { data, legends, topTenSessionData, defaultOptionsOfTenSessions, trafficData, defaultOptionsOfTrafficcDats } =
    useDmaReporting({ dmaReporting, fetchSixMonthTrafficData, sixMonthsTrafficData });
  const maxCountInLoansCreated =
    dmaReporting?.loansCreatedByBusinessUnits &&
    Math.max(...dmaReporting.loansCreatedByBusinessUnits.map(item => item.count));

  const maxCountInLoansShipped =
    dmaReporting?.loansDeliveredByBusinessUnits &&
    Math.max(...dmaReporting?.loansDeliveredByBusinessUnits?.map(item => item.count));
  
  const hasCountGreaterThanZero = dmaReporting?.sessionByUserGroupKPI?.some(item => item.count > 0);
  return (
    <>
      <DmaReportingFilters isShowFilter={isShowFilter} fetchDmaReportingData={fetchDmaReportingData} />
      <div className='container-fluid'>
        <div className='adjust-card-data'>
          <>
            {dmaReporting?.newRegistrationsKPI?.count >= 0 && (
              <div className='set-caed-height-width'>
                <div
                  className={dmaReporting?.newRegistrationsKPI.growth <= 0 ? 'data-card-count-decreased' : `data-card`}
                >
                  <div class='data-card-title mt-4'>New registrations</div>
                  <div className='main-value-section mt-3'>
                    <div class='data-card-value'>{dmaReporting.newRegistrationsKPI.count}</div>
                    <div
                      class={`data-card-title ${
                        dmaReporting?.newRegistrationsKPI.growth <= 0
                          ? 'decreaded-percent-text-color'
                          : 'increased-percent-text-color'
                      }`}
                    >{`${
                      dmaReporting?.newRegistrationsKPI.growth <= 0 ? '↓' : '↑'
                    } ${dmaReporting.newRegistrationsKPI.growth.toFixed(2).toString().replace('-', '')}%`}</div>
                  </div>
                </div>
              </div>
            )}
            {dmaReporting?.visitorsKPI?.count >= 0 && (
              <div className='set-caed-height-width'>
                <div className={dmaReporting?.visitorsKPI?.growth <= 0 ? 'data-card-count-decreased' : `data-card`}>
                  <div class='data-card-title mt-4'>Total Visitors</div>
                  <div className='main-value-section mt-3'>
                    <div class='data-card-value'>{dmaReporting.visitorsKPI?.count}</div>
                    <div
                      class={`data-card-title ${
                        dmaReporting?.visitorsKPI?.growth <= 0
                          ? 'decreaded-percent-text-color'
                          : 'increased-percent-text-color'
                      }`}
                    >{`${dmaReporting?.visitorsKPI?.growth <= 0 ? '↓' : '↑'} ${dmaReporting.visitorsKPI?.growth
                      .toFixed(2)
                      .toString()
                      .replace('-', '')}%`}</div>
                  </div>
                </div>
              </div>
            )}
            {dmaReporting?.sessionsKPI?.count >= 0 && (
              <div className='set-caed-height-width'>
                <div className={dmaReporting?.sessionsKPI?.growth <= 0 ? 'data-card-count-decreased' : `data-card`}>
                  <div class='data-card-title mt-4'>Total Sessions</div>
                  <div className='main-value-section mt-3'>
                    <div class='data-card-value'>{dmaReporting?.sessionsKPI.count}</div>
                    <div
                      class={`data-card-title ${
                        dmaReporting?.sessionsKPI.growth <= 0
                          ? 'decreaded-percent-text-color'
                          : 'increased-percent-text-color'
                      }`}
                    >{`${dmaReporting?.sessionsKPI.growth <= 0 ? '↓' : '↑'} ${dmaReporting.sessionsKPI.growth
                      .toFixed(2)
                      .toString()
                      .replace('-', '')}%`}</div>
                  </div>
                </div>
              </div>
            )}
            {dmaReporting?.totalRegisteredUsers >= 0 && (
              <div className='set-caed-height-width'>
                <div className='data-card'>
                  <div class='data-card-title mt-4'>Total Registered Users</div>
                  <div className='main-value-section mt-3'>
                    <div class='data-card-value'>{dmaReporting?.totalRegisteredUsers}</div>
                  </div>
                </div>
              </div>
            )}
          </>
        </div>
        {dmaReporting?.topTenUserCountries?.length > 0 && (
          <div className='bar-chart-pie-position-alignment'>
            <div className='mt-5 session-by-visitor' style={{ width: '70%' }}>
              <>
                <div className='data-card-title mb-4'>{strings.toptenCountriesUsgae}</div>
                <BarChart data={topTenSessionData} defaultOptions={defaultOptionsOfTenSessions} height={130} />
              </>
            </div>
            <div className='session-by-visitor mt-5' style={{ width: '30%' }}>
              {dmaReporting?.sessionByUserGroupKPI?.length > 0 && (
                <>
                  <div className='data-card-title'>Sessions by visitor group</div>
                  {hasCountGreaterThanZero ? 
                    <DoughnutChart
                      data={data}
                      height={160}
                      additionalOptions={{ cutoutPercentage: 70 }}
                      legendPositon='bottom'
                      hideLegend={true}
                      tooltip={true}
                    /> : 
                  <div className='no-data-text'>{strings.noDataFoundText}</div>
                  }
                </>
              )}
              <div className='d-flex align-items-center justify-content-between flex-wrap mt-4'>
                {legends?.map(item => (
                  <div className='d-flex align-items-center col-6'>
                    <div className='legend-adjust mr-2 ' style={{ backgroundColor: item.color }}></div>{' '}
                    <span>{item.name}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {dmaReporting?.loansCreatedByBusinessUnits?.length > 0 && (
          <div className='adjust-loan-shipped-created mt-5 mb-5' style={{ width: '100%' }}>
            <div className='request-loan-section'>
              <p className='data-card-title'>{strings.requestsCreatedBy}</p>
              {
                <div className='loans-content mt-3'>
                  {dmaReporting?.loansCreatedByBusinessUnits?.map(item => {
                    const barWidth = maxCountInLoansCreated > 0 ? (item.count / maxCountInLoansCreated) * 100 : 0;
                    return (
                      <div key={item.businessUnitDesignation} className='loan-sub-content'>
                        <span className='set-loan-businessUnit-font'>{item.businessUnitName}</span>
                        <div className='bar-count-style'>
                          <div style={{ width: `${barWidth}%` }} className='bar-color'>
                            {' '}
                          </div>
                          <div className='set-loans-businessUnit-count-font'> {item.count}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              }
            </div>
            <div className='request-loan-section'>
              <p className='data-card-title'>{strings.requestsShippedBy}</p>
              {
                <div className='loans-content mt-3'>
                  {dmaReporting?.loansDeliveredByBusinessUnits?.map(item => {
                    const barWidth = maxCountInLoansShipped > 0 ? (item.count / maxCountInLoansShipped) * 100 : 0;
                    return (
                      <div key={item.businessUnitDesignation} className='loan-sub-content'>
                        <span className='set-loan-businessUnit-font'>{item.businessUnitName}</span>
                        <div className='bar-count-style'>
                          <div style={{ width: `${barWidth}%` }} className='bar-color'>
                            {' '}
                          </div>
                          <div className='set-loans-businessUnit-count-font'> {item.count}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              }
            </div>
          </div>
        )}
        <div className='session-by-visitor' style={{ width: '100%' }}>
          {sixMonthsTrafficData?.length > 0 && (
            <>
              <div className='data-card-title p-4 ml-4'>Traffic - Sessions Last 6 Month</div>
              <BarChart data={trafficData} defaultOptions={defaultOptionsOfTrafficcDats} height={40} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DmaReporting;
