import Validators from "library/utilities/Validators";

export const formModel = [
  {
    label: 'email',
    value: '',
    type: 'email',
    placeholder: '',
    field: 'email',
    validators: [
      {check: Validators.email, message: 'invalidEmailError'},
      {check: Validators.required, message: 'requiredErrorMessage'}
    ],
    required: true,
    styleClass: 'col-sm-12'
  },
  {
    label: 'contactUsSubject',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'subject',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-sm-12'
  },
  {
    label: 'contactUsMessage',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'comment',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-sm-12'
  }
];
