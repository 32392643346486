import { useEffect, useState } from 'react';
import strings from 'resources/locales/Translate';

export const useToDoList = (props) => {

  const { formValue, user } = props;
  const { todosListForm, logisticsAndCoordinationForm, deleteTodoIds } = formValue;

  const [todoText, setTodoText] = useState('');
  
  const [todosList, setTodosList] = useState([]);

  const [todoIdsToDelete, setTodoIdsToDelete] = useState([]);

  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (todoText !== '') {
      setTodoText('')
    }
  },[todosListForm.formData.zmccTodosListDataList])
  
  const addIntoTodosList  = (value) => {
    if(value === ''){
      setErrorMsg(strings.requiredErrorMessage);
      return;
    }
    setTodosList(
      todosListForm.formData.zmccTodosListDataList ? [
        ...todosListForm.formData.zmccTodosListDataList,
        {
          zmccTodosId: null,
          zmccTodosText: value,
          createdBy: user.accountId,
          checkedBy: null,
          isChecked: false,
          title: null
        }
      ]
    :
    [
      {
        zmccTodosId: null,
        zmccTodosText: value,
        createdBy: user.accountId,
        checkedBy: null,
        isChecked: false,
        title: null
      }
    ]
    );
    setTodoText('');
    props.isClickedOnAdInTodoTab(true);
    handleNonFormComponent(
      todosListForm.formData.zmccTodosListDataList ?
      [
      ...todosListForm.formData.zmccTodosListDataList,
      {
        zmccTodosId: null,
        zmccTodosText: value,
        createdBy: user.accountId,
        checkedBy: null,
        isChecked: false,
        title: null
      }
      ]
    : [
        {
          zmccTodosId: null,
          zmccTodosText: value,
          createdBy: user.accountId,
          checkedBy: null,
          isChecked: false,
          title: null
        }
      ]
    , 'zmccTodosListDataList', 'todosListForm');
  }

  const handleNonFormComponent = (values, field, formName ) => {
    props.updateSEMForm({
      [formName]: {
        ...props.formValue[formName],
        formData: {
          ...((props.formValue[formName] && props.formValue[formName].formData) || {}),
          [field]: values,
          [`${field}Error`]: null
        },
      },
    });
  };


  const removeATodoFromList = (i) => {
    var deleteTodoFromDB = todosListForm.formData.zmccTodosListDataList.find((item, index) => i == index);
    if (deleteTodoFromDB.zmccTodosId != null){
      var updatedDelete = (deleteTodoIds.formData &&  deleteTodoIds.formData.deleteTodoIds) ? [...deleteTodoIds.formData.deleteTodoIds,deleteTodoFromDB.zmccTodosId]: [deleteTodoFromDB.zmccTodosId];
      handleNonFormComponent(updatedDelete, 'deleteTodoIds', 'deleteTodoIds');
    }
    setTodosList([...todosListForm.formData.zmccTodosListDataList.filter((item,index) => i!=index)]);
    handleNonFormComponent([...todosListForm.formData.zmccTodosListDataList.filter((item,index) => i!=index)], 'zmccTodosListDataList', 'todosListForm');
  }

  const onChangeInput = (value, field) => {
    if (field === 'todoText') {
      handleNonFormComponent(value, 'todoText', 'todosListForm');
      if (value) {
        props.isClickedOnAdInTodoTab(false);
      }
      setErrorMsg("");
      return setTodoText(value);
    }
    props.setUnsavedChanges(true);
  };

  return {
    onChangeInput,
    todoText,
    addIntoTodosList,
    todosList,
    removeATodoFromList,
    errorMsg
  };

};
