import React from 'react';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import Modal from 'library/common/commonComponents/Modal';
import strings from 'resources/locales/Translate';
import { useArrivalCheckListPopup } from './ArrivalCheckListPopup.hook';

export const ArrivalCheckListPopup = ({
  id,
  user,
  usersList,
  saveMaintenanceNewCheckListForm,
  transactionId,
  maintenanceCheckListData,
  saveMantenanceCheckListData,
}) => {
  const {
    arrivalAndUnpakingModel,
    handleForm,
    arrivalFormData,
    installationFormData,
    instalationgModel,
    onSaveClick,
    closeClick,
    loading,
  } = useArrivalCheckListPopup({
    id,
    user,
    usersList,
    saveMaintenanceNewCheckListForm,
    transactionId,
    maintenanceCheckListData,
    saveMantenanceCheckListData,
  });
  return (
    <Modal
      id={id}
      confirmAction={onSaveClick}
      loading={loading}
      disableBackgroundClose
      onCloseClick={closeClick}
      confirmTitle={strings.save}
      title={strings.maitenanceCheckList}
    >
      <p className='checkList-form-lable'>{strings.arrivalAndUnpacking}</p>
      <div className='mt-2'>
        <FormComponent
          model={arrivalAndUnpakingModel()}
          formName='arrivalForm'
          formValue={arrivalFormData.formData}
          onChange={handleForm}
        />
      </div>
      <p className='checkList-form-lable'>{strings.installation}</p>
      <div className='mt-2'>
        <FormComponent
          model={instalationgModel()}
          formName='instalationForm'
          formValue={installationFormData.formData}
          onChange={handleForm}
        />
      </div>
    </Modal>
  );
};
export default ArrivalCheckListPopup;