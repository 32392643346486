import React, { useCallback, useState } from 'react';
import strings from 'resources/locales/Translate';
import Modal from 'library/common/commonComponents/Modal';
import $ from 'jquery';

import MapContainer from './GoogleApiWrapper.component';

const ShowGoogleMap = ({ id, locations, handleFilterChange, calenderState }) => {
  const [locationData, setLocationData] = useState(null);
  const locationList = [].concat.apply(
    [],
    locations
      .map(location => {
        if (location.stockInformation) {
          return [...location.stockInformation];
        }
      })
      .filter(item => item !== undefined),
  );

  const closeMapPopUp = useCallback(() => {
    $(`#${id}`).modal('hide');
  }, []);

  const handleConfirm = () => {
    handleFilterChange('stock', [locationData.id], [locationData]);
    closeMapPopUp();
  };
  const selectedPartner = location => {
    setLocationData(location);
  };

  return (
    <Modal
      id={id}
      title={strings.mapTitle}
      className='map-modal'
      onCloseClick={closeMapPopUp}
      titleOfCancel={strings.cancel}
      disableBackgroundClose
      confirmAction={handleConfirm}
      confirmTitle={strings.selectPartner}
    >
      <div style={{ display: 'flex', flexDirection: 'column', height: '55vh', width: '70vw' }}>
        {locationList.length > 0 && (
          <MapContainer locations={locationList} selectedPartner={selectedPartner} calenderState={calenderState} />
        )}
      </div>
    </Modal>
  );
};

export default ShowGoogleMap;
