import React, { useState } from 'react';
import cn from 'classnames';

import strings from 'resources/locales/Translate';
import Icon from 'library/common/commonComponents/Icon/Icon';

import './search-field.styles.scss';

const SearchField = ({ initialValue, onChange, placeholder, error, iconStyles, inputStyles }) => {
  const [value, setValue] = useState(initialValue);
  
  const onChangeField = (e) => {
    onChange(e);
    setValue(e.target.value);
  }

  return (
    <>
      <input
        type='text'
        className={cn('input-search', inputStyles, { 'input-error': error })}
        placeholder={placeholder}
        onChange={onChangeField}
        value={value || ''}
      />
      <Icon name='search' width={24} height={24} fill='#9a9b9c' className={cn('search-icon', iconStyles)} />
      {error && <span className='text-danger error-block'>{strings[error]}</span>}
    </>
  );
};

export default SearchField;

SearchField.defaultProps = {
  placeholder: strings.searchInput,
  error: null,
};