import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
require('dayjs/locale/en');
require('dayjs/locale/de');

export const getDateInFormat = (format, dateFrom, dateTo, locale = 'en') => {
  const validLocales = ['en', 'de'];
  if (!validLocales.includes(locale)) {
    console.warn(`Unexpected locale: ${locale}, falling back to 'en'.`);
    locale = 'en';
  }
  if (dateTo) return `${dayjs(dateFrom).locale(locale).format(format)} - ${dayjs(dateTo).locale(locale).format(format)}`

  return dayjs(dateFrom).locale(locale).format(format);
}

export const getDateInUTCFormat = (format, dateFrom, dateTo) => {
  if (dateTo) return `${dayjs.utc(dateFrom).format(format)} - ${dayjs.utc(dateTo).format(format)}`

  return dayjs.utc(dateFrom).format(format);
}
