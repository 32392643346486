import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { IoIosPrint } from 'react-icons/io';

import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/Breadcrumbs';
import Tabs from 'library/common/commonComponents/Tabs/Tabs';
import Icon from 'library/common/commonComponents/Icon/Icon';
import ImageGallery from 'library/common/commonComponents/ImageGallery/ImageGallery';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import { getPriceWithCurrencyName } from 'library/utilities/getCurrencyFormat';
import { images } from 'library/common/commonConstants/ImageConstants';
import { updateKeysForSellOffImages } from 'library/utilities/fileHelperFunctions';
import { makeTwoDigits } from 'library/utilities/makeTwoDigits';
import Print from 'library/common/commonComponents/KitPrint';
import CreateServiceTicketPopup from 'library/common/commonComponents/CreateServiceTicketPopup';
import ConfirmDeletionPopup from 'library/common/commonComponents/ConfirmationBox';
import strings from 'resources/locales/Translate';

import { useKitForSaleDetails } from './KitForSaleDetails.hook';

const KitForSaleDetails = props => {
  const {
    user,
    kitDetails,
    fetchKitForSaleDetail,
    isLoading,
    history,
    displayActionMessage,
    type,
    message,
    currentTab,
    saveCurrentTab,
    questions,
    kitDetailsAttachments,
    clearKitDetailsData,
    damageLossReportData,
    commentsData,
    match,
    deleteKitDetails,
    transactionAttachments,
    fetchViewsCount
  } = props;
  const {
    printComponentRef,
    description,
    fetchKit,
    handleDelete,
    demoSaleUrls,
    checkKitImage,
    localSalesPrice,
    transferPrice,
    currency,
    isReserved,
    reservedBy,
    reservationToFormatted,
    breadcrumbs,
    tabs,
    isEditable,
    isDeletable,
    transferCurrency,
    count,
    isShowSalesDetailsTab,
    sellOffPlatformImages,
    toggleFullDescription,
    isFullDescriptionShown,
    isDescriptionLengthMoreThanLimit,
    isKitForSale,
    isSingleItemForSale,
    ignoreArchieveView
  } = useKitForSaleDetails({
    user,
    kitDetails,
    fetchKitForSaleDetail,
    history,
    saveCurrentTab,
    clearKitDetailsData,
    damageLossReportData,
    commentsData,
    match,
    deleteKitDetails,
    fetchViewsCount
  });

  return (
    <div className='kit-details'>
      {displayActionMessage && <ActionMessage type={type} message={strings[message] || message} />}
      <div className='container-fluid mb-3'>
        <div className='d-flex'>
          <Breadcrumbs data={breadcrumbs} />
        </div>
        <div className='row mb-3'>
          <div className='col-12 col-lg-auto'>
            <div className='d-flex justify-content-center mb-3'>
              {checkKitImage ? (
                <ImageGallery images={updateKeysForSellOffImages(sellOffPlatformImages)} />
              ) : (
                <img alt='' src={images.imagePlaceholder} className='dummy-image' />
              )}
            </div>
          </div>

          <div className='col-12 col-md-6 col-lg'>
            <h2 className='title'>{kitDetails.kitName}</h2>
            <div className='kit-number-text'>{`${strings.kitNumber}: ${kitDetails.kitNumber}`}</div>
            <div className='kit-number-text'>{`${strings.ibaseText}: ${
              kitDetails.ibase ? kitDetails.ibase : '-'
            }`}</div>
            <div className='subtitle'>{strings.descriptionText}</div>
            <div className='description-container mb-3'>{description}</div>
            <div className='full-description' onClick={toggleFullDescription}>
              <Icon
                name='visibility'
                width={24}
                height={24}
                fill={isFullDescriptionShown || !isDescriptionLengthMoreThanLimit ? '#737678' : '#0088d0'}
                className='mr-2'
              />
              <span
                style={{ color: isFullDescriptionShown || !isDescriptionLengthMoreThanLimit ? '#737678' : '#0088d0' }}
              >
                {strings.fullDescription}
              </span>
            </div>
            <div>
              <div className='list-price'>{strings.grossListPrice}</div>
              <div className='price'>
                {`${getPriceWithCurrencyName(localSalesPrice, currency)}`}
              </div>
              <div className='d-flex'>
                <div>
                  <div className='list-price'>{strings.transferPriceInEUR}</div>
                  <div className='price'>{getPriceWithCurrencyName(transferPrice, transferCurrency)}</div>
                </div>
                {kitDetails.isObserve && (
                  <div className='ml-5'>
                    <div className='list-price'>{strings.observations}</div>
                    <div className='price'>{makeTwoDigits(kitDetails.observationCount)}</div>
                  </div>
                )}

                {!ignoreArchieveView && (<div className='ml-5'>
                  <div className='list-price'>{strings.Views}</div>
                  <div className='price'>{makeTwoDigits(kitDetails.marketPlaceKitViewCount)}</div>
                </div>)}

              </div>
              {isReserved && reservationToFormatted && (
                <div className='reservation-text'>{`${strings.reservedTill(reservationToFormatted)} ${reservedBy}`}</div>
              )}
            </div>
          </div>

          <div className='col-12 col-md-6 col-lg-auto'>
            <div className='d-flex justify-content-end'>
              {kitDetails.ibase && (
                <div
                  className='button-blue'
                  data-toggle='modal'
                  data-target={`#createServiceTicket${kitDetails.kitInformationId}`}
                >
                  <Icon name='inRefurbishment' width={24} height={24} fill='#0088d0' />
                  <div className='tooltiptext'>
                    {strings.createServiceTicket}
                    <i />
                  </div>
                </div>
              )}
              <div className='button-blue' data-test='copyKit-button'>
                <NavLink
                  to={{
                    pathname: demoSaleUrls.copyURL,
                    state: { edit: false },
                  }}
                >
                <Icon name='copy' width={24} height={24} fill='#0088d0' />
                </NavLink>
                <div className='tooltiptext'>
                  {strings.copy}
                  <i />
                </div>
              </div>
              {isEditable && (
                <div className='button-blue'>
                  <NavLink
                    to={{
                      pathname: demoSaleUrls.editingURL,
                      state: { edit: true },
                    }}
                  >
                    <Icon name='edit' width={24} height={24} fill='#0088d0' />
                  </NavLink>
                  <div className='tooltiptext'>
                    {strings.edit}
                    <i />
                  </div>
                </div>
              )}
              <ReactToPrint
                trigger={() => (
                  <div className='button-blue'>
                    <IoIosPrint size={24} color='#0088d0' />
                    <div className='tooltiptext'>
                      {strings.print}
                      <i />
                    </div>
                  </div>
                )}
                content={() => printComponentRef.current}
              />
              {!kitDetails.isTransactionAssociated && isDeletable && (
                <div
                  className='button-red'
                  data-toggle='modal'
                  data-target={`#confirmKitDeleteModal${kitDetails.kitInformationId}`}
                >
                  <Icon name='delete' width={24} height={24} fill='#ff1a00' />
                  <div className='tooltiptext'>
                    {strings.delete}
                    <i />
                  </div>
                </div>
              )}
            </div>
            <div className='d-flex justify-content-end p-3'>
              {kitDetails.qrCode && <img src={kitDetails.qrCode} alt='' className='grCode-image' />}
            </div>
          </div>
        </div>
      </div>
      <div className='print-hidden'>
        <Print
          ref={printComponentRef}
          kitDetails={kitDetails}
        />
      </div>
      <Tabs
        tabs={tabs({
          kitDetails,
          history,
          isEditable,
          match,
          count,
          isKitForSale,
          isSingleItemForSale,
          questions,
          kitDetailsAttachments,
          isShowSalesDetailsTab,
          transactionAttachments,
        })}
        currentTab={currentTab}
        saveCurrentTab={saveCurrentTab}
      />

      {isLoading && <div className='loader' />}
      <CreateServiceTicketPopup
        id={`createServiceTicket${kitDetails.kitInformationId}`}
        kitDetails={kitDetails}
        user={user}
        handleFetch={fetchKit}
      />
      <ConfirmDeletionPopup
        id={`confirmKitDeleteModal${kitDetails.kitInformationId}`}
        onConfirm={() => handleDelete(kitDetails.kitInformationId)}
        title={strings.confirmDeleteKit}
        subtitle={strings.confirmDeleteKitMessage}
      />
    </div>
  );
}

export default KitForSaleDetails;
