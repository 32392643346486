import { FETCH_C2C_LOANS } from 'library/common/commonConstants/storeConstants';

export const C2C_LOANS_INITIAL_STATE = {
  c2cLoans: {},
};

const C2CLoansReducer = (state = C2C_LOANS_INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_C2C_LOANS:
      return {
        ...state,
        c2cLoans: action.payload,
      };
    default:
      return state;
  }
};

export default C2CLoansReducer;
