import React from 'react';
import moment from 'moment';

import strings from 'resources/locales/Translate';
import Button from 'library/common/commonComponents/Button/Button';
import Icon from 'library/common/commonComponents/Icon/Icon';

const DemoUnitStatusSubscriptions = ({
  subscriptionsList,
  handleEditSubscription,
  handleDeleteSubscription,
  openCreateSubscriptionPopup,
}) => {
  const hasSubscriptionsList = subscriptionsList.length > 0;

  return (
    <div className='w-75'>
      <div className='d-flex justify-content-between align-items-center mb-3'>
        <div className='d-flex align-items-center'>
          <h4 className='mr-2'>{strings.demoUnitStatusSubscriptions}</h4>
          <Icon
            name='info'
            width={18}
            height={18}
            fill='#9a9b9c'
            className='cursor-pointer'
            data-tooltip={strings.demoUnitStatusSubscriptionTooltip}
            data-tooltip-config={JSON.stringify({ direction: 'bottom' })}
          />
        </div>
        <div>
          <Button
            bgFill={false}
            iconName='plus'
            onClick={openCreateSubscriptionPopup}
            value={strings.subscribeNew}
          />
        </div>
      </div>
      {hasSubscriptionsList && (
        <div className='row no-gutters border-bottom-dark  py-2'>
          <div className='col-2'>{strings.subscribedOn}</div>
          <div className='col-4'>{strings.systemClassText}</div>
          <div className='col-6'>{strings.systemMainComponent}</div>
        </div>
      )}
      {hasSubscriptionsList ? subscriptionsList.map(subscription => (
        <div className='row no-gutters border-bottom align-items-center py-2' key={subscription.subscriptionId}>
          <div className='col-2'>
            {subscription.createdOn ? moment(subscription.createdOn).format('DD.MM.YYYY') : '-'}
          </div>
          <div className='col-4'>{subscription.systemClassName}</div>
          <div className='col-4'>{subscription.systemMaincomponentName}</div>
          <div className='col-1'>
            <Button
              bgFill={false}
              iconName='edit'
              onClick={() => handleEditSubscription(subscription)}
              styleClass='settings-button'
              iconStyle='settings-icon'
            />
          </div>
          <div className='col-1'>
            <Button
              bgFill={false}
              iconName='delete'
              onClick={() => handleDeleteSubscription(subscription)}
              styleClass='settings-button'
              colorIcon='#ff1a00'
              iconStyle='settings-icon'
            />
          </div>
          {/* <div className='row'>
            <div className='col-6'>
              <div>{strings.systemClassText}:</div>
              <div>{strings.systemMainComponent}:</div>
            </div>
            <div className='col-4'>
              <div>{subscription.systemClassName}</div>
              <div>{subscription.systemMaincomponentName}</div>
            </div>
            <div className='col-1'>
              <Button
                bgFill={false}
                iconName='edit'
                onClick={() => handleEditSubscription(subscription)}
                styleClass='settings-button'
                iconStyle='settings-icon'
              />
            </div>
            <div className='col-1'>
              <Button
                bgFill={false}
                iconName='delete'
                onClick={() => handleDeleteSubscription(subscription)}
                styleClass='settings-button'
                colorIcon='#ff1a00'
                iconStyle='settings-icon'
              />
            </div>
          </div> */}
        </div>
      )) : (
        <div className='col-12'>
          <div className='d-flex justify-content-center'>
            <div className='py-3'>
              <strong>{strings.noSubscriptionsAvailable}</strong>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DemoUnitStatusSubscriptions;
