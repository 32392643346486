import React from 'react';

import strings from 'resources/locales/Translate';

const KitDataStatistics = ({ kitDataStatistics }) => {
  const {
    utilisationRate,
    reparationRate,
    transportationRate,
    idleTime,
    reconditioningTime,
  } = kitDataStatistics || {};

  return (
    <div className='demos-statistic-wrapper'>
      <div className='w-100 d-flex'>
        <div className='w-20'>
          <div>
            <p className='statistics-text'>{utilisationRate || 0} %</p>
            <p className='subtitle'>{strings.utilizationRate}</p>
          </div>
        </div>
        <div className='w-20'>
          <div>
            <p className='statistics-text'>{reparationRate || 0} %</p>
            <p className='subtitle'>{strings.reparationRate}</p>
          </div>
        </div>
        <div className='w-20'>
          <div>
            <p className='statistics-text'>
              {reconditioningTime || 0} {strings.dayOrDayes(reconditioningTime)}
            </p>
            <p className='subtitle'>{strings.refurbishmentTime}</p>
          </div>
        </div>
        <div className='w-20'>
          <div>
            <p className='statistics-text'>{transportationRate || 0} %</p>
            <p className='subtitle'>{strings.transportationRate}</p>
          </div>
        </div>
        <div className='w-20'>
          <div>
            <p className='statistics-text'>{idleTime || 0} %</p>
            <p className='subtitle'>{strings.idleTime}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default KitDataStatistics;
