import { FETCH_RMS_ZMCC_COUNTRIES } from 'library/common/commonConstants/storeConstants';
import * as actionTypes from 'modules/Booking/BookingConstants';

const initialState = {
  transactionsList: [],
  labsLocationTransactionsList: [],
  transactionStatuses: [],
  transactionPositions: [],
  returnDelayedTransactions: [],
  kitConditions: [],
  teamMembers: [],
  labsLocationCountries: [],
  labsLocationSystemClasses: [],
  labsLocationTransactionStatuses: [],
  zmccTransactionList: [],
  zmccTransactionStatuses: [],
  zmccCountries: [],
  zmccCategory: [],
  zmccOperatorList: [],
  safeSearchList: null,
  saveFilterData: null,
};

const BookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TRANSACTIONS_LIST:
      return {
        ...state,
        transactionsList: action.payload
      };
    case actionTypes.FETCH_LABS_LOCATION_TRANSACTIONS_LIST:
      return {
        ...state,
        labsLocationTransactionsList: action.payload
      };
    case actionTypes.FETCH_TRANSACTION_STATUSES:
      return {
        ...state,
        transactionStatuses: action.payload
      };
    case actionTypes.FETCH_LABS_LOCATION_TRANSACTION_STATUSES:
      return {
        ...state,
        labsLocationTransactionStatuses: action.payload
      };
    case actionTypes.FETCH_TRANSACTION_POSITIONS:
      return {
        ...state,
        transactionPositions: action.payload
      };
    case actionTypes.SET_DELAYED_TRANSACTIONS:
      return {
        ...state,
        returnDelayedTransactions: action.payload
      };
    case actionTypes.SET_CONDITIONS_BOOKING_LIST_FILTER:
      return {
        ...state,
        kitConditions: action.payload
      };
    case actionTypes.FETCH_TEAM_MEMBERS:
      return {
        ...state,
        teamMembers: action.payload
      };
    case actionTypes.FETCH_LABS_LOCATION_COUNTRIES:
      return {
        ...state,
        labsLocationCountries: action.payload
      };
    case actionTypes.FETCH_LABS_LOCATION_SYSTEM_CLASSES:
      return {
        ...state,
        labsLocationSystemClasses: action.payload
      };
    case actionTypes.FETCH_ZMCC_TRANSACTION_LIST:
      return {
        ...state,
        zmccTransactionList: action.payload
      };
    case actionTypes.FETCH_ZMCC_TRANSACTION_STATUSES:
      return {
        ...state,
        zmccTransactionStatuses: action.payload
      };

      case FETCH_RMS_ZMCC_COUNTRIES:
      return {
        ...state,
        zmccCountries: action.payload
      };

      case actionTypes.FETCH_ZMCC_TRANSACTION_CATEGORY:
      return {
        ...state,
        zmccCategory: action.payload
      };

      case actionTypes.FETCH_ZMCC_TRANSACTION_OPERATORS:
      return {
        ...state,
        zmccOperatorList: action.payload
      };

      case actionTypes.FETCH_SAFE_SEARCH_LIST:
      return {
        ...state,
        safeSearchList: action.payload
      };

      case actionTypes.FETCH_SAVED_FILTERS:
      return {
        ...state,
        saveFilterData: action.payload
      };
      
    default:
      return state;
  }
};

export default BookingReducer;
