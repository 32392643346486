import Validators from 'library/utilities/Validators';

export const subscriptiontModel = [
  {
    label: 'categorySystem',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'systemClassId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    selectAllOption: false,
    idKey: 'systemClassId',
    displayValue: [{ key: 'systemClassName', separator: '' }],
  },
  {
    label: 'systemMainComponent',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'systemMainComponentId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    options: [],
    hasSection: false,
    multiSelect: true,
    filter: false,
    idKey: 'systemMainComponentId',
    displayValue: [{ key: 'systemMainComponentName', separator: '' }],
  },
  {
    label: 'emailNotification',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'systemClassSubscriptionsEmailTypeId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'value', separator: '' }],
  },
];
