import Validators from 'library/utilities/Validators';

export const materialNumberAndDescriptionModel = [
  {
    label: 'materialNumber',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'materialNumber',
    validators: [
      { check: Validators.required, message: 'requiredErrorMessage' },
      { check: Validators.materialNumber, message: 'materialNumberFormatError' },
    ],
    required: true,
    styleClass: 'col-12 col-sm-9',
  },
  {
    label: 'quantity',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'quantity',
    validators: [
      { check: Validators.required, message: 'requiredErrorMessage' },
      { check: Validators.number, message: 'invalidNumberError' },
    ],
    required: true,
    styleClass: 'col-12 col-sm-3',
    extraProps: {
      maxLength: 2,
    },
  },
  {
    label: 'singleLoanDescription',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'descriptionText',
    validators: [],
    required: false,
    styleClass: 'col-12',
  },
];
