export const subscriptionsBreadcrumbData = [
  {
    name:'demoUnitStatusTool',
    url: '/dusd/subscriptions'
  },
  {
    name:'subscriptions',
  },
];

export const GET_SYSTEM_CLASSES_SUBSCRIPTIONS = 'SUBSCRIPTIONS/GET_SYSTEM_CLASSES_SUBSCRIPTIONS';
export const GET_EMAIL_TYPES = 'SUBSCRIPTIONS/GET_EMAIL_TYPES';
