import React, { Fragment } from 'react';
import strings from 'resources/locales/Translate';
import moment from 'moment';
import Checkbox from 'library/common/commonComponents/Checkbox/Checkbox';
import { NavLink } from 'react-router-dom';
import Icon from 'library/common/commonComponents/Icon/Icon';

export const dataTable = (getDateInFormat, type, stateCheckBox, onCheckBoxClick, setCurrentTab) => [
  {
    title: strings.number,
    name: 'number',
    isSort: false,
    render: item => {
      return <span>{`#${item.transactionsId}`}</span>;
    },
  },
  {
    title: strings.name,
    name: 'name',
    isSort: false,
    render: item => {
      return <span>{item.name}</span>;
    },
  },
  {
    title: strings.date,
    name: 'date',
    isSort: false,
    render: item => {
    return <span> 
        {item.targetReservationFrom && item.targetReservationTo &&
        getDateInFormat(
          'DD.MM.YYYY',
          moment(item.targetReservationFrom).format('YYYY-MM-DD'),
          moment(item.targetReservationTo).format('YYYY-MM-DD'),
        )}</span>;
    },
  },
  {
    title: strings.markToC2CLoan,
    name: 'Mark to C2C Loan',
    isSort: false,
    render: (item, index) => {
      return <span>          
        {type === 'possible-loan' && (
        <div className='mr-2'>
          <Checkbox
            dataTest={`${type}-checkbox`}
            selected={stateCheckBox && stateCheckBox[index] && stateCheckBox[index].value}
            onClick={onCheckBoxClick(index)}
            style={{padding:"10px"}}
            newuiVersionStyle={true}
          />
        </div>
      )}</span>;
    },
  },
  {
    // title: strings.showDetails,
    name: 'Show details',
    isSort: false,
    render: item => {
      return <span style={{display:"flex", justifyContent:'flex-end'}}>          
            <NavLink to={`/transactions/${item.transactionsId}`} onClick={() => setCurrentTab(1)} data-test='transaction-details-test'>
                <div className={`c2c-btn-bordered mb-1${type === 'possible-loan' ? ' ml-md-2' : ''}`} >
                <Icon
                    className='app-btn-icon app-btn-icon-right'
                    name='visibility'
                    width={24}
                    height={24}
                    fill='#0088d0'
                />
                    <span style={{marginLeft:"10px"}}>{strings.showDetails}</span>
                </div>
            </NavLink>
        </span>;
    },
  }
];
