import React from 'react';
import strings from 'resources/locales/Translate';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import ZmccRadioButton from 'library/common/commonComponents/FormComponent/Optimized/ZMCCRadioButton';
import Validators from 'library/utilities/Validators';
import { xrmSamplesSpecModel } from './XRMApplicationSamplesDetails.constants';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import { useXRMApplicationSampleDetails } from './XRMApplicationSamplesDetails.hook';
import Attachments from 'library/common/commonComponents/AttachmentsForm';
import Button from 'library/common/commonComponents/Button/Button';
import AdditionalInformation from '../AdditionalInformation/AdditionalInformation.component';
import StatusOfSample from '../../StatusOfSample/StatusOfSample.component';

export const XRMApplicationSamplesDetails = props => {
    const { formValue, user } = props;
    const {xrmDemoDetailsForm, xrmApplicationInformationForm, xrmSamplesDetailsForm, xrmSampleDescriptionForm, xrmSampleSpecsForm} = formValue;
    
    const {handleForm,
        handleNonFormComponent} = useXRMApplicationSampleDetails(props);
  return (
    <>
      <h2 className='mt-4 zmcc-title-heading'>{strings.demoDetails}</h2>
      <div className='p-2 section-border'>
      <p className='mt-3'>{strings.rankMessage}</p>
      <div className='col-sm-12 row p-0'>
        <div className='col-sm-3  mt-3'>
          <InputField
            label={'1'}
            field='rankOne'
            formName='xrmDemoDetailsForm'
            placeholder=''
            isRequired={false}
            type='text'
            validators={[]}
            value={xrmDemoDetailsForm.formData.rankOne}
            error={xrmDemoDetailsForm.formData.rankOneError}
            onChange={e => handleNonFormComponent(e, 'rankOne', 'xrmDemoDetailsForm')}
          />
        </div>
        <div className='col-sm-3  mt-3'>
          <InputField
            label={'2'}
            field='rankTwo'
            formName='xrmDemoDetailsForm'
            placeholder=''
            isRequired={false}
            type='text'
            validators={[]}
            value={xrmDemoDetailsForm.formData.rankTwo}
            error={xrmDemoDetailsForm.formData.rankTwoError}
            onChange={e => handleNonFormComponent(e, 'rankTwo', 'xrmDemoDetailsForm')}
          />
        </div>
        <div className='col-sm-3  mt-3'>
          <InputField
            label={'3'}
            field='rankThree'
            formName='xrmDemoDetailsForm'
            placeholder=''
            isRequired={false}
            type='text'
            validators={[]}
            value={xrmDemoDetailsForm.formData.rankThree}
            error={xrmDemoDetailsForm.formData.rankThreeError}
            onChange={e => handleNonFormComponent(e, 'rankThree', 'xrmDemoDetailsForm')}
          />
        </div>
        <div className='col-sm-3  mt-3'>
          <InputField
            label={'4'}
            field='rankFour'
            formName='xrmDemoDetailsForm'
            placeholder=''
            isRequired={false}
            type='text'
            validators={[]}
            value={xrmDemoDetailsForm.formData.rankFour}
            error={xrmDemoDetailsForm.formData.rankFourError}
            onChange={e => handleNonFormComponent(e, 'rankFour', 'xrmDemoDetailsForm')}
            //   bigSizeInput={'bigger-input-field'}}
          />
        </div>
        <div className='col-sm-4  mt-3'>
          <p className='mb-2'>{strings.intendedSystemText}</p>
          <Checkbox
            label={strings.failureAnalysis}
            field='failureAnalysis'
            isRequired={false}
            selected={xrmDemoDetailsForm.formData.failureAnalysis}
            onChange={e => handleNonFormComponent(e, 'failureAnalysis', 'xrmDemoDetailsForm')}
            newuiVersionStyle={true}
          />

          <Checkbox
            label={strings.researchRandD}
            field='researchRd'
            isRequired={false}
            selected={xrmDemoDetailsForm.formData.researchRd}
            onChange={e => handleNonFormComponent(e, 'researchRd', 'xrmDemoDetailsForm')}
            newuiVersionStyle={true}
          />
          <Checkbox
            label={strings.processDevelopment}
            field='processDevelopment'
            isRequired={false}
            selected={xrmDemoDetailsForm.formData.processDevelopment}
            onChange={e => handleNonFormComponent(e, 'processDevelopment', 'xrmDemoDetailsForm')}
            newuiVersionStyle={true}
          />
          <Checkbox
            label={strings.production}
            field='production'
            isRequired={false}
            selected={xrmDemoDetailsForm.formData.production}
            onChange={e => handleNonFormComponent(e, 'production', 'xrmDemoDetailsForm')}
            newuiVersionStyle={true}
          />
          <Checkbox
            label={strings.qaqc}
            field='qaQc'
            isRequired={false}
            selected={xrmDemoDetailsForm.formData.qaQc}
            onChange={e => handleNonFormComponent(e, 'qaQc', 'xrmDemoDetailsForm')}
            newuiVersionStyle={true}
          />
        </div>
        <div className='col-sm-4  mt-3'>
          <p className='mb-2'>{strings.customerBudget}</p>
          <Checkbox
            label={strings.flatPanel}
            field='flatPanel'
            isRequired={false}
            selected={xrmDemoDetailsForm.formData.flatPanel}
            onChange={e => handleNonFormComponent(e, 'flatPanel', 'xrmDemoDetailsForm')}
            newuiVersionStyle={true}
          />
          <Checkbox
            label={strings.fourtyX}
            field='fortyX'
            isRequired={false}
            selected={xrmDemoDetailsForm.formData.fortyX}
            onChange={e => handleNonFormComponent(e, 'fortyX', 'xrmDemoDetailsForm')}
            newuiVersionStyle={true}
          />
          <Checkbox
            label={strings.art}
            field='art'
            isRequired={false}
            selected={xrmDemoDetailsForm.formData.art}
            onChange={e => handleNonFormComponent(e, 'art', 'xrmDemoDetailsForm')}
            newuiVersionStyle={true}
          />
        </div>
        <div className='col-sm-12  mt-3'>
          <InputField
            label={strings.xrmPrimaryGoals}
            field='primaryGoalsOfDemo'
            formName='xrmDemoDetailsForm'
            placeholder=''
            isRequired={true}
            type='text'
            validators={[{ check: Validators.required, message: 'requiredErrorMessage' }]}
            value={xrmDemoDetailsForm.formData.primaryGoalsOfDemo}
            error={xrmDemoDetailsForm.formData.primaryGoalsOfDemoError}
            onChange={e => handleNonFormComponent(e, 'primaryGoalsOfDemo', 'xrmDemoDetailsForm')}
            bigSizeInput={'bigger-input-field'}
          />
        </div>
        <div className='col-sm-12  mt-3'>
          <InputField
            label={strings.xrmCustomerCompetition}
            field='customerSystemCompetition'
            formName='xrmDemoDetailsForm'
            placeholder=''
            isRequired={true}
            type='text'
            validators={[{ check: Validators.required, message: 'requiredErrorMessage' }]}
            value={xrmDemoDetailsForm.formData.customerSystemCompetition}
            error={xrmDemoDetailsForm.formData.customerSystemCompetitionError}
            onChange={e => handleNonFormComponent(e, 'customerSystemCompetition', 'xrmDemoDetailsForm')}
            bigSizeInput={'bigger-input-field'}
          />
        </div>
        <div className='col-sm-4 mt-3'>
          <ZmccRadioButton
            label={strings.doYouHaveAnyDataFromTheCustomer}
            formName='xrmDemoDetailsForm'
            value={xrmDemoDetailsForm.formData.xrmDoYouHaveDataFromCustomer}
            error={xrmDemoDetailsForm.formData.xrmDoYouHaveDataFromCustomerError}
            placeholder=''
            field='xrmDoYouHaveDataFromCustomer'
            validators={[{ check: Validators.required, message: 'requiredErrorMessage' }]}
            required={true}
            data={[
              {
                id: 'yes',
                value: 'yes',
              },
              {
                id: 'no',
                value: 'no',
              },
            ]}
            onChange={e => handleNonFormComponent(e, 'xrmDoYouHaveDataFromCustomer', 'xrmDemoDetailsForm')}
            isRequired={true}
          />
        </div>
      </div>
      </div>

      <h2 className='mt-4 zmcc-title-heading'>{strings.xrmApplicationInformation}</h2>
      <div className='col-sm-12 p-2 section-border'>
        <InputField
          label={strings.xrmApplicationInforDescText}
          field='detailedDescriptionOfCustomerFocus'
          formName='xrmApplicationInformationForm'
          placeholder=''
          isRequired={true}
          type='text'
          validators={[{ check: Validators.required, message: 'requiredErrorMessage' }]}
          value={xrmApplicationInformationForm.formData.detailedDescriptionOfCustomerFocus}
          error={xrmApplicationInformationForm.formData.detailedDescriptionOfCustomerFocusError}
          onChange={e =>
            handleNonFormComponent(e, 'detailedDescriptionOfCustomerFocus', 'xrmApplicationInformationForm')
          }
          bigSizeInput={'bigger-input-field'}
        />
      </div>
      <h2 className='mt-4 zmcc-title-heading'>{strings.xrmSampleDetails}</h2>
      <div className='col-sm-12  row p-2 section-border'>
        <div className='col-sm-3'>
          <p className='label-container'>{strings.totalNumberOfSamples}<span className='mandatory-asterik'>*</span></p>
          <div className='row p-3' style={xrmSamplesDetailsForm.formData.totalNumberOfSamplesForXrmError === 'requiredErrorMessage' ? {border: 'solid 1px red'} : {}}>
            <Checkbox
              label={'1'}
              field='totalNumberOfSamplesForXrm'
              isRequired={false}
              selected={
                xrmSamplesDetailsForm.formData.totalNumberOfSamplesForXrm
                  ? xrmSamplesDetailsForm.formData.totalNumberOfSamplesForXrm === '1'
                  : null
              }
              onChange={() => handleNonFormComponent('1', 'totalNumberOfSamplesForXrm', 'xrmSamplesDetailsForm')}
              className={'mr-5'}
              newuiVersionStyle={true}
            />
            <Checkbox
              label={'2'}
              field='totalNumberOfSamplesForXrm'
              isRequired={false}
              selected={
                xrmSamplesDetailsForm.formData.totalNumberOfSamplesForXrm
                  ? xrmSamplesDetailsForm.formData.totalNumberOfSamplesForXrm === '2'
                  : null
              }
              onChange={() => handleNonFormComponent('2', 'totalNumberOfSamplesForXrm', 'xrmSamplesDetailsForm')}
              className={'mr-5'}
              newuiVersionStyle={true}
            />
            <Checkbox
              label={'3'}
              field='totalNumberOfSamplesForXrm'
              isRequired={false}
              selected={
                xrmSamplesDetailsForm.formData.totalNumberOfSamplesForXrm
                  ? xrmSamplesDetailsForm.formData.totalNumberOfSamplesForXrm === '3'
                  : null
              }
              onChange={() => handleNonFormComponent('3', 'totalNumberOfSamplesForXrm', 'xrmSamplesDetailsForm')}
              className={'mr-5'}
              newuiVersionStyle={true}
            />
          </div>
        </div>
        <div className='col-sm-4'>
          <StatusOfSample {...props} />
        </div>
        <div className='col-sm-4'>
          <ZmccRadioButton
            label={strings.xrmPhysicallyManipulateSamples}
            formName='xrmSamplesDetailsForm'
            value={xrmSamplesDetailsForm.formData.xrmManipulateTheSamplesPriorToImaging}
            error={xrmSamplesDetailsForm.formData.xrmManipulateTheSamplesPriorToImagingError}
            placeholder=''
            field='xrmManipulateTheSamplesPriorToImaging'
            validators={[{ check: Validators.required, message: 'requiredErrorMessage' }]}
            required={true}
            data={[
              {
                id: 'yes',
                value: 'yes',
              },
              {
                id: 'no',
                value: 'no',
              },
            ]}
            onChange={e => handleNonFormComponent(e, 'xrmManipulateTheSamplesPriorToImaging', 'xrmSamplesDetailsForm')}
            isRequired={true}
          />
        </div>

        {xrmDemoDetailsForm.formData.xrmDoYouHaveDataFromCustomer === 'yes' && (
          <>
            <div className='d-flex'>
              <p className='mt-4'>{strings.zmccDocumentLink}</p>
              <Button
                isGhost={true}
                bgFill={false}
                dataTest='transaction-details-show-messages'
                styleClass='show-all-messages-button ml-2'
                iconPosition='left'
                onClick={() => props.downloadZmccDocumentFolder()}
                value={'ZMCC Documents'}
              />
            </div>

            <div className='col-sm-12 m-0 p-0 '>
              <Attachments
                text='Attach File'
                id='kitAttachmentsId'
                formValue={formValue && formValue}
                hasVideo={true}
                handleForm={props.updateSEMForm}
                actionFrom='zmccAttachement'
                isDownloadable
                downloadStream
                downloadIdField='kitAttachmentsId'
                isUploadAllowed={false}
                newVersionUi={true}
                uploaderStyle={"height-increased-dropzone"}
              />
            </div>
          </>
        )}
      </div>

      <h2 className='mt-4 zmcc-title-heading'>{strings.samepleDescription}</h2>
      <div className='col-sm-12  p-2 section-border'>
        <InputField
          label={strings.xrmOverAllSamples}
          field='describeOverallSamples'
          formName='xrmSampleDescriptionForm'
          placeholder=''
          isRequired={true}
          type='text'
          validators={[{ check: Validators.required, message: 'requiredErrorMessage' }]}
          value={xrmSampleDescriptionForm.formData.describeOverallSamples}
          error={xrmSampleDescriptionForm.formData.describeOverallSamplesError}
          onChange={e => handleNonFormComponent(e, 'describeOverallSamples', 'xrmSampleDescriptionForm')}
          bigSizeInput={'bigger-input-field'}
        />
      </div>

      <h2 className='mt-4 zmcc-title-heading'>{strings.sampleSpecs}</h2>

      <div className='p-2 section-border'>
        <FormComponent
          model={xrmSamplesSpecModel}
          formName='xrmSampleSpecsForm'
          formValue={xrmSampleSpecsForm.formData}
          onChange={handleForm}
        />
      </div>
    </>
  );
};

export default XRMApplicationSamplesDetails;
