import { batchActions } from 'redux-batched-actions';

import { logNetworkError } from 'library/utilities/logError';
import {
  fetchMasterDataServicePost,
} from 'library/api/master-data.service';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { scrollToTop } from 'library/utilities/scrollActions';

import { GET_STATUS_LIST } from './StatusList.constants';

export const fetchStatusListData = (dataToSend, id, demoUnitStatus) => async dispatch => {
  try {
    dispatch(changeLoader(true));
    let finalUrl = '';
    if(demoUnitStatus){
      finalUrl = URLS.demoUnitCompletedListWithDemoUnitStatus(id, demoUnitStatus);
    } else {
      finalUrl = URLS.demoUnitCompletedList(id);
    }
    const response = await fetchMasterDataServicePost(finalUrl, dataToSend);

    dispatch(
      batchActions([
        dispatch({
          type: GET_STATUS_LIST,
          payload: response.data,
        }),
        dispatch(changeLoader(false)),
      ]),
    );
  } catch (err) {
    dispatch(
      batchActions([
        dispatch(changeLoader(false)),
        dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage')),
      ]),
    );
    scrollToTop(500);
    logNetworkError(err);
  }
};
