import React from 'react';
import { useIqsSscRooms } from './IqsSscRooms.hook';
import { addNewRoom, deleteRoom, editRooms, getIqsRoomsList } from '../../CreateStock.actions';
import { connect } from 'react-redux';
import ExpandableTable from 'library/common/commonComponents/Table/AppExpandableTable';
import SearchField from 'library/common/commonComponents/SearchField/SearchField';

export const IqsSscRooms = ({
  stockId,
  addNewRoom,
  getIqsRoomsList,
  iqsSscRoomsList,
  editRooms,
  deleteRoom,
  canEditStock,
}) => {
  const { getCols, roomsList, handleSearchChange, searchData } = useIqsSscRooms({
    stockId,
    addNewRoom,
    getIqsRoomsList,
    editRooms,
    deleteRoom,
    iqsSscRoomsList,
    canEditStock,
  });
  return (
    <div className='container-fluid'>
      <div className='d-flex justify-content-end mt-3'>
        <div></div>
        <div className='input-wrapper'>
          <SearchField onChange={handleSearchChange} initialValue={searchData.searchText} />
        </div>
      </div>
      <div className='mt-3'>
        <div>
          <ExpandableTable cols={getCols()} rows={roomsList} dontShowExpandArrow={true} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ authReducer, languageReducer, createStockReducer }) => ({
  language: languageReducer.language,
  user: authReducer.user,
  stockId: createStockReducer.stockDetails.id,
  iqsSscRoomsList: createStockReducer.iqsSscRoomsList,
  canEditStock: createStockReducer.canEditStock,
});

export default connect(mapStateToProps, { addNewRoom, getIqsRoomsList, editRooms, deleteRoom })(IqsSscRooms);
