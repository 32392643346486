import React from 'react';
import toArray from 'lodash/toArray';

import strings from 'resources/locales/Translate';

import ItemsPerPage from 'library/common/commonComponents/ItemsPerPage/ItemsPerPage';
import ExpandableTable from 'library/common/commonComponents/Table/ExpandableTable';
import SearchField from 'library/common/commonComponents/SearchField/SearchField';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import Pagination from 'library/common/commonComponents/Pagination/Pagination';
import Button from 'library/common/commonComponents/Button/Button';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';

import { useShop } from '../../Shop.hook';
import { dataTable } from '../../dataTable';
import DataExpandableTable from '../../Components/DataExpandableTable';
import { itemsPerPage  } from '../../Shop.constants';
import '../../Shop.styles.scss';
import Filter from '../../Components/Filter';
import CancelReservationPopup from '../../Components/CancelReservationPopup';
import OrderPopup from '../../Components/OrderPopup';
import { useReservationList } from './Reservation.hook';
import { reservationListBreadcrumbs } from './Reservation.constants';

export default function Reservation({
  user,
  reservationList,
  countries,
  categoriesAndSystems,
  fetchMasterData,
  appActions,
  fetchReservationListData,
  addKitToObservationList,
  removeKitFromObservationList,
  reserveKit,
  cancelKitReservation,
  orderKit,
  language,
  fetchMarketplaceCountryList,
  marketplaceCountry
}) {
  document.title = 'Reservation';
  const { isLoading, displayActionMessage, type, message } = appActions;
  const {
    isShowFilter,
    data,
    handleSearchChange,
    showOrHideFilter,
    handleFilterChange,
    resetFilters,
    handleSort,
    handleItemsPerPage,
    handlePageChange,
    systemsClass,
  } = useShop({
    fetchMasterData,
    countries,
    categoriesAndSystems,
    language,
    fetchMarketplaceCountryList,
    marketplaceCountry
  });
  const {
    handleClickOnObserve,
    handleClickOnStopObserve,
    handleClickOnReserveKit,
    handleClickOnCancelReservation,
    openCancelReservatioPopup,
    closeCancelReservatioPopup,
    updateReservationList,
    openOrderPopup,
    isShowReservationList,
    isConfirmLoading,
    errorReservation,
    selectedKitId,
    isReservedKit,
  } = useReservationList({
    data,
    reservationList,
    fetchReservationListData,
    addKitToObservationList,
    removeKitFromObservationList,
    reserveKit,
    cancelKitReservation,
  });

  return (
    <div>
      {displayActionMessage && <ActionMessage type={type} message={strings[message]} />}
      <div className='container-fluid mb-3'>
        <Breadcrumbs data={reservationListBreadcrumbs} />
        <div className='row no-gutters align-items-center'>
          <h1 className='col-md col-12 kit-title'>{strings.reservation}</h1>
          <div className='col-md-auto col mr-3'>
            <div className='input-wrapper'>
              <SearchField onChange={handleSearchChange} initialValue={data.filter.searchTerm} />
            </div>
          </div>
          <div className='col-auto'>
            <Button
              bgFill={false}
              dataTest='reservation-list-filter-button'
              iconName='filter'
              styleClass='filter-button'
              onClick={showOrHideFilter}
              value={isShowFilter ? strings.hideFilter : strings.showFilter}
            />
          </div>
        </div>
      </div>
      <Filter
        countries={countries}
        categoriesAndSystems={systemsClass}
        resetFilters={resetFilters}
        handleFilterChange={handleFilterChange}
        selected={data.filter}
        isShowFilter={isShowFilter}
      />
      <div className='shop-table'>
        <div className='container-fluid shop-container mb-3'>
          <div className='title'>{isShowReservationList ? reservationList.totalElements : 0} {strings.results}</div>
          {isShowReservationList ? (
            <ExpandableTable
              user={user}
              cols={dataTable()}
              rows={reservationList.content}
              sortFields={data.sort}
              handleSort={handleSort}
            >
              <DataExpandableTable
                handleClickOnObserve={handleClickOnObserve}
                handleClickOnStopObserve={handleClickOnStopObserve}
                handleClickOnReserveKit={handleClickOnReserveKit}
                handleClickOnCancelReservation={openCancelReservatioPopup}
                openOrderPopup={openOrderPopup}
              />
              </ExpandableTable>
          ) : (
            <div className='d-flex justify-content-center mb-3'>
              <strong>{strings.noDataFoundText}</strong>
            </div>
          )}
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row no-gutters'>
          {isShowReservationList && (
            <div className='col d-flex justify-content-end mb-3'>
              <ItemsPerPage
                itemsPerPage={toArray(itemsPerPage)}
                handleItemsPerPage={handleItemsPerPage}
                value={itemsPerPage[data.size]}
              />
              <Pagination
                currentPage={data.page - 1}
                totalPages={reservationList.totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
      {isLoading && <div className='loader' />}
      <CancelReservationPopup
        id='reservation-list-cancel-reservation-popup'
        isLoading={isConfirmLoading}
        handleConfirmation={handleClickOnCancelReservation}
        closePopup={closeCancelReservatioPopup}
        error={errorReservation}
      />
      <OrderPopup
        id='reservation-order-popup'
        handleClickOnConfirmation={orderKit}
        handleClickOnReserve={handleClickOnReserveKit}
        updateList={updateReservationList}
        kitId={selectedKitId}
        isReservedKit={isReservedKit}
      />
    </div>
  );
}
