import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import Modal from 'library/common/commonComponents/Modal';
import React from 'react';
import { useAddressConfigration } from './AddAddressConfigPopUp.hook';
import { connect } from 'react-redux';

import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { saveAddressConfigration } from '../../MasterData.actions';

export const AddAddressConfigrationModal = ({ id, masterData, saveAddressConfigration }) => {
  const { model, handleForm, configForm, onCloseClick, onSaveClick, error, loading } = useAddressConfigration({
    masterData,
    id,
    saveAddressConfigration,
  });
  return (
    <Modal id={id} confirmAction={onSaveClick} loading={loading} disableBackgroundClose onCloseClick={onCloseClick}>
      <FormComponent
        model={model}
        formName='AddAddressconfigForm'
        formValue={configForm.formData}
        onChange={handleForm}
      />
      {error && <div className='error-block'>{error}</div>}
    </Modal>
  );
};
const mapStateToProps = ({ languageReducer, masterDataReducer, administrationReducer, appActionsReducer }) => ({
  language: languageReducer,
  masterData: masterDataReducer,
  administrationReducer: administrationReducer,
  appActions: appActionsReducer,
});

export default connect(mapStateToProps, {
  saveAddressConfigration,
  toggleActionMessage,
})(AddAddressConfigrationModal);
