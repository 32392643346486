import { connect } from 'react-redux';

import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { createDeliveryDocumentFooter, fetchDeliveryDocumentFooter } from './DeliveryDocuments.actions';

import DeliveryDocuments from './DeliveryDocuments.component';

const mapStateToProps = ({ createStockReducer, languageReducer }) => ({
  //   masterData: masterDataReducer,
  createStockReducer: createStockReducer,
  //   entitlements: entitlementReducer.entitlements,
  language: languageReducer,
});

export default connect(mapStateToProps, {
  createDeliveryDocumentFooter,
  fetchDeliveryDocumentFooter,
  toggleActionMessage,
})(DeliveryDocuments);

// const data = {
//   kitInformationId: 919,
//   deleteImageIds: [],
//   deleteAttachmentIds: [],
//   kitImages: [{ kitImagesId: 98, size: '106.8 KB', type: 'image/jpeg', file: 'single_lens4.jpg' }],
//   kitAttachments: [],
//   kitName: 'Kit name- 07 april',
//   description: 'test↵test↵test↵test',
//   systemValue: 32434,
//   hideKit: false,
//   stockInformation: { id: 1 },
//   storageLocation: 'test',
//   businessUnit: { id: 1 },
//   sapERP: '',
//   systemClass: { systemClassId: 4 },
//   systemMainComponent: { systemMainComponentId: 11 },
//   serialNumber: '2132',
//   ibase: 'MM0919',
//   marketingMaterial: true,
//   dateOfManufacture: '2020-01-01 00:00:00',
//   country: { id: 1 },
//   commodityCode: '',
//   deliveryDays: 1,
//   returnDeliveryDays: 1,
//   reconditioningDeliveryDays: 1,
//   availabilityFrom: '2020-04-01 00:00:00',
//   availabilityTo: '',
//   packageDimensions: '',
//   kitCondition: { kitConditionId: 7 },
//   comment: 'test',
//   freeAccessories: '',
//   furtherImportantComponents: '',
//   salesComponents: '414919-9',
// };
