import moment from 'moment';

export const checkIsBeforeDate = (targetDate, actualDate) => {
    const target = moment(targetDate);
    const actual = moment(actualDate);

    const isValidTarget = target.isValid();
    const isValidActual = actual.isValid();
    const isSameDates = target.isSame(actual);

    if (!isValidActual) {
      return moment(moment()).isBefore(target);
    }

    if (isSameDates) {
      return true;
    }

    if (isValidTarget) {
      return moment(actualDate).isBefore(target);
    }

    return false;
}
