import React, { useState, useEffect } from 'react';

import Table from 'library/common/commonComponents/Table/Table';
import Pagination from 'library/common/commonComponents/Pagination/Pagination';
import { fetchServiceTicketsService } from 'library/api/master-data.service';

import { dataTable } from './dataTable';

const ServiceTicket = ({ language, kitDetail }) => {
  const [serviceTickets, setServiceTickets] = useState({});
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const totalPages = serviceTickets.items && Math.ceil(serviceTickets.count / 10);

  useEffect(() => {
    if (kitDetail.kitInformationId) {
      async function getServiceTickets() {
        setIsLoading(true);
        try {
          const tickets = await fetchServiceTicketsService(page, 10, kitDetail.kitInformationId);
          setServiceTickets(tickets);
        } catch (e) {
          setServiceTickets([]);
        }
        setIsLoading(false);
      }
  
      getServiceTickets();
    }
  }, [page, kitDetail]);

  const handlePageChange = page => setPage(page);

  return (
      <div>
        {!isLoading && serviceTickets.items && serviceTickets.items.length > 0 ? (
          <>
            <div className='container-fluid m-3'>
              <Table
                cols={dataTable(language)}
                rows={serviceTickets.items}
              />
            </div>
            <div className='col d-flex justify-content-end'>
              <Pagination
                currentPage={page}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </>
        ) : (
          <strong className='d-flex justify-content-center dummy-data'>No Service Tickets</strong>
        )}
        {isLoading && <div className='loader' />}
      </div>
  );
};

export default ServiceTicket;
