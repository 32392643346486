import React from 'react';

import strings from 'resources/locales/Translate';

const Card = ({ requestInfo }) => {
  const { budget, currencyName, createdBy, description, validity, systemClassName, systemMainComponentName } = requestInfo;
  const name = `${createdBy.firstName} ${createdBy.lastName}`;

  return (
    <div className='card-block'>
      <h5 className='title mb-3'>{strings.haveRequestFor} {description}</h5>
      <div className='row'>
        <div className='col-6'>{strings.systemClassText}:</div>
          <div className='col-6'>
            <span className='text-bold'>{systemClassName}</span>
          </div>
        <div className='col-6'>{strings.mainComponent}:</div>
        <div className='col-6'>
          <span className='text-bold'>
            {systemMainComponentName}
          </span>
        </div>
        <div className='col-6'>{strings.budget}:</div>
        <div className='col-6'>
          <span className='text-bold'>
            {budget} {currencyName}
          </span>
        </div>
        <div className='col-6'>{strings.validity}:</div>
        <div className='col-6'>
          <span className='text-bold'>
            {validity} {strings.daysLeft}
          </span>
        </div>
        <div className='col-6'>{strings.createdBy}:</div>
        <div className='col-6'>
          <span className='text-bold'>
            {name}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Card;
