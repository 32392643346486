import { useEffect, useCallback } from 'react';
import $ from 'jquery';

import { saveToStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';

export function useOrder({
  data,
  orderList,
  fetchOrderListData,
  addKitToObservationList,
  removeKitFromObservationList,
}) {
  useEffect(() => {
    saveToStorage(identifiers.filtersShop, data.filter);
    fetchOrderListData(data);
  }, [data, fetchOrderListData]);

  const openExportOrderList = () => {
    $('#export-order-list').modal('show');
  };

  const handleClickOnObserve = useCallback(async kitId => {
    await addKitToObservationList(kitId);
    fetchOrderListData(data);
  }, [data, addKitToObservationList, fetchOrderListData]);

  const handleClickOnStopObserve = useCallback(async kitId => {
    await removeKitFromObservationList(kitId)
    fetchOrderListData(data);
  }, [data, fetchOrderListData, removeKitFromObservationList]);

  const isShowOrderListList = orderList && orderList.content && orderList.content.length > 0;

  return {
    openExportOrderList,
    handleClickOnObserve,
    handleClickOnStopObserve,
    isShowOrderListList,
  };
}
