import Validators from 'library/utilities/Validators';
import strings from 'resources/locales/Translate';

export const validityOptions = () => [
  {
    id: 1,
    value: strings.oneMonth,
  },
  {
    id: 2,
    value: `2 ${strings.months}`,
  },
  {
    id: 3,
    value: `3 ${strings.months}`,
  },
];

export const model = [
  {
    label: 'whatAreYouLookingFor',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'description',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-12'
  },
  {
    label: 'categorySystem',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'systemClassId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    selectAllOption: false,
    idKey: 'systemClassId',
    displayValue: [{ key: 'systemClassText', separator: '' }],
  },
  {
    label: 'mainComponent',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'systemMainComponentId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'systemComponentId',
    displayValue: [{ key: 'systemMainComponentText', separator: '' }],
  },
  {
    label: 'budget',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'budget',
    validators: [
      { check: Validators.required, message: 'requiredErrorMessage' },
      { check: Validators.number, message: 'invalidNumberError' },
    ],
    required: true,
    styleClass: 'col-6'
  },
  {
    label: 'currency',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'currencyId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'value', separator: '' }],
    selectAllPlaceholder: 'pleaseSelect',
  },
  {
    label: 'validity',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'validity',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'value', separator: '' }],
  },
  {
    label: 'countryLand',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'countryId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    selectAllOption: false,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: '' }],
  },
];
