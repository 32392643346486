import React from 'react';
import moment from 'moment';
import Information from './Tabs/Information';
import Messages from './Tabs/Messages';
import ChangeHistory from './Tabs/ChangeHistory';
import C2CLoanProcesses from './Tabs/C2CLoanProcesses';
import Rating from './Tabs/Rating/RatingContainer';
import Attachments from './Tabs/Attachments';
import strings from 'resources/locales/Translate';
import { getDateInUTCFormat } from 'library/utilities/getDateInFormat';
import LLInformation from './Tabs/LLInformation';
import MaintenanceCheckList from './Tabs/MaintenanceCheckList/MaintenanceCheckList.component';

const getTransactionName = urlData => {
  let transactionType = '';
  urlData &&
    urlData.split('-').forEach((item, index) => {
      if (index !== 0) {
        transactionType += item.charAt(0).toUpperCase() + item.slice(1);
      } else {
        transactionType = item;
      }
    });
  if (transactionType === 'c2c') {
    transactionType = 'c2cLoans';
  }
  return transactionType;
};
export const breadcrumbsData = (transactionType, route) => [
  {
    name: 'homepage',
    url: '/home',
  },
  {
    name: getTransactionName(transactionType),
    url: `/${transactionType === 'c2c' ? `${transactionType}-loans` : transactionType}`,
  },
  {
    name: route,
    url: '',
  },
];

export const createTabsKitLoan = ({
  editing,
  history,
  messagesCount,
  changeHistoryCount,
  hasPermissions,
  setCurrentTab,
  onEditPress,
  transactionsType,
  detailPage,
  usersList,
  bundleLoansWithSameStock,
  openRelatedTransactionsModal,
  fetchTransactionDetails,
  attachmentsCount,
  showRequestPopup,
  workFlowQuestions,
  hasManagerPermission,
  transactionIdDummy,
  match
}) => {
  const hasERPActiavted = () => {
    const workFlowCategoryData =
      workFlowQuestions &&
      workFlowQuestions.stockWorkflowDTOS && workFlowQuestions.stockWorkflowDTOS[1] &&
      workFlowQuestions.stockWorkflowDTOS[1]['stockWorkflowSettingDTO'];
    const isWorkflowErp = workFlowCategoryData && workFlowCategoryData.find(item => item.categoryQuestionId === 33);
    const finalValue = isWorkflowErp != null ? isWorkflowErp.categoryAnswer === 'yes' : false;
    return finalValue;
  };
  const isActivated = hasERPActiavted();
  if (isActivated === true) {
    return [
      {
        id: 1,
        tabTitle: 'information',
        className: '',
        render: () => (
          <Information
            editing={editing}
            onEditPress={onEditPress}
            hasPermissions={hasPermissions}
            setCurrentTab={setCurrentTab}
            transactionsType={transactionsType}
            detailPage={detailPage}
            transactionType={transactionsType}
            usersList={usersList}
            bundleLoansWithSameStock={bundleLoansWithSameStock}
            openRelatedTransactionsModal={openRelatedTransactionsModal}
            hasManagerPermission={hasManagerPermission}
          />
        ),
      },
      {
        id: 2,
        tabTitle: 'messages',
        count: messagesCount,
        className: '',
        render: () => <Messages showRequestPopup={showRequestPopup} />,
      },
      {
        id: 3,
        tabTitle: 'changeHistory',
        count: changeHistoryCount,
        className: '',
        render: () => <ChangeHistory  match={match}/>,
      },
      {
        id: 4,
        tabTitle: 'c2cLoanProcesses',
        className: '',
        render: () => (
          <C2CLoanProcesses history={history} setCurrentTab={setCurrentTab} transactionsType={transactionsType} />
        ),
      },
      {
        id: 7,
        tabTitle: 'maitenanceCheckList',
        className: '',
        render: () => <MaintenanceCheckList />,
      },
      {
        id: 5,
        tabTitle: 'rating',
        className: '',
        render: () => <Rating editing={editing} />,
      },
      {
        id: 6,
        tabTitle: 'attachments',
        className: '',
        count: attachmentsCount,
        render: () => <Attachments fetchTransactionDetails={fetchTransactionDetails} editing={editing} />,
      },
    ];
  } else {
    return [
      {
        id: 1,
        tabTitle: 'information',
        className: '',
        render: () => (
          <Information
            editing={editing}
            onEditPress={onEditPress}
            hasPermissions={hasPermissions}
            setCurrentTab={setCurrentTab}
            transactionsType={transactionsType}
            detailPage={detailPage}
            transactionType={transactionsType}
            usersList={usersList}
            bundleLoansWithSameStock={bundleLoansWithSameStock}
            openRelatedTransactionsModal={openRelatedTransactionsModal}
            hasManagerPermission={hasManagerPermission}
          />
        ),
      },
      {
        id: 2,
        tabTitle: 'messages',
        count: messagesCount,
        className: '',
        render: () => <Messages showRequestPopup={showRequestPopup} />,
      },
      {
        id: 3,
        tabTitle: 'changeHistory',
        count: changeHistoryCount,
        className: '',
        render: () => <ChangeHistory match={match}/>,
      },
      {
        id: 4,
        tabTitle: 'c2cLoanProcesses',
        className: '',
        render: () => (
          <C2CLoanProcesses history={history} setCurrentTab={setCurrentTab} transactionsType={transactionsType} />
        ),
      },
      {
        id: 5,
        tabTitle: 'rating',
        className: '',
        render: () => <Rating editing={editing} />,
      },
      {
        id: 6,
        tabTitle: 'attachments',
        className: '',
        count: attachmentsCount,
        render: () => <Attachments fetchTransactionDetails={fetchTransactionDetails} editing={editing} />,
      },
    ];
  }
};

export const createTabsLLKitLoan = ({
  editing,
  messagesCount,
  changeHistoryCount,
  setCurrentTab,
  onEditPress,
  transactionsType,
  detailPage,
  fetchTransactionDetails,
  attachmentsCount,
  match,
  bundleLoans
}) => {
  return [
    {
      id: 1,
      tabTitle: 'information',
      className: '',
      render: () => (
        <LLInformation
          editing={editing}
          onEditPress={onEditPress}
          setCurrentTab={setCurrentTab}
          detailPage={detailPage}
          transactionType={transactionsType}
          bundleLoans={bundleLoans}
        />
      ),
    },
    {
      id: 2,
      tabTitle: 'messages',
      count: messagesCount,
      className: '',
      render: () => <Messages />,
    },
    {
      id: 3,
      tabTitle: 'changeHistory',
      count: changeHistoryCount,
      className: '',
      render: () => <ChangeHistory match={match}/>,
    },
    {
      id: 5,
      tabTitle: 'attachments',
      className: '',
      count: attachmentsCount,
      render: () => <Attachments fetchTransactionDetails={fetchTransactionDetails} editing={editing} />,
    },
  ];
};

export const createTabs = ({
  editing,
  onEditPress,
  history,
  messagesCount,
  changeHistoryCount,
  hasPermissions,
  setCurrentTab,
  transactionsType,
  detailPage,
  loanType,
  usersList,
  bundleLoansWithSameStock,
  openRelatedTransactionsModal,
  fetchTransactionDetails,
  attachmentsCount,
  showRequestPopup,
  hasManagerPermission,
  match
}) => {
  const tabs = [
    {
      id: 1,
      tabTitle: 'information',
      className: '',
      render: () => (
        <Information
          editing={editing}
          onEditPress={onEditPress}
          hasPermissions={hasPermissions}
          setCurrentTab={setCurrentTab}
          transactionsType={transactionsType}
          detailPage={detailPage}
          usersList={usersList}
          bundleLoansWithSameStock={bundleLoansWithSameStock}
          openRelatedTransactionsModal={openRelatedTransactionsModal}
          hasManagerPermission={hasManagerPermission}
        />
      ),
    },
    {
      id: 2,
      tabTitle: 'messages',
      count: messagesCount,
      className: '',
      render: () => <Messages showRequestPopup={showRequestPopup} />,
    },
    {
      id: 3,
      tabTitle: 'changeHistory',
      count: changeHistoryCount,
      className: '',
      render: () => <ChangeHistory match={match}/>,
    },
    {
      id: 4,
      tabTitle: 'rating',
      className: '',
      render: () => <Rating />,
    },
  ];
  const attachmentTab = {
    id: 5,
    tabTitle: 'attachments',
    className: '',
    count: attachmentsCount,
    render: () => <Attachments fetchTransactionDetails={fetchTransactionDetails} editing={editing} />,
  };

  return loanType === 'courseLoan' || loanType === 'singleLoan' ? [...tabs, attachmentTab] : tabs;
};

export const transactionInformationModel = (transactionDetails, canEditRequested, isC2c, user, stockId) => {
  let defaultValue = '';
  const stockRoles = user.stockRoles.filter(sr => sr.roleName === 'Stock Controller');
  if (
    transactionDetails &&
    (transactionDetails.targetDeliveryDate ||
      transactionDetails.targetReservationFrom ||
      transactionDetails.targetReservationFrom ||
      transactionDetails.targetReturnDeliveryDate)
  ) {
    defaultValue = 'Target: ...';
  }
  let isOpenDateModal = false;
  if (transactionDetails) {
    isOpenDateModal = !transactionDetails.showReturnGoodsBooking;
  }
  let additionalLabelCheckedDate = defaultValue;
  if (transactionDetails && transactionDetails.type === 'kit loan' && transactionDetails.targetReconditioningTo) {
    additionalLabelCheckedDate = `${strings.target}: ${getDateInUTCFormat(
      'DD.MM.YYYY',
      transactionDetails.targetReconditioningTo,
    )}`;
  } else if (transactionDetails && transactionDetails.targetCheckedDate) {
    additionalLabelCheckedDate = `${strings.target}: ${getDateInUTCFormat(
      'DD.MM.YYYY',
      transactionDetails.targetCheckedDate,
    )}`;
  }
  const isAdmin = user.admin;
  const isStockController = stockRoles.length ? true : false;
   // Checking if the current transaction is from MED USA
   const stockInformation = transactionDetails?.stockInformation || transactionDetails?.transactionKit?.stockInformation
   const isBUMED = stockInformation?.businessUnit?.designation === "MED"
   const isCountryUSA = stockInformation?.country?.shortName === "US"
   const isMedUSA = isBUMED && isCountryUSA
  const deliveryDateDisabled =
    isAdmin || isStockController
      ? false
      : !!(
          transactionDetails.deliveryDate &&
          moment(transactionDetails.deliveryDate).isValid() &&
          moment(transactionDetails.deliveryDate).isBefore(moment())
        );
  const reservationFromDisabled =
    isAdmin || isStockController
      ? false
      : !!(
          transactionDetails.reservationFrom &&
          moment(transactionDetails.reservationFrom).isValid() &&
          moment(transactionDetails.reservationFrom).isBefore(moment())
        );
  const reservationToDisabled = isAdmin || isStockController
    ? false
    : !!(
        transactionDetails.reservationTo &&
        moment(transactionDetails.reservationTo).isValid() &&
        moment(transactionDetails.reservationTo).isBefore(moment())
      );
  const returnDeliveryDateDisabled = isAdmin || isStockController
    ? false
    : !!(transactionDetails.returnDelivery && moment(transactionDetails.returnDelivery).isValid());
  const checkedDateDisabled = isAdmin || isStockController
    ? false
    : (!transactionDetails.showReturnGoodsBooking && transactionDetails.type !== 'kit loan') ||
      (transactionDetails && transactionDetails.checkedDate);

  const disableAddingDate = !user.admin && !transactionDetails.deliveryDate;

  const roles = ['Borrower', 'External Borrower', 'Borrower MCS', 'Borrower ODX', 'Borrower SUR'];
  const checkRolesPermissionForReturnDeliveryAndRecieptAtCustomer = () => {
    if (user.admin) {
      return false
    }
    if (user && user.stockRoles && user.stockRoles.length > 0) {
      return roles.some(role => {
        return user.stockRoles.some(stockRole => stockRole.roleName === role && stockId === stockRole.stockId);
      });
    }
    return false;
  }

  const datesDisableForborrowerRole = checkRolesPermissionForReturnDeliveryAndRecieptAtCustomer();

  let isWarehouseManager = user.stockRoles.filter(sr => sr.roleName === 'Stock Controller' && sr.stockId === stockInformation.id);
  isWarehouseManager = isWarehouseManager.length ? true: false
  return [
    {
      dateFormat: 'DD.MM.YYYY',
      field: 'deliveryDate',
      label: 'shipped',
      disabled: datesDisableForborrowerRole || (!canEditRequested || deliveryDateDisabled),
      additionalLabel:
        transactionDetails && transactionDetails.targetDeliveryFrom
          ? `${strings.target}: ${getDateInUTCFormat('DD.MM.YYYY', transactionDetails.targetDeliveryFrom)}`
          : defaultValue,
      footerLabel:
        transactionDetails && transactionDetails.deliveryDate &&
        transactionDetails.deliveryDateEditedBy &&
        `${strings.editedBy}: ${transactionDetails.deliveryDateEditedBy.firstName} ${transactionDetails.deliveryDateEditedBy.lastName}`,
      maxDate: new Date(moment().add(10, 'years').format('YYYY-MM-DD')),
      placeholder: 'selectDate',
      required: false,
      hideOptionalText: true,
      styleClass: 'transaction-details-date-input',
      mainContainerStyle: 'transaction-details-date-container mb-4 mb-md-0 mr-md-3',
      type: 'date',
      validators: [],
      value: '',
      extraProps: {
        enableClearDate: isAdmin || (isWarehouseManager && isMedUSA),
      }
    },
    {
      dateFormat: 'DD.MM.YYYY',
      field: 'reservationFrom',
      label: 'receiptAtTheCustomer',
      disabled: (canEditRequested && disableAddingDate) || !canEditRequested || reservationFromDisabled,
      additionalLabel:
        transactionDetails && transactionDetails.targetReservationFrom
          ? `${strings.target}: ${getDateInUTCFormat('DD.MM.YYYY', transactionDetails.targetReservationFrom)}`
          : defaultValue,
      footerLabel:
        transactionDetails && transactionDetails.reservationFrom &&
        transactionDetails.reservationFromEditedBy &&
        `${strings.editedBy}: ${transactionDetails.reservationFromEditedBy.firstName} ${transactionDetails.reservationFromEditedBy.lastName}`,
      maxDate: new Date(moment().add(10, 'years').format('YYYY-MM-DD')),
      placeholder: 'selectDate',
      required: false,
      hideOptionalText: true,
      styleClass: 'transaction-details-date-input',
      mainContainerStyle: 'transaction-details-date-container mb-4 mb-md-0 mr-md-3',
      type: 'date',
      validators: [],
      value: '',
      extraProps: {
        enableClearDate: isAdmin || (isWarehouseManager && isMedUSA),
      }
    },
    (transactionDetails.type === 'kit loan' && isC2c) && {
      dateFormat: 'DD.MM.YYYY',
      field: 'checkBeforeReturn',
      label: 'checkBeforeReturn',
      disabled: (canEditRequested && disableAddingDate) || !canEditRequested || checkedDateDisabled,
      additionalLabel:
        transactionDetails && transactionDetails.targetCheckBeforeReturnDate ?
        `${strings.target}: ${moment(transactionDetails.targetCheckBeforeReturnDate).format('DD.MM.YYYY')}`
        : transactionDetails.targetReservationTo  ? `${strings.target}: ${moment(transactionDetails.targetReservationTo).subtract(1, 'day').format('DD.MM.YYYY')}`
        : defaultValue,
      footerLabel:
        transactionDetails && transactionDetails.checkBeforeReturn &&
        transactionDetails.checkBeforeReturnEditedBy &&
        `${strings.editedBy}: ${transactionDetails.checkBeforeReturnEditedBy.firstName} ${transactionDetails.checkBeforeReturnEditedBy.lastName}`,
      maxDate: new Date(moment().add(10, 'years').format('YYYY-MM-DD')),
      placeholder: 'selectDate',
      required: false,
      hideOptionalText: true,
      styleClass: 'transaction-details-date-input',
      mainContainerStyle: 'transaction-details-date-container mb-4 mb-md-0 mr-md-3',
      type: 'date',
      validators: [],
      value: '',
      extraProps: {
        enableClearDate: isAdmin || (isWarehouseManager && isMedUSA),
      }
    },
    {
      dateFormat: 'DD.MM.YYYY',
      field: 'reservationTo',
      label: 'returnDelivery',
      disabled: (canEditRequested && disableAddingDate) || !canEditRequested || reservationToDisabled,
      additionalLabel:
        transactionDetails && transactionDetails.targetReturnDeliveryFrom
          ? `${strings.target}: ${getDateInUTCFormat('DD.MM.YYYY', transactionDetails.targetReturnDeliveryFrom)}`
          : defaultValue,
      footerLabel:
        transactionDetails && transactionDetails.reservationTo &&
        transactionDetails.reservationToEditedBy &&
        `${strings.editedBy}: ${transactionDetails.reservationToEditedBy.firstName} ${transactionDetails.reservationToEditedBy.lastName}`,
      maxDate: new Date(moment().add(10, 'years').format('YYYY-MM-DD')),
      placeholder: 'selectDate',
      required: false,
      hideOptionalText: true,
      styleClass: 'transaction-details-date-input',
      mainContainerStyle: 'transaction-details-date-container mb-4 mb-md-0 mr-md-3',
      type: 'date',
      validators: [],
      value: '',
      extraProps: {
        enableClearDate: isAdmin || (isWarehouseManager && isMedUSA),
      }
    },
    {
      dateFormat: 'DD.MM.YYYY',
      field: 'returnDeliveryDate',
      disabled:
      datesDisableForborrowerRole ||
      ((canEditRequested && disableAddingDate) || !canEditRequested || returnDeliveryDateDisabled),
      additionalLabel:
        transactionDetails && transactionDetails.targetReturnDeliveryTo
          ? `${strings.target}: ${getDateInUTCFormat('DD.MM.YYYY', transactionDetails.targetReturnDeliveryTo)}`
          : defaultValue,
      footerLabel:
        transactionDetails && transactionDetails.returnDelivery &&
        transactionDetails.returnDeliveryEditedBy &&
        `${strings.editedBy}: ${transactionDetails.returnDeliveryEditedBy.firstName} ${transactionDetails.returnDeliveryEditedBy.lastName}`,
      label: (isC2c && transactionDetails.allNextC2c.length > 0) ? `${strings.receivedNextCustomer} / ${strings.atStock.toLowerCase()}` : 'receiptInStock',
      maxDate: new Date(moment().add(10, 'years').format('YYYY-MM-DD')),
      placeholder: 'selectDate',
      required: false,
      hideOptionalText: true,
      styleClass: 'transaction-details-date-input',
      mainContainerStyle: 'transaction-details-date-container mb-4 mb-md-0 mr-md-3',
      type: 'date',
      validators: [],
      value: '',
      isOpenDateModal: isOpenDateModal,
      extraProps: {
        enableClearDate: isAdmin || (isWarehouseManager && isMedUSA),
      }
    },
    (((transactionDetails.type === 'kit loan' || transactionDetails.type === 'single loan' || transactionDetails.type === 'course loan') && !isC2c ) ||
    isC2c && !transactionDetails.allNextC2c.length > 0) && {
      dateFormat: 'DD.MM.YYYY',
      field: 'checkedDate',
      label: 'checkedDate',
      disabled:
      datesDisableForborrowerRole ||
      ((canEditRequested && disableAddingDate) || !canEditRequested || checkedDateDisabled || ( isC2c && transactionDetails.allNextC2c.length > 0)),
      additionalLabel: additionalLabelCheckedDate,
      footerLabel:
        transactionDetails && transactionDetails.checkedDate &&
        transactionDetails.checkedDateEditedBy &&
        `${strings.editedBy}: ${transactionDetails.checkedDateEditedBy.firstName} ${transactionDetails.checkedDateEditedBy.lastName}`,
      maxDate: new Date(moment().add(10, 'years').format('YYYY-MM-DD')),
      placeholder: 'selectDate',
      required: false,
      hideOptionalText: true,
      styleClass: 'transaction-details-date-input',
      mainContainerStyle: 'transaction-details-date-container mb-4 mb-md-0',
      type: 'date',
      validators: [],
      value: '',
      extraProps: {
        enableClearDate: isAdmin || (isWarehouseManager && isMedUSA),
      }
    },
  ];
};

export const FETCH_ORDER_TYPES_LIST = 'FETCH_ORDER_TYPES_LIST';
export const FETCH_BILLINGS_LIST = 'FETCH_BILLINGS_LIST';
export const FETCH_DISTRIBUTION_CHANNEL = 'FETCH_DISTRIBUTION_CHANNEL';
export const DIVISION_LIST = 'DIVISION_LIST';
export const FETCH_DELIVERY_BLOCK_LIST = 'FETCH_DELIVERY_BLOCK_LIST';
export const FETCH_SAP_ERP_MAPPING_DATA = 'FETCH_SAP_ERP_MAPPING_DATA';
export const FETCH_CUSTOMER_GROUP_LIST = 'FETCH_CUSTOMER_GROUP_LIST';
export const FETCH_TRANSACTIONS_MAINTENANCE_CHECKLIST_DETAILS = 'FETCH_TRANSACTIONS_MAINTENANCE_CHECKLIST_DETAILS';
export const FETCH_ACTIVE_TRANSACTION = 'FETCH_ACTIVE_TRANSACTION';
