import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';

import { fetchArchiveListData, exportArchiveList, fetchMarketplaceCountryList } from '../../Shop.actions';
import Archive from './Archive.component';

const mapStateToProps = ({ authReducer, languageReducer, masterDataReducer, appActionsReducer, shopListReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
  appActions: appActionsReducer,
  countries: masterDataReducer.countries,
  categoriesAndSystems: masterDataReducer.systemClassWithStock,
  archiveList: shopListReducer.archiveList,
  marketplaceCountry: shopListReducer.marketplaceCountry,
});

export default withRouter(connect(mapStateToProps, {
  fetchMasterData,
  fetchArchiveListData,
  exportArchiveList,
  fetchMarketplaceCountryList
})(Archive));
