import React, { Component } from 'react';

import strings from 'resources/locales/Translate';

export class Print extends Component {
  render() {
    const { kitDetails } = this.props;
    const splitted = kitDetails.description && kitDetails.description.split("\n");   
    return (
      <div className='container-fluid kit-print-container'>
        <div className='d-flex flex-row mb-3'>
          <div>
          {kitDetails.qrCode && <img src={kitDetails.qrCode} alt='' className='print-qrcode' />}
          </div>
          <div className='ml-5'>
            <h2 className='title'>{kitDetails.kitName}</h2>
            <div className='d-flex flex-row mt-3'>
              <div className="bold mr-2">{`${strings.kitNumber}: `}</div>
              <div>{kitDetails.kitInformationId}</div>
            </div>
            <div className='d-flex flex-row'>
              <div className="bold mr-2">{`${strings.serialNumber}: `}</div>
              <div>{kitDetails.serialNumber || '-'}</div>
            </div>
            <div className='d-flex flex-row'>
              <div className="bold mr-2">{`${strings.ibaseText}: `}</div>
              <div>{kitDetails.ibase ? kitDetails.ibase : '-'}</div>
            </div>
            <div className='d-flex flex-row'>
              <div className="bold mr-2">{`${strings.stock}: `}</div>
              <div>{(kitDetails.stockInformation && kitDetails.stockInformation.locationName) || '-'}</div>
            </div>

            <div className='d-flex flex-row'>
              <div className="bold mr-2">{`${strings.categorySystem}: `}</div>
              <div>{(kitDetails.systemClass && kitDetails.systemClass.systemClassName) || '-'}</div>
            </div>

            <div className='subtitle mb-0 mt-3'>{strings.descriptionText}</div>
            {splitted && splitted.map(item => <div className='description-container mb-3' key={item}>{item}</div>)}
          </div>
        </div>
      </div>
    );
  }
}
export default Print;
