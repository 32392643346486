import Validators from "library/utilities/Validators";

export const AddressFormModel = [
  {
    label: 'addressTitle',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'addressType',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-sm-12',
    extraProps: {
      maxLength: 50
    }
  },
  {
    label: 'salutation',
    value: '',
    type: 'radio-button',
    placeholder: '',
    field: 'salutation',
    validators: [],
    required: false,
    styleClass: 'col-sm-12',
    options: [
      {
        id: 'mr',
        value: 'mr'
      },
      {
        id: 'ms',
        value: 'ms'
      }
    ]
  },
  {
    label: 'firstName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'firstName',
    validators: [],
    required: false,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50
    }
  },
  {
    label: 'lastName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'lastName',
    validators: [],
    required: false,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50
    }
  },
  {
    label: 'organization',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'organization',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-sm-12',
    extraProps: {
      maxLength: 50
    }
  },
  {
    label: 'department',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'department',
    validators: [],
    required: false,
    styleClass: 'col-sm-12',
    extraProps: {
      maxLength: 50
    }
  },
  {
    label: 'street',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'street',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-sm-8',
    extraProps: {
      maxLength: 50
    }
  },
  {
    label: 'number',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'houseNumber',
    validators: [],
    required: false,
    styleClass: 'col-sm-4',
    extraProps: {
      maxLength: 20
    }
  },
  {
    label: 'postalCode',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'postalCode',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-sm-4',
    extraProps: {
      maxLength: 20
    }
  },
  {
    label: 'city',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'city',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-sm-8',
    extraProps: {
      maxLength: 50
    }
  },
  {
    label: 'state',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'state',
    validators: [],
    required: false,
    styleClass: 'col-sm-4',
    extraProps: {
      maxLength: 50
    }
  },
  {
    label: 'countryLand',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'country',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-sm-8',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [
      {key: 'name', separator: ''}
    ]
  },
  {
    label: 'phone',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'phone',
    validators: [
      {check: Validators.required, message: 'requiredErrorMessage'}
    ],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50
    }
  },
  {
    label: 'email',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'email',
    validators: [{check: Validators.email, message: 'invalidEmailError'}, {
      check: Validators.required,
      message: 'requiredErrorMessage'
    }],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50
    }
  },
];
