import { useState } from 'react';

import strings from 'resources/locales/Translate';

export function useAttachments({ formValue, handleForm, actionFrom }) {
  const [viewType, setViewType] = useState('list');
  const [error, setError] = useState('');
  const [maxFileError, setMaxFileError] = useState('');

  const toggleViewType = viewType => () => {
    setViewType(viewType);
  };

  const addKitAttachmentFile = files => {
    const { kitAttachments } = formValue;
    const newAttachments = [...kitAttachments, ...files];
    handleForm({ kitAttachments: newAttachments });
  };

  const removeKitAttachmentFile = index => {
    const { kitAttachments, kitAttachmentsToDelete } = formValue;
    
    const fileToRemove = kitAttachments[index];
    let newKitAttachmentsToDelete = [...kitAttachmentsToDelete];
    if ( actionFrom == 'zmccAttachement' ? fileToRemove.zmccAttachmentsId: fileToRemove.kitAttachmentsId) {
      actionFrom == 'zmccAttachement' ? 
      newKitAttachmentsToDelete.push(fileToRemove.zmccAttachmentsId)
      :
      newKitAttachmentsToDelete.push(fileToRemove.kitAttachmentsId);
    }
    const newKitAttachments = kitAttachments.filter((_, i) => i !== index);
    handleForm({ kitAttachments: newKitAttachments, kitAttachmentsToDelete: newKitAttachmentsToDelete });
  };

  const handleFileRejection = files => {
    const fileNames = files.map(file => file.name);
    const message = strings.fileSizeLimitExceed.replace('{filename}', fileNames.join(', '));
    setErrorMessage(message);
  };

  const handleMaxFileReached = (isMaxFileReached, isMultipleFileUploader) => {
    if (isMaxFileReached) {
      const message = strings.maxFilesErrorMessage.replace('{number}', 30);
      if (isMultipleFileUploader) {
        setMaxFileError(message);
        setTimeout(() => {
          setMaxFileError('');
        }, 10 * 1000);
      } else {
        setMaxFileError(message);
      }
    } else {
      setMaxFileError('');
    }
  };

  const setErrorMessage = message => {
    setError(message);
    setTimeout(() => {
      setError('');
    }, 10 * 1000);
  };

  return {
    viewType,
    error,
    maxFileError,
    toggleViewType,
    addKitAttachmentFile,
    handleFileRejection,
    handleMaxFileReached,
    removeKitAttachmentFile,
  };
};
