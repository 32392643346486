import Validators from 'library/utilities/Validators';

export const deliveryDocumentsModel = {
  address: [
    {
      label: 'companyName',
      value: '',
      type: 'text',
      placeholder: '',
      field: 'companyName',
      validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
      required: true,
      styleClass: 'col-sm-12',
      extraProps: {
        maxLength: 256,
      },
    },
    {
      label: 'groupName',
      value: '',
      type: 'text',
      placeholder: '',
      field: 'groupName',
      validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
      required: true,
      styleClass: 'col-sm-12',
      extraProps: {
        maxLength: 256,
      },
    },
    {
      label: 'streetHouseNumber',
      value: '',
      type: 'text',
      placeholder: '',
      field: 'streetAndHouseNumber',
      validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
      required: true,
      styleClass: 'col-sm-12',
      extraProps: {
        maxLength: 256,
      },
    },
    {
      label: 'postalCodeCity',
      value: '',
      type: 'text',
      placeholder: '',
      field: 'postalCodeAndCity',
      validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
      required: true,
      styleClass: 'col-sm-12',
      extraProps: {
        maxLength: 256,
      },
    },
    {
      label: 'webAddress',
      value: '',
      type: 'text',
      placeholder: '',
      field: 'webAddress',
      validators: [
        { check: Validators.required, message: 'requiredErrorMessage' },
        { check: Validators.url, message: 'urlErrorMessage' },
      ],
      required: true,
      styleClass: 'col-sm-12',
      extraProps: {
        maxLength: 256,
      },
    },
  ],
  management: [
    {
      label: 'name1',
      value: '',
      type: 'text',
      placeholder: '',
      field: 'name1',
      validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
      required: true,
      styleClass: 'col-sm-12',
      extraProps: {
        maxLength: 256,
      },
    },
    {
      label: 'name2',
      value: '',
      type: 'text',
      placeholder: '',
      field: 'name2',
      validators: [],
      required: false,
      styleClass: 'col-sm-12',
      extraProps: {
        maxLength: 256,
      },
    },
    {
      label: 'name3',
      value: '',
      type: 'text',
      placeholder: '',
      field: 'name3',
      validators: [],
      required: false,
      styleClass: 'col-sm-12',
      extraProps: {
        maxLength: 256,
      },
    },
    {
      label: 'name4',
      value: '',
      type: 'text',
      placeholder: '',
      field: 'name4',
      validators: [],
      required: false,
      styleClass: 'col-sm-12',
      extraProps: {
        maxLength: 256,
      },
    },
  ],
  law: [
    {
      label: 'placeOfJuridiction',
      value: '',
      type: 'text',
      placeholder: '',
      field: 'placeOfJurisdiction',
      validators: [],
      required: false,
      styleClass: 'col-sm-12',
      extraProps: {
        maxLength: 256,
      },
    },
    {
      label: 'vatIdentificationNumber',
      value: '',
      type: 'text',
      placeholder: '',
      field: 'vatIdentificationNumber',
      validators: [],
      required: false,
      styleClass: 'col-sm-12',
      extraProps: {
        maxLength: 256,
      },
    },
    {
      label: 'taxNumber',
      value: '',
      type: 'text',
      placeholder: '',
      field: 'taxNumber',
      validators: [],
      required: false,
      styleClass: 'col-sm-12',
      extraProps: {
        maxLength: 256,
      },
    },
    {
      label: 'weeeNumber',
      value: '',
      type: 'text',
      placeholder: '',
      field: 'weeeNumber',
      validators: [],
      required: false,
      styleClass: 'col-sm-12',
      extraProps: {
        maxLength: 256,
      },
    },
  ],
  signature: [
    {
      label: 'signature',
      selected: false,
      type: 'checkbox',
      field: 'signature',
      required: false,
      styleClass: 'col-sm-12',
      newuiVersionStyle: true,
    },
    {
      label: 'showCustomerInfoPage',
      selected: false,
      type: 'checkbox',
      field: 'showCustomerInfoPage',
      required: false,
      styleClass: 'col-sm-12',
      newuiVersionStyle: true,
    },
    {
      label: 'showDeliveryDocument',
      selected: false,
      type: 'checkbox',
      field: 'showDeliveryDocument',
      required: false,
      styleClass: 'col-sm-12',
      newuiVersionStyle: true,
    },
    {
      label: 'showStorageLocation',
      selected: false,
      type: 'checkbox',
      field: 'showStorageLocation',
      required: false,
      styleClass: 'col-sm-12',
      newuiVersionStyle: true,
    },
    {
      label: 'additionalInformation',
      selected: false,
      type: 'checkbox',
      field: 'additionalInformation',
      required: false,
      styleClass: 'col-sm-12 tooltip-container',
      showInfoIcon: true,
      description: 'text',
      isDisplayed: false,
    },

   ],
  legalParagraph: [
    {
      label: 'legalParagraphEnglish',
      value: '',
      type: 'text',
      placeholder: '',
      field: 'legalParagraph',
      validators: [],
      required: false,
      styleClass: 'col-sm-12',
      extraProps: {
        maxLength: 256,
      },
    },
    {
      label: 'legalParagraphGerman',
      value: '',
      type: 'text',
      placeholder: '',
      field: 'legalParagraphDe',
      validators: [],
      required: false,
      styleClass: 'col-sm-12',
      extraProps: {
        maxLength: 256,
      },
    },
  ],
};

export const returnDeliverAddressModel = [
  {
    label: 'returnDeliveryAddress',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'returnDeliveryAddress',
    required: true,
    styleClass: 'col-sm-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    displayValue: [
      {key: 'name', separator: ''}
    ],
  },
  {
    label: 'returnDeliveryAddressText',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'returnDeliveryAddressText',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
    maxLength: 100,
    },
  },
]
