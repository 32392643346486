import { useState } from 'react';
import strings from 'resources/locales/Translate';

export const useDmaReportingFilter = fetchDmaReportingData => {

  const currentYear = new Date().getFullYear();
  const years = [
    { id: currentYear - 2, value: currentYear - 2 },
    { id: currentYear - 1, value: currentYear - 1 },
    { id: currentYear, value: currentYear },
  ];

  const monthsList = [
    { id: '01', value: strings.january },
    { id: '02', value: strings.february },
    { id: '03', value: strings.march },
    { id: '04', value: strings.april },
    { id: '05', value: strings.may },
    { id: '06', value: strings.june },
    { id: '07', value: strings.july },
    { id: '08', value: strings.august },
    { id: '09', value: strings.september },
    { id: '10', value: strings.october },
    { id: '11', value: strings.november },
    { id: '12', value: strings.december },
  ];
  
  const currentMonth = new Date().toISOString().slice(5, 7);

  const filteredMonthsList = monthsList.filter(month => month.id <= currentMonth);

  const [filters, setFilters] = useState({
    year: null,
    yearFullValue: null,
    yearError: null,
    month: null,
    monthFullValue: null,
    monthError: null,
  });

  const handleFilter = (id, field, value) => {
    const newFilters = {
      ...filters,
      [field]: id,
      [`${field}FullValue`]: value,
      [`${field}Error`]: null,
    };
    setFilters(newFilters);
  };

  const onClickLoad = async () => {
    if (filters.year === null || filters.year === '') {
      setFilters({ ...filters, yearError: 'requiredErrorMessage' });
      return;
    }
    if (filters.month === null || filters.month === '') {
      setFilters({ ...filters, monthError: 'requiredErrorMessage' });
      return;
    }
    const dataToSend = {
      month: filters.month,
      year: filters.year,
    };

    await fetchDmaReportingData(dataToSend);
  };
  return {
    years,
    handleFilter,
    filters,
    monthsList,
    onClickLoad,
    currentYear,
    filteredMonthsList,
  };
};
