import { connect } from 'react-redux';

import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { cancelStockCreation, getAllActiveProvinceCountries } from 'modules/Stock/CreateStock/CreateStock.actions';

import DeliveryTimes from './DeliveryTimes.component';
import {
  createStockDeliveryTime,
  fetchStockDeliveryTime,
  fetchStockProvincesDeliveryTime,
  getProvincesOfCountry
} from './DeliveryTimes.actions';

const mapStateToProps = ({ authReducer, createStockReducer, languageReducer, masterDataReducer }) => ({
  user: authReducer.user,
  masterData: masterDataReducer,
  canEditStock: createStockReducer.canEditStock,
  stockId: createStockReducer.stockDetails.id,
  stockDetails: createStockReducer.stockDetails,
  language: languageReducer,
  stockWorkflowDetails: createStockReducer.stockWorkflows,
  stockAddressesList: createStockReducer.addressList
});

export default connect(mapStateToProps, {
  toggleActionMessage,
  cancelStockCreation,
  createStockDeliveryTime,
  fetchStockDeliveryTime,
  fetchStockProvincesDeliveryTime,
  getProvincesOfCountry,
  getAllActiveProvinceCountries
})(DeliveryTimes);
