import React from 'react';

import Icon from 'library/common/commonComponents/Icon/Icon';

const FolderGridView = ({ handleOpenFolder, handleDelete, handleOpenEditFolderPopup, folder, user }) => (
  <div className='col-sm-3 mb-4'>
    <div className='single-folder-grid-view' onClick={handleOpenFolder}>
      <div>
        {user.admin && (
          <>
            <button className='edit-button' onClick={handleOpenEditFolderPopup}>
              <Icon name='edit' height={16} width={16} fill='#0088d0' />
            </button>
            <button className='delete-button' onClick={handleDelete}>
              <Icon name='delete' height={24} width={24} fill='#737678' />
            </button>
          </>
        )}
      </div>
      <div className='folder-image'>
        <Icon name='folder' viewBox = '0 0 408 408' fill='#000' className='folder-icon ml-2' />
      </div>
      <p className='folder-name'>{folder.name}</p>
    </div>
  </div>
);

export default FolderGridView;
