import { useState } from 'react';
import { model, transactionTypes } from '../SaveQueryPopup/SaveQueryPopup.constants';
import strings from 'resources/locales/Translate';
import { validateForm } from 'library/utilities/ValidateForm.util';
import $ from 'jquery';

export const useEditMyQueryOnResultPage = ({ id, data, dbSavedFilter, createMyQuery }) => {
  const [selectedOption, setSelectedOption] = useState({
    existingFilter: false,
    newQuery: false,
  });

  const [newQueryForm, setNewQueryForm] = useState({ formData: {}, isFormValid: false });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleChange = value => {
    if (value === 'esistingFilter') {
      setSelectedOption({ existingFilter: true, newQuery: false, cancel: false });
    } else if (value === 'newQuery') {
      setSelectedOption({ existingFilter: false, newQuery: true, cancel: false });
    } else if (value === 'cancel') {
      setSelectedOption({ existingFilter: false, newQuery: false, cancel: true });
    } else {
      setSelectedOption({ existingFilter: false, newQuery: false, cancel: false });
    }
    setNewQueryForm({ formData: {}, isFormValid: false });
    setError('');
  };

  const handleForm = ({ values }) => {
    setError('');
    setNewQueryForm({
      ...newQueryForm,
      formData: {
        ...newQueryForm.formData,
        ...values,
      },
    });
  };

  const handleSave = async () => {
    if (!selectedOption.existingFilter && !selectedOption.newQuery) {
      setError(strings.updateQueryErrorMessage);
      return;
    }
    let validForm;
    if (selectedOption.newQuery) {
      validForm = validateForm({
        form: newQueryForm,
        model: model,
      });
      if (!validForm.isFormValid) {
        return setNewQueryForm(validForm);
      }
    }

    let dataToSend = {
      safeSearchId: null,
      searchName: selectedOption.newQuery ? validForm?.formData.searchName : dbSavedFilter.searchName,
      searchDescription: selectedOption.newQuery
        ? validForm?.formData.searchDescription
        : dbSavedFilter.searchDescription,
      searchType: dbSavedFilter.searchType,
      search: JSON.stringify(data.filter),
    };
    if (selectedOption.existingFilter) {
      setLoading(true);
      const res = await createMyQuery(dataToSend, true, dbSavedFilter.safeSearchId);
      setLoading(false);
      if (res.success) {
        onCloseClick();
      } else {
        setError(strings.somethingWentWrongMessage);
      }
    } else if (selectedOption.newQuery) {
      setLoading(true);
      const res = await createMyQuery(dataToSend, false);
      setLoading(false);
      if (res.success) {
        onCloseClick();
      } else {
        setError(strings.somethingWentWrongMessage);
      }
    } else {
      onCloseClick();
    }
  };

  const getModelData = () => {
    const newModel = [...model];
    const type = transactionTypes[dbSavedFilter.searchType];
    newModel[0].inputGroupText = `${strings[type]}  > `;

    return newModel;
  };

  const onCloseClick = () => {
    $(`#${id}`).modal('hide');
    setNewQueryForm({ formData: {}, isFormValid: false });
    setSelectedOption({ existingFilter: false, newQuery: false, cancel: false });
    setError('');
  };

  return {
    selectedOption,
    handleChange,
    handleSave,
    getModelData,
    handleForm,
    loading,
    error,
    newQueryForm,
    onCloseClick,
  };
};
