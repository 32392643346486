export const FETCH_INITIAL_DATA_FOR_USER_PERMISSIONS = 'USER_PERMISSIONS/FETCH_INITIAL_DATA_FOR_USER_PERMISSIONS';
export const FETCH_USER_PERMISSIONS = 'USER_PERMISSIONS/FETCH_USER_PERMISSIONS';
export const CLEAR_USER_PERMISSIONS = 'USER_PERMISSIONS/CLEAR_USER_PERMISSIONS';
export const FETCH_ROLES_LIST = 'USER_PERMISSIONS/FETCH_ROLES_LIST';
export const FETCH_USER_PEMISSIONS = 'USER_PERMISSIONS/FETCH_USER_PEMISSIONS';

export const userPermissionsBreadcrumbData = [
  {
    name:'homepage',
    url: '/home',
  },
  {
    name:'administration',
  },
  {
    name:'userPermissions',
  },
];
