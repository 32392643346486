import React, { PureComponent } from 'react';
import SingleFileListView from 'library/common/commonComponents/FileList/SingleFileListView';
import Uploader from 'library/common/commonComponents/Uploader/Uploader';
import './kitPictureUploader.styles.scss';
import Icon from 'library/common/commonComponents/Icon/Icon';

export default class KitPictureListView extends PureComponent {
  handleRemove(index) {
    this.props.onRemove(index);
  }

  render() {
    const {
      files,
      mimeType,
      maxSize,
      onFileAdd,
      isUploader = true,
      isUploadAllowed,
      isDownloadable,
      downloadUrl,
      downloadIdField,
      downloadStream,
      onReject,
      maxFiles,
      onMaxFileReached,
      disableOnlyUpload,
      availableTypes,
      errorMessage,
      isNotRemoved,
      uploaderStyle,
      newVersionUi,
      maxFilesError,
    } = this.props;

    return (
      <div className='kits-file-list-container d-flex'>
        <div className='kits-pic-list' style={{ marginRight: files.length > 0 ? 7 : 0 }}>
          {files.length > 0 ? (
            <div className='list'>
              {files.map((file, key) => (
                <SingleFileListView
                  file={file}
                  key={key}
                  index={key}
                  downloadUrl={downloadUrl}
                  isDownloadable={isDownloadable}
                  downloadIdField={downloadIdField}
                  downloadStream={downloadStream}
                  onRemove={index => this.handleRemove(index)}
                  isUploadAllowed={isUploadAllowed}
                  isNotRemoved={isNotRemoved}
                  enhancedUi={true}
                />
              ))}
            </div>
          ) : null}
        </div>
        {isUploadAllowed && disableOnlyUpload && isUploader ? (
          <div className='kits-picture-upload'>
            <Uploader
              allFiles={files}
              mimeType={mimeType}
              maxSize={maxSize}
              maxFiles={maxFiles}
              onReject={onReject}
              onFileAdd={onFileAdd}
              onMaxFileReached={onMaxFileReached}
              availableTypes={availableTypes}
              errorMessage={errorMessage}
              uploaderStyle={uploaderStyle}
              newVersionUi={newVersionUi}
            />
          </div>
        ) : null}
        {maxFilesError && (
          <div className='mt-2 ml-3' style={{ width: '100%' }}>
            <Icon name='info' width={16} height={16} fill='#FF0000' className='action-icon' />
            <span className='info-helper-message ml-1'>{maxFilesError}</span>
          </div>
        )}
      </div>
    );
  }
}
