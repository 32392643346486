import { connect } from 'react-redux';

import { fetchActiveSystemList, fetchInitialDate, activateOrDeactivateSystem } from './ActiveSystems.actions';
import ActiveSystems from './ActiveSystems.component';

const mapStateToProps = ({ authReducer, languageReducer, appActionsReducer, activeSystemsReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
  appActions: appActionsReducer,
  activeSystemsList: activeSystemsReducer.activeSystemsList,
});

export default connect(mapStateToProps, { fetchActiveSystemList, fetchInitialDate, activateOrDeactivateSystem })(ActiveSystems);
