import { useEffect, useState, useCallback } from 'react';
import { isEqual, get } from 'lodash';
import $ from 'jquery';

import {
  FETCH_CONDITIONS,
  FETCH_SELL_OFF_CONDITION,
  FETCH_SELL_OFF_VISIBILITY,
  FETCH_SELL_OFF_CURRENCY,
} from 'library/common/commonConstants/storeConstants';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';

import { kitForSaleInformationModel, singleItemForSaleInformationModel, kitStatusModel } from '../../CreateKitForSale.constants';

export function useInformation({
  formValue,
  fetchSystemClassData,
  fetchSystemMainComponentData,
  fetchMasterData,
  history,
  kitDetails,
  language,
  masterData,
  saveCreateKitManually,
  updateKitForSaleForm,
  isSingleItemForSale,
  fetchStockInfo,
  saveCreateSingleItem,
  user,
}) {
  const [currentLanguage, setCurrentLanguage] = useState(language);
  const [conditions, setConditions] = useState(null);
  const [systemClassData, setSystemClassData] = useState(null);
  const [systemMainComponentData, setSystemMainComponentData] = useState(null);

  useEffect(() => {
    fetchMasterData(URLS.sellOffCondition, FETCH_SELL_OFF_CONDITION);
    fetchMasterData(URLS.sellOffVisibility, FETCH_SELL_OFF_VISIBILITY);
    fetchMasterData(URLS.sellOffCurrency, FETCH_SELL_OFF_CURRENCY);
  }, [fetchMasterData]);

  useEffect(() => {
    const fetchInitialData = async () => {
      if (kitDetails && kitDetails.businessUnit && kitDetails.businessUnit.id) {
        const systemClassData = await fetchSystemClassData(kitDetails.businessUnit.id);
        setSystemClassData(systemClassData);
      }
      if (kitDetails && kitDetails.systemClass && kitDetails.systemClass.systemClassId) {
        const systemMainComponentData = await fetchSystemMainComponentData(kitDetails.systemClass.systemClassId);
        setSystemMainComponentData(systemMainComponentData);
      }
    };
    if (!systemClassData) {
      fetchInitialData();
    }
  }, [formValue.kitStatusForm, systemClassData, fetchSystemClassData, fetchSystemMainComponentData, kitDetails]);

  /**
   * IF LANGUAGE HAS CHANGED
   * call to fetch new masterData.conditions
   * update kitConditionFullValue because of the language change
   */
  useEffect(() => {
    if (language !== currentLanguage) {
      setCurrentLanguage(language);
      fetchMasterData(URLS.kitCondition, FETCH_CONDITIONS);
    }
    if (masterData.conditions && masterData.conditions.length && !isEqual(conditions, masterData.conditions)) {
      if (
        formValue.kitStatusForm &&
        formValue.kitStatusForm.formData &&
        formValue.kitStatusForm.formData.kitConditionIdFullValue
      ) {
        setConditions(masterData.conditions);
        const kitConditionIdFullValue = masterData.conditions.find(
          item => item.kitStatusId === formValue.kitStatusForm.formData.kitConditionIdFullValue.kitConditionId,
        );
        if (kitConditionIdFullValue) {
          updateKitForSaleForm({
            kitStatusForSaleForm: {
              ...formValue.kitStatusForm,
              formData: {
                ...((formValue.kitStatusForm && formValue.kitStatusForm.formData) || {}),
                ...{
                  kitConditionId: formValue.kitStatusForm.formData.kitConditionId,
                  kitConditionIdFullValue: {
                    ...kitConditionIdFullValue,
                    kitConditionId: kitConditionIdFullValue.kitStatusId,
                  },
                  kitConditionError: null,
                },
              },
            },
          });
        }
      }
    }
  }, [
    masterData.conditions,
    conditions,
    language,
    currentLanguage,
    formValue.kitStatusForm,
    updateKitForSaleForm,
    fetchMasterData,
  ]);

  const fetchShopManager = useCallback(async () => {
    await fetchStockInfo(formValue.kitForSaleForm.formData.stockInformationId);
  }, [fetchStockInfo, formValue.kitForSaleForm.formData.stockInformationId]);

  useEffect(() => {
    if (formValue.kitForSaleForm.formData.stockInformationId) {
      fetchShopManager();
    }
  }, [formValue.kitForSaleForm.formData.stockInformationId, fetchShopManager]);

  const checkUserRole = useCallback(() => {
    const roles = ['Shop Manager'];
    if (formValue.kitForSaleForm.formData.stockInformationId) {
      return user.stockRoles.find(role => (role.stockId === formValue.kitForSaleForm.formData.stockInformationId) && roles.includes(role.roleName));
    }
    return false;
  }, [formValue, user]);

  const fetchSystemClass = useCallback(async businessUnitId => {
    if (businessUnitId) {
      const systemClassData = await fetchSystemClassData(businessUnitId);
      setSystemClassData(systemClassData);
      setSystemMainComponentData([]);
    }
  }, [fetchSystemClassData]);

  const fetchSystemMainComponent = useCallback(async systemClassId => {
    if (systemClassId) {
      const systemMainComponentData = await fetchSystemMainComponentData(systemClassId);
      setSystemMainComponentData(systemMainComponentData);
    }
  }, [fetchSystemMainComponentData]);

  const openSoldPopup = () => {
    $('#sold-popup').modal('show');
  };

  const handleKitCondition = useCallback(({ values, formName }) => {
    const isHavePermissionForSold = user.admin || checkUserRole();
    if ((values.kitConditionId === 4) && isHavePermissionForSold) {
      return openSoldPopup();
    }

    updateKitForSaleForm({
      [formName]: {
        ...formValue[formName],
        formData: {
          ...((formValue[formName] && formValue[formName].formData) || {}),
          ...values,
        },
      },
    });
  }, [user, formValue, updateKitForSaleForm, checkUserRole]);

  const handleBusinessUnit = useCallback(values => {
    if (formValue.kitForSaleForm.formData.businessUnitId !== values.businessUnitId) {
      fetchSystemClass(values.businessUnitId);
    }

    const newkitForSaleForm = {
      ...formValue.kitForSaleForm,
      formData: {
        ...formValue.kitForSaleForm.formData,
        ...values,
        systemMainComponentId: null,
        systemMainComponentIdFullValue: null,
        systemClassId: null,
        systemClassIdFullValue: null,
      },
    };

    updateKitForSaleForm({ kitForSaleForm: newkitForSaleForm });
  }, [formValue, updateKitForSaleForm, fetchSystemClass]);

  const handleSystemClass = useCallback(values => {
    if (formValue.kitForSaleForm.formData.systemClassId !== values.systemClassId) {
      fetchSystemMainComponent(values.systemClassId);
    }

    const newkitForSaleForm = {
      ...formValue.kitForSaleForm,
      formData: {
        ...formValue.kitForSaleForm.formData,
        ...values,
        systemMainComponentId: null,
        systemMainComponentIdFullValue: null,
      },
    };

    updateKitForSaleForm({ kitForSaleForm: newkitForSaleForm });
  }, [formValue, fetchSystemMainComponent, updateKitForSaleForm]);

  const handleForm = useCallback(async ({ values, field, formName }) => {
    if (field === 'businessUnitId') {
      return handleBusinessUnit(values);
    } else if (field === 'systemClassId') {
      return handleSystemClass(values)
    } else if (field === 'kitConditionId') {
      return handleKitCondition({ values, formName });
    }

    updateKitForSaleForm({
      [formName]: {
        ...formValue[formName],
        formData: {
          ...((formValue[formName] && formValue[formName].formData) || {}),
          ...values,
        },
      },
    });
  }, [formValue, updateKitForSaleForm, handleBusinessUnit, handleKitCondition, handleSystemClass]);

  const getKitForSaleInformationModel = useCallback(() => {
    const model = [...kitForSaleInformationModel];

    model[0].options = masterData.sellOffCountry || [];
    model[1].options = masterData.businessUnit.filter(item => item.id !== 9) || [];
    model[2].options = systemClassData || [];
    model[3].options = systemMainComponentData || [];
    model[7].options = masterData.sellOffConditions || [];
    model[10].options = masterData.sellOffVisibility || [];

    return model;
  }, [masterData, systemClassData, systemMainComponentData]);

  const getSingleItemInformationModel = useCallback(() => {
    const model = [...singleItemForSaleInformationModel];

    model[0].options = masterData.sellOffCountry || [];
    model[3].options = masterData.sellOffConditions || [];
    model[7].options = masterData.sellOffVisibility || [];

    return model;
  }, [masterData]);

  const currentKitStatusModel = useCallback(() => {
    const model = [...kitStatusModel];
    if (masterData.conditions && masterData.conditions.length) {
      const kitCondition = get(kitDetails, 'kitCondition.kitConditionId');
      const reservedId = 12;
      const conditionIds = kitCondition === reservedId  ? [3, 4, 8, 9, 11, 12] : [3, 4, 8, 9, 11];
      let newOptions = masterData.conditions.filter(condition => conditionIds.includes(condition.kitStatusId));

      model[0].options = newOptions;
    }
    return model;
  }, [masterData, kitDetails]);

  const saveKit = useCallback(async () => {
    const request = isSingleItemForSale ? saveCreateSingleItem : saveCreateKitManually;
    const { success, kitInformation } = await request();
    if (success) {
      const url = isSingleItemForSale ? `/single-item-details/${kitInformation}` : `/kit-manually-details/${kitInformation}`;
      history.replace(url);
    }
  }, [isSingleItemForSale, history, saveCreateSingleItem, saveCreateKitManually]);

  const cancelCreateKit = useCallback(() => {
    history.replace('/demo-sale');
  }, [history]);

  return {
    handleForm,
    getKitForSaleInformationModel,
    getSingleItemInformationModel,
    currentKitStatusModel,
    saveKit,
    cancelCreateKit,
  };
};
