import React from 'react';
import Button from 'library/common/commonComponents/Button/Button';
import strings from 'resources/locales/Translate';
import UploadAttachmentPopup from './UploadAttachmentPopup/UploadAttachmentPopup.component';
import { useIqsAttachments } from './IqsAttachment.hook';
import { connect } from 'react-redux';
import { getIqsSscAttachmentList, deleteIqsSscDeleteAttachment } from '../../CreateStock.actions';
import ExpandableTable from 'library/common/commonComponents/Table/AppExpandableTable';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import DateInput from 'library/common/commonComponents/FormComponent/Optimized/DateInput';
import { changeLoader } from 'library/common/commonActions/AppActionsActions';

export const IqsAttachment = ({ getIqsSscAttachmentList, stockId, iqsSscAttachmentList, attachmentCategory, deleteIqsSscDeleteAttachment, canEditStock, changeLoader, enableIOOptions = false, kitDetails, kitResourceAttachmentCategoriesList, kitNewAttachments, getKitAttachmentList, deleteKitAttachment }) => {
  const kitId = kitDetails && kitDetails.kitInformationId;
  const { onClickUpload, getCols, getKitCols, onChange, filters, handleDateChange, onInputChange, searchText} = useIqsAttachments({ getIqsSscAttachmentList, stockId, deleteIqsSscDeleteAttachment, canEditStock, changeLoader, getKitAttachmentList, kitId, deleteKitAttachment });

  return (
    <div className='container-fluid'>
      <div className='new-filter-body'>
        <div className='row col-lg-12'>
          <Dropdown
            label={strings.category}
            data={kitId ? kitResourceAttachmentCategoriesList : attachmentCategory}
            field='attachmentCategory'
            idKey='staticCode'
            displayValue={[{ key: 'name', separator: '' }]}
            placeholder={strings.pleaseSelect}
            onChange={onChange}
            mainContainerStyle='col-lg-4 mb-3'
            value={filters.attachmentCategory}
            fullValue={filters.attachmentCategoryFullValue}
            newDropdownStyle={true}
            showClearIcon={true}
          />
          <div className='col-lg-4'>
            <InputField
              label={strings.titleComment}
              field='searchText'
              placeholder={strings.searchHere}
              type='text'
              validators={[]}
              value={searchText}
              //   error={}
              onChange={onInputChange}
              icon='search'
              appendIcon={false}
            />
          </div>
          <div className='col-lg-4'>
          <DateInput
              label={strings.dateRange}
              field='dateRange'
              placeholder={strings.pleaseSelectDate + '...'}
              onChange={handleDateChange}
              selectedDate={filters.dateRange}
              isRequired={false}
              isRange={true}
              newDropdownStyle={true}
              clearIcon={true}
            />
          </div>
        </div>
      </div>

      {(iqsSscAttachmentList?.content?.length > 0 || kitNewAttachments?.content?.length > 0)? (
        <div>
          <ExpandableTable 
            cols={kitId ? getKitCols() : getCols()} 
            rows={kitId ? kitNewAttachments.content : iqsSscAttachmentList.content} 
            dontShowExpandArrow={true} 
          />
        </div>
      ) : (
        <div className='d-flex justify-content-center'>
          <strong>{strings.noDataFoundText}</strong>
        </div>
      )}

      {canEditStock && <Button
        bgFill={false}
        iconName='plus'
        onClick={onClickUpload}
        value={strings.upload}
        styleClass='w-auto'
        newVersionUi={true}
      />}

      <UploadAttachmentPopup id='iqs-upload-attachment-modal' enableIOOptions={enableIOOptions} kitId={kitId} kitResourceAttachmentCategoriesList={kitResourceAttachmentCategoriesList} />

    </div>
  );
};

const mapStateToProps = ({ authReducer, languageReducer, createStockReducer }) => ({
  language: languageReducer.language,
  user: authReducer.user,
  stockId: createStockReducer.stockDetails.id,
  iqsSscAttachmentList: createStockReducer.iqsSscAttachmentList,
  attachmentCategory: createStockReducer.attachmentCategory,
  canEditStock: createStockReducer.canEditStock,
});

export default connect(mapStateToProps, { getIqsSscAttachmentList, deleteIqsSscDeleteAttachment, changeLoader })(IqsAttachment);
