import React from 'react';
import $ from 'jquery';

import strings from 'resources/locales/Translate';
import Button from 'library/common/commonComponents/Button/Button';

import DeletePermissionsModal from '../DeletePermissionsModal';

const DeleteButton = ({ disabled, state, deleteAllPermissionsForAllStocks, setGlobalRole }) => {
  const handleClick = () => {
    if ($(`#deleteAllPermissions`) && $(`#deleteAllPermissions`).modal) {
      $(`#deleteAllPermissions`).modal('show');
    }
  };

  const closePopup = () => {
    if ($(`#deleteAllPermissions`) && $(`#deleteAllPermissions`).modal) {
      $(`#deleteAllPermissions`).modal('hide');
    }
  };

  return (
    <>
      <Button
        bgFill={false}
        iconPosition='left'
        iconName='delete'
        onClick={handleClick}
        value={strings.deleteAllPermissionsForThisUser}
        disabled={disabled}
        styleClass='deletePermissions-button'
      />
      <DeletePermissionsModal
        id='deleteAllPermissions'
        state={state}
        closePopup={closePopup}
        deleteAllPermissionsForAllStocks={deleteAllPermissionsForAllStocks}
        setGlobalRole={setGlobalRole}
      />
    </>
  );
};

export default DeleteButton;
