import { connect } from 'react-redux';

import LLTransactions from './LLTransactions.component';

const mapStateToProps = ({ languageReducer, bookingReducer, authReducer }) => ({
  user: authReducer.user,
  language: languageReducer.language,
  countries: bookingReducer.labsLocationCountries,
  transactionStatuses: bookingReducer.transactionStatuses,
  labsLocationTransactionsList: bookingReducer.labsLocationTransactionsList,
  labsLocationSystemClasses: bookingReducer.labsLocationSystemClasses,
  labsLocationTransactionStatuses: bookingReducer.labsLocationTransactionStatuses,
});

export default connect(mapStateToProps)(LLTransactions);
