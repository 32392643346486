import React from 'react';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import strings from 'resources/locales/Translate';
import cn from 'classnames';
import { useDmaReportingFilter } from './DmaReportingFilter.hook';
import Button from 'library/common/commonComponents/Button/Button';

export const DmaReportingFilters = ({ isShowFilter, fetchDmaReportingData }) => {
  const { years, handleFilter, filters, monthsList, onClickLoad, currentYear, filteredMonthsList } =
    useDmaReportingFilter(fetchDmaReportingData);
  return (
    <div className={cn('container-fluid mt-3', { 'd-block': isShowFilter, 'd-none': !isShowFilter })}>
      <div className='new-filter-body'>
        <div className='row col-lg-12'>
          <Dropdown
            label={strings.years}
            data={years}
            field='year'
            idKey='id'
            displayValue={[{ key: 'value', separator: '' }]}
            isRequired={true}
            placeholder={strings.pleaseSelect}
            onChange={handleFilter}
            mainContainerStyle='col-lg-5 mb-3'
            value={filters.year}
            fullValue={filters.yearFullValue}
            newDropdownStyle={true}
            error={filters.yearError}
          />
          <Dropdown
            label={strings.month}
            data={filters.year === currentYear ? filteredMonthsList : monthsList}
            field='month'
            idKey='id'
            displayValue={[{ key: 'value', separator: '' }]}
            isRequired={true}
            multiSelect={false}
            placeholder={strings.pleaseSelect}
            onChange={handleFilter}
            mainContainerStyle='col-lg-5 mb-3'
            value={filters.month}
            fullValue={filters.monthFullValue}
            newDropdownStyle={true}
            error={filters.monthError}
          />
          <div className='col-lg-2 mb-3 mt-3'>
            <Button bgFill={false} value={strings.getData} onClick={onClickLoad} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DmaReportingFilters;
