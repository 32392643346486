export const archiveListBreadcrumbs = [
  {
    name:'homepage',
    url: '/home',
  },
  {
    name:'demoSale',
    url: '/demo-sale',
  },
  {
    name:'archive',
  },
];