import React from 'react';
import strings from 'resources/locales/Translate';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import Validators from 'library/utilities/Validators';
import ZmccRadioButton from 'library/common/commonComponents/FormComponent/Optimized/ZMCCRadioButton';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import { useLMApplicationSamplesDetails } from './LMApplicationSamplesDetails.hook';
import Attachments from 'library/common/commonComponents/AttachmentsForm';
import Button from 'library/common/commonComponents/Button/Button';
import AdditionalInformation from '../AdditionalInformation/AdditionalInformation.component';
import StatusOfSample from '../../StatusOfSample/StatusOfSample.component';

export const LMApplicationDetails = (props) => {
    const { formValue, user } = props;
    const { lmApplicationAndSamplesForm } = formValue;
    const { handleNonFormComponent } = useLMApplicationSamplesDetails(props);
    return (
      <>
        <h2 className='mt-4 zmcc-title-heading'>{strings.lmApplicationAndSamples}</h2>
        <div className='col-sm-12 p-2 section-border'>
          <div className='d-flex flex-wrap justify-content-between'>
          <div className='mt-3 lmApplication-input-box-width'>
            <InputField
              label={strings.primaryApplicationForThisCustomer}
              field='primaryApplicationForThisCustomer'
              formName='lmApplicationAndSamplesForm'
              placeholder=''
              isRequired={true}
              type='text'
              validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
              value={lmApplicationAndSamplesForm.formData.primaryApplicationForThisCustomer}
              error={lmApplicationAndSamplesForm.formData.primaryApplicationForThisCustomerError}
              onChange={e =>
                handleNonFormComponent(e, 'primaryApplicationForThisCustomer', 'lmApplicationAndSamplesForm')
              }
              bigSizeInput={'bigger-input-field'}
            />
          </div>
          <div className='mt-3 lmApplication-input-box-width '>
            <InputField
              label={strings.sampleGoingToProvideForThisDemo}
              field='sampleGoingToProvideForThisDemo'
              formName='lmApplicationAndSamplesForm'
              placeholder=''
              isRequired={true}
              type='text'
              validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
              value={lmApplicationAndSamplesForm.formData.sampleGoingToProvideForThisDemo}
              error={lmApplicationAndSamplesForm.formData.sampleGoingToProvideForThisDemoError}
              onChange={e =>
                handleNonFormComponent(e, 'sampleGoingToProvideForThisDemo', 'lmApplicationAndSamplesForm')
              }
              bigSizeInput={'bigger-input-field'}
            />
          </div>
          <div className='mt-3 lmApplication-input-box-width'>
            <InputField
              label={strings.learningAboutTheseSamples}
              field='learningAboutTheseSamples'
              formName='lmApplicationAndSamplesForm'
              placeholder=''
              isRequired={true}
              type='text'
              validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
              value={lmApplicationAndSamplesForm.formData.learningAboutTheseSamples}
              error={lmApplicationAndSamplesForm.formData.learningAboutTheseSamplesError}
              onChange={e => handleNonFormComponent(e, 'learningAboutTheseSamples', 'lmApplicationAndSamplesForm')}
              bigSizeInput={'bigger-input-field'}
            />
          </div>
          <div className='mt-3 lmApplication-input-box-width'>
            <InputField
              label={strings.justificationPoints}
              field='justificationPoints'
              formName='lmApplicationAndSamplesForm'
              placeholder=''
              isRequired={true}
              type='text'
              validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
              value={lmApplicationAndSamplesForm.formData.justificationPoints}
              error={lmApplicationAndSamplesForm.formData.justificationPointsError}
              onChange={e => handleNonFormComponent(e, 'justificationPoints', 'lmApplicationAndSamplesForm')}
              bigSizeInput={'bigger-input-field'}
            />
          </div>
          </div>
          <div className='col-sm-12 p-0 mt-3'>
            <InputField
              label={strings.aboutCompetitionInThisSale}
              field='aboutCompetitionInThisSale'
              formName='lmApplicationAndSamplesForm'
              placeholder=''
              isRequired={true}
              type='text'
              validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
              value={lmApplicationAndSamplesForm.formData.aboutCompetitionInThisSale}
              error={lmApplicationAndSamplesForm.formData.aboutCompetitionInThisSaleError}
              onChange={e => handleNonFormComponent(e, 'aboutCompetitionInThisSale', 'lmApplicationAndSamplesForm')}
              bigSizeInput={'bigger-input-field'}
            />
          </div>
          <div className='row m-0'>
            <div className='col-sm-4 mt-4 p-0'>
              <ZmccRadioButton
                label={strings.lmDoYouhaveCustomerData}
                formName='lmApplicationAndSamplesForm'
                value={lmApplicationAndSamplesForm.formData.lmDoYouHaveDataFromCustomer}
                error={lmApplicationAndSamplesForm.formData.lmDoYouHaveDataFromCustomerError}
                placeholder=''
                field='lmDoYouHaveDataFromCustomer'
                validators={[{ check: Validators.required, message: 'requiredErrorMessage' }]}
                isRequired={true}
                data={[
                  {
                    id: 'yes',
                    value: 'yes',
                  },
                  {
                    id: 'no',
                    value: 'no',
                  },
                ]}
                onChange={e => handleNonFormComponent(e, 'lmDoYouHaveDataFromCustomer', 'lmApplicationAndSamplesForm')}
              />
            </div>
            <div className='col-sm-2'></div>
            <div className='col-sm-6 mt-3'>
              <p className='label-container'>{strings.lmTotalNumberOfSamples}<span className='mandatory-asterik'>*</span></p>
              <div className='row p-3' style={lmApplicationAndSamplesForm.formData.lmTotalNumberOfSamplesError === 'requiredErrorMessage' ? {border:'solid red 1px'} : {}} >
                <Checkbox
                  label={'1'}
                  field='lmTotalNumberOfSamples'
                  isRequired={false}
                  selected={
                    lmApplicationAndSamplesForm.formData.lmTotalNumberOfSamples
                      ? lmApplicationAndSamplesForm.formData.lmTotalNumberOfSamples === '1'
                      : null
                  }
                  onChange={() => handleNonFormComponent('1', 'lmTotalNumberOfSamples', 'lmApplicationAndSamplesForm')}
                  className={'mr-5'}
                  newuiVersionStyle={true}
                />
                <Checkbox
                  label={'2'}
                  field='lmTotalNumberOfSamples'
                  isRequired={false}
                  selected={
                    lmApplicationAndSamplesForm.formData.lmTotalNumberOfSamples
                      ? lmApplicationAndSamplesForm.formData.lmTotalNumberOfSamples === '2'
                      : null
                  }
                  onChange={() => handleNonFormComponent('2', 'lmTotalNumberOfSamples', 'lmApplicationAndSamplesForm')}
                  className={'mr-5'}
                  newuiVersionStyle={true}
                />
                <Checkbox
                  label={'3'}
                  field='lmTotalNumberOfSamples'
                  isRequired={false}
                  selected={
                    lmApplicationAndSamplesForm.formData.lmTotalNumberOfSamples
                      ? lmApplicationAndSamplesForm.formData.lmTotalNumberOfSamples === '3'
                      : null
                  }
                  onChange={() => handleNonFormComponent('3', 'lmTotalNumberOfSamples', 'lmApplicationAndSamplesForm')}
                  className={'mr-5'}
                  newuiVersionStyle={true}
                />
                <Checkbox
                  label={'4'}
                  field='lmTotalNumberOfSamples'
                  isRequired={false}
                  selected={
                    lmApplicationAndSamplesForm.formData.lmTotalNumberOfSamples
                      ? lmApplicationAndSamplesForm.formData.lmTotalNumberOfSamples === '4'
                      : null
                  }
                  onChange={() => handleNonFormComponent('4', 'lmTotalNumberOfSamples', 'lmApplicationAndSamplesForm')}
                  className={'mr-5'}
                  newuiVersionStyle={true}
                />
                <Checkbox
                  label={'>4'}
                  field='lmTotalNumberOfSamples'
                  isRequired={false}
                  selected={
                    lmApplicationAndSamplesForm.formData.lmTotalNumberOfSamples
                      ? lmApplicationAndSamplesForm.formData.lmTotalNumberOfSamples === '5'
                      : null
                  }
                  onChange={() => handleNonFormComponent('5', 'lmTotalNumberOfSamples', 'lmApplicationAndSamplesForm')}
                  className={'mr-5'}
                  newuiVersionStyle={true}
                />
              </div>
            </div>
          </div>

          {lmApplicationAndSamplesForm.formData.lmDoYouHaveDataFromCustomer === 'yes' && (
            <>
              <div className='d-flex'>
                <p className='mt-4'>{strings.zmccDocumentLink}</p>
                <Button
                  isGhost={true}
                  bgFill={false}
                  dataTest='transaction-details-show-messages'
                  styleClass='show-all-messages-button ml-2'
                  iconPosition='left'
                  onClick={() => props.downloadZmccDocumentFolder()}
                  value={'ZMCC Documents'}
                />
              </div>

              <div className='col-sm-12 m-0 p-0 '>
                <Attachments
                  text='Attach File'
                  id='kitAttachmentsId'
                  formValue={formValue && formValue}
                  hasVideo={true}
                  handleForm={props.updateSEMForm}
                  actionFrom='zmccAttachement'
                  isDownloadable
                  downloadStream
                  downloadIdField='kitAttachmentsId'
                  isUploadAllowed={false}
                />
              </div>
            </>
          )}

          <div className='col-sm-4 p-0'>
            <StatusOfSample {...props} />
          </div>
          <div className='col-sm-12 mt-4 p-0'>
            <InputField
              label={strings.othersComments}
              field='othersComments'
              formName='lmApplicationAndSamplesForm'
              placeholder=''
              isRequired={false}
              type='text'
              validators={[]}
              value={lmApplicationAndSamplesForm.formData.othersComments}
              error={lmApplicationAndSamplesForm.formData.othersCommentsError}
              onChange={e => handleNonFormComponent(e, 'othersComments', 'lmApplicationAndSamplesForm')}
              bigSizeInput={'bigger-input-field'}
            />
          </div>
        </div>
      </>
    );
}

export default LMApplicationDetails;