import React, { useState, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import cn from 'classnames';

import {
  getIconByCondition,
  getIconByTransactionStatus,
  getIconByCurrentPosition,
} from 'library/utilities/getStatusAndConditionIcons';
import strings from 'resources/locales/Translate';
import { getDateInUTCFormat } from 'library/utilities/getDateInFormat';
import { checkIsBeforeDate } from 'library/utilities/checkIsBeforeDate';
import Icon from 'library/common/commonComponents/Icon/Icon';

import {  FETCH_STOCK_BY_COUNTRY_FOR_DELAYED_TRANSACTION } from '../../Statistics.constants';

export const dataTable = () => [
  {
    title: '#',
    name: 'transactionsId',
    isSort: true,
    render: item => {
      return <span>{item.transactionNumber}</span>;
    },
  },
  {
    title: `${strings.status}`,
    name: 'status',
    isSort: false,
    render: item => {
      const {
        transactionKit,
        transactionStatus,
        transactionPosition,
        twentyFourHourReservation,
        mileStoneDelayForDelivery,
        mileStoneDelayForBegin,
        mileStoneDelayForEnd,
        mileStoneDelayForChecked,
        mileStoneDelayForReturnDelivery,
        returnDelivery,
      } = item;
      const hasCondition = !!get(transactionKit, 'transactionKit.kitInformation.kitCondition.kitConditionId');
      const hasStatus = !!get(transactionStatus, 'name');
      const hasCurrentPosition = !!get(transactionPosition, 'transactionPositionId');
      const isExpandable = false;
      const isDelay =
        mileStoneDelayForBegin ||
        mileStoneDelayForChecked ||
        mileStoneDelayForDelivery ||
        mileStoneDelayForEnd ||
        mileStoneDelayForReturnDelivery;

      return (
        <div className='d-flex flex-wrap'>
          {hasStatus && getIconByTransactionStatus(transactionStatus.transactionStatusId, isExpandable)}
          {hasCondition && getIconByCondition(transactionKit.kitInformation.kitCondition, isExpandable)}
          {hasCurrentPosition && getIconByCurrentPosition(transactionPosition, isExpandable, twentyFourHourReservation, returnDelivery)}
          {isDelay ? <Icon name='conflictBooking' width={16} height={16} fill='#000' className='mr-1' /> : null}
        </div>
      );
    },
  },
  {
    title: strings.name,
    name: 'name',
    isSort: true,
    render: item => {
      const { name } = item;
      return <>{name}</>;
    },
  },
  {
    title: strings.serialNumber,
    name: 'serialNumber',
    isSort: true,
    render: item => {
      const serialNumber = get(item, 'transactionKit.kitInformation.serialNumber') || 'NA';
      return <>{serialNumber}</>;
    },
  },
  {
    title: strings.delivery,
    name: 'delivery',
    isSort: true,
    render: (item, index) => {
      const isDelay = !checkIsBeforeDate(item.targetDeliveryFrom, item.deliveryDate);
      return (
        <div className='delivery-tr' id={`delivery-${index}`}>
          <div className='pr-1'>
            <div className={cn({ 'delay-text': isDelay })}>
              <strong>{item.targetDeliveryFrom ? getDateInUTCFormat('DD.MM.YYYY', item.targetDeliveryFrom) : ''}</strong>
            </div>
            <div>{item.deliveryDate ? getDateInUTCFormat('DD.MM.YYYY', item.deliveryDate) : ''}</div>
          </div>
        </div>
      );
    },
  },
  {
    title: strings.begin,
    name: 'begin',
    isSort: true,
    render: item => {
      const isDelay = !checkIsBeforeDate(item.targetReservationFrom, item.reservationFrom);
      return (
        <>
          <div className={cn({ 'delay-text': isDelay })}>
            <strong>
              {item.targetReservationFrom ? getDateInUTCFormat('DD.MM.YYYY', item.targetReservationFrom) : ''}
            </strong>
          </div>
          <div>{item.reservationFrom ? getDateInUTCFormat('DD.MM.YYYY', item.reservationFrom) : ''}</div>
        </>
      );
    },
  },
  {
    title: strings.end,
    name: 'end',
    isSort: true,
    render: item => {
      const isDelay = !checkIsBeforeDate(item.targetReservationTo, item.reservationTo);
      return (
        <>
          <div className={cn({ 'delay-text': isDelay })}>
            <strong>{item.targetReservationTo ? getDateInUTCFormat('DD.MM.YYYY', item.targetReservationTo) : ''}</strong>
          </div>
          <div>{item.reservationTo ? getDateInUTCFormat('DD.MM.YYYY', item.reservationTo) : ''}</div>
        </>
      );
    },
  },
  {
    title: strings.receiptInStock,
    name: 'returnDelivery',
    isSort: true,
    render: item => {
      const isDelay = !checkIsBeforeDate(item.targetReturnDeliveryTo, item.returnDeliveryDate);
      return (
        <>
          <div className={cn({ 'delay-text': isDelay })}>
            <strong>{item.targetReturnDeliveryTo ? getDateInUTCFormat('DD.MM.YYYY', item.targetReturnDeliveryTo) : ''}</strong>
          </div>
          <div>{item.returnDeliveryDate ? getDateInUTCFormat('DD.MM.YYYY', item.returnDeliveryDate) : ''}</div>
        </>
      );
    },
  },
  {
    title: strings.checkedDate,
    name: 'checkedDate',
    isSort: true,
    render: item => {
      const isDelay = !!item.targetCheckedDate && !checkIsBeforeDate(item.targetCheckedDate, item.checkedDate);
      return (
        <>
          <div className={cn({ 'delay-text': isDelay })}>
            <strong>{item.targetCheckedDate ? getDateInUTCFormat('DD.MM.YYYY', item.targetCheckedDate) : ''}</strong>
          </div>
          <div>{item.checkedDate ? getDateInUTCFormat('DD.MM.YYYY', item.checkedDate) : ''}</div>
        </>
      );
    },
  },
  {
    title: strings.countryLand,
    name: 'country',
    isSort: true,
    render: item => {
      const { country } = item;
      return <span>{country && country.name}</span>;
    },
  },
  {
    title: strings.city,
    name: 'city',
    isSort: true,
    render: item => {
      const { city } = item;
      return <>{city}</>;
    },
  },
];

export const useDelayedTransactionsTab = ({
  getStocksByBussinessUnitandCountryIds,
  getLateReturnsTransactions,
  lateTransactionsList,
  clearLateReturnsTransactions,
}) => {
  const [filters, setFilters] = useState({
    businessGroup: null,
    businessGroupFullValue: null,
    country: null,
    countryFullValue: null,
    stock: null,
    stockFullValue: null,
    loanTypes: null,
    loanTypesFullValue: null,
  });
  const [data, setData] = useState({
    page: 1,
    size: 10,
    sort: {
      transactionsId: null,
      serialNumber: null,
      delivery: null,
      begin: null,
      end: null,
      returnDelivery: null,
      checkedDate: null,
      country: null,
      city: null,
    },
  });

  useEffect(() => {
    return () => clearLateReturnsTransactions();
  }, [clearLateReturnsTransactions]);

  useEffect(() => {
    if (filters.country && filters.businessGroupFullValue) {
      getStocksByBussinessUnitandCountryIds(filters.country,filters.businessGroupFullValue.name, FETCH_STOCK_BY_COUNTRY_FOR_DELAYED_TRANSACTION);
      setFilters(prevState => ({
        ...prevState,
        stock: null,
        stockFullValue: null,
      }));
    }
  },
   [filters.country,filters.businessGroupFullValue, getStocksByBussinessUnitandCountryIds]);

  useEffect(() => {
    if (filters.businessGroup) {
      const sortField = Object.keys(data.sort).find(item => data.sort[item]) || '';
      const dataToSend = {
        businessUnitId: filters.businessGroup,
        countryIds: filters.country || [],
        stockIds: filters.stock || [],
        loanType: filters.loanTypesFullValue ? filters.loanTypesFullValue.valueToSend : '',
        sortField,
        sortDirection: data.sort[sortField] || '',
        page: data.page,
        size: data.size,
      };

      getLateReturnsTransactions(dataToSend);
    }
  }, [filters, data, getLateReturnsTransactions]);

  const handleSort = name => {
    setData(prevState => {
      const newData = cloneDeep(prevState);
      Object.keys(newData.sort).forEach(key => (newData.sort[key] = null));
      newData.sort[name] = prevState.sort[name] ? (prevState.sort[name] === 'asc' ? 'desc' : null) : 'asc';

      return newData;
    });
  };

  const handleItemsPerPage = size => {
    setData(prevState => ({
      ...prevState,
      size,
    }));
  };

  const handlePageChange = page => {
    setData(prevState => ({
      ...prevState,
      page: page + 1,
    }));
  };

  const cols = dataTable();

  const isShowActiveLateTransactions = !!(lateTransactionsList.content && lateTransactionsList.content.length > 0);

  return {
    filters,
    data,
    cols,
    isShowActiveLateTransactions,
    setFilters,
    handleSort,
    handleItemsPerPage,
    handlePageChange,
  }
}
