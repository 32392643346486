import { useCallback, useState } from 'react';
import $ from 'jquery';

export const useAddCommentPopup = ({
  id,
  createComment,
  cardInfo,
  setCardInfo,
  onOpenConfirmDeletionModal,
  onOpenAddOrEditCardPopup,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState({
    value: null,
    error: null,
  });
  const [error, setError] = useState('');
  const {
    budget,
    countryName,
    comments,
    currencyName,
    createdBy,
    description,
    systemMainComponentName,
    systemClassName,
    purchaseId,
    validity,
  } = cardInfo;
  const name = createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : '';

  const handleSaving = useCallback(async () => {
    if (!inputValue.value.trim()) {
      return setInputValue(prevState => ({
        ...prevState,
        error: 'requiredErrorMessage',
      }));
    }

    const dataToSend = {
      purchaseId,
      text: inputValue.value,
    };

    setIsLoading(true);
    const { success, cardInfo } = await createComment(dataToSend);
    setIsLoading(false);

    if (!success) {
      setError('somethingWentWrongMessage');
    } else {
      setInputValue({ 
        value: null,
        error: null, 
      });
      setCardInfo(cardInfo);
    }
  }, [inputValue, createComment, purchaseId, setCardInfo]);

  const handleClosePopup = useCallback(() => {
    $(`#${id}`).modal('hide');
    setInputValue({ 
      value: null,
      error: null, 
    });
    setIsLoading(false);
    setCardInfo({});
  }, [id, setCardInfo]);

  const handleInput = useCallback(value => {
    setInputValue({ value, error: '' });
  }, []);

  const handleClickOnEditButton = () => {
    $(`#${id}`).modal('hide');
    onOpenAddOrEditCardPopup();
  };

  const handleClickOnDeleteButton = () => {
    $(`#${id}`).modal('hide');
    onOpenConfirmDeletionModal();
  };

  return {
    isLoading,
    inputValue,
    error,
    budget,
    countryName,
    comments,
    currencyName,
    description,
    systemMainComponentName,
    systemClassName,
    validity,
    name,
    handleSaving,
    handleClosePopup,
    handleInput,
    handleClickOnEditButton,
    handleClickOnDeleteButton,
  };
}
