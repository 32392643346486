import React from 'react';
import "../../calendar.style.scss";

const ColorLegends = ({ items }) => {
  return (
    <>
      {items.map((item, index) => (
          <>
            <div key={index} className='new-ui-color-legends' >
                <div className='color-box' style={{backgroundColor: item.backgroundColor}}/>
                <div className='color-legend-text'>
                    {item.text}
                </div>
            </div>
          </>
      ))}
    </>
  );
};

export default ColorLegends;
