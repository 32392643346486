import React from 'react';

import './dotLoader.styles.scss';

export default function DotLoader({ styles }) {

  return (
    <div className={`saving ${styles}`}>
      <div><div /></div>
      <div><div /></div>
      <div><div /></div>
    </div>
  );
}
