import React from 'react';

import strings from 'resources/locales/Translate';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import Button from 'library/common/commonComponents/Button/Button';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';

import Filter from './Components/Filter';
import SystemMainComponentList from './Components/SystemMainComponentList';
import KitImages from './Components/KitImages';
import { kitPicturesBreadcrumbData } from './ManageKitPictures.constants';
import { useManageKitPictures } from './ManageKitPictures.hook';

const ManageKitPictures = ({
  businessUnits,
  systemClasses,
  kitImages,
  getBusinessUnits,
  getSystemClassesByBU,
  fetchKitsPictures,
  uploadKitsPicture,
  deleteKitsPicture,
  clearKitPictures,
  appActions,
}) => {
  const {
    isShowFilter,
    deletePendingId,
    filters,
    mainComponent,
    setMainComponent,
    handleFilter,
    handleUploadImage,
    handleDeleteImage,
    systemClassesByBU,
    systemMainComponents,
    hasShowList,
    hasShowImages,
    showOrHideFilter,
  } = useManageKitPictures({
    systemClasses,
    getBusinessUnits,
    getSystemClassesByBU,
    fetchKitsPictures,
    uploadKitsPicture,
    deleteKitsPicture,
    clearKitPictures,
  });
  const { displayActionMessage, type, message } = appActions;

  return (
    <div>
      {displayActionMessage && <ActionMessage type={type} message={strings[message]} />}
      <div className='container-fluid mb-3'>
        <Breadcrumbs data={kitPicturesBreadcrumbData} />
        <div className='d-flex justify-content-between'>
          <h1 className='kit-title'>{strings.kitPictures}</h1>
          <Button
            bgFill={false}
            iconName='filter'
            onClick={showOrHideFilter}
            value={isShowFilter ? strings.hideFilter : strings.showFilter}
            styleClass='w-auto'
          />
          </div>
      </div>
      <Filter
        isShowFilter={isShowFilter}
        filters={filters}
        businessUnits={businessUnits}
        systemClasses={systemClassesByBU}
        handleFilter={handleFilter}
      />
      {hasShowList ? (
        <div className='container-fluid mb-3'>
          <h4 className='mb-3'>{strings.systemMainComponentKitPicture}</h4>
          <div className='d-flex border-top'>
            <SystemMainComponentList
              systemMainComponents={systemMainComponents}
              activeComponent={mainComponent}
              onClick={setMainComponent}
            />
            <div className='w-100'>
              <h4 className='my-3'>{strings.imagesText}</h4>
              {hasShowImages ? (
                <KitImages
                  kitImages={kitImages}
                  handleUploadImage={handleUploadImage}
                  handleDeleteImage={handleDeleteImage}
                  deletePendingId={deletePendingId}
                />
              ) : (
                <div className='d-flex justify-content-center my-5'>
                  <strong>{strings.noDataFoundText}</strong>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className='d-flex justify-content-center my-5'>
          <strong>{strings.noDataFoundText}</strong>
        </div>
      )}
    </div>
  );
};

export default ManageKitPictures;
