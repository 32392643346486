import React from 'react';
import { get } from 'lodash';

import strings from 'resources/locales/Translate';
import Button from 'library/common/commonComponents/Button/Button';
import { getIconByCondition, getIconByStatus } from 'library/utilities/getStatusAndConditionIcons';
import { getDateInFormat } from 'library/utilities/getDateInFormat';
import { user } from '../../__mocks__/kit-details-mock-data';
import KeyValueDetails from 'library/common/commonComponents/KeyValueDetails/KeyValueDetails';
import 'library/common/commonComponents/KeyValueDetails/keyValue.styles.scss';

const LLInformation = ({ kitDetail, history }) => {
  const businessUnit = get(kitDetail, 'businessUnit.name');
  const conditionString = get(kitDetail, 'kitCondition.kitConditionTranslations[0].translatedCondition', '').toLowerCase();
  const isEditable = (user && user.admin) || (user && user.labLocationPartner);

  const details = [
  ];

  const assignmentDetails =[
    { key: strings.partnerSite, value: kitDetail.stockInformation?.locationName },
    { key: strings.businessUnit, value: businessUnit },
    { key: strings.countryLand, value: kitDetail.stockInformation?.country.name },
  ]

  const mainComponentDetails =[
    { key: strings.categorySystem, value: kitDetail.systemClass?.systemClassName },
    { key: strings.systemMainComponent, value: kitDetail.systemMainComponent?.systemMainComponentName },
    { key: strings.serialNumber, value: kitDetail.serialNumber },
    { key: strings.ibaseText, value: kitDetail.ibase },
    { key: strings.yearOfManufacture, value: kitDetail.dateOfManufacture ? getDateInFormat('YYYY', kitDetail.dateOfManufacture) : '-' },
    { key: strings.monthOfManufacture, value: kitDetail.dateOfManufacture ? getDateInFormat('MMMM', kitDetail.dateOfManufacture) : '-' },
    { key: strings.dateCreated, value: kitDetail.dateCreated ? `${getDateInFormat('DD.MM.YYYY', kitDetail.dateCreated)} ${kitDetail.createdBy ? strings.by + " " + kitDetail.createdBy.firstName + " " + kitDetail.createdBy.lastName : ""}` : '-' },
    { key: strings.dateModified, value: kitDetail.updatedAt && kitDetail.updatedBy ? `${getDateInFormat('DD.MM.YYYY', kitDetail.updatedAt)} ${kitDetail.updatedBy ? strings.by + " " + kitDetail.updatedBy.firstName + " " + kitDetail.updatedBy.lastName : ""}` : '-' },
  ]
  const availabilityDetails =[
    { key: strings.availability, value: kitDetail.availabilityFrom ? getDateInFormat('DD.MM.YYYY', kitDetail.availabilityFrom, kitDetail.availabilityTo) : '-' },
  ]

  const buildingAndRoomDetails =[
    { key: strings.buildingAndRoom, value: kitDetail.storageLocation },
  ]  
  return (
    <div className='information-container'>
      <div className='container-fluid'>
        <div className='mt-3'>
        <KeyValueDetails details={[]} sectionTitle={strings.kitStatus}>
          <div className='col-6'>
            <div className='key-header mb-2'>{strings.status}</div>
            <div className='value d-flex mb-3 flex-wrap pt-1'>
              {kitDetail.kitStatuses?.map(
                item =>
                  item.kitStatusesTranslated &&
                  item.kitStatusId !== 15 && (
                    <div key={item.kitStatusId} className='mr-4 mb-2'>
                      {getIconByStatus(item, true, 11)}
                    </div>
                  ),
              )}
              {conditionString && (
                <div key={kitDetail.kitCondition.kitConditionId} className='mr-4 mb-2'>
                  {getIconByCondition(kitDetail.kitCondition, true, 24)}
                </div>
              )}
            </div>
          </div>
          <div className='col-6'>
            <div className='key-header'>{strings.function}</div>
            <div className='description-container word-wrap mb-3 value pt-1'>
              {kitDetail.comment || strings.noCommentAvailable}
            </div>
          </div>
        </KeyValueDetails>
        <KeyValueDetails details={assignmentDetails} sectionTitle={strings.assignment} styleColClass='col-6' />
        <KeyValueDetails details={mainComponentDetails} sectionTitle={strings.mainComponent} styleColClass='col-3' />
        <KeyValueDetails details={availabilityDetails} sectionTitle={strings.availability} styleColClass='col-6' />
        <KeyValueDetails details={buildingAndRoomDetails} sectionTitle={strings.buildingAndRoom} styleColClass='col-6' />
        </div>
        {isEditable && (
          <Button
            data-test='edit-kit-button'
            bgFill={false}
            iconName='edit'
            value={strings.edit}
            styleClass='w-auto'
            onClick={() =>
              history.push({
                pathname: `/edit-llkit/${kitDetail.kitInformationId}`,
                state: { edit: true, tab: 'information' },
              })
            }
          />
        )}
      </div>
    </div>
  );
};

export default LLInformation;