import { connect } from 'react-redux';

import {
  getInitialData,
  fetchRolesList,
  fetchUserPermissions,
  deleteAllPermissionsForAllStocks,
  updateAllUserPermissionsForStosk,
  updateDifferentUserPermissionsForStosk,
  clearUserPermissions,
  getCountriesByBusinessUnit,
} from './UserPermissions.actions';
import UserPermissions from './UserPermissions.component';

const mapStateToProps = ({ appActionsReducer, languageReducer, userPermissionsReducer }) => ({
  appActions: appActionsReducer,
  language: languageReducer,
  userPermissions: userPermissionsReducer.userPermissions,
  businessUnits: userPermissionsReducer.businessUnits,
  countries: userPermissionsReducer.countries,
  users: userPermissionsReducer.users,
  roles: userPermissionsReducer.roles,
});

export default connect(mapStateToProps, {
  getInitialData,
  fetchRolesList,
  fetchUserPermissions,
  deleteAllPermissionsForAllStocks,
  updateAllUserPermissionsForStosk,
  updateDifferentUserPermissionsForStosk,
  clearUserPermissions,
  getCountriesByBusinessUnit,
})(UserPermissions);

