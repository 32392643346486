import React from 'react';
import Button from 'library/common/commonComponents/Button/Button';
import strings from 'resources/locales/Translate';
import { useRooms } from './Rooms.hook';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import { connect } from 'react-redux';
import { addNewRoom, fetchRoomsList, editRooms, deleteRoom } from 'modules/Stock/CreateStock/CreateStock.actions';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import DeleteRoomPopup from './DeleteRoomPopup/DeleteRoomPopup.component';

export const Rooms = ({ roomsList, addNewRoom, fetchRoomsList, stockId, editRooms, deleteRoom, canEditStock }) => {
  const {
    onClickOnAddRoom,
    showAddFiled,
    roomForm,
    handleForm,
    onSave,
    editMode,
    clickOnEdit,
    editingFieldName,
    roomList,
    saveEditedData,
    onCancelClick,
    editForm,
    onDeleteClick,
    handleEditForm,
  } = useRooms({
    roomsList,
    addNewRoom,
    fetchRoomsList,
    stockId,
    editRooms,
  });
  const disbaleAddRoom = roomList && roomList.length === 30;
  const showlabelNumber = roomList && roomList.length + 1;
  return (
    <div className='entitle-container '>
      <div className='container-fluid form-containe'>
        <h4 className='form-title mt-3 ml-3'>{strings.rooms}</h4>
        {roomList &&
          roomList.length > 0 &&
          roomList.map((item, index) => (
            <>
              {editMode && editingFieldName === item.roomName ? (
                <div className='row container'>
                  <div className='col-lg-3'>
                    <InputField
                      isRequired
                      label={`${strings.inputRoomLabel} ${index + 1}`}
                      onChange={e => handleEditForm(e)}
                      value={editForm.value}
                      error={editForm.error}
                    />
                  </div>
                  <div className='col-lg-6 row'>
                    <Button
                      bgFill={false}
                      iconName='cross'
                      value={strings.cancel}
                      styleClass='w-auto mb-4 mt-3'
                      onClick={() => onCancelClick(item.roomName)}
                    />
                    <Button
                      bgFill={false}
                      iconName='save'
                      value={strings.save}
                      styleClass='w-auto mb-4 mt-3 ml-3'
                      onClick={() => saveEditedData(item.warehouseRoomId)}
                    />
                  </div>
                </div>
              ) : (
                <div className='row container'>
                  <div className='mb-4 col-lg-3 mt-3' style={{ overflowWrap: 'break-word' }}>
                    <p style={{ fontSize: '12px' }}>{`${strings.inputRoomLabel} ${index + 1}`}</p>
                    {item.roomName}
                  </div>
                  <div className='col-lg-6 row'>
                    {canEditStock && (
                      <Button
                        bgFill={false}
                        iconName='edit'
                        value={strings.edit}
                        styleClass=' w-auto  mb-4 mt-3'
                        onClick={() => clickOnEdit(item.roomName, index)}
                      />
                    )}
                    {canEditStock && (
                      <Button
                        bgFill={false}
                        iconName='delete'
                        value={strings.delete}
                        styleClass=' w-auto mb-4 ml-3 mt-3'
                        onClick={() => onDeleteClick(item.warehouseRoomId)}
                      />
                    )}
                  </div>
                  <DeleteRoomPopup
                    id={`delete-room-modal${item.warehouseRoomId}`}
                    deleteRoom={deleteRoom}
                    stockId={stockId}
                    singleRoom={item}
                  />
                </div>
              )}
            </>
          ))}

        {canEditStock && (
          <div className='ml-3'>
            {!showAddFiled && (
              <Button
                bgFill={false}
                iconName='plus'
                value={strings.addRooms}
                styleClass='w-auto mt-4'
                onClick={onClickOnAddRoom}
                disabled={disbaleAddRoom}
              />
            )}
          </div>
        )}

        {showAddFiled && (
          <>
            <div className='row container'>
              <div className='col-lg-3'>
                <InputField
                  isRequired
                  label={`${strings.inputRoomLabel} ${showlabelNumber}`}
                  onChange={e => handleForm(e)}
                  value={roomForm.value}
                  error={roomForm.error}
                />
              </div>
              <div className='col-lg-3' style={{ marginTop: '1.5px' }}>
                <Button
                  bgFill={true}
                  iconName='plus'
                  value={strings.addRooms}
                  styleClass='w-auto mt-3'
                  onClick={onSave}
                />
              </div>
            </div>
            <p className='ml-3 mt-2'>{strings.roomAddHintMessage}</p>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ createStockReducer, languageReducer }) => ({
  roomsList: createStockReducer.roomsList,
  language: languageReducer,
  canEditStock: createStockReducer.canEditStock,
});

export default connect(mapStateToProps, { addNewRoom, fetchRoomsList, editRooms, deleteRoom })(Rooms);
