import Validators from 'library/utilities/Validators';

export const dataExportBreadcrumbData = [
  {
    name:'homepage',
    url: '/home',
  },
  {
    name:'exportData',
  },
];

export const exportDataModel = [
   {
    label: 'businessUnit',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'businessUnit',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-md-6',
    options: [],
    hasSection: false,
    multiSelect: true,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: '' }],
  },
  {
    label: 'country',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'country',
    validators: [],
    required: false,
    styleClass: 'col-md-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'shortName',
    displayValue: [{ key: 'name', separator: '' }],
  },
];
