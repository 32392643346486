import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  fetchAllNotifications,
  openOrHideNotificationsBar,
  addNotification,
  deleteNotification,
  changeStatusNotification,
  deleteAllNotifications,
} from 'library/common/commonActions/NotificationsActions';

import NotificationBar from './NotificationBar.component';

const mapStateToProps = ({ appActionsReducer, authReducer, languageReducer, notificationsReducer }) => ({
  appActions: appActionsReducer,
  language: languageReducer,
  user: authReducer.user,
  notifications: notificationsReducer.notifications,
  isOpenNotificationBar: notificationsReducer.isOpenNotificationBar,
  isNotificationsLoading: notificationsReducer.isNotificationsLoading,
});

export default withRouter(connect(mapStateToProps, {
  fetchAllNotifications,
  openOrHideNotificationsBar,
  addNotification,
  deleteNotification,
  changeStatusNotification,
  deleteAllNotifications,
})(NotificationBar));
