import React from 'react';

import strings from 'resources/locales/Translate';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import Icon from 'library/common/commonComponents/Icon/Icon';

import { subscriptionsBreadcrumbData } from './NotificationSettings.constants';
import { useSystemClassesSubscriptions } from './NotificationSettings.hook';
import NotifyMeVia from './Components/NotifyMeVia';
import './NotificationSettings.styles.scss';

const NotificationSettings = ({
  appActions,
  notificationSettings,
  changetNotificationSettings,
  getInitialData,
}) => {
  document.title = 'Subscriptions';
  const { isLoading, displayActionMessage, type, message } = appActions;
  const { handleNotificationSettings } = useSystemClassesSubscriptions({
    getInitialData,
    notificationSettings,
    changetNotificationSettings,
  });

  return (
    <div className='subscriptions-container'>
      {displayActionMessage && <ActionMessage type={type} message={strings[message]} />}
      <div className='container-fluid mb-3'>
        <Breadcrumbs data={subscriptionsBreadcrumbData} />
        <div className='mb-5'>
          <h1 className='page-title'>{strings.notificationSettings}</h1>
          <div className='d-flex align-items-center'>
            <h4 className='mr-2'>{strings.demoLoanMessages}</h4>
            <Icon
              name='info'
              width={18}
              height={18}
              fill='#9a9b9c'
              className='cursor-pointer'
              data-tooltip={strings.demoLoanMessagesTooltip}
              data-tooltip-config={JSON.stringify({ direction: 'bottom' })}
            />
          </div>
        </div>
        <NotifyMeVia
          notificationSettings={notificationSettings}
          handleNotificationSettings={handleNotificationSettings}
        />
      </div>
      {isLoading && <div className='loader' />}
    </div>
  );
};

export default NotificationSettings;
