import { FETCH_MULTIPLE_ADDRESS_LIST } from './AddressConfigurationList.constants';

const initialState = {
  multipleAddressConfigurationList: [],
};

const MultipleAddressConfigurationList = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MULTIPLE_ADDRESS_LIST:
      return {
        ...state,
        multipleAddressConfigurationList: action.payload,
      };

    default:
      return state;
  }
};

export default MultipleAddressConfigurationList;
