import { connect } from 'react-redux';

import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';

import Information from './Information.component';
import {
  fetchSystemClassData,
  fetchSystemMainComponentData,
  updateKitForSaleForm,
  saveCreateKitManually,
  fetchStockInfo,
  saveCreateSingleItem,
} from '../../CreateKitForSale.actions';

const mapStateToProps = ({ createKitForSaleReducer, masterDataReducer, languageReducer, authReducer }) => ({
  formValue: {
    generalInformationKitForSaleForm: createKitForSaleReducer.generalInformationKitForSaleForm,
    kitForSaleForm: createKitForSaleReducer.kitForSaleForm,
    kitStatusForm: createKitForSaleReducer.kitStatusForSaleForm,
  },
  kitInformationId: createKitForSaleReducer.kitInformationId,
  kitDetails: createKitForSaleReducer.kitDetails,
  language: languageReducer.language,
  masterData: masterDataReducer,
  user: authReducer.user,
  sellOffCurrency: masterDataReducer.sellOffCurrency,
});

export default connect(mapStateToProps, {
  fetchSystemClassData,
  fetchSystemMainComponentData,
  updateKitForSaleForm,
  fetchMasterData,
  saveCreateKitManually,
  fetchStockInfo,
  saveCreateSingleItem,
})(Information);
