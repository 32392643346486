import React from 'react';
import dayjs from 'dayjs';
import { get } from 'lodash';

import strings from 'resources/locales/Translate';
import { getPriceWithCurrencySymbol } from 'library/utilities/getCurrencyFormat'

export const dataTable = () => [
  {
    title: '#',
    name: 'number',
    isSort: false,
    render: (item) => {
      return (
        <span>{item.kitNumber}</span>
      )
    },
  },
  {
    title: strings.serialNumber,
    name: 'serialNumber',
    isSort: false,
    render: (item) => {
      return (
        <span>{item.serialNumber || ''}</span>
      )
    },
  },
  {
    title: strings.kitName,
    name: 'kitName',
    isSort: true,
    render: (item) => {
      return (
        <span>{item.kitName}</span>
      )
    },
  },
  {
    title: strings.systemClassText,
    name: 'systemClass',
    isSort: true,
    render: (item) => {
      return (
        <span>{(item.systemClass && item.systemClass.systemClassName) || ''}</span>
      )
    },
  },
  {
    title: strings.systemMainComponent,
    name: 'systemMainComponent',
    isSort: true,
    render: (item) => {
      const systemMainComponent = get(item, 'systemMainComponent.systemMainComponentName');
      return (
        <span>{systemMainComponent || '-'}</span>
      )
    },
  },
  {
    title: strings.yearOfManufacturing,
    name: 'yearOfManufacturing',
    isSort: false,
    render: (item) => {
      return (
        <span>{item.dateOfManufacture ? dayjs(item.dateOfManufacture).format('YYYY') : null}</span>
      )
    },
  },
  {
    title: strings.condition,
    name: 'condition',
    isSort: true,
    render: (item) => {
      const condition = get(item, 'sellOffPlatformInformation.sellOffCondition.sellOffKitConditionTranslated[0].name');
      return (
       <span>{condition || ''}</span>
      )
    },
  },
  {
    title: strings.grossListPrice,
    name: 'localSalesPrice',
    isSort: true,
    render: (item) => {
      const localSalesPrice = get(item, 'sellOffPlatformInformation.localSalePrice', 0);
      const symbol = get(item, 'sellOffPlatformInformation.currency.symbol');
      const currency = get(item, 'sellOffPlatformInformation.currency.name');
      return (
      <span>{getPriceWithCurrencySymbol(localSalesPrice, currency, symbol)}</span>
      )
    },
  },
  {
    title: strings.transferPriceInEUR,
    name: 'transferPrice',
    isSort: true,
    render: (item) => {
      const transferPrice = get(item, 'sellOffPlatformInformation.transferPrice', 0);
      const symbol = '€';
      const currency = 'EUR';
      return (
        <span>{getPriceWithCurrencySymbol(transferPrice, currency, symbol)}</span>
      )
    },
  },
  {
    title: strings.salesDate,
    name: 'salesDate',
    isSort: true,
    render: (item) => {
      const saleDate = get(item, 'sellOffPlatformInformation.saleDate');
      return (
        <span>{saleDate ? dayjs(saleDate).format('DD.MM.YYYY') : null}</span>
      )
    },
  },
  {
    title: strings.deliveryTimes,
    name: 'deliveryTimes',
    isSort: true,
    render: (item) => {
      const deliveryTimeInWeeks = get(item, 'sellOffPlatformInformation.deliveryTimeInWeeks');
      return (
        <span>{deliveryTimeInWeeks ? `${deliveryTimeInWeeks} ${deliveryTimeInWeeks === 1 ? 'week' : 'weeks'}` : '-'}</span>
      )
    },
  },
  {
    title: strings.location,
    name: 'stock',
    isSort: true,
    render: (item) => {
      const stock = get(item, 'stockInformation.locationName');
      return (
        <span>{stock || '-'}</span>
      )
    },
  },
];
