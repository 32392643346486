import { connect } from 'react-redux';

import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';

import CreateLLKit from './CreateLLKit.component';
import {
  fetchActiveUnitDate,
  updateForm,
  fetchStocks,
  createLLKit,
  fetchLLKitDetails,
  resetForm,
  fetchSystemClasses,
} from './CreateLLKit.actions';

const mapStateToProps = ({ appActionsReducer, authReducer, createLLKitReducer, languageReducer }) => ({
  appActions: appActionsReducer,
  generalInformationForm: createLLKitReducer.generalInformationForm,
  informationForm: createLLKitReducer.informationForm,
  freeAccessoriesForm: createLLKitReducer.freeAccessoriesForm,
  furtherImportantComponentForm: createLLKitReducer.furtherImportantComponentForm,
  salesComponentForm: createLLKitReducer.salesComponentForm,
  kitStatusForm: createLLKitReducer.kitStatusForm,
  kitImages: createLLKitReducer.kitImages,
  kitImagesToDelete: createLLKitReducer.kitImagesToDelete,
  kitAttachments: createLLKitReducer.kitAttachments,
  kitAttachmentsToDelete: createLLKitReducer.kitAttachmentsToDelete,
  kitDetails: createLLKitReducer.kitDetails,
  kitInformationId: createLLKitReducer.kitInformationId,
  language: languageReducer,
  user: authReducer.user,
});

export default connect(mapStateToProps, {
  fetchActiveUnitDate,
  updateForm,
  fetchStocks,
  createLLKit,
  fetchMasterData,
  fetchLLKitDetails,
  resetForm,
  fetchSystemClasses,
})(CreateLLKit);
