import React from 'react';
import Button from 'library/common/commonComponents/Button/Button';
import strings from 'resources/locales/Translate';
import $ from 'jquery';

export const CheckListOptionsPopup = ({
  id,
  maintenanceCheckListData,
  openArrivalCheckListPopUp,
  openDemoCheckListPopUp,
  openDismantleCheckListPopUp,
}) => {
  const { arrivalAbnormalities, demoAbnormalities, dismantleCompleteness } = maintenanceCheckListData;

  const onCloseClick = () => {
    $(`#${id}`).modal('hide');
  };
  return (
    <div
      className='modal fade modal-container'
      id={id}
      tabIndex='-1'
      role='dialog'
      aria-labelledby={id}
      aria-hidden='true'
      data-test=''
    >
      <div className='modal-background'>
        <div className='modal-dialog' role='document'>
          <div className='modal-content rounded-0'>
            <div className='modal-body p-0'>
              <div className='content' id='app-scheduler-wrapper'>
                <div className='col-sm-12'>
                  {(arrivalAbnormalities === null || arrivalAbnormalities === undefined) && (
                    <div className='mt-1'>
                      <Button value={strings.arrivalForm} bgFill={false} onClick={openArrivalCheckListPopUp} />
                    </div>
                  )}
                  {(demoAbnormalities === null || demoAbnormalities === undefined) && (
                    <div className='mt-1'>
                      <Button value={strings.demoForm} bgFill={false} onClick={openDemoCheckListPopUp} />
                    </div>
                  )}
                  {(dismantleCompleteness === null || dismantleCompleteness === undefined) && (
                    <div className='mt-1'>
                      <Button value={strings.dismantlingForm} bgFill={false} onClick={openDismantleCheckListPopUp} />
                    </div>
                  )}
                </div>

                <div className='d-flex justify-content-center align-content-center mt-4'>
                  <Button styleClass='btn-max-width-300' value={strings.close} bgFill={true} onClick={onCloseClick} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckListOptionsPopup;