import { connect } from 'react-redux';

import { getKPI, getMainUtilizedMainComponents, getTypesOfJobs, clearTables } from '../../LLManagement.actions';
import KPIsTab from './KPIsTab.component';

const mapStateToProps = ({ authReducer, languageReducer, llManagementReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
  continentsList: llManagementReducer.continentsList,
  stocks: llManagementReducer.stocks,
  kpi: llManagementReducer.kpi,
  mainUtilizedMainComponents: llManagementReducer.mainUtilizedMainComponents,
  typesOfJobs: llManagementReducer.typesOfJobs,
});

export default connect(mapStateToProps, { getKPI, getMainUtilizedMainComponents, getTypesOfJobs, clearTables })(
  KPIsTab,
);
