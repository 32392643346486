import { connect } from 'react-redux';

import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';
import CreateKitForSale from './CreateKitForSale.component';
import { fetchKitDetail, resetCreateKit, saveCreateKitManually, updateKitForSaleForm, saveCreateSingleItem } from './CreateKitForSale.actions';

const mapStateToProps = ({ appActionsReducer, authReducer, createKitForSaleReducer, languageReducer, masterDataReducer }) => ({
  appActions: appActionsReducer,
  formValue: {
    generalInformationKitForSaleForm: createKitForSaleReducer.generalInformationKitForSaleForm,
    kitImages: createKitForSaleReducer.kitImages,
    kitImagesToDelete: createKitForSaleReducer.kitImagesToDelete,
    furtherImportantComponentForm: createKitForSaleReducer.furtherImportantComponentForm,
    salesComponentForm: createKitForSaleReducer.salesComponentForm,
    freeAccessoriesForm: createKitForSaleReducer.freeAccessoriesForm,
    salesDetailsForm: createKitForSaleReducer.salesDetailsForm,
    kitAttachments: createKitForSaleReducer.kitAttachments,
    kitAttachmentsToDelete: createKitForSaleReducer.kitAttachmentsToDelete,
  },
  kitDetails: createKitForSaleReducer.kitDetails,
  sellOffCurrency: masterDataReducer.sellOffCurrency,
  kitInformationId: createKitForSaleReducer.kitInformationId,
  language: languageReducer,
  user: authReducer.user,
});

export default connect(mapStateToProps, {
  fetchMasterData,
  fetchKitDetail,
  updateKitForSaleForm,
  resetCreateKit,
  saveCreateKitManually,
  saveCreateSingleItem,
})(CreateKitForSale);
