import {identifiers} from "library/common/commonConstants/IdentifiersConstants";
import {saveToStorage} from "library/utilities/storage";

import {SET_LANGUAGE} from '../commonConstants/storeConstants';

export const setLanguage = (language) => dispatch => {

  if(language.includes('en')) {
    language = 'en';
  } else if(language.includes('de')) {
    language = 'de';
  }

  let locale = 'en-US';

  switch (language) {
    case 'en':
      locale = 'en-US';
      break;
    case 'de':
      locale = 'de-DE';
      break;
    default:
      break;
  }

  saveToStorage(identifiers.language, language);

  dispatch({
    type: SET_LANGUAGE,
    payload: {language, locale}
  });
};
