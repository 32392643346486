export const getCheckBoxOptions = (useBooleanValues = false) => [
  {
    id: useBooleanValues ? true : 'Yes',
    value: 'yes',
  },
  {
    id: useBooleanValues ? false : 'No',
    value: 'no',
  },
];
