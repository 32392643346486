import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';

import * as actionTypes from 'library/common/commonConstants/storeConstants';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { fetchFromStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import { useDispatch } from 'react-redux';
import { fetchMasterDataPost } from 'library/common/commonActions/MasterDataActions';

const INITIAL_FILTER = {
  searchTerm: null,
  country: null,
  countryFullValue: null,
  category: null,
  categoryFullValue: null,
  singleItems: null,
  singleItemsFullValue: null,
};

export function useShop({
  fetchMasterData,
  countries,
  categoriesAndSystems,
  language,
  fetchMarketplaceCountryList,
  marketplaceCountry
}) {
  const dispatch = useDispatch();
  let filter = { ...INITIAL_FILTER };
  const storage = fetchFromStorage(identifiers.filtersShop);
  if (storage) {
    filter = {
      ...storage,
      searchTerm: storage.searchTerm ? storage.searchTerm : '',
    };
  }
  const [isShowFilter, setIsShowFilter] = useState(true);
  const [data, setData] = useState({
    page: 1,
    size: 10,
    sort: {
      kitName: null,
      systemClass: null,
      systemMainComponent: null,
      condition: null,
      localSalesPrice: null,
      transferPrice: null,
      salesDate: null,
      deliveryTimes: null,
      stock: null,
    },
    filter,
  });


useEffect(()=>{
  if (!(marketplaceCountry && marketplaceCountry.length)) {
    fetchMarketplaceCountryList();
  }
}, [marketplaceCountry])
  useEffect(() => {
    fetchMasterDataPost(URLS.getStocksBasedOnCountries, actionTypes.FETCH_COUNTRY_LIST, [])(dispatch);
  }, [language]);

  useEffect(() => {
    const fetchSystemClass = async () => {
      await fetchMasterData(URLS.systemClassWithStock, actionTypes.FETCH_SYSTEM_CLASS_WITH_STOCKS);
    };

    if (Array.isArray(categoriesAndSystems) && !(categoriesAndSystems.stockSystemClasses && categoriesAndSystems.systemClasses)) {
      fetchSystemClass();
    }
  }, [categoriesAndSystems, fetchMasterData]);

  const handleSearchChange = e => {
    const { value } = e.target;
    debounceUpdateData({ value });
  };

  const debounceUpdateData = debounce(({ value }) => {
    setData(
      prevState => {
        const newData = { ...prevState };
        newData.page = 1;
        newData.filter.searchTerm = value;
        return { ...newData };
      });
  }, 1000);

  const showOrHideFilter = () => {
    setIsShowFilter(prevState => !prevState);
  };

  const handleFilterChange = (key, value, fullValue) => {
    setData(prevState => {
      const newData = { ...prevState };
      newData.filter[key] = value;
      newData.filter[`${key}FullValue`] = fullValue;
      return { ...newData };
    });
  };

  const resetFilters = () => {
    setData(prevState => ({ ...prevState, filter: { ...INITIAL_FILTER, searchTerm: prevState.searchTerm }}));
  };

  const handleSort = name => {
    setData(prevState => {
      const newData = cloneDeep(prevState);
      Object.keys(newData.sort).forEach(key => (newData.sort[key] = null));
      newData.sort[name] = prevState.sort[name] ? (prevState.sort[name] === 'asc' ? 'desc' : null) : 'asc';
      return newData;
    });
  };

  const handleItemsPerPage = size => {
    setData(prevState => {
      const newData = { ...prevState };
      newData.size = size;

      return newData;
    });
  };

  const handlePageChange = page => {
    setData(prevState => {
      const newData = { ...prevState };
      newData.page = page + 1;

      return newData;
    });
  };

  const createDataForSystemClassDropdown = () => {
    let arrayOfSystemClass = (categoriesAndSystems && categoriesAndSystems.systemClasses) || [];

    return Array.from(new Set(arrayOfSystemClass)).sort((a, b) => {
      const systemClassA = a.systemClassName.toUpperCase();
      const systemClassB = b.systemClassName.toUpperCase();

      return systemClassA > systemClassB ? 1 : -1;
    });
  };

  const systemsClass = createDataForSystemClassDropdown();

  return {
    isShowFilter,
    data,
    handleSearchChange,
    showOrHideFilter,
    handleFilterChange,
    resetFilters,
    handleSort,
    handleItemsPerPage,
    handlePageChange,
    debounceUpdateData,
    systemsClass,
  };
};
