import Validators from "library/utilities/Validators";

export const marketplaceInformationModel =  [
   {
    label: 'kitMarketplaceAvaialble',
    selected: false,
    type: 'enhanced-checkbox',
    field: 'availableOnMarketplace',
    styleClass: 'col-sm-3',
    newuiVersionStyle: true,
    labelTopPosition: true
  },
  {
    label: 'kitFirstSalesOrder',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'firstSalesOrder',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
  },
  {
    label: 'kitMarketplacelLoanOrder',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'loanOrder',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
  },
  {
    label: 'conditionText',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'condition',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'value', separator: '' }],
    disabled: false,
  },
  {
    label: 'salesDate',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'saleDate',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
  },
  {
    label: 'marketplaceDeliveryTimes',
    value: '',
    type: 'inputgroup',
    placeholder: '',
    field: 'deliveryTimeInWeeks',
    validators: [{ check: Validators.number, message: 'invalidNumberError' }],
    validateOnBlur: true,
    required: false,
    styleClass: 'col-sm-3',
    inputGroupText: 'weeks',
    isAppend: true,
    neweVersionStyle: true,
  },
  {
    label: 'kitMarketplaceSalesComment',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'saleComment',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
  },
];

export const marketplacePriceModel = [
  {
    label: 'kitMarketplaceGrossPrice',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'localSalePrice',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' },
      { check: Validators.number, message: 'invalidNumberError' },
    ],
    required: true,
    styleClass: 'col-sm-3',
  },
  {
    label: 'currency',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'currency',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'value', separator: '' }],
    disabled: false,
  },
  {
    label: 'priceType',
    value: '',
    type: 'enhanced-circle-RadioButton',
    placeholder: 'pleaseSelect',
    field: 'priceTypeDynamic',
    validators: [{ check: Validators.isBooleanRequired, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    options: [
      {
              id: false,
              value: 'staticPirce',
            },
            {
              id: true,
              value: 'dynamicPrice',
            },
    ],
    displayValue: [{ key: 'id', separator: '' }],
  },
  {
    label: 'priceFactor',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'priceFactor',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'value',
    displayValue: [{ key: 'value', separator: '' }],
    disabled: false,
  },
  {
    label: 'kitMarketplaceTransferPrice',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'transferPrice',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage'},
      { check: Validators.number, message: 'invalidNumberError'},
    ],
    required: true,
    styleClass: 'col-sm-3',
  },
];


export const priceFactorList = [
  {id: 1, value: '1'},
  {id: 2, value: '1.05'},
  {id: 3, value: '1.07'},
  {id: 4, value: '1.1'},
]