import { batchActions } from 'redux-batched-actions';

import store from 'main/store/configureStore';
import { ADD_REMOVE_DOCUMENTS, FETCH_DOCUMENTS, RESET_DOCUMENTS_AND_FOLDERS } from 'library/common/commonConstants/storeConstants';
import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { scrollToTop } from 'library/utilities/scrollActions';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import {
  fetchMasterDataService,
  fetchMasterDataServicePost,
  deleteMasterDataService,
  fetchMasterDataServicePut,
} from 'library/api/master-data.service';

import { deleteDocumentService, uploadDocumentsServices } from './documents-service';

export const fetchdDocumentsAndFolders = id => async dispatch => {
  dispatch(changeLoader(true));
  try {
    const { status, data } = await fetchMasterDataService(`${URLS.folder}/${id}`);
    if (status === 200) {
      const files = [];
      const folders = [];
      data.forEach(item => {
        if (item.type === 'FOLDER') {
          folders.push(item)
        }
        if (item.type === 'FILE') {
          files.push(item)
        }
      });
      dispatch(
        batchActions([
          dispatch({
            type: FETCH_DOCUMENTS,
            payload: { files, folders },
          }),
          dispatch(changeLoader(false)),
        ]),
      );
    }
  } catch (err) {
    dispatch(changeLoader(false))
  }
};

export const uploadDocuments = id => dispatch => {
  const documentsReducer = store.getState().documentsReducers;

  let filesArray = documentsReducer.files.filter(file => !file.hasOwnProperty('id'));

  if (filesArray.length > 0) {
    const formData = new FormData();

    filesArray.forEach(file => {
      formData.append('zeissDocument', file);
    });

    dispatch(changeLoader(true));
    uploadDocumentsServices(id, formData)
      .then(res => {
        if(res.status === 200) {
          dispatch(
            batchActions([
              dispatch(changeLoader(false)),
              dispatch(fetchdDocumentsAndFolders(id)),
              dispatch(toggleActionMessage(true, 'success', 'documentUploadedSuccessfully')),
            ]),
          );
          scrollToTop(500);
        }
      })
      .catch(() => {
        dispatch(
          batchActions([
            dispatch(changeLoader(false)),
            dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage')),
          ]),
        );
        scrollToTop(500);
      });
  } else {
    dispatch(toggleActionMessage(true, 'error', 'noFilesUploadedErrorMessage'));
    scrollToTop(500);
  }

};

export const createFolder = dataToSend => async dispatch => {
  try {
    await fetchMasterDataServicePost(URLS.folder, dataToSend);
    scrollToTop(500);
    dispatch(toggleActionMessage(true, 'success', 'folderCreatedSuccessfully'));
    return { success: true };
  } catch (err) {
    return { success: false, data: err };
  }
};

export const editFolder = dataToSend => async () => {
  try {
    await fetchMasterDataServicePut(URLS.folder, dataToSend);
    return { success: true };
  } catch (err) {
    return { success: false, data: err };
  }
};

export const deleteFolder = (folderId, parentFolderId) => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const res = await deleteMasterDataService(`${URLS.folder}/${folderId}`);
    if (res.status === 200) {
      dispatch(
        batchActions([
          dispatch(changeLoader(false)),
          dispatch(fetchdDocumentsAndFolders(parentFolderId)),
          dispatch(toggleActionMessage(true, 'success', 'folderDeletedSuccessfully')),
        ]),
      );
      scrollToTop(500);
    }
  } catch (err) {
    if (err.response.status === 422) {
      dispatch(toggleActionMessage(true, 'error', err.response.data.message));
    } else {
      dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    }
    dispatch(changeLoader(false));
    scrollToTop(500);
  }
};

export const addFile = (file) => dispatch => {
  const documentsReducer = store.getState().documentsReducers;

  documentsReducer.files.unshift(...file);

  dispatch({
    type: ADD_REMOVE_DOCUMENTS,
    payload: documentsReducer.files
  });
};

export const removeFile = (index, parentFolderId) => dispatch => {
  const documentsReducer = store.getState().documentsReducers;

  const fileToRemove = documentsReducer.files[index];

  if (fileToRemove.hasOwnProperty('id')) {
    dispatch(deleteDocumentFromServer(fileToRemove.id, parentFolderId));
  }

  documentsReducer.files.splice(index, 1);

  dispatch({
    type: ADD_REMOVE_DOCUMENTS,
    payload: documentsReducer.files
  })
};

export const deleteDocumentFromServer = (id, parentFolderId) => dispatch => {
  dispatch(changeLoader(true));

  deleteDocumentService(id)
    .then(res => {
      if(res.status === 200) {
        dispatch(fetchdDocumentsAndFolders(parentFolderId));
      }
      dispatch(changeLoader(false));
    })
    .catch(dispatch(changeLoader(false)));
};

export const resetDocumentsAndFolders = () => dispatch => {
  dispatch({
    type: RESET_DOCUMENTS_AND_FOLDERS,
    payload: {
      files: [],
      folders: [],
    }
  });
};
