import React from 'react';
import moment from 'moment';

import { images } from 'library/common/commonConstants/ImageConstants';
import Icon from 'library/common/commonComponents/Icon/Icon';
import strings from 'resources/locales/Translate';

const CartItem = ({ item, deleteItem, history, indexForZMCC}) => {
  const image = item.kit && item.kit.kitImages && item.kit.kitImages.length > 0 ? item.kit.kitImages[0].preview : images.imagePlaceholder;
  const startDate = item.start !== null ? moment(item.start).format('DD.MM.YYYY') : '';
  const endDate = item.start !== null ? moment(item.end).format('DD.MM.YYYY') : '';

  const goToSemForm = () => {
    history.push({pathname: `/${item.isZmccLmBooking?'LM': item.isZmccSemBooking? 'SEM': item.isZmccXbBooking ? 'XB': item.isZmccXrmBooking ? 'XRM': ''}/${item.transactionsId }`});
  }

  return (
    <div className="cart-item d-flex align-items-center">
      <div className="kit-image">
        <img src={image} alt={item.kit && item.kit.kitName}/>
      </div>
      <div className="kit-detail">
        {
          item.isZMCCCalendar ?
          <p className="kit-name">{`ZMCC ${item.isZmccLmBooking?'LM': item.isZmccSemBooking? 'SEM': item.isZmccXbBooking ? 'XB': item.isZmccXrmBooking ? 'XRM': ''} DRF: ${item.customerName ? item.customerName : indexForZMCC}`}</p>
          :
          <p className="kit-name">{item.kit.kitName}</p>
        }
        <p className='kit-date'>{startDate} {item.isZMCCCalendar ? '' : `-${endDate}`}</p>
        {!item.isZMCCCalendar && item.kit && !item.kit.twentyFourHourReservation && <button className="kit-delete" onClick={() => deleteItem(item.isZMCCCalendar ? item.transactionsId :item)}>
          <Icon name='delete' width={16} height={16} fill='#0088d0'/>
          <span>{strings.delete}</span>
        </button>}
        {item.isZMCCCalendar &&
         <button className="kit-delete" onClick={() => deleteItem(item.isZMCCCalendar ? item.transactionsId :item)}>
          <Icon name='delete' width={16} height={16} fill='#0088d0'/>
          <span>{strings.delete}</span>
        </button>}
        <span style={{marginLeft:"10px"}}></span>
        {item.isZMCCCalendar &&
          <button className="kit-delete" onClick={() => goToSemForm()}>
          <span>{strings.continueForm}</span>
        </button>
        }
      </div>
    </div>
  )
};

export default CartItem;
