import React from 'react';

import strings from 'resources/locales/Translate';

import KitDataStatistics from '../KitDataStatisticsBlock';
import WorkingCapitalInStockChart from '../WorkingCapitalInStockChart';
import ActiveKitConditionsPieChart from '../ActiveKitConditionsPieChart';
import DemoActivityAndKitDataFilter from '../DemoActivityAndKitDataFilter';
import UtilizedKits from '../UtilizedKits';
import { useKitDataTab } from './KitDataTab.hook';

const KitDataTab = ({
  isShowFilter,
  loanTypes,
  stocksKitData,
  systemClassKitData,
  getStocksByBussinessUnitandCountryIds,
  getSystemClassesByBU,
  fetchKitStatusChartStatistics,
  kitStatusChartStatistics,
  clearKitStatusChartStatistics,
  fetchKitDataStatistics,
  kitDataStatistics,
  clearKitDataStatistics,
  utilizedKits,
  fetchUtilizedKits,
  workingCapitalInStockData,
  fetchWorkingCapitalInStock,
  clearWorkingCapitalInStock,
  language,
}) => {
  const {
    filters,
    systemClass,
    data,
    hasDataForChart,
    currentDate,
    chartLegend,
    setFilters,
    tableCols,
  } = useKitDataTab({
    systemClassKitData,
    kitStatusChartStatistics,
    getStocksByBussinessUnitandCountryIds,
    getSystemClassesByBU,
    fetchKitStatusChartStatistics,
    clearKitStatusChartStatistics,
    fetchKitDataStatistics,
    clearKitDataStatistics,
    fetchUtilizedKits,
    fetchWorkingCapitalInStock,
    clearWorkingCapitalInStock,
    language,
  });
  const stocks = filters.stock || [];
  const stockFullValue = filters.stockFullValue ? filters.stockFullValue.map(item => item.locationName) : [];

  return (
    <>
      <DemoActivityAndKitDataFilter
        isShowFilter={isShowFilter}
        loanTypes={loanTypes}
        stocks={stocksKitData}
        systemClass={systemClass}
        filters={filters}
        setFilters={setFilters}
        isKitDataTab={true}
      />
      <div className='container-fluid tab-wrapper'>
        <h2>{strings.activeKitConditionAsOn(currentDate)}</h2>
        {hasDataForChart ? (
          <ActiveKitConditionsPieChart
            data={data}
            chartLegend={chartLegend}
            stocks={stocks}
            stockFullValue={stockFullValue}
          />
        ) : (
          <div className='d-flex justify-content-center my-5'>
            <strong>{strings.noDataFoundText}</strong>
          </div>
        )}
        <KitDataStatistics kitDataStatistics={kitDataStatistics} />
        <div className='row'>
          <div className='col-md-6'>
            <UtilizedKits
              title={strings.mostUtilizedKits}
              tableData={utilizedKits.mostUtilizedKits}
              tableCols={tableCols}
            />
          </div>
          <div className='col-md-6'>
            <UtilizedKits
              title={strings.leastUtilizedDemo}
              tableData={utilizedKits.leastUtilizedKits}
              tableCols={tableCols}
            />
          </div>
        </div>
        <WorkingCapitalInStockChart chartData={workingCapitalInStockData} language={language.language} />
      </div>
    </>
  );
}

export default KitDataTab;
