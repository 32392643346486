import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import {HashRouter} from 'react-router-dom';

import strings from "resources/locales/Translate"

import store from 'main/store/configureStore';

import * as serviceWorker from './serviceWorker';
import App from './App';
import './index.scss';

//Include bootstrap's css
import './../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './../node_modules/bootstrap/dist/css/bootstrap-grid.min.css';
import './../node_modules/bootstrap/dist/css/bootstrap-reboot.min.css';
import './../node_modules/font-awesome/css/font-awesome.min.css';

// Import JS files for Bootstrap
import './../node_modules/jquery/dist/jquery.min.js';
import './../node_modules/popper.js/dist/popper.min.js';
import './../node_modules/bootstrap/dist/js/bootstrap.min.js';

// subscribe to configureStore for language change and update app language accordingly
store.subscribe(() => {
  const language = store.getState().languageReducer;
  strings.setLanguage(language.language);
});

const app = (
  <Provider store={store}>
    <HashRouter basename='/' getUserConfirmation={() => { /* Empty callback to block the default browser prompt */}}>
      <App/>
    </HashRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
