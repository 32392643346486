import {URLS} from "library/common/commonConstants/ApiUrlConstants";
import zeissInstance from "library/utilities/AxiosInstance";

export async function createStockService(data) {
  return await zeissInstance.post(URLS.createStock, data);
}

export async function createStockRoleService(data) {
  return await zeissInstance.post(URLS.createStockRole, data);
}

export async function createStockWorkflowService(data, stockId) {
  return await zeissInstance.put(URLS.stockWorkflow.replace('{id}', stockId), data);
}

export async function createStockDeliveryTimeService(data, stockId) {
  return await zeissInstance.post(URLS.stockDeliveryDays.replace('{id}', stockId), data);
}

export async function getStockDeliveryTimeService(stockId) {
  return await zeissInstance.get(URLS.stockDeliveryDays.replace('{id}', stockId));
}

export async function getStockProvincesDeliveryTimeService(stockId) {
  return await zeissInstance.get(URLS.stockProvincesDeliveryDays.replace('{id}', stockId));
}

export async function fetchRequestAccessService(stockId) {
  return await zeissInstance.get(URLS.requestAccess.replace('{stockId}', stockId));
}

export async function getStockEmailMessages(stockId) {
  return await zeissInstance.get(URLS.stockMessages.replace('{id}', stockId));
}

export async function createUpdateEmailMessages(data, stockId) {
  return await zeissInstance.put(URLS.stockMessages.replace('{id}', stockId), data);
}

export async function updateStockService(data, stockId) {
  return await zeissInstance.put(URLS.stockDetails + stockId, data);
}

export async function fetchStockImageService(stockId) {
  return await zeissInstance.get(URLS.fetchStockImage.replace('{stockId}', stockId));
}

export async function uploadStockImagesService(stockId, data) {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  return await zeissInstance.post(URLS.fetchStockImage.replace('{stockId}', stockId), data, config);
}

export async function getStockEntitlementsService(stockId) {
  return await zeissInstance.get(URLS.getStockUserRoles.replace('{id}', stockId));
}

export async function deleteStockService(stockId) {
  return await zeissInstance.delete(URLS.deleteStock + stockId);
}

export async function requestAccessListService(stockId) {
  return await zeissInstance.get(URLS.requestAccessList.replace('{stockId}', stockId));
}

export async function requestAcceptRejectService(data) {
  return await zeissInstance.post(URLS.requestStockAccessAceptOrReject, data);
}

export async function deleteStockImageService(id) {
  return await zeissInstance.delete(URLS.fetchStockImage.replace('{stockId}', id));
}

export async function saveDeliveryDocumentFooter(data, stockId) {
  return await zeissInstance.post(URLS.deliveryDocumentFooter.replace('{stockId}', stockId), data);
}

export async function getDeliveryDocumentFooter(stockId, query) {
  return await zeissInstance.get(URLS.deliveryDocumentFooter.replace('{stockId}', stockId) + '?graphql=' + encodeURIComponent(query));
}

export async function getStates(countryId) {
  return await zeissInstance.get(URLS.getStates.replace('{countryId}', countryId));
}

export async function getStatesSpecialDeliveryTime(stockId, countryId , stateId) {
  return await zeissInstance.get(URLS.getStatesSpecialDeliveryTime(stockId, countryId , stateId));
}