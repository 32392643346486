import React from "react";
import strings from "resources/locales/Translate";

import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import { connect } from 'react-redux';
import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';
import { useKitMarketplace } from "./KitMarketplace.hook";
import CreateStockActionButtons from 'library/common/commonComponents/CreateStockActionButtons';


export const KitMarketplace = ({sellOffConditions, fetchMasterData, sellOffCurrency,handleForm, marketplaceInformation, marketplacePrice, saveKit, cancelCreateKit}) => {
  
    const {informationModel, priceModel} = useKitMarketplace({sellOffConditions, fetchMasterData, sellOffCurrency})
    return(
        <div className="container-fluid">
            <div className='col-sm-12 p-1'>
            <h3 className='mt-2 mb-2 zmcc-title-heading'>{strings.information}</h3>
            <div className='p-2 mt-2 section-border-kit'>
            <FormComponent
              model={informationModel()}
              formName='marketplaceInformation'
              formValue={marketplaceInformation.formData}
              onChange={handleForm}
            />
            </div>
          </div>

          <div className='col-sm-12 p-1'>
            <h3 className='mt-2 mb-2 zmcc-title-heading'>{strings.price}</h3>
            <div className='p-2 mt-2 section-border-kit'>
            <FormComponent
              model={priceModel()}
              formName='marketplacePrice'
              formValue={marketplacePrice.formData}
              onChange={handleForm}
            />
            </div>
          </div>

          <CreateStockActionButtons onSave={saveKit} onCancel={cancelCreateKit} styleClass={'create-kit-button mt-3'} />
        </div>
    )
}


const mapStateToProps = ({ masterDataReducer, languageReducer, authReducer, createZmccKitReducer }) => ({
    sellOffConditions: masterDataReducer.sellOffConditions,
    user: authReducer.user,
    sellOffCurrency: masterDataReducer.sellOffCurrency,
    marketplaceInformation: createZmccKitReducer.marketplaceInformation,
    marketplacePrice: createZmccKitReducer.marketplacePrice,
  });

export default connect(mapStateToProps, {
    fetchMasterData,
  })(KitMarketplace);


