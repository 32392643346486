import moment from 'moment';
import React, { Component } from 'react';
import DatePicker from 'react-date-picker';

import './loanListDatePicker.scss';
import Icon from 'library/common/commonComponents/Icon/Icon';

export default class LoanListDatePicker extends Component {
  state = {
    date: new Date(),
    isCalendarVisible: false,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.selectedDate && this.props.selectedDate !== prevProps.selectedDate) {
      this.setState({ date: new Date(this.props.selectedDate) });
    }
  }

  toggleCalendar = () => {
    this.setState(prevState => {
      return {
        isCalendarVisible: !prevState.isCalendarVisible,
      };
    });
  };

  onChange = date => {
    this.setState({ date });
    this.props.onChangeDate(date);
  };

  render() {
    const { isCalendarVisible } = this.state;
    const { selectedDate, label } = this.props;
    let minDate = selectedDate ? new Date(selectedDate.split(' ')[0]) : new Date();

    return (
      <div className='loan-list-date-picker'>
        <div className='app-datepicker cursor-pointer'>
          <p className='date-picker-label'>{label}</p>
          <div
            className={
              'd-flex datepicker-view align-items-center text-overflow-ellipsis' +
              (isCalendarVisible && 'calendar-shadow')
            }
            onClick={this.toggleCalendar}
          >
            <Icon name='calendar' width={24} height={24} fill='#000' />
            <h4 className='current-date'>{moment(selectedDate).format('DD.MM.YYYY')}</h4>
          </div>

          <DatePicker
            minDetail='decade'
            className='datepicker-component-container'
            calendarClassName='datepicker-calendar-container calendar-shadow'
            clearIcon={null}
            showLeadingZeros={true}
            isOpen={isCalendarVisible}
            onChange={this.onChange}
            value={minDate}
            minDate={new Date()}
            {...this.props}
          />
        </div>
      </div>
    );
  }
}
