import { batchActions } from 'redux-batched-actions';
import { saveAs } from 'file-saver';

import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { fetchMasterDataService, fetchMasterDataServicePut, fetchMasterDataServicePost } from 'library/api/master-data.service';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { scrollToTop } from 'library/utilities/scrollActions';
import { exportListRequest } from 'modules/DemoSale/Shop/Shop.service';
import { fetchFromStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import { logNetworkError } from 'library/utilities/logError';
import {
  FETCH_CONTINENTS_LIST,
  FETCH_CALCULATION_LIST,
  CLEAR_CALCULATION_LIST,
  FETCH_STOCKS,
  FETCH_ACCOUNT_STATEMENT_LIST,
  FETCH_USER_LIST,
  FETCH_KPI,
  FETCH_MAIN_UTILIZED_MAIN_COMPONENTS,
  FETCH_TYPES_OF_JOBS,
  CLEAR_KPI_TABLES,
  FETCH_CONTROLLING_REPORT_LIST,
  FETCH_SYSTEMCLASSIFICATION_LIST,
  FETCH_SYSTEMMAINCOMPONENT_LIST
} from './LLManagement.constants';
import zeissInstance from 'library/utilities/AxiosInstance';

export const getContinentsList = () => async dispatch => {
  try {
    dispatch(changeLoader(true));

    const { data } = await fetchMasterDataService(URLS.continents);

    batchActions([
      dispatch({
        type: FETCH_CONTINENTS_LIST,
        payload: data,
      }),
      dispatch(changeLoader(false)),
    ]);
  } catch (err) {
    batchActions([
      dispatch(changeLoader(false)),
      dispatch({
        type: FETCH_CONTINENTS_LIST,
        payload: [],
      }),
    ]);
  }
};

export const getCalculationList = continent => async dispatch => {
  try {
    dispatch(changeLoader(true));

    const { data } = await fetchMasterDataService(URLS.compensationPoints(continent));

    batchActions([
      dispatch({
        type: FETCH_CALCULATION_LIST,
        payload: data,
      }),
      dispatch(changeLoader(false)),
    ]);
  } catch (err) {
    batchActions([
      dispatch(changeLoader(false)),
      dispatch({
        type: FETCH_CALCULATION_LIST,
        payload: [],
      }),
    ]);
  }
};

export const updateCalculations = (values, continent, isForAll) => async dispatch => {
  try {
    const { status } = await fetchMasterDataServicePut(URLS.compensationPointsSetValue, {
      forAllContinents: isForAll,
      continent: isForAll ? null : continent,
      values,
    });

    if (status === 200) {
      batchActions([
        dispatch(getCalculationList(continent)),
        dispatch(toggleActionMessage(true, 'success', 'valuesSavedSuccessMessage')),
      ]);
      scrollToTop(500);
    }

    return { success: true };
  } catch (err) {
    dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    scrollToTop(500);

    return { success: false };
  }
};

export const clearCalculationList = () => dispatch => {
  dispatch({ type: CLEAR_CALCULATION_LIST });
};

export const getStocks = () => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const { data } = await fetchMasterDataService(URLS.managerCountriesLabLocation);
    dispatch(changeLoader(false));
    dispatch({
      type: FETCH_STOCKS,
      payload: data,
    })
  } catch (err) {
    dispatch(changeLoader(false));
  }
};

export const fetchUserList = () => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.userList);

    dispatch({
      type: FETCH_USER_LIST,
      payload: data,
    });

    return { success: true };
  } catch (err) {
    return { success: false };
  }
};

export const getAccountStatement = dataToSend => async dispatch => {
  try {
    dispatch(changeLoader(true));

    const { data } = await fetchMasterDataServicePost(URLS.transactionCompensationPoints, dataToSend);

    batchActions([
      dispatch({
        type: FETCH_ACCOUNT_STATEMENT_LIST,
        payload: data,
      }),
      dispatch(changeLoader(false)),
    ]);
  } catch (err) {
    batchActions([
      dispatch(changeLoader(false)),
      dispatch({
        type: FETCH_ACCOUNT_STATEMENT_LIST,
        payload: {},
      }),
    ]);
  }
};

export const exportList = dataToSend => async () => {
  const token = fetchFromStorage(identifiers.token);

  try {
    const response = await exportListRequest(URLS.transactionCompensationPointsExel, dataToSend, token);
    if (response.data) {
      const newFile = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(newFile, 'Account Statement list');
      return { success: true };
    }
  } catch (err) {
    return { success: false };
  }
};

export const saveDeductPoint = (dataToSend, settings) => async dispatch => {
  try {
    await fetchMasterDataServicePost(URLS.deductedPointsSetValue, dataToSend);

    if (settings.filter.stock && settings.filter.years) {
      const dateRange = settings.filter.yearsFullValue.map(item => ({ from: item.dateFrom, to: item.dateTo }));
      const data = {
        page: settings.page,
        size: settings.size,
        stockIds: [settings.filter.stock],
        dateRange,
      };
      dispatch(getAccountStatement(data));
    }

    return { success: true };
  } catch (err) {
    return { success: false };
  }
};

export const getKPI = dataToSend => async dispatch => {
  try {
    dispatch(changeLoader(true));

    const { data } = await fetchMasterDataServicePost(URLS.statisticPartnerKpi, dataToSend);

    batchActions([
      dispatch({
        type: FETCH_KPI,
        payload: data,
      }),
      dispatch(changeLoader(false)),
    ]);
  } catch (err) {
    batchActions([
      dispatch(changeLoader(false)),
      dispatch({
        type: FETCH_KPI,
        payload: [],
      }),
    ]);
  }
};

export const getMainUtilizedMainComponents = dataToSend => async dispatch => {
  try {
    dispatch(changeLoader(true));

    const { data } = await fetchMasterDataServicePost(URLS.statisticPartnerUtilizedComponents, dataToSend);

    batchActions([
      dispatch({
        type: FETCH_MAIN_UTILIZED_MAIN_COMPONENTS,
        payload: data,
      }),
      dispatch(changeLoader(false)),
    ]);
  } catch (err) {
    batchActions([
      dispatch(changeLoader(false)),
      dispatch({
        type: FETCH_MAIN_UTILIZED_MAIN_COMPONENTS,
        payload: [],
      }),
    ]);
  }
};

export const getTypesOfJobs = dataToSend => async dispatch => {
  try {
    dispatch(changeLoader(true));

    const { data } = await fetchMasterDataServicePost(URLS.statisticPartnerTypesOfJobs, dataToSend);

    batchActions([
      dispatch({
        type: FETCH_TYPES_OF_JOBS,
        payload: data,
      }),
      dispatch(changeLoader(false)),
    ]);
  } catch (err) {
    batchActions([
      dispatch(changeLoader(false)),
      dispatch({
        type: FETCH_TYPES_OF_JOBS,
        payload: [],
      }),
    ]);
  }
};

export const clearTables = () => dispatch => {
  dispatch({
    type: CLEAR_KPI_TABLES,
  });
}

export const getControllingReport = dataToSend => async dispatch => {
  try {
    dispatch(changeLoader(true));

    const { data } = await fetchMasterDataServicePost(URLS.stocksStatements, dataToSend);

    batchActions([
      dispatch({
        type: FETCH_CONTROLLING_REPORT_LIST,
        payload: data,
      }),
      dispatch(changeLoader(false)),
    ]);
  } catch (err) {
    batchActions([
      dispatch(changeLoader(false)),
      dispatch({
        type: FETCH_CONTROLLING_REPORT_LIST,
        payload: {},
      }),
    ]);
  }
};

export const exportControllingReportList = dataToSend => async () => {
  const token = fetchFromStorage(identifiers.token);

  try {
    const response = await exportListRequest(URLS.exportControllingReport, dataToSend,token);
    if (response.data) {
      const newFile = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const dispositionHeader = response.headers['content-disposition'] && response.headers['content-disposition'].split('filename=');
      const filename = dispositionHeader[1] || 'Controlling Report List';
      saveAs(newFile,filename);
      return { success: true };
    }
  } catch (err) {
    return { success: false };
  }
};

export const getSystemClassificationList = () => async dispatch => {
  try {
    dispatch(changeLoader(true));

    const { data } = await fetchMasterDataService(URLS.systemClassificationList);

    batchActions([
      dispatch({
        type: FETCH_SYSTEMCLASSIFICATION_LIST,
        payload: data,
      }),
      dispatch(changeLoader(false)),
    ]);
  } catch (err) {
    batchActions([
      dispatch(changeLoader(false)),
      dispatch({
        type: FETCH_SYSTEMCLASSIFICATION_LIST,
        payload: [],
      }),
    ]);
  }
};

export const getSystemMainComponentList = () => async dispatch => {
  try {
    dispatch(changeLoader(true));

    const { data } = await fetchMasterDataService(URLS.rmsSystemClass);

    batchActions([
      dispatch({
        type: FETCH_SYSTEMMAINCOMPONENT_LIST,
        payload: data,
      }),
      dispatch(changeLoader(false)),
    ]);
  } catch (err) {
    batchActions([
      dispatch(changeLoader(false)),
      dispatch({
        type: FETCH_SYSTEMMAINCOMPONENT_LIST,
        payload: [],
      }),
    ]);
  }
};

export const saveOrDeleteSystemMainComponent = dataToSend => async dispatch => {
  try {
    const { status } = await fetchMasterDataServicePost(URLS.addOrDeleteSystemClassification, dataToSend)
  
    if (status === 200) {
      batchActions([
        dispatch(getSystemClassificationList()),
        dispatch(toggleActionMessage(true, 'success', 'valuesSavedSuccessMessage')),
      ]);
      scrollToTop(500);
      return { success: true };
    }
    
  } catch (err) {
    logNetworkError(err);
    if (err && err.response) {
      if (err.response.status === 422) {
        scrollToTop(500);
         dispatch(toggleActionMessage(true, 'error',err.response.data.message, true));
           
         return { success: true };
      }
      else {
        dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
      }
    }
    else {
      dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    }
  }
};