import { useEffect, useCallback } from 'react';

export const useSystemClassesSubscriptions = ({
  getInitialData,
  notificationSettings,
  changetNotificationSettings,
}) => {
  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  const handleNotificationSettings = useCallback((checked, field) => {
    const dataToSend = {
      ...notificationSettings,
      [field]: checked,
    };

    changetNotificationSettings(dataToSend);
  }, [notificationSettings, changetNotificationSettings]);

  return { handleNotificationSettings };
};
