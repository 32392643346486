import { useState, useCallback, useEffect } from 'react';
import $ from 'jquery';

const OPPORTUNITY_LIST_ID_POPUP = 'opportunityListPopup';
const OPPORTUNITY_SEARCH_ID_POPUP = 'opportunitySearchPopup';

export const useOpportunitySearchPopup = ({
  fetchOpportunitiesList,
  selectedKits,
  componentWarehouseForm,
  loanType,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [opportunitySearchForm, setOpportunitySearchForm] = useState({
    firstName: null,
    lastName: null,
  });

  const handleForm = useCallback(({ values }) => {
    setOpportunitySearchForm(prevState => ({
      ...prevState,
      ...values,
    }));
  }, []);

  const onCloseClick = useCallback(() => {
    const popup = $(`#${OPPORTUNITY_SEARCH_ID_POPUP}`);

    if (popup && popup.modal) {
      popup.modal('hide');
    }

    setOpportunitySearchForm({
      firstName: null,
      lastName: null,
    });
  }, []);

  const openOpportunityListPopup = useCallback(() => {
    const popup = $(`#${OPPORTUNITY_LIST_ID_POPUP}`);

    if (popup && popup.modal) {
      popup.modal('show');
    }
  }, []);

  const onSaveClick = useCallback(async () => {
    if (error) setError(null);

    setIsLoading(true);
    const { firstName, lastName } = opportunitySearchForm;
    let stockId;
    if (loanType === 'kitLoan') {
      stockId = selectedKits && selectedKits[0].kit.stockInformation.id;
    } else if (loanType === 'singleLoan') {
      stockId = componentWarehouseForm && componentWarehouseForm.formData.stock;
    } else if (loanType === 'labsLocationType') {
      stockId = selectedKits && selectedKits[0].kit.stockInformation.id;
    } else {
      stockId = null;
    }
    const { success } = await fetchOpportunitiesList({ firstName, lastName, stockId });

    setIsLoading(false);

    if (!success) {
      return setError('somethingWentWrongMessage');
    }

    onCloseClick();
    setTimeout(() => openOpportunityListPopup(), 500);
  }, [
    error,
    fetchOpportunitiesList,
    onCloseClick,
    openOpportunityListPopup,
    opportunitySearchForm,
    selectedKits,
    componentWarehouseForm,
    loanType,
  ]);

  return {
    isLoading,
    error,
    opportunitySearchForm,
    handleForm,
    onCloseClick,
    onSaveClick,
    openOpportunityListPopup,
  };
};
