import React from 'react';
import cn from 'classnames';

/**
 * @fileoverview A simple component the renders a bar on Calendar
 * @returns React Node
 */
function SlotItem({ event, mustAddCssClass, mustBeHeight }) {
  const textOnly = event.commentOnly;

  let style1 = '';
  let eventBorder = '';
  if (event.type === 'type1') {
    style1 = 'request-type1';
    eventBorder = 'request-type1-border';
  } else if (event.type === 'type2') {
    style1 = 'request-type2';
    eventBorder = 'request-type2-border';
  } else if (event.type === 'type3') {
    style1 = 'request-type3';
    eventBorder = 'request-type3-border';
  }

  return (
    <div
      key={event.id}
      className={cn(mustAddCssClass, 'calendar-slot')}
      style={{
        height: mustBeHeight,
      }}
    >
      {!textOnly ? (
        <>
          {' '}
          <div className={`d-flex align-items-center justify-content-between header ${style1}`}>
            <small className={`${event.type === 'type2' ? 'request-type2-time-color' : ''}`}>8: 00</small>
            <small className={`${event.type === 'type2' ? 'request-type2-time-color' : ''}`}>11: 00</small>
          </div>
          <div className={`slot-name-container ${eventBorder}`}>{event.title}</div>
        </>
      ) : (
        <p className='comment-slots'>{event.title}</p>
      )}
    </div>
  );
}

export default SlotItem;
