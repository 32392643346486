import React, { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';
import dayjs from 'dayjs';
import { get } from 'lodash';
import $ from 'jquery';

import strings from 'resources/locales/Translate';
import * as actionTypes from 'library/common/commonConstants/storeConstants';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { DUST_MANAGER } from 'library/common/commonConstants/roles';

const INITIAL_FILTER = {
  searchTerm: null,
  systemClass: null,
  systemClassFullValue: null,
  systemMainComponent: null,
  systemMainComponentFullValue: null,
  status: null,
  statusFullValue: null,
};

export const useDusdTask = ({
  fetchMasterData,
  categoriesAndSystems,
  fetchTaskListData,
  removeDudsTask,
  history,
  user,
  fetchSystemClassDataForPopup,
  popUpSystemClassData,
}) => {
  const filter = { ...INITIAL_FILTER };
  const [isShowFilter, setIsShowFilter] = useState(true);
  const [deletionError, setDeletionError] = useState(false);
  const [isLoadingDeletion, setIsLoadingDeletion] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [data, setData] = useState({
    page: 1,
    size: 10,
    sort: {
      systemClassSort: null,
      systemMainComponentSort: null,
      createdOnSort: 'desc',
    },
    filter,
  });
  const isAdmin = user && user.admin;
  const isServiceManager = user && user.globalRoles && user.globalRoles.includes(DUST_MANAGER);
  const isShowStatusButton = isAdmin || isServiceManager;

  useEffect(() => {
    fetchTaskListData(data);
  }, [data, fetchTaskListData]);

  useEffect(() => {
    const fetchSystemClass = async () => {
      await fetchMasterData(URLS.systemClassWithStock, actionTypes.FETCH_SYSTEM_CLASS_WITH_STOCKS);
    };

    if (Array.isArray(categoriesAndSystems) && !(categoriesAndSystems.stockSystemClasses && categoriesAndSystems.systemClasses)) {
      fetchSystemClass();
    }
  }, [categoriesAndSystems, fetchMasterData]);

  const handleSearchChange = e => {
    const { value } = e.target;
    debounceUpdateData({ value });
  };
  useEffect(() => {
    fetchSystemClassDataForPopup()
  }, [fetchSystemClassDataForPopup]);
  const debounceUpdateData = debounce(({ value }) => {
    setData(
      prevState => {
        const newData = { ...prevState };
        newData.page = 1;
        newData.filter.searchTerm = value;
        return { ...newData };
      });
  }, 1000);

  const showOrHideFilter = () => {
    setIsShowFilter(prevState => !prevState);
  };

  const handleFilterChange = (key, value, fullValue) => {
    if (key === 'systemClass') {
      setData(prevState => ({
        ...prevState,
        filter: {
          systemClass: value,
          systemClassFullValue: fullValue,
          systemMainComponent: null,
          systemMainComponentFullValue: null,
          status: prevState.status,
          statusFullValue: prevState.statusFullValue,
          searchTerm: prevState.searchTerm,
        }
      }));
    } else {
      setData(prevState => {
        const newData = { ...prevState };
        newData.filter[key] = value;
        newData.filter[`${key}FullValue`] = fullValue;
        return { ...newData };
      });
    }
  };

  const resetFilters = () => {
    setData(prevState => ({ ...prevState, filter: { ...INITIAL_FILTER, searchTerm: prevState.searchTerm }}));
  };

  const handleSort = name => {
    setData(prevState => {
      const newData = cloneDeep(prevState);
      Object.keys(newData.sort).forEach(key => (newData.sort[key] = null));
      newData.sort[name] = prevState.sort[name] ? (prevState.sort[name] === 'asc' ? 'desc' : null) : 'asc';
      return newData;
    });
  };

  const handleItemsPerPage = size => {
    setData(prevState => {
      const newData = { ...prevState };
      newData.size = size;

      return newData;
    });
  };

  const handlePageChange = page => {
    setData(prevState => {
      const newData = { ...prevState };
      newData.page = page + 1;

      return newData;
    });
  };

  const createDataForSystemClassDropdown = () => {
    let arrayOfSystemClass = (categoriesAndSystems && categoriesAndSystems.systemClasses) || [];

    return Array.from(new Set(arrayOfSystemClass)).sort((a, b) => {
      const systemClassA = a.systemClassName.toUpperCase();
      const systemClassB = b.systemClassName.toUpperCase();

      return systemClassA > systemClassB ? 1 : -1;
    });
  };

  const systemsClass = createDataForSystemClassDropdown();
  const createDataForPopupSystemClass = () => {
    let arrayOfSystemClass = (popUpSystemClassData && popUpSystemClassData.systemClasses) || [];

    return Array.from(new Set(arrayOfSystemClass)).sort((a, b) => {
      const systemClassA = a.systemClassName.toUpperCase();
      const systemClassB = b.systemClassName.toUpperCase();

      return systemClassA > systemClassB ? 1 : -1;
    });
  };
  const popUpSystemData = createDataForPopupSystemClass();

  const getTableRows = () => [
    {
      title: '#',
      name: 'id',
      isSort: false,
      render: item => {
        return (
          <span>{item.id}</span>
        )
      },
    },
    {
      title: strings.descriptionText,
      name: 'description',
      isSort: false,
      render: item => {
        return (
          <span>{item.description || ''}</span>
        )
      },
    },
    {
      title: strings.systemClassText,
      name: 'systemClassSort',
      isSort: true,
      render: item => {
        return (
          <span>{get(item, 'systemClass.systemClassName') || '-'}</span>
        )
      },
    },
    {
      title: strings.systemMain,
      name: 'systemMainComponentSort',
      isSort: true,
      render: item => {
        return (
          <span>{get(item, 'systemMainComponent.systemMainComponentName') || ''}</span>
        )
      },
    },
    {
      title: strings.createdBy,
      name: 'createdBy',
      isSort: false,
      render: item => {
        const firstName = get(item, 'createdBy.firstName') || '';
        const lastName = get(item, 'createdBy.lastName') || '';
        const fullName = `${firstName} ${lastName}` || '-';

        return (
          <span>{fullName}</span>
        )
      },
    },
    {
      title: strings.createdOn,
      name: 'createdOnSort',
      isSort: true,
      render: item => {
        return (
          <span>{item.createdOn ? dayjs(item.createdOn).format('DD.MM.YYYY') : null}</span>
        )
      },
    },
  ];

  const openCreateTaskPopup = () => {
    $('#create-task-modal').modal('show');
  };

  const openEditTaskPopup = selectedTask => {
    setSelectedTask(selectedTask);
    $('#edit-task-modal').modal('show');
  };

  const openDeleteTaskPopup = selectedTask => {
    setSelectedTask(selectedTask);
    $('#confirm-delete-task-modal').modal('show');
  };

  const hideDeleteTaskPopup = () => {
    setSelectedTask(null);
  };

  const confirmTaskDeletion = async () => {
    if (deletionError) {
      setDeletionError('');
    }
    setIsLoadingDeletion(true);

    const { success } = await removeDudsTask(selectedTask.id, data);

    if (success) {
      hideDeleteTaskPopup();
    } else {
      setDeletionError('somethingWentWrongMessage');
    }
    setIsLoadingDeletion(false);
  };

  const goToListOfStatuses = useCallback(selectedTask => {
    history.push(`/administration/status-list/${selectedTask.id}`);
  }, [history]);

  return {
    isShowFilter,
    data,
    handleSearchChange,
    showOrHideFilter,
    handleFilterChange,
    resetFilters,
    handleSort,
    handleItemsPerPage,
    handlePageChange,
    debounceUpdateData,
    systemsClass,
    getTableRows,
    deletionError,
    isLoadingDeletion,
    openDeleteTaskPopup,
    hideDeleteTaskPopup,
    confirmTaskDeletion,
    selectedTask,
    setSelectedTask,
    openCreateTaskPopup,
    openEditTaskPopup,
    goToListOfStatuses,
    isShowStatusButton,
    createDataForSystemClassDropdown,
    setDeletionError,
    popUpSystemData,
  };
};
