import { batchActions } from 'redux-batched-actions';
import moment from 'moment';

import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { fetchMasterDataService, fetchMasterDataServicePost } from 'library/api/master-data.service';
import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { scrollToTop } from 'library/utilities/scrollActions';
import { validateForm } from 'library/utilities/ValidateForm.util';

import services from '../CreateKit/CreateKit.service';
import { uploadKitImage, uploadKitAttachment } from '../CreateKit/CreateKit.actions';

import {
  UPDATE_L_L_KIT_FORM,
  FETCH_STOCKS_FOR_LAB_LOCATION_BU,
  FETCH_SYSTEM_CLASSES_LAB_LOCATION_BU,
  FETCH_SYSTEM_MAIN_COMPONENT_LAB_LOCATION_BU,
  FETCH_ACTIVE_UNTIL_DATE,
  FETCH_LAB_LOCATION_KIT_DETAILS,
  RESET_LAB_LOCATION_KIT_FORM,
} from './CreateLLKit.constants';
import { generalInformationModel } from './CreateLLKit.constants';
import { assignmentModel, mainComponentModel, availabilityModel, buildingAndRoomModel } from './Components/InformationTab/InformationTab.constants';

export const updateForm = payload => ({
  type: UPDATE_L_L_KIT_FORM,
  payload,
});

export const resetForm = () => ({
  type: RESET_LAB_LOCATION_KIT_FORM,
});

export const fetchActiveUnitDate = (id, values) => async (dispatch, getState) => {
  try {
    const { createLLKitReducer } = getState();
    const { data } = await fetchMasterDataService(URLS.activeUntilDate(id));
    const existingAvailableTo = createLLKitReducer.informationForm?.formData?.availabilityTo
    dispatch(updateForm({
      informationForm: {
        ...createLLKitReducer.informationForm,
        formData: {
          ...createLLKitReducer.informationForm.formData,
          availabilityTo: (existingAvailableTo ? new Date(existingAvailableTo): data ? new Date(data): null),
          ...values,
        },
      },
    }));
    dispatch({
      type: FETCH_ACTIVE_UNTIL_DATE,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_ACTIVE_UNTIL_DATE,
      payload: null,
    });
  }
};

export const fetchStocks = () => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(`${URLS.countryList}/business-unit?busunessUnitId=9`);

    dispatch({
      type: FETCH_STOCKS_FOR_LAB_LOCATION_BU,
      payload: data.map(item => ({ ...item, systemClassText: item.systemClassName })),
    });
  } catch (err) {
    dispatch({
      type: FETCH_STOCKS_FOR_LAB_LOCATION_BU,
      payload: [],
    });
  }
};

export const fetchSystemClasses = () => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.systemClass.replace('{id}', 9));

    dispatch({
      type: FETCH_SYSTEM_CLASSES_LAB_LOCATION_BU,
      payload: data.map(item => ({ ...item, systemClassName: item.systemClassText })),
    });
  } catch (err) {
    dispatch({
      type: FETCH_SYSTEM_CLASSES_LAB_LOCATION_BU,
      payload: [],
    });
  }
};

export const fetchSystemMainComponent = systemClassId => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.systemMainComponent.replace('{id}', systemClassId));

    dispatch({
      type: FETCH_SYSTEM_MAIN_COMPONENT_LAB_LOCATION_BU,
      payload: data.map(item => ({ ...item, systemMainComponentName: item.systemMainComponentText })),
    });
  } catch (err) {
    dispatch({
      type: FETCH_SYSTEM_MAIN_COMPONENT_LAB_LOCATION_BU,
      payload: [],
    });
  }
};

export const createLLKit = isCopy => async (dispatch, getState) => {
  const { createLLKitReducer } = getState();
  const {
    generalInformationForm,
    informationForm,
    furtherImportantComponentForm,
    salesComponentForm,
    freeAccessoriesForm,
    kitStatusForm,
    kitImagesToDelete,
    kitAttachmentsToDelete,
    kitImages,
    kitAttachments,
    kitInformationId,
  } = createLLKitReducer;

  const validatedGeneralForm = validateForm({ form: generalInformationForm, model: generalInformationModel });

  if (!validatedGeneralForm.isFormValid) {
    dispatch(toggleActionMessage(true, 'error', 'fillKitHeaderInformation'));
    dispatch(updateForm({ generalInformationForm: validatedGeneralForm }));
    scrollToTop(500);

    return { success: false };
  }

  let combinedModel = [...assignmentModel, ...mainComponentModel, ...availabilityModel, ...buildingAndRoomModel]

  const validatedInformationForm = validateForm({ form: informationForm, model: combinedModel });

  if (!validatedInformationForm.isFormValid) {
    dispatch(toggleActionMessage(true, 'error', 'formIsInvalid'));
    dispatch(updateForm({ informationForm: validatedInformationForm }));
    scrollToTop(500);

    return { success: false };
  }

  const valuesToSend = {
    ...generalInformationForm.formData,
    ...informationForm.formData,
    ...kitStatusForm.formData,
    ...furtherImportantComponentForm.formData,
    ...salesComponentForm.formData,
    ...freeAccessoriesForm.formData,
    deleteImageIds: kitImagesToDelete,
    deleteAttachmentIds: kitAttachmentsToDelete,
  };

  Object.keys(valuesToSend).forEach(key => {
    if (key.includes('Error') || key.includes('FullValue')) {
      delete valuesToSend[key];
    }
    if (key === 'stockInformation' || key === 'businessUnit' || key === 'country') {
      valuesToSend[key] = { id: valuesToSend[key] };
    }
    if (key === 'systemClass') {
      valuesToSend[key] = { systemClassId: valuesToSend[key] };
    }
    if (key === 'systemMainComponent') {
      valuesToSend[key] = { systemMainComponentId: valuesToSend[key] };
    }
    if (key === 'kitCondition') {
      valuesToSend[key] = valuesToSend[key] ? { kitConditionId: valuesToSend[key] } : null;
    }
    if (key === 'dateOfManufacture') {
      valuesToSend[key] = valuesToSend[key] ? valuesToSend[key] : '';
    }
    if (key === 'refurbishmentFrom') {
      valuesToSend[key] = (kitStatusForm.formData.kitCondition === 5) ? moment(kitStatusForm.formData.refurbishmentFrom).format('YYYY-MM-DD') : null;
    }
    if (key === 'refurbishmentTo') {
      valuesToSend[key] = (kitStatusForm.formData.kitCondition === 5) ? moment(kitStatusForm.formData.refurbishmentTo).format('YYYY-MM-DD') : null;
    }
    if (key === 'ibase') {
      valuesToSend[key] = valuesToSend[key] ? valuesToSend[key] : '';
    }
  });

  valuesToSend.kitInformationId = kitInformationId || '';

  if (isCopy) {
    valuesToSend.kitImages = kitImages.filter(image => image.kitImagesId).map(image => ({ ...image, kitImagesId: 0, preview: image.actualImageUrl }));
    valuesToSend.kitAttachments = kitAttachments.filter(file => file.kitAttachmentsId).map(file => ({ ...file, kitAttachmentsId: 0 }));
  }

  const imagesToUpload = kitImages.filter(image => !image.kitImagesId);
  const attachmentsToUpload = kitAttachments.filter(file => !file.kitAttachmentsId);

  try {
    dispatch(changeLoader(true));
    const { data } = await fetchMasterDataServicePost(URLS.kit, valuesToSend);
    const message = isCopy ? 'kitCopySuccessMessage' : 'kitSavedSuccessMessage';

    if (imagesToUpload.length) {
      await dispatch(uploadKitImage(imagesToUpload, data.kitInformationId));
    }

    if (attachmentsToUpload.length) {
      await dispatch(uploadKitAttachment(attachmentsToUpload, data.kitInformationId));
    }

    dispatch(changeLoader(false));
    dispatch(toggleActionMessage(true, 'success', message));

    return { success: true, kitInformationId: data.kitInformationId };
  } catch (err) {
    const response = err && err.response && err.response.status;

    dispatch(changeLoader(false));
    scrollToTop(500);

    if (response === 422 || response === 403) {
      dispatch(toggleActionMessage(true, 'error', err.response.data.message, true));
    } else {
      dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    }

    return { success: false };
  }
};

export const fetchLLKitDetails = (id, isCopy) => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const { data } = await services.fetchKitDetailService(id);
    let kitDetails = data;
    if (isCopy) {
      kitDetails = {
        ...data,
        kitInformationId: null,
        serialNumber: '',
        dateOfManufacture: '',
      };
    }
    const formData = createFormData(kitDetails);

    dispatch({
      type: FETCH_LAB_LOCATION_KIT_DETAILS,
      payload: { kitDetails, ...formData },
    });
    dispatch(changeLoader(false));
  } catch (err) {
    dispatch(changeLoader(false));
  }
};

export const createFormData = kitDetails => {
  const {
    kitReparation,
    kitInformationId,
    kitName,
    description,
    furtherImportantComponents,
    salesComponents,
    stockInformation,
    businessUnit,
    systemClass,
    systemMainComponent,
    ibase,
    serialNumber,
    dateOfManufacture,
    availabilityFrom,
    availabilityTo,
    kitCondition,
    storageLocation,
    comment,
    freeAccessories,
    kitImages,
    kitAttachments,
  } = kitDetails || {};
  const reparation = (kitReparation && kitReparation.length && kitReparation.find(item => item.isReparationTimeActive)) || {};
  const generalInformationForm = {
    formData: {
      kitInformationId: kitInformationId,
      kitName: kitName,
      description: description,
    },
    isFormValid: false,
  };
  const furtherImportantComponentForm = {
    formData: {
      furtherImportantComponents: furtherImportantComponents,
    },
    isFormValid: false,
  };
  const salesComponentForm = {
    formData: {
      salesComponents: salesComponents,
    },
    isFormValid: false,
  };
  const informationForm = {
    formData: {
      stockInformation: stockInformation && stockInformation.id,
      stockInformationFullValue: stockInformation,
      businessUnit: businessUnit && businessUnit.id,
      businessUnitFullValue: businessUnit,
      systemClass: systemClass && systemClass.systemClassId,
      systemClassFullValue: systemClass,
      systemMainComponent: systemMainComponent && systemMainComponent.systemMainComponentId,
      systemMainComponentFullValue: systemMainComponent,
      ibase: ibase,
      serialNumber: serialNumber,
      dateOfManufacture: dateOfManufacture && new Date(dateOfManufacture),
      availabilityFrom: moment(availabilityFrom).isValid() ? new Date(availabilityFrom) : '',
      availabilityTo: moment(availabilityTo).isValid() ? new Date(availabilityTo) : '',
      storageLocation,
    },
    isFormValid: false,
  };
  const kitStatusForm = {
    formData: {
      kitCondition: kitCondition && kitCondition.kitConditionId,
      kitConditionFullValue: kitCondition && {
        kitConditionId: kitCondition.kitConditionId,
        kitStatusId: kitCondition.kitConditionId,
        translatedStatus:
          kitCondition.kitConditionTranslated &&
          kitCondition.kitConditionTranslated[0] &&
          kitCondition.kitConditionTranslated[0].translatedCondition,
      },
      comment,
      refurbishmentFrom: reparation.reparationFrom ? new Date(reparation.reparationFrom) : null,
      refurbishmentTo: reparation.reparationTo ? new Date(reparation.reparationTo) : null,
    },
    isFormValid: false,
  };
  const freeAccessoriesForm = {
    formData: {
      freeAccessories,
    },
    isFormValid: false,
  };
  const images = kitImages ? kitImages.map(image => ({ ...image, name: image.file })) : [];
  const attachments = kitAttachments ? kitAttachments.map(file => ({ ...file, name: file.file })) : [];

  return {
    generalInformationForm,
    informationForm,
    kitStatusForm,
    salesComponentForm,
    furtherImportantComponentForm,
    freeAccessoriesForm,
    kitImages: images,
    kitImagesToDelete: [],
    kitAttachments: attachments,
    kitAttachmentsToDelete: [],
  };
};
