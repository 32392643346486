export const kitsListBreadcrumbData = [
  {
    name:'homepage',
    url: '/home'
  },
  {
    name:'kits',
  },
];

export const itemsPerPage = {
  10: { id: 10, pages: 10 },
  20: { id: 20, pages: 20 },
  50: { id: 50, pages: 50 },
};

export const INITIAL_FILTER = {
  searchTerm: null,
  stock: null,
  stockFullValue: null,
  category: null,
  categoryFullValue: null,
  status: null,
  statusFullValue: null,
  condition: null,
  conditionFullValue: null,
};

