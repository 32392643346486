import React from 'react';

import strings from 'resources/locales/Translate';
import { getSymptomCode } from 'library/utilities/getSymptomByCode';
import { getDateInFormat } from 'library/utilities/getDateInFormat';
import { getStatusDescriptionByCode } from 'library/utilities/getStatusDescriptionByCode';

export const dataTable = language => [
  {
    title: strings.ticketId,
    name: 'ticketId',
    isSort: false,
    render: (item) => {
      return (
        <span>{item.ticketId}</span>
      )
    },
  },
  {
    title: strings.status,
    name: 'status',
    isSort: false,
    render: (item) => {
      return (
        <span>{getStatusDescriptionByCode(language, item.status)}</span>
      )
    },
  },
  {
    title: strings.createdBy,
    name: 'createdBy',
    isSort: false,
    render: (item) => {
      return (
        <span>{`${item.contactPerson.firstName} ${item.contactPerson.lastName}`}</span>
      )
    },
  },
  {
    title: strings.createdOn,
    name: 'createdOn',
    isSort: false,
    render: (item) => {
      return (
        <span>{getDateInFormat('DD.MM.YYYY', item.createdAt)}</span>
      )
    },
  },
  {
    title: strings.problem,
    name: 'problem',
    isSort: false,
    render: (item) => {
      return (
        <span>{getSymptomCode(language, item.codes.symptomCode)}</span>
      )
    },
  },
];
