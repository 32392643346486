import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { saveAs } from 'file-saver';
import { exportListRequest } from 'modules/DemoSale/Shop/Shop.service';
import { fetchFromStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import { fetchMasterDataService } from 'library/api/master-data.service';
import { batchActions } from 'redux-batched-actions';
import { changeLoader } from 'library/common/commonActions/AppActionsActions';
import { FETCH_REFURBISHMENT_FEES_COUNTRY_LIST } from './RefurbishmenFess.constants';

export const exportRefurbishmentFess = dataToSend => async () => {
  const token = fetchFromStorage(identifiers.token);

  try {
    const language = fetchFromStorage(identifiers.language);
    const response = await exportListRequest(URLS.refurbishmentFessExport, dataToSend, token,language);
    if (response.data) {
      const newFile = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const dispositionHeader =
        response.headers['content-disposition'] && response.headers['content-disposition'].split('filename=');
      const filename = dispositionHeader[1] || 'Refurbishment Fees ';
      saveAs(newFile, filename);
      return { success: true };
    }
  } catch (err) {
    return { success: false };
  }
};

export const fetchRefurbishmentStocks = () => async dispatch => {
  try {
    dispatch(changeLoader(true));
    let url = `${URLS.countryList}?loantype=loan-fee-active`;

    const { data } = await fetchMasterDataService(url);

    batchActions([
      dispatch({
        type: FETCH_REFURBISHMENT_FEES_COUNTRY_LIST,
        payload: data,
      }),
      dispatch(changeLoader(false)),
    ]);
  } catch (err) {
    batchActions([
      dispatch(changeLoader(false)),
      dispatch({
        type: FETCH_REFURBISHMENT_FEES_COUNTRY_LIST,
        payload: [],
      }),
    ]);
  }
};
