import { useState, useEffect } from 'react';
import $ from 'jquery';

import strings from 'resources/locales/Translate';

export function useRelatedTransactions({
  id,
  type,
  onEditPress,
  setConflictedTransactions,
  handleSaveTransactionDetails,
  transactionDetails,
  bundleLoans,
}) {
  const [transactionsId, setTransactionsId] = useState([transactionDetails.transactionsId]);
  const [isSelectedAll, toggleIsSelectedAll] = useState(false);
  const [isLoading, toggleIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setTransactionsId([transactionDetails.transactionsId]);
  }, [transactionDetails.transactionsId]);

  const onCloseClick = () => {
    if ($(`#${id}`) && $(`#${id}`).modal) {
      $(`#${id}`).modal('hide');
    }
    setTransactionsId([transactionDetails.transactionsId]);
    toggleIsSelectedAll(false);
    setError('');
  };

  const handleSelect = id => () => {
    const ids = [...transactionsId];
    if (ids.includes(id)) {
      ids.splice(ids.indexOf(id), 1);
    } else {
      ids.push(id);
    }
    const isAllCheckbox = ids.length === (bundleLoans.length + 1);
    if (isAllCheckbox) {
      toggleIsSelectedAll(true);
    } else if (isSelectedAll && !isAllCheckbox) {
      toggleIsSelectedAll(false);
    }
    setTransactionsId(ids);
  };

  const handleSelectAll = () => {
    if (isSelectedAll) {
      setTransactionsId([transactionDetails.transactionsId]);
    } else {
      const ids = bundleLoans.map(item => item.transactionsId);
      setTransactionsId([transactionDetails.transactionsId, ...ids]);
    }
    toggleIsSelectedAll(prevState => !prevState);
  };

  const handleConfirmAction = async () => {
    setError('');
    toggleIsLoading(true);

    const { success, error } = await handleSaveTransactionDetails(transactionsId);

    if (success) {
      toggleIsLoading(false);
      if (type === 'isTransactionDetails' || type === 'isUpdatingTransactionWithTransactionStatus') onEditPress();
      onCloseClick();
    } else {
      toggleIsLoading(false);
      if (error.response.status === 422 && error.response.data && Array.isArray(error.response.data)) {
        const currentTransaction = error.response.data.find(item => item.transactionsId === transactionDetails.transactionsId);

        if (currentTransaction) {
          setError(strings.conflictingWithOtherTransactions);
        } else {
          setConflictedTransactions(error.response.data);
          onCloseClick();
          openConflictTransactionsPopup();
        }
      } else if (error.response.status === 422 && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      }
    }
  };

  const openConflictTransactionsPopup = () => {
    if ($('#conflictTransactionsModal') && $('#conflictTransactionsModal').modal) {
      $('#conflictTransactionsModal').modal('show');
    }
  };

  return {
    isLoading,
    error,
    handleSelect,
    handleSelectAll,
    handleConfirmAction,
    onCloseClick,
    isSelectedAll,
    transactionsId,
  };
};
