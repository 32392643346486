import { useEffect, useState, useRef } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { getDateInFormat } from 'library/utilities/getDateInFormat';
import { checkStockRoles } from 'library/utilities/checkRoles';

import { kitForSaleDetailsBreadcrumb } from './KitForSaleDetails.constants';
import { manuallyKitDetailsTabs, singleItemDetailsTabs } from './KitForSaleDetails.tabs';

export const useKitForSaleDetails = ({
  user,
  kitDetails,
  fetchKitForSaleDetail,
  history,
  saveCurrentTab,
  clearKitDetailsData,
  damageLossReportData,
  commentsData,
  match,
  deleteKitDetails,
  fetchViewsCount
}) => {
  document.title = `Kit #${match.params.id}`;
  const isKitForSale = match.path.includes('kit-manually-details');
  const isSingleItemForSale = match.path.includes('single-item-details');
  const [isFullDescriptionShown, setIsFullDescriptionShown] = useState(false);
  const printComponentRef = useRef(null);
  const isDescriptionLengthMoreThanLimit = kitDetails.description && kitDetails.description.length > 180;
  const shortDescription = isDescriptionLengthMoreThanLimit ? kitDetails.description.substring(0, 180) + '...' : kitDetails.description;
  const description = isFullDescriptionShown ? kitDetails.description : shortDescription;

  const isArchiveList = [8,4,9];
 
  const ignoreArchieveView = kitDetails && kitDetails.kitCondition && isArchiveList.includes(kitDetails.kitCondition.kitConditionId);
  useEffect(() => {
    fetchKitForSaleDetail(match.params.id);  
    return () => {
      clearKitDetailsData();
      if (clearKitDetailsData) {
        saveCurrentTab(null);
      }
    }
  }, [match.params.id, fetchKitForSaleDetail, clearKitDetailsData, saveCurrentTab, ]);

  useEffect(() => {
    if (ignoreArchieveView === false){
      fetchViewsCount(match.params.id);    
    }
  }, [ignoreArchieveView]);

  const checkRoleAndPermission = () => {
    const { stockInformation } = kitDetails;

    let isEditable = user && user.admin ? user.admin : false;
    let isDeletable = user && user.admin ? user.admin : false;

    if (stockInformation && stockInformation.userRole && stockInformation.userRole.length > 0) {
      isEditable =
        stockInformation.userRole[0].role.role.toLowerCase() === 'stock controller' ||
        (user && user.admin) ||
        stockInformation.userRole[0].role.role.toLowerCase() === 'reconditioning';
      isDeletable = stockInformation.userRole[0].role.role.toLowerCase() === 'stock controller' ||
        (user && user.admin);
    }

    return { isEditable, isDeletable };
  }

  const toggleFullDescription = () => {
    if (isDescriptionLengthMoreThanLimit) {
      setIsFullDescriptionShown(prevState => ({
        isFullDescriptionShown: !prevState.isFullDescriptionShown,
      }));
    }
  };

  const getUrls = () => {
    if (isKitForSale) {
      return {
        editingURL: `/edit-manually-kit/${kitDetails.kitInformationId}`,
        copyURL: `/copy-manually-kit/${kitDetails.kitInformationId}`,
      };
    } else if (isSingleItemForSale) {
      return {
        editingURL: `/edit-single-item/${kitDetails.kitInformationId}`,
        copyURL: `/copy-single-item/${kitDetails.kitInformationId}`,
      }
    }
  };

  const fetchKit = () => {
    fetchKitForSaleDetail(kitDetails.kitInformationId);
  };

  const handleDelete = async () => {
    const { success } = await deleteKitDetails(kitDetails.kitInformationId);
    if (success) {
      history.replace('/demo-sale');
    }
  };

  const demoSaleUrls = getUrls();
  const sellOffPlatformImages = get(kitDetails, 'sellOffPlatformInformation.sellOffPlatformImages', []);
  const checkKitImage = sellOffPlatformImages.length > 0;
  const localSalesPrice = get(kitDetails, 'sellOffPlatformInformation.localSalePrice', 0);
  const transferPrice = get(kitDetails, 'sellOffPlatformInformation.transferPrice', 0);
  const currency = get(kitDetails, 'sellOffPlatformInformation.currency.name', '');
  const isReserved = get(kitDetails, 'kitCondition.kitConditionId') === 12;
  const reservedBy =`${get(kitDetails, 'kitReservation.createdBy.firstName')} ${get(kitDetails, 'kitReservation.createdBy.lastName')}`;
  const reservationTo = get(kitDetails, 'kitReservation.reservationTo');
  const reservationToFormatted = reservationTo && getDateInFormat('DD MMM YYYY', reservationTo);
  const breadcrumbs = [...kitForSaleDetailsBreadcrumb, { name: kitDetails.kitName }];
  const tabs = isKitForSale ? manuallyKitDetailsTabs : singleItemDetailsTabs;
  const isEditable = checkRoleAndPermission().isEditable;
  const isDeletable = checkRoleAndPermission().isDeletable;
  const transferCurrency = 'EUR';
  const roles = ['Shop Manager', 'Stock Controller'];
  const stock = !isEmpty(kitDetails) && get(kitDetails, 'stockInformation');
  const isHaveRoles = (user && user.admin) || (!isEmpty(kitDetails) && checkStockRoles(roles, user, stock));
  const isOrdered = get(kitDetails, 'kitCondition.kitConditionId') === 11;
  const isSold = get(kitDetails, 'kitCondition.kitConditionId') === 4;
  const isShowSalesDetailsTab = (isOrdered || isSold) && isHaveRoles;
  let count = 0;
  if (damageLossReportData.totalElements) {
    count += damageLossReportData.totalElements;
  }
  if (commentsData.totalElements) {
    count += commentsData.totalElements;
  }

  return {
    printComponentRef,
    description,
    fetchKit,
    handleDelete,
    demoSaleUrls,
    checkKitImage,
    localSalesPrice,
    transferPrice,
    currency,
    isReserved,
    reservedBy,
    reservationToFormatted,
    breadcrumbs,
    tabs,
    isEditable,
    isDeletable,
    transferCurrency,
    count,
    isShowSalesDetailsTab,
    sellOffPlatformImages,
    toggleFullDescription,
    isFullDescriptionShown,
    isDescriptionLengthMoreThanLimit,
    isKitForSale,
    isSingleItemForSale,
    ignoreArchieveView
  };
};
