import { useEffect, useState } from "react"
import { arrivalAndUnPackingModel, installationModel } from "./ArrivalCheckListPopup.constants";
import Validators from "library/utilities/Validators";
import { validateForm } from 'library/utilities/ValidateForm.util';
import $ from 'jquery';

export const useArrivalCheckListPopup = ({
  id,
  user,
  usersList,
  saveMaintenanceNewCheckListForm,
  transactionId,
  maintenanceCheckListData,
  saveMantenanceCheckListData,
}) => {
  
  const [arrivalFormData, setArrivalFormData] = useState({ formData: {} });
  const [installationFormData, setInstallationFormData] = useState({ formData: {} });
  const [loading, setLoading] = useState(false);
  const { maintenanceChecklistId } = maintenanceCheckListData;
  useEffect(() => {
    setDefaultData();
  }, [usersList]);

  const setDefaultData = () => {
    if (user && usersList && usersList.length > 0) {
      const loggedInUser = usersList.find(item => item.accountId === user.accountId);
      if (loggedInUser) {
        setArrivalFormData({
          formData: {
            unpackedFrom: loggedInUser.userId,
            unpackedFromFullValue: loggedInUser,
          },
        });

        setInstallationFormData({
          formData: {
            setUpBy: loggedInUser.userId,
            setUpByFullValue: loggedInUser,
          },
        });
      }
    }
  };

  const arrivalAndUnpakingModel = () => {
    const model = [...arrivalAndUnPackingModel];
    model[0].options = usersList;
    if (arrivalFormData.formData.arrivalAbnormalities === 'yes') {
      model[2].validators = [{ check: Validators.required, message: 'requiredErrorMessage' }];
      model[2].isDisplayed = false;
      model[2].required = true;
    } else {
      model[2].validators = [];
      model[2].isDisplayed = true;
      model[2].required = false;
    }

    return model;
  };

  const instalationgModel = () => {
    const model = [...installationModel];
    model[0].options = usersList;
    if (installationFormData.formData.arrivalCompleteness === 'no') {
      model[2].validators = [{ check: Validators.required, message: 'requiredErrorMessage' }];
      model[2].isDisplayed = false;
      model[2].required = true;
    } else {
      model[2].validators = [];
      model[2].isDisplayed = true;
      model[2].required = false;
    }

    if (installationFormData.formData.arrivalInstallationAbnormalities === 'yes') {
      model[4].validators = [{ check: Validators.required, message: 'requiredErrorMessage' }];
      model[4].isDisplayed = false;
      model[4].required = true;
    } else {
      model[4].validators = [];
      model[4].isDisplayed = true;
      model[4].required = false;
    }

    return model;
  };

  const handleForm = ({ values, field, formName }) => {
    if (formName === 'arrivalForm') {
      setArrivalFormData({
        ...arrivalFormData,
        formData: {
          ...arrivalFormData.formData,
          ...values,
        },
      });
    }
    if (formName === 'instalationForm') {
      setInstallationFormData({
        ...installationFormData,
        formData: {
          ...installationFormData.formData,
          ...values,
        },
      });
    }
  };

  const onSaveClick = async () => {
    const arivalAndUnpackingValidForm = validateForm({
      form: arrivalFormData,
      model: arrivalAndUnPackingModel,
    });

    if (!arivalAndUnpackingValidForm.isFormValid) {
      return setArrivalFormData(arivalAndUnpackingValidForm);
    }
    const installationvalidForm = validateForm({
      form: installationFormData,
      model: installationModel,
    });

    if (!installationvalidForm.isFormValid) {
      return setInstallationFormData(installationvalidForm);
    }
    const { unpackedFrom, arrivalAbnormalities, arrivalAbnormalitiesComment ,onTimeDelivery} = arivalAndUnpackingValidForm.formData;

    const {
      setUpBy,
      arrivalCompleteness,
      arrivalCompletenessComment,
      arrivalInstallationAbnormalities,
      arrivalInstallationAbnormalitiesComment,

    } = installationvalidForm.formData;
    const dataToSend = {
      unpackedFrom: unpackedFrom,
      arrivalAbnormalities: arrivalAbnormalities === 'yes' ? true : false,
      arrivalAbnormalitiesComment: arrivalAbnormalitiesComment,
      setupBy: setUpBy,
      arrivalCompleteness: arrivalCompleteness === 'yes' ? true : false,
      arrivalCompletenessComment: arrivalCompletenessComment,
      arrivalInstallationAbnormalities: arrivalInstallationAbnormalities === 'yes' ? true : false,
      arrivalInstallationAbnormalitiesComment: arrivalInstallationAbnormalitiesComment,
      onTimeDelivery: onTimeDelivery === 'yes' ? true : false,
    };
    setLoading(true);
    const res = await maintenanceChecklistId
    ? saveMantenanceCheckListData(dataToSend, 'arrival', maintenanceChecklistId, transactionId)
    : saveMaintenanceNewCheckListForm(dataToSend, transactionId, 'arrival');

    setLoading(false);
      closeClick();
    
  };

  const closeClick = () => {
    $(`#${id}`).modal('hide');
    setDefaultData();
  };

  return {
    arrivalAndUnpakingModel,
    handleForm,
    arrivalFormData,
    installationFormData,
    instalationgModel,
    onSaveClick,
    closeClick,
    loading
  };
};