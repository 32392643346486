import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { fetchMasterData, fetchMasterDataPost } from 'library/common/commonActions/MasterDataActions';
import { fetchCartDataFromLocalStorage } from 'modules/LoanList/LoanListActions';
import { updateTransactionStatus } from '../Transactions/TransactionDetails/TransactionDetailsActions'
import {
  fetchTransactionListData,
  fetchCancellationRequest,
  fetchMasterDataOnLanguageChange,
  saveAdjustTransactionDates,
  exportTransactionList,
  getTeamMembers,
  addUsersToTeamBookings,
  removeUserFromTeamBookings,
  fetchZmccTransactionList,
  createMyQuery,
  getSaveFilterData
} from './BookingActions';
import Booking from './Booking';

import {addItemToCart} from '../LoanList/LoanListActions';
import { changeLoader } from 'library/common/commonActions/AppActionsActions';

const mapStateToProps = state => {
  return {
    language: state.languageReducer.language,
    isLoading: state.appActionsReducer.isLoading,
    displayActionMessage: state.appActionsReducer.displayActionMessage,
    type: state.appActionsReducer.type,
    message: state.appActionsReducer.message,
    countries: state.masterDataReducer.countries,
    categoriesAndSystems: state.masterDataReducer.systemClassWithStock,
    loanTypes: state.masterDataReducer.loanTypes,
    conditions: state.bookingReducer.kitConditions,
    transactionsList: state.bookingReducer.transactionsList,
    transactionPositions: state.bookingReducer.transactionPositions,
    transactionStatuses: state.bookingReducer.transactionStatuses,
    labsLocationTransactionStatuses: state.bookingReducer.labsLocationTransactionStatuses,
    returnDelayedTransactions: state.bookingReducer.returnDelayedTransactions,
    user: state.authReducer.user,
    cartItems: state.loanList.cart,
    usersList: state.masterDataReducer.usersList,
    teamMembers: state.bookingReducer.teamMembers,
    zmccTransactionStatuses: state.bookingReducer.zmccTransactionStatuses,
    zmccOperatorList: state.bookingReducer.zmccOperatorList,
    saveFilterData: state.bookingReducer.saveFilterData,
    zmccCategory: state.bookingReducer.zmccCategory,
    systemCategoriesList: state.createSEMFormReducer.systemCategoriesList,
  }
};

export default withRouter(connect(mapStateToProps, {
  fetchTransactionListData,
  fetchCancellationRequest,
  toggleActionMessage,
  addItemToCart,
  fetchMasterData,
  fetchMasterDataPost,
  fetchMasterDataOnLanguageChange,
  fetchCartDataFromLocalStorage,
  saveAdjustTransactionDates,
  exportTransactionList,
  getTeamMembers,
  addUsersToTeamBookings,
  removeUserFromTeamBookings,
  updateTransactionStatus,
  fetchZmccTransactionList,
  changeLoader,
  createMyQuery,
  getSaveFilterData
})(Booking));
