import React from 'react';

import './ToggleSwitch.styles.scss';

const ToggleSwitch = props => {
  const { name, checked, onChange, yes, no } = props;

  const handleOnChange = e => {
    if (onChange) {
      onChange(e.target.checked)
    }
  };

  return (
    <div className="toggle-switch small-switch">
      <input
        type="checkbox"
        className="toggle-switch-checkbox"
        name={name}
        id={name}
        checked={checked}
        onChange={handleOnChange}
      />
      <label className="toggle-switch-label" htmlFor={name}>
        <span className="toggle-switch-inner" data-yes={yes} data-no={no} />
        <span className="toggle-switch-switch" />
      </label>
    </div>
  );
}

export default ToggleSwitch;