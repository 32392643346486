import { useEffect, useCallback, useState } from 'react';
import $ from 'jquery';

import * as actionTypes from 'library/common/commonConstants/storeConstants';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { saveToStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';

export function useSalesOffers({
  data,
  shopList,
  fetchShopListData,
  fetchMasterData,
  addKitToObservationList,
  removeKitFromObservationList,
  reserveKit,
  cancelKitReservation,
  history,
}) {
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [selectedKitId, setSelectedKitId] = useState(null);
  const [errorReservation, setErrorReservation] = useState('');
  const [isReservedKit, setIsReservedKit] = useState(false);

  useEffect(() => {
    saveToStorage(identifiers.filtersShop, data.filter);
    fetchShopListData(data);
  }, [data, fetchShopListData]);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      await fetchMasterData(URLS.subscriptions, actionTypes.FETCH_SHOP_SUBSCRIPTIONS);
    };

    fetchSubscriptions();
  }, [fetchMasterData]);

  const openExportShopList = () => {
    $('#export-shop-list').modal('show');
  };

  const openNotifyPopup = () => {
    $('#notify-me-popup').modal('show');
  };

  const openOrderPopup = (kitId, isReserved) => {
    setSelectedKitId(kitId);
    setIsReservedKit(isReserved);
    $('#sales-offers-order-popup').modal('show');
  };

  const openSubscriptionsPopup = () => {
    $('#subscriptions-popup').modal('show');
  };

  const goToCreateKitManually = () => {
    history.push('/create-kit-manually');
  };

  const goToCreateSingleItem = () => {
    history.push('/create-single-item');
  };

  const handleClickOnObserve = useCallback(async kitId => {
    await addKitToObservationList(kitId);
    fetchShopListData(data);
  }, [data, addKitToObservationList, fetchShopListData]);

  const handleClickOnStopObserve = useCallback(async kitId => {
    await removeKitFromObservationList(kitId)
    fetchShopListData(data);
  }, [data, fetchShopListData, removeKitFromObservationList]);

  const handleClickOnReserveKit = useCallback(async kitId => {
    await reserveKit(kitId);
    fetchShopListData(data);
  }, [data, fetchShopListData, reserveKit]);

  const openCancelReservatioPopup = kitId => {
    setSelectedKitId(kitId);
    setErrorReservation('');
    $('#sales-offers-cancel-reservation-popup').modal('show');
  };

  const closeCancelReservatioPopup = () => {
    setSelectedKitId(null);
    $('#sales-offers-cancel-reservation-popup').modal('hide');
  };

  const handleClickOnCancelReservation = useCallback(async () => {
    setIsConfirmLoading(true);
    const { success } = await cancelKitReservation(selectedKitId);
    setIsConfirmLoading(false);
    if (success) {
      closeCancelReservatioPopup();
      fetchShopListData(data);
    } else {
      setErrorReservation('somethingWentWrongMessage');
    }
  }, [data, selectedKitId, cancelKitReservation, fetchShopListData]);

  const updateShopList = useCallback(() => {
    setSelectedKitId(null);
    fetchShopListData(data);
  }, [data, fetchShopListData]);

  const isShowShopList = shopList && shopList.content && shopList.content.length > 0;

  return {
    openExportShopList,
    openNotifyPopup,
    openOrderPopup,
    openSubscriptionsPopup,
    goToCreateKitManually,
    goToCreateSingleItem,
    handleClickOnObserve,
    handleClickOnStopObserve,
    handleClickOnReserveKit,
    handleClickOnCancelReservation,
    openCancelReservatioPopup,
    closeCancelReservatioPopup,
    updateShopList,
    isShowShopList,
    isConfirmLoading,
    errorReservation,
    selectedKitId,
    isReservedKit,
  };
}
