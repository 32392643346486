import { connect } from 'react-redux';

import PurchaseRequests from './PurchaseRequests.component';
import {
  getInitialPurchaseRequestsAndPopupData,
  addPurchaseRequests,
  resetPurchaseRequests,
  getPurchaseRequests,
  createComment,
  fetchSystemMainComponentData,
  getCountryList,
  updatePurchaseRequests,
  deletePurchaseRequests,
} from '../../Shop.actions';

const mapStateToProps = ({ authReducer, languageReducer, masterDataReducer, appActionsReducer, shopListReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
  appActions: appActionsReducer,
  purchaseRequests: shopListReducer.purchaseRequests,
  totalPurchaseRequestsItems: shopListReducer.totalPurchaseRequestsItems,
  countries: masterDataReducer.countries,
  rmsSystemClass: shopListReducer.rmsSystemClass,
  sellOffCurrency: masterDataReducer.sellOffCurrency,
});

export default connect(mapStateToProps, {
  getInitialPurchaseRequestsAndPopupData,
  addPurchaseRequests,
  resetPurchaseRequests,
  getPurchaseRequests,
  createComment,
  fetchSystemMainComponentData,
  getCountryList,
  updatePurchaseRequests,
  deletePurchaseRequests,
})(PurchaseRequests);
