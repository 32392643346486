import React from 'react';

import strings from 'resources/locales/Translate';
import Modal from 'library/common/commonComponents/Modal';

const CalculationMethodPopup = props => {
  const { id, continent, language, isLoading, onSave } = props;
  const title = language.language === 'en'
      ? strings.shouldChangesApplyToAllContinents(continent)
      : strings.shouldChangesApplyToAllContinents;

  return (
    <Modal
      id={id}
      className='modal-lg'
      confirmAction={() => onSave([continent], false)}
      confirmTitle={strings.saveOnlyForSelectedContinent}
      titleOfCancel={strings.applyForAll}
      loading={isLoading}
      disableBackgroundClose
      onCloseClick={() => onSave([continent], true)}
      closeIcon='save'
      dataTest='calculation-popup'
      confirmDataTest="calculation-popup-confirm"
    >
      <h4 className='font-bold mb-4'>{title}</h4>
    </Modal>
  );
};

export default CalculationMethodPopup;
