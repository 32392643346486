import { useState, useCallback } from 'react';
import $ from 'jquery';

import { validateForm } from 'library/utilities/ValidateForm.util';

import { activeSystemModel } from '../../ActiveSystems.constants';

export const useAddActiveSystemPopup = ({
  id,
  systemClasses,
  saveActiveSystem,
  page,
  size,
}) => {
  const [loading, setLoading] = useState(false);
  const [activeSystemForm, setActiveSystemForm] = useState({ formData: {}, isFormValid: false });
  const [error, setError] = useState('');

  const getModel = useCallback(() => {
    const newModel = [ ...activeSystemModel ];
    newModel[0].options = systemClasses.filter(item => item.systemMainComponent && item.systemMainComponent.length);

    if (activeSystemForm.formData && activeSystemForm.formData.systemClassId) {
      const selectedSystemClass = systemClasses.find(item => activeSystemForm.formData.systemClassId === item.systemClassId) || {};
      newModel[1].options = selectedSystemClass.systemMainComponent;
    } else {
      newModel[1].options = [];
    }

    return newModel;
  }, [systemClasses, activeSystemForm.formData]);

  const model = getModel();

  const onCloseClick = () => {
    const popup = $(`#${id}`);
    if (popup && popup.modal) {
      popup.modal('hide');
    }

    setError('');
    setActiveSystemForm({ formData: {}, isFormValid: false });
  };

  const onSaveClick = async () => {
    setError('');
    const validForm = validateForm({
      form: activeSystemForm,
      model: activeSystemModel,
    });

    if (!validForm.isFormValid) {
      return setActiveSystemForm(validForm);
    } 

    setLoading(true);
    const { systemClassId, systemMainComponentId } = validForm.formData;
    const dataToSend = {
      systemClassId,
      systemMainComponentId,
    };
    const { success, error } = await saveActiveSystem(dataToSend, page, size);
    setLoading(false);

    if (!success) {
      setError(error);
    } else {
      onCloseClick();
    }
  };

  const handleForm = ({ values, field }) => {
    if (field === 'systemClassId') {
      setActiveSystemForm({
        ...activeSystemForm,
        formData: {
          ...activeSystemForm.formData,
          ...values,
          systemMainComponentId: null,
          systemMainComponentIdFullValue: null,
        },
      });
    } else {
      setActiveSystemForm({
        ...activeSystemForm,
        formData: {
          ...activeSystemForm.formData,
          ...values,
        },
      });
    }
  };

  return {
    activeSystemForm,
    loading,
    error,
    onSaveClick,
    handleForm,
    onCloseClick,
    model,
  };
};
