import { useState, useEffect } from "react";
import * as $ from 'jquery';

export const useBulkCancelTwentyFourHourReservation = ({
  id,
  data,
  bundleLoans,
  cancelBulkResevations,
  setSelectedData,
  fetchTransListData,
  transactionType,
  setShowBulkCancelPopup,
  removeItemFromCartForBulk
}) => {
  const [transactionsId, setTransactionsId] = useState([]);
  const [isSelectedAll, toggleIsSelectedAll] = useState(false);
  const [isLoading, toggleIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (data !== null) {
      setTransactionsId([data.transactionsId]);
    }
  }, [data]);
  const filteredBundleLoans =
    bundleLoans && bundleLoans.length > 0 && bundleLoans.filter(item => item.twentyFourHoursReservation);

  const onCloseClick = () => {
    $(`#${id}`).modal('hide');
    setSelectedData(null);
    setShowBulkCancelPopup(false);
    setTransactionsId([]);
    toggleIsSelectedAll(false);
    setError('');
  };

  const handleSelect = data => () => {
    const ids = [...transactionsId];
    const id = data.transactionsId;
    if (ids.includes(id)) {
      ids.splice(ids.indexOf(id), 1);
    } else {
      ids.push(id);
    }
    const isAllCheckbox = ids.length === filteredBundleLoans.length + 1;
    if (isAllCheckbox) {
      toggleIsSelectedAll(true);
    } else if (isSelectedAll && !isAllCheckbox) {
      toggleIsSelectedAll(false);
    }
    setTransactionsId(ids);
  };

  const handleSelectAll = () => {
    if (isSelectedAll) {
      setTransactionsId([data.transactionsId]);
    } else {
      const ids = filteredBundleLoans.map(item => item.transactionsId);
      setTransactionsId([data.transactionsId, ...ids]);
    }
    toggleIsSelectedAll(prevState => !prevState);
  };

  const onClickSave = async () => {
    toggleIsLoading(true);
    let dataToSend = { transactionIds: transactionsId };
    const res = await cancelBulkResevations(dataToSend, fetchTransListData, transactionType);
    toggleIsLoading(false);
    if (res.success) {
      transactionsId.forEach(element => {
        removeItemFromCartForBulk([{transactionsId: element}]);
      });
      onCloseClick();
    } else {
      setError('somethingWentWrongMessage');
    }
  };

  return {
    transactionsId,
    isSelectedAll,
    onCloseClick,
    handleSelect,
    handleSelectAll,
    filteredBundleLoans,
    onClickSave,
    isLoading,
    error,
  };
};