import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import strings from 'resources/locales/Translate';
import { images } from 'library/common/commonConstants/ImageConstants';
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { scrollToTop } from 'library/utilities/scrollActions';
import './stylesheet.scss';
import Icon from '../Icon/Icon';

export class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      selectedValues: [],
      isSelectedAll: false,
      selectedSingleValue: null,
      isDropdownOpen: false,
      error: false,
      isInfoOpen: false,
    };
    this.dropdown = React.createRef();
  }

  componentDidMount() {
    const { data, value } = this.props;
    if (data && data.length > 0) {
      this.setState({ data });
    }

    if (value) {
      this.setSelectedValue();
    }
  }

  resetSelectedValue = () => {
    this.setState({ selectedValues: [], isSelectedAll: false, selectedSingleValue: null });
  };

  getSelected = () => {
    const { isSelectedAll, selectedSingleValue, selectedValues } = this.state;
    return { isSelectedAll, selectedSingleValue, selectedValues };
  };

  getData = () => {
    const { multiSelect, idKey } = this.props;
    const { isSelectedAll, selectedSingleValue, selectedValues } = this.state;
    let value = null;
    if (multiSelect) {
      value = selectedValues;
    } else if (selectedSingleValue && (selectedSingleValue[idKey] || selectedSingleValue[idKey] === 0)) {
      value = selectedSingleValue[idKey];
    }
    const error = this.validate(value);
    if (error) {
      this.setState({ error });
    }
    return { isSelectedAll, value, error };
  };

  excludeAddedData = () => {
    const { data, excludeData, idKey } = this.props;
    let dropdownData = excludeData.length > 0 ? _.xorBy(data, excludeData, idKey) : data;
    this.setState({
      data: dropdownData,
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { data, excludeData, clear, idKey, multiSelect, value, disabled } = this.props;
    const { selectedValues, selectedSingleValue } = this.state;

    if (
      !_.isEqual(data, prevProps.data) ||
      !_.isEqual(excludeData, prevProps.excludeData) ||
      !_.isEqual(value, prevProps.value)
    ) {
      this.excludeAddedData();
      this.setSelectedValue();
    }

    if (!_.isEqual(disabled, prevProps.disabled)) {
      this.setState({ selectedSingleValue: null });
    }

    if (clear && clear !== prevProps.clear) {
      if (this.props.clear) {
        this.setState({ selectedValues: [], selectedSingleValue: null });
      }
    }

    if (this.props.doValidateForm && this.props.doValidateForm !== prevProps.doValidateForm) {
      let valuesToSend = multiSelect
        ? selectedValues.length > 0
          ? selectedValues.map(item => item[idKey])
          : null
        : selectedSingleValue && selectedSingleValue[idKey].toString();
      this.validateValue(valuesToSend);
    }

    if (this.props.resetValidation && this.props.resetValidation !== prevProps.resetValidation) {
      this.setState({ error: false });
    }
  }

  setSelectedValue() {
    const { multiSelect, data, value, childArrayKey, idKey, hasSection, optionsArrayKey } = this.props;

    if (value) {
      if (hasSection && !multiSelect) {
        data.forEach(section => {
          if (section[optionsArrayKey] && section[optionsArrayKey].length) {
            section[optionsArrayKey].forEach(item => {
              const valueToCheck = typeof value === 'object' ? value[idKey] : value;
              if (item[idKey] === valueToCheck) {
                this.setState({ selectedSingleValue: item });
              }
            });
          }
        });
        return;
      }

      if (!hasSection && !multiSelect) {
        if (data && data.length) {
          data.forEach(item => {
            const valueToCheck = typeof value === 'object' ? value[idKey] : value;
            if (item[idKey] === valueToCheck) {
              this.setState({ selectedSingleValue: item });
            }
          });
        }
        return;
      }

      if (hasSection && multiSelect) {
        let valuesToSave = [];
        data.forEach(section => {
          if (section[optionsArrayKey] && section[optionsArrayKey].length) {
            let arrayToCheck = section[optionsArrayKey];
            if (childArrayKey && arrayToCheck[childArrayKey] && arrayToCheck[childArrayKey].length) {
              arrayToCheck = arrayToCheck[childArrayKey];
            }
            arrayToCheck.forEach(item => {
              value.forEach(val => {
                const valueToCheck = typeof val === 'object' ? val[idKey] : val;
                if (item[idKey] === valueToCheck) {
                  valuesToSave.push(item);
                }
              });
            });
          }
        });
        this.setState({ selectedValues: valuesToSave });
        return;
      }
      if (!hasSection && multiSelect) {
        data.forEach(item => {
          if (item[idKey] === value) {
            this.setState({ selectedSingleValue: item });
          }
        });
        let valuesToSave = [];
        data.forEach(item => {
          value.forEach(val => {
            const valueToCheck = typeof val === 'object' ? val[idKey] : val;
            if (valueToCheck === item[idKey]) {
              valuesToSave.push(item);
            }
          });
        });
        this.setState({ selectedValues: valuesToSave });
      } else {
        data.forEach(item => {
          if (item[idKey] === value) {
            this.setState({ selectedSingleValue: item });
          }
        });
      }
    } else if (!multiSelect && !value) {
      //this.setState({selectedSingleValue: null});
    }
  }

  componentWillMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  setError = error => {
    const { field, onError } = this.props;
    if (error.error) {
      this.setState({ error: true });
      onError(error, field);
    } else {
      this.setState({ error: false });
      onError({ error: false, type: null }, field);
    }
  };

  validate = value => {
    const { validators } = this.props;
    for (let i = 0; i < validators.length; i++) {
      let error = validators[i].check(value, validators[i].message);
      if (error) {
        return error;
      }
    }
    return false;
  };

  validateValue = value => {
    this.setError(this.validate(value));
  };

  handleClickOutside = e => {
    if (this.dropdown.contains(e.target)) {
      return;
    }
    this.setState({ isDropdownOpen: false }, () => {
      this.excludeAddedData();
    });
  };

  renderNonSectionData() {
    const { data, selectedValues } = this.state;
    const { multiSelect, displayValue, idKey } = this.props;
    let options = [];

    if (data.length > 0) {
      data.forEach((item, key) => {
        options.push(
          <div
            onClick={!item.disabled ? () => this.handleSelect(item) : null}
            key={key}
            data-test='dropdown-option'
            className={`dropdown-option d-flex align-items-center mb-2 ${!!item.disabled && 'opacity-50'}`}
          >
            {multiSelect ? (
              <img
                src={
                  selectedValues && selectedValues.findIndex(index => index[idKey] === item[idKey]) > -1
                    ? images.checkboxSelected
                    : images.checkboxDeselected
                }
                alt='checkbox'
                className='mr-3'
              />
            ) : null}

            {displayValue.map(valueFetch => {
              if (valueFetch.name) {
                return item[valueFetch.key] + valueFetch.separator + item[valueFetch.name];
              }
              return item[valueFetch.key] + valueFetch.separator;
            })}
          </div>,
        );
      });
    } else {
      options.push(
        <div
          key={0}
          data-test='dropdown-option'
          className='dropdown-option d-flex justify-content-center align-items-center mb-2'
        >
          {strings.noDataFoundText}
        </div>,
      );
    }
    return options;
  }

  renderSectionableData() {
    const { data, selectedValues } = this.state;
    const { multiSelect, idKey, displayValue, titleDisplay, optionsArrayKey } = this.props;

    let options = [];

    let singleSection = [];
    let counter = 3;

    if (data.length > 0) {
      data.forEach((menu, i) => {
        if (menu[optionsArrayKey] && menu[optionsArrayKey].length > 0) {
          singleSection.push(
            <div className='single-section mb-3' key={i}>
              {titleDisplay.length > 0 && (
                <h6 className='mb-2 font-weight-bold'>
                  {titleDisplay.map(titleItem => {
                    return menu[titleItem.key] + titleItem.separator;
                  })}
                </h6>
              )}
              {menu[optionsArrayKey].map((item, key) => (
                <div
                  tabIndex={counter}
                  onClick={() => this.handleSelect(item)}
                  key={i + '-' + key}
                  data-test='dropdown-option'
                  className='dropdown-option d-flex align-items-center mb-2'
                >
                  {multiSelect ? (
                    <img
                      src={
                        selectedValues && selectedValues.findIndex(index => index[idKey] === item[idKey]) > -1
                          ? images.checkboxSelected
                          : images.checkboxDeselected
                      }
                      alt='checkbox'
                      className='mr-2'
                    />
                  ) : null}

                  {displayValue.map(valueFetch => {
                    return item[valueFetch.key] + valueFetch.separator;
                  })}
                </div>
              ))}
            </div>,
          );
          ++counter;
          options.push(singleSection);
          singleSection = [];
        }
      });
    } else {
      options.push(
        <div
          key={0}
          data-test='dropdown-option'
          className='dropdown-option d-flex justify-content-center align-items-center mb-2'
        >
          {strings.noDataFoundText}
        </div>,
      );
    }

    return options;
  }

  handleSelect(value) {
    const { multiSelect, field, idKey, optionsArrayKey, onChangeValue } = this.props;
    const { data, isSelectedAll, selectedValues } = this.state;
    let allValues = [];
    let array = [...selectedValues];

    if (multiSelect) {
      const index = array.findIndex(item => item[idKey] === value[idKey]);

      data.forEach(item =>
        item[optionsArrayKey] ? item[optionsArrayKey].map(item => allValues.push(item)) : allValues.push(item),
      );

      if (index === -1) {
        array.push(value);
      } else {
        array.splice(index, 1);
      }

      if (value[idKey] === '') {
        if (!isSelectedAll) {
          this.setState({ selectedValues: allValues, isSelectedAll: true }, () => {
            const valuesToSend = this.state.selectedValues.map(item => item[idKey]);
            this.validateValue(valuesToSend);
            onChangeValue(valuesToSend, field, allValues);
          });
        } else {
          this.setState({ selectedValues: [], isSelectedAll: false }, () => {
            const valuesToSend = '';

            this.validateValue(valuesToSend);
            onChangeValue(valuesToSend, field, []);
          });
        }
      } else {
        if (allValues.length === array.length) {
          this.setState({ selectedValues: array, isSelectedAll: true }, () => {
            const valuesToSend = this.state.selectedValues.map(item => item[idKey]);

            this.validateValue(valuesToSend);
            onChangeValue(valuesToSend, field, array);
          });
        } else {
          this.setState({ selectedValues: array, isSelectedAll: false }, () => {
            const valuesToSend = this.state.selectedValues.map(item => item[idKey]);

            this.validateValue(valuesToSend);
            onChangeValue(valuesToSend, field, array);
          });
        }
      }
    } else {
      if (value[idKey] || value[idKey] === 0) {
        this.setState({ selectedSingleValue: value, isDropdownOpen: false }, () => {
          this.validateValue(this.state.selectedSingleValue[idKey].toString());
          onChangeValue(this.state.selectedSingleValue[idKey], field, value);
        });
      } else if (idKey === 'accountId') {
        this.props.toggleActionMessage(true, 'error', strings.emptyAccountId, true);
        scrollToTop(500);
      } else {
        this.props.toggleActionMessage(true, 'error', strings.emptyDropDownField(idKey), true);
        scrollToTop(500);
      }
    }
  }

  handleFilter = e => {
    e.preventDefault();
    const text = e.target.value;
    const { data, hasSection, displayValue, optionsArrayKey } = this.props;
    let array = [];

    if (hasSection) {
      let single = {};
      data.forEach(menu => {
        single = { ...menu };
        if(menu[optionsArrayKey] !== null) {
          single[optionsArrayKey] = menu[optionsArrayKey].filter(item => {
            let displayValueString = displayValue.map(display => {
              if (display.name) {
                return item[display.key] + display.separator + item[display.name];
              }
              return item[display.key] + display.separator;
            });
            return displayValueString
              .join('')
              .toLowerCase()
              .includes(text.toLowerCase());
          });
          array.push(single);
          single = {};
        }
      });
    } else {
      array = data.filter(item => {
        let displayValueString = displayValue.map(display => {
          if (display.name) {
            return item[display.key] + display.separator + item[display.name];
          }
          return item[display.key] + display.separator;
        });
        return displayValueString
          .join('')
          .toLowerCase()
          .includes(text.toLowerCase());
      });
    }

    this.setState({ data: array });
  };

  toggleDropdown = () => {
    if (!this.props.disabled) {
      this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
    }
  };

  toggleInfoMessage = () => {
    this.setState({ isInfoOpen: !this.state.isInfoOpen });
  };

  getPlaceholder = () => {
    const { selectedSingleValue, selectedValues } = this.state;
    const { displayValue, placeholder } = this.props;
    let displayPlaceholder = placeholder;

    if (selectedSingleValue !== null) {
      displayPlaceholder = displayValue
        .map(display => {
          if (display.name) {
            return selectedSingleValue[display.key] + display.separator + selectedSingleValue[display.name];
          }
          return selectedSingleValue[display.key] + display.separator;
        })
        .join('');
    }

    if (selectedValues.length > 0) {
      const displayFormat = selectedValues.map(item => {
        return displayValue
          .map(display => {
            return item[display.key] + display.separator;
          })
          .join('');
      });

      displayPlaceholder =
        selectedValues.length === 1 ? displayFormat[0] : `${displayFormat[0]} + ${displayFormat.length - 1}`;
    }

    return displayPlaceholder;
  };

  render() {
    const {
      dataTest,
      hasSection,
      filter,
      label,
      isRequired,
      mainContainerStyle,
      displayValue,
      idKey,
      clearOption,
      disabled,
      showExtraInfo,
      infoMessage,
      clearMultiOption,
      filterDataPlaceholder,
      selectAllPlaceholder,
    } = this.props;
    const { isDropdownOpen, data, error, isSelectedAll } = this.state;

    return (
      <div className={mainContainerStyle} data-test={dataTest}>
        <div className='label-container d-flex justify-content-between align-items-center'>
          <div className='d-flex position-relative'>
            <p>{label} {isRequired && label && <span className='mandatory-asterik'>*</span>}</p>
            {showExtraInfo && <p className='input-info cursor-pointer'>i</p>}
            {showExtraInfo && <div className='info-message position-absolute'>{strings[infoMessage]}</div>}
          </div>
        </div>

        <div
          className={`app-dropdown cursor-pointer ${disabled && 'disabled'} ${error && 'input-error'}`}
          tabIndex={1}
          ref={el => (this.dropdown = el)}
        >
          <div
            className='dropdown-title d-flex justify-content-between align-items-center'
            data-test='dropdown-title'
            onClick={this.toggleDropdown}
          >
            <span className='dropdown-placeholder'>{this.getPlaceholder()}</span>

            <Icon
              name='arrow'
              width={24}
              height={24}
              fill={'#9a9b9c'}
              style={{ transform: isDropdownOpen ? 'rotate(-90deg)' : 'rotate(90deg)' }}
            />
          </div>

          {isDropdownOpen && (
            <div className='dropdown-items-container dropdown-shadow'>
              <div
                className='dropdown-title d-flex mb-2 justify-content-between align-items-center'
                onClick={this.toggleDropdown}
              >
                <span className='dropdown-placeholder'>{this.getPlaceholder()}</span>

                <Icon
                  name='arrow'
                  width={24}
                  height={24}
                  fill={'#9a9b9c'}
                  style={{ transform: isDropdownOpen ? 'rotate(-90deg)' : 'rotate(90deg)' }}
                />
              </div>

              {filter && (
                <div>
                  <input
                    type='text'
                    className='form-control rounded-0 position-relative'
                    tabIndex={2}
                    placeholder={filterDataPlaceholder}
                    onChange={this.handleFilter}
                  />
                  <Icon name={'search'} width={24} height={24} fill={'#9a9b9c'} className='search-icon' />
                </div>
              )}
              <div className='options-container' key={0}>
                {clearOption && (
                  <div
                    onClick={() =>
                      this.handleSelect({ [idKey]: 0, [displayValue[0].key]: `${strings[selectAllPlaceholder]}` })
                    }
                    data-test='dropdown-option'
                    className='dropdown-option d-flex align-items-center mb-2'
                  >
                    {strings[selectAllPlaceholder]}
                  </div>
                )}

                {clearMultiOption && (
                  <div
                    onClick={() =>
                      this.handleSelect({ [idKey]: '', [displayValue[0].key]: `${strings[selectAllPlaceholder]}` })
                    }
                    data-test='dropdown-option'
                    className='dropdown-option d-flex align-items-center mb-2'
                  >
                    <img
                      src={isSelectedAll ? images.checkboxSelected : images.checkboxDeselected}
                      alt='checkbox'
                      className='mr-2'
                    />
                    {strings[selectAllPlaceholder]}
                  </div>
                )}
                {hasSection ? this.renderSectionableData(data) : this.renderNonSectionData(data)}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Dropdown.defaultProps = {
  hasSection: false,
  multiSelect: false,
  filter: false,
  isRequired: false,
  fetchValueFrom: [],
  optionsArrayKey: 'options',
  placeholder: '',
  label: '',
  data: [],
  excludeData: [],
  validators: [],
  displayValue: [],
  idKey: '',
  clearOption: false,
  showExtraInfo: false,
  infoMessage: '',
  value: null,
  onError: () => {},
  onChangeValue: () => {},
  disabled: false,
  doValidateForm: false,
  titleDisplay: [],
  selectAllPlaceholder: 'selectAll',
};

const mapStateToProps = state => {
  return {
    language: state.languageReducer,
  };
};

export default connect(mapStateToProps, { toggleActionMessage }, null, { forwardRef: true })(Dropdown);
