import React from 'react';
import moment from 'moment';

import Icon from 'library/common/commonComponents/Icon/Icon';
import Button from 'library/common/commonComponents/Button/Button';
import strings from 'resources/locales/Translate';

import { useSubscriptionsPopup } from './useSubscriptionsPopup';
import './subscriptionsPopup.styles.scss';

export default function SubscriptionsPopup({
  id,
  subscriptions,
  removeSubscription,
}) {
  const {
    onCloseClick,
    handleRemovingSubscription,
    isLoading,
    error,
  } = useSubscriptionsPopup({ id, removeSubscription });

  return (
    <div
      className='modal fade modal-container'
      id={id}
      tabIndex='-1'
      role='dialog'
      aria-labelledby={id}
      aria-hidden='true'
      data-test=''
    >
      <div className='modal-background' />
      <div className='modal-dialog' role='document'>
        <div className='modal-content rounded-0 '>
          <div className='modal-body p-0'>
            <div className='content subscriptions-container' id='app-scheduler-wrapper'>
              <button
                type='button'
                className='close modal-close position-absolute'
                data-dismiss='modal'
                aria-label='Close'
                onClick={onCloseClick}
                data-test='subscriptions-close-button'
              >
                <Icon name='cross' width={24} height={24} />
              </button>
              <h3 className='modal-title'>{strings.mySubscriptions}</h3>
              {subscriptions && subscriptions.length > 0 ? subscriptions.map(subscription => (
                <div className='border-top py-3' key={subscription.id}>
                  <div className='text-small'>
                    {`${strings.subscribedOn} ${subscription.createdOn ? moment(subscription.createdOn).format('YYYY-MM-DD / hh:mm A') : '-'}`}
                  </div>
                  <div className='row'>
                    <div className='col-4'>
                      <div>{strings.countryLand}:</div>
                      <div>{strings.systemClassText}:</div>
                    </div>
                    <div className='col-6'>
                      <div>{subscription.countryName}</div>
                      <div>{subscription.systemClassName}</div>
                    </div>
                    <div className='col-2'>
                      <Button
                        loading={isLoading.includes(subscription.id)}
                        bgFill={false}
                        iconName='delete'
                        onClick={() => handleRemovingSubscription(subscription.id)}
                        styleClass='border-0 delete-button'
                      />
                    </div>
                  </div>
                </div>
              )) : (
                <div className='d-flex justify-content-center border-top'>
                  <div className='py-3'>
                    <strong>{strings.noSubscriptionsAvailable}</strong>
                  </div>
                </div>
              )}
              {error && <div className='error-block'>{error}</div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
