export const kitDetails = {
  kitName: "system RC 121",
  description: "Yo man",
  systemValue: 9.8,
  marketingMaterial: false,
  hideKit: true,
  informationTab: false,
  stockId: 0,
  storageLocation: null,
  businessUnit: 1,
  sapERP: null,
  systemClassId: 10,
  systemMainComponentId: 19,
  serialNumber: "semmme",
  dateOfManufacture: "09/2019",
  countryId: 1,
  commodityCode: "",
  deliveryDays: 9,
  returnDeliveryDays: 10,
  reconditioningDeliveryDays: 2,
  availabilityFrom: "09/2019",
  availabilityTo: null,
  packageDimensions: "",
  conditionStatus: 0,
  comment: "",
  componentsTab: false,
  salesComponents: "mdmfm",
  furtherImportantComponents: "",
  accessoriesTab: false,
  freeAccessories: "",
  deleteImageIds: null,
  deleteAttachmentIds: null,
  kitImages: [],
  kitAttachments: null,
  kitId: 12,
  iBase: "MM0010",
  targetDeliveryDate: '2019-09-03',
  targetReservationFrom: '2019-09-08',
  targetReservationTo: '2019-09-16',
  targetReturnDeliveryDate: '2019-09-21',
  kitInformationId: 1,
  businessUnitList: [],
  stockInformation:{id:1}
};

export const kitDetailsForErrorMessageMockData = {
  isLabsLocationPartner:false,
  kitStatuses:[{kitStatusId:1}],
  kitCondition:{kitConditionId:1},
  kitName: "system RC 121",
  description: "Yo man",
  systemValue: 9.8,
  marketingMaterial: false,
  hideKit: true,
  informationTab: false,
  stockId: 0,
  storageLocation: null,
  businessUnit: 1,
  sapERP: null,
  systemClassId: 10,
  systemMainComponentId: 19,
  serialNumber: "semmme",
  dateOfManufacture: "09/2019",
  countryId: 1,
  commodityCode: "",
  deliveryDays: 9,
  returnDeliveryDays: 10,
  reconditioningDeliveryDays: 2,
  availabilityFrom: "09/2019",
  availabilityTo: null,
  packageDimensions: "",
  conditionStatus: 0,
  comment: "",
  componentsTab: false,
  salesComponents: "mdmfm",
  furtherImportantComponents: "",
  accessoriesTab: false,
  freeAccessories: "",
  deleteImageIds: null,
  deleteAttachmentIds: null,
  kitImages: [],
  kitAttachments: null,
  kitId: 12,
  iBase: "MM0010",
  targetDeliveryDate: '2019-09-03',
  targetReservationFrom: '2019-09-08',
  targetReservationTo: '2019-09-16',
  targetReturnDeliveryDate: '2019-09-21',
  kitInformationId: 1,
  businessUnitList: [],
};

export const user = {
  accountId: 8746,
  admin: true,
  email: 'ghazi.khan@theleanapps.com',
  firstName: 'Ghazi',
  lastName: 'Khan',
  stockRoles: [
    {
      roleName: 'Dispatcher',
      stockId: 4,
      role: {
        role: 'stock controller'
      }
    },
    {
      roleName: '"Manager"',
      stockId: 5
    },
    {
      roleName: 'Stock Controller',
      stockId: 27
    },
  ],
};

export const dummyMessages = [
  {
    id: 1,
    name: 'Kurs PA4611',
    createdBy: {
      firstName: 'Sandy',
      lastName: 'Willer',
      userAddresses: [{
        phone: '+49 3641 643168',
      }],
    },
    email: 'sandy.willer.ext@zeiss.com',
    text:
      'Hallo Herr Seidler, der Hinversand erfolgt aus logistischen Gründen aus unserem Außenlager, somit kann der abgesagt werden. Bitte nur noch den Rückversand für den 10.12.18 zwischen 8 und 12 oder 13 bis 15 Uhr organisieren. Bitte um Bestätigung. Mit freundlichen Grüßen / Best regards Sandy Willer',
    createdAt: 1569069000,
  },
  {
    id: 2,
    name: 'Kurs PA4611',
    createdBy: {
      firstName: 'Sandy',
      lastName: 'Willer',
      userAddresses: [{
        phone: '+49 3641 643168',
      }],
    },
    email: 'sandy.willer.ext@zeiss.com',
    text:
      'Hallo Herr Seidler, der Hinversand erfolgt aus logistischen Gründen aus unserem Außenlager, somit kann der abgesagt werden. Bitte nur noch den Rückversand für den 10.12.18 zwischen 8 und 12 oder 13 bis 15 Uhr organisieren. Bitte um Bestätigung. Mit freundlichen Grüßen / Best regards Sandy Willer',
    createdAt: 1569067260,
  },
  {
    id: 3,
    name: 'Kurs PA4611',
    createdBy: {
      firstName: 'Sandy',
      lastName: 'Willer',
      userAddresses: [{
        phone: '+49 3641 643168',
      }],
    },
    email: 'sandy.willer.ext@zeiss.com',
    text:
      'Hallo Herr Seidler, der Hinversand erfolgt aus logistischen Gründen aus unserem Außenlager, somit kann der abgesagt werden. Bitte nur noch den Rückversand für den 10.12.18 zwischen 8 und 12 oder 13 bis 15 Uhr organisieren. Bitte um Bestätigung. Mit freundlichen Grüßen / Best regards Sandy Willer',
    createdAt: 1569064260,
  },
];

export const dummyDataForComments = {
  first: true,
  last: false,
  content: dummyMessages,
  number: 1,
  totalElements: 36,
};

export const images = [
  {
    kitImagesId: 9,
    size: "8.7 KB",
    preview: "https://zeissappzdladiag.blob.core.windows.net/zdla-local/kit-images%5C1561701316381citiesReport.pdf",
    type: "application/pdf",
    file: "citiesReport.pdf"
  }
];


export const questions = [
  {
    answers: [{
      createdBy: {
        accountId: 9514,
        email: "test@test.com",
        firstName: "test",
        lastName: "test",
        salutation: "m",
      },
      createdOn: "2020-09-22T11:13:12.000+0000",
      id: 14,
      text: "test 1 answer",
      updatedOn: "2020-09-22T11:13:12.000+0000",
    }],
    createdBy: {
      accountId: 9514,
      email: "test@test.com",
      firstName: "test",
      lastName: "test",
      salutation: "m",
    },
    createdOn: "2020-09-22T11:13:05.000+0000",
    id: 23,
    kitInformationId: 71,
    text: "test 1",
    updatedOn: "2020-09-22T11:13:05.000+0000",
  },
  {
    answers: [{
      createdBy: {
        accountId: 9514,
        email: "test@test.com",
        firstName: "test",
        lastName: "test",
        salutation: "m",
      },
      createdOn: "2020-09-22T11:13:12.000+0000",
      id: 14,
      text: "test 2 answer",
      updatedOn: "2020-09-22T11:13:12.000+0000",
    }],
    createdBy: {
      accountId: 9514,
      email: "test@test.com",
      firstName: "test",
      lastName: "test",
      salutation: "m",
    },
    createdOn: "2020-09-22T11:13:05.000+0000",
    id: 25,
    kitInformationId: 72,
    text: "test 2",
    updatedOn: "2020-09-22T11:13:05.000+0000",
  }
];

export const answers = [{
  id: 1,
  text: 'test',
  createdBy: {
    accountId: 9514,
    email: "test@test.com",
    firstName: "test",
    lastName: "test",
    salutation: "m",
  },
}];

export const transactionAttachments = [
  {
    file: 'test.png',
    fileName: 'test.png',
    transactionAttachmentsId: 1,
    preview: 'https://zdladata.blob.core.windows.net/zdladata-dev/kit-attachments%5C1634040913246test.png',
    size: '580 KB',
    type: 'image/png',
  },
];

export const kitDetailsReducer = {
  kitDetails: kitDetails,
  kitDetailsAttachments: [1,2],
  commentsData: {},
  damageLossReportData: {},
  currentTab: null,
  kitAttachments: [1],
  transactionAttachments: [],
};

export const kitDetailsReducerWithoutKitDetails = {
  kitDetailsAttachments: [],
  commentsData: {},
  damageLossReportData: {},
  currentTab: null,
  kitAttachments: [],
  transactionAttachments: [],
};

export const kitDetailsForAvailabilityNull = {
  kitName: 'system RC 121',
  description: 'Yo man',
  systemValue: 9.8,
  marketingMaterial: false,
  hideKit: true,
  informationTab: false,
  stockId: 0,
  storageLocation: null,
  businessUnit: 1,
  sapERP: null,
  systemClassId: 10,
  systemMainComponentId: 19,
  serialNumber: 'semmme',
  dateOfManufacture: '09/2019',
  countryId: 1,
  commodityCode: '',
  deliveryDays: 9,
  returnDeliveryDays: 10,
  reconditioningDeliveryDays: 2,
  availabilityFrom: '09/2019',
  availabilityTo: null,
  packageDimensions: '',
  conditionStatus: 0,
  comment: '',
  componentsTab: false,
  salesComponents: 'mdmfm',
  furtherImportantComponents: '',
  accessoriesTab: false,
  freeAccessories: '',
  deleteImageIds: null,
  deleteAttachmentIds: null,
  kitImages: [],
  kitAttachments: null,
  kitId: 12,
  iBase: 'MM0010',
  targetDeliveryDate: '2019-09-03',
  targetReservationFrom: '2019-09-08',
  targetReservationTo: '2019-09-16',
  targetReturnDeliveryDate: '2019-09-21',
  kitInformationId: 1,
  businessUnitList: [],
  kitStatuses: [{ kitStatusId: 11, kitStatusesTranslated: [{ translatedStatus: 'Not available' }] }],
};
