import { useState, useEffect, useCallback } from 'react';
import { fetchCountries } from './StockDashboard.actions';

export const useStockDashboardHook = ({ getInitialData, getStatisticsForThisMonth, fetchCountries, language }) => {
  const [counter, setCounter] = useState(0);
  const [activeTabName, setActiveTabName] = useState(1);
  const [isOpenFullScreen, setIsOpenFullScreen] = useState(false);
  const [isShowFilter, setIsShowFilter] = useState(true);
  const [filters, setFilters] = useState({
    businessGroup: null,
    businessGroupFullValue: null,
    country: null,
    countryFullValue: null,
    stock: null,
    stockFullValue: null,
  });

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  useEffect(() => {
    fetchCountries();
  }, [language, fetchCountries]);


  const updateMonthStatistics = useCallback(() => {
    if (filters.businessGroup && filters.country) {
      getStatisticsForThisMonth({
        businessUnitId: filters.businessGroup,
        countryId: filters.country,
        stockIds: filters.stock,
        type: 'week',
      });
    }
  }, [filters, getStatisticsForThisMonth]);

  useEffect(() => {
    updateMonthStatistics();
  }, [updateMonthStatistics]);

  const updateActiveTabName = useCallback(() => {
    setActiveTabName(prevValue => prevValue === 1 ? 2 : 1);
  }, []);

  const updateCounter = useCallback((prevValue) => {
    const newValue = prevValue + 1;

    if (newValue >= 10) {
      updateActiveTabName();
      updateMonthStatistics();
      return 0;
    }
    else return newValue;
  }, [updateActiveTabName, updateMonthStatistics]);

  const setTime = useCallback(() => {
    setCounter(updateCounter);
  }, [setCounter, updateCounter]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime();
    }, 1000 * 60);

    return () => {
      clearInterval(timer);
    }
  }, [setTime]);

  const showOrHideFilter = useCallback(() => {
    setIsShowFilter(prevState => !prevState);
  }, []);

  const onOpenFullScree = useCallback(() => {
    document.body.setAttribute('is-open-modal', true);
    setIsOpenFullScreen(true);
  }, []);

  const onCloseFullScreen = useCallback(() => {
    document.body.removeAttribute('is-open-modal');
    setIsOpenFullScreen(false);
  }, []);

  return {
    isShowFilter,
    filters,
    activeTabName,
    counter,
    updateActiveTabName,
    setFilters,
    showOrHideFilter,
    isOpenFullScreen,
    onOpenFullScree,
    onCloseFullScreen,
  };
}
