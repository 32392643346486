import Validators from 'library/utilities/Validators';

export const addAddressConfigModel = [
  {
    label: 'businessUnit',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'businessUnitId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: '' }],
    excludeData: [],
  },
  {
    label: 'country',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'countryId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: '' }],
    excludeData: [],
  },
];
