import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { createServiceTicket } from './CreateServiceTicketPopup.action';
import CreateServiceTicketPopup from './CreateServiceTicketPopup.component';

const mapStateToProps = ({ kitsListReducer }) => ({
  problemsList: kitsListReducer.problemsList,
});

export default withRouter(connect(mapStateToProps, { createServiceTicket })(CreateServiceTicketPopup));
