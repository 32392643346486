import {connect} from "react-redux";

import Tabs from "../Tabs";

const mapStateToProps = state => {
  return {
    language: state.languageReducer
  }
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(Tabs);
