import React from 'react';
import toArray from 'lodash/toArray';

import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import ItemsPerPage from 'library/common/commonComponents/ItemsPerPage/ItemsPerPage';
import Pagination from 'library/common/commonComponents/Pagination/Pagination';
import Table from 'library/common/commonComponents/Table/Table';
import strings from 'resources/locales/Translate';

import './StatusList.styles.scss';
import { statusListBreadcrumbData, itemsPerPage } from './StatusList.constants';
import { useStatusList } from './StatusList.hook';

const StatusList = ({ user, fetchStatusListData, statusList, match }) => {
  const {
    isNoEmptyStatusList,
    state,
    tableCols,
    handleItemsPerPage,
    handlePageChange,
  } = useStatusList({ user, fetchStatusListData, statusList, match });

  return (
    <div className='status-list-container container-fluid'>
      <Breadcrumbs data={statusListBreadcrumbData}/>
      <h1 className='mb-3'>{strings.status}</h1>

      {isNoEmptyStatusList ? (
        <div className='mb-3'>
          <Table
            user={user}
            cols={tableCols}
            rows={statusList.content}
          />
        </div>
      ) : (
        <div className='d-flex justify-content-center'>
          <strong>{strings.noDataFoundText}</strong>
        </div>
      )}
      {isNoEmptyStatusList ? (
        <div className='d-flex justify-content-end mb-3'>
          <ItemsPerPage
            itemsPerPage={toArray(itemsPerPage)}
            handleItemsPerPage={handleItemsPerPage}
            value={itemsPerPage[state.size]}
          />
          <Pagination
            currentPage={state.page - 1}
            totalPages={statusList.totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      ) : null}
    </div>
  );
};

export default StatusList;
