import { useState, useEffect } from 'react';
import $ from 'jquery';

import strings from 'resources/locales/Translate';
import { validateForm } from 'library/utilities/ValidateForm.util';

import { exportModel } from './ExportTable.constants';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { FETCH_COUNTRY_LIST } from 'library/common/commonConstants/storeConstants';

export function useExportTable({
  categoriesAndSystems,
  countries,
  singleItemsOptions,
  id,
  filters,
  exportShopList,
  marketplaceCountry,
  fetchMasterDataPost
}) {
  const [loading, setLoading] = useState(false);
  const [exportTableForm, setExportTableForm] = useState({ formData: {}, isFormValid: false });
  const [error, setError] = useState('');

  useEffect(() => {
    if (categoriesAndSystems && categoriesAndSystems.length) {
      setExportTableForm(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          category: filters.category,
          categoryFullValue: filters.categoryFullValue,
          categoryError: null,
        },
      }));
    }
  }, [categoriesAndSystems, filters.category, filters.categoryFullValue]);

  useEffect(() => {
    if (singleItemsOptions && singleItemsOptions.length) {
      setExportTableForm(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          singleItems: filters.singleItems,
          singleItemsFullValue: filters.singleItemsFullValue,
          singleItemsError: null,
        },
      }));
    }
  }, [singleItemsOptions, filters.singleItemsOptions, filters.singleItemsFullValue, filters.singleItems]);

  const onCloseClick = () => {
    const popup = $(`#${id}`);
    if (popup && popup.modal) {
      popup.modal('hide');
    }
    setError('');
    setExportTableForm({
      ...exportTableForm,
      formData: {
        stock: null,
        stockFullValue: null,
        stockError: null,
        category: filters.category,
        categoryFullValue: filters.categoryFullValue,
        categoryError: null,
        singleItems: filters.singleItems,
        singleItemsFullValue: filters.singleItemsFullValue,
        singleItemsError: null,
      },
    });
  };

  const onSaveClick = async () => {
    setError('');

    const validForm = validateForm({
      form: exportTableForm,
      model: exportModel({ categoriesAndSystems, countries, singleItemsOptions, marketplaceCountry }),
    });
    if (!validForm.isFormValid) {
      return setExportTableForm(validForm);
    }

    setLoading(true);
    const { success } = await exportShopList(validForm.formData);
    setLoading(false);

    if (!success) {
      setError(strings.somethingWentWrongMessage);
    } else {
      onCloseClick();
    }
  };

  const handleForm = ({ values, field }) => {
    if (field === 'stock' && values.stock && values.stock.length > 20) {
      setExportTableForm({
        ...exportTableForm,
        formData: {
          ...exportTableForm.formData,
          ...values,
          stockError: 'maxLengthErrorMessage',
        },
      });
    } else {
    if(field === 'country'){
            fetchMasterDataPost(URLS.getStocksBasedOnCountries, FETCH_COUNTRY_LIST, values.country)
          }
      setExportTableForm({
        ...exportTableForm,
        formData: {
          ...exportTableForm.formData,
          ...values,
          stockError: false,
        },
      });
    }
  };

const checkForDisableButton = () => {
  if (exportTableForm && exportTableForm.formData) {
    if (exportTableForm.formData.stock && exportTableForm.formData.stock.length > 0) {
      return false;
    } else if (exportTableForm.formData.country && exportTableForm.formData.country.length > 0) {
      return false;
    } else if (
      (exportTableForm.formData.category === null ||
        (exportTableForm.formData.category && exportTableForm.formData.category.length === 0)) &&
      exportTableForm.formData.singleItems === null
    ) {
      return true;
    }
  }
};
  return {
    loading,
    error,
    exportTableForm,
    onSaveClick,
    handleForm,
    onCloseClick,
    checkForDisableButton,
  };
};
