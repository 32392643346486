import { useEffect, useCallback } from 'react';
import get from 'lodash/get';

import strings from 'resources/locales/Translate';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import {
  FETCH_CONDITIONS,
  FETCH_KIT_STATUS,
  FETCH_BUSINESS_UNIT_LIST,
  FETCH_SELL_OFF_CURRENCY,
  FETCH_SELL_OFF_COUNTRY_LIST,
} from 'library/common/commonConstants/storeConstants';
import { checkStockRoles } from 'library/utilities/checkRoles';

import {
  generalInformationKitForSaleModel,
  generalInformationSingleItemModel,
  FETCH_CUSTOMERS,
} from './CreateKitForSale.constants';

function getCurrentTab(tab, isSingleItem) {
  switch(tab) {
    case 'information':
      return 1;
    case 'components':
      return 2;
    case 'accessories':
      return 3;
    case 'salesDetails': 
      return isSingleItem ? 3 : 5;
    default:
      return 1;
  }
};

export const useCreateKitForSale = ({
  user,
  history,
  match,
  fetchMasterData,
  resetCreateKit,
  updateKitForSaleForm,
  formValue,
  sellOffCurrency,
  saveCreateKitManually,
  saveCreateSingleItem,
  fetchKitDetail,
  kitDetails,
  language,
}) => {
  let isCopyKit = match.path.includes('copy-manually-kit') || match.path.includes('copy-single-item');
  let isKitForSale = match.path.includes('kit-manually') || match.path.includes('manually-kit');
  let isSingleItemForSale = match.path.includes('single-item');
  const isEditView = !!match.params.id;
  document.title = 'Create Kit';
  if (isEditView && !isCopyKit) {
    document.title = 'Maintain Kit';
  } else if (isCopyKit) {
    document.title = 'Copy Kit';
  }
  const { generalInformationKitForSaleForm, kitImages, kitImagesToDelete } = formValue;
  const isOrdered = get(kitDetails, 'kitCondition.kitConditionId') === 11;
  const isSold = get(kitDetails, 'kitCondition.kitConditionId') === 4;
  const roles = ['Shop Manager', 'Stock Controller'];
  const stock = get(kitDetails, 'stockInformation');
  const isHavePermission = (user && user.admin) || (kitDetails && checkStockRoles(roles, user, stock));
  const isShowSalesDetailsTab = (isOrdered || isSold) && isHavePermission;
  const { tab } = history.location.state || {};
  const currentTab = getCurrentTab(tab || '', isSingleItemForSale);

  const getInitialData = () => {
    if (isCopyKit || isEditView) {
      fetchKitDetail(match.params.id, isCopyKit, isSingleItemForSale, user);
    }
    fetchMasterData(URLS.sellOffCurrency, FETCH_SELL_OFF_CURRENCY);
    fetchMasterData(URLS.sellOffCountry, FETCH_SELL_OFF_COUNTRY_LIST);
    fetchMasterData(URLS.kitStatus, FETCH_KIT_STATUS);
    fetchMasterData(URLS.kitCondition, FETCH_CONDITIONS);
    fetchMasterData(URLS.businessUnitList, FETCH_BUSINESS_UNIT_LIST);
    fetchMasterData(URLS.userList, FETCH_CUSTOMERS);
  };

  useEffect(() => {
    getInitialData();

    return () => resetCreateKit();
  }, [match.params.id, resetCreateKit]);

  useEffect(() => {
    fetchMasterData(URLS.sellOffCountry, FETCH_SELL_OFF_COUNTRY_LIST);
  },[language]);

  const handleForm = useCallback(({ values, formName }) => {
    updateKitForSaleForm({
      [formName]: {
        ...formValue[formName],
        formData: {
          ...((formValue[formName] && formValue[formName].formData) || {}),
          ...values,
        },
      },
    });
  }, [updateKitForSaleForm, formValue]);

  const addKitImageFile = useCallback(files => {
    const newImages = [...kitImages, ...files];
    updateKitForSaleForm({ kitImages: newImages });
  }, [kitImages, updateKitForSaleForm]);
  
  const removeKitImageFile = useCallback(index => {
    const fileToRemove = kitImages[index];
    let newKitImagesToDelete = [...kitImagesToDelete];
    const newKitImages = kitImages.filter((_, i) => i !== index);
  
    if (fileToRemove.kitImagesId) {
      newKitImagesToDelete.push(fileToRemove.kitImagesId);
    }
  
    updateKitForSaleForm({
      kitImages: newKitImages,
      kitImagesToDelete: newKitImagesToDelete,
    });
  }, [kitImages, updateKitForSaleForm, kitImagesToDelete]);

  const getTitle = useCallback(() => {
    if (isEditView && !isCopyKit) {
      return strings.maintainKits;
    } else if (isCopyKit) {
      return strings.copyKit;
    } else if (isKitForSale) {
      return strings.addKitForSale;
    } else if (isSingleItemForSale) {
      return strings.createSingleItem
    }
  }, [isEditView, isCopyKit, isKitForSale, isSingleItemForSale]);

  const getGeneralInformationKitForSaleModel = useCallback(() => {
    let newGeneralInformationKitForSaleModel = [...generalInformationKitForSaleModel];
    newGeneralInformationKitForSaleModel[4].options = sellOffCurrency;

    return newGeneralInformationKitForSaleModel;
  }, [sellOffCurrency]);

  const getGeneralInformationSingleItemModel = useCallback(() => {
    let newGeneralInformationSingleItemModel = [...generalInformationSingleItemModel];
    newGeneralInformationSingleItemModel[3].options = sellOffCurrency;

    return newGeneralInformationSingleItemModel;
  }, [sellOffCurrency]);

  const getModel = useCallback(() => {
    if (isKitForSale) {
      return getGeneralInformationKitForSaleModel();
    } else if (isSingleItemForSale) {
      return getGeneralInformationSingleItemModel();
    }
  }, [isKitForSale, isSingleItemForSale, getGeneralInformationKitForSaleModel, getGeneralInformationSingleItemModel]);

  const saveKit = useCallback(async () => {
    const request = isSingleItemForSale ? saveCreateSingleItem : saveCreateKitManually;
    const { success, kitInformation } = await request();
    if (success) {
      const url = isSingleItemForSale ? `/single-item-details/${kitInformation}` : `/kit-manually-details/${kitInformation}`;
      history.replace(url);
    }
  }, [isSingleItemForSale, history, saveCreateSingleItem, saveCreateKitManually]);

  const cancelCreateKit = () => {
    history.replace('/demo-sale');
  };

  return {
    handleForm,
    addKitImageFile,
    removeKitImageFile,
    getTitle,
    getModel,
    isEditView,
    generalInformationKitForSaleForm,
    kitImages,
    isCopyKit,
    isSingleItemForSale,
    saveKit,
    cancelCreateKit,
    isShowSalesDetailsTab,
    currentTab,
  };
};
