import React from 'react';

import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import Button from 'library/common/commonComponents/Button/Button';
import Tabs from 'library/common/commonComponents/Tabs/Tabs';
import strings from 'resources/locales/Translate';

import DemoActivityTab from './Components/DemoActivityTab';
import DelayedTransactionsTab from './Components/DelayedTransactionsTab';
import KitDataTab from './Components/KitDataTab';
import { statisticsBreadcrumbData } from './Statistics.constants';
import { useStatistics } from './Statistics.hook';
import './statistics.styles.scss';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import DmaReporting from './Components/DmaReporting/DmaReporting.component';

const Statistics = ({ getInitialData, language, getDataOnLanguageChange, appActions, user, fetchDmaReportingData, dmaReporting, fetchSixMonthTrafficData, sixMonthsTrafficData }) => {
  const { isShowFilter, loanTypes, showOrHideFilter } = useStatistics({ getInitialData, language, getDataOnLanguageChange });
  const { displayActionMessage, type, message, showAsIs } = appActions;
  const tabs = [
    {
      id: 1,
      tabTitle: 'demoActivity',
      className: '',
      render: isActive => {
        return (
          <DemoActivityTab isActiveTab={isActive} loanTypes={loanTypes} isShowFilter={isShowFilter} />
        );
      },
    },
    {
      id: 2,
      tabTitle: 'lateReturns',
      className: '',
      render: isActive => {
        return (
          <DelayedTransactionsTab isActiveTab={isActive} loanTypes={loanTypes} isShowFilter={isShowFilter} />
        );
      },
    },
    {
      id: 3,
      tabTitle: 'kitData',
      className: '',
      render: isActive => {
        return (
          <KitDataTab isActiveTab={isActive} loanTypes={loanTypes} isShowFilter={isShowFilter} />
        );
      },
    },
  ];

  if (user?.admin) {
    tabs.push({
      id:4,
      tabTitle: 'dmaReporting',
      className: '',
      render: isActive => {
        return (
          <DmaReporting isActiveTab={isActive} isShowFilter={isShowFilter} fetchDmaReportingData={fetchDmaReportingData} dmaReporting={dmaReporting} fetchSixMonthTrafficData={fetchSixMonthTrafficData} sixMonthsTrafficData={sixMonthsTrafficData}/>
        );
      },
    })
  }

  return (
    <div className='statistics-container'>
      {displayActionMessage && <ActionMessage type={type} message={showAsIs ? message : (strings[message] || message)} />}
      <div className='container-fluid mb-3'>
        <Breadcrumbs data={statisticsBreadcrumbData} />
        <div className='d-flex justify-content-between'>
          <h1 className='kit-title'>{strings.statistics}</h1>
          <Button
            dataTest='statistics-filter-button'
            bgFill={false}
            iconName='filter'
            onClick={showOrHideFilter}
            value={isShowFilter ? strings.hideFilter : strings.showFilter}
            styleClass='w-auto'
          />
          </div>
      </div>
      <Tabs tabs={tabs} />
    </div>
  );
}

export default Statistics;
