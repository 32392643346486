import React, {Component} from 'react';
import { connect } from "react-redux";
import {isEqual} from 'lodash';

import strings from 'resources/locales/Translate';

import {FormComponent} from "library/common/commonComponents/FormComponent/FormComponent";
import Button from "library/common/commonComponents/Button/Button";
import Icon from "library/common/commonComponents/Icon/Icon";
import {scrollToTop} from "library/utilities/scrollActions";
import $ from 'jquery'

import { requestAccessPermission, setDropdownValues, isLabsLocationStock} from "./RequestStockPermissionActions";
import './requestStockPermissionStyles.scss';

export class RequestStockPermission extends Component {

  state = {
    stockPermissionForm: null,
    clear: false,
    isError: false,
    errorMessage: ''
  };

  componentDidMount() {
    this.props.setDropdownValues();
  }

  componentDidUpdate(prevProps) {
    const {masterData} = this.props;

    if (
      !isEqual(masterData.rolesList, prevProps.masterData.rolesList)
      || !isEqual(masterData.countries, prevProps.masterData.countries)
      || !isEqual(masterData.activeAndNonDeletedStocks, prevProps.masterData.activeAndNonDeletedStocks)
      || !isEqual(this.props.value.stockId, prevProps.value.stockId)
    ) {
      this.props.setDropdownValues(this.props.value.stockId);
    }
  }

  handleChange = (formData) => {
    this.props.isLabsLocationStock(formData.formData.stockId);
    this.setState({stockPermissionForm: formData, clear: false, isError: false});
  };

  closeModal = () => {
    $('#requestPermissionModal').modal('hide');
  };

  handleSave = () => {
    const {requestAccessPermission} = this.props;
    if (this.state.stockPermissionForm.isFormValid) {
      requestAccessPermission(this.state.stockPermissionForm);
      this.setState({clear: true, stockPermissionForm: null, isError: false}, () => {
        scrollToTop(500);
        this.closeModal();
      });
    } else {
      this.setState({isError: true});
    }
  };

  render() {
    const {isError} = this.state;
    const {id, value} = this.props;
    const {requestStockForm} = this.props.requestStockPermissionReducer;

    return (
      <div
        className="modal fade request-permission-modal" id={id} tabIndex="-1" role="dialog"
        aria-labelledby="requestStockPermission"
        aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content rounded-0 ">
            <div className="modal-body p-0">
              <div className="content">
                <button type="button" className="close modal-close position-absolute"
                        data-dismiss="modal" aria-label="Close"
                        onClick={this.closeModal}
                >
                  <Icon name='cross' width={24} height={24}/>
                </button>

                <h3 className='modal-title'>{strings.requestStockPermission}</h3>

                <FormComponent
                  value={value}
                  model={requestStockForm}
                  clear={this.state.clear}
                  onChange={this.handleChange}/>

                {isError && <div className='error-message'>{strings.formIsInvalid}</div>}

                <div className="row mt-5">
                  <div className="col-md-4">
                    <Button iconName='cross' bgFill={false} value={strings.cancel} onClick={this.closeModal}/>
                  </div>
                  <div className="col-md-8">
                    <Button iconName='save' value={strings.save} onClick={this.handleSave}/>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    language: state.languageReducer,
    masterData: state.masterDataReducer,
    requestStockPermissionReducer: state.requestStockPermissionReducer
  }
};

export default connect(mapStateToProps, {
  setDropdownValues,
  requestAccessPermission,
  isLabsLocationStock,
})(RequestStockPermission);
