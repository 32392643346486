const ViewTypes = {
  Day: 0,
  Week: 1,
  Month: 2,
  Quarter: 3,
  Year: 4,
  Custom: 5,
  Custom1: 6,
  Custom2: 7,
  WeekDay: 8,
};

export default ViewTypes;
