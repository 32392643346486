import { FETCH_REFURBISHMENT_FEES_COUNTRY_LIST } from 'modules/Administration/RefurbishmentFees/RefurbishmenFess.constants';
import { REMOVE_AUTHENTICATION, SET_AUTHENTICATION, SET_AUTHENTICATION_LOADING } from '../commonConstants/storeConstants';

const initialState = {
  user: null,
  isLoggedIn: false,
  isAuthLoading: true,
  refurbishmentFessStockList: []
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTHENTICATION:
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload
      };
    case REMOVE_AUTHENTICATION:
      return {
        ...state,
        user: null,
        isLoggedIn: false
      };
    case SET_AUTHENTICATION_LOADING:
      return {
        ...state,
        isAuthLoading: action.payload,
      };
      case FETCH_REFURBISHMENT_FEES_COUNTRY_LIST:
      return {
        ...state,
        refurbishmentFessStockList: action.payload,
      };
    default:
      return state;
  }
};

export default AuthReducer;
