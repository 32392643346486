import { saveAs } from 'file-saver';

import { logNetworkError } from 'library/utilities/logError';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { changeLoader } from 'library/common/commonActions/AppActionsActions';
import { exportListRequest } from 'library/api/exportList.service';

export const exportTransactionList = buName => async () => {
  try {
    const response = await exportListRequest(URLS.exportTransactionGlobalDashboard(buName));
    if (response.data) {
      const newFile = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const dispositionHeader = response.headers['content-disposition'] && response.headers['content-disposition'].split('filename=');
      const filename = dispositionHeader[1] || 'Transaction data';
      saveAs(newFile, filename);
      return { success: true };
    }
  } catch (err) {
    logNetworkError(err);
    return { success: false };
  }
};

export const exportKitList = buName => async () => {
  try {
    const response = await exportListRequest(URLS.exportKitGlobalDashboard(buName));
    if (response.data) {
      const newFile = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const dispositionHeader = response.headers['content-disposition'] && response.headers['content-disposition'].split('filename=');
      const filename = dispositionHeader[1] || 'Kit data';
      saveAs(newFile, filename);
      return { success: true };
    }
  } catch (err) {
    logNetworkError(err);
    return { success: false };
  }
};

export const exportKitListWithCountry = (buName,countryName) => async (dispatch) => {
  try {
    dispatch(changeLoader(true));

    const response = await exportListRequest(URLS.exportKitGlobalDashboardWithCountry(buName,countryName));
    if (response.data) {
      const newFile = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const dispositionHeader = response.headers['content-disposition'] && response.headers['content-disposition'].split('filename=');
      const filename = dispositionHeader[1] || 'Kit data';
      saveAs(newFile, filename);
      dispatch(changeLoader(false));
      return { success: true };
    }
  } catch (err) {
    dispatch(changeLoader(false));
    logNetworkError(err);
    return { success: false };
  }
};

export const exportTransactionListWithCountry = (buName,countryName) => async (dispatch) => {
  try {
    dispatch(changeLoader(true));
    const response = await exportListRequest(URLS.exportTransactionsGlobalDashboardWithCountry(buName,countryName));
    if (response.data) {
      const newFile = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const dispositionHeader = response.headers['content-disposition'] && response.headers['content-disposition'].split('filename=');
      const filename = dispositionHeader[1] || 'Kit data';
      saveAs(newFile, filename);
      dispatch(changeLoader(false));
      return { success: true };
    }
  } catch (err) {
    dispatch(changeLoader(false));
    logNetworkError(err);
    return { success: false };
  }
};
