import React from "react";
import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import {
  customerBackgroundModel,
  customerDetilsModel,
  primaryPointOfContactModel,
} from './CustomerInformation.constants';
import { useCustomerInformation } from "./CustomerInformation.hook";

export const CustomerInformation = (props) =>{
  const {formValue} = props;
  const { customerDetilsForm, primaryPointOfContactForm, customerBackgroundForm  } = formValue;
  const { handleForm, customerBackModel, primaryContactModel, getCustomerDetailsModel } = useCustomerInformation(props);
  const isPointOfContactSelectedYes = customerDetilsForm.formData.customerPointOfContact === 'no'? true : false;
   return (
     <>
       <h2 className='mt-4 zmcc-title-heading'>{strings.customerDetails}</h2>
       <div className='p-2 section-border'>
         <FormComponent
           model={getCustomerDetailsModel()}
           formName='customerDetilsForm'
           formValue={customerDetilsForm.formData}
           onChange={handleForm}
         />
       </div>

       {isPointOfContactSelectedYes && 
       <>
       <h2 className='mt-4 zmcc-title-heading'>{strings.primaryPointOfContact}</h2>
       <div className='p-2 section-border'>
         <FormComponent
           // dataTest={'create-kit-form-component'}
           model={primaryContactModel()}
           formName='primaryPointOfContactForm'
           formValue={primaryPointOfContactForm.formData}
           onChange={handleForm}
         />
       </div></>}

       <h2 className='mt-4 zmcc-title-heading'>{strings.customerBackground}</h2>
       <div className='p-2 section-border'>
         <FormComponent
           // dataTest={'create-kit-form-component'}
           model={customerBackModel()}
           formName='customerBackgroundForm'
           formValue={customerBackgroundForm.formData}
           onChange={handleForm}
         />
       </div>
     </>
   ); 
}

export default CustomerInformation