import { useEffect } from 'react';

const useTitle = (title = 'Zeiss Demo Management App') => {
  useEffect(() => {
    let prevTitle = document.title;
    document.title = title;
    return () => {
      document.title = prevTitle;
    };
  }, [title]);
};

export default useTitle;
