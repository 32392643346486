import React from 'react';
import cn from 'classnames';

import Icon from 'library/common/commonComponents/Icon/Icon';

const DocumentsBreadcrumb = ({ foldersNavigation, setFoldersNavigation }) => {
  const handleClick = folder => {
    if (foldersNavigation.length !== 1) {
      const index = foldersNavigation.findIndex(item => item.id === folder.id);
      const newNavigation = foldersNavigation.slice(0, index + 1);
      setFoldersNavigation(newNavigation);
    }
  };

  return (
    <div className='breadcrumbs-container'>
      <ol className='breadcrumb bg-white p-0'>
        {foldersNavigation.map((item, key) => (
          <li
            key={key}
            className={cn('breadcrumb-item', { 'active': key === foldersNavigation.length - 1 })}
            aria-current='page'
            onClick={() => handleClick(item)}
          >
            {key !== 0 ?
              <span>{item.name}</span> : (
              <Icon
                name='home'
                width={24}
                height={24}
                fill={foldersNavigation.length === 1 ? '#9a9b9c' : '#0088d0'}
              />
            )}
          </li>
        ))}
      </ol>
    </div>
  );
}

export default DocumentsBreadcrumb;
