import React from 'react';
import { get } from 'lodash';

import Button from 'library/common/commonComponents/Button/Button';
import { getDateInFormat } from 'library/utilities/getDateInFormat';
import strings from 'resources/locales/Translate';

const InformationForSaleKit = ({ kitDetail, history, isEditable, editingKitURL }) => {
  const consumedQuantity = get(kitDetail, 'sellOffPlatformInformation.consumedQuantity') || 0;
  const initialQuantity = get(kitDetail, 'sellOffPlatformInformation.quantity') || 0;
  const remainingQuantity = initialQuantity - consumedQuantity;

  return (
    <div className='information-container'>
      <div className='container-fluid'>
        <div className='row mb-3'>
          <div className='col-12 col-md-6'>
            <div className='section-title'>{strings.information}</div>

            <div className='subtitle'>{strings.mainComponent}</div>
            <div className='row mb-3'>
              <div className='col-6'>{`${strings.stock}:`}</div>
              <div className='col-6'>
                {get(kitDetail, 'stockInformation.locationName', '-')}
              </div>
              <div className='col-6'>{`${strings.systemClassText}:`}</div>
              <div className='col-6'>
                {get(kitDetail, 'systemClass.systemClassName') || '-'}
              </div>
              <div className='col-6'>{`${strings.systemMainComponent}:`}</div>
              <div className='col-6'>
                {get(kitDetail, 'systemMainComponent.systemMainComponentName', '-')}
              </div>
              {!get(kitDetail, 'kitSingleItem') &&
                <>
                  <div className='col-6'>{`${strings.serialNumber}:`}</div>
                  <div className='col-6'>
                    {get(kitDetail, 'serialNumber') || '-'}
                  </div>
                </>
              }
              <div className='col-6'>{`${strings.loanOrder}:`}</div>
              <div className='col-6'>
                {get(kitDetail, 'sapERP', '-')}
              </div>
              <div className='col-6'>{`${strings.yearOfManufacture}:`}</div>
              <div className='col-6'>
                {kitDetail.dateOfManufacture ? getDateInFormat('YYYY', kitDetail.dateOfManufacture) : '-'}
              </div>
              <div className='col-6'>{`${strings.monthOfManufacture}:`}</div>
              <div className='col-6'>
                {kitDetail.dateOfManufacture ? getDateInFormat('MMMM', kitDetail.dateOfManufacture) : '-'}
              </div>
            </div>

            <div className='subtitle'>{strings.itemDetails}</div>
            <div className='row mb-3'>
              <div className='col-6'>{`${strings.itemName}:`}</div>
              <div className='col-6'>
                {get(kitDetail, 'kitName') || '-'}
              </div>
              <div className='col-6'>{`${strings.itemDescription}:`}</div>
              <div className='col-6'>
                {get(kitDetail, 'description') || '-'}
              </div>
              <div className='col-6'>{`${strings.condition}:`}</div>
              <div className='col-6'>
                {get(kitDetail, 'sellOffPlatformInformation.sellOffCondition.sellOffKitConditionTranslated[0].name') || '-'}
              </div>
              {get(kitDetail, 'kitSingleItem') &&
                <>
                  <div className='col-6'>{`${strings.components}:`}</div>
                  <div className='col-6'>
                    {get(kitDetail, 'salesComponents') || '-'}
                  </div>
                </>
              }
            </div>

            <div className='subtitle'>{strings.sellDetails}</div>
            <div className='row mb-3'>
              <div className='col-6'>{`${strings.storageLocation}:`}</div>
              <div className='col-6'>
                {get(kitDetail, 'storageLocation') || '-'}
              </div>
              <div className='col-6'>{`${strings.internalIdentifier}:`}</div>
              <div className='col-6'>
                {get(kitDetail, 'sellOffPlatformInformation.internalIdentifier') || '-'}
              </div>
              <div className='col-6'>{`${strings.visibility}:`}</div>
              <div className='col-6'>
                {get(kitDetail, 'sellOffPlatformInformation.sellOffVisibility.sellOffVisibilityTranslated[0].name') || '-'}
              </div>
              <div className='col-6'>{`${strings.salesDate}:`}</div>
              <div className='col-6'>
                {kitDetail.sellOffPlatformInformation && kitDetail.sellOffPlatformInformation.saleDate ? getDateInFormat('DD.MM.YYYY', kitDetail.sellOffPlatformInformation.saleDate) : '-'}
              </div>
              <div className='col-6'>{`${strings.deliveryTimeInWeeks}:`}</div>
              <div className='col-6'>
                {get(kitDetail, 'sellOffPlatformInformation.deliveryTimeInWeeks') || '-'}
              </div>
              <div className='col-6'>{`${strings.quantity}:`}</div>
              <div className='col-6'>
                {remainingQuantity || '-'}
              </div>
            </div>

            <div className='subtitle'>{strings.notes}</div>
            <div className='row mb-3'>
              <div className='col-6'>{`${strings.commentText}:`}</div>
              <div className='col-6'>
                {get(kitDetail, 'sellOffPlatformInformation.saleComment') || '-'}
              </div>
              <div className='col-6'>{`${strings.contactPerson}:`}</div>
              <div className='col-6'>
                {get(kitDetail, 'sellOffPlatformInformation.contactPerson') || '-'}
              </div>
            </div>
          </div>
        </div>
        {isEditable && (
          <Button
            data-test='edit-kit-button'
            bgFill={false}
            iconName='edit'
            value={strings.edit}
            styleClass='w-auto'
            onClick={() =>
              history.push({
                pathname: editingKitURL,
                state: { edit: true, tab: 'information' },
              })
            }
          />
        )}
      </div>
    </div>
  );
};

export default InformationForSaleKit;
