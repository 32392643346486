import * as actionTypes from 'library/common/commonConstants/storeConstants';

const initialState = {
  kitsList: null,
  problemsList: [],
  labsLocationSystemClasses: [],
};

const KitsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_KITS_LIST:
      return {
        ...state,
        kitsList: action.payload
      };
     case actionTypes.FETCH_PROBLEM_LIST:
      return {
        ...state,
        problemsList: action.payload
      };
      case actionTypes.FETCH_LABS_LOCATION_SYSTEM_CLASSES:
      return {
        ...state,
        labsLocationSystemClasses: action.payload
      };
    default:
      return state;
  }
};

export default KitsListReducer;
