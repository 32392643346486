import strings from 'resources/locales/Translate';
import React from 'react';
import Modal from 'library/common/commonComponents/Modal';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import { connect } from 'react-redux';
import { useRequestPickUpPopup } from './RequestPickupPopup.hook';
export const RequestPickupPopup = props => {
  const { id } = props;
  const { model, handleForm, form, onsave, onCloseClick,isLoading, linkToTransactionText } = useRequestPickUpPopup(props);
  const emailText = form.formData.emailBody && form.formData.emailBody.replaceAll('**', '<b>').replaceAll('*', '</b>').replaceAll('\n', '<br>').replaceAll('###','<a href=').replaceAll('##',`>${linkToTransactionText}</a>`);
  
  return (
    <Modal
      id={id}
      title={strings.requestPickup}
      confirmAction={onsave}
      confirmTitle={strings.save}
      titleOfCancel={strings.cancel}
        loading={isLoading}
      disableBackgroundClose
      onCloseClick={onCloseClick}
      className='modal-width-for-pickRequest'
    >
      <FormComponent model={model} formName='requestPickForm' formValue={form.formData} onChange={handleForm} />
      <p class="dropdown-label">Preview :</p>
      <div dangerouslySetInnerHTML={{ __html: emailText }} style={{ height: "150px", overflow: "auto" }} />

    </Modal>
  );
};

const mapStateToProps = ({ languageReducer }) => ({
  language: languageReducer,
});

export default connect(mapStateToProps, {
})(RequestPickupPopup);
