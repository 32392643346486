import Modal from 'library/common/commonComponents/Modal';
import React, { useState } from 'react';
import strings from 'resources/locales/Translate';
import moment from 'moment';
import OperatorsSchedules from './OperatorsSchedules';
import './index.styles.scss'
import $ from 'jquery'

const MODAL_ID = 'operators-availability-schedule-modal';

function OperatorsModal({
  id = MODAL_ID,
  onOpen = () => {},
  transactionDetails = {},
  stockOperators = [],
  fetchOperatorScheduleOfStockOnGivenDate,
  changeLoader,
  formType,
  getDataToSendFromInformationTab,
  onClickEdit,
  editing
}) {

  const [resetStateFunc, setResetStateFunc] = useState(null);

  const handleCloseModal = () => {
    if(resetStateFunc != null && typeof resetStateFunc === "function"){
      resetStateFunc();
    }
    $('#operators-availability-schedule-modal').modal('hide');
  }

  return (
    <Modal
      id={MODAL_ID}
      onCloseClick={handleCloseModal}
      title={strings.assignOperator}
      confirmTitle={strings.assignOperator}
      className={'modal-lg custom-modal-width'}
      buttonStyles={'hide'}
      titleOfCancel={strings.cancel}
    >
      <OperatorsSchedules
        fetchOperatorScheduleOfStockOnGivenDate={fetchOperatorScheduleOfStockOnGivenDate}
        trasanctionDetails={transactionDetails}
        zmccOperators={stockOperators}
        changeLoader={changeLoader}
        formType={formType}
        getDataToSendFromInformationTab={getDataToSendFromInformationTab}
        onClickEdit={onClickEdit}
        editing={editing}
        handleCloseModal={setResetStateFunc}
      ></OperatorsSchedules>
    </Modal>
  );
}

export default React.memo(OperatorsModal);
