import React from 'react';

import Components from 'library/common/commonComponents/ComponentsForm';
import Accessories from 'library/common/commonComponents/AccessoriesForm';
import Attachments from 'library/common/commonComponents/AttachmentsForm';

import SalesDetails from './Components/SalesDetails';
import Information from './Components/Information';

export const createKitForSaleTabs = ({
  user,
  history,
  isCopyKit,
  kitDetails,
  isSingleItemForSale,
  formValue,
  saveKit,
  cancelCreateKit,
  handleForm,
  kitInformationId,
  updateKitForSaleForm,
  isShowSalesDetailsTab,
}) => {
  const singleItemTabs = [
    {
      id: 1,
      tabTitle: 'information',
      className: '',
      render: isActive => (
        <Information
          isActive={isActive}
          history={history}
          isCopyKit={isCopyKit}
          kitDetails={kitDetails}
          isSingleItemForSale={isSingleItemForSale}
        />
      ),
    },
    {
      id: 2,
      tabTitle: 'attachments',
      className: '',
      render: () => (
        <Attachments
          isCopyKit={isCopyKit}
          kitDetails={kitDetails}
          formValue={formValue}
          saveKit={saveKit}
          cancelCreateKit={cancelCreateKit}
          handleForm={updateKitForSaleForm}
          kitInformationId={kitInformationId}
        />
      ),
    },
  ];
  const kitForSaleTabs = [
    {
      id: 1,
      tabTitle: 'information',
      className: '',
      render: isActive => (
        <Information
          isActive={isActive}
          history={history}
          isCopyKit={isCopyKit}
          kitDetails={kitDetails}
          isSingleItemForSale={isSingleItemForSale}
        />
      ),
    },
    {
      id: 2,
      tabTitle: 'components',
      className: '',
      render: isActive => (
        <Components
          isActive={isActive}
          kitDetails={kitDetails}
          formValue={formValue}
          saveKit={saveKit}
          cancelCreateKit={cancelCreateKit}
          handleForm={handleForm}
        />
      ),
    },
    {
      id: 3,
      tabTitle: 'accessories',
      className: '',
      render: isActive => (
        <Accessories
          isActive={isActive}
          kitDetails={kitDetails}
          formValue={formValue}
          saveKit={saveKit}
          cancelCreateKit={cancelCreateKit}
          handleForm={handleForm}
        />
      ),
    },
    {
      id: 4,
      tabTitle: 'attachments',
      className: '',
      render: () => (
        <Attachments
          isCopyKit={isCopyKit}
          kitDetails={kitDetails}
          formValue={formValue}
          saveKit={saveKit}
          cancelCreateKit={cancelCreateKit}
          handleForm={updateKitForSaleForm}
          kitInformationId={kitInformationId}
        />
      ),
    },
  ];

  if (isSingleItemForSale) {
    if (!isCopyKit && isShowSalesDetailsTab) {
      singleItemTabs.push({
        id: 3,
        tabTitle: 'salesDetails',
        className: '',
        render: isActive => (
          <SalesDetails
            isActive={isActive}
            isCopyKit={isCopyKit}
            kitDetails={kitDetails}
            formValue={formValue}
            saveKit={saveKit}
            cancelCreateKit={cancelCreateKit}
            handleForm={handleForm}
            kitInformationId={kitInformationId}
          />
        ),
      });
    }

    return singleItemTabs;
  } else {
    if (!isCopyKit && isShowSalesDetailsTab) {
      kitForSaleTabs.push({
        id: 5,
        tabTitle: 'salesDetails',
        className: '',
        render: isActive => (
          <SalesDetails
            isActive={isActive}
            isCopyKit={isCopyKit}
            kitDetails={kitDetails}
            formValue={formValue}
            saveKit={saveKit}
            cancelCreateKit={cancelCreateKit}
            handleForm={handleForm}
            kitInformationId={kitInformationId}
          />
        ),
      });
    }

    return kitForSaleTabs;
  }
};
