import {URLS} from "library/common/commonConstants/ApiUrlConstants";
import zeissInstance from "library/utilities/AxiosInstance";


export async function uploadDocumentsServices(id, files) {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  return await zeissInstance.post(`${URLS.uploadDocument}?folderId=${id || ''}`, files, config);
}

export async function deleteDocumentService(id) {
  return await zeissInstance.delete(URLS.deleteDocument.replace('{id}', id));
}
