import React from 'react';
import cn from 'classnames';

import './table.styles.scss';

const Table = ({ className, rowId, rows, cols, sortFields, handleSort, handleDelete, childTable }) => {
  return (
    <div className={`table-responsive custom-table${childTable ? ' child-table' : ''}${className ? ' ' + className : ''}`}>
      <table className='table table-borderless'>
        <thead>
          <tr>
            {cols.map(col => (
              <th
                key={col.name}
                data-test={col.isSort ? 'table-header-column-sort' : 'table-header-column'}
                onClick={col.isSort ? handleSort.bind(this, col.name) : null}
              >
                <div className='d-flex'>
                  <span className='mr-1'>{col.title}</span>
                  {col.isSort ? (
                    <div>
                      <div
                        className={cn('triangle-up', {
                          'active-triangle-up': sortFields[col.name] === 'asc',
                        })}
                      />
                      <div
                        className={cn('triangle-down', {
                          'active-triangle-down': sortFields[col.name] === 'desc',
                        })}
                      />
                    </div>
                  ) : null}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map(row => (
            <tr key={rowId ? row[rowId] : row.id}>
              {cols.map(col => (
                <td key={col.name}>{col.render(row, handleDelete)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
