import Validators from 'library/utilities/Validators';

export const adjustDatesFormModel = [
  {
    dateFormat: 'DD.MM.YYYY',
    field: 'targetReservationFrom',
    label: 'demoStart',
    placeholder: '',
    required: true,
    type: 'date',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    value: '',
    styleClass: 'col-6',
  },
  {
    dataTest: 'adjust-dates-targetReservationFrom',
    field: 'targetReservationTo',
    label: 'demoEnd',
    placeholder: '',
    required: true,
    type: 'date',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    value: '',
    styleClass: 'col-6',
  },
];
