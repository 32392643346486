export const getAllStockIds = (countries = []) => {
  const stockIds = [];
  countries.forEach(country => {
    if (country.stockInformation) {
      country.stockInformation.forEach(stock => {
        if (stock.id) {
          stockIds.push(stock.id);
        }
      })
    }
  });

  return stockIds;
};
