import { useCallback } from 'react';

import { getDateInFormat } from 'library/utilities/getDateInFormat';

export const useDemoActivityAndKitDataFilter = ({ filters, setFilters }) => {
  const handleDateCange = dateRange => {
    let newDateRange = null;

    if (dateRange) {
      newDateRange = [...dateRange];
      const dateRangeStart = dateRange[0] ? `${getDateInFormat('YYYY-MM-DD', dateRange[0])} 00:00:00` : null;
      const dateRangeEnd = dateRange[1] ? `${getDateInFormat('YYYY-MM-DD', dateRange[1])} 00:00:00` : null;
      newDateRange[0] = dateRangeStart;
      newDateRange[1] = dateRangeEnd;
    }

    setFilters({ ...filters, dateRange });
  };

  const handleFilter = useCallback((id, field, value) => {
    const idValue = Array.isArray(id) ? [...id] : id;
    const newFilters = {
      ...filters,
      [field]: idValue,
      [`${field}FullValue`]: value,
    }
    setFilters(newFilters);
  } , [filters, setFilters]);

  return {
    handleDateCange,
    handleFilter,
  };
}
