import { useCallback } from 'react';

import { ceruleanColor, osloGrayColor } from 'resources/styles/variables.scss';

export const useSatisfactionIndexChart = ({ satisfactionIndex }) => {
  const getDataForChart = useCallback(() => {
    if (satisfactionIndex) {
      const data = {
        datasets: [
          {
            data: [(100-satisfactionIndex), satisfactionIndex],
            backgroundColor: [osloGrayColor, ceruleanColor],
            hoverBackgroundColor: [osloGrayColor, ceruleanColor],
            order: 2,
          },
        ],
      };
  
      return data;
    }

    const defaultData = {
      datasets: [
        {
          data: [10],
          backgroundColor: [osloGrayColor],
          hoverBackgroundColor: [osloGrayColor],
          order: 1,
        },
      ],
    }

    return defaultData;
  }, [satisfactionIndex]);

  const data = getDataForChart();
  const options = { cutoutPercentage: 70 };

  return {
    data,
    options,
    getDataForChart,
  };
}
