import React from 'react';

import strings from 'resources/locales/Translate';

const DemosStatisticBlock = ({ demosStatistic }) => {
  const {
    demoPerformed,
    incomingDemoBooking,
    demoWithOppId,
    oppDemoWinRate,
    demoReturnedInTime,
    demoShippedInTime,
    meanTimeToDemo
  } = demosStatistic || {};

  return (
    <div className='container-fluid demos-statistic-wrapper'>
      <div className='row'>
        <div className='col-2 custom-width'>
          <p className='statistics-text'>{demoPerformed || 0}</p>
          <p className='subtitle'>{strings.demoPerformed}</p>
        </div>
        <div className='col-2 custom-width'>
          <p className='statistics-text'>{incomingDemoBooking || 0}</p>
          <p className='subtitle'>{strings.incomingDemoBooking}</p>
        </div>
        <div className='col-2 custom-width'>
          <p className='statistics-text'>{demoWithOppId || 0}</p>
          <p className='subtitle'>{strings.demoWithOppId}</p>
        </div>
        <div className='col-2 custom-width'>
          <p className='statistics-text'>{oppDemoWinRate || 0} %</p>
          <p className='subtitle'>{strings.oppDemoWinRate}</p>
        </div>
        <div className='col-2 custom-width'>
          <p className='statistics-text'>{demoReturnedInTime || 0} %</p>
          <p className='subtitle'>{strings.demoReturnedInTime}</p>
        </div>
        <div className='col-2 custom-width'>
          <p className='statistics-text'>{demoShippedInTime || 0} %</p>
          <p className='subtitle'>{strings.demoShippedInTime}</p>
        </div>
        <div className='col-2 custom-width'>
          <p className='statistics-text'>{meanTimeToDemo || 0} Days</p>
          <p className='subtitle'>{strings.meantimeToDemo}</p>
        </div>
      </div>
    </div>
  );
}

export default DemosStatisticBlock;
