import React from 'react';

import strings from 'resources/locales/Translate';
import Modal from 'library/common/commonComponents/Modal';

const SaveChangesPopup= ({ id, handleSavePermissionForStock, handleCancelButton, loading, updatePage }) => {
  const onConfirm = () => {
    handleSavePermissionForStock();
    updatePage();
  };

  return (
    <Modal
      id={id}
      title={strings.pleaseSaveYourChanges}
      confirmAction={onConfirm}
      titleOfCancel={strings.no}
      confirmTitle={strings.yes}
      loading={loading}
      onCloseClick={handleCancelButton}
      disableBackgroundClose
      buttonStyles='w-100'
    />
  );
};

export default SaveChangesPopup;
