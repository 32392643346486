import Validators from 'library/utilities/Validators';
import strings from 'resources/locales/Translate';

export const ratingModelDropDowns = [
  {
    label: 'onTimeDelivery',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'onTimeDelivery',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-4',
    options: [      
      {
        id: 'yes',
        value: strings.yes
      },
      {
        id: 'no',
        value: strings.no
      }],
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'value', separator: ' ' }],
  },
  {
    label: 'orderCompleteness',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'orderCompleteness',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-4',
    options: [      
      {
        id: 'yes',
        value: strings.yes
      },
      {
        id: 'no',
        value: strings.no
      }],
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'value', separator: ' ' }],
  },
  {
    label: 'equipmentFunctionality',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'equipmentFunctionality',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-4',
    options: [      
      {
        id: 'yes',
        value: strings.yes
      },
      {
        id: 'no',
        value: strings.no
      }],
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'value', separator: ' ' }],
  },
  {
    label: 'commentText',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'comment',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-12',
  },
];
