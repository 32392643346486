import { batchActions } from 'redux-batched-actions';

import strings from 'resources/locales/Translate';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import {
  fetchMasterDataServicePost,
  fetchMasterDataServicePut,
  fetchMasterDataService,
} from 'library/api/master-data.service';
import { changeLoader } from 'library/common/commonActions/AppActionsActions';
import { FETCH_CATEGORY_LIST } from 'library/common/commonConstants/storeConstants';
import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';
import { categoriesListRequestJson } from 'library/api/endpoint-request-json';

import * as actionTypes from './ActiveSystems.constants';

export const fetchInitialDate = () => async dispatch => {
  await dispatch(fetchMasterData(URLS.categoryList, FETCH_CATEGORY_LIST, categoriesListRequestJson));
};

export const fetchActiveSystemList = (page, size) => async dispatch => {
  dispatch(changeLoader(true));

  try {
    const res = await fetchMasterDataService(`${URLS.demoUnitSystemClass}?page=${page}&size=${size}`);

    if (res.status === 200) {
      await dispatch(
        batchActions([
          dispatch({
            type: actionTypes.FETCH_ACTIVE_SYSTEM_LIST,
            payload: res.data,
          }),
          dispatch(changeLoader(false))
        ]),
      );
    }
  } catch (err) {
    dispatch(changeLoader(false));
  }
};

export const saveActiveSystem = (dataToSend, page, size) => async dispatch => {
  try {
    const res = await fetchMasterDataServicePost(URLS.demoUnitSystemClass, dataToSend);

    if (res.status === 200) {
      dispatch(fetchActiveSystemList(page, size));

      return { success: true };
    }

    return { success: false };
  } catch (err) {
    const status = err && err.response && err.response.status;
    const message = err && err.response && err.response.data && err.response.data.message;
    let error = '';

    if (status === 409) {
      error = strings.recordAlreadyExists;
    } else {
      error = message;
    }

    return { success: false, error };
  }
};

export const activateOrDeactivateSystem = (id, activated, page, size) => async dispatch => {
  try {
    const res = await fetchMasterDataServicePut(`${URLS.demoUnitSystemClass}/${id}`, { activated });

    if (res.status === 204) {
      dispatch(fetchActiveSystemList(page, size));

      return { error: false };
    }

    return { error: true };
  } catch (err) {
    return { error: true };
  }
};
