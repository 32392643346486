import React from 'react';
import cn from 'classnames';

import strings from 'resources/locales/Translate';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import SearchField from 'library/common/commonComponents/SearchField/SearchField';

import { endSystem } from '../../InterfaceMonitoring.constants';

const Filters = ({ isShowFilter, data, handleDropdownChange, handleSearchChange }) => {
  return (
    <div className={cn('filter-wrapper', { 'd-block': isShowFilter, 'd-none': !isShowFilter })}>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-6'>
            <SearchField
              onChange={handleSearchChange}
              initialValue={data.searchText}
              placeholder={strings.searchForTransactions}
              inputStyles='search-field'
              iconStyles='loupe-icon'
            />
          </div>
          <Dropdown
            data={endSystem}
            selectAllOption
            selectAllPlaceholder={strings.selectAll}
            idKey='id'
            field='interfaceType'
            displayValue={[{ key: 'name', separator: '' }]}
            isRequired={false}
            placeholder={strings.pleaseSelect + '...'}
            label={strings.endSystem}
            onChange={handleDropdownChange}
            mainContainerStyle='col-md-6'
            value={data.interfaceType}
            fullValue={data.interfaceTypeFullValue}
          />
        </div>
      </div>
    </div>
  );
}

export default Filters;
