import Validators from 'library/utilities/Validators';

export const addingCourseLoanItem = [
  {
    label: 'location',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'stock',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    options: [],
    hasSection: true,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'locationName', separator: '' }],
    optionsArrayKey: 'stockInformation',
    titleDisplay: [
      { key: 'shortName', separator: ' ' },
      { key: 'name', separator: '' },
    ],
  },
  {
    label: 'systemClassSystem',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'categories',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    options: [
      {
        systemClassName: 'Axio Lab.A1 series',
        systemMainComponent: [
          {
            systemMainComponentId: 92,
            systemMainComponentName: 'Axio Lab.A1',
          },
        ],
      },
      {
        systemClassName: 'Primo Star series',
        systemMainComponent: [
          {
            systemMainComponentId: 97,
            systemMainComponentName: 'Primo Star Standard',
          },
        ],
      },
      {
        systemClassName: 'Stemi 3xx series',
        systemMainComponent: [
          {
            systemMainComponentId: 106,
            systemMainComponentName: 'Stemi 305',
          },
        ],
      },
    ],
    hasSection: true,
    multiSelect: false,
    filter: false,
    idKey: 'systemMainComponentId',
    displayValue: [{ key: 'systemMainComponentName', separator: '' }],
    optionsArrayKey: 'systemMainComponent',
    titleDisplay: [{ key: 'systemClassName', separator: '' }],
    styleClass: 'col-12 col-sm-10',
  },
  {
    label: 'quantity',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'quantity',
    validators: [
      { check: Validators.required, message: 'hideMessage' },
      { check: Validators.number, message: 'hideMessage' },
    ],
    required: true,
    styleClass: 'col-12 col-sm-2',
    extraProps: {
      maxLength: 20,
    },
  },
  {
    label: 'productId',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'productId',
    validators: [],
    required: false,
    styleClass: 'col-12',
  },
];
