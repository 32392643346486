import React from 'react';

import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import Modal from 'library/common/commonComponents/Modal';
import strings from 'resources/locales/Translate';

import { useCreateOrEditPopup } from './CreateOrEditFolderPopup.hook';

export default function CreateOrEditPopup({
  id,
  parentFolderId,
  createFolder,
  fetchdDocumentsAndFolders,
  folder,
  setFolder,
  isEditFolder,
}) {
  const {
    isLoading,
    inputData,
    error,
    onSaveClick,
    handleInput,
    onCloseClick,
  } = useCreateOrEditPopup({
    id,
    parentFolderId,
    createFolder,
    fetchdDocumentsAndFolders,
    folder,
    setFolder,
    isEditFolder,
  });

  return (
    <Modal
      id={id}
      title={isEditFolder ? strings.editFolderName : strings.createFolder}
      confirmAction={onSaveClick}
      loading={isLoading}
      disableBackgroundClose
      onCloseClick={onCloseClick}
    >
      <div className='mb-3'>
        <InputField
          isRequired={true}
          label={strings.folderName}
          onChange={handleInput}
          value={inputData.value}
          validators={[]}
          error={inputData.error}
        />
      </div>
      {error && <div className='error-block'>{error}</div>}
    </Modal>
  );
};
