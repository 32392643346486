import React, {PureComponent} from 'react';
import moment from 'moment';
import DatePicker from 'react-date-picker';

import Icon from 'library/common/commonComponents/Icon/Icon';

export default class CourseLoanDatePicker extends PureComponent {
  state = {
    date:  this.props.selectedDate ? new Date(this.props.selectedDate) : '',
    isCalendarVisible: false
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.selectedDate && this.props.selectedDate !== prevProps.selectedDate) {
      this.setState({date: new Date(this.props.selectedDate)});
    }
  }

  toggleCalendar = () => {
    this.setState(prevState => {
      return {
        isCalendarVisible: !prevState.isCalendarVisible
      }
    });
  };

  onChange = (date) => {
    const {itemIndex, onChangeDate} = this.props;
    this.setState({date}, () => onChangeDate(date, itemIndex));
  };

  render() {
    const {isCalendarVisible, date} = this.state;
    const {selectedDate, label, minDate, maxDate, activeStartDate} = this.props;

    return (
      <div className='loan-list-date-picker'>
        <div className='app-datepicker cursor-pointer'>
          <p className='date-picker-label'>{label}</p>
          <div
            className={'d-flex datepicker-view align-items-center text-overflow-ellipsis' + (isCalendarVisible && 'calendar-shadow')}
            onClick={this.toggleCalendar}
          >
            <Icon name='calendar' width={24} height={24} fill='#000'/>
            <h4 className='current-date'>{moment(selectedDate).format('DD.MM.YYYY')}</h4>
          </div>

          <DatePicker
            minDetail='decade'
            className='datepicker-component-container'
            calendarClassName='datepicker-calendar-container calendar-shadow'
            clearIcon={null}
            showLeadingZeros={true}
            isOpen={isCalendarVisible}
            onChange={this.onChange}
            value={date}
            minDate={minDate}
            maxDate={maxDate}
            activeStartDate={activeStartDate}
            {...this.props}
          />
        </div>
      </div>
    )
  }
}
