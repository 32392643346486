import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchMasterData, fetchMasterDataPost } from 'library/common/commonActions/MasterDataActions';
import { fetchCartDataFromLocalStorage } from 'modules/LoanList/LoanListActions';
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';

import { fetchKitsListData, deleteKit, fetchProblemsList, fetchDemoRequestForm } from './KitsList.actions';
import { resetCreateKit } from '../CreateKit/CreateKit.actions';
import KitsList from './KitsList.component';
import { changeLoader } from 'library/common/commonActions/AppActionsActions';

const mapStateToProps = state => {
  return {
    user: state.authReducer.user,
    language: state.languageReducer,
    appActions: state.appActionsReducer,
    kitsList: state.kitsListReducer.kitsList,
    countries: state.masterDataReducer.countries,
    kitStatus: state.masterDataReducer.kitStatus,
    conditions: state.masterDataReducer.conditions,
    categoriesAndSystems: state.masterDataReducer.systemClassWithStock,
    problemsList: state.kitsListReducer.problemsList,
    labsLocationSystemClasses: state.kitsListReducer.labsLocationSystemClasses
  };
};

export default withRouter(
  connect(mapStateToProps, {
    fetchKitsListData,
    fetchMasterData,
    fetchMasterDataPost,
    deleteKit,
    fetchCartDataFromLocalStorage,
    resetCreateKit,
    toggleActionMessage,
    fetchProblemsList,
    fetchDemoRequestForm,
    changeLoader,
  })(KitsList),
);
