export const regex = {
  email: new RegExp(
    '^(([^<>()\\[\\]\\\\.,;:\\s@]+(\\.[^<>()\\[\\]\\\\.,;:\\s@]+)*)|(.+))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
  ),
  url: new RegExp('^http(s?):\\/\\/([\\w\\d\\-]+\\.)+\\w{2,}(\\/.+)?$'),
  price: new RegExp(/^\d+(\.\d{1,2})?$/),
  number: new RegExp('^[0-9]+$'),
  numberWithDecimals: new RegExp(/^[0-9]*\.?[0-9]*$/),
  alphaNumeric: new RegExp(/^[0-9a-zA-Z]+$/),
  materailNumber: new RegExp(/^\d{6}-\d{4}-\d{3}$/),
  validCrmCheck: new RegExp(/^200\d{6}$/),
  validCrmCheckUk: new RegExp(/^250\d{6}$|^2500\d{5}$/),
  alphaNumericWithSpace: new RegExp(/^[a-zA-Z0-9 ]*$/),
  mobileAlphaNumericWithPlus: new RegExp(/^\+?[a-zA-Z0-9 ]*$/),
  numberOrDecimal: new RegExp(/^(\d+(\.\d+)?|\.\d+)$/ ),
  crmIdExp: new RegExp(/^[0-9QU-]+$/),

};
