import { useCallback, useState } from 'react';

export const useNotificationBar = ({
  history,
  isOpenNotificationBar,
  openOrHideNotificationsBar,
  notifications,
  deleteNotification,
  changeStatusNotification,
}) => {
  const [isStatusLoading, setIsStatusLoading] = useState(false);
  const [isDeletLoading, setIsDeleteLoading] = useState(false);

  const onClose = useCallback(() => {
    openOrHideNotificationsBar(!isOpenNotificationBar);
  }, [openOrHideNotificationsBar, isOpenNotificationBar]);

  const handleClickOnSettings = useCallback(() => {
    openOrHideNotificationsBar(false);
    history.push('/subscriptions');
  }, [history, openOrHideNotificationsBar]);

  const createRedirectUrl = useCallback(notification => {
    const { eventType, outputNotificationFilterDTO, transactionId, transactionType,kitId } = notification || {};

    switch (eventType) {
      case 'NEW_STOCK_ACCESS_REQUEST':
        return { pathname: `/edit-stock/${outputNotificationFilterDTO.stockId}`, state: { tab: 'entitlements' } };
      case 'STOCK_ACCESS_REQUEST_PROCESSED':
        return { pathname: `/calendar`, state: { stocks: [outputNotificationFilterDTO.stockId] } };
      case 'ADD_DUSD_TASK':
        return {
          pathname: '/kits',
          state: {
            systemMainComponent: outputNotificationFilterDTO.systemMainComponent,
            systemMainComponentName: outputNotificationFilterDTO.systemMainComponentName,
          },
        };
      case 'ADD_COMMENT_STATUS_TO_DUSD':
      case 'ADD_COMMENT_ANSWER_STATUS_TO_DUSD':
        return { pathname: `/kit_details/${outputNotificationFilterDTO.kitId}`, state: { tab: 'demoUnitStatus' } };
      case 'DEMO_KIT_SERVICE_TICKET_CREATED':
        return { pathname: `/kit_details/${outputNotificationFilterDTO.kitId}`, state: { tab: 'serviceTicket' } };
      case 'NEW_LOST_OR_DAMEGED_REPORT':
        return { pathname: `/kit_details/${outputNotificationFilterDTO.kitId}`, state: { tab: 'messages' } };
      case 'C2C_SUGGESTIONS':
        return { pathname: `/transactions/${transactionId}`, state: { tab: 'c2cLoanProcesses' } };
      case 'DEMO_SATISFACTION_MAIL':
        return { pathname: `/transactions/${transactionId}`, state: { tab: 'rating', transactionType } };
      case 'TRANSACTION_MESSAGE_COMMENT':
        return { pathname: `/transactions/${transactionId}`, state: { tab: 'messages' } };
      case 'NEW_INVENTORY_ADDED':
        return { pathname: `/kit_details/${kitId}`, state: { tab: 'information' } };
      case 'SAP_LOAN_ORDER_ADDED':
        return { pathname: `/transactions/${transactionId}`, state: { tab: 'information' } };
      case 'LOAN_ORDER_RETURN_FAILURE':
        return { pathname: `/transactions/${transactionId}`, state: { tab: 'information' } };
      case 'KIT_EXPIRING_TODAY':
        return { pathname: `/kit_details/${kitId}`, state: { tab: 'information' } };
      case 'ZMCC_DETAILS_UPDATED':
        return `/transactions/${transactionId}/isZmcc`;
      default:
        return `/transactions/${transactionId}`;
    }
  }, []);

  const handleClickOnNotification = useCallback(notification => {
    openOrHideNotificationsBar(false);
    changeStatusNotification(notification.id);
    const redirectUrl = createRedirectUrl(notification);
    history.push(redirectUrl);
  }, [openOrHideNotificationsBar, createRedirectUrl, history, changeStatusNotification]);

  const handleDeleteNotification = useCallback(async (e, id) => {
    e.stopPropagation();
    setIsDeleteLoading(true);
    await deleteNotification(id);
    setIsDeleteLoading(false);
  }, [deleteNotification]);

  const handleChangeStatusOfNotification = useCallback(async (e, id) => {
    e.stopPropagation();
    setIsStatusLoading(true);
    await changeStatusNotification(id);
    setIsStatusLoading(false);
  }, [changeStatusNotification]);

  return {
    isStatusLoading,
    isDeletLoading,
    handleClickOnNotification,
    handleClickOnSettings,
    onClose,
    handleDeleteNotification,
    handleChangeStatusOfNotification,
  };
}
