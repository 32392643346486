import { connect } from 'react-redux';

import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { cancelStockCreation } from 'modules/Stock/CreateStock/CreateStock.actions';
import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';
import { fetchMasterDataOnLanguageChange } from 'modules/Booking/BookingActions';

import Entitlements from './Entitlements.component';
import {
  createStockRole,
  fetchStockEntitlements,
  fetchRequestAccess,
  requestAcceptReject,
  getNewRoles,
} from './Entitlements.actions';

const mapStateToProps = ({ createStockReducer, languageReducer, masterDataReducer, authReducer }) => ({
  canEditStock: createStockReducer.canEditStock,
  stockId: createStockReducer.stockDetails.id,
  entitlements: createStockReducer.entitlements,
  masterData: masterDataReducer,
  language: languageReducer,
  stockDetails: createStockReducer.stockDetails,
  user: authReducer.user,
  newStockRoles: createStockReducer.newStockRoles,
  crmAccountId: createStockReducer.crmAccountId
});

export default connect(mapStateToProps, {
  createStockRole,
  toggleActionMessage,
  cancelStockCreation,
  fetchStockEntitlements,
  fetchRequestAccess,
  requestAcceptReject,
  fetchMasterData,
  fetchMasterDataOnLanguageChange,
  getNewRoles,
})(Entitlements);
