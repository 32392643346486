import { connect } from 'react-redux';

import StockDashboard from './StockDashboard.component';
import { getInitialData, getStatisticsForThisMonth, fetchCountries } from './StockDashboard.actions';

const mapStateToProps = ({ authReducer, languageReducer, appActionsReducer, stockDashboardReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
  appActions: appActionsReducer,
  businessUnits: stockDashboardReducer.businessUnits,
  countries: stockDashboardReducer.countries,
  stocks: stockDashboardReducer.stocks,
});

export default connect(mapStateToProps, { getInitialData, getStatisticsForThisMonth, fetchCountries })(StockDashboard);
