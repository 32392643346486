import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { filter, isEqual } from 'lodash';
import Icon from '../../../../library/common/commonComponents/Icon/Icon';
import strings from 'resources/locales/Translate';
import moment from 'moment';

function FilterTags({ filters = {}, onClear = () => {}, onClearAll = () => {}, model = {} }) {
  const getTag = (selectedValue, displayValue, multiple) => {
    let tag = '';
    if (!multiple && selectedValue !== null && selectedValue !== undefined) {
      tag = displayValue
        .map(display => {
          return selectedValue[display.key];
        })
        .join('');
      return tag;
    } else if (
      multiple &&
      selectedValue !== null &&
      selectedValue !== undefined &&
      Array.isArray(selectedValue) &&
      selectedValue.length > 0
    ) {
      tag = selectedValue.map(item => {
        return displayValue
          .map(display => {
            return item[display.key] + display.separator;
          })
          .join('');
      });
      tag = selectedValue.length === 1 ? tag[0] : `${tag[0]} + ${tag.length - 1}`;
      return tag;
    }
  };

  const renderFilters = () => {
    let newFilters = [];
    Object.keys(model).forEach(field => {
      if (filters[field] !== null && filters[field] !== undefined) {
        if (Array.isArray(filters[field]) && filters[field].length <= 0) {
          return;
        }
        if (typeof filters[field] === 'object' && Object.keys(filters[field]).length === 0) {
          return;
        }
        getTag(filters[`${field}FullValue`], model[field].displayValue, model[field].multiple);
        newFilters.push(
          <div className='filter-chips-container mt-2' key={`${field}`}>
            {getTag(filters[`${field}FullValue`], model[field].displayValue, model[field].multiple)}
            <span onClick={() => onClear(field)}>&times;</span>
          </div>,
        );
      }
    });
    if (
      filters?.dateRange !== null &&
      filters?.dateRange !== undefined &&
      Array.isArray(filters.dateRange) &&
      filters.dateRange.length === 2
    ) {
      newFilters.push(
        <div className='filter-chips-container mt-2' key={`dateRange`}>
          {`${moment(filters.dateRange[0]).format('DD.MM.YYYY')} - ${moment(filters.dateRange[1]).format(
            'DD.MM.YYYY',
          )}`}
          <span onClick={() => onClear('dateRange')}>&times;</span>
        </div>,
      );
    }
    return newFilters;
  };

  let filterTags = renderFilters();

  return (
    <>
      {filterTags && filterTags.length > 0 && (
        <div className='container-fluid mb-4 d-flex flex-direction-row align-items-center flex-wrap'>
          <div className='mr-2 mt-2'>
            <strong>Filters:</strong>
          </div>
          {filterTags}
          <div className='filter-chips-delete-all mt-2' onClick={onClearAll}>
            <Icon name='delete' width={24} height={24} fill='#0088d0' /> {strings.deleteAllFilters}
          </div>
        </div>
      )}
    </>
  );
}

FilterTags.propTypes = {
  filters: PropTypes.object,
  onClear: PropTypes.func,
  onClearAll: PropTypes.func,
  model: PropTypes.object,
};

export default FilterTags;
