import React, { useCallback } from 'react';

import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import CreateStockActionButtons from 'library/common/commonComponents/CreateStockActionButtons';

import { salesDetailsModel } from '../../CreateKitForSale.constants';

const SalesDetails = ({ formValue, customers, handleForm, saveKit, cancelCreateKit }) => {
  const { salesDetailsForm } = formValue;

  const getModel = useCallback(() => {
    const newModel = [ ...salesDetailsModel ];
    newModel[2].options = customers;

    return newModel;
  }, [customers]);

  return (
    <div className='information-container mb-5'>
      <div className='container-fluid form-container'>
        <div className='row'>
          <div className='col-sm-6'>
            <h3 className='form-section-title'>{strings.salesDetails}</h3>
            <FormComponent
              model={getModel()}
              formName='salesDetailsForm'
              formValue={salesDetailsForm.formData}
              onChange={handleForm}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <CreateStockActionButtons onSave={saveKit} onCancel={cancelCreateKit} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesDetails;
