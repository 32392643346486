import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import { checkHasMedUsWarehouseAccess, checkUserRoles } from 'library/utilities/checkRoles';
import { permissions } from 'library/common/commonConstants/permissionsConstants';
import strings from 'resources/locales/Translate';
import { DISPATCHER, LL_BUSINESS_UNIT } from 'library/common/commonConstants/storeConstants';
import { BUSINESS_UNIT_NAMES } from 'library/utilities/constants';

const Navigation = ({ user, pathname, location, refurbishmentFessStockList, loansSubMenu }) => {
  const isAdmin = user && user.admin;
  const hasOnlyDustUserRole = user && user.dustUser;
  const isUserPartner = user && user.labLocationPartner;
  const checkActiveClassForLoan = pathname === '/single-loan' || pathname === '/course-loan' || pathname === '/SEM';
  const checkActiveClassForBooking = pathname === '/my-bookings';
  const checkActiveClassForShop = pathname.includes('/demo-sale');
  const checkActiveClassForDUSD = pathname.includes('/dusd');
  const checkActiveClassForAdministration = pathname.includes('/administration');
  const checkHomepageActive = pathname.includes('/home');
  const checkKitsActive = pathname.includes('/kits');
  const checkWarehouseActive = pathname.includes('/stocks');
  const isShowBookingTeams = checkUserRoles(permissions.bookingTeams, user);
  const isShowOrderList = checkUserRoles(permissions.orderList, user);
  const isShowAdministrationMenu = checkUserRoles(permissions.stockDashboardMenu, user);
  const isShowDustTaskAndPermissions = checkUserRoles(permissions.permissionsMenu, user);
  const isShowSubscriptions = checkUserRoles(permissions.subscriptionsMenu, user);
  const hasMaintainStatusPermissions = checkUserRoles(permissions.maintainStatusMenu, user);
  const isShowUserPermissionsMenu = checkUserRoles(permissions.userPermissionsMenu, user);
  const hasActiveSystemsPermissions = checkUserRoles(permissions.activeSystemsMenu, user);
  const isDustUser = checkUserRoles(permissions.dustStatus, user);
  const hasMedUsWarehouseAccess = checkHasMedUsWarehouseAccess(user);
  const rmsUser =
    user &&
    user.stockRoles &&
    user.stockRoles.filter(role => role.businessUnitName === 'RMS' && role.roleName !== 'Shop Manager').length;
  const llRMSUser =
    user &&
    user.stockRoles &&
    user.stockRoles.filter(
      role => role.businessUnitName === 'RMS labs@location Partner' && role.roleName !== 'Shop Manager',
    ).length;
  const allUserRoles = user && user.stockRoles && user.stockRoles.filter(role => role.businessUnitName).length;
  const noUserRoles = !isAdmin && !isUserPartner && allUserRoles === 0;
  const userHasOnlyLLRMS = llRMSUser === allUserRoles && !isAdmin;
  const isShowLabLocationMenu = isAdmin || isUserPartner || !!rmsUser || !!llRMSUser;
  const isLLManager = user.llmanager;
  const isLLPartner = 
    user &&
    user.stockRoles.find(item => item.businessUnitName === LL_BUSINESS_UNIT && ((item.roleName === DISPATCHER)));
  const canViewSapErpMapping =
    user && (user.admin || user.stockRoles.some(item => item.roleName === 'Stock Controller'));
  const isLLEnabled = process.env.REACT_APP_IS_LABSLOCATION_ENABLED === 'true';
  const checkStockId = () => {
    if (user && user.admin) {
      return true;
    }
    let stockRoles = user && user.stockRoles;
    let myStockIds = [];
    let refurbishmentId = [];
    if (stockRoles && stockRoles.length) {
      stockRoles.forEach(stockRole => {
        if (stockRole.businessUnitName !== 'RMS labs@location Partner' && stockRole.roleName === 'Stock Controller') {
          myStockIds.push(stockRole.stockId);
        }
      });
    }
    const removingNullStockInformation =
      refurbishmentFessStockList &&
      refurbishmentFessStockList.length &&
      refurbishmentFessStockList.filter(item => item.stockInformation && item.stockInformation.length > 0);
    const stockInformation =
      removingNullStockInformation &&
      removingNullStockInformation.length &&
      removingNullStockInformation.map(item => item.stockInformation.map(item => refurbishmentId.push(item.id)));

    if (myStockIds.length > 0) {
      for (let index = 0; index < myStockIds.length; index++) {
        if (refurbishmentId.length > 0) {
          if (refurbishmentId.includes(myStockIds[index])) {
            return true;
          }
        }
      }
    }

    return false;
  };
  const showtab = checkStockId();
  const isZmccUser =
    user && user.stockRoles.find(item => item.businessUnitName === 'RMS (ZMCC)');
  const isZmccEnabled = process.env.REACT_APP_IS_ZMCC_ENABLED === 'true';

  const isZmccManagerOrOperator = user && user.stockRoles.find(item => item.businessUnitName === 'RMS (ZMCC)' && (item.roleName === 'Stock Controller' || item.roleName === 'Operator'));

  const limitMenuOptionsForZmccRequestor = () => {
    let value;
    const businessUnits = ['RMS', 'MED-SUR', 'MED-OPT', 'COP', 'VIS', 'IQS', 'SMT', 'MED', 'RMS labs@location Partner'];
    if (user && user.stockRoles && user.stockRoles.length > 0) {
      let isDlaOrLabsLocation = false;
      let isZmcc = false;
      let isZmccBorrower = false;
      for (let i = 0; i < user.stockRoles.length; i++) {
        const element = user.stockRoles[i];
        if (businessUnits.includes(element.businessUnitName)) {
          value = true;
          isDlaOrLabsLocation = true;
        } else if (element.businessUnitName === 'RMS (ZMCC)' && element.roleName !== 'Borrower') {
          value = true;
          isZmcc = true;
        } else if (element.businessUnitName === 'RMS (ZMCC)' && element.roleName === 'Borrower') {
          value = false;
          isZmccBorrower = true;
        }
      }
      value = (isDlaOrLabsLocation || isZmcc);
    }
    return value;
  }

  const isZmccRequestor = limitMenuOptionsForZmccRequestor();
  const hideTransactionForZmccUser = user && user.stockRoles.find(item => item.businessUnitName !== 'RMS (ZMCC)');
  const hasAccessToIqsSscDe = user && (user.stockRoles.some(stockRole => stockRole.businessUnitName === BUSINESS_UNIT_NAMES.IQS_SSC_DE) || isAdmin);
  const isIqsEnabled = process.env.REACT_APP_IQS_SSC_DE_ENABLED === 'true';
  if (hasOnlyDustUserRole) {
    return (
      <div className='' id='navbarSupportedContent'>
        <ul className='mr-auto'>
          <li className='nav-parent'>
            <NavLink to={'/home'} exact={true} activeClassName={checkHomepageActive ? 'active': ''} className='nav-link'>
              {strings.homepage}
            </NavLink>
          </li>
          <li className='nav-parent'>
            <NavLink to={'/stocks'} exact={true} activeClassName='active' className='nav-link'>
              {strings.stocks}
            </NavLink>
          </li>
          <li className='nav-parent loan-menu'>
            <div
              className={cn('nav-link cursor-pointer', { active: checkActiveClassForDUSD })}
              id='dusdDropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <span data-toggle='dropdown' className='dropdown-toggle'>
                {strings.demoUnitStatusTool}
              </span>
              <div className='dropdown-menu nav-child rounded-0' aria-labelledby='administartionDropdown'>
                <NavLink exact={true} to={'/dusd/subscriptions'} className='dropdown-item'>
                  {strings.subscriptions}
                </NavLink>
                <NavLink exact={true} to={'/dusd/maintain-status'} className='dropdown-item'>
                  {strings.maintainStatus}
                </NavLink>
                <NavLink exact={true} to={'/dusd/dusd-task'} className='dropdown-item'>
                  {strings.tasks}
                </NavLink>
                <NavLink exact={true} to={'/dust/active-systems'} className='dropdown-item'>
                  {strings.activeSystems}
                </NavLink>
              </div>
            </div>
          </li>
        </ul>
      </div>
    );
  }

  return (
    <div className='' id='navbarSupportedContent'>
      <ul className='mr-auto'>
        <li className='nav-parent'>
          <NavLink to={'/home'} exact={true}  className={`nav-link ${checkHomepageActive? 'set-active-color': 'set-non-active-color'}`}>
            {strings.homepage}
          </NavLink>
        </li>
        <li className='nav-parent loan-menu'>
          <div
            className={cn('nav-link cursor-pointer main-option', { active: checkActiveClassForLoan }, )}
            id='loanDropdown'
            aria-haspopup='true'
            aria-expanded='false'
          >
            <span data-toggle='dropdown'  className={`dropdown-toggle ${checkActiveClassForLoan? 'set-active-color': 'set-non-active-color'} main-option`}>
              {strings.homeBooking}
            </span>
            <div className='dropdown-menu nav-child rounded-0 submenu-optipons' aria-labelledby='loanDropdown' style={{transform:'none'}}>
            {isZmccRequestor && isZmccUser && isZmccEnabled && (
                <li>
                  <>
                    <NavLink to={{ pathname: '/', prevLocation: location }} exact={true} className='dropdown-item'>
                      {strings.zmccRequest}
                    </NavLink>
                    <ul class='dropdown-menu dropdown-submenu'>
                      {loansSubMenu &&
                        loansSubMenu.length > 0 &&
                        loansSubMenu.map(item => (
                          <li>
                            <NavLink
                              to={{ pathname: `/${item.translationSubMenu}`, prevLocation: location }}
                              exact={true}
                              className='dropdown-item'
                            >
                              {item.translationSubMenu}
                            </NavLink>
                          </li>
                        ))}
                    </ul>
                  </>
                </li>
              )}
              {isZmccEnabled && isZmccManagerOrOperator && <NavLink
                  to={{ pathname: '/zmcc-calendar', prevLocation: location }}
                  exact={true}
                  className='dropdown-item'
                >
                  {strings.zmccCalendar}
                </NavLink>}
              {(!userHasOnlyLLRMS || noUserRoles || !isUserPartner) && (
                <NavLink to={'/calendar'} exact={true} className='dropdown-item'>
                  {strings.bookLoanEquipment}
                </NavLink>
              )}
              {!isUserPartner && (
                <NavLink to={'/single-loan'} exact={true} className='dropdown-item'>
                  {strings.bookSingleLoan}
                </NavLink>
              )}
              {!isUserPartner && (
                <NavLink to={'/course-loan'} exact={true} className='dropdown-item'>
                  {strings.bookCourseLoan}
                </NavLink>
              )}

              {isShowLabLocationMenu && (
                <NavLink
                  to={{ pathname: '/labs-location-calendar', prevLocation: location }}
                  exact={true}
                  className='dropdown-item'
                >
                  {strings.labsLocationRequest}
                </NavLink>
              )}
              {hasAccessToIqsSscDe && isIqsEnabled && (
                <NavLink
                  to={{ pathname: '/iqs-calendar', prevLocation: location }}
                  exact={true}
                  className='dropdown-item'
                >
                  {"IQS " + strings.calendar}
                </NavLink>
              )}
            </div>
          </div>
        </li>
        <li className='nav-parent loan-menu'>
          <div
            className={cn('nav-link cursor-pointer', { active: checkActiveClassForBooking }, 'main-option')}
            id='bookingDropdown'
            aria-haspopup='true'
            aria-expanded='false'
          >
            <span data-toggle='dropdown' className={`dropdown-toggle ${checkActiveClassForBooking ? 'set-active-color': 'set-non-active-color'}`}>
              {strings.transaction}
            </span>
            <div className='dropdown-menu nav-child rounded-0 submenu-optipons' aria-labelledby='bookingDropdown'>
            {(user.admin || isZmccManagerOrOperator ) && <NavLink
                to={{ pathname: '/my-queries', prevLocation: location }}
                exact={true}
                className='dropdown-item'
              >
                {strings.myQueries}
              </NavLink>}

              {hideTransactionForZmccUser && isShowBookingTeams && !isUserPartner && (
                <NavLink
                  to={{ pathname: '/team-bookings', prevLocation: location }}
                  exact={true}
                  className='dropdown-item'
                >
                  {strings.teamBookings}
                </NavLink>
              )}

              <NavLink to={{ pathname: '/my-bookings', prevLocation: location }} exact={true} className='dropdown-item'>
                {strings.myBookings}
              </NavLink>

              {isZmccRequestor && <NavLink
                to={{ pathname: '/open-processes', prevLocation: location }}
                exact={true}
                className='dropdown-item'
              >
                {strings.openProcesses}
              </NavLink>}
              {isZmccRequestor && <NavLink
                to={{ pathname: '/loan-requests', prevLocation: location }}
                exact={true}
                className='dropdown-item'
              >
                {strings.loanRequests}
              </NavLink>}
              {hideTransactionForZmccUser && !isUserPartner && (
                <>
                  <NavLink
                    to={{ pathname: '/sap-order-creation', prevLocation: location }}
                    exact={true}
                    className='dropdown-item'
                  >
                    {strings.sapOrderCreation}
                  </NavLink>
                  <NavLink
                    to={{ pathname: '/outgoing-goods', prevLocation: location }}
                    exact={true}
                    className='dropdown-item'
                  >
                    {strings.outgoingGoods}
                  </NavLink>
                  <NavLink
                    to={{ pathname: '/list-receiving', prevLocation: location }}
                    exact={true}
                    className='dropdown-item'
                  >
                    {strings.listReceiving}
                  </NavLink>
                  <NavLink
                    to={{ pathname: '/incoming-goods', prevLocation: location }}
                    exact={true}
                    className='dropdown-item'
                  >
                    {strings.incomingDemoKits}
                  </NavLink>
                  <NavLink
                    to={{ pathname: '/good-receipt', prevLocation: location }}
                    exact={true}
                    className='dropdown-item'
                  >
                    {strings.goodReceipt}
                  </NavLink>
                  <NavLink
                    to={{ pathname: '/list-reconditioning', prevLocation: location }}
                    exact={true}
                    className='dropdown-item'
                  >
                    {strings.listReconditioning}
                  </NavLink>
                </>
              )}
              {isZmccRequestor && <NavLink
                to={{ pathname: '/cancellation-approval', prevLocation: location }}
                exact={true}
                className='dropdown-item'
              >
                {strings.cancellationApproval}
              </NavLink>}
              <NavLink
                to={{ pathname: '/cancelled-processes', prevLocation: location }}
                exact={true}
                className='dropdown-item'
              >
                {strings.cancelledProcesses}
              </NavLink>
              <NavLink
                to={{ pathname: '/archive-processes', prevLocation: location }}
                exact={true}
                className='dropdown-item'
              >
                {strings.archiveProcesses}
              </NavLink>
              {hideTransactionForZmccUser && !isUserPartner && (
                <NavLink to={{ pathname: '/c2c-loans', prevLocation: location }} exact={true} className='dropdown-item'>
                  {strings.c2cLoans}
                </NavLink>
              )}
            </div>
          </div>
        </li>
        {isZmccRequestor && <li className='nav-parent'>
          <NavLink
            to={{ pathname: '/kits', prevLocation: location }}
            exact={true}
            className={`nav-link ${checkKitsActive? 'set-active-color': 'set-non-active-color'}`}
          >
            {strings.kits}
          </NavLink>
        </li>}
        {isZmccRequestor &&
          <li className='nav-parent'>
          <NavLink to={'/stocks'} exact={true} activeClassName={checkWarehouseActive ? 'active': ''} className={`nav-link ${checkWarehouseActive? 'set-active-color': 'set-non-active-color'}`}>
            {strings.stocks}
          </NavLink>
        </li>}
        {(!isUserPartner) && (
          <li className='nav-parent loan-menu'>
            <div
              className={cn('nav-link cursor-pointer', { active: checkActiveClassForShop }, 'main-option')}
              id='shopDropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              {isZmccRequestor && <span data-toggle='dropdown' className={`dropdown-toggle ${checkActiveClassForShop ? 'set-active-color': 'set-non-active-color'}`}>
                {strings.demoSale}
              </span>}
              <div className='dropdown-menu nav-child rounded-0 submenu-optipons' aria-labelledby='shopDropdown'>
                <NavLink to={'/demo-sale'} exact={true} className='dropdown-item'>
                  {strings.salesOffers}
                </NavLink>
                <NavLink to={'/demo-sale/observations'} exact={true} className='dropdown-item'>
                  {strings.myObservations}
                </NavLink>
                <NavLink to={'/demo-sale/reservation'} exact={true} className='dropdown-item'>
                  {strings.reservation}
                </NavLink>
                {isShowOrderList && (
                  <NavLink to={'/demo-sale/order'} exact={true} className='dropdown-item'>
                    {strings.orders}
                  </NavLink>
                )}
                <NavLink to={'/demo-sale/archive'} exact={true} className='dropdown-item'>
                  {strings.archive}
                </NavLink>
                <NavLink to={'/demo-sale/statistics'} exact={true} className='dropdown-item'>
                  {strings.statistics}
                </NavLink>
                <NavLink to={'/demo-sale/purchase-requests'} exact={true} className='dropdown-item'>
                  {strings.purchaseRequests}
                </NavLink>
              </div>
            </div>
          </li>
        )}
        {isShowSubscriptions && !isUserPartner && (
          <li className='nav-parent loan-menu'>
            <div
              className={cn('nav-link cursor-pointer', { active: checkActiveClassForDUSD }, 'main-option')}
              id='dusdDropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <span data-toggle='dropdown' className={`dropdown-toggle ${checkActiveClassForDUSD ? 'set-active-color': 'set-non-active-color'}`}>
                {strings.demoUnitStatusTool}
              </span>
              <div className='dropdown-menu nav-child rounded-0 submenu-optipons' aria-labelledby='administartionDropdown'>
                {isShowDustTaskAndPermissions && (
                  <NavLink to={'/dusd/permissions'} exact={true} className='dropdown-item'>
                    {strings.permissions}
                  </NavLink>
                )}
                {isDustUser && (
                  <NavLink exact={true} to={'/dusd/dusd-task'} className='dropdown-item'>
                    {strings.tasks}
                  </NavLink>
                )}
                {isShowSubscriptions && (
                  <NavLink exact={true} to={'/dusd/subscriptions'} className='dropdown-item'>
                    {strings.subscriptions}
                  </NavLink>
                )}
                {hasMaintainStatusPermissions && (
                  <NavLink exact={true} to={'/dusd/maintain-status'} className='dropdown-item'>
                    {strings.maintainStatus}
                  </NavLink>
                )}
                {hasActiveSystemsPermissions && (
                  <NavLink exact={true} to={'/dust/active-systems'} className='dropdown-item'>
                    {strings.activeSystems}
                  </NavLink>
                )}
              </div>
            </div>
          </li>
        )}
        {isShowAdministrationMenu && (
          <li className='nav-parent loan-menu'>
            <div
              className={cn('nav-link cursor-pointer', { active: checkActiveClassForAdministration }, 'main-option')}
              id='administartionDropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <span data-toggle='dropdown' className={`dropdown-toggle ${checkActiveClassForAdministration ? 'set-active-color': 'set-non-active-color'}`}>
                {strings.administration}
              </span>
              <div className='dropdown-menu nav-child rounded-0 submenu-optipons' aria-labelledby='administartionDropdown'>
                {(isAdmin || hasMedUsWarehouseAccess) && (
                  <NavLink to={'/administration/master-data'} exact={true} className='dropdown-item'>
                    {strings.masterData}
                  </NavLink>
                )}
                {isShowBookingTeams && !isUserPartner &&(
                  <NavLink to={'/administration/export-data'} exact={true} className='dropdown-item'>
                    {strings.exportData}
                  </NavLink>
                )}
                {isShowAdministrationMenu && !isUserPartner && (
                  <NavLink to={'/administration/stock-dashboard'} exact={true} className='dropdown-item'>
                    {strings.stockDashboard}
                  </NavLink>
                )}
                {isShowAdministrationMenu && !isUserPartner && (
                  <NavLink to={'/administration/statistics'} exact={true} className='dropdown-item'>
                    {strings.statistics}
                  </NavLink>
                )}
                {isAdmin && (
                  <NavLink to={'/administration/interface-monitoring'} exact={true} className='dropdown-item'>
                    {strings.interfaceMonitoring}
                  </NavLink>
                )}
                {isShowUserPermissionsMenu && !isUserPartner && (
                  <NavLink to={'/administration/user-permissions'} exact={true} className='dropdown-item'>
                    {strings.userPermissions}
                  </NavLink>
                )}
                {isAdmin && (
                  <NavLink to={'/administration/kit-pictures'} exact={true} className='dropdown-item'>
                    {strings.manageKitPictures}
                  </NavLink>
                )}
                {isAdmin && isLLEnabled && (
                  <NavLink to={'/administration/partner-permissions'} exact={true} className='dropdown-item'>
                    {strings.partnerPermissions}
                  </NavLink>
                )}
                {(isAdmin || isLLManager || isLLPartner) && isLLEnabled && (
                  <NavLink to={'/administration/labs-location-management'} exact={true} className='dropdown-item'>
                    {strings.labsLocationManagement}
                  </NavLink>
                )}
                {canViewSapErpMapping && !isUserPartner && (
                  <NavLink to={'/administration/sap-erp-mapping'} exact={true} className='dropdown-item'>
                    {strings.sapERPMappingTable}
                  </NavLink>
                )}
                {showtab && (
                  <NavLink to={'/administration/refurbishment-fees'} exact={true} className='dropdown-item'>
                    {strings.refurbishmentFees}
                  </NavLink>
                )}
              </div>
            </div>
          </li>
        )}
        {user && <>
          <div className='nav-link divider-line'></div>
          <div className='nav-link d-flex align-items-center'>
            <div>
              <svg width="20" height="20" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.44821 8.27586C8.6298 8.27586 7.82978 8.03318 7.1493 7.5785C6.46882 7.12381 5.93845 6.47756 5.62526 5.72145C5.31207 4.96534 5.23012 4.13334 5.38979 3.33066C5.54945 2.52798 5.94355 1.79067 6.52225 1.21197C7.10095 0.633274 7.83826 0.239174 8.64094 0.079511C9.44362 -0.0801519 10.2756 0.00179291 11.0317 0.314983C11.7878 0.628173 12.4341 1.15854 12.8888 1.83902C13.3435 2.5195 13.5861 3.31953 13.5861 4.13793C13.5861 5.23538 13.1502 6.28788 12.3742 7.06389C11.5982 7.8399 10.5457 8.27586 9.44821 8.27586ZM9.44821 1.65517C8.95716 1.65517 8.47715 1.80079 8.06886 2.07359C7.66057 2.3464 7.34235 2.73416 7.15444 3.18782C6.96652 3.64149 6.91736 4.14069 7.01315 4.62229C7.10895 5.1039 7.34541 5.54629 7.69263 5.89351C8.03985 6.24073 8.48224 6.47719 8.96385 6.57299C9.44545 6.66878 9.94465 6.61962 10.3983 6.4317C10.852 6.24379 11.2397 5.92557 11.5125 5.51728C11.7854 5.10899 11.931 4.62898 11.931 4.13793C11.931 3.47946 11.6694 2.84796 11.2038 2.38236C10.7382 1.91675 10.1067 1.65517 9.44821 1.65517ZM17.1723 16C16.9537 15.9971 16.7449 15.909 16.5903 15.7544C16.4357 15.5999 16.3476 15.391 16.3448 15.1724C16.3448 13.0207 15.1751 11.5862 9.44821 11.5862C3.72131 11.5862 2.55166 13.0207 2.55166 15.1724C2.55166 15.3919 2.46446 15.6024 2.30926 15.7576C2.15406 15.9128 1.94356 16 1.72407 16C1.50458 16 1.29408 15.9128 1.13888 15.7576C0.983676 15.6024 0.896484 15.3919 0.896484 15.1724C0.896484 9.93104 6.88821 9.93104 9.44821 9.93104C12.0082 9.93104 17.9999 9.93104 17.9999 15.1724C17.9971 15.391 17.909 15.5999 17.7544 15.7544C17.5998 15.909 17.3909 15.9971 17.1723 16Z" fill="#0088D0"/>
              </svg>
            </div>
            <p className='logged-user-name'>{user.firstName} {user.lastName}</p>
          </div>
        </>}
      </ul>
      
    </div>
  );
};

export default Navigation;
