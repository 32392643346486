export function checkStockForRole(user, countries, role) {
  const filteredRole = user && user.stockRoles ? user.stockRoles.filter(item => item.roleName.toLowerCase() === role) : [];

  const newCountries = countries.reduce((acc, current) => {
    const country = { ...current };

    if (!country.stockInformation) {
      acc.push(country);

      return acc;
    }

    const stocks = country.stockInformation.map(stock => {
      const foundedStock = filteredRole.find(filterStock => stock.id === filterStock.stockId);

      if (!foundedStock) return stock;
    }).filter(item => item);

    country.stockInformation = stocks;

    acc.push(country);

    return acc;
  }, []);

  return newCountries;
}
