import React, { useState } from 'react';
import Button from 'library/common/commonComponents/Button/Button';
import strings from 'resources/locales/Translate';
import AssignmentCancelPopup from "../Assignment/AssignmentCancelPopup.component";


const Confirmation = (props) => {
    const {
        setActiveStep,
        onContinueBooking, 
        onCancelClick, 
        isLoading,
        hasAccess
    } = props;

    return(
        <>
        <div className='row mt-2'>
            <div className='col-12'>
                <p>{strings.zmccConfirmationPage}</p>
            </div>
            
        </div>
        <div>
            <div className='save-cancel-next-button-adjust mt-2'>
                <div  className='save-next-button-alignment'>
                <Button
                value={strings.back}
                onClick={() => props.setActiveStep(1)}
                bgFill={false}
                styleClass='add-button ml-2 save-next-pev-button'
            /> 
            <Button
                value={strings.executeBooking}
                onClick={() => props.saveZmccSemData('execute', setActiveStep)}
                bgFill={true}
                styleClass='add-button execute-button-widith ml-2'
                disabled={!hasAccess}
            />
                </div>
            
            <Button
                value={strings.cancel}
                onClick={() => props.cancelSemZmccLoanData()}
                bgFill={false}
                styleClass='add-button save-next-pev-button'
            />
            </div>
            

        </div>
        <AssignmentCancelPopup 
          id ='cancel-zmcc-loan-data-modal'
          onCancelClick={onCancelClick}
          onContinueBooking={onContinueBooking}
          isLoading={isLoading}
       />
        </>
        
    )
}

export default Confirmation;