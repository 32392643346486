import { connect } from 'react-redux';

import Statistics from './Statistics.component';
import { fetchKitStatistics } from '../../KitDetailsAction';

const mapStateToProps = ({ languageReducer }) => ({
  language: languageReducer.language,
});

export default connect(mapStateToProps, { fetchKitStatistics })(Statistics);
