import { connect } from 'react-redux';

import { fetchMasterData, fetchMasterDataPost } from 'library/common/commonActions/MasterDataActions';
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { bookNowHandler, fetchCartDataFromLocalStorage } from 'modules/LoanList/LoanListActions';
import { fetchWorkflowData } from 'modules/Stock/CreateStock/Tabs/Workflows/Workflows.actions';
import { fetchKitListForHomepage, fetchSubMenuOfZmccRequest, getAllScheduledEventList, checkIsOperator} from 'modules/Homepage/HomepageActions';
import { fetchRefurbishmentStocks } from 'modules/Administration/RefurbishmentFees/RefurbishmenFess.action';
import { getListOfUnexecutedSemTransaction } from 'modules/ZmccRequest/SEMBooking/SemBooking.actions';

import Homepage from "./Homepage";

const mapStateToProps = state => {
  return {
    language: state.languageReducer,
    authentication: state.authReducer,
    homepage: state.homepage,
    appActions: state.appActionsReducer,
  }
};

export default connect(mapStateToProps, {
  fetchMasterData,
  fetchMasterDataPost,
  fetchKitListForHomepage,
  toggleActionMessage,
  bookNowHandler,
  fetchCartDataFromLocalStorage,
  fetchWorkflowData,
  fetchRefurbishmentStocks,
  fetchSubMenuOfZmccRequest,
  getListOfUnexecutedSemTransaction,
  getAllScheduledEventList,
  checkIsOperator
})(Homepage);
