import { useEffect } from 'react';
import $ from 'jquery';

import { saveToStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';

export const useArchive = ({
  data,
  archiveList,
  fetchArchiveListData,
}) => {
  useEffect(() => {
    saveToStorage(identifiers.filtersShop, data.filter);
    fetchArchiveListData(data);
  }, [data, fetchArchiveListData]);

  const openExportArchiveList = () => {
    $('#export-archive-list').modal('show');
  };

  const isShowArchiveList = archiveList && archiveList.content && archiveList.content.length > 0;

  return {
    openExportArchiveList,
    isShowArchiveList,
  };
}
