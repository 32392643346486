export const useStatusOfSample = props => {
  const STATUS_OF_SAMPLE = {
    DISCARD_OR_INACTIVE: 2,
    HOLD: 3,
    RETURN: 1,
  };

  const handleForm = ({ values, field, formName }) => {
    props.updateSEMForm({
      [formName]: {
        ...props.formValue[formName],
        formData: {
          ...((props.formValue[formName] && props.formValue[formName].formData) || {}),
          ...values,
        },
      },
    });
    props.setUnsavedChanges(true);
  };

  const handleNonFormComponent = (values, field, formName) => {
    const { formValue, user } = props;
    const { sampleDeatilsFrom } = formValue;
    let dependentValues = {};
    if (field === 'statusOfSampleId' && values === STATUS_OF_SAMPLE.HOLD) {
      dependentValues = { statusOfSampleSolved: false };
    }
    if (
      field === 'statusOfSampleSolved' &&
      formName === 'sampleDeatilsFrom' &&
      values === true &&
      !sampleDeatilsFrom.formData.completedByName
    ) {
      dependentValues = { completedByName: `${user.firstName} ${user.lastName}` };
    }

    props.updateSEMForm({
      [formName]: {
        ...props.formValue[formName],
        formData: {
          ...((props.formValue[formName] && props.formValue[formName].formData) || {}),
          [field]: values,
          [`${field}Error`]: null,
          ...dependentValues,
        },
      },
    });
  };

  const onEditCompleted = () => {
    const { user, formValue } = props;
    const { sampleDeatilsFrom } = formValue;
    props.setEditCompltedBy(true);
    if (sampleDeatilsFrom.formData.completedByName) {
      handleNonFormComponent(sampleDeatilsFrom.formData.completedByName, 'completedByName', 'sampleDeatilsFrom');
    } else {
      handleNonFormComponent(`${user.firstName} ${user.lastName}`, 'completedByName', 'sampleDeatilsFrom');
    }
  };

  return {
    handleForm,
    handleNonFormComponent,
    onEditCompleted,
  };
};
