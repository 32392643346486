import React from 'react';

import DoughnutChart from 'library/common/commonComponents/DoughnutChart';

import { useSatisfactionIndexChart } from './SatisfactionIndexChart.hook';

const SatisfactionIndexChart = ({ satisfactionIndex, legend }) => {
  const {
    data,
    options,
  } = useSatisfactionIndexChart({ satisfactionIndex });

  return (
    <div className='satisfaction-chart-container'>
      <div className='satisfaction-chart-wrapper'>
        <DoughnutChart data={data} hideLegend={true} additionalOptions={options} />
        <div className='text-inside'>
          <span>{satisfactionIndex || 0}%</span>
        </div>
      </div>
      <div className='custom-legend'>
        <span>{legend}</span>
      </div>
    </div>
  );
}

export default SatisfactionIndexChart;
