import React from 'react';
import toArray from 'lodash/toArray';

import strings from 'resources/locales/Translate';
import ItemsPerPage from 'library/common/commonComponents/ItemsPerPage/ItemsPerPage';
import ExpandableTable from 'library/common/commonComponents/Table/ExpandableTable';
import SearchField from 'library/common/commonComponents/SearchField/SearchField';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import Pagination from 'library/common/commonComponents/Pagination/Pagination';
import Button from 'library/common/commonComponents/Button/Button';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';

import { useShop } from '../../Shop.hook';
import { dataTable } from '../../dataTable';
import DataExpandableTable from '../../Components/DataExpandableTable';
import { singleItemsOptions, itemsPerPage  } from '../../Shop.constants';
import '../../Shop.styles.scss';
import Filter from '../../Components/Filter';
import ExportTable from '../../Components/ExportTable';
import CancelReservationPopup from '../../Components/CancelReservationPopup';
import OrderPopup from '../../Components/OrderPopup';
import { useObservations } from './Observations.hook';
import { observationsListBreadcrumbs } from './Observations.constants';

export default function Observations({
  user,
  observationsList,
  countries,
  categoriesAndSystems,
  fetchMasterData,
  fetchObservationsListData,
  appActions,
  exportObservationsList,
  addKitToObservationList,
  removeKitFromObservationList,
  reserveKit,
  orderKit,
  cancelKitReservation,
  language,
  fetchMarketplaceCountryList,
  marketplaceCountry
}) {
  document.title = 'Shop';
  const { isLoading, displayActionMessage, type, message } = appActions;
  const {
    isShowFilter,
    data,
    handleSearchChange,
    showOrHideFilter,
    handleFilterChange,
    resetFilters,
    handleSort,
    handleItemsPerPage,
    handlePageChange,
    systemsClass,
  } = useShop({
    fetchMasterData,
    countries,
    categoriesAndSystems,
    language,
    fetchMarketplaceCountryList,
    marketplaceCountry
  });
  const {
    openExportObservationsList,
    handleClickOnObserve,
    handleClickOnStopObserve,
    handleClickOnReserveKit,
    openCancelReservatioPopup,
    closeCancelReservatioPopup,
    handleClickOnCancelReservation,
    isShowObservationsList,
    isConfirmLoading,
    errorReservation,
    isReservedKit,
    selectedKitId,
    openOrderPopup,
    updateObservationList,
  } = useObservations({
    data,
    observationsList,
    fetchObservationsListData,
    addKitToObservationList,
    removeKitFromObservationList,
    reserveKit,
    cancelKitReservation,
  });

  return (
    <div>
      {displayActionMessage && <ActionMessage type={type} message={strings[message]} />}
      <div className='container-fluid mb-3'>
        <Breadcrumbs data={observationsListBreadcrumbs} />
        <div className='row no-gutters align-items-center'>
          <h1 className='col-md col-12 kit-title'>{strings.myObservations}</h1>
          <div className='col-md-auto col mr-3'>
            <div className='input-wrapper'>
              <SearchField onChange={handleSearchChange} initialValue={data.filter.searchTerm} />
            </div>
          </div>
          <div className='col-auto'>
            <Button
              dataTest='shop-list-filter-button'
              bgFill={false}
              iconName='filter'
              onClick={showOrHideFilter}
              value={isShowFilter ? strings.hideFilter : strings.showFilter}
              styleClass='filter-button'
            />
          </div>
        </div>
      </div>
      <Filter
        isShowFilter={isShowFilter}
        countries={countries}
        categoriesAndSystems={systemsClass}
        handleFilterChange={handleFilterChange}
        selected={data.filter}
        resetFilters={resetFilters}
      />
      <div className='shop-table'>
        <div className='container-fluid shop-container mb-3'>
          <div className='mb-3'>
            <div className='title'>{isShowObservationsList ? observationsList.totalElements : 0} {strings.results}</div>
          </div>
          {isShowObservationsList ? (
            <ExpandableTable
              user={user}
              cols={dataTable()}
              rows={observationsList.content}
              sortFields={data.sort}
              handleSort={handleSort}
            >
              <DataExpandableTable
                openOrderPopup={openOrderPopup}
                handleClickOnReserveKit={handleClickOnReserveKit}
                handleClickOnCancelReservation={openCancelReservatioPopup}
                handleClickOnObserve={handleClickOnObserve}
                handleClickOnStopObserve={handleClickOnStopObserve}
              />
            </ExpandableTable>
          ) : (
            <div className='d-flex justify-content-center mb-3'>
              <strong>{strings.noDataFoundText}</strong>
            </div>
          )}
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row no-gutters'>
          <div className='col-md-automb-3'>
            <Button
              dataTest='export-list-button'
              bgFill={false}
              iconName='download'
              value={strings.exportList}
              styleClass='mt-0'
              onClick={openExportObservationsList}
            />
          </div>
          {isShowObservationsList && (
            <div className='col d-flex justify-content-end mb-3'>
              <ItemsPerPage
                itemsPerPage={toArray(itemsPerPage)}
                handleItemsPerPage={handleItemsPerPage}
                value={itemsPerPage[data.size]}
              />
              <Pagination
                currentPage={data.page - 1}
                totalPages={observationsList.totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
      {isLoading && <div className='loader' />}
      <CancelReservationPopup
        id='observation-list-cancel-reservation-popup'
        handleConfirmation={handleClickOnCancelReservation}
        closePopup={closeCancelReservatioPopup}
        isLoading={isConfirmLoading}
        error={errorReservation}
      />
      <OrderPopup
        id='observation-order-popup'
        handleClickOnConfirmation={orderKit}
        handleClickOnReserve={handleClickOnReserveKit}
        updateList={updateObservationList}
        kitId={selectedKitId}
        isReservedKit={isReservedKit}
      />
      <ExportTable
        id='export-observetions-list'
        countries={countries}
        categoriesAndSystems={systemsClass}
        singleItemsOptions={singleItemsOptions()}
        filters={data.filter}
        exportShopList={exportObservationsList}
        marketplaceCountry={marketplaceCountry}
      />
    </div>
  );
}
