import React from 'react';
import strings from 'resources/locales/Translate';

import './breadcrumbStyle.scss';
import { NavLink } from 'react-router-dom';

const Breadcrumbs = props => {
  const goBack = () => {
    props.history && props.history.goBack();
  };

  return (
    <div className='breadcrumbs-container'>
      <nav aria-label='breadcrumb'>
        <ol className='breadcrumb bg-white px-0'>
          {props.data.map((item, key) => {
            if (key === props.data.length - 1) {
              return (
                <li
                  key={key}
                  className={`breadcrumb-item${item.action !== 'goBack' ? ' active' : ''}`}
                  aria-current='page'
                  onClick={item.action === 'goBack' ? goBack : null}
                >
                  {strings[item.name] ? strings[item.name] : item.name}
                </li>
              );
            } else if (item.action && item.action === 'goBack') {
              return (
                <li key={key} className='breadcrumb-item' aria-current='page' onClick={goBack}>
                  {strings[item.name] ? strings[item.name] : item.name}
                </li>
              );
            } else {
              return (
                <li key={key} className='breadcrumb-item'>
                  {item.url ?(
                    <NavLink to={item.url}>{strings[item.name] ? strings[item.name] : item.name}</NavLink>
                  ) : (
                    strings[item.name] ? strings[item.name] : item.name
                  )}
                </li>
              );
            }
          })}
        </ol>
      </nav>
    </div>
  );
};

export default Breadcrumbs;
