import React from 'react';
import get from 'lodash/get';

import strings from 'resources/locales/Translate';
import Modal from 'library/common/commonComponents/Modal';

const SaveAllPermissionsPopup = ({ id, filters, role, closePopup, handleUpdateAllUserRolesForStock, loading }) => {
  const businessUnit = get(filters, 'businessUnitFullValue.name');
  const country = get(filters, 'countryFullValue.name') || '-';
  const lastName = get(filters, 'userFullValue.lastName');
  const firstName = get(filters, 'userFullValue.firstName');
  const email = get(filters, 'userFullValue.email');
  const user = `${lastName}, ${firstName} (${email})`;

  return (
    <Modal
      id={id}
      title={strings.setPermissionForAll}
      confirmAction={handleUpdateAllUserRolesForStock}
      titleOfCancel={strings.cancel}
      confirmTitle={strings.setForAllStocks}
      loading={loading}
      onCloseClick={closePopup}
      disableBackgroundClose
      buttonStyles='w-100'
      className='delete-all-permision-adjust-width'
    >
      <h6 className='font-bold mb-4'>{strings.doYouWantToSetPermission(role)}</h6>
      <div className='d-flex mb-1'>
        <div className='w-50'>{strings.businessUnit}:</div>
        <div className='w-50'>{businessUnit}</div>
      </div>
      <div className='d-flex mb-1'>
        <div className='w-50'>{strings.country}:</div>
        <div className='w-50'>{country}</div>
      </div>
      <div className='d-flex mb-1'>
        <div className='w-50'>{strings.userLabelText}:</div>
        <div className='w-50'>{user}</div>
      </div>
    </Modal>
  );
};

export default SaveAllPermissionsPopup;
