import React, { Component } from 'react';
import { FormComponent } from 'library/common/commonComponents/FormComponent/Optimized';
import strings from 'resources/locales/Translate';
import IconButton from 'library/common/commonComponents/IconButton';
import { componentsModel } from './SingleLoanCart.constants';
import { checkStockForRole } from 'library/utilities/checkStockForRole';
import Button from 'library/common/commonComponents/Button/Button';
import { validateForm } from 'library/utilities/ValidateForm.util';
import Icon from 'library/common/commonComponents/Icon/Icon';

export class SingleLoanCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayForm: !!props.opened,
      componentsForm: {
        formData: {
          quantity:1
        },
      },
      isEditComponent: props.opened,
    };
  }

  handleForm = ({ values, field, formName }) => {
  if (field === 'materialNumber') { 
    values['materialNumber'] = values['materialNumber'].replace(/\s/g, '');
    this.setState(prevState => ({
      [formName]: {
        ...prevState[formName],
        formData: {
          ...((prevState[formName] && prevState[formName].formData) || {}),
          ...values,
        },
      },
    }));
  }

    this.setState(prevState => ({
      [formName]: {
        ...prevState[formName],
        formData: {
          ...((prevState[formName] && prevState[formName].formData) || {}),
          ...values,
        },
      },
    }));
  };

  renderKit = (item, index) => (
    <div className='selected-kit-container' key={`${item.materialNumber}-${index}`}>
      <p>
        {strings.positionText} {index + 1}
      </p>
      <h5 className='kit-info'>{`${strings.materialNumber}: ${item.materialNumber}`}</h5>
      <p className='kit-info'>{`${strings.descriptionText}: ${item.descriptionText}`}</p>
      <p className='kit-info'>{`${strings.quantity}: ${item.quantity}`}</p>
    </div>
  );
  onAddItem = () => {
    this.setState({ displayForm: true });
  };
  onSaveItem = () => {
    const newFormData = validateForm({
      form: this.state.componentsForm,
      formName: 'componentsForm',
      model: componentsModel,
    });
    if (newFormData.isFormValid) {
      const { formData } = newFormData;
      this.props.onSelect({
        materialNumber: formData.materialNumber,
        descriptionText: formData.descriptionText,
        quantity: formData.quantity
      });
      this.setState({
        componentsForm: { formData: {quantity:1} },
        displayForm: false,
      });
    } else {
      this.setState({ componentsForm: newFormData });
    }
  };

  setIsEditComponent = () => {
    this.setState({ isEditComponent: true });
  };
  render() {
    const { componentsForm, displayForm } = this.state;
    const { selectedKits } = this.props;
    const disableButton = selectedKits.length >= 20;
    const { form, formName, model, onChange, activeStep,expectedLoanFees ,language} = this.props;
    const { isEditComponent } = this.state;
    const enText1 = ` Please expect refurbishment fees for single loans which are longer than ${expectedLoanFees && expectedLoanFees[0] && expectedLoanFees[0].numberOfDaysSingleLoanFree} days.`
    const email = expectedLoanFees && expectedLoanFees.length > 0 && expectedLoanFees[0].stockEmail;
    const enText2 = `We charge ${expectedLoanFees && expectedLoanFees[0]!= null && expectedLoanFees[0].costFactorSingleLoan?expectedLoanFees[0].costFactorSingleLoan*100: '0'} % of the loan value per month. To get more information, please contact`

    const deText1 = `Für diese Einzelleihe werden Aufarbeitungsgebühren berechnet, wenn die Leihe länger als  ${expectedLoanFees && expectedLoanFees[0] && expectedLoanFees[0].numberOfDaysSingleLoanFree} Tage dauert.`
    const deText2 = `Wir berechnen Ihnen ${expectedLoanFees && expectedLoanFees[0]!= null && expectedLoanFees[0].costFactorSingleLoan?expectedLoanFees[0].costFactorSingleLoan*100: '0'} % des Leihwertes pro Monat Leihe. Für weitere Informationen wenden Sie sich bitte an`
    const showMessage1 = language.language === 'en'?enText1:deText1;
    const showMessage2 = language.language === 'en'?enText2:deText2;
    return (
      <>
        <>
          <div className='d-flex align-items-start'>
            <h4 className='subtitle-loan mb-3'>{strings.materialNumber}</h4>
            {!isEditComponent && <IconButton iconName='edit' value={strings.edit} onClick={this.setIsEditComponent} buttonStyles='mt-1 ml-3'/>}
          </div>
          {isEditComponent ? (
            <FormComponent formName={formName} model={model} formValue={form.formData} onChange={onChange} />
          ) : (
            <div className='mb-3'>
              <div className='label-field'>{strings.location}</div>
              <div className='mb-3'>
                {form.formData && form.formData.stockFullValue
                  ? form.formData.stockFullValue.locationName
                  : 'No location'}
              </div>
            </div>

          )}
          {activeStep && expectedLoanFees && expectedLoanFees[0] && expectedLoanFees[0].loanFeeActivated &&
          <div className='col-12 col-md-6 singleLoan-Message-block'>
             <div className='d-flex'>
                      <Icon name='info' width={18} height={18} fill='#0088D0' className='mt-2'/>
                      <p className='mb-2 ml-1 mt-2' style={{fontWeight: '700'}}>{strings.costInformation}</p>
                    </div>
            <ul >
            <li className='mb-2 ml-3'>{showMessage1}</li>
            <li className='ml-3'>{showMessage2} <a href={`mailTo:${email}`} style={{textDecoration:'underline'}}>{email}.</a></li>
            </ul>
          </div>
          }
        </>
        {selectedKits && !!selectedKits.length
          ? selectedKits.map((kit, i) => this.renderKit(kit, i))
          : !displayForm && (
              <div className='d-flex justify-content-center mb-3'>
                <strong>{strings.kitsNotSelected}</strong>
              </div>
            )}
        {displayForm && (
          <>
            <FormComponent
              formName='componentsForm'
              model={componentsModel}
              formValue={componentsForm.formData}
              onChange={this.handleForm}
            />
          </>
        )}
        <Button
          bgFill={false}
          iconName='plus'
          value={strings.addItem}
          styleClass='w-auto mt-0'
          onClick={displayForm ? this.onSaveItem : this.onAddItem}
          disabled={disableButton}
        />
        <p className='mt-3'>{strings.addSingleLoanMaterial}</p>
      </>
    );
  }
}

export default SingleLoanCart;
