import Validators from "library/utilities/Validators";

export const demoCheckListModel = [
    {
        label: 'abnormalities',
        value: '',
        type: 'radio-button',
        placeholder: '',
        field: 'demoAbnormalities',
        validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
        required: true,
        styleClass: 'col-sm-12',
        options: [
          {
            id: 'yes',
            value: 'yes',
          },
          {
            id: 'no',
            value: 'no',
          },
        ],
      },

      {
        label: 'commentText',
        value: '',
        type: 'text',
        placeholder: '',
        field: 'demoAbnormalitiesComment',
        validators: [],
        required: false,
        styleClass: 'col-sm-12',
        isDisplayed: true
      },
      {
        label: 'temporaryFilesBackup',
        value: '',
        type: 'radio-button',
        placeholder: '',
        field: 'demoBackupOfTempCustFiles',
        validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
        required: true,
        styleClass: 'col-sm-12',
        options: [
          {
            id: 'yes',
            value: 'yes',
          },
          {
            id: 'no',
            value: 'no',
          },
        ],
      },

      {
        label: 'commentText',
        value: '',
        type: 'text',
        placeholder: '',
        field: 'demoBackupOfTempCustFilesComment',
        validators: [],
        required: false,
        styleClass: 'col-sm-12',
        isDisplayed: true
      },
      {
        label: 'systemDemoReady',
        value: '',
        type: 'radio-button',
        placeholder: '',
        field: 'systemIsDemoReady',
        validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
        required: true,
        styleClass: 'col-sm-12',
        options: [
          {
            id: 'YES',
            value: 'yes',          },
          {
            id: 'RESTRICTED',
            value: 'yesButRestricted',
          },
          {
            id: 'NO',
            value: 'no',
          },
        ],
      },

      {
        label: 'commentText',
        value: '',
        type: 'text',
        placeholder: '',
        field: 'systemIsDemoReadyComment',
        validators: [],
        required: false,
        styleClass: 'col-sm-12',
      },
      
]