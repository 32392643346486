import Validators from 'library/utilities/Validators';

export const mappingModel = [
    {
        label: 'accountId',
        value: '',
        type: 'text',
        field: 'accountId',
        validators: [{ check: Validators.required, message: 'requiredErrorMessage' },
        { check: Validators.number, message: 'invalidNumberError' },],
        required: true,
        styleClass: 'col-12',
        idKey: 'accountId',
    },
    {
        label: 'accountName',
        value: '',
        type: 'text',
        field: 'accountName',
        validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
        required: true,
        styleClass: 'col-12',
        idKey: 'accountName',
    },
    {
        label: 'countryLand',
        value: '',
        type: 'dropdown',
        placeholder: 'pleaseSelect',
        field: 'countryId',
        validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
        required: true,
        options: [],
        hasSection: false,
        multiSelect: false,
        filter: true,
        idKey: 'id',
        displayValue: [
            { key: 'name', separator: '' }
        ],
        excludeData: [],
        styleClass: 'col-12',
    },
    {
        label: 'postalCode',
        value: '',
        type: 'text',
        placeholder: '',
        field: 'postalCode',
        validators: [{ check: Validators.required, message: 'requiredErrorMessage' },],
        required: true,
        styleClass: 'col-12',
    },
    {
        label: 'city',
        value: '',
        type: 'text',
        placeholder: '',
        field: 'city',
        validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
        required: true,
        styleClass: 'col-12',
    },
    {
        label: 'street',
        value: '',
        type: 'text',
        placeholder: '',
        field: 'street',
        validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
        required: true,
        styleClass: 'col-12',
    },
    {
        label: 'houseNumber',
        value: '',
        type: 'text',
        placeholder: '',
        field: 'houseNumber',
        required: false,
        styleClass: 'col-12',
    },
    {
        label: 'comment',
        value: '',
        type: 'text',
        placeholder: '',
        field: 'comment',
        required: false,
        styleClass: 'col-12',
    },
];