import React, { useEffect } from 'react';

import * as $ from 'jquery';
import ToggleSwitch from 'library/common/commonComponents/ToggleSwitch';
import strings from 'resources/locales/Translate';
import Icon from '../../../../../library/common/commonComponents/Icon/Icon';

export const useAddressConfigrationList = ({
  masterData,
  addAddressconfigrationList,
  enableAndDisableConfig,
  deletingConfigration,
}) => {
  useEffect(() => {
     addAddressconfigrationList();
  }, [addAddressconfigrationList]);
  const openAddAddressPopup = () => {
    $('#address-config-modal').modal('show');
  };

  const getTableCols = () => [
    {
      title: strings.businessUnit,
      name: 'systemClass',
      isSort: false,
      render: item => {
        return <span>{item.businessUnitName}</span>;
      },
    },
    {
      title: strings.country,
      name: 'systemMainComponent',
      isSort: false,
      render: item => {
        return <span>{item.countryName}</span>;
      },
    },
    {
      title: '',
      name: 'buttons',
      isSort: false,
      render: item => {
        const { activated, id } = item;

        return (
          <div onClick={() => enableAndDisableConfig(id, activated)}>
            <ToggleSwitch checked={activated} yes={strings.on} no={strings.off} />
          </div>
        );
      },
    },
    {
      title: '',
      name: 'buttons',
      isSort: false,
      render: item => {
        const { activated, id } = item;

        return (
          <div>
            <button type='button' className='delete-button' onClick={() => deletingConfigration(id)}>
              <Icon name='delete' width={24} height={24} fill='#0088d0' className='mr-2' />
              {strings.delete}
            </button>
          </div>
        );
      },
    },
  ];
  const cols = getTableCols();
  return {
    openAddAddressPopup,
    cols,
  };
};
