import React, { Component } from 'react';

import { FormComponent } from 'library/common/commonComponents/FormComponent/Optimized';
import strings from 'resources/locales/Translate';
import Button from 'library/common/commonComponents/Button/Button';
import { addingCourseLoanItem } from './CourseLoanCart.constants';
import { validateForm } from 'library/utilities/ValidateForm.util';
import { checkStockForRole } from 'library/utilities/checkStockForRole';

import CourseLoanDatePicker from '../CourseLoanDatePicker/CourseLoanDatePicker';

export class CourseLoanCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayForm: !!props.opened,
      courseLoanItemForm: {
        formData: {
          stock: props.overtakenDataForCourseLoan ? props.overtakenDataForCourseLoan.stocks : null,
          stockFullValue: props.overtakenDataForCourseLoan ? props.overtakenDataForCourseLoan.stocksFullValue : null,
          categories: props.overtakenDataForCourseLoan ? props.overtakenDataForCourseLoan.systemClass : null,
          categoriesFullValue: props.overtakenDataForCourseLoan ? props.overtakenDataForCourseLoan.systemClassFullValue : null,
        },
      },
    };
  }

  handleForm = ({ values, field, formName }) => {
    if (field === 'stock') {
      this.props.handleWorkflowData(values.stock);
      this.props.checkMedusStock(values);
      this.props.checkMedTaiwanWarehouse(values)
    }
    this.setState(prevState => ({
      [formName]: {
        ...prevState[formName],
        formData: {
          ...((prevState[formName] && prevState[formName].formData) || {}),
          ...values,
        },
      },
    }));
  };

  addingCourseLoanItem = () => {
    const { countries, user, selectedKits } = this.props;
    const model = [...addingCourseLoanItem];
    let updatedCountry = null;
    if (countries && selectedKits && selectedKits.length) {
      const country = countries.find(item => item.id === selectedKits[0].stock.countryId) || {};
      const stock = country.stockInformation.filter(item => item.id === selectedKits[0].stock.id);
      updatedCountry = [{ ...country, stockInformation: stock }];
    }
    if (updatedCountry) {
      model[0].options = checkStockForRole(user, updatedCountry, 'inbound staff');
    } else if (countries) {
      model[0].options = checkStockForRole(user, countries, 'inbound staff');
    }
    return model;
  };

  onAddItem = () => {
    this.setState({ displayForm: true });
  };

  onSaveItem = () => {
    const newFormData = validateForm({
      form: this.state.courseLoanItemForm,
      formName: 'courseLoanItemForm',
      model: this.addingCourseLoanItem(),
    });
    if (newFormData.isFormValid) {
      const { formData } = newFormData;
      this.props.onSelect({
        stock: formData.stockFullValue,
        categories: formData.categoriesFullValue,
        quantity: formData.quantity,
        productId: formData.productId,
      });
      this.setState({
        courseLoanItemForm: {
          formData: {
            stock: formData.stock,
            stockFullValue: formData.stockFullValue,
          }
        }, displayForm: false
      });
    } else {
      this.setState({ courseLoanItemForm: newFormData });
    }
  };

  handleDateChange = (type, item) => (date, index) => {
    this.props.handleDateChange(date, index, item, type, 'courseLoan');
  };

  renderKit = (item, index) => (
    <div className='selected-kit-container' key={`${item.productId}-${index}`}>
      <p>
        {strings.positionText} {index + 1}
      </p>
      <h5 className='kit-title'>{`${strings.systemClassSystem}: ${item.categories.systemMainComponentName}`}</h5>
      <p className='kit-info'>{`${strings.productId}: ${item.productId}`}</p>
      <p className='kit-info'>{`${strings.quantity}: ${item.quantity}`}</p>
      <p className='kit-info'>{`${strings.location}: ${item.stock.locationName}`}</p>
      {!!this.props.handleDateChange && (
        <div className='d-flex align-items-center mb-4'>
          <CourseLoanDatePicker
            label={strings.reservationFrom}
            selectedDate={item.reservationFrom}
            onChangeDate={this.handleDateChange('reservationFrom')}
            itemIndex={index}
            maxDate={item.reservationTo ? new Date(item.reservationTo) : new Date()}
            activeStartDate={item.reservationFrom ? new Date(item.reservationFrom) : new Date()}
          />
          <CourseLoanDatePicker
            label={strings.reservationTo}
            selectedDate={item.reservationTo}
            onChangeDate={this.handleDateChange('reservationTo', item)}
            itemIndex={index}
            minDate={item.reservationFrom ? new Date(item.reservationFrom) : new Date()}
            activeStartDate={item.reservationTo ? new Date(item.reservationTo) : new Date()}
          />
        </div>
      )}
    </div>
  );

  render() {
    const { courseLoanItemForm, displayForm } = this.state;
    const { selectedKits } = this.props;
    return (
      <>
        <h4 className='subtitle-loan mb-3'>{strings.selectedKits}</h4>
        {selectedKits && !!selectedKits.length
          ? selectedKits.map((kit, i) => this.renderKit(kit, i))
          : !displayForm && (
            <div className='d-flex justify-content-center mb-3'>
              <strong>{strings.kitsNotSelected}</strong>
            </div>
          )}
        {displayForm && (
          <>
            <h4 className='subtitle-loan mb-3'>{strings.addKit}</h4>
            <FormComponent
              formName='courseLoanItemForm'
              model={this.addingCourseLoanItem()}
              formValue={courseLoanItemForm.formData}
              onChange={this.handleForm}
            />
          </>
        )}
        <Button
          data-test='go-to-calendar-button'
          bgFill={false}
          iconName='plus'
          value={strings.addItem}
          styleClass='w-auto mt-0'
          onClick={displayForm ? this.onSaveItem : this.onAddItem}
        />
      </>
    );
  }
}

export default CourseLoanCart;
