import { batchActions } from 'redux-batched-actions';

import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { scrollToTop } from 'library/utilities/scrollActions';
import {
  fetchMasterDataService,
  fetchMasterDataServicePost,
} from 'library/api/master-data.service';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';

import { SET_NOTIFICATION_SETTINGS } from './NotificationSettings.constants';

export const getInitialData = () => async (dispatch, getState) => {
  try {
  const { notificationSettings } = getState().notificationSettingsReducer;
  dispatch(changeLoader(true));

  if (!notificationSettings.length) {
    await dispatch(getNotificationSettings());
  }

  dispatch(changeLoader(false));
  } catch (err)  {
    dispatch(changeLoader(false));
  }
};

export const getNotificationSettings = () => async dispatch => {
  try {
  const response = await fetchMasterDataService(URLS.notificationSettings);

  if (response && response.status === 200) {
    dispatch({
      type: SET_NOTIFICATION_SETTINGS,
      payload: response.data,
    });
  }
  } catch (err)  {
    dispatch({
      type: SET_NOTIFICATION_SETTINGS,
      payload: {},
    });
  }
};

export const changetNotificationSettings = dataToSend => async dispatch => {
  try {
  dispatch(changeLoader(true));

  const response = await fetchMasterDataServicePost(URLS.notificationSettings, dataToSend);

  if (response && response.status === 200) {
    await dispatch(getNotificationSettings());
  }

  dispatch(
    batchActions([
      dispatch(changeLoader(false)),
      dispatch(toggleActionMessage(true, 'success', 'notificationSettingsUpdatedSucessfully')),
    ]),
  );
  scrollToTop(500);
  } catch (err)  {
    dispatch(
      batchActions([
        dispatch(changeLoader(false)),
        dispatch({
          type: SET_NOTIFICATION_SETTINGS,
          payload: {},
        }),
        dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage')),
      ]),
    );
    scrollToTop(500);
  }
};
