import { useEffect, useCallback, useState } from 'react';
import $ from 'jquery';

export const usePurchaseRequests = ({
  appActions,
  getInitialPurchaseRequestsAndPopupData,
  getPurchaseRequests,
  purchaseRequests,
  totalPurchaseRequestsItems,
  resetPurchaseRequests,
  getCountryList,
  language,
  deletePurchaseRequests,
}) => {
  const [isLoadingRequests, setIsLoadingRequests] = useState(false);
  const [cardInfo, setCardInfo] = useState({});
  const [deletionError, setDeletionError] = useState('');
  const [isLoadingDeletion, setIsLoadingDeletion] = useState(false);
  const { isLoading, displayActionMessage, type, message } = appActions;
  const isHasMoreItems = purchaseRequests.length && (purchaseRequests.length < totalPurchaseRequestsItems);

  useEffect(() => {
    getInitialPurchaseRequestsAndPopupData();

    return () => resetPurchaseRequests();
  }, [getInitialPurchaseRequestsAndPopupData, resetPurchaseRequests]);

  useEffect(() => {
    getCountryList();
  }, [language, getCountryList]);

  const loadMoreHandler = useCallback(async () => {
    setIsLoadingRequests(true);
    await getPurchaseRequests(Math.ceil(purchaseRequests.length / 20));
    setIsLoadingRequests(false);
  }, [purchaseRequests, getPurchaseRequests]);

  const handleOpenAddOrEditCardPopup = useCallback(() => {
    $('#add-purchase-requests-popup').modal('show');
  }, []);

  const handleClickOnCard = useCallback(request => {
    setCardInfo(request);
    $('#add-comment-popup').modal('show');
  }, []);

  const handleDelete = useCallback(async () => {
    if (deletionError) {
      setDeletionError('');
    }
    setIsLoadingDeletion(true);
    const { success } = await deletePurchaseRequests(cardInfo.purchaseId);

    if (success) {
      handleCloseConfirmDeletionModal();
    } else {
      setDeletionError('somethingWentWrongMessage');
    }
    setIsLoadingDeletion(false);
  }, [deletePurchaseRequests, cardInfo, deletionError]);

  const handleOpenConfirmDeletionModal = () => {
    $('#confirm-delete-modal').modal('show');
  };

  const handleCloseConfirmDeletionModal = () => {
    setDeletionError('');
    setCardInfo({});
  };

  return {
    isLoadingRequests,
    cardInfo,
    isLoading,
    displayActionMessage,
    type,
    message,
    setCardInfo,
    handleOpenAddOrEditCardPopup,
    loadMoreHandler,
    handleClickOnCard,
    isHasMoreItems,
    handleDelete,
    handleCloseConfirmDeletionModal,
    handleOpenConfirmDeletionModal,
    deletionError,
    isLoadingDeletion,
  };
}
