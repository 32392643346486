export const orderListBreadcrumbData = [
  {
    name:'homepage',
    url: '/home'
  },
  {
    name:'demoSale',
    url: '/demo-sale',
  },
  {
    name:'order',
  },
];
