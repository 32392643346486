import { connect } from 'react-redux';
import ZmccTransactionDetails from './ZmccTransactionDetails.component';
import {
  getWholeSemTransaction,
  fetchZmccUser,
  fetchZmccPriorites,
  fetchConnectedDemoLoans,
  saveAdjustDates,
  updateZmccTransaction
} from './ZmccTransactionDetails.actions';
import { fetchLoansIntended, updateSEMForm, isClickedOnAdInTodoTab } from 'modules/ZmccRequest/SEMBooking/SemBooking.actions';
import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';
import { createMessage, sendLogisticEmail } from 'modules/Transactions/TransactionDetails/TransactionDetailsActions';
import { fetchOperatorsForTransactions, fetchZmccUsersForSubscriber, fetchSampleAtList } from './ZmccTransactionDetails.actions';

const mapStateToProps = ({
  languageReducer,
  bookingReducer,
  authReducer,
  transactionDetailsReducer,
  appActionsReducer,
  zmccTransactionDetailsReducer,
}) => ({
  user: authReducer.user,
  language: languageReducer.language,
  countries: bookingReducer.labsLocationCountries,
  zmccTransactionStatuses: bookingReducer.zmccTransactionStatuses,
  zmccTransactionList: bookingReducer.zmccTransactionList,
  zmccCountries: bookingReducer.zmccCountries,
  zmccCategory: bookingReducer.zmccCategory,
  typesOfJobsList: transactionDetailsReducer.typesOfJobsList,
  appActions: appActionsReducer,
  zmccTransactionDetails: zmccTransactionDetailsReducer.zmccTransactionDetails,
  commentsData: transactionDetailsReducer.commentsData,
  damageLossReportData: transactionDetailsReducer.damageLossReportData,
  changeLog: transactionDetailsReducer.changeLog,
  formValues:{
    generalInformationForm: zmccTransactionDetailsReducer.generalInformationForm,
    schedulingInformationForm: zmccTransactionDetailsReducer.schedulingInformationForm,
    projectDetailsForm: zmccTransactionDetailsReducer.projectDetailsForm,
    customerDetilsForm: zmccTransactionDetailsReducer.customerDetilsForm,
    primaryPointOfContactForm: zmccTransactionDetailsReducer.primaryPointOfContactForm,
    customerBackgroundForm: zmccTransactionDetailsReducer.customerBackgroundForm,
    mainApplicationChecklistForm: zmccTransactionDetailsReducer.mainApplicationChecklistForm,
    sampleDeatilsFrom: zmccTransactionDetailsReducer.sampleDeatilsFrom,
    demoCenterInformationForm: zmccTransactionDetailsReducer.demoCenterInformationForm,
    additionalInformationForm: zmccTransactionDetailsReducer.additionalInformationForm,
    logisticsAndCoordinationForm: zmccTransactionDetailsReducer.logisticsAndCoordinationForm,
    todosListForm: zmccTransactionDetailsReducer.todosListForm,
    kitAttachments: zmccTransactionDetailsReducer.kitAttachments,
    kitAttachmentsToDelete: zmccTransactionDetailsReducer.kitAttachmentsToDelete,
    deleteTodoIds: zmccTransactionDetailsReducer.deleteTodoIds
  },
  zmccInformationTabData: zmccTransactionDetailsReducer.zmccInformationTabData
});

export default connect(mapStateToProps, {
  getWholeSemTransaction,
  fetchLoansIntended,
  fetchZmccUser,
  fetchZmccPriorites,
  fetchMasterData,
  fetchConnectedDemoLoans,
  createMessage,
  saveAdjustDates,
  updateSEMForm,
  updateZmccTransaction,
  fetchOperatorsForTransactions,
  fetchZmccUsersForSubscriber,
  sendLogisticEmail,
  isClickedOnAdInTodoTab,
  fetchSampleAtList
})(ZmccTransactionDetails);