import {
  FETCH_SYSTEM_CLASSES_BY_DUSINESS_UNIT,
  FETCH_BUSINESS_UNITS,
  FETCH_KITS_IMAGES,
  CLEAR_KITS_IMAGES,
} from './ManageKitPictures.constants';
  
const initialState = {
  businessUnits: [],
  systemClasses: {},
  kitImages: [],
};

const kitPicturesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BUSINESS_UNITS:
      return {
        ...state,
        businessUnits: action.payload,
      };
    case FETCH_SYSTEM_CLASSES_BY_DUSINESS_UNIT:
      return {
        ...state,
        systemClasses: action.payload,
      };
    case FETCH_KITS_IMAGES:
      return {
        ...state,
        kitImages: action.payload,
      };
    case CLEAR_KITS_IMAGES:
      return {
        ...state,
        kitImages: [],
      };
    default:
      return state;
  }
};
  
export default kitPicturesReducer;
  