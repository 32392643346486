import * as actionTypes from 'library/common/commonConstants/storeConstants';

const initialState = {
  loanAttachments: [],
};

const LoanAttachmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_OR_REMOVE_LOAN_ATTACHMENTS:
      return {
        ...state,
        loanAttachments: action.payload,
      };
    default:
      return state;
  }
};

export default LoanAttachmentsReducer;
