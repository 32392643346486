import Validators from 'library/utilities/Validators';

export const VrDetailsModel = [
    {
      label: 'windowsUserPassword',
      value: '',
      type: 'text',
      placeholder: 'windowsUserPassword',
      field: 'windowsUserPassword',
      validators: [],
      required: false,
      styleClass: 'col-sm-3',
    },
    {
      label: 'userName',
      value: '',
      type: 'text',
      placeholder: 'userName',
      field: 'vrDetailsUserName',
      validators: [],
      required: false,
      styleClass: 'col-sm-3',
    },
    {
      label: 'password',
      value: '',
      type: 'text',
      placeholder: 'password',
      field: 'vrDetailsPassword',
      validators: [],
      required: false,
      styleClass: 'col-sm-3',
      extraProps: {
        maxLength: 1000,
      },
    },
    {
      label: 'linkToClickableURL',
      value: '',
      type: 'text',
      placeholder: 'linkToClickableURL',
      field: 'linkToOnboardingMaterial',
      validators: [
        { check: Validators.url, message: 'urlErrorMessage' }
      ],
      required: false,
      styleClass: 'col-sm-3',
    },
  ];
  