import React from 'react';
import get from 'lodash/get';

import strings from 'resources/locales/Translate';

import {
  getIconByCondition,
  getIconByTransactionStatus,
  getIconByCurrentPosition,
  getIconFor24HourReservation,
} from 'library/utilities/getStatusAndConditionIcons';

const LateReturnsExpandableTable = props => {
  const {
    transactionKit,
    transactionStatus,
    transactionPosition,
    twentyFourHourReservation,
    returnDelivery,
    deliveryComment,
    comment,
  } = props.row || {};
  const hasCondition = !!get(transactionKit, 'transactionKit.kitInformation.kitCondition.kitConditionId');
  const hasStatus = !!get(transactionStatus, 'name');
  const hasCurrentPosition = !!get(transactionPosition, 'transactionPositionId');
  const isExpandable = true;

  return (
    <>
      <td />
      <td />
      <td colSpan='12'>
        <div className='d-flex flex-wrap mb-3'>
          {hasStatus && getIconByTransactionStatus(transactionStatus.transactionStatusId, isExpandable)}
          {hasCondition && getIconByCondition(transactionKit.kitInformation.kitCondition, isExpandable)}
          {hasCurrentPosition && getIconByCurrentPosition(transactionPosition, isExpandable, twentyFourHourReservation, returnDelivery)}
          {twentyFourHourReservation && getIconFor24HourReservation(isExpandable)}
        </div>
        <div className='d-flex flex-wrap mb-3'>
          <div>
            <strong>{strings.deliveryCommentText}</strong>
            <p className='mb-3'>{deliveryComment || 'No comment'}</p>
          </div>

          <div className='ml-3'>
            <strong>{strings.commentText}</strong>
            <p className='mb-3'>{comment || 'No comment'}</p>
          </div>
        </div>
      </td>
    </>
  );
};

export default LateReturnsExpandableTable;
