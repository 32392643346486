import React from 'react';

import strings from 'resources/locales/Translate';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import Steps from 'library/common/commonComponents/Steps/Steps';

import Assignment from './Components/Assignment';
import CustomerData from './Components/CustomerData';
import Confirmation from './Components/Confirmation';
import { useLLKitLoan } from './LLKiLoan.hook';

const LLKitLoan = props => {
  const {
    appActions,
    fetchInitialData,
    fetchCartDataFromLocalStorage,
    user,
    selectedKits,
    fetchKitDetails,
    fetchWorkflowData,
    history,
    clearForms,
    fetchTypeOfJobAssociatedWithKitSystemmainComponent,
  } = props;
  const { displayActionMessage, type, message, isLoading } = appActions;
  const {
    activeStep,
    isShowCheckbox,
    setActiveStep,
    enteredOpportunityId,
    handleEnteredOpportunityId,
    isOpportunityIdCrmError,
    setIsOpportunityIdCrmError,
  } = useLLKitLoan({
    user,
    fetchInitialData,
    fetchCartDataFromLocalStorage,
    selectedKits,
    fetchKitDetails,
    fetchWorkflowData,
    clearForms,
    history,
    fetchTypeOfJobAssociatedWithKitSystemmainComponent,
  });

  return (
    <div className='single-loan-container'>
      {displayActionMessage && <ActionMessage type={type} message={strings[message] || message} />}
      <Steps activeStep={activeStep} />
      <div className='container-fluid'>
        {activeStep === 1 && <h2 className='title-loan mb-4'>{strings.labsAtLocationActivity}</h2>}
        {activeStep === 1 && (
          <Assignment
            setActiveStep={setActiveStep}
            isShowCheckbox={isShowCheckbox}
            enteredOpportunityId={enteredOpportunityId}
            handleEnteredOpportunityId={handleEnteredOpportunityId}
            isOpportunityIdCrmError={isOpportunityIdCrmError}
            setIsOpportunityIdCrmError={setIsOpportunityIdCrmError}
            history={history}
            user={user}
          />
        )}
        {activeStep === 2 && (
          <CustomerData
            setActiveStep={setActiveStep}
            history={history}
          />
        )}
        {activeStep === 3 && (
          <Confirmation
            setActiveStep={setActiveStep}
            isShowCheckbox={isShowCheckbox}
            enteredOpportunityId={enteredOpportunityId}
            handleEnteredOpportunityId={handleEnteredOpportunityId}
            isOpportunityIdCrmError={isOpportunityIdCrmError}
            setIsOpportunityIdCrmError={setIsOpportunityIdCrmError}
            history={history}
            user={user}
          />
        )}
      </div>
      {isLoading && <div className='loader' />}
    </div>
  );
}

export default LLKitLoan;
