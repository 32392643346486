import { useState, useLayoutEffect, useCallback, useRef, useEffect } from "react"

import { VIEWPORT_MARGIN, DEFAULT_CONFIG, HIDDEN_STYLES, AUTO_STYLES } from './Tooltip.constants';

export const getFirstPosition = (targetPosition, targetSize, tooltipSize, viewportSize) => {
  const targetCenter = (targetPosition + targetPosition + targetSize) / 2;
  const tooltipHalfSize = tooltipSize / 2;

  const position = Math.max(VIEWPORT_MARGIN, targetCenter - tooltipHalfSize);
  const isReachTheEdge = position > VIEWPORT_MARGIN && position + tooltipSize > viewportSize - VIEWPORT_MARGIN;

  const arrowLength = (targetCenter - position) * 2;
  const edgeArrowLength = (viewportSize - targetCenter - VIEWPORT_MARGIN) * 2;

  return isReachTheEdge ? [false, VIEWPORT_MARGIN, edgeArrowLength] : [true, position, arrowLength];
};

 export const getSecondPosition = (
  targetPosition,
  targetSize,
  tooltipSize,
  viewportSize,
  config,
) => {
  const targetEnd = targetPosition + targetSize;

  const position = targetEnd + config.offset;
  const invertPosition = viewportSize - targetPosition + config.offset;
  const needInvert =
    position + tooltipSize + VIEWPORT_MARGIN > viewportSize &&
    invertPosition + tooltipSize + VIEWPORT_MARGIN < viewportSize;

  return needInvert ? [false, invertPosition] : [true, position];
};

export const getValue = value => (value === null ? 'auto' : `${value}px`);

export const setCSSVariables = (element, data) => {
  if (!element) return;

  element.style.setProperty('--top', data.top);
  element.style.setProperty('--right', data.right);
  element.style.setProperty('--bottom', data.bottom);
  element.style.setProperty('--left', data.left);
  element.style.setProperty('--opacity', String(data.opacity));
  element.style.setProperty('--arrow', data.arrow);
};

export const getConfig = target => {
  if (!target) return null;
  const config = target.getAttribute('data-tooltip-config') || '';

  try {
    return JSON.parse(config);
  } catch (error) {
    return null;
  }
};

const { setTimeout } = window;

export const useTooltip = (ref) => {
  const [tooltip, setTooltip] = useState(null);
  const timer = useRef(undefined);

  useLayoutEffect(() => {
    if (!tooltip || !ref.current) return;

    const {targetRect, config} = tooltip;


    const tooltipReact = ref.current.getBoundingClientRect();
    const tooltipWidth = tooltipReact.width;
    const tooltipHeight = tooltipReact.height;

    const styles = { ...AUTO_STYLES };

    const isTop = config.direction === 'top';
    const isRight = config.direction === 'right';
    const isBottom = config.direction === 'bottom';
    const isLeft = config.direction === 'left';

    const targetTop = targetRect.top;
    const targetRight = targetRect.right;
    const targetBottom = targetRect.bottom;
    const targetLeft = targetRect.left;
    const targetWidth = targetRect.width;
    const targetHeight = targetRect.height;

    const winWidth = window.innerWidth;
    const winHeight = window.innerHeight;

    let direction = config.direction;
    let inverse;

    if (isLeft || isRight) {
      const [direct, y, arrow] = getFirstPosition(targetTop, targetHeight, tooltipHeight, winHeight);
      styles[direct ? 'top' : 'bottom'] = getValue(y);
      styles.arrow = getValue(arrow);
      inverse = !direct;
    }
    
    if (isTop || isBottom) {
      const [direct, x, arrow] = getFirstPosition(targetLeft, targetWidth, tooltipWidth, winWidth);
      styles[direct ? 'left' : 'right'] = getValue(x);
      styles.arrow = getValue(arrow);
      inverse = !direct;
    }
    
    if (isLeft) {
      const [direct, x] = getSecondPosition(winWidth - targetRight, targetWidth, tooltipWidth, winWidth, config);
      styles[direct ? 'right' : 'left'] = getValue(x);
      if (!direct) direction = 'right';
    }
    
    if (isRight) {
      const [direct, x] = getSecondPosition(targetLeft, targetWidth, tooltipWidth, winWidth, config);
      styles[direct ? 'left' : 'right'] = getValue(x);
      if (!direct) direction = 'left';
    }
    
    if (isTop) {
      const [direct, y] = getSecondPosition(winHeight - targetBottom, targetHeight, tooltipHeight, winHeight, config);
      styles[direct ? 'bottom' : 'top'] = getValue(y);
      if (!direct) direction = 'bottom';
    }
    
    if (isBottom) {
      const [direct, y] = getSecondPosition(targetTop, targetHeight, tooltipHeight, winHeight, config);
      styles[direct ? 'top' : 'bottom'] = getValue(y);
      if (!direct) direction = 'top';
    }
    
    setCSSVariables(ref.current, styles);
    ref.current.setAttribute('data-direction', direction);
    ref.current.toggleAttribute('data-inverse', inverse);
    
  }, [ref, tooltip]);

  const handleOver = useCallback(
    event => {
      clearTimeout(timer.current);
      const { target } = event;


      const tooltipTarget = target instanceof Element && target ? target.closest('[data-tooltip]') : null;
      const tooltipText = tooltipTarget ? tooltipTarget.getAttribute('data-tooltip') : null;
      const tooltipConfig = getConfig(tooltipTarget);

      const config = { ...DEFAULT_CONFIG, ...tooltipConfig };

      if (!tooltipText) return setTooltip(null);

      if (tooltipTarget && tooltipConfig && tooltipConfig.onlyClipped) {
        const { scrollWidth = 0, clientWidth = 0 } = tooltipTarget;
        const isNotClipped = scrollWidth <= clientWidth;

        if (isNotClipped) return setTooltip(null);
      }

      timer.current = setTimeout(() => {
        const targetRect = tooltipTarget.getBoundingClientRect();
        if (!targetRect) return;

        // Reset tooltip styles for correct size calculation
        setCSSVariables(ref.current, HIDDEN_STYLES);

        setTooltip({ config, targetRect, tooltipText });
      }, config.delay);
    },
    [ref],
  );


  const handleOut = useCallback(() => {
    clearTimeout(timer.current);
    setTooltip(null);
  }, []);

  useEffect(() => {
    document.addEventListener('mouseover', handleOver);
    document.addEventListener('mouseout', handleOut);

    return () => {
      clearTimeout(timer.current);
      document.removeEventListener('mouseover', handleOver);
      document.removeEventListener('mouseout', handleOut);
    };
  }, [handleOver, handleOut]);

  return { ...tooltip, handleOver, handleOut };
}
