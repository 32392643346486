import { batchActions } from 'redux-batched-actions';

import { logNetworkError } from 'library/utilities/logError';
import {
  fetchMasterDataService,
  fetchMasterDataServicePost,
} from 'library/api/master-data.service';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import * as actionTypes from 'library/common/commonConstants/storeConstants';
import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';
import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { scrollToTop } from 'library/utilities/scrollActions';

import { GET_PERMISSIONS_LIST } from './Permissions.constants';

export const getInitialPermissionsData = () => async dispatch => {
  try {
    dispatch(changeLoader(true));
    await dispatch(fetchMasterData(URLS.userList, actionTypes.FETCH_USERS_LIST));
    await dispatch(getPermissionsList());
    dispatch(changeLoader(false));
  } catch (err) {
    dispatch(changeLoader(false));
    scrollToTop(500);
    dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    logNetworkError(err);
  }
};

export const getPermissionsList = () => async dispatch => {
  try {
    const response = await fetchMasterDataService(URLS.demoUnitPermissionList);

    if (response && response.data) {
      const permissionsList = response.data.map(item => ({ ...item, role: item.globalRoles[0] }));

      dispatch({
        type: GET_PERMISSIONS_LIST,
        payload: permissionsList,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_PERMISSIONS_LIST,
      payload: [],
    });
  }
};

export const updateDemoUnitPermission = (permissions, accountIdsToDelete) => async (dispatch, getState) => {
  try {
    const permissionsList = getState().permissionsReducer.permissionsList;
    const existPermissions = permissionsList.reduce((acc, cur) => {
      const {accountId, role} = cur;

      acc[`${accountId}_${role}`] = cur;
      return acc;
    }, {});
    const newPermissions = permissions.filter(({accountId, role}) => !existPermissions[`${accountId}_${role}`]).map(({accountId, role}) => ({accountId, role}))
    const data = {
      toAdd: newPermissions,
      toDelete: accountIdsToDelete,
    };
    const response = await fetchMasterDataServicePost(URLS.demoUnitPermission, data);

    if (response && response.status === 200) {
      dispatch(
        batchActions([
          dispatch(getPermissionsList()),
          dispatch(toggleActionMessage(true, 'success', 'permissionsWereUpdated')),
        ]),
      );
      scrollToTop(500);
    }
  } catch (err) {
    scrollToTop(500);
    dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    logNetworkError(err);
  }
};
