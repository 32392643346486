import { connect } from 'react-redux';

import { fetchOpportunitiesList } from '../../KitLoanActions';
import OpportunitySearchPopup from './OpportunitySearchPopup.component';

const mapStateToProps = ({ languageReducer }) => ({
    language: languageReducer,
});

export default connect(mapStateToProps, { fetchOpportunitiesList })(OpportunitySearchPopup);
