import strings from "resources/locales/Translate";

import Validators from "library/utilities/Validators";

export const requestAccessModel = [
  {
    label: 'userLabelText',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'accountId',
    validators: [{check: Validators.required, message: strings.requiredErrorMessage}],
    required: true,
    styleClass: 'col-sm-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'accountId',
    displayValue: [
      {key: 'lastName', separator: ', '},
      {key: 'firstName', separator: ''}
    ],
    disabled: true
  },
  {
    label: 'roleLabelText',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'roleId',
    validators: [{check: Validators.required, message: strings.requiredErrorMessage}],
    required: true,
    styleClass: 'col-sm-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [
      {key: 'role', separator: ''}
    ]
  },
];
