import React from 'react';

import ModalPortal from 'library/common/commonComponents/ModalPortal';
import Icon from 'library/common/commonComponents/Icon/Icon';
import DotLoader from 'library/common/commonComponents/DotLoader';
import strings from 'resources/locales/Translate';

import NotificationCart from './Components/NotificationCart';
import { useNotifications } from './Notifications.hook';
import { useNotificationBar } from './NotificationBar.hook';
import './NotificationBar.style.scss';

const NotificationBar = ({
  isOpenNotificationBar,
  openOrHideNotificationsBar,
  notifications,
  addNotification,
  fetchAllNotifications,
  user,
  history,
  language,
  deleteNotification,
  changeStatusNotification,
  isNotificationsLoading,
  deleteAllNotifications,
}) => {
  useNotifications({ user, addNotification, fetchAllNotifications, language });
  const {
    handleClickOnNotification,
    handleClickOnSettings,
    onClose,
    handleDeleteNotification,
    handleChangeStatusOfNotification,
    isStatusLoading,
    isDeletLoading,
  } = useNotificationBar({
    history,
    isOpenNotificationBar,
    openOrHideNotificationsBar,
    notifications,
    deleteNotification,
    changeStatusNotification,
  });

  if (!isOpenNotificationBar) return null;

  return (
    <ModalPortal onClose={onClose} className='notificationBar'>
      <div className='notificationBar-container'>
        <div className='notificationBar-header'>
          <h3 className='notificationBar-title'>{strings.notifications}</h3>
          <div className='d-flex align-items-center'>
            <div className='notificationBar-menu'>
              <span className='menu-item mr-3' onClick={handleClickOnSettings}>{strings.settings}</span>
              <div className='dot-icon mr-3' />
              <span className='menu-item mr-3' onClick={deleteAllNotifications}>{strings.clearAll}</span>
            </div>
            <button
              type='button'
              className='close'
              onClick={onClose}
            >
              <Icon name='cross' width={20} height={20} viewBox='0 0 45 45' />
            </button>
          </div>
        </div>
        {!isNotificationsLoading ? (
          <div className='notifications-wrapper'>
            {notifications.length > 0 ? notifications.map(notification => (
              <NotificationCart
                key={notification.id}
                notification={notification}
                handleClickOnNotification={handleClickOnNotification}
                handleDeleteNotification={handleDeleteNotification}
                handleChangeStatusOfNotification={handleChangeStatusOfNotification}
                isStatusLoading={isStatusLoading}
                isDeletLoading={isDeletLoading}
              />
            )) : (
              <div className='noNotifications-text'>{strings.noNotifications}</div>
            )}
          </div>
        ) : (
          <div className='loading-wrapper'>
            <DotLoader />
          </div>
        )}
      </div>
    </ModalPortal>
  );
};

export default NotificationBar;
