import React from 'react';
import cn from 'classnames';

import FormComponent from '../FormComponent/Optimized';
import Icon from '../Icon/Icon';

import './addMoreUsersStyle.scss';

export const AddMoreUsers = ({
  formName,
  model,
  hideDeleteIcon,
  onChange,
  onAdd,
  onRemove,
  value,
  valueAlreadyAdded,
  buttonText,
}) => {

  return (
    <div className='add-more-users'>
      <div className='row'>
        <div className={cn({ 'col-sm-10': buttonText, 'col-sm-11': !buttonText })}>
          <FormComponent
            model={model}
            formName={formName}
            disableForm={hideDeleteIcon}
            onChange={onChange}
            formValue={value}
          />
        </div>
        {!hideDeleteIcon && (
          <div className={cn({ 'col-sm-2': buttonText, 'col-sm-1': !buttonText })}>
            {buttonText ? (
              <button
                className={cn({ 'remove-button-with-text': valueAlreadyAdded, 'add-button-with-text': !valueAlreadyAdded })}
                onClick={valueAlreadyAdded ? onRemove : onAdd}
              >
                {buttonText}
                <Icon
                  name={valueAlreadyAdded ? 'delete' : 'plus'}
                  width={24}
                  height={24}
                  fill={valueAlreadyAdded ? '#ff1a00' : '#0088d0'}
                />
              </button>
            ) : (
              <button
                className={cn({ 'remove-button': valueAlreadyAdded, 'add-button': !valueAlreadyAdded })}
                onClick={valueAlreadyAdded ? onRemove : onAdd}
              >
                <Icon
                  name={valueAlreadyAdded ? 'delete' : 'plus'}
                  width={24}
                  height={24}
                  fill={valueAlreadyAdded ? '#0088d0' : '#fff'}
                />
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AddMoreUsers;
