import React from 'react';

import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import Modal from 'library/common/commonComponents/Modal';

import { useDeductPointsPopup } from './DeductPointsPopup.hook';

const DeductPointsPopup = props => {
  const { id } = props;
  const {
    deductPointsForm,
    model,
    isLoading,
    error,
    onSaveClick,
    onCloseClick,
    handleForm,
  } = useDeductPointsPopup(props);

  return (
    <Modal
      id={id}
      title={strings.deductPoints}
      confirmAction={onSaveClick}
      confirmTitle={strings.save}
      titleOfCancel={strings.cancel}
      loading={isLoading}
      disableBackgroundClose
      onCloseClick={onCloseClick}
    >
      <FormComponent
        model={model}
        formName='deductPointsForm'
        formValue={deductPointsForm.formData}
        onChange={handleForm}
      />
      {error && <div className='error-block'>{error}</div>}
    </Modal>
  );
};

export default DeductPointsPopup;
