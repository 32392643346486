import { connect } from 'react-redux';

import Subscriptions from './Subscriptions.component';
import {
  getSystemClassSubscriptions,
  createOrEditSubscription,
  deleteSubscription,
  getEmailTypes,
} from './Subscriptions.actions';

const mapStateToProps = ({ appActionsReducer, masterDataReducer, authReducer, languageReducer, subscriptionsReducer }) => ({
  appActions: appActionsReducer,
  user: authReducer.user,
  language: languageReducer,
  subscriptionsList: subscriptionsReducer.subscriptionsList,
  systemClasses: masterDataReducer.categories,
  emailTypes: subscriptionsReducer.emailTypes,
});

export default connect(mapStateToProps, {
  getSystemClassSubscriptions,
  createOrEditSubscription,
  deleteSubscription,
  getEmailTypes,
})(Subscriptions);
