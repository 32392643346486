import { connect } from 'react-redux';

import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';

import { fetchTaskListData, createOrEditDudsTask, removeDudsTask, fetchSystemClassDataForPopup } from './DusdTask.actions';
import DusdTask from './DusdTask.component';

const mapStateToProps = ({ authReducer, languageReducer, appActionsReducer, masterDataReducer, dusdTaskReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
  appActions: appActionsReducer,
  categoriesAndSystems: masterDataReducer.systemClassWithStock,
  tasksList: dusdTaskReducer.tasksList,
  popUpSystemClassData: dusdTaskReducer.popUpSystemClassData,
});

export default connect(mapStateToProps, {
  fetchMasterData,
  fetchTaskListData,
  createOrEditDudsTask,
  removeDudsTask,
  fetchSystemClassDataForPopup,
})(DusdTask);
