import React from 'react';

import './progressBar.styles.scss';

const ProgressBar = props => {
  const { bgColor, title, current, max } = props;
  const completed = max ? (current * 100) / max : 0;

  return (
    <div className='progress-bar-container'>
      <div className='title-wrapper'>
        <p className='title'>{title}</p>
        <p className='indicators'>{current} / {max}</p>
      </div>
      <div className='filler-wrapper'>
        <div className='filler' style={{ width: `${completed}%`, backgroundColor: bgColor  }} />
      </div>
    </div>
  );
}

export default ProgressBar;
