import { GET_SYSTEM_CLASSES_SUBSCRIPTIONS, GET_EMAIL_TYPES } from './Subscriptions.constants';

const initialState = {
  subscriptionsList: [],
  emailTypes: [],
};

const SubscriptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SYSTEM_CLASSES_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptionsList: action.payload,
      };
    case GET_EMAIL_TYPES:
      return {
        ...state,
        emailTypes: action.payload,
      };
    default:
      return state;
  }
};

export default SubscriptionsReducer;
