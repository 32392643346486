import React, { useEffect, useState } from 'react';

import strings from 'resources/locales/Translate';
import DateInput from 'library/common/commonComponents/FormComponent/Optimized/DateInput';
import { getDateInFormat } from 'library/utilities/getDateInFormat';

export const LLStatistics = ({ fetchLLKitStatistics, kitDetail }) => {
  const [dateRange, setDateRange] = useState(null);
  const [statistics, setStatistics] = useState({});

  useEffect(() => {
    const getData = async () => {
      const { success, data } = await fetchLLKitStatistics(kitDetail.kitInformationId, dateRange);
      if (success) {
        setStatistics(data);
      } else {
        setStatistics({});
      }
    };

    if (kitDetail && kitDetail.kitInformationId) {
      getData();
    }
  }, [fetchLLKitStatistics, kitDetail, dateRange]);

  const onChangeDate = dateRange => {
    if (dateRange) {
      const dateRangeStart = dateRange[0] ? `${getDateInFormat('YYYY-MM-DD', dateRange[0])} 00:00:00` : null;
      const dateRangeEnd = dateRange[1] ? `${getDateInFormat('YYYY-MM-DD', dateRange[1])} 00:00:00` : null;
      setDateRange([dateRangeStart, dateRangeEnd]);
    } else {
      setDateRange(null);
    }
  };

  return (
    <div className='kit-attachments-container'>
      <div className='container-fluid my-4'>
          <div className='d-flex w-100 justify-content-between align-items-center'>
            <h3 className='form-section-title mb-0'>{strings.overview}</h3>
            <DateInput
              mainContainerStyle='d-flex flex-row'
              datePickerFieldStyle='statistics-datepicker'
              field='dateRange'
              label={strings.timePeriod}
              placeholder={strings.pleaseSelectDate}
              selectedDate={dateRange}
              onChange={onChangeDate}
              isRequired={true}
              isRange
              clearIcon
            />
          </div>
          <div className='percentage-item'>
            <h4 className='font-bold'>
              {statistics.amountOfBookings || '0'}
            </h4>
            <p>{strings.bookings}</p>
          </div>
      </div>
    </div>
  );
};

export default LLStatistics;
