import Button from 'library/common/commonComponents/Button/Button';
import SearchField from 'library/common/commonComponents/SearchField/SearchField';
import React from 'react';
import strings from 'resources/locales/Translate';
import { itemsPerPage, myQueryBreadcrumbData } from './MyQueries.constants';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/Breadcrumbs';
import Table from 'library/common/commonComponents/Table/Table';
import { useMyQueries } from './MyQueries.hook';
import ItemsPerPage from 'library/common/commonComponents/ItemsPerPage/ItemsPerPage';
import Pagination from 'library/common/commonComponents/Pagination/Pagination';
import toArray from 'lodash/toArray';
import { SaveQueryPopup } from '../SaveQueryPopup/SaveQueryPopup.component';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';

export const MyQueries = ({ fetchMyQueriesList, safeSearchList, createMyQuery, appActions, deleteMyQuery }) => {
  const {
    getTableRows,
    data,
    handleSort,
    handleItemsPerPage,
    handlePageChange,
    handleSearchChange,
    selectedData,
    iseditClicked,
    setIsEditClicked,
  } = useMyQueries({
    fetchMyQueriesList,
    deleteMyQuery,
  });

  const { displayActionMessage, type, message } = appActions;
  const hasQueryList =  safeSearchList?.content?.length > 0
  return (
    <>
    {displayActionMessage && <ActionMessage type={type} message={strings[message]} />}
    <div className='container-fluid mb-3'>
      <Breadcrumbs data={myQueryBreadcrumbData} />
      <div className='row no-gutters align-items-center'>
        <h1 className='col-md col-12 stock-title mb-3'>{strings.myQueries}</h1>
        <div className='col-md-auto col mr-3'>
          <div className='input-wrapper'>
            <SearchField onChange={handleSearchChange} initialValue={data.searchKey} />
          </div>
        </div>
        <div className='col-auto'>
          <Button
            dataTest='stock-list-filter-button'
            bgFill={false}
            iconName='filter'
            //   onClick={this.showOrHideFilter}
            value={strings.showFilter}
            styleClass='filter-button'
          />
        </div>
      </div>

      <div className='booking-table mt-2'>
        {hasQueryList ? (
          <Table cols={getTableRows()} rows={safeSearchList.content} sortFields={data.sort} handleSort={handleSort} />
        ) : (
          <div className='d-flex justify-content-center mt-4'>
            <strong>{strings.noDataFoundText}</strong>
          </div>
        )}
        {hasQueryList ? (
          <div className='col d-flex justify-content-end mb-3'>
            <ItemsPerPage
              itemsPerPage={toArray(itemsPerPage)}
              handleItemsPerPage={handleItemsPerPage}
              value={itemsPerPage[data.size]}
            />
            <Pagination
              currentPage={data.page}
              totalPages={safeSearchList.totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        ) : null}
      </div>

      <SaveQueryPopup
        id='edit-save-query'
        selectedData={selectedData}
        isEditClicked={iseditClicked}
        setIsEditClicked={setIsEditClicked}
        createMyQuery={createMyQuery}
      />
    </div>
    </>
  );
};

export default MyQueries;
