import React from 'react';

import strings from 'resources/locales/Translate';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import ConfirmDeletionPopup from 'library/common/commonComponents/ConfirmationBox';

import { subscriptionsBreadcrumbData } from './Subscriptions.constants';
import { useSystemClassesSubscriptions } from './Subscriptions.hook';
import CreateOrEditSubscriptionPopup from './Components/CreateOrEditSubscriptionPopup';
import DemoUnitStatusSubscriptions from './Components/DemoUnitStatusSubscriptions';

const Subscriptions = ({
  appActions,
  subscriptionsList,
  systemClasses,
  getSystemClassSubscriptions,
  createOrEditSubscription,
  deleteSubscription,
  emailTypes,
  getEmailTypes,
  language,
}) => {
  document.title = 'Subscriptions';
  const { isLoading, displayActionMessage, type, message } = appActions;
  const {
    openCreateSubscriptionPopup,
    handleEditSubscription,
    selectedSubscription,
    setSelectedSubscription,
    handleDeleteSubscription,
    isLoadingDeletion,
    hideDeleteSubscriptionPopup,
    confirmSubscriptionDeletion,
    deletionError,
  } = useSystemClassesSubscriptions({
    subscriptionsList,
    getSystemClassSubscriptions,
    deleteSubscription,
    getEmailTypes,
    language,
  });

  return (
    <div className='subscriptions-container'>
      {displayActionMessage && <ActionMessage type={type} message={strings[message]} />}
      <div className='container-fluid mb-3'>
        <Breadcrumbs data={subscriptionsBreadcrumbData} />
        <DemoUnitStatusSubscriptions
          subscriptionsList={subscriptionsList}
          handleEditSubscription={handleEditSubscription}
          handleDeleteSubscription={handleDeleteSubscription}
          openCreateSubscriptionPopup={openCreateSubscriptionPopup}
        />
      </div>
      {isLoading && <div className='loader' />}
      <CreateOrEditSubscriptionPopup
        id='create-subscription-popup'
        systemClasses={systemClasses}
        emailTypes={emailTypes}
        createSubscription={createOrEditSubscription}
      />
      <CreateOrEditSubscriptionPopup
        id='edit-subscription-popup'
        systemClasses={systemClasses}
        emailTypes={emailTypes}
        createSubscription={createOrEditSubscription}
        hasEditMode={true}
        selectedSubscription={selectedSubscription}
        setSelectedSubscription={setSelectedSubscription}
      />
      <ConfirmDeletionPopup
        id='confirm-delete-subscription-modal'
        onConfirm={confirmSubscriptionDeletion}
        onClose={hideDeleteSubscriptionPopup}
        error={deletionError}
        isLoading={isLoadingDeletion}
        title={strings.deleteSubscription}
        subtitle={strings.areYouSureYouWantToDeleteSubscription}
      />
    </div>
  );
};

export default Subscriptions;
