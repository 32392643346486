import React, { useState } from 'react';
import $ from 'jquery';

import strings from 'resources/locales/Translate';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';

import SaveAllPermissionsPopup from '../SaveAllPermissionsPopup';

const SetAllPermissionsDropdown = ({
  rolesWithoutAdmin,
  isDisabledUpdates,
  state,
  globalRole,
  setGlobalRole,
  updateAllUserPermissionsForStosk,
}) => {
  const [loading, setLoading] = useState(false);

  const openPopup = () => {
    if ($(`#setAllPermissionsPopup`) && $(`#setAllPermissionsPopup`).modal) {
      $(`#setAllPermissionsPopup`).modal('show');
    }
  };

  const closePopup = () => {
    if ($(`#setAllPermissionsPopup`) && $(`#setAllPermissionsPopup`).modal) {
      $(`#setAllPermissionsPopup`).modal('hide');
    }
  };

  const cancelPopup = () => {
    closePopup();
    setGlobalRole(null);
  };

  const handleDropdown = (value, field, fullValue) => {
    openPopup();
    if (fullValue) {
      setGlobalRole(fullValue);
    }
  };

  const handleUpdateAllUserRolesForStock = async () => {
    setLoading(true);
    await updateAllUserPermissionsForStosk(state, globalRole);
    setLoading(false);
    closePopup();
  };

  return (
    <>
      <div className='d-flex align-items-center'>
        <span className='mr-2'>{strings.permissionForAll}:</span>
        <Dropdown
          data={rolesWithoutAdmin}
          idKey='id'
          field='role'
          displayValue={[{ key: 'role', separator: '' }]}
          isRequired={false}
          placeholder={strings.pleaseSelect + '...'}
          onChange={handleDropdown}
          mainContainerStyle='w-auto'
          value={globalRole ? globalRole.id : ''}
          fullValue={globalRole ? globalRole : ''}
          disabled={isDisabledUpdates}
        />
      </div>
      <SaveAllPermissionsPopup
        id='setAllPermissionsPopup'
        filters={state.filters}
        role={globalRole ? globalRole.role : ''}
        closePopup={cancelPopup}
        handleUpdateAllUserRolesForStock={handleUpdateAllUserRolesForStock}
        loading={loading}
      />
    </>
  );
};

export default SetAllPermissionsDropdown;
