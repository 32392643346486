import { FETCH_OPPORTUNITY_LIST, FETCH_REFURBISHMENT_FEES } from './KitLoan.constants';

const initialState = {
  opportunityList: [],
  expectedLoanFees :[],
};

const KitLoanReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_OPPORTUNITY_LIST:
      return {
        ...state,
        opportunityList: action.payload
      };
      case FETCH_REFURBISHMENT_FEES:
      return {
        ...state,
        expectedLoanFees: action.payload
      };
    default:
      return state;
  }
};

export default KitLoanReducer;
