import { useEffect, useCallback, useState } from 'react';
import $ from 'jquery';

import { saveToStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';

export function useReservationList({
  data,
  reservationList,
  fetchReservationListData,
  addKitToObservationList,
  removeKitFromObservationList,
  reserveKit,
  cancelKitReservation,
}) {
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [selectedKitId, setSelectedKitId] = useState(null);
  const [errorReservation, setErrorReservation] = useState('');
  const [isReservedKit, setIsReservedKit] = useState(false);

  useEffect(() => {
    saveToStorage(identifiers.filtersShop, data.filter);
    fetchReservationListData(data);
  }, [data, fetchReservationListData]);

  const handleClickOnObserve = useCallback(async kitId => {
    await addKitToObservationList(kitId);
    fetchReservationListData(data);
  }, [data, addKitToObservationList, fetchReservationListData]);

  const handleClickOnStopObserve = useCallback(async kitId => {
    await removeKitFromObservationList(kitId)
    fetchReservationListData(data);
  }, [data, fetchReservationListData, removeKitFromObservationList]);

  const handleClickOnReserveKit = useCallback(async kitId => {
    await reserveKit(kitId);
    fetchReservationListData(data);
  }, [data, fetchReservationListData, reserveKit]);

  const openCancelReservatioPopup = kitId => {
    setSelectedKitId(kitId);
    setErrorReservation('');
    $('#reservation-list-cancel-reservation-popup').modal('show');
  };

  const closeCancelReservatioPopup = () => {
    setSelectedKitId(null);
    $('#reservation-list-cancel-reservation-popup').modal('hide');
  };

  const handleClickOnCancelReservation = useCallback(async () => {
    setIsConfirmLoading(true);
    const { success } = await cancelKitReservation(selectedKitId);
    setIsConfirmLoading(false);
    if (success) {
      closeCancelReservatioPopup();
      fetchReservationListData(data);
    } else {
      setErrorReservation('somethingWentWrongMessage');
    }
  }, [data, selectedKitId, cancelKitReservation, fetchReservationListData]);

  const openOrderPopup = (kitId, isReserved) => {
    setSelectedKitId(kitId);
    setIsReservedKit(isReserved);
    $('#reservation-order-popup').modal('show');
  };

  const updateReservationList = useCallback(() => {
    setSelectedKitId(null);
    fetchReservationListData(data);
  }, [data, fetchReservationListData]);

  const isShowReservationList = reservationList && reservationList.content && reservationList.content.length > 0;

  return {
    handleClickOnObserve,
    handleClickOnStopObserve,
    handleClickOnReserveKit,
    handleClickOnCancelReservation,
    openCancelReservatioPopup,
    closeCancelReservatioPopup,
    openOrderPopup,
    updateReservationList,
    isShowReservationList,
    isConfirmLoading,
    errorReservation,
    selectedKitId,
    isReservedKit,
  };
}
