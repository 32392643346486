import { useState } from 'react';
import * as $ from 'jquery';
import strings from 'resources/locales/Translate';

export const useViewAndEditSchedule = ({ id, scheduledDetails, deleteSchedule }) => {
  const [editing, setEditing] = useState(false);

  const onCloseClick = () => {
    $(`#${id}`).modal('hide');
  }

  const onDeleteClick = async () => {
    if (scheduledDetails !== null) {
      if (scheduledDetails.recurring) {
        $(`#${id}`).modal('hide');
        setTimeout(() => {
          $('#show-recurring-event-options').modal('show');
        }, 500);
      } else {
        let dataTosend = {};
        dataTosend.scheduledId = scheduledDetails.operatorsScheduleId;
        dataTosend.accountId = scheduledDetails.operatorUser.accountId;
        dataTosend.onlyCurrentEvent = true;
        dataTosend.currentAndFollowingEvents = false;
        dataTosend.allEvents = false;
        deleteSchedule(dataTosend, true);
        onCloseClick();
      }
    }
  };

  const displayDays = (days) => {
    if(!days){
      return "";
    }
    days = days.toString();
    const daysArray = days.split(",");
    let translatedDays = "";
    daysArray.forEach((day, index) => {
      day = day.trim().toLowerCase();
      translatedDays = index != daysArray.length-1 ? translatedDays + strings[day] + ", ": translatedDays + strings[day];
    })
    return translatedDays;
   }

  return {
    onDeleteClick,
    onCloseClick,
    displayDays,
  };
};
