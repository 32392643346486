import { connect } from 'react-redux';

import { saveTransactionRatings } from '../../TransactionDetailsActions';
import Rating from './index';

const mapStateToProps = ({ languageReducer, transactionDetailsReducer }) => {
  return {
    language: languageReducer,
    transactionDetails: transactionDetailsReducer.transactionDetails,
  }
};

export default connect(mapStateToProps, { saveTransactionRatings })(Rating);
