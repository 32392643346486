import { useState, useEffect } from 'react';
import $ from 'jquery';

import strings from 'resources/locales/Translate';
import { validateForm } from 'library/utilities/ValidateForm.util';

import { priceModel, localSalesPriceModel, transferPriceModel } from './SoldPopup.constants';

export const useSoldPopup = ({
  id,
  currency,
  generalInformationKitForSaleForm,
  updateKit,
  updateFormCreateKit,
}) => {
  const priceOptions = [
    {
      name: strings.transferPrice,
      id: 'transfer_price',
    },
    {
      name: strings.grossListPrice,
      id: 'local_sale_price',
    },
  ];
  const [priceForm, setPriceForm] = useState({
    formData: {
      currencyId: 1,
    },
  });
  const isLocalSalePrice = priceForm.formData && priceForm.formData.soldPriceType === 'local_sale_price';

  useEffect(() => {
    if (generalInformationKitForSaleForm.formData) {
      setPriceForm({
        formData: {
          transferPrice: generalInformationKitForSaleForm.formData.transferPrice,
          currencyForTransferPrice: generalInformationKitForSaleForm.formData.currencyForTransferPrice,
          localPrice: generalInformationKitForSaleForm.formData.localPrice,
          currencyId: 1,
        },
      });
    }
  }, [generalInformationKitForSaleForm]);

  const getModel = () => {
    let newModel = [ ...priceModel ];
    newModel[0].options = priceOptions;

    if (priceForm.formData && priceForm.formData.soldPriceType === 'transfer_price') {
      newModel = [ ...priceModel, ...transferPriceModel ];
    }

    if (priceForm.formData && priceForm.formData.soldPriceType === 'local_sale_price') {
      newModel = [ ...priceModel, ...localSalesPriceModel ];
      newModel[2].options = currency;
    }

    return newModel;
  };

  const model = getModel();

  const closePopup = () => {
    $(`#${id}`).modal('hide');
    setPriceForm({
      formData: {
        transferPrice: generalInformationKitForSaleForm.formData.transferPrice,
        currencyForTransferPrice: generalInformationKitForSaleForm.formData.currencyForTransferPrice,
        localPrice: generalInformationKitForSaleForm.formData.localPrice,
        currencyId: 1,
      }
    });
  };

  const handleConfirmation = () => {
    const validForm = validateForm({
      form: priceForm,
      model: model,
    });
    if (!validForm.isFormValid) {
      return setPriceForm(validForm);
    } 

    updateFormCreateKit({
      generalInformationKitForSaleForm: {
        formData: {
          ...generalInformationKitForSaleForm.formData,
          transferPrice: priceForm.formData.transferPrice,
          currencyForTransferPrice: priceForm.formData.currencyForTransferPrice,
          localPrice: priceForm.formData.localPrice,
          currencyId: priceForm.formData.currencyId,
          soldPriceType: priceForm.formData.soldPriceType,
        },
      },
      kitStatusForSaleForm: {
        formData: {
          kitConditionId: 4,
          kitConditionIdFullValue: { id: 4, translatedStatus: 'Sold' },
        },
      },
    });
    updateKit();
    closePopup();
  };

  const handleForm = ({ values }) => {
    setPriceForm(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        ...values,
      },
    }));
  };

  return {
    model,
    priceForm,
    closePopup,
    handleConfirmation,
    handleForm,
    isLocalSalePrice,
  };
}
