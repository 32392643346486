import { batchActions } from 'redux-batched-actions';

import { logNetworkError } from 'library/utilities/logError';
import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { addNameKey } from 'library/utilities/fileHelperFunctions';

import * as services from '../../CreateStock.services';
import { scrollToTop } from 'library/utilities/scrollActions';

export const removeStockImageFile = (stockImageId) => (dispatch) => {
  return services
    .deleteStockImageService(stockImageId)
    .then((res) => {
      if (res.status === 200) {
        return { success: true };
      }
      return { success: false };
    })
    .catch(err => {
      logNetworkError(err);
      dispatch(changeLoader(false));
      return { success: false };
    });
};

export const uploadStockImages = (files, stockId) => (dispatch) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append('files', file);
  });
  dispatch(changeLoader(true));
  return services
    .uploadStockImagesService(stockId, formData)
    .then(async (res) => {
      if (res.status === 200) {
        const { data } = await dispatch(fetchStockImages(stockId));
        scrollToTop(500);
        dispatch(
          batchActions([
            dispatch(toggleActionMessage(true, 'success', 'stockImageUploadedSuccessfully')),
            dispatch(changeLoader(false)),
          ]),
        );
        return { success: true, data };
      }
      dispatch(changeLoader(false));
      return { success: false };
    })
    .catch((err) => {
      logNetworkError(err);
      dispatch(
        batchActions([
          dispatch(changeLoader(false)),
          dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage')),
        ]),
      );
      return { success: false };
    });
};

export const fetchStockImages = (stockId) => (dispatch) => {
  dispatch(changeLoader(true));
  return services
    .fetchStockImageService(stockId)
    .then(({ status, data }) => {
      dispatch(changeLoader(false));
      if (status === 200) {
        return { success: true, data: addNameKey(data) };
      }
      return { success: false, data: [] };
    })
    .catch((err) => {
      logNetworkError(err);
      dispatch(changeLoader(false));
      return { success: false, data: [] };
    });
};
