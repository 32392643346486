export const llManagementBreadcrumbData = [
  {
    name:'homepage',
    url: '/home',
  },
  {
    name:'labsLocationManagement',
  },
];

export const FETCH_CONTINENTS_LIST = 'FETCH_CONTINENTS_LIST';
export const FETCH_CALCULATION_LIST = 'FETCH_CALCULATION_LIST';
export const CLEAR_CALCULATION_LIST = 'CLEAR_CALCULATION_LIST';
export const FETCH_STOCKS = 'FETCH_STOCKS';
export const FETCH_ACCOUNT_STATEMENT_LIST = 'FETCH_ACCOUNT_STATEMENT_LIST';
export const FETCH_USER_LIST = 'FETCH_USER_LIST';
export const FETCH_KPI = 'FETCH_KPI';
export const FETCH_MAIN_UTILIZED_MAIN_COMPONENTS = 'FETCH_MAIN_UTILIZED_MAIN_COMPONENTS';
export const FETCH_TYPES_OF_JOBS = 'FETCH_TYPES_OF_JOBS';
export const CLEAR_KPI_TABLES = 'CLEAR_KPI_TABLES';
export const FETCH_CONTROLLING_REPORT_LIST = 'FETCH_CONTROLLING_REPORT_LIST';
export const FETCH_SYSTEMCLASSIFICATION_LIST = 'FETCH_SYSTEMCLASSIFICATION_LIST';
export const FETCH_SYSTEMMAINCOMPONENT_LIST = 'FETCH_SYSTEMMAINCOMPONENT_LIST';
export const SAVEOREDITSYSTEMMAINCOMPONENT = 'FETCH_SYSTEMMAINCOMPONENT_LIST';

export const itemsPerPage = {
  10: { id: 10, pages: 10 },
  20: { id: 20, pages: 20 },
  50: { id: 50, pages: 50 },
};
