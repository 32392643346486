import React from 'react';

import Button from 'library/common/commonComponents/Button/Button';

import strings from 'resources/locales/Translate';
import { EditOutlineIcon } from '../Icon/SvgIcons/Icons';

const Components = ({ kitDetail, history, isEditable }) => {
  const editingKitURL = kitDetail.isLabsLocationPartner
    ? `/edit-llkit/${kitDetail.kitInformationId}`
    : (kitDetail.isZMCCKit || kitDetail.isIqsSscDeKit)
    ? `/edit-zmcc-kit/${kitDetail.kitInformationId}`
    : `/edit-kit/${kitDetail.kitInformationId}`;
  return (
    <div className='components-container'>
      <div className='container-fluid mt-4'>
        {kitDetail.salesComponents || kitDetail.furtherImportantComponents ? (
          <div className='row mb-4'>
            <div className='col-12 col-lg-6'>
              <div className='new-style-heading'>{`${strings.salesComponentLabel}:`}</div>
              <div className='description-container word-wrap components-description'>
                {kitDetail.salesComponents || 'No components available'}
              </div>
            </div>
            <div className='col-12 col-lg-6'>
              <div className='new-style-heading'>{`${strings.furtherImportantComponentLabel}:`}</div>
              <div className='description-container word-wrap components-description'>
                {kitDetail.furtherImportantComponents || 'No components available'}
              </div>
            </div>
          </div>
        ) : (
          <div className='dummy-data'>
            <span>{strings.noComponentsAvailableMessage}</span>
          </div>
        )}
        <div>
          {isEditable && (
            <button
              className='component-edit-button'
              onClick={() =>
                history.push({
                  pathname: editingKitURL,
                  state: { edit: true, tab: 'components' },
                })
              }
            >
              <EditOutlineIcon viewBox='0 0 20 20' width='17' height='17' />
              <span className='ml-1'>{strings.edit}</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Components;
