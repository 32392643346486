import {SET_REQUEST_STOCK_PERMISSION_DROPDOWN} from "library/common/commonConstants/storeConstants";

import {RequestModal} from "./request-stock-modal";

const initialState = {
  requestStockForm: RequestModal
};

const RequestStockPermissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REQUEST_STOCK_PERMISSION_DROPDOWN:
      return {
        ...state,
        requestStockForm: action.payload
      };
    default:
      return state;
  }
};

export default RequestStockPermissionReducer;
