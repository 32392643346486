import Validators from 'library/utilities/Validators';

export const createServiceTicketModel = [
    {
      label: 'stock',
      value: '',
      type: 'text',
      placeholder: '',
      field: 'location',
      validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
      required: true,
      styleClass: 'col-12'
    },
    {
      label: 'nameDepartment',
      value: '',
      type: 'text',
      placeholder: '',
      field: 'nameDepartment',
      validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
      required: true,
      styleClass: 'col-12'
    },
    {
      label: 'ticketSystemMainComponent',
      value: '',
      type: 'text',
      placeholder: '',
      field: 'systemMainComponent',
      validators: [],
      required: false,
      styleClass: 'col-12'
    },
    {
    label: 'serialNumber',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'serialNumber',
    validators: [],
    required: false,
    styleClass: 'col-12'
  },
  {
    label: 'selectProblem',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'selectProblem',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'serviceTicketProblemId',
    displayValue: [
      {key: 'serviceProblemName', separator: ''}
    ],
  },
  {
    label: 'providingMoreDetails',
    value: '',
    type: 'textarea',
    placeholder: 'suggestionText',
    field: 'providingMoreDetails',
    validators: [],
    required: false,
    styleClass: 'col-12',
  },
];
