import React, { useState } from 'react';
import ZMCCFilters from '../ZMCCFilters';
import { useZmccTranaction } from './ZMCCtransaction.hook';
import ZmccDataExpandableTable from '../ZMCCDataExpandableTable';
import strings from 'resources/locales/Translate';
import { itemsPerPage } from 'modules/Booking/BookingConstants';
import ItemsPerPage from 'library/common/commonComponents/ItemsPerPage/ItemsPerPage';
import Pagination from 'library/common/commonComponents/Pagination/Pagination';
import { toArray } from 'lodash';
import ExpandableTable from 'library/common/commonComponents/Table/AppExpandableTable';

const ZMCCTransaction = props => {
  const { cols, hasZmccTransactionList } = useZmccTranaction(props);
  const {
    zmccTransactionList,
    handleItemsPerPage,
    handlePageChange,
    data,
    isShowFilter,
    zmccTransactionStatuses,
    handleFilterChange,
    zmccCountries,
    zmccCategory,
    zmccDateTpyes,
    match,
    user,
    saveCancellationRequest,
    zmccOperatorList,
    systemCategoriesList,
    resetFiltersZmcc
  } = props;
  const [rejectCancelRequest, setRejectCancelRequest] = useState(false);
  const requestTypeData = [
    {id: 'lm', name: 'LM'},
    {id: 'sem', name: 'SEM'},
    {id: 'xb', name: 'XB'},
    {id: 'xrm', name: 'XRM'},
    {id: 'notype', name: strings.noTypeAdded}
  ]
  return (
    <>
      <ZMCCFilters
        isShowFilter={isShowFilter}
        countries={zmccCountries}
        zmccCategory={zmccCategory}
        selected={data.filter}
        transactionStatuses={zmccTransactionStatuses}
        handleFilterChange={handleFilterChange}
        zmccDateTpyes={zmccDateTpyes}
        zmccOperatorList={zmccOperatorList}
        requestTypeData={requestTypeData}
        systemCategoriesList={systemCategoriesList}
        resetFiltersZmcc={resetFiltersZmcc}
      />
      <div className='booking-table'>
        {hasZmccTransactionList ? (
          <div className='container-fluid mb-3'>
            <ExpandableTable
              cols={cols}
              rows={zmccTransactionList.content}
              //   sortFields={data.sort}
              //   handleSort={handleSort}
              user={user}
            >
              <ZmccDataExpandableTable
                data={data}
                match={match}
                setRejectCancelRequest={setRejectCancelRequest}
                rejectCancelRequest={rejectCancelRequest}
                saveCancellationRequest={saveCancellationRequest}
              />
            </ExpandableTable>
          </div>
        ) : (
          <div className='d-flex justify-content-center'>
            <strong>{strings.noDataFoundText}</strong>
          </div>
        )}
      </div>
      <div className='container-fluid'>
        <div className='row no-gutters'>
          {hasZmccTransactionList && (
            <div className='col d-flex justify-content-center mb-3'>
              <ItemsPerPage
                itemsPerPage={toArray(itemsPerPage)}
                handleItemsPerPage={handleItemsPerPage}
                value={itemsPerPage[data.size]}
              />
              <Pagination
                currentPage={data.page - 1}
                totalPages={zmccTransactionList.totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ZMCCTransaction;
