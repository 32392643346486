import React, { useCallback } from 'react';
import $ from 'jquery';
import Modal from 'library/common/commonComponents/Modal';
import strings from 'resources/locales/Translate';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { Component } from 'react';
import { ICON_URL } from 'modules/Calendar/calendarConstants';

const mapStyle = [
  {
    height: '50vh',
    width: '95%',
  },
];
export class GoogleMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLocation: this.props.selectedLocation,
      myLocation: {
        lat: -1,
        lng: -1,
      },
    };
  }

  closeMapPopUp = () => {
    $(`#${this.props.id}`).modal('hide');
  };

  handleConfirm = () => {
    const lat = this.state.selectedLocation.lat;
    const lng = this.state.selectedLocation.lng;
    this.props.selectLocation(lat, lng);
    this.closeMapPopUp();
  };

  _mapLoaded = (mapProps, map) => {
    map.setOptions({
      styles: mapStyle,
    });
  };

  componentDidMount() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.setState({
          myLocation: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        });
      });
    } else {
      this.setState({
        myLocation: {
          lat: 0,
          lng: 0,
        },
      });
    }
  }

  mapClicked = (mapProps, map, e) => {
    this.setState({ selectedLocation: { lat: e.latLng.lat(), lng: e.latLng.lng() } });
  };

  render() {
    const { lat, lng } = this.state.selectedLocation;
    const icon = {
      url: ICON_URL, // url
      scaledSize: new this.props.google.maps.Size(20, 30), // scaled size
    };

    return (
      <Modal
        id={this.props.id}
        title={strings.selectLocation}
        className='map-modal'
        onCloseClick={this.closeMapPopUp}
        titleOfCancel={strings.cancel}
        disableBackgroundClose
        confirmAction={this.handleConfirm}
        confirmTitle={strings.selectLocation}
      >
        <div style={{ display: 'flex', flexDirection: 'column', height: '55vh', width: '70vw' }}>
          {this.state.myLocation.lat >= 0 && (
            <Map
              style={{
                height: '55vh',
                width: '97%',
              }}
              google={this.props.google}
              zoom={3}
              initialCenter={this.state.myLocation}
              onReady={(mapProps, map) => this._mapLoaded(mapProps, map)}
              onClick={this.mapClicked}
            >
              <Marker
                position={this.state.myLocation}
                name={strings.myLocation}
                title={strings.myLocation}
                icon={icon}
              />
              {lat && lng && (
                <Marker
                  position={this.state.selectedLocation}
                  name={strings.selectLocation}
                  title={strings.selectLocation}
                />
              )}
            </Map>
          )}
        </div>
      </Modal>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAPS_API_KEY,
})(GoogleMap);
