import { batchActions } from 'redux-batched-actions';

import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { scrollToTop } from 'library/utilities/scrollActions';
import {
  fetchMasterDataService,
  fetchMasterDataServicePost,
  deleteMasterDataService,
} from 'library/api/master-data.service';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import * as actionTypes from 'library/common/commonConstants/storeConstants';
import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';
import { categoriesListRequestJson } from 'library/api/endpoint-request-json';

import { GET_SYSTEM_CLASSES_SUBSCRIPTIONS, GET_EMAIL_TYPES } from './Subscriptions.constants';

export const getSystemClassSubscriptions = () => async (dispatch, getState) => {
  try {
  dispatch(changeLoader(true));

  await dispatch(fetchMasterData(URLS.categoryList, actionTypes.FETCH_CATEGORY_LIST, categoriesListRequestJson));

  const response = await fetchMasterDataService(URLS.currentUserSubscription);

  if (response && response.status === 200) {
    dispatch({
      type: GET_SYSTEM_CLASSES_SUBSCRIPTIONS,
      payload: response.data,
    });
  }
  dispatch(changeLoader(false));
  } catch (err)  {
    dispatch(
      batchActions([
        dispatch(changeLoader(false)),
        dispatch({
          type: GET_SYSTEM_CLASSES_SUBSCRIPTIONS,
          payload: [],
        }),
      ]),
    );
  }
};

export const getEmailTypes = () => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.emailTypes);
    dispatch(changeLoader(true));
    dispatch({
      type: GET_EMAIL_TYPES,
      payload: data,
    });
    dispatch(changeLoader(false));
  } catch (err)  {
    dispatch({
      type: GET_EMAIL_TYPES,
      payload: [],
    });
    dispatch(changeLoader(false));
  }
};

export const createOrEditSubscription = (dataToSend, hasEditMode) => async dispatch => {
  try {
    const response = await fetchMasterDataServicePost(URLS.systemClassSubscribe, dataToSend);

    if (response && response.status === 200) {
      const textMessage = hasEditMode ? 'subscribeWasUpdated' : 'subscribeWasAdded';
      dispatch(
        batchActions([
          dispatch(getSystemClassSubscriptions()),
          dispatch(toggleActionMessage(true, 'success', textMessage)),
        ]),
      );
      scrollToTop(500);

      return { success: true };
    }
  } catch (err)  {
    return { success: false, error: 'somethingWentWrongMessage' };
  }
};

export const deleteSubscription = subscriptionId => async dispatch => {
  try {
    const response = await deleteMasterDataService(URLS.deleteSystemClassSubscription(subscriptionId));

    if (response && response.status === 200) {
      dispatch(
        batchActions([
          dispatch(getSystemClassSubscriptions()),
          dispatch(toggleActionMessage(true, 'success', 'subscribeWasDeleted')),
        ]),
      );
      scrollToTop(500);

      return { success: true };
    }
  } catch (err)  {
    return { success: false, error: 'somethingWentWrongMessage' };
  }
};
