import React, { createRef, PureComponent } from 'react';

import strings from 'resources/locales/Translate';
import CreateStockActionButtons from 'library/common/commonComponents/CreateStockActionButtons';
import AddStockController from 'library/common/commonComponents/AddStockController/AddStockContoller';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import {
  addressFormModel,
  descriptionFormModel,
  stateDropdown,
  newStockCreateAddressModel,
  iqsAddress,
} from './Information.constants';
import { scrollToTop } from 'library/utilities/scrollActions';
import { validateForm } from 'library/utilities/ValidateForm.util';
import Button from 'library/common/commonComponents/Button/Button';
import $ from 'jquery';
import GoogleMap from './GoogleMap.component';
import AddNewAddressPopup from './AddNewAddressPopup/AddNewAddressPopup.component';
import AddressList from '../AddressList/AddressList.component';
import Validators from 'library/utilities/Validators';
import { LL_BUSINESS_UNIT, RMS, RMS_L_L, SPECIAL } from 'library/common/commonConstants/storeConstants';
import { STOCK_CONTROLLER } from 'library/common/commonConstants/roles';
import Section from 'library/common/commonComponents/Section/Section';
class Information extends PureComponent {
  constructor(props) {
    super(props);
    const { stockDetails } = props.createStockReducer;
    if (stockDetails && stockDetails.id) {
      props.updateCrmAccountId(stockDetails.crmAccountId);
    }
    this.addStockController = createRef();
    this.state = {
      countryId: null,
      descriptionForm: {
        formData:
          stockDetails && stockDetails.id
            ? {
                businessUnit: stockDetails.businessUnit,
                businessUnitFullValue: stockDetails.businessUnitFullValue || [],
                country: stockDetails.country && stockDetails.country,
                countryFullValue: stockDetails.countryFullValue || [],
                locationName: stockDetails.locationName,
                locationShortName: stockDetails.locationShortName,
                crmAccountId: stockDetails.crmAccountId,
                sapAccountId: stockDetails.sapAccountId,
                locationCrmId: stockDetails.locationCrmId,
                standardSapPlant: stockDetails.standardSapPlant,
                standardSapStorageLocation: stockDetails.standardSapStorageLocation
              }
            : {},
        isFormValid: false,
      },
      addressForm: {
        formData:
          stockDetails && stockDetails.id
            ? {
                salutation: stockDetails.salutation,
                firstName: stockDetails.firstName,
                lastName: stockDetails.lastName,
                organization: stockDetails.organization,
                department: stockDetails.department,
                street: stockDetails.street,
                number: stockDetails.number,
                postalCode: stockDetails.postalCode,
                stateName: stockDetails.stateName,
                city: stockDetails.city,
                phone: stockDetails.phone,
                email: stockDetails.email,
                latitude: stockDetails.latitude,
                longitude: stockDetails.longitude,
              }
            : {
                addressType: 'Main Address',
              },
        isFormValid: false,
      },
      stockControllersForm: [],
      stockControllersToDelete: [],
      stateData: null,
      iqsEmailAddress: {
        formData:
          stockDetails && stockDetails.id ? {
            locationReceptionEmail: stockDetails.locationReceptionEmail,
            locationContactEmail: stockDetails.locationContactEmail
          } : {}
        
      }
    };
  }

  componentDidMount = async () => {
    const { stockDetails } = this.props.createStockReducer;
    if (stockDetails && stockDetails.id) {
      const stateData = await this.props.getStateInput(stockDetails.countryFullValue);
      this.setState(prevState => ({
        stateData,
        addressForm: {
          formData: { ...this.state.addressForm.formData, state: null, stateFullValue: null, stateError: null },
        },
      }));
    }
    const { user } = this.props;
    if (user) {
      this.handleDefaultBusinessUnit(user, stockDetails);
    }
  }

  handleDefaultBusinessUnit = (user, stockDetails) => {
    if (
      ((stockDetails && stockDetails.stockUserRole && stockDetails.stockUserRole.length === 0) ||
        stockDetails.businessUnit === LL_BUSINESS_UNIT) &&
      (!user.admin && user.llmanager)
    ) {
      const defaultBusinessUnit = {
        businessUnit: 9,
        businessUnitFullValue: LL_BUSINESS_UNIT,
        businessUnitGroup: RMS,
        businessUnitType: SPECIAL,
        designation: RMS_L_L,
        id: 9,
        name: LL_BUSINESS_UNIT,
        deleted: false,
      };

      this.setState(prevState => ({
        descriptionForm: {
          ...prevState.descriptionForm,
          formData: {
            ...prevState.descriptionForm.formData,
            businessUnit: 9,
            businessUnitError: null,
            businessUnitFullValue: defaultBusinessUnit,
          },
        },
      }));
    }
  };
  handleForm = ({ values, field, formName }) => {
    if (field === 'crmAccountId') {
      this.props.updateCrmAccountId(values.crmAccountId);
    }
    this.setState(
      prevState => ({
        [formName]: {
          ...prevState[formName],
          formData: {
            ...((prevState[formName] && prevState[formName].formData) || {}),
            ...values,
          },
        },
      }),
      async () => {
        if (field === 'country') {
          const stateData = await this.props.getStateInput(values.countryFullValue);
          this.setState(prevState => ({
            stateData,
            addressForm: {
              formData: { ...prevState.addressForm.formData, state: null, stateFullValue: null, stateError: null },
            },
          }));
        }
      },
    );
  };

  handleRemoveStockController = accountId => {
    this.setState(prevState => ({
      stockControllersToDelete: [...prevState.stockControllersToDelete, accountId],
    }));
  };

  generateDataForAPI = async () => {
    const { addedStockControllers, deletedStockControllersIds } = this.addStockController.current.getData();
    let { addressForm, descriptionForm, stateData, iqsEmailAddress  } = this.state;
    const chinaLocationAddressModel = [...addressFormModel, stateDropdown]
    const isLLStock = this.state.descriptionForm?.formData?.businessUnitFullValue?.name === 'RMS labs@location Partner';
    const isRMSZMCCStock = this.state.descriptionForm.formData?.businessUnitFullValue?.designation === 'RMS_ZMCC';
    const isIqsSccDeStock = this.state.descriptionForm?.formData?.businessUnitFullValue?.designation === 'IQS_SSC_DE';
    
    const { id: stockId } = this.props.createStockReducer.stockDetails;
    addressForm = validateForm({ form: addressForm, formName: 'addressForm', model: stateData && (!isLLStock && !isRMSZMCCStock) ? chinaLocationAddressModel: addressFormModel });
    descriptionForm = validateForm({
      form: descriptionForm,
      formName: 'descriptionForm',
      model: this.descriptionForm(),
    });
    if (isIqsSccDeStock) {
      iqsEmailAddress =   validateForm({
        form: iqsEmailAddress,
        formName: 'iqsEmailAddress',
        model: iqsAddress,
      });
    }
    let error = '';
    if ((!addressForm.isFormValid && !stockId) || !descriptionForm.isFormValid || (isIqsSccDeStock && !iqsEmailAddress.isFormValid)) {
      error = 'formIsInvalid';
      this.setState({ addressForm, descriptionForm, iqsEmailAddress });
    } else if (!addedStockControllers || !addedStockControllers.length) {
      error = 'addAtleastOneStockController';
    }
    if (error) {
      this.props.toggleActionMessage(true, 'error', error);
    } else {
      const valuesToSend = {
        ...descriptionForm.formData,
        ...addressForm.formData,
        ...iqsEmailAddress.formData,
        [stockId ? 'accoundIds' : 'accountId']: addedStockControllers.map(controller => {
          if (stockId) {
            return {
              accountId: controller.accountId,
              id: controller.stockId || 0,
            };
          }
          return controller.accountId;
        }),
        deletedIds: deletedStockControllersIds,
      };
      if (!valuesToSend.hasOwnProperty('state') || !valuesToSend.state) {
        valuesToSend.state = 0;
      }
      if (!valuesToSend.hasOwnProperty('stateName') || !valuesToSend.stateName) {
        valuesToSend.stateName = '';
      }
      try {
        if (stockId) {
          if (valuesToSend.businessUnitFullValue && valuesToSend.businessUnitFullValue.designation !== 'RMS_L_L') {
            valuesToSend['crmAccountId'] = '';
          }
          const res = await this.props.updateStockInformation(valuesToSend);
          if (res.status === 200) {
            this.addStockController.current.resetDeletedControllers();
          }
        } else {
          const res = await this.props.saveStockInformation(valuesToSend, this.props.history);
          if (res.status === 200) {
            this.addStockController.current.resetDeletedControllers();
          }
        }
      } catch (e) {}
    }
    scrollToTop(500);
  };

  cancelStockCreation = () => {
    this.props.cancelStockCreation();
    this.props.history.replace('/stocks');
  };

  descriptionForm = () => {
    const {user} = this.props;
    const filteredStockIds = user && user.stockRoles && user.stockRoles
    .filter(role => role.roleName === STOCK_CONTROLLER)
    .map(role => role.stockId);
    const { businessUnit, countries } = this.props.masterData;
    const { canEditStock, stockId } = this.props.createStockReducer;
    const { descriptionForm } = this.state;
    const model = [...descriptionFormModel];
    model[4].inputGroupText = '';
    if (businessUnit && businessUnit.length) {
      model[3].options = businessUnit;
    }
    if (countries && countries.length) {
      model[2].options = countries;
    }
    if (descriptionForm.formData && descriptionForm.formData.countryFullValue) {
      model[4].inputGroupText = `${descriptionForm.formData.countryFullValue.shortName} `;
    }
    if (descriptionForm.formData && descriptionForm.formData.businessUnitFullValue) {
      let isSpecialBU = false;
      isSpecialBU = descriptionForm.formData.businessUnitFullValue.name === 'RMS labs@location Partner';
      const name = isSpecialBU ? 'RMS L@L' : descriptionForm.formData.businessUnitFullValue.name;
      if (isSpecialBU) {
        model[1].isDisplayed = false;
        model[0].styleClass = 'col-sm-4';
        model[2].styleClass = 'col-sm-4';
        model[1].validators = [
          { check: Validators.required, message: 'requiredErrorMessage' },
          { check: Validators.number, message: 'invalidNumberError' },
        ];
      } else {
        model[1].isDisplayed = true;
        model[0].styleClass = 'col-sm-6';
        model[2].styleClass = 'col-sm-6';
        model[1].validators = [];
      }
      model[4].inputGroupText += name;
    }
    model[0].disabled = !canEditStock;
    model[2].disabled = stockId ? true : !canEditStock;
    model[3].disabled = !canEditStock;
    model[4].disabled = !canEditStock;
    const stockIdToCheck = Number(stockId);
    model[3].disabled= user.admin || filteredStockIds.includes(stockIdToCheck) ? false:true;
    
    const isIqs = descriptionForm.formData?.businessUnitFullValue?.designation === 'IQS_SSC_DE' ? true : false;
    if (isIqs) {
      model[5].isDisplayed = false;
      model[5].validators =  [{ check: Validators.required, message: 'requiredErrorMessage' },
         { check: Validators.alphaNumericWithSpace, message: 'alphanumericError' }];
      model[6].isDisplayed = false;
      model[6].validators = [{ check: Validators.required, message: 'requiredErrorMessage' }, 
        { check: Validators.alphaNumericWithSpace, message: 'alphanumericError' }];
      model[7].isDisplayed = false;
      model[7].validators =  [{ check: Validators.required, message: 'requiredErrorMessage' }];
      model[8].isDisplayed = false;
      model[8].validators =  [{ check: Validators.required, message: 'requiredErrorMessage' }];
    }else{
      model[5].isDisplayed = true;
      model[5].validators = [];
      model[6].isDisplayed = true;
      model[6].validators = [];
      model[7].isDisplayed = true;
      model[7].validators = [];
      model[8].isDisplayed = true;
      model[8].validators = [];
    }
    return model;
  };

  addressForm = () => {
    const { canEditStock } = this.props.createStockReducer;
    const model = [...newStockCreateAddressModel];
    const { stateData } = this.state;
    const isLLStock =
    this.state.descriptionForm.formData.businessUnitFullValue &&
    this.state.descriptionForm.formData.businessUnitFullValue.name === 'RMS labs@location Partner';
    const isRMSZMCCStock =
    this.state.descriptionForm.formData.businessUnitFullValue &&
    this.state.descriptionForm.formData.businessUnitFullValue.designation === 'RMS_ZMCC';
    if (stateData && (!isLLStock && !isRMSZMCCStock)) {
      model[9] = stateDropdown;
      model[9].options = stateData;
    }
    if (!canEditStock) {
      model.forEach((_, i) => {
        model[i].disabled = true;
      });
    }
    return model;
  };

  showMap = () => {
    $('#googlemap-modal').modal('show');
  };
  selectLocation = (lat, lng) => {
    this.setState(prevState => ({
      addressForm: {
        formData: {
          ...prevState.addressForm.formData,
          latitude: lat,
          longitude: lng,
        },
      },
    }));
  };
  showNewAddressPopup = () => {
    $('#AddAddress-modal').modal('show');
  };
  render() {
    const { canEditStock, addressList, stockId, canAddMultipleAddress } = this.props.createStockReducer;
    const { entitlements, stockDetails, addNewAddress, editAddress, deleteAddress } = this.props;
    const { usersList } = this.props.masterData;
    const { addressForm, descriptionForm, iqsEmailAddress } = this.state;

    const lat = this.state.addressForm.formData.latitude;
    const lng = this.state.addressForm.formData.longitude;
    const isLLStock =
      this.state.descriptionForm.formData.businessUnitFullValue &&
      this.state.descriptionForm.formData.businessUnitFullValue.name === 'RMS labs@location Partner';
    const isRMSZMCCStock =
      this.state.descriptionForm.formData.businessUnitFullValue &&
      this.state.descriptionForm.formData.businessUnitFullValue.designation === 'RMS_ZMCC';

    const disableButton = addressList && addressList.length >= 6;
    const isIqsLocation = descriptionForm.formData?.businessUnitFullValue?.designation === 'IQS_SSC_DE';
    return (
      <div className='information-container mb-5'>
        <div className='container-fluid form-container'>
          <div className='row'>
            <div className='col-md-7'>
              <Section sectionTitle={isIqsLocation ? strings.generalInformation : strings.descriptionText}>
                <FormComponent
                  disableForm={!canEditStock}
                  formName='descriptionForm'
                  model={this.descriptionForm()}
                  formValue={descriptionForm.formData}
                  onChange={this.handleForm}
                />
              </Section>
              <Section sectionTitle={isRMSZMCCStock ? strings.zmccManager : strings.stockController}>
              <AddStockController
                ref={this.addStockController}
                canEditStock={canEditStock}
                dropdownData={usersList}
                existingStockControllers={entitlements || []}
                onRemoveStockController={this.handleRemoveStockController}
                onChange={this.handleForm}
                isRMSZMCCStock={isRMSZMCCStock}
              />
              </Section>
              
            </div>
            <div className='col-md-5'>
            <Section sectionTitle={strings.address}>
            {!stockId && (
                <FormComponent
                  disableForm={!canEditStock}
                  formName='addressForm'
                  model={this.addressForm()}
                  formValue={addressForm.formData}
                  onChange={this.handleForm}
                />
              )}
              {!isLLStock && canAddMultipleAddress && canAddMultipleAddress.isEnabled && canEditStock && (
                <>
                  <Button
                    value={strings.addAddress}
                    styleClass='col-md-4'
                    onClick={this.showNewAddressPopup}
                    disabled={disableButton}
                    bgFill={false}
                    iconName='plus'
                  />
                  <AddNewAddressPopup
                    id='AddAddress-modal'
                    addressFormModelchange={this.addressForm}
                    addNewAddress={addNewAddress}
                    stockId={stockId}
                  />
                </>
              )}
              <div className='mt-3'>
                <AddressList
                  addressList={addressList}
                  editAddress={editAddress}
                  stockId={stockId}
                  deleteAddress={deleteAddress}
                  canEditStock={canEditStock}
                  isRMSZMCCStock={isRMSZMCCStock}
                  allProvinces ={this.state.stateData && this.state.stateData}
                  isLLStock={isLLStock}
                  isIqsLocation={isIqsLocation}
                />
              </div>
              {isLLStock && (
                <div className='col-md-4 p-0 mt-3'>
                  <Button value={strings.addLocationToMap} onClick={this.showMap} />
                </div>
              )}
            </Section>
              
              <div className='mt-2'>
              {isIqsLocation && <Section sectionTitle={strings.address}>
                <div className='mt-3'>
                <FormComponent
                  disableForm={!canEditStock}
                  formName='iqsEmailAddress'
                  model={iqsAddress}
                  formValue={iqsEmailAddress.formData}
                  onChange={this.handleForm}
                />
                </div>
              </Section>}
              </div>
            </div>
          </div>

          {canEditStock && (
            <CreateStockActionButtons onSave={this.generateDataForAPI} onCancel={this.cancelStockCreation} styleClass={'save-next-pev-button'} />
          )}
          {isLLStock && (
            <GoogleMap
              id='googlemap-modal'
              selectedLocation={{ lat: lat, lng: lng }}
              selectLocation={this.selectLocation}
            />
          )}
        </div>
      </div>
    );
  }
}

export default Information;
