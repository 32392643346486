import { useState, useEffect } from 'react';
import $ from 'jquery';

import strings from 'resources/locales/Translate';
import { validateForm } from 'library/utilities/ValidateForm.util';

import { kitForSaleModel } from '../../Information.constants';

export function useKitForSalePopup({
  id,
  sellOffConditions,
  sellOffVisibility,
  sellOffCurrency,
  updateFormCreateKit,
  saveSaleKit,
  saveKit,
  kitInformationId,
  kitStatusForm,
  conditions,
  availabilityTo,
  systemClassData,
  assignmentForm,
  mainComponentForm,
}) {
  const [kitForSaleForm, setKitForSaleForm] = useState({ formData: {
      currencyForTransferPrice: 'EUR',
    },
  });
  const [attachmentFiles, updateAttachmentFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (assignmentForm.systemClass && assignmentForm.systemClassFullValue) {
      setKitForSaleForm({
        formData: {
          currencyForTransferPrice: 'EUR',
          systemClass: assignmentForm.systemClass,
          systemClassFullValue: assignmentForm.systemClassFullValue
        },
      });
    }
  }, [assignmentForm]);

  const closeModal = () => {
    if ($(`#${id}`) && $(`#${id}`).modal) {
      $(`#${id}`).modal('hide');
    }
    clearData();
  };

  const clearData = () => {
    setKitForSaleForm({
      formData: {
        currencyForTransferPrice: 'EUR',
        systemClass: assignmentForm.systemClass,
        systemClassFullValue: assignmentForm.systemClassFullValue
      },
    });
    updateAttachmentFiles([]);
    setError('');
  };

  const getKitForSaleModel = () => {
    const model = [...kitForSaleModel];
    model[0].options = sellOffConditions;
    model[2].options = sellOffVisibility;
    model[3].options = systemClassData;
    model[5].options = sellOffCurrency;

    return model;
  };

  const handleForm = ({ values }) => {
    setKitForSaleForm({
      ...kitForSaleForm,
      formData: {
        ...kitForSaleForm.formData,
        ...values
      },
    });
  };

  const updateKitCondition = () => {
    const kitConditionFullValue = conditions && conditions.length && conditions.find(item => item.kitStatusId === 3);
    const isChangedSystemClass = kitForSaleForm.formData.systemClass !== mainComponentForm.systemMainComponent;

    updateFormCreateKit({
      kitStatusForm: {
        ...kitStatusForm,
        formData: {
          ...((kitStatusForm && kitStatusForm.formData) || {}),
          kitCondition: 3,
          kitConditionFullValue,
          refurbishmentFrom: null,
          refurbishmentTo: null,
          systemClass: kitForSaleForm.formData.systemClass,
          systemClassFullValue: kitForSaleForm.formData.systemClassFullValue,
          systemMainComponent: isChangedSystemClass ? null : mainComponentForm.systemMainComponent,
          systemMainComponentFullValue: isChangedSystemClass ? null : mainComponentForm.systemMainComponentFullValue,
        },
      },
    });
  };

  const handleSave = async () => {
    setError('');
    const form = validateForm({ form: kitForSaleForm, model: getKitForSaleModel() });

    if (form.isFormValid) {
      const valueToSend = {
        ...kitForSaleForm.formData,
        kitInformationId,
      };
      setIsLoading(true);
      const { success } = await saveSaleKit(valueToSend, attachmentFiles);
      setIsLoading(false);

      if (success) {
        const isKitForSale = true;
        updateKitCondition();
        closeModal();
        saveKit(true, isKitForSale);
      } else {
        setError(strings.somethingWentWrongMessage);
      }
    } else {
      setKitForSaleForm(form);
    }
  };

  const addAttachments = files => {
    updateAttachmentFiles([...attachmentFiles, ...files]);
  };

  const removeAttachments = index => {
    const newAattachments = attachmentFiles.filter((_, i) => i !== index);
    updateAttachmentFiles(newAattachments);
  };

  return {
    kitForSaleForm,
    handleSave,
    closeModal,
    isLoading,
    handleForm,
    removeAttachments,
    addAttachments,
    error,
    attachmentFiles,
    getKitForSaleModel,
    clearData,
    updateKitCondition,
  };
};
