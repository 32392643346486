import React, { useState, useRef, useCallback, Children } from 'react';
import cn from 'classnames';

import PopupMenu, { PopupMenuItem } from 'library/common/commonComponents/PopupMenu';
import strings from 'resources/locales/Translate';

const MenuCell = props => {
  const { children, roles, activeRoleId, id, updateItem } = props;
  const ref = useRef(null);

  const [state, setState] = useState();

  const handleOpen = useCallback(() => {
    setState(true);
  }, []);

  const handleClose = useCallback(() => {
    setState(false);
  }, []);

  const handleClick = useCallback(role => {
    updateItem(id, role);
    handleClose();
  }, [handleClose, id, updateItem]);

  return (
    <div style={{ position: 'relative' }}>
      {Children.map(children, child => {
          if (!child || child.props === undefined) return child;

        return React.cloneElement(child, { onClick: handleOpen, ref });
      })}
      {state && (
        <PopupMenu onClose={handleClose} anchorRef={ref}>
          <PopupMenuItem className={cn({ 'active-item': !activeRoleId })} onClick={() => handleClick(null)}>{strings.none}</PopupMenuItem>
          {roles.map(role => (
            <PopupMenuItem key={role.id} className={cn({ 'active-item': activeRoleId === role.id })} onClick={() => handleClick(role)}>{role.role}</PopupMenuItem>
          ))}
        </PopupMenu>
      )}
    </div>
  );
};

export default MenuCell;
