import store from 'main/store/configureStore';

import { logNetworkError } from 'library/utilities/logError';
import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';

import { createUpdateEmailMessages, getStockEmailMessages } from '../../CreateStock.services';

export const fetchEmailMessages = stockId => dispatch => {
  const { rolesList } = store.getState().masterDataReducer;
  return getStockEmailMessages(stockId)
    .then(res => {
      if (res.status === 200) {
        const emailMessages = res.data;
        const data = [];
        const roleAndStatus = [];
        rolesList.forEach(role => {
          roleAndStatus.push({ roleId: role.id, status: false, isActive: false });
        });
        emailMessages.forEach(emailMessage => {
          const tempRoleAndStatus = JSON.parse(JSON.stringify(roleAndStatus));
          emailMessage.roleAndStatus.forEach(roleStatus => {
            const index = tempRoleAndStatus.findIndex(item => item.roleId === roleStatus.roleId);
            if (index > -1) {
              tempRoleAndStatus[index].status = roleStatus.status;
              tempRoleAndStatus[index].isActive = true;
            }
          });
          data.push({
            roleAndStatus: tempRoleAndStatus,
            messagesId: emailMessage.messagesId,
            messageName: emailMessage.messageName,
            categoryName: emailMessage.categoryName,
          });
        });
        return { success: true, data };
      }
    })
    .catch(err => {
      logNetworkError(err);
      dispatch(toggleActionMessage(true, 'error', 'errorOccuredText'));
    });
};

export const saveUpdateEmailMessages = (data, stockId) => dispatch => {
  dispatch(changeLoader(true));
  return createUpdateEmailMessages(data, stockId)
    .then(res => {
      if (res.status === 200) {
        dispatch(toggleActionMessage(true, 'success', 'stockInformationSavedSuccessfully'));
      }
      dispatch(changeLoader(false));
    })
    .catch(err => {
      logNetworkError(err);
      dispatch(changeLoader(false));
      if (err.response.status === 500) {
        dispatch(toggleActionMessage(true, 'error', 'errorOccuredText'));
      }
    });
};
