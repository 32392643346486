import Button from 'library/common/commonComponents/Button/Button';
import React from 'react';
import strings from 'resources/locales/Translate';
import $ from 'jquery';

export const ContinueUseApplicationPopup = ({id, changeLoader}) =>{
    const onClickOk = () =>{
        $(`#${id}`).modal('hide');
        changeLoader(false)
    }
    return(
        <div
        className='modal fade modal-container'
        id={id}
        tabIndex='-1'
        role='dialog'
        aria-labelledby={'confirm-partner'}
        aria-hidden='true'
        data-backdrop='static'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content rounded-0'>
            <div className='modal-body p-0'>
              <div className='content' id='app-scheduler-wrapper'>
                
                <p>{strings.continueAppMessage}</p>
                <div className='d-flex justify-content-center align-content-center mt-4'>
                  <Button
                    styleClass='btn-max-width-300'
                    value={strings.ok}
                    bgFill={true}
                    onClick={onClickOk}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default ContinueUseApplicationPopup