import { useState } from 'react';
import $ from 'jquery';

import strings from 'resources/locales/Translate';
import { validateForm } from 'library/utilities/ValidateForm.util';
import { exportModel } from './NotifyPopup.constants';

export function useNotifyPopup({
  id,
  categoriesAndSystems,
  countries,
  createSubscription,
  subscriptions,
}) {
  const [loading, setLoading] = useState(false);
  const [notifyForm, setNotifyForm] = useState({ formData: {
    countryId: null,
    countryIdFullValue: null,
    systemClassIds: null,
    systemClassIdsFullValue: null,
  }, isFormValid: false });
  const [error, setError] = useState('');

  const onCloseClick = () => {
    const popup = $(`#${id}`);
    if (popup && popup.modal) {
      popup.modal('hide');
    }
    setError('');
    setNotifyForm({ formData: {}, isFormValid: false });
  };

  const checkSubscriptionsCount = () => {
    const maxCount = 10;
    const countCurrentSubscriptions = subscriptions.length;
    const countNewSubscriptions = (notifyForm.formData.systemClassIds && notifyForm.formData.systemClassIds.length) || 0;
    return !(countCurrentSubscriptions + countNewSubscriptions > maxCount);
  };

  const onSaveClick = async () => {
    setError('');
    const validForm = validateForm({
      form: notifyForm,
      model: exportModel({ categoriesAndSystems, countries }),
    });
    if (!validForm.isFormValid) {
      return setNotifyForm(validForm);
    } 

    const isValidCount = checkSubscriptionsCount();
    if (!isValidCount) {
      return setError(strings.youCanNotHaveMore);
    } 

    setLoading(true);
    const { success, error } = await createSubscription(validForm.formData);
    setLoading(false);

    if (!success) {
      setError(error);
    } else {
      onCloseClick();
    }
  };

  const handleForm = ({ values }) => {
    setNotifyForm({
      ...notifyForm,
      formData: {
        ...notifyForm.formData,
        ...values,
      },
    });
  };

  return {
    notifyForm,
    loading,
    error,
    onSaveClick,
    handleForm,
    onCloseClick,
  };
};
