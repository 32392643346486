import {connect} from 'react-redux';

import LoanList from "modules/LoanList/LoanList";
import {toggleActionMessage} from "library/common/commonActions/AppActionsActions";

import {
  removeItemFromCart,
  toggleSelectionForKit,
  updateDateOfCartItem,
  fetchCartDataFromLocalStorage,
  closeAlternateDateSuggestionMessage,
} from "./LoanListActions";
import {reserveKit} from "../KitLoan/KitLoanActions";
import { removeLoanFromCart, getListOfUnexecutedSemTransaction } from 'modules/ZmccRequest/SEMBooking/SemBooking.actions';

const mapStateToProps = state => {
  return {
    cart: state.loanList.cart,
    language: state.languageReducer,
    appActions: state.appActionsReducer,
    alternateMessageData: state.loanList.alternateMessageData,
    user: state.authReducer.user,
    allUnexecutedtransactions: state.createSEMFormReducer.allUnexecutedLoans
  }
};

export default connect(mapStateToProps, {
  toggleSelectionForKit,
  removeItemFromCart,
  updateDateOfCartItem,
  toggleActionMessage,
  fetchCartDataFromLocalStorage,
  reserveKit,
  closeAlternateDateSuggestionMessage,
  removeLoanFromCart,
  getListOfUnexecutedSemTransaction
})(LoanList);
