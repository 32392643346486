import Validators from 'library/utilities/Validators';
import strings from 'resources/locales/Translate';

export const generalInformationModel = [
  {
    label: 'zmccFullForm',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'stockInformationId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-4',
    options: [],
    hasSection: true,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'locationName', separator: ' ' }],
    optionsArrayKey: 'stockInformation',
    titleDisplay: [
      { key: 'shortName', separator: ' ' },
      { key: 'name', separator: '' },
    ],
  },
  {
    label: 'thisFormIsIntendedFor',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'intendedForId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-4',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: ' ' }],
  },
  {
    label: 'nameOfRequestor',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'requesterId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-4',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'accountId',
    displayValue: [
      { key: 'lastName', separator: ', ' },
      { key: 'firstName', separator: '' },
    ],
  },
  {
    label: 'precallWithZmcc',
    value: '',
    type: 'enhanced-RadioButton',
    placeholder: '',
    field: 'precallWithZmcc',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-4 mt-3',
    options: [
      {
        id: 'yes',
        value: 'yes',
      },
      {
        id: 'no',
        value: 'no',
      },
    ],
  },
  {
    label: 'email',
    value: "",
    type: 'text',
    placeholder: '',
    field:'email',
    validators: [
      { check: Validators.email, message: 'invalidEmailError' },
      { check: Validators.required, message: 'requiredErrorMessage' },
    ],
    required: true,
    styleClass: 'col-sm-4',
    extraProps: {
      maxLength: 50
    },
  },
  {
    label: 'mobile',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'mobile',
    validators: [{ check: Validators.mobileAlphaNumericWithPlus, message: 'alphanumericError'}],
    required: false,
    styleClass: 'col-sm-4',
    disabled: false,
  },
  
];

export const schedulingInformationModel = [
  {
    label: 'systemCategory',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'zmccSystemCategoriesId',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: ' ' }],
    showClearIcon: true
  },
  {
    label: 'primaryInstrument',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'kitInformationId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'kitInformationId',
    displayValue: [{ key: 'kitName', separator: ' ' }],
  },
  {
    label: 'additionalInstruments',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'additionalInstrument',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: true,
    selectAllOption: true,
    filter: true,
    idKey: 'kitInformationId',
    displayValue: [{ key: 'kitName', separator: ' ' }],
    showExtraInfo: false,
    infoMessage: 'infoMessageForAdditionalInstrument',
  },
  {
    label: 'suggestedDate',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'suggestedDate',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
    dateFormat: 'DD.MM.YYYY',
    disabled: true,
    tileDisabled: [],
  },
  {
    label: 'alternateDate',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'alternateDate',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
    dateFormat: 'DD.MM.YYYY',
    disabled: true, 
    tileDisabled: [],
  },
  {
    label: 'dueDate',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'dueDate',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
    dateFormat: 'DD.MM.YYYY',
  },
  {
    label: 'estimatedDays',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'estimatedDays',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: ' ' }],
  },
  {
    label: 'needTheDataWithin',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'needDataWithinId',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: ' ' }],
  },
  {
    label: 'MultiSystemDemo',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'corrMicDetails',
    validators: [],
    required: false,
    styleClass: 'col-sm-12 mt-3',
    disabled: false,
    bigSizeInput: 'bigger-input-field',
  },
];

  export const projectDetailsModel = [
    {
      label: 'opportunityId',
      value: '',
      type: 'text',
      placeholder: '',
      field: 'opportunityId',
      validators: [],
      required: true,
      styleClass: 'col-sm-6',
      disabled: false,
    },
    {
      label: 'taskId',
      value: '',
      type: 'text',
      placeholder: '',
      field: 'crmTaskId',
      validators: [],
      required: true,
      styleClass: 'col-sm-6',
      disabled: false,
      isDisplayed: true,
    },
    {
      label: 'firstDemoForThisProject',
      value: '',
      type: 'enhanced-RadioButton',
      placeholder: '',
      field: 'isItFirstDemo',
      validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
      required: true,
      styleClass: 'col-sm-6 mt-3',
      options: [
        {
          id: 'yes',
          value: 'yes',
        },
        {
          id: 'no',
          value: 'no',
        },
      ],
    },
    {
      label: 'customerApplyingForFunding',
      value: '',
      type: 'enhanced-RadioButton',
      placeholder: '',
      field: 'isCustomerApplyingForFunding',
      validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
      required: true,
      styleClass: 'col-sm-9 mt-3 pl-4',
      options: [
        {
          id: 'yes',
          value: 'alreadyBudgeted',
        },
        {
          id: 'no',
          value: 'applyingForFunding',
        },
      ],
    }
  ];
