export const stockDashboardBreadcrumbData = [
  {
    name:'homepage',
    url: '/home',
  },
  {
    name:'stockDashboard',
  },
];

export const FETCH_STOCK_BY_COUNTRY= 'FETCH_STOCK_BY_COUNTRY';
export const FETCH_INITIAL_DATA_FOR_STOCK_DASHBOARD = 'FETCH_INITIAL_DATA_FOR_STOCK_DASHBOARD';
export const FETCH_OUTGOING_STATISTICS = 'FETCH_OUTGOING_STATISTICS';
export const FETCH_INCOMING_STATISTICS = 'FETCH_INCOMING_STATISTICS';
export const FETCH_LOAN_AVAILABILITY_BY_STOCKS = 'FETCH_LOAN_AVAILABILITY_BY_STOCKS';
export const FETCH_THIS_MONTH_STATISTICS = 'FETCH_THIS_MONTH_STATISTICS';
