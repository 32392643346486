import Validators from 'library/utilities/Validators';

export const requestPickUpModel = [
  {
    label: 'email',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'emailAddress',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    options: [],
    multiSelect: false,
    filter: false,
    idKey: 'value',
    displayValue: [{ key: 'value', separator: ' ' }],
  },
  {
    label: 'ccEmail',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'ccEmail',
    validators: [],
    required: false,
    styleClass: 'col-12',
    options: [],
    multiSelect: true,
    filter: false,
    idKey: 'value',
    displayValue: [{ key: 'value', separator: ' ' }],
    isDisplayed: true
  },
  {
    label: 'pickUp',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'picUpType',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    options: [],
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'value', separator: ' ' }],
  },
  
  {
    label: 'pickUpDate',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'pickUpDate',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12 col-sm-6',
    momentDateFormat: true,
  },

  {
    label: 'subject',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'subject',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
  },
  {
    label: 'emailText',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'emailBody',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    clear: true,
    isClearable: true,
  },
];
