import { connect } from 'react-redux';

import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';

import CreateZmccKit from './CreateZmccKit.component';
import {
  updateForm,
  fetchStocks,
  createZmccKit,
  resetForm,
  fetchRoomsListForWarehouse,
  fetchSystemClasses,
  fetchUsersOfBusinessUnit,
  fetchZMCCKitDetails,
  fetchFunctionDropdownValues,
  addUpdateNewComponent,
  fetchNewComponentsList,
  deleteNewComponent,
  createKitMessage,
  fetchKitMessagesList,
  fetchKitResourceAttachmentCategories,
  getKitAttachmentList,
  deleteKitAttachment,
  getIsMarketplaceActive,
  getSystemClassByProductHierarchy
} from './CreateZmccKit.actions';
import { getAttachmentCategory } from 'modules/Stock/CreateStock/CreateStock.actions';
import { fetchKitDetailsMessages, fetchKitDetailsComments } from 'library/common/commonActions/MessagesCommentsActions';
import { toggleActionMessage, changeLoader } from 'library/common/commonActions/AppActionsActions';
import { fetchUserList } from 'modules/KitLoan/KitLoanActions';

const mapStateToProps = ({ appActionsReducer, masterDataReducer ,authReducer, createZmccKitReducer, languageReducer, createStockReducer }) => ({
  appActions: appActionsReducer,
  generalInformationForm: createZmccKitReducer.generalInformationForm,
  informationForm: createZmccKitReducer.informationForm,
  freeAccessoriesForm: createZmccKitReducer.freeAccessoriesForm,
  furtherImportantComponentForm: createZmccKitReducer.furtherImportantComponentForm,
  salesComponentForm: createZmccKitReducer.salesComponentForm,
  kitStatusForm: createZmccKitReducer.kitStatusForm,
  kitImages: createZmccKitReducer.kitImages,
  kitImagesToDelete: createZmccKitReducer.kitImagesToDelete,
  kitAttachments: createZmccKitReducer.kitAttachments,
  kitAttachmentsToDelete: createZmccKitReducer.kitAttachmentsToDelete,
  kitDetails: createZmccKitReducer.kitDetails,
  kitInformationId: createZmccKitReducer.kitInformationId,
  rooms: createZmccKitReducer.rooms,
  zmccUsers: createZmccKitReducer.zmccUsers,
  language: languageReducer,
  user: authReducer.user,
  masterData: masterDataReducer,
  vrDetalisForm: createZmccKitReducer.vrDetalisForm,
  resourceFunctionValues: createZmccKitReducer.resourceFunctionValues,
  iqsIdentifierForm: createZmccKitReducer.iqsIdentifierForm,
  iqsStatusForm: createZmccKitReducer.iqsStatusForm,
  iqsLoanInformationForm: createZmccKitReducer.iqsLoanInformationForm,
  newComponentsForm: createZmccKitReducer.newComponentsForm,
  kitResourceAttachmentCategoriesList: createZmccKitReducer.kitResourceAttachmentCategoriesList,
  newComponentsList: createZmccKitReducer.newComponentsList,
  kitMessagesList: createZmccKitReducer.kitMessagesList,
  kitNewAttachments: createZmccKitReducer.kitNewAttachments,
  marketplaceInformation: createZmccKitReducer.marketplaceInformation,
  marketplacePrice: createZmccKitReducer.marketplacePrice,
  isMarketplaceActiveInLocation: createZmccKitReducer.isMarketplaceActiveInLocation,
  componentsFetchedFromIbase: createZmccKitReducer.componentsFetchedFromIbase,
  ibaseComponentsList: createZmccKitReducer.ibaseComponentsList
});

export default connect(mapStateToProps, {
  updateForm,
  fetchStocks,
  createZmccKit,
  fetchMasterData,
  resetForm,
  fetchSystemClasses,
  fetchRoomsListForWarehouse,
  fetchUsersOfBusinessUnit,
  fetchZMCCKitDetails,
  fetchFunctionDropdownValues,
  addUpdateNewComponent,
  getAttachmentCategory,
  fetchKitDetailsMessages,
  fetchKitDetailsComments,
  fetchNewComponentsList,
  toggleActionMessage,
  deleteNewComponent,
  createKitMessage,
  fetchKitMessagesList,
  fetchKitResourceAttachmentCategories,
  getKitAttachmentList,
  changeLoader,
  deleteKitAttachment,
  fetchUserList,
  getIsMarketplaceActive,
  getSystemClassByProductHierarchy
})(CreateZmccKit);
