import React from 'react';

import ProgressBar from 'library/common/commonComponents/ProgressBar';
import strings from 'resources/locales/Translate';

const ProgressBarBlock = ({ statistics, isShowCourseLoan, isShowSingleLoan }) => {
  const {
    courseLoanDelivered,
    courseLoanTotal,
    demoLoanDelivered,
    demoLoanTotal,
    singleLoanDelivered,
    singleLoanTotal,
  } = statistics;

  return (
    <div className='mx-5'>
      {isShowSingleLoan && (
        <ProgressBar
          bgColor='#0088D0'
          title={strings.singleLoan}
          current={singleLoanDelivered}
          max={singleLoanTotal}
        />
      )}
      <ProgressBar
        bgColor='#39BBFF'
        title={strings.demoLoan}
        current={demoLoanDelivered}
        max={demoLoanTotal}
      />
      {isShowCourseLoan && (
        <ProgressBar
          bgColor='#B5E4FD'
          title={strings.courseLoan}
          current={courseLoanDelivered}
          max={courseLoanTotal}
        />
      )}
    </div>
  );
}

export default ProgressBarBlock;
