import { connect } from 'react-redux';

import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';

import { exportTransactionList, exportKitList, exportKitListWithCountry, exportTransactionListWithCountry } from './ExportData.actions';
import ExportData from './ExportData.component';

const mapStateToProps = ({ languageReducer, appActionsReducer, masterDataReducer }) => ({
  language: languageReducer,
  appActions: appActionsReducer,
  businessUnit: masterDataReducer.businessUnit,
});

export default connect(mapStateToProps, 
  { fetchMasterData, 
    exportTransactionList, 
    exportKitList, 
    exportKitListWithCountry, 
    exportTransactionListWithCountry 
  })(ExportData);
