import { connect } from 'react-redux';

import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';

import DeliveryAddresses from './DeliveryAddresses';
import { fetchDeliveryAddress, populateDropdownValue, saveDeliveryAddress, deleteDeliveryAddress } from './DeliveryAddressesActions';

const mapStateToProps = state => {
  return {
    user: state.authReducer.user,
    language: state.languageReducer,
    masterData: state.masterDataReducer,
    appActions: state.appActionsReducer,
    deliveryAddresses: state.deliveryAddresses,
  }
};

export default connect(mapStateToProps, {
  fetchDeliveryAddress,
  fetchMasterData,
  populateDropdownValue,
  saveDeliveryAddress,
  deleteDeliveryAddress
})(DeliveryAddresses);
