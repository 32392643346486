import { useEffect, useState, useCallback } from 'react';
import $ from 'jquery';

import { validateForm } from 'library/utilities/ValidateForm.util';

import { mappingModel } from './CreateOrEditMapping.constants';

export const useCreateOrEditMapping = ({ id, hasEditMode, selectedMapping, setSelectedMapping, createOrEditMapping, data, countries }) => {

    const [loading, setLoading] = useState(false);
    const [mappingForm, setMappingForm] = useState({ formData: {}, isFormValid: false });
    const [error, setError] = useState('');

    useEffect(() => {
        if (hasEditMode && selectedMapping) {
            setMappingForm({
                formData: {
                    accountId: selectedMapping.accountId,
                    accountName: selectedMapping.accountName,
                    countryId: selectedMapping.country.id,
                    countryIdFullValue: selectedMapping.country,
                    city: selectedMapping.city,
                    postalCode: selectedMapping.postalCode,
                    street: selectedMapping.postalCode,
                    houseNumber: selectedMapping.houseNumber,
                    comment: selectedMapping.comment,
                }
            });
        }
    }, [hasEditMode, selectedMapping]);

    const onCloseClick = () => {
        const popup = $(`#${id}`);
        if (popup && popup.modal) {
            popup.modal('hide');
        }

        setError('');
        setMappingForm({ formData: {}, isFormValid: false });

        if (hasEditMode) {
            setSelectedMapping(null);
        }
    };

    const onSaveClick = async () => {
        setError('');
        const validForm = validateForm({
            form: mappingForm,
            model: mappingModel,
        });
        if (!validForm.isFormValid) {
            return setMappingForm(validForm);
        }
        setLoading(true);
        const { accountId, accountName, countryId, city, postalCode, street, houseNumber, comment } = validForm.formData

        const dataToSend = {
            accountId, accountName, countryId, city, postalCode, street, houseNumber, comment
        }
        const { success, error } = await createOrEditMapping(dataToSend, data, hasEditMode);
        setLoading(false);

        if (!success) {
            setError(error);
            onCloseClick();
        } else {
            onCloseClick();
        }
    };

    const handleForm = ({ values }) => {
        setMappingForm({
            ...mappingForm,
            formData: {
                ...mappingForm.formData,
                ...values,
            },
        });

    };

    const getModel = () => {
        const newModel = [...mappingModel];
        newModel[0].disabled = hasEditMode ? true : false;
        if (countries && countries.length) {
            newModel[2].options = countries;
        }
        return newModel;
    }

    const model = getModel();

    return {
        mappingForm,
        loading,
        error,
        onSaveClick,
        onCloseClick,
        handleForm,
        model,
    };

};