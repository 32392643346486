import { useCallback } from 'react';
import moment from 'moment';
import { validateForm } from 'library/utilities/ValidateForm.util';
import { scrollToTop } from 'library/utilities/scrollActions';
import { fetchFromStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';

import { customerModel, borrowerModel, taskIdModel, nameOfOperatorModal, nameOfPartnerOperatorModel} from './LLKiLoan.constants';
import {
  descriptionModelKitLoan,
  typeOfDeliveryAddressModel,
  deliveryAddressModel,
  addressesForDropdown,
} from './LLKiLoan.constants';
import strings from 'resources/locales/Translate';

export const useLLKitLoanModel = props => {
  const {
    loanReasons,
    descriptionForm,
    customerForm,
    borrowerForm,
    toggleActionMessage,
    updateForm,
    setActiveStep,
    userList,
    fetchRequestors,
    countries,
    addresses,
    typeOfDeliveryAddressForm,
    deliveryAddressForm,
    setHelpForBuildingAndDismantling,
    opportunities,
    handleEnteredOpportunityId,
    enteredOpportunityId,
    createBooking,
    history,
    selectedKits,
    typesOfJobsId,
    nameOfOperatorForm,
    nameOfPartnerOperatorForm,
    operatorId,
    user
  } = props;
  const getDescriptionModel = useCallback(() => {
    const crmData = fetchFromStorage(identifiers.labslocationCrmParams);
    const { taskId } = crmData || {};
    const model = [ ...descriptionModelKitLoan ];

    model[0].options = loanReasons;

    if (taskId) {
      model.push(taskIdModel);
    }

    return model;
  }, [loanReasons]);

  const getBorrowerModel = useCallback(() => {
    const model = [ ...borrowerModel ];

    model[0].options = userList;

    return model;
  }, [userList]);

  const getNmaeOfOperatorModal = useCallback(() => {
    const model = [ ...nameOfOperatorModal ];

    model[0].options =  userList;

    return model;
  }, [userList]);

  const getNameOfOperatorPartnerModal = useCallback(() => {
    const model = [ ...nameOfPartnerOperatorModel ];

    return model;
  }, []);

  const getTypeOfDeliveryAddressModel = useCallback(() => {
    const model = [ ...typeOfDeliveryAddressModel ];

    model[0].options = addressesForDropdown();
    return model;
  }, []);

  const getDeliveryAddressModel = useCallback(() => {
    const model = [ ...deliveryAddressModel ];

    model[10].options = countries;

    return model;
  }, [countries]);

  const descriptionModel = getDescriptionModel();
  const requestorModel = getBorrowerModel();
  const typeDeliveryAddressModel = getTypeOfDeliveryAddressModel();
  const customerSiteModel = getDeliveryAddressModel();
  const operatorNameModel = getNmaeOfOperatorModal();
  const operatorPartnerModel = getNameOfOperatorPartnerModal();

  const handleForm = ({ values, formName }) => {
    if (formName === 'borrowerForm') {
      return fetchRequestors(values.borrowerId, values.borrowerIdFullValue);
    }

    if (formName === 'typeOfDeliveryAddressForm') {
      const form = values.deliveryAddress === 'new' ? {} : addresses;

      return updateForm({
        [formName]: {
          ...props[formName],
          formData: {
            ...((props[formName] && props[formName].formData) || {}),
            ...values,
          },
        },
        deliveryAddressForm: {
          formData: {
            deliveryAddress: values.deliveryAddress,
            ...form,
          },
        },
      });
    }

    updateForm({
      [formName]: {
        ...[formName],
        formData: {
          ...((props[formName] && props[formName].formData) || {}),
          ...values,
        },
      },
    });
  };

  const updateDescriptionForm = (name, deliveryAddressForm) => {
    updateForm({
      descriptionForm: {
        formData: {
          ...descriptionForm.formData,
          name: name ? name : descriptionForm.formData.name,
        },
        isFormValid: descriptionForm.isFormValid,
      },
      deliveryAddressForm: {
        formData: {
          ...deliveryAddressForm,
        },
        isFormValid: false,
      },
      typeOfDeliveryAddressForm: {
        formData: {
          deliveryAddress: 'new',
        },
        isFormValid: false,
      },
    });
  };


  const updateCustomerForm = (customerName, customerFirstAndLastName) => {
    updateForm({
      customerForm: {
        formData: {
          ...customerForm.formData,
          customerInstitute: customerName ? customerName : customerForm.formData.customerInstitute,
          customer: customerFirstAndLastName ? customerFirstAndLastName : customerForm.formData.customer,
        },
        isFormValid: customerForm.isFormValid,
      }
    });
  };

  const opportunityValidation = () =>{
    if (user && user.crmSystemId === 'ngcrm') {
      handleEnteredOpportunityId({ ...enteredOpportunityId, error: `${strings.pleaseMindThatFieldNeedsToHave(18)}` });
    }else{
      handleEnteredOpportunityId({ ...enteredOpportunityId, error: 'pleaseMindThat' });
    }
  }

  const checkSelectedKits = useCallback(() => {
    if (!selectedKits.length) {
      scrollToTop(500);
      toggleActionMessage(true, 'error', 'pleaseSelectKits');

      return false;
    }

    let selectedError = false;
    const start = selectedKits[0].start;
    const end = selectedKits[0].end;
    selectedKits.forEach(item => {
      if (
        moment(start).format('YYYY-MM-DD') !== moment(item.start).format('YYYY-MM-DD') ||
        moment(end).format('YYYY-MM-DD') !== moment(item.end).format('YYYY-MM-DD')
      ) {
        selectedError = true;
      }
    });

    if (selectedError) {
      scrollToTop(500);
      toggleActionMessage(true, 'error', 'sameReservationPeriod');

      return false;
    }

    return true;
  }, [selectedKits, toggleActionMessage]);

  const validateDescriptionForm = useCallback(() => {
    const validatedDescriptionForm = validateForm({ form: descriptionForm, model: descriptionModel });

    return validatedDescriptionForm;
  }, [descriptionForm, descriptionModel]);

  const validateNameOfOperatorForm = useCallback(() => {
    const validatedNameOfOperatorForm = validateForm({ form: nameOfOperatorForm, model: operatorNameModel });

    return validatedNameOfOperatorForm;
  }, [nameOfOperatorForm, operatorNameModel]);

  const validateOperatorPartnerForm = useCallback(() => {
    const validatedOperatorPartnerForm= validateForm({ form: nameOfPartnerOperatorForm, model: operatorPartnerModel });

    return validatedOperatorPartnerForm;
  }, [nameOfPartnerOperatorForm, operatorPartnerModel]);

  const validateCustomerForm = useCallback(() => {
    const validatedCustomerForm = validateForm({ form: customerForm, model: customerModel });

    return validatedCustomerForm;
  }, [customerForm]);

  const validateBorrowerForm = useCallback(() => {
    const validatedBorrowerForm = validateForm({ form: borrowerForm, model: requestorModel });

    return validatedBorrowerForm;
  }, [borrowerForm, requestorModel]);


  const validateCustomerSiteForm = useCallback(() => {
    const validatedCustomerSiteForm = validateForm({ form: typeOfDeliveryAddressForm, model: typeDeliveryAddressModel });

    return validatedCustomerSiteForm;
  }, [typeOfDeliveryAddressForm, typeDeliveryAddressModel]);

  const validateAddressForm = useCallback(() => {
    const validatedAddressForm = validateForm({ form: deliveryAddressForm, model: customerSiteModel });

    return validatedAddressForm;
  }, [deliveryAddressForm, customerSiteModel]);

  const gotToCustomerStep = useCallback(() => {
    const validatedDescriptionForm = validateDescriptionForm();
    const validatedCustomerForm = validateCustomerForm();
    const hasOpportunities = opportunities.length;
    const hasEnteredOpportunity = enteredOpportunityId.value;
    const hasTypesOfJobs = typesOfJobsId.length;
    const validatedNameOfOperatorForm = validateNameOfOperatorForm()
    const validatedOperatorPartnerForm = validateOperatorPartnerForm()
    let isValidForms = validatedDescriptionForm.isFormValid && validatedCustomerForm.isFormValid;
    if (operatorId == 2){
      isValidForms = validatedDescriptionForm.isFormValid && validatedCustomerForm.isFormValid && validatedOperatorPartnerForm.isFormValid;
    }
    const isValidKits = checkSelectedKits();

    if (!isValidForms) {
      scrollToTop(500);
      toggleActionMessage(true, 'error', 'formIsInvalid');
      updateForm({
        descriptionForm: validatedDescriptionForm,
        customerForm: validatedCustomerForm,
      })
      operatorId == 1 ? updateForm({nameOfOperatorForm:validatedNameOfOperatorForm}) : operatorId == 2 && updateForm({nameOfPartnerOperatorForm:validatedOperatorPartnerForm});
      ;
    }

    if (hasEnteredOpportunity) {
      opportunityValidation()
     
    }

    if (!hasOpportunities) {
      opportunityValidation()
    }

    if (!hasTypesOfJobs) {
      scrollToTop(500);
      toggleActionMessage(true, 'error', 'pleaseSelectAnOption');
    }

    if(operatorId == 2){
      if (!nameOfPartnerOperatorForm.formData.llPartnerEmail){
        scrollToTop(500);
        toggleActionMessage(true, 'error', 'invalidEmailError');
      }
      if (!nameOfPartnerOperatorForm.formData.llPartnerName){
        scrollToTop(500);
        toggleActionMessage(true, 'error', 'requiredErrorMessage');
      }
    }
    if (!isValidForms || hasEnteredOpportunity || !hasOpportunities || !hasTypesOfJobs || !isValidKits) return;

    setActiveStep(2);
  }, [setActiveStep, checkSelectedKits, enteredOpportunityId, handleEnteredOpportunityId, toggleActionMessage, updateForm, operatorId]);

  const gotToConfirmationStep = useCallback(() => {
    const validatedBorrowerForm = validateBorrowerForm();
    const validatedCustomerSiteForm = validateCustomerSiteForm();
    const validatedAddressForm = validateAddressForm();
    const isValidForms = validatedBorrowerForm.isFormValid && validatedCustomerSiteForm.isFormValid && validatedAddressForm.isFormValid
    const isValidKits = checkSelectedKits();
    if (!isValidForms) {
      scrollToTop(500);
      toggleActionMessage(true, 'error', 'formIsInvalid');

      updateForm({
        borrowerForm: validatedBorrowerForm,
        typeOfDeliveryAddressForm: validatedCustomerSiteForm,
        deliveryAddressForm: validatedAddressForm,
      });
    }

    if (!isValidForms || !isValidKits) return;

    setActiveStep(3);
  }, [checkSelectedKits, setActiveStep, toggleActionMessage, updateForm]);

  const handleBookingProccess = useCallback(() => {
    const validatedDescriptionForm = validateDescriptionForm();
    const validatedCustomerForm = validateCustomerForm();
    const validatedNameOfOperatorForm = validateNameOfOperatorForm()
    const validatedOperatorPartnerForm = validateOperatorPartnerForm();
    const validatedBorrowerForm = validateBorrowerForm();
    const validatedCustomerSiteForm = validateCustomerSiteForm();
    const validatedAddressForm = validateAddressForm();
    const hasOpportunities = opportunities.length;
    const hasEnteredOpportunity = enteredOpportunityId.value;
    const hasTypesOfJobs = typesOfJobsId.length;
    let isValidForms = validatedDescriptionForm.isFormValid && validatedCustomerForm.isFormValid && validatedBorrowerForm.isFormValid && validatedCustomerSiteForm.isFormValid && validatedAddressForm.isFormValid;
    if (operatorId == 2){
      isValidForms = validatedDescriptionForm.isFormValid && validatedCustomerForm.isFormValid && validatedOperatorPartnerForm.isFormValid && validatedBorrowerForm.isFormValid && validatedCustomerSiteForm.isFormValid && validatedAddressForm.isFormValid;
    }
    const isValidKits = checkSelectedKits();
    if (!isValidForms) {
      scrollToTop(500);
      toggleActionMessage(true, 'error', 'formIsInvalid');
      updateForm({
        descriptionForm: validatedDescriptionForm,
        customerForm: validatedCustomerForm,
        nameOfOperatorForm: validatedNameOfOperatorForm,
        borrowerForm: validatedBorrowerForm,
        typeOfDeliveryAddressForm: validatedCustomerSiteForm,
        deliveryAddressForm: validatedAddressForm,

      })
      operatorId == 1 ? updateForm({nameOfOperatorForm:validatedNameOfOperatorForm}) : operatorId == 2 && updateForm({nameOfPartnerOperatorForm:validatedOperatorPartnerForm});
    }

    if (hasEnteredOpportunity) {
      opportunityValidation()
    }

    if (!hasOpportunities) {
      opportunityValidation()
    }

    if (!hasTypesOfJobs) {
      scrollToTop(500);
      toggleActionMessage(true, 'error', 'pleaseSelectAnOption');
    }

    if(operatorId == 2){
      if (!nameOfPartnerOperatorForm.formData.llPartnerEmail){
        scrollToTop(500);
        toggleActionMessage(true, 'error', 'invalidEmailError');
      }
      if (!nameOfPartnerOperatorForm.formData.llPartnerName){
        scrollToTop(500);
        toggleActionMessage(true, 'error', 'requiredErrorMessage');
      }
    }

    if (!isValidForms || !isValidKits || hasEnteredOpportunity || !hasOpportunities || !hasTypesOfJobs) return;

    createBooking(history, selectedKits);
  }, [checkSelectedKits, createBooking, enteredOpportunityId, handleEnteredOpportunityId, history, selectedKits, toggleActionMessage, updateForm]);

  const goToPrevStep = () => {
    setActiveStep(prevState => prevState - 1);
  };

  const handleHelpForBuildingAndDismantling = checked => {
    setHelpForBuildingAndDismantling(checked);
  };

  const goToLoanListPage = () => {
    history.push('/labs-location-calendar');
  };

  const goToCalendar = () => {
    history.push('/labs-location-calendar');
  };


  return {
    descriptionModel,
    requestorModel,
    typeDeliveryAddressModel,
    customerSiteModel,
    operatorNameModel,
    gotToCustomerStep,
    gotToConfirmationStep,
    handleForm,
    goToPrevStep,
    handleHelpForBuildingAndDismantling,
    updateDescriptionForm,
    updateCustomerForm,
    handleBookingProccess,
    goToLoanListPage,
    goToCalendar,
    nameOfPartnerOperatorModel
  }
}
