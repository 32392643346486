import React from 'react';
import cn from 'classnames';

import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';

const Filters = ({ isShowFilter, model, data, handleFilterChange }) => {
  return (
    <div className={cn('filter-wrapper', { 'd-block': isShowFilter, 'd-none': !isShowFilter })}>
      <div className='container-fluid'>
        <FormComponent
          model={model}
          onChange={handleFilterChange}
          formValue={data}
        />
      </div>
    </div>
  );
}

export default Filters;
