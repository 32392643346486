import { useState, useEffect } from 'react';
import moment from 'moment';
import strings from 'resources/locales/Translate';
import * as $ from 'jquery';
import { fetchFromStorage, saveToStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';

export const useAddSchedulePopup = ({
  id,
  getScheludeTypeList,
  addSchedule,
  user,
  getAllScheduledEventList,
  toggleActionMessage,
  language,
  editing,
  onEditClick,
  scheduledDetails,
  convetTime,
  editSchedule,
  setPayloadDataForEventPopup,
  setEditing,
}) => {
  const [selectedDays, setSelectedDays] = useState({
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
  });
  const [recurring, setRecurring] = useState(false);
  const [date, setDate] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [scheduleType, setScheduleType] = useState({ value: null, fullValue: null });
  const [startTime, setStartTime] = useState({ value: null, fullValue: null });
  const [endTime, setEndTime] = useState({ value: null, fullValue: null });
  const [allDay, setAllDay] = useState(false);
  const [title, setTitle] = useState({ value: '', error: null });
  const [selectedDaysError, setSelectedDaysError] = useState('');
  const [scheduleTypeError, setScheduleTypeError] = useState('');
  const [dateError, setDateError] = useState('');
  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');
  const [startTimeError, setStartTimeError] = useState('');
  const [endTimeError, setEndTimeError] = useState('');
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getScheludeTypeList();
  }, [language]);

  const setDataForEdit = () => {
    if (scheduledDetails !== null) {
      setTitle({ value: scheduledDetails.title, error: null });
      setScheduleType({
        value: scheduledDetails.zmccScheduleType.zmccScheduleTypeId,
        fullValue: {
          id: scheduledDetails.zmccScheduleType.zmccScheduleTypeId,
          name: scheduledDetails.zmccScheduleType.zmccScheduledTypesTranslated[0].name,
        },
      });
      setRecurring(scheduledDetails.recurring);
      const initialDaysArray = scheduledDetails.days && scheduledDetails.days.split(',').map(day => day.trim());
      if (initialDaysArray) {
        const updatedSelectedDays = { ...selectedDays };
        Object.keys(updatedSelectedDays).forEach(day => {
          updatedSelectedDays[day] = initialDaysArray.includes(day);
        });
        setSelectedDays(updatedSelectedDays);
      }
      const modifiedStartDate = moment(scheduledDetails.startDate).format('YYYY-MM-DD');
      const modifiedEndDate = moment(scheduledDetails.endDate).format('YYYY-MM-DD');
      const modifiedScheduledDate = moment(scheduledDetails.scheduledDate).format('YYYY-MM-DD');
      setDate(new Date(modifiedScheduledDate));

      if (scheduledDetails.recurring) {
        setStartDate(new Date(modifiedStartDate));
        setEndDate(new Date(modifiedEndDate));
      }

      setStartTime({
        value: scheduledDetails.startTime,
        fullValue: { id: scheduledDetails.startTime, value: convetTime(scheduledDetails.startTime) },
      });
      setEndTime({
        value: scheduledDetails.endTime,
        fullValue: { id: scheduledDetails.endTime, value: convetTime(scheduledDetails.endTime) },
      });
      setAllDay(scheduledDetails.allDayEvent);
    }
  };
  useEffect(() => {
    if (editing) {
      setDataForEdit();
    }
  }, [editing, scheduledDetails]);

  const timeSlotList = [
    { id: '08:00', value: '8:00 AM' },
    { id: '08:30', value: '8:30 AM' },
    { id: '09:00', value: '9:00 AM' },
    { id: '09:30', value: '9:30 AM' },
    { id: '10:00', value: '10:00 AM' },
    { id: '10:30', value: '10:30 AM' },
    { id: '11:00', value: '11:00 AM' },
    { id: '11:30', value: '11:30 AM' },
    { id: '12:00', value: '12:00 PM' },
    { id: '12:30', value: '12:30 PM' },
    { id: '13:00', value: '1:00 PM' },
    { id: '13:30', value: '1:30 PM' },
    { id: '14:00', value: '2:00 PM' },
    { id: '14:30', value: '2:30 PM' },
    { id: '15:00', value: '3:00 PM' },
    { id: '15:30', value: '3:30 PM' },
    { id: '16:00', value: '4:00 PM' },
    { id: '16:30', value: '4:30 PM' },
    { id: '17:00', value: '5:00 PM' },
    { id: '17:30', value: '5:30 PM' },
    { id: '18:00', value: '6:00 PM' }
  ];
  const onChangeRecurring = () => {
    setRecurring(prevSate => !prevSate);
    setSelectedDaysError('');
  };

  const onChangeDate = (date, type) => {
    if (type === 'date') {
      setDate(date);
      setDateError('');
      setSelectedDaysError('');
    } else if (type === 'start') {
      setStartDate(date);
      setStartDateError('');
      setSelectedDaysError('');
    } else {
      setEndDate(date);
      setEndDateError('');
      setSelectedDaysError('');
    }
  };

  const handleOnDayClick = day => {
    setSelectedDays(prevSelectedDays => ({
      ...prevSelectedDays,
      [day]: !prevSelectedDays[day],
    }));
    setSelectedDaysError('');
  };

  const onChangeDropdown = (value, field, fullValue) => {
    if (field === 'scheduleType') {
      setScheduleTypeError('');
      setSelectedDaysError('');
      return setScheduleType({ value, fullValue });
    }
    if (field === 'startTime') {
      setStartTimeError('');
      setSelectedDaysError('');
      return setStartTime({ value, fullValue });
    }
    if (field === 'endTime') {
      setEndTimeError('');
      setSelectedDaysError('');
      return setEndTime({ value, fullValue });
    }
  };

  const onChangeCheckbox = () => {
    setAllDay(prevSate => !prevSate);
    setSelectedDaysError('');
    setStartTimeError('');
    setEndTimeError('');
  };

  const onInputChange = value => {
    setSelectedDaysError('');
    return setTitle({
      value,
      error: null,
    });
  };

  const vaildateFields = () => {
    let isValid = true;
    if (title.value === null || title.value === '') {
      setTitle({ value: '', error: 'requiredErrorMessage' });
      isValid = false;
    }
    if (scheduleType.value === '' || scheduleType.value === null) {
      setScheduleTypeError('requiredErrorMessage');
      isValid = false;
    }
    if ((!recurring && date === '') || date === null) {
      setDateError('requiredErrorMessage');
      isValid = false;
    }
    if ((recurring && startDate === '') || startDate === null) {
      setStartDateError('requiredErrorMessage');
      isValid = false;
    }
    if ((recurring && endDate === '') || endDate === null) {
      setEndDateError('requiredErrorMessage');
      isValid = false;
    }
    if (!allDay && (startTime.value === '' || startTime.value === null)) {
      setStartTimeError('requiredErrorMessage');
      isValid = false;
    }

    if (!allDay && (endTime.value === '' || endTime.value === null)) {
      setEndTimeError('requiredErrorMessage');
      isValid = false;
    }
    return isValid;
  };

  const onSaveClick = async () => {
    if (!vaildateFields()) {
      return;
    }
    const selectedDaysString = Object.keys(selectedDays)
      .filter(day => selectedDays[day])
      .map(day => day)
      .join(', ');

    if (recurring && selectedDaysString === '') {
      setSelectedDaysError(strings.slecteDaysError);
      return;
    }
    let dataToSend = {
      scheduleTypeId: scheduleType.value,
      title: title.value,
      recurring: recurring,
      startDate: recurring ? moment(startDate).format('YYYY-MM-DD') : moment(date).format('YYYY-MM-DD'),
      endDate: recurring ? moment(endDate).format('YYYY-MM-DD') : moment(date).format('YYYY-MM-DD'),
      startTime: allDay ? null : startTime.value,
      endTime: allDay ? null : endTime.value,
      allDayEvent: allDay === true ? true : false,
      accountId: user.accountId,
      daysSelected: recurring && selectedDaysString ? selectedDaysString : '',
      scheduleDate: date !== '' ? moment(date).format('YYYY-MM-DD') : '',
    };
    if (editing === true) {
      dataToSend.eventGroupId = scheduledDetails.eventGroupId;
      dataToSend.scheduledId = scheduledDetails.operatorsScheduleId;
      dataToSend.onlyCurrentEvent = recurring ? false : true;
      dataToSend.allEvents = recurring ? true : false;
      dataToSend.currentAndFollowingEvents = false;
    }
    if (editing === true && recurring && scheduledDetails.recurring) {
      //show modal
      setPayloadDataForEventPopup(dataToSend);
      $(`#add-my-schedule`).modal('hide');
      setTimeout(() => {
        $('#show-recurring-event-options').modal('show');
      }, 500);
      return;
    }
    setLoading(true);
    const res = await (editing ? editSchedule(dataToSend) : addSchedule(dataToSend));
    setLoading(false);
    if (
      !res.success &&
      res.error &&
      res.error.response &&
      res.error.response.data &&
      res.error.response.data.status === 422
    ) {
      setSelectedDaysError(res.error.response.data.message);
    } else if (!res.success) {
      setSelectedDaysError(strings.somethingWentWrongMessage);
    } else {
      onCloseClick();
      toggleActionMessage(true, 'success', 'slotCreatedSucessfully');
    }
  };

  const onCloseClick = () => {
    $(`#${id}`).modal('hide');
    setEditing(false);
    setTitle({ value: '', error: null });
    setScheduleType({ value: null, fullValue: null });
    setEndDate('');
    setStartDate('');
    setRecurring(false);
    setSelectedDays({
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
    });
    setSelectedDaysError('');
    setStartDateError('');
    setEndDateError('');
    setStartTime('');
    setEndTime('');
    setAllDay(false);
    setScheduleTypeError('');
    setStartTimeError('');
    setEndTimeError('');
    setDateError('');
    setDate('');
  };

  return {
    handleOnDayClick,
    selectedDays,
    onChangeRecurring,
    recurring,
    onChangeDate,
    date,
    startDate,
    endDate,
    timeSlotList,
    onChangeDropdown,
    scheduleType,
    startTime,
    endTime,
    allDay,
    onChangeCheckbox,
    onInputChange,
    title,
    onSaveClick,
    scheduleTypeError,
    dateError,
    startDateError,
    endDateError,
    startTimeError,
    endTimeError,
    selectedDaysError,
    onCloseClick,
    loading,
  };
};
