import React from 'react';

import strings from 'resources/locales/Translate';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import FileUploader from 'library/common/commonComponents/FileUploader/FileUploader';
import Tabs from 'library/common/commonComponents/Tabs/container/TabsContainer';

import { createKitBreadcrumbs } from '../CreateKit/CreateKit.constants';
import { generalInformationModel } from './CreateLLKit.constants';
import { useCreateLLKit } from './CreateLLKit.hook';
import KitPictureUploader from '../KitPictureUploader/KitPictureUploader.component';
import classNames from 'classnames';
import Icon from "../../../library/common/commonComponents/Icon/Icon"
const CreateLLKit = props => {
  const {
    appActions,
    generalInformationForm,
    informationForm,
    updateForm,
    location,
    fetchStocks,
    createLLKit,
    freeAccessoriesForm,
    furtherImportantComponentForm,
    salesComponentForm,
    kitStatusForm,
    kitDetails,
    kitInformationId,
    kitAttachments,
    kitAttachmentsToDelete,
    kitImages,
    kitImagesToDelete,
    fetchMasterData,
    fetchActiveUnitDate,
    fetchLLKitDetails,
    resetForm,
    fetchSystemClasses,
    history,
    match,
  } = props;
  const { displayActionMessage, type, message, isLoading } = appActions;

  const { title, tabs, addKitImageFile, removeKitImageFile, handleForm, onMaxFileReached, maxFilesError } =
    useCreateLLKit({
      history,
      location,
      updateForm,
      fetchStocks,
      generalInformationForm,
      createLLKit,
      freeAccessoriesForm,
      furtherImportantComponentForm,
      salesComponentForm,
      kitDetails,
      kitInformationId,
      kitAttachments,
      kitAttachmentsToDelete,
      kitImages,
      kitImagesToDelete,
      fetchMasterData,
      informationForm,
      fetchActiveUnitDate,
      kitStatusForm,
      fetchLLKitDetails,
      resetForm,
      fetchSystemClasses,
      match,
    });

  return (
    <div className='create-kit-container'>
      {displayActionMessage && <ActionMessage type={type} message={strings[message] || message} />}
      <div className='container-fluid'>
        <Breadcrumbs data={createKitBreadcrumbs()} />
        <h1 className='kits-page-title'>{title}</h1>
        <div className='row' style={{ marginBottom: '2.25rem' }}>
          <div
            className={`${kitImages.length > 0 ? 'col-sm-5' : maxFilesError ? 'col-sm-5' :'col-sm-3'}`}
          >
            <div className='kit-pictures-container mr-4'>
              <KitPictureUploader
                mimeType='image/*,.czi'
                files={kitImages}
                maxSize={50}
                errorMessage={strings.maxFileSize}
                onFileAdd={addKitImageFile}
                onRemove={removeKitImageFile}
                newVersionUi={true}
                isUploadAllowed={true}
                uploaderStyle={'kit-attachments-bordered kits-picture-upload'}
                maxFiles={6}
                onMaxFileReached={onMaxFileReached}
                availableTypes={strings.availableTypesOnlyImagesOnKit}
                maxFilesError={maxFilesError}
                />
            </div>
          </div>
          <div className={`general-information-container ${kitImages.length > 0 ? 'col-7' : maxFilesError ? 'col-sm-7' :'col-8'}`}>
            <FormComponent
              model={generalInformationModel}
              formName='generalInformationForm'
              formValue={generalInformationForm.formData}
              onChange={handleForm}
            />
          </div>
        </div>
      </div>
      <div className='mt-4' style={{ marginTop: '30px' }}>
        <Tabs tabs={tabs} newUiVersion={true} />
      </div>
      {isLoading && <div className='loader' />}
    </div>
  );
};

export default CreateLLKit;
