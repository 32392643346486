import { useState, useEffect, useCallback } from 'react';

import { validateForm } from 'library/utilities/ValidateForm.util';
import { checkStockRoles } from 'library/utilities/checkRoles';
import { DUST_MANAGER, DUST_USER } from 'library/common/commonConstants/roles';

import { editionalCommentModel } from '../CreatingOrEditingEditionalComment/CreatingOrEditingEditionalComment.constants';

export const useComment = ({
  user,
  comment,
  createEditionalComment,
  updateDemoUnitComment,
  kitDetail,
  deleteEditionalComment,
}) => {
  const userName = user ? `${user.firstName} ${user.lastName}` : '';
  const [currentEditionalComment, setCurrentEditionalComment] = useState(null);
  const [editionalCommentForm, setEditionalCommentForm] = useState({
    formData: {
      yourName: userName,
      date: new Date(),
    },
  });
  const [isCreationMode, setIsCreationMode] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const isAdmin = user && user.admin;
  const isServiceTechnician = user && user.globalRoles.includes(DUST_USER);
  const isServiceManager = user && user.globalRoles.includes(DUST_MANAGER);
  const roles = ['Manager', 'Reconditioning'];
  const isManager = user && kitDetail.stockInformation && checkStockRoles(roles, user, kitDetail.stockInformation);
  const hasPermissions = (isAdmin || isServiceManager || isServiceTechnician || isManager) && kitDetail.systemClassActive;

  useEffect(() => {
    if (currentEditionalComment) {
      const { updatedOn, createdBy, text } = currentEditionalComment;
      const yourName = `${createdBy.firstName} ${createdBy.lastName}`

      setEditionalCommentForm({
        formData: {
          yourName,
          date: new Date(updatedOn),
          text,
        },
      });
    }
  }, [currentEditionalComment]);

  const toggleCreationMode = useCallback(() => {
    setIsCreationMode(prevState => !prevState);
    setEditionalCommentForm({
      formData: {
        yourName: userName,
        date: new Date(),
      },
    });
  }, [userName]);

  const handleEditionalCommentForm = useCallback(({ values }) => {
    setEditionalCommentForm(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        ...values,
      },
    }))
  }, []);

  const handleEditEditionalComment = useCallback(editionalComment => {
    setCurrentEditionalComment(editionalComment)
  }, []);

  const handleSaveEditionalComment = useCallback(async () => {
    const validForm = validateForm({
      form: editionalCommentForm,
      model: editionalCommentModel,
    });

    if (!validForm.isFormValid) {
      return setEditionalCommentForm(validForm);
    }

    const dataToSend = {
      demoUnitTaskComment: comment.id,
      text: editionalCommentForm.formData.text,
    };
    const { kitInformationId } = kitDetail;

    setIsLoader(true);
    await createEditionalComment(dataToSend, kitInformationId);
    setIsLoader(false);
    setIsCreationMode(false);
  }, [editionalCommentForm, kitDetail, createEditionalComment, comment.id]);

  const handleEditComment = useCallback(async () => {
    const validForm = validateForm({
      form: editionalCommentForm,
      model: editionalCommentModel,
    });

    if (!validForm.isFormValid) {
      return setEditionalCommentForm(validForm);
    }

    const { kitInformationId } = kitDetail;
    const dataToSend = {
      kitInformationId,
      text: editionalCommentForm.formData.text,
      commentId: currentEditionalComment.id,
    };

    setIsLoader(true);
    await updateDemoUnitComment(dataToSend, kitInformationId);
    setIsLoader(false);
    setCurrentEditionalComment(null);
  }, [editionalCommentForm, currentEditionalComment, kitDetail, updateDemoUnitComment]);

  const resetCurrentEditionalComment = useCallback(() => {
    setCurrentEditionalComment(null);
  }, []);
  const handleDeleteComment = useCallback(async ({editionalComment, deleteStatus}) => {
    const { kitInformationId, kitDUSDComments} = kitDetail;
    const [dusdComments] = kitDUSDComments.filter(comments => comments.id === comment.id);
    const dataToSend = {
      kitInfId:kitInformationId,
      id: (deleteStatus)? comment.id : editionalComment.id,
      ...(deleteStatus) && {
        status: dusdComments.kitDUSDCommentStatus.code
      },
    };
    setIsLoader(true);
    await deleteEditionalComment(dataToSend, kitInformationId);
    setIsLoader(false);
    setCurrentEditionalComment(null);
  }, [kitDetail, comment, deleteEditionalComment]);

  return {
    isCreationMode,
    editionalCommentForm,
    currentEditionalComment,
    hasPermissions,
    isLoader,
    toggleCreationMode,
    handleEditionalCommentForm,
    handleSaveEditionalComment,
    handleEditEditionalComment,
    handleEditComment,
    resetCurrentEditionalComment,
    handleDeleteComment,
  };
}
