import React, { useEffect, useState } from 'react';

import strings from 'resources/locales/Translate';
import Chart from 'library/common/commonComponents/DoughnutChart';
import DateInput from 'library/common/commonComponents/FormComponent/Optimized/DateInput';
import { getDateInFormat } from 'library/utilities/getDateInFormat';

export const Statistics = ({ fetchKitStatistics, kitDetail }) => {
  const [dateRange, setDateRange] = useState(null);
  const [statistics, setStatistics] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const { success, data } = await fetchKitStatistics(kitDetail.kitInformationId, dateRange);
      if (success) {
        setStatistics(data);
      } else {
        setStatistics({});
      }
    };

    if (kitDetail && kitDetail.kitInformationId) {
      getData();
    }
  }, [fetchKitStatistics, kitDetail, dateRange]);

  const onChangeDate = dateRange => {
    if (dateRange) {
      const dateRangeStart = dateRange[0] ? `${getDateInFormat('YYYY-MM-DD', dateRange[0])} 00:00:00` : null;
      const dateRangeEnd = dateRange[1] ? `${getDateInFormat('YYYY-MM-DD', dateRange[1])} 00:00:00` : null;
      setDateRange([dateRangeStart, dateRangeEnd]);
    } else {
      setDateRange(null);
    }
  };

  let dataUtilization = null;
  let dataTransactions = null;
  let hideLegend = false;
  if (statistics) {
    const labels = [];
    const data = [];
    [
      { label: 'stock', field: 'daysInStock' },
      { label: 'refurbishment', field: 'daysInRefurbishment' },
      { label: 'loan', field: 'daysInLoan' },
      { label: 'transport', field: 'daysInTransport' },
    ].forEach(({ label, field }) => {
      if (statistics[field] && typeof statistics[field] === 'number') {
        labels.push(` ${strings[field]}: ${statistics[field]}`);
      }
    });
    ['daysInStock', 'daysInRefurbishment', 'daysInLoan', 'daysInTransport'].forEach(field => {
      if (statistics[field] && typeof statistics[field] === 'number') {
        data.push(statistics[field]);
      }
    });
    if (!data.length) {
      data.push(100);
      labels.push('0 days');
      hideLegend = true;
    }
    dataUtilization = {
      labels,
      datasets: [
        {
          data,
          backgroundColor: ['#747678', '#80C5F6', '#0C2040'],
          hoverBackgroundColor: ['#747678', '#80C5F6EE', '#0C2040EE'],
          datasetIndex: '10',
          order: 4,
        },
      ],
    };
  }

  return (
    <div className='kit-attachments-container form-container'>
      <div className='container-fluid'>
        {statistics && !!Object.keys(statistics).length && (
          <>
            <div className='toggle-container d-flex flex-column align-items-start justify-content-between'>
              <div className='d-flex w-100 flex-row justify-content-between align-items-center'>
                <h3 className='form-section-title mb-0'>{strings.overview}</h3>
                <DateInput
                  mainContainerStyle='d-flex flex-row'
                  datePickerFieldStyle='statistics-datepicker'
                  field='dateRange'
                  label={strings.timePeriod}
                  placeholder={strings.pleaseSelectDate}
                  selectedDate={dateRange}
                  onChange={onChangeDate}
                  isRequired={true}
                  isRange
                  clearIcon
                />
              </div>
              <div className='d-flex flex-row'>
                <div className='percentage-item'>
                  <h4 className='font-bold'>
                    {statistics.amountOfBookings && typeof statistics.amountOfBookings === 'number'
                      ? statistics.amountOfBookings
                      : '0'}
                  </h4>
                  <p>{strings.bookings}</p>
                </div>
                <div className='percentage-item pl-0'>
                  <h4 className='font-bold'>
                    {statistics.utilizationInPercent && typeof statistics.utilizationInPercent === 'number'
                      ? `${parseFloat(statistics.utilizationInPercent).toFixed(2)}%`
                      : '0%'}
                  </h4>
                  <p>{strings.utilization}</p>
                </div>
                <div className='percentage-item'>
                  <h4 className='font-bold'>
                    {statistics.reparationTimeInPercent && typeof statistics.reparationTimeInPercent === 'number'
                      ? `${parseFloat(statistics.reparationTimeInPercent).toFixed(2)}%`
                      : '0%'}
                  </h4>
                  <p>{strings.reparationTime}</p>
                </div>
                <div className='percentage-item'>
                  <h4 className='font-bold'>
                    {statistics.transportationTimeInPercent && typeof statistics.transportationTimeInPercent === 'number'
                      ? `${parseFloat(statistics.transportationTimeInPercent).toFixed(2)}%`
                      : '0%'}
                  </h4>
                  <p>{strings.transportTime}</p>
                </div>
                {statistics.transactionsWon && typeof statistics.transactionsWon === 'number' && (
                  <div className='percentage-item'>
                    <h4 className='font-bold'>
                      {statistics.transactionsWon ? `${parseFloat(statistics.transactionsWon).toFixed(2)}%` : '0%'}
                    </h4>
                    <p>{strings.wonOpportunities}</p>
                  </div>
                )}
              </div>
            </div>

            <div className='row mt-3'>
              {dataUtilization && (
                <div className='col col-12 col-md-6 col-lg-5 mb-4'>
                  <h3 className='form-section-title'>{strings.utilization}</h3>
                  <Chart data={dataUtilization} height={200} hideLegend={hideLegend} />
                </div>
              )}
              {dataTransactions && <div className='col col-12 col-md-5'></div>}
            </div>
          </>
        )}
        {statistics && !Object.keys(statistics).length && <div className='dummy-data'> {strings.noDataFoundText}</div>}
      </div>
    </div>
  );
};

export default Statistics;
