import React, { useEffect, useState, useCallback } from 'react';
import $ from 'jquery';
import { contactPopupModel } from './ContactPopup.constants';
import { validateForm } from 'library/utilities/ValidateForm.util';
import strings from 'resources/locales/Translate';

export const useContactPopup = ({
  id,
  countries,
  selected,
  getStockInformation,
  sendKitSignalizeDemand,
  changeShowContactPopup,
  fetchSystemClass,
}) => {
  const [model, setModal] = useState(contactPopupModel);
  const [contactForm, setContactForm] = useState({ formData: {} });
  const [stockInformation, setStockInformation] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [systemClasses, setSystemClasses] = useState([]);

  const preSelectedValue = () => {
    if (selected && selected.stock && selected.stock.length === 1) {
      setContactForm(prevState => ({
        formData: {
          stock: selected.stock[0],
          stockFullValue: selected.stockFullValue[0],
        },
      }));
      fetchStockInfo(selected.stock[0]);
      fetchSystemClasses(selected.stockFullValue[0].businessUnitName);
    }
  };
  const fetchStockInfo = async id => {
    const res = await getStockInformation(id);
    if (res && res.res.status === 200) {
      return setStockInformation(prevState => res.res.data);
    }
  };
  const fetchSystemClasses = async id => {
    const res = await fetchSystemClass(id);
    if (res && res.res.status === 200) {
      const data = res.res.data['systemClasses'].map(item => {
        return item;
      });
      return setSystemClasses(prevState => data);
    }
  };

  useEffect(() => {
    const newModel = [...model];
    newModel[0].options = countries;
    if (selected && selected.stock && selected.stock.length === 1) {
      preSelectedValue();
    }
    setModal(newModel);
  }, [countries]);

  useEffect(() => {
    const newModel = [...model];
    newModel[1].options = systemClasses;
    setModal(newModel);
  }, [systemClasses]);

  const handleForm = ({ values, field, formName }) => {
    if (field === 'stock') {
      setContactForm(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          ...values,
        },
      }));
      fetchStockInfo(values.stock);
      fetchSystemClasses(values.stockFullValue.businessUnitName || values.stockFullValue.businessUnit?.name);
    }
    return setContactForm(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        ...values,
      },
    }));
  };
  const onSaveClick = async () => {
    const validForm = validateForm({
      form: contactForm,
      model,
    });

    if (!validForm.isFormValid) {
      return setContactForm(validForm);
    }
    setIsLoading(true);
    const { stock, systemClass } = validForm.formData;
    const dataToSend = {
      warehouseId: stock,
      systemMainComponentId: systemClass,
    };
    const { success } = await sendKitSignalizeDemand(dataToSend);
    setIsLoading(false);
    if (!success) {
      setError(strings.somethingWentWrongMessage);
    } else {
      onCloseClick();
    }
  };
  const onCloseClick = useCallback(() => {
    if (selected && selected.stock && selected.stock.length === 1) {
      preSelectedValue();
    }
    setError('');
    $(`#${id}`).modal('hide');
    changeShowContactPopup();
  }, []);

  return {
    model,
    onCloseClick,
    handleForm,
    contactForm,
    stockInformation,
    isLoading,
    onSaveClick,
    error,
  };
};
