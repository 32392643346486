import React from 'react';
import moment from 'moment';

import strings from 'resources/locales/Translate';
import AppScheduler from 'library/common/commonComponents/AppSchedular/AppSchedular';
import Modal from 'library/common/commonComponents/Modal';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';

import { adjustDatesFormModel } from './LLAdjustDates.constants';
import { useLLAdjustDates } from './LLAdjustDates.hook';

const LLAdjustDates = props => {
  const { transactionDetails, id, visible } = props;
  const {
    isLoading,
    adjustDatesForm,
    bookedKits,
    errorMessage,
    onClose,
    onSave,
    handleForm,
  } = useLLAdjustDates(props);

  return (
    <Modal
      id={id}
      className='modal-lg'
      title={strings.adjustDates}
      confirmAction={onSave}
      confirmDataTest='adjust-dates-button-save'
      loading={isLoading}
      onCloseClick={onClose}
      confirmTitle={strings.save}
      titleOfCancel={strings.cancel}
    >
      <div className='adjust-dates-content-container'>
        <FormComponent model={adjustDatesFormModel} formValue={adjustDatesForm.formData} onChange={handleForm} />
        {!!errorMessage && <span className='text-danger error-block'>{errorMessage}</span>}
        {visible && (
          <AppScheduler
            resources={[
              {
                id: 'r0',
                name: 'Resource0',
                groupOnly: true,
              },
            ]}
            updateEventsOnChange
            disableClick
            resizeable={false}
            dragable={false}
            schedulerWrapperClassName='mr-3'
            hideOptions
            startDate={transactionDetails && moment(transactionDetails.targetReservationFrom).format('YYYY-MM-DD')}
            alreadyBooked={bookedKits}
            isLabsLocation={true}
            isCalenderFromAdjustDates={true}
            events={[]}
            cart={[]}
          />
        )}
      </div>
    </Modal>
  );
};

export default LLAdjustDates;
