import React, { forwardRef, useCallback } from 'react';
import cn from 'classnames';

import styles from './PopupMenuItem.module.scss';

const PopupMenuItem = (props, ref) => {
  const { className, rightDecorator, disabled = false, onClick, active = false, children, ...rest } = props;
  const isInteractive = !!onClick && !disabled;
  const classes = cn(
    {
      [styles.item]: true,
      [styles.disabled]: disabled,
      [styles.active]: active,
      [styles.interactive]: isInteractive,
    },
    className,
  );

  const handleClick = useCallback(
    event => {
      if (disabled) return;
      if (onClick) onClick(event);
    },
    [onClick, disabled],
  );

  return (
    <div
      data-popup-menu-interactive={isInteractive ? true : undefined}
      tabIndex={isInteractive ? -1 : undefined}
      className={classes}
      ref={ref}
      onClick={handleClick}
      {...rest}
    >
      <span className={styles.title}>{children}</span>
      {rightDecorator && <span className={styles.rightDecorator}>{rightDecorator}</span>}
    </div>
  );
};

export default forwardRef(PopupMenuItem);
