import React from 'react';

import Button from 'library/common/commonComponents/Button/Button';
import { getDateInFormat } from 'library/utilities/getDateInFormat';
import CreatingOrEditingEditionalComment from '../CreatingOrEditingEditionalComment';
import Icon from 'library/common/commonComponents/Icon/Icon';
import { DeleteIcon, DustCommentArrowIcon, EditOutlineIcon } from 'library/common/commonComponents/Icon/SvgIcons/Icons';

const EditionalComment = ({
  editionalComment,
  currentEditionalComment,
  userId,
  handleEdit,
  handleForm,
  form,
  handleUpdate,
  handleDelete,
  resetCurrentEditionalComment,
  isLoading,
  kitDetail,
}) => {
  const { createdBy, updatedOn, text } = editionalComment;
  const name = `${createdBy.firstName} ${createdBy.lastName}`;
  const date = getDateInFormat('DD.MM.YYYY', updatedOn);
  const createdById = createdBy ? createdBy.id : null;
  const isShowEditButton = (userId === createdById) && kitDetail.systemClassActive;

  if (editionalComment.id === (currentEditionalComment && currentEditionalComment.id)) {
    return (
      <CreatingOrEditingEditionalComment
        form={form}
        handleForm={handleForm}
        handleCancel={resetCurrentEditionalComment}
        handleSave={handleUpdate}
        isLoading={isLoading}
      />
    );
  }

  return (
    <>
      <div className='d-flex mb-2'>
        <div className='d-flex align-items-center ml-3' style={{width: '89%'}}>
          <div className='ml-2'>
            <DustCommentArrowIcon />
          </div>
          <div className='d-flex align-items-center ml-3'>
            <p className='dust-comment-name-date'>
              <span>{date}, </span>
              <span>{name}</span>
            </p>
          </div>
          <div className='description-container ml-3 dust-bold-text'>{text}</div>
        </div>

        {isShowEditButton && (
          <div className='d-flex  flex-direction-row justify-content-between align-items-center'>
            <div className='cursor-pointer btn-no-border pr-2' onClick={() => handleEdit(editionalComment)}>
                <EditOutlineIcon />
              </div>
            <div
              className='btn-no-border cursor-pointer'
              onClick={() => handleDelete({ editionalComment, deleteStatus: false })}
            >
                <DeleteIcon width={18} height={21} viewBox={'0 0 15 14'} />
              <i />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EditionalComment;
