export const getCurrencyFormat = (value, currency = 'EUR') => {
  if  (!value) return `0`;

  return currency === 'EUR' ?
    `${(value).toString().replace(/\d(?=(\d{3})+$)/g, '$&.')}` :
    `${(value).toString().replace(/\d(?=(\d{3})+$)/g, '$&,')}`;
}

export const getCurrencyFormatBasedonLanguage = (value, language = 'en') => {
  if  (!value) return `0`;
  return language === 'en' ?
    `${value.toLocaleString('en-EN', {minimumFractionDigits: 2})}` :
    `${value.toLocaleString('de-DE', {minimumFractionDigits: 2})}`;
}

export const getPriceWithCurrencySymbol = (value, currency, symbol) => {
  const price = getCurrencyFormat(value, currency);
  
  return `${symbol}${price}`;
}

export const getPriceWithCurrencyName = (value, currency) => {
  const price = getCurrencyFormat(value, currency);

  return `${price} ${currency}`;
}

export const getPriceBasedonLanguage = (value, language ) =>{
  const price = getCurrencyFormatBasedonLanguage(value, language);

  return `${price} EUR`;
}

export const getCurrencyFormatForChart = (value) => {
  const integerValue = Math.trunc(value);
  const remainder = (value % integerValue).toFixed(2) * 100;
  const showedRemainder = remainder ? `,${remainder}` : ''

  const formattedValue = getCurrencyFormat(integerValue);
  const fullValue = `${formattedValue}${showedRemainder}`;

  return `${fullValue} EUR`;
}
