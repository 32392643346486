import React, { useState, useEffect } from 'react';

import strings from 'resources/locales/Translate';
import { getDateInFormat } from 'library/utilities/getDateInFormat';

export const useKPIsTab = ({
  getKPI,
  kpi,
  getMainUtilizedMainComponents,
  mainUtilizedMainComponents,
  typesOfJobs,
  getTypesOfJobs,
  clearTables,
  language,
}) => {
  const [filters, setFilters] = useState({
    continent: null,
    continentFullValue: null,
    stock: null,
    stockFullValue: null,
    dateRange: null,
  });
  const isShowKPITable = kpi && kpi.length > 0;
  const isShowMainUtilizedMainComponents = mainUtilizedMainComponents && mainUtilizedMainComponents.length > 0;
  const isShowTypesOfJobsTable = typesOfJobs && typesOfJobs.length > 0;

  useEffect(() => {
    if (filters.continent && filters.continent.length > 0 && filters.dateRange ) {
      getKPI({
        continents: filters.continent,
        stockId: filters.stock ? filters.stock : null,
        from: filters.dateRange ? getDateInFormat('YYYY-MM-DD', filters.dateRange[0]) : null,
        to: filters.dateRange ? getDateInFormat('YYYY-MM-DD', filters.dateRange[1]) : null,
      });
      getMainUtilizedMainComponents({
        continents: filters.continent,
        stockId: filters.stock ? filters.stock : null,
        from: filters.dateRange ? getDateInFormat('YYYY-MM-DD', filters.dateRange[0]) : null,
        to: filters.dateRange ? getDateInFormat('YYYY-MM-DD', filters.dateRange[1]) : null,
      });
    }
  }, [filters.continent, filters.stock, filters.dateRange, getKPI, getMainUtilizedMainComponents]);

  useEffect(() => {
    if (filters.continent && filters.continent.length > 0 && filters.dateRange ) {
      getTypesOfJobs({
        continents: filters.continent,
        stockId: filters.stock ? filters.stock : null,
        from: filters.dateRange ? getDateInFormat('YYYY-MM-DD', filters.dateRange[0]) : null,
        to: filters.dateRange ? getDateInFormat('YYYY-MM-DD', filters.dateRange[1]) : null,
      });
    }
  }, [filters.continent, filters.stock, filters.dateRange, getTypesOfJobs, language]);

  useEffect(() => {
    if (filters.continent && filters.continent.length === 0) {
      clearTables();
    }
  }, [filters.continent, clearTables]);

  const getKPITitle = type => {
    switch(type) {
      case 'NUMBER_OF_PARTNERS':
        return strings.numberOfPartners;
      case 'AMOUNT_OF_TRANSACTIONS':
        return strings.numberOfTransactions;
      case 'OPPORTUNITIES_WIN':
        return strings.opportunitiesWon;
      case 'OPPORTUNITIES_LOST':
        return strings.opportunitiesLost;
      case 'OPPORTUNITIES_IN_PROCESS':
        return strings.opportunitiesInProcess;
      case 'OPPORTUNITIES_DISCONTINUED':
        return strings.opportunitiesDiscontinued;
      case 'OPPORTUNITIES_WIN_RATE':
        return strings.opportunityWinRate;
      case 'OPPORTUNITIES_LOST_RATE':
        return strings.opportunityLostRate;
      default:
        return '';
    }
  };

  const addPercent = (type)=>{
    if (type ==='OPPORTUNITIES_WIN_RATE' || type ==='OPPORTUNITIES_LOST_RATE') {
      return '%';
    }else{
      return '';
    }
  }

  const hideValue = type =>{
    if (filters.stock && filters.continent && filters.continent.length > 0 && filters.dateRange && type ==='NUMBER_OF_PARTNERS') {
      for (let index = 0; index < kpi.length; index++) {
        const element = kpi[index];
        element.operatorZeissValue = '-';
        element.operatorPartnerValue = '-';
         break;
      }
    }
  }


  const getKPICols = () => [
    {
      title: strings.kpis,
      name: 'kpis',
      isSort: false,
      render: item => {
        return <>{getKPITitle(item.kpiType)}</>;
      },
    },
    {
      title: strings.operatorZeiss,
      name: 'operatorZeiss',
      isSort: false,
      render: item => {
        return <>{item.operatorZeissValue}{hideValue(item.kpiType)}{addPercent(item.kpiType)}</>;
      },
    },
    {
      title: strings.operatorPartner,
      name: 'operatorPartner',
      isSort: false,
      render: item => {
        return <>{item.operatorPartnerValue}{hideValue(item.kpiType)}{addPercent(item.kpiType)}</>;
      },
    },
    {
      title: strings.total,
      name: 'total',
      isSort: false,
      render: item => {
        return <>{item.totalValue}{addPercent(item.kpiType)}</>;
      },
    },
  ];

  const getTypesOfJobsCols = () => [
    {
      title: '',
      name: '',
      isSort: false,
      render: item => {
        return < div className='set-title-cursor-style' title={item.toolTipValue !== null ? item.toolTipValue : ''}>{item.typesOfJobsName}</div>;
      },
    },
    {
      title: strings.operatorZeiss,
      name: 'operatorZeiss',
      isSort: false,
      render: item => {
        return <>{item.operatorZeissValue}</>;
      },
    },
    {
      title: strings.operatorPartner,
      name: 'operatorPartner',
      isSort: false,
      render: item => {
        return <>{item.operatorPartnerValue}</>;
      },
    },
    {
      title: strings.totalAmountOfDemos,
      name: 'totalAmountOfDemos',
      isSort: false,
      render: item => {
        return <>{item.totalValue}</>;
      },
    },
  ];

  const openKitDetailsPage = kitId => {
    window.open(`${process.env.REACT_APP_UI_CALLBACK_URL}/#/kit_details/${kitId}`, '_blank');
  };

  const getMainUtilizedMainComponentsCols = () => [
    {
      title: strings.systemMain,
      name: 'systemMainComponent',
      isSort: false,
      render: item => {
        return <>{item.name}</>;
      },
    },
    {
      title: strings.noOfBookings,
      name: 'count',
      isSort: false,
      render: item => {
        return <>{item.count}</>;
      },
    },
  ];

  const kpiCols = getKPICols();
  const typesOfJobsCols = getTypesOfJobsCols();
  const mainUtilizedMainComponentsCols = getMainUtilizedMainComponentsCols();

  return {
    filters,
    kpiCols,
    typesOfJobsCols,
    mainUtilizedMainComponentsCols,
    isShowKPITable,
    isShowMainUtilizedMainComponents,
    isShowTypesOfJobsTable,
    setFilters,
    getKPITitle,
    getKPICols,
    getTypesOfJobsCols,
  };
};
