import React, {Component} from 'react';
import * as $ from 'jquery';

import strings from "resources/locales/Translate";
import Icon from "library/common/commonComponents/Icon/Icon";
import Button from "library/common/commonComponents/Button/Button";
import FormComponent from "library/common/commonComponents/FormComponent/FormComponent";

import './createDeliveryAddressStyles.scss';

export default class CreateDeliveryAddressForm extends Component {

  state = {
    addressForm: {},
    doValidate: false,
    formValue: null,
    resetValidation: false
  };

  handleForm = (formData) => {
    this.setState({addressForm: formData});
  };

  handleAddressSave = () => {
    const {onSave} = this.props;
    const {addressForm} = this.state;

    if (addressForm.isFormValid) {
      this.setState({doValidate: false, resetValidation: false});
      onSave(addressForm.formData);
      this.handleModalClose();
    } else {
      this.setState({doValidate: true, resetValidation: false});
    }
  };

  handleModalClose = () => {
    const {onClose} = this.props;
    this.setState({
      addressForm: {},
      doValidate: false,
      resetValidation: true
    });

    $('#create-address-modal').modal('hide');
    onClose();
  };

  render() {
    const {formModel, value, clear, isEditView} = this.props;
    const {doValidate, resetValidation} = this.state;

    return (
      <div className='modal fade createAddressModal'
           id='create-address-modal'
           tabIndex='-1'
           role='dialog'
           aria-labelledby='createAddressModal'
           aria-hidden='true'>
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content rounded-0 '>
            <div className='modal-body p-0'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='content position-relative'>
                    <button type='button' className='close modal-close position-absolute'
                            onClick={this.handleModalClose}>
                      <Icon name='cross' width={24} height={24}/>
                    </button>

                    <h2 className='form-title'>
                      {isEditView ? strings.editDeliveryAddessFormHeader : strings.createDeliveryAddessFormHeader}
                    </h2>

                    <FormComponent
                      clear={clear}
                      value={value}
                      model={formModel}
                      doValidate={doValidate}
                      resetValidation={resetValidation}
                      onChange={(formData, lastEditedField) => this.handleForm(formData, lastEditedField)}/>

                    <div className="row">
                      <div className="col-md-4">
                        <Button iconName='save' value={strings.saveAddressButtonText} onClick={this.handleAddressSave}/>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
