import Validators from 'library/utilities/Validators';

export const mainApplicationChecklist = [
  {
    label: 'highResolutionImaging',
    selected: false,
    type: 'checkbox',
    field: 'highResolutionImaging',
    styleClass: 'col-sm-4',
    newuiVersionStyle: true,
  },

  {
    label: 'eds',
    selected: false,
    type: 'checkbox',
    field: 'eds',
    styleClass: 'col-sm-4',
    newuiVersionStyle: true,
  },
  {
    label: 'lowkvImagingForCharging',
    selected: false,
    type: 'checkbox',
    field: 'lowKVImaging',
    styleClass: 'col-sm-4',
    newuiVersionStyle: true,
  },
  {
    label: 'ebsd',
    selected: false,
    type: 'checkbox',
    field: 'ebsd',
    styleClass: 'col-sm-4',
    newuiVersionStyle: true,
  },
  {
    label: 'materialContrastImaging',
    selected: false,
    type: 'checkbox',
    field: 'materialContrastImaging',
    styleClass: 'col-sm-4',
    newuiVersionStyle: true,
  },
  {
    label: 'correlativeMicroscopy',
    selected: false,
    type: 'checkbox',
    field: 'correlativeMicroscopy',
    styleClass: 'col-sm-4',
    newuiVersionStyle: true,
  },
  {
    label: 'vpMode',
    selected: false,
    type: 'checkbox',
    field: 'vpMode',
    styleClass: 'col-sm-4',
    newuiVersionStyle: true,
  },
  {
    label: 'smartPi',
    selected: false,
    type: 'checkbox',
    field: 'smartPi',
    styleClass: 'col-sm-4',
    newuiVersionStyle: true,
  },
  {
    label: 'wetImaging',
    selected: false,
    type: 'checkbox',
    field: 'wetImagingEPMode',
    styleClass: 'col-sm-4',
    newuiVersionStyle: true,
  },
  {
    label: 'threeView',
    selected: false,
    type: 'checkbox',
    field: 'threeView',
    styleClass: 'col-sm-4',
    newuiVersionStyle: true,
  },
  {
    label: 'largeAreaMpping',
    selected: false,
    type: 'checkbox',
    field: 'largeAreaMapping',
    styleClass: 'col-sm-4',
    newuiVersionStyle: true,
  },
  {
    label: 'raman',
    selected: false,
    type: 'checkbox',
    field: 'raman',
    styleClass: 'col-sm-4',
    newuiVersionStyle: true,
  },
  {
    label: 'stemImaging',
    selected: false,
    type: 'checkbox',
    field: 'stemImaging',
    styleClass: 'col-sm-4',
    newuiVersionStyle: true,
  },
  {
    label: 'cryo',
    selected: false,
    type: 'checkbox',
    field: 'cryo',
    styleClass: 'col-sm-4',
    newuiVersionStyle: true,
  },
  {
    label: 'stageBias',
    selected: false,
    type: 'checkbox',
    field: 'stageBias',
    styleClass: 'col-sm-4',
    newuiVersionStyle: true,
  },
  {
    label: 'mineralogic',
    selected: false,
    type: 'checkbox',
    field: 'mineralogic',
    styleClass: 'col-sm-4',
    newuiVersionStyle: true,
  },
  {
    label: 'othersSuchAsWds',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'others',
    validators: [],
    required: false,
    styleClass: 'col-sm-12',
    disabled: false,
    bigSizeInput: 'bigger-input-field',
  },
  {
    label: 'detailedCompetitionMicroscopeModels',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'detailedCompetitionMicroscopeModels',
    validators: [],
    required: false,
    styleClass: 'col-sm-12',
    disabled: false,
    bigSizeInput: 'bigger-input-field',
  },
  {
    label: 'doYouHaveAnyDataFromTheCustomer',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'dataFromCustomerId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-4',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: '' }],
  },
];
