import React from 'react';

import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import CreateStockActionButtons from 'library/common/commonComponents/CreateStockActionButtons';

import InRefurbishment from '../../../CreateKit/Tabs/Information/InRefurbishmentPopup';
import { useInformationTab } from './InformationTab.hook';
import Section from 'library/common/commonComponents/Section/Section';
import { buildingAndRoomModel } from './InformationTab.constants';

const CreateLLKitInformation = props => {
  const { informationForm, kitStatusForm, cancelCreateKit, saveKit, handleForm, conditions, updateForm } = props;
  const { kitConditionModel, assignmentsModel, mainComponentsModel, availabilityModelForm } = useInformationTab(props);
  return (
    <div className='information-container mb-5'>
      <div className='container-fluid form-container'>
        <div className='row'>
          <div className='col-12'>
            <Section sectionTitle={strings.kitStatus}>
              <div className='mt-3'>
                <FormComponent
                  model={kitConditionModel}
                  formName='kitStatusForm'
                  formValue={kitStatusForm.formData}
                  onChange={handleForm}
                />
              </div>
            </Section>
          </div>
          <div className='col-12 mt-4'>
            <Section sectionTitle={strings.assignment}>
              <div className='mt-3'>
                <FormComponent
                  model={assignmentsModel}
                  formName='informationForm'
                  formValue={informationForm.formData}
                  onChange={handleForm}
                />
              </div>
            </Section>
          </div>
          <div className='col-12 mt-4'>
            <Section sectionTitle={strings.mainComponent}>
              <div className='mt-3'>
                <FormComponent
                  model={mainComponentsModel}
                  formName='informationForm'
                  formValue={informationForm.formData}
                  onChange={handleForm}
                />
              </div>
            </Section>
          </div>
          <div className='col-12 mt-4'>
            <Section sectionTitle={strings.availability}>
              <div className='mt-3'>
                <FormComponent
                  model={availabilityModelForm}
                  formName='informationForm'
                  formValue={informationForm.formData}
                  onChange={handleForm}
                />
              </div>
            </Section>
          </div>
          <div className='col-12 mt-4'>
            <Section sectionTitle={strings.buildingAndRoom}>
              <div className='mt-3'>
                <FormComponent
                  model={buildingAndRoomModel}
                  formName='informationForm'
                  formValue={informationForm.formData}
                  onChange={handleForm}
                />
              </div>
            </Section>
          </div>
        </div>
        <CreateStockActionButtons onSave={saveKit} onCancel={cancelCreateKit} styleClass={'create-kit-button mt-3'}/>
      </div>
      <InRefurbishment
        id='inRefurbishmentStatus'
        conditions={conditions}
        kitStatusForm={kitStatusForm}
        updateFormCreateKit={updateForm}
      />
    </div>
  );
};

export default CreateLLKitInformation;
