import React from 'react';
import Modal from 'library/common/commonComponents/Modal';
import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import { useContactPopup } from './ContactPopup.hook';

export const ContactPopup = ({
  id,
  countries,
  selected,
  getStockInformation,
  sendKitSignalizeDemand,
  changeShowContactPopup,
  fetchSystemClass,
  fetchSystemMaincomponent,
}) => {
  const { model, onCloseClick, handleForm, contactForm, stockInformation, onSaveClick, error, isLoading } =
    useContactPopup({
      id,
      countries,
      selected,
      getStockInformation,
      sendKitSignalizeDemand,
      changeShowContactPopup,
      fetchSystemClass,
      fetchSystemMaincomponent,
    });

  return (
    <Modal
      id={id}
      confirmAction={onSaveClick}
      confirmTitle={strings.save}
      titleOfCancel={strings.cancel}
      loading={isLoading}
      onCloseClick={onCloseClick}
    >
      <FormComponent model={model} formName='contactPopupForm' formValue={contactForm.formData} onChange={handleForm} />
      <div>
        <p className='font-bold'>{strings.stockController}:</p>
        <p>
          {stockInformation && stockInformation.firstName
            ? `${stockInformation.lastName}, ${stockInformation.firstName} (${stockInformation.email})`
            : ' '}
        </p>
      </div>

      {error && <div className='error-block'>{error}</div>}
    </Modal>
  );
};

export default ContactPopup;
