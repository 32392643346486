import React from 'react';
import moment from 'moment';
import strings from 'resources/locales/Translate';
import Button from 'library/common/commonComponents/Button/Button';
import './reparationslots.styles.scss';

export const dataTable = (language,isEditingKit, kitInformationId, deleteKitReparation) => [
  {
    title: strings.from,
    name: 'start',
    isSort: false,
    render: (item) => {
      return (
        <span>{moment.utc(item.reparationFrom).format("YYYY-MM-DD")}</span>
      )
    },
  },
  {
    title: strings.to,
    name: 'end',
    isSort: false,
    render: (item) => {
      return (
        <span>{moment.utc(item.reparationTo).format("YYYY-MM-DD")}</span>
      )
    },
  },
  {
    title: strings.comment,
    name: 'comment',
    isSort: false,
    render: (item) => {
      return (
        <span>{item.reparationComment ? item.reparationComment : '-'}</span>
      )
    },
  },
  {
    title: '',
    name: 'delete',
    render: (item) => {
        return (
          isEditingKit ?
          <span>
            <Button
                bgFill={false}
                iconName='delete'
                value={strings.delete}
                onClick={() => deleteKitReparation(item.kitReparationId)}
                styleClass='deletePermissions-button'
                />
          </span>
          : <></>
        )
      },
  }
];
