import React from 'react';

import strings from 'resources/locales/Translate';
import Button from 'library/common/commonComponents/Button/Button';

const AddressInfo = ({ formData, changeIsEditTypeFlag, addressCountry, flag, isEditable = true }) => (
  <div className='d-flex justify-content-between mb-3'>
    <div className='edit-info-wrapper'>
      <p>{`${formData.firstName ? formData.firstName : ''} ${formData.lastName ? formData.lastName : ''}`}</p>
      <p>{formData.organization ? formData.organization : ''}</p>
      <p>{`${formData.street ? formData.street : ''} ${formData.houseNumber ? formData.houseNumber : ''}`}</p>
      <p>{`${formData.postalCode ? formData.postalCode : ''} ${formData.city ? formData.city : ''}`}</p>
      <p>{addressCountry ? addressCountry : ''}</p>
      <p>{formData.storageLocation ? formData.storageLocation : ''}</p>
    </div>
    <div>
      {isEditable && (
        <Button
          iconName='edit'
          iconPosition='left'
          bgFill={false}
          isGhost={true}
          value={strings.editAddress}
          onClick={() => changeIsEditTypeFlag(flag)}
          styleClass='font-weight-normal'
        />
      )}
    </div>
  </div>
);

export default AddressInfo;
