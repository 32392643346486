import React from 'react';

import strings from 'resources/locales/Translate';
import Table from 'library/common/commonComponents/Table/Table';

const UtilizedKits = ({ title, tableCols, tableData }) => {
  return (
    <div className='utilizedKits-wrapper'>
      <h2 className='mb-3'>{title}</h2>
      {tableData && tableData.length > 0 ? (
        <div className='utilizedKits-table'>
          <Table
            cols={tableCols}
            rows={tableData}
          />
        </div>
      ) : (
        <div className='d-flex justify-content-center my-5'>
          <strong>{strings.noDataFoundText}</strong>
        </div>
      )}
    </div>
  );
};

export default UtilizedKits;
