import { useState, useEffect } from 'react';
import $ from 'jquery';

import strings from 'resources/locales/Translate';

export const useCreateOrEditPopup = ({
  id,
  parentFolderId,
  createFolder,
  fetchdDocumentsAndFolders,
  folder,
  setFolder,
  isEditFolder,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [inputData, setInputData] = useState({
    value: '',
    error: '',
  });
  const [error, setError] = useState('');

  useEffect(() => {
    setInputData({
      value: isEditFolder ? folder.name : '',
      error: '',
    });
  }, [folder, isEditFolder]);

  const onSaveClick = async () => {
    if (!inputData.value.trim()) {
      return setInputData(prevState => ({
        ...prevState,
        error: 'requiredErrorMessage',
      }));
    }

    let dataToSend = {
      folderName: inputData.value,
    };
    if (isEditFolder) {
      dataToSend = {
        ...dataToSend,
        folderId: folder.id,
        parentFolderId: parentFolderId ? parentFolderId : null,
      };
    } else {
      dataToSend = {
        ...dataToSend,
        parentFolderId: parentFolderId ? parentFolderId : null,
      };
    }
  
    setIsLoading(true);
    const { success, data } = await createFolder(dataToSend);
    setIsLoading(false);

    if (success) {
      setInputData({
        value: '',
        error: '',
      });
      onCloseClick();
      fetchdDocumentsAndFolders(parentFolderId);
      setError("");
    } else {
      if(data.response.data.status == 422){
        setError(data.response.data.message);
      }
      else{
        setError(strings.somethingWentWrongMessage);
      }
    }
  };

  const onCloseClick = () => {
    const popup = $(`#${id}`);
    if (popup && popup.modal) {
      popup.modal('hide');
    }
    setInputData({
      value: '',
      error: '',
    });
    setFolder({});
    setError("");
  };

  const handleInput = value => {
    setInputData({
      value,
      error: '',
    });
  };

  return {
    isLoading,
    inputData,
    error,
    onSaveClick,
    handleInput,
    onCloseClick,
  };
}
