import React, { useEffect, useRef, useState } from 'react';
import $ from 'jquery';
import moment from 'moment';
import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/FormComponent';
import Modal from 'library/common/commonComponents/Modal';
import { adjustDatesFormModel } from './AdjustDatesConstants';
import './adjustDates.style.scss';

const getTransactionDates = transactionDetails => ({
  targetDeliveryFrom:
      (moment(transactionDetails.targetDeliveryFrom).isValid() &&
      moment(transactionDetails.targetDeliveryFrom).format('YYYY-MM-DD')),
  targetReservationFrom:
    moment(transactionDetails.targetReservationFrom).isValid() &&
    moment(transactionDetails.targetReservationFrom).format('YYYY-MM-DD'),
  targetCheckBeforeReturn:
  transactionDetails.targetCheckBeforeReturnDate ?
    moment(transactionDetails.targetCheckBeforeReturnDate).isValid() &&
    moment(transactionDetails.targetCheckBeforeReturnDate).format('YYYY-MM-DD')
  : 
    moment(transactionDetails.targetReservationTo).isValid() &&
    moment(transactionDetails.targetReservationTo).subtract(1, 'day').format('YYYY-MM-DD'),
  targetReservationTo:
    moment(transactionDetails.targetReservationTo).isValid() &&
    moment(transactionDetails.targetReservationTo).format('YYYY-MM-DD'),
  targetReturnDeliveryDate:
    (moment(transactionDetails.targetReturnDeliveryTo).isValid() &&
      moment(transactionDetails.targetReturnDeliveryTo).format('YYYY-MM-DD')),
  targetReconditioningTo:
    moment(transactionDetails.targetReconditioningTo).isValid() &&
    moment(transactionDetails.targetReconditioningTo).format('YYYY-MM-DD'),
});

export const AdjustDates = ({
  adjustTransactionDates,
  getTransactionDetailsWithSuggestions,
  enableSave,
  id,
  showScheduler,
  transactionDetails,
  updateTransaction,
}) => {
  const formRef = useRef();
  const [adjustDatesFormData, setAdjustDatesFormData] = useState(null);
  const [datesFormModel, setDatesFormModel] = useState(adjustDatesFormModel(false, transactionDetails));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [conflictError, setConflictError] = useState('');

  useEffect(() => {
    if (transactionDetails) {
      setAdjustDatesFormData(getTransactionDates(transactionDetails));
      setDatesFormModel(adjustDatesFormModel(false, transactionDetails));
      setConflictError('');
    }
  }, [transactionDetails]);

  const onCloseClick = reset => () => {
    if ($(`#${id}`) && $(`#${id}`).modal) {
      $(`#${id}`).modal('hide');
      setConflictError('');
    }
    if (reset) {
      setDataFromTransaction();
    }
  };

  const setDataFromTransaction = () => {
    const targetData = getTransactionDates(transactionDetails);
    if (formRef && formRef.current && formRef.current.dates && Object.keys(formRef.current.dates).length) {
      Object.keys(formRef.current.dates).forEach(key => {
        formRef && formRef.current && formRef.current.dates && formRef.current.dates[key] && formRef.current.dates[key].setState({
          date:
            formRef.current.dates[key].props && targetData[formRef.current.dates[key].props.field]
              ? new Date(targetData[formRef.current.dates[key].props.field])
              : '',
        });
      });
    }
    setError('');
    setAdjustDatesFormData(targetData);
  };

  const onSaveClick = async () => {
    const validateForm = formRef.current.getFormData();
    if (enableSave) {
      const { formData: newFormData } = validateForm;
      if (validateForm.isValid && checkForChanges(newFormData)) {
        const fields = [
          'targetDeliveryFrom',
          'targetReservationFrom',
          'targetCheckBeforeReturn',
          'targetReservationTo',
          'targetReturnDeliveryDate',
          'targetReconditioningTo',
        ];
        const dataToSend = {};
        fields.forEach(key => {
          if (moment(newFormData[key]).isValid()) {
            dataToSend[key] = moment(newFormData[key]).format('YYYY-MM-DD');
          }
        });
        dataToSend['targetReturnDeliveryTo']= dataToSend['targetReturnDeliveryDate'];
        dataToSend['targetReconditioningTo']= datesFormModel.length == 6 ? dataToSend['targetReconditioningTo'] : dataToSend['targetReturnDeliveryDate'];

        const newError = checkError(newFormData);
        if (newError) {
          setError(newError);
        } else {
          if (error) {
            setError('');
          }
          setLoading(true);
          adjustTransactionDates(
            dataToSend,
            transactionDetails.transactionsId,
            'c2c',
            getTransactionDetailsWithSuggestions,
          ).then(res => {
            setLoading(false);
            if (res.success) {
              updateTransaction({
                ...transactionDetails,
                ...dataToSend,
              });
              setConflictError('');
              onCloseClick(false)();
            }
            else{
              setConflictError(res.error && res.error.response && res.error.response.data.message);
            }
          });
        }
      }
    }
  };

  const checkForChanges = formData => {
    let changed = false;
    const fieldsToCheck = [
      'targetDeliveryFrom',
      'targetReservationFrom',
      'targetReservationTo',
      'targetReturnDeliveryFrom',
      'targetReconditioningTo',
      'targetReturnDeliveryDate',
      'targetCheckBeforeReturn'
    ];
    fieldsToCheck.forEach(key => {
      if (
        (!transactionDetails[key] && formData[key]) ||
        (transactionDetails[key] &&
          formData[key] &&
          moment(transactionDetails[key]).format('YYYY-MM-DD') !== moment(formData[key]).format('YYYY-MM-DD'))
      ) {
        changed = true;
      }
    });
    return changed;
  };

  const checkTypeError = ({ fieldOne, fieldTwo, fieldOneString, fieldTwoString, data }) => {
    if (data[fieldOne] && data[fieldTwo] && moment(data[fieldOne]).isAfter(moment(data[fieldTwo]))) {
      return strings.hasToBeBefore(fieldOneString, fieldTwoString);
    }
    return '';
  };

  const checkError = data => {
    const checkArrays = [
      {
        fieldOne: 'targetDeliveryFrom',
        fieldTwo: 'targetReservationFrom',
        fieldOneString: strings.shipped,
        fieldTwoString: strings.receiptAtTheCustomer,
      },
      {
        fieldOne: 'targetReservationFrom',
        fieldTwo: 'targetCheckBeforeReturn',
        fieldOneString: strings.receiptAtTheCustomer,
        fieldTwoString: strings.checkBeforeReturn,
      },
      {
        fieldOne: 'targetCheckBeforeReturn',
        fieldTwo: 'targetReservationTo',
        fieldOneString: strings.checkBeforeReturn,
        fieldTwoString: strings.returnDelivery,
      },
      {
        fieldOne: 'targetReservationTo',
        fieldTwo: 'targetReturnDeliveryDate',
        fieldOneString: strings.returnDelivery,
        fieldTwoString: strings.receivedNextCustomer,
      },
      {
        fieldOne: 'targetReturnDeliveryDate',
        fieldTwo: 'targetReconditioningTo',
        fieldOneString: strings.receivedNextCustomer,
        fieldTwoString: strings.checked,
      },
    ];
    let error = '';
    checkArrays.forEach(item => {
      if (!error) {
        error = checkTypeError({ ...item, data: data });
      }
    });
    return error;
  };

  const onFormChange = data => {
    const targetData = {
      ...data.formData,
      targetDeliveryDate: data.formData.targetDeliveryFrom,
      targetReturnDeliveryDate: data.formData.targetReturnDeliveryFrom,
      targetCheckedDate: data.formData.targetReconditioningTo,
    };
    if (error) {
      const newError = checkError(targetData);
      if (newError) {
        setError(newError);
      } else {
        setError('');
      }
    }
  };

  return (
    <Modal
      id={id}
      className='modal-lg'
      title={strings.adjustDates}
      confirmAction={onSaveClick}
      confirmDataTest='adjust-dates-button-save'
      loading={loading}
      onCloseClick={onCloseClick(true)}
    >
      <div className='adjust-dates-content-container'>
        {adjustDatesFormData && (
          <FormComponent
            ref={formRef}
            className='d-flex'
            rowStyle='adjust-dates-form-row'
            model={datesFormModel}
            value={adjustDatesFormData}
            onChange={onFormChange}
          />
        )}
        {!!error && <span className='text-danger error-block'>{error}</span>}
        {!enableSave && <div className='adjust-dates-disable-overlay' />}
        {conflictError && <span className='text-danger error-block'>{conflictError}</span>}
      </div>
      {!enableSave && <div className='help-text'>{strings.viewOnlyPermissions}</div>}
    </Modal>
  );
};

AdjustDates.defaultProps = {
  showScheduler: true,
};

export default AdjustDates;
