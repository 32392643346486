import * as actionTypes from './KitForSaleDetails.constants';

export const INITIAL_STATE_KIT_DETAILS = {
  kitDetails: {},
  kitDetailsAttachments: [],
  commentsData: {},
  damageLossReportData: {},
  currentTab: null,
  questions: [],
  transactionAttachments: [],
};

const KitForSaleDetailsReducer = (state = INITIAL_STATE_KIT_DETAILS, action) => {
  switch (action.type) {
    case actionTypes.GET_KIT_FOR_SALE_DETAILS:
      return {
        ...state,
        kitDetails: action.payload,
      };
    case actionTypes.FETCH_KIT_DETAILS_ATTACHMENTS:
      return {
        ...state,
        kitDetailsAttachments: action.payload,
      };
    case actionTypes.FETCH_KIT_DETAILS_DAMAGE_LOSS_REPORT:
      return {
        ...state,
        damageLossReportData: action.payload,
      };
    case actionTypes.SAVE_CURRENT_TAB:
      return {
        ...state,
        currentTab: action.payload,
      };
    case actionTypes.CLEAR_KIT_FOR_SALE_DETAILS_DATA:
      return {
        ...state,
        kitDetails: {},
      };
    case actionTypes.FETCH_KIT_DETAILS_COMMENTS: {
      return {
        ...state,
        commentsData: action.payload
      };
    }
    case actionTypes.FETCH_KIT_QUESTIONS: {
      return {
        ...state,
        questions: action.payload
      };
    }
    case actionTypes.FETCH_KIT_TRANSACTION_ATTACHMENTS: {
      return {
        ...state,
        transactionAttachments: action.payload,
      }
    }
    default:
      return state;
  }
};

export default KitForSaleDetailsReducer;
