import { batchActions } from 'redux-batched-actions';
import zeissInstance from "library/utilities/AxiosInstance";

import {
  fetchMasterDataService,
  deleteMasterDataService,
} from 'library/api/master-data.service';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { scrollToTop } from 'library/utilities/scrollActions';

import {
  FETCH_SYSTEM_CLASSES_BY_DUSINESS_UNIT,
  FETCH_BUSINESS_UNITS,
  FETCH_KITS_IMAGES,
  CLEAR_KITS_IMAGES,
} from './ManageKitPictures.constants';

export const getBusinessUnits = () => async dispatch => {
  try {
    dispatch(changeLoader(true));

    const { data } = await fetchMasterDataService(URLS.businessUnitList);

    batchActions([
      dispatch({
        type: FETCH_BUSINESS_UNITS,
        payload: data,
      }),
      dispatch(changeLoader(false)),
    ]);
  } catch (err) {
    batchActions([
      dispatch(changeLoader(false)),
      dispatch({
        type: FETCH_BUSINESS_UNITS,
        payload: [],
      }),
    ]);
  }
};

export const getSystemClassesByBU = buName => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.systemclassesBuName(buName));

    dispatch({
      type: FETCH_SYSTEM_CLASSES_BY_DUSINESS_UNIT,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_SYSTEM_CLASSES_BY_DUSINESS_UNIT,
      payload: {},
    });
  }
};

export const fetchKitsPictures = (buId, sysClassId, sysMainCompId) => async dispatch => {
  try {
    dispatch(changeLoader(true));

    const { data } = await fetchMasterDataService(URLS.genericPictures(buId, sysClassId, sysMainCompId));

    batchActions([
      dispatch({
        type: FETCH_KITS_IMAGES,
        payload: data,
      }),
      dispatch(changeLoader(false)),
    ]);
  } catch (err) {
    batchActions([
      dispatch({
        type: FETCH_KITS_IMAGES,
        payload: [],
      }),
      dispatch(changeLoader(false)),
    ]);
  }
};

export const uploadKitsPicture = (buId, sysClassId, sysMainCompId, file) => async dispatch => {
  try {
    dispatch(changeLoader(true));

    const formData = new FormData();
    formData.append('imgFile', file[0]);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
  
    await zeissInstance.post(URLS.genericPicture(buId, sysClassId, sysMainCompId), formData, config);

    batchActions([
      dispatch(fetchKitsPictures(buId, sysClassId, sysMainCompId)),
      dispatch(toggleActionMessage(true, 'success', 'imageUploadedSuccessfully')),
    ]);
    scrollToTop(500);
  } catch (err) {
    batchActions([
      dispatch(changeLoader(false)),
      dispatch(toggleActionMessage(true, 'error', 'errorUploadingImage')),
    ]);
    scrollToTop(500);
  }
};

export const clearKitPictures = () => dispatch => {
  dispatch({ type: CLEAR_KITS_IMAGES });
};

export const deleteKitsPicture = (buId, sysClassId, sysMainCompId, imgId) => async dispatch => {
  try {
    await deleteMasterDataService(URLS.deleteGenericPicture(imgId));

    batchActions([
      dispatch(fetchKitsPictures(buId, sysClassId, sysMainCompId)),
      dispatch(toggleActionMessage(true, 'success', 'imageWasDeletedSuccessfully')),
    ]);
    scrollToTop(500);
  } catch (err) {
    dispatch(toggleActionMessage(true, 'error', 'errorDeletingImage'));
    scrollToTop(500);
  }
}
