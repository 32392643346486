import { useEffect, useCallback, useState } from 'react';
import $ from 'jquery';

import { saveToStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';

export function useObservations({
  data,
  observationsList,
  fetchObservationsListData,
  addKitToObservationList,
  removeKitFromObservationList,
  reserveKit,
  cancelKitReservation,
}) {
  const [selectedKitId, setSelectedKitId] = useState(null);
  const [isReservedKit, setIsReservedKit] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [errorReservation, setErrorReservation] = useState('');

  useEffect(() => {
    saveToStorage(identifiers.filtersShop, data.filter);
    fetchObservationsListData(data);
  }, [data, fetchObservationsListData]);

  const openExportObservationsList = () => {
    $('#export-observetions-list').modal('show');
  };

  const handleClickOnObserve = useCallback(async kitId => {
    await addKitToObservationList(kitId);
    fetchObservationsListData(data);
  }, [data, addKitToObservationList, fetchObservationsListData]);

  const handleClickOnStopObserve = useCallback(async kitId => {
    await removeKitFromObservationList(kitId);
    fetchObservationsListData(data);
  }, [data, fetchObservationsListData, removeKitFromObservationList]);

  const handleClickOnReserveKit = useCallback(async kitId => {
    await reserveKit(kitId);
    fetchObservationsListData(data);
  }, [data, fetchObservationsListData, reserveKit]);

  const openCancelReservatioPopup = kitId => {
    setSelectedKitId(kitId);
    setErrorReservation('');
    $('#observation-list-cancel-reservation-popup').modal('show');
  };

  const closeCancelReservatioPopup = () => {
    setSelectedKitId(null);
    $('#observation-list-cancel-reservation-popup').modal('hide');
  };

  const handleClickOnCancelReservation = useCallback(async () => {
    setIsConfirmLoading(true);
    const { success } = await cancelKitReservation(selectedKitId);
    setIsConfirmLoading(false);
    if (success) {
      closeCancelReservatioPopup();
      fetchObservationsListData(data);
    } else {
      setErrorReservation('somethingWentWrongMessage');
    }
  }, [data, selectedKitId, cancelKitReservation, fetchObservationsListData]);

  const openOrderPopup = (kitId, isReserved) => {
    setSelectedKitId(kitId);
    setIsReservedKit(isReserved);
    $('#observation-order-popup').modal('show');
  };

  const updateObservationList = useCallback(() => {
    setSelectedKitId(null);
    fetchObservationsListData(data);
  }, [data, fetchObservationsListData]);

  const isShowObservationsList = observationsList && observationsList.content && observationsList.content.length > 0;

  return {
    openExportObservationsList,
    handleClickOnObserve,
    handleClickOnStopObserve,
    handleClickOnReserveKit,
    openCancelReservatioPopup,
    closeCancelReservatioPopup,
    handleClickOnCancelReservation,
    isShowObservationsList,
    isConfirmLoading,
    errorReservation,
    isReservedKit,
    selectedKitId,
    openOrderPopup,
    updateObservationList,
  };
}
