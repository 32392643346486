import React from 'react';

import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import Modal from 'library/common/commonComponents/Modal';
import strings from 'resources/locales/Translate';

import { useCreateOrEditSubscriptionPopup } from './CreateOrEditSubscriptionPopup.hook';

const CreateOrEditSubscriptionPopup = ({
  id,
  systemClasses,
  emailTypes,
  selectedSubscription,
  createSubscription,
  hasEditMode,
  setSelectedSubscription,
}) => {
  const {
    subscriptionForm,
    loading,
    error,
    onSaveClick,
    handleForm,
    onCloseClick,
    model,
  } = useCreateOrEditSubscriptionPopup({
    id,
    systemClasses,
    emailTypes,
    hasEditMode,
    selectedSubscription,
    createSubscription,
    setSelectedSubscription,
  });

  return (
    <Modal
      id={id}
      title={strings.subscribeToDUSD}
      confirmAction={onSaveClick}
      confirmDataTest='system-class-button-subscribe'
      confirmTitle={strings.subscribe}
      loading={loading}
      disableBackgroundClose
      onCloseClick={onCloseClick}
    >
      <p className='font-weight-bold mb-3'>{strings.pleaseSubscribeSystemClass}</p>
      <FormComponent
        model={model}
        formName='subscriptionForm'
        formValue={subscriptionForm.formData}
        onChange={handleForm}
      />
      {error && <div className='error-block'>{strings[error]}</div>}
    </Modal>
  );
}

export default CreateOrEditSubscriptionPopup;
