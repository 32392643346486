import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';

import { addItemToCart, removeItemFromCartForBulk} from 'modules/LoanList/LoanListActions';
import { fetchListOfConnectedTransaction } from 'modules/Transactions/TransactionDetails/TransactionDetailsActions';
import { fetchConnectTransactionData, cancelBulkResevations } from 'modules/Booking/BookingActions';
import DLATransactions from './DLATransactions.component';

const mapStateToProps = ({ languageReducer, masterDataReducer, bookingReducer, authReducer, loanList, transactionDetailsReducer }) => ({
  language: languageReducer.language,
  countries: masterDataReducer.countries,
  conditions: bookingReducer.kitConditions,
  user: authReducer.user,
  cartItems: loanList.cart,
  transactionPositions: bookingReducer.transactionPositions,
  transactionStatuses: bookingReducer.transactionStatuses,
  transactionsList: bookingReducer.transactionsList,
  bundleLoans: transactionDetailsReducer.bundleLoans,
});

export default withRouter(connect(mapStateToProps, {
  addItemToCart,
  toggleActionMessage,
  fetchListOfConnectedTransaction,
  fetchConnectTransactionData,
  cancelBulkResevations,
  removeItemFromCartForBulk,
})(DLATransactions));
