export const systemClassForCourseLoan = [
  {
    systemClassName: 'Axio Lab.A1 series',
    systemMainComponent: [
      {
        systemMainComponentId: 92,
        systemMainComponentName: 'Axio Lab.A1'
      }
    ]
  },
  {
    systemClassName: 'Primo Star series',
    systemMainComponent: [
      {
        systemMainComponentId: 97,
        systemMainComponentName: 'Primo Star Standard'
      },
    ]
  },
  {
    systemClassName: 'Stemi 3xx series',
    systemMainComponent: [
      {
        systemMainComponentId: 106,
        systemMainComponentName: 'Stemi 305'
      }
    ]
  }
];
