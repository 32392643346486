import { useState, useMemo, useCallback } from 'react';
import $ from 'jquery';

export function useQuestions({ questions, user, kitDetail }) {
  const [selectedAnswer, setSelectedAnswer] = useState({
    answerText: '',
    questionId: '',
    answerId: '',
  });
  const [isShowAllItems, setIsShowAllItems] = useState(false);

  const clearSelectedAnswer = () => {
    setSelectedAnswer({
      answerText: '',
      questionId: '',
      answerId: '',
    });
  };

  const showAllQuestions = useCallback(() => {
    setIsShowAllItems(true);
  }, []);

  const showingQuestions = useMemo(() => (!isShowAllItems ? questions.slice(0, 5) : questions), [isShowAllItems, questions]);
  const isShowMoreButton = useMemo(() => (questions && questions.length > 5 && !isShowAllItems), [questions, isShowAllItems]);

  const openQuestionPopup = useCallback(() => {
    $('#create-question-popup').modal('show');
  }, []);

  const checkPermission = useCallback(() => {
    const roles = ['Stock Controller', 'Shop Manager'];
    if (user && user.admin) return true;
    if (kitDetail.stockInformation && kitDetail.stockInformation.id) {
      return !!user.stockRoles.find(role => (role.stockId === kitDetail.stockInformation.id) && roles.includes(role.roleName));
    }
    return false;
  }, [kitDetail, user]);

  const isHavePermission = checkPermission();

  return {
    isShowAllItems,
    isShowMoreButton,
    showingQuestions,
    selectedAnswer,
    setSelectedAnswer,
    clearSelectedAnswer,
    showAllQuestions,
    openQuestionPopup,
    checkPermission,
    isHavePermission,
  };
};
