import { useState } from 'react';

export const useShopManagers = ({ addShopManagers, removeShopManagers }) => {
  const [value, setValue] = useState({
    selected: null,
    selectedFullValue: null,
    error: '',
  });

  const handleClickOnAddButton = () => {
    if (value.selected && value.selectedFullValue) {
      addShopManagers(value.selected, value.selectedFullValue);
      setValue({
        selected: null,
        selectedFullValue: null,
      });
    } else {
      setValue({
        selected: null,
        selectedFullValue: null,
        error: 'pleaseSelectUser',
      });
    }
  };

  const handleClickOnDeleteButton = accountId => {
    removeShopManagers(accountId);
  };

  const handleValueChange = (value, field, fullValue) => {
    setValue({
      selected: value,
      selectedFullValue: fullValue,
      error: '',
    });
  };

  return {
    value,
    handleClickOnAddButton,
    handleClickOnDeleteButton,
    handleValueChange,
  };
};
