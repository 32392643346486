import React from "react";
import strings from "resources/locales/Translate";

const MessageDataExpandable = ({pickupRequestActive, row}) => {
    const pickupRequestText = row?.text?.replaceAll('**', '<b>').replaceAll('*', '</b>').replaceAll('\n', '<br>');
    return(
        <td colSpan='5' className='expanded-row'>
            <div className="message-comment-data">{strings.comment}</div>
            <div className="mt-2" dangerouslySetInnerHTML={{ __html: pickupRequestText }}/>
        </td>
    )
}

export default MessageDataExpandable;