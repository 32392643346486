import React from 'react';

import Icon from 'library/common/commonComponents/Icon/Icon';
import DotLoader from 'library/common/commonComponents/DotLoader';

import './IconButton.styles.scss';

const IconButton = ({
  value,
  disabled,
  onClick,
  styles,
  iconName = 'edit',
  width = 24,
  height = 24,
  viewBox = '0 0 40 40',
  fill = '#0088d0',
  loading,
  bgFill,
  buttonStyles = '',
}) => {
  if (loading) {
    return (
      <DotLoader bgFill={bgFill} styles={buttonStyles} />
    );
  }

  return (
    <button
      className={`icon-button p-0 ${buttonStyles}`}
      disabled={disabled}
      onClick={onClick}
    >
      <Icon
        className={styles}
        name={iconName}
        width={width}
        height={height}
        viewBox={viewBox}
        fill={fill}
      />
      {value && <span className='ml-2'>{value}</span>}
    </button>
  )
};

export default IconButton;
