import zeissInstance from 'library/utilities/AxiosInstance';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';

export const logError = logData => {
  return zeissInstance
    .post(URLS.logError, { logData })
    .then(res => ({ success: true }))
    .catch(() => ({ success: false }));
};

export const logNetworkError = error => {
  const errorToSend = (error.response && error.response.data) || error;
  logError(JSON.stringify(errorToSend));
};
