import { useEffect, useState, useCallback } from 'react';
import $ from 'jquery';

import { validateForm } from 'library/utilities/ValidateForm.util';

import { subscriptiontModel } from './CreateOrEditSubscriptionPopup.constants';

export const useCreateOrEditSubscriptionPopup = ({
  id,
  systemClasses,
  emailTypes,
  hasEditMode,
  selectedSubscription,
  createSubscription,
  setSelectedSubscription,
}) => {
  const [loading, setLoading] = useState(false);
  const [subscriptionForm, setSubscriptionForm] = useState({ formData: {
    systemClassSubscriptionsEmailTypeId: 1,
  }, isFormValid: false });
  const [error, setError] = useState('');

  useEffect(() => {
    if (hasEditMode && selectedSubscription) {
      setSubscriptionForm({
        formData: {
          systemClassId: selectedSubscription.systemClassId,
          systemMainComponentId: [selectedSubscription.systemMianComponentId],
          systemMainComponentIdFullValue: [{
            systemMainComponentId: selectedSubscription.systemMianComponentId,
            systemMainComponentName: selectedSubscription.systemMaincomponentName,
          }],
          systemClassSubscriptionsEmailTypeId: selectedSubscription.systemClassSubscriptionsEmailTypeId,
        },
      });
    }
  }, [hasEditMode, selectedSubscription]);

  const getModel = useCallback(() => {
    const newModel = [ ...subscriptiontModel ];
    newModel[0].options = systemClasses;

    if (subscriptionForm.formData && subscriptionForm.formData.systemClassId) {
      const selectedSystemClass = systemClasses.find(item => subscriptionForm.formData.systemClassId === item.systemClassId) || {};
      newModel[1].options = selectedSystemClass.systemMainComponent;
    } else {
      newModel[1].options = [];
    }

    newModel[2].options = emailTypes;

    return newModel;
  }, [systemClasses, emailTypes, subscriptionForm.formData]);

  const model = getModel();

  const onCloseClick = () => {
    const popup = $(`#${id}`);
    if (popup && popup.modal) {
      popup.modal('hide');
    }

    setError('');
    setSubscriptionForm({ formData: { systemClassSubscriptionsEmailTypeId: 1, }, isFormValid: false });

    if (hasEditMode) {
      setSelectedSubscription(null);
    }
  };

  const onSaveClick = async () => {
    setError('');
    const validForm = validateForm({
      form: subscriptionForm,
      model: subscriptiontModel,
    });

    if (!validForm.isFormValid) {
      return setSubscriptionForm(validForm);
    } 

    setLoading(true);
    const { systemClassId, systemMainComponentId, systemClassSubscriptionsEmailTypeId } = validForm.formData;
    const dataToSend = {
      systemClassId,
      systemMainComponentId,
      systemClassSubscriptionsEmailTypeId,
    };
    const { success, error } = await createSubscription(dataToSend, hasEditMode);
    setLoading(false);

    if (!success) {
      setError(error);
    } else {
      onCloseClick();
    }
  };

  const handleForm = ({ values, field }) => {
    if (field === 'systemClassId') {
      setSubscriptionForm({
        ...subscriptionForm,
        formData: {
          ...subscriptionForm.formData,
          ...values,
          systemMainComponentId: null,
          systemMainComponentIdFullValue: null,
        },
      });
    } else {
      setSubscriptionForm({
        ...subscriptionForm,
        formData: {
          ...subscriptionForm.formData,
          ...values,
        },
      });
    }
  };

  return {
    subscriptionForm,
    loading,
    error,
    onSaveClick,
    handleForm,
    onCloseClick,
    model,
  };
};
