import React from 'react';
import "./section.styles.scss"

function Section({ sectionTitle = '', children, containerClass }) {
  return (
    <>
      <h3 className='section-title-heading'>{sectionTitle}</h3>
      <div className={`p-2 section-border-kit ${containerClass}`}>{children}</div>
    </>
  );
}

export default Section;
