import Validators from "library/utilities/Validators";

export const dismatleModel = [
    {
        label: 'dismantlingBy',
        value: '',
        type: 'dropdown',
        placeholder: 'pleaseSelect',
        field: 'dismantleBy',
        validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
        required: true,
        styleClass: 'col-12',
        options: [],
        hasSection: false,
        multiSelect: false,
        filter: true,
        idKey: 'userId',
        displayValue: [{ key: 'firstName', separator: ' ,' },{ key: 'lastName', separator: ' ' }],
      },

      {
        label: 'maintenanceCompleteness',
        value: '',
        type: 'radio-button',
        placeholder: '',
        field: 'dismantleCompleteness',
        validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
        required: true,
        styleClass: 'col-sm-12',
        options: [
          {
            id: 'yes',
            value: 'yes',
          },
          {
            id: 'no',
            value: 'no',
          },
        ],
      },

      {
        label: 'commentText',
        value: '',
        type: 'text',
        placeholder: '',
        field: 'dismantleCompletenessComment',
        validators: [],
        required: false,
        styleClass: 'col-sm-12',
        isDisplayed: true
      },

      {

        styleClass: 'col-sm-12',
        options: [
          {
            id: 'yes',
            value: 'yes',
          },
          {
            id: 'no',
            value: 'no',
          },
        ],
      },

      {
        label: 'commentText',
        value: '',
        type: 'text',
        placeholder: '',
        field: 'dismantleLaserSafetyComment',
        validators: [],
        required: false,
        styleClass: 'col-sm-12',
        isDisplayed: true
      },
      {

        styleClass: 'col-sm-12',
        options: [
          {
            id: 'yes',
            value: 'yes',
          },
          {
            id: 'no',
            value: 'no',
          },
        ],
      },

      {
        label: 'commentText',
        value: '',
        type: 'text',
        placeholder: '',
        field: 'dismantleElectronicSecurityComment',
        validators: [],
        required: false,
        styleClass: 'col-sm-12',
        isDisplayed: true
      },
      {
        label: 'filesFromPCDeleted',
        value: '',
        type: 'radio-button',
        placeholder: '',
        field: 'dismantleFileDeleted',
        validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
        required: true,
        styleClass: 'col-sm-12',
        options: [
          {
            id: 'yes',
            value: 'yes',
          },
          {
            id: 'no',
            value: 'no',
          },
        ],
      },

      {
        label: 'commentText',
        value: '',
        type: 'text',
        placeholder: '',
        field: 'dismantleFileDeletedComment',
        validators: [],
        required: false,
        styleClass: 'col-sm-12',
        isDisplayed: true
      },
]
