import React from 'react';
import SystemClassificationBlock from '../SystemClassificationBlock';
import { useSystemClassificationTab } from './SystemClassificationTab.hook';
import './SystemClassificationTab.styles.scss'

const SystemClassificationTab = props => {
  const { state,isEditMode, isLoading, copySystemClassificationList, handleInput, onEdit, selectedSystemClassification , copySystemMainComponentList, handleFilterChange, saveOrDeleteSystemMainComponent, onSave, checkedValues } = useSystemClassificationTab(props);
  return (
    <div>
      <SystemClassificationBlock
        handleInput={handleInput}
        copySystemClassificationList={copySystemClassificationList}
        isEditMode={isEditMode}
        onEdit={onEdit}
        selectedSystemClassification={selectedSystemClassification}
        copySystemMainComponentList={copySystemMainComponentList}
        handleFilterChange={handleFilterChange}
        filter={state.filter}
        onSave={onSave}
        checkedValues={checkedValues}
      />
    </div>
  );
};

export default SystemClassificationTab;
