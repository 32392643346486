import { logNetworkError } from 'library/utilities/logError';
import { useState, useEffect, useCallback } from 'react';

export const useSystemClassificationTab = props => {
  const {
    getSystemClassificationList,
    language,
    systemClassificationList,
    getSystemMainComponentList,
    systemMainComponentList,
    saveOrDeleteSystemMainComponent
  } = props;
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSystemClassification, setSelectedSystemClassification] = useState("");
  const [copySystemClassificationList, setCopySystemClassificationList] = useState([]);
  const [listOfChangedValues, setListOfChangedValues] = useState([]);
  const [copySystemMainComponentList, setCopySystemMainComponentList] = useState([]);
  const [checkedValues, setCheckedValues]= useState([]);
  const [state, setState] = useState({
    filter: {
    }})

  useEffect(() => {
    getSystemClassificationList();
  }, [getSystemClassificationList, language]);

  useEffect(() => {
    getSystemMainComponentList();
  }, [getSystemMainComponentList, language]);

  useEffect(() => {
    if (systemClassificationList && systemClassificationList.length > 0) {
      setCopySystemClassificationList(systemClassificationList);
    }
  }, [systemClassificationList]);

  const onEdit = (field, isEditMode, items ) => {
    if(isEditMode === false){
      systemClassificationList.map((item) => {item.systemClassificationName === field ?   
        setIsEditMode(prevState => !prevState)
         : setIsEditMode(prevState => prevState)
      })
      setSelectedSystemClassification(field);
      setListOfChangedValues([]);
      setCopySystemClassificationList(systemClassificationList);
      setCopySystemMainComponentList({...systemMainComponentList});
      
      let latestData=[]
      let latestCheckedVALUES=[]

      systemMainComponentList && systemMainComponentList["systemClasses"].map((series) => series["systemMainComponent"].map((each) => items && items.length > 0 && items.map(item => each.systemMainComponentName === item.systemMainComponentName && latestData.push(each)) ))
      latestData && latestData.length && latestData.map((each) => latestCheckedVALUES.push(each.systemMainComponentId))
      setState(prevState => {
        const newData = { ...prevState };

          newData.filter[`${field}`] = latestCheckedVALUES;
          newData.filter[`${field}FullValue`] = latestData;
        
        return { ...newData };
      })


    }
    else if(isEditMode === true){
      setIsEditMode(prevState => !prevState)
      setListOfChangedValues([]);
      setCopySystemClassificationList(systemClassificationList);
      setCopySystemMainComponentList({...systemMainComponentList});
      setState(prevState => {
        const newData = { ...prevState };
        newData.filter["classification"] = [] ;
        newData.filter["classificationFullValue"] = [] ;
        return { ...newData };
      });
    }
  };


  const onSave = useCallback(async (fullValue, ClassificationId, checked, systemClassificationName) => {
    setIsLoading(true);
    let datToSend= {
      "systemClassificationId":ClassificationId,
      "systemMainComponentIds":checked
    }
    try {
      const { success } = await saveOrDeleteSystemMainComponent(datToSend);
      setIsLoading(false);

      if (success) {
        setIsEditMode(false);
      }
    } catch (err) {
      logNetworkError(err);
    }

    
  }, []);


  const handleFilterChange = useCallback((value, field, fullValue) => {
    setCheckedValues(value);
    const idValue = Array.isArray(value) ? [...value] : value;

    setState(prevState => {
      const newData = { ...prevState };
        newData.filter[`${field.item}`] = idValue;
        newData.filter[`${field.item}FullValue`] = fullValue;

     
      return { ...newData };
    });

  }, []);

  

  return {
    isEditMode,
    isLoading,
    copySystemClassificationList,
    onEdit,
    selectedSystemClassification,
    copySystemMainComponentList,
    handleFilterChange,
    state,
    onSave,
    checkedValues
  };
}
