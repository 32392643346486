import store from 'main/store/configureStore';

import { logNetworkError } from 'library/utilities/logError';
import {SET_REQUEST_STOCK_PERMISSION_DROPDOWN} from "library/common/commonConstants/storeConstants";
import {changeLoader, toggleActionMessage} from "library/common/commonActions/AppActionsActions";

import {requestStockAccessService} from "./request-stock-permission-services";
import { getIQSSscDeRoles, getllStockRoles, getRMSZMCCStockRoles } from 'library/common/commonConstants/roles';


export const setDropdownValues = (id = null) => dispatch => {
  const masterData = store.getState().masterDataReducer;
  const {stocksList} = store.getState().stocksListReducer;
  const requestStockReducer = store.getState().requestStockPermissionReducer;
  const stock = stocksList && stocksList.content.find(item => item.id === id);
  const isLLStock = stock && stock.businessUnit.designation === 'RMS_L_L';
  //Below are Dropdown issue changes
  requestStockReducer.requestStockForm[0].options  = (stocksList&&stocksList.content) ? stocksList.content : [];
  requestStockReducer.requestStockForm[1].options = isLLStock ? getllStockRoles() : masterData.rolesList.filter(role => role.role.toLowerCase() !== 'admin');
  dispatch({
    type: SET_REQUEST_STOCK_PERMISSION_DROPDOWN,
    payload: requestStockReducer.requestStockForm
  });
};

export const isLabsLocationStock = (id) => dispatch => {
  const masterData = store.getState().masterDataReducer;
  const { stocksList } = store.getState().stocksListReducer;
  const stock = stocksList && stocksList.content.find(item => item.id === id);
  const isLLStock = stock && stock.businessUnit.designation === 'RMS_L_L';
  const isRMsZMCCStock = stock && stock.businessUnit.designation === 'RMS_ZMCC';
  const isIqsSscDeLocation = stock?.businessUnit?.designation === 'IQS_SSC_DE';
  const requestStockReducer = store.getState().requestStockPermissionReducer;
  requestStockReducer.requestStockForm[1].options = isRMsZMCCStock ? getRMSZMCCStockRoles() : isLLStock ? getllStockRoles() : isIqsSscDeLocation ? getIQSSscDeRoles() : masterData.rolesList.filter(role => role.role.toLowerCase() !== 'admin');
  dispatch({
    type: SET_REQUEST_STOCK_PERMISSION_DROPDOWN,
    payload: requestStockReducer.requestStockForm
  });
}

export const requestAccessPermission = (value) => dispatch => {
  const data = [
    {
      stockId: value.formData.stockId,
      roleId: value.formData.roleId,
    }
  ];

  dispatch(changeLoader(true));

  requestStockAccessService(data)
    .then(res => {
      if (res.status === 200) {
        dispatch(toggleActionMessage(true, 'success', 'requestStockPermissionSuccess'));
      }
      dispatch(changeLoader(false));
    })
    .catch(err => {
      logNetworkError(err);
      dispatch(changeLoader(false));
      if (err && err.response.status === 404) {
        dispatch(toggleActionMessage(true, 'error', 'requestAlreadyExists'));
      } else {
        dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
      }
    })
};
