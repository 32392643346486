import React from 'react';

import strings from 'resources/locales/Translate';

import { InputField } from '../InputField/InputField';

export default class TextArea extends InputField {
  render() {
    const {
      className,
      dataTest,
      label,
      isRequired,
      extraProps,
      placeholder,
      rows,
      showExtraInfo,
      infoMessage,
    } = this.props;
    const { error, errorMessage, value, isInfoOpen } = this.state;
    return (
      <div className={className ? `input-field ${className ? className : ''}` : 'input-field'} data-test={dataTest}>
        <div className='label-container d-flex justify-content-between align-items-center'>
          <div className='d-flex position-relative'>
            <p>{label} {isRequired && label && <span className='mandatory-asterik'>*</span>}</p>
            {showExtraInfo && (
              <p className='input-info cursor-pointer' onClick={this.toggleInfoMessage}>
                i
              </p>
            )}
            {isInfoOpen && (
              <div className='info-message position-absolute'>{strings[infoMessage]}</div>
            )}
          </div>
        </div>

        <textarea
          className={'form-control rounded-0 position-relative ' + (error && 'input-error')}
          data-test='textarea-common-component'
          placeholder={placeholder}
          {...extraProps}
          value={value}
          rows={rows}
          onChange={this.handleOnChange}
          onBlur={this.onBlur}
        />
        {error && <span className='text-danger'>{strings[errorMessage]}</span>}
      </div>
    );
  }
}

TextArea.defaultProps = {
  rows: 5,
};
