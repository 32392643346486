import { useState, useEffect, useCallback } from 'react';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import * as actionTypes from 'library/common/commonConstants/storeConstants';
import {  monthsList, refurbishmentFeesModel } from './RefurbishmenFess.constants';
import moment from 'moment';
import { validateForm } from 'library/utilities/ValidateForm.util';
import strings from 'resources/locales/Translate';

export const useRefurbishmentFeesHook = props => {
  const {
    countries,
    fetchMasterData,
    exportRefurbishmentFess,
    language,
    refurbushmentFeesReducer,
    fetchRefurbishmentStocks,
  } = props;
  const [model, setModel] = useState(refurbishmentFeesModel);
  const [refurbishmentFeesForm, setRefurbishmentFeesForm] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const getYearsOptions = useCallback(() => {
    const yearsArr = [];
    const currentDate = moment();
    const startYear = moment(currentDate).subtract(12, 'years');

    for (let i = 0; i < 13; i++) {
      const start = startYear.format('YY');
      const startFullYear = startYear.format('YYYY');
      const end = startYear.add(1, 'year').format('YY');
      const endFullYear = startYear.format('YYYY');

      yearsArr.push({
        id: i + 1,
        value: `20${start}`,
      });
    }

    return yearsArr;
  }, []);

  const yearsOptions = getYearsOptions();

  const loanTypelist = [
    { id: 'kit loan', value: strings.kitLoan },
    { id: 'single loan', value: strings.singleLoan },
  ];
  const monthsList = [
    { id: '01', value: strings.january },
    { id: '02', value: strings.february },
    { id: '03', value: strings.march },
    { id: '04', value: strings.april },
    { id: '05', value: strings.may },
    { id: '06', value: strings.june },
    { id: '07', value: strings.july },
    { id: '08', value: strings.august },
    { id: '09', value: strings.september },
    { id: '10', value: strings.october },
    { id: '11', value: strings.november },
    { id: '12', value: strings.december },
  ];

  useEffect(() => {
    if (!(refurbushmentFeesReducer && refurbushmentFeesReducer.length)) {
      fetchRefurbishmentStocks();
    }
    const currentMonth = new Date();
    let tempMonth = 3
    let tempYear = 2022
    let newMonthsList =monthsList.filter((item,index)=>(index >= tempMonth))
    const newModel = [...model];
    if (refurbushmentFeesReducer && refurbushmentFeesReducer.length) {
      model[2].options = refurbushmentFeesReducer;
    }
    model[0].options = yearsOptions;
    model[1].options = currentMonth.getFullYear() > tempYear ?monthsList:newMonthsList;
    model[3].options = loanTypelist ;
    setModel(newModel);
  }, [refurbushmentFeesReducer, fetchRefurbishmentStocks,language]);

  const handleForm = ({ values, field, formName }) => {
    setRefurbishmentFeesForm({
      ...refurbishmentFeesForm,
      formData: {
        ...refurbishmentFeesForm.formData,
        ...values,
      },
    });
  };

  const onExport = async refurbishmentFeesData => {
    const newFormData = validateForm({
      form: refurbishmentFeesForm,
      model: model,
    });
    if (!newFormData.isFormValid) {
      return setRefurbishmentFeesForm(newFormData);
    }
    let dataToSend = {};
    dataToSend.stockIds = refurbishmentFeesData.formData.stockIds;
    dataToSend.type = refurbishmentFeesData.formData.type;
    dataToSend.year = refurbishmentFeesData.formData.yearFullValue.value;
    dataToSend.month = refurbishmentFeesData.formData.month;
    dataToSend.monthName = refurbishmentFeesData.formData.monthFullValue.value;
    setIsLoading(true);
    await exportRefurbishmentFess(dataToSend);
    setIsLoading(false);
    setRefurbishmentFeesForm({});
  };
  return {
    model,
    handleForm,
    refurbishmentFeesForm,
    onExport,
    isLoading,
  };
};
