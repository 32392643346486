import zeissInstance from "library/utilities/AxiosInstance";
import {URLS} from "library/common/commonConstants/ApiUrlConstants";

export async function fetchDeliveryAddressesService(userId) {
  return await zeissInstance.get(URLS.userAddress.replace('{id}', userId));
}

export async function saveDeliveryAddressService(url, method, data) {
  return await zeissInstance[method](url, data)
}

export async function deleteUserAddressService(id) {
  return await zeissInstance.delete(URLS.deleteUserAddress + id);
}
