import React from 'react';
import $ from 'jquery';

import Modal from 'library/common/commonComponents/Modal';
import strings from 'resources/locales/Translate';

const ConfirmDeletionPopup = ({
  id,
  error,
  isLoading,
  onConfirm,
  onClose,
  title,
  subtitle,
  children,
}) => {
  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  const handleClose = () => {
    if ($(`#${id}`) && $(`#${id}`).modal) {
      $(`#${id}`).modal('hide');
    }
    onClose && onClose();
  };

  return (
    <Modal
      id={id}
      title={title}
      confirmAction={handleConfirm}
      confirmTitle={strings.confirm}
      loading={isLoading}
      disableBackgroundClose
      onCloseClick={handleClose}
    >
      {children}
      <h6 className='mb-5'>{subtitle}</h6>
      {error && <div className='error-block'>{strings[error]}</div>}
    </Modal>
  );
};

export default ConfirmDeletionPopup;
