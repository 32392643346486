import React, { useState } from 'react';
import { connect } from 'react-redux';

import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import Button from 'library/common/commonComponents/Button/Button';
import { validateForm } from 'library/utilities/ValidateForm.util';
import { ratingModelDropDowns } from './ratingModel';

const Rating = ({ transactionDetails, saveTransactionRatings }) => {
  const isTransactionRating = transactionDetails && transactionDetails.transactionRating && transactionDetails.transactionRating.length;
  const getValue = value => value ? 'yes' : 'no';
  const [ratingForm, updateForm] = useState({
    formData: {
      onTimeDelivery: isTransactionRating ? getValue(transactionDetails.transactionRating[0].rating) : '',
      orderCompleteness: isTransactionRating ? getValue(transactionDetails.transactionRating[1].rating) : '',
      equipmentFunctionality: isTransactionRating ? getValue(transactionDetails.transactionRating[2].rating) : '',
      comment: isTransactionRating ? transactionDetails.transactionRating[3] && transactionDetails.transactionRating[3].comment : '',
    }
  });

  const saveRating = async () => {
    let sendData = {};
    const rating = [];
    const newRatingForm = validateForm({ form: ratingForm, formName: 'ratingForm', model: ratingModelDropDowns });

    if (!newRatingForm.isFormValid) {
      updateForm(newRatingForm);
      return;
    }

    for (let key in ratingForm.formData) {
      if (!key.includes('Error')) {
        if (key === 'onTimeDelivery') rating.push({ratingTypeId: 1, rating: ratingForm.formData[key] === 'yes' ? true : false});
        else if (key === 'orderCompleteness') rating.push({ratingTypeId: 2, rating: ratingForm.formData[key] === 'yes' ? true : false});
        else if (key === 'equipmentFunctionality') rating.push({ratingTypeId: 3, rating: ratingForm.formData[key] === 'yes' ? true : false});
      }
    }

    sendData = {
      rating,
      comment: ratingForm.formData.comment,
    };

    await saveTransactionRatings(transactionDetails.transactionsId, sendData);
  };

  const handleForm = ({ values }) => {
    updateForm(
      (prevState) => ({
        formData: {
          ...((prevState.formData) || {}),
          ...values,
        },
      }));
  };

  return (
    <>
      {transactionDetails && transactionDetails.c2cLoan && (
        <div className={'blue-bg-message'}>
          <div className='container-fluid'>{strings.c2cLoanProcessText(transactionDetails.c2cId)}</div>
        </div>
      )}
        <div className='container-fluid'>
          <div className='col-lg-12 my-3'>
              <div>
                <FormComponent
                  model={ratingModelDropDowns}
                  formValue={ratingForm.formData}
                  formName='ratingForm'
                  onChange={handleForm}
                  disableForm={!!(transactionDetails && transactionDetails.transactionRating && transactionDetails.transactionRating.length)}
                />


                {!(transactionDetails && transactionDetails.transactionRating && transactionDetails.transactionRating.length) && (
                  <Button
                    dataTest='save-rating-button'
                    styleClass='w-auto mt-3'
                    bgFill
                    // iconName='save'
                    onClick={saveRating}
                    value={strings.saveRating}
                  />
                )}
              </div>
          </div>
        </div>
    </>
  );
};

const mapStateToProps = ({ languageReducer }) => ({
  language: languageReducer,
});

export default connect(mapStateToProps)(Rating);
