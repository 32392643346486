import React, { useState, useCallback } from 'react';
import $ from 'jquery';
import strings from 'resources/locales/Translate';
import cloneDeep from 'lodash/cloneDeep';
import { regex } from 'library/common/commonConstants/RegexConstants';

export const useAdditionalSingleLoanPopup = ({ id, createSingleLoanUsingExistingKitLoan, transactionDetails, transactionsType, detailPage }) => {
  const [showForm, setShowForm] = useState(true);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [transactionMaterials, setTransactionMaterialNumber] = useState([]);
  const [newTransactionMaterialValue, setNewTransactionMaterialValue] = useState({
    materialNumber: '',
    description: '',
    transactionMaterialNumberId: null,
    quantity: 1,
  });

  const onShowFileds = () => {
    setShowForm(true);
  };

  const addTransactionMaterialNumber = useCallback(
    (materialNumber, description) => {
      if (newTransactionMaterialValue.materialNumber === '') {
        setError(strings.materialnumberWarning);
        return;
      }
      if (regex.materailNumber.test(newTransactionMaterialValue.materialNumber) === false) {
        setError(strings.materialNumberFormatError);
        return;
      }
      if (newTransactionMaterialValue.quantity === '') {
        setError(strings.qunatityFieldIsRequired);
        return;
      }
      if (regex.number.test(newTransactionMaterialValue.quantity) === false) {
        setError(strings.invalidNumberError);
        return;
      }
      let tempvalues = [...transactionMaterials, newTransactionMaterialValue];

      setTransactionMaterialNumber(tempvalues);
      setError('');
      setShowForm(false);
      setNewTransactionMaterialValue({ materialNumber: '', description: '', quantity: 1 });
    },
    [transactionMaterials, newTransactionMaterialValue],
  );
  const handleOnChangeExstingValue = useCallback(
    (e, index, type) => {
      const newListOfChangedValues = cloneDeep(transactionMaterials);
      let tempvalues = [...newListOfChangedValues];
      if (type === 'materialNumber') {
        tempvalues[index].materialNumber = e;
      } else if (type === 'decription') {
        tempvalues[index].description = e;
      } else if (type === 'quantity') {
        tempvalues[index].quantity = e;
      }

      setTransactionMaterialNumber(tempvalues);
      setError('');
    },

    [transactionMaterials],
  );

  const handleOnChange = useCallback(
    (e, value) => {
      if (value === 'materialNumber') {
        setNewTransactionMaterialValue({
          ...newTransactionMaterialValue,
          materialNumber: e,
        });
      } else if (value === 'quantity') {
        setNewTransactionMaterialValue({
          ...newTransactionMaterialValue,
          quantity: e,
        });
      } else if (value === 'decription') {
        setNewTransactionMaterialValue({
          ...newTransactionMaterialValue,
          description: e,
        });
      }

      setError('');
    },
    [newTransactionMaterialValue],
  );

  const deleteTransactionMaterialNumber = index => {
    const tempValues = transactionMaterials.filter((item, i) => index !== i);
    setTransactionMaterialNumber(tempValues);
  };

  const saveMaterialNumber = useCallback(
    async transactionMaterial => {
      if (transactionMaterials && transactionMaterials.length <= 0) {
        setError(strings.addMaterialNumberInfo);
        setShowForm(true);
        return;
      }
      if (showForm) {
        setError(strings.addMaterialNumberInfo);
        return;
      }
      setIsLoading(true);
      let transactionMaterialNumbers = transactionMaterial.map(item =>
        item.materialNumber != '' && regex.materailNumber.test(item.materialNumber) !== false
          ? item.quantity != '' && regex.number.test(item.quantity)
            ? {
                transactionMaterialNumberId: item.transactionMaterialNumberId ? item.transactionMaterialNumberId : null,
                materialNumber: item.materialNumber,
                description: item.description,
                quantity: item.quantity ? item.quantity : null,
              }
            : item.quantity == ''
            ? strings.qunatityFieldIsRequired
            : strings.invalidNumberError
          : strings.materialnumberWarning,
      );
      if (transactionMaterialNumbers.includes(strings.materialnumberWarning)) {
        setError(`${strings.materialnumberWarning} and ${strings.materialNumberFormatError}`);
        setIsLoading(false);
        return;
      } else if (transactionMaterialNumbers.includes(strings.invalidNumberError)) {
        setError(`${strings.invalidNumberError}`);
        setIsLoading(false);
        return;
      } else if (transactionMaterialNumbers.includes(strings.qunatityFieldIsRequired)) {
        setError(strings.qunatityFieldIsRequired);
        setIsLoading(false);
        return;
      }
      const { success, error } = await createSingleLoanUsingExistingKitLoan(
        transactionDetails.transactionsId,
        transactionMaterialNumbers,
        transactionsType,
        detailPage,
      );
      setIsLoading(false);
      if (!success) {
        if (error && error.response && error.response.data && error.response.data.status === 422){
          setError(error.response.data.message);
        }
        else {
          setError(strings.somethingWentWrongMessage);
        }
      } else {
        onCloseClick();
        setTransactionMaterialNumber(transactionMaterials);
      }
    },
    [transactionMaterials, showForm],
  );

  const onCloseClick = useCallback(() => {
    setNewTransactionMaterialValue({ materialNumber: '', description: '', quantity: 1 });
    setTransactionMaterialNumber([]);
    closeMaterialNumberPopup();
    setShowForm(true);
    setError('');
  }, []);

  const closeMaterialNumberPopup = useCallback(() => {
    $(`#${id}`).modal('hide');
  }, []);

  return {
    deleteTransactionMaterialNumber,
    handleOnChange,
    newTransactionMaterialValue,
    addTransactionMaterialNumber,
    transactionMaterials,
    handleOnChangeExstingValue,
    saveMaterialNumber,
    onCloseClick,
    isLoading,
    error,
    showForm,
    onShowFileds,
  };
};
