import { connect } from 'react-redux';

import { saveDeductPoint } from '../../LLManagement.actions';
import DeductPointsPopup from './DeductPointsPopup.component';

const mapStateToProps = ({ authReducer, languageReducer, llManagementReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
  userList: llManagementReducer.userList,
});

export default connect(mapStateToProps, { saveDeductPoint })(DeductPointsPopup);
