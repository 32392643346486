import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchMasterData, fetchMasterDataPost } from 'library/common/commonActions/MasterDataActions';
import {fetchCartDataFromLocalStorage} from "modules/LoanList/LoanListActions";
import { cancelStockCreation } from 'modules/Stock/CreateStock/CreateStock.actions';

import { fetchStocksListData, deleteStock } from './StocksListActions';
import StocksList from './StocksList';
import { fetchMasterDataOnLanguageChange } from 'modules/Booking/BookingActions';

const mapStateToProps = ({ authReducer, languageReducer, appActionsReducer, stocksListReducer, masterDataReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
  appActions: appActionsReducer,
  countries: stocksListReducer.countriesWithActiveStocks,
  businessUnit: masterDataReducer.businessUnit,
  stocksList: stocksListReducer.stocksList,
});

export default withRouter(connect(mapStateToProps, {
  fetchMasterData,
  fetchStocksListData,
  fetchMasterDataPost,
  deleteStock,
  fetchCartDataFromLocalStorage,
  fetchMasterDataOnLanguageChange,
  cancelStockCreation,
})(StocksList));
