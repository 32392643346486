import { connect } from 'react-redux';

import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { cancelStockCreation, getAllKitsListByWarehouse, getPendingKitsListByWarehouseWhereSlotIsNotAvailable } from 'modules/Stock/CreateStock/CreateStock.actions';

import { createStockWorkflow, fetchWorkflowData, fetchCurrency ,distributionChannelList,billingsList,orderTypesList, getSystemAvailabiltySoltsList, deleteAvailabiltySlot } from './Workflows.actions';
import Workflows from './Workflows.component';
import { getNewRoles } from '../Entitlements/Entitlements.actions';
import { fetchReasonForLoan, getLoanReaonsBasedOnStock } from './Workflows.actions';

const mapStateToProps = ({ createStockReducer, languageReducer }) => ({
  canEditStock: createStockReducer.canEditStock,
  stockId: createStockReducer.stockDetails.id,
  businessUnitFullValue: createStockReducer.stockDetails.businessUnitFullValue,
  language: languageReducer,
  crmAccountId: createStockReducer.crmAccountId,
  availabilitySlotList: createStockReducer.availabilitySlotList,
  kitsListByWarehouse: createStockReducer.kitsListByWarehouse,
})

export default connect(mapStateToProps, {
  toggleActionMessage,
  cancelStockCreation,
  createStockWorkflow,
  fetchWorkflowData,
  fetchCurrency,
  distributionChannelList,
  billingsList,
  orderTypesList,
  getNewRoles,
  getSystemAvailabiltySoltsList,
  getAllKitsListByWarehouse,
  deleteAvailabiltySlot,
  getPendingKitsListByWarehouseWhereSlotIsNotAvailable,
  fetchReasonForLoan,
  getLoanReaonsBasedOnStock
})(Workflows);
