import Axios from 'axios/index';
import moment from 'moment';

import { identifiers } from '../common/commonConstants/IdentifiersConstants';
import { checkAccessTokenExpiry, checkSessionExpiry } from './AuthHandler';
import { errorHandler } from './ErrorHandler';
import { fetchFromStorage } from './storage';

const zeissInstance = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

zeissInstance.interceptors.request.use(function (config) {

  const user = fetchFromStorage(identifiers.user);
  if(user){
    const sessionExpiryDate = fetchFromStorage(identifiers.login_session_expiry);

    let currentTime = moment().valueOf();
    if(sessionExpiryDate) {
      let stillUtc = moment.utc(sessionExpiryDate).toDate();
      let local = moment(stillUtc).local().valueOf();
      //session time check
      if (currentTime >= local) {
        //do logout
        checkSessionExpiry('');
        return;
      }
    }

    //check access token expiry
    const accessTokenExpireyDate = fetchFromStorage(identifiers.access_token_expiry);
    let isCalled = fetchFromStorage("accessTokenCheckCalled");
    if(accessTokenExpireyDate && (isCalled === null || isCalled === undefined || isCalled === "false")){
      let stillUtc = moment.utc(accessTokenExpireyDate).toDate();
      let local = moment(stillUtc).local().valueOf();
      if (currentTime >= local) {
        checkAccessTokenExpiry();
      }
    }
  }



  const token = fetchFromStorage(identifiers.token);
  const access_token = fetchFromStorage(identifiers.access_token);
  const language = fetchFromStorage(identifiers.language);

  let clonedConfig = config;
  clonedConfig.headers.common = {
    token: `${token}`,
    'Content-Type': 'application/json',
    'Accept-Language': `${language !== null ? language : 'en'}`,
    type: 'WEB',
    access_token: `${access_token}`,
  };
  return clonedConfig;
});

zeissInstance.interceptors.response.use(
  config => {
    return config;
  },
  error => {
    errorHandler(error);
    return Promise.reject(error);
  },
);

export default zeissInstance;
