import { connect } from 'react-redux';

import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { cancelStockCreation } from 'modules/Stock/CreateStock/CreateStock.actions';

import EmailMessages from './EmailMessages.component';
import { fetchEmailMessages, saveUpdateEmailMessages } from './EmailMessages.actions';

const mapStateToProps = ({ createStockReducer, languageReducer, masterDataReducer }) => ({
  rolesList: masterDataReducer.rolesList,
  language: languageReducer,
  stockId: createStockReducer.stockDetails.id,
  canEditStock: createStockReducer.canEditStock,
  crmAccountId: createStockReducer.crmAccountId,
  businessUnitFullValue: createStockReducer.stockDetails.businessUnitFullValue,
});

export default connect(mapStateToProps, {
  fetchEmailMessages,
  toggleActionMessage,
  cancelStockCreation,
  saveUpdateEmailMessages,
})(EmailMessages);
