export const getStatusDescriptionByCode = (language, code) => {
  if (language === 'en') {
    switch (code) {
      case 'E0001':
        return 'Open';
      case 'E0003':
        return 'Customer Action Required';
      case 'E0004':
        return 'Solution Provided';
      case 'E0005':
        return 'Completed';
      case 'E0006':
        return 'Cancelled';
      case 'E0002':
      case 'E0008':
      case 'E0009':
      case 'E0010':
      case 'E0012':
      case 'E0013':
      case 'E0014':
      case 'E0016':
        return 'In Process';
      case 'E0011':
        return 'In Process - Service scheduled';
      case 'E0017':
        return 'Technical Error';
      default:
        break;
    }
  } else if (language === 'de') {
    switch (code) {
      case 'E0001':
        return 'Offen';
      case 'E0003':
        return 'Kundenrückmeldung ausstehend';
      case 'E0004':
        return 'Lösung bereitgestellt';
      case 'E0005':
        return 'Abgeschlossen';
      case 'E0006':
        return 'Storniert';
      case 'E0002':
      case 'E0008':
      case 'E0009':
      case 'E0010':
      case 'E0012':
      case 'E0013':
      case 'E0014':
      case 'E0016':
        return 'In Arbeit';
      case 'E0011':
        return 'In Process - Service scheduled';
      case 'E0017':
        return 'Technischer Fehler';
      default:
        break;
    }
  }
};
  