import React, { Component } from 'react';

import strings from 'resources/locales/Translate';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import DateInput from 'library/common/commonComponents/DateInput/DateInput';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import Button from 'library/common/commonComponents/Button/Button';
import { checkStockForRole } from 'library/utilities/checkStockForRole';
import { saveToStorage,fetchFromStorage } from 'library/utilities/storage';
import { getLocale } from 'library/utilities/getLocale';
import Validators from 'library/utilities/Validators';
import SelectZmccFormTypePopup from 'modules/ZmccTransactions/ZmccTransactionDetails/Components/SelectTypePopup/SelectTypePopup.component';
import $ from 'jquery'
import { systemClassForCourseLoan } from './systemClassData';
import './filter.styles.scss';

const LOAN_TYPES = {
  KIT_LOAN: 1,
  COURSE_LOAN: 3,
  SINGLE_LOAN: 2,
  ZMCC_REQUEST: 5,
  LL_REQUEST: 6
}

const SHOW_WAREHOUSE_TYPE = {
  DLA_TYPE: strings.warehouses,
  LL_PARTNER: strings.lAndlPartner,
  ZMCC: strings.zmccWarehouses
}

export default class Filter extends Component {

  setLoanTypeByDefault = () => {
    let singleUnits = ["RMS", "IQS", "MED", "MED-OPT", "COP", "VIS", "MED-SUR", "MED-ODX"];

    let containsSingleUnit = singleUnits.some(unit => this.props.uniqueBusinessUnits.includes(unit));
    if (this.props.uniqueBusinessUnits.includes('RMS (ZMCC)')) {
      this.props.onChangeLoanType(5);
      return 5;
    } else if (containsSingleUnit){
      this.props.onChangeLoanType(1);
      return 1;
    } else if(this.props.uniqueBusinessUnits.includes("RMS labs@location Partner")){
      this.props.onChangeLoanType(6);
      return 6;
    }
  }

  setWarehouseLabelForDiffTypes = () => {
    let singleUnits = ["RMS", "IQS", "MED", "MED-OPT", "COP", "VIS", "MED-SUR", "MED-ODX"];

    let containsSingleUnit = singleUnits.some(unit => this.props.uniqueBusinessUnits.includes(unit));
    if (this.props.uniqueBusinessUnits.includes('RMS (ZMCC)')) {
      return SHOW_WAREHOUSE_TYPE.ZMCC;
    } else if (containsSingleUnit){
      return SHOW_WAREHOUSE_TYPE.DLA_TYPE;
    } else if (this.props.uniqueBusinessUnits.includes("RMS labs@location Partner")){
      return SHOW_WAREHOUSE_TYPE.LL_PARTNER;
    }
  }
  
  state = {
    fromDate: null,
    toDate: null,
    stocks: [],
    stockFullValue: [],
    systemClass: [],
    systemClassFullValue: [],
    loanType: this.setLoanTypeByDefault(),
    doValidateForm: false,
    loader: false,
    showWarehousesType: this.setWarehouseLabelForDiffTypes(),
    zmccSelectedRequestType: "",
    error: "",
    subLoanTypeForZmcc: "",
    locationLabel: this.setWarehouseLabelForDiffTypes(),
  };

  componentDidMount(){
    this.setLocationLabel()
  }

  componentDidUpdate(prevProps, prevState){
    if(prevState?.loanType !== this.state.loanType){
      this.setState((prev) => {
        const {loanType} = this.state
      let isDLAtype = loanType === LOAN_TYPES.KIT_LOAN || loanType === LOAN_TYPES.COURSE_LOAN || loanType === LOAN_TYPES.SINGLE_LOAN ? SHOW_WAREHOUSE_TYPE.DLA_TYPE : ""
      let isLabsLocationType = loanType === LOAN_TYPES.LL_REQUEST ? SHOW_WAREHOUSE_TYPE.LL_PARTNER : ""
      let isZmcc = loanType === LOAN_TYPES.ZMCC_REQUEST ? SHOW_WAREHOUSE_TYPE.ZMCC: ""
       return {...prev, showWarehousesType: isDLAtype || isLabsLocationType || isZmcc || ""}
      })
    }
    if((prevState.showWarehousesType !== this.state.showWarehousesType) || 
      this.props.language?.language !== prevProps.language?.language){
        this.setLocationLabel()
    }
  }

  setLocationLabel = () => {
    const {showWarehousesType} = this.state;
      let label = ""
      if(showWarehousesType === "Warehouses"){
        label = strings.newWarehouse
      }else if(showWarehousesType === "Customer Center"){
        label = strings.zmccWarehouses
      }else{
        label = strings.lAndlPartner
      }
      this.setState({locationLabel: label})
  }

  handleDropdown = key => (value, field, fullValue) => {
    const { onChangeLoanType } = this.props;
    if (key === 'loanType') {
      this.setState({
        [key]: value,
        [`${key}FullValue`]: fullValue,
        stocks: null,
        stocksFullValue: null,
        systemClass: null,
        systemClassFullValue: null,
      }, () => this.sendFilterValue());
      return onChangeLoanType(value);
    }
    return this.setState({ [key]: value, [`${key}FullValue`]: fullValue }, () => this.sendFilterValue());
  };

  onChangeDate = key => date => {
    this.setState({ [key]: date }, () => this.sendFilterValue());
  };

  sendFilterValue = () => {
    const dataToSave = {
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      stocks: this.state.stocks,
      stocksFullValue: this.state.stocksFullValue,
      loanType: this.state.loanType,
      systemClass: this.state.systemClass,
      systemClassFullValue: this.state.systemClassFullValue,
    };
    saveToStorage(identifiers.homepageFilter, dataToSave);
  };

  showZmccFormTypePopup = () => {
    $('#select-type').modal('toggle')
  }

  handleLLLoanRequestFilter = () => {
    const {history} = this.props
    history.replace({
      pathname: '/labs-location-calendar',
      state: {
        stocks: this.state.stocks,
        stocksFullValue: this.state.stocksFullValue,
        systemClass: this.state.systemClass,
        systemClassFullValue: this.state.systemClassFullValue,
        loanFromDate: this.state.fromDate,
        loanToDate: this.state.toDate
      },
    });
  }

  navigateToZMCCForm = () =>{
    saveToStorage(identifiers.showCrmPopup, false);
    const {subLoanTypeForZmcc, subLoanTypeForZmccFullValue} = this.state;

    if(!subLoanTypeForZmccFullValue){
      this.setState({error: strings.pleaseSelectRequestTypeToContinue})
      return
    }
    const {history} = this.props
    saveToStorage(
      identifiers.zmccHomePageFilters, {
      stocks: this.state.stocks,
      stocksFullValue: this.state.stocksFullValue,
      formType: this.state.subLoanTypeForZmcc
    })
    setTimeout(() => {

      history.push({
        pathname: `/${subLoanTypeForZmccFullValue.translationSubMenu}`.toUpperCase(),
        state: {
          stocks: this.state.stocks ? this.state.stocks : [],
          stocksFullValue: this.state.stocksFullValue ? this.state.stocksFullValue : [],
          formType: this.state.subLoanTypeForZmcc
        }
      })
      window.location.reload();

    }, [500])
  }

  onSubmitFilter = () => {
    const { history, onToggleMessage, isFilterAllowed } = this.props;
    const { loanType } = this.state;
    const { isLoggedIn } = this.props.authentication;

    if (!isLoggedIn) {
      onToggleMessage(true, 'error', 'loginForFilter');
      return;
    }

    if (!isFilterAllowed) {
      onToggleMessage(true, 'error', 'notAllowedToFilter');
      return;
    }

    if (loanType !== null) {
      switch (loanType) {
        case 1:
          history.replace({
            pathname: '/calendar',
            state: {
              stocks: this.state.stocks,
              stocksFullValue: this.state.stocksFullValue,
              systemClass: this.state.systemClass,
              systemClassFullValue: this.state.systemClassFullValue,
              loanFromDate: this.state.fromDate,
              loanToDate: this.state.toDate
            },
          });
          break;
        case 2:
          this.checkLoanBookingForDate('single');
          break;
        case 3:
          this.checkLoanBookingForDate('course');
          break;
        case LOAN_TYPES.ZMCC_REQUEST:
          const {subLoanTypeForZmccFullValue} = this.state;
          if (subLoanTypeForZmccFullValue == null || subLoanTypeForZmccFullValue== ""){
            return onToggleMessage(true, 'error', 'pleaseSelectRequestTypeToContinue');
          }
          this.navigateToZMCCForm();
          break;
        case LOAN_TYPES.LL_REQUEST:
          this.handleLLLoanRequestFilter()
          break;
        default:
          break;
      }
    } else {
      onToggleMessage(true, 'error', 'selectLoanTypeMessage');
    }
  };

  handleWorkflowData = async type => {
    if (!this.state.stocks) return 0;

    const { fetchWorkflowData } = this.props;
    const { success, data: stockWorkflowSettings } = await fetchWorkflowData(this.state.stocks);
    let index = 1;
    let questionId;

    if (type === 'single') {
      index = 2;
      questionId = 17;
    } else {
      index = 3;
      questionId = 18;
    }

    if (
      success &&
      stockWorkflowSettings &&
      stockWorkflowSettings[index] &&
      stockWorkflowSettings[index].stockWorkflowSettingDTO &&
      stockWorkflowSettings[index].stockWorkflowSettingDTO.length
    ) {
      const leadTimeItem = stockWorkflowSettings[index].stockWorkflowSettingDTO.find(
        item => item.categoryQuestionId === questionId,
      ) || {};

      return leadTimeItem.categoryAnswer;
    }

    return 0;
  };


  checkLeadTimeOfBooking = (type, leadTime, fromDate) => {
    let isValidDate = true;
    let errorMessage;
    const week = 7;
    const isDays = leadTime % week;
    if (type === 'single') {
      isValidDate = Validators.date(fromDate, leadTime);
      errorMessage = isDays ? 'singleLoanBookedDays' : 'singleLoanBookedWeeks';
    }
    if (type === 'course') {
      isValidDate = Validators.date(fromDate, leadTime);
      errorMessage = isDays ? 'courseLoanBookedDays' : 'courseLoanBookedWeeks';
    }

    return { isValidDate, errorMessage };
  };

  showLeadTimeOfBookingError = (error, leadTime) => {
    const { onToggleMessage } = this.props;
    const week = 7;
    const amountOfDaysOrWeeks = leadTime % week ? leadTime : leadTime / week;
    const errorMessage = strings[error](amountOfDaysOrWeeks);

    onToggleMessage(true, 'error', errorMessage, true);
  };

  async checkLoanBookingForDate(type) {
    const { fromDate } = this.state;
    const { history } = this.props;
    const path = type === 'single' ? '/single-loan' : '/course-loan';

    this.setState({ loader: true });
    const leadTime = await this.handleWorkflowData(type);
    this.setState({ loader: false });

    if (fromDate) {
      const { isValidDate, errorMessage } = this.checkLeadTimeOfBooking(type, leadTime, fromDate);

      if (!isValidDate) {
        return this.showLeadTimeOfBookingError(errorMessage, leadTime);
      }
    }

    history.replace(
      {
        pathname: path,
        state: {
          stocks: this.state.stocks,
          stocksFullValue: this.state.stocksFullValue,
          systemClass: this.state.systemClass,
          systemClassFullValue: this.state.systemClassFullValue,
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
        },
      }
    );
  }

  createDataForSystemClassDropdown() {
    const { stocks } = this.state;
    const { systemClassWithStock } = this.props.masterDataReducer;
    let arrayOfSystemClass = [];

    if (stocks && stocks.length > 0) {
      const { stockSystemClasses, systemClasses } = systemClassWithStock;
      stocks.forEach(stock => {
        const data = stockSystemClasses && stockSystemClasses[stock];
        if (data) {
          data.forEach(item => {
            arrayOfSystemClass.push(systemClasses.find(systemClass => item === systemClass.systemClassId));
          });
        }
      });
    } else {
      arrayOfSystemClass = systemClassWithStock && systemClassWithStock.systemClasses;
    }

    return Array.from(new Set(arrayOfSystemClass)).sort((a, b) => {
      const systemClassA = a.systemClassName.toUpperCase();
      const systemClassB = b.systemClassName.toUpperCase();

      return systemClassA > systemClassB ? 1 : -1;
    });
  }

  handleOnChangeFilter = (formType) => {
    this.setState({zmccSelectedRequestType: formType, error: ""})
  }

  handleOnSubmit = () => {
    if(!this.state.zmccSelectedRequestType){
      this.setState({error: strings.pleaseSelectRequestTypeToContinue})
      return
    }
    const {history} = this.props
    const {zmccSelectedRequestType} = this.state
    saveToStorage(
      identifiers.zmccHomePageFilters, {
      stocks: this.state.stocks,
      stocksFullValue: this.state.stocksFullValue,
      systemClass: this.state.systemClass,
      formType: this.state.zmccSelectedRequestType
    })
    setTimeout(() => {
      history.replace({
        pathname: `/${zmccSelectedRequestType}`.toUpperCase(),
        state: {
          stocks: this.state.stocks,
          stocksFullValue: this.state.stocksFullValue,
          systemClass: this.state.systemClass,
          formType: this.state.zmccSelectedRequestType
        }
      })
    }, [500])
  }
  
  render() {
    const { masterDataReducer, language, user, loansSubMenu } = this.props;
    const isUserPartner = user && user.labLocationPartner
    const { fromDate, toDate, loader } = this.state;
    const { loanType, showWarehousesType } = this.state;
    let fieldStyle = 'col-lg-4 col-md-6 col-12 mt-3';
    let categoryData = this.createDataForSystemClassDropdown();
    if (loanType && loanType === 3) {
      categoryData = systemClassForCourseLoan;
    }
    let dataOptions = [...masterDataReducer.loanTypes];
    dataOptions = dataOptions.filter((loanType) => {
      let isZmccEnabled = process.env.REACT_APP_IS_ZMCC_ENABLED === 'true'
      if((loanType.id === LOAN_TYPES.ZMCC_REQUEST && isZmccEnabled) || loanType.id !== LOAN_TYPES.ZMCC_REQUEST){
        return true
      }
      return false
    })
    if (isUserPartner) {
      dataOptions = masterDataReducer.loanTypes.filter(item => item.name !== 'Single loan' && item.name !== 'Course loan')
    }

    const updatedDataOptions = dataOptions.map(eachLoanType => {
      if (eachLoanType.id === 5) {
        return { ...eachLoanType, name: strings.customerCenter };
      } else if(eachLoanType.id === 1){
        return { ...eachLoanType, name: strings.demoLoan };
      } else if(eachLoanType.id === LOAN_TYPES.SINGLE_LOAN){
        return { ...eachLoanType, name: strings.singleLoan };
      } else if(eachLoanType.id === LOAN_TYPES.COURSE_LOAN){
        return { ...eachLoanType, name: strings.courseLoan };
      } else if(eachLoanType.id === LOAN_TYPES.LL_REQUEST){
        return { ...eachLoanType, name: strings.labsLocationRequest };
      }
      return eachLoanType;
    });
    let singleUnits = ["RMS", "IQS", "MED", "MED-OPT", "COP", "VIS", "MED-SUR", "MED-ODX"];
  
    let updatedDataOptionsLast = [];

    updatedDataOptions.forEach(eachLoanProcess => {
      
        if (eachLoanProcess.id === 5 && this.props.uniqueBusinessUnits.includes("RMS (ZMCC)")) {
          if (user?.stockRoles?.every(item => item.roleName === 'Borrower')) {
            updatedDataOptionsLast.push(...updatedDataOptions.filter(option => option.id === 5));
          } else {
            let options = updatedDataOptions.filter(option => option.id !== 6);
            updatedDataOptionsLast.push(...options);
          }
        } else if (eachLoanProcess.id === 6 && this.props.uniqueBusinessUnits.includes("RMS labs@location Partner")) {
            updatedDataOptionsLast.push(...updatedDataOptions.filter(option => option.id === 6));
        } else {
          let hasPushed = updatedDataOptionsLast.some(option => option.id !== 5 && option.id !== 6);
            if (!hasPushed) {
              let containsSingleUnit = singleUnits.some(unit => this.props.uniqueBusinessUnits.includes(unit));
                if (containsSingleUnit) {
                    let options = updatedDataOptions.filter(option => option.id !== 5 && option.id !== 6);
                    updatedDataOptionsLast.push(...options);
                }
          }
        }
    });
    
    return (
      <div className='filter-container'>
        <div className='container-fluid'>         
            <div className="button-container">
              <div className='row d-flex justify-content-center align-items-center'>
                {
                  updatedDataOptionsLast.map(eachLoanType => (
                    <Button
                      key={eachLoanType.id} 
                      dataTest='hompage-loantype-button'
                      value={eachLoanType.name}
                      onClick={() => this.handleDropdown('loanType')(eachLoanType.id, eachLoanType, eachLoanType)}
                      bgFill={false}
                      isGhost={true}
                      styleClass={eachLoanType.id === this.state.loanType ? `active-homepage-filter col-md-2 col-sm-12` : `non-active-homepage-filter col-md-2 col-sm-12`}
                    />
                  ))
                }
              </div>
            </div>
          <div className='row'>
          {showWarehousesType && <Dropdown
              dataTest='homepage-dropdown-stock-filter'
              data={checkStockForRole(user, masterDataReducer.countries, 'inbound staff')}
              hasSection={true}
              multiSelect={loanType !== 2 && loanType !== 3 && loanType !== LOAN_TYPES.ZMCC_REQUEST}
              filter={true}
              idKey='id'
              displayValue={[
                { key: 'countryName', separator: ' ' },
                { key: 'businessUnitName', separator: ' ' },
                { key: 'locationShortName', separator: ' - ' },
                { key: 'locationName', separator: '' },
              ]}
              optionsArrayKey={'stockInformation'}
              titleDisplay={[
                { key: 'shortName', separator: ' ' },
                { key: 'name', separator: '' },
              ]}
              isRequired={false}
              placeholder={strings.pleaseSelect + '...'}
              label={this.state.locationLabel}
              onChange={this.handleDropdown('stocks')}
              mainContainerStyle={fieldStyle}
              filterDataPlaceholder={strings.filterStockPlaceholder}
              value={this.state.stocks}
              fullValue={this.state.stocksFullValue}
            />}
            {this.state.loanType === LOAN_TYPES.ZMCC_REQUEST && 
              <Dropdown
              dataTest='homepage-dropdown-zmcc-sub-menu-filter'
              data={loansSubMenu}
              filter={false}
              idKey='loanSubMenuId'
              displayValue={[
                { key: 'translationSubMenu', separator: ' ' },
              ]}
              titleDisplay={[
                { key: 'translationSubMenu', separator: ' ' },
              ]}
              isRequired={true}
              placeholder={strings.pleaseSelect + '...'}
              label={strings.requestType}
              onChange={this.handleDropdown('subLoanTypeForZmcc')}
              mainContainerStyle={fieldStyle}
              value={this.state.subLoanTypeForZmcc}
              fullValue={this.state.subLoanTypeForZmccFullValue}
            />
            }
            {(this.state.loanType !== LOAN_TYPES.ZMCC_REQUEST && this.state.loanType !== LOAN_TYPES.SINGLE_LOAN)&& <Dropdown
              dataTest='homepage-dropdown-categories-filter'
              data={categoryData}
              hasSection={true}
              multiSelect={loanType !== 3}
              filter={true}
              idKey='systemMainComponentId'
              optionsArrayKey={'systemMainComponent'}
              titleDisplay={[{ key: 'systemClassName', separator: '' }]}
              displayValue={[{ key: 'systemMainComponentName', separator: '' }]}
              isRequired={false}
              placeholder={strings.pleaseSelect + '...'}
              label={strings.categorySystem}
              mainContainerStyle={fieldStyle}
              filterDataPlaceholder={strings.filterCategoryPlaceholder}
              onChange={this.handleDropdown('systemClass')}
              disabled={loanType === 2 || LOAN_TYPES.ZMCC_REQUEST === loanType}
              value={this.state.systemClass}
              fullValue={this.state.systemClassFullValue}
            /> }
            {this.state.loanType !== LOAN_TYPES.ZMCC_REQUEST &&
            <DateInput
              label={strings.demoStart}
              placeholder={strings.pleaseSelectDate + ' ...'}
              mainContainerStyle={fieldStyle}
              onChangeDate={this.onChangeDate('fromDate')}
              minDate={new Date()}
              maxDate={toDate}
              locale={getLocale(language.locale)}
              disabled={loanType === LOAN_TYPES.ZMCC_REQUEST}
            />}
            {this.state.loanType !== LOAN_TYPES.ZMCC_REQUEST &&
            <DateInput
              data-test='homepage-filter-from-date'
              label={strings.homeDemoEndDate}
              placeholder={strings.pleaseSelectDate + ' ...'}
              mainContainerStyle={fieldStyle}
              onChangeDate={this.onChangeDate('toDate')}
              minDate={fromDate === null ? new Date() : fromDate}
              locale={getLocale(language.locale)}
              disabled={loanType === LOAN_TYPES.ZMCC_REQUEST}
            />}
            <div className={'col-lg-2 col-md-3 col-12 mt-3 d-flex align-items-end'}>
              <Button
                dataTest='hompage-submit-filter'
                value={strings.showOptions}
                onClick={this.onSubmitFilter}
                loading={loader}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
