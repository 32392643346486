import React from 'react';
import strings from 'resources/locales/Translate';
import './stylesheet.scss';
import Icon from '../../../../commonComponents/Icon/Icon';
import classNames from 'classnames';

export const RadioButton = ({ data, dataTest, disabled, error, field, label, onChange, isRequired, value, showClearIcon = false }) => {
  const onValueChange = value => () => {
    onChange(value, field);
  };

  const handleClear = (e) => {
    e.stopPropagation(); // This avoid passing click event to parent button
    onChange("", field)
  }

  return (
    <div className='app-radio-button' data-test={dataTest}>
      <div className='label-container d-flex align-items-center'>
        <span>{label} {isRequired && label && <span className='mandatory-asterik'>*</span>}</span>
      </div>
      <div className='btn-group d-flex' role='group' aria-label='Basic example'>
        {data.map((item, key) => (
          <button
            disabled={disabled}
            key={key}
            type='button'
            className={classNames(`btn d-flex  rounded-0 ${value === item.id ? 'active' : ''} ${error && 'input-error'}`, {
              'd-flex align-items-center': value !== item.id,
              'd-flex align-items-center justify-content-between': value === item.id
            })}
            onClick={onValueChange(item.id)}
          >
            {strings[item.value]}
            {showClearIcon && <span className={classNames({'d-none': value !== item.id})} onClick={handleClear}>
              <Icon name='clear' width='15' height='15' viewBox='0 0 48 48'  fill={'#fff'} />
            </span>}
          </button>
        ))}
      </div>
    </div>
  );
};

RadioButton.defaultProps = {
  value: '',
  disabled: false,
  showClearIcon: false,
};

export default RadioButton;
