import React from 'react';

import AttachmentsForm from 'library/common/commonComponents/AttachmentsForm';
import Attachments from 'library/common/commonComponents/KitAttachments';
import Accessories from 'library/common/commonComponents/Accessories';
import Components from 'library/common/commonComponents/Components';
import Messages from 'library/common/commonComponents/KitMessages';
import ServiceTicket from 'library/common/commonComponents/ServiceTicket';
import * as actionTypes from './KitDetailsConstants';
import Information from './Components/Information';
import Statistics from './Components/Statistics';
import DemoUnitStatus from './Components/DemoUnitStatus';
import LLInformation from './Components/LLInformation';
import LLStatistics from './Components/LLStatistics';
import ZMCCInformation from './Components/ZMCCInformation';
import VRDetails from "./Components/VrDetails";
import ReparationSlots from './Components/ReparationSlots';
import KitMarketplaceDetails from './Components/KitMarketplaceDetails/KitMarketplaceDetails.component';
import NewCreateKitComponents from '../CreateZmccKit/Components/NewComponentsTab';
import IQSInformation from './Components/IQSInformation';

export const kitDetailsTabs = (
  kitDetail,
  history,
  isEditable,
  match,
  count,
  kitDetailsAttachments,
  commentsData,
  damageLossReportData,
  updateKitAttachents,
  formValue,
  saveKitAttachments,
  isLoading,
  isServiceManagerOrServiceTechnician,
  hasOnlyDustUserRole,
  user,
  transactionAttachments,
  workFlowQuestions,
  isVrKit,
  checkForShowingVRDetailsTab,
  availabilitySlotList,
  newComponentsForm,
  fetchNewComponentsList,
  newComponentsList,
  addUpdateNewComponent,
  toggleActionMessage,
  deleteNewComponent,
  handlenewComponentsForm,
  updateComponentsForm
) => {
  if (kitDetail.isLabsLocationPartner) {
    return [
      {
        id: 1,
        tabTitle: 'information',
        className: '',
        render: isActive => {
          return <LLInformation isActive={isActive} kitDetail={kitDetail} history={history} user={user} />;
        },
      },
      {
        id: 2,
        tabTitle: 'components',
        className: '',
        render: isActive => {
          return <Components isActive={isActive} kitDetail={kitDetail} history={history} isEditable={isEditable} />;
        },
      },
      {
        id: 3,
        tabTitle: 'accessories',
        className: '',
        render: isActive => {
          return <Accessories isActive={isActive} kitDetail={kitDetail} history={history} isEditable={isEditable} />;
        },
      },
      {
        id: 4,
        tabTitle: 'messages',
        className: '',
        count,
        render: isActive => {
          return (
            <Messages
              isActive={isActive}
              kitDetail={kitDetail}
              commentsData={commentsData}
              damageLossReportData={damageLossReportData}
              history={history}
              isEditable={isEditable}
              actionTypes={actionTypes}
              match={match}
            />
          );
        },
      },
      {
        id: 5,
        tabTitle: 'attachments',
        className: '',
        render: () => {
          if (!isEditable && isServiceManagerOrServiceTechnician) {
            return (
              <AttachmentsForm
                cancelCreateKit={null}
                kitDetails={kitDetail}
                saveKit={saveKitAttachments}
                formValue={formValue}
                kitInformationId={kitDetail.kitInformationId}
                handleForm={updateKitAttachents}
                isNotRemoved={true}
                isLoading={isLoading}
                hasVideo={true}
                transactionAttachments={transactionAttachments}
                hasTransactionAttachmentsSection={true}
              />
            );
          }

          return (
            <Attachments
              kitDetail={kitDetail}
              kitDetailsAttachments={kitDetailsAttachments}
              transactionAttachments={transactionAttachments}
              hasTransactionAttachmentsSection={true}
            />
          );
        },
      },
      {
        id: 6,
        tabTitle: 'statistics',
        className: '',
        render: () => {
          return <LLStatistics kitDetail={kitDetail} />;
        },
      },
    ];
  }

  if (kitDetail.isZMCCKit && (!isVrKit)) {
    return [
      {
        id: 1,
        tabTitle: 'information',
        className: '',
        render: isActive => {
          return <ZMCCInformation isActive={isActive} kitDetail={kitDetail} history={history} user={user} availabilitySlotList={availabilitySlotList}/>;
        },
      },
      {
        id: 2,
        tabTitle: 'components',
        className: '',
        render: (isActive) => (
          (newComponentsList?.length || newComponentsList?.content?.length) ?
          <NewCreateKitComponents
            history={history}
            kitDetails={kitDetail && kitDetail}
            // saveKit={saveKit}
            formValue={{ newComponentsForm }}
            // cancelCreateKit={cancelCreateKit}
            handleForm={handlenewComponentsForm}
            isOptional={true}
            addUpdateNewComponent={addUpdateNewComponent}
            fetchNewComponentsList={fetchNewComponentsList}
            newComponentsList={newComponentsList}
            updateForm={updateComponentsForm}
            toggleActionMessage={toggleActionMessage}
            deleteNewComponent={deleteNewComponent}
            isEditable={isEditable}
            isInEditOrCreateorCopyMode={false}
          />
          :
          <Components isActive={isActive} kitDetail={kitDetail} history={history} isEditable={isEditable} />
        ),
      },
      {
        id: 3,
        tabTitle: 'accessories',
        className: '',
        render: isActive => {
          return <Accessories isActive={isActive} kitDetail={kitDetail} history={history} isEditable={isEditable} />;
        },
      },
      {
        id: 4,
        tabTitle: 'messages',
        className: '',
        count,
        render: isActive => {
          return (
            <Messages
              isActive={isActive}
              kitDetail={kitDetail}
              commentsData={commentsData}
              damageLossReportData={damageLossReportData}
              history={history}
              isEditable={isEditable}
              actionTypes={actionTypes}
              match={match}
            />
          );
        },
      },
      {
        id: 5,
        tabTitle: 'attachments',
        className: '',
        render: () => {
          if (!isEditable && isServiceManagerOrServiceTechnician) {
            return (
              <AttachmentsForm
                cancelCreateKit={null}
                kitDetails={kitDetail}
                saveKit={saveKitAttachments}
                formValue={formValue}
                kitInformationId={kitDetail.kitInformationId}
                handleForm={updateKitAttachents}
                isNotRemoved={true}
                isLoading={isLoading}
                hasVideo={true}
                transactionAttachments={transactionAttachments}
                hasTransactionAttachmentsSection={true}
              />
            );
          }

          return (
            <Attachments
              kitDetail={kitDetail}
              kitDetailsAttachments={kitDetailsAttachments}
              transactionAttachments={transactionAttachments}
              hasTransactionAttachmentsSection={true}
            />
          );
        },
      },
      {
        id: 6,
        tabTitle: 'statistics',
        className: '',
        render: () => {
          return <LLStatistics kitDetail={kitDetail} />;
        },
      },
    ];
  }

  if (kitDetail?.isIqsSscDeKit){
    return [
      {
        id: 1,
        tabTitle: 'information',
        className: '',
        render: isActive => {
          return <IQSInformation isActive={isActive} kitDetail={kitDetail} history={history} workFlowQuestions={workFlowQuestions} />;
        },
      },
      {
        id: 2,
        tabTitle: 'components',
        className: '',
        render: (isActive) => (
          <NewCreateKitComponents
            history={history}
            kitDetails={kitDetail && kitDetail}
            // saveKit={saveKit}
            formValue={{ newComponentsForm }}
            // cancelCreateKit={cancelCreateKit}
            handleForm={handlenewComponentsForm}
            isOptional={true}
            addUpdateNewComponent={addUpdateNewComponent}
            fetchNewComponentsList={fetchNewComponentsList}
            newComponentsList={newComponentsList}
            updateForm={updateComponentsForm}
            toggleActionMessage={toggleActionMessage}
            deleteNewComponent={deleteNewComponent}
            isEditable={isEditable}
            isInEditOrCreateorCopyMode={false}
          />
        ),
      },
      {
        id: 3,
        tabTitle: 'messages',
        className: '',
        count,
        render: isActive => {
          return (
            <Messages
              isActive={isActive}
              kitDetail={kitDetail}
              commentsData={commentsData}
              damageLossReportData={damageLossReportData}
              history={history}
              isEditable={isEditable}
              actionTypes={actionTypes}
              match={match}
            />
          );
        },
      },
      {
        id: 5,
        tabTitle: 'demoUnitStatus',
        className: '',
        render: () => {
          return <DemoUnitStatus kitDetail={kitDetail} />;
        },
      },
      {
        id: 6,
        tabTitle: 'attachments',
        className: '',
        render: () => {
          if (!isEditable && isServiceManagerOrServiceTechnician) {
            return (
              <AttachmentsForm
                cancelCreateKit={null}
                kitDetails={kitDetail}
                saveKit={saveKitAttachments}
                formValue={formValue}
                kitInformationId={kitDetail.kitInformationId}
                handleForm={updateKitAttachents}
                isNotRemoved={true}
                isLoading={isLoading}
                hasVideo={true}
                transactionAttachments={transactionAttachments}
                hasTransactionAttachmentsSection={true}
              />
            );
          }

          return (
            <Attachments
              kitDetail={kitDetail}
              kitDetailsAttachments={kitDetailsAttachments}
              transactionAttachments={transactionAttachments}
              hasTransactionAttachmentsSection={true}
            />
          );
        },
      },
    ]
  }

  if (kitDetail.isZMCCKit && (isVrKit && checkForShowingVRDetailsTab())) {
    return [
      {
        id: 1,
        tabTitle: 'information',
        className: '',
        render: isActive => {
          return <ZMCCInformation isActive={isActive} kitDetail={kitDetail} history={history} user={user} availabilitySlotList={availabilitySlotList} />;
        },
      },
      {
        id: 2,
        tabTitle: 'components',
        className: '',
        render: isActive => {
          return <Components isActive={isActive} kitDetail={kitDetail} history={history} isEditable={isEditable} />;
        },
      },
      {
        id: 3,
        tabTitle: 'accessories',
        className: '',
        render: isActive => {
          return <Accessories isActive={isActive} kitDetail={kitDetail} history={history} isEditable={isEditable} />;
        },
      },
      {
        id: 4,
        tabTitle: 'vrDetails',
        className: '',
        render: isActive => {
          return <VRDetails isActive={isActive} kitDetail={kitDetail} history={history} isEditable={isEditable} />;
        },
      },
      {
        id: 5,
        tabTitle: 'messages',
        className: '',
        count,
        render: isActive => {
          return (
            <Messages
              isActive={isActive}
              kitDetail={kitDetail}
              commentsData={commentsData}
              damageLossReportData={damageLossReportData}
              history={history}
              isEditable={isEditable}
              actionTypes={actionTypes}
              match={match}
            />
          );
        },
      },
      {
        id: 6,
        tabTitle: 'attachments',
        className: '',
        render: () => {
          if (!isEditable && isServiceManagerOrServiceTechnician) {
            return (
              <AttachmentsForm
                cancelCreateKit={null}
                kitDetails={kitDetail}
                saveKit={saveKitAttachments}
                formValue={formValue}
                kitInformationId={kitDetail.kitInformationId}
                handleForm={updateKitAttachents}
                isNotRemoved={true}
                isLoading={isLoading}
                hasVideo={true}
                transactionAttachments={transactionAttachments}
                hasTransactionAttachmentsSection={true}
              />
            );
          }

          return (
            <Attachments
              kitDetail={kitDetail}
              kitDetailsAttachments={kitDetailsAttachments}
              transactionAttachments={transactionAttachments}
              hasTransactionAttachmentsSection={true}
            />
          );
        },
      },
      {
        id: 7,
        tabTitle: 'statistics',
        className: '',
        render: () => {
          return <LLStatistics kitDetail={kitDetail} />;
        },
      },
    ];
  }

  const tabsForDustUser = [
    {
      id: 2,
      tabTitle: 'components',
      className: '',
      render: isActive => {
        return <Components isActive={isActive} kitDetail={kitDetail} history={history} isEditable={isEditable} />;
      },
    },
    {
      id: 4,
      tabTitle: 'messages',
      className: '',
      count,
      render: isActive => {
        return (
          <Messages
            isActive={isActive}
            kitDetail={kitDetail}
            commentsData={commentsData}
            damageLossReportData={damageLossReportData}
            history={history}
            isEditable={isEditable}
            actionTypes={actionTypes}
            match={match}
          />
        );
      },
    },
    {
      id: 5,
      tabTitle: 'demoUnitStatus',
      className: '',
      render: () => {
        return <DemoUnitStatus kitDetail={kitDetail} />;
      },
    },
    {
      id: 6,
      tabTitle: 'attachments',
      className: '',
      render: () => {
        if (!isEditable && isServiceManagerOrServiceTechnician) {
          return (
            <AttachmentsForm
              cancelCreateKit={null}
              kitDetails={kitDetail}
              saveKit={saveKitAttachments}
              formValue={formValue}
              kitInformationId={kitDetail.kitInformationId}
              handleForm={updateKitAttachents}
              isNotRemoved={true}
              isLoading={isLoading}
              hasVideo={true}
              transactionAttachments={transactionAttachments}
              hasTransactionAttachmentsSection={true}
            />
          );
        }

        return (
          <Attachments
            kitDetail={kitDetail}
            kitDetailsAttachments={kitDetailsAttachments}
            transactionAttachments={transactionAttachments}
            hasTransactionAttachmentsSection={true}
          />
        );
      },
    },
  ];
  const tabs = [
    {
      id: 1,
      tabTitle: 'information',
      className: '',
      render: isActive => {
        return <Information isActive={isActive} kitDetail={kitDetail} history={history} workFlowQuestions={workFlowQuestions} />;
      },
    },
    {
      id: 2,
      tabTitle: 'components',
      className: '',
      render: (isActive) => (
        (newComponentsList?.length || newComponentsList?.content?.length) ?
        <NewCreateKitComponents
          history={history}
          kitDetails={kitDetail && kitDetail}
          // saveKit={saveKit}
          formValue={{ newComponentsForm }}
          // cancelCreateKit={cancelCreateKit}
          handleForm={handlenewComponentsForm}
          isOptional={true}
          addUpdateNewComponent={addUpdateNewComponent}
          fetchNewComponentsList={fetchNewComponentsList}
          newComponentsList={newComponentsList}
          updateForm={updateComponentsForm}
          toggleActionMessage={toggleActionMessage}
          deleteNewComponent={deleteNewComponent}
          isEditable={isEditable}
          isInEditOrCreateorCopyMode={false}
        />
        :
        <Components isActive={isActive} kitDetail={kitDetail} history={history} isEditable={isEditable} />
      ),
    },
    {
      id: 3,
      tabTitle: 'accessories',
      className: '',
      render: isActive => {
        return <Accessories isActive={isActive} kitDetail={kitDetail} history={history} isEditable={isEditable} />;
      },
    },
    {
      id: 4,
      tabTitle: 'messages',
      className: '',
      count,
      render: isActive => {
        return (
          <Messages
            isActive={isActive}
            kitDetail={kitDetail}
            commentsData={commentsData}
            damageLossReportData={damageLossReportData}
            history={history}
            isEditable={isEditable}
            actionTypes={actionTypes}
            match={match}
          />
        );
      },
    },
    {
      id: 5,
      tabTitle: 'demoUnitStatus',
      className: '',
      render: () => {
        return <DemoUnitStatus kitDetail={kitDetail} />;
      },
    },
    {
      id: 6,
      tabTitle: 'attachments',
      className: '',
      render: () => {
        if (!isEditable && isServiceManagerOrServiceTechnician) {
          return (
            <AttachmentsForm
              cancelCreateKit={null}
              kitDetails={kitDetail}
              saveKit={saveKitAttachments}
              formValue={formValue}
              kitInformationId={kitDetail.kitInformationId}
              handleForm={updateKitAttachents}
              isNotRemoved={true}
              isLoading={isLoading}
              hasVideo={true}
              transactionAttachments={transactionAttachments}
              hasTransactionAttachmentsSection={true}
            />
          );
        }

        return (
          <Attachments
            kitDetail={kitDetail}
            kitDetailsAttachments={kitDetailsAttachments}
            transactionAttachments={transactionAttachments}
            hasTransactionAttachmentsSection={true}
          />
        );
      },
    },
    {
      id: 7,
      tabTitle: 'statistics',
      className: '',
      render: () => {
        return <Statistics kitDetail={kitDetail} isEditable={isEditable} />;
      },
    },
    {
      id: 8,
      tabTitle: 'serviceTicket',
      className: '',
      render: () => {
        return <ServiceTicket kitDetail={kitDetail} />;
      },
    },
    {
      id: 9,
      tabTitle: 'reparationSlots',
      className: '',
      render: () => {
        return <ReparationSlots kitDetail={kitDetail} history={history} />;
      },
    },
  ];

  if (kitDetail?.isSalesFunctionActivatedInLocation) {
    tabs.push({
      id: 10,
      tabTitle: 'demoSale',
      className: '',
      render: () => {
        return <KitMarketplaceDetails kitDetail={kitDetail} history={history} />;
      },
    },)
  }

  const tabsForVrKitSystemClass = [
    {
      id: 1,
      tabTitle: 'information',
      className: '',
      render: isActive => {
        return <Information isActive={isActive} kitDetail={kitDetail} history={history} workFlowQuestions={workFlowQuestions} />;
      },
    },
    {
      id: 2,
      tabTitle: 'components',
      className: '',
      render: isActive => {
        return <Components isActive={isActive} kitDetail={kitDetail} history={history} isEditable={isEditable} />;
      },
    },
    {
      id: 3,
      tabTitle: 'accessories',
      className: '',
      render: isActive => {
        return <Accessories isActive={isActive} kitDetail={kitDetail} history={history} isEditable={isEditable} />;
      },
    },
    {
      id: 4,
      tabTitle: 'vrDetails',
      className: '',
      render: isActive => {
        return <VRDetails isActive={isActive} kitDetail={kitDetail} history={history} isEditable={isEditable} />;
      },
    },
    {
      id: 5,
      tabTitle: 'messages',
      className: '',
      count,
      render: isActive => {
        return (
          <Messages
            isActive={isActive}
            kitDetail={kitDetail}
            commentsData={commentsData}
            damageLossReportData={damageLossReportData}
            history={history}
            isEditable={isEditable}
            actionTypes={actionTypes}
            match={match}
          />
        );
      },
    },
    {
      id: 6,
      tabTitle: 'attachments',
      className: '',
      render: () => {
        if (!isEditable && isServiceManagerOrServiceTechnician) {
          return (
            <AttachmentsForm
              cancelCreateKit={null}
              kitDetails={kitDetail}
              saveKit={saveKitAttachments}
              formValue={formValue}
              kitInformationId={kitDetail.kitInformationId}
              handleForm={updateKitAttachents}
              isNotRemoved={true}
              isLoading={isLoading}
              hasVideo={true}
              transactionAttachments={transactionAttachments}
              hasTransactionAttachmentsSection={true}
            />
          );
        }

        return (
          <Attachments
            kitDetail={kitDetail}
            kitDetailsAttachments={kitDetailsAttachments}
            transactionAttachments={transactionAttachments}
            hasTransactionAttachmentsSection={true}
          />
        );
      },
    },
    {
      id: 7,
      tabTitle: 'statistics',
      className: '',
      render: () => {
        return <Statistics kitDetail={kitDetail} isEditable={isEditable} />;
      },
    },
  ];

  return hasOnlyDustUserRole ? tabsForDustUser : (isVrKit && checkForShowingVRDetailsTab())  ? tabsForVrKitSystemClass : tabs;
};
