import React, { useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';

import strings from 'resources/locales/Translate';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import DateInput from 'library/common/commonComponents/DateInput/DateInput';
import { getDateInFormat } from 'library/utilities/getDateInFormat';
import FilterTags from '../FilterTags/FilterTags.component';

const ZMCCFilters = props => {
  const {
    selected,
    isShowFilter,
    countries,
    zmccCategory,
    transactionStatuses,
    handleFilterChange,
    zmccDateTpyes,
    zmccOperatorList,
    requestTypeData,
    systemCategoriesList,
    resetFiltersZmcc,
  } = props;
  const filterStatus = transactionStatuses && transactionStatuses.length >0 && transactionStatuses.filter(item => item.availableForZmcc === true)
  const [dateObj, setDateObj] = useState('');
  const dateInputRef = useRef();
  useEffect(() => {
    if (selected.dateRange && selected.dateType) {
      setDateObj(selected.dateRange);
    }
  },[selected])

  const handleFilter = key => (value, field, fullValue) => {
    if (key === 'dateType') {
      if (value !== zmccDateTpyes) {
        setDateObj('');
        handleFilterChange(value, key, fullValue);
      }
    } 
  };

 const handleChange = dateRange => {
   let newDateRange = null;
   if (dateRange) {
     newDateRange = [...dateRange];
     const dateRangeStart = dateRange[0] ? `${getDateInFormat('YYYY-MM-DD', dateRange[0])} 00:00:00` : null;
     const dateRangeEnd = dateRange[1] ? `${getDateInFormat('YYYY-MM-DD', dateRange[1])} 00:00:00` : null;
     newDateRange[0] = dateRangeStart;
     newDateRange[1] = dateRangeEnd;
   }
   // this.setState({ dateObj: dateRange });
   setDateObj(dateRange);
   handleFilterChange(newDateRange, 'dateRange', newDateRange);
 };

 const handleOnClear = (field) => {
  if(field === 'dateRange'){
    if(dateInputRef && dateInputRef.current){
      dateInputRef.current.setState({date: ""})
    }
  }
  handleFilterChange(null, field, null)
 }

  let zmccFiltersModel = {
      stock: { displayValue: [{ key: 'locationName', separator: '' }], multiple: true },
      transactionStatuses: { displayValue: [{ key: 'name', separator: ''}], multiple: true},
      requestType: {displayValue: [{ key: 'name', separator: '' }], multiple: false},
      category: {displayValue: [{ key: 'name', separator: '' }], multiple: true},
      systemCategoriesId: {displayValue: [{ key: 'name', separator: '' }], multiple: true},
      operator: {displayValue:[{ key: 'firstName', separator: ', ' },{ key: 'lastName', separator: ' ' }], multiple: true},
      dateType: {displayValue: [{ key: 'name', separator: '' }], multiple: false}
  }
  return (
    <>
      <div className={cn('container-fluid', { 'd-block': isShowFilter, 'd-none': !isShowFilter })}>
        <div className='new-filter-body'>
          <div className='row'>
            <Dropdown
              label={strings.zmccFullForm}
              data={countries}
              selectAllOption
              selectAllPlaceholder={strings.selectAll}
              idKey='id'
              field='stock'
              displayValue={[{ key: 'locationName', separator: '' }]}
              optionsArrayKey='stockInformation'
              titleDisplay={[
                { key: 'shortName', separator: ' ' },
                { key: 'name', separator: '' },
              ]}
              isRequired={false}
              hasSection={true}
              multiSelect={true}
              filter={true}
              placeholder={strings.pleaseSelect + '...'}
              onChange={handleFilterChange}
              mainContainerStyle='col-lg-3 mb-3'
              value={selected.stock}
              fullValue={selected.stockFullValue}
              newDropdownStyle={true}
            />

            <Dropdown
              data={requestTypeData}
              hasSection={false}
              multiSelect={false}
              filter={false}
              clearOption={true}
              idKey='id'
              displayValue={[{ key: 'name', separator: '' }]}
              isRequired={false}
              placeholder={strings.pleaseSelect + '...'}
              label={strings.requestType}
              field='requestType'
              onChange={handleFilterChange}
              mainContainerStyle='col-lg-3 mb-3'
              value={selected.requestType}
              fullValue={selected.requestTypeFullValue}
              newDropdownStyle={true}
            />

            <Dropdown
              data={filterStatus}
              hasSection={false}
              multiSelect={true}
              filter={false}
              clearMultiOption={true}
              idKey='transactionStatusId'
              displayValue={[{ key: 'name', separator: '' }]}
              isRequired={false}
              placeholder={strings.pleaseSelect + '...'}
              label={strings.transactionStatus}
              field='transactionStatuses'
              onChange={handleFilterChange}
              mainContainerStyle='col-lg-3 mb-3'
              value={selected.transactionStatuses}
              fullValue={selected.transactionStatusesFullValue}
              selectAllOption
              newDropdownStyle={true}
            />
            <Dropdown
              data={zmccCategory}
              hasSection={false}
              multiSelect={true}
              filter={false}
              clearMultiOption={true}
              idKey='id'
              displayValue={[{ key: 'name', separator: '' }]}
              isRequired={false}
              placeholder={strings.pleaseSelect + '...'}
              label={strings.category}
              field='category'
              onChange={handleFilterChange}
              mainContainerStyle='col-lg-3 mb-3'
              value={selected.category}
              fullValue={selected.categoryFullValue}
              selectAllOption
              newDropdownStyle={true}
            />
            <Dropdown
              data={systemCategoriesList}
              hasSection={false}
              multiSelect={true}
              filter={false}
              clearMultiOption={false}
              idKey='id'
              displayValue={[
                { key: 'name', separator: '' }
              ]}
              isRequired={false}
              placeholder={strings.pleaseSelect + '...'}
              label={strings.systemCategory}
              field='systemCategoriesId'
              onChange={handleFilterChange}
              mainContainerStyle='col-lg-3 mb-3'
              value={selected.systemCategoriesId}
              fullValue={selected.systemCategoriesIdFullValue}
              newDropdownStyle={true}
            />
            <Dropdown
              data={zmccOperatorList}
              hasSection={false}
              multiSelect={true}
              filter={false}
              clearMultiOption={false}
              idKey='accountId'
              displayValue={[
                { key: 'firstName', separator: ', ' },
                { key: 'lastName', separator: ' ' },
              ]}
              isRequired={false}
              placeholder={strings.pleaseSelect + '...'}
              label={strings.operator}
              field='operator'
              onChange={handleFilterChange}
              mainContainerStyle='col-lg-3 mb-3'
              value={selected.operator}
              fullValue={selected.operatorFullValue}
              newDropdownStyle={true}
            />
            <Dropdown
              data={zmccDateTpyes}
              hasSection={false}
              multiSelect={false}
              filter={false}
              clearOption={true}
              idKey='id'
              displayValue={[{ key: 'name', separator: '' }]}
              isRequired={false}
              placeholder={strings.pleaseSelect + '...'}
              label={strings.dateType}
              field='dateType'
              onChange={handleFilter('dateType')}
              mainContainerStyle='col-lg-3 mb-3'
              value={selected.dateType}
              fullValue={selected.dateTypeFullValue}
              selectAllOption
              selectAllPlaceholder={strings.selectAll}
              newDropdownStyle={true}
            />
            <DateInput
              disabled={!selected.dateType}
              label={strings.dateRange}
              placeholder={strings.pleaseSelectDate + '...'}
              onChangeDate={handleChange}
              selectedDate={dateObj}
              isRequired={false}
              mainContainerStyle='col-lg-3 mb-3'
              isRange={true}
              validateOnBlur={false}
              ref={dateInputRef}
            />
          </div>
        </div>
      </div>
      <div>
        <FilterTags 
          filters={selected} 
          model={zmccFiltersModel} 
          onClear={handleOnClear} 
          onClearAll={() => {
            if(dateInputRef && dateInputRef.current){
              dateInputRef.current.setState({date: ""})
            }
            resetFiltersZmcc();
          }}
        />
      </div>
    </>
  );
};

export default ZMCCFilters;
