import React from 'react';

import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import CreateStockActionButtons from 'library/common/commonComponents/CreateStockActionButtons';

import { addressModel } from './SellOff.constants';
import ShopManagers from './ShopManagers';
import { useSellOff } from './SellOff.hook';
import './SellOff.style.scss';

export default function SellOff({
  canEditStock,
  usersList,
  stockDetails,
  stockViewDuration,
  history,
  saveSellOff,
  fetchMasterData,
  language,
}) {
  const {
    handleClickOnSave,
    cancelSellOff,
    handleForm,
    addShopManagers,
    removeShopManagers,
    shopManagersForm,
    sellOffForm,
    addressForm,
    getSellOffModel,
  } = useSellOff({
    history,
    saveSellOff,
    stockDetails,
    stockViewDuration,
    fetchMasterData,
    language,
  });

  return (
    <div>
      <div className='container-fluid form-container'>
        <h3 className='sellOff-title'>{strings.demoSale}</h3>
        <div className='row'>
          <div className='col-md-6'>
            <FormComponent
              disableForm={!canEditStock}
              formName='sellOffForm'
              model={getSellOffModel()}
              formValue={sellOffForm.formData}
              onChange={handleForm}
            />
            <h4 className='form-title mt-3'>{strings.shopManagers}</h4>
            <ShopManagers
              canEditStock={canEditStock}
              usersList={usersList}
              addShopManagers={addShopManagers}
              removeShopManagers={removeShopManagers}
              shopManagers={shopManagersForm.fullValue}
              shopManagersIds={shopManagersForm.value}
              error={shopManagersForm.error}
            />
          </div>
          <div className='col-md-6'>
            <h4 className='form-title'>{strings.addressText}</h4>
            <FormComponent
              disableForm={!canEditStock}
              formName='addressForm'
              model={addressModel}
              formValue={addressForm.formData}
              onChange={handleForm}
            />
          </div>
        </div>
        {canEditStock && (
          <CreateStockActionButtons onSave={handleClickOnSave} onCancel={cancelSellOff} styleClass={'save-next-pev-button'}/>
        )}
      </div>
    </div>
  );
};
