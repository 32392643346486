import Modal from 'library/common/commonComponents/Modal';
import React from 'react';
import strings from 'resources/locales/Translate';

export  const ReparationSlotsCancelPopup = (props) =>{
    
    return(
        <Modal 
            id={props.id}
            confirmAction={() => props.onContinueBooking('delete-reparation-slot-modal')}
            confirmTitle={strings.confirm}
            titleOfCancel={strings.cancel}
            loading={props.isLoading}
            onCloseClick={() => props.onCancelClick('delete-reparation-slot-modal')}
        >
            <p>
                {strings.cancelWarningMessageForReparation}
            </p>
        </Modal>
    )
}

export default ReparationSlotsCancelPopup;