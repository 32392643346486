export const camelCase = str => {
  str.replace(/^\s+|\s+$|\s+(?=\s)/g, '').toLowerCase();
  const array = str.split(' ');
  let value = '';
  array.forEach((s, i) => {
    if (value) {
      value += s[0].toUpperCase() + s.slice(1);
    } else {
      value = s; 
    }
  });
  return value;
};
  
export const firstToUpperCase = str => str && str[0].toUpperCase() + str.slice(1);

export const containsIgnoreCase = (string1 = "", string2 = "") => {
  if(string1 === string2){
    return true;
  }
  let str1 = new String(string1);
  let str2 = new String(string2);

  str1 = str1.replace(/ /g, "").toLowerCase();
  str2 = str2.replace(/ /g, "").toLowerCase();
  return str1.includes(str2);
}