import { DUST_MANAGER, DUST_USER, ADMIN } from 'library/common/commonConstants/roles';

export const permissions = {
  stockDashboardMenu: [
    'Dispatcher',
    'Inbound staff',
    'Outbound staff',
    'Manager',
    'Reconditioning',
    'Stock Controller',
    'Admin',
  ],
  statisticsMenu: [
    'Dispatcher',
    'Inbound staff',
    'Outbound staff',
    'Manager',
    'Reconditioning',
    'Stock Controller',
    'Admin',
  ],
  subscriptionsMenu: ['Reconditioning', 'Stock Controller', 'Dispatcher', 'Manager', 'Admin', DUST_MANAGER, DUST_USER],
  permissionsMenu: [DUST_MANAGER, 'Admin'],
  dustTaskMenu: [DUST_MANAGER, 'Admin', DUST_USER],
  orderList: ['Shop Manager', 'Admin'],
  opportunitySearch: ['Borrower', 'Admin'],
  stockRestrictions: ['Manager', 'Admin'],
  bookingTeams: ['Stock Controller', 'Manager', 'Admin'],
  maintainStatusMenu: [DUST_USER, DUST_MANAGER],
  userPermissionsMenu: ['Stock Controller', 'Admin'],
  dustStatus: [DUST_MANAGER, DUST_USER, ADMIN],
  activeSystemsMenu: [DUST_USER, DUST_MANAGER, 'Admin'],
};
