import React, { useEffect, useState } from 'react';
import Information from './Tabs/Information';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import {  FETCH_TYPES_OF_JOBS} from 'library/common/commonConstants/storeConstants';
import { get } from 'lodash';
import { saveToStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import { item } from 'modules/Calendar/__mocks__/calendarData';
import SemDemoRequestForm from './Tabs/SemDemoRequestForm/SemDemoRequestForm.component';
import Messages from 'modules/Transactions/TransactionDetails/Tabs/Messages';
import ChangeHistory from 'modules/Transactions/TransactionDetails/Tabs/ChangeHistory';
import ZmccAttachements from './Tabs/ZmccAttachments/ZmccAttachments.component';
import ZmccTodoList from './Tabs/ZmccTodoList/ZmccTodoList.component';
import ZmccTimeManagement from './Tabs/ZmccTimeManagement/ZmccTimeManagement.component';
import $ from 'jquery';
import { useHistory } from "react-router-dom";

export const useZmccTransactionDetails = props => {
  const {
    getWholeSemTransaction,
    fetchLoansIntended,
    fetchZmccUser,
    fetchZmccPriorites,
    typesOfJobsList,
    fetchMasterData,
    user,
    zmccTransactionDetails,
    language,
    commentsData,
    damageLossReportData,
    changeLog,
    formValues,
    updateSEMForm,
    zmccInformationTabData,
    updateZmccTransaction,
    fetchOperatorsForTransactions,
    fetchZmccUsersForSubscriber,
    isClickedOnAdInTodoTab,
    fetchSampleAtList,
    location
  } = props;
  const queryParams = new URLSearchParams(location.search);
  const editValue = queryParams.get("edit") === "true";

  const [editing, setEditing] = useState(editValue);
  const [transactionType, setTransactionType] = useState('');
  const [formType, setFormType] = useState('');
  const [tabId, setTabId] = useState('');
  const [showAllButton, setShowAllButton] = useState(false);
  const [showModel, setShowModal] = useState(false);
  const transactionTypeName = props.match && props.match.path.replace('/:id', '').replace('/', '').replace('/:type', '');
  const history = useHistory();

  const connectedTransaction = get(zmccTransactionDetails, 'dataOfConnectedLoans');
  const transactionId = get(zmccTransactionDetails, 'transactions.transactionsId');
  const filteredConnectedTransaction = [];
  const {transactions} = zmccTransactionDetails
  const zmccStockId = get(zmccTransactionDetails, 'stockInformation.id');
  let messagesCount = 0;
  let changeHistoryCount = 0;
  const setType = () => {
    if (transactions) {
      if (transactions.zmccLmBooking) {
        setFormType('lm')
        setShowAllButton(true) ;
      } else if (transactions.zmccSemBooking) {
        setFormType('sem')
        setShowAllButton(true);
      }else if (transactions.zmccXbBooking) {
        setFormType('xb')
        setShowAllButton(true) ;
      }else if (transactions.zmccXrmBooking) {
        setFormType('xrm')
        setShowAllButton(true) ;
      }else{
        setFormType('');
        setShowAllButton(false) ;
      }
    }
  }
  if (commentsData.totalElements) {
    messagesCount += commentsData.totalElements;
  }
  if (damageLossReportData.totalElements) {
    messagesCount += damageLossReportData.totalElements;
  }

  if (changeLog.totalElements) {
    changeHistoryCount += changeLog.totalElements;
  }

  useEffect(() => {
    fetchLoansIntended();
    fetchZmccUser();
    getWholeSemTransaction(props.match.params.id);
    fetchZmccPriorites();
    fetchSampleAtList();
    if (!typesOfJobsList || !typesOfJobsList.length) {
      fetchMasterData(URLS.loansTypesOfJobs, FETCH_TYPES_OF_JOBS);
    }
    setTransactionType(transactionTypeName);
  },[props.match.params.id, language]);

  useEffect(() => {
    if(zmccStockId){
      fetchOperatorsForTransactions(zmccStockId);
      fetchZmccUsersForSubscriber(zmccStockId)
    }
  }, [zmccStockId])

  useEffect(() => {
    if (tabId === 2 && formType === ''&&  showModel) {
      $('#select-type').modal('show');
    }
  }, [tabId, showModel]);

  useEffect(() =>{
    setType();
  },[transactions])

  

  const onClickEdit = () => {
    setEditing((prevState) => {
      const newEditingState = !prevState;
      queryParams.set("edit", newEditingState);
      history.replace(`${location.pathname}?${queryParams.toString()}`);

      return newEditingState;
    });
  };

  const checkRoleAndPermission = () => {
    let allow = false;
    if (!allow && user && user.stockRoles && user.stockRoles.length) {
      let stockId = get(zmccTransactionDetails, 'stockInformation.id');
      if (stockId) {
        user.stockRoles.forEach(role => {
          if (
            (role.roleName.toLowerCase() === 'stock controller' || role.roleName.toLowerCase() === 'dispatcher' || role.roleName.toLowerCase() === "operator" || role.roleName.toLowerCase() === "reconditioning") &&
            stockId === role.stockId
          ) {
            allow = true;
          }
        });
      }
    }
    if (user && user.admin) {
      allow = true;
    }
    return allow;
  };

  const hasPermission = checkRoleAndPermission();

  const getTabId = (id) => {
    setTabId(id);
  }

  const createZmccTransactionTabs = [
    {
      id: 1,
      tabTitle: 'information',
      className: '',
      render: () => {
        return <Information editing={editing} onClickEdit={onClickEdit} hasPermission={hasPermission} formType={formType} tabId={1} showAllButton={showAllButton} transactionType={transactionType} />;
      },
    },
    {
      id: 2,
      tabTitle: 'demoRequestForm',
      className: '',
      render: () => {
        return <SemDemoRequestForm  props={props} formType={formType} editing={editing} onClickEdit={onClickEdit} hasPermission={hasPermission} tabId={2}/>;
      },
    },
    {
      id: 3,
      tabTitle: 'toDoList',
      className: '',
      render: () => <ZmccTodoList formValues={formValues} updateSEMForm={updateSEMForm} user={user} zmccInformationTabData={zmccInformationTabData} updateZmccTransaction={updateZmccTransaction} formType={formType} isClickedOnAdInTodoTab={isClickedOnAdInTodoTab} showAllButton={showAllButton}/>,
    },
    {
      id: 4,
      tabTitle: 'messages',
      count: messagesCount,
      className: '',
      render: () => <Messages match={props.match}/>,
    },
    {
      id: 5,
      tabTitle: 'timeManagement',
      className: '',
      render: () => <ZmccTimeManagement />,
    },

    {
      id: 6,
      tabTitle: 'changeHistory',
      count: changeHistoryCount,
      className: '',
      render: () => <ChangeHistory match={props.match}/>,
    },
    {
      id: 7,
      tabTitle: 'attachments',
      className: '',
      render: () => <ZmccAttachements formValues={formValues} updateSEMForm={updateSEMForm} zmccInformationTabData={zmccInformationTabData} updateZmccTransaction={updateZmccTransaction} formType={formType} showAllButton={showAllButton}/>,
    },
    
  ];

  return {
    createZmccTransactionTabs,
    onClickEdit,
    transactionType,
    filteredConnectedTransaction,
    hasPermission,
    transactionId,
    getTabId,
    setFormType,
    showAllButton,
    setShowModal,
    tabId,
    editing
  };
};