import Validators from 'library/utilities/Validators';

export const adjustDatesFormModel = type => {
  const array = [
    {
      dataTest: 'adjust-dates-target-delivery-from',
      dateFormat: 'DD.MM.YYYY',
      field: 'targetDeliveryFrom',
      label: 'shipped',
      placeholder: '',
      required: false,
      type: 'date',
      validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
      value: '',
    },
    {
      dateFormat: 'DD.MM.YYYY',
      dataTest: 'adjust-dates-targetReservationFrom',
      field: 'targetReservationFrom',
      label: 'receiptAtTheCustomer',
      placeholder: '',
      required: false,
      type: 'date',
      validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
      value: '',
    },
    {
      dateFormat: 'DD.MM.YYYY',
      dataTest: 'adjust-dates-targetReservationTo',
      field: 'targetReservationTo',
      label: 'returnDelivery',
      placeholder: '',
      required: false,
      type: 'date',
      validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
      value: '',
    },
    {
      dateFormat: 'DD.MM.YYYY',
      dataTest: 'adjust-dates-targetReturnDeliveryTo',
      field: 'targetReturnDeliveryTo',
      label: 'receiptInStock',
      placeholder: '',
      required: false,
      type: 'date',
      validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
      value: '',
    },
  ];
  if (type !== 'kit loan') {
    array.push({
      dateFormat: 'DD.MM.YYYY',
      dataTest: 'adjust-dates-targetReconditioningTo',
      field: 'targetCheckedDate',
      label: 'returnGoodsBooking',
      placeholder: '',
      required: false,
      type: 'date',
      validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
      value: '',
    });
  }
  if (type === 'kit loan') {
    array.push({
      dateFormat: 'DD.MM.YYYY',
      dataTest: 'adjust-dates-targetReconditioningTo',
      field: 'targetReconditioningTo',
      label: 'checked',
      placeholder: '',
      required: false,
      type: 'date',
      validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
      value: '',
    });
  }

  return array;
};
