import React from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';

import ModalRoot from '../ModalRoot';
import './ModalPortal.styles.scss';

const ModalPortal = ({ children, onClose, className }) => {
  return ReactDOM.createPortal(<div className='modalPortal'>
    <div className='modalOverlay' onClick={onClose}></div>
    <div className={cn('modalContent', className)}>{children}</div>
  </div>, ModalRoot);
}

export default ModalPortal;
