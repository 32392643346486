import React from 'react';

import Icon from 'library/common/commonComponents/Icon/Icon';
import strings from 'resources/locales/Translate';

const AddCardBlock = ({ handleClick }) => {
  return (
    <div className='add-card-block' onClick={handleClick}>
      <div>
        <Icon
          name='plus'
          width={24}
          height={24}
          fill='#0088d0'
        />
      </div>
      <span>{strings.addPurchaseRequest}</span>
    </div>
  );
}

export default AddCardBlock;
