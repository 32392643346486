import {
  FETCH_INITIAL_DATA_FOR_ADMINISTRATION_STATISTICS,
  FETCH_STOCK_BY_COUNTRY_FOR_DELAYED_TRANSACTION,
  FETCH_STOCK_BY_COUNTRY_FOR_DEMO_ACTIVITY,
  FETCH_STOCK_BY_COUNTRY_FOR_KIT_DATA,
  FETCH_SYSTEM_CLASS_FOR_DEMO_ACTIVITY,
  FETCH_SYSTEM_CLASS_FOR_KIT_DATA,
  FETCH_LOAN_REASONS_STATISTIC,
  FETCH_DEMOS_STATISTIC,
  FETCH_SATISFACTION_INDEX_STATISTIC,
  CLEAR_STATISTIC,
  FETCH_DEMOS_CHART_DATA,
  FETCH_LATE_RETURNS_TRANSACTIONS,
  CLEAR_LATE_RETURNS_TRANSACTIONS,
  FETCH_KIT_STATUS_CHART_STATISTICS,
  CLEAR_KIT_STATUS_CHART_STATISTICS,
  FETCH_KIT_DATA_STATISTICS,
  CLEAR_KIT_DATA_STATISTICS,
  CHANGE_KIT_STATUS_CHART_LOADER,
  CHANGE_KIT_DATA_LOADER,
  FETCH_UTILIZED_KITS,
  FETCH_WORKING_CAPITAL_IN_STOCK,
  CLEAR_WORKING_CAPITAL_IN_STOCK,
  NEW_REGISTRATIONS,
  DMA_REPORTING_DATA,
  SIX_MONTHS_TRAFFIC_DATA,
} from './Statistics.constants';

const initialState = {
  businessUnits: [],
  countries: [],
  stocksDelayedTransaction: [],
  stocksDemoActivity: [],
  stocksKitData: [],
  systemClassDemoActivity: {},
  systemClassKitData: {},
  loanReasonsStatistic: [],
  demosStatistic: {},
  demosChartData: [],
  satisfactionIndexStatistic: {},
  lateTransactionsList: {},
  kitStatusChartStatistics: [],
  kitDataStatistics: {},
  isKitStatusChartLoading: false,
  isKitDataLoading: false,
  utilizedKits: {},
  workingCapitalInStockData: {},
  newRegistrations: {},
  dmaReporting: {},
  sixMonthsTrafficData: [],
};

const StatisticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INITIAL_DATA_FOR_ADMINISTRATION_STATISTICS:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_STOCK_BY_COUNTRY_FOR_DELAYED_TRANSACTION:
      return {
        ...state,
        stocksDelayedTransaction: action.payload,
      };
    case FETCH_STOCK_BY_COUNTRY_FOR_DEMO_ACTIVITY:
      return {
        ...state,
        stocksDemoActivity: action.payload,
      };
    case FETCH_STOCK_BY_COUNTRY_FOR_KIT_DATA:
      return {
        ...state,
        stocksKitData: action.payload,
      };
    case FETCH_SYSTEM_CLASS_FOR_DEMO_ACTIVITY:
      return {
        ...state,
        systemClassDemoActivity: action.payload,
      };
    case FETCH_SYSTEM_CLASS_FOR_KIT_DATA:
      return {
        ...state,
        systemClassKitData: action.payload,
      };
    case FETCH_LOAN_REASONS_STATISTIC:
      return {
        ...state,
        loanReasonsStatistic: action.payload,
      };
    case FETCH_DEMOS_STATISTIC:
      return {
        ...state,
        demosStatistic: action.payload,
      };
    case FETCH_SATISFACTION_INDEX_STATISTIC:
      return {
        ...state,
        satisfactionIndexStatistic: action.payload,
      };
    case FETCH_DEMOS_CHART_DATA:
      return {
        ...state,
        demosChartData: action.payload,
      };
    case FETCH_LATE_RETURNS_TRANSACTIONS:
      return {
        ...state,
        lateTransactionsList: action.payload,
      };
    case CLEAR_LATE_RETURNS_TRANSACTIONS:
      return {
        ...state,
        lateTransactionsList: {},
      };
    case FETCH_KIT_STATUS_CHART_STATISTICS:
      return {
        ...state,
        kitStatusChartStatistics: action.payload,
      };
    case CLEAR_KIT_STATUS_CHART_STATISTICS:
      return {
        ...state,
        kitStatusChartStatistics: [],
        utilizedKits: {},
      };
    case FETCH_KIT_DATA_STATISTICS:
      return {
        ...state,
        kitDataStatistics: action.payload,
      };
    case CLEAR_KIT_DATA_STATISTICS:
      return {
        ...state,
        kitDataStatistics: {},
      };
    case CHANGE_KIT_STATUS_CHART_LOADER:
      return {
        ...state,
        isKitStatusChartLoading: action.payload,
      };
    case CHANGE_KIT_DATA_LOADER:
      return {
        ...state,
        isKitDataLoading: action.payload,
      };
    case FETCH_UTILIZED_KITS:
      return {
        ...state,
        utilizedKits: action.payload,
      };
    case FETCH_WORKING_CAPITAL_IN_STOCK:
      return {
        ...state,
        workingCapitalInStockData: action.payload,
      };
    case CLEAR_WORKING_CAPITAL_IN_STOCK:
      return {
        ...state,
        workingCapitalInStockData: {},
      };
    case CLEAR_STATISTIC:
      return {
        ...state,
        loanReasonsStatistic: [],
        demosStatistic: {},
        satisfactionIndexStatistic: {},
        demosChartData: [],
      };
      case DMA_REPORTING_DATA:
        return {
          ...state,
          dmaReporting: action.payload,
        };
      case SIX_MONTHS_TRAFFIC_DATA:
        return {
          ...state,
          sixMonthsTrafficData: action.payload,
        };
    default:
      return state;
  }
};

export default StatisticsReducer;
