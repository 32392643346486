import { connect } from 'react-redux';

import { getInitialData, getDataOnLanguageChange, fetchDmaReportingData, fetchSixMonthTrafficData } from './Statistics.actions';
import Statistics from './Statistics.component';

const mapStateToProps = ({ authReducer, languageReducer, appActionsReducer, statisticsReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
  appActions: appActionsReducer,
  dmaReporting: statisticsReducer.dmaReporting,
  sixMonthsTrafficData: statisticsReducer.sixMonthsTrafficData
});

export default connect(mapStateToProps, { getInitialData, getDataOnLanguageChange, fetchDmaReportingData, fetchSixMonthTrafficData })(Statistics);
