import { FETCH_REFURBISHMENT_FEES_COUNTRY_LIST } from './RefurbishmenFess.constants';

const initialState = {
  refurbishmentStockList: [],
};

const RefurbushmentFeesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REFURBISHMENT_FEES_COUNTRY_LIST:
      return {
        ...state,
        refurbishmentStockList: action.payload,
      };
    default:
      return state;
  }
};
export default RefurbushmentFeesReducer;
