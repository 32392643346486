import { connect } from 'react-redux';

import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';

import { fetchStockImages, removeStockImageFile, uploadStockImages } from './StockImages.actions';
import StockImages from './StockImages.component';

const mapStateToProps = ({ languageReducer, createStockReducer }) => ({
  language: languageReducer,
  canEditStock: createStockReducer.canEditStock,
  stockId: createStockReducer.stockDetails.id,
  crmAccountId: createStockReducer.crmAccountId,
  businessUnitFullValue: createStockReducer.stockDetails.businessUnitFullValue,
});

export default connect(mapStateToProps, {
  fetchStockImages,
  removeStockImageFile,
  toggleActionMessage,
  uploadStockImages,
})(StockImages);
