import React from 'react';
import strings from 'resources/locales/Translate';
import Table from 'library/common/commonComponents/Table/Table';
import Button from 'library/common/commonComponents/Button/Button';
import { useAddressConfigrationList } from './AddressConfigurationList.hook';
import AddAddressConfigrationModal from '../AddAddressConfigPopUp/AddAddressConfigPopUp.component';
import { connect } from 'react-redux';
import { addAddressconfigrationList, enableAndDisableConfig, deletingConfigration } from '../../MasterData.actions';

export const AddressConfigration = ({
  masterData,
  addAddressconfigrationList,
  enableAndDisableConfig,
  deletingConfigration,
  multipleAddressConfigurationList,
}) => {
  const { openAddAddressPopup, cols } = useAddressConfigrationList({
    masterData,
    addAddressconfigrationList,
    enableAndDisableConfig,
    deletingConfigration,
  });
  return (
    <div>
      <div className='container-fluid mb-3'>
        <div className='d-flex justify-content-between mb-3 mt-4'>
          <h4 className='mr-2'>{strings.addressConfig}</h4>

          <Button
            bgFill={false}
            iconName='plus'
            onClick={openAddAddressPopup}
            value={strings.add}
            styleClass='w-auto'
          />

          <AddAddressConfigrationModal id={'address-config-modal'} />
        </div>
        {multipleAddressConfigurationList && multipleAddressConfigurationList.length > 0 ? (
          <div>
            <Table cols={cols} rows={multipleAddressConfigurationList} />
          </div>
        ) : (
          <div className='d-flex justify-content-center'>
            <strong>{strings.noActiveSystemsAvailable}</strong>
          </div>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = ({ appActionsReducer, multipleAddressConfigurationList }) => ({
  appActions: appActionsReducer,
  multipleAddressConfigurationList: multipleAddressConfigurationList.multipleAddressConfigurationList,
});

export default connect(mapStateToProps, { addAddressconfigrationList, enableAndDisableConfig, deletingConfigration })(
  AddressConfigration,
);
