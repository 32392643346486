import React from 'react';
import cn from 'classnames';

import { getDateFormatForNotification } from 'library/utilities/getDateFormatForNotification';
import Button from 'library/common/commonComponents/Button/Button';

const NotificationCart = ({
  notification,
  handleClickOnNotification,
  handleDeleteNotification,
  handleChangeStatusOfNotification,
  isStatusLoading,
  isDeletLoading,
}) => {
  const iconName = notification.status === 'READ' ? 'unread' : 'read';
  const isDisabled = notification.status === 'READ';
  if(!notification.title){
    console.log("Notification title seems to be empty with type: ", notification.eventType, " id: ", notification.id)
  }
  return (
    <div
      className={cn('notification', { 'unread-notification': notification.status === 'UNREAD' })}
      onClick={() => handleClickOnNotification(notification)}
    >
      <div className='d-flex justify-content-between align-items-center'>
        <h6 className='notification-title'>{notification.title}</h6>
        <div className='notification-date'>{notification.date ? getDateFormatForNotification(notification.date) : null}</div>
        <div className='buttons-wrapper'>
          <Button
            iconName={iconName}
            iconWidth='22'
            iconHeight='22'
            viewBox={notification.status === 'READ' ? '0 0 20 20' : '0 0 20 14'}
            bgFill={false}
            isGhost={true}
            disabled={isDisabled}
            loading={isStatusLoading}
            onClick={(e) => handleChangeStatusOfNotification(e, notification.id)}
            styleClass='mr-3 eye'
          />
          <Button
            iconName='notificationFolder'
            iconWidth='20'
            iconHeight='14'
            viewBox='0 0 20 14'
            bgFill={false}
            isGhost={true}
            loading={isDeletLoading}
            onClick={(e) => handleDeleteNotification(e, notification.id)}
            styleClass='folder'
          />
        </div>
      </div>
    </div>
  );
};

export default NotificationCart;
