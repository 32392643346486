import { connect } from 'react-redux';

import ZMCCTransaction from './ZMCCTransaction.component'

const mapStateToProps = ({ languageReducer, bookingReducer, authReducer, createSEMFormReducer }) => ({
  user: authReducer.user,
  language: languageReducer.language,
  countries: bookingReducer.labsLocationCountries,
  zmccTransactionStatuses: bookingReducer.zmccTransactionStatuses,
  zmccTransactionList: bookingReducer.zmccTransactionList,
  zmccCountries: bookingReducer.zmccCountries,
  zmccCategory: bookingReducer.zmccCategory,
  zmccOperatorList: bookingReducer.zmccOperatorList,
  systemCategoriesList: createSEMFormReducer.systemCategoriesList,
});

export default connect(mapStateToProps)(ZMCCTransaction);