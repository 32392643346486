import React, { useState } from 'react';
import { editSchedule } from 'modules/Homepage/HomepageActions';
import { connect } from 'react-redux';
import * as $ from 'jquery';
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import strings from 'resources/locales/Translate';
import Modal from 'library/common/commonComponents/Modal';

export const RecurringEventOptionsPopup = ({
  id,
  payloadDataForEventPopup,
  editing,
  editSchedule,
  scheduledDetails,
  deleteSchedule,
  toggleActionMessage,
  setEditing,
}) => {
  const [selectedOption, setSelectedOption] = useState({
    allEvent: false,
    thisAndFollowingEvent: false,
    currentEvent: false,
  });

  const [loading, setLoading] = useState(false);

  const onCloseClick = () => {
    setSelectedOption({ allEvent: false, thisAndFollowingEvent: false, currentEvent: false });
    setEditing(false);
    $(`#${id}`).modal('hide');
  };

  const handleChange = type => {
    if (type === 'thisEvent') {
      setSelectedOption({ allEvent: false, thisAndFollowingEvent: false, currentEvent: true });
    } else if (type === 'thisAndFollowingEvents') {
      setSelectedOption({ allEvent: false, thisAndFollowingEvent: true, currentEvent: false });
    } else {
      setSelectedOption({ allEvent: true, thisAndFollowingEvent: false, currentEvent: false });
    }
  };

  const onClickOk = async () => {
    setLoading(true);
    if (editing === true) {
      let dataToSend = payloadDataForEventPopup;
      dataToSend.onlyCurrentEvent = selectedOption.currentEvent;
      dataToSend.currentAndFollowingEvents = selectedOption.thisAndFollowingEvent;
      dataToSend.allEvents = selectedOption.allEvent;
      const res = await editSchedule(dataToSend);
      setLoading(false);
      if (
        !res.success &&
        res.error &&
        res.error.response &&
        res.error.response.data &&
        res.error.response.data.status === 422
      ) {
        onCloseClick();
        toggleActionMessage(true, 'error', res.error.response.data.message);
      } else if (!res.success) {
        onCloseClick();
        toggleActionMessage(true, 'error', 'somethingWentWrongMessage');
      } else {
        onCloseClick();
        toggleActionMessage(true, 'success', 'slotCreatedSucessfully');
      }
    } else {
      let payload = {};
      payload.scheduledId = scheduledDetails.operatorsScheduleId;
      payload.accountId = scheduledDetails.operatorUser.accountId;
      payload.onlyCurrentEvent = selectedOption.currentEvent;
      payload.currentAndFollowingEvents = selectedOption.thisAndFollowingEvent;
      payload.allEvents = selectedOption.allEvent;
      const res = await deleteSchedule(payload, false);
      setLoading(false);
      if (
        !res.success &&
        res.error &&
        res.error.response &&
        res.error.response.data &&
        res.error.response.data.status === 422
      ) {
        onCloseClick();
        toggleActionMessage(true, 'error', res.error.response.data.message);
      } else if (!res.success) {
        onCloseClick();
        toggleActionMessage(true, 'error', 'somethingWentWrongMessage');
      } else {
        onCloseClick();
        toggleActionMessage(true, 'success', 'deleteScheduledEvent');
      }
    }
  };

  return (
    <Modal
      id={id}
      confirmAction={onClickOk}
      title={editing ? strings.UpdateRecurringEvent : strings.deleteRecurringEvent}
      disableBackgroundClose
      onCloseClick={onCloseClick}
      loading={loading}
      titleOfCancel={strings.cancel}
      confirmTitle={strings.save}
    >
      <div className=''>
        <div>
          <input
            type='radio'
            id='thisEvent'
            value='thisEvent'
            checked={selectedOption.currentEvent}
            onChange={() => handleChange('thisEvent')}
          />
          <label className='ml-2' htmlFor='thisEvent'>
            {strings.thisEvent}
          </label>
        </div>
        <div>
          <input
            type='radio'
            id='thisAndFollowingEvents'
            value='thisAndFollowingEvents'
            checked={selectedOption.thisAndFollowingEvent}
            onChange={() => handleChange('thisAndFollowingEvents')}
          />
          <label className='ml-2' htmlFor='thisAndFollowingEvents'>
            {strings.thisAndFollowingEvents}
          </label>
        </div>
        <div>
          <input
            type='radio'
            id='allEvents'
            value='allEvents'
            checked={selectedOption.allEvent}
            onChange={() => handleChange('allEvents')}
          />
          <label className='ml-2' htmlFor='allEvents'>
            {strings.allEvents}
          </label>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ authReducer, homepage, languageReducer, appActionsReducer }) => ({
  user: authReducer.user,
  scheduledEventsList: homepage.scheduledEventsList,
  appActions: appActionsReducer,
  language: languageReducer.language,
  scheduledDetails: homepage.scheduledDetails,
});

export default connect(mapStateToProps, { editSchedule, toggleActionMessage })(RecurringEventOptionsPopup);
