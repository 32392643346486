import { useEffect, useState, useCallback } from 'react';
import * as $ from 'jquery';
import { addAddressConfigModel } from './AddAddressConfigPopUp.constants';
import { validateForm } from 'library/utilities/ValidateForm.util';
import strings from 'resources/locales/Translate';

export const useAddressConfigration = ({ masterData, id, saveAddressConfigration }) => {
  const { businessUnit, countries } = masterData;
  const [model, setModel] = useState(addAddressConfigModel);
  const [configForm, setConfigForm] = useState({ formData: {} });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const newModel = [...model];
    if (businessUnit && businessUnit.length > 0) {
      newModel[0].options = businessUnit;
    }
    if (countries && countries.length > 0) {
      newModel[1].options = countries;
    }

    setModel(newModel);
  }, [businessUnit, countries]);

  const handleForm = ({ values, field }) => {
    setConfigForm({
      ...configForm,
      formData: {
        ...configForm.formData,
        ...values,
      },
    });
  };
  const onSaveClick = async () => {
    const validForm = validateForm({
      form: configForm,
      model,
    });

    if (!validForm.isFormValid) {
      return setConfigForm(validForm);
    }
    const { businessUnitId, countryId } = validForm.formData;
    const dataToSend = {
      countryId: countryId,
      buId: businessUnitId,
    };
    setLoading(true);
    const { success, err } = await saveAddressConfigration(dataToSend);
    if (!success) {
      if (err && err.response && err.response.data.status === 422) {
        setError(err.response.data.message);
      } else {
        setError(strings.somethingWentWrongMessage);
      }
      setLoading(false);
    } else {
      setLoading(false);
      onCloseClick();
    }
  };

  const onCloseClick = () => {
    setConfigForm({
      formData: {},
    });
    setError('');
    closePopup();
  };
  const closePopup = useCallback(() => {
    $(`#${id}`).modal('hide');
  }, []);
  return {
    model,
    handleForm,
    configForm,
    onCloseClick,
    onSaveClick,
    error,
    loading,
  };
};
