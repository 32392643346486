import React from 'react';
import toArray from 'lodash/toArray';

import strings from 'resources/locales/Translate';

import ItemsPerPage from 'library/common/commonComponents/ItemsPerPage/ItemsPerPage';
import ExpandableTable from 'library/common/commonComponents/Table/ExpandableTable';
import SearchField from 'library/common/commonComponents/SearchField/SearchField';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import Pagination from 'library/common/commonComponents/Pagination/Pagination';
import Button from 'library/common/commonComponents/Button/Button';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';

import { useShop } from '../../Shop.hook';
import { dataTable } from '../../dataTable';
import DataExpandableTable from '../../Components/DataExpandableTable';
import { singleItemsOptions, itemsPerPage  } from '../../Shop.constants';
import '../../Shop.styles.scss';
import Filter from '../../Components/Filter';
import ExportTable from '../../Components/ExportTable';
import NotifyPopup from '../../Components/NotifyPopup';
import CancelReservationPopup from '../../Components/CancelReservationPopup';
import OrderPopup from '../../Components/OrderPopup';
import SubscriptionsPopup from '../../Components/SubscriptionsPopup';
import { useSalesOffers } from './SalesOffers.hook';
import { shopListBreadcrumbData } from './SalesOffers.constants';

export default function SalesOffers({
  user,
  shopList,
  countries,
  categoriesAndSystems,
  fetchMasterData,
  appActions,
  fetchShopListData,
  exportShopList,
  createSubscription,
  subscriptions,
  removeSubscription,
  addKitToObservationList,
  removeKitFromObservationList,
  reserveKit,
  cancelKitReservation,
  orderKit,
  history,
  language,
  fetchMarketplaceCountryList,
  marketplaceCountry,
}) {
  document.title = 'Shop';
  const { isLoading, displayActionMessage, type, message } = appActions;
  const {
    isShowFilter,
    data,
    handleSearchChange,
    showOrHideFilter,
    handleFilterChange,
    resetFilters,
    handleSort,
    handleItemsPerPage,
    handlePageChange,
    systemsClass,
  } = useShop({
    fetchMasterData,
    countries,
    categoriesAndSystems,
    language,
    fetchMarketplaceCountryList,
    marketplaceCountry,
  });
  const {
    openExportShopList,
    openNotifyPopup,
    openOrderPopup,
    openSubscriptionsPopup,
    goToCreateKitManually,
    goToCreateSingleItem,
    handleClickOnObserve,
    handleClickOnStopObserve,
    handleClickOnReserveKit,
    handleClickOnCancelReservation,
    openCancelReservatioPopup,
    closeCancelReservatioPopup,
    updateShopList,
    isShowShopList,
    isConfirmLoading,
    errorReservation,
    selectedKitId,
    isReservedKit,
  } = useSalesOffers({
    data,
    shopList,
    fetchShopListData,
    fetchMasterData,
    addKitToObservationList,
    removeKitFromObservationList,
    reserveKit,
    cancelKitReservation,
    history,
  });

  return (
    <div>
      {displayActionMessage && <ActionMessage type={type} message={strings[message]} />}
      <div className='container-fluid mb-3'>
        <Breadcrumbs data={shopListBreadcrumbData} />
        <div className='row no-gutters align-items-center'>
          <h1 className='col-md col-12 kit-title'>{strings.salesOffers}</h1>
          <div className='col-md-auto col mr-3'>
            <div className='input-wrapper'>
              <SearchField onChange={handleSearchChange} initialValue={data.filter.searchTerm} />
            </div>
          </div>
          <div className='col-auto'>
            <Button
              dataTest='shop-list-filter-button'
              bgFill={false}
              iconName='filter'
              onClick={showOrHideFilter}
              value={isShowFilter ? strings.hideFilter : strings.showFilter}
              styleClass='filter-button'
            />
          </div>
        </div>
      </div>
      <Filter
        isShowFilter={isShowFilter}
        countries={countries}
        categoriesAndSystems={systemsClass}
        handleFilterChange={handleFilterChange}
        selected={data.filter}
        resetFilters={resetFilters}
      />
      <div className='shop-table'>
        <div className='container-fluid shop-container mb-3'>
          <div className='d-flex justify-content-between align-items-center mb-3'>
            <div className='title'>{isShowShopList ? shopList.totalElements : 0} {strings.results}</div>
            <div>
              {subscriptions && subscriptions.length > 0 && (
                <span onClick={openSubscriptionsPopup} className='button-text mr-3'>{strings.mySubscriptions}</span>
              )}
              <span onClick={openNotifyPopup} className='button-text'>{strings.notifyMe}</span>
            </div>
          </div>
          {isShowShopList ? (
            <ExpandableTable
              user={user}
              cols={dataTable()}
              rows={shopList.content}
              sortFields={data.sort}
              handleSort={handleSort}
            >
              <DataExpandableTable
                handleClickOnObserve={handleClickOnObserve}
                handleClickOnStopObserve={handleClickOnStopObserve}
                handleClickOnReserveKit={handleClickOnReserveKit}
                handleClickOnCancelReservation={openCancelReservatioPopup}
                openOrderPopup={openOrderPopup}
              />
              </ExpandableTable>
          ) : (
            <div className='d-flex justify-content-center mb-3'>
              <strong>{strings.noDataFoundText}</strong>
            </div>
          )}
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row no-gutters'>
          <div className='col-md-auto mb-3'>
            <Button
              dataTest='create-kit-manually-button'
              bgFill={false}
              iconName='plus'
              value={strings.createKit}
              onClick={goToCreateKitManually}
            />
          </div>
          <div className='col-md-auto pl-md-3 mb-3'>
            <Button
              dataTest='create-single-item-button'
              bgFill={false}
              iconName='plus'
              value={strings.createSingleItem}
              onClick={goToCreateSingleItem}
            />
          </div>
          <div className='col-md-auto pl-md-3 mb-3'>
            <Button
              dataTest='export-list-button'
              bgFill={false}
              iconName='download'
              value={strings.exportList}
              styleClass='mt-0'
              onClick={openExportShopList}
            />
          </div>
          {isShowShopList ? (
            <div className='col d-flex justify-content-end mb-3'>
              <ItemsPerPage
                itemsPerPage={toArray(itemsPerPage)}
                handleItemsPerPage={handleItemsPerPage}
                value={itemsPerPage[data.size]}
              />
              <Pagination
                currentPage={data.page - 1}
                totalPages={shopList.totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          ) : null}
        </div>
      </div>
      {isLoading && <div className='loader' />}
      <ExportTable
        id='export-shop-list'
        countries={countries}
        categoriesAndSystems={systemsClass}
        singleItemsOptions={singleItemsOptions()}
        filters={data.filter}
        exportShopList={exportShopList}
        marketplaceCountry={marketplaceCountry}
      />
      <NotifyPopup
        id='notify-me-popup'
        countries={countries}
        categoriesAndSystems={systemsClass}
        subscriptions={subscriptions}
        createSubscription={createSubscription}
      />
      <SubscriptionsPopup
        id='subscriptions-popup'
        subscriptions={subscriptions}
        removeSubscription={removeSubscription}
      />
      <CancelReservationPopup
        id='sales-offers-cancel-reservation-popup'
        isLoading={isConfirmLoading}
        handleConfirmation={handleClickOnCancelReservation}
        closePopup={closeCancelReservatioPopup}
        error={errorReservation}
      />
      <OrderPopup
        id='sales-offers-order-popup'
        handleClickOnConfirmation={orderKit}
        handleClickOnReserve={handleClickOnReserveKit}
        updateList={updateShopList}
        kitId={selectedKitId}
        isReservedKit={isReservedKit}
      />
    </div>
  );
}
