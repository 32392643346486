import strings from 'resources/locales/Translate';

export const itemsPerPage = {
    10: { id: 10, pages: 10 },
    20: { id: 20, pages: 20 },
    50: { id: 50, pages: 50 },
};

export const sapErpMappingBreadcrumbData = [
    {
        name: 'homepage',
        url: '/home'
    },
    {
        name: 'SAP ERP Mapping Table',

    },
];


export const GET_SAP_ERP_MAPPING_LIST = 'GET_SAP_ERP_MAPPING_LIST';