import React from 'react';

import strings from 'resources/locales/Translate';
import Uploader from 'library/common/commonComponents/Uploader/Uploader';
import Button from 'library/common/commonComponents/Button/Button';

import './KitImages.styles.scss';

const KitImages = ({ kitImages, deletePendingId, handleUploadImage, handleDeleteImage }) => {
  const hasShowUploader = kitImages.length < 3;

  return (
    <div className='d-flex flex-wrap'>
      {kitImages.map(({kitGenericDevicePictureId: id, preview}) => (
        <div className='img-wrapper' key={id}>
          <img
            src={preview}
            width='256'
            height='256'
            alt='...'
          />
          <Button
            bgFill={false}
            iconName='delete'
            onClick={() => handleDeleteImage(id)}
            value={strings.delete}
            styleClass='img-button'
            disabled={deletePendingId}
            loading={deletePendingId === id}
          />
        </div>
      ))}
      {hasShowUploader && (
        <Uploader
          allFiles={[]}
          mimeType='image/*'
          maxFiles={1}
          onFileAdd={handleUploadImage}
          errorMessage={strings.allowedImgFormats}
          text={strings.addImage}
          iconStyle='icon'
          uploaderStyle='uploader-wrapper'
          isMultiple={false}
        />
      )}
    </div>
  );
};

export default KitImages;
