import React from 'react';
import { Pie } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

const PieChart = ({ data, hideLegend, additionalOptions }) => {
  const options = {
    ...additionalOptions,
    elements: {
      arc: {
        borderWidth: 0
      }
    },
    layout: {
      padding: {
        left: 50,
        right: 50,
        top: 50,
        bottom: 50,
      }
  },
    legend: {
      display: !hideLegend,
      position: 'right',
      align: 'start',
      labels: {
        boxWidth: 16,
        boxHeight: 16,
        padding: 20,
        fontSize: 16,
        position: 'outside',
				textMargin: 10,
      },
      maxHeight: 100,
    },
    tooltips: {
      mode: 'label',
      enabled: true,
      callbacks: {
        label: (tooltipItem, data) => {
          const value = data.datasets[0].data[tooltipItem.index];
          return `${value}% - ${data.labels[tooltipItem.index]}`;
        },
      },
    },
    plugins: {
      datalabels: {
        color: function(context) {
          return context.dataset.backgroundColor;
        },
        font: function(context) {
          var w = context.chart.width;
          return {
            size: w < 512 ? 10 : 12,
          };
        },
        formatter: function(value, context) {
          if (value === 0.0 || value < 1.5) return null;
          return `${context.chart.data.datasets[0].data[context.dataIndex]}%`;
        }
      },
      beforeDraw: function(c) {
        var chartHeight = c.chart.height;
        c.scales['y-axis-0'].options.ticks.fontSize = chartHeight * 6 / 100;
     }
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return <Pie data={data} options={options} />;
};

Pie.defaultProps = {
  hideLegend: false,
};

export default PieChart;
