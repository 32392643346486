import React, { useState } from 'react';
import toArray from 'lodash/toArray';

import strings from 'resources/locales/Translate';
import ItemsPerPage from 'library/common/commonComponents/ItemsPerPage/ItemsPerPage';
import Pagination from 'library/common/commonComponents/Pagination/Pagination';

import DataExpandableTable from '../DataExpandableTable';
import { itemsPerPage } from '../../BookingConstants';
import LLFilters from '../LLFilters';
import { useLLTransactions } from './LLTransactions.hook'
import ExpandableTable from 'library/common/commonComponents/Table/AppExpandableTable';

const LLTransactions = props => {
  const {
    labsLocationTransactionsList,
    isShowFilter,
    countries,
    language,
    labsLocationSystemClasses,
    handleFilterChange,
    resetFilters,
    data,
    handleSort,
    saveCancellationRequest,
    user,
    history,
    labsLocationTransactionStatuses,
    handleItemsPerPage,
    handlePageChange,
    match,
  } = props;
  const { cols, hasTransactionList } = useLLTransactions({ labsLocationTransactionsList });
  const [rejectCancelRequest, setRejectCancelRequest] = useState(false);
  const isDlaOrLab = props.typeTransactions;
  return (
    <>
      <LLFilters
        isShowFilter={isShowFilter}
        countries={countries}
        language={language}
        categoriesAndSystems={labsLocationSystemClasses}
        selected={data.filter}
        transactionStatuses={labsLocationTransactionStatuses}
        handleFilterChange={handleFilterChange}
        resetFilters={resetFilters}
      />
      <div className='booking-table'>
        {hasTransactionList ? (
          <div className='container-fluid mb-3'>
             <ExpandableTable
              cols={cols}
              rows={labsLocationTransactionsList.content}
              sortFields={data.sort}
              handleSort={handleSort}
              user={user}
            >
              <DataExpandableTable 
                data={data}
                history={history}
                saveCancellationRequest={saveCancellationRequest}
                match={match}
                setRejectCancelRequest={setRejectCancelRequest}
                rejectCancelRequest={rejectCancelRequest}
                isDlaOrLab={isDlaOrLab}
              />
            </ExpandableTable>
          </div>
        ) : (
          <div className='d-flex justify-content-center'>
            <strong>{strings.noDataFoundText}</strong>
          </div>
        )}
      </div>
      <div className='container-fluid'>
        <div className='row no-gutters'>
          {hasTransactionList && (
            <div className='col d-flex justify-content-center mb-3'>
              <ItemsPerPage
                itemsPerPage={toArray(itemsPerPage)}
                handleItemsPerPage={handleItemsPerPage}
                value={itemsPerPage[data.size]}
              />
              <Pagination
                currentPage={data.page - 1}
                totalPages={labsLocationTransactionsList.totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LLTransactions;
