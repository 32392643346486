import React from 'react';
import cn from 'classnames';

import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import strings from 'resources/locales/Translate';

const Filters = ({ isShowFilter, filters, businessUnits, countries, users=[], handleFilter }) => {

  return (
    <div className={cn('filter-wrapper', { 'd-block': isShowFilter, 'd-none': !isShowFilter })}>
      <div className='container-fluid'>
        <div className='row'>
          <Dropdown
            label={strings.businessUnit}
            data={businessUnits}
            field='businessUnit'
            idKey='id'
            displayValue={[{ key: 'name', separator: '' }]}
            isRequired={true}
            placeholder={strings.pleaseSelect + '...'}
            onChange={handleFilter}
            mainContainerStyle='col-lg-4 mt-3 mt-md-0'
            value={filters.businessUnit}
            fullValue={filters.businessUnitFullValue}
          />
          <Dropdown
            label={strings.countryLand}
            data={countries}
            filter
            field='country'
            idKey='id'
            displayValue={[{ key: 'name', separator: '' }]}
            isRequired={false}
            placeholder={strings.pleaseSelect + '...'}
            onChange={handleFilter}
            mainContainerStyle='col-lg-4 mt-3 mt-md-0'
            value={filters.country}
            fullValue={filters.countryFullValue}
          />
          <Dropdown
            label={strings.users}
            data={users}
            idKey='accountId'
            filter
            field='user'
            displayValue={[
              {key: 'lastName', separator: ', '},
              {key: 'firstName', separator: ' ('},
              {key: 'email', separator: ')'}
            ]}
            isRequired={true}
            placeholder={strings.pleaseSelect + '...'}
            onChange={handleFilter}
            mainContainerStyle='col-lg-4 mt-3 mt-md-0'
            value={filters.user}
            fullValue={filters.userFullValue}
          />
        </div>
      </div>
    </div>
  );
}

export default Filters;
