import React, {useState, useEffect} from 'react';
import ZMCCTabs from '../../ZMCCTabs.component';
import { createSemTabsConstant, createXbTabsConstants, createXRMTabsConstants, createLmTabsConstants, noTypeTab } from './tabsData';
import Button from 'library/common/commonComponents/Button/Button';
import strings from 'resources/locales/Translate';
import { useAssignment } from './Assignment.hook';
import AssignmentCancelPopup from "./AssignmentCancelPopup.component";
import { RouterCustomPrompt } from '../../RouterPrompt.component';
import * as $ from 'jquery';

const Assignment = props => {
  const {
    saveZmccSemData,
    onContinueBooking,
    onCancelClick,
    cancelSemZmccLoanData,
    isLoading,
    isXbBooking,
    isSemBooking,
    isXRMBooking,
    isLMBooking,
    zmccXrmBooking,
    zmccLmBooking,
    zmccXbBooking,
    zmccSemBooking,
    tabId,
    formType,
    unsavedChanges,
    hasAccess
  } = props;
  
   const { currentTab, goToPreviousTab, goToNextTab, getTabId } = useAssignment({ ...props });
   useEffect(() => {
     const handleBeforeUnload = (event) => {
       if (unsavedChanges) {
         event.preventDefault();
         event.returnValue = 'Changes you made may not be saved';
       }
     };
     window.addEventListener('beforeunload', handleBeforeUnload);
     return () => {
       window.removeEventListener('beforeunload', handleBeforeUnload);
     };

   }, [unsavedChanges]);
   const zmmctransactionDeatilesSave = () => {  
    const {formValue} = props
      if (formValue.sampleDeatilsFrom.formData.completedByName && zmccSemBooking) {
        props.setEditCompltedBy(false);
      } 
      if (formValue.xbSampleDeatilsFrom.formData.completedByNameXb && zmccXbBooking) {
        props.setEditCompltedBy(false);
      }
    props.updateZmccTransaction(zmccLmBooking ? 'LM' : zmccXbBooking ? 'XB' : zmccXrmBooking ? 'XRM' : 'SEM')
   }
   return (
     <>
       {formType !== '' &&
        <ZMCCTabs
         tabs={
           (isXbBooking || zmccXbBooking)
             ? createXbTabsConstants
             : (isXRMBooking || zmccXrmBooking)
             ? createXRMTabsConstants
             : (isLMBooking || zmccLmBooking)
             ? createLmTabsConstants :
             (isSemBooking ||zmccSemBooking) ? createSemTabsConstant: noTypeTab
         }
         currentTab={currentTab}
         getTabId={id => getTabId(id)}
         {...props}
         isXbBooking={isXbBooking}
         isSemBooking={isSemBooking}
         isXRMBooking={isXRMBooking}
         isLMBooking={isLMBooking}
         zmccLmBooking={zmccLmBooking}
         zmccSemBooking={zmccSemBooking}
         zmccXbBooking={zmccXbBooking}
         zmccXrmBooking={zmccXrmBooking}
         saveZmccSemData={saveZmccSemData}
       />}
       {formType !== '' &&<div className='save-cancel-next-button-adjust mt-2'>
        <div className='save-next-button-alignment'>
        {currentTab !== 1 && (
           <Button
             value={strings.back}
             onClick={goToPreviousTab}
             bgFill={false}
             styleClass='add-button ml-2 save-next-pev-button' 
           />
         )}
         <Button
           value={strings.save}
           onClick={() =>
             props.isZmcc ? zmmctransactionDeatilesSave() : saveZmccSemData('save', currentTab)
           }
           bgFill={true}
           styleClass='add-button ml-2 save-next-pev-button'
           disabled={!hasAccess}
         />
         {props.isZmcc && currentTab !== 7 && (
           <Button
             value={strings.next}
             onClick={goToNextTab}
             bgFill={false}
             styleClass='add-button ml-2 save-next-pev-button'
           />
         )}
         {!props.isZmcc && (
           <Button
             value={strings.next}
             onClick={goToNextTab}
             bgFill={false}
             styleClass='add-button ml-2 save-next-pev-button'
           />
         )}
        </div>
        {!props.isZmcc &&<div>
         <Button
           value={strings.cancel}
           onClick={() => cancelSemZmccLoanData()}
           bgFill={false}
           styleClass='add-button save-next-pev-button'
         />
       </div>}
       </div>}
       
       <AssignmentCancelPopup
         id='cancel-zmcc-loan-data-modal'
         onCancelClick={onCancelClick}
         onContinueBooking={onContinueBooking}
         isLoading={isLoading}
       />

        <RouterCustomPrompt
          id='leave-zmcc-form'
          when={unsavedChanges}
          cancelText={strings.cancel}
          okText={strings.leave}
          onOK={() => true}
          onCancel={() => false}
        />
     </>
   ); 

}

export default Assignment;