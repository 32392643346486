import { GET_PERMISSIONS_LIST } from './Permissions.constants';

const initialState = {
  permissionsList: [],
};

const PermissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PERMISSIONS_LIST:
      return {
        ...state,
        permissionsList: action.payload
      };
    default:
      return state;
  }
};

export default PermissionsReducer;
