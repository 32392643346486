import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setLanguage } from 'library/common/commonActions/LanguageActions';
import { logoutUser } from 'library/common/commonActions/AuthActions';
import { removeItemFromCart } from 'modules/LoanList/LoanListActions';
import { openOrHideNotificationsBar } from 'library/common/commonActions/NotificationsActions';
import { getListOfUnexecutedSemTransaction, removeLoanFromCart } from 'modules/ZmccRequest/SEMBooking/SemBooking.actions';
import { fetchCartDataFromLocalStorage } from 'modules/LoanList/LoanListActions';
import { getAllScheduledEventList, checkIsOperator } from 'modules/Homepage/HomepageActions';
import FullScreenMenu from './FullScreenMenu.component';

const mapStateToProps = ({
  languageReducer,
  authReducer,
  loanList,
  appActionsReducer,
  notificationsReducer,
  refurbushmentFeesReducer,
  homepage,
  createSEMFormReducer
}) => ({
  language: languageReducer,
  authentication: authReducer,
  loanList: loanList,
  isLoading: appActionsReducer.isLoading,
  notifications: notificationsReducer.notifications,
  refurbishmentStockList: refurbushmentFeesReducer.refurbishmentStockList,
  loansSubMenu: homepage.loansSubMenu,
  allUnexecutedtransactions: createSEMFormReducer.allUnexecutedLoans,
  isOperatorUser: homepage.isOperatorUser,
});

export default withRouter(connect(mapStateToProps, {
  setLanguage,
  logoutUser,
  removeItemFromCart,
  openOrHideNotificationsBar,
  getListOfUnexecutedSemTransaction,
  removeLoanFromCart,
  fetchCartDataFromLocalStorage,
  getAllScheduledEventList,
  checkIsOperator,
})(FullScreenMenu));
