import {
  FETCH_CONTINENTS_LIST,
  FETCH_CALCULATION_LIST,
  CLEAR_CALCULATION_LIST,
  FETCH_STOCKS,
  FETCH_ACCOUNT_STATEMENT_LIST,
  FETCH_USER_LIST,
  FETCH_KPI,
  FETCH_MAIN_UTILIZED_MAIN_COMPONENTS,
  FETCH_TYPES_OF_JOBS,
  CLEAR_KPI_TABLES,
  FETCH_CONTROLLING_REPORT_LIST,
  FETCH_SYSTEMCLASSIFICATION_LIST,
  FETCH_SYSTEMMAINCOMPONENT_LIST,
} from './LLManagement.constants';

const initialState = {
  continentsList: [],
  calculationList: [],
  stocks: [],
  accountStatementList: {},
  userList: [],
  kpi: [],
  mainUtilizedMainComponents: [],
  typesOfJobs: [],
  controllingReport: {},
  systemClassificationList: [],
  systemMainComponentList: {},
};

const LLManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONTINENTS_LIST:
      return {
        ...state,
        continentsList: action.payload,
      };
    case FETCH_CALCULATION_LIST:
      return {
        ...state,
        calculationList: action.payload,
      };
    case CLEAR_CALCULATION_LIST:
      return {
        stocks: [],
        continentsList: [],
        calculationList: [],
        accountStatementList: {},
        userList: [],
        kpi: [],
        mainUtilizedMainComponents: [],
        typesOfJobs: [],
      };
    case FETCH_STOCKS:
      return {
        ...state,
        stocks: action.payload,
      };
    case FETCH_ACCOUNT_STATEMENT_LIST:
      return {
        ...state,
        accountStatementList: action.payload,
      };
    case FETCH_USER_LIST:
      return {
        ...state,
        userList: action.payload,
      };
    case FETCH_KPI:
      return {
        ...state,
        kpi: action.payload,
      };
    case FETCH_MAIN_UTILIZED_MAIN_COMPONENTS:
      return {
        ...state,
        mainUtilizedMainComponents: action.payload,
      };
    case FETCH_TYPES_OF_JOBS:
      return {
        ...state,
        typesOfJobs: action.payload,
      };
    case CLEAR_KPI_TABLES:
      return {
        ...state,
        kpi: [],
        mainUtilizedMainComponents: [],
        typesOfJobs: [],
      };
    case FETCH_CONTROLLING_REPORT_LIST:
      return {
        ...state,
        controllingReport: action.payload,
      };
    case FETCH_SYSTEMCLASSIFICATION_LIST:
      return {
        ...state,
        systemClassificationList: action.payload,
      };
    case FETCH_SYSTEMMAINCOMPONENT_LIST:
      return {
        ...state,
        systemMainComponentList: action.payload,
      };
    default:
      return state;
  }
};

export default LLManagementReducer;
