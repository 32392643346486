import React from 'react';

import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import Button from 'library/common/commonComponents/Button/Button';
import Tabs from 'library/common/commonComponents/Tabs/Tabs';
import strings from 'resources/locales/Translate';

import StatisticsTab from './Components/StatisticsTab';
import StockDashboardFilter from './Components/StockDashboardFilter';
import MonthBlock from './Components/MonthBlock';
import FullScreen from './Components/FullScreen';
import { stockDashboardBreadcrumbData } from './StockDashboard.constants';
import { useStockDashboardHook } from './StockDashboard.hook';
import './stockDashboard.styles.scss';

const  EMPTY_ARR = [];

const StockDashboard = ({ getInitialData, getStatisticsForThisMonth, fetchCountries, language }) => {
  const {
    isShowFilter,
    filters,
    activeTabName,
    counter,
    updateActiveTabName,
    setFilters,
    showOrHideFilter,
    isOpenFullScreen,
    onOpenFullScree,
    onCloseFullScreen,
  } = useStockDashboardHook({ getInitialData, getStatisticsForThisMonth, fetchCountries, language });

  const tabs = [
    {
      id: 1,
      tabTitle: 'todayText',
      render: isActive => {
        return (
          <StatisticsTab
            isActiveTab={isActive}
            counter={counter}
            country={filters.country}
            businessGroup={filters.businessGroup}
            stockArr={filters.stock || EMPTY_ARR}
            period='day'
          />
        );
      },
    },
    {
      id: 2,
      tabTitle: 'thisWeek',
      render: isActive => {
        return (
          <StatisticsTab
            isActiveTab={isActive}
            counter={counter}
            country={filters.country}
            businessGroup={filters.businessGroup}
            stockArr={filters.stock || EMPTY_ARR}
            period='week'
          />
        );
      },
    },
  ];

  return (
    <div className='stock-dashboard-container'>
      <div className='container-fluid mb-3'>
        <Breadcrumbs data={stockDashboardBreadcrumbData} />
        <div className='d-flex justify-content-between'>
          <div className='d-flex'>
            <h1 className='kit-title mr-3'>{strings.stockDashboard}</h1>
            <Button
              dataTest='stock-dashboard-filter-button'
              bgFill={false}
              iconName='fullScreen'
              iconWidth={18}
              iconHeight={18}
              viewBox='0 0 18 18'
              onClick={onOpenFullScree}
              value={strings.fullScreen}
              styleClass='w-auto'
            />
          </div>
          <Button
            dataTest='stock-dashboard-filter-button'
            bgFill={false}
            iconName='filter'
            onClick={showOrHideFilter}
            value={isShowFilter ? strings.hideFilter : strings.showFilter}
            styleClass='w-auto'
          />
          </div>
      </div>
      <StockDashboardFilter
        isShowFilter={isShowFilter}
        filters={filters}
        setFilters={setFilters}
      />
      <MonthBlock />
      <Tabs tabs={tabs} currentTab={activeTabName} saveCurrentTab={updateActiveTabName} />
      <FullScreen
        isOpenFullScreen={isOpenFullScreen}
        onCloseFullScreen={onCloseFullScreen}
        tabs={tabs}
        activeTabName={activeTabName}
        updateActiveTabName={updateActiveTabName}
      />
    </div>
  );
}

export default StockDashboard;
