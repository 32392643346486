import * as actionTypes from "../commonConstants/storeConstants";

export const changeLoader = (value) => dispatch => {
  dispatch({
    type: actionTypes.CHANGE_LOADER,
    payload: value
  });
};


export const toggleActionMessage = (display, type = null, message = null, showAsIs = false) => (dispatch, getState) => {
  const randomValue = Math.floor(Math.random() * 10000000);
  dispatch({
    type: actionTypes.DISPLAY_ACTION_MESSAGE,
    payload: {
      displayActionMessage: display,
      type: type,
      message: message,
      showAsIs: showAsIs,
      randomValue, 
    }
  });
  // to autoclose message after 15 seconds
  if (display) {
    setTimeout(() => {
      const appAction  = getState().appActionsReducer;
      if (appAction.randomValue === randomValue) {
        dispatch({
          type: actionTypes.DISPLAY_ACTION_MESSAGE,
          payload: {
            displayActionMessage: false,
            type: null,
            message: null,
            showAsIs: false,
            randomValue: null,
          }
        });
      }
    }, 1000 * 15);
  }
};
