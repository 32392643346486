import { useState } from "react"
import { demoCheckListModel } from "./DemoCheckListPopup.constants";
import Validators from "library/utilities/Validators";
import { validateForm } from 'library/utilities/ValidateForm.util';
import $ from 'jquery';

export const useDemoCheckListPopup = ({id,user, usersList, saveMaintenanceNewCheckListForm, transactionId, maintenanceCheckListData, saveMantenanceCheckListData}) =>{
  const {maintenanceChecklistId} = maintenanceCheckListData
    const [demoForm, setDemoForm] = useState({formData: {}});
    const [npsValue, setNpsValue] = useState('');
    const [loading, setLoading] = useState(false);

    const demoFormModel = () => {
      const model = [...demoCheckListModel];
      if (demoForm.formData.demoAbnormalities === 'yes') {
        model[1].validators = [{ check: Validators.required, message: 'requiredErrorMessage' }];
        model[1].isDisplayed = false;
        model[1].required = true;
      } else {
        model[1].validators = [];
        model[1].isDisplayed = true;
        model[1].required = false;
      }
      if (demoForm.formData.demoBackupOfTempCustFiles === 'yes') {
        model[3].validators = [{ check: Validators.required, message: 'requiredErrorMessage' }];
        model[3].isDisplayed = false;
        model[3].required = true;
      } else {
        model[3].validators = [];
        model[3].isDisplayed = true;
        model[3].required = false;
      }

      if (demoForm.formData.systemIsDemoReady === "NO") {
        model[5].validators = [{ check: Validators.required, message: "requiredErrorMessage" }];
        model[5].required = true;
      } else {
        model[5].validators = [];
        model[5].required = false;
      }
      return model;
    };

    const handleForm = ({ values, field, formName }) => {
      setDemoForm({
        ...demoForm,
        formData: {
          ...demoForm.formData,
          ...values,
        },
      });
    };

    const onChangeNps = value => {
      setNpsValue(value);
    };

    const onSaveClick = async () => {
      const validForm = validateForm({
        form: demoForm,
        model: demoCheckListModel,
      });
      if (!validForm.isFormValid) {
        return setDemoForm(validForm);
      }

      const {
        demoAbnormalities,
        demoAbnormalitiesComment,
        demoIssues,
        demoIssuesComment,
        demoBackupOfTempCustFiles,
        demoBackupOfTempCustFilesComment,
        systemIsDemoReady,
        systemIsDemoReadyComment,
      } = validForm.formData;

      const dataToSend = {
        demoAbnormalities: demoAbnormalities === 'yes' ? true : false,
        demoAbnormalitiesComment: demoAbnormalitiesComment,
        demoIssues: demoIssues === 'yes' ? true : false,
        demoIssuesComment: demoIssuesComment,
        demoBackupOfTempCustFiles: demoBackupOfTempCustFiles === 'yes' ? true : false,
        demoBackupOfTempCustFilesComment,
        systemIsDemoReady: systemIsDemoReady,
        systemIsDemoReadyComment: systemIsDemoReadyComment,
        netPromoterScore: npsValue,
      };
      setLoading(true);
      const res = await maintenanceChecklistId
        ? saveMantenanceCheckListData(dataToSend, 'demo', maintenanceChecklistId, transactionId)
        : saveMaintenanceNewCheckListForm(dataToSend, transactionId, 'demo');

      setLoading(false);
      closeClick();
    };

    const closeClick = () => {
      $(`#${id}`).modal('hide');
    };

    return {
      demoForm,
      demoFormModel,
      handleForm,
      onChangeNps,
      npsValue,
      onSaveClick,
      closeClick,
      loading,
    };
}