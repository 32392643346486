import React from 'react';
import cn from 'classnames';

import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import strings from 'resources/locales/Translate';

const KitPicturesFilter = ({
  isShowFilter,
  filters = {},
  setFilters,
  businessUnits,
  systemClasses,
  handleFilter,
}) => {

  return (
    <div className={cn('filter-wrapper', { 'd-block': isShowFilter, 'd-none': !isShowFilter })}>
      <div className='container-fluid'>
        <div className='row'>
          <Dropdown
            label={strings.businessUnit}
            data={businessUnits}
            field='businessUnit'
            idKey='id'
            displayValue={[{ key: 'name', separator: '' }]}
            isRequired={true}
            placeholder={strings.pleaseSelect + '...'}
            onChange={handleFilter}
            mainContainerStyle='col-lg-6'
            value={filters.businessUnit}
            fullValue={filters.businessUnitFullValue}
          />
           <Dropdown
            label={strings.systemClassText}
            data={systemClasses}
            field='systemClass'
            idKey='systemClassId'
            displayValue={[{ key: 'systemClassName', separator: '' }]}
            isRequired={true}
            multiSelect={false}
            placeholder={strings.pleaseSelect + '...'}
            onChange={handleFilter}
            mainContainerStyle='col-lg-6 mt-3 mt-lg-0'
            value={filters.systemClass}
            fullValue={filters.systemClassFullValue}
          />
        </div>
      </div>
    </div>
  );
}

export default KitPicturesFilter;
