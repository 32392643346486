import React, { useEffect, useState } from 'react';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import {
  CheckMark,
  CrossIcon,
  EditOutlineIcon,
  StockControllerDelete,
} from 'library/common/commonComponents/Icon/SvgIcons/Icons';
import strings from 'resources/locales/Translate';
import Button from 'library/common/commonComponents/Button/Button';
import debounce from 'lodash/debounce';

export const useIqsSscRooms = ({
  stockId,
  addNewRoom,
  getIqsRoomsList,
  editRooms,
  deleteRoom,
  iqsSscRoomsList,
  canEditStock,
}) => {
  const [searchData, setSearchData] = useState({ searchText: '' });
  const [editingFileds, setEditingFields] = useState([-1]);
  const [roomsList, setRoomsList] = useState(iqsSscRoomsList);

  useEffect(() => {
    getIqsRoomsList(stockId, searchData);
  }, [searchData]);

  useEffect(() => {
    setRoomsList(
      iqsSscRoomsList?.content?.length < 30 && canEditStock
        ? [emptyRoom, ...iqsSscRoomsList.content]
        : canEditStock && iqsSscRoomsList?.content?.length === 0
        ? [emptyRoom]
        : iqsSscRoomsList?.content,
    );
  }, [iqsSscRoomsList]);

  const onChange = (e, id, field) => {
    const changedValues = roomsList.map(item => {
      if (item.warehouseRoomId === id) {
        return { ...item, [field]: e, [`${field}Error`]: '' };
      } else {
        return item;
      }
    });
    setRoomsList(changedValues);
  };

  const onAddClick = async warehouseRoomId => {
    const filterData = roomsList.filter(item => item.warehouseRoomId === warehouseRoomId);
    if (!checkValidation(warehouseRoomId, filterData)) {
      return;
    }
    const dataToSend = {
      roomName: filterData[0].roomName,
      capacity: filterData[0].capacity,
      location: filterData[0].location,
      warehouseId: stockId,
      warehouseRoomId: null,
    };
    const { success } = await addNewRoom(dataToSend, stockId, true);
    if (success) {
      setRoomsList([emptyRoom, ...iqsSscRoomsList.content]);
    }
  };

  const onEditClick = warehouseRoomId => {
    setEditingFields(prevSate => [...prevSate, warehouseRoomId]);
  };

  const onSaveEditData = async roomId => {
    const filterData = roomsList.filter(item => item.warehouseRoomId === roomId);
    if (!checkValidation(roomId, filterData)) {
      return;
    }
    const dataToSend = {
      roomName: filterData[0].roomName,
      location: filterData[0].location,
      capacity: filterData[0].capacity,
      warehouseId: stockId,
      warehouseRoomId: roomId,
    };
    await editRooms(dataToSend, roomId, stockId, true);
    const removedEditingField = editingFileds.filter(item => item !== roomId);
    setEditingFields(removedEditingField);
  };

  const onCancelClick = warehouseRoomId => {
    const removedEditingField = editingFileds.filter(item => item !== warehouseRoomId);
    setEditingFields(removedEditingField);
    setRoomsList(iqsSscRoomsList?.content?.length > 0 ? [emptyRoom, ...iqsSscRoomsList.content] : [emptyRoom]);
  };

  const deleteRooms = async (roomName, location, capacity, warehouseRoomId) => {
    const dataToSend = {
      roomName: roomName,
      location: location,
      capacity: capacity,
      warehouseId: stockId,
      warehouseRoomId: warehouseRoomId,
    };

    const { success, error } = await deleteRoom(dataToSend, warehouseRoomId, stockId, true);
  };

  const checkValidation = (warehouseRoomId, filterData) => {
    let isValid = true;
    let validObj = {};
    if (filterData[0].roomName === '') {
      validObj = { roomNameError: 'requiredErrorMessage' };
      isValid = false;
    }
    if (filterData[0].capacity === '') {
      validObj = { ...validObj, capacityError: 'requiredErrorMessage' };
      isValid = false;
    }
    if (filterData[0].location === '') {
      validObj = { ...validObj, locationError: 'requiredErrorMessage' };
      isValid = false;
    }

    const changedValues = roomsList.map(item => {
      if (item.warehouseRoomId === warehouseRoomId) {
        return { ...item, ...validObj };
      }
      return item;
    });
    setRoomsList(changedValues);

    return isValid;
  };

  let emptyRoom = {
    roomName: '',
    warehouseId: '',
    warehouseRoomId: -1,
    location: '',
    capacity: '',
  };

  const getCols = () => [
    {
      title: strings.name,
      name: 'roomName',
      isSort: false,
      render: item => {
        return (
          <span>
            {editingFileds.includes(item.warehouseRoomId) ? (
              <InputField
                label={''}
                field='editRoomName'
                placeholder={''}
                isRequired={true}
                type='text'
                validators={[]}
                value={item.roomName}
                error={item.roomNameError}
                onChange={e => onChange(e, item.warehouseRoomId, 'roomName')}
              />
            ) : (
              item.roomName
            )}
          </span>
        );
      },
    },
    {
      title: strings.location,
      name: 'location',
      isSort: false,
      render: item => {
        return (
          <span>
            {editingFileds.includes(item.warehouseRoomId) ? (
              <InputField
                label={''}
                field='editLocation'
                placeholder={''}
                isRequired={true}
                type='text'
                validators={[]}
                value={item.location}
                error={item.locationError}
                onChange={e => onChange(e, item.warehouseRoomId, 'location')}
                maxLength={255}
              />
            ) : (
              item.location
            )}
          </span>
        );
      },
    },
    {
      title: strings.capacity,
      name: 'capacity',
      isSort: false,
      render: item => {
        return (
          <span>
            {editingFileds.includes(item.warehouseRoomId) ? (
              <InputField
                label={''}
                field='editCapacity'
                placeholder={''}
                isRequired={true}
                type='text'
                validators={[]}
                value={item.capacity}
                error={item.capacityError}
                onChange={e => onChange(e, item.warehouseRoomId, 'capacity')}
                maxLength={255}
              />
            ) : (
              item.capacity
            )}
          </span>
        );
      },
    },
    {
      title: '',
      name: 'deleteOrEdit',
      isSort: false,
      render: item => {
        return (
          <span>
            {!editingFileds.includes(item.warehouseRoomId) && canEditStock && (
              <div className='adjust-delete-edit-icon cursor-pointer'>
                <div onClick={() => onEditClick(item.warehouseRoomId)}>
                  <EditOutlineIcon width={16} height={16} />
                </div>
                <div onClick={() => deleteRooms(item.roomName, item.location, item.capacity, item.warehouseRoomId)}>
                  <StockControllerDelete fill='#0088D0' />
                </div>
              </div>
            )}
            {editingFileds.includes(item.warehouseRoomId) && item.warehouseRoomId !== -1 && canEditStock && (
              <div className='adjust-delete-edit-icon mt-3 cursor-pointer'>
                <div onClick={() => onSaveEditData(item.warehouseRoomId)}>
                  <CheckMark />
                </div>
                <div onClick={() => onCancelClick(item.warehouseRoomId)}>
                  <CrossIcon />
                </div>
              </div>
            )}
            {item.warehouseRoomId === -1 && canEditStock && (
              <div>
                <Button
                  bgFill={false}
                  iconName='plus'
                  onClick={() => onAddClick(item.warehouseRoomId)}
                  value={strings.addRooms}
                  styleClass='w-auto adjust-iqsSsc-add-room-btn'
                  newVersionUi={true}
                />
              </div>
            )}
          </span>
        );
      },
    },
  ];

  const handleSearchChange = e => {
    const { value } = e.target;
    debounceUpdateData({ value });
  };

  const debounceUpdateData = debounce(({ value }) => {
    setSearchData(prevState => {
      const newData = { ...prevState };
      newData.searchText = value;
      return { ...newData };
    });
  }, 1000);

  return {
    onChange,
    onAddClick,
    getCols,
    emptyRoom,
    roomsList,
    handleSearchChange,
    searchData,
  };
};
