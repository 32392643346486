import React, { Fragment } from 'react';
import strings from 'resources/locales/Translate';

export const dataTable = (calculateDate, makeBoldToHeaders) => [
  {
    title: strings.date,
    name: 'number',
    isSort: false,
    render: item => {
      return <span>{calculateDate(item.createdAt)}</span>;
    },
  },
  {
    title: strings.name,
    name: 'name',
    isSort: false,
    render: item => {
      return (
        <span>
          {item.createdBy 
            ? `${item.createdBy.firstName || ''} ${item.createdBy.lastName || ''}`.trim() 
            : (item.type === 3 && strings.updatedBySystem)}
        </span>
      );
    }
  },
  {
    title: strings.mobile,
    name: 'Mobile',
    isSort: false,
    render: item => {
      return <span>{item.createdBy && item.createdBy.userAddresses && item.createdBy.userAddresses[0] && item.createdBy.userAddresses[0].phone}</span>;
    },
  },
  {
    title: strings.emailColHeader,
    name: 'Email',
    isSort: false,
    render: item => {
      return <span>{item.createdBy && item.createdBy.email}</span>;
    },
  },
  {
    title: strings.action,
    name: 'Action',
    isSort: false,
    render: item => {
        return <div>{item.displayMessage.includes('\n') ? item.displayMessage.split("\n").map((t,key) => {
        return <p key={key} dangerouslySetInnerHTML={{ __html: makeBoldToHeaders(t) }}></p>;
       }):item.displayMessage}</div>;
    },
  },
];
