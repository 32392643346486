import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setAuthentication , setOAuthentication,} from 'library/common/commonActions/AuthActions';
import { setLanguage } from 'library/common/commonActions/LanguageActions';
import { getAllScheduledEventList } from 'modules/Homepage/HomepageActions';

import Routes from './Routes';

const mapStateToProps = ({ authReducer, homepage, appActionsReducer }) => ({
  authentication: authReducer,
  loansSubMenu: homepage.loansSubMenu,
  scheduledEventsList: homepage.scheduledEventsList,
  appActions: appActionsReducer,
});

export default withRouter(connect(mapStateToProps, {setAuthentication, setLanguage,setOAuthentication, getAllScheduledEventList})(Routes));
