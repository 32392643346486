import * as actionTypes from "../commonConstants/storeConstants";

const initialState = {
  isLoading: false,
  displayActionMessage: false,
  type: null,
  message: null,
  showAsIs: false,
  randomValue: null,
};

const AppActionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_LOADER:
      return {
        ...state,
        isLoading: action.payload
      };
    case actionTypes.DISPLAY_ACTION_MESSAGE:
      return {
        ...state,
        displayActionMessage: action.payload.displayActionMessage,
        type: action.payload.type,
        message: action.payload.message,
        showAsIs: action.payload.showAsIs,
        randomValue: action.payload.randomValue,
      };
    default:
      return state;
  }
};

export default AppActionsReducer;
