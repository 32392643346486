import React, { useEffect } from 'react';
import $ from 'jquery';
import strings from 'resources/locales/Translate';
import Button from 'library/common/commonComponents/Button/Button';
import Icon from 'library/common/commonComponents/Icon/Icon';

export const InfoMessagePopupForBorrower = ({hideBorrowerPopup, history}) => {
  useEffect(() => {
    $('#info-message-borrower').modal('show');
  }, []);

  const onClickOk = () => {
    hideBorrowerPopup();
    history.push('/');
    $(`#${'info-message-borrower'}`).modal('hide');
  };

  return (
    <div
      className='modal fade modal-container'
      id={'info-message-borrower'}
      tabIndex='-1'
      role='dialog'
      aria-labelledby={'info-message-borrower'}
      aria-hidden='true'
      data-backdrop='static'
    >
      <div className='modal-dialog stock-popup' role='document'>
        <div className='modal-content rounded-0 '>
          <div className='modal-body p-0'>
            <div className='content' id='app-scheduler-wrapper'>
            <h3 className='modal-title mr-2'>{strings.attention}</h3>
              <p>{strings.borrowerInfoMessage}</p>
              <div className='d-flex justify-content-center align-content-center mt-4'>
                <Button
                  styleClass='btn-max-width-300'
                  value={strings.gotIt}
                  bgFill={true}
                  onClick={onClickOk}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoMessagePopupForBorrower;
