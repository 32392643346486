import React, { useEffect } from 'react';

import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import Button from 'library/common/commonComponents/Button/Button';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import strings from 'resources/locales/Translate';
import { countries_En, countries_De } from './country-list';

import {
  administrationBreadcrumbs,
  CountryModel,
} from './MasterData.constants';
import { useMasterData } from './MasterData.hook';


const Administration = ({ appActions, masterData, getInitialData, saveMasterData, language }) => {
  const categoryList = [
    { name: strings.categorySystem, id: 'systemClass' },
    { name: strings.systemMainComponent, id: 'systemMainComponent' },
    { name: strings.countryLand, id: 'countryOfOrigin' },
  ];
  const { displayActionMessage, type, message } = appActions;
  const {
    formName,
    form,
    modelForSystemClass,
    modelForSystemMainComponent,
    handleDropdown,
    handleChangeSystemMainComponentOrCountryForm,
    handleChangeSystemClassForm,
    addDataToMasterList,
  } = useMasterData({ masterData, getInitialData, saveMasterData, language });

  useEffect(() => {
    language.language === 'en' ? CountryModel[0].options = countries_En : CountryModel[0].options = countries_De
  }, [language]);
  return (
    <div>
      <div className='container-fluid no-gutters form-container'>
        <h5 className='mb-3'>{strings.administrationHeading}</h5>
        <Dropdown
          value={formName.value}
          fullValue={formName.fullValue}
          data={categoryList}
          hasSection={false}
          multiSelect={false}
          filter={false}
          idKey='id'
          displayValue={[{ key: 'name', separator: '' }]}
          isRequired={true}
          placeholder={strings.pleaseSelect + '...'}
          label={strings.chooseCategory}
          onChange={handleDropdown}
          mainContainerStyle='col-sm-3 mb-3'
          error={formName.error}
        />

        {formName.value === 'systemClass' && (
          <FormComponent
            model={modelForSystemClass}
            formName='systemClass'
            formValue={form.formData}
            onChange={handleChangeSystemClassForm}
            className='mb-3'
          />
        )}
        {formName.value === 'systemMainComponent' && (
          <FormComponent
            model={modelForSystemMainComponent}
            formName='systemMainComponent'
            formValue={form.formData}
            onChange={handleChangeSystemMainComponentOrCountryForm}
            className='mb-3'
          />
        )}
        {formName.value === 'countryOfOrigin' && (
          <FormComponent
            model={CountryModel}
            formName='countryOfOrigin'
            formValue={form.formData}
            onChange={handleChangeSystemMainComponentOrCountryForm}
            className='mb-3'
          />
        )}

        <div className='col-sm-2'>
          <Button value={strings.save} iconName='save' onClick={addDataToMasterList} />
        </div>
      </div>
    </div>
  );
};

export default Administration;
