import React from 'react';

export const useTasksFilter = ({
  handleFilterChange,
  selected,
  systemsClass,
}) => {
  const handleFilter = key => (value, field, fullValue) => {
    handleFilterChange(key, value, fullValue);
  };

  const getPlaceholder = (selectedValues, displayValue, multiple) => {
    let displayPlaceholder = '';
    if (!multiple && selectedValues) {
      displayPlaceholder = displayValue
        .map(display => {
          if (display.name) {
            return selectedValues[display.key] + display.separator + selectedValues[display.name];
          }
          return selectedValues[display.key] + display.separator;
        })
        .join('');
    }
    if (selectedValues && selectedValues.length > 0) {
      const displayFormat = selectedValues.map(item => {
        return displayValue
          .map(display => {
            return item[display.key] + display.separator;
          })
          .join('');
      });
      displayPlaceholder =
        selectedValues.length === 1 ? displayFormat[0] : `${displayFormat[0]} + ${displayFormat.length - 1}`;
    }
    return displayPlaceholder;
  };

  const resetFilter = key => () => {
    handleFilterChange(key, null, null);
  };

  const renderFilters = () => {
    const newFilters = [];
    const filtersValue = {
      systemClass: { displayValue: [{ key: 'systemClassName', separator: '' }], multiple: false },
      systemMainComponent: { displayValue: [{ key: 'systemMainComponentName', separator: '' }], multiple: true },
      status: { displayValue: [{ key: 'name', separator: '' }], multiple: false },
    };
    Object.keys(filtersValue).forEach(key => {
      if (
        selected &&
        selected[key] &&
        (typeof selected[key] !== 'object' || selected[key].length) && selected[`${key}FullValue`]
      ) {
        newFilters.push(
          <div className='filter-chips-container' key={key}>
            {getPlaceholder(
              selected[`${key}FullValue`],
              filtersValue[key].displayValue,
              filtersValue[key].multiple,
            )}
            <span onClick={resetFilter(key)}>&times;</span>
          </div>,
        );
      }
    });
    return newFilters;
  };

  const getOptionsForMainComponent = () => {
    if (systemsClass && selected.systemClass) {
      const selectedSystemClass = systemsClass.find(item => selected.systemClass === item.systemClassId) || {};

      return selectedSystemClass.systemMainComponent; 
    }

    return [];
  };

  const filters = renderFilters();
  const optionsForMainComponent = getOptionsForMainComponent();

  return {
    filters,
    optionsForMainComponent,
    handleFilter,
    getPlaceholder,
    resetFilter,
    renderFilters,
    getOptionsForMainComponent,
  };
}
