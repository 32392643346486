import { connect } from 'react-redux';

import { fetchProblemsList } from 'modules/Kits/KitsList/KitsList.actions';
import { bookNowHandler } from 'modules/LoanList/LoanListActions';

import { resetCreateKit } from '../CreateKit/CreateKit.actions';
import { deleteKitDetails, fetchKitDetail, saveCurrentTab, clearKitDetailsData, updateKitAttachents, saveKitAttachments, fetchActiveTransactio, deletereparationSlotForKit, checkActiveLoanorder , updateKitWithIbase} from './KitDetailsAction';
import KitDetails from './KitDetails';
import { fetchNewComponentsList, addUpdateNewComponent, deleteNewComponent, updateForm } from '../CreateZmccKit/CreateZmccKit.actions';
import { toggleActionMessage, changeLoader } from 'library/common/commonActions/AppActionsActions';

const mapStateToProps = ({
  authReducer,
  kitDetailsReducer,
  appActionsReducer,
  languageReducer,
  loanList,
  masterDataReducer,
  kitsListReducer,
  createStockReducer,
  createZmccKitReducer
}) => ({
  formValue: {
    kitAttachments: kitDetailsReducer.kitAttachments,
  },
  user: authReducer.user,
  kitDetails: kitDetailsReducer.kitDetails,
  isLoading: appActionsReducer.isLoading,
  language: languageReducer,
  displayActionMessage: appActionsReducer.displayActionMessage,
  type: appActionsReducer.type,
  message: appActionsReducer.message,
  currentTab: kitDetailsReducer.currentTab,
  damageLossReportData: kitDetailsReducer.damageLossReportData,
  commentsData: kitDetailsReducer.commentsData,
  cart: loanList.cart,
  businessUnitList: masterDataReducer.businessUnit,
  problemsList: kitsListReducer.problemsList,
  kitDetailsAttachments: kitDetailsReducer.kitDetailsAttachments,
  transactionAttachments: kitDetailsReducer.transactionAttachments,
  workFlowQuestions: kitDetailsReducer.workFlowQuestions,
  availabilitySlotList: createStockReducer.availabilitySlotList,
  newComponentsForm: createZmccKitReducer.newComponentsForm,
  newComponentsList: createZmccKitReducer.newComponentsList,
});

export default connect(mapStateToProps, {
  deleteKitDetails,
  fetchKitDetail,
  saveCurrentTab,
  clearKitDetailsData,
  bookNowHandler,
  fetchProblemsList,
  resetCreateKit,
  updateKitAttachents,
  saveKitAttachments,
  deletereparationSlotForKit,
  checkActiveLoanorder,
  updateKitWithIbase,
  fetchNewComponentsList,
  addUpdateNewComponent,
  toggleActionMessage,
  deleteNewComponent,
  updateForm
})(KitDetails);
