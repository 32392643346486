import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import strings from 'resources/locales/Translate';

import Button from '../Button/Button';
import './stylesheet.scss';
export class LoginModal extends PureComponent {

  render() {
    const { hash } = window.location;
    const { id } = this.props;
    // const localHost = encodeURIComponent(`${process.env.REACT_APP_UI_CALLBACK_URL}/${hash}`);
    // const url = process.env.REACT_APP_ZEISS_LOGIN_URL.replace('{localhost}', localHost);
    const tempUrl = `${process.env.REACT_APP_BASE_URL}/oauth-login`;

    return (
      <div
        className='modal fade loginModal'
        id={id}
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
        data-backdrop='static'
      >
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content rounded-0 '>
            <div className='modal-body p-0'>
              <div className='row'>
                <div className='col-lg-6'>
                  <div className='content position-relative'>
                    <h3 className='login-title'>{strings.loginTitle}</h3>
                    <p className='description'>{strings.loginDescriptionText}</p>
                    {/* <Button value={strings.loginButton} bgFill={true} onClick={() => window.location.replace(url)} /> */}
                    <br></br>
                    <Button value={strings.loginButton} bgFill={true} onClick={() => window.location.replace(tempUrl)} />
                  </div>
                </div>

                <div className='col-lg-6'>
                  <div className='content bg-off-white position-relative'>
                    <h3 className='login-title'>{strings.registerTitle}</h3>
                    <p className='description'>{strings.registerDescriptionText}</p>

                    <h4 className='benefit-title'>{strings.yourBenefits}</h4>

                    <ul className='list-unstyled benefits'>
                      <li>{strings.benefits1}</li>
                      <li>{strings.benefits2}</li>
                      <li>{strings.benefits3}</li>
                    </ul>

                    <Button
                      value={strings.registerButton}
                      bgFill={false}
                      onClick={() => window.open(process.env.REACT_APP_ZEISS_REGISTER_URL, '_blank')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.languageReducer,
  };
};

export default connect(mapStateToProps)(LoginModal);
