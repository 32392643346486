import { connect } from 'react-redux';

import { fetchProblemsList } from 'modules/Kits/KitsList/KitsList.actions';

import { fetchKitForSaleDetail, saveCurrentTab, clearKitDetailsData, deleteKitDetails, fetchViewsCount } from './KitForSaleDetails.actions';
import KitForSaleDetails from './KitForSaleDetails.component';

const mapStateToProps = ({ authReducer, appActionsReducer, languageReducer, kitForSaleDetailsReducer, kitsListReducer }) => {
  return {
    user: authReducer.user,
    isLoading: appActionsReducer.isLoading,
    displayActionMessage: appActionsReducer.displayActionMessage,
    type: appActionsReducer.type,
    message: appActionsReducer.message,
    language: languageReducer,
    kitDetails: kitForSaleDetailsReducer.kitDetails,
    currentTab: kitForSaleDetailsReducer.currentTab,
    damageLossReportData: kitForSaleDetailsReducer.damageLossReportData,
    commentsData: kitForSaleDetailsReducer.commentsData,
    questions: kitForSaleDetailsReducer.questions,
    kitDetailsAttachments: kitForSaleDetailsReducer.kitDetailsAttachments,
    problemsList: kitsListReducer.problemsList,
    transactionAttachments: kitForSaleDetailsReducer.transactionAttachments,
  }
};

export default connect(mapStateToProps, {
  fetchKitForSaleDetail,
  saveCurrentTab,
  clearKitDetailsData,
  fetchProblemsList,
  deleteKitDetails,
  fetchViewsCount
})(KitForSaleDetails);
