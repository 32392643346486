import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import Icon from 'library/common/commonComponents/Icon/Icon';
import strings from 'resources/locales/Translate';

import './delayMessageStyles.scss';

const colors = {
  error: '#cc0000',
  success: '#4ab300',
  info: '#0088d0',
};

class DelayMessage extends Component {
  displayAndGenerateLinkForDelayTransactionsId = transactions => (
    <span>
      {transactions.map((transaction, index) => (
        <NavLink key={index} to={`/transactions/${transaction.transactionsId}`} className='transaction-items'>
          {transaction.transactionNumber}
        </NavLink>
      ))}
    </span>
  );

  render() {
    const { transactions } = this.props;
    return (
      <div className='action-message-container' style={{ backgroundColor: colors['error'] }}>
        <div className='container-fluid text-white d-flex justify-content-between align-items-center'>
          <div>
            <Icon name='info' width={16} height={16} fill='#fff' className='action-icon' />
            <span>{strings.delayReturnTransactionMessage}</span>
            {this.displayAndGenerateLinkForDelayTransactionsId(transactions)}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.languageReducer,
  };
};

export default connect(mapStateToProps)(DelayMessage);
