import {
  ADD_BOOKING,
  FETCH_AVAILABLE_KIT_LIST,
  FETCH_OPERATORS_LIST,
  FETCH_STOCKS_FOR_USERS,
  UPDATE_FILTERS,
  UPDATE_LABS_LOCATION_FILTERS,
  UPDATE_ZMCC_FILTERS,
  UPDATE_ZMCC_DATE_FILTERS,
  CLEAR_ZMCC_EXISTING_DATA,
  SYSTEM_MAIN_COMPONENTS_BY_SYSTEM_CLASSES
} from './calendarConstants';

export const initialState = {
  kits: null,
  bookings: [],
  stocks: [],
  alreadyBooked: [],
  filters: {
    loanType: 1,
    category: null,
    categoryFullValue: null,
    stock: null,
    stockFullValue: null,
  },
  labsLocationFilters: {
    loanType: 1,
    category: null,
    categoryFullValue: null,
    stock: null,
    stockFullValue: null,
  },
  zmccFilters: {
    loanType: 1,
    category: null,
    categoryFullValue: null,
    stock: null,
    stockFullValue: null,
    systemCategory: null,
    systemCategoryFullValue: null,
  },
  operatorsList: [],
  zmccExistingMonths: new Set(),
};

const CalendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BOOKING:
      return {
        ...state,
        bookings: action.payload
      };
    case FETCH_AVAILABLE_KIT_LIST:
      if(action.payload?.isZmccCalendar){
        return {
          ...state,
          kits: {content: [...(state.kits?.content || []), ...(action.payload?.kits?.content || [])], ...action.payload.kits},
          alreadyBooked: [...state.alreadyBooked, ...action.payload.alreadyBooked]
        };
      }
      return {
        ...state,
        kits: action.payload.kits,
        alreadyBooked: action.payload.alreadyBooked
      };
    case FETCH_STOCKS_FOR_USERS:
      return {
        ...state,
        stocks: action.payload
      };
    case UPDATE_FILTERS:
      return {
        ...state,
        filters: action.payload
      };
    case UPDATE_LABS_LOCATION_FILTERS:
      return {
        ...state,
        labsLocationFilters: action.payload
      };
    case UPDATE_ZMCC_FILTERS:
      return {
        ...state,
        zmccFilters: action.payload
      };
    case FETCH_OPERATORS_LIST:
      return {
        ...state,
        operatorsList: action.payload
      };
    case UPDATE_ZMCC_DATE_FILTERS: 
      return {...state, zmccExistingMonths: new Set([...state.zmccExistingMonths, ...action.payload])}
    case CLEAR_ZMCC_EXISTING_DATA:
      return {
        ...state, 
        kits: null,
        alreadyBooked: [],
        zmccExistingMonths: new Set()
      }
    case SYSTEM_MAIN_COMPONENTS_BY_SYSTEM_CLASSES: 
      return {...state, systemMainComponentValues: action.payload}
    default:
      return state;
  }
};

export default CalendarReducer;
