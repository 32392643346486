import React from 'react';

import TextArea from 'library/common/commonComponents/FormComponent/Optimized/TextArea';
import Modal from 'library/common/commonComponents/Modal';
import strings from 'resources/locales/Translate';

import { useAnswerPopup } from './AnswerPopup.hook';

export default function AnswerPopup(props) {
  const { id, title, label, maxLength } = props;

  const {
    answer,
    loading,
    error,
    onSaveClick,
    handleForm,
    onCloseClick,
  } = useAnswerPopup(props);

  return (
    <Modal
      id={id}
      title={title}
      confirmAction={onSaveClick}
      confirmDataTest='notify-button-subscribe'
      confirmTitle={strings.save}
      loading={loading}
      disableBackgroundClose
      onCloseClick={onCloseClick}
    >
      <TextArea
        label={label}
        isRequired={true}
        error={answer.error}
        value={answer.value}
        onChange={handleForm}
        maxLength={maxLength}
      />
      {error && <div className='error-block mt-3'>{strings[error]}</div>}
    </Modal>
  );
};
