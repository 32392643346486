import { useRef, useCallback } from 'react';

const INTERSECTION_MARGIN = '20% 0%';

export const useShowMore = () => {
  const observerRef = useRef(null);

  const buttonRef = useCallback((element) => {
    observerRef.current && observerRef.current.disconnect();

    if (element) {
      const observer = new IntersectionObserver(
        entries => {
          const isIntersecting = entries[entries.length - 1].isIntersecting;
          if (isIntersecting) element.click();
        },
        {
          root: null,
          rootMargin: INTERSECTION_MARGIN,
        },
      );
      observer.observe(element);
    }
  }, []);

  return buttonRef;
}
