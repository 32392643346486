import { regex } from 'library/common/commonConstants/RegexConstants';
import strings from 'resources/locales/Translate';
import { sapCrmNewCient } from 'library/utilities/constants';

export const useOpportunityForm = ({
  setOpportunity,
  removeOpportunity,
  updateOpportunities,
  opportunities,
  setIsOpportunityIdCrmError,
  state,
  handleEnteredOpportunityId,
  isBusinessUnitMed,
  usCountryStock,
  user
}) => {
  const medBu= isBusinessUnitMed();
  const usCountry= usCountryStock();
  const handleInput = value => {
    setIsOpportunityIdCrmError(false);
    const inputValue = value.trim();
    if(user && user.crmSystemId==='ngcrm' || medBu && usCountry){
      if (inputValue.length > 18) return;
      if (inputValue && !regex.alphaNumeric.test(inputValue)) return;
    }
    else{
      if (inputValue.length < 9 &&inputValue && !regex.number.test(inputValue)) return;
      if (inputValue.length > 9 &&!regex.validCrmCheck.test(inputValue)) return
    }
    handleEnteredOpportunityId({
      value: inputValue,
      error: '',
    });
  };

  const handleSetOpportunity = () => {
    if(user && user.crmSystemId === 'ngcrm' || medBu && usCountry){
      if (state.value.length < 18) {
        return handleEnteredOpportunityId({
          error: strings.pleaseMindThatFieldNeedsToHave(18),
        });
      }
    }
    else{
      if (state.value.length != 9) {
        return handleEnteredOpportunityId({
          error: 'pleaseMindThat',
        });
      }

      if (user.crmSystemId === sapCrmNewCient) {
        if (!regex.validCrmCheckUk.test(state.value) && !regex.validCrmCheck.test(state.value)) {
          return handleEnteredOpportunityId({
            error: strings.correctOpportunityMessage,
          });
        }
      } else if (!regex.validCrmCheck.test(state.value)) {
        return handleEnteredOpportunityId({
          error: strings.correctOpportunityMessage,
        });
      }
    }
    const isSameOpportunities = opportunities.find(opportunity => (opportunity.opportunityNumber.toString() === state.value));
    if (isSameOpportunities) {
      return handleEnteredOpportunityId({
        error: 'youCanNotEnter',
      });
    }
    setOpportunity(state.value);
    handleEnteredOpportunityId({
      value: '',
      error: '',
    });
  };

  const handleRemoveOpportunity = value => {
    removeOpportunity(value);
  };

  const handleUpdateOpportunities = (value, key) => {
    const inputValue = value.trim();
    if (user && user.crmSystemId === 'ngcrm') {
      if (inputValue.length > 18) return;
      if (inputValue && !regex.alphaNumeric.test(inputValue)) return;
    }else{
      if (inputValue.length > 9) return;
      if (inputValue && !regex.number.test(inputValue)) return;
    }

    updateOpportunities(value, key);
  };

  return {
    state,
    handleInput,
    handleSetOpportunity,
    handleRemoveOpportunity,
    handleUpdateOpportunities,
  };
};
