import React from 'react';
import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import Modal from 'library/common/commonComponents/Modal';

import { exportModel } from './ExportTable.constants';
import { useExportTable } from './ExportTable.hook';
import { fetchMasterDataPost } from 'library/common/commonActions/MasterDataActions';
import { connect } from 'react-redux';

export const ExportTable = ({
  categoriesAndSystems,
  countries,
  singleItemsOptions,
  id,
  filters,
  exportShopList,
  marketplaceCountry,
  fetchMasterDataPost,
}) => {
  const { loading, error, exportTableForm, onSaveClick, handleForm, onCloseClick, checkForDisableButton } =
    useExportTable({
      categoriesAndSystems,
      countries,
      singleItemsOptions,
      id,
      filters,
      exportShopList,
      fetchMasterDataPost,
    });
  const disableSaveButton = checkForDisableButton();
  return (
    <Modal
      id={id}
      className=''
      title={strings.exportList}
      confirmAction={onSaveClick}
      confirmDataTest='export-table-button-save'
      dataTest='export-table-modal'
      confirmTitle={strings.export}
      loading={loading}
      disableBackgroundClose
      onCloseClick={onCloseClick}
      disabledButton={disableSaveButton}
    >
      <FormComponent
        model={exportModel({ categoriesAndSystems, countries, singleItemsOptions, marketplaceCountry })}
        formName='exportTableForm'
        formValue={exportTableForm.formData}
        onChange={handleForm}
      />
      {error && <div className='error-block'>{error}</div>}
    </Modal>
  );
};

export default
connect(null, {
    fetchMasterDataPost
  })(ExportTable)
