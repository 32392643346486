import React from 'react';

import strings from 'resources/locales/Translate';

const Counter = ({ counter }) => {
  return (
    <div className='counter-wrapper'>
      <p className='counter-text'>{strings.lastUpdated(counter)}</p>
    </div>
  );
};

export default Counter;
