import { connect } from 'react-redux';

import { setLanguage } from 'library/common/commonActions/LanguageActions';

import OffCanvasMenu from './OffCanvasMenu.component';

const mapStateToProps = state => {
  return {
    language: state.languageReducer,
    authentication: state.authReducer
  }
};

export default connect(mapStateToProps, { setLanguage })(OffCanvasMenu);
