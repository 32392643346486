import React, { Component } from 'react';
import cn from 'classnames';

import './stylesheet.scss';

export default class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelected: props.selected,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.selected !== prevProps.selected) {
      this.setState({ isSelected: this.props.selected });
    }
  }

  handleChange = event => {
    const { field, onClick } = this.props;
    const { checked } = event.target;
    this.setState(
      {
        isSelected: checked,
      },
      () => onClick(checked, field),
    );
  };

  render() {
    const { className, dataTest, label, selected, disabled, style, small, customeStyledClass, newuiVersionStyle } = this.props;
    return (
      <label className={`checkbox-container ${className ? className : ''}`} data-test={dataTest} style={style}>
        <p className={'label'}>{label}</p>
        <input
          type='checkbox'
          checked={
            this.state.isSelected !== null && this.state.isSelected !== undefined ? this.state.isSelected : selected
          }
          onChange={this.handleChange}
          disabled={disabled}
        />
        <span
          className={cn(
            { checkmark: !small && !newuiVersionStyle, 'small-checkmark': small && !newuiVersionStyle },
            { 'newui-checkmark': newuiVersionStyle && !small, 'newui-small-checkmark': newuiVersionStyle && small },
            `${customeStyledClass ? customeStyledClass : ''}`,
          )}
        >
          {' '}
        </span>
      </label>
    );
  }
}

Checkbox.defaultProps = {
  label: '',
  selected: false,
  field: '',
  disabled: false,
  style: {},
  onClick: () => {},
  newuiVersionStyle: false,
};
