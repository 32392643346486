import { useEffect, useState, useCallback } from 'react';
import * as $ from 'jquery';
import { validateForm } from 'library/utilities/ValidateForm.util';
import strings from 'resources/locales/Translate';
import { addressFormModel } from '../Information.constants';

export const useAddNewAddress = ({ id, addNewAddress, stockId }) => {
  const [model, setModel] = useState(addressFormModel);
  const [addAddressForm, setAddAddressForm] = useState({ formData: {} });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleForm = ({ values, field }) => {
    setAddAddressForm({
      ...addAddressForm,
      formData: {
        ...addAddressForm.formData,
        ...values,
      },
    });
  };
  const onSaveClick = async () => {
    const validForm = validateForm({
      form: addAddressForm,
      model,
    });

    if (!validForm.isFormValid) {
      return setAddAddressForm(validForm);
    }
    const {
      addressType,
      salutation,
      firstName,
      lastName,
      organization,
      department,
      street,
      number,
      postalCode,
      stateName,
      city,
      phone,
      email,
      activeAddress,
    } = validForm.formData;
    const dataToSend = {
      addressType: addressType,
      salutation: salutation ? salutation : '',
      firstName: firstName,
      lastName: lastName,
      organization: organization,
      department: department ? department : '',
      street: street,
      houseNumber: number ? number : '',
      postalCode: postalCode,
      stateName: stateName,
      city: city,
      phone: phone,
      email: email,
      activeAddress: activeAddress,
    };
    setLoading(true);
    const { success, err } = await addNewAddress(dataToSend, stockId);
    if (!success) {
      if (err && err.response && err.response.data.status === 422) {
        setError(err.response.data.message);
      } else {
        setError(strings.somethingWentWrongMessage);
      }
      setLoading(false);
    } else {
      setLoading(false);
      onCloseClick();
    }
  };

  const onCloseClick = () => {
    setAddAddressForm({
      formData: {},
    });
    setError('');
    closePopup();
  };
  const closePopup = useCallback(() => {
    $(`#${id}`).modal('hide');
  }, []);
  return {
    model,
    handleForm,
    addAddressForm,
    onCloseClick,
    onSaveClick,
    error,
    loading,
  };
};
