import Button from 'library/common/commonComponents/Button/Button';
import React from 'react';
import { connect } from 'react-redux';
import strings from 'resources/locales/Translate';
import { useMaintenanceCheckList } from './MaintenanceCheckList.hook';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import Validators from 'library/utilities/Validators';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import RadioButton from 'library/common/commonComponents/FormComponent/Optimized/RadioButton';
import {
  saveMantenanceCheckListData,
  saveMaintenanceNewCheckListForm,
  getActiveTransaction,
} from '../../TransactionDetailsActions';
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import NetPromoterScore from './NetpromoterScore.component';
import moment from 'moment';
import CheckListOptionsPopup from '../CheckListOptionsPopup/CheckListOptionsPopup.component';
import ArrivalCheckListPopup from '../ArrivalCheckLIstPopup/ArrivalCheckListPopup.component';
import DemoCheckListPopup from '../DemoCheckListPopup/DemoCheckListPopup.component';
import DismantleCheckListPopup from '../DismantleCheckListPopup/DismantleCheckListPopup.component';
import classNames from 'classnames';

export const MaintenanceCheckList = props => {
  const {
    maintenanceCheckListData,
    usersList,
    saveMantenanceCheckListData,
    toggleActionMessage,
    transactionDetails,
    user,
    saveMaintenanceNewCheckListForm,
    getActiveTransaction,
    activeTranction,
  } = props;
  const {
    arrivalAbnormalities,
    arrivalAbnormalitiesComment,
    arrivalCompleteness,
    arrivalCompletenessComment,
    arrivalInstallationAbnormalities,
    arrivalInstallationAbnormalitiesComment,
    unpackedFrom,
    setupBy,
    onTimeDelivery,
    demoAbnormalities,
    demoAbnormalitiesComment,
    demoIssues,
    demoIssuesComment,
    demoBackupOfTempCustFiles,
    demoBackupOfTempCustFilesComment,
    systemIsDemoReady,
    systemIsDemoReadyComment,
    netPromoterScore,
    dismantleBy,
    dismantleCompleteness,
    dismantleCompletenessComment,
    dismantleLaserSafety,
    dismantleLaserSafetyComment,
    dismantleElectronicSecurity,
    dismantleElectronicSecurityComment,
    dismantleFileDeleted,
    dismantleFileDeletedComment,
    dismantleDemoKitConfirmation,
    demoChecklistCreatedOn,
  } = maintenanceCheckListData;
  const {
    isArrivalCheckListEdit,
    isDemoCheckListEdit,
    isDismantelCheckListEdit,
    editCheckList,
    onChangeDropdown,
    onChangeInput,
    onChangeCheckbox,
    abnormalitiesComment,
    completenesComment,
    installationAbnormalitiesComment,
    unpackedBy,
    setUpBy,
    onChangeRadioButton,
    saveMaintenanceCheckListForm,
    isArrivalAbonormalities,
    isCompleteness,
    isInstallationAbnormalities,
    isDemoAbnormalitiesComment,
    isOnTimeDelivery,
    isDemoAbnormalities,
    isDemoIssuesComment,
    isDemoIssues,
    demoTemperoryFilesComment,
    isDemoTemperoryFile,
    demoSystemIsReadyComment,
    isSystemReadyDemo,
    isNps,
    isDismanteledBy,
    isDismantleCompleteness,
    isDismantleCompletenessComment,
    isLaserSafety,
    isLaserSafetyComment,
    isElectronicSecurity,
    isElectronicSecurityComment,
    isDeletedFiles,
    isDeletedFilesComment,
    isDismantleDemoKitConfirmation,
    onChangeNps,
    demoFormFilledUser,
    currentDate,
    loading,
    hasPermissionToEditCheckList,
    cancelMaintenanceCheckListForm,
    arrivalFormFilledUser,
    dismantleFormFilledUser,
    demochecklistFilledUser,
    openCheckListOptionsPopup,
    openArrivalCheckListPopUp,
    openDemoCheckListPopUp,
    openDismantleCheckListPopUp,
  } = useMaintenanceCheckList({
    maintenanceCheckListData,
    usersList,
    saveMantenanceCheckListData,
    toggleActionMessage,
    transactionDetails,
    user,
    getActiveTransaction,
  });

  const isCheckedDateAdded = transactionDetails && transactionDetails.checkedDate;
  const showSubmitCheckListButton =
    arrivalAbnormalities === null ||
    arrivalAbnormalities === undefined ||
    demoAbnormalities === null ||
    demoAbnormalities === undefined ||
    dismantleCompleteness === null ||
    dismantleCompleteness === undefined;
  return (
    <div className='p-0'>
      <div>
        {activeTranction && showSubmitCheckListButton && (
          <div className='submit-maintainceCheckList-button'>
            <Button
              bgFill={true}
              value={strings.submitMaintenanceChecklist}
              onClick={openCheckListOptionsPopup}
              styleClass='maintanance-checklist-btn'
            />
          </div>
        )}
        <CheckListOptionsPopup
          id='checkList-options'
          maintenanceCheckListData={maintenanceCheckListData}
          openArrivalCheckListPopUp={openArrivalCheckListPopUp}
          openDemoCheckListPopUp={openDemoCheckListPopUp}
          openDismantleCheckListPopUp={openDismantleCheckListPopUp}
        />

        <ArrivalCheckListPopup
          id='arrival-form'
          user={user}
          usersList={usersList}
          saveMaintenanceNewCheckListForm={saveMaintenanceNewCheckListForm}
          transactionId={transactionDetails.transactionsId}
          maintenanceCheckListData={maintenanceCheckListData}
          saveMantenanceCheckListData={saveMantenanceCheckListData}
        />
        <DemoCheckListPopup
          id='demo-form'
          user={user}
          usersList={usersList}
          saveMaintenanceNewCheckListForm={saveMaintenanceNewCheckListForm}
          transactionId={transactionDetails.transactionsId}
          maintenanceCheckListData={maintenanceCheckListData}
          saveMantenanceCheckListData={saveMantenanceCheckListData}
        />
        <DismantleCheckListPopup
          id='dismantle-form'
          user={user}
          usersList={usersList}
          saveMaintenanceNewCheckListForm={saveMaintenanceNewCheckListForm}
          transactionId={transactionDetails.transactionsId}
          maintenanceCheckListData={maintenanceCheckListData}
          saveMantenanceCheckListData={saveMantenanceCheckListData}
        />
      </div>

      {maintenanceCheckListData ? (
        <div>
          {arrivalAbnormalities !== null && (
            <div className='col-12 section-border'>
              <div className='maintenanceEdit'>
                <div className='section-title'>{strings.headerTextArrival}</div>
                {(arrivalFormFilledUser || hasPermissionToEditCheckList) && !isCheckedDateAdded && (
                  <div onClick={() => editCheckList('arrival')} className='cursor-pointer'>
                    <svg width='18' height='18' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M1.42757 13.4697L1.85411 10.5451C1.88011 10.3539 1.96724 10.1761 2.10357 10.0391L10.368 1.76347C10.4751 1.65523 10.6083 1.57635 10.7548 1.53439C10.9012 1.49243 11.0559 1.4888 11.2042 1.52386C11.9627 1.73391 12.6504 2.14522 13.1942 2.71422C13.7616 3.26181 14.1701 3.95292 14.3761 4.71409C14.4112 4.86233 14.4076 5.01708 14.3656 5.16351C14.3236 5.30994 14.2448 5.44313 14.1365 5.5503L5.86299 13.8154C5.72561 13.9512 5.54776 14.0385 5.35634 14.0642L2.43243 14.4907C2.29431 14.5108 2.15338 14.4982 2.02108 14.4537C1.88879 14.4092 1.76884 14.3341 1.67096 14.2346C1.57309 14.1351 1.50004 14.0139 1.45776 13.8809C1.41547 13.7479 1.40513 13.6067 1.42757 13.469M8.80447 3.33751L12.573 7.10607'
                        stroke='#0088D0'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                    <span className='ml-2 mt-2 color-blue'>{strings.edit}</span>
                  </div>
                )}
              </div>
              <div className='checklist-filed-label'>
                <u>{strings.arrivalAndUnpacking}</u>
              </div>
              <div className='row m-0 section-border'>
                <div className='mt-3 col-3 p-0'>
                  <div className={classNames('checklist-filed-label', {'label-important': isArrivalCheckListEdit})}>{strings.unpackedFrom}</div>
                  {isArrivalCheckListEdit ? (
                    <div className='col-8 p-0'>
                      <Dropdown
                        data={usersList ? usersList : []}
                        hasSection={false}
                        multiSelect={false}
                        filter={true}
                        idKey='userId'
                        field='unpackedBy'
                        displayValue={[
                          { key: 'lastName', separator: ', ' },
                          { key: 'firstName', separator: ' ' },
                        ]}
                        isRequired
                        validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                        placeholder={strings.pleaseSelect}
                        onChange={onChangeDropdown}
                        value={unpackedBy.value}
                        fullValue={unpackedBy.fullValue}
                      />
                    </div>
                  ) : (
                    <div className='checkList-value'>
                      {unpackedBy.fullValue
                        ? `${unpackedBy.fullValue.lastName}, ${unpackedBy.fullValue.firstName}`
                        : ''}
                    </div>
                  )}
                </div>
                <div className=' mt-3 col-3'>
                  <div className={classNames('checklist-filed-label', {'label-important': isArrivalCheckListEdit})}>{strings.abnormalities}</div>
                  {isArrivalCheckListEdit ? (
                    <div className='col-8 p-0'>
                      <RadioButton
                        type={'radio-button'}
                        value={isArrivalAbonormalities}
                        placeholder={''}
                        data={[
                          {
                            id: 'yes',
                            value: 'yes',
                          },
                          {
                            id: 'no',
                            value: 'no',
                          },
                        ]}
                        field='arrivalAbnormalities'
                        isRequired={true}
                        validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                        onChange={onChangeRadioButton}
                      />
                    </div>
                  ) : (
                    <div className='checkList-value'>
                      {arrivalAbnormalities === true
                        ? strings.yes
                        : arrivalAbnormalities === false
                        ? strings.no
                        : '' || ''}
                    </div>
                  )}
                </div>
                {isArrivalAbonormalities === 'yes' && (
                  <div className='mt-3 col-3'>
                    <div className={classNames('checklist-filed-label', {'label-important': isArrivalCheckListEdit})}>{strings.maintenanceComment}</div>
                    {isArrivalCheckListEdit ? (
                      <div className='col-8 p-0'>
                        <InputField
                          field='arrivalAbnormalitiesComment'
                          placeholder=''
                          isRequired
                          validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                          value={abnormalitiesComment.value}
                          error={abnormalitiesComment.error}
                          onChange={onChangeInput}
                        />
                      </div>
                    ) : (
                      <div className='checkList-value'>{arrivalAbnormalitiesComment || ''}</div>
                    )}
                  </div>
                )}
                <div className='col-3 mt-4 p-0'>
                  <div className={classNames('checklist-filed-label', {'label-important': isArrivalCheckListEdit})}>{strings.onTimeDelivery}</div>
                  {isArrivalCheckListEdit ? (
                    <div className='col-8 p-0'>
                      <RadioButton
                        type={'radio-button'}
                        value={isOnTimeDelivery}
                        placeholder={''}
                        data={[
                          {
                            id: 'yes',
                            value: 'yes',
                          },
                          {
                            id: 'no',
                            value: 'no',
                          },
                        ]}
                        field='onTimeDelivery'
                        isRequired={true}
                        validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                        onChange={onChangeRadioButton}
                      />
                    </div>
                  ) : (
                    <div className='checkList-value'>{onTimeDelivery === true ? strings.yes : strings.no || ''}</div>
                  )}
                </div>
              </div>

              <div className='checklist-filed-label mt-3'>
                <u>{strings.installation}</u>
              </div>
              <div className='row m-0 section-border'>
                <div className='mt-3 col-3 p-0'>
                  <div className={classNames('checklist-filed-label', {'label-important': isArrivalCheckListEdit})}>{strings.setUpBy}</div>
                  {isArrivalCheckListEdit ? (
                    <div className='col-8 p-0'>
                      <Dropdown
                        data={usersList ? usersList : []}
                        hasSection={false}
                        multiSelect={false}
                        filter={true}
                        idKey='userId'
                        field='setUpBy'
                        displayValue={[
                          { key: 'lastName', separator: ', ' },
                          { key: 'firstName', separator: ' ' },
                        ]}
                        isRequired
                        validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                        placeholder={strings.pleaseSelect}
                        onChange={onChangeDropdown}
                        value={setUpBy.value}
                        fullValue={setUpBy.fullValue}
                      />
                    </div>
                  ) : (
                    <div className='checkList-value'>
                      {setUpBy.fullValue ? `${setUpBy.fullValue.lastName}, ${setUpBy.fullValue.firstName}` : ''}
                    </div>
                  )}
                </div>
                <div className='col-3 mt-3'>
                  <div className={classNames('checklist-filed-label', {'label-important': isArrivalCheckListEdit})}>{strings.maintenanceCompleteness}</div>
                  {isArrivalCheckListEdit ? (
                    <div className='col-8 p-0'>
                      <RadioButton
                        type={'radio-button'}
                        value={isCompleteness}
                        placeholder={''}
                        data={[
                          {
                            id: 'yes',
                            value: 'yes',
                          },
                          {
                            id: 'no',
                            value: 'no',
                          },
                        ]}
                        field='arrivalCompleteness'
                        isRequired={true}
                        validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                        onChange={onChangeRadioButton}
                      />
                    </div>
                  ) : (
                    <div className='checkList-value'>{arrivalCompleteness === true ? strings.yes : strings.no || ''}</div>
                  )}
                </div>
                {isCompleteness === 'no' && (
                  <div className='col-3 mt-3'>
                    <div className={classNames('checklist-filed-label', {'label-important': isArrivalCheckListEdit})}>{strings.maintenanceComment}</div>
                    {isArrivalCheckListEdit ? (
                      <div className='col-8 p-0'>
                        <InputField
                          field='arrivalCompletenessComment'
                          placeholder=''
                          isRequired
                          validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                          value={completenesComment.value}
                          error={completenesComment.error}
                          onChange={onChangeInput}
                        />
                      </div>
                    ) : (
                      <div className='checkList-value'>{arrivalCompletenessComment || ''}</div>
                    )}
                  </div>
                )}
                <div className='col-3 mt-4 p-0'>
                  <div className={classNames('checklist-filed-label', {'label-important': isArrivalCheckListEdit})}>{strings.abnormalities}</div>
                  {isArrivalCheckListEdit ? (
                    <div className='col-8 p-0'>
                      <RadioButton
                        type={'radio-button'}
                        value={isInstallationAbnormalities}
                        placeholder={''}
                        data={[
                          {
                            id: 'yes',
                            value: 'yes',
                          },
                          {
                            id: 'no',
                            value: 'no',
                          },
                        ]}
                        field='arrivalInstallationAbnormalities'
                        isRequired={true}
                        validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                        onChange={onChangeRadioButton}
                      />
                    </div>
                  ) : (
                    <div className='checkList-value'>{arrivalInstallationAbnormalities === true ? strings.yes : strings.no || ''}</div>
                  )}
                </div>

                {isInstallationAbnormalities === 'yes' && (
                  <div className='col-3 mt-4 p-0'>
                    <div className={classNames('checklist-filed-label', {'label-important': isArrivalCheckListEdit})}>{strings.maintenanceComment}</div>
                    {isArrivalCheckListEdit ? (
                      <div className='col-8 p-0'>
                        <InputField
                          field='arrivalInstallationAbnormalitiesComment'
                          placeholder=''
                          isRequired={true}
                          validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                          value={installationAbnormalitiesComment.value}
                          error={installationAbnormalitiesComment.error}
                          onChange={onChangeInput}
                        />
                      </div>
                    ) : (
                      <div className='checkList-value'>{arrivalInstallationAbnormalitiesComment || ''}</div>
                    )}
                  </div>
                )}
              </div>
              {isArrivalCheckListEdit && (
                <div className='row mt-4 section-border'>
                  <div className='col-md-1'>
                    <Button
                      bgFill={true}
                      value={strings.save}
                      loading={loading}
                      onClick={() => saveMaintenanceCheckListForm('arrival')}
                    />
                  </div>
                  <div className='col-md-1'>
                    <Button
                      bgFill={true}
                      value={strings.cancel}
                      onClick={() => cancelMaintenanceCheckListForm('arrival')}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          {demoAbnormalities !== null && (
            <div className='col-12 mt-4'>
              <div className='maintenanceEdit'>
                <div className='section-title'>Demo</div>
                {(demochecklistFilledUser || hasPermissionToEditCheckList) && !isCheckedDateAdded && (
                  <div onClick={() => editCheckList('demo')} className='cursor-pointer'>
                    <svg width='18' height='18' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M1.42757 13.4697L1.85411 10.5451C1.88011 10.3539 1.96724 10.1761 2.10357 10.0391L10.368 1.76347C10.4751 1.65523 10.6083 1.57635 10.7548 1.53439C10.9012 1.49243 11.0559 1.4888 11.2042 1.52386C11.9627 1.73391 12.6504 2.14522 13.1942 2.71422C13.7616 3.26181 14.1701 3.95292 14.3761 4.71409C14.4112 4.86233 14.4076 5.01708 14.3656 5.16351C14.3236 5.30994 14.2448 5.44313 14.1365 5.5503L5.86299 13.8154C5.72561 13.9512 5.54776 14.0385 5.35634 14.0642L2.43243 14.4907C2.29431 14.5108 2.15338 14.4982 2.02108 14.4537C1.88879 14.4092 1.76884 14.3341 1.67096 14.2346C1.57309 14.1351 1.50004 14.0139 1.45776 13.8809C1.41547 13.7479 1.40513 13.6067 1.42757 13.469M8.80447 3.33751L12.573 7.10607'
                        stroke='#0088D0'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                    <span className='ml-2 mt-2 color-blue'>{strings.edit}</span>
                  </div>
                )}
              </div>
              <div className='row m-0 section-border'>
                <div className='col-3 mt-3 p-0 mb-5'>
                  <div className={classNames('checklist-filed-label', {'label-important': isDemoCheckListEdit})}>{strings.abnormalities}</div>
                  {isDemoCheckListEdit ? (
                    <div className='col-8 p-0'>
                      <RadioButton
                        type={'radio-button'}
                        value={isDemoAbnormalities}
                        placeholder={''}
                        data={[
                          {
                            id: 'yes',
                            value: 'yes',
                          },
                          {
                            id: 'no',
                            value: 'no',
                          },
                        ]}
                        field='demoAbnormalities'
                        isRequired={true}
                        validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                        onChange={onChangeRadioButton}
                      />
                    </div>
                  ) : (
                    <div className='checkList-value'>
                      {demoAbnormalities === true ? strings.yes : demoAbnormalities === false ? strings.no : '' || ''}
                    </div>
                  )}
                </div>
                {isDemoAbnormalities === 'yes' && (
                  <div className='col-3 mt-3'>
                    <div className={classNames('checklist-filed-label', {'label-important': isDemoCheckListEdit})}>{strings.maintenanceComment}</div>
                    {isDemoCheckListEdit ? (
                      <div className='col-8 p-0'>
                        <InputField
                          field='demoAbnormalitiesComment'
                          placeholder=''
                          isRequired={true}
                          validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                          value={isDemoAbnormalitiesComment.value}
                          error={isDemoAbnormalitiesComment.error}
                          onChange={onChangeInput}
                        />
                      </div>
                    ) : (
                      <div className='checkList-value'>{demoAbnormalitiesComment || ''}</div>
                    )}
                  </div>
                )}
                <div className='col-3 mt-3'>
                  <div className={classNames('checklist-filed-label', {'label-important': isDemoCheckListEdit})}>{strings.temporaryFilesBackup}</div>
                  {isDemoCheckListEdit ? (
                    <div className='col-8 p-0'>
                      <RadioButton
                        type={'radio-button'}
                        value={isDemoTemperoryFile}
                        placeholder={''}
                        data={[
                          {
                            id: 'yes',
                            value: 'yes',
                          },
                          {
                            id: 'no',
                            value: 'no',
                          },
                        ]}
                        field='demoBackupOfTempCustFiles'
                        isRequired={true}
                        validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                        onChange={onChangeRadioButton}
                      />
                    </div>
                  ) : (
                    <div className='checkList-value'>
                      {demoBackupOfTempCustFiles === true
                        ? strings.yes
                        : demoBackupOfTempCustFiles === false
                        ? strings.no
                        : '' || ''}
                    </div>
                  )}
                </div>
                {isDemoTemperoryFile === 'yes' && (
                  <div className='col-3 mt-3'>
                    <div className={classNames('checklist-filed-label', {'label-important': isDemoCheckListEdit})}>{strings.maintenanceComment}</div>
                    {isDemoCheckListEdit ? (
                      <div className='col-8 p-0'>
                        <InputField
                          field='demoBackupOfTempCustFilesComment'
                          placeholder=''
                          isRequired={true}
                          validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                          value={demoTemperoryFilesComment.value}
                          error={demoTemperoryFilesComment.error}
                          onChange={onChangeInput}
                        />
                      </div>
                    ) : (
                      <div className='checkList-valueol'>{demoBackupOfTempCustFilesComment || ''}</div>
                    )}
                  </div>
                )}
                <div className='col-3 mt-3 p-0'>
                  {isDemoCheckListEdit ? (
                    <div className={classNames('checklist-filed-label', {'label-important': isDemoCheckListEdit})}>{strings.systemDemoReady}</div>
                  ) : (
                    <div className={classNames('checklist-filed-label', {'label-important': isDemoCheckListEdit})}>{strings.systemDemoReady}</div>
                  )}
                  {isDemoCheckListEdit ? (
                    <div className='col-8 p-0'>
                      <RadioButton
                        type={'radio-button'}
                        value={isSystemReadyDemo}
                        placeholder={''}
                        data={[
                          {
                            id: 'yes',
                            value: 'yes',
                          },
                          {
                            id: 'yesButRestricted',
                            value: 'yesButRestricted',
                          },
                          {
                            id: 'no',
                            value: 'no',
                          },
                        ]}
                        field='systemIsDemoReady'
                        isRequired={true}
                        validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                        onChange={onChangeRadioButton}
                      />
                    </div>
                  ) : (
                    <div className='checkList-value'>
                      {systemIsDemoReady === 'YES'
                        ? strings.yes
                        : systemIsDemoReady === 'RESTRICTED'
                        ? strings.yesButRestricted
                        : systemIsDemoReady === 'NO'
                        ? strings.no
                        : '' || ''}
                    </div>
                  )}
                </div>
                <div className='col-3 mt-3 p-0'>
                  <div className={classNames('checklist-filed-label', {'label-important': isDemoCheckListEdit & isSystemReadyDemo === 'no'})}>{strings.maintenanceComment}</div>
                  {isDemoCheckListEdit ? (
                    <div className='col-8 p-0'>
                      <InputField
                        field='systemIsDemoReadyComment'
                        placeholder=''
                        isRequired={true}
                        validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                        value={demoSystemIsReadyComment.value}
                        error={demoSystemIsReadyComment.error}
                        onChange={onChangeInput}
                      />
                    </div>
                  ) : (
                    <div className='checkList-value'>{systemIsDemoReadyComment || ''}</div>
                  )}
                </div>
                <div className='col-3 mt-3 p-0'>
                  <div className='checklist-filed-label'>{strings.dateAndNameOfUser}</div>
                  <div>{`${moment(demoChecklistCreatedOn).format('DD-MM-YYYY')}, ${
                    demoFormFilledUser && demoFormFilledUser.lastName
                  } ${demoFormFilledUser && demoFormFilledUser.firstName}`}</div>
                </div>
              </div>
              {isDemoCheckListEdit && (
                <div className='row mt-5 section-border'>
                  <div className='col-md-1'>
                    <Button
                      bgFill={true}
                      loading={loading}
                      value={strings.save}
                      onClick={() => saveMaintenanceCheckListForm('demo')}
                    />
                  </div>
                  <div className='col-md-1'>
                    <Button
                      bgFill={true}
                      value={strings.cancel}
                      onClick={() => cancelMaintenanceCheckListForm('demo')}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          {dismantleCompleteness !== null && (
            <div className='col-12 mt-4'>
              <div className='maintenanceEdit'>
                <div className='section-title'>{strings.dismantling}</div>
                {(dismantleFormFilledUser || hasPermissionToEditCheckList) && !isCheckedDateAdded && (
                  <div onClick={() => editCheckList('dismantle')} className='cursor-pointer'>
                    <svg width='18' height='18' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M1.42757 13.4697L1.85411 10.5451C1.88011 10.3539 1.96724 10.1761 2.10357 10.0391L10.368 1.76347C10.4751 1.65523 10.6083 1.57635 10.7548 1.53439C10.9012 1.49243 11.0559 1.4888 11.2042 1.52386C11.9627 1.73391 12.6504 2.14522 13.1942 2.71422C13.7616 3.26181 14.1701 3.95292 14.3761 4.71409C14.4112 4.86233 14.4076 5.01708 14.3656 5.16351C14.3236 5.30994 14.2448 5.44313 14.1365 5.5503L5.86299 13.8154C5.72561 13.9512 5.54776 14.0385 5.35634 14.0642L2.43243 14.4907C2.29431 14.5108 2.15338 14.4982 2.02108 14.4537C1.88879 14.4092 1.76884 14.3341 1.67096 14.2346C1.57309 14.1351 1.50004 14.0139 1.45776 13.8809C1.41547 13.7479 1.40513 13.6067 1.42757 13.469M8.80447 3.33751L12.573 7.10607'
                        stroke='#0088D0'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                    <span className='ml-2 mt-2 color-blue'>{strings.edit}</span>
                  </div>
                )}
              </div>
              <div className='row m-0 section-border'>
                <div className='col-3 mt-3 p-0 mb-5'>
                  <div className={classNames('checklist-filed-label', {'label-important': isDismantelCheckListEdit})}>{strings.dismantlingBy}</div>
                  {isDismantelCheckListEdit ? (
                    <div className='col-8 p-0'>
                      <Dropdown
                        data={usersList ? usersList : []}
                        hasSection={false}
                        multiSelect={false}
                        filter={true}
                        idKey='userId'
                        field='dismantleBy'
                        displayValue={[
                          { key: 'lastName', separator: ', ' },
                          { key: 'firstName', separator: ' ' },
                        ]}
                        isRequired
                        validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                        placeholder={strings.pleaseSelect}
                        onChange={onChangeDropdown}
                        value={isDismanteledBy.value}
                        fullValue={isDismanteledBy.fullValue}
                      />
                    </div>
                  ) : (
                    <div className='checkList-value'>
                      {isDismanteledBy.fullValue
                        ? `${isDismanteledBy.fullValue.lastName}, ${isDismanteledBy.fullValue.firstName}`
                        : ''}
                    </div>
                  )}
                </div>
                <div className='col-3 mt-3'>
                  <div className={classNames('checklist-filed-label', {'label-important': isDismantelCheckListEdit})}>{strings.maintenanceCompleteness}</div>
                  {isDismantelCheckListEdit ? (
                    <div className='col-8 p-0'>
                      <RadioButton
                        type={'radio-button'}
                        value={isDismantleCompleteness}
                        placeholder={''}
                        data={[
                          {
                            id: 'yes',
                            value: 'yes',
                          },
                          {
                            id: 'no',
                            value: 'no',
                          },
                        ]}
                        field='dismantleCompleteness'
                        isRequired={true}
                        validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                        onChange={onChangeRadioButton}
                      />
                    </div>
                  ) : (
                    <div className='checkList-value'>
                      {dismantleCompleteness === true
                        ? strings.yes
                        : dismantleCompleteness === false
                        ? strings.no
                        : '' || ''}
                    </div>
                  )}
                </div>
                {isDismantleCompleteness === 'no' && (
                  <div className='col-3 mt-3'>
                    <div className={classNames('checklist-filed-label', {'label-important': isDismantelCheckListEdit})}>{strings.maintenanceComment}</div>
                    {isDismantelCheckListEdit ? (
                      <div className='col-8 p-0'>
                        <InputField
                          field='dismantleCompletenessComment'
                          placeholder=''
                          isRequired={true}
                          validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                          value={isDismantleCompletenessComment.value}
                          error={isDismantleCompletenessComment.error}
                          onChange={onChangeInput}
                        />
                      </div>
                    ) : (
                      <div className='checkList-value'>{dismantleCompletenessComment || ''}</div>
                    )}
                  </div>
                )}
                <div className='col-3 mt-3'>
                  <div className={classNames('checklist-filed-label', {'label-important': isDismantelCheckListEdit})}>{strings.filesFromPCDeleted}</div>
                  {isDismantelCheckListEdit ? (
                    <div className='col-8 p-0'>
                      <RadioButton
                        type={'radio-button'}
                        value={isDeletedFiles}
                        placeholder={''}
                        data={[
                          {
                            id: 'yes',
                            value: 'yes',
                          },
                          {
                            id: 'no',
                            value: 'no',
                          },
                        ]}
                        field='dismantleFileDeleted'
                        isRequired={true}
                        validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                        onChange={onChangeRadioButton}
                      />
                    </div>
                  ) : (
                    <div className='checkList-value'>
                      {dismantleFileDeleted === true
                        ? strings.yes
                        : dismantleFileDeleted === false
                        ? strings.no
                        : '' || ''}
                    </div>
                  )}
                </div>
                {isDeletedFiles === 'no' && (
                  <div className='col-3 mt-3 p-0'>
                    <div className={classNames('checklist-filed-label', {'label-important': isDismantelCheckListEdit})}>{strings.maintenanceComment}</div>
                    {isDismantelCheckListEdit ? (
                      <div className='col-8 p-0'>
                        <InputField
                          field='dismantleFileDeletedComment'
                          placeholder=''
                          isRequired={true}
                          validators={[{ check: Validators.required, message: strings.requiredErrorMessage }]}
                          value={isDeletedFilesComment.value}
                          error={isDeletedFilesComment.error}
                          onChange={onChangeInput}
                        />
                      </div>
                    ) : (
                      <div className='checkList-value'>{dismantleFileDeletedComment || ''}</div>
                    )}
                  </div>
                )}
                <div className='col-3 mt-3 p-0'>
                  <div className='checklist-filed-label'>{strings.maintenanceDemoKit}</div>
                  {isDismantelCheckListEdit ? (
                    <div>
                      <Checkbox
                        label={strings.canBeShipped}
                        field='canBeShipped'
                        isRequired={true}
                        selected={isDismantleDemoKitConfirmation === 'CAN_BE_SHIPPED'}
                        onChange={onChangeCheckbox}
                      />
                      <Checkbox
                        label={strings.mustBeShipped}
                        field='mustBeShipped'
                        isRequired={true}
                        selected={isDismantleDemoKitConfirmation === 'MUST_BE_SHIPPED'}
                        onChange={onChangeCheckbox}
                      />
                      <Checkbox
                        label={strings.discussionWithProdCenter}
                        field='discussionWithProdCenter'
                        isRequired={true}
                        selected={isDismantleDemoKitConfirmation === 'DISCUSSION_WITH_PRODUCTION_CENTER'}
                        onChange={onChangeCheckbox}
                      />
                    </div>
                  ) : (
                    <div className='checkList-value'>
                      {isDismantleDemoKitConfirmation === 'CAN_BE_SHIPPED' && strings.canBeShipped}
                      {isDismantleDemoKitConfirmation === 'MUST_BE_SHIPPED' && strings.mustBeShipped}
                      {isDismantleDemoKitConfirmation === 'DISCUSSION_WITH_PRODUCTION_CENTER' &&
                        strings.discussionWithProdCenter}
                    </div>
                  )}
                </div>
              </div>
              {isDismantelCheckListEdit && (
                <div className='row mt-4 section-borde'>
                  <div className='col-md-1'>
                    <Button
                      bgFill={true}
                      // styleClass='show-all-messages-button'
                      loading={loading}
                      value={strings.save}
                      onClick={() => saveMaintenanceCheckListForm('dismantle')}
                    />
                  </div>
                  <div className='col-md-1'>
                    <Button
                      bgFill={true}
                      value={strings.cancel}
                      onClick={() => cancelMaintenanceCheckListForm('dismantle')}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div style={{ textAlign: 'center', marginTop: '3%' }}>{strings.noDataFoundText}</div>
      )}
    </div>
  );
};

const mapStateToProps = ({ authReducer, languageReducer, masterDataReducer, transactionDetailsReducer }) => ({
  language: languageReducer,
  transactionDetails: transactionDetailsReducer.transactionDetails,
  user: authReducer.user,
  usersList: masterDataReducer.usersList,
  maintenanceCheckListData: transactionDetailsReducer.maintenanceCheckListData,
  activeTranction: transactionDetailsReducer.activeTranction,
});

export default connect(mapStateToProps, {
  saveMantenanceCheckListData,
  toggleActionMessage,
  saveMaintenanceNewCheckListForm,
  getActiveTransaction,
})(MaintenanceCheckList);
