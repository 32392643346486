import SemBooking from 'modules/ZmccRequest/SEMBooking';
import React from 'react';

export const SemDemoRequestForm =({props, formType}) =>{;
    return (
        <div className='mt-4'>
            <SemBooking match={props.match} InformationData={props.InformationData} tabId={props.tabId} formType={formType}/>
        </div>
        
    )
} 

export default SemDemoRequestForm;