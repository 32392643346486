import React, { Component } from 'react';
import moment from 'moment';

import strings from 'resources/locales/Translate';
import Icon from 'library/common/commonComponents/Icon/Icon';
import LoanItem from 'modules/LoanList/components/LoanItem/LoanItem';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import Button from 'library/common/commonComponents/Button/Button';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import { scrollToTop } from 'library/utilities/scrollActions';
import AlternateSlotMessage from 'modules/Calendar/component/AlternateSlotMessage/AlternateSlotMessage';

import { loanListBreadcrumbData ,labsLocationLoanListBreadcrumbData} from './loanListBreadcrumbConstant';
import './loanListStyles.scss';

export default class LoanList extends Component {

  state = {
    kitName: null
  };

  componentDidMount() {
    const isZMCCEnabled = process.env.REACT_APP_IS_ZMCC_ENABLED === 'true';
    document.title = 'Loan List';
    this.props.fetchCartDataFromLocalStorage();
    isZMCCEnabled && this.props.getListOfUnexecutedSemTransaction();
  }

  goToCalendar = () => {
    const {history} = this.props;
    history.push('/calendar');
  };

  reserveKits = () => {
    const {cart, toggleActionMessage, history, reserveKit} = this.props;
    const selectedKits = cart.filter(item => item.isSelected === true && item.isZMCCCalendar === false);
    const isEmptyKitsArray = !selectedKits.length;
    let isDifferentPeriodsKits = false;
    const isStartDateAfterEndDate = selectedKits.every(item => moment(item.end).isAfter(item.start) && item.isSelected);
    if (isEmptyKitsArray) {
      scrollToTop(500);
      toggleActionMessage(true, 'error', 'selectKitsForBooking');
      return;
    }

    const isReservationAllowed = selectedKits.every(item => item.isTwentyFourReservationAllowed === true);

    if (!isStartDateAfterEndDate) {
      scrollToTop(500);
      toggleActionMessage(true, 'error', 'startDateGreaterThanEndDate');
      return;
    }

    if (!isReservationAllowed) {
      scrollToTop(500);
      toggleActionMessage(true, 'error', 'oneOrMoreKitsNotAllowedForReserve');
      return;
    } else {
      selectedKits.forEach(item => {
        if (selectedKits && selectedKits[0] && !(selectedKits[0].start === item.start && selectedKits[0].end === item.end)) return isDifferentPeriodsKits = true;
        return isDifferentPeriodsKits = false;
      });
      if (isDifferentPeriodsKits) {
        scrollToTop(500);
        toggleActionMessage(true, 'error', 'demoPeriodError');
        return;
      }
      scrollToTop(500);
      reserveKit(selectedKits, history);
    }
  };

  goToDemoKitLoan = () => {
    const { cart, toggleActionMessage, history } = this.props;
    const updatedCart = cart.filter(item => !item.isZMCCCalendar);
    const selectedKits = cart.filter(item => item.isSelected === true && !item.isZMCCCalendar );
    const isLabsLocation = selectedKits.find(item => item.isLabsLocation === true);
    const isRegular = selectedKits.find(item => !item.isLabsLocation);
    const url = isLabsLocation ? '/labs-location-kit-loan' : '/kit-loan';
    const isEmptyKitsArray = !selectedKits.length;
    let isDifferentPeriodsKits = false;
    let isAllKitsAreInAvailableSlot = true;
    const isStartDateAfterEndDate = selectedKits.every(item => moment(item.end).isAfter(item.start));
    const isDatesNotAdded = cart.every(item => item.start === null && item.end === null);
    updatedCart.forEach(item => {
      if (item.isSelected === true){
        if (selectedKits && selectedKits[0] && !(selectedKits[0].start === item.start && selectedKits[0].end === item.end)) return isDifferentPeriodsKits = true;
      }
      return isDifferentPeriodsKits = false;
    });

    updatedCart.forEach(item => {
      if (!item.isAvailabilityChecked) {
        isAllKitsAreInAvailableSlot = false;
        this.setState({kitName: item.kit.kitName});
        return false;
      } else {
        isAllKitsAreInAvailableSlot = true;
        this.setState({kitName: null});
      }
    });

    if (isDatesNotAdded) {
      scrollToTop(500);
      toggleActionMessage(true, 'error', 'addFullDetailsForLoanListItem');
      return;
    }
    if (isLabsLocation && isRegular) {
      scrollToTop(500);
      toggleActionMessage(true, 'error', 'slectedKitsAreNotAvailableForBooking');
      return;
    }

    if (!isStartDateAfterEndDate) {
      scrollToTop(500);
      toggleActionMessage(true, 'error', 'startDateGreaterThanEndDate');
      return;
    }

    if (!isAllKitsAreInAvailableSlot) {
      scrollToTop(500);
      toggleActionMessage(true, 'error', 'notInAvailableSlot');
      return;
    }

    if (isEmptyKitsArray) {
      scrollToTop(500);
      toggleActionMessage(true, 'error', 'selectKitsForBooking');

      return;
    }

    if (isDifferentPeriodsKits) {
      scrollToTop(500);
      toggleActionMessage(true, 'error', 'demoPeriodError');

      return;
    }

    if(this.isSameKitBookedOnSameDate() > 1) {
      scrollToTop(500);
      toggleActionMessage(true, 'error', 'alreadyAddedKitWithSameDate');

      return;
    }

    history.push(url);
  };

  isSameKitBookedOnSameDate() {
    const {cart} = this.props;
    let sameCount = 0;
    let counts = {};

    cart.forEach(item => {
      const id = item.resourceId;
      counts[id] = counts[id] ? counts[id] + 1 : 1;
    });

    for (let key in counts) {
      if (counts[key] > 1) {
        const sameBookingsForKit = cart.filter(item => parseInt(key, 10) === parseInt(item.resourceId, 10));
        for (let i = 0; i < sameBookingsForKit.length; i++) {
          sameCount = 0;
          for (let j = 0; j < sameBookingsForKit.length; j++) {
            if (moment(sameBookingsForKit[i].start).isSame(moment(sameBookingsForKit[j].start))
              || moment(sameBookingsForKit[i].end).isSame(moment(sameBookingsForKit[j].end))) {
              ++sameCount;
              if(sameCount > 1) {break;}
            }
          }
        }
      }
    }

    return sameCount;
  }

  get shouldEnableReserveButton() {
    const { cart } = this.props;
    return cart.some(item => item.isTwentyFourReservationAllowed === true)
  }

  enableReserveButton() {
    const{user,cart} = this.props;
    let isAllowed = false;
    if (!isAllowed && user && user.stockRoles && user.stockRoles.length) {
      let stockId = cart[0].kit && cart[0].kit.stockInformation.id;
      if (stockId) {
        user.stockRoles.forEach(role => {
          if (
            (role.roleName.toLowerCase() === 'stock controller' ||
              role.roleName.toLowerCase() === 'dispatcher' ||
              role.roleName.toLowerCase() === 'outbound staff' ||
              role.roleName.toLowerCase() === 'borrower') &&
            stockId === role.stockId
          ) {
            isAllowed = true;
          }
        });
      }
    }
    if (user && user.admin) {
      isAllowed = true;
    }
    return isAllowed;
  }

  checkIsRecentlyAdded = (itemAddedTime) => {
    //This method return true if object added just before 30mins
    let recentlyAdded = false
    let addedBefore = 1000
    if(!itemAddedTime){
      recentlyAdded = false
    }else{
      let now = (new Date()).getTime()
      try{
        let timeDiff = now - itemAddedTime;
        const differenceInMinutes = timeDiff / (1000 * 60);
        addedBefore = differenceInMinutes
        if(differenceInMinutes < 30){
          recentlyAdded = true
        }else{
          recentlyAdded = false
        }
      }catch(e){
        recentlyAdded = false
      }
    }
    return recentlyAdded
  }

  sortUpdatedCartOnAddedAtTimeStamp = (updatedCart) => {
    let sortedCart = []
    if(updatedCart && updatedCart?.length > 0){
      sortedCart = updatedCart.sort((item1, item2) => {
        if(item1?.addedAtTimeStamp && item2?.addedAtTimeStamp){
          return item2.addedAtTimeStamp - item1.addedAtTimeStamp;
        }else if(!item1?.addedAtTimeStamp && item2?.addedAtTimeStamp){
          return 1;
        }else if(item1?.addedAtTimeStamp && !item2?.addedAtTimeStamp){
          return -1;
        }else{
          return 0;
        }
      })
    }
    return sortedCart.length === updatedCart.length ? sortedCart : updatedCart;
  }

  renderActionButtons = () => {
    const { cart } = this.props;
    return(
      <div className='row d-flex align-items-center  loan-list-action'>
        <div className='col-md-6 mb-3'>
          <button className='create-more-booking-button' onClick={this.goToCalendar}>
            <Icon name='arrow' width={24} height={24} fill='#0088d0' className='create-more-booking-icon'/>
            <span>{strings.createMoreBooking}</span>
          </button>
        </div>

        <div className='col-md-6'>
          <div className='row d-flex justify-content-end mr-0'>
            {this.shouldEnableReserveButton && (
              <Button
                styleClass='reserve-button mr-md-2'
                disabled={cart.length === 0 || !this.enableReserveButton()}
                value={strings.reserve24}
                iconName='reserve'
                bgFill={false}
                onClick={this.reserveKits}
              />
            )}
            <Button
              styleClass='reserve-button mt-md-0'
              value={strings.continueBooking}
              iconName='arrow'
              disabled={cart.length === 0}
              onClick={this.goToDemoKitLoan}
            />
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {
      cart,
      toggleSelectionForKit,
      removeItemFromCart,
      updateDateOfCartItem,
      closeAlternateDateSuggestionMessage,
      language,
      allUnexecutedtransactions,
      removeLoanFromCart
    } = this.props;
    let updatedCart = cart;

    const updatedCartListForZmcc = [];

    if (allUnexecutedtransactions && allUnexecutedtransactions.length > 0){
      allUnexecutedtransactions.forEach(item => {
        item = JSON.parse(item);
        updatedCartListForZmcc.push({
          transactionsId : item.transactions.transactionsId,
          start: item.suggestedDate,
          end: item.dueDate,
          kit: item['kitInformation'],
          customerName: item.customerName,
          isZMCCCalendar: true,
          isZmccLmBooking: item.transactions.zmccLmBooking,
          isZmccSemBooking: item.transactions.zmccSemBooking,
          isZmccXbBooking: item.transactions.zmccXbBooking,
          isZmccXrmBooking: item.transactions.zmccXrmBooking,
          addedAtTimeStamp: item?.transactions?.editedAt
        });
      })
    }
    updatedCart = [...cart,...updatedCartListForZmcc];
    const {displayActionMessage, type, message} = this.props.appActions;
    const {displayMessage, from, to} = this.props.alternateMessageData;
    const {kitName} = this.state;
    const availabilityErrorMessage = message === 'notInAvailableSlot' ? strings.notInAvailableSlot.replace('{kitName}', kitName) : null;
    const isGerman = language.locale === 'de-DE' ? strings.loanListNoticeAddressTextNormal.split('/') : '';
    const isLabsLocation = this.props.location && this.props.location.pathname && this.props.location.pathname.toLowerCase().includes('/labs-location-loan-list');
    let indexForZMCC=0;
    let indexForDemoloan = 0;
    let indexForLabsLocations = 0;
    return (
      <div className='loan-list-container'>
        {displayActionMessage && <ActionMessage data-test='action-message' type={type}
                                                message={availabilityErrorMessage ? availabilityErrorMessage : strings[message] ? strings[message] : message}/>}

        {displayMessage && (
          <AlternateSlotMessage
            from={moment.utc(from).format('DD-MM-YYYY')}
            to={moment.utc(to).format('DD-MM-YYYY')}
            onClose={() => closeAlternateDateSuggestionMessage()}
          />
        )}
        <div className='container-fluid'>
          <Breadcrumbs data={isLabsLocation?labsLocationLoanListBreadcrumbData:loanListBreadcrumbData}/>
          <h2 className='page-title'>{isLabsLocation?strings.yourDemoRequestList:strings.yourLoanListHeader}</h2>

          {!isLabsLocation &&(<div className='loan-list-notice d-flex align-items-center'>
            <Icon name='info' width={32} height={32} fill='#000'/>
            <div className='notice-text'>
              {isGerman ? (
                <p>{isGerman[0]}<strong>{strings.loanListNoticeAddressTextBold}</strong>{isGerman[1]}</p>
              ) : (
                <p>{strings.loanListNoticeAddressTextNormal} <strong>{strings.loanListNoticeAddressTextBold}</strong></p>
              )}
              <p>{strings.loanListNoticeAddressWithBookingMessage}</p>
            </div>
          </div>)}

          {updatedCart.length > 0 && this.sortUpdatedCartOnAddedAtTimeStamp(updatedCart) && this.sortUpdatedCartOnAddedAtTimeStamp(updatedCart)?.length ?
            this.sortUpdatedCartOnAddedAtTimeStamp(updatedCart).map((item, key) => {
              if(item?.isLabsLocation){
                indexForLabsLocations = indexForLabsLocations+1;
              }else if(!item.isZMCCCalendar){
                indexForDemoloan = indexForDemoloan+1;
              }
              return !item.isZMCCCalendar && <LoanItem
                data={item}
                key={key}
                index={key + 1}
                toggleSelection={toggleSelectionForKit}
                deleteKit={item.isZMCCCalendar ?  removeLoanFromCart : removeItemFromCart}
                updateDate={updateDateOfCartItem}
                indexForZMCC={indexForZMCC}
                history={this.props.history}
                currentBooking={this.checkIsRecentlyAdded(item.addedAtTimeStamp)}
                indexForDemoLoans={indexForDemoloan}
                indexForLabsLocations={indexForLabsLocations}
              />
          }) : <></>
          }
          {cart.length !== 0 && this.renderActionButtons()}
          {updatedCart.length > 0 && this.sortUpdatedCartOnAddedAtTimeStamp(updatedCart) && this.sortUpdatedCartOnAddedAtTimeStamp(updatedCart)?.length ?
            this.sortUpdatedCartOnAddedAtTimeStamp(updatedCart).map((item, key) => {
              if (item.isZMCCCalendar){
                indexForZMCC+=1;
              }
              return item.isZMCCCalendar && <LoanItem
                data={item}
                key={key}
                index={key + 1}
                toggleSelection={toggleSelectionForKit}
                deleteKit={item.isZMCCCalendar ?  removeLoanFromCart : removeItemFromCart}
                updateDate={updateDateOfCartItem}
                indexForZMCC={indexForZMCC}
                history={this.props.history}
                currentBooking={this.checkIsRecentlyAdded(item.addedAtTimeStamp)}
                indexForDemoLoans={indexForDemoloan}
                indexForLabsLocations={indexForLabsLocations}
              />
          }) : <></>
          }
          {updatedCart.length === 0 && <div className='d-flex justify-content-center'>
              <strong>{strings.noKitAddedInCart}</strong>
          </div>}
          {/* {cart.length === 0 && this.renderActionButtons()} */}
          {!isLabsLocation && (<div className='loan-list-notice d-flex align-items-center mt-3'>
            <Icon name='info' width={32} height={32} fill='#000'/>
            <div className='notice-text'>
              <p>{strings.loanListBottomNoticeText1}</p>
              <p>{strings.loanListBottomNoticeText2}</p>
            </div>
          </div>)}
        </div>
      </div>
    );
  }
}
