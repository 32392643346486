import React from 'react';

import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import Button from 'library/common/commonComponents/Button/Button';
import strings from 'resources/locales/Translate';
import { LLPermissionsBreadcrumbData } from './LLPermissions.constants';

const LLPermissions = ({ appActions, saveData }) => {
  const { displayActionMessage, type, message } = appActions;
  return (
    <div>
      {displayActionMessage && <ActionMessage type={type} message={strings[message]} />}
      <div className='container-fluid no-gutters form-container'>
      <Breadcrumbs data={LLPermissionsBreadcrumbData} />
        <div className='col-sm-2'>
          <Button value={strings.actualizePermissions} iconName='save' onClick={saveData}/>
        </div>
      </div>
    </div>
  );
};

export default LLPermissions;
