import { connect } from 'react-redux';

import ServiceTicket from './ServiceTicket.component';

const mapStateToProps = state => {
  return {
    language: state.languageReducer.language,
  }
};

export default connect(mapStateToProps)(ServiceTicket);
