import { SET_NOTIFICATION_SETTINGS } from './NotificationSettings.constants';

const initialState = {
  notificationSettings: {},
};

const NotificationSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATION_SETTINGS:
      return {
        ...state,
        notificationSettings: action.payload,
      };
    default:
      return state;
  }
};

export default NotificationSettingsReducer;
