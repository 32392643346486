import React from 'react';

import Attachments from 'library/common/commonComponents/AttachmentsForm';
import Accessories from 'library/common/commonComponents/AccessoriesForm';
import Components from 'library/common/commonComponents/ComponentsForm';
import VRDetailsForm from 'modules/Kits/KitDetails/Components/VrDetails/VrDetails.componentForm';
import Information from './Information';
import ReparationSlots from 'modules/Kits/KitDetails/Components/ReparationSlots';
import NewCreateKitComponents from 'modules/Kits/CreateZmccKit/Components/NewComponentsTab';

export const createKitTabsConstant = ({
  history,
  isCopyKit,
  kitDetails,
  saveKit,
  cancelCreateKit,
  formValue,
  handleForm,
  kitInformationId,
  updateFormCreateKit,
  fetchKitDetail,
  toggleActionMessage,
  isVrKit,
  checkForShowingVRDetailsTab,
  deletereparationSlotForKit,
  addUpdateNewComponent, 
  fetchNewComponentsList, 
  deleteNewComponent,
  newComponentsForm,
  newComponentsList,
  handleComponentsForm,
  updateComponentsForm,
  isEditView,
  componentsFetchedFromIbase,
  ibaseComponentsList
}) => {
  
  if (isVrKit && checkForShowingVRDetailsTab()) {
    return [
      {
        id: 1,
        tabTitle: 'information',
        className: '',
        render: isActive => (
          <Information
            history={history}
            isActive={isActive}
            isCopyKit={isCopyKit}
            kitDetails={kitDetails}
            fetchKitDetail={fetchKitDetail}
          />
        ),
      },
      {
        id: 2,
        tabTitle: 'components',
        className: '',
        render: (isActive) => (
          (newComponentsList?.length || newComponentsList?.content?.length || kitDetails.kitCreatedThroughIbaseData || ibaseComponentsList.length)?
          <NewCreateKitComponents 
            history={history}
            kitDetails={kitDetails && kitDetails}
            saveKit={saveKit}
            formValue={{ newComponentsForm }}
            cancelCreateKit={cancelCreateKit}
            handleForm={handleComponentsForm}
            isOptional={true}
            addUpdateNewComponent={addUpdateNewComponent}
            fetchNewComponentsList={fetchNewComponentsList}
            newComponentsList={newComponentsList}
            updateForm={updateComponentsForm}
            toggleActionMessage={toggleActionMessage}
            deleteNewComponent={deleteNewComponent}
            isEditView={isEditView}
            componentsFetchedFromIbase={componentsFetchedFromIbase}
            ibaseComponentsList={ibaseComponentsList}
          /> 
          : 
          <Components
            history={history}
            isActive={isActive}
            isCopyKit={isCopyKit}
            kitDetails={kitDetails}
            saveKit={saveKit}
            formValue={formValue}
            cancelCreateKit={cancelCreateKit}
            handleForm={handleForm}
            toggleActionMessage={toggleActionMessage}
          />
        ),
      },
      {
        id: 3,
        tabTitle: 'accessories',
        className: '',
        render: isActive => (
          <Accessories
            history={history}
            isActive={isActive}
            isCopyKit={isCopyKit}
            kitDetails={kitDetails}
            saveKit={saveKit}
            formValue={formValue}
            cancelCreateKit={cancelCreateKit}
            handleForm={handleForm}
          />
        ),
      },
      {
        id: 4,
        tabTitle: 'vrDetails',
        className: '',
        render: isActive => (
          <VRDetailsForm 
            isActive={isActive}
            kitDetail={kitDetails} 
            history={history} 
            saveKit={saveKit}
            formValue={formValue}
            cancelCreateKit={cancelCreateKit}
            handleForm={handleForm}
          />
        )
      },
      {
        id: 5,
        tabTitle: 'attachments',
        className: '',
        render: isActive => (
          <Attachments
            history={history}
            isActive={isActive}
            isCopyKit={isCopyKit}
            kitDetails={kitDetails}
            saveKit={saveKit}
            formValue={formValue}
            cancelCreateKit={cancelCreateKit}
            kitInformationId={kitInformationId}
            handleForm={updateFormCreateKit}
            hasVideo={true}
            sectionWrapper={true}
            newVersionUi={true}
            uploaderStyle={"kit-attachments-bordered"}
            enhancedUi={true}
          />
        ),
      },
    ];
  
  }
  if (!!kitInformationId){
    return [
      {
        id: 1,
        tabTitle: 'information',
        className: '',
        render: isActive => (
          <Information
            history={history}
            isActive={isActive}
            isCopyKit={isCopyKit}
            kitDetails={kitDetails}
            fetchKitDetail={fetchKitDetail}
          />
        ),
      },

      {
        id: 2,
        tabTitle: 'components',
        className: '',
        render: (isActive) => (
          (newComponentsList?.length || newComponentsList?.content?.length || kitDetails.kitCreatedThroughIbaseData || ibaseComponentsList.length)?
          <NewCreateKitComponents 
            history={history}
            kitDetails={kitDetails && kitDetails}
            saveKit={saveKit}
            formValue={{ newComponentsForm }}
            cancelCreateKit={cancelCreateKit}
            handleForm={handleComponentsForm}
            isOptional={true}
            addUpdateNewComponent={addUpdateNewComponent}
            fetchNewComponentsList={fetchNewComponentsList}
            newComponentsList={newComponentsList}
            updateForm={updateComponentsForm}
            toggleActionMessage={toggleActionMessage}
            deleteNewComponent={deleteNewComponent}
            isEditView={isEditView}
            componentsFetchedFromIbase={componentsFetchedFromIbase}
            ibaseComponentsList={ibaseComponentsList}
           /> 
          :
          <Components
            history={history}
            isActive={isActive}
            isCopyKit={isCopyKit}
            kitDetails={kitDetails}
            saveKit={saveKit}
            formValue={formValue}
            cancelCreateKit={cancelCreateKit}
            handleForm={handleForm}
            toggleActionMessage={toggleActionMessage}
          />
        ),
      },
      {
        id: 3,
        tabTitle: 'accessories',
        className: '',
        render: isActive => (
          <Accessories
            history={history}
            isActive={isActive}
            isCopyKit={isCopyKit}
            kitDetails={kitDetails}
            saveKit={saveKit}
            formValue={formValue}
            cancelCreateKit={cancelCreateKit}
            handleForm={handleForm}
          />
        ),
      },
      {
        id: 4,
        tabTitle: 'attachments',
        className: '',
        render: isActive => (
          <Attachments
            history={history}
            isActive={isActive}
            isCopyKit={isCopyKit}
            kitDetails={kitDetails}
            saveKit={saveKit}
            formValue={formValue}
            cancelCreateKit={cancelCreateKit}
            kitInformationId={kitInformationId}
            handleForm={updateFormCreateKit}
            hasVideo={true}
            sectionWrapper={true}
            newVersionUi={true}
            uploaderStyle={"kit-attachments-bordered"}
            enhancedUi={true}
          />
        ),
      },
      {
        id: 5,
        tabTitle: 'reparationSlots',
        className: '',
        render: isActive => (
           <ReparationSlots 
            history={history}
            kitDetail={kitDetails}
            isActive={isActive}
            deletereparationSlotForKit={deletereparationSlotForKit}
          />   
  
        )
  
      }
    ]
  }
  return [
    {
      id: 1,
      tabTitle: 'information',
      className: '',
      render: isActive => (
        <Information
          history={history}
          isActive={isActive}
          isCopyKit={isCopyKit}
          kitDetails={kitDetails}
          fetchKitDetail={fetchKitDetail}
        />
      ),
    },

    {
      id: 2,
      tabTitle: 'components',
      className: '',
      render: (isActive) => (
        <NewCreateKitComponents 
          history={history}
          kitDetails={kitDetails && kitDetails}
          saveKit={saveKit}
          formValue={{ newComponentsForm }}
          cancelCreateKit={cancelCreateKit}
          handleForm={handleComponentsForm}
          isOptional={true}
          addUpdateNewComponent={addUpdateNewComponent}
          fetchNewComponentsList={fetchNewComponentsList}
          newComponentsList={newComponentsList}
          updateForm={updateComponentsForm}
          toggleActionMessage={toggleActionMessage}
          deleteNewComponent={deleteNewComponent}
          isEditView
          componentsFetchedFromIbase={componentsFetchedFromIbase}
          ibaseComponentsList={ibaseComponentsList}
        />
      ),
    },
    {
      id: 3,
      tabTitle: 'accessories',
      className: '',
      render: isActive => (
        <Accessories
          history={history}
          isActive={isActive}
          isCopyKit={isCopyKit}
          kitDetails={kitDetails}
          saveKit={saveKit}
          formValue={formValue}
          cancelCreateKit={cancelCreateKit}
          handleForm={handleForm}
        />
      ),
    },
    {
      id: 4,
      tabTitle: 'attachments',
      className: '',
      render: isActive => (
        <Attachments
          history={history}
          isActive={isActive}
          isCopyKit={isCopyKit}
          kitDetails={kitDetails}
          saveKit={saveKit}
          formValue={formValue}
          cancelCreateKit={cancelCreateKit}
          kitInformationId={kitInformationId}
          handleForm={updateFormCreateKit}
          hasVideo={true}
          sectionWrapper={true}
          newVersionUi={true}
          uploaderStyle={"kit-attachments-bordered"}
          enhancedUi={true}
        />
      ),
    },
  ];
};
