import { useState, useEffect, useCallback } from 'react';
import $ from 'jquery';

import { validateForm } from 'library/utilities/ValidateForm.util';
import { checkStockRoles } from 'library/utilities/checkRoles';
import { DUST_USER, DUST_MANAGER } from 'library/common/commonConstants/roles';

import { commentModel } from '../CreatingOrEditingComment/CreatingOrEditingComment.constants';

export const useDemoUnitStatus = ({
  kitDetail,
  user,
  createDemoUnitComment,
  updateDemoUnitComment,
  changeDemoUnitStatusTask,
  fetchKitDetail,
}) => {
  const userName = user ? `${user.firstName} ${user.lastName}` : '';
  const userId = user && user.userId;
  const hasIBase = kitDetail.ibase;
  const [creatingCommentForm, setCreatingCommentForm] = useState({
    formData: {
      yourName: userName,
      date: new Date(),
    },
  });
  const [isCreationMode, setIsCreationMode] = useState(false);
  const [currentEditableComment, setCurrentEditableComment] = useState(null);
  const [editingCommentForm, setEditingCommentForm] = useState({ formData: {} });
  const [isCreatLoader, setIsCreatLoader] = useState(false);
  const [isEditLoader, setIsEditLoader] = useState(false);
  const isAdmin = user && user.admin;
  const isServiceTechnician = user && user.globalRoles.includes(DUST_USER);
  const isDUSDManager = user && user.globalRoles.includes(DUST_MANAGER);
  const roles = ['Reconditioning', 'Stock Controller'];
  const isReconditioningOrStockController = user && kitDetail.stockInformation && checkStockRoles(roles, user, kitDetail.stockInformation);
  const isDisabledUncheckedTasks = !isAdmin && !isServiceTechnician && !isReconditioningOrStockController;
  const isDisableCreationgComment = !(isAdmin || isReconditioningOrStockController || isServiceTechnician || isDUSDManager) || !kitDetail.systemClassActive;
 
  useEffect(() => {
    if (currentEditableComment) {
      const { updatedOn, createdBy, text, kitDUSDCommentStatus } = currentEditableComment;
      const yourName = `${createdBy.firstName} ${createdBy.lastName}`
  
      setEditingCommentForm({
        formData: {
          yourName,
          date: new Date(updatedOn),
          text,
          status: kitDUSDCommentStatus ? kitDUSDCommentStatus.code : null,
        },
      });
    }
  }, [currentEditableComment]);

  const toggleCreationMode = useCallback(() => {
    setIsCreationMode(prevState => !prevState);
    setCreatingCommentForm({
      formData: {
        yourName: userName,
        date: new Date(),
      },
    });
  }, [userName]);

  const handleCreatingForm = useCallback(({ values }) => {
    setCreatingCommentForm(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        ...values,
      },
    }))
  }, []);

  const handleCreateComment = useCallback(async () => {
    const validCreatingCommentForm = validateForm({
      form: creatingCommentForm,
      model: commentModel,
    });

    if (!validCreatingCommentForm.isFormValid) {
      return setCreatingCommentForm(validCreatingCommentForm);
    }

    const { kitInformationId } = kitDetail;
    const dataToSend = {
      kitInformationId,
      text: creatingCommentForm.formData.text,
      status: creatingCommentForm.formData.status,
    };

    setIsCreatLoader(true);
    await createDemoUnitComment(dataToSend, kitInformationId);
    setIsCreatLoader(false);
    setCurrentEditableComment(null);
    setIsCreationMode(false);
  }, [creatingCommentForm, kitDetail, createDemoUnitComment]);

  const cancelEditingComment = useCallback(() => {
    setCurrentEditableComment(null);
  }, []);

  const showEditingComment = useCallback(comment => {
    setCurrentEditableComment(comment);
  }, []);

  const handleEditingCommentForm = useCallback(({ values }) => {
    setEditingCommentForm(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        ...values,
      },
    }))
  }, []);

  const handleEditComment = useCallback(async () => {
    const validEditingCommentForm = validateForm({
      form: editingCommentForm,
      model: commentModel,
    });

    if (!validEditingCommentForm.isFormValid) {
      return setEditingCommentForm(validEditingCommentForm);
    }

    const { kitInformationId } = kitDetail;
    const dataToSend = {
      kitInformationId,
      text: editingCommentForm.formData.text,
      status: editingCommentForm.formData.status,
      commentId: currentEditableComment.id,
    };

    setIsEditLoader(true);
    await updateDemoUnitComment(dataToSend, kitInformationId);
    setIsEditLoader(false);
    setCurrentEditableComment(null);
  }, [editingCommentForm, currentEditableComment, kitDetail, updateDemoUnitComment]);

  const handleTaskStatus = useCallback((status, demoUnitTaskId) => {
    const { kitInformationId } = kitDetail;
    const dataToSend = {
      demoUnitTaskId,
      status: status === true ? 'COMPLETED' : 'NEW',
      kitInformationId,
    };

    changeDemoUnitStatusTask(dataToSend, kitInformationId);
  }, [kitDetail, changeDemoUnitStatusTask]);

  const showCreateServiceTicketPopup = useCallback(() => {
    const popupId = `#createServiceTicket${kitDetail.kitInformationId}`;

    if ($(popupId) && $(popupId).modal) {
      $(popupId).modal('show');
    }
  }, [kitDetail]);

  const handleFetchKitDetails = useCallback(() => {
    const { kitInformationId } = kitDetail;

    fetchKitDetail(kitInformationId);
  }, [kitDetail, fetchKitDetail]);

  return {
    creatingCommentForm,
    isCreationMode,
    currentEditableComment,
    editingCommentForm,
    isCreatLoader,
    isEditLoader,
    isDisabledUncheckedTasks,
    isDisableCreationgComment,
    userId,
    hasIBase,
    toggleCreationMode,
    cancelEditingComment,
    showEditingComment,
    handleCreatingForm,
    handleTaskStatus,
    handleCreateComment,
    handleEditingCommentForm,
    handleEditComment,
    showCreateServiceTicketPopup,
    handleFetchKitDetails,
  };
}
