import { useEffect, useState, useCallback } from 'react';
import $ from 'jquery';

export const useSystemClassesSubscriptions = ({
  getSystemClassSubscriptions,
  deleteSubscription,
  getEmailTypes,
  language,
}) => {
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [isLoadingDeletion, setIsLoadingDeletion] = useState(false);
  const [deletionError, setDeletionError] = useState('');

  useEffect(() => {
    getEmailTypes();
  }, [getEmailTypes, language]);

  useEffect(() => {
    getSystemClassSubscriptions();
  }, [getSystemClassSubscriptions]);

  const openCreateSubscriptionPopup = useCallback(() => {
    $('#create-subscription-popup').modal('show');
  }, []);

  const openEditSubscriptionPopup = useCallback(() => {
    $('#edit-subscription-popup').modal('show');
  }, []);

  const openDeleteSubscriptionPopup = useCallback(() => {
    $('#confirm-delete-subscription-modal').modal('show');
  }, []);

  const hideDeleteSubscriptionPopup = useCallback(() => {
    setSelectedSubscription(null);
  }, []);

  const handleEditSubscription = useCallback(subscription => {
    setSelectedSubscription(subscription);
    openEditSubscriptionPopup();
  }, [openEditSubscriptionPopup]);

  const handleDeleteSubscription = useCallback(subscription => {
    setSelectedSubscription(subscription);
    openDeleteSubscriptionPopup();
  }, [openDeleteSubscriptionPopup]);

  const confirmSubscriptionDeletion = useCallback(async () => {
    if (deletionError) {
      setDeletionError('');
    }
    setIsLoadingDeletion(true);

    const { success } = await deleteSubscription(selectedSubscription.subscriptionId);

    if (success) {
      hideDeleteSubscriptionPopup();
    } else {
      setDeletionError('somethingWentWrongMessage');
    }
    setIsLoadingDeletion(false);
  }, [selectedSubscription, deleteSubscription, deletionError, hideDeleteSubscriptionPopup]);


  return {
    openCreateSubscriptionPopup,
    handleEditSubscription,
    selectedSubscription,
    setSelectedSubscription,
    handleDeleteSubscription,
    isLoadingDeletion,
    hideDeleteSubscriptionPopup,
    confirmSubscriptionDeletion,
  };
};
