import React from 'react';
import { NavLink } from 'react-router-dom';
import Icon from 'library/common/commonComponents/Icon/Icon';
import strings from 'resources/locales/Translate';
import '../DeleteButton/DeleteButton.styles.scss';

const EditButton = ({ dataTest, link, to, text, onClick }) => {
  if (link) {
    return (
      <NavLink to={link || to} data-test={dataTest}>
        <button className='button-blue d-flex flex-row'>
          <Icon name='edit' width={16} height={16} fill='#0088d0' />
          <span className='ml-1'>{text ? text : strings.edit}</span>
        </button>
      </NavLink>
    );
  }
  return (
    <button className='button-blue d-flex flex-row' onClick={onClick} data-test={dataTest}>
      <Icon name='edit' width={16} height={16} fill='#0088d0' />
      <span className='ml-1'>{text ? text : strings.edit}</span>
    </button>
  );
};

export default EditButton;
