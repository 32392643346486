import React, { Fragment, useEffect, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Calendar, Views, DateLocalizer, momentLocalizer, Navigate } from 'react-big-calendar';
import { useMySchedule } from './MySchedule.hook';
import Button from 'library/common/commonComponents/Button/Button';
import strings from 'resources/locales/Translate';
import AddSchedulePupup from './AddSchedulePopup/AddSchedulePopup.componet';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import DatePicker from 'react-date-picker';
import '../../../../../AppSchedular/components/RightHeader/rightHeaderStyles.scss';
import Icon from 'library/common/commonComponents/Icon/Icon';
import ViewAndEditSchedule from './ViewAndEditSchedulePopup/ViewAndEditSchedule.compoent';
import RecurringEventOptionsPopup from './RecurringEventsOptions/RecurringEventsPopup.component';

const mLocalizer = momentLocalizer(moment);

const ColoredDateCellWrapper = ({ children }) => React.cloneElement(React.Children.only(children), {});

export const MySchedule = ({
  localizer = mLocalizer,
  showDemoLink = true,
  scheduledEventsList,
  user,
  getAllScheduledEventList,
  appActions,
  language,
  getScheduledDetailes,
  scheduledDetails,
  deleteSchedule,
  ...props
}) => {
  const { openMyShedule, onClickEvent, onEditClick, editing, convetTime, setEditing, eventPropGetter } = useMySchedule({
    getScheduledDetailes,
  });
  const { displayActionMessage, type, message, showAsIs } = appActions;
  const [list, setList] = useState([]);
  const [displayDate, setDisplayDate] = useState('');
  const [payloadDataForEventPopup, setPayloadDataForEventPopup] = useState(null);

  const dateConverter = (date, index) => {
    let year = moment(date).format('YYYY');
    let monthIdx = moment(date).format('MM');
    let selectedDate = moment(date).format('DD');
    return index == 0 ? year : index == 1 ? monthIdx : selectedDate;
  };

  const timeConverter = (time, index) => {
    let startTimeHr = time && time.substring(0, 2);
    let startTimeMin = time && time.substring(3);
    return index == 0 ? startTimeHr : startTimeMin;
  };

  const businessTravelCode = () => {
    return <>&#128992; {strings.businessTravel}</>;
  };
  const wfhCode = () => {
    return <>&#128993; {strings.workFromHome}</>;
  };
  const otherCode = () => {
    return <>&#128309; {strings.otherType}</>;
  };
  const vaccationCode = () => {
    return <>&#128308; {strings.vacation}</>;
  };

  const emptyCode = () => {
    return <>&#128308; {strings.transactions}</>;
  };

  const dataNormalization = (scheduledEventsList, scheduledDate, startTime, endTime) => {
    let id = scheduledEventsList.operatorsScheduleId;
    let isDemo = false
    let title =
      scheduledEventsList &&
      scheduledEventsList.zmccScheduleType != null ? (
      scheduledEventsList.zmccScheduleType.staticValue === 'OTHER'
        ? otherCode()
        : scheduledEventsList.zmccScheduleType.staticValue === 'VACATION'
        ? vaccationCode()
        : scheduledEventsList.zmccScheduleType.staticValue === 'BUSINESS_TRAVEL'
        ? businessTravelCode()
        : wfhCode())
        : emptyCode();

    if(scheduledEventsList && scheduledEventsList?.transactionDetails){
      // title =  <NavLink to={`/transactions/${scheduledEventsList?.transactionDetails.transactionsId}/isZmcc?detailPage=1`} >`${scheduledEventsList?.transactionDetails?.name || "Demo"}`</NavLink>
      title = `${scheduledEventsList?.transactionDetails?.name || "Demo"}`
      isDemo = true
    }

    let year = dateConverter(scheduledDate, 0);
    let monthIdx = dateConverter(scheduledDate, 1);
    let date = dateConverter(scheduledDate, 2);
    let startTimeHr = timeConverter(startTime, 0);
    let startTimeMin = timeConverter(startTime, 1);
    let endTimeHr = timeConverter(endTime, 0);
    let endTimeMin = timeConverter(endTime, 1);
    let data = {
      id,
      title,
      start: new Date(year, monthIdx - 1, date, startTimeHr, startTimeMin, 0),
      end: new Date(year, monthIdx - 1, date, endTimeHr, endTimeMin, 0),
      isDemo,
      transactionId: scheduledEventsList?.transactionDetails?.transactionsId
    };
    return data;
  };

  const dataArray = () => {
    let listItems = [];
    if (scheduledEventsList && scheduledEventsList.length > 0) {
      for (let i = 0; i < scheduledEventsList.length; i++) {
        listItems.push(
          dataNormalization(
            scheduledEventsList[i],
            scheduledEventsList[i].scheduledDate,
            scheduledEventsList[i].startTime,
            scheduledEventsList[i].endTime,
          ),
        );
      }
      setList(listItems);
    }
  };

  useEffect(() => {
    dataArray();
  }, [scheduledEventsList, language]);

  const CustomToolbar = toolbar => {
    const [isCalendarVisible, setIsCalendarVisible] = useState(false);
    const goToBack = () => {
      toolbar.date.setDate(toolbar.date.getDate() - 7);
      toolbar.onNavigate('prev');
    };

    const goToNext = () => {
      toolbar.date.setDate(toolbar.date.getDate() + 7);
      toolbar.onNavigate('next');
    };

    const setCalendarDate = e => {
      setIsCalendarVisible(!isCalendarVisible);
      const modifiedDate = new Date(e);
      setDisplayDate(modifiedDate);
      toolbar.date.setDate(modifiedDate.getDate());
      toolbar.date.setMonth(modifiedDate.getMonth());
      toolbar.date.setYear(modifiedDate.getFullYear());
      toolbar.onNavigate('current');
    };

    const goToCurrent = () => {
      const now = new Date();
      toolbar.date.setDate(now.getDate());
      toolbar.date.setMonth(now.getMonth());
      toolbar.date.setYear(now.getFullYear());
      toolbar.onNavigate('current');
    };
    const onToggleCalendar = state => {
      setIsCalendarVisible(!state);
    };

    const label = () => {
      const date = moment(toolbar.date);
      return (
        <span>
          <div className='app-datepicker cursor-pointer' style={{ border: 'none' }}>
            <div
              className={
                'd-flex datepicker-view align-items-center text-overflow-ellipsis' +
                (isCalendarVisible && 'calendar-shadow')
              }
              onClick={() => onToggleCalendar(isCalendarVisible)}
            >
              <Icon name='calendar' width={24} height={24} fill={'rgba(0,0,0,0.5)'} />
              <h4 className='current-date-my-schudle'>{moment(date).format('MMMM')}</h4>
              <h4 className='current-date-my-schudle'>{moment(date).format('YYYY')}</h4>
            </div>
            <DatePicker
              minDetail='decade'
              className='datepicker-component-container'
              calendarClassName='datepicker-calendar-container calendar-shadow'
              clearIcon={null}
              showLeadingZeros={true}
              isOpen={isCalendarVisible}
              onChange={setCalendarDate}
              value={displayDate}
              {...props}
            />
          </div>
        </span>
      );
    };

    return (
      <div className={'toolbar-container'}>
        <label className={'label-date'}>{label()}</label>

        <div className={'back-next-buttons'}>
          <button className={'btn-back'} onClick={goToBack}>
            &#8249;
          </button>
          <button className={'btn-current'} onClick={goToCurrent}>
            Today
          </button>
          <button className={'next'} onClick={goToNext}>
            &#8250;
          </button>
          <Button bgFill={false} value={strings.add} iconName='plus' onClick={openMyShedule} />
        </div>
      </div>
    );
  };
  const { components, defaultDate, views } = useMemo(
    () => ({
      components: {
        timeSlotWrapper: ColoredDateCellWrapper,
        toolbar: CustomToolbar,
      },
      defaultDate: new Date(),
      views: [Views.WEEK],
    }),
    [],
  );
  const today = new Date();

  // start time 8:00am
  const min = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8);

  // end time 8:00pm
  const max = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 18, 0);

  return (
    <Fragment>
      {displayActionMessage && <ActionMessage type={type} message={strings[message] || message} />}
      <AddSchedulePupup
        id={'add-my-schedule'}
        editing={editing}
        onEditClick={e => onEditClick(e)}
        convetTime={e => convetTime(e)}
        setPayloadDataForEventPopup={e => setPayloadDataForEventPopup(e)}
        setEditing={e => setEditing(e)}
      />
      <ViewAndEditSchedule id={'view-edit-schedule'} onEditClick={e => onEditClick(e)} />
      <RecurringEventOptionsPopup
        id={'show-recurring-event-options'}
        payloadDataForEventPopup={payloadDataForEventPopup}
        editing={editing}
        scheduledDetails={scheduledDetails}
        deleteSchedule={deleteSchedule}
        setEditing={e => setEditing(e)}
      />
      {/* {showDemoLink ? <DemoLink fileName="basic" /> : null} */}
      <div className='calendar-container ' {...props}>
        <Calendar
          components={components}
          defaultDate={defaultDate}
          defaultView={Views.WEEK}
          events={list}
          localizer={localizer}
          max={max}
          showMultiDayTimes={true}
          step={15}
          views={views}
          tooltipAccessor={null}
          dayLayoutAlgorithm={'no-overlap'}
          min={min}
          onSelectEvent={onClickEvent}
          eventPropGetter={eventPropGetter}
        />
      </div>
    </Fragment>
  );
};

export default MySchedule;
