import React from "react";
import {Redirect, Route, withRouter} from "react-router-dom";

import Layout from "../Layout/Layout";

const PrivateRoute = withRouter(({isLoggedIn, component: Component, ...rest}) => {
  const componentCheck = (props) => {
    if (isLoggedIn) {
      return <Layout {...props}><Component {...props}/></Layout>
    } else {
      return <Redirect to={{
        pathname: '/',
        state: {from: props.location}
      }}/>
    }
  };

  return (
    <Route {...rest} render={(props) => (
      componentCheck(props)
    )}/>
  )
});

export default PrivateRoute;
