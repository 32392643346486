import React from 'react';

import FolderListView from '../FolderListView';
import FolderGridView from '../FolderGridView';

const Folder = ({ folder, user, viewType, parentFolderId, openFolder, deleteFolder, openEditFolderPopup, setFolder }) => {
  const handleOpenFolder = () => {
    openFolder(prevState => [ ...prevState, { id: folder.id, name: folder.name }]);
  };

  const handleDelete = e => {
    e.stopPropagation();
    deleteFolder(folder.id, parentFolderId)
  };

  const handleOpenEditFolderPopup = e => {
    e.stopPropagation();
    setFolder(folder);
    openEditFolderPopup();
  };

  return (
    viewType === 'list' ? (
      <FolderListView
        folder={folder}
        user={user}
        handleOpenFolder={handleOpenFolder}
        handleDelete={handleDelete}
        handleOpenEditFolderPopup={handleOpenEditFolderPopup}
      />
    ) : (
      <FolderGridView
        folder={folder}
        user={user}
        handleOpenFolder={handleOpenFolder}
        handleDelete={handleDelete}
        handleOpenEditFolderPopup={handleOpenEditFolderPopup}
      />
    )
  );
};

export default Folder;
