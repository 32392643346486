import { connect } from 'react-redux';

import {
  getStocksByBussinessUnitandCountryIds,
  getSystemClassesByBU,
  fetchKitStatusChartStatistics,
  clearKitStatusChartStatistics,
  fetchKitDataStatistics,
  clearKitDataStatistics,
  fetchUtilizedKits,
  fetchWorkingCapitalInStock,
  clearWorkingCapitalInStock,
} from '../../Statistics.actions';
import KitDataTab from './KitDataTab.component';

const mapStateToProps = ({ authReducer, languageReducer, statisticsReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
  stocksKitData: statisticsReducer.stocksKitData,
  systemClassKitData: statisticsReducer.systemClassKitData,
  kitStatusChartStatistics: statisticsReducer.kitStatusChartStatistics,
  kitDataStatistics: statisticsReducer.kitDataStatistics,
  utilizedKits: statisticsReducer.utilizedKits,
  workingCapitalInStockData: statisticsReducer.workingCapitalInStockData,
});

export default connect(mapStateToProps, {
  getStocksByBussinessUnitandCountryIds,
  getSystemClassesByBU,
  fetchKitStatusChartStatistics,
  clearKitStatusChartStatistics,
  fetchKitDataStatistics,
  clearKitDataStatistics,
  fetchUtilizedKits,
  fetchWorkingCapitalInStock,
  clearWorkingCapitalInStock,
})(KitDataTab);
