import React from 'react';
import moment from 'moment';
import cn from 'classnames';

import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import DateInput from 'library/common/commonComponents/FormComponent/Optimized/DateInput';
import strings from 'resources/locales/Translate';

import { useDemoActivityAndKitDataFilter } from './DemoActivityAndKitDataFilter.hook';

const DemoActivityAndKitDataFilter = ({
  isShowFilter,
  businessUnitsForkits,
  countries,
  loanTypes,
  stocks,
  systemClass = [],
  filters,
  setFilters,
  isKitDataTab,
}) => {
  const {
    handleDateCange,
    handleFilter,
  } = useDemoActivityAndKitDataFilter({ filters, setFilters });

  return (
    <div className={cn('filter-wrapper mt-3', { 'd-block': isShowFilter, 'd-none': !isShowFilter })}>
      <div className='container-fluid'>
        <div className='row'>
          <Dropdown
            label={strings.businessUnit}
            data={businessUnitsForkits}
            field='businessGroup'
            idKey='id'
            displayValue={[{ key: 'name', separator: '' }]}
            isRequired={true}
            placeholder={strings.pleaseSelect + '...'}
            onChange={handleFilter}
            mainContainerStyle={cn('mb-3', { 'col-lg-4': isKitDataTab, 'col-lg-3': !isKitDataTab })}
            value={filters.businessGroup}
            fullValue={filters.businessGroupFullValue}
          />
          <Dropdown
            label={strings.countryLand}
            data={countries}
            filter
            field='country'
            idKey='id'
            displayValue={[{ key: 'name', separator: '' }]}
            isRequired={false}
            multiSelect={true}
            placeholder={strings.pleaseSelect + '...'}
            onChange={handleFilter}
            mainContainerStyle={cn('mb-3', { 'col-lg-4': isKitDataTab, 'col-lg-3': !isKitDataTab })}
            value={filters.country}
            fullValue={filters.countryFullValue}
          />
          <Dropdown
            label={strings.stock}
            data={stocks}
            selectAllOption
            selectAllPlaceholder={strings.selectAll}
            idKey='id'
            field='stock'
            displayValue={[{ key: 'locationName', separator: '' }]}
            isRequired={false}
            multiSelect={true}
            placeholder={strings.pleaseSelect + '...'}
            onChange={handleFilter}
            mainContainerStyle={cn('mb-3', { 'col-lg-4': isKitDataTab, 'col-lg-3': !isKitDataTab })}
            value={filters.stock}
            fullValue={filters.stockFullValue}
          />
          <Dropdown
            label={strings.systemClassText}
            data={systemClass}
            hasSection
            selectAllOption
            selectAllPlaceholder={strings.selectAll}
            field='systemClass'
            idKey='systemMainComponentId'
            displayValue={[{ key: 'systemMainComponentName', separator: '' }]}
            optionsArrayKey='systemMainComponent'
            titleDisplay={[{ key: 'systemClassName', separator: '' }]}
            isRequired={false}
            multiSelect={true}
            placeholder={strings.pleaseSelect + '...'}
            onChange={handleFilter}
            mainContainerStyle={cn('mb-3', { 'col-lg-6': isKitDataTab, 'col-lg-3': !isKitDataTab })}
            value={filters.systemClass}
            fullValue={filters.systemClassFullValue}
          />
          <DateInput
            label={strings.dateRange}
            field='dateRange'
            placeholder={strings.pleaseSelectDate + '...'}
            onChange={handleDateCange}
            selectedDate={filters.dateRange}
            isRequired={true}
            mainContainerStyle='col-lg-6 mb-3'
            isRange={true}
            clearIcon={true}
            minDate={moment().subtract(10, 'years')}
            maxDate={isKitDataTab ? moment() : moment().add(1, 'years')}
          />
          {!isKitDataTab && (
            <Dropdown
              label={strings.loanType}
              data={loanTypes}
              selectAllOption
              selectAllPlaceholder={strings.selectAll}
              idKey='id'
              field='loanTypes'
              displayValue={[{ key: 'name', separator: '' }]}
              multiSelect={false}
              placeholder={strings.pleaseSelect + '...'}
              onChange={handleFilter}
              mainContainerStyle='col-lg-6 mb-3'
              value={filters.loanTypes}
              fullValue={filters.loanTypesFullValue}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default DemoActivityAndKitDataFilter;
