import { connect } from 'react-redux';

import DemoUnitStatus from './DemoUnitStatus.component';
import {
  createDemoUnitComment,
  updateDemoUnitComment,
  changeDemoUnitStatusTask,
  createEditionalComment,
  fetchKitDetail,
  deleteEditionalComment,
} from '../../KitDetailsAction';

const mapStateToProps = ({ authReducer, kitsListReducer, languageReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
});

export default connect(mapStateToProps, {
  createDemoUnitComment,
  updateDemoUnitComment,
  changeDemoUnitStatusTask,
  createEditionalComment,
  fetchKitDetail,
  deleteEditionalComment,
})(DemoUnitStatus);
