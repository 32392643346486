import { useEffect, useState } from 'react';
import { editAddressModel } from '../MedUsWarehouseAddress.constants';
import { validateForm } from 'library/utilities/ValidateForm.util';
import $ from 'jquery';

export const useEditMedAddressPopup = ({
  id,
  selectedAddress,
  countries,
  editMedUsAddress,
  addressListPayloadData,
}) => {
  const [addressForm, setAddressForm] = useState({ formData: {} });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedAddress) {
      setDefaultDataInPopup();
    }
  }, [selectedAddress]);

  const setDefaultDataInPopup = () => {
    setAddressForm({
      formData: {
        salutation: selectedAddress.salutation,
        firstName: selectedAddress.firstName,
        lastName: selectedAddress.lastName,
        organization: selectedAddress.organization,
        department: selectedAddress.department,
        street: selectedAddress.street,
        houseNumber: selectedAddress.houseNumber,
        postalCode: selectedAddress.postalCode,
        state: selectedAddress.state,
        city: selectedAddress.city,
        phone: selectedAddress.phone,
        country: selectedAddress.country.id,
        countryFullValue: selectedAddress.country,
        email: selectedAddress.email,
      },
    });
  };

  const addressModel = () => {
    const model = [...editAddressModel];
    model[11].options = countries;
    return model;
  };

  const handleForm = ({ values, field }) => {
    setAddressForm({
      ...addressForm,
      formData: {
        ...addressForm.formData,
        ...values,
      },
    });
  };

  const onSaveClick = async () => {
    const validForm = validateForm({
      form: addressForm,
      model: editAddressModel,
    });

    if (!validForm.isFormValid) {
      return setAddressForm(validForm);
    }
    const medWarehouseId = selectedAddress.meditechUsAddressId;
    const {
      salutation,
      firstName,
      lastName,
      organization,
      department,
      street,
      houseNumber,
      postalCode,
      state,
      city,
      phone,
      countryFullValue,
      email,
    } = validForm.formData;
    const dataToSend = {
      salutation: salutation ? salutation : '',
      firstName: firstName,
      lastName: lastName,
      organization: organization,
      department: department ? department : '',
      street: street,
      houseNumber: houseNumber ? houseNumber : '',
      postalCode: postalCode,
      state: state,
      city: city,
      phone: phone,
      country: countryFullValue,
      email: email,
    };
    setLoading(true);
    const res = await editMedUsAddress(dataToSend, medWarehouseId, addressListPayloadData);
    setLoading(false);
    if (res.success) {
      onCloseClick();
    }
  };

  const onCloseClick = () => {
    const popup = $(`#${id}`);
    if (popup && popup.modal) {
      popup.modal('hide');
    }
    setDefaultDataInPopup();
  };

  return {
    addressForm,
    addressModel,
    handleForm,
    onSaveClick,
    onCloseClick,
    loading
  };
};
