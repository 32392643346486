import { connect } from 'react-redux';

import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { updateDateOfCartItem } from '../../../LoanList/LoanListActions';
import {
  updateTypesOfJobs,
  updateOperatorId,
  updateForm,
  setHelpForBuildingAndDismantling,
  setOpportunity,
  removeOpportunity,
  setOpportunities,
  addAttachmentFile,
  removeAttachmentFile,
} from '../../LLKiLoan.actions';
import Assignment from './Assignment.component';

const mapStateToProps = ({ authReducer, languageReducer, loanList, llKitLoanReducer ,masterDataReducer}) => ({
  user: authReducer.user,
  language: languageReducer,
  selectedKits: loanList.cart.filter(item => (item.isLabsLocation === true) && (item.isSelected === true)),
  loanReasons: llKitLoanReducer.loanReasons,
  descriptionForm: llKitLoanReducer.descriptionForm,
  customerForm: llKitLoanReducer.customerForm,
  loansTypesOfJobs: llKitLoanReducer.loansTypesOfJobs,
  typesOfJobsId: llKitLoanReducer.typesOfJobsId,
  operatorId: llKitLoanReducer.operatorId,
  helpForBuildingAndDismantling: llKitLoanReducer.helpForBuildingAndDismantling,
  opportunities: llKitLoanReducer.opportunities,
  attachments: llKitLoanReducer.attachments,
  userList: masterDataReducer.usersList,
  nameOfOperatorForm: llKitLoanReducer.nameOfOperatorForm,
  nameOfPartnerOperatorForm: llKitLoanReducer.nameOfPartnerOperatorForm,
  newLabsLocationTypesOfJob: llKitLoanReducer.newLabsLocationTypesOfJob,
});

export default connect(mapStateToProps, {
  updateDateOfCartItem,
  updateTypesOfJobs,
  updateOperatorId,
  toggleActionMessage,
  updateForm,
  setHelpForBuildingAndDismantling,
  setOpportunity,
  removeOpportunity,
  setOpportunities,
  addAttachmentFile,
  removeAttachmentFile,
})(Assignment);
