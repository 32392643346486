import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import * as $ from 'jquery';

import { fetchFromStorage, saveToStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import ContactUs from 'modules/ContactUs/ContactUs';

import FullScreenMenu from './Components/FullScreenMenu';
import MobileMenu from './Components/MobileMenu';
import { LoginModal } from '../LoginModal/LoginModal';
import './header.styles.scss';
import AppUrlChangePopupComponent from 'modules/Homepage/UrlChangePopup/AppUrlChangePopup.component';
import { getUserCheckedDontShowAgain } from 'modules/Homepage/HomepageActions';
import moment from 'moment';
import WelcomePopup from 'modules/Homepage/WelcomePopup/WelcomePopup.component';
import NoStockAccessPopup from 'modules/Homepage/NoStockAcessWelcomePopup/NoStockAcessWelcomePopup.component';
const MODAL_ID = 'app-url-change-notify';

class Header extends Component {
  state = {
    contactOpened: false,
    showUrlChangePopup: false,
    showWelcmePopup: false,
    showNonAccessWelcomePopup: false,
  };

  componentDidUpdate(prevProps){
    if(prevProps.isLoggedIn !== this.props.isLoggedIn){
      if(this.props.isLoggedIn)
      this.handleShowUrlChangePopup()
    }
  }

  componentDidMount() {
    this.checkLoggedIn();
    this.handleShowUrlChangePopup()
  }

  checkLoggedIn() {
    const { pathname } = this.props.location;
    const token = fetchFromStorage(identifiers.access_token)
    const shouldShowLoginPopup = fetchFromStorage (identifiers.shouldShowLoginPopup) ;
   let showPopup = shouldShowLoginPopup === null || shouldShowLoginPopup === undefined || shouldShowLoginPopup === "false"

   // const user = fetchFromStorage(identifiers.user);
     if (!token  && !pathname.includes('login_id') && showPopup) {
      setTimeout(() => {
        $('#loginModal').modal('show');
        saveToStorage(identifiers.shouldShowLoginPopup,"true")
      }, 3 * 1000);
    }
  }

  onContactChange = () => {
    this.setState(
      state => ({ contactOpened: !state.contactOpened }),
      () => {
        if (this.state.contactOpened && $(`#contact-us`) && $(`#contact-us`).modal) {
          $(`#contact-us`).modal('show');
        }
      },
    );
  };

  handleShowPopup = userCheckValue => {
    const showFromDate = process.env.REACT_APP_APP_URL_CHANGE_NOTIFICATION_FROM_DATE;
    const showToDate = process.env.REACT_APP_APP_URL_CHANGE_NOTIFICATION_TO_DATE;
    if (!moment(showFromDate).isValid() && !moment(showToDate).isValid()) {
      return;
    }
    let today = new Date();
    today = moment(today).format('YYYY-MM-DD');
    const shownPopup = fetchFromStorage(identifiers.urlChangeNotificationShown);

    if (shownPopup === null || shownPopup === undefined || shownPopup !== true /*true*/) {
      if (
        moment(today).isSameOrAfter(showFromDate) &&
        moment(today).isSameOrBefore(showToDate) &&
        this.props.isLoggedIn &&
        userCheckValue
      ) {
        const isZmccCrmPopupOpen = $('#zmcc-Crm').is(':visible');
        this.setState({showUrlChangePopup: true}, () => {
          if (!isZmccCrmPopupOpen) {
          $(`#${MODAL_ID}`).modal('show');
          }
        })

        saveToStorage(identifiers.urlChangeNotificationShown, true);
      } else {
        $(`#${MODAL_ID}`).modal('hide');
      }
    }
  };

  handleShowWelcomePopup = (checkedValue) => {
    const showDate = process.env.REACT_APP_WELCOME_POPUP_DATE;
    const currentDate = new Date();
    const shownWelcomePopup = fetchFromStorage(identifiers.showWelcomePopup);
    if (shownWelcomePopup === null || shownWelcomePopup === undefined || shownWelcomePopup !== true ) {
      if (moment(currentDate).isSameOrBefore(showDate) && checkedValue && this.props.isLoggedIn) {
        this.setState({showWelcmePopup: true});
        saveToStorage(identifiers.showWelcomePopup, true);
      }
    }
    
  }

  handleShowUrlChangePopup = async () => {
    if(this.props.isLoggedIn){
      getUserCheckedDontShowAgain()
        .then(result => {
          // this.handleShowPopup(result.data);
          this.handleShowWelcomePopup(result.data);
        })
        .catch(res => {
          console.log('result error: ', res);
        });
    }
  };

  onClose = () => {
    this.setState({showUrlChangePopup: false})
    this.setState({showWelcmePopup: false});
    this.setState({ showNonAccessWelcomePopup: true});
  }
  chnageTestValue () {
    saveToStorage(identifiers.stockPopup, false);
  }

  render() {
    const isShowStockPopup =fetchFromStorage(identifiers.stockPopup);
    const localStorageUser = fetchFromStorage(identifiers.user);
    const hasNoAccessToStock =localStorageUser?.stockRoles?.length === 0 && localStorageUser?.globalRoles?.length === 0;
    
    return (
      <div className='header-container'>
        <div className='container-fluid'>
          <div className='d-lg-block'>
            <FullScreenMenu contactOpened={this.state.contactOpened} onContactChange={this.onContactChange} />
          </div>
        </div>
        <ContactUs contactOpened={this.state.contactOpened} onContactChange={this.onContactChange} />
        {!this.props.isLoggedIn&& (<LoginModal id={'loginModal'} />)}
        {this.props.isLoggedIn && this.state.showUrlChangePopup && (<AppUrlChangePopupComponent onClose={this.onClose} />)}
        {this.props.isLoggedIn && this.state.showWelcmePopup && <WelcomePopup onClose={this.onClose} history={this.props.history}/>}
       {hasNoAccessToStock && isShowStockPopup === null && !this.state.showWelcmePopup && this.state.showNonAccessWelcomePopup &&
        <NoStockAccessPopup chnageTestValue={this.chnageTestValue} history ={this.props.history} user={localStorageUser} />} 
      </div>
    );
  }
}

export default withRouter(Header);
