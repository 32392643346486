import { useEffect } from "react";
import { marketplaceInformationModel, marketplacePriceModel, priceFactorList } from "./KitMarketplace.constants";
import {
    FETCH_SELL_OFF_CONDITION,
    FETCH_SELL_OFF_CURRENCY,
  } from 'library/common/commonConstants/storeConstants';
import { URLS } from "library/common/commonConstants/ApiUrlConstants";


export const useKitMarketplace = ({sellOffConditions, fetchMasterData, sellOffCurrency}) => {

    useEffect(() => {
        fetchMasterData(URLS.sellOffCondition, FETCH_SELL_OFF_CONDITION);
        fetchMasterData(URLS.sellOffCurrency, FETCH_SELL_OFF_CURRENCY);
      }, []);

    const informationModel = () => {
        const model = [...marketplaceInformationModel]
        model[3].options = sellOffConditions || []
        return model;
    };

    const priceModel = () => {
        const model = [...marketplacePriceModel]
        model[1].options = sellOffCurrency || [];
        model[3].options = priceFactorList;
        return model;
    }

    return{
        informationModel,
        priceModel,
    }
}