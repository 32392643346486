import { useEffect, useState } from 'react';
import {
  generalInformationModel,
  schedulingInformationModel,
  projectDetailsModel
} from "./Tabs/GeneralInformation/GeneralInformation.constants";
import { validateForm } from 'library/utilities/ValidateForm.util';
import { scrollToTop } from 'library/utilities/scrollActions';
import { regex } from 'library/common/commonConstants/RegexConstants';
import {
  customerDetilsModel,
  primaryPointOfContactModel,
  customerBackgroundModel
} from "./Tabs/CustomerInformation/CustomerInformation.constants"
import {
  mainApplicationChecklist
} from "./Tabs/ApplicationSampleDetails/ApplicationSampleDetails.constants";
import { xbMainApplicationChecklist } from './Tabs/XBApplicationSamplesDetails/XBApplicationSampleDetails.constants';
import { xrmSamplesSpecModel } from './Tabs/XRMApplicationSamplesDetails/XRMApplicationSamplesDetails.constants';
import { fetchFromStorage, saveToStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import { validateNameEmailAndFunctionInAttendees } from '../../SemBooking.actions';

export const useAssignment = props => {
  const { setActiveStep, isSemBooking, isXbBooking, isXRMBooking, isLMBooking } = props;
  const [currentTab, setCurrentTab] = useState(1);

  useEffect(() => {
    const localTabId = fetchFromStorage(identifiers.zmccDrfTabId);
    if (localTabId?.tabId !== null && localTabId?.tabId !== undefined && localTabId?.tabId !== "" && !props.isZmcc && localTabId?.url == props.match.url) {
      setCurrentTab(localTabId.tabId);
    }
    setTimeout(() => {
      saveToStorage(identifiers.zmccDrfTabId , null);
    }, 500);

    return(() => {
      setTimeout(() => {
        saveToStorage(identifiers.zmccDrfTabId , null);
      }, 500);
    })
  },[props.match.id,props.match.url]);
  const goToSecondStep = async () => {
    const { toggleActionMessage, updateSEMForm } = props;

    let formValues = {
      generalInformationForm: props.formValue.generalInformationForm,
      schedulingInformationForm: props.formValue.schedulingInformationForm,
      projectDetailsForm: props.formValue.projectDetailsForm,
      customerDetilsForm: props.formValue.customerDetilsForm,
      primaryPointOfContactForm: props.formValue.primaryPointOfContactForm,
      customerBackgroundForm: props.formValue.customerBackgroundForm,
      mainApplicationChecklistForm: props.formValue.mainApplicationChecklistForm,
      sampleDeatilsFrom: props.formValue.sampleDeatilsFrom,
      demoCenterInformationForm: props.formValue.demoCenterInformationForm,
      additionalInformationForm: props.formValue.additionalInformationForm,
      logisticsAndCoordinationForm: props.formValue.logisticsAndCoordinationForm,
      todosListForm: props.formValue.todosListForm,
      transactionsId: props.formValue.transactionsId,
      kitAttachmentsToDelete: props.formValue.kitAttachmentsToDelete,
      xbMainApplicationChecklistForm: props.formValue.xbMainApplicationChecklistForm,
      xbSampleDeatilsFrom: props.formValue.xbSampleDeatilsFrom,
      xrmDemoDetailsForm: props.formValue.xrmDemoDetailsForm,
      xrmApplicationInformationForm: props.formValue.xrmApplicationInformationForm,
      xrmSamplesDetailsForm: props.formValue.xrmSamplesDetailsForm,
      xrmSampleDescriptionForm: props.formValue.xrmSampleDescriptionForm,
      xrmSampleSpecsForm: props.formValue.xrmSampleSpecsForm,
      lmApplicationAndSamplesForm: props.formValue.lmApplicationAndSamplesForm,
    };

    const validateArray = [
      { formName: 'generalInformationForm', form: formValues.generalInformationForm, model: generalInformationModel },
      {
        formName: 'schedulingInformationForm',
        form: formValues.schedulingInformationForm,
        model: schedulingInformationModel,
      },
      { formName: 'projectDetailsForm', form: formValues.projectDetailsForm, model: projectDetailsModel },

      { formName: 'customerDetilsForm', form: formValues.customerDetilsForm, model: customerDetilsModel },
      {
        formName: 'primaryPointOfContactForm',
        form: formValues.primaryPointOfContactForm,
        model: primaryPointOfContactModel,
      },
      { formName: 'customerBackgroundForm', form: formValues.customerBackgroundForm, model: customerBackgroundModel },
    ];

    if (isSemBooking) {
      validateArray.push({
        formName: 'mainApplicationChecklistForm',
        form: formValues.mainApplicationChecklistForm,
        model: mainApplicationChecklist,
      });
    }
    if (isXbBooking) {
      validateArray.push({
        formName: 'xbMainApplicationChecklistForm',
        form: formValues.xbMainApplicationChecklistForm,
        model: xbMainApplicationChecklist,
      });
    }

    if (isXRMBooking) {
      validateArray.push({
        formName: 'xrmSampleSpecsForm',
        form: formValues.xrmSampleSpecsForm,
        model: xrmSamplesSpecModel,
      });
    }

    validateArray.forEach(({ formName, form, model }) => {
      const newForm = validateForm({ form, model });
      formValues[formName] = newForm;
    });

    if (!formValues.generalInformationForm.isFormValid) {
      updateSEMForm({ generalInformationForm: formValues.generalInformationForm });
    }

    if (!formValues.schedulingInformationForm.isFormValid) {
      updateSEMForm({ schedulingInformationForm: formValues.schedulingInformationForm });
    }

    if (!formValues.projectDetailsForm.isFormValid) {
      updateSEMForm({ projectDetailsForm: formValues.projectDetailsForm });
    }

    if (!formValues.customerDetilsForm.isFormValid) {
      updateSEMForm({ customerDetilsForm: formValues.customerDetilsForm });
    }

    if (!formValues.primaryPointOfContactForm.isFormValid) {
      updateSEMForm({ primaryPointOfContactForm: formValues.primaryPointOfContactForm });
    }

    if (!formValues.customerBackgroundForm.isFormValid) {
      updateSEMForm({ customerBackgroundForm: formValues.customerBackgroundForm });
    }

    if (isSemBooking && !formValues.mainApplicationChecklistForm.isFormValid) {
      updateSEMForm({ mainApplicationChecklistForm: formValues.mainApplicationChecklistForm });
    }
    if (isXbBooking && !formValues.mainApplicationChecklistForm.isFormValid) {
      updateSEMForm({ xbMainApplicationChecklistForm: formValues.xbMainApplicationChecklistForm });
    }
    if (isXRMBooking && !formValues.xrmSampleSpecsForm.isFormValid) {
      updateSEMForm({ xrmSampleSpecsForm: formValues.xrmSampleSpecsForm });
    }

    if (isSemBooking && !formValues.sampleDeatilsFrom.isFormValid) {
      if (!formValues.sampleDeatilsFrom.formData.totalNumberOfSamples) {
        formValues.sampleDeatilsFrom.formData.totalNumberOfSamplesError = 'requiredErrorMessage';
        updateSEMForm({ sampleDeatilsFrom: formValues.sampleDeatilsFrom });
      } else if (formValues.sampleDeatilsFrom.formData.totalNumberOfSamples) {
        let result = regex.number.test(formValues.sampleDeatilsFrom.formData.totalNumberOfSamples);
        if (!result) {
          formValues.sampleDeatilsFrom.formData.totalNumberOfSamplesError = 'invalidNumberError';
          updateSEMForm({ sampleDeatilsFrom: formValues.sampleDeatilsFrom });
        }
      }

      if (!formValues.sampleDeatilsFrom.formData.statusOfSampleId) {
        formValues.sampleDeatilsFrom.formData.statusOfSampleIdError = 'requiredErrorMessage';
        formValues.sampleDeatilsFrom.formData.statusOfSampleIdFullValue = 'null';
        updateSEMForm({ sampleDeatilsFrom: formValues.sampleDeatilsFrom });
      }

      if (!formValues.sampleDeatilsFrom.formData.manipulateTheSamplesPriorToImaging) {
        formValues.sampleDeatilsFrom.formData.manipulateTheSamplesPriorToImagingError = 'requiredErrorMessage';
        formValues.sampleDeatilsFrom.formData.manipulateTheSamplesPriorToImagingFullValue = 'null';
        updateSEMForm({ sampleDeatilsFrom: formValues.sampleDeatilsFrom });
      }

      if (formValues.sampleDeatilsFrom.formData.statusOfSampleSolved && formValues.sampleDeatilsFrom.formData.completedByName === "") {
        formValues.sampleDeatilsFrom.formData.completedByNameError = 'requiredErrorMessage';
        updateSEMForm({ sampleDeatilsFrom: formValues.sampleDeatilsFrom });
        toggleActionMessage(true, 'error', 'formIsInvalidWithValidations');
        scrollToTop(500);
        return { success: false };
      }
    }

    if (isXbBooking && !formValues.xbSampleDeatilsFrom.isFormValid) {
      if (!formValues.xbSampleDeatilsFrom.formData.xbStatusOfSampleId) {
        formValues.xbSampleDeatilsFrom.formData.xbStatusOfSampleIdError = 'requiredErrorMessage';
        formValues.xbSampleDeatilsFrom.formData.xbStatusOfSampleIdFullValue = 'null';
        updateSEMForm({ xbSampleDeatilsFrom: formValues.xbSampleDeatilsFrom });
      }

      if (!formValues.xbSampleDeatilsFrom.formData.xbManipulateTheSamplesPriorToImaging) {
        formValues.xbSampleDeatilsFrom.formData.xbManipulateTheSamplesPriorToImagingError = 'requiredErrorMessage';
        formValues.xbSampleDeatilsFrom.formData.xbManipulateTheSamplesPriorToImagingFullValue = 'null';
        updateSEMForm({ xbSampleDeatilsFrom: formValues.xbSampleDeatilsFrom });
      }

      if (!formValues.xbSampleDeatilsFrom.formData.detailedDescription) {
        formValues.xbSampleDeatilsFrom.formData.detailedDescriptionError = 'requiredErrorMessage';
        updateSEMForm({ xbSampleDeatilsFrom: formValues.xbSampleDeatilsFrom });
      }
      if (!formValues.xbSampleDeatilsFrom.formData.sampleSpecificComments) {
        formValues.xbSampleDeatilsFrom.formData.sampleSpecificCommentsError = 'requiredErrorMessage';
        updateSEMForm({ xbSampleDeatilsFrom: formValues.xbSampleDeatilsFrom });
      }
      if (formValues.xbSampleDeatilsFrom.formData.statusOfSampleSolvedXb && formValues.xbSampleDeatilsFrom.formData.completedByNameXb === "") {
        formValues.xbSampleDeatilsFrom.formData.completedByNameXbError = 'requiredErrorMessage';
        updateSEMForm({ xbSampleDeatilsFrom: formValues.xbSampleDeatilsFrom });
        toggleActionMessage(true, 'error', 'formIsInvalidWithValidations');
        scrollToTop(500);
        return { success: false };
      }
    }

    if (isXRMBooking && !formValues.xrmDemoDetailsForm.isFormValid) {
      if (!formValues.xrmDemoDetailsForm.formData.primaryGoalsOfDemo) {
        formValues.xrmDemoDetailsForm.formData.primaryGoalsOfDemoError = 'requiredErrorMessage';
        updateSEMForm({ xrmDemoDetailsForm: formValues.xrmDemoDetailsForm });
      }
      if (!formValues.xrmDemoDetailsForm.formData.customerSystemCompetition) {
        formValues.xrmDemoDetailsForm.formData.customerSystemCompetitionError = 'requiredErrorMessage';
        updateSEMForm({ xrmDemoDetailsForm: formValues.xrmDemoDetailsForm });
      }
      if (!formValues.xrmDemoDetailsForm.formData.xrmDoYouHaveDataFromCustomer) {
        formValues.xrmDemoDetailsForm.formData.xrmDoYouHaveDataFromCustomerError = 'requiredErrorMessage';
        updateSEMForm({ xrmDemoDetailsForm: formValues.xrmDemoDetailsForm });
      }
    }

    if (isXRMBooking && !formValues.xrmApplicationInformationForm.isFormValid) {
      if (!formValues.xrmApplicationInformationForm.formData.detailedDescriptionOfCustomerFocus) {
        formValues.xrmApplicationInformationForm.formData.detailedDescriptionOfCustomerFocusError =
          'requiredErrorMessage';
        updateSEMForm({ xrmApplicationInformationForm: formValues.xrmApplicationInformationForm });
      }
    }

    if (isXRMBooking && !formValues.xrmSamplesDetailsForm.isFormValid) {
      if (!formValues.xrmSamplesDetailsForm.formData.totalNumberOfSamplesForXrm) {
        formValues.xrmSamplesDetailsForm.formData.totalNumberOfSamplesForXrmError = 'requiredErrorMessage';
        updateSEMForm({ xrmSamplesDetailsForm: formValues.xrmSamplesDetailsForm });
      }

      if (!formValues.xrmSamplesDetailsForm.formData.xrmManipulateTheSamplesPriorToImaging) {
        formValues.xrmSamplesDetailsForm.formData.xrmManipulateTheSamplesPriorToImagingError = 'requiredErrorMessage';
        updateSEMForm({ xrmSamplesDetailsForm: formValues.xrmSamplesDetailsForm });
      }
      if (!formValues.sampleDeatilsFrom.formData.statusOfSampleId) {
        formValues.sampleDeatilsFrom.formData.statusOfSampleIdError = 'requiredErrorMessage';
        formValues.sampleDeatilsFrom.formData.statusOfSampleIdFullValue = 'null';
        updateSEMForm({ sampleDeatilsFrom: formValues.sampleDeatilsFrom });
      }else{
        formValues.sampleDeatilsFrom.formData.statusOfSampleIdError = null;
        updateSEMForm({ sampleDeatilsFrom: formValues.sampleDeatilsFrom });
      }

      if (formValues.sampleDeatilsFrom.formData.statusOfSampleSolved && !formValues.sampleDeatilsFrom.formData.completedByName) {
        formValues.sampleDeatilsFrom.formData.completedByNameError = 'requiredErrorMessage';
        updateSEMForm({ sampleDeatilsFrom: formValues.sampleDeatilsFrom });
      }else{
        formValues.sampleDeatilsFrom.formData.completedByNameError = null
        updateSEMForm({ sampleDeatilsFrom: formValues.sampleDeatilsFrom });
      }
    }

    if (isXRMBooking && !formValues.xrmSampleDescriptionForm.isFormValid) {
      if (!formValues.xrmSampleDescriptionForm.formData.describeOverallSamples) {
        formValues.xrmSampleDescriptionForm.formData.describeOverallSamplesError = 'requiredErrorMessage';
        updateSEMForm({ xrmSampleDescriptionForm: formValues.xrmSampleDescriptionForm });
      }
    }
    if (!formValues.demoCenterInformationForm.isFormValid) {
      if (formValues.demoCenterInformationForm.formData.zmccHardwareSoftwareAccessoriesList?.length <= 0)
       {
        if (!formValues.demoCenterInformationForm.formData.hasOwnProperty('hardOrSoftwareAccessories')) {
          formValues.demoCenterInformationForm.formData.hardOrSoftwareAccessoriesError = 'requiredErrorMessage';
          updateSEMForm({ demoCenterInformationForm: formValues.demoCenterInformationForm });
        }else if (formValues.demoCenterInformationForm.formData.hasOwnProperty('hardOrSoftwareAccessories') && (formValues.demoCenterInformationForm.formData.hardOrSoftwareAccessories === "" || formValues.demoCenterInformationForm.formData.hardOrSoftwareAccessories === null) ) {
          formValues.demoCenterInformationForm.formData.hardOrSoftwareAccessoriesError = 'requiredErrorMessage';
          updateSEMForm({ demoCenterInformationForm: formValues.demoCenterInformationForm });
        }
      }
    }

    if (isLMBooking && !formValues.lmApplicationAndSamplesForm.isFormValid) {
      if (!formValues.lmApplicationAndSamplesForm.formData.primaryApplicationForThisCustomer) {
        formValues.lmApplicationAndSamplesForm.formData.primaryApplicationForThisCustomerError = 'requiredErrorMessage';
        updateSEMForm({ lmApplicationAndSamplesForm: formValues.lmApplicationAndSamplesForm });
      }
      if (!formValues.lmApplicationAndSamplesForm.formData.sampleGoingToProvideForThisDemo) {
        formValues.lmApplicationAndSamplesForm.formData.sampleGoingToProvideForThisDemoError = 'requiredErrorMessage';
        updateSEMForm({ lmApplicationAndSamplesForm: formValues.lmApplicationAndSamplesForm });
      }

      if (!formValues.lmApplicationAndSamplesForm.formData.learningAboutTheseSamples) {
        formValues.lmApplicationAndSamplesForm.formData.learningAboutTheseSamplesError = 'requiredErrorMessage';
        updateSEMForm({ lmApplicationAndSamplesForm: formValues.lmApplicationAndSamplesForm });
      }

      if (!formValues.lmApplicationAndSamplesForm.formData.justificationPoints) {
        formValues.lmApplicationAndSamplesForm.formData.justificationPointsError = 'requiredErrorMessage';
        updateSEMForm({ lmApplicationAndSamplesForm: formValues.lmApplicationAndSamplesForm });
      }

      if (!formValues.lmApplicationAndSamplesForm.formData.aboutCompetitionInThisSale) {
        formValues.lmApplicationAndSamplesForm.formData.aboutCompetitionInThisSaleError = 'requiredErrorMessage';
        updateSEMForm({ lmApplicationAndSamplesForm: formValues.lmApplicationAndSamplesForm });
      }

      if (!formValues.lmApplicationAndSamplesForm.formData.lmDoYouHaveDataFromCustomer) {
        formValues.lmApplicationAndSamplesForm.formData.lmDoYouHaveDataFromCustomerError = 'requiredErrorMessage';
        updateSEMForm({ lmApplicationAndSamplesForm: formValues.lmApplicationAndSamplesForm });
      }

      if (!formValues.sampleDeatilsFrom.formData.statusOfSampleId) {
        formValues.sampleDeatilsFrom.formData.statusOfSampleIdError = 'requiredErrorMessage';
        formValues.sampleDeatilsFrom.formData.statusOfSampleIdFullValue = 'null';
        updateSEMForm({ sampleDeatilsFrom: formValues.sampleDeatilsFrom });
      }else{
        formValues.sampleDeatilsFrom.formData.statusOfSampleIdError = null;
        updateSEMForm({ sampleDeatilsFrom: formValues.sampleDeatilsFrom });
      }

      if (formValues.sampleDeatilsFrom.formData.statusOfSampleSolved && !formValues.sampleDeatilsFrom.formData.completedByName) {
        formValues.sampleDeatilsFrom.formData.completedByNameError = 'requiredErrorMessage';
        updateSEMForm({ sampleDeatilsFrom: formValues.sampleDeatilsFrom });
      }else{
        formValues.sampleDeatilsFrom.formData.completedByNameError = null
        updateSEMForm({ sampleDeatilsFrom: formValues.sampleDeatilsFrom });
      }

      if (!formValues.lmApplicationAndSamplesForm.formData.lmTotalNumberOfSamples) {
        formValues.lmApplicationAndSamplesForm.formData.lmTotalNumberOfSamplesError = 'requiredErrorMessage';
        updateSEMForm({ lmApplicationAndSamplesForm: formValues.lmApplicationAndSamplesForm });
      }
    }

    if (!formValues.logisticsAndCoordinationForm.formData.isFormValid) {
      if (formValues.logisticsAndCoordinationForm.formData.attendeeEmail && formValues.logisticsAndCoordinationForm.formData.attendeeEmail !=="" && formValues.logisticsAndCoordinationForm.formData.attendeeEmail !== null) {
        let result = regex.email.test(formValues.logisticsAndCoordinationForm.formData.attendeeEmail);
        if (!result) {
          formValues.logisticsAndCoordinationForm.formData.attendeeEmailError = 'invalidEmailError';
          updateSEMForm({ logisticsAndCoordinationForm: formValues.logisticsAndCoordinationForm });
        }
      }
      let  data = formValues.logisticsAndCoordinationForm.formData
      if (data.accomodationNeeded === true && (data.accomodationNeededData === '' || data.accomodationNeededData === null || data.accomodationNeededData === undefined)) {
        formValues.logisticsAndCoordinationForm.formData.accomodationNeededDataError = 'requiredErrorMessage';
        updateSEMForm({ logisticsAndCoordinationForm: formValues.logisticsAndCoordinationForm });
      }
      if(data.flightNeeded === true && (data.flightNeededData === "" || data.flightNeededData === null || data.flightNeededData === undefined)){
        formValues.logisticsAndCoordinationForm.formData.flightNeededDataError = 'requiredErrorMessage';
        updateSEMForm({ logisticsAndCoordinationForm: formValues.logisticsAndCoordinationForm });
      }
      if (data.visitMuseumOfOptics === true &&  (data.visitMuseumOfOpticsData === "" || data.visitMuseumOfOpticsData === null || data.visitMuseumOfOpticsData === undefined)) {
        formValues.logisticsAndCoordinationForm.formData.visitMuseumOfOpticsDataError = 'requiredErrorMessage';
        updateSEMForm({ logisticsAndCoordinationForm: formValues.logisticsAndCoordinationForm });
      }
    }
    if (
      isSemBooking &&
      (!formValues.generalInformationForm.isFormValid ||
        !formValues.schedulingInformationForm.isFormValid ||
        !formValues.projectDetailsForm.isFormValid ||
        !formValues.customerDetilsForm.isFormValid ||
        !formValues.primaryPointOfContactForm.isFormValid ||
        !formValues.customerBackgroundForm.isFormValid ||
        !formValues.mainApplicationChecklistForm.isFormValid ||
        formValues.sampleDeatilsFrom.formData.totalNumberOfSamplesError == 'requiredErrorMessage' ||
        formValues.sampleDeatilsFrom.formData.totalNumberOfSamplesError == 'invalidNumberError' ||
        formValues.sampleDeatilsFrom.formData.statusOfSampleIdError == 'requiredErrorMessage' ||
        formValues.sampleDeatilsFrom.formData.manipulateTheSamplesPriorToImagingError == 'requiredErrorMessage' ||
        formValues.demoCenterInformationForm.formData.hardOrSoftwareAccessoriesError == 'requiredErrorMessage' ||
        formValues.logisticsAndCoordinationForm.formData.attendeeEmailError == 'invalidEmailError'||
        formValues.logisticsAndCoordinationForm.formData.accomodationNeededDataError == 'requiredErrorMessage' ||
        formValues.logisticsAndCoordinationForm.formData.flightNeededDataError == 'requiredErrorMessage' ||
        formValues.logisticsAndCoordinationForm.formData.visitMuseumOfOpticsDataError == 'requiredErrorMessage'||
        formValues.sampleDeatilsFrom.formData.completedByNameError =='requiredErrorMessage'
        )
    ) {
      toggleActionMessage(true, 'error', 'formIsInvalid');
      scrollToTop(500);
      return { success: false };
    }

    if (
      isXbBooking &&
      (!formValues.generalInformationForm.isFormValid ||
        !formValues.schedulingInformationForm.isFormValid ||
        !formValues.projectDetailsForm.isFormValid ||
        !formValues.customerDetilsForm.isFormValid ||
        !formValues.primaryPointOfContactForm.isFormValid ||
        !formValues.customerBackgroundForm.isFormValid ||
        !formValues.xbMainApplicationChecklistForm.isFormValid ||
        formValues.xbSampleDeatilsFrom.formData.xbStatusOfSampleIdError == 'requiredErrorMessage' ||
        formValues.xbSampleDeatilsFrom.formData.xbManipulateTheSamplesPriorToImagingError == 'requiredErrorMessage' ||
        formValues.demoCenterInformationForm.formData.hardOrSoftwareAccessoriesError == 'requiredErrorMessage' ||
        formValues.xbSampleDeatilsFrom.formData.detailedDescriptionError == 'requiredErrorMessage' ||
        formValues.xbSampleDeatilsFrom.formData.sampleSpecificCommentsError == 'requiredErrorMessage' ||
        formValues.logisticsAndCoordinationForm.formData.attendeeEmailError == 'invalidEmailError'||
        formValues.logisticsAndCoordinationForm.formData.accomodationNeededDataError == 'requiredErrorMessage' ||
        formValues.logisticsAndCoordinationForm.formData.flightNeededDataError == 'requiredErrorMessage' ||
        formValues.logisticsAndCoordinationForm.formData.visitMuseumOfOpticsDataError == 'requiredErrorMessage'||
        formValues.xbSampleDeatilsFrom.formData.completedByNameXbError =='requiredErrorMessage'
        )
    ) {
      toggleActionMessage(true, 'error', 'formIsInvalid');
      scrollToTop(500);
      return { success: false };
    }

    if (
      isXRMBooking &&
      (!formValues.generalInformationForm.isFormValid ||
        !formValues.schedulingInformationForm.isFormValid ||
        !formValues.projectDetailsForm.isFormValid ||
        !formValues.customerDetilsForm.isFormValid ||
        !formValues.primaryPointOfContactForm.isFormValid ||
        !formValues.customerBackgroundForm.isFormValid ||
        !formValues.xrmSampleSpecsForm.isFormValid ||
        formValues.xrmDemoDetailsForm.formData.customerSystemCompetitionError === 'requiredErrorMessage' ||
        formValues.xrmDemoDetailsForm.formData.primaryGoalsOfDemoError === 'requiredErrorMessage' ||
        formValues.xrmSamplesDetailsForm.formData.xrmManipulateTheSamplesPriorToImagingError === 'requiredErrorMessage' ||
        formValues.xrmDemoDetailsForm.formData.xrmDoYouHaveDataFromCustomerError === 'requiredErrorMessage' ||
        formValues.xrmApplicationInformationForm.formData.detailedDescriptionOfCustomerFocusError ===
          'requiredErrorMessage' ||
        formValues.xrmSampleDescriptionForm.formData.describeOverallSamples === 'requiredErrorMessage' ||
        formValues.sampleDeatilsFrom.formData.statusOfSampleIdError == 'requiredErrorMessage' ||
        formValues.logisticsAndCoordinationForm.formData.attendeeEmailError == 'invalidEmailError'||
        formValues.logisticsAndCoordinationForm.formData.accomodationNeededDataError == 'requiredErrorMessage' ||
        formValues.logisticsAndCoordinationForm.formData.flightNeededDataError == 'requiredErrorMessage' ||
        formValues.logisticsAndCoordinationForm.formData.visitMuseumOfOpticsDataError == 'requiredErrorMessage' ||
        formValues.xrmSamplesDetailsForm.formData.totalNumberOfSamplesForXrmError === 'requiredErrorMessage' ||
        formValues.sampleDeatilsFrom.formData.completedByNameError =='requiredErrorMessage'
        )
    ) {
      toggleActionMessage(true, 'error', 'formIsInvalid');
      scrollToTop(500);
      return { success: false };
    }

    if (
      isLMBooking &&
      (!formValues.generalInformationForm.isFormValid ||
        !formValues.schedulingInformationForm.isFormValid ||
        !formValues.projectDetailsForm.isFormValid ||
        !formValues.customerDetilsForm.isFormValid ||
        !formValues.primaryPointOfContactForm.isFormValid ||
        !formValues.customerBackgroundForm.isFormValid ||
        formValues.demoCenterInformationForm.formData.hardOrSoftwareAccessoriesError == 'requiredErrorMessage' ||
        formValues.lmApplicationAndSamplesForm.formData.primaryApplicationForThisCustomerError ==
          'requiredErrorMessage' ||
        formValues.lmApplicationAndSamplesForm.formData.sampleGoingToProvideForThisDemoError ==
          'requiredErrorMessage' ||
        formValues.lmApplicationAndSamplesForm.formData.learningAboutTheseSamplesError == 'requiredErrorMessage' ||
        formValues.lmApplicationAndSamplesForm.formData.justificationPointsError == 'requiredErrorMessage' ||
        formValues.lmApplicationAndSamplesForm.formData.aboutCompetitionInThisSaleError == 'requiredErrorMessage' ||
        formValues.lmApplicationAndSamplesForm.formData.lmDoYouHaveDataFromCustomerError == 'requiredErrorMessage' ||
        formValues.sampleDeatilsFrom.formData.statusOfSampleIdError == 'requiredErrorMessage' ||
        formValues.logisticsAndCoordinationForm.formData.attendeeEmailError == 'invalidEmailError'||
        formValues.logisticsAndCoordinationForm.formData.accomodationNeededDataError == 'requiredErrorMessage' ||
        formValues.logisticsAndCoordinationForm.formData.flightNeededDataError == 'requiredErrorMessage' ||
        formValues.logisticsAndCoordinationForm.formData.visitMuseumOfOpticsDataError == 'requiredErrorMessage' ||
        formValues.lmApplicationAndSamplesForm.formData.lmTotalNumberOfSamplesError === 'requiredErrorMessage' ||
        formValues.sampleDeatilsFrom.formData.completedByNameError =='requiredErrorMessage'
      )
    ) {
      toggleActionMessage(true, 'error', 'formIsInvalid');
      scrollToTop(500);
      return { success: false };
    }
    let validations = validateNameEmailAndFunctionInAttendees(formValues.logisticsAndCoordinationForm)
    if(!validations.allowedToSave){
      updateSEMForm({logisticsAndCoordinationForm: { formData: {...formValues.logisticsAndCoordinationForm.formData, ...validations.nameEmailAndFunctionErrors}}})
      toggleActionMessage(true, 'error', 'pleaseFillRequiredFieldsForAttendee');
      scrollToTop(500);
      return { success: false };
    } else if(!validations.isValid && !validations.allEmpty){
      updateSEMForm({logisticsAndCoordinationForm: { formData: {...formValues.logisticsAndCoordinationForm.formData, ...validations.nameEmailAndFunctionErrors}}})
      toggleActionMessage(true, 'error', 'pleaseFillRequiredFieldsForAttendee');
      scrollToTop(500);
      return { success: false };
    } else if(validations.allEmpty && formValues.logisticsAndCoordinationForm.formData.zmccAttendeesList?.length === 0){
      updateSEMForm({logisticsAndCoordinationForm: { formData: {...formValues.logisticsAndCoordinationForm.formData, ...validations.nameEmailAndFunctionErrors}}})
      toggleActionMessage(true, 'error', 'mustHaveAttendee');
      scrollToTop(500);
      return { success: false };
    }

    setActiveStep(2);
  };

  const goToNextTab = () => {
    if (currentTab == 7) {
      goToSecondStep();
    } else {
      let curTab = currentTab;
      // Tab 4: additional information tab
      // As this to removed, and to avoid validation failures done based on tabid 
      // manually added +2 similarly for prev click
      if(currentTab === 4){
        curTab = curTab + 2;
      }else
      curTab = curTab + 1;
      setCurrentTab(curTab);
    }
  };

  const goToPreviousTab = () => {
    setCurrentTab(() => {
      let curTab = currentTab;
      if(currentTab === 6){
        return curTab - 2;
      }
      return curTab - 1;
    });
  };

  const getTabId = id => {
    setCurrentTab(id);
  };

  return {
    currentTab,
    goToNextTab,
    goToPreviousTab,
    getTabId,
  };
};
