import {
  FETCH_FREQUENTLY_BORROWED_LIST,
  FETCH_HOMEPAGE_NEW_KIT_LIST,
  FETCH_LOANS_SUB_MENU,
  IS_OPERATOR,
  MYSCHEDULED_EVENT_LIST,
  SCHEDULED_DETAILS,
  SCHEDULE_TYPES,
} from 'library/common/commonConstants/storeConstants';

export const initialState = {
  newKitsList: {
    content: [],
  },
  borrowedFrequentlyKits: [],
  loansSubMenu: [],
  scheludeTypeList: [],
  scheduledEventsList: [],
  isOperatorUser: null,
  scheduledDetails: null,
};

const HomepageReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HOMEPAGE_NEW_KIT_LIST:
      return {
        ...state,
        newKitsList: action.payload,
      };
    case FETCH_FREQUENTLY_BORROWED_LIST:
      return {
        ...state,
        borrowedFrequentlyKits: action.payload,
      };
    case FETCH_LOANS_SUB_MENU:
      return {
        ...state,
        loansSubMenu: action.payload,
      };
      case SCHEDULE_TYPES:
        return {
          ...state,
          scheludeTypeList: action.payload,
        };
        case MYSCHEDULED_EVENT_LIST:
        return {
          ...state,
          scheduledEventsList: action.payload,
        };
        case IS_OPERATOR:
        return {
          ...state,
          isOperatorUser: action.payload,
        };
        case SCHEDULED_DETAILS:
        return {
          ...state,
          scheduledDetails: action.payload,
        };
    default:
      return state;
  }
};

export default HomepageReducer;
