import React from 'react';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import Modal from 'library/common/commonComponents/Modal';
import strings from 'resources/locales/Translate';
import { demoCheckListModel } from './DemoCheckListPopup.constants';
import NetPromoterScore from '../MaintenanceCheckList/NetpromoterScore.component';
import moment from 'moment';
import { useDemoCheckListPopup } from './DemoCheckListPopup.hook';

export const DemoCheckListPopup = ({id,user, usersList, saveMaintenanceNewCheckListForm, transactionId, maintenanceCheckListData, saveMantenanceCheckListData }) => {
    const { demoForm, demoFormModel, handleForm, onChangeNps, npsValue, onSaveClick, closeClick, loading } =
      useDemoCheckListPopup({
        id,
        user,
        usersList,
        saveMaintenanceNewCheckListForm,
        transactionId,
        maintenanceCheckListData,
        saveMantenanceCheckListData,
      });
    const currentData = moment().format('DD-MM-YYYY')
    return (
      <Modal id={id} confirmAction={onSaveClick} loading={loading} disableBackgroundClose onCloseClick={closeClick} title={strings.maitenanceCheckList}>
        <p className='checkList-form-lable'>{strings.demo}</p>
        <div className='mt-2'>
          <FormComponent
            model={demoFormModel()}
            formName='demoForm'
            formValue={demoForm.formData}
            onChange={handleForm}
          />
        </div>
        <div className='mt-2 font-bold'>{strings.dateAndNameOfUser}</div>
        <div>{currentData}, {user ? `${user.firstName} ${user.lastName}` : ''}</div>
      </Modal>
    );
}
export default DemoCheckListPopup;