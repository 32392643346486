import { connect } from 'react-redux';
import ControllingReportTab from './ControllingReportTab.component';
import { getControllingReport , exportControllingReportList} from '../../LLManagement.actions';
const mapStateToProps = ({ authReducer, languageReducer, llManagementReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
  controllingReport: llManagementReducer.controllingReport,
});

export default connect(mapStateToProps,{getControllingReport, exportControllingReportList})(
  ControllingReportTab,
);
