import React from 'react';
import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import Modal from 'library/common/commonComponents/Modal';
import { useEditMyQueryOnResultPage } from './EditMyQueryOnResultPage.hooks';

export const EditMyQueryOnResultPage = ({ id, data, dbSavedFilter, createMyQuery }) => {
  const {
    selectedOption,
    handleChange,
    handleSave,
    getModelData,
    handleForm,
    loading,
    error,
    newQueryForm,
    onCloseClick,
  } = useEditMyQueryOnResultPage({ id, data, dbSavedFilter, createMyQuery });
  return (
    <Modal
      id={id}
      confirmAction={handleSave}
      loading={loading}
      disableBackgroundClose
      onCloseClick={onCloseClick}
      title={strings.saveQuery}
    >
      <div className='mt-2'>
        <div className=''>
          <div>
            <input
              type='radio'
              id='esistingFilter'
              value='esistingFilter'
              checked={selectedOption.existingFilter}
              onChange={() => handleChange('esistingFilter')}
            />
            <label className='ml-2' htmlFor='esistingFilter'>
              {strings.updateExistingFilters}
            </label>
          </div>
          <div>
            <input
              type='radio'
              id='newQuery'
              value='newQuery'
              checked={selectedOption.newQuery}
              onChange={() => handleChange('newQuery')}
            />
            <label className='ml-2' htmlFor='newQuery'>
              {strings.newQuery}
            </label>
          </div>
        </div>
        {selectedOption.newQuery && (
          <FormComponent
            model={getModelData()}
            formName='newQueryForm'
            formValue={newQueryForm.formData}
            onChange={handleForm}
          />
        )}
      </div>
      {error && <div className='error-block'>{error}</div>}
    </Modal>
  );
};
