import { useEffect, useState, useCallback } from 'react';

import { getDateInFormat } from 'library/utilities/getDateInFormat';
import {
  astronautColor,
  toreaBayColor,
  ceruleanColor,
  malibuColor,
  limeadeColor,
  blueVioletColor,
  javaColor,
  atlantisColor,
  dairyCreamColor,
  sanMarinoColor,
  osloGrayColor,
} from 'resources/styles/variables.scss';

import {  FETCH_STOCK_BY_COUNTRY_FOR_DEMO_ACTIVITY, FETCH_SYSTEM_CLASS_FOR_DEMO_ACTIVITY } from '../../Statistics.constants';

export const useDemoActivityTab = ({
  systemClassDemoActivity,
  loanReasonsStatistic,
  getStocksByBussinessUnitandCountryIds,
  getSystemClassesByBU,
  getLoanReasonStatistic,
  clearStatistics,
  language,
  getDemosStatistic,
  getSatisfactionIndexStatistics,
  getDemosChartData,
}) => {
  const [filters, setFilters] = useState({
    businessGroup: null,
    businessGroupFullValue: null,
    country: null,
    countryFullValue: null,
    stock: null,
    stockFullValue: null,
    systemClass: null,
    systemClassFullValue: null,
    loanTypes: null,
    loanTypesFullValue: null,
    dateRange: null,
  });

  useEffect(() => {
    if (filters.country) {
      const businessGroupFullValue = (filters.businessGroup && filters.businessGroupFullValue.name) ? filters.businessGroupFullValue.name : '';
      getStocksByBussinessUnitandCountryIds(filters.country, businessGroupFullValue, FETCH_STOCK_BY_COUNTRY_FOR_DEMO_ACTIVITY);
      setFilters(prevState => ({
        ...prevState,
        stock: null,
        stockFullValue: null,
      }));
    }
  }, [filters.country, filters.businessGroup, getStocksByBussinessUnitandCountryIds]);

  useEffect(() => {
    if (filters.businessGroup) {
      getSystemClassesByBU(filters.businessGroupFullValue.name, FETCH_SYSTEM_CLASS_FOR_DEMO_ACTIVITY);
      setFilters(prevState => ({
        ...prevState,
        systemClass: null,
        systemClassFullValue: null,
      }));
    }
  }, [filters.businessGroup, filters.businessGroupFullValue, getSystemClassesByBU]);

  useEffect(() => {
    if (filters.businessGroup && filters.dateRange && filters.dateRange.length) {
      const dataToSend = {
        businessUnitId: filters.businessGroup,
        countryIds: filters.country,
        stockIds: filters.stock,
        systemMainComponentIds: filters.systemClass,
        loanType: filters.loanTypesFullValue ? filters.loanTypesFullValue.valueToSend : '',
        from: getDateInFormat('YYYY-MM-DD', filters.dateRange[0]),
        to: getDateInFormat('YYYY-MM-DD', filters.dateRange[1]),
      };

      getLoanReasonStatistic(dataToSend);
      getDemosStatistic(dataToSend);
      getSatisfactionIndexStatistics(dataToSend);
      getDemosChartData(dataToSend);
    }
  }, [getLoanReasonStatistic, getDemosStatistic, getSatisfactionIndexStatistics, getDemosChartData, filters, language]);

  useEffect(() => {
    if ((!filters.businessGroup || !filters.dateRange || !filters.dateRange.length) && loanReasonsStatistic.length) {
      clearStatistics();
    }
  }, [filters.businessGroup, filters.dateRange, loanReasonsStatistic, clearStatistics]);

  const getSystemClass = useCallback(() => {
    if (filters.stock && filters.stock.length > 0) {
      const { stockSystemClasses, systemClasses } = systemClassDemoActivity || {};

      return filters.stock.reduce((acc, current) => {
        const data = stockSystemClasses && stockSystemClasses[current];
    
        if (!data) {   
          return acc;
        }

        return systemClasses.filter(systemClass => data.includes(systemClass.systemClassId));
      }, []);
    }

    return systemClassDemoActivity.systemClasses || [];
  }, [systemClassDemoActivity, filters.stock]);

  const systemClass = getSystemClass().filter(item => item.systemMainComponent.length);
  const chartLabels = loanReasonsStatistic.map(item => item.reasonName);
  const chartData = loanReasonsStatistic.map(item => item.loanPercent);
  const hasDataForChart = chartLabels.length > 0;
  const data = {
    labels: chartLabels,
    datasets: [
      {
        data: chartData,
        backgroundColor: [
          astronautColor,
          toreaBayColor,
          ceruleanColor,
          malibuColor,
          limeadeColor,
          blueVioletColor,
          javaColor,
          atlantisColor,
          dairyCreamColor,
          sanMarinoColor,
          osloGrayColor,
        ],
        datalabels: {
          anchor: 'end',
          align: 'end',
          offset: 10,
        },
      },
    ],
  };

  return {
    filters,
    systemClass,
    hasDataForChart,
    data,
    setFilters,
    getSystemClass,
  }
}
