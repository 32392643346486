import React, { Fragment } from 'react';
import {
  getIconByCondition,
  getIconByTransactionStatus,
  getIconByCurrentPosition,
  getIconFor24HourReservation,
  getNameOfReturnGoodsBookingCondition,
  getIconByZmccTransactionStatus,
  getIconByLLTransactionStatus,
} from 'library/utilities/getStatusAndConditionIcons';
import Icon from 'library/common/commonComponents/Icon/Icon';
import strings from 'resources/locales/Translate';

export const TransactionStatusAndPositionTooltip = ({
  checkStatus,
  checkCondition,
  checkCurrentPosition,
  twentyFourHourReservation,
  isDelay,
  transactionStatus,
  isExpandable,
  transactionKit,
  transactionPosition,
  returnDelivery,
  isZmcc = false,
  isLabsLocation = false,
}) => {

  if(isZmcc){
    return (
      <div className='d-flex justify-content-between align-items-center' onClick={e => e.stopPropagation()}>
        {checkStatus ? (
          <>
            {getIconByZmccTransactionStatus(transactionStatus.transactionStatusId, true)}
          </>
        ): null}
        {checkCondition ? <><span className='ml-2'>{getIconByCondition(transactionKit.kitInformation.kitCondition, true)}</span></>: null}
      </div>
    )
  }
  if (isLabsLocation) {
    return(
      <div className='d-flex justify-content-between align-items-center' onClick={e => e.stopPropagation()}>
          {checkStatus ? <>{getIconByLLTransactionStatus(transactionStatus.transactionStatusId, true)}</> : null}
          {checkCondition ? <>{getIconByCondition(transactionKit.kitInformation.kitCondition, true)}</>: null}      
       </div>
    )
  }
  return (
    <div className='d-flex justify-content-between align-items-center' onClick={e => e.stopPropagation()}>
      {checkStatus ? (
        <>
          {getIconByTransactionStatus(transactionStatus.transactionStatusId, true)}{' '}
        </>
      ) : null}
      {checkCondition ? <>{getIconByCondition(transactionKit.kitInformation.kitCondition, true)}</>: null}
      {checkCurrentPosition ? (
        <div className='d-flex align-items-center mr-1'>
          {transactionPosition.transactionPositionTranslated.map(position => (
            <Fragment key={position.positionText}>
              {getIconByCurrentPosition({...position, transactionPositionId: transactionPosition.transactionPositionId}, true, twentyFourHourReservation, returnDelivery)}
            </Fragment>
          ))}
        </div>
      ) : null}
      {twentyFourHourReservation ? (
        <>
          <span className='mr-2'>{getIconFor24HourReservation(true)}</span>
        </>
      ) : null}
      {isDelay ? <Icon name='conflictBooking' width={16} height={16} fill='#000' className='ml-1' /> : null}
    </div>
  );
};

export default TransactionStatusAndPositionTooltip;
