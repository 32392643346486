export const getSelectedCountry = (id, countries) => {
  let findInfo;

  if (countries && countries.length) {
    countries.forEach(country => {
      if (country.id === id) findInfo = country.name;
    })
  }

  return findInfo;
}
