export const purchaseRequestsBreadcrumb = [
  {
    name:'homepage',
    url: '/home',
  },
  {
    name:'demoSale',
    url: '/demo-sale',
  },
  {
    name:'purchaseRequests',
  },
];
