import { fetchMasterDataService } from 'library/api/master-data.service';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';

export const searchKitBySerialNumber = serialNumber => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.kitsSerialNumber(serialNumber));

    return { success: true, kitId: data.kitInformationId, systemClassActive: data.systemClassActive };
  } catch (err) {
    return { success: false };
  }
};