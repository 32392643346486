import { connect } from 'react-redux';

import RefurbishmentFees from './RefurbishmenFess.component';
import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';
import { exportRefurbishmentFess, fetchRefurbishmentStocks } from './RefurbishmenFess.action';

const mapStateToProps = ({ masterDataReducer, languageReducer, refurbushmentFeesReducer }) => ({
  countries: masterDataReducer.countries,
  language: languageReducer,
  refurbushmentFeesReducer: refurbushmentFeesReducer.refurbishmentStockList,
});

export default connect(mapStateToProps, { fetchMasterData, exportRefurbishmentFess, fetchRefurbishmentStocks })(
  RefurbishmentFees,
);
