import { connect } from "react-redux";

import SapErpMapping from "./SapErpMapping.component";
import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';
import { fetchSapErpMappingList, createOrEditMapping, deleteMapping } from "./SapErpMapping.actions";

const mapStateToProps = ({ authReducer, languageReducer, appActionsReducer, sapErpMappingReducer, masterDataReducer }) => ({
    user: authReducer.user,
    language: languageReducer,
    appActions: appActionsReducer,
    mappingList: sapErpMappingReducer.mappingList,
    masterData: masterDataReducer,
});

export default connect(mapStateToProps, { fetchSapErpMappingList, createOrEditMapping, deleteMapping, fetchMasterData, })(SapErpMapping);