import { FETCH_MED_ADDRESS_LIST } from './MedUsWarehouseAddress.constants';

const initialState = {
  medAddressArray: {},
};

const MedUsWarehouseAddressReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MED_ADDRESS_LIST:
      return {
        ...state,
        medAddressArray: action.payload,
      };

    default:
      return state;
  }
};

export default MedUsWarehouseAddressReducer;