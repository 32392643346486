import React from 'react';

import Button from 'library/common/commonComponents/Button/Button';
import { getDateInFormat } from 'library/utilities/getDateInFormat';
import strings from 'resources/locales/Translate';

import CreatingOrEditingEditionalComment from '../CreatingOrEditingEditionalComment';
import EditionalComment from '../EditionalComment';
import { useComment } from './Comment.hook';
import Icon from 'library/common/commonComponents/Icon/Icon';
import { DeleteIcon, EditOutlineIcon } from 'library/common/commonComponents/Icon/SvgIcons/Icons';

const Comment = ({
  comment,
  userId,
  user,
  handleEdit,
  createEditionalComment,
  kitDetail,
  updateDemoUnitComment,
  deleteEditionalComment,
}) => {
  const getColorIcon = (status) => {
    switch(status) {
      case 'NO_ISSUES':
        return 'green-round';
      case 'SYSTEM_RESTRICTED':
        return 'yellow-round';
      case 'SYSTEM_DOWN':
        return 'red-round';
      default:
        break;
    }
  };

  const handleClick = () => handleEdit(comment);

  const { createdBy, updatedOn, kitDUSDCommentStatus, text, childComments } = comment;
  const name = `${createdBy.firstName} ${createdBy.lastName}`;
  const date = getDateInFormat('DD.MM.YYYY', updatedOn);
  const colorIcon = getColorIcon(kitDUSDCommentStatus.code);
  const createdById = createdBy ? createdBy.id : null;
  const isShowEditButton = (userId === createdById) && kitDetail.systemClassActive;
  const hasChildComments = childComments && childComments.length > 0;
  const {
    isCreationMode,
    editionalCommentForm,
    isLoader,
    currentEditionalComment,
    hasPermissions,
    toggleCreationMode,
    handleEditionalCommentForm,
    handleSaveEditionalComment,
    handleEditEditionalComment,
    handleEditComment,
    resetCurrentEditionalComment,
    handleDeleteComment,
  } = useComment({
    user,
    comment,
    deleteEditionalComment,
    createEditionalComment,
    kitDetail,
    updateDemoUnitComment,
  });

  return (
    <div className='mb-5'>
      <div className='d-flex align-items-start mb-2'>
        <div className='' style={{ width: '90%' }}>
          <div className='d-flex align-items-start mb-2'>
            <div className={`${colorIcon} mr-2`} style={{marginTop:'2px'}}/>
            <p className='dust-comment-name-date'>
              <span>{date}, </span>
              <span>{name}</span>
            </p>
            <p className='description-container ml-3 dust-bold-text' style={{ width: '67%' }}>
              {text}
            </p>
          </div>
        </div>
        <div className='' style={{ width: '10%' }}>
          <div className='d-flex'>
            {!isCreationMode && hasPermissions && (
              <div className='btn-no-border pr-2 cursor-pointer' onClick={() => toggleCreationMode()}>
                <Icon name='plus' width={21} height={21} fill='rgba(0, 136, 208, 1)' />
              </div>
            )}
            {isShowEditButton&& (
              <div className='cursor-pointer btn-no-border pr-2' onClick={() => handleClick()}>
                <EditOutlineIcon />
              </div>
            )}
            {isShowEditButton && (
              <div
                className='btn-no-border pr-2 cursor-pointer'
                onClick={() => handleDeleteComment({ deleteStatus: true })}
              >
                <DeleteIcon width={18} height={21} viewBox={'0 0 15 14'} />
              </div>
            )}
          </div>
        </div>
      </div>
      {isCreationMode && (
        <CreatingOrEditingEditionalComment
          form={editionalCommentForm}
          handleForm={handleEditionalCommentForm}
          handleCancel={toggleCreationMode}
          handleSave={handleSaveEditionalComment}
          isLoading={isLoader}
        />
      )}
      {hasChildComments &&
        childComments.map(editionalComment => (
          <EditionalComment
            key={editionalComment.id}
            editionalComment={editionalComment}
            userId={userId}
            form={editionalCommentForm}
            handleEdit={handleEditEditionalComment}
            handleUpdate={handleEditComment}
            handleForm={handleEditionalCommentForm}
            currentEditionalComment={currentEditionalComment}
            handleDelete={handleDeleteComment}
            resetCurrentEditionalComment={resetCurrentEditionalComment}
            isLoading={isLoader}
            kitDetail={kitDetail}
          />
        ))}
    </div>
  );
};

export default Comment;
