import {
  FETCH_USER_PERMISSIONS,
  CLEAR_USER_PERMISSIONS,
  FETCH_INITIAL_DATA_FOR_USER_PERMISSIONS,
  FETCH_ROLES_LIST,
  FETCH_USER_PEMISSIONS,
} from './UserPermissions.constants';

const initialState = {
  userPermissions: {},
  businessUnits: [],
  countries: [],
  users: [],
  roles: [],
};

const userPermissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INITIAL_DATA_FOR_USER_PERMISSIONS:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_USER_PERMISSIONS:
      return {
        ...state,
        userPermissions: action.payload,
      };
    case FETCH_ROLES_LIST:
      return {
        ...state,
        roles: action.payload,
      };
    case FETCH_USER_PEMISSIONS:
      return {
        ...state,
        userPermissions: action.payload,
      };
    case CLEAR_USER_PERMISSIONS:
      return {
        ...state,
        userPermissions: {},
      };
    default:
      return state;
  }
};

export default userPermissionsReducer;
