import Validators from 'library/utilities/Validators';

export const priceModel = [
  {
    label: 'price',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'soldPriceType',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: '' }],
    selectAllPlaceholder: 'pleaseSelect',
  },
];

export const localSalesPriceModel = [
  {
    label: 'grossListPrice',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'localPrice',
    validators: [
      { check: Validators.required, message: 'requiredErrorMessage' },
      { check: Validators.number, message: 'invalidNumberError' },
    ],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 15,
    },
  },
  {
    label: 'currency',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'currencyId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'value', separator: '' }],
    selectAllPlaceholder: 'pleaseSelect',
    disabled: true,
  },
];

export const transferPriceModel = [
  {
    label: 'transferPrice',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'transferPrice',
    validators: [
      { check: Validators.required, message: 'requiredErrorMessage' },
      { check: Validators.number, message: 'invalidNumberError' },
    ],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 15,
    },
  },
  {
    label: 'currency',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'currencyForTransferPrice',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    disabled: true,
  },
];
