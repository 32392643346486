import React from 'react';
import $ from 'jquery';

import strings from 'resources/locales/Translate';
import ConfirmDeletionPopup from 'library/common/commonComponents/ConfirmationBox';
import Button from 'library/common/commonComponents/Button/Button';

import './deleteButton.styles.scss'
import SmallButton from 'library/common/commonComponents/SmallButton';
import { DeleteFilledIcon, DeleteIcon } from 'library/common/commonComponents/Icon/SvgIcons/Icons';

const DeleteButton = (props) => {
  const { handleDelete, id, iconName, value, disabled } = props;

  const openPopup = () => {
    if ($(`#confirmKitDeleteModal${id}`) && $(`#confirmKitDeleteModal${id}`).modal) {
      $(`#confirmKitDeleteModal${id}`).modal('show');
    }
  };

  return (
    <div>
      <SmallButton
        dataTest='permissions-filter-button'
        bgFill={false}
        customIcon={<DeleteFilledIcon  className='mr-2' />}
        onClick={openPopup}
        value={value}
        textOnly={true}
        disabled={disabled}
        className="transaction-actions-btns action-danger" 
      />
      <ConfirmDeletionPopup
        id={`confirmKitDeleteModal${id}`}
        onConfirm={() => handleDelete(id)}
        title={strings.confirmDeleteKit}
        subtitle={strings.confirmDeleteKitMessage}
      />
    </div>
  )
};

export default DeleteButton;
