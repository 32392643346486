import React from 'react';

import BarChart from 'library/common/commonComponents/BarChart';
import strings from 'resources/locales/Translate';

import { useWorkingCapitalInStockChart } from './WorkingCapitalInStockChart.hook';

const WorkingCapitalInStockChart = ({ chartData, language }) => {
  const {
    data,
    options,
    hasDataForChart,
  } = useWorkingCapitalInStockChart({ chartData, language });

  return (
    <>
      <h2 className='mb-5'>{strings.workingCapitalInStock}</h2>
        {hasDataForChart ? (
          <div className='row'>
            <div className='col-md-10'>
              <BarChart data={data} defaultOptions={options} />
            </div>
          </div>
        ) : (
          <div className='d-flex justify-content-center my-5'>
            <strong>{strings.noDataFoundText}</strong>
          </div>
        )}
    </>
  );
}

export default WorkingCapitalInStockChart;
