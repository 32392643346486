import React, { useState, useEffect } from 'react';
import { scrollToTop } from 'library/utilities/scrollActions';
import { get } from 'lodash';
import $ from 'jquery';

export const useMaintenanceCheckList = ({
  maintenanceCheckListData,
  usersList,
  saveMantenanceCheckListData,
  toggleActionMessage,
  transactionDetails,
  user,
  getActiveTransaction
}) => {
  const [isArrivalCheckListEdit, setIsArrivalCheckListEdit] = useState(false);
  const [isDemoCheckListEdit, setIsDemoCheckListEdit] = useState(false);
  const [isDismantelCheckListEdit, setIsDismantelCheckListEdit] = useState(false);
  const {
    arrivalAbnormalities,
    arrivalAbnormalitiesComment,
    arrivalCompleteness,
    arrivalCompletenessComment,
    arrivalInstallationAbnormalities,
    arrivalInstallationAbnormalitiesComment,
    unpackedFrom,
    onTimeDelivery,
    setupBy,
    maintenanceChecklistId,
    demoAbnormalities,
    demoAbnormalitiesComment,
    demoIssues,
    demoIssuesComment,
    demoBackupOfTempCustFiles,
    demoBackupOfTempCustFilesComment,
    systemIsDemoReady,
    systemIsDemoReadyComment,
    netPromoterScore,
    dismantleBy,
    dismantleCompleteness,
    dismantleCompletenessComment,
    dismantleLaserSafety,
    dismantleLaserSafetyComment,
    dismantleElectronicSecurity,
    dismantleElectronicSecurityComment,
    dismantleFileDeleted,
    dismantleFileDeletedComment,
    dismantleDemoKitConfirmation,
    demoChecklistCreatedByUserID,
    arrivalChecklistCreatedByUserID,
    dismantleChecklistCreatedByUserID,
  } = maintenanceCheckListData;
  //Defining the sataes
  const [abnormalitiesComment, setAbnormalitiesComment] = useState({
    value: '',
    error: null,
  });
  const [completenesComment, setcompletenesComment] = useState({
    value: '',
    error: null,
  });
  const [installationAbnormalitiesComment, setInstallationAbnormalitiesComment] = useState({
    value: '',
    error: null,
  });
  const [isDemoAbnormalitiesComment, setIsDemoAbnormalitiesComment] = useState({
    value: '',
    error: null,
  });
  const [isDemoIssuesComment, setDemoIssuesComment] = useState({
    value: '',
    error: null,
  });
  const [demoTemperoryFilesComment, setDemoTemperoryFileComment] = useState({
    value: '',
    error: null,
  });
  const [demoSystemIsReadyComment, setDemoSystemIsReadyComment] = useState({
    value: '',
    error: null,
  });
  const [unpackedBy, setunpackedBy] = useState({
    value: '',
    fullValue: '',
  });
  const [setUpBy, setSetUpBy] = useState({
    value: '',
    fullValue: '',
  });
  const [isDismanteledBy, setIsDismanteledBy] = useState({ value: '', fullValue: '' });
  const [isDismantleCompletenessComment, setIsDismantleCompletenessComment] = useState({
    value: '',
    error: null,
  });
  const [isLaserSafetyComment, setIsLaserSafetyComment] = useState({
    value: '',
    error: null,
  });
  const [isElectronicSecurityComment, setIsElectronicSecurityComment] = useState({
    value: '',
    error: null,
  });
  const [isDeletedFilesComment, setIsDeletedFilesComment] = useState({
    value: '',
    error: null,
  });
  const [isArrivalAbonormalities, setArrivalAbnormalities] = useState('');
  const [isCompleteness, setIsCompleteness] = useState('');
  const [isInstallationAbnormalities, setIsInstallationAbnormalities] = useState('');
  const [isOnTimeDelivery, setIsOnTimeDelivery] = useState('');
  const [isDemoAbnormalities, setIsDemoAbnormalities] = useState('');
  const [isDemoIssues, setIsDemoIssues] = useState('');
  const [isDemoTemperoryFile, setIsDemoTemperory] = useState('');
  const [isSystemReadyDemo, setIsSystemReadyDemo] = useState('');
  const [isNps, setIsNps] = useState('');
  const [isDismantleCompleteness, setIsDismantleCompleteness] = useState('');
  const [isLaserSafety, setIsLaserSafety] = useState('');
  const [isElectronicSecurity, setIsElectronicSecurity] = useState('');
  const [isDeletedFiles, setIsDeletedFiles] = useState('');
  const [isDismantleDemoKitConfirmation, setIsDismantleDemoKitConfirmation] = useState('');
  const [loading, setloading] = useState(false);
  const prefilledUnpackBy = usersList && usersList.length > 0 && usersList.find(item => item.userId === unpackedFrom);
  const preFilledSetUpBy = usersList && usersList.length > 0 && usersList.find(item => item.userId === setupBy);
  const preFilledDismatelBy = usersList && usersList.length > 0 && usersList.find(item => item.userId === dismantleBy);
  const demoFormFilledUser =
    usersList && usersList.length > 0 && usersList.find(item => item.userId === demoChecklistCreatedByUserID);
  const arrivalFormFilledUser = user && user.userId === arrivalChecklistCreatedByUserID;
  const dismantleFormFilledUser = user && user.userId === dismantleChecklistCreatedByUserID;
  const demochecklistFilledUser = user && user.userId === demoChecklistCreatedByUserID;

  //seting predefined values in useEfeect start
  const predefinedValuesSet = () => {
    setAbnormalitiesComment({ value: arrivalAbnormalitiesComment ? arrivalAbnormalitiesComment : '', error: null });
    setcompletenesComment({ value: arrivalCompletenessComment ? arrivalCompletenessComment : '', error: null });
    setInstallationAbnormalitiesComment({
      value: arrivalInstallationAbnormalitiesComment ? arrivalInstallationAbnormalitiesComment : '',
      error: null,
    });
    setArrivalAbnormalities(arrivalAbnormalities === true ? 'yes' : arrivalAbnormalities === false ? 'no' : '');
    setIsCompleteness(arrivalCompleteness === true ? 'yes' : arrivalCompleteness === false ? 'no' : '');
    setIsInstallationAbnormalities(
      arrivalInstallationAbnormalities === true ? 'yes' : arrivalInstallationAbnormalities === false ? 'no' : '',
    );
    setIsOnTimeDelivery(
      onTimeDelivery === true ? 'yes' : onTimeDelivery === false ? 'no' : '',
    );
    setunpackedBy({
      value: prefilledUnpackBy && prefilledUnpackBy.userId,
      fullValue: prefilledUnpackBy && prefilledUnpackBy,
    });
    setSetUpBy({ value: preFilledSetUpBy && preFilledSetUpBy.userId, fullValue: preFilledSetUpBy && preFilledSetUpBy });
    setIsDemoAbnormalities(demoAbnormalities === true ? 'yes' : demoAbnormalities === false ? 'no' : '');
    setIsDemoAbnormalitiesComment({ value: demoAbnormalitiesComment ? demoAbnormalitiesComment : '', error: null });
    setIsDemoIssues(demoIssues === true ? 'yes' : demoIssues === false ? 'no' : '');
    setDemoIssuesComment({ value: demoIssuesComment ? demoIssuesComment : '', error: null });
    setIsDemoTemperory(demoBackupOfTempCustFiles === true ? 'yes' : demoBackupOfTempCustFiles === false ? 'no' : '');
    setDemoTemperoryFileComment({
      value: demoBackupOfTempCustFilesComment ? demoBackupOfTempCustFilesComment : '',
      error: null,
    });
    setIsSystemReadyDemo(
      systemIsDemoReady === 'YES'
        ? 'yes'
        : systemIsDemoReady === 'RESTRICTED'
        ? 'yesButRestricted'
        : systemIsDemoReady === 'NO'
        ? 'no'
        : '',
    );
    setDemoSystemIsReadyComment({ value: systemIsDemoReadyComment ? systemIsDemoReadyComment : '', error: null });
    setIsNps(netPromoterScore);
    setIsDismanteledBy({
      value: preFilledDismatelBy && preFilledDismatelBy.userId,
      fullValue: preFilledDismatelBy && preFilledDismatelBy,
    });
    setIsDismantleCompleteness(dismantleCompleteness === true ? 'yes' : dismantleCompleteness === false ? 'no' : '');
    setIsDismantleCompletenessComment({
      value: dismantleCompletenessComment ? dismantleCompletenessComment : '',
      error: null,
    });
    setIsLaserSafety(dismantleLaserSafety === true ? 'yes' : dismantleLaserSafety === false ? 'no' : '');
    setIsLaserSafetyComment({ value: dismantleLaserSafetyComment ? dismantleLaserSafetyComment : '', error: null });
    setIsElectronicSecurity(
      dismantleElectronicSecurity === true ? 'yes' : dismantleElectronicSecurity === false ? 'no' : '',
    );
    setIsElectronicSecurityComment({
      value: dismantleElectronicSecurityComment ? dismantleElectronicSecurityComment : '',
      error: null,
    });
    setIsDeletedFiles(dismantleFileDeleted === true ? 'yes' : dismantleFileDeleted === false ? 'no' : '');
    setIsDeletedFilesComment({ value: dismantleFileDeletedComment ? dismantleFileDeletedComment : '', error: null });
    setIsDismantleDemoKitConfirmation(dismantleDemoKitConfirmation ? dismantleDemoKitConfirmation : '');
  };
  useEffect(() => {
    predefinedValuesSet();
    getActiveTransaction(transactionDetails.transactionsId);
  }, [maintenanceCheckListData, saveMantenanceCheckListData]);

  //seting predefined values in useEfeect end

  const editCheckList = key => {
    if (key === 'arrival') {
      setIsArrivalCheckListEdit(prevState => !prevState);
    }
    if (key === 'demo') {
      setIsDemoCheckListEdit(prevState => !prevState);
    }
    if (key === 'dismantle') {
      setIsDismantelCheckListEdit(prevState => !prevState);
    }
  };

  const onChangeDropdown = (value, field, fullValue) => {
    if (field === 'unpackedBy') {
      return setunpackedBy({ value, fullValue });
    }
    if (field === 'setUpBy') {
      return setSetUpBy({ value, fullValue });
    }
    if (field === 'dismantleBy') {
      return setIsDismanteledBy({ value, fullValue });
    }
  };

  const onChangeCheckbox = (value, field) => {
    if (field === 'canBeShipped') {
      return setIsDismantleDemoKitConfirmation('CAN_BE_SHIPPED');
    }
    if (field === 'mustBeShipped') {
      return setIsDismantleDemoKitConfirmation('MUST_BE_SHIPPED');
    }
    if (field === 'discussionWithProdCenter') {
      return setIsDismantleDemoKitConfirmation('DISCUSSION_WITH_PRODUCTION_CENTER');
    }
  };

  const onChangeInput = (value, field) => {
    if (field === 'arrivalAbnormalitiesComment') {
      return setAbnormalitiesComment({
        value,
        error: null,
      });
    }
    if (field === 'arrivalCompletenessComment') {
      return setcompletenesComment({
        value,
        error: null,
      });
    }
    if (field === 'arrivalInstallationAbnormalitiesComment') {
      return setInstallationAbnormalitiesComment({
        value,
        error: null,
      });
    }
    if (field === 'demoAbnormalitiesComment') {
      return setIsDemoAbnormalitiesComment({
        value,
        error: null,
      });
    }
    if (field === 'demoIssuesComment') {
      return setDemoIssuesComment({
        value,
        error: null,
      });
    }
    if (field === 'demoBackupOfTempCustFilesComment') {
      return setDemoTemperoryFileComment({
        value,
        error: null,
      });
    }
    if (field === 'systemIsDemoReadyComment') {
      return setDemoSystemIsReadyComment({
        value,
        error: null,
      });
    }
    if (field === 'dismantleCompletenessComment') {
      return setIsDismantleCompletenessComment({
        value,
        error: null,
      });
    }
    if (field === 'dismantleLaserSafetyComment') {
      return setIsLaserSafetyComment({
        value,
        error: null,
      });
    }
    if (field === 'dismantleElectronicSecurityComment') {
      return setIsElectronicSecurityComment({
        value,
        error: null,
      });
    }
    if (field === 'dismantleFileDeletedComment') {
      return setIsDeletedFilesComment({
        value,
        error: null,
      });
    }
  };

  const onChangeRadioButton = (value, filed) => {
    if (filed === 'arrivalAbnormalities') {
      setArrivalAbnormalities(value);
    }
    if (filed === 'arrivalInstallationAbnormalities') {
      setIsInstallationAbnormalities(value);
    }
    if (filed === 'onTimeDelivery') {
      setIsOnTimeDelivery(value);
    }
    if (filed === 'arrivalCompleteness') {
      setIsCompleteness(value);
    }
    if (filed === 'demoAbnormalities') {
      setIsDemoAbnormalities(value);
    }
    if (filed === 'demoIssues') {
      setIsDemoIssues(value);
    }
    if (filed === 'demoBackupOfTempCustFiles') {
      setIsDemoTemperory(value);
    }
    if (filed === 'systemIsDemoReady') {
      setIsSystemReadyDemo(value);
    }
    if (filed === 'dismantleCompleteness') {
      setIsDismantleCompleteness(value);
    }
    if (filed === 'dismantleLaserSafety') {
      setIsLaserSafety(value);
    }
    if (filed === 'dismantleElectronicSecurity') {
      setIsElectronicSecurity(value);
    }
    if (filed === 'dismantleFileDeleted') {
      setIsDeletedFiles(value);
    }
  };
  const onChangeNps = value => {
    setIsNps(value);
  };

  const hasPermissionToEdit = (user, stockId) => {
    let isPermission = false;
    if (user.admin) {
      return true;
    }
    if (user && user.stockRoles && user.stockRoles.length && stockId) {
      user.stockRoles.forEach(role => {
        if (
          (role.roleName.toLowerCase() === 'dispatcher' ||
            role.roleName.toLowerCase() === 'stock controller' ||
            role.roleName.toLowerCase() === 'dispatcher' ||
            role.roleName.toLowerCase() === 'reconditioning') &&
          stockId === role.stockId
        ) {
          isPermission = true;
        }
      });
    }
    return isPermission;
  };
  const stockId = get(transactionDetails, 'transactionKit.stockInformation.id', '');
  const hasPermissionToEditCheckList = hasPermissionToEdit(user, stockId);
  const arrivalValidateFields = () => {
    let isValid = true;
    if (abnormalitiesComment.value === '' && isArrivalAbonormalities === 'yes') {
      isValid = false;
      setAbnormalitiesComment(prevState => ({
        ...prevState,
        error: 'requiredErrorMessage',
      }));
    }
    if (completenesComment.value === '' && isCompleteness === 'no') {
      isValid = false;
      setcompletenesComment(prevState => ({
        ...prevState,
        error: 'requiredErrorMessage',
      }));
    }
    if (installationAbnormalitiesComment.value === '' && isInstallationAbnormalities === 'yes') {
      isValid = false;
      setInstallationAbnormalitiesComment(prevState => ({
        ...prevState,
        error: 'requiredErrorMessage',
      }));
    }
    return isValid;
  };
  const demoValidateFields = () => {
    let isValid = true;
    if (isDemoAbnormalitiesComment.value === '' && isDemoAbnormalities === 'yes') {
      isValid = false;
      setIsDemoAbnormalitiesComment(prevState => ({
        ...prevState,
        error: 'requiredErrorMessage',
      }));
    }
    if (isDemoIssuesComment.value === '' && isDemoIssues === 'yes') {
      isValid = false;
      setDemoIssuesComment(prevState => ({
        ...prevState,
        error: 'requiredErrorMessage',
      }));
    }
    if (demoTemperoryFilesComment.value === '' && isDemoTemperoryFile === 'yes') {
      isValid = false;
      setDemoTemperoryFileComment(prevState => ({
        ...prevState,
        error: 'requiredErrorMessage',
      }));
    }
    if (demoSystemIsReadyComment.value === '' && isSystemReadyDemo === 'no') {
      isValid = false;
      setDemoSystemIsReadyComment(prevState => ({
        ...prevState,
        error: 'requiredErrorMessage',
      }));
    }
    return isValid;
  };

  const dismantleValidateFields = () => {
    let isValid = true;
    if (isDismantleCompletenessComment.value === '' && isDismantleCompleteness === 'no') {
      isValid = false;
      setIsDismantleCompletenessComment(prevState => ({
        ...prevState,
        error: 'requiredErrorMessage',
      }));
    }
    if (isLaserSafetyComment.value === '' && isLaserSafety === 'no') {
      isValid = false;
      setIsLaserSafetyComment(prevState => ({
        ...prevState,
        error: 'requiredErrorMessage',
      }));
    }
    if (isElectronicSecurityComment.value === '' && isElectronicSecurity === 'no') {
      isValid = false;
      setIsElectronicSecurityComment(prevState => ({
        ...prevState,
        error: 'requiredErrorMessage',
      }));
    }
    if (isDeletedFilesComment.value === '' && isDeletedFiles === 'no') {
      isValid = false;
      setIsDeletedFilesComment(prevState => ({
        ...prevState,
        error: 'requiredErrorMessage',
      }));
    }
    return isValid;
  };
  const saveMaintenanceCheckListForm = async action => {
    let isValidFields;
    if (action === 'arrival') {
      isValidFields = arrivalValidateFields();
    } else if (action === 'demo') {
      isValidFields = demoValidateFields();
    } else if (action === 'dismantle') {
      isValidFields = dismantleValidateFields();
    }
    if (!isValidFields) {
      scrollToTop(500);
      return toggleActionMessage(true, 'error', 'formIsInvalid');
    }
    let dataToSend = {};
    dataToSend.arrivalAbnormalities =
      isArrivalAbonormalities === 'yes' ? true : isArrivalAbonormalities === 'no' ? false : null;
    dataToSend.arrivalAbnormalitiesComment =
      isArrivalAbonormalities === 'no' ? null : abnormalitiesComment.value !== '' ? abnormalitiesComment.value : null;
    dataToSend.arrivalCompleteness = isCompleteness === 'yes' ? true : isCompleteness === 'no' ? false : null;
    dataToSend.arrivalCompletenessComment =
      isCompleteness === 'yes' ? null : completenesComment.value !== '' ? completenesComment.value : null;
    dataToSend.arrivalInstallationAbnormalities =
      isInstallationAbnormalities === 'yes' ? true : isInstallationAbnormalities === 'no' ? false : null;
    dataToSend.onTimeDelivery =
      isOnTimeDelivery === 'yes' ? true : isOnTimeDelivery === 'no' ? false : null;
    dataToSend.arrivalInstallationAbnormalitiesComment =
      isInstallationAbnormalities === 'no'
        ? null
        : installationAbnormalitiesComment.value !== ''
        ? installationAbnormalitiesComment.value
        : null;
    dataToSend.unpackedFrom = unpackedBy.value ? unpackedBy.value : null;
    dataToSend.setupBy = setUpBy.value ? setUpBy.value : null;
    dataToSend.demoAbnormalities = isDemoAbnormalities === 'yes' ? true : isDemoAbnormalities === 'no' ? false : null;
    dataToSend.demoAbnormalitiesComment =
      isDemoAbnormalities === 'no'
        ? null
        : isDemoAbnormalitiesComment.value !== ''
        ? isDemoAbnormalitiesComment.value
        : null;
    dataToSend.demoIssues = isDemoIssues === 'yes' ? true : isDemoIssues === 'no' ? false : null;
    dataToSend.demoIssuesComment =
      isDemoIssues === 'no' ? null : isDemoIssuesComment.value !== '' ? isDemoIssuesComment.value : null;
    dataToSend.demoBackupOfTempCustFiles =
      isDemoTemperoryFile === 'yes' ? true : isDemoTemperoryFile === 'no' ? false : null;
    dataToSend.demoBackupOfTempCustFilesComment =
      isDemoTemperoryFile === 'no'
        ? null
        : demoTemperoryFilesComment.value !== ''
        ? demoTemperoryFilesComment.value
        : null;
    dataToSend.systemIsDemoReady =
      isSystemReadyDemo === 'yes'
        ? 'YES'
        : isSystemReadyDemo === 'yesButRestricted'
        ? 'RESTRICTED'
        : isSystemReadyDemo === 'no'
        ? 'NO'
        : null;
    dataToSend.systemIsDemoReadyComment = demoSystemIsReadyComment.value !== '' ? demoSystemIsReadyComment.value : null;
    dataToSend.netPromoterScore = isNps;
    dataToSend.dismantleBy = isDismanteledBy.value ? isDismanteledBy.value : null;
    dataToSend.dismantleCompleteness =
      isDismantleCompleteness === 'yes' ? true : isDismantleCompleteness === 'no' ? false : null;
    dataToSend.dismantleCompletenessComment =
      isDismantleCompleteness === 'yes'
        ? null
        : isDismantleCompletenessComment.value !== ''
        ? isDismantleCompletenessComment.value
        : null;
    dataToSend.dismantleLaserSafety = isLaserSafety === 'yes' ? true : isLaserSafety === 'no' ? false : null;
    dataToSend.dismantleLaserSafetyComment =
      isLaserSafety === 'yes' ? null : isLaserSafetyComment.value !== '' ? isLaserSafetyComment.value : null;
    dataToSend.dismantleElectronicSecurity =
      isElectronicSecurity === 'yes' ? true : isElectronicSecurity === 'no' ? false : null;
    dataToSend.dismantleElectronicSecurityComment =
      isElectronicSecurity === 'yes'
        ? null
        : isElectronicSecurityComment.value !== ''
        ? isElectronicSecurityComment.value
        : null;
    dataToSend.dismantleFileDeleted = isDeletedFiles === 'yes' ? true : isDeletedFiles === 'no' ? false : null;
    dataToSend.dismantleFileDeletedComment =
      isDeletedFiles === 'yes' ? null : isDeletedFilesComment.value !== '' ? isDeletedFilesComment.value : null;
    dataToSend.dismantleDemoKitConfirmation = isDismantleDemoKitConfirmation ? isDismantleDemoKitConfirmation : null;
    setloading(true);
    const { success } = await saveMantenanceCheckListData(
      dataToSend,
      action,
      maintenanceChecklistId,
      transactionDetails.transactionsId,
    );
    setloading(false);
    if (success && action === 'arrival') {
      setIsArrivalCheckListEdit(prevState => !prevState);
    } else if (success && action === 'demo') {
      setIsDemoCheckListEdit(prevState => !prevState);
    } else if (success && action === 'dismantle') {
      setIsDismantelCheckListEdit(prevState => !prevState);
    }
  };
  const cancelMaintenanceCheckListForm = key => {
    if (key === 'arrival') {
      setIsArrivalCheckListEdit(prevState => !prevState);
      predefinedValuesSet();
    }
    if (key === 'demo') {
      setIsDemoCheckListEdit(prevState => !prevState);
      predefinedValuesSet();
    }
    if (key === 'dismantle') {
      setIsDismantelCheckListEdit(prevState => !prevState);
      predefinedValuesSet();
    }
  };

  const openCheckListOptionsPopup = () => {
    $('#checkList-options').modal('show');
    // $('#demo-form').modal('show');
  }

  const openArrivalCheckListPopUp = () => {
    $(`#checkList-options`).modal('hide'); 
    setTimeout(() => {
    $('#arrival-form').modal('show');
    }, 500);
  
  }

  const openDemoCheckListPopUp = () => {
    $('#checkList-options').modal('hide');
    setTimeout(() => {
      $('#demo-form').modal('show');
    }, 500);
  }

  const openDismantleCheckListPopUp = () => {
    $('#checkList-options').modal('hide');
    setTimeout(() => {
      $('#dismantle-form').modal('show');
    }, 500);
  }

  return {
    isArrivalCheckListEdit,
    isDemoCheckListEdit,
    isDismantelCheckListEdit,
    editCheckList,
    onChangeDropdown,
    onChangeInput,
    onChangeCheckbox,
    abnormalitiesComment,
    completenesComment,
    installationAbnormalitiesComment,
    unpackedBy,
    setUpBy,
    saveMaintenanceCheckListForm,
    onChangeRadioButton,
    isArrivalAbonormalities,
    isCompleteness,
    isInstallationAbnormalities,
    isOnTimeDelivery,
    isDemoAbnormalitiesComment,
    isDemoAbnormalities,
    isDemoIssuesComment,
    isDemoIssues,
    demoTemperoryFilesComment,
    isDemoTemperoryFile,
    demoSystemIsReadyComment,
    isSystemReadyDemo,
    isNps,
    isDismanteledBy,
    isDismantleCompleteness,
    isDismantleCompletenessComment,
    isLaserSafety,
    isLaserSafetyComment,
    isElectronicSecurity,
    isElectronicSecurityComment,
    isDeletedFiles,
    isDeletedFilesComment,
    isDismantleDemoKitConfirmation,
    onChangeNps,
    demoFormFilledUser,
    loading,
    hasPermissionToEditCheckList,
    cancelMaintenanceCheckListForm,
    arrivalFormFilledUser,
    dismantleFormFilledUser,
    demochecklistFilledUser,
    openCheckListOptionsPopup,
    openArrivalCheckListPopUp,
    openDemoCheckListPopUp,
    openDismantleCheckListPopUp
  };
};
