import React from 'react';

import FormComponent from '../FormComponent/Optimized';
import Icon from '../Icon/Icon';

import './addMoreDeliveryTimesStyle.scss';
import { validateForm } from 'library/utilities/ValidateForm.util';

export const AddMoreDeliveryTimes = ({
  countries,
  index,
  model,
  hideDeleteIcon,
  onAdd,
  onChange,
  onRemove,
  valueAlreadyAdded,
  value,
  fromProvinces,
  stockCountryId
}) => {
  const valuesToDisplay = valueAlreadyAdded ? {
    deliveryTimeId: value.deliveryTimeId,
    countryId: value.countryId,
    countryIdFullValue: value.countryIdFullValue,
    countryIdError: value.countryIdError,
    deliveryDays: value.deliveryDays,
    deliveryDaysError: value.deliveryDaysError,
    returnDeliveryDays: value.returnDeliveryDays,
    returnDeliveryDaysError: value.returnDeliveryDaysError,
    returnDeliveryAddress: value.returnDeliveryAddress,
    returnDeliveryAddressError: value.returnDeliveryAddressError,
    returnDeliveryAddressFullValue: value.returnDeliveryAddressFullValue,
    stateId: value.stateId,
    stateIdFullValue: value.stateIdFullValue,
    stateIdError: value.stateIdError,
  } : value

  const addNewDeliveryTime = () => {
    const form = validateForm({ form: { formData: value }, model });
    if (form.isFormValid) {
      onAdd(value);
      onChange(
        fromProvinces ? {
          countryIdFullValue: countries.find(country => country.id === stockCountryId),
          countryId:stockCountryId, countryIdError:null,
          deliveryDays: '', returnDeliveryDays: '', returnDeliveryAddress: ''
        } :
       {deliveryDays: '', returnDeliveryDays: '', returnDeliveryAddress: '' }, index, valueAlreadyAdded);
    } else {
      onChange(form.formData, index, valueAlreadyAdded);
    }
  };

  const handleForm = ({ values }) => {
    onChange({ ...valuesToDisplay, ...values }, index, valueAlreadyAdded);
  };

  const removeDeliveryTime = () => {
    onRemove(index, value.deliveryTimeId)
  };

  return (
    <div className='add-more-delivery-time'>
      <div className='row'>
        <div className='col-sm-11'>
          <FormComponent
            model={model}
            disableForm={hideDeleteIcon}
            onChange={handleForm}
            formValue={valuesToDisplay}
          />
        </div>
        {!hideDeleteIcon && (
          <div className='col-sm-1'>
            <button
              className={valueAlreadyAdded ? 'remove-button' : 'add-button'}
              onClick={valueAlreadyAdded ? removeDeliveryTime : addNewDeliveryTime}
            >
              <Icon
                name={valueAlreadyAdded ? 'delete' : 'plus'}
                width={24}
                height={24}
                fill={valueAlreadyAdded ? '#0088d0' : '#fff'}
              />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddMoreDeliveryTimes;
