import React, { useState } from 'react';
import $ from 'jquery';
import { xorBy } from 'lodash';

import Modal from 'library/common/commonComponents/Modal';
import strings from 'resources/locales/Translate';

export default function ConflictTransactionsPopup({
  id,
  conflictedTransactions,
  handleSaveTransactionDetails,
  onEditPress,
  setConflictedTransactions,
  bundleLoans,
  type,
  transactionDetails,
}) {
  const [isLoading, toggleIsLoading] = useState(false);
  const transactionsNumber = conflictedTransactions.map(item => item.transactionNumber);

  const closePopup = () => {
    if ($(`#${id}`) && $(`#${id}`).modal) {
      $(`#${id}`).modal('hide');
    }
  };

  const onCloseClick = async () => {
    toggleIsLoading(true);
    const { success } = await handleSaveTransactionDetails([transactionDetails.transactionsId]);

    if (success) {
      toggleIsLoading(false);
      if (type === 'isTransactionDetails' || type === 'isUpdatingTransactionWithTransactionStatus') onEditPress();
      setConflictedTransactions([]);
      closePopup();
    }
  };

  const handleConfirmAction = async () => {
    const unicTransactions = xorBy(bundleLoans, conflictedTransactions, 'transactionNumber');
    const reletedTransactionsId = unicTransactions.map(item => item.transactionsId);
    const transactionsId = [transactionDetails.transactionsId, ...reletedTransactionsId];

    toggleIsLoading(true);
    const { success } = await handleSaveTransactionDetails(transactionsId);

    if (success) {
      toggleIsLoading(false);
      if (type === 'isTransactionDetails' || type === 'isUpdatingTransactionWithTransactionStatus') onEditPress();
      setConflictedTransactions([]);
      closePopup();
    } 
  };

  return (
    <Modal
      id={id}
      className='mt-5'
      title={strings.conflict}
      confirmAction={handleConfirmAction}
      confirmDataTest='conflict-transactions-popup'
      loading={isLoading}
      onCloseClick={onCloseClick}
      titleOfCancel={strings.no}
      confirmTitle={strings.yes}
    >
      <div>{strings.conflictWithTransaction(transactionsNumber)}</div>
    </Modal>
  );
};
