import React, { useEffect } from 'react';
import toArray from 'lodash/toArray';

import strings from 'resources/locales/Translate';
import { FETCH_COUNTRY_LIST } from 'library/common/commonConstants/storeConstants';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import Table from 'library/common/commonComponents/Table/Table';
import SearchField from 'library/common/commonComponents/SearchField/SearchField';
import ItemsPerPage from 'library/common/commonComponents/ItemsPerPage/ItemsPerPage';
import Pagination from 'library/common/commonComponents/Pagination/Pagination';
import { itemsPerPage, sapErpMappingBreadcrumbData } from './SapErpMapping.constants';
import Button from 'library/common/commonComponents/Button/Button';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import './sapErpMapping.styles.scss'
import { useSapErpMapping } from './SapErpMapping.hook';
import CreateOrEditMapping from './Components/CrateOrEditMapping/CreateOrEditMapping.component';

const SapErpMapping = ({ user, appActions, fetchSapErpMappingList, mappingList, createOrEditMapping, deleteMapping, fetchMasterData, masterData }) => {
    document.title = 'SAP ERP Mapping';
    const { isLoading, displayActionMessage, type, message } = appActions;
    useEffect(() => {
        fetchMasterData(URLS.countryList, FETCH_COUNTRY_LIST);
    }, []);
    const countries = masterData.countries;
    const {
        data,
        getTableRows,
        handleSort,
        handleItemsPerPage,
        handlePageChange,
        openCreateTaskPopup,
        selectedMapping,
        setSelectedMapping,
        openEditTaskPopup,
        handleSearchChange,
    } = useSapErpMapping({ fetchSapErpMappingList, deleteMapping });
    const isTasksList = mappingList && mappingList.content && mappingList.content.length > 0;
    return (
        <div>
            {displayActionMessage && <ActionMessage type={type} message={strings[message] || message} />}
            <div className='container-fluid mb-3'>
                <Breadcrumbs data={sapErpMappingBreadcrumbData} />
                <div className='row no-gutters align-items-center'>
                    <h1 className='col-md col-12 kit-title'>{strings.sapERPMappingTable}</h1>
                    <div className='col-md-auto col mr-3'>
                        <div className='input-wrapper'>
                            <SearchField onChange={handleSearchChange} initialValue={data.filter.searchTerm} />
                        </div>

                    </div>
                </div>
            </div>
            <div className='task-table'>
                <div className='container-fluid mb-3'>
                    {isTasksList ? (
                        <Table
                            user={user}
                            cols={getTableRows()}
                            rows={mappingList.content}
                            sortFields={data.sort}
                            handleSort={handleSort}
                            rowId='accountId'
                        />
                    ) : (
                        <div className='d-flex justify-content-center'>
                            <strong>{strings.noDataFoundText}</strong>
                        </div>
                    )}
                </div>
            </div>
            <div className='container-fluid'>
                <div className='row no-gutters'>
                    <Button
                        bgFill={false}
                        iconName='plus'
                        onClick={openCreateTaskPopup}
                        value={strings.addMapping}
                        styleClass='w-auto'
                    />
                    {isTasksList ? (
                        <div className='col d-flex justify-content-end mb-3'>
                            <ItemsPerPage
                                itemsPerPage={toArray(itemsPerPage)}
                                handleItemsPerPage={handleItemsPerPage}
                                value={itemsPerPage[data.size]}
                            />
                            <Pagination
                                currentPage={data.page - 1}
                                totalPages={mappingList.totalPages}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    ) : null}
                </div>
            </div>

            <CreateOrEditMapping
                id='create-mapping-modal'
                selectedMapping={selectedMapping}
                setSelectedMapping={setSelectedMapping}
                createOrEditMapping={createOrEditMapping}
                data={data}
                countries={masterData.countries} />
            <CreateOrEditMapping
                id='edit-mapping-modal'
                selectedMapping={selectedMapping}
                setSelectedMapping={setSelectedMapping}
                hasEditMode={true}
                data={data}
                createOrEditMapping={createOrEditMapping}
                countries={masterData.countries} />
        </div>
    );
}

export default SapErpMapping;