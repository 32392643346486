import React, { useEffect, useCallback, useState } from 'react';
import strings from 'resources/locales/Translate';
import Validators from 'library/utilities/Validators';
import $ from 'jquery';
import { validateForm } from 'library/utilities/ValidateForm.util';

export const useKitMessagesTab = ({ fetchKitMessagesList, kitMessagesList, kitId, modalId, createKitMessage }) => {
  const [doValidate, setDoValidate] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState(1);
  const [state, setState] = useState({
    page: 0,
    size: 20
  });

  const [createMessageFormData, setCreateMessageFormData] = useState({
    formData: {
      type: 1,
    },
  });
  const [messagesUpdatedList, setMessagesUpdatedList] = useState(kitMessagesList);

  const formValue = { type: 1 };

  const dropdownOptions = {
    1: { id: 1, value: 'comment', name: strings.commentText },
  };

  const model = [
    {
      dataTest: 'transaction-details-dropdown',
      label: '',
      value: '',
      type: 'dropdown',
      placeholder: 'pleaseSelect',
      field: 'type',
      validators: [{ check: Validators.required, message: strings.requiredErrorMessage }],
      required: true,
      styleClass: 'mt-3',
      options: Object.values(dropdownOptions),
      initialValue: dropdownOptions[formValue.type],
      hasSection: false,
      multiSelect: false,
      filter: false,
      idKey: 'id',
      displayValue: [{ key: 'name', separator: '' }],
      clear: true,
      isClearable: true,
    },
    {
      dataTest: 'transaction-details-textarea',
      label: 'commentText',
      value: '',
      type: 'textarea',
      placeholder: '',
      field: 'comment',
      validators: [{ check: Validators.required, message: strings.requiredErrorMessage }],
      required: true,
      styleClass: 'mt-2',
      clear: true,
      isClearable: true,
    },
  ];

  const [dynamicModel, setDynamicModel] = useState(model);

  const onCloseClick = () => {
    resetForm();
    if ($(`#${modalId}`) && $(`#${modalId}`).modal) {
      $(`#${modalId}`).modal('hide');
    }
  };

  const resetForm = () => {
    setErrorMessage('');
    setDoValidate(false);
    setCreateMessageFormData({
      formData: {
        type: 1,
      },
    });
  };

  const handleItemsPerPage = useCallback(size => {
    setState(prevState => ({
      ...prevState,
      size,
    }));

  }, []);

  const handlePageChange = useCallback(page => {
    setState(prevState => ({
      ...prevState,
      page: page ,
    }));

  }, []);

  const onSaveClick = async () => {
    const {page, size} = state;
    const validForm = validateForm({
      form: createMessageFormData,
      model: dynamicModel,
    });

    if (!validForm.isFormValid) {
      return setCreateMessageFormData(validForm);
    }
    onCloseClick();
    const { success } = await createKitMessage(kitId, createMessageFormData?.formData?.comment);
    if (success) {
      fetchKitMessagesList(kitId, page, size);
    }
  };

  const handleForm = ({ values, field }) => {
    if (field === 'type') {
      handleTypeChange(values.type);
    }
    setCreateMessageFormData({
      ...createMessageFormData,
      formData: {
        ...createMessageFormData.formData,
        ...values,
      },
    });
  };

  const onClickingCreateMessage = () => {
    $(`#${modalId}`).modal('show');
  };

  const handleTypeChange = value => {
    setSelectedType(value);
    setDoValidate(false);
  };

  useEffect(() => {
    setMessagesUpdatedList(kitMessagesList);
  }, [kitMessagesList]);

  useEffect(() => {
    const {page, size} = state;
    if (kitId) {
      fetchKitMessagesList(kitId, page, size);
    }
  }, [fetchKitMessagesList, kitId, state]);

  return {
    messagesUpdatedList,
    onSaveClick,
    loading,
    onCloseClick,
    dynamicModel,
    errorMessage,
    createMessageFormData,
    handleForm,
    onClickingCreateMessage,
    handleItemsPerPage,
    handlePageChange,
    state
  };
};
