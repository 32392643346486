import React, {PureComponent} from 'react';
import Dropzone from 'react-dropzone';
import {connect} from 'react-redux';
import cn from 'classnames';

import strings from 'resources/locales/Translate';

import Icon from '../Icon/Icon';
import './uploaderStyles.scss';

class Uploader extends PureComponent {
  dropzoneRef = React.createRef();

  state = {
    error: '',
  };

  checkFormat = files => {
    const { availableTypes, errorMessage } = this.props;
    let isValid = true;
    if (!files.length) {
      isValid = false;
      this.setState({ error: errorMessage ? (availableTypes || strings.allowedFormats) : '' });
    } else {
      this.setState({ error: '' });
    }

    return isValid;
  };
  checkFileSize = files => {
    const { errorMessage } = this.props;
    let isValid = true;
    if (files && files.length) {
      //This will return size in MB
    const size = parseFloat(files[0].size / (1024 * 1024)).toFixed(2);
     // here we have to use method to find file size
    if (size > 30) {
      isValid = false;
      this.setState({ error: errorMessage || '' });
    } else {
      this.setState({ error: '' });
    }
    }
    return isValid;
  };


  onDrop(files) {
    const {maxFiles, allFiles, onFileAdd, onMaxFileReached} = this.props;
    const isMultipleFileUploader = true;
    const totalFiles = allFiles.length + files.length;
    const isAllowedFormat = this.checkFormat(files);
    
    if (!isAllowedFormat) return;

    const isValidFileSize = this.checkFileSize(files);

    if (!isValidFileSize) return;

    if (maxFiles > -1 && totalFiles > maxFiles) {
      onMaxFileReached(true, isMultipleFileUploader);
      return;
    }

    files.map(file => {
      Object.assign(file, {preview: URL.createObjectURL(file)});
      return true;
    });

    onFileAdd(files);
  }

  onDropRejected(files) {
    this.props.onReject(files);
  }

  render() {
    const { error } = this.state;
    const { viewType, mimeType, maxSize, availableTypes, iconStyle, uploaderStyle, isMultiple, text, newVersionUi, isIqsAttachment, disabled } = this.props;
    return (
      <div className='file-uploader-container'>
        <Dropzone
          ref={this.dropzoneRef}
          multiple={isMultiple}
          accept={mimeType}
          maxSize={1000 * 1000 * maxSize}
          onDropRejected={this.onDropRejected.bind(this)}
          onDrop={this.onDrop.bind(this)}
          disabled={disabled}>
          {({getRootProps, getInputProps}) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className={cn('uploader', uploaderStyle, { 'error-border': error })}
                   style={{height: viewType === 'grid' && '348px'}}>
                {!isIqsAttachment && <div className='d-flex justify-content-center align-items-center h-100'>
                  <Icon name='plus' height={24} width={24} className={iconStyle} fill={newVersionUi ? '#0088D0' :''}/>
                  <p>{text}</p>
                </div>}
                {isIqsAttachment && <div className=''>
                  <Icon name='uploaderBrowse' height={37} width={30} className={iconStyle} fill={'#0088D0'}/>
                  <p className='adjust-font-size-drag'>{strings.dragFile} <span style={{color:'#0088D0'}}>{strings.browse}</span> </p> 
                  </div>}
              </div>
              {error && <div className='mt-2 error-block'>{error}</div>}
              {!error && availableTypes && <div className='mt-2 allowed-formats-text'>{availableTypes}</div>}
            </div>
          )}
        </Dropzone>
      </div>
    )
  }
}

Uploader.defaultProps = {
  onFileAdd: () => {
  },
  viewType: 'list',
  mimeType: '',
  maxSize: 100, // MB
  maxFiles: -1,
  isMultiple: true,
  text: strings.addFileText,
  onReject: () => {
  },
  onMaxFileReached: () => {
  },
  isIqsAttachment: false,
  disabled: false,
};

const mapStateToProps = state => {
  return {
    language: state.languageReducer
  }
};

export default connect(mapStateToProps)(Uploader);
