import moment from 'moment';
import { useEffect, useState, useCallback } from 'react';
import strings from 'resources/locales/Translate';
import { requestPickUpModel } from './RequestPickupPopup.constants';
import $ from 'jquery';
import { validateForm } from 'library/utilities/ValidateForm.util';
import { fetchFromStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import { header, footer } from './RequestPickUpHeaderandFooter';

export const useRequestPickUpPopup = props => {
  const { transactionDetails, id, language, sendLogisticEmail, workFlowQuestions } = props;
  const [model, setModel] = useState(requestPickUpModel);
  const [form, setFormData] = useState({
    formData: {},
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const user = fetchFromStorage(identifiers.user);
  const [tempPickUpType, settempPickUpType] = useState('');
  const checkForWorkFlowActivation = () => {
    if (workFlowQuestions && workFlowQuestions.stockWorkflowDTOS && workFlowQuestions.stockWorkflowDTOS.length > 0) {
      if (
        workFlowQuestions.stockWorkflowDTOS[0].stockWorkflowSettingDTO &&
        workFlowQuestions.stockWorkflowDTOS[0].stockWorkflowSettingDTO.length > 0 &&
        workFlowQuestions.stockWorkflowDTOS[0].stockWorkflowSettingDTO[5].categoryAnswer === 'yes'
      ) {
        return true;
      }
    }
    return false;
  };
  const showPicUpComment = checkForWorkFlowActivation() && workFlowQuestions && workFlowQuestions.pickUpComment;

  const [prevTran, setPrevTran] = useState({});

  const stockCountry =    transactionDetails && transactionDetails.stockInformation && transactionDetails.stockInformation
  ? transactionDetails &&
    transactionDetails.stockInformation &&
    transactionDetails.stockInformation.country
  : transactionDetails &&
    transactionDetails.transactionKit &&
    transactionDetails.transactionKit.stockInformation.country;

  const isStockCountryUsOrCanada = stockCountry && (stockCountry.shortName === "US" || stockCountry.shortName === "CA");

  let emailsList =
    transactionDetails &&
    transactionDetails.transactionKit &&
    transactionDetails.transactionKit.stockInformation.logisticProviderEmailAddresses &&
    transactionDetails.transactionKit.stockInformation.logisticProviderEmailAddresses
      .split(',')
      .map((item, index) => ({
        id: index + '',
        value: item.trim(),
      }));

  let emailAdressList =
    transactionDetails &&
    transactionDetails.stockInformation &&
    transactionDetails.stockInformation.logisticProviderEmailAddresses &&
    transactionDetails.stockInformation.logisticProviderEmailAddresses.split(',').map((item, index) => ({
      id: index + '',
      value: item,
    }));
  const pickUpType =
    Object.keys(prevTran).length > 0
      ? [
          { id: '1', value: strings.pickUpFromWarehouse },
          { id: '2', value: strings.PickUpFromCustomer },
          { id: '3', value: strings.PickUpFromLastCustomer },
        ]
      : [
          { id: '1', value: strings.pickUpFromWarehouse },
          { id: '2', value: strings.PickUpFromCustomer },
        ];
  const emailSubject = strings.emailSubject(transactionDetails && transactionDetails.transactionNumber);
  const targetShippingDate = moment
    .utc(transactionDetails && transactionDetails.targetDeliveryFrom)
    .format('DD-MM-YYYY');
  const firstName =
    transactionDetails &&
    transactionDetails.stockInformation &&
    transactionDetails.stockInformation.warehouseActiveAddress
      ? transactionDetails &&
        transactionDetails.stockInformation &&
        transactionDetails.stockInformation.warehouseActiveAddress[0].firstName
      : transactionDetails &&
        transactionDetails.transactionKit &&
        transactionDetails.transactionKit.stockInformation.warehouseActiveAddress[0].firstName;
  const lastName =
    transactionDetails &&
    transactionDetails.stockInformation &&
    transactionDetails.stockInformation.warehouseActiveAddress
      ? transactionDetails &&
        transactionDetails.stockInformation &&
        transactionDetails.stockInformation.warehouseActiveAddress[0].lastName
      : transactionDetails &&
        transactionDetails.transactionKit &&
        transactionDetails.transactionKit.stockInformation.warehouseActiveAddress[0].lastName;
  const organization =
    transactionDetails &&
    transactionDetails.stockInformation &&
    transactionDetails.stockInformation.warehouseActiveAddress[0]
      ? transactionDetails &&
        transactionDetails.stockInformation &&
        transactionDetails.stockInformation.warehouseActiveAddress[0].organization
      : transactionDetails &&
        transactionDetails.transactionKit &&
        transactionDetails.transactionKit.stockInformation.warehouseActiveAddress[0].organization;
  const phone =
    transactionDetails &&
    transactionDetails.stockInformation &&
    transactionDetails.stockInformation.warehouseActiveAddress[0]
      ? transactionDetails &&
        transactionDetails.stockInformation &&
        transactionDetails.stockInformation.warehouseActiveAddress[0].phone
      : transactionDetails &&
        transactionDetails.transactionKit &&
        transactionDetails.transactionKit.stockInformation.warehouseActiveAddress[0].phone;
  const street =
    transactionDetails &&
    transactionDetails.stockInformation &&
    transactionDetails.stockInformation.warehouseActiveAddress[0]
      ? transactionDetails &&
        transactionDetails.stockInformation &&
        transactionDetails.stockInformation.warehouseActiveAddress[0].street
      : transactionDetails &&
        transactionDetails.transactionKit &&
        transactionDetails.transactionKit.stockInformation.warehouseActiveAddress[0].street;
  const postalCode =
    transactionDetails &&
    transactionDetails.stockInformation &&
    transactionDetails.stockInformation.warehouseActiveAddress[0]
      ? transactionDetails &&
        transactionDetails.stockInformation &&
        transactionDetails.stockInformation.warehouseActiveAddress[0].postalCode
      : transactionDetails &&
        transactionDetails.transactionKit &&
        transactionDetails.transactionKit.stockInformation.warehouseActiveAddress[0].postalCode;
  const city =
    transactionDetails && transactionDetails.stockInformation && transactionDetails.stockInformation
      ? transactionDetails &&
        transactionDetails.stockInformation &&
        transactionDetails.stockInformation.warehouseActiveAddress[0].city
      : transactionDetails &&
        transactionDetails.transactionKit &&
        transactionDetails.transactionKit.stockInformation.warehouseActiveAddress[0].city;
  const deliverAddressFristname = transactionDetails && transactionDetails.firstName;
  const deliverAddressLastName = transactionDetails && transactionDetails.lastName;
  const deliverAddressDepartment = transactionDetails && transactionDetails.department;
  const deliverAddressOrganistation = transactionDetails && transactionDetails.organization;
  const deliverAddressCity = transactionDetails && transactionDetails.city;
  const deliverAddressHouseNumber = transactionDetails && transactionDetails.houseNumber;
  const deliverAddressPostalCode = transactionDetails && transactionDetails.postalCode;
  const deliverAddressStreet = transactionDetails && transactionDetails.street;
  const deliverAddressCountry = transactionDetails && transactionDetails.country && transactionDetails.country.name;
  const deliverAdressPhone = transactionDetails && transactionDetails.phone;
  const deliverAdressState = transactionDetails && transactionDetails.state;

  const receiptAtTheCustomer = moment
    .utc(transactionDetails && transactionDetails.targetReservationFrom)
    .format('DD-MM-YYYY');
  const receiptAtStock = moment
    .utc(transactionDetails && transactionDetails.targetReturnDeliveryTo)
    .format('DD-MM-YYYY');
  const targetReturnDeliveryDate = moment
    .utc(transactionDetails && transactionDetails.targetReservationTo)
    .format('DD-MM-YYYY');
  const serialNumber =
    transactionDetails &&
    transactionDetails.transactionKit &&
    transactionDetails.transactionKit.kitInformation &&
    transactionDetails.transactionKit.kitInformation.serialNumber !== null
      ? transactionDetails &&
        transactionDetails.transactionKit &&
        transactionDetails.transactionKit.kitInformation &&
        transactionDetails.transactionKit.kitInformation.serialNumber
      : '-';
  const kitName =
    transactionDetails &&
    transactionDetails.transactionKit &&
    transactionDetails.transactionKit.kitInformation &&
    transactionDetails.transactionKit.kitInformation.kitName
      ? transactionDetails &&
        transactionDetails.transactionKit &&
        transactionDetails.transactionKit.kitInformation &&
        transactionDetails.transactionKit.kitInformation.kitName
      : '-';

  const packageDimensions =
    transactionDetails &&
    transactionDetails.transactionKit &&
    transactionDetails.transactionKit.kitInformation &&
    transactionDetails.transactionKit.kitInformation.packageDimensions !== ''
      ? transactionDetails &&
        transactionDetails.transactionKit &&
        transactionDetails.transactionKit.kitInformation &&
        transactionDetails.transactionKit.kitInformation.packageDimensions
      : '-';

  const linkToTransactionText = `${process.env.REACT_APP_UI_CALLBACK_URL}/#/transactions/${transactionDetails.transactionsId}`;
  const linkToTransaction =
    transactionDetails && transactionDetails
      ? `###${process.env.REACT_APP_UI_CALLBACK_URL}/#/transactions/${transactionDetails.transactionsId}##`
      : '';
  const department =
    transactionDetails &&
    transactionDetails.stockInformation &&
    transactionDetails.stockInformation.warehouseActiveAddress[0].department !== null
      ? transactionDetails &&
        transactionDetails.stockInformation &&
        transactionDetails.stockInformation.warehouseActiveAddress[0].department
      : transactionDetails &&
        transactionDetails.transactionKit &&
        transactionDetails.transactionKit.stockInformation.warehouseActiveAddress[0].department;
  const number =
    transactionDetails && transactionDetails.stockInformation && transactionDetails.stockInformation
      ? transactionDetails &&
        transactionDetails.stockInformation &&
        transactionDetails.stockInformation.warehouseActiveAddress[0].houseNumber
      : transactionDetails &&
        transactionDetails.transactionKit &&
        transactionDetails.transactionKit.stockInformation.warehouseActiveAddress[0].houseNumber;

  const state =
        transactionDetails && transactionDetails.stockInformation && transactionDetails.stockInformation
          ? transactionDetails &&
            transactionDetails.stockInformation &&
            transactionDetails.stockInformation.warehouseActiveAddress[0].stateName
          : transactionDetails &&
            transactionDetails.transactionKit &&
            transactionDetails.transactionKit.stockInformation.warehouseActiveAddress[0].stateName;

  let c2cFirstName;
  let c2cLastName;
  let c2cOrganization;
  let c2cDepartment;
  let c2cStreet;
  let c2cPostalCode;
  let c2cCity;
  let c2cNextFirstName;
  let c2cNextLastName;
  let c2cNextOrganization;
  let c2cNextDepartment;
  let c2cNextStreet;
  let c2cNextPostalCode;
  let c2cNextCity;
  let c2cCountry;
  let c2cPhone;
  let c2cNextCountry;
  let c2cNextPhone;
  let c2cNumber;
  let c2cNextNumber;
  let c2cState;
  let c2cNextState;
  const c2cPrev =
    transactionDetails &&
    transactionDetails.allPrevC2c &&
    transactionDetails.allPrevC2c.length > 0 &&
    transactionDetails.allPrevC2c.map(
      item => (
        (c2cFirstName = item.firstName),
        (c2cLastName = item.lastName),
        (c2cOrganization = item.organization),
        (c2cDepartment = item.department !== null ? item.department : ''),
        (c2cStreet = item.street),
        (c2cPostalCode = item.postalCode),
        (c2cCity = item.city),
        (c2cCountry = item.country && item.country.name),
        (c2cPhone = item.phone),
        (c2cNumber = item.number),
        (c2cState = item.state)
      ),
    );
  const c2cNext =
    transactionDetails &&
    transactionDetails.allNextC2c &&
    transactionDetails.allNextC2c.length > 0 &&
    transactionDetails.allNextC2c.map(
      item => (
        (c2cNextFirstName = item.firstName),
        (c2cNextLastName = item.lastName),
        (c2cNextOrganization = item.organization),
        (c2cNextDepartment = item.department !== null ? item.department : ''),
        (c2cNextStreet = item.street),
        (c2cNextPostalCode = item.postalCode),
        (c2cNextCity = item.city),
        (c2cNextCountry = item.country && item.country.name),
        (c2cNextPhone = item.phone),
        (c2cNextNumber = item.number !== null ? item.number : ''),
        (c2cNextState = item.state !== null ? item.state : '')
      ),
    );
  const pickAddressForWhereHouse = `${c2cFirstName ? c2cFirstName : firstName} ${
    c2cLastName ? c2cLastName : lastName
  }\n${c2cOrganization ? c2cOrganization : organization}\n${
    c2cDepartment ? c2cDepartment : department !== '' ? department : ''
  }\n${c2cStreet ? c2cStreet : street} ${c2cNumber ? c2cNumber : number !== null ? number : ''} \n${
    c2cPostalCode ? c2cPostalCode : postalCode
  } ${c2cCity ? c2cCity : city} ${c2cState ? c2cState : state}\n${c2cPhone ? c2cPhone : phone} `;

  const pickAddressForWhereHouseToUSCanada = `${c2cOrganization ? c2cOrganization : organization}\n${
    c2cDepartment ? c2cDepartment : department !== '' ? department : '' } ${c2cStreet ? c2cStreet : street} ${c2cNumber ? c2cNumber : number !== null ? number : ''}\n${
    c2cCity ? c2cCity : city}, ${c2cState ? c2cState : state} ${c2cPostalCode ? c2cPostalCode : postalCode}`;

  const deliveryAdress = `${deliverAddressFristname} ${deliverAddressLastName}\n${deliverAddressOrganistation}\n${
    deliverAddressDepartment !== null ? deliverAddressDepartment : ''
  }\n${deliverAddressStreet} ${deliverAddressHouseNumber}\n${deliverAddressPostalCode} ${deliverAddressCity} ${deliverAdressState}\n${deliverAddressCountry}\n${deliverAdressPhone}`;

  const deliveryAdressToUSCanada = `${deliverAddressOrganistation}\n${
    deliverAddressDepartment !== null ? deliverAddressDepartment : ''} ${deliverAddressStreet} ${deliverAddressHouseNumber}\n${
    deliverAddressCity}, ${deliverAdressState} ${deliverAddressPostalCode} `;

  const pickAddressForCustomer = `${c2cNextFirstName ? c2cNextFirstName : firstName} ${
    c2cNextLastName ? c2cNextLastName : lastName
  }\n${c2cNextOrganization ? c2cNextOrganization : organization}\n${
    c2cNextDepartment ? c2cNextDepartment : department !== '' ? department : ''
  }\n${c2cNextStreet ? c2cNextStreet : street} ${c2cNextNumber ? c2cNextNumber : number !== null ? number : ''}\n${
    c2cNextPostalCode ? c2cNextPostalCode : postalCode
  } ${c2cNextCity ? c2cNextCity : city} ${c2cNextState ? c2cNextState : state} \n${c2cNextPhone ? c2cNextPhone : phone}`;

  const pickAddressForCustomerToUSCanada = `${c2cNextOrganization ? c2cNextOrganization : organization}\n${
    c2cNextDepartment ? c2cNextDepartment : department !== '' ? department : ''} ${c2cNextStreet ? c2cNextStreet : street} ${c2cNextNumber ? c2cNextNumber : number !== null ? number : ''}\n${
    c2cNextCity ? c2cNextCity : city}, ${c2cNextState ? c2cNextState : state} ${ c2cNextPostalCode ? c2cNextPostalCode : postalCode}`;

  const pickUpComment = transactionDetails && transactionDetails.pickUpComment;
  const deliveryComment = transactionDetails && transactionDetails.deliveryComment;

  const emailBody = strings.pickFromWarehoueBody(
    firstName,
    lastName,
    organization,
    phone,
    isStockCountryUsOrCanada ? pickAddressForWhereHouseToUSCanada : pickAddressForWhereHouse,
    targetShippingDate,
    receiptAtTheCustomer,
    kitName,
    serialNumber,
    isStockCountryUsOrCanada ? null : packageDimensions,
    isStockCountryUsOrCanada ? deliveryAdressToUSCanada : deliveryAdress,
    linkToTransaction,
    showPicUpComment,
    pickUpComment,
    deliveryComment,
  );
  const customerSubject = strings.customerEmailSubject(transactionDetails && transactionDetails.transactionNumber);
  const customEmailBody = strings.pickCustomerBody(
    firstName,
    lastName,
    organization,
    phone,
    isStockCountryUsOrCanada ? pickAddressForCustomerToUSCanada : pickAddressForCustomer,
    isStockCountryUsOrCanada ? deliveryAdressToUSCanada : deliveryAdress ,
    targetShippingDate,
    receiptAtTheCustomer,
    kitName,
    serialNumber,
    isStockCountryUsOrCanada ? null : packageDimensions,
    targetReturnDeliveryDate,
    receiptAtStock,
    linkToTransaction,
    showPicUpComment,
    pickUpComment,
    deliveryComment,
  );

  const customerToCustomerSubject = strings.customerToCustomerEmailSubject(
    transactionDetails && transactionDetails.transactionNumber,
  );
  const customerToCustomerFirstName = prevTran && prevTran.firstName;
  const customerToCustomerLastName = prevTran && prevTran.lastName;
  const customerToCustomerOrganization = prevTran && prevTran.organization;
  const customerToCustomerPhone = prevTran && prevTran.phone;
  const customerToCustomerStreet = prevTran && prevTran.street;
  const customerToCustomerPostalCode = prevTran && prevTran.postalCode;
  const customerToCustomerCity = prevTran && prevTran.city;
  const customerToCustomerDepartment = prevTran && prevTran.department;
  const customerToCustomerNumber = prevTran && prevTran.houseNumber;
  const customerToCustomerState = prevTran && prevTran.state;

  const pickupAddressForCustomerToCustomer = `${c2cFirstName ? c2cFirstName : customerToCustomerFirstName} ${
    c2cLastName ? c2cLastName : customerToCustomerLastName
  }\n${c2cOrganization ? c2cOrganization : customerToCustomerOrganization}\n${
    c2cDepartment ? c2cDepartment : customerToCustomerDepartment !== '' ? customerToCustomerDepartment : ''
  }\n${c2cStreet ? c2cStreet : customerToCustomerStreet} ${
    c2cNumber ? c2cNumber : customerToCustomerNumber !== null ? customerToCustomerNumber : ''
  } \n${c2cPostalCode ? c2cPostalCode : customerToCustomerPostalCode} ${c2cCity ? c2cCity : customerToCustomerCity} ${c2cState ? c2cState : customerToCustomerState}\n${c2cPhone ? c2cPhone : customerToCustomerPhone}`;

  const pickupAddressForCustomerToCustomerTousCanada = `${c2cOrganization ? c2cOrganization : customerToCustomerOrganization}\n${
    c2cDepartment ? c2cDepartment : customerToCustomerDepartment !== '' ? customerToCustomerDepartment : ''} ${c2cStreet ? c2cStreet : customerToCustomerStreet}  ${c2cNumber ? c2cNumber : customerToCustomerNumber !== null ? customerToCustomerNumber : ''}\n${
    c2cCity ? c2cCity : customerToCustomerCity}, ${c2cState ? c2cState : customerToCustomerState} ${c2cPostalCode ? c2cPostalCode : customerToCustomerPostalCode}`;

  const customerToCustomerEmailBody =
    prevTran &&
    strings.pickCustomerToCustomerBody(
      firstName,
      lastName,
      organization,
      phone,
      isStockCountryUsOrCanada ? pickupAddressForCustomerToCustomerTousCanada : pickupAddressForCustomerToCustomer,
      isStockCountryUsOrCanada ? deliveryAdressToUSCanada : deliveryAdress ,
      targetShippingDate,
      targetReturnDeliveryDate,
      kitName,
      serialNumber,
      isStockCountryUsOrCanada ? null : packageDimensions,
      receiptAtTheCustomer,
      receiptAtStock,
      linkToTransaction,
      showPicUpComment,
      pickUpComment,
      deliveryComment,
    );

  const ccEmail = workFlowQuestions && workFlowQuestions.logisticsProviderCcEmailAddress
  && workFlowQuestions.logisticsProviderCcEmailAddress.length > 0 && 
  workFlowQuestions.logisticsProviderCcEmailAddress
  .map((item, index) => ({
    id: index + '',
    value: item.trim(),
  }));
  useEffect(() => {
    const newModel = [...model];
    model[2].options = pickUpType;
    model[0].options = emailsList ? emailsList : emailAdressList;
    model[1].options = ccEmail;
    if (ccEmail) {
      model[1].isDisplayed = false;
    }else{
      model[1].isDisplayed = true;
    }
    const transID = transactionDetails && transactionDetails.transactionsId;
    const getPrevTransaction = async () => {
      if (transactionDetails.type === 'kit loan') {
        const { data } = await props.getPreviousTransactionDetailsForPickupRequest(transID);
        setPrevTran(data);
      } else {
        setPrevTran({});
      }
    };
    getPrevTransaction();

    if (emailAdressList !== null && emailAdressList.length <= 1) {
      setFormData(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          emailAddressFullValue: emailAdressList,
          emailAddress: emailAdressList[0].value,
        },
      }));
    }
    if (emailsList !== null && emailsList.length <= 1) {
      setFormData(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          emailAddressFullValue: emailsList,
          emailAddress: emailsList && emailsList[0] && emailsList[0].value,
        },
      }));
    }

    if (form && form.formData && form.formData.picUpType && form.formData.picUpType === '1') {
      setFormData(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          pickUpDate: moment
            .utc(transactionDetails && transactionDetails && transactionDetails.targetDeliveryFrom)
            .format('YYYY-MM-DD'),
          subject: emailSubject ? emailSubject : '',
          emailBody: emailBody,
        },
      }));
      settempPickUpType(form.formData.picUpType);
    }
    if (form && form.formData && form.formData.picUpType && form.formData.picUpType === '2') {
      setFormData(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          pickUpDate: moment
            .utc(transactionDetails && transactionDetails && transactionDetails.targetReservationTo)
            .format('YYYY-MM-DD'),
          subject: customerSubject ? customerSubject : '',
          emailBody: customEmailBody,
        },
      }));
      settempPickUpType(form.formData.picUpType);
    }
    if (form && form.formData && form.formData.picUpType && form.formData.picUpType === '3') {
      prevTran &&
        setFormData(prevState => ({
          ...prevState,
          formData: {
            ...prevState.formData,
            pickUpDate: moment
              .utc(transactionDetails && transactionDetails && transactionDetails.targetDeliveryFrom)
              .format('YYYY-MM-DD'),
            subject: customerToCustomerSubject ? customerToCustomerSubject : '',
            emailBody: customerToCustomerEmailBody,
          },
        }));
      settempPickUpType(form.formData.picUpType);
    }
    setModel(newModel);
  }, [form.formData.picUpType, language, customerToCustomerFirstName]);
  const handleForm = ({ values, field }) => {
    if (field === 'pickUpDate' && tempPickUpType === '1') {
      const tempEMailBody = strings.pickFromWarehoueBody(
        firstName,
        lastName,
        organization,
        phone,
        isStockCountryUsOrCanada ? pickAddressForWhereHouseToUSCanada : pickAddressForWhereHouse,
        moment(values.pickUpDate).format('DD-MM-YYYY'),
        receiptAtTheCustomer,
        kitName,
        serialNumber,
        isStockCountryUsOrCanada ? null : packageDimensions,
        isStockCountryUsOrCanada ? deliveryAdressToUSCanada : deliveryAdress,
        linkToTransaction,
      );
      return setFormData(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          ...values,
          emailBody: tempEMailBody,
        },
      }));
    } else if (field === 'pickUpDate' && tempPickUpType === '2') {
      const tempcustomerEmailBody = strings.pickCustomerBody(
        firstName,
        lastName,
        organization,
        phone,
        isStockCountryUsOrCanada ? pickAddressForCustomerToUSCanada : pickAddressForCustomer,
        isStockCountryUsOrCanada ? deliveryAdressToUSCanada : deliveryAdress,
        targetShippingDate,
        receiptAtTheCustomer,
        kitName,
        serialNumber,
        isStockCountryUsOrCanada ? null : packageDimensions,
        moment(values.pickUpDate).format('DD-MM-YYYY'),
        receiptAtStock,
        linkToTransaction,
      );
      return setFormData(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          ...values,
          emailBody: tempcustomerEmailBody,
        },
      }));
    } else if (field === 'pickUpDate' && tempPickUpType === '3') {
      const tempcustomerEmailBody = strings.pickCustomerToCustomerBody(
        firstName,
        lastName,
        organization,
        phone,
        isStockCountryUsOrCanada ? pickupAddressForCustomerToCustomerTousCanada : pickupAddressForCustomerToCustomer,
        isStockCountryUsOrCanada ? deliveryAdressToUSCanada : deliveryAdress,
        moment(values.pickUpDate).format('DD-MM-YYYY'),
        receiptAtStock,
        kitName,
        serialNumber,
        isStockCountryUsOrCanada ? null : packageDimensions,
        receiptAtTheCustomer,
        targetShippingDate,
        linkToTransaction,
      );
      return setFormData(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          ...values,
          emailBody: tempcustomerEmailBody,
        },
      }));
    } else {
      return setFormData(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          ...values,
        },
      }));
    }
  };

  const onsave = async () => {
    const validForm = validateForm({
      form: form,
      model,
    });

    if (!validForm.isFormValid) {
      return setFormData(validForm);
    }
    const emailText = `${header}${form.formData.emailBody
      .replaceAll('**', '<b>')
      .replaceAll('*', '</b>')
      .replaceAll('\n', '<br>')
      .replaceAll('###', '<a href=')
      .replaceAll('##', `>${linkToTransactionText}</a>`)}${footer}`;
    setFormData(validForm);
    setIsLoading(true);
    const dataToSend = {
      toEmailAddress: form.formData.emailAddress,
      subject: form.formData.subject,
      emailText: JSON.parse(JSON.stringify(emailText)),
      senderEmailAddress: user && user.email,
      emailBody: form.formData.emailBody
        .replaceAll('**', '')
        .replaceAll('*', '')
        .replaceAll('###', '')
        .replaceAll('##', ''),
      toCcEmailAddress: form.formData.ccEmail ? form.formData.ccEmail : [],
      isZmcc: false
    };
    const transactionID = transactionDetails && transactionDetails.transactionsId;
    const { success } = await sendLogisticEmail(dataToSend, transactionID);
    setIsLoading(false);
    if (!success) {
      setError(strings.somethingWentWrongMessage);
    } else {
      onCloseClick();
    }
  };
  const onCloseClick = useCallback(() => {
    if (emailAdressList !==null && emailAdressList.length <=1) {
      setFormData({
        formData:{
          emailAddressFullValue:emailAdressList
        }
      })
    }else if (emailsList !==null && emailsList.length <=1) {
      setFormData({
        formData:{
          emailAddressFullValue:emailsList
        }
      })
    }else{
      setFormData({
        formData: {},
      });
    }

    closePopUp();
  }, []);

  const closePopUp = useCallback(() => {
    $(`#${id}`).modal('hide');
  }, []);
  return {
    model,
    handleForm,
    form,
    onsave,
    onCloseClick,
    isLoading,
    linkToTransactionText
  };
};
