import { connect } from 'react-redux';

import CourseLoanCart from './CourseLoanCart.component';

const mapStateToProps = ({ masterDataReducer, authReducer }) => ({
    countries: masterDataReducer.countries,
    user: authReducer.user,
});

export default connect(mapStateToProps)(CourseLoanCart);
