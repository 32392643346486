import Validators from 'library/utilities/Validators';

export const descriptionFormModel = [
  {
    label: 'locationName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'locationName',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 40,
    },
  },
  {
    label: 'crmAccountId',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'crmAccountId',
    validators: [],
    required: true,
    styleClass: 'col-sm-4',
    extraProps: {
      maxLength: 7,
    },
    isDisplayed: true,
  },
  {
    label: 'countryLand',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'country',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: '' }],
  },
  {
    label: 'businessUnit',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'businessUnit',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: '' }],
  },
  {
    label: 'locationShortName',
    value: '',
    type: 'inputgroup',
    placeholder: '',
    field: 'locationShortName',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 8,
    },
    inputGroupText: '',
    isAppend: false,
    disabled: false,
  },
  {
    label: 'accountIdSap',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'sapAccountId',
    validators: [],
    required: true,
    styleClass: 'col-sm-6',
    isDisplayed: true,
  },
  {
    label: 'locationCrmId',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'locationCrmId',
    validators: [],
    required: true,
    styleClass: 'col-sm-6',
    isDisplayed: true,
  },
  {
    label: 'standardSapPlant',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'standardSapPlant',
    validators: [],
    required: true,
    styleClass: 'col-sm-6',
    isDisplayed: true,
  },
  {
    label: 'standardSapStorageLocation',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'standardSapStorageLocation',
    validators: [],
    required: true,
    styleClass: 'col-sm-6',
    isDisplayed: true,
  },
];

export const addressFormModel = [
  {
    label: 'addressTitle',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'addressType',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-12',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'salutation',
    value: '',
    type: 'radio-button',
    placeholder: '',
    field: 'salutation',
    validators: [],
    required: false,
    styleClass: 'col-sm-12',
    options: [
      {
        id: 'mr',
        value: 'mr',
      },
      {
        id: 'ms',
        value: 'ms',
      },
    ],
    showClearIcon: true
  },
  {
    label: 'firstName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'firstName',
    // validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: false,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'lastName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'lastName',
    // validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: false,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'organization',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'organization',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-12',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'department',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'department',
    validators: [],
    required: false,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'street',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'street',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 256,
    },
  },
  {
    label: 'number',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'number',
    required: false,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 256,
    },
  },
  {
    label: 'postalCode',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'postalCode',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 20,
    },
  },
  {
    label: 'stateOrProvince',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'stateName',
    validators: [],
    required: false,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'city',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'city',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'phone',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'phone',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'email',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'email',
    validators: [
      { check: Validators.email, message: 'invalidEmailError' },
      {
        check: Validators.required,
        message: 'requiredErrorMessage',
      },
    ],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'setDefault',
    selected: false,
    type: 'checkbox',
    field: 'activeAddress',
    styleClass: 'col-sm-6 mt-2',
    newuiVersionStyle: true,
  },
  {  
    label: 'setShippingAddressForZmcc',
    selected: false,
    type: 'checkbox',
    field: 'activeShippingAddressForZmcc',
    styleClass: 'col-sm-6 mt-2',
    disabled: true,
    newuiVersionStyle: true,
  }
];

export const newStockCreateAddressModel = [
  {
    label: 'addressTitle',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'addressType',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-12',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'salutation',
    value: '',
    type: 'radio-button',
    placeholder: '',
    field: 'salutation',
    validators: [],
    required: false,
    styleClass: 'col-sm-12',
    options: [
      {
        id: 'mr',
        value: 'mr',
      },
      {
        id: 'ms',
        value: 'ms',
      },
    ],
    showClearIcon: true
  },
  {
    label: 'firstName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'firstName',
    // validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'lastName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'lastName',
    // validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'organization',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'organization',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-12',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'department',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'department',
    validators: [],
    required: false,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'street',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'street',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 256,
    },
  },
  {
    label: 'number',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'number',
    required: false,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 256,
    },
  },
  {
    label: 'postalCode',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'postalCode',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 20,
    },
  },
  {
    label: 'state',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'stateName',
    validators: [],
    required: false,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'city',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'city',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'phone',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'phone',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50,
    },
  },
  {
    label: 'email',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'email',
    validators: [
      { check: Validators.email, message: 'invalidEmailError' },
      {
        check: Validators.required,
        message: 'requiredErrorMessage',
      },
    ],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 50,
    },
  },
];

export const stateDropdown = {
  label: 'stateOrProvince',
  value: '',
  type: 'dropdown',
  placeholder: 'pleaseSelect',
  field: 'stateName',
  validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
  required: true,
  styleClass: 'col-sm-6',
  options: [],
  hasSection: false,
  multiSelect: false,
  filter: false,
  idKey: 'stateName',
  displayValue: [{ key: 'stateName', separator: '' }],
};

export const iqsAddress = [
  {
    label: 'locationSecurityEmail',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'locationReceptionEmail',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' },
      { check: Validators.email, message: 'invalidEmailError' },
    ],
    required: true,
    styleClass: 'col-sm-12',
  },
  {
    label: 'locationContactEmail',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'locationContactEmail',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' },
      { check: Validators.email, message: 'invalidEmailError' },
    ],
    required: true,
    styleClass: 'col-sm-12',
  },
]
