import { connect } from 'react-redux';

import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';

import { updateDateOfCartItem } from '../../../LoanList/LoanListActions';
import { updateForm, fetchRequestors } from '../../LLKiLoan.actions';
import CustomerData from './CustomerData.component';

const mapStateToProps = ({ authReducer, languageReducer, loanList, llKitLoanReducer, masterDataReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
  selectedKits: loanList.cart.filter(item => (item.isLabsLocation === true) && (item.isSelected === true)),
  borrowerForm: llKitLoanReducer.borrowerForm,
  typeOfDeliveryAddressForm: llKitLoanReducer.typeOfDeliveryAddressForm,
  deliveryAddressForm: llKitLoanReducer.deliveryAddressForm,
  userList: masterDataReducer.usersList,
  countries: masterDataReducer.countries,
  addresses: llKitLoanReducer.addresses,
});

export default connect(mapStateToProps, {
  updateDateOfCartItem,
  toggleActionMessage,
  updateForm,
  fetchRequestors,
})(CustomerData);
