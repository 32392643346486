import React from 'react';
import strings from 'resources/locales/Translate';
import Button from 'library/common/commonComponents/Button/Button';
import LoanListDatePicker from '../../../LoanList/components/LoanListDatePicker/LoanListDatePicker';
import moment from 'moment';
import Icon from 'library/common/commonComponents/Icon/Icon';

const SelectedKits = ({ selectedKits, goToCalendar, handleDateChange, expectedLoanFees, activeStep }) => (
  <>
    <h4 className='subtitle-loan mb-3'>{strings.selectedKits}</h4>
    {selectedKits.length ? (
      <div className='mb-3'>
        {selectedKits.map((item, index) => (
          <div className='d-flex g-2 align-items-center'>
            <>
              <div className='selected-kit-container' key={item.kit.kitInformationId}>
                <p>
                  {strings.positionText} {index + 1}
                </p>
                <h5 className='kit-title'>{item.kit.kitName}</h5>
                <p className='kit-info'>{`${strings.kitNumber}: ${item.kit.kitInformationId}`}</p>
                <p className='kit-info'>
                  {item.isLabsLocation === true ? strings.llPartner : strings.locationText}:{' '}
                  {`${item.kit.stockInformation !== null ? item.kit.stockInformation.locationShortName : ''}`}
                </p>
                <div className='d-flex align-items-center mb-4'>
                  <LoanListDatePicker
                    label={item.isLabsLocation === true  ? strings.startOfDemo : strings.beginningOfBorrowLabel}
                    selectedDate={item.start}
                    onChangeDate={date => handleDateChange(item, date, 'start')}
                  />
                  <LoanListDatePicker
                    label={item.isLabsLocation === true  ? strings.endOfDemo : strings.endOfBorrowLabel}
                    selectedDate={item.end}
                    onChangeDate={date => handleDateChange(item, date, 'end')}
                  />
                </div>
                {moment(item.end).isBefore(item.start) && (
                  <div className='error-message pt-1 pb-1'>{strings.startDateGreaterThanEndDate}</div>
                )}
              </div>
              {activeStep && item.expectedLoanFees && item.expectedLoanFees.loanFeeActivated && (
                <div className='kitLoan-message-block kit-loan-message-dispaly'>
                  <>
                    <div className='d-flex'>
                      <Icon name='info' width={18} height={18} fill='#0088D0' />
                      <p className='mb-2 ml-1' style={{fontWeight: '700'}}>{strings.costInformation}</p>
                    </div>

                    <table className='kit-loan-message-display-table'>
                      <tr>
                        <td> {`${strings.expectedRefurbishmentFees}:`}</td>
                        <td className='kit-loan-table-row-block'>
                          {`${item.expectedLoanFees && item.expectedLoanFees.kitExpectedFee} EUR`}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: '200px' }}> {`${strings.penaltyFeesPerDayWhenLoanIsReturnedLate}: `}</td>
                        <td className='kit-loan-table-row-block'>{`${
                          item.expectedLoanFees && item.expectedLoanFees.kitPenaltyFeePerDay
                        } EUR`}</td>
                      </tr>
                    </table>
                    <p>{strings.theFeesAreSettledInternallyWithTheBorrowingSsc}</p>
                  </>
                </div>
              )}
            </>
          </div>
        ))}
      </div>
    ) : (
      <div className='d-flex justify-content-center mb-3'>
        <strong>{strings.kitsNotSelected}</strong>
      </div>
    )}

    <Button
      data-test='go-to-calendar-button'
      bgFill={false}
      iconName='plus'
      value={strings.addItem}
      styleClass='w-auto mt-0'
      onClick={goToCalendar}
    />
  </>
);

export default SelectedKits;
