import store from 'main/store/configureStore';
import {changeLoader, toggleActionMessage} from "library/common/commonActions/AppActionsActions";
import {scrollToTop} from "library/utilities/scrollActions";

import {
  fetchDeliveryAddressesService,
  saveDeliveryAddressService,
  deleteUserAddressService
} from "./deliveryAddresses.service";
import {DELIVERY_ADDRESSES_FETCH_LIST, POPULATE_DELIVERY_ADDRESS_FORM} from "./deliveryAddressesConstants";

export const populateDropdownValue = () => dispatch => {
  const masterDataState = store.getState().masterDataReducer;
  const deliveryAddressForm = store.getState().deliveryAddresses.addressForm;

  deliveryAddressForm[11].options = masterDataState.countries;

  dispatch({
    type: POPULATE_DELIVERY_ADDRESS_FORM,
    payload: deliveryAddressForm
  });

};

export const fetchDeliveryAddress = () => dispatch => {
  const authState = store.getState().authReducer;
  const accountId = authState.user.accountId;
  const countries = store.getState().masterDataReducer.countries;

  dispatch(changeLoader(true));

  return fetchDeliveryAddressesService(accountId)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: DELIVERY_ADDRESSES_FETCH_LIST,
          payload: createData(res.data, countries)
        });
      }
      dispatch(changeLoader(false));
    })
    .catch(err => {
      dispatch({
        type: DELIVERY_ADDRESSES_FETCH_LIST,
        payload: []
      });
      dispatch(changeLoader(false));
    });
};

function createData(data, countries) {
  let array = [];

  if (data[0].countryId === 0) {
    const objectDefaultAddress = {
      ...data[0],
      countryName: '',
      country: data[0].countryId
    };
    array.push(objectDefaultAddress);
  }

  data.forEach(address => {
    countries.forEach(country => {
      if (country.id === address.countryId) {
        address['countryName'] = country.name;
        address['country'] = address.countryId;
        array.push(address);
      }
    });
  });

  return array;
}

export const saveDeliveryAddress = (url, value, method) => dispatch => {
  const valueToSend = {};

  for (let key in value) {
    if (!key.includes('Error')) {
      valueToSend[key] = value[key];
    }
  }

  dispatch(changeLoader(true));

  saveDeliveryAddressService(url, method, valueToSend)
    .then(res => {
      if (res.status === 200) {
        dispatch(fetchDeliveryAddress());
        dispatch(toggleActionMessage(true, 'success', 'addressSavedSuccessfully'));
      }
      dispatch(changeLoader(false));
    })
    .catch(err => {
      dispatch(changeLoader(false));
      dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    });
};

export const deleteDeliveryAddress = (id) => async dispatch => {
  dispatch(changeLoader(true));

  try {
    const res = await deleteUserAddressService(id);

    if (res.status === 200) {
      await dispatch(fetchDeliveryAddress());
      dispatch(changeLoader(false));
      scrollToTop(500);
      dispatch(toggleActionMessage(true, 'success', 'addressDeletedSuccessfully'));
    }
  } catch (e) {
    dispatch(changeLoader(false));
    dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
  }
}
