import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Footer from './Footer.component';

const mapStateToProps = ({ languageReducer }) => ({
  language: languageReducer
});
  
export default withRouter(connect(mapStateToProps)(Footer));
