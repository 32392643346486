import React from 'react';
import cn from 'classnames';

import { findMax } from 'library/utilities/findMax';
import strings from 'resources/locales/Translate';
import { getDateInFormat } from 'library/utilities/getDateInFormat';

import './horizontalBarChart.styles.scss';

const HorizontalBarChart = ({ dateArr, bookingsArr, performedArr, locale }) => {
  const bigValue = findMax([ ...bookingsArr, ...performedArr ]);

  return (
    <div className='horizontalBarChart-container'>
      <div className='horizontalBarChart-wrapper'>
        <div className='date'>
          <div class='header'>{strings.date}</div>
          <div class='content'>
            {dateArr.map(item => <div className='item'>{getDateInFormat('MMM YYYY', item.value, null, locale)}</div>)}
          </div>
        </div>
        <div className='booking'>
          <div class='header'>{strings.bookings}</div>
          <div class='content'>
            {bookingsArr.map(item => (
              <div className='item bookingItem' style={{ width: `${(item.value/bigValue)*100 || 0}%`}}>
                <div>{item.value}</div>
              </div>
            ))}
          </div>
        </div>
        <div className='performed'>
          <div class='header'>{strings.performed}<span className='planned'> / {strings.planned}</span></div>
          <div class='content'>
            {performedArr.map(item => (
              <div
                className={cn('item', { 'plannedItem': item.planned, 'performedItem': !item.planned })}
                style={{ width: `${(item.value/bigValue)*100 || 0}%`}}
              >
                <div>{item.value}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HorizontalBarChart;
