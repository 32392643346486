import React from 'react';

import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import CreateStockActionButtons from 'library/common/commonComponents/CreateStockActionButtons';

import SoldPopup from '../SoldPopup';
import { useInformation } from './Information.hook';

const Information = (props) => {
  const {
    handleForm,
    getKitForSaleInformationModel,
    getSingleItemInformationModel,
    currentKitStatusModel,
    saveKit,
    cancelCreateKit,
  } = useInformation(props);
  const { formValue, isSingleItemForSale, kitDetails, updateKitForSaleForm, sellOffCurrency } = props;
  const { kitForSaleForm, kitStatusForm, generalInformationKitForSaleForm } = formValue;
  const isDisabledKitStatusForm = kitDetails && kitDetails.kitCondition && (kitDetails.kitCondition.kitConditionId === 4)

  return (
    <div className='information-container mb-5'>
      <div className='container-fluid form-container'>
        <div className='row'>
          <div className='col-sm-6'>
            <h3 className='form-section-title'>{strings.information}</h3>

            <FormComponent
              model={isSingleItemForSale ? getSingleItemInformationModel() : getKitForSaleInformationModel()}
              formName='kitForSaleForm'
              formValue={kitForSaleForm.formData}
              onChange={handleForm}
            />
          </div>

          <div className='col-sm-6'>
            <h3 className='form-section-title'>{strings.kitStatus}</h3>
            <FormComponent
              model={currentKitStatusModel()}
              formName='kitStatusForSaleForm'
              formValue={kitStatusForm.formData}
              onChange={handleForm}
              disableForm={isDisabledKitStatusForm}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <CreateStockActionButtons onSave={saveKit} onCancel={cancelCreateKit} />
          </div>
        </div>
        <SoldPopup
          id='sold-popup'
          currency={sellOffCurrency}
          updateKit={saveKit}
          updateFormCreateKit={updateKitForSaleForm}
          generalInformationKitForSaleForm={generalInformationKitForSaleForm}
        />
      </div>
    </div>
  );
};

export default Information;
