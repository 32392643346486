import zeissInstance from "library/utilities/AxiosInstance";
import {URLS} from "library/common/commonConstants/ApiUrlConstants";

/**
 * This service is used to send token fetched from ZEISS ID to ZDLA Backend
 * @returns {Promise<any>}
 */
export async function sendAuthenticationToServerService(data) {
  return await zeissInstance.post(URLS.userProfile, data);
}

/**
 * This function is used to logout user from our app/server
 * @returns {Promise<any>}
 */
export async function logoutService() {
  return await zeissInstance.post(URLS.logout, {});
}
