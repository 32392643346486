import { useState, useCallback, useEffect } from 'react';
import $ from 'jquery';

import strings from 'resources/locales/Translate';
import { validateForm } from 'library/utilities/ValidateForm.util';
import { getDateInFormat } from 'library/utilities/getDateInFormat';
import { deductPointsModel } from './DeductPointsPopup.constants';

export const useDeductPointsPopup = props => {
  const { id, user, userList, state, saveDeductPoint } = props;
  const [deductPointsForm, setDeductPointsForm] = useState({
    formData: {
      date: new Date(),
      editor: `${user.firstName} ${user.lastName}`,
    }
  });
  const [model, setModal] = useState(deductPointsModel);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const newModel = [...model];

    if (userList && userList.length) {
      newModel[4].options = userList;
    }

    setModal(newModel);
  }, [user, userList]);

  const onSaveClick = useCallback(async () => {
    setError('');
    const validForm = validateForm({
      form: deductPointsForm,
      model,
    });

    if (!validForm.isFormValid) {
      return setDeductPointsForm(validForm);
    } 
  
    setIsLoading(true);

    const { date, pointDeduction, reason, salesRepresentative } = validForm.formData;
    const dataToSend = {
      stockId: state.filter.stock,
      date: getDateInFormat('YYYY-MM-DD', date),
      editorId: user.accountId,
      salesRepresentativeId: salesRepresentative,
      value: pointDeduction,
      reason,
    };

    const { success } = await saveDeductPoint(dataToSend, state);

    setIsLoading(false);

    if (!success) {
      setError(strings.somethingWentWrongMessage);
    } else {
      onCloseClick();
    }
  }, [deductPointsForm, saveDeductPoint]);

  const onCloseClick = useCallback(() => {
    setDeductPointsForm({
      formData: {
        date: new Date(),
        editor: `${user.firstName} ${user.lastName}`,
      }
    });
    setError('');
    closeDeductPointsPopup();
  }, []);

  const handleForm = useCallback(({ values, field }) => {
    if (field === 'pointDeduction') {
      const regex = /^(\d+(\.\d{0,2})?|\.?\d{1,2})?$/; // contains a positive integer or floating point number up to two characters after the decimal point. Example: 1, 1.1, 1.11
      const isValid = regex.test(values.pointDeduction);

      if (isValid) {
        return setDeductPointsForm(prevState => ({
          ...prevState,
          formData: {
            ...prevState.formData,
            ...values,
          },
        }));
      }
    } else {
      return setDeductPointsForm(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          ...values,
        },
      }));
    }
  }, []);

  const closeDeductPointsPopup = useCallback(() => {
    $(`#${id}`).modal('hide');
  }, []);

  return {
    deductPointsForm,
    model,
    isLoading,
    error,
    onSaveClick,
    onCloseClick,
    handleForm,
  };
}
