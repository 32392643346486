import React from 'react';

import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import strings from 'resources/locales/Translate';

const OpportunityItem = ({ opportunity, hasMaxOpportunities, handleChange, selectedOpportunities }) => {
  const {
    opportunityId,
    opportunityName,
    customerId,
    customerName,
    contactFirstName,
    contactLastName,
    street,
    houseNumber,
    zip,
    city,
    countryCode,
  } = opportunity;

  return (
    <div className='d-flex align-items-center mb-3' key={opportunityId}>
      <Checkbox
        selected={selectedOpportunities.includes(opportunityId)}
        disabled={!selectedOpportunities.includes(opportunityId) && hasMaxOpportunities}
        className='mb-4'
        field={opportunityId}
        onChange={handleChange}
      />
      <div className='ml-3'>
        <p><span className='text-bold'>{strings.opportunityIdCRM}</span>: {opportunityId}</p>
        <p><span className='text-bold'>{strings.opportunityName}</span>: {opportunityName}</p>
        <p><span className='text-bold'>{strings.customerID}</span>: {customerId}</p>
        <p><span className='text-bold'>{strings.customerName}</span>: {customerName}</p>
        <p><span className='text-bold'>{strings.contactPerson}</span>: {contactFirstName} {contactLastName}</p>
        <p><span className='text-bold'>{strings.contactAddress}</span>: {street} {houseNumber}, {zip} {city}, {countryCode}</p>
      </div>
    </div>
  );
}

export default OpportunityItem;
