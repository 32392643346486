import React, {PureComponent, Fragment} from 'react';

import ItemGallery from './ItemGallery';

class ImageGallery extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeImage: this.props.images[0],
    };
  }

  changeActiveImage = (activeImage) => {
    this.setState({activeImage});
  }

  render() {
    const {activeImage} = this.state;
    const {images} = this.props;

    return (
      <div className='d-flex'>
        <div className='images-container ml-3 pr-2'>
          {
            images.map(item => (
              <Fragment key={item.kitImagesId}>
                <ItemGallery
                  changeActiveImage={this.changeActiveImage}
                  item={item}
                  activeImage={activeImage}
                />
              </Fragment>
            ))
          }
        </div>
        <div className='d-flex ml-3'>
          <img
            src={activeImage && activeImage.preview}
            width='250px'
            height='250px'
            alt={activeImage && activeImage.kitName}
          />
        </div>
      </div>
    );
  }
}

export default ImageGallery;
