import React, { Component } from 'react';

import strings from 'resources/locales/Translate';
import { scrollToTop } from 'library/utilities/scrollActions';
import { getSelectedCountry } from 'library/utilities/getSelectedValues';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import Button from 'library/common/commonComponents/Button/Button';
import AddressInfo from 'library/common/commonComponents/AddressInfo/AddressInfo';
import IconButton from 'library/common/commonComponents/IconButton';

import SelectedKits from '../SelectedKits';
import CourseLoanCart from '../CourseLoanCart/CourseLoanCart.component';
import SingleLoanCart from '../SingleLoanCart';
import AsyncSelect from 'react-select/async';

class CustomerDataStep extends Component {
  state = {
    isEditTypeOfDeliveryAddress: false,
    isEditUserAddress: false,
  };

  // componentDidMount() {
  //   scrollToTop(500);
  // }

  goToNextStep = () => {
    if (this.props.isMedUsStock || this.props.courseLoanMedStock) {
      const { typeOfDeliveryAddressForm, toggleActionMessage } = this.props;
      if (typeOfDeliveryAddressForm && !typeOfDeliveryAddressForm.isFormValid) {
        toggleActionMessage(true, 'error', strings.selectDeliveryAddress, true);
        scrollToTop(500);
        return;
      }
      const formsToValidate = [
        { model: this.props.borrowerModel, form: this.props.borrowerForm, formName: 'borrowerForm' },

        {
          model: this.props.deliveryAddressModel,
          form: this.props.deliveryAddressForm,
          formName: 'deliveryAddressForm',
        },
      ];
      this.props.goToThirdStep(formsToValidate);
    } else {
      const formsToValidate = [
        { model: this.props.borrowerModel, form: this.props.borrowerForm, formName: 'borrowerForm' },
        {
          model: this.props.typeOfDeliveryAddressModel,
          form: this.props.typeOfDeliveryAddressForm,
          formName: 'typeOfDeliveryAddressForm',
        },
        {
          model: this.props.deliveryAddressModel,
          form: this.props.deliveryAddressForm,
          formName: 'deliveryAddressForm',
        },
      ];
      this.props.goToThirdStep(formsToValidate);
    }
  };

  goToPrevStepKitLoan = () => {
    this.props.changeActiveStep(1);
  };

  goToCalendar = () => {
    this.props.history.push('/calendar');
  };

  changeIsEditTypeFlag = type => {
    const defaultEditState = { isEditTypeOfDeliveryAddress: false, isEditUserAddress: false };
    this.setState(prevState => ({ ...defaultEditState, [`isEdit${type}`]: !prevState[`isEdit${type}`] }));
    this.props.updateForm({ isEditTypeOfDeliveryAddress: true });
  };

  renderCart = () => {
    const {
      loanType,
      handleForm,
      componentsForm,
      componentsModel,
      onSelectCourseItem,
      selectedKits,
      updateDateOfCartItem,
      overtakenDataForSingleLoan,
      onSelectSingleItem,
      handleWorkflowData,
      checkMedusStock, 
      checkMedTaiwanWarehouse, 
    } = this.props;
    if (loanType === 'kitLoan') {
      return (
        <SelectedKits
          selectedKits={selectedKits}
          goToCalendar={this.goToCalendar}
          handleDateChange={updateDateOfCartItem}
        />
      );
    } else if (loanType === 'courseLoan') {
      return (
        <CourseLoanCart
          handleDateChange={updateDateOfCartItem}
          onSelect={onSelectCourseItem}
          selectedKits={selectedKits}
          checkMedusStock={checkMedusStock}
          checkMedTaiwanWarehouse={checkMedTaiwanWarehouse}
        />
      );
    } else if (loanType === 'singleLoan') {
      return (
        <SingleLoanCart
          overtakenDataForSingleLoan={overtakenDataForSingleLoan}
          selectedKits={selectedKits}
          onSelect={onSelectSingleItem}
          handleWorkflowData={handleWorkflowData}
          model={componentsModel}
          form={componentsForm}
          formName='componentsForm'
          onChange={handleForm}
        />
      );
    }
  };

  render() {
    const {
      borrowerForm,
      borrowerModel,
      handleForm,
      typeOfDeliveryAddressModel,
      typeOfDeliveryAddressForm,
      deliveryAddressModel,
      deliveryAddressForm,
      deliveryCommentModel,
      deliveryCommentForm,
      countries,
      disableSave,
      loadOptions,
      handleSelectDropdownChanges,
      isMedUsStock,
      courseLoanMedStock,
      addressesForDropdown,
      medUsAddressDropDownList,
      pickUpComment,
      pickUpCommentModel,
      pickUpCommentForm
    } = this.props;
    const filterArray = addressesForDropdown && addressesForDropdown.length > 0 && addressesForDropdown.map(item => ({title:item.addressType ? item.addressType: item.name, value:item}));
    const { isEditTypeOfDeliveryAddress } = this.state;
    const deliveryAddressCountry =
      deliveryAddressForm && deliveryAddressForm.formData && deliveryAddressForm.formData.countryId
        ? getSelectedCountry(deliveryAddressForm.formData.countryId, countries)
        : null;
    const yourAddressCountry =
      borrowerForm &&
      borrowerForm.formData &&
      borrowerForm.formData.userAddress &&
      borrowerForm.formData.userAddress.countryId
        ? getSelectedCountry(borrowerForm.formData.userAddress.countryId, countries)
        : '';
    const setDefaultValueOfDropdown =
      typeOfDeliveryAddressForm && typeOfDeliveryAddressForm.formData && typeOfDeliveryAddressForm.formData.name
        ? { title: typeOfDeliveryAddressForm.formData.name }
        : typeOfDeliveryAddressForm.formData.organization
        ? {
            title: `${typeOfDeliveryAddressForm.formData.organization}, ${typeOfDeliveryAddressForm.formData.postalCode}`,
          }
        : { title: '' };
    return (
      <div>
        <h2 className='title-loan mb-4'>{strings.customerData}</h2>
        <div className='row'>
          <div className='col-12 col-sm-6'>
            <div className='mb-3'>
              <h4 className='subtitle-loan mb-3'>{strings.borrower}</h4>
              <FormComponent
                formName='borrowerForm'
                model={borrowerModel}
                formValue={borrowerForm && borrowerForm.formData}
                onChange={handleForm}
              />
              {borrowerForm && borrowerForm.formData && borrowerForm.formData.userAddress && (
                <AddressInfo
                  formData={borrowerForm.formData.userAddress}
                  changeIsEditTypeFlag={this.changeIsEditTypeFlag}
                  addressCountry={yourAddressCountry}
                  flag='UserAddress'
                  isEditable={false}
                />
              )}
            </div>

            <div className='mb-3'>
              <h4 className='subtitle-loan mb-3'>{strings.deliveryAddress}</h4>
              {!(isMedUsStock || courseLoanMedStock) && (
                <FormComponent
                  formName='typeOfDeliveryAddressForm'
                  model={typeOfDeliveryAddressModel}
                  formValue={typeOfDeliveryAddressForm && typeOfDeliveryAddressForm.formData}
                  onChange={handleForm}
                />
              )}
              {(isEditTypeOfDeliveryAddress && !(isMedUsStock || courseLoanMedStock)) ||
              (!(isMedUsStock || courseLoanMedStock) &&
                typeOfDeliveryAddressForm &&
                typeOfDeliveryAddressForm.formData &&
                typeOfDeliveryAddressForm.formData.deliveryAddress === 'new') ? (
                <FormComponent
                  formName='deliveryAddressForm'
                  model={deliveryAddressModel}
                  formValue={deliveryAddressForm && deliveryAddressForm.formData}
                  onChange={handleForm}
                />
              ) : (
                <div>
                  {!(isMedUsStock || courseLoanMedStock) &&
                    deliveryAddressForm &&
                    deliveryAddressForm.formData &&
                    deliveryAddressForm.formData.editButtonVisible && (
                      <AddressInfo
                        formData={deliveryAddressForm.formData}
                        changeIsEditTypeFlag={this.changeIsEditTypeFlag}
                        deliveryAddressCountry={deliveryAddressCountry}
                        flag='TypeOfDeliveryAddress'
                      />
                    )}
                </div>
              )}
              
              {(isMedUsStock || courseLoanMedStock) && (
                <>
                <p style={{ fontSize: '12px' }}>{strings.deliveryAddress}</p>
                <AsyncSelect
                  defaultOptions={filterArray}
                  loadOptions={loadOptions}
                  getOptionLabel={e => e.title}
                  onChange={handleSelectDropdownChanges}
                  components={{
                    IndicatorSeparator: () => null,
                    clearValue: () => null
                  }}
                  styles={{
                    menu: provided => ({ ...provided, zIndex: 9999 })
                  }}
                  placeholder={strings.pleaseSelect}
                  defaultValue={setDefaultValueOfDropdown ? setDefaultValueOfDropdown : { title: '' }}
                /></>
              )}
              {(isEditTypeOfDeliveryAddress && (isMedUsStock || courseLoanMedStock)) ||
              ((isMedUsStock || courseLoanMedStock) &&
                deliveryAddressForm &&
                deliveryAddressForm.formData &&
                deliveryAddressForm.formData.id === 'new') ? (
                <FormComponent
                  formName='deliveryAddressForm'
                  model={deliveryAddressModel}
                  formValue={deliveryAddressForm && deliveryAddressForm.formData}
                  onChange={handleForm}
                />
              ) : (
                <div className='mt-3'>
                  {(isMedUsStock || courseLoanMedStock) &&
                    deliveryAddressForm &&
                    deliveryAddressForm.formData &&
                    deliveryAddressForm.formData.editButtonVisible && (
                      <AddressInfo
                        formData={deliveryAddressForm.formData}
                        changeIsEditTypeFlag={this.changeIsEditTypeFlag}
                        deliveryAddressCountry={deliveryAddressCountry}
                        flag='TypeOfDeliveryAddress'
                      />
                    )}
                </div>
              )}
              <FormComponent
                formName='deliveryCommentForm'
                model={deliveryCommentModel}
                formValue={deliveryCommentForm && deliveryCommentForm.formData}
                onChange={handleForm}
              />
              {pickUpComment && <FormComponent
                formName='pickUpCommentForm'
                model={pickUpCommentModel}
                formValue={pickUpCommentForm && pickUpCommentForm.formData}
                onChange={handleForm}
              />}
            </div>
            
          </div>

          <div className='col-12 col-sm-6 mt-sm-3'>{this.renderCart()}</div>

          <div className='col-12 col-md-6'>
            <div className='d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between'>
              <div className='col-auto p-0 mt-3 mt-md-0 pt-3 pb-3 pt-md-0 pb-md-0'>
                <IconButton
                  data-test='go-to-prev-step-button'
                  iconName='arrow'
                  value={strings.backToAssignment}
                  styles='arrow-left'
                  onClick={() => this.goToPrevStepKitLoan()}
                />
              </div>
              <div className='col-auto p-0'>
                <Button
                  data-test='go-to-next-step-button'
                  bgFill={true}
                  iconName='arrow'
                  value={strings.continueBooking}
                  styleClass='mt-0'
                  disabled={disableSave}
                  onClick={this.goToNextStep}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerDataStep;



