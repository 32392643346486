import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import store from 'main/store/configureStore';
import { logNetworkError } from 'library/utilities/logError';

import { saveDeliveryDocumentFooter, getDeliveryDocumentFooter } from '../../CreateStock.services';

export const createDeliveryDocumentFooter = (data, stockId) => dispatch => {
  dispatch(changeLoader(true));
  return saveDeliveryDocumentFooter(data, stockId)
    .then(res => {
      if (res.status === 201) {
        dispatch(toggleActionMessage(true, 'success', 'deliveryDocumentFooterSavedSuccessfully'));
      }
      dispatch(changeLoader(false));
    })
    .catch(err => {
      logNetworkError(err);
      dispatch(changeLoader(false));
      dispatch(toggleActionMessage(true, 'error', 'errorOccuredText'));
    });
};

export const fetchDeliveryDocumentFooter = () => dispatch => {
  const stockReducer = store.getState().createStockReducer;
  dispatch(changeLoader(true));
  const query =
    'companyName,groupName,name1,name2,name3,name4,placeOfJurisdiction,postalCodeAndCity,streetAndHouseNumber,' +
    'taxNumber,vatIdentificationNumber,webAddress,weeeNumber,showCustomerInfoPage,legalParagraph,signature,showDeliveryDocument,legalParagraphDe,showStorageLocation,returnDeliveryAddressText,additionalInformation';
  return getDeliveryDocumentFooter(stockReducer.stockId, query)
    .then(({ status, data }) => {
      dispatch(changeLoader(false));
      if (status === 200) {
        return { success: true, data };
      }
      return { success: false };
    })
    .catch(err => {
      logNetworkError(err);
      dispatch(changeLoader(false));
      dispatch(toggleActionMessage(true, 'error', 'errorOccuredText'));
    });
};
