import React from 'react';

import strings from 'resources/locales/Translate';
import Table from 'library/common/commonComponents/Table/Table';
import Pagination from 'library/common/commonComponents/Pagination/Pagination';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import Button from 'library/common/commonComponents/Button/Button';

import Filters from './Components/Filters';
import { interfaceMonitoringBreadcrumbData } from './InterfaceMonitoring.constants';
import { useInterfaceMonitoring } from './InterfaceMonitoring.hook';
import './interfaceMonitoring.styles.scss';

const InterfaceMonitoring = ({ fetchInterfaceMonitoring, interfaceMonitoring }) => {
  const {
    data,
    toggleFilterVisibility,
    handleDropdownChange,
    handleSearchChange,
    isShowFilter,
    tableCols,
    handleSort,
    handlePageChange,
    hasInterfaceMonitoring,
  } = useInterfaceMonitoring({
    fetchInterfaceMonitoring,
    interfaceMonitoring,
  });
  return (
    <div className='interfaceMonitoring-container'>
      <div className='container-fluid mb-3'>
        <Breadcrumbs data={interfaceMonitoringBreadcrumbData} />
        <div className='row no-gutters align-items-center'>
          <h1 className='col-sm kit-title'>{strings.interfaceMonitoring}</h1>
          <div className='col-sm-auto mt-3 mt-sm-0'>
            <Button
              dataTest='permissions-filter-button'
              bgFill={false}
              iconName='filter'
              onClick={toggleFilterVisibility}
              value={isShowFilter ? strings.hideFilter : strings.showFilter}
              styleClass='filter-button'
            />
          </div>
        </div>
      </div>
      <Filters
        isShowFilter={isShowFilter}
        handleDropdownChange={handleDropdownChange}
        handleSearchChange={handleSearchChange}
        data={data.filters}
      />
      <div className='container-fluid'>
        {hasInterfaceMonitoring ? (
          <>
            <Table
              cols={tableCols}
              rows={interfaceMonitoring.content}
              handleSort={handleSort}
              sortFields={data.sort}
            />
            <div className='d-flex justify-content-end mb-3'>
              <Pagination
                currentPage={data.page - 1}
                totalPages={interfaceMonitoring.totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </>
        ) : (
          <div className='d-flex justify-content-center my-5'>
            <strong>{strings.noDataFoundText}</strong>
          </div>
        )}
      </div>
    </div>
  );
}

export default InterfaceMonitoring;
