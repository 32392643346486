import React, { useState, useEffect } from 'react';
import * as $ from 'jquery';

export const useRooms = ({ roomsList, addNewRoom, fetchRoomsList, stockId, editRooms }) => {
  const [showAddFiled, setShowAddFiled] = useState(false);
  const [roomForm, setRoomForm] = useState({ value: '', error: '' });
  const [editMode, setEditMode] = useState(false);
  const [editingFieldName, setEditingFieldName] = useState('');
  const [roomList, setRoomList] = useState(roomsList);
  const [editForm, setEditForm] = useState({ value: '', error: '' });

  useEffect(() => {
    if (roomsList) {
      setRoomList(roomsList);
    }
  }, [roomsList]);

  const onClickOnAddRoom = () => {
    setShowAddFiled(true);
  };

  const handleForm = e => {
    setRoomForm({ value: e, error: '' });
  };

  const handleEditForm = e => {
    setEditForm({ value: e, error: '' });
  };

  const clickOnEdit = (roomName, index) => {
    setEditingFieldName(roomName, 'roomName');
    setEditMode(true);
    setEditForm({ value: roomName, error: '' });
  };

  const onCancelClick = roomName => {
    setEditMode(false);
    setEditForm({ value: roomName, error: '' });
  };

  const onSave = async () => {
    if (roomForm.value === '') {
      return setRoomForm(prevState => ({
        ...prevState,
        error: 'requiredErrorMessage',
      }));
    }
    const checkForUnique =
      roomsList && roomsList.length > 0 && roomsList.find(item => item.roomName === roomForm.value);
    if (checkForUnique) {
      return setRoomForm(prevState => ({
        ...prevState,
        error: 'uniqueRoomNameErrorMessaage',
      }));
    }
    const dataToSend = {
      roomName: roomForm.value,
      warehouseId: stockId,
      warehouseRoomId: 'null',
    };
    const { success } = await addNewRoom(dataToSend, stockId);
    if (success) {
      setShowAddFiled(false);
      setRoomForm({
        value: '',
        error: '',
      });
    }
  };

  const saveEditedData = async warehouseRoomId => {
    if (editForm.value === '') {
      return setEditForm(prevState => ({
        ...prevState,
        error: 'requiredErrorMessage',
      }));
    }
    const checkForUnique =
      roomsList && roomsList.length > 0 && roomsList.find(item => item.roomName === editForm.value);
    if (checkForUnique) {
      return setEditForm(prevState => ({
        ...prevState,
        error: 'uniqueRoomNameErrorMessaage',
      }));
    }
    const dataToSend = {
      roomName: editForm.value,
      warehouseId: stockId,
      warehouseRoomId: warehouseRoomId,
    };
    await editRooms(dataToSend, warehouseRoomId, stockId);
  };

  const onDeleteClick = roomId => {
    if ($(`delete-room-modal${roomId}`) && $(`delete-room-modal${roomId}`).modal) {
      $(`#delete-room-modal${roomId}`).modal('show');
    }
  };

  return {
    onClickOnAddRoom,
    showAddFiled,
    roomForm,
    handleForm,
    onSave,
    editMode,
    clickOnEdit,
    editingFieldName,
    roomList,
    saveEditedData,
    onCancelClick,
    editForm,
    onDeleteClick,
    handleEditForm,
  };
};
