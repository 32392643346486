import zeissInstance from "library/utilities/AxiosInstance";
import {URLS} from "library/common/commonConstants/ApiUrlConstants";
import { kitDetailsRequestJson } from 'library/api/endpoint-request-json';
/**
 * This service will fetch master data from server
 * @returns {Promise<any>}
 */
export async function fetchMasterDataService(url) {
  return await zeissInstance.get(url);
}

export async function getDataByCriteriaService(url, data) {
  return await zeissInstance.post(url, data);
}

export async function deleteKitService(id) {
  return await zeissInstance.delete(URLS.deleteKit + id);
}

export async function fetchMasterDataServicePost(url, data, options) {
  return await zeissInstance.post(url, data, options);
}

export async function fetchMasterDataServicePut(url, data) {
  return await zeissInstance.put(url, data);
}

export async function printDocumentService(url) {
  const config = {
      responseType: 'blob'
  };
  return await zeissInstance.get(url, config);
}

export function fetchProblemListService() {
  return zeissInstance.get(URLS.getServiceTicketProblemList);
}

export function deleteMasterDataService(url, data) {
  return zeissInstance.delete(url, { data });
}

export function fetchServiceTicketsService(page, size, kitId) {
  const config = {
    params: { page, size },
  };
  return zeissInstance.get(URLS.serviceTicket(kitId), config);
}
export async function deleteKitDusdComment(url, data) {
  const appendStatusToUrl    = (data.status) ? `status=${data.status}`: '';
  return await zeissInstance.delete(`${url}?kitInfId=${data.kitInfId}&id=${data.id}&${appendStatusToUrl}`);
}

export function postKitDetails(url) {
  let kitDataFetch = {
    graphql : `${
      `${(kitDetailsRequestJson)}`
    }`
  }
  return zeissInstance.post(url,kitDataFetch);
}
