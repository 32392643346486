import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import './c2cStyle.scss';
import { dataTable } from './dataTable';
import ExpandableTable from 'library/common/commonComponents/Table/AppExpandableTable';

import Button from 'library/common/commonComponents/Button/Button';
import Checkbox from 'library/common/commonComponents/Checkbox/Checkbox';
import Icon from 'library/common/commonComponents/Icon/Icon';
import { getDateInFormat } from 'library/utilities/getDateInFormat';
import { scrollToTop } from 'library/utilities/scrollActions';
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import strings from 'resources/locales/Translate';

import { getC2CSuggestions } from '../../TransactionDetailsActions';

const calculateTransactions = (transactionDetails) => {
  let currentC2CLoans = [];
  if (
    transactionDetails &&
    ((transactionDetails.allPrevC2c && transactionDetails.allPrevC2c.length) ||
      (transactionDetails.allNextC2c && transactionDetails.allNextC2c.length))
  ) {
    if (transactionDetails.allPrevC2c && transactionDetails.allPrevC2c.length) {
      currentC2CLoans = [...transactionDetails.allPrevC2c];
    }
    currentC2CLoans = [
      ...currentC2CLoans,
      {
        name: transactionDetails.name,
        transactionsId: transactionDetails.transactionsId,
        transactionNumber: transactionDetails.transactionNumber,
        type: transactionDetails.type,
        targetDeliveryFrom: transactionDetails.targetDeliveryFrom,
        targetReservationFrom: transactionDetails.targetReservationFrom,
        targetReservationTo: transactionDetails.targetReservationTo,
        targetReturnDeliveryFrom: transactionDetails.targetReturnDeliveryFrom,
        targetReconditioningFrom: transactionDetails.targetReconditioningFrom,
        current: true,
      },
    ];
    if (transactionDetails.allNextC2c && transactionDetails.allNextC2c.length) {
      currentC2CLoans = [...currentC2CLoans, ...transactionDetails.allNextC2c];
    }
  }
  let currentTransactionsId = null;
  if (
    currentC2CLoans.length &&
    currentC2CLoans[currentC2CLoans.length - 1] &&
    currentC2CLoans[currentC2CLoans.length - 1].transactionsId
  ) {
    currentTransactionsId = currentC2CLoans[currentC2CLoans.length - 1].transactionsId;
  }
  if (!currentTransactionsId && transactionDetails && transactionDetails.transactionsId) {
    currentTransactionsId = transactionDetails.transactionsId;
  }
  let currentC2cId = null;
  currentC2cId = transactionDetails.c2cId;
  return { currentC2CLoans, currentTransactionsId, currentC2cId };
}

export class C2CLoanProcesses extends Component {
  constructor(props) {
    super(props);
    const { currentC2CLoans, currentTransactionsId, currentC2cId } = calculateTransactions(props.transactionDetails);
    this.state = {
      checkbox: null,
      currentC2CLoans,
      currentTransactionsId,
      loading: false,
      possibleC2CLoans: null,
      activeTab: 'current',
      currentC2cId
    };
  }

  componentDidMount() {
    if (this.props.transactionDetails && this.props.transactionDetails.transactionsId) {
      this.props.getC2CSuggestions(this.props.transactionDetails.transactionsId);
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.state.checkbox && this.props.possibleC2CLoans && this.props.possibleC2CLoans.before && this.props.possibleC2CLoans.after) {
      this.setCheckboxes(this.props.possibleC2CLoans);
    }
    if (prevProps.transactionDetails && prevProps.transactionDetails.transactionsId && prevProps.transactionDetails.transactionsId !== this.props.transactionDetails.transactionsId) {
      this.updateC2cSuggestions();
    }
  }

  updateC2cSuggestions = async () => {
    if (this.props.transactionDetails && this.props.transactionDetails.transactionsId) {
      await this.props.getC2CSuggestions(this.props.transactionDetails.transactionsId);
      const { currentC2CLoans, currentTransactionsId } = calculateTransactions(this.props.transactionDetails);
      this.setState({ currentC2CLoans, currentTransactionsId }, () => {
        this.setCheckboxes(this.props.possibleC2CLoans);
      });
    }
  }

  setCheckboxes = res => {
    const checkbox = {};
    let possibleC2CLoans = [];
    if (res.before && res.before.length) {
      res.before.forEach((item, i) => {
        possibleC2CLoans = [...possibleC2CLoans, { ...item, before: true }];
      })
    }
    if (res.after && res.after) {
      res.after.forEach((item, i) => {
        possibleC2CLoans = [...possibleC2CLoans, { ...item, after: true }];
      })
    }
    possibleC2CLoans.forEach((item,i) => {
      checkbox[i] = {
        after: item.after,
        before: item.before,
        current: item.current,
        transactionsId: item.transactionsId,
        value: false,
        c2cId: item.c2cId
      };
    })
    this.setState({ checkbox, possibleC2CLoans });
  };

  onCreateC2CLoanPress = () => {
    const { checkbox, currentC2CLoans, possibleC2CLoans } = this.state;
    const oldC2CLoans = [];
    const newC2CLoans = [];
    const addC2CLoans = [];
    currentC2CLoans.forEach(item => {
      oldC2CLoans.push(item);
    });
    if (!oldC2CLoans.length) {
      oldC2CLoans.push({
        name: this.props.transactionDetails.name,
        transactionsId: this.props.transactionDetails.transactionsId,
        transactionNumber: this.props.transactionDetails.transactionNumber,
        type: this.props.transactionDetails.type,
        targetDeliveryFrom: this.props.transactionDetails.targetDeliveryFrom,
        targetReservationFrom: this.props.transactionDetails.targetReservationFrom,
        targetReservationTo: this.props.transactionDetails.targetReservationTo,
        targetReturnDeliveryFrom: this.props.transactionDetails.targetReturnDeliveryFrom,
        targetReconditioningFrom: this.props.transactionDetails.targetReconditioningFrom,
        current: true,
      });
    }
    Object.keys(checkbox).forEach((key, i) => {
      if (checkbox[key].value) {
        newC2CLoans.push(possibleC2CLoans[i]);
      } else {
        addC2CLoans.push(possibleC2CLoans[i]);
      }
    });
    if (!newC2CLoans.length) {
      this.props.toggleActionMessage(true, 'error', 'pleaseSelectC2c');
      scrollToTop(500);
    } else {
      const {displayActionMessage} = this.props.appActions;
      if (displayActionMessage) {
        this.props.toggleActionMessage(false);
      }
      this.props.history.push({
        pathname: `/${this.props.transactionsType}/${this.props.transactionDetails.transactionsId}/create-c2c-loan`,
        state: { oldC2CLoans, newC2CLoans, addC2CLoans, currentTransactionsId: this.state.currentTransactionsId },
      });
    }  
  };

  onCheckBoxClick = field => () => {
    this.setState(state => {
      const value = !state.checkbox[field].value;
      let newCheckbox = {
        ...state.checkbox,
        [field]: {
          ...state.checkbox[field],
          value,
        }
      };
      const type = newCheckbox[field].after ? 'after' : 'before';
      let pass = false;
      let check = false;
      if (type === 'after') {
        if (value) {
          check = false;
        } else {
          check = true;
        }
      } else {
        if (value) {
          check = true;
        } else {
          check = false;
        }
      }
      Object.keys(state.checkbox).forEach((_, i) => {
        if (pass === check && newCheckbox[i][type] && newCheckbox[i][type].value !== value) {
          newCheckbox[i] = {
            ...newCheckbox[i],
            value,
          };
        }
        if (i === field) {
          pass = true;
        }
        if (newCheckbox[i]['c2cId'] && newCheckbox[i]['c2cId'] === newCheckbox[field]['c2cId']){
          newCheckbox[i] = {
            ...newCheckbox[i],
            value,
          };
        }
      })
      return { checkbox: newCheckbox };
    });
  };

  setCurrentTab = (tab) => () => {
    this.props.setCurrentTab(tab);
  };

  setActiveTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  renderLoan = type => (loan, index) => {
    if (loan.current) {
      return this.renderLoanCurrent(loan);
    }
    return (
      <div key={loan.transactionsId} className='c2c-box mt-3' data-test={type}>
        <span className='font-bold'>#{loan.transactionNumber}</span>
        <span> {loan.name}</span>
        <div className='d-flex flex-row align-items-center mt-2 mb-2'>
          <Icon name='calendar' width={24} height={24} fill='#212529' className='mr-1' />
          <span className='mt-1'>
            {loan.targetReservationFrom &&
              loan.targetReservationTo &&
              getDateInFormat(
                'DD.MM.YYYY',
                moment(loan.targetReservationFrom).format('YYYY-MM-DD'),
                moment(loan.targetReservationTo).format('YYYY-MM-DD'),
              )}
          </span>
        </div>
        <div className='d-flex flex-md-row flex-column align-items-center mt-3' id='show-transaction-details-container'>
          {type === 'possible-loan' && (
            <div className='mr-2'>
              <Checkbox
                dataTest={`${type}-checkbox`}
                label={strings.markToC2CLoan}
                selected={this.state.checkbox && this.state.checkbox[index] && this.state.checkbox[index].value}
                onClick={this.onCheckBoxClick(index)}
              />
            </div>
          )}
          <NavLink to={`/transactions/${loan.transactionsId}`} onClick={this.setCurrentTab(1)} data-test='transaction-details-test'>
            <div className={`c2c-btn-bordered mb-1${type === 'possible-loan' ? ' ml-md-2' : ''}`}>
              {strings.showTransactionDetails}
              <Icon
                className='app-btn-icon app-btn-icon-right'
                name='visibility'
                width={24}
                height={24}
                fill='#0088d0'
              />
            </div>
          </NavLink>
        </div>
      </div>
    );
  };

  renderLoanCurrent = loan => {
    return (
      <div key={loan.transactionsId} className='c2c-box c2c-box-gray mt-3'>
        <span className='font-bold'>#{loan.transactionNumber}</span>
        <span> {loan.name}</span>
        <div className='d-flex flex-row align-items-center mt-2 mb-2'>
          <Icon name='calendar' width={24} height={24} fill='#212529' className='mr-1' />
          <span className='mt-1'>
            {loan.targetReservationFrom &&
              loan.targetReservationTo &&
              getDateInFormat(
                'DD.MM.YYYY',
                moment(loan.targetReservationFrom).format('YYYY-MM-DD'),
                moment(loan.targetReservationTo).format('YYYY-MM-DD'),
              )}
          </span>
        </div>
      </div>
    );
  };

  renderCurrentC2CLoans() {
    const { currentC2CLoans } = this.state;
    return (
      <>
        {currentC2CLoans.map(loan => {
          if (loan.current) {
            return this.renderLoanCurrent(loan);
          }
          return this.renderLoan('current-loan')(loan);
        })}
      </>
    );
  }

  renderPossibleC2CLoans(possibleC2CLoans) {
    return (
      <>
        {possibleC2CLoans && possibleC2CLoans.map(this.renderLoan('possible-loan'))}
        <div className='d-flex flex-row justify-content-end'>
          <Button
            dataTest='create-c2c-loan-button'
            styleClass='btn-lighter align-self-end mt-3'
            bgFill
            loading={this.state.loading}
            iconName='check'
            onClick={this.onCreateC2CLoanPress}
            value={strings.createC2CLoan}
          />
        </div>
      </>
    );
  }

  render() {
    const { currentC2CLoans, possibleC2CLoans, activeTab, currentC2cId } = this.state;
    return (
      <>
        {this.props.transactionDetails && this.props.transactionDetails.c2cLoan && (
          <div className={'blue-bg-message'}>
            <div className='container-fluid'>{strings.c2cLoanProcessText(this.props.transactionDetails.c2cId)}</div>
          </div>
        )}      
        <div className='container-fluid my-3 m-2'>
        <div className='c2c-tab-headers'>
          <div className='left-buttons'>
            <Button
                dataTest='create-c2c-loan-button'
                styleClass={`c2c-tab-btn ${activeTab === 'current' ? 'active' : ''}`}
                bgFill={false}
                loading={this.state.loading}
                onClick={() => this.setActiveTab('current')}
                value={strings.currentC2CLoanProcesses}
              />
            <Button
                dataTest='create-c2c-loan-button'
                styleClass={`c2c-tab-btn ${activeTab === 'possible' ? 'active' : ''}`}
                bgFill={false}
                loading={this.state.loading}
                onClick={() => this.setActiveTab('possible')}
                value={strings.possibleC2CLoanProcesses}
            />
          </div>
          <div className='right-button'>
          {
            !!possibleC2CLoans && !!possibleC2CLoans.length && activeTab === 'possible' &&
            <Button
                dataTest='create-c2c-loan-button'
                styleClass='btn-whiter'
                bgFill={true}
                loading={this.state.loading}
                onClick={this.onCreateC2CLoanPress}
                value={strings.createC2CLoan}
              />
          }
          </div>
        </div>  
          <div className='row no-gutters align-items-start mt-3 d-flex  align-items-center justify-content-center'>
          {
            !!currentC2CLoans && !!currentC2CLoans.length && activeTab === 'current' ?
            <ExpandableTable
              cols={dataTable(getDateInFormat, 'current-loan', this.state.checkbox, this.onCheckBoxClick, this.setCurrentTab)}
              rows={currentC2CLoans}
              dontShowExpandArrow ={true}
            /> 
            : 
            !!currentC2CLoans && !currentC2CLoans.length && activeTab === 'current' && <div className='mt-3'>{strings.noDataFoundText}.</div>

          }

          {
            !!possibleC2CLoans && !!possibleC2CLoans.length && activeTab === 'possible' ?
            <ExpandableTable
              cols={dataTable(getDateInFormat, 'possible-loan', this.state.checkbox, this.onCheckBoxClick, this.setCurrentTab)}
              rows={possibleC2CLoans}
              dontShowExpandArrow ={true}
            />
            :  
            !!possibleC2CLoans && !possibleC2CLoans.length && activeTab === 'possible' && <div className='mt-3'>{strings.noDataFoundText}.</div>
          }
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ appActionsReducer, languageReducer, transactionDetailsReducer }) => ({
  appActions: appActionsReducer,
  language: languageReducer,
  transactionDetails: transactionDetailsReducer.transactionDetails,
  possibleC2CLoans: transactionDetailsReducer.possibleC2CLoans,
});

export default connect(
  mapStateToProps,
  { getC2CSuggestions, toggleActionMessage },
)(C2CLoanProcesses);
