import { batchActions } from 'redux-batched-actions';

import { logNetworkError } from 'library/utilities/logError';
import {
  deleteMasterDataService,
  fetchMasterDataServicePost,
  fetchMasterDataServicePut,
  fetchMasterDataService,
} from 'library/api/master-data.service';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { scrollToTop } from 'library/utilities/scrollActions';

import { GET_DUSD_TASKS_LIST ,GET_DUSD_POPUP_SYSTEM_CLASSES} from './DusdTask.constants';

export const handlingDataToSend = data => {
  const {
    filter: { systemClass, systemMainComponent, searchTerm , status},
  } = data;
  const filter = [];
  const sort = [];
  const {
    sort: {
      systemClassSort,
      systemMainComponentSort,
      createdOnSort,
    },
  } = data;
  const sendData = { ...data };

  if (systemClass) {
    if (filter.length) filter.push('and');
    filter.push(`systemClass.id:eq:${systemClass}`);
  }
  if (systemMainComponent && systemMainComponent.length) {
    let systemMainComponentFilter = [];
    if (filter.length) filter.push('and');

    systemMainComponent.forEach((item, index) => {
      if (index !== 0) systemMainComponentFilter.push('or');
      systemMainComponentFilter.push(`systemMainComponent.id:eq:${item}`);
    });

    filter.push(systemMainComponentFilter);
  }
  if (status) {
    let tempStatus = status
    if (status && status === "ASSIGNED") {
      tempStatus = "NEW";
    }
    if (filter.length) filter.push('and');
    filter.push(`status.id:eq:${tempStatus}`);
  }

  if (searchTerm) {
    if (filter.length) filter.push('and');
    let searchArray = [];
    searchArray.push(`id:eq:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`description:cic:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`systemClass.id:eq:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`systemMainComponent.id:eq:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`status:eq:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`systemClass.name:cic:${searchTerm}`);
    searchArray.push(`or`);
    searchArray.push(`systemMainComponent.systemMainComponentName:cic:${searchTerm}`);

    filter.push(searchArray);
  }

  if (systemClassSort) {
    sort.push('systemClass.systemClassName');
    sort.push(`${systemClassSort}`);
  }
  if (systemMainComponentSort) {
    sort.push('systemMainComponent.systemMainComponentName');
    sort.push(`${systemMainComponentSort}`);
  }
  if (createdOnSort) {
    sort.push('createdOn');
    sort.push(`${createdOnSort}`);
  }

  sendData.filter = filter;
  sendData.sort = sort;
  return sendData;
};

export const fetchTaskListData = data => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const dataToSend = handlingDataToSend(data);
    const response = await fetchMasterDataServicePost(URLS.demoUnutTaskList, dataToSend);
    dispatch(
      batchActions([
        dispatch({
          type: GET_DUSD_TASKS_LIST,
          payload: response.data,
        }),
        dispatch(changeLoader(false)),
      ]),
    );
  } catch (err) {
    dispatch(
      batchActions([
        dispatch(changeLoader(false)),
        dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage')),
      ]),
    );
    scrollToTop(500);
    logNetworkError(err);
  }
};

export const createOrEditDudsTask = (dataToSend, filterData, hasEditMode) => async dispatch => {
  try {
    const request = hasEditMode ? fetchMasterDataServicePut : fetchMasterDataServicePost;
    const textMessage = hasEditMode ? 'taskWasUpdated' : 'taskWasAdded';

    await request(URLS.demoUnitTask, dataToSend);

    dispatch(
      batchActions([
        dispatch(fetchTaskListData(filterData)),
        dispatch(changeLoader(false)),
        dispatch(toggleActionMessage(true, 'success', textMessage)),
      ]),
    );

    return { success: true };
  } catch (err) {
    dispatch(
      batchActions([
        dispatch(changeLoader(false)),
        dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage')),
      ]),
    );
    scrollToTop(500)
    logNetworkError(err);

    return { success: false };
  }
};

export const removeDudsTask = (taskId, data) => async dispatch => {
  try {
    await deleteMasterDataService(`${URLS.demoUnitTask}/${taskId}`);

    dispatch(
      batchActions([
        dispatch(fetchTaskListData(data)),
        dispatch(toggleActionMessage(true, 'success', 'taskWasDeleted')),
      ]),
    );

    return { success: true };
  } catch (err) {
    dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    scrollToTop(500);
    logNetworkError(err);

    return { success: false };
  }
};
export const fetchSystemClassDataForPopup = () => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const response = await fetchMasterDataService(`${URLS.systemClassWithStock}/v2`);
    dispatch(
      batchActions([
        dispatch({
          type: GET_DUSD_POPUP_SYSTEM_CLASSES,
          payload: response.data,
        }),
        dispatch(changeLoader(false)),
      ]),
    )
  } catch (err) {
    dispatch(
      batchActions([
        dispatch(changeLoader(false)),
        dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage')),
      ]),
    );
    scrollToTop(500);
    logNetworkError(err);
  }
};
