import { connect } from 'react-redux';

import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';

import {
  addFile,
  createFolder,
  fetchdDocumentsAndFolders,
  removeFile,
  uploadDocuments,
  deleteFolder,
  editFolder,
  resetDocumentsAndFolders,
} from './Documents.actions';
import Documents from './Documents.component';

const mapStateToProps = ({ authReducer, languageReducer, appActionsReducer, documentsReducers }) => ({
  appActions: appActionsReducer,
  documentsReducers: documentsReducers,
  language: languageReducer,
  user: authReducer.user,
});
  
export default connect(mapStateToProps, {
  addFile,
  removeFile,
  uploadDocuments,
  fetchdDocumentsAndFolders,
  toggleActionMessage,
  createFolder,
  deleteFolder,
  editFolder,
  resetDocumentsAndFolders,
})(Documents);
