import { useEffect, useState, useCallback } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import { sortUsers } from 'library/utilities/sort';
import { validateForm } from 'library/utilities/ValidateForm.util';

import { filtersModel, userPermissionModel, roles } from './Permissions.constants';

export const usePermissions = ({
  getInitialPermissionsData,
  updateDemoUnitPermission,
  permissionsList,
  usersList,
  isServiceManager,
  user,
}) => {
  const isAdmin = user && user.admin;
  const [isShowFilter, setIsShowFilter] = useState(true);
  const [isSavingPermissionsLoading, setIsSavingPermissionsLoading] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [newPermission, setNewPermission] = useState({});
  const [availableUsers, setAvailableUsers] = useState([]);
  const [accountIdsToDelete, setAccountIdsToDelete] = useState([]);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    getInitialPermissionsData();
  }, [getInitialPermissionsData]);

  useEffect(() => {
    setPermissions(permissionsList);
  }, [permissionsList]);

  useEffect(() => {
    if (permissions && permissions.length) {
      const users = usersList.filter(user => !permissions.find(permission => permission.accountId === user.accountId));
      
      setAvailableUsers(users);
    } else {
      setAvailableUsers(usersList);
    }
  }, [usersList, permissions]);

  const handleFilterChange = useCallback(({ values }) => {
    setFilters(prevState => ({ ...prevState, ...values }));
  }, []);

  const toggleFilterVisibility = useCallback(() => {
    setIsShowFilter(prevState => !prevState);
  }, []);

  const getFiltersModel = useCallback(() => {
    const newModel = [ ...filtersModel ];
    newModel[1].options = roles;

    return newModel;
  }, []);

  const getPermissionsModel = useCallback((isManager) => {
    const newModel = cloneDeep(userPermissionModel);
    newModel[0].options = permissions;
    newModel[0].disabled = true;
    newModel[1].options = (isManager || isAdmin) ? roles : [ roles[0] ];

    return newModel;
  }, [permissions, isAdmin]);

  const getNewPermissionModel = useCallback(() => {
    const newModel = cloneDeep(userPermissionModel);
    newModel[0].options = sortUsers(availableUsers);
    newModel[0].disabled = false;
    newModel[1].options = isServiceManager ? [ roles[0] ] : roles;

    return newModel;
  }, [availableUsers, isServiceManager]);

  const changeUserRole = useCallback(accountId => ({ values }) => {
    const newPermissions = permissions.map(item => {
      if (item.accountId === accountId) {
        return { ...item, role: values.role };
      }
      return item;
    });

    setPermissions(newPermissions);
  }, [permissions]);

  const removeUserRole = useCallback((accountId, role) => () => {
    const accountIds = [ ...accountIdsToDelete ];
    const isExistsPermission = permissionsList.find(item => item.accountId === accountId);
    const newPermissions = permissions.filter(item => item.accountId !== accountId);

    setPermissions(newPermissions);

    if (isExistsPermission) {
      accountIds.push({ accountId, role  });
      setAccountIdsToDelete(accountIds);
    }
  }, [permissions, permissionsList, accountIdsToDelete]);

  const changeNewUserRole = useCallback(({ values }) => {
    setNewPermission(prevState => ({ ...prevState, ...values }));
  }, []);

  const addNewUserRole = useCallback(() => {
    const validated = validateForm({ form: { formData: newPermission }, model: userPermissionModel });

    if (!validated.isFormValid) {
      return setNewPermission(validated.formData);
    }
 
    const newPermissions = [ ...permissions ];
    newPermissions.push({
      ...newPermission.accountIdFullValue,
      role: newPermission.role,
    });
    const isDeletedAccountId = accountIdsToDelete.find(item => 
      (item.accountId === newPermission.accountId) && (item.role === newPermission.role)
    );

    if (isDeletedAccountId) {
      const newAccountIdsToDelete = accountIdsToDelete.filter(item => item.accountId !== newPermission.accountId);
      setAccountIdsToDelete(newAccountIdsToDelete);
    }

    setPermissions(newPermissions);
    setNewPermission({});
  }, [newPermission, permissions, accountIdsToDelete]);

  const filterPermissions = useCallback(() => {
    const hasFilters = filters.username || filters.role;
    if (permissions.length && hasFilters) {
      let filteredData = permissions;

      if (filters.username) {
        const username = filters.username.toLowerCase();
        filteredData = filteredData.filter(
          item => item.firstName.toLowerCase().includes(username) || item.lastName.toLowerCase().includes(username),
        );
      }

      if (filters.role) {
        filteredData = filteredData.filter(item => item.role === filters.role);
      }

      return filteredData;
    }

    return permissions;
  }, [permissions, filters]);

  const savePermissions = useCallback(async () => {
    const hasNotUpdatedPermissions = isEqual(permissionsList, permissions);

    if (hasNotUpdatedPermissions) return;

    setIsSavingPermissionsLoading(true);
    await updateDemoUnitPermission(permissions, accountIdsToDelete);
    setIsSavingPermissionsLoading(false);
  }, [permissionsList, permissions, accountIdsToDelete, updateDemoUnitPermission]);

  const modelOfFilters = getFiltersModel();
  const modelOfPermissions = getPermissionsModel();
  const modelOfPermissionsForServiceManager = getPermissionsModel(isServiceManager);
  const modelOfNewPemission = getNewPermissionModel();
  const filteredPermissions = filterPermissions();
 
  return {
    isShowFilter,
    isSavingPermissionsLoading,
    handleFilterChange,
    toggleFilterVisibility,
    changeUserRole,
    removeUserRole,
    changeNewUserRole,
    addNewUserRole,
    savePermissions,
    getFiltersModel,
    getPermissionsModel,
    getNewPermissionModel,
    modelOfFilters,
    modelOfPermissions,
    modelOfPermissionsForServiceManager,
    modelOfNewPemission,
    filteredPermissions,
    filters,
    newPermission,
    permissions,
    accountIdsToDelete,
  };
}
