import React , {useCallback, useState} from 'react';
import toArray from 'lodash/toArray';
import { itemsPerPage } from '../../../../Administration/LLManagement/LLManagement.constants';
import strings from 'resources/locales/Translate';
import moment from 'moment';
import ExpandableTable from 'library/common/commonComponents/Table/AppExpandableTable';
import Button from 'library/common/commonComponents/Button/Button';
import { useKitMessagesTab } from './KitMessagesTab.hook';
import MessageDataExpandable from 'modules/Transactions/TransactionDetails/Tabs/Messages/messageDataExpandableTable';
import Modal from 'library/common/commonComponents/Modal';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import ItemsPerPage from 'library/common/commonComponents/ItemsPerPage/ItemsPerPage';
import Pagination from 'library/common/commonComponents/Pagination/Pagination';

export const KitMessagesTab = props => {
  const { createKitMessage, kitDetails, kitMessagesList, fetchKitMessagesList } = props;
  const kitId = kitDetails?.kitInformationId;


  const modalId = 'kit-message-modal';

  const {
    messagesUpdatedList,
    onSaveClick,
    loading,
    onCloseClick,
    dynamicModel,
    errorMessage,
    createMessageFormData,
    handleForm,
    onClickingCreateMessage,
    handleItemsPerPage,
    handlePageChange,
    state
  } = useKitMessagesTab({ fetchKitMessagesList, kitMessagesList, kitId, modalId, createKitMessage });

  const dataTableCols = () => {
    return [
      {
        title: strings.date,
        name: 'date',
        isSort: false,
        render: item => {
          return (
            <span>
              {(item.createdAt &&
                moment(item.createdAt).isValid() &&
                moment(item.createdAt).format('DD.MM.YYYY HH:mm')) ||
                ''}
            </span>
          );
        },
      },
      {
        title: strings.name,
        name: 'name',
        isSort: false,
        render: item => {
          return (
            <span>
              {' '}
              {item.createdBy ? (
                <div>
                  {item.createdBy.firstName} {item.createdBy.lastName}
                </div>
              ) : (
                <div>{strings.updatedBySystem}</div>
              )}
            </span>
          );
        },
      },
      {
        title: strings.contactNumber,
        name: 'contactNumber',
        isSort: false,
        render: item => {
          return (
            <span>{item?.createdBy?.userAddresses?.length > 0 ? item?.createdBy?.userAddresses[0].phone : ''}</span>
          );
        },
      },
      {
        title: strings.email,
        name: 'email',
        isSort: false,
        render: item => {
          return <span>{(item.createdBy && item.createdBy.email) || ''}</span>;
        },
      },
    ];
  };


  return (
    <div className='container-fluid my-3'>
      {messagesUpdatedList?.content?.length > 0 && (
        <ExpandableTable cols={dataTableCols()} rows={messagesUpdatedList.content}>
          <MessageDataExpandable />
        </ExpandableTable>
      )}
      <Button
        bgFill={false}
        iconName='plus'
        onClick={() => onClickingCreateMessage()}
        value={strings.createMessage}
        styleClass='w-auto adjust-iqsSsc-add-room-btn'
        newVersionUi={true}
      />

      <Modal
        id={modalId}
        className='modal-lg'
        title={strings.createMessage}
        confirmAction={onSaveClick}
        confirmDataTest='create-message-save'
        confirmTitle={strings.save}
        loading={loading}
        onCloseClick={onCloseClick}
        titleOfCancel={strings.cancel}
      >
        <FormComponent
          model={dynamicModel}
          formName='createmMessageForm'
          formValue={createMessageFormData.formData}
          onChange={handleForm}
          className='d-flex'
          clear
          rowStyle='flex-column w-100 ml-0'
        />
        {!!errorMessage && <div className='error-message'>{errorMessage}</div>}
      </Modal>
      {
        messagesUpdatedList?.content?.length 
        ?
        <div className='col d-flex justify-content-center align-items-center'>
          <ItemsPerPage
            itemsPerPage={toArray(itemsPerPage)}
            handleItemsPerPage={handleItemsPerPage}
            value={itemsPerPage[state.size]}
          />
          <Pagination
            currentPage={state.page}
            totalPages={messagesUpdatedList.totalPages}
            onPageChange={handlePageChange}
          />      
        </div>
        :
        <div className='d-flex justify-content-center'><strong>{strings.noDataFoundText}</strong></div>
      }

    </div>
  );
};

export default KitMessagesTab;
