import { connect } from 'react-redux';

import DelayedTransactionsFilter from './DelayedTransactionsFilter.component';

const mapStateToProps = ({ languageReducer, statisticsReducer }) => ({
  language: languageReducer,
  countries: statisticsReducer.countries,
  businessUnitsForkits: statisticsReducer.businessUnitsForkits
});

export default connect(mapStateToProps)(DelayedTransactionsFilter);
