export const formatKitComponentID = (componentID) => {
  if (componentID){
    const trimmedID = componentID.replace(/^0+/, '');
    return trimmedID;
  } else{
    return componentID
  }
};

export const formatKitProductID = (productID) => {
  if (productID && productID.length >= 13) {
    const last13Digits = productID.slice(-13);
    return last13Digits.replace(/(\d{6})(\d{4})(\d{3})/, '$1-$2-$3');
  } else {
    return productID;
  }
};
