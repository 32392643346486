import React from 'react';

import Modal from 'library/common/commonComponents/Modal';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import Icon from 'library/common/commonComponents/Icon/Icon';
import strings from 'resources/locales/Translate';

import { useSoldPopup } from './SoldPopup.hook';

const SoldPopup = ({
  id,
  currency,
  generalInformationKitForSaleForm,
  updateKit,
  updateFormCreateKit,
}) => {
  const {
    model,
    priceForm,
    closePopup,
    handleConfirmation,
    handleForm,
    isLocalSalePrice,
  } = useSoldPopup({ id, currency, updateKit, updateFormCreateKit, generalInformationKitForSaleForm });

  return (
    <Modal
      id={id}
      confirmAction={handleConfirmation}
      onCloseClick={closePopup}
      confirmTitle={strings.save}
      disableBackgroundClose
      loading={false}
    >
      <div className='kitForSale-popup'>
        <h2 className='mb-3'>{strings.pleaseVerifyPrice}</h2>
        <FormComponent
          model={model}
          formName='priceForm'
          formValue={priceForm.formData}
          onChange={handleForm}
        />
        {isLocalSalePrice && 
          <div className='d-flex align-items-center'>
            <Icon name='info' width={24} height={24} className='mr-2' />
            <div className='ext-muted text-small'>{strings.pleaseConvertGrossListPrice}</div>
          </div>
        }
      </div>
    </Modal>
  );
}

export default SoldPopup;
