import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import strings from 'resources/locales/Translate';
import Icon from 'library/common/commonComponents/Icon/Icon';

import './quickstart.styles.scss';
import { images } from 'library/common/commonConstants/ImageConstants';

function QuickStartBox(props) {
  if (!props.externalLink) {
    if (props.disabled) {
      return (
        <div className={`quickstart-item row p-4 ${props.class}`}>
          <div className='col-lg-10'>
            <p className='item-name'>{props.name}</p>
            <p className='sub-heading pt-1'>{props.description}</p>
          </div>
          <div className='col-lg-2 mt-2'>
            <img src={props.icon} className='icon-size-set'></img>
          </div>
        </div>
      );
    }
    return (
      <NavLink to={props.link}>
        <div className={`quickstart-item row ${props.class}`}>
          <div className='col-lg-10'>
            <p className='item-name'>{props.name}</p>
            <p className='sub-heading pt-1'>{props.description}</p>
          </div>
          <div className='col-lg-2 mt-2'>
            <img src={props.icon} className='icon-size-set'></img>
          </div>
        </div>
      </NavLink>
    );
  } else {
    return (
      <a href={props.link} target='_blank' rel='noopener noreferrer'>
        <div className={`quickstart-item row ${props.class}`}>
          <div className='col-lg-10'>
            <p className='item-name'>{props.name}</p>
            <p className='sub-heading pt-1'>{props.description}</p>
          </div>
          <div className='col-lg-2 mt-2'>
            <img src={props.icon} className='icon-size-set'></img>
          </div>
        </div>
      </a>
    );
  }
}

export const QuickStart = ({ isFilterAllowed, user }) => {
  let isRms = false;
  let isNonRms = false;
  let isDustUser = false;
  let isLabsAndLocationUser = false;
  let isRmsZmccUser = false;
  const isZmccEnabled = process.env.REACT_APP_IS_ZMCC_ENABLED === 'true';
  const isNonRmsUser = allRoles => {
    const isNormalBusinessUnit = ['COP', 'MED', 'IQS', 'MED-OPT', 'MED-SUR', 'SMT', 'VIS'];
    const value = isNormalBusinessUnit.map(item => {
      return allRoles && allRoles.includes(item);
    });
    return value.includes(true);
  };
  const checkUser = user => {
    let allRoles = user && user.stockRoles && user.stockRoles.map(item => item.businessUnitName);
    if (allRoles && allRoles.includes('RMS')) {
      isRms = true;
      return;
    } else if (isNonRmsUser(allRoles)) {
      isNonRms = true;
      return;
    } else if ((user && user.labLocationPartner) || (allRoles && allRoles.includes('RMS labs@location Partner'))) {
      isLabsAndLocationUser = true;
      return;
    } else if (
      user &&
      user.globalRoles &&
      user.globalRoles.length > 0 &&
      (user.globalRoles.includes('DUST_USER') || user.globalRoles.includes('DUST_MANAGER'))
    ) {
      isDustUser = true;
      return;
    } else if (allRoles && (allRoles.includes('RMS (ZMCC)') || allRoles.includes('RMS'))) {
      isRmsZmccUser = true;
      return;
    }
  };
  checkUser(user);
  const showRmsButton = [
    {
      name: strings.homeKitloanCalendar,
      link: '/calendar',
      externalLink: false,
      icon: images.cradCart,
      class: 'box-black-gradient',
      disabled: !isFilterAllowed,
      description: strings.homeKitloanCalendarDescription,
    },
    {
      name: strings.homeLabsAndLocationCalendar,
      link: '/labs-location-calendar',
      externalLink: false,
      icon: images.cradCart,
      class: 'box-black-gradient',
      disabled: false,
      description: strings.homeLabsAndLocationCalendarDescription,
    },
    {
      name: strings.zmccCalendar,
      link: '/zmcc-calendar',
      externalLink: false,
      icon: images.cradCart,
      class: 'box-black-gradient mb-lg-0',
      disabled: false,
      description: strings.homeZmccDescription,
    },
    {
      name: strings.homeMyTransaction,
      link: '/my-bookings',
      externalLink: false,
      icon: images.cardList,
      class: 'box-black-gradient',
      disabled: false,
      description: strings.homeMyTransactionDescription,
    },
    {
      name: strings.demoSale,
      link: '/demo-sale',
      externalLink: false,
      icon: images.cardList,
      class: 'box-black-gradient mb-lg-0',
      disabled: false,
      description: strings.homeMarketplaceDescription,
    },
    {
      name: strings.aboutDma,
      link: '/documents',
      externalLink: false,
      icon: images.cardList,
      class: 'box-black-gradient',
      disabled: false,
      description: strings.homeDocumentDescription,
    },
  ];
  const showNonRmsButton = [
    {
      name: strings.homeKitloanCalendar,
      link: '/calendar',
      externalLink: false,
      icon: images.cradCart,
      class: 'box-black-gradient',
      disabled: !isFilterAllowed,
      description: strings.homeKitloanCalendarDescription,
    },
    {
      name: strings.homeKitsList,
      link: '/kits',
      externalLink: false,
      icon: images.cardList,
      class: 'box-black-gradient mb-lg-0',
      disabled: false,
      description: strings.homeKitslistDescription,
    },
    {
      name: strings.homeMyTransaction,
      link: '/my-bookings',
      externalLink: false,
      icon: images.cardList,
      class: 'box-black-gradient',
      disabled: false,
      description: strings.homeMyTransactionDescription,
    },
    {
      name: strings.stock,
      link: '/stocks',
      externalLink: false,
      icon: images.cardList,
      class: 'box-black-gradient',
      disabled: false,
      description: strings.warehouseDescription,
    },
    {
      name: strings.homeMyAccount,
      link: process.env.REACT_APP_ZEISS_PROFILE_URL,
      externalLink: true,
      icon: images.cardList,
      class: 'box-black-gradient mb-lg-0',
      disabled: false,
      description: strings.homeMyAccountDescription,
    },
    {
      name: strings.aboutDma,
      link: '/documents',
      externalLink: false,
      icon: images.cardList,
      class: 'box-black-gradient',
      disabled: false,
      description: strings.homeNonRmsDecription,
    },
  ];
  const showDustuserButton = [
    {
      name: strings.maintainDemoUnitStatus,
      link: '/dusd/maintain-status',
      externalLink: false,
      icon: images.cardList,
      class: 'box-black-gradient',
      disabled: false,
      description: strings.maintainDusDescription,
    },
    {
      name: strings.subscribeToSystems,
      link: '/dusd/subscriptions',
      externalLink: false,
      icon: images.cardList,
      class: 'box-black-gradient mb-lg-0',
      disabled: false,
      description: strings.subscribeToSystemsDescription,
    },
    {
      name: strings.activeSystems,
      link: '/dust/active-systems',
      externalLink: false,
      icon: images.cardList,
      class: 'box-black-gradient',
      disabled: false,
      description: strings.activeSystemDescription,
    },
    {
      name: strings.tasks,
      link: '/dusd/dusd-task',
      externalLink: false,
      icon: images.cardList,
      class: 'box-black-gradient  mb-lg-0',
      disabled: false,
      description: strings.taskDescription,
    },
    {
      name: strings.stock,
      link: '/stocks',
      externalLink: false,
      icon: images.cardList,
      class: 'box-black-gradient',
      disabled: false,
      description: strings.warehouseDescription,
    },
    {
      name: strings.aboutDma,
      link: '/documents',
      externalLink: false,
      icon: images.cardList,
      class: 'box-black-gradient mb-lg-0',
      disabled: false,
      description: strings.homeDocumentDescription,
    },
  ];
  const showlabsAndLocationButton = [
    {
      name: strings.homeLabsAndLocationCalendar,
      link: '/labs-location-calendar',
      externalLink: false,
      icon: images.cradCart,
      class: 'box-black-gradient',
      disabled: false,
      description: strings.homeLabsAndLocationDescription,
    },
    {
      name: strings.homeKitsList,
      link: '/kits',
      externalLink: false,
      icon: images.cardList,
      class: 'box-black-gradient mb-lg-0',
      disabled: false,
      description: strings.homelabsAndLocationKitListDescription,
    },
    {
      name: strings.loanRequests,
      link: '/my-bookings',
      externalLink: false,
      icon: images.cardList,
      class: 'box-black-gradient',
      disabled: false,
      description: strings.demoRequestDescription,
    },
    {
      name: strings.openProcesses,
      link: '/open-processes',
      externalLink: false,
      icon: images.cardList,
      class: 'box-black-gradient mb-lg-0',
      disabled: false,
      description: strings.openProcessesDescription,
    },
    {
      name: strings.homeMyAccount,
      link: process.env.REACT_APP_ZEISS_PROFILE_URL,
      externalLink: true,
      icon: images.person,
      class: 'box-black-gradient',
      disabled: false,
      description: strings.homelabslocationMyAccountDesc,
    },
    {
      name: strings.aboutDma,
      link: '/documents',
      externalLink: false,
      icon: images.cardList,
      class: 'box-black-gradient',
      disabled: false,
      description: strings.homeLabsLocationDcomentDesc,
    },
  ];
  const showRmsZmccButton = [
    {
      name: strings.homeKitloanCalendar,
      link: '/calendar',
      externalLink: false,
      icon: images.cradCart,
      class: 'box-black-gradient',
      disabled: !isFilterAllowed,
      description: strings.homeKitloanCalendarDescription,
    },
    {
      name: strings.homeMyTransaction,
      link: '/my-bookings',
      externalLink: false,
      icon: images.cardList,
      class: 'box-black-gradient',
      disabled: false,
      description: strings.homeMyTransactionDescription,
    },
    {
      name: strings.demoSale,
      link: '/demo-sale',
      externalLink: false,
      icon: images.cardList,
      class: 'box-black-gradient mb-lg-0',
      disabled: false,
      description: strings.homeMarketplaceDescription,
    },
    {
      name: strings.aboutDma,
      link: '/documents',
      externalLink: false,
      icon: images.cardList,
      class: 'box-black-gradient',
      disabled: false,
      description: strings.homeDocumentDescription,
    },
  ];
  const stageRmsButton = [
    {
      name: strings.homeKitloanCalendar,
      link: '/calendar',
      externalLink: false,
      icon: images.cradCart,
      class: 'box-black-gradient',
      disabled: !isFilterAllowed,
      description: strings.homeKitloanCalendarDescription,
    },
    {
      name: strings.homeLabsAndLocationCalendar,
      link: '/labs-location-calendar',
      externalLink: false,
      icon: images.cradCart,
      class: 'box-black-gradient',
      disabled: false,
      description: strings.homeLabsAndLocationCalendarDescription,
    },
    {
      name: strings.homeKitsList,
      link: '/kits',
      externalLink: false,
      icon: images.cardList,
      class: 'box-black-gradient mb-lg-0',
      disabled: false,
      description: strings.homeKitslistDescription,
    },
    {
      name: strings.homeMyTransaction,
      link: '/my-bookings',
      externalLink: false,
      icon: images.cardList,
      class: 'box-black-gradient',
      disabled: false,
      description: strings.homeMyTransactionDescription,
    },
    {
      name: strings.demoSale,
      link: '/demo-sale',
      externalLink: false,
      icon: images.cardList,
      class: 'box-black-gradient mb-lg-0',
      disabled: false,
      description: strings.homeMarketplaceDescription,
    },
    {
      name: strings.aboutDma,
      link: '/documents',
      externalLink: false,
      icon: images.cardList,
      class: 'box-black-gradient',
      disabled: false,
      description: strings.homeDocumentDescription,
    },
  ];
  if(user && user.stockRoles && user.stockRoles.length > 0 && user.stockRoles.find(item => item.businessUnitName === 'RMS (ZMCC)' && item.roleName !== 'Borrower')){
    showRmsZmccButton.splice(1, 0, {
      name: strings.zmccCalendar,
      link: '/zmcc-calendar',
      externalLink: false,
      icon: images.cradCart,
      class: 'box-black-gradient mb-lg-0',
      disabled: false,
      description: strings.homeZmccDescription,
    });

    showRmsZmccButton.splice(2,0, {
      name: strings.homeKitsList,
      link: '/kits',
      externalLink: false,
      icon: images.cardList,
      class: 'box-black-gradient mb-lg-0',
      disabled: false,
      description: strings.homeKitslistDescription,
    });
  }
  return (
    <div className='quickstart-container'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='row'>
              {isRms &&
                isZmccEnabled &&
                showRmsButton.map((item, index) => (
                  <div className='col-lg-4'>
                    <QuickStartBox
                      name={item.name}
                      link={item.link}
                      externalLink={item.externalLink}
                      disabled={item.disabled}
                      icon={item.icon}
                      class={item.class}
                      description={item.description}
                    />
                  </div>
                ))}
              {isRms &&
                !isZmccEnabled &&
                stageRmsButton.map((item, index) => (
                  <div className='col-lg-4'>
                    <QuickStartBox
                      name={item.name}
                      link={item.link}
                      externalLink={item.externalLink}
                      disabled={item.disabled}
                      icon={item.icon}
                      class={item.class}
                      description={item.description}
                    />
                  </div>
                ))}
              {isNonRms &&
                showNonRmsButton.map((item, index) => (
                  <div className='col-lg-4'>
                    <QuickStartBox
                      name={item.name}
                      link={item.link}
                      externalLink={item.externalLink}
                      disabled={item.disabled}
                      icon={item.icon}
                      class={item.class}
                      description={item.description}
                    />
                  </div>
                ))}
              {isDustUser &&
                showDustuserButton.map((item, index) => (
                  <div className='col-lg-4'>
                    <QuickStartBox
                      name={item.name}
                      link={item.link}
                      externalLink={item.externalLink}
                      disabled={item.disabled}
                      icon={item.icon}
                      class={item.class}
                      description={item.description}
                    />
                  </div>
                ))}
              {isLabsAndLocationUser &&
                showlabsAndLocationButton.map((item, index) => (
                  <div className='col-lg-4'>
                    <QuickStartBox
                      name={item.name}
                      link={item.link}
                      externalLink={item.externalLink}
                      disabled={item.disabled}
                      icon={item.icon}
                      class={item.class}
                      description={item.description}
                    />
                  </div>
                ))}
              {isRmsZmccUser &&
                isZmccEnabled &&
                showRmsZmccButton.map((item, index) => (
                  <div className='col-lg-4'>
                    <QuickStartBox
                      name={item.name}
                      link={item.link}
                      externalLink={item.externalLink}
                      disabled={item.disabled}
                      icon={item.icon}
                      class={item.class}
                      description={item.description}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickStart;
