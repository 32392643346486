import React from 'react';
import Modal from 'library/common/commonComponents/Modal';
import strings from 'resources/locales/Translate';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import { useBulkConfirmationPopup } from './BulkConfirmationPopup.hook';
import { addItemToCart, removeItemFromCart, removeItemFromCartForBulk } from 'modules/LoanList/LoanListActions';
import { connect } from 'react-redux';

export const BulkConfirmationPopup = ({
  id,
  data,
  bundleLoans,
  cartItems,
  addItemToCart,
  history,
  removeItemFromCart,
  removeItemFromCartForBulk,
  setSelectedData,
  setShowBulkConfirmPopup,
}) => {
  const {
    handleSelect,
    handleSelectAll,
    transactionsId,
    isSelectedAll,
    onCirfirmClick,
    onCloseClick,
    filteredBundleLoans,
  } = useBulkConfirmationPopup({
    id,
    data,
    bundleLoans,
    cartItems,
    addItemToCart,
    history,
    removeItemFromCart,
    removeItemFromCartForBulk,
    setSelectedData,
    setShowBulkConfirmPopup,
  });
  return (
    <Modal
      id={id}
      className='mt-5 modal-lg'
      title={strings.adoptChangeForSubsequentReservation}
      confirmAction={onCirfirmClick}
      confirmTitle={strings.relatedTransactionsConfirm}
      titleOfCancel={strings.relatedTransactionsCancel}

      disableBackgroundClose
      onCloseClick={onCloseClick}
    >
     <Checkbox
        selected={isSelectedAll}
        field='0'
        onChange={handleSelectAll}
        label={strings.selectAll}
      />
      <Checkbox
        selected={transactionsId.includes(data && data.transactionsId)}
        onChange={handleSelect(data)}
        label={`#${data && data.transactionNumber} (${strings.currentTransaction})`}
        disabled={true}
      />
      {filteredBundleLoans &&
        filteredBundleLoans.map(loans => (
          <Checkbox
            selected={transactionsId.includes(loans.transactionsId)}
            // field={loans.transactionsId}
            onChange={handleSelect(loans)}
            label={`#${loans.transactionNumber}`}
          />
        ))}
    </Modal>
  );
};



const mapStateToProps = ({ authReducer, languageReducer }) => ({
    user: authReducer.user,
    language: languageReducer.language,
  });
  
  export default connect(mapStateToProps, {
    addItemToCart,
    removeItemFromCart,
    removeItemFromCartForBulk
  })(BulkConfirmationPopup);
