import React, { Fragment } from 'react';

import strings from 'resources/locales/Translate';
import Button from 'library/common/commonComponents/Button/Button';

import QuestionAnswerBlock from './components/QuestionAnswerBlock';
import AnswerPopup from './components/AnswerPopup';
import { useQuestions } from './Questions.hook';

export default function Questions({
  questions,
  kitDetail,
  createQuestion,
  createAnswer,
  editAnswer,
  user,
  actionTypes,
}) {
  const {
    isShowMoreButton,
    showingQuestions,
    selectedAnswer,
    setSelectedAnswer,
    clearSelectedAnswer,
    showAllQuestions,
    openQuestionPopup,
    isHavePermission,
  } = useQuestions({ questions, user, kitDetail });

  return (
    <div className='container-fluid'>
      <div className='mb-4'>
        <div className='section-title'>{strings.questions}</div>
        {showingQuestions && showingQuestions.length > 0 ? showingQuestions.map(question => (
          <Fragment key={question.id}>
            <QuestionAnswerBlock {...question} setSelectedAnswer={setSelectedAnswer} isShowButtons={isHavePermission} />
          </Fragment>
        )) : (
          <div className='d-flex justify-content-center mb-3'><strong>{strings.noQuestionsAvailable}</strong></div>
        )}
      </div>
      {isShowMoreButton && (
        <Button
          dataTest='show-more-button'
          bgFill={false}
          value={strings.showMore}
          iconName='visibility'
          iconPosition='left'
          onClick={showAllQuestions}
          styleClass='w-auto showMore-button mb-3'
        />
      )}
      <Button
        dataTest='create-question'
        bgFill={false}
        value={strings.postYourQuestion}
        onClick={openQuestionPopup}
        styleClass='w-auto'
      />
      <AnswerPopup
        id='create-question-popup'
        kitDetail={kitDetail}
        title={strings.postYourQuestion}
        label={strings.enterYourQuestionHere}
        onSave={createQuestion}
        maxLength='500'
        actionTypes={actionTypes}
      />
      {isHavePermission && (
        <AnswerPopup
          id='create-answer-popup'
          kitDetail={kitDetail}
          title={strings.postYourAnswer}
          label={strings.enterYourAnswerHere}
          selectedAnswer={selectedAnswer}
          clearSelectedAnswer={clearSelectedAnswer}
          onSave={createAnswer}
          maxLength='1000'
          actionTypes={actionTypes}
        />
      )}
      {isHavePermission && (
        <AnswerPopup
          id='edit-answer-popup'
          kitDetail={kitDetail}
          title={strings.postYourAnswer}
          label={strings.enterYourAnswerHere}
          selectedAnswer={selectedAnswer}
          clearSelectedAnswer={clearSelectedAnswer}
          onSave={editAnswer}
          maxLength='1000'
          actionTypes={actionTypes}
        />
      )}
    </div>
  );
};
