import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import {setLabLocation} from 'library/api/labLocation.service'

export const saveData = () => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const res = await setLabLocation();
    
    if (res.status === 201 || res.status === 200) {
      dispatch(toggleActionMessage(true, 'success', 'LLPermissionsSuccessSaved'));
    }

    dispatch(changeLoader(false));
  } catch (err) {
    dispatch(changeLoader(false));

    if (err && err.response && err.response.status !== 404) {
      return dispatch(toggleActionMessage(true, 'error', 'errorOccuredText'));
    }
  }
};
