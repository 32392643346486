import React from 'react';
import toArray from 'lodash/toArray';

import strings from 'resources/locales/Translate';
import ItemsPerPage from 'library/common/commonComponents/ItemsPerPage/ItemsPerPage';
import Table from 'library/common/commonComponents/Table/Table';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import Pagination from 'library/common/commonComponents/Pagination/Pagination';
import Button from 'library/common/commonComponents/Button/Button';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import Icon from 'library/common/commonComponents/Icon/Icon';
import ConfirmDeletionPopup from 'library/common/commonComponents/ConfirmationBox';

import AddActiveSystemPopup from './Components/AddActiveSystemPopup';
import { useActiveSystems } from './ActiveSystems.hook';
import { itemsPerPage, activeSystemsBreadcrumbData } from './ActiveSystems.constants';
import './ActiveSystems.styles.scss';

const ActiveSystems = props => {
  const { activeSystemsList, appActions, user } = props;
  const { displayActionMessage, type, message, isLoading } = appActions;
  const {
    state,
    hasActiveSystemsList,
    cols,
    openAddActiveSystemPopup,
    handleItemsPerPage,
    handlePageChange,
    confirmActiveSystemDeletion,
    hideDeleteActiveSystemPopup,
    hideActivationSystemPopup,
    activateSystem,
    deletionError,
    isLoadingDeletion,
    isLoadingActivation,
    isDustUser,
  } = useActiveSystems(props);

  return (
    <div>
      {displayActionMessage && <ActionMessage type={type} message={strings[message]} />}
      <div className='container-fluid mb-3'>
        <Breadcrumbs data={activeSystemsBreadcrumbData} />
        <div className='d-flex justify-content-between mb-3'>
          <div className='d-flex align-items-center'>
            <h4 className='mr-2'>{strings.activeSystems}</h4>
            <Icon
              name='info'
              width={18}
              height={18}
              fill='#9a9b9c'
              className='cursor-pointer'
              data-tooltip={strings.demoUnitStatusCanOnlyBeAddedToSystems}
              data-tooltip-config={JSON.stringify({ direction: 'right' })}
            />
          </div>
          {!isDustUser && (
            <Button
              bgFill={false}
              iconName='plus'
              onClick={openAddActiveSystemPopup}
              value={strings.add}
              styleClass='w-auto'
            />
          )}
        </div>
        {hasActiveSystemsList ? (
          <div>
            <Table cols={cols} rows={activeSystemsList.content} />
          </div>
        ) : (
          <div className='d-flex justify-content-center'>
            <strong>{strings.noActiveSystemsAvailable}</strong>
          </div>
        )}
        <div>
          {hasActiveSystemsList && (
            <div className='d-flex justify-content-end mb-3'>
              <ItemsPerPage
                itemsPerPage={toArray(itemsPerPage)}
                handleItemsPerPage={handleItemsPerPage}
                value={itemsPerPage[state.size]}
              />
              <Pagination
                currentPage={state.page}
                totalPages={activeSystemsList.totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
      {isLoading && <div className='loader' />}
      <AddActiveSystemPopup id='add-active-system-popup' page={state.page} size={state.size} />
      <ConfirmDeletionPopup
        id='confirm-delete-active-system-modal'
        onConfirm={confirmActiveSystemDeletion}
        onClose={hideDeleteActiveSystemPopup}
        error={deletionError}
        isLoading={isLoadingDeletion}
        title={strings.deactivateSystem}
        subtitle={strings.areYouSureYouWantToRemoveSystem}
      />
      <ConfirmDeletionPopup
        id='confirm-activate-system-modal'
        onConfirm={activateSystem}
        onClose={hideActivationSystemPopup}
        error={deletionError}
        isLoading={isLoadingActivation}
        title={strings.activateSystem}
        subtitle={strings.areYouSureYouWantToActivateSystem}
      />
    </div>
  );
};

export default ActiveSystems;
