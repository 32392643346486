import React, { Component } from 'react';
import { debounce } from 'lodash';
import $ from 'jquery';

import strings from 'resources/locales/Translate';

import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import SearchField from 'library/common/commonComponents/SearchField/SearchField';
import Pagination from 'library/common/commonComponents/Pagination/Pagination';
import * as actionTypes from 'library/common/commonConstants/storeConstants';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import Button from 'library/common/commonComponents/Button/Button';
import Table from 'library/common/commonComponents/Table/Table';
import Icon from 'library/common/commonComponents/Icon/Icon';
import { scrollToTop } from 'library/utilities/scrollActions';

import RequestStockPermission from './StocksListFrames/RequestStockPermission/RequestStockPermission';
import { stocksListBreadcrumbData } from './constants';
import Filter from './StocksListFrames/Filter/Filter';
import { dataTable } from './dataTable';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import { fetchFromStorage, saveToStorage } from 'library/utilities/storage';

const INITIAL_FILTER = {
  countryId: null,
  countryIdFullValue: null,
  businessId: null,
  businessIdFullValue: null,
  searchTerm: null,
};

class StocksList extends Component {
  constructor(props) {
    super(props);
    let filter = { ...INITIAL_FILTER };
    const storage = fetchFromStorage(identifiers.filtersStock);
    if (storage) {
      filter = {
        ...storage,
        searchTerm: storage.searchTerm ? storage.searchTerm : '',
      };
    }
    this.state = {
      isShowFilter: true,
      data: {
        page: 1,
        size: 10,
        sort: {
          locationName: null,
          locationShortName: null,
          countryName: null,
          countryShortName: null,
          businessUnitName: null,
          firstName: null,
          email: null,
          createdOn: 'desc',
        },
        filter,
      },
      requestPermissionModalValue: {},
    };
  }

  componentDidMount() {
    document.title = strings.stock;
    const { fetchMasterData, fetchStocksListData, user } = this.props;

    fetchMasterData(URLS.countriesWithActiveStocks, actionTypes.FETCH_COUNTRIES_WITH_ACTIVE_STOCKS);
    fetchMasterData(URLS.businessUnitList, actionTypes.FETCH_BUSINESS_UNIT_LIST);
    fetchMasterData(URLS.userList, actionTypes.FETCH_USERS_LIST);
    fetchMasterData(URLS.rolesList, actionTypes.FETCH_ROLES_LIST);

    this.props.fetchCartDataFromLocalStorage();
    const isUserPartner = user && user.labLocationPartner;

    if (isUserPartner) {
      this.setState(
        prevState => {
          const newData = { ...prevState.data };
          newData.filter['businessId'] = 9;
          return { data: newData };
        },
        () => {

          saveToStorage(identifiers.filtersStock, this.state.data.filter);
          fetchStocksListData(URLS.stockList, actionTypes.FETCH_STOCKS_LIST, this.state.data);
        },
      );
    } else {
      fetchStocksListData(URLS.stockList, actionTypes.FETCH_STOCKS_LIST, this.state.data);
    }
  }

  componentDidUpdate(prevProps) {
    const { language, fetchMasterDataOnLanguageChange, fetchMasterData } = this.props;
    if (language !== prevProps.language) {
      document.title = strings.stock;
      fetchMasterDataOnLanguageChange();
      fetchMasterData(URLS.rolesList, actionTypes.FETCH_ROLES_LIST);
      fetchMasterData(URLS.countriesWithActiveStocks, actionTypes.FETCH_COUNTRIES_WITH_ACTIVE_STOCKS);
    }
  }

  resetFilter = async key => {
    if (!key) {
      await this.setState(prevState => ({ data: { ...prevState.data, filter: { ...INITIAL_FILTER } } }));
    } else {
      await this.setState(prevState => ({
        data: {
          ...prevState.data,
          filter: {
            ...prevState.data.filter,
            [key]: null,
            [`${key}FullValue`]: null,
          },
        },
      }));
    }
    saveToStorage(identifiers.filtersStock, this.state.data.filter);
    this.props.fetchStocksListData(URLS.stockList, actionTypes.FETCH_STOCKS_LIST, this.state.data);
  };

  handleFilterChange = key => (value, field, fullValue) => {
    const { fetchStocksListData } = this.props;
    this.setState(
      prevState => {
        const newData = { ...prevState.data };
        newData.filter[key] = value;
        newData.filter[`${key}FullValue`] = fullValue;
        return { data: newData };
      },
      () => {
        saveToStorage(identifiers.filtersStock, this.state.data.filter);
        fetchStocksListData(URLS.stockList, actionTypes.FETCH_STOCKS_LIST, this.state.data);
      },
    );
  };

  showOrHideFilter = () => {
    this.setState(prevState => ({ isShowFilter: !prevState.isShowFilter }));
  };

  handleSort = name => {
    const { fetchStocksListData } = this.props;

    this.setState(
      prevState => {
        const newData = JSON.parse(JSON.stringify(prevState.data));
        Object.keys(newData.sort).forEach(key => (newData.sort[key] = null));
        newData.sort[name] = prevState.data.sort[name] ? (prevState.data.sort[name] === 'asc' ? 'desc' : null) : 'asc';

        return { data: newData };
      },
      () => fetchStocksListData(URLS.stockList, actionTypes.FETCH_STOCKS_LIST, this.state.data),
    );
  };

  handlePageChange = page => {
    const { fetchStocksListData } = this.props;

    this.setState(
      prevState => {
        const newData = { ...prevState.data };

        newData.page = page + 1;
        return { data: newData };
      },
      () => fetchStocksListData(URLS.stockList, actionTypes.FETCH_STOCKS_LIST, this.state.data),
    );
  };

  handleSearchChange = e => {
    const { value } = e.target;
    const { fetchStocksListData } = this.props;
    this.debounceUpdateData({ value, fetchStocksListData });
  };

  debounceUpdateData = debounce(({ value, fetchStocksListData }) => {
    this.setState(
      prevState => {
        const newData = { ...prevState.data };
        newData.page = 1;
        newData.filter.searchTerm = value;
        return { data: newData };
      },
      () => {
        saveToStorage(identifiers.filtersStock, this.state.data.filter);
        fetchStocksListData(URLS.stockList, actionTypes.FETCH_STOCKS_LIST, this.state.data);
      },
    );
  }, 1000);

  goToCreateLocation = () => {
    this.props.cancelStockCreation();
    this.props.history.push('/create_stocks');
    window.location.reload();
  };

  handleDelete = id => {
    const { data } = this.state;
    const { fetchStocksListData, deleteStock } = this.props;

    deleteStock(id).then(res => {
      fetchStocksListData(URLS.stockList, actionTypes.FETCH_STOCKS_LIST, data);
    });
    scrollToTop(500);
  };

  openRequestModal = id => {
    this.setState(
      {
        requestPermissionModalValue: { stockId: id },
      },
      () => {
        $('#requestPermissionModal').modal('show');
      },
    );
  };

  render() {
    const { countries, businessUnit, stocksList, user } = this.props;
    const { isShowFilter, data, requestPermissionModalValue } = this.state;
    const { displayActionMessage, type, message } = this.props.appActions;
    
    const checkStocksList = stocksList && stocksList.content && stocksList.content.length;
    const isUserPartner = user && user.labLocationPartner;

    return (
      <div>
        {displayActionMessage && <ActionMessage type={type} message={strings[message]} />}
        <div className='container-fluid mb-3'>
          <Breadcrumbs data={stocksListBreadcrumbData} />
          <div className='row no-gutters align-items-center'>
            <h1 className='col-md col-12 stock-title'>{strings.stocks}</h1>
            <div className='col-md-auto col mr-3'>
              <div className='input-wrapper'>
                <SearchField onChange={this.handleSearchChange} initialValue={data.filter.searchTerm} />
              </div>
            </div>
            <div className='col-auto'>
              <Button
                dataTest='stock-list-filter-button'
                bgFill={false}
                iconName='filter'
                onClick={this.showOrHideFilter}
                value={isShowFilter ? strings.hideFilter : strings.showFilter}
                styleClass='filter-button'
              />
            </div>
          </div>
        </div>
        <Filter
          isShowFilter={isShowFilter}
          isUserPartner={isUserPartner}
          countries={countries}
          businessUnit={businessUnit}
          handleFilterChange={this.handleFilterChange}
          selected={data.filter}
          resetFilter={this.resetFilter}
        />
        <div className='stock-table'>
          {checkStocksList ? (
            <div className='container-fluid mb-3'>
              <Table
                cols={dataTable(user, this.openRequestModal)}
                rows={stocksList.content}
                sortFields={data.sort}
                handleSort={this.handleSort}
                handleDelete={this.handleDelete}
              />
            </div>
          ) : (
            <div className='d-flex justify-content-center'>
              <strong>{strings.noDataFoundText}</strong>
            </div>
          )}
        </div>
        <div className='container-fluid'>
          <div className='row no-gutters'>
            {user && (user.admin || user.llmanager) &&  (
              <div className='col-sm-auto mb-3' style={{ marginRight: '20px' }}>
                <Button bgFill={false} iconName='plus' value={user.admin ? strings.createStockButton:strings.createLLStockButton} onClick={this.goToCreateLocation}/>
              </div>
            )}

            {user && !user.admin && (
              <div className='col-sm-auto mb-3'>
                <p
                  className='request-stock-button cursor-pointer'
                  data-toggle='modal'
                  data-target='#requestPermissionModal'
                >
                  {strings.requestStockPermissionButtonText}
                  <Icon name='plus' width={24} height={24} fill='#0088d0' className='plus-icon' />
                </p>
              </div>
            )}

            {checkStocksList ? (
              <div className='col d-flex justify-content-end'>
                <Pagination
                  currentPage={data.page - 1}
                  totalPages={stocksList && stocksList.totalPages}
                  onPageChange={this.handlePageChange}
                />
              </div>
            ) : null}
          </div>
        </div>
        <RequestStockPermission id='requestPermissionModal' value={requestPermissionModalValue} />
      </div>
    );
  }
}

export default StocksList;
