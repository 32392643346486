import ReactPaginate from 'react-paginate';
import React, {Component} from 'react';

import './pagination.styles.scss';

export default class Pagination extends Component {
  handlePageClick = (data) => {
    const {selected} = data;
    const {onPageChange} = this.props;
    
    onPageChange(selected);
  };

  render() {
    const {totalPages, currentPage} = this.props;

    return(
      <div className="pagination-container">
        <ReactPaginate
          previousLabel='<'
          nextLabel='>'
          breakLabel='...'
          breakClassName='break'
          pageCount={totalPages}
          forcePage={currentPage}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick}
          disabledClassName='disabled-link'
          containerClassName='pagination'
          pageLinkClassName='page-link'
          nextLinkClassName='page-link'
          previousLinkClassName='page-link'
          activeClassName='active'
        />
      </div>
    )
  }
}
