import { connect } from 'react-redux';

import InterfaceMonitoring from './InterfaceMonitoring.component';
import { fetchInterfaceMonitoring } from './InterfaceMonitoring.actions';

const mapStateToProps = ({ languageReducer, interfaceMonitoringReducer }) => ({
  language: languageReducer,
  interfaceMonitoring: interfaceMonitoringReducer.interfaceMonitoring,
});

export default connect(mapStateToProps, { fetchInterfaceMonitoring })(InterfaceMonitoring);

