import {
  FETCH_CREATE_KIT_FOR_SALE_DETAILS,
  UPDATE_CREATE_KIT_FOR_SALE_FORM,
  RESET_CREATE_KIT_FOR_SALE,
  FETCH_CUSTOMERS,
} from './CreateKitForSale.constants';

const EMPTY_FORM = {
  formData: {},
  isFormValid: false,
};

export const INITIAL_STATE_CREATE_KIT_FOR_SALE_REDUCER = {
  kitInformationId: null,
  kitDetails: null,
  generalInformationKitForSaleForm: {
    formData: {
      currencyForTransferPrice: 'EUR',
    },
    isFormValid: false,
  },
  kitStatusForSaleForm: {
    formData: {
      kitConditionId: 3,
    },
    isFormValid: false,
  },
  furtherImportantComponentForm: EMPTY_FORM,
  salesComponentForm: EMPTY_FORM,
  freeAccessoriesForm: EMPTY_FORM,
  kitForSaleForm: {
    formData: {
      quantity: 1,
    },
    isFormValid: false,
  },
  salesDetailsForm: EMPTY_FORM,
  customers: [],
  kitImages: [],
  kitAttachments: [],
  kitImagesToDelete: [],
  kitAttachmentsToDelete: [],
};

export const CreateKitForSaleReducer = (state = INITIAL_STATE_CREATE_KIT_FOR_SALE_REDUCER, action) => {
  switch (action.type) {
    case FETCH_CREATE_KIT_FOR_SALE_DETAILS:
      return { ...state, ...action.payload, kitInformationId: action.payload.kitDetails.kitInformationId };
    case UPDATE_CREATE_KIT_FOR_SALE_FORM:
      return { ...state, ...action.payload };
    case RESET_CREATE_KIT_FOR_SALE:
      return { ...INITIAL_STATE_CREATE_KIT_FOR_SALE_REDUCER };
    case FETCH_CUSTOMERS:
      return { ...state, customers: action.payload };
    default:
      return state;
  }
};

export default CreateKitForSaleReducer;
