import {
  FETCH_INITIAL_DATA_FOR_STOCK_DASHBOARD,
  FETCH_STOCK_BY_COUNTRY,
  FETCH_OUTGOING_STATISTICS,
  FETCH_INCOMING_STATISTICS,
  FETCH_LOAN_AVAILABILITY_BY_STOCKS,
  FETCH_THIS_MONTH_STATISTICS,
} from './StockDashboard.constants';

const initialState = {
  businessUnits: [],
  countries: [],
  stocks: [],
  outgoingStatistics: {},
  incomingStatistics: {},
  loanAvailabilityByStocks: [],
  thisMonthStatistics: {},
};

const StockDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INITIAL_DATA_FOR_STOCK_DASHBOARD:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_STOCK_BY_COUNTRY:
      return {
        ...state,
        stocks: action.payload,
      };
    case FETCH_OUTGOING_STATISTICS:
      return {
        ...state,
        outgoingStatistics: action.payload,
      };
    case FETCH_INCOMING_STATISTICS:
      return {
        ...state,
        incomingStatistics: action.payload,
      };
    case FETCH_LOAN_AVAILABILITY_BY_STOCKS:
      return {
        ...state,
        loanAvailabilityByStocks: action.payload,
      };
    case FETCH_THIS_MONTH_STATISTICS:
      return {
        ...state,
        thisMonthStatistics: action.payload,
      };
    default:
      return state;
  }
};

export default StockDashboardReducer;
