import React from 'react';

import DoughnutChartWithTextInside from '../DoughnutChartWithTextInside';
import ProgressBarBlock from '../ProgressBarBlock';

const StatisticsBlock = ({
  statistics,
  title,
  isShowCourseLoan,
  isShowSingleLoan,
  legend,
}) => {
  const { delivered, total } = statistics;

  return (
    <div className='col-md-6'>
      <DoughnutChartWithTextInside total={total} delivered={delivered} legend={legend} />
      <div className='block-with-border'>
        <h3 className='text-center mb-5'>{title}</h3>
        <ProgressBarBlock
          statistics={statistics}
          isShowSingleLoan={isShowSingleLoan}
          isShowCourseLoan={isShowCourseLoan}
        />  
      </div>
    </div>
  );
}

export default StatisticsBlock;
