import { batchActions } from 'redux-batched-actions';

import { fetchMasterDataServicePost } from 'library/api/master-data.service';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { changeLoader } from 'library/common/commonActions/AppActionsActions';

import { FETCH_INTERFACE_MONITORING, CLEAR_INTERFACE_MONITORING } from './InterfaceMonitoring.constants';

export const fetchInterfaceMonitoring = dataToSend => async dispatch => {
  try {
    dispatch(changeLoader(true));

    const { data } = await fetchMasterDataServicePost(URLS.interfaceMonitoring, {
      graph: "id,httpStatus,interfaceType,status,message,transactionOrKitName,createdDate,createdTime,transactionsOrKitNumber,sapCorrelationId,numberType",
      ...dataToSend,
    });

    batchActions([
      dispatch({
        type: FETCH_INTERFACE_MONITORING,
        payload: data,
      }),
      dispatch(changeLoader(false)),
    ]);
  } catch (err) {
    batchActions([
      dispatch(changeLoader(false)),
      dispatch({
        type: FETCH_INTERFACE_MONITORING,
        payload: {},
      }),
    ]);
  }
};

export const clearInterfaceMonitoring = () => dispatch => {
  dispatch({ type: CLEAR_INTERFACE_MONITORING });
};
