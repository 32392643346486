import React from 'react';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';

import strings from 'resources/locales/Translate';
import Icon from "library/common/commonComponents/Icon/Icon";
import ConfirmDeletionPopup from 'library/common/commonComponents/ConfirmationBox';
import Button from 'library/common/commonComponents/Button/Button';

const openPopup = id => {
  if ($(`confirmDeleteModal${id}`) && $(`confirmDeleteModal${id}`).modal) {
    $(`#confirmDeleteModal${id}`).modal('show');
  }
};

export const dataTable = (user, openRequestModal) => [
  {
    title: strings.stock,
    name: 'locationName',
    isSort: true,
    render: (item) => {
      return (
        <span>{item.locationName}</span>
      )
    },
  },
  {
    title: strings.shortName,
    name: 'locationShortName',
    isSort: true,
    render: (item) => {
      const{locationShortName, country, businessUnit} = item;

      const shortName = locationShortName && item.locationShortName;
      const countryName = country && country.shortName && item.country.shortName;
      const businessUnitName = businessUnit && businessUnit.name && item.businessUnit.name;

      return (
        <span>{countryName} {businessUnitName} {shortName}</span>
      )
    },
  },
  {
    title: strings.countryLand,
    name: 'countryName',
    isSort: true,
    render: (item) => {
      return (
        <span>{item.country.name}</span>
      )
    },
  },
  {
    title: strings.shortNameCountry,
    name: 'countryShortName',
    isSort: true,
    render: (item) => {
      return (
        <span>{item.country.shortName}</span>
      )
    },
  },
  {
    title: strings.businessUnit,
    name: 'businessUnitName',
    isSort: true,
    render: (item) => {
      return (
        <span>{item.businessUnit.name}</span>
      )
    },
  },
  {
    title: strings.stockControllerSingle,
    name: 'firstName',
    isSort: true,
    render: (item) => {
      return (
        <span>
          {item.warehouseActiveAddress && item.warehouseActiveAddress[0] && item.warehouseActiveAddress[0].firstName} {item.warehouseActiveAddress && item.warehouseActiveAddress[0] && item.warehouseActiveAddress[0].lastName}
        </span>
      );
    },
  },
  {
    title: strings.email,
    name: 'email',
    isSort: true,
    render: (item) => {
      return <span>{item.warehouseActiveAddress && item.warehouseActiveAddress[0]?.email}</span>;
    },
  },
  {
    title: '',
    name: 'edit',
    render: (item) => {
      if (!user.admin && (item.userRole && item.userRole.length === 0)) {
        return (
          <button className='button-blue' style={{width: '150px'}} onClick={() => openRequestModal(item.id)}>
            <Icon
              name='plus'
              width={20}
              height={20}
              fill='#0088d0'
            />
            <span className='ml-1'>{strings.requestStockPermissionButtonText}</span>
          </button>
        )
      } else {
        return (
          <NavLink to={`/edit-stock/${item.id}`}>
            <button className='button-blue'>
              <Icon
                name='edit'
                width={16}
                height={16}
                fill='#0088d0'
              />
              <span className='ml-1'>{strings.edit}</span>
            </button>
          </NavLink>
        )
      }
    },
  },
  {
    title: '',
    name: 'delete',
    render: (item, handleDelete) => {
      if (user.admin || (item.userRole.length && item.userRole[0].role.role.toLowerCase() === 'stock controller')) {
        return (
          <div>
            <Button
              bgFill={false}
              isGhost={true}
              iconWidth={16}
              iconHeight={16}
              iconPosition='left'
              iconStyle='mr-1'
              iconName='delete'
              onClick={() => openPopup(item.id)}
              value={strings.delete}
              styleClass='small-blue-button'
            />
            <ConfirmDeletionPopup
              id={`confirmDeleteModal${item.id}`}
              onConfirm={() => handleDelete(item.id)}
              title={strings.confirmDeleteStock}
              subtitle={strings.confirmDeleteStockMessage}
            />
          </div>
        )
      }
    },
  }
]
