import { batchActions } from 'redux-batched-actions';
import {} from './C2CLoansConstants';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import {
  fetchMasterDataServicePost,
} from 'library/api/master-data.service';
import { CHANGE_LOADER, FETCH_C2C_LOANS } from 'library/common/commonConstants/storeConstants';

const transactionDates = 'targetDeliveryDate,targetDeliveryFrom,targetDeliveryTo,targetReservationFrom,targetReservationTo,targetReturnDeliveryDate,targetReturnDeliveryFrom,targetReturnDeliveryTo,targetReconditioningFrom,targetReconditioningTo,targetCheckedDate';
const c2cDetails = `transactionsId,c2cId,transactionNumber,type,name,${transactionDates},allNextC2c[transactionsId,c2cId,transactionNumber,type,name,${transactionDates}]`;
const transaction = 'transactionsId,c2cId,transactionNumber,name,transactionKit[kitInformation[kitInformationId,kitName,serialNumber,transactionPosition[transactionPositionTranslated[positionText]]]]'
const allPrevC2c = `allPrevC2c[${c2cDetails}]`
const allNextC2c = `allNextC2c[${c2cDetails}]`
const graphql = `${transaction},${transactionDates},${allPrevC2c},${allNextC2c}`;

export const handlingSendData = (data, transactionType) => {
  const {
    sort,
    filter: {
      condition,
      currentPosition,
      stock,
      systemClass,
      transactionStatuses,
    },
    search,
  } = data;
  let sortField = null;
  let sortDirection = null;
  Object.keys(sort).forEach(item => {
    if (sort[item]) {
      sortField = item;
      sortDirection = sort[item];
    }
  });
  const sendData = {
    graph: graphql,
    searchType: 'c2c',
    search,
    transactionType: null,
    stockIds: stock ? stock : [],
    systemMainComponentIds: systemClass ? systemClass : [],
    currentPositionIds: currentPosition ? currentPosition : [],
    transactionStatusIds: transactionStatuses ? transactionStatuses : [],
    kitConditionIds: condition ? condition : [],
    dateType: null,
    timestampStart: null,
    timestampEnd: null,
    sortField,
    sortDirection,
    page: data.page,
    size: data.size,
  };
  return sendData;
};

export const fetchC2CTransactionList = (data, transactionType, fetchMasterData = false) => async dispatch => {
  try {
    const sendData = handlingSendData(data, transactionType);
    dispatch({ type: CHANGE_LOADER, payload: true });
    //if (fetchMasterData) {
      const { data: transactionsList } = await fetchMasterDataServicePost(URLS.transactionsListNew, sendData);
      dispatch(
        batchActions([
          dispatch({ type: FETCH_C2C_LOANS, payload: transformTransactions(transactionsList) }),
          dispatch({ type: CHANGE_LOADER, payload: false }),
        ]),
      );
   // } 
    // else {
    //   const { data: transactionsList } = await fetchMasterDataServicePost(URLS.transactionsListNew, sendData);
    //   dispatch(
    //     batchActions([
    //       dispatch({ type: FETCH_C2C_LOANS, payload: transformTransactions(transactionsList) }),
    //       dispatch({ type: CHANGE_LOADER, payload: false }),
    //     ]),
    //   );
    // }
  } catch (e) {
    dispatch(
      batchActions([
        dispatch({ type: CHANGE_LOADER, payload: false }),
        dispatch({ type: FETCH_C2C_LOANS, payload: [] }),
      ]),
    );
  }
};

export const transformTransactions = data => {
  const content = [];
  data.content.forEach(item => {
    content.push({
      c2cId: item.c2cId,
      transactionNumber: item.transactionNumber,
      transactionsId: item.transactionsId,
      transactionKit: item.transactionKit,
      startDate: item.targetReservationFrom,
      endDate:
        item.allNextC2c && item.allNextC2c.length
          ? item.allNextC2c[item.allNextC2c.length - 1].targetReservationTo
          : item.targetReservationTo,
      allNextC2c: item.allNextC2c && item.allNextC2c.length ? [item, ...item.allNextC2c] : [item],
      allPrevC2c: item.allNextC2c && item.allNextC2c.length ? [...item.allPrevC2c] : [],
    });
  });
  return { ...data, content };
};
