import React from 'react';

import strings from 'resources/locales/Translate';
import FileUploader from 'library/common/commonComponents/FileUploader/FileUploader';

const AttachmentsBlock = props => {
  const { title, downloadUrl, id, viewType, files } = props;

  return (
    <>
      {title && <p className='key-header mb-3'>{title}</p>}
      {files.length > 0 ? (
        <FileUploader
          mimeType='image/*'
          files={files}
          viewType={viewType}
          isDownloadable
          downloadStream
          downloadIdField={id}
          downloadUrl={downloadUrl}
          isUploadAllowed={false}
        />
      ) : (
        <div className='value my-3'>
          <p>{strings.noDataFoundText}</p>
        </div>
      )}
    </>
  );
};

export default AttachmentsBlock;
