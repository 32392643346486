export const kitPicturesBreadcrumbData = [
  {
    name:'homepage',
    url: '/home',
  },
  {
    name: 'administration',
  },
  {
    name:'kitPictures',
  },
];

export const FETCH_SYSTEM_CLASSES_BY_DUSINESS_UNIT = 'MANAGE_KIT_PICTURES/FETCH_SYSTEM_CLASSES_BY_DUSINESS_UNIT';
export const FETCH_BUSINESS_UNITS = 'MANAGE_KIT_PICTURES/FETCH_BUSINESS_UNITS';
export const FETCH_KITS_IMAGES = 'MANAGE_KIT_PICTURES/FETCH_KITS_IMAGES';
export const CLEAR_KITS_IMAGES = 'MANAGE_KIT_PICTURES/CLEAR_KITS_IMAGES';
