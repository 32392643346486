import React from 'react';

import strings from 'resources/locales/Translate';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import Button from 'library/common/commonComponents/Button/Button';

import OpportunitySearchPopup from '../OpportunitySearchPopup';
import OpportunityListPopup from '../OpportunityListPopup';
import { useOpportunityIds } from './OpportunityIds.hook';
import './OpportunityIds.styles.scss';

const OpportunityIds = ({
  setOpportunity,
  setOpportunities,
  removeOpportunity,
  opportunities,
  isRequired,
  setIsOpportunityIdCrmError,
  isOpportunityIdCrmError,
  state,
  handleEnteredOpportunityId,
  user,
  descriptionForm,
  updateShortDescription,
  customerForm,
  updateCustomerForm,
  handleForm,
  hasShow,
  selectedKits,
  componentWarehouseForm,
  loanType,
}) => {
  const {
    disabled,
    isShowSearchButton,
    hasOpportunities,
    openOpportunitySearchPopup,
    handleInput,
    handleSetOpportunity,
    handleRemoveOpportunity,
    disableonDifferentStock,
  } = useOpportunityIds({
    user,
    descriptionForm,
    customerForm,
    setOpportunity,
    removeOpportunity,
    opportunities,
    setIsOpportunityIdCrmError,
    state,
    handleEnteredOpportunityId,
    selectedKits,
    componentWarehouseForm,
    loanType,
  });
  return (
    <div className='opportunity-container mb-3'>
      <div className='d-flex mb-3'>
        <InputField
          disabled={disabled}
          label={strings.opportunityIdCRM}
          onChange={handleInput}
          value={state.value}
          isRequired={isRequired}
          error={state.error || (isOpportunityIdCrmError && 'pleaseMindThat')}
          className='w-100 mr-3'
        />
        <Button
          bgFill={false}
          iconName='plus'
          styleClass='add-button p-2'
          disabled={disabled}
          onClick={handleSetOpportunity}
        />
        {((hasShow && !hasOpportunities) || isShowSearchButton) && (
          <Button
            bgFill={false}
            value={strings.searchWithCustomerName}
            styleClass='add-button ml-3'
            disabled={disableonDifferentStock === false ? true : hasShow ? false : !isShowSearchButton}
            onClick={() => openOpportunitySearchPopup()}
          />
        )}
      </div>
      <div>
        {hasOpportunities &&
          opportunities.map((item, key) => (
            <div key={key} className='d-flex justify-content-between align-items-center opportunity-list-item'>
              <p>{item.opportunityNumber}</p>
              <Button
                bgFill={false}
                isGhost={true}
                iconName='delete'
                styleClass='w-auto p-0'
                onClick={() => handleRemoveOpportunity(key)}
              />
            </div>
          ))}
      </div>
      <OpportunitySearchPopup
        selectedKits={selectedKits}
        componentWarehouseForm={componentWarehouseForm}
        loanType={loanType}
      />
      <OpportunityListPopup
        setOpportunities={setOpportunities}
        updateShortDescription={updateShortDescription}
        updateCustomerForm={updateCustomerForm}
        handleForm={handleForm}
      />
    </div>
  );
};

export default OpportunityIds;
