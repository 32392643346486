export const UPDATE_CREATE_SEM_FORM = 'UPDATE_CREATE_SEM_FORM';
export const FETCH_CREATE_SEM_TRANSACTION_DETAILS = 'FETCH_CREATE_SEM_TRANSACTION_DETAILS';
export const FETCH_LOANS_INTENDED = 'FETCH_LOANS_INTENDED';
export const FETCH_BESTWAY_TO_REACH = 'FETCH_BESTWAY_TO_REACH';
export const FETCH_DATA_WITHIN = 'FETCH_DATA_WITHIN';
export const FETCH_MARKET_SEGMENTS = 'FETCH_MARKET_SEGMENTS';
export const FETCH_RELATIONSHIP_WITH_ZEISS = 'FETCH_RELATIONSHIP_WITH_ZEISS';
export const FETCH_STATUS_OF_SAMPLE = 'FETCH_STATUS_OF_SAMPLE';
export const FETCH_DATA_WITH_CUSTOMER = 'FETCH_DATA_WITH_CUSTOMER';
export const FETCH_PRIMARY_INSTRUMENT = 'FETCH_PRIMARY_INSTRUMENT';
export const FETCH_SKILL_LEVEL = 'FETCH_SKILL_LEVEL';
export const RESET_CREATE_SEM_FORM ='RESET_CREATE_SEM_FORM';
export const FETCH_ALL_UNEXECUTED_LOANS = 'FETCH_ALL_UNEXECUTED_LOANS';
export const FETCH_ESTIMATE_DAYS = 'FETCH_ESTIMATE_DAYS';
export const FETCH_ZMCC_SHIPPING_ADDRESS= 'FETCH_ZMCC_SHIPPING_ADDRESS';
export const FETCH_TRANSACTION_ADDRESS= 'FETCH_TRANSACTION_ADDRESS';
export const FETCH_MARKET_SUB_SEGMENTS = 'FETCH_MARKET_SUB_SEGMENTS';
export const FETCH_SYSTEM_CATEGORIES = 'FETCH_SYSTEM_CATEGORIES';
export const CLICKED_ADD_IN_DEMO_CENTRE_TAB = 'CLICKED_ADD_IN_DEMO_CENTRE_TAB';
export const CLICKED_ADD_IN_TODO_TAB = 'CLICKED_ADD_IN_TODO_TAB';
export const FETCH_SBU_TYPES = 'FETCH_SBU_TYPES';