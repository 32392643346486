import {URLS} from '../common/commonConstants/ApiUrlConstants';

export function errorHandler(error) {
  if (error.response) {
    console.log(error.response);

    const loginURL = process.env.REACT_APP_BASE_URL + URLS.login;

    if (error.response.status === 401) {
      if (error.response.config.url !== loginURL) {
        localStorage.clear();
        window.location.reload();
      }
    }

  } else if (error.request) {
    console.log(error.request);
  } else {
    console.log('Error', error.message);
  }
}
