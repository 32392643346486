import { useState, useEffect } from 'react';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import { fetchFromStorage } from 'library/utilities/storage';

export const useLLKitLoan = props => {
  const {
    user,
    fetchInitialData,
    fetchCartDataFromLocalStorage,
    selectedKits,
    fetchKitDetails,
    fetchWorkflowData,
    clearForms,
    fetchTypeOfJobAssociatedWithKitSystemmainComponent,
  } = props;
  const kits = selectedKits && selectedKits.filter(item => (item.isLabsLocation === true) && (item.isSelected === true));
  const [activeStep, setActiveStep] = useState(1);
  const [isShowCheckbox, setIsShowCheckbox] = useState(false);
  const [enteredOpportunityId, setEnteredOpportunityId] = useState({
    value: '',
    error: '',
  });
  const [isOpportunityIdCrmError, setIsOpportunityIdCrmError] = useState(false);

  const handleEnteredOpportunityId = value => {
    setEnteredOpportunityId(prevState => ({ ...prevState.enteredOpportunityId, ...value }));
  };

  useEffect(() => {
    return () => clearForms();
  }, [clearForms]);

  useEffect(() => {
    fetchInitialData(user.accountId, user);
    fetchCartDataFromLocalStorage();
  }, [fetchInitialData, fetchCartDataFromLocalStorage]);

  const language = fetchFromStorage(identifiers.language);
  
  useEffect(()=>{
    fetchInitialData(user.accountId, user);
  },[language]);

  useEffect(() => {
    if (kits && kits[0] && kits[0].kit.kitInformationId) {
      fetchKitDetails(kits[0].kit.kitInformationId, kits[0].kit.stockInformation && kits[0].kit.stockInformation.id);
      fetchTypeOfJobAssociatedWithKitSystemmainComponent(kits[0].kit.kitInformationId);
      checkShowingCheckbox(selectedKits);
    }
  }, [selectedKits]);

  const checkShowingCheckbox = async () => {    
    if (kits && kits.length) {
      const stockIds = kits.map(item => item.kit.stockInformation.id);
      const statusArr = await Promise.all(stockIds.map(async key => await handleWorkflowData(key)));
      const isCheck = statusArr.some(status => status);
      if (isCheck) {
        setIsShowCheckbox(true);
      }
    }
  };

  const handleWorkflowData = async stockId => {
    const { success, data: stockWorkflowSettings } = await fetchWorkflowData(stockId);
    const index = 1;
    const workflowId = 14;
    const hasWorkflowSettings = stockWorkflowSettings && stockWorkflowSettings[index] && 
      stockWorkflowSettings[index].stockWorkflowSettingDTO &&
      stockWorkflowSettings[index].stockWorkflowSettingDTO.length

    if (!success) return false;

    if (hasWorkflowSettings) {
      const foundItem = stockWorkflowSettings[index].stockWorkflowSettingDTO.find(
        item => item.categoryQuestionId === workflowId,
      );

      return foundItem ? foundItem.categoryAnswer === 'yes' : false;
    }
  };

  return {
    activeStep,
    isShowCheckbox,
    enteredOpportunityId,
    isOpportunityIdCrmError,
    setActiveStep,
    handleEnteredOpportunityId,
    setIsOpportunityIdCrmError,
  }
}
