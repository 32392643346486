import { connect } from 'react-redux';
 
import {SingleLoanCart} from './SingleLoanCart.component'

const mapStateToProps = ({ masterDataReducer, authReducer,state ,languageReducer}) => ({
    countries: masterDataReducer.countries,
    user: authReducer.user,
    language: languageReducer,
});

export default connect(mapStateToProps)(SingleLoanCart);