import { useState, useCallback, useEffect } from 'react';
import $ from 'jquery';
import moment from 'moment';

import strings from 'resources/locales/Translate';
import { fetchBookingsForLabsLocationKit } from 'modules/Calendar/calendar-services';

export const useLLAdjustDates = props => {
  const { transactionDetails, id, changeVisible, adjustTransactionDates } = props;
  const { targetReservationFrom, targetReservationTo, transactionKit, transactionsId, type } = transactionDetails || {};
  const [adjustDatesForm, setAdjustDatesForm] = useState({
    formData: {
      targetReservationFrom: new Date(targetReservationFrom),
      targetReservationTo: new Date(targetReservationTo),
    },
  });
  const [bookedKits, setBookedKits] = useState([]);
  const [copyBookedKitsForRest, setCopyBookedKitsForRest] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getBookedKits = useCallback(async () => {
    const startDate = targetReservationFrom;
    const { data } = await fetchBookingsForLabsLocationKit(
      { filter: { kitId: transactionKit.kitInformation.kitInformationId } },
      startDate,
    );
    const alreadyBooked = [];
    data.content[0] &&
      data.content[0].transactionsForCalendar.forEach(item => {
        alreadyBooked.push({
          id: 1,
          resourceId: 'r0',
          isBooked: true,
          isTwentyFourHourReservation: false,
          kit: { kitInformationId: 1, kitName: '' },
          start: moment(item.targetReservationFrom).format('YYYY-MM-DD'),
          title: '',
          end: `${moment(item.targetReservationTo).format('YYYY-MM-DD')} 23:59:59`,
          transactionsId: item.transactionsId,
        });
      });

    setBookedKits(alreadyBooked);
    setCopyBookedKitsForRest(alreadyBooked);
  }, [targetReservationFrom, transactionKit]);

  const setInitialDates = useCallback(() => {
    setAdjustDatesForm({
      formData: {
        targetReservationFrom: new Date(targetReservationFrom),
        targetReservationTo: new Date(targetReservationTo),
      },
    });
  }, [targetReservationFrom, targetReservationTo]);

  useEffect(() => {
    if (targetReservationFrom && targetReservationTo) {
      getBookedKits();
    }
  }, [targetReservationFrom, targetReservationTo, getBookedKits]);

  const closePopup = useCallback(() => {
    if ($(`#${id}`) && $(`#${id}`).modal) {
      $(`#${id}`).modal('hide');
    }

    setErrorMessage(null);
    changeVisible();
  }, [changeVisible, id]);

  const resetBookedKits = useCallback(() => {
    setInitialDates();
    setBookedKits(copyBookedKitsForRest);
  }, [copyBookedKitsForRest, setInitialDates]);

  const onClose = useCallback(() => {
    closePopup();
    resetBookedKits();
  }, [closePopup, resetBookedKits]);

  const updateAdjustDates = useCallback(values => {
    setAdjustDatesForm({
      ...adjustDatesForm,
      formData: {
        ...adjustDatesForm.formData,
        ...values,
      },
    });
  }, [adjustDatesForm]);

  const updateBookedKits = useCallback(
    ({ values, field }) => {
      const isTargetReservationFrom = field === 'targetReservationFrom';
      const isTargetReservationTo = field === 'targetReservationTo';

      const updatedBookedKits = bookedKits.map(item => {
        if (item.transactionsId === transactionsId) {
          return {
            ...item,
            start: isTargetReservationFrom
              ? moment(values.targetReservationFrom).format('YYYY-MM-DD')
              : moment(adjustDatesForm.formData.targetReservationFrom).format('YYYY-MM-DD'),
            end: isTargetReservationTo
              ? `${moment(values.targetReservationTo).format('YYYY-MM-DD')} 23:59:59`
              : `${moment(adjustDatesForm.formData.targetReservationTo).format('YYYY-MM-DD')} 23:59:59`,
          };
        }
        return item;
      });

      setBookedKits(updatedBookedKits);
    },
    [
      adjustDatesForm.formData.targetReservationFrom,
      adjustDatesForm.formData.targetReservationTo,
      bookedKits,
      transactionsId,
    ],
  );

  const handleForm = useCallback(
    ({ values, field }) => {
      updateAdjustDates(values);
      updateBookedKits({ values, field });
    },
    [updateAdjustDates, updateBookedKits],
  );

  const checkTypeError = useCallback((demoStart, demoEnd) => {
    if (demoStart && demoEnd && moment(demoStart).isAfter(moment(demoEnd))) {
      setErrorMessage(strings.hasToBeBefore(strings.demoStart, strings.demoEnd));

      return strings.hasToBeBefore(strings.demoStart, strings.demoEnd);
    }
    return null;
  }, []);

  const onSave = useCallback(async () => {
    const hasError = checkTypeError(adjustDatesForm.formData.targetReservationFrom, adjustDatesForm.formData.targetReservationTo);

    if (hasError) return;

    if (errorMessage) {
      setErrorMessage(null);
    }

    const dataToSend = {
      targetReservationFrom: moment(adjustDatesForm.formData.targetReservationFrom).format('YYYY-MM-DD'),
      targetReservationTo: moment(adjustDatesForm.formData.targetReservationTo).format('YYYY-MM-DD'),
    };
    const isLabsLocation = true;

    setIsLoading(true);
    const { success, error } = await adjustTransactionDates(
      dataToSend,
      transactionsId,
      type,
      null,
      isLabsLocation,
    );
    setIsLoading(false);

    if (success) {
      closePopup();
    }

    if (error) {
      const { response } = error;

      if (response && response.data && response.data.message) {
        setErrorMessage(error.response.data.message);
      }

      if (response && response.status === 422) {
        setErrorMessage(strings.youCanNotCreateOverlappingBookings);
      } else {
        setErrorMessage(strings.somethingWentWrongMessage);
      }

      setTimeout(() => {
        setErrorMessage(null);
      }, 5000);
    }
  }, [
    adjustDatesForm.formData.targetReservationFrom,
    adjustDatesForm.formData.targetReservationTo,
    adjustTransactionDates,
    closePopup,
    errorMessage,
    transactionsId,
    type,
    checkTypeError,
  ]);

  return {
    isLoading,
    adjustDatesForm,
    bookedKits,
    errorMessage,
    onClose,
    onSave,
    handleForm,
    closePopup,
    resetBookedKits,
    updateAdjustDates,
    setInitialDates,
    updateBookedKits,
  };
};
