import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';

import { deepBlueColor, limeadeColor } from 'resources/styles/variables.scss';
import strings from 'resources/locales/Translate';
import { getDateInUTCFormat } from 'library/utilities/getDateInFormat';

export const useInterfaceMonitoring = ({ fetchInterfaceMonitoring, interfaceMonitoring }) => {
  const [isShowFilter, setIsShowFilter] = useState(true);
  const [data, setData] = useState({
    page: 1,
    size: 10,
    sort: {
      createdDate: '',
      createdTime: '',
    },
    filters: {
      searchText: '',
      interfaceType: null,
    },
  });

  useEffect(() => {
    fetchInterfaceMonitoring({
      searchText: data.filters.searchText,
      interfaceType: data.filters.interfaceType,
      sortField: (data.sort.createdDate && 'createdDate') ||  (data.sort.createdTime && 'createdTime'),
      sortDirection: data.sort.createdDate || data.sort.createdTime,
      page: data.page,
      size: data.size,
    });
  }, [fetchInterfaceMonitoring, data]);

  const toggleFilterVisibility = () => {
    setIsShowFilter(prevState => !prevState);
  };

  const handleSearchChange = e => {
    const { value } = e.target;
    debounceUpdateData(value);
  };

  const debounceUpdateData = debounce((value) => {
    setData(
      prevState => {
        const newData = { ...prevState };
        newData.page = 1;
        newData.filters.searchText = value;
        return { ...newData };
      });
  }, 1000);

  const handleDropdownChange = (value, field, fullValue) => {
    setData(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [field]: value,
        [`${field}FullValue`]: fullValue,
      },
    }));
  };

  const handleSort = name => {
    setData(prevState => {
      const newData = cloneDeep(prevState);
      Object.keys(newData.sort).forEach(key => (newData.sort[key] = ''));
      newData.sort[name] = prevState.sort[name] ? (prevState.sort[name] === 'asc' ? 'desc' : '') : 'asc';

      return newData;
    });
  };

  const handlePageChange = page => {
    setData(prevState => ({
      ...prevState,
      page: page + 1,
    }));
  };

  const greenColurId =['CRM', 'SAP'];

  const getTableCols = () => [
    {
      title: strings.endSystem,
      name: 'endSystem',
      isSort: false,
      render: item => {
        return <div>{item.interfaceType}</div>;
      },
    },
    {
      title: strings.status,
      name: 'status',
      isSort: false,
      render: item => {
        return (
          <div className='d-flex align-items-center'>
            <div className={cn('mr-2', { 'green-round': item.status === 'Success', 'red-round': item.status === 'Error' })} />
            <div>{item.status}</div>
          </div>
        );
      },
    },
    {
      title: strings.statusCode,
      name: 'statusCode',
      isSort: false,
      render: item => {
        return <div>{item.httpStatus}</div>;
      },
    },
    {
      title: strings.message,
      name: 'message',
      isSort: false,
      render: item => {
        return <div>{item.message}</div>;
      },
    },
    {
      title: (
        <span>
          <span style={{ color: limeadeColor }}>{strings.transactionId}</span> / <span style={{ color: deepBlueColor }}>{strings.kitId}</span>
        </span>
      ),
      name: 'transactionId',
      isSort: false,
      render: item => {
        return (
          <div style={{ color: item.numberType === "TRANSACTION" ? limeadeColor : item.numberType === "KIT" ? deepBlueColor : item.interfaceType === "CRM" &&  limeadeColor }}>
            {item.transactionsOrKitNumber}
          </div>
        );
      },
    },
    {
      title: strings.transactionName,
      name: 'transactionName',
      isSort: false,
      render: item => {
        return <div>{item.transactionOrKitName}</div>;
      },
    },
    {
      title: strings.date,
      name: 'createdDate',
      isSort: true,
      render: item => {
        return <div>{item.createdDate ? getDateInUTCFormat('DD.MM.YYYY', item.createdDate) : ''}</div>;
      },
    },
    {
      title: strings.time,
      name: 'createdTime',
      isSort: true,
      render: item => {
        return <div>{item.createdTime} UTC</div>;
      },
    },
    {
      title: strings.esbCorrelationId,
      name: 'sapCorrelationId',
      isSort: false,
      render: item => {
        return <div>{item.sapCorrelationId ? item.sapCorrelationId : ''}</div>;
      },
    },
  ];

  const tableCols = getTableCols();
  const hasInterfaceMonitoring = interfaceMonitoring && interfaceMonitoring.content && interfaceMonitoring.content.length > 0

  return {
    data,
    toggleFilterVisibility,
    handleDropdownChange,
    handleSort,
    handlePageChange,
    handleSearchChange,
    isShowFilter,
    tableCols,
    hasInterfaceMonitoring,
  };
}
