import { useState, useEffect } from 'react';
import $ from 'jquery';

export const useDocuments = ({
  uploadDocuments,
  history,
  removeFile,
  addFile,
  resetDocumentsAndFolders,
  fetchdDocumentsAndFolders,
}) => {
  const [viewType, setViewType] = useState('list');
  const [foldersNavigation, setFoldersNavigation] = useState([
    { id: 0, name: 'root' },
  ]);
  const [folder, setFolder] = useState({});

  useEffect(() => {
    document.title = 'Documents';

    const getInitialData = async () => {
      await fetchdDocumentsAndFolders(foldersNavigation[foldersNavigation.length - 1].id);
    }

    getInitialData();

    return () => resetDocumentsAndFolders();
  }, [foldersNavigation, resetDocumentsAndFolders, fetchdDocumentsAndFolders]);

  const toggleViewType = (viewType) => {
    setViewType(viewType);
  }

  const handleSave = () => {
    uploadDocuments(foldersNavigation[foldersNavigation.length - 1].id);
  };

  const cancelFileUploading = () => {
    history.replace('/home');
  };

  const openCreateFolderPopup = () => {
    const popup = $('#create-folder-popup');
    if (popup && popup.modal) {
      popup.modal('show');
    }
  };

  const handleRemoveFile = index => {
    removeFile(index, foldersNavigation[foldersNavigation.length - 1].id);
  };

  const handleAddFile = file => {
    addFile(file, foldersNavigation[foldersNavigation.length - 1].id);
  };

  const openEditFolderPopup = () => {
    const popup = $('#edit-folder-popup');
    if (popup && popup.modal) {
      popup.modal('show');
    }
  };

  return {
    viewType,
    folder,
    foldersNavigation,
    setFolder,
    setFoldersNavigation,
    toggleViewType,
    handleSave,
    cancelFileUploading,
    openCreateFolderPopup,
    handleRemoveFile,
    handleAddFile,
    openEditFolderPopup,
  };
}
