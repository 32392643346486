import React from 'react';
import PropTypes from 'prop-types';
import Button from 'library/common/commonComponents/Button/Button';
import Icon from 'library/common/commonComponents/Icon/Icon';
import strings from 'resources/locales/Translate';
import './modalStyle.scss';

export const Modal = ({
  id,
  children,
  className,
  confirmAction,
  confirmDataTest,
  confirmIcon,
  confirmTitle,
  titleOfCancel,
  dataTest,
  disableBackgroundClose,
  loading,
  onCloseClick,
  title,
  disabledButton,
  buttonStyles,
  isGhost,
  bgFill = true,
  closeIcon = 'cross',
  newVersionUi,
}) => (
  <div
    className='modal fade modal-container'
    id={id}
    tabIndex='-1'
    role='dialog'
    aria-labelledby={id}
    aria-hidden='true'
    data-test={dataTest}
  >
    <div className='modal-background' onClick={!disableBackgroundClose && onCloseClick ? onCloseClick : null} />
    <div className={`modal-dialog${className ? ` ${className}` : ''}`} role='document'>
      <div className={`modal-content rounded-0 ${className ? ` ${className}` : ''}`}>
        <div className='modal-body p-0'>
          <div className='content' id='app-scheduler-wrapper'>
            <button
              type='button'
              className='close modal-close position-absolute'
              data-dismiss='modal'
              aria-label='Close'
              onClick={onCloseClick}
              data-test={`${id}-close-button`}
            >
              <Icon name='cross' width={24} height={24} />
            </button>
            <h3 className='modal-title mr-2'>{title}</h3>

            {children}
            {!newVersionUi && <div className='row mt-3'>
              <div className='col-md-4'>
                <Button
                  data-test='modal-close-button'
                  iconName={closeIcon}
                  bgFill={false}
                  value={titleOfCancel}
                  onClick={onCloseClick}
                  styleClass={buttonStyles}
                />
              </div>
              <div className='col-md-8'>
                <Button
                  dataTest={confirmDataTest}
                  loading={loading}
                  iconName={confirmIcon}
                  value={confirmTitle}
                  onClick={confirmAction}
                  disabled={disabledButton}
                  styleClass={buttonStyles}
                  isGhost={isGhost}
                  bgFill={bgFill}
                />
              </div>
            </div>}
            {newVersionUi && <div className='row mt-3'>
              <div className='col-md-6'>
                <Button
                  data-test='modal-close-button'
                  bgFill={false}
                  value={titleOfCancel}
                  onClick={onCloseClick}
                  styleClass={buttonStyles}
                />
              </div>
              <div className='col-md-6'>
                <Button
                  dataTest={confirmDataTest}
                  loading={loading}
                  value={confirmTitle}
                  onClick={confirmAction}
                  disabled={disabledButton}
                  styleClass={buttonStyles}
                  isGhost={isGhost}
                  bgFill={bgFill}
                />
              </div>
            </div>}
          </div>
        </div>
      </div>
    </div>
  </div>
);

Modal.defaultProps = {
  confirmDataTest: '',
  confirmIcon: 'save',
  confirmTitle: strings.save,
  titleOfCancel: strings.cancel,
  confirmAction: () => {},
  dataTest: 'modal-container',
  loading: false,
  closeIcon: 'cross',
  newVersionUi: false,
};

Modal.propTypes = {
  confirmDataTest: PropTypes.string,
  confirmIcon: PropTypes.string,
  confirmTitle: PropTypes.string,
  titleOfCancel: PropTypes.string,
  confirmAction: PropTypes.func,
};

export default Modal;
