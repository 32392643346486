import React, { useCallback } from 'react';
import cn from 'classnames';

import strings from 'resources/locales/Translate';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import FilterTags from '../FilterTags/FilterTags.component';

const LLFilters = props => {
  const { selected, isShowFilter, countries, categoriesAndSystems, transactionStatuses, handleFilterChange, resetFilters } = props;
  const llFiltersModel = {
    stock: {displayValue: [{key: 'locationName', separator: ''}], multiple: true},
    systemClass: {displayValue: [{ key: 'systemMainComponentName', separator: '' }], multiple: true},
    transactionStatuses: {displayValue: [{ key: 'name', separator: '' }], multiple: true }
  }

  const handleClearFilter = useCallback((fieldName) => {
    handleFilterChange(null, fieldName, null);
  }, [selected])

  const handleClearAllFilters = useCallback(() => {
    resetFilters();
  }, [selected])

  return (
    <>
      <div className={cn('container-fluid', { 'd-block': isShowFilter, 'd-none': !isShowFilter })}>
        <div className='new-filter-body'>
          <div className='row'>
            <Dropdown
              label={strings.partnerSite}
              data={countries}
              selectAllOption
              selectAllPlaceholder={strings.selectAll}
              idKey='id'
              field='stock'
              displayValue={[{ key: 'locationName', separator: '' }]}
              optionsArrayKey='stockInformation'
              titleDisplay={[
                { key: 'shortName', separator: ' ' },
                { key: 'name', separator: '' },
              ]}
              hasSection={true}
              multiSelect={true}
              filter={true}
              placeholder={strings.pleaseSelect + '...'}
              onChange={handleFilterChange}
              mainContainerStyle='col-lg-4 mb-3'
              value={selected.stock}
              fullValue={selected.stockFullValue}
              newDropdownStyle={true}
            />
            <Dropdown
              data={categoriesAndSystems}
              hasSection={true}
              multiSelect={true}
              filter={true}
              clearMultiOption={true}
              idKey='systemMainComponentId'
              childArrayKey='systemMainComponent'
              displayValue={[{ key: 'systemMainComponentName', separator: '' }]}
              optionsArrayKey='systemMainComponent'
              titleDisplay={[{ key: 'systemClassName', separator: '' }]}
              placeholder={strings.pleaseSelect + '...'}
              label={strings.categorySystem}
              field='systemClass'
              onChange={handleFilterChange}
              mainContainerStyle='col-lg-4 mb-3'
              value={selected.systemClass}
              fullValue={selected.systemClassFullValue}
              selectAllOption
              newDropdownStyle={true}
            />
            <Dropdown
              data={transactionStatuses}
              hasSection={false}
              multiSelect={true}
              filter={false}
              clearMultiOption={true}
              idKey='transactionStatusId'
              displayValue={[{ key: 'name', separator: '' }]}
              placeholder={strings.pleaseSelect + '...'}
              label={strings.transactionStatus}
              field='transactionStatuses'
              onChange={handleFilterChange}
              mainContainerStyle='col-lg-4 mb-3'
              value={selected.transactionStatuses}
              fullValue={selected.transactionStatusesFullValue}
              selectAllOption
              newDropdownStyle={true}
            />
          </div>
        </div>
      </div>
      <FilterTags filters={selected} model={llFiltersModel} onClear={handleClearFilter} onClearAll={handleClearAllFilters} />
    </>
  );
};

export default LLFilters;
