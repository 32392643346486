import React from 'react';
import { NavLink } from 'react-router-dom';
import get from 'lodash/get';

import strings from 'resources/locales/Translate';
import SmallButton from 'library/common/commonComponents/SmallButton';

import { useDataExpandableTable } from './useDataExpandableTable';
import { makeTwoDigits } from 'library/utilities/makeTwoDigits';

export const DataExpandableTable = props => {
  const {
    row,
    user,
    handleClickOnStopObserve,
    handleClickOnObserve,
    handleClickOnReserveKit,
    handleClickOnCancelReservation,
    openOrderPopup,
    isArchive,
  } = props;
  const {
    kitCondition,
    kitReservation,
    kitInformationId,
    stockInformation,
    kitSingleItem,
    singleItemParent,
    isObserve,
    observationCount,
    marketPlaceKitViewCountForList, observationCountForList
  } = row || {};

  const {
    isObservationLoading,
    isReservationLoading,
    handleRemoveFromObservationList,
    handleAddToObservationList,
    handleReserveKit,
    handleCancelReserveKit,
    handleClickOnOrder,
    checkUserRole,
    isReserved,
    isOrdered,
  } = useDataExpandableTable({
    kitInformationId,
    handleClickOnStopObserve,
    handleClickOnObserve,
    handleClickOnReserveKit,
    handleClickOnCancelReservation,
    openOrderPopup,
    user,
    stockInformation,
    kitCondition,
  });

  const rolesForEditing = ['Stock Controller', 'Shop Manager'];
  const editingKitURL = kitSingleItem
    ? `/edit-single-item/${kitInformationId}`
    : `/edit-manually-kit/${kitInformationId}`;
  const detailsKitURL = kitSingleItem
    ? `/single-item-details/${kitInformationId}`
    : `/kit-manually-details/${kitInformationId}`;
  const isReservedByCurrentUser = user.userId === get(kitReservation, 'createdBy.id');
  const isEditable = user.admin || checkUserRole(rolesForEditing);
  const isHavePermissionForOrder = user.admin || !isReserved || (isReserved && isReservedByCurrentUser);
  const isHavePermissionForReservation =
    user.admin || !isReserved || (isReserved && isReservedByCurrentUser) || checkUserRole('Shop Manager');
  const isCanNotReserveSingleItem = kitSingleItem && !singleItemParent && !isReserved;

  return (
    <>
      <td />
      <td />
      <td colSpan='11' data-test='shop-expandable-table'>
        <div className='d-flex my-2'>
          <NavLink to={detailsKitURL} exact={true}>
            <SmallButton iconName='visibility' value={strings.showDetails} />
          </NavLink>
          <>
            {isEditable && (
              <div className='ml-2'>
                <NavLink
                  to={{
                    pathname: editingKitURL,
                    state: { edit: true },
                  }}
                >
                  <SmallButton bgFill={false} iconName='edit' value={strings.edit} />
                </NavLink>
              </div>
            )}
            {!isArchive && (
              <>
                <div className='ml-2'>
                  <SmallButton
                    bgFill={true}
                    value={strings.order}
                    iconName='arrow'
                    onClick={handleClickOnOrder}
                    disabled={isReservationLoading || isObservationLoading || !isHavePermissionForOrder || isOrdered}
                  />
                </div>
                <div className='ml-2'>
                  <SmallButton
                    bgFill={false}
                    value={isReserved ? strings.cancelReservation : strings.reserveKitForSale}
                    iconName='bookmark'
                    viewBox='0 0 431.972 431.972'
                    size='14'
                    onClick={isReserved ? handleCancelReserveKit : handleReserveKit}
                    isLoading={isReservationLoading}
                    disabled={
                      isObservationLoading || !isHavePermissionForReservation || isOrdered || isCanNotReserveSingleItem
                    }
                  />
                </div>
                <div className='ml-2'>
                  <SmallButton
                    bgFill={false}
                    value={isObserve ? strings.stopObservation : strings.observe}
                    iconName='star'
                    viewBox='0 0 512.002 512.002'
                    size='14'
                    onClick={isObserve ? handleRemoveFromObservationList : handleAddToObservationList}
                    isLoading={isObservationLoading}
                    disabled={isReservationLoading}
                  />
                </div>
                <div className='ml-2'>
                  <SmallButton
                    bgFill={false}
                    value={observationCountForList}
                    iconName='star'
                    viewBox='0 0 512.002 512.002'
                    size='14'
                    className='views'
                  />
                </div>
                <div className='ml-2'>
                  <SmallButton
                    bgFill={false}
                    value={marketPlaceKitViewCountForList}
                    iconName='visibility'
                    size='14'
                    className='views'
                  />
                </div>
              </>
            )}
          </>
        </div>
      </td>
    </>
  );
};

DataExpandableTable.defaultProps = {
  handleClickOnReserveKit: () => {},
  handleClickOnCancelReservation: () => {},
  openOrderPopup: () => {},
};

export default DataExpandableTable;
