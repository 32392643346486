import { GET_SAP_ERP_MAPPING_LIST } from "./SapErpMapping.constants";

const initialState = {
    mappingList: [],
};

const SapErpMappingReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SAP_ERP_MAPPING_LIST:
            return {
                ...state,
                mappingList: action.payLoad,
            };
        default: return state;
    }
};

export default SapErpMappingReducer;