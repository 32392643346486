import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import Modal from 'library/common/commonComponents/Modal';
import React from 'react';
import strings from 'resources/locales/Translate';
import { useAddNewAddress } from './AddNewAddressPopup.hook';

export const AddNewAddressPopup = ({ id, addNewAddress, stockId }) => {
  const { model, handleForm, addAddressForm, onCloseClick, onSaveClick, error, loading } = useAddNewAddress({
    id,
    addNewAddress,
    stockId,
  });
  return (
    <Modal
      id={id}
      confirmAction={onSaveClick}
      title={strings.addNewAddress}
      loading={loading}
      disableBackgroundClose
      onCloseClick={onCloseClick}
    >
      <FormComponent
        model={model}
        formName='AddNewAddressForm'
        formValue={addAddressForm.formData}
        onChange={handleForm}
      />
      {error && <div className='error-block'>{error}</div>}
    </Modal>
  );
};

export default AddNewAddressPopup;
