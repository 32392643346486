import React, { useState } from 'react';

import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import AddressInfo from 'library/common/commonComponents/AddressInfo/AddressInfo';
import Button from 'library/common/commonComponents/Button/Button';
import { getSelectedCountry } from 'library/utilities/getSelectedValues';

import SelectedKits from '../../../KitLoan/Components/SelectedKits';
import { useLLKitLoanModel } from '../../LLKitLoanModel.hook';

const CustomerData = props => {
  const {
    borrowerForm,
    typeOfDeliveryAddressForm,
    selectedKits,
    updateDateOfCartItem,
    deliveryAddressForm,
    countries,
  } = props;
  const {
    requestorModel,
    typeDeliveryAddressModel,
    customerSiteModel,
    handleForm,
    gotToConfirmationStep,
    goToPrevStep,
    goToCalendar,
  } = useLLKitLoanModel({ ...props });
  const [isEdit, setIsEdit] = useState(false);
  const deliveryAddressCountry = deliveryAddressForm.formData.country && deliveryAddressForm.formData.country.name;
  const borrowerAddressCountry = borrowerForm.formData.userAddress && borrowerForm.formData.userAddress.countryId
    ? getSelectedCountry(borrowerForm.formData.userAddress.countryId, countries)
    : '';
    if (!typeOfDeliveryAddressForm.formData || !typeOfDeliveryAddressForm.formData.deliveryAddress) {
      typeOfDeliveryAddressForm.formData = {};
      typeOfDeliveryAddressForm.formData.deliveryAddress = 'customerSite';
      let obj = {};
      obj.values = typeOfDeliveryAddressForm.formData;
      obj.formName = 'typeOfDeliveryAddressForm';
      handleForm(obj);
    }

  return (
    <>
      <h2 className='title-loan mb-4'>{strings.customerData}</h2>
      <div className='row'>
        <div className='col-12 col-md-6'>
          <div className='mb-3'>
            <h4 className='subtitle-loan mb-3'>{strings.requestor}</h4>
              <FormComponent
                formName='borrowerForm'
                model={requestorModel}
                formValue={borrowerForm.formData}
                onChange={handleForm}
              />
              {borrowerForm.formData.userAddress && (
                <AddressInfo
                  formData={borrowerForm.formData.userAddress}
                  addressCountry={borrowerAddressCountry}
                  flag='UserAddress'
                  isEditable={false}
                />
              )}
          </div>

          <div className='mb-3'>
            <h4 className='subtitle-loan mb-3'>{strings.partnerSite}</h4>
            <FormComponent
              formName='typeOfDeliveryAddressForm'
              model={typeDeliveryAddressModel}
              formValue={typeOfDeliveryAddressForm.formData}
              onChange={handleForm}
            />
            {(isEdit || (typeOfDeliveryAddressForm.formData.deliveryAddress === 'new')) && (
              <FormComponent
                formName='deliveryAddressForm'
                model={customerSiteModel}
                formValue={deliveryAddressForm.formData}
                onChange={handleForm}
              />
            )}
            {!isEdit && (typeOfDeliveryAddressForm.formData.deliveryAddress === 'customerSite') && (
              <AddressInfo
                formData={deliveryAddressForm.formData}
                changeIsEditTypeFlag={() => setIsEdit(true)}
                addressCountry={deliveryAddressCountry}
                flag='TypeOfDeliveryAddress'
              />
            )}
          </div>
        </div>
        <div className='col-12 col-md-6'>
          <SelectedKits
            selectedKits={selectedKits}
            goToCalendar={goToCalendar}
            handleDateChange={updateDateOfCartItem}
          />
        </div>
        <div className='col-md-6'>
          <div className='d-flex justify-content-between'>
            <Button
              bgFill={false}
              isGhost={true}
              iconName='arrow'
              iconPosition='left'
              iconStyle='arrow-left'
              styleClass='w-auto'
              value={strings.backToAssignment}
              onClick={goToPrevStep}
            />
            <Button
              data-test='go-to-next-step-button'
              bgFill={true}
              iconName='arrow'
              styleClass='w-auto'
              value={strings.continueBooking}
              onClick={gotToConfirmationStep}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomerData;
