import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { clearLoanAttachmentFile } from 'library/common/commonActions/LoanAttachmentsActions';
import { closeAlternateDateSuggestionMessage, fetchCartDataFromLocalStorage } from 'modules/LoanList/LoanListActions';
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';
import { fetchWorkflowData } from 'modules/Stock/CreateStock/Tabs/Workflows/Workflows.actions';
import { fetchAdaptedLoanReasonForStock } from './KitLoanActions';
import { updateDateOfCartItem } from '../LoanList/LoanListActions';
import { getProvincesOfCountry, fetchStateHasSpecialDeliveryTime } from 'modules/Stock/CreateStock/Tabs/DeliveryTimes/DeliveryTimes.actions';
import {
  createKitLoan,
  createCourseLoan,
  createSingleLoan,
  checkAvailabilityOfKit,
  fetchDataForKitLoan,
  fetchBorrowerAddresses,
  fetchAdditionalLoanRequest,
  fetchValuesForRefurbishmentFess,
  getSearchedAddress
} from './KitLoanActions';
import KitLoan from './KitLoan';
import { getAllActiveProvinceCountries } from 'modules/Stock/CreateStock/CreateStock.actions';

const mapStateToProps = state => {
  return {
    language: state.languageReducer,
    stocks: state.masterDataReducer.countries,
    masterData: state.masterDataReducer,
    cart: state.loanList.cart.filter(item => item.isLabsLocation === false),
    appActions: state.appActionsReducer,
    alternateMessageData: state.loanList.alternateMessageData,
    user: state.authReducer.user,
    expectedLoanFees: state.kitLoanReducer.expectedLoanFees
  };
};

export default withRouter(
  connect(mapStateToProps, {
    fetchDataForKitLoan,
    clearLoanAttachmentFile,
    fetchBorrowerAddresses,
    fetchCartDataFromLocalStorage,
    fetchAdditionalLoanRequest,
    closeAlternateDateSuggestionMessage,
    toggleActionMessage,
    fetchMasterData,
    updateDateOfCartItem,
    checkAvailabilityOfKit,
    createKitLoan,
    createCourseLoan,
    createSingleLoan,
    fetchWorkflowData,
    fetchValuesForRefurbishmentFess,
    getSearchedAddress,
    fetchAdaptedLoanReasonForStock,
    getProvincesOfCountry,
    fetchStateHasSpecialDeliveryTime,
    getAllActiveProvinceCountries
  })(KitLoan),
);
