import React from 'react';
import cn from 'classnames';

import strings from 'resources/locales/Translate';
import Icon from 'library/common/commonComponents/Icon/Icon';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';

import { useOpportunityForm } from './useOpportunityForm';
import './opportunityForm.styles.scss';

export default function OpportunityForm({
  setOpportunity,
  removeOpportunity,
  updateOpportunities,
  opportunities,
  isEditableField,
  isRequired,
  setIsOpportunityIdCrmError,
  isOpportunityIdCrmError,
  state,
  handleEnteredOpportunityId,
  isBusinessUnitMed,
  usCountryStock,
  user
}) {
  const {
    handleInput,
    handleSetOpportunity,
    handleRemoveOpportunity,
    handleUpdateOpportunities,
  } = useOpportunityForm({
    setOpportunity,
    removeOpportunity,
    updateOpportunities,
    opportunities,
    setIsOpportunityIdCrmError,
    state,
    handleEnteredOpportunityId,
    isBusinessUnitMed,
    usCountryStock,
    user
  });
  const disabled = opportunities.length >= 10;

  return (
    <div className='mb-3'>
      <div className='form opportunity-container'>
        <div className='row mb-3'>
          <div className='col'>
            <InputField
              disabled={opportunities.length >= 10}
              label={strings.opportunityIdCRM}
              onChange={handleInput}
              value={state.value}
              validators={[]}
              isRequired={isRequired}
              error={state.error || (isOpportunityIdCrmError && 'pleaseMindThat')}
            />
          </div>
          <div className='col-auto'>
            <button
              type='button'
              className={`add-button ${disabled ? 'disabled-button' : ''}`}
              onClick={handleSetOpportunity}
              disabled={disabled}
            >
              <Icon name='plus' width={24} height={24} fill={disabled ? '#8c92ac' : '#0088d0'} />
            </button>
          </div>
        </div>
        {opportunities && opportunities.length > 0 && (
          <div>
            {opportunities.map((item, key) => (
              <div
                key={key}
                className={cn('d-flex justify-content-between align-items-center', {'opportunity-list-item': !isEditableField, 'row mb-3': isEditableField})}
              >
                {isEditableField ? (
                  <div className='col'>
                    <InputField
                      isRequired
                      onChange={(value) => handleUpdateOpportunities(value, key)}
                      value={opportunities[key].opportunityNumber}
                      validators={[]}
                      error={opportunities[key].error}
                    />
                  </div>
                ) : (
                  <p>{item.opportunityNumber}</p>
                )}
                <div className={cn({'col-auto d-flex align-items-center': isEditableField})}>
                  <button
                    type='button'
                    className='delete-button'
                    onClick={() => handleRemoveOpportunity(key)}
                  >
                    <Icon name='delete' width={24} height={24} fill='#0088d0' />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
