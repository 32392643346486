import { logNetworkError } from 'library/utilities/logError';
import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { deleteMasterDataService, fetchMasterDataService, fetchMasterDataServicePost, fetchMasterDataServicePut } from 'library/api/master-data.service';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { scrollToTop } from 'library/utilities/scrollActions';

import { createStockWorkflowService } from '../../CreateStock.services';
import { AVAILABILITY_SLOTS_LIST } from '../../CreateStock.constants';
import { adaptLoanReaonsForMED, adaptedLoanReasonsModel } from './workflows.constants';
import strings from 'resources/locales/Translate';
import { WORKFLOW_CATEGORY_QUESTION } from './workflows.constants';

export const fetchWorkflowData = stockId => dispatch => {
  const url = URLS.stockWorkflow;
  return fetchMasterDataService(url.replace('{id}', stockId))
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({type: WORKFLOW_CATEGORY_QUESTION, payload: data})
        return { success: true, data };
      }
      return { success: false };
    })
    .catch(err => {
      logNetworkError(err);
      dispatch({type: WORKFLOW_CATEGORY_QUESTION, payload: {}})
      return { success: false };
    });
};

export const fetchReasonForLoan = () => dispatch =>{
  const url = URLS.reasonForLoans;
  return fetchMasterDataService(url)
    .then(({ status, data }) => {
      if (status === 200) {
        return { success: true, data };
      }
      return { success: false };
    })
    .catch(err => {
      logNetworkError(err);
      return { success: false };
    });
}

export const getLoanReaonsBasedOnStock = (stockId) => async dispatch => {
  const apiUrl = `${URLS.stockDetails}${stockId}`
  try{
    dispatch(changeLoader(true))
    const resp = await fetchMasterDataService(apiUrl);
    if(resp?.status === 200 && resp?.data){
      const {businessUnit, country} = resp.data;

      if( businessUnit && businessUnit?.designation === "MED" && country && country?.shortName === "US" ){
        return {success: true, adaptedLoanReasonsModel: adaptLoanReaonsForMED }
      }else{
        return {success: true, adaptedLoanReasonsModel: adaptedLoanReasonsModel}
      }
    }
  }catch(err){
    logNetworkError(err);
    return { success: false,  adaptedLoanReasonsModel: []};
  }finally{
    dispatch(changeLoader(false))
  }
}

export const fetchCurrency = () => dispatch =>{
  return fetchMasterDataService(URLS.sellOffCurrency)
    .then(({ status, data }) => {
      if (status === 200) {
        return { success: true, data };
      }
      return { success: false };
    })
    .catch(err => {
      logNetworkError(err);
      return { success: false };
    });
  
}

export const createStockWorkflow = (data, stockId, isRMSLL) => dispatch => {
  dispatch(changeLoader(true));

  return createStockWorkflowService(data, stockId)
    .then(res => {
      if (res.status === 200) {
        const message = isRMSLL ? 'mindToChange' : 'stockInformationSavedSuccessfully';
        dispatch(toggleActionMessage(true, 'success', message));
      }
      dispatch(changeLoader(false));
    })
    .catch(err => {
      logNetworkError(err);
      dispatch(changeLoader(false));
      if (err.response && err.response.status === 500) {
        dispatch(toggleActionMessage(true, 'error', 'errorOccuredText'));
      }
      if (err.response && err.response?.data?.status === 422) {
        dispatch(toggleActionMessage(true, 'error', err.response?.data?.message || 'errorOccuredText', true ));
      }
      if (err && err.response && err.response.status && err.response.status === 404) {
        dispatch(toggleActionMessage(true, 'error', err.response.data.message, true));
      }
    });
};

export const distributionChannelList = () => dispatch =>{
  return fetchMasterDataService(URLS.distributionChannel)
    .then(({ status, data }) => {
      if (status === 200) {
        return { success: true, data };
      }
      return { success: false };
    })
    .catch(err => {
      logNetworkError(err);
      return { success: false };
    });
  
}
export const billingsList = () => dispatch =>{
  return fetchMasterDataService(URLS.billings)
    .then(({ status, data }) => {
      if (status === 200) {
        return { success: true, data };
      }
      return { success: false };
    })
    .catch(err => {
      logNetworkError(err);
      return { success: false };
    });
  
}

export const orderTypesList = () => dispatch =>{
  return fetchMasterDataService(URLS.orderType)
    .then(({ status, data }) => {
      if (status === 200) {
        return { success: true, data };
      }
      return { success: false };
    })
    .catch(err => {
      logNetworkError(err);
      return { success: false };
    });
  
}

export const saveCreateAvailabilty = (dataToSend) => async dispatch =>{
  try {
    dispatch(changeLoader(true));
    const {data} = await fetchMasterDataServicePost(URLS.createAvailability(dataToSend.warehouseId), dataToSend);
    dispatch(getSystemAvailabiltySoltsList(dataToSend.warehouseId));
    dispatch(changeLoader(false));
    if(data && data.message){
      dispatch(toggleActionMessage(true, 'success', data.message, true));
    }
    return { success: true };
  } catch (error) {
    if (error?.response?.data?.status === 500) {
      dispatch(toggleActionMessage(true, 'error', error.response.data.message, true));
    } else{
      dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    }
    scrollToTop(500);
    dispatch(changeLoader(false));
    return { success: false };
  }
}

export const getSystemAvailabiltySoltsList = (warehouseId) => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.getAvailabilityList(warehouseId));
    dispatch({
      type: AVAILABILITY_SLOTS_LIST,
      payload: data,
    });
    return { success: true };
  } catch (error) {
    dispatch({
      type: AVAILABILITY_SLOTS_LIST,
      payload: [],
    });
    return { success: false };
  }
}

export const editAvailabiltySlot = (dataToSend, zmccWarehouseSlotId, isPopupFromKit) => async dispatch =>{
  try {
    dispatch(changeLoader(true));
    const {data} = await fetchMasterDataServicePut(URLS.editAvdailabilitySlot(zmccWarehouseSlotId), dataToSend);
    dispatch(getSystemAvailabiltySoltsList(dataToSend.warehouseId));
    dispatch(changeLoader(false));
    if (isPopupFromKit && dataToSend.kitIds.length > 1) {
      dispatch(toggleActionMessage(true, 'info', strings.messageForKitSlotEdit));
    }
    else{
      dispatch(toggleActionMessage(true, 'success', data?.message, true));
    }
    return { success: true };
  } catch (error) {
    dispatch(changeLoader(false));
    if (error?.response?.data?.status === 500) {
      dispatch(toggleActionMessage(true, 'error', error.response.data.message, true));
    }else{
      dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    }
    scrollToTop(500);
    return { success: false };
  }
}

export const deleteAvailabiltySlot = (zmccWarehouseSlotId, warehouseId) => async dispatch =>{
  try {
    dispatch(changeLoader(true));
    await deleteMasterDataService(URLS.deleteAvailabiltySlot(zmccWarehouseSlotId));
    dispatch(getSystemAvailabiltySoltsList(warehouseId));
    dispatch(changeLoader(false));
    dispatch(toggleActionMessage(true, 'success', 'deletdSucessFully'));
    return { success: true };
  } catch (error) {
    dispatch(changeLoader(false));
    dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    return { success: false };
  }
}


