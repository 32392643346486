import React, { useState, useCallback, useEffect } from 'react';
import $ from 'jquery';
import strings from 'resources/locales/Translate';
import cloneDeep from 'lodash/cloneDeep';
import { regex } from 'library/common/commonConstants/RegexConstants';
export const useEditMaterialNumberAndDescriptionPopup = props => {
  const {
    transactionMaterialNumber,
    saveMaterialNumberAndDescription,
    transactionDetails,
    id,
    transactionsType,
    detailPage,
    editMaterialNumberPermission,
  } = props;
  const [dispaly, setDisplay] = useState(false);
  const [transactionMaterials, setTransactionMaterialNumber] = useState(transactionMaterialNumber);
  const [newTransactionMaterialValue, setNewTransactionMaterialValue] = useState({
    materialNumber: '',
    description: '',
    transactionMaterialNumberId: null,
    sapReturnable: false,
    quantity: 1,
    checked: false,
    sold: false
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const dispalyInput = () => {
    setDisplay(true);
  };
  const addTransactionMaterialNumber = useCallback(
    (materialNumber, description) => {
      if (newTransactionMaterialValue.materialNumber === '') {
        setError(strings.materialnumberWarning);
        return;
      }
      if (regex.materailNumber.test(newTransactionMaterialValue.materialNumber) === false) {
        setError(strings.materialNumberFormatError);
        return;
      }
      if (newTransactionMaterialValue.quantity === '') {
        setError(strings.qunatityFieldIsRequired);
        return;
      }
      if (regex.numberOrDecimal.test(newTransactionMaterialValue.quantity) === false) {
        setError(strings.invalidNumberError);
        return;
      }
      let tempvalues = [...transactionMaterials, newTransactionMaterialValue];

      setTransactionMaterialNumber(tempvalues);
      setError('');
      setDisplay(false);
      setNewTransactionMaterialValue({ materialNumber: '', description: '', quantity: 1 });
    },
    [transactionMaterials, newTransactionMaterialValue],
  );
  const handleOnChangeExstingValue = useCallback(
    (e, index, type) => {
      const newListOfChangedValues = cloneDeep(transactionMaterials);
      let tempvalues = [...newListOfChangedValues];
      if (type === 'materialNumber') {
        tempvalues[index].materialNumber = e;
      } else if (type === 'returned') {
        tempvalues[index].sapReturnable = e;
      } else if (type === 'decription') {
        tempvalues[index].description = e;
      } else if (type === 'quantity') {
        tempvalues[index].quantity = e;
      } else if (type === 'checked') {
        tempvalues[index].checked = e;
      } else if (type === 'sold') {
        tempvalues[index].sold = e;
      }

      setTransactionMaterialNumber(tempvalues);
    },

    [transactionMaterials],
  );

  const handleOnChange = useCallback(
    (e, value) => {
      if (value === 'materialNumber') {
        setNewTransactionMaterialValue({
          ...newTransactionMaterialValue,
          materialNumber: e,
        });
      } else if (value === 'returned') {
        setNewTransactionMaterialValue({
          ...newTransactionMaterialValue,
          sapReturnable: e,
        });
      } else if (value === 'quantity') {
        setNewTransactionMaterialValue({
          ...newTransactionMaterialValue,
          quantity: e,
        });
      } else if (value === 'checked') {
        setNewTransactionMaterialValue({
          ...newTransactionMaterialValue,
          checked: e,
        });
      } else if (value === 'sold') {
        setNewTransactionMaterialValue({
          ...newTransactionMaterialValue,
          sold: e,
        });
      } 
      else if (value === 'decription'){
        setNewTransactionMaterialValue({
          ...newTransactionMaterialValue,
          description: e,
        });
      }
    },
    [newTransactionMaterialValue],
  );

  const deleteTransactionMaterialNumber = index => {
    const tempValues = transactionMaterials.filter((item, i) => index !== i);
    setTransactionMaterialNumber(tempValues);
  };

  const saveMaterialNumber = useCallback(
    async transactionMaterial => {
      if (!editMaterialNumberPermission) {
        setError(strings.noAccessRights);
        return;
      }
      if (transactionMaterials && transactionMaterials.length <= 0) {
        setError(strings.materialnumberWarning);
        setDisplay(true);
        return;
      }
      if (dispaly) {
        setError(strings.addMaterialNumberInfo);
        return;
      }
      setIsLoading(true);
      let transactionMaterialNumbers = transactionMaterial.map(item => 
        (item.materialNumber != '' && regex.materailNumber.test(item.materialNumber) !== false) ? 
          ((item.quantity != '' && regex.numberOrDecimal.test(item.quantity)) ?
          {
          transactionMaterialNumberId: item.transactionMaterialNumberId ? item.transactionMaterialNumberId : null,
          materialNumber: item.materialNumber,
          description: item.description,
          sapReturnable: item.sapReturnable,
          quantity: item.quantity ? item.quantity : null,
          checked: item.checked,
          sold: item.sold
        } : (item.quantity == '' ? strings.qunatityFieldIsRequired : strings.invalidNumberError))
      : strings.materialnumberWarning);
      if (transactionMaterialNumbers.includes(strings.materialnumberWarning)) {
        setError(`${strings.materialnumberWarning} and ${strings.materialNumberFormatError}`);
        setIsLoading(false);
        return;
      } else if (transactionMaterialNumbers.includes(strings.invalidNumberError)){
        setError(`${strings.invalidNumberError}`);
        setIsLoading(false);
        return;
      } else if (transactionMaterialNumbers.includes(strings.qunatityFieldIsRequired)){
        setError(strings.qunatityFieldIsRequired);
        setIsLoading(false);
        return;
      }
      const { success, error } = await saveMaterialNumberAndDescription(
        transactionDetails.transactionsId,
        transactionMaterialNumbers,
        transactionsType,
        detailPage,
      );
      setIsLoading(false);
      if (!success) {
        if (error && error.response && error.response.data && error.response.data.status === 422){
          setError(error.response.data.message);
        }
        else {
          setError(strings.somethingWentWrongMessage);
        }
      } else {
        onCloseClick();
        setTransactionMaterialNumber(transactionMaterials);
      }
    },
    [transactionMaterials, transactionMaterialNumber, dispaly],
  );

  const onCloseClick = useCallback(() => {
    setNewTransactionMaterialValue({ materialNumber: '', description: '', quantity: 1 });
    if (
      transactionDetails &&
      transactionDetails.transactionMaterialNumber &&
      transactionDetails.transactionMaterialNumber.length
    ) {
      setTransactionMaterialNumber(transactionDetails.transactionMaterialNumber);
    }
    closeMaterialNumberPopup();
    setDisplay(false);
    setError('');
  }, [transactionMaterialNumber]);

  const closeMaterialNumberPopup = useCallback(() => {
    $(`#${id}`).modal('hide');
  }, []);

  return {
    dispaly,
    dispalyInput,
    deleteTransactionMaterialNumber,
    handleOnChange,
    newTransactionMaterialValue,
    addTransactionMaterialNumber,
    transactionMaterials,
    handleOnChangeExstingValue,
    saveMaterialNumber,
    onCloseClick,
    isLoading,
    error,
  };
};
