import Axios from 'axios';

import { fetchFromStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';

export const exportListRequest = url => {
  const token = fetchFromStorage(identifiers.token);

  return Axios(process.env.REACT_APP_BASE_URL + url, {
    method: 'GET',
    responseType: 'blob',
    headers: {
      token: `${token}`,
      'Content-Type': 'application/json',
      'Accept-Language': 'en',
    },
  });
}
