import React from 'react';
import Modal from 'library/common/commonComponents/Modal';
import { useEditMedAddressPopup } from './EditMedAddressPopup.hook';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';

export const EditMedAddressPopup = ({ id, selectedAddress, countries, editMedUsAddress, addressListPayloadData }) => {
  const { addressForm, addressModel, handleForm, onSaveClick, onCloseClick, loading } = useEditMedAddressPopup({
    id,
    selectedAddress,
    countries,
    editMedUsAddress,
    addressListPayloadData,
  });
  return (
    <Modal
      id={id}
      confirmAction={onSaveClick}
      loading={loading}
      disableBackgroundClose
      onCloseClick={onCloseClick}
    >
      <FormComponent
        model={addressModel()}
        formName='AddAddressconfigForm'
        formValue={addressForm.formData}
        onChange={handleForm}
      />
    </Modal>
  );
};

export default EditMedAddressPopup;
