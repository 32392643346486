import { connect } from 'react-redux';

import { searchKitBySerialNumber } from './MaintainStatus.actions';
import MaintainStatus from './MaintainStatus.component';

const mapStateToProps = ({ authReducer, languageReducer, appActionsReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
});

export default connect(mapStateToProps, { searchKitBySerialNumber })(MaintainStatus);
