import { useCallback, useEffect } from 'react';
import moment from 'moment';

import strings from 'resources/locales/Translate';

import { MONTH, YEAR } from '../../Statistics.constants';

export const useStatisticsFilter = ({ setFilter, filter, getStatistics, resetStatistics }) => {
  const timePeriodOptions = [
    {
      id: 1,
      value: strings.year,
    },
    {
      id: 2,
      value: strings.month,
    },
  ];

  const getYearsOptions = useCallback(() => {
    const yearsArr = [];
    const currentDate = moment();
    const startYear = moment(currentDate).subtract(12, 'years');

    for (let i = 0; i < 13; i++) {
      const start = startYear.format('YY');
      const startFullYear = startYear.format('YYYY')
      const end = startYear.add(1, 'year').format('YY');
      const endFullYear = startYear.format('YYYY');


      yearsArr.push({
        id: i + 1,
        value: `FY${start}/${end}`,
        dateFrom: `${startFullYear}-10-01`,
        dateTo: `${endFullYear}-09-30`,
      });
    }

    return yearsArr;
  }, [])

  const yearsOptions = getYearsOptions();

  const getMonthsOptions = useCallback(() => {
    const { yearFullValue } = filter;
    const { dateFrom, dateTo } = (yearFullValue && yearFullValue) || {};

    if (!dateFrom || !dateTo) {
      return [];
    }

    const start = moment(dateFrom);
    const end = moment(dateTo);
    const monthsCount = end.diff(start, 'month');

    const monthArray = [];

    for (let i = 0; i < monthsCount+1; i++) {
      const month = start.format('MMMM');

      monthArray.push({
        id: i + 1,
        value: month,
        dateFrom: start.startOf('month').format('YYYY-MM-DD'),
        dateTo: start.endOf('month').format('YYYY-MM-DD'),
      });
      start.add(1, 'month');
    }

    return monthArray;
  }, [filter]);

  const monthsOptions = getMonthsOptions();

  const handleTimePeriod = (key, value, fullValue) => {
    setFilter(prevState => {
      const resetedMonthAndYear = {
        year: null,
        yearFullValue: null,
        month: null,
        monthFullValue: null,
      };
      const newData = {
        ...prevState,
        timePeriod: value,
        timePeriodFullValue: fullValue,
       };
      return { ...newData, ...resetedMonthAndYear };
    });
  };

  const handleYearOrMonth = (key, value, fullValue) => {
    const error = fullValue.length > 3 ? 'youCantSelectMoreThanThreeValues' : null;
    setFilter(prevState => {
      const newData = { ...prevState };
      newData[key] = value;
      newData[`${key}FullValue`] = fullValue;
      newData[`${key}Error`] = error;
      return { ...newData };
    });
  };

  const resetMonth = (key, value, fullValue) => {
    setFilter(prevState => {
      const newData = { ...prevState };
      newData[key] = value;
      newData[`${key}FullValue`] = fullValue;
      newData.month = null;
      newData.monthFullValue = null;
      newData.monthError = null;
      return { ...newData };
    });
  };

  const handleFilter = (value, key, fullValue) => {
    if (key === 'timePeriod') {
      return handleTimePeriod(key, value, fullValue);
    }

    if ((key === 'year' && filter.timePeriod === YEAR) || (key === 'month' && filter.timePeriod === MONTH)) {
      return handleYearOrMonth(key, value, fullValue);
    }

    if ((key === 'year') && (filter.timePeriod === MONTH)) {
      return resetMonth(key, value, fullValue);
    }

    setFilter(prevState => {
      const newData = { ...prevState };
      newData[key] = value;
      newData[`${key}FullValue`] = fullValue;
      return { ...newData };
    });
  };

  useEffect(() => {
    const { timePeriod, yearFullValue, yearError, monthFullValue, monthError, stock, country } = filter;
    const isYearPeriod = timePeriod === YEAR;
    const isMonthPeriod = timePeriod === MONTH;
    const isFullYearValue = yearFullValue && yearFullValue.length;
    const isMonthValue = monthFullValue && monthFullValue.length;
    const data = {
      stockId: stock && stock.length ? stock : null,
      countryId: country && country.length ? country : null,
      timePeriod: 'year',
    };
    

    if (isMonthPeriod) {
      data.timePeriod = 'month';
    }

    if (isYearPeriod && isFullYearValue && !yearError) {
      const dateRange = yearFullValue.map(item => ({ from: item.dateFrom, to: item.dateTo }));
      data.dateRange = dateRange;
      getStatistics(data);
      return;
    }
    if (isMonthPeriod && isMonthValue && !monthError) {
      const dateRange = monthFullValue.map(item => ({ from: item.dateFrom, to: item.dateTo }));
      data.dateRange = dateRange;
      getStatistics(data);
      return;
    }

    resetStatistics();
  }, [filter, getStatistics, resetStatistics]);

  return {
    timePeriodOptions,
    yearsOptions,
    monthsOptions,
    handleFilter,
  };
}
