import Validators from 'library/utilities/Validators';

export const xrmSamplesSpecModel = [
  {
    label: 'totalSampleSize',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'totalSampleSize',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-12',
    disabled: false,
    bigSizeInput: 'bigger-input-field',
  },
  {
    label: 'xrmFeatureSizes',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'featureSizes',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-12',
    disabled: false,
    bigSizeInput: 'bigger-input-field',
  },
  {
    label: 'xrmFeatureOfRegion',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'featureRegionOfInterest',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-12',
    disabled: false,
    bigSizeInput: 'bigger-input-field',
  },
  {
    label: 'xrmExceptedMaterial',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'chemicalCompositions',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-12',
    disabled: false,
    bigSizeInput: 'bigger-input-field',
  },
  {
    label: 'xrmSubstrate',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'substrateOrSorroundingMaterial',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-12',
    disabled: false,
    bigSizeInput: 'bigger-input-field',
  },
  {
    label: 'xrmContrastApplied',
    value: '',
    type: 'enhanced-RadioButton',
    placeholder: '',
    field: 'contrastAgentBeenApplied',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-4 mt-3',
    options: [
      {
        id: 'yes',
        value: 'yes',
      },
      {
        id: 'no',
        value: 'no',
      },
    ],
  },
];
