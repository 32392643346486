import React from 'react';

import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import Modal from 'library/common/commonComponents/Modal';
import strings from 'resources/locales/Translate';

import { useNotifyPopup } from './NotifyPopup.hook';
import { exportModel } from './NotifyPopup.constants';

export default function NotifyPopup({
  id,
  categoriesAndSystems,
  countries,
  createSubscription,
  subscriptions,
}) {
  const {
    notifyForm,
    loading,
    error,
    onSaveClick,
    handleForm,
    onCloseClick,
  } = useNotifyPopup({
    id,
    createSubscription,
    categoriesAndSystems,
    countries,
    subscriptions,
  });

  return (
    <Modal
      id={id}
      title={strings.notifyMe}
      confirmAction={onSaveClick}
      confirmDataTest='notify-button-subscribe'
      confirmTitle={strings.subscribe}
      loading={loading}
      disableBackgroundClose
      onCloseClick={onCloseClick}
    >
      <p className='font-weight-bold mb-3'>{strings.subscribeAndGetNotified}</p>
      <FormComponent
        model={exportModel({ categoriesAndSystems, countries })}
        formName='notifyForm'
        formValue={notifyForm.formData}
        onChange={handleForm}
      />
      {error && <div className='error-block'>{error}</div>}
    </Modal>
  );
};
