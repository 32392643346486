import Validators from "library/utilities/Validators";

export const newComponentsModel = [
  {
    label: 'kitComponenetDescription',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'componentName',
    validators: [ {check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-sm-3',
  },
  {
    label: 'kitComponentID',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'componentNumber',
    validators: [{check: Validators.number, message: 'invalidNumberError'},],
    required: false,
    styleClass: 'col-sm-3',
  },
  {
    label: 'kitComponentProductID',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'materialNumber',
    validators: [
      { check: Validators.required, message: 'requiredErrorMessage' },
      { check: Validators.materialNumber, message: 'materialNumberFormatErrorOnKitComponent' },
    ],
    required: true,
    styleClass: 'col-sm-2',
  },
  {
    label: 'kitComponentSerialNumber',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'serialNumber',
    validators: [],
    required: false,
    styleClass: 'col-sm-2',
  },
  {
    label: 'kitComponentUserStatus',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'userStatus',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-sm-2',
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'value',
    options: [
      {
        id: 'Active',
        value: 'ACTV',
      },
      {
        id: 'InActive',
        value: 'INACTV',
      },
    ],
    displayValue: [{ key: 'id', separator: '' }],
  },
];