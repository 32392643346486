export const getStockFullValueByIds = (ids, countries) => {
  const array = [];
  countries.forEach(country => {
    if (country.stockInformation) {
      country.stockInformation.forEach(stock => {
        const fullValue = ids.find(id => id === stock.id);
        if (fullValue) {
          array.push(stock);
        }
      });
    }
  });

  return array;
};
  
export const getCategoryFullValueByIds = (ids, categories) => {
  const array = [];
  categories.forEach(category => {
    if (category.systemMainComponent) {
      category.systemMainComponent.forEach(component => {
        const fullValue = ids.find(id => id === component.systemMainComponentId);
        if (fullValue) {
          array.push(component);
        }
      });
    }
  });

  return array;
};
