import { getDateInUTCFormat } from 'library/utilities/getDateInFormat';
import {
  getIconByCondition,
  getIconByLLTransactionStatus,
  getIconByZmccTransactionStatus,
  getRequestStatusOfZmcc,
} from 'library/utilities/getStatusAndConditionIcons';
import { get } from 'lodash';
import React from 'react';
import strings from "resources/locales/Translate";
import TransactionStatusAndPositionTooltip from '../TransactionStatusAndPositionTooltip';


export const useZmccTranaction = (props) =>{
    const getCols = () => [
      {
        title: '#',
        name: 'number',
        isSort: false,
        render: item => {
          return <span>{item.transactionNumber}</span>;
        },
      },
      {
        title: `${strings.status}`,
        name: 'status',
        isSort: false,
        render: item => {
          const {
            transactionKit,
            transactionStatus,
            //   transactionPosition,
          } = item;
          const kitCondition = get(transactionKit, 'kitInformation.kitCondition.kitConditionTranslated');
          const hasKitCondition = kitCondition && kitCondition.length > 0;
          const status = get(transactionStatus, 'transactionStatusId') || '';
          const isExpandable = false;

          return (
            <div className='d-flex flex-wrap show-info-onhover'>
              {status && <>{getIconByZmccTransactionStatus(status, isExpandable)}</>}
              {hasKitCondition && <>{getIconByCondition(transactionKit.kitInformation.kitCondition, isExpandable)}</>}
              <div className='dispaly-status'>
              <TransactionStatusAndPositionTooltip 
                checkStatus={true} 
                transactionStatus={transactionStatus}  
                isZmcc={true} 
                checkCondition={hasKitCondition}
                transactionKit={transactionKit}
              />
              </div>
            </div>
          );
        },
      },
      {
        title: strings.name,
        name: 'name',
        isSort: false,
        render: item => {
          const { zmccTransactionsFullInformation } = item;
          return <>{zmccTransactionsFullInformation.customerName}</>;
        },
      },
      {
        title: strings.category,
        name: 'category',
        isSort: false,
        render: item => {
          const { zmccTransactionsFullInformation } = item;
          const category = get(zmccTransactionsFullInformation, 'intendedFor.intendedForTranslated[0].name', '');
          return <>{category}</>;
        },
      },
      {
        title: strings.transactionStatus,
        name: 'requestStatus',
        isSort: false,
        render: item => {
          const { transactionStatus } = item;
          return <>{getRequestStatusOfZmcc(transactionStatus?.transactionStatusId)}</>;
        },
      },
      {
        title: strings.priority,
        name: 'priority',
        isSort: false,
        render: item => {
          const { zmccTransactionsFullInformation } = item;
          const priority = get(zmccTransactionsFullInformation, 'zmccPriority.zmccPriorityTranslated[0].name', '');
          return <>{priority}</>;
        },
      },
      {
        title: strings.zmccSystemClass,
        name: 'systemClass',
        isSort: false,
        render: item => {
          const { transactionKit } = item;
          const systemClassName = get(transactionKit, 'kitInformation.systemClass.systemClassName');
          return <>{systemClassName}</>;
        },
      },
      {
        title: strings.operator,
        name: 'operator',
        isSort: false,
        render: item => {
          const { zmccTransactionsFullInformation } = item;
          const operator = get(zmccTransactionsFullInformation, 'zmccOperatorsList');
          return <>{operator && operator.map(item => `${item.user.firstName}, `)}</>;
        },
      },
      {
        title: strings.requestor,
        name: 'requestor',
        isSort: false,
        render: item => {
          const requestorName = get(item, 'zmccTransactionsFullInformation.requestor.firstName');
          return <span>{requestorName}</span>;
        },
      },
      {
        title: strings.requestDate,
        name: 'requestDate',
        isSort: false,
        render: item => {
          return <span> {item.createdAt ? getDateInUTCFormat('DD.MM.YYYY', item.createdAt) : ''}</span>;
        },
      },
      {
        title: strings.zmccStartDate,
        name: 'startDate',
        isSort: false,
        render: item => {
          const startDate = get(item, 'zmccTransactionsFullInformation.suggestedDate');
          return <span> {startDate ? getDateInUTCFormat('DD.MM.YYYY', startDate) : ''}</span>;
        },
      },
      {
        title: strings.dueDate,
        name: 'duesDate',
        isSort: false,
        render: item => {
          return (
            <>
              {item.zmccTransactionsFullInformation.dueDate
                ? getDateInUTCFormat('DD.MM.YYYY', item.zmccTransactionsFullInformation.dueDate)
                : ''}
            </>
          );
        },
      },
      {
        title: strings.lastModified,
        name: 'lastModified',
        isSort: false,
        render: item => {
          return <>{item.editedAt ? getDateInUTCFormat('DD.MM.YYYY', item.editedAt) : ''}</>;
        },
      },

      {
        title: strings.requestType,
        name: 'zmccWarehouse',
        isSort: false,
        render: item => {
          return <>{item.zmccSemBooking ? 'SEM':item.zmccXbBooking ? 'XB' : item.zmccXrmBooking ? 'XRM' :item.zmccLmBooking ? 'LM': item.zmccCalendarBooking ? strings.noTypeAdded :  '' }</>;
        },
      },
      
      {
        title: strings.zmcc,
        name: 'zmccWarehouse',
        isSort: false,
        render: item => {
          const zmccWarehouse = get(item.transactionKit, 'kitInformation.stockInformation.locationName');
          return <>{zmccWarehouse}</>;
        },
      },
    ];

    const cols = getCols();
      
      const hasZmccTransactionList = props.zmccTransactionList && props.zmccTransactionList.content && props.zmccTransactionList.content.length > 0;

      return{
        cols,
        hasZmccTransactionList
      }
}