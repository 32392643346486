export const searchModel = [
  {
    label: 'firstName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'firstName',
    validators: [],
    required: false,
    styleClass: 'col-12'
  },
  {
    label: 'lastName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'lastName',
    validators: [],
    required: false,
    styleClass: 'col-12'
  },
];
