import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import strings from 'resources/locales/Translate';
import Icon from 'library/common/commonComponents/Icon/Icon';
import Checkbox from 'library/common/commonComponents/Checkbox/Checkbox';
import { images } from 'library/common/commonConstants/ImageConstants';

import './loanItemStyles.scss';
import LoanListDatePicker from '../LoanListDatePicker/LoanListDatePicker';
import moment from 'moment';
import Button from 'library/common/commonComponents/Button/Button';

export default class LoanItem extends Component {
  handleDateChange = (date, field) => {
    const { data, updateDate } = this.props;
    updateDate(data, date, field);
  };

  goToSemForm = () => {
    const { data } = this.props;
    this.props.history.push({pathname: `/${data.isZmccLmBooking?'LM': data.isZmccSemBooking? 'SEM': data.isZmccXbBooking ? 'XB': data.isZmccXrmBooking ? 'XRM': ''}/${data.transactionsId}`});
  }

  render() {
    const { data, index, toggleSelection, deleteKit, indexForZMCC, currentBooking, indexForDemoLoans, indexForLabsLocations } = this.props;
    let image = data.kit && data.kit.kitImages && data.kit.kitImages.length > 0 ? data.kit.kitImages[0].preview : images.imagePlaceholder;
    let isStartDateGreaterThanEndDate = false;
    if (data.start !== null && data.end !== null && data.isSelected === true) {
      isStartDateGreaterThanEndDate = moment(data.end).isBefore(data.start);
    }

    return (
      <div className={`loan-item-container ${currentBooking === true ? 'active-loan-item': ''} `}>
        <p className='position-text mb-2'>
          {
            data.isZMCCCalendar ?
            `ZMCC ${data.isZmccLmBooking?'LM': data.isZmccSemBooking? 'SEM': data.isZmccXbBooking ? 'XB': data.isZmccXrmBooking ? 'XRM': ''} request form ${indexForZMCC}`
            :
            `${data?.isLabsLocation === true ? strings.labslocationOnCart : strings.demoLoan}: ${strings.positionText} ${data?.isLabsLocation === true ? indexForLabsLocations : indexForDemoLoans}`
          }

        </p>
        <div className='row d-flex align-items-center'>
          <div className='col-12 col-lg-8 d-flex align-items-start flex-column flex-md-row'>
            <div className='kit-image mb-2'>
              <img src={image} alt={data.kit && data.kit.kitName} />
            </div>

            <div className='kit-detail'>
            { data.kit &&
             <>
              <h2 className='kit-name'>{data.kit.kitName}</h2>
              <p className='kit-number'>
                {strings.kitNumber}: {data.kit.kitInformationId}
              </p>
              <p className='kit-description'>{data.kit.description}</p>
              <p className='display-kit'>
                <NavLink to={'/kit_details/' + data.kit.kitInformationId}>
                  <Icon name='visibility' width={24} height={24} fill='#0088d0' /> {strings.displayEquipment}
                </NavLink>
              </p>
              </>}
              <div className='d-flex align-items-center kit-actions'>
                {
                  data.isZMCCCalendar 
                  ?
                  <></>
                  :
                <div className='checkbox'>
                  <Checkbox
                    data-test='toggle-selection-checkbox'
                    label={strings.markForBookingText}
                    selected={data.isSelected}
                    onClick={checked => toggleSelection(data, checked)}
                  />
                </div>
                }
                {!data.isZMCCCalendar && data.kit && !data.kit.twentyFourHourReservation && (
                  <button
                    data-test='delete-kit-checkbox'
                    className='delete-kit d-flex align-items-center'
                    onClick={() => deleteKit(data)}
                  >
                    <Icon name='delete' width={24} height={24} fill='#0088d0' />
                    <span>{strings.removalText}</span>
                  </button>
                )}
                {
                  data.isZMCCCalendar &&
                  <>
                  <button
                  data-test='delete-kit-checkbox'
                  className='delete-kit d-flex align-items-center'
                  onClick={() => deleteKit(data.transactionsId)}
                  >
                    <Icon name='delete' width={24} height={24} fill='#0088d0' />
                    <span>{strings.removalText}</span>
                  </button>
                  </>
                }

              </div>
                {/* {
                data.isZMCCCalendar &&
                <div className='d-flex align-items-center kit-actions'>
                <button
                data-test='delete-kit-checkbox'
                className='delete-kit d-flex align-items-center'
                onClick={() => this.goToSemForm(data.transactionsId)}
                >
                  <span>{strings.continueForm}</span>
                </button>
                </div>              

                } */}
                {
                    data.isZMCCCalendar 
                    ?
                    <></>
                    :
                    !data.isTwentyFourReservationAllowed ? (
                      <p className='allow-reservation pt-2'>{strings.twentyFourHourReservationNotAvailable}</p>
                    ) : (
                      ''
                    )
                }
            </div>
          </div>
          <div className='col-4 d-flex flex-column'>
            <div className='d-flex flex-row mb-3'>
              <div className='mr-3'>
                {
                  data.isZMCCCalendar 
                  ? 
                  data.start && 
                  <div className='loan-list-date-picker'>
                    <p className='date-picker-label'>{strings.suggestedDate}</p>
                    <p>{data.start ? moment(data.start).format("DD.MM.YYYY") : ''}</p>

                  </div>
                  
                  :
                  <LoanListDatePicker
                  label={data?.isLabsLocation === true ? strings.startOfDemo : strings.beginningOfBorrowLabel}
                  selectedDate={data.start}
                  onChangeDate={date => this.handleDateChange(date, 'start')}
                />
                }

              </div>
              {
                  data.isZMCCCalendar 
                  ? 
                  data.end &&
                  <div className='loan-list-date-picker' style={{marginLeft:'50px'}}>
                    <p className='date-picker-label'>{strings.dueDate}</p>
                    <p>{data.end ? moment(data.end).format("DD.MM.YYYY") : ''}</p>
                  </div>
                  :
              <LoanListDatePicker
                label={data?.isLabsLocation === true ? strings.endOfDemo  : strings.endOfBorrowLabel}
                selectedDate={data.end}
                onChangeDate={date => this.handleDateChange(date, 'end')}
              />
              }
            </div>

            {!!data.isIncomplete && (
              <p className='allow-reservation pt-2'>{strings.addFullDetailsForLoanListItem}</p>
            )}
            {data.start === null && data.end !== null && !data.isZMCCCalendar && (
              <p className='allow-reservation pt-2'>{strings.startDateNotSelected}</p>
            )}
            {!!isStartDateGreaterThanEndDate && (
              <p className='allow-reservation pt-2'>{strings.startDateGreaterThanEndDate}</p>
            )}
            {
                data?.isZMCCCalendar ? <>
                  <div className='align-self-end mt-2'>
                    <Button
                      styleClass='reserve-button mt-md-0'
                      value={strings.continueForm}
                      iconName='arrow'
                      disabled={false}
                      onClick={() => this.goToSemForm(data.transactionsId)}
                    />
                  </div>
                </>: null
              }
          </div>
        </div>
      </div>
    );
  }
}
