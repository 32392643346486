import React from 'react';
import { VrDetailsModel } from './VrDetails.constants';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import CreateStockActionButtons from 'library/common/commonComponents/CreateStockActionButtons';

const TAB_ID = 4;
const VRDetailsForm = ({ saveKit, formValue, cancelCreateKit, handleForm }) => {
  const { vrDetalisForm } = formValue;
  
  const handleSaveKit = () => {
    saveKit(TAB_ID);
  };
  return (
    <div className='mb-5 mt-3 container-fluid'>
      <FormComponent
        model={VrDetailsModel}
        formName='vrDetalisForm'
        formValue={vrDetalisForm.formData}
        onChange={handleForm}
        className='mb-3'
        rowStyle=''
      />
      <CreateStockActionButtons
        onSave={handleSaveKit} onCancel={cancelCreateKit}
      />
    </div>
  );
};

export default VRDetailsForm;
