import React from 'react';
import toArray from 'lodash/toArray';

import strings from 'resources/locales/Translate';
import ItemsPerPage from 'library/common/commonComponents/ItemsPerPage/ItemsPerPage';
import ExpandableTable from 'library/common/commonComponents/Table/ExpandableTable';
import SearchField from 'library/common/commonComponents/SearchField/SearchField';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import Pagination from 'library/common/commonComponents/Pagination/Pagination';
import Button from 'library/common/commonComponents/Button/Button';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';

import { useShop } from '../../Shop.hook';
import { dataTable } from '../../dataTable';
import DataExpandableTable from '../../Components/DataExpandableTable';
import { singleItemsOptions, itemsPerPage  } from '../../Shop.constants';
import '../../Shop.styles.scss';
import Filter from '../../Components/Filter';
import ExportTable from '../../Components/ExportTable';
import { useArchive } from './Archive.hook';
import { archiveListBreadcrumbs } from './Archive.constants';

export default function Archive({
  user,
  archiveList,
  countries,
  categoriesAndSystems,
  fetchMasterData,
  fetchArchiveListData,
  exportArchiveList,
  appActions,
  language,
  fetchMarketplaceCountryList,
  marketplaceCountry
}) {
  document.title = 'Shop';
  const { isLoading, displayActionMessage, type, message } = appActions;
  const {
    isShowFilter,
    data,
    handleSearchChange,
    showOrHideFilter,
    handleFilterChange,
    resetFilters,
    handleSort,
    handleItemsPerPage,
    handlePageChange,
    systemsClass,
  } = useShop({
    fetchMasterData,
    countries,
    categoriesAndSystems,
    language,
    fetchMarketplaceCountryList,
    marketplaceCountry,
  });
  const {
    openExportArchiveList,
    isShowArchiveList,
  } = useArchive({
    data,
    archiveList,
    fetchArchiveListData,
  });

  return (
    <div>
      {displayActionMessage && <ActionMessage type={type} message={strings[message]} />}
      <div className='container-fluid mb-3'>
        <Breadcrumbs data={archiveListBreadcrumbs} />
        <div className='row no-gutters align-items-center'>
          <h1 className='col-md col-12 kit-title'>{strings.archive}</h1>
          <div className='col-md-auto col mr-3'>
            <div className='input-wrapper'>
              <SearchField onChange={handleSearchChange} initialValue={data.filter.searchTerm} />
            </div>
          </div>
          <div className='col-auto'>
            <Button
              iconName='filter'
              styleClass='filter-button'
              dataTest='archiveList-list-filter-button'
              bgFill={false}
              value={isShowFilter ? strings.hideFilter : strings.showFilter}
              onClick={showOrHideFilter}
            />
          </div>
        </div>
      </div>
      <Filter
        selected={data.filter}
        countries={countries}
        categoriesAndSystems={systemsClass}
        isShowFilter={isShowFilter}
        handleFilterChange={handleFilterChange}
        resetFilters={resetFilters}
      />
      <div className='shop-table'>
        <div className='container-fluid shop-container mb-3'>
          <div className='mb-3'>
            <div className='title'>{isShowArchiveList ? archiveList.totalElements : 0} {strings.results}</div>
          </div>
          {isShowArchiveList ? (
            <ExpandableTable
              user={user}
              cols={dataTable()}
              rows={archiveList.content}
              sortFields={data.sort}
              handleSort={handleSort}
            >
              <DataExpandableTable isArchive={true} />
            </ExpandableTable>
          ) : (
            <div className='d-flex justify-content-center mb-3'>
              <strong>{strings.noDataFoundText}</strong>
            </div>
          )}
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row no-gutters'>
          <div className='col-md-automb-3'>
            <Button
              dataTest='export-list-button'
              bgFill={false}
              iconName='download'
              value={strings.exportList}
              styleClass='mt-0'
              onClick={openExportArchiveList}
            />
          </div>
          {isShowArchiveList && (
            <div className='col d-flex justify-content-end mb-3'>
              <ItemsPerPage
                itemsPerPage={toArray(itemsPerPage)}
                handleItemsPerPage={handleItemsPerPage}
                value={itemsPerPage[data.size]}
              />
              <Pagination
                currentPage={data.page - 1}
                totalPages={archiveList.totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
      {isLoading && <div className='loader' />}
      <ExportTable
        id='export-archive-list'
        countries={countries}
        categoriesAndSystems={systemsClass}
        singleItemsOptions={singleItemsOptions()}
        filters={data.filter}
        exportShopList={exportArchiveList}
        marketplaceCountry={marketplaceCountry}
      />
    </div>
  );
}
