import React from 'react';

import PieChart from 'library/common/commonComponents/PieChart';

const ActiveKitConditionsPieChart = ({ data, chartLegend, stocks, stockFullValue }) => {
  const openKitList = statusId => {
    window.open(`${process.env.REACT_APP_UI_CALLBACK_URL}/#/kits?condition=${statusId}&stock=${stocks}&stockFullValue=${stockFullValue}`, '_blank');
  };

  return (
    <div className='activeKitConditionsChart-wrapper'>
      <div className='w-50'>
        <PieChart data={data} hideLegend={true} />
      </div>
      <div className='w-50'>
        <div className='d-flex'>
          <div className='mr-5'>
            {chartLegend.slice(0, 4).map(item => (
              <div className='legend' onClick={() => openKitList(item.statusId)} key={item.statusId}>
                <div className='palette' style={{ backgroundColor: item.color }} />
                <span>{item.name}</span>
              </div>
            ))}
          </div>
          <div>
            {chartLegend.slice(4).map(item => (
              <div className='legend' onClick={() => openKitList(item.statusId)} key={item.statusId}>
                <div className='palette' style={{ backgroundColor: item.color }} />
                <span>{item.name}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActiveKitConditionsPieChart;
