import Validators from 'library/utilities/Validators';
import strings from 'resources/locales/Translate';

export const loanOrderModel = [
  {
    label: 'orderType',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'orderType',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: ' ' }],
  },
  {
    label: 'distributionChannel',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'distributionChannel',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: ' ' }],
  },
  {
    label: 'billing',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'billing',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: ' ' }],
  },
  {
    label: 'partner',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'partner',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
  },
  {
    label: 'orderData',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'orderData',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
  },
  {
    label: 'division',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'division',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'divisionsId',
    displayValue: [
      { key: 'name', separator: ' , ' },
      { key: 'id', separator: ' ' },
    ],
    disabled: false,
  },
  {
    label: 'itemNumber',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'itemNumber',
    validators: [],
    required: false,
    styleClass: 'col-6',
    disabled: false,
    isDisplayed: true,
  },
  {
    label: 'customerId',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'customerId',
    validators: [
      { check: Validators.required, message: 'requiredErrorMessage' },
      { check: Validators.number, message: 'invalidNumberError' },
    ],
    required: true,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'goodsRecipientId',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'goodsRecipientId',
    validators: [
      { check: Validators.required, message: 'requiredErrorMessage' },
      { check: Validators.number, message: 'invalidNumberError' },
    ],
    required: true,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'deliveryBlock',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'deliveryBlock',
    required: false,
    styleClass: 'col-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: ' ' }],
    disabled: false,
  },
  {
    label: 'formularHeaderText',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'formularHeaderText',
    required: false,
    styleClass: 'col-6',
    disabled: false,
    extraProps: {
      maxLength: 132,
    },
  },
  {
    label: 'shippingInstructions',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'shippingInstructions',
    required: false,
    styleClass: 'col-6',
    disabled: false,
    extraProps: {
      maxLength: 132,
    },
  },
  {
    label: 'customerGroup',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'customerGroup',
    required: true,
    styleClass: 'col-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: ' ' }],
    disabled: false,
    isDisplayed: true,
  },
];

export const emailModel = [
  {
    label: 'contactEmail',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'email',
    validators: [
      { check: Validators.required, message: 'requiredErrorMessage' },
      { check: Validators.email, message: 'invalidEmailError' },
    ],
    required: true,
    styleClass: 'col-6',
    disabled: false,
  },
];

export const searchCustomerFeildsModel = [
  {
    label: 'accountName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'accountName',
    validators: [],
    required: false,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'contactEmail',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'email',
    validators: [{ check: Validators.email, message: 'invalidEmailError' }],
    required: false,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'contactFirstName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'contactFirstName',
    validators: [],
    required: false,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'contactLastName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'contactLastName',
    validators: [],
    required: false,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'city',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'city',
    validators: [],
    required: false,
    styleClass: 'col-6',
    disabled: false,
  },
];
