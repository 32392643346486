import React from 'react';

import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import Modal from 'library/common/commonComponents/Modal';
import strings from 'resources/locales/Translate';

import { useCreateOrEditTaskPopup } from './CreateOrEditTaskPopup.hook';

const CreateOrEditTaskPopup = ({
  id,
  systemClasses,
  selectedTask,
  createOrEditDudsTask,
  hasEditMode,
  setSelectedTask,
  filterData,
}) => {
  const {
    taskForm,
    loading,
    error,
    onSaveClick,
    handleForm,
    onCloseClick,
    model,
  } = useCreateOrEditTaskPopup({
    id,
    systemClasses,
    hasEditMode,
    selectedTask,
    createOrEditDudsTask,
    setSelectedTask,
    filterData,
  });

  return (
    <Modal
      id={id}
      title={strings.addTask}
      confirmAction={onSaveClick}
      confirmTitle={strings.task}
      loading={loading}
      disableBackgroundClose
      onCloseClick={onCloseClick}
    >
      <FormComponent
        model={model}
        formName='taskForm'
        formValue={taskForm.formData}
        onChange={handleForm}
      />
      {error && <div className='error-block'>{strings[error]}</div>}
    </Modal>
  );
}

export default CreateOrEditTaskPopup;
