import moment from 'moment';
import { batchActions } from 'redux-batched-actions';

import { fetchFromStorage, saveToStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import strings from 'resources/locales/Translate';
import { getDateInFormat } from 'library/utilities/getDateInFormat';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { removeItemFromCart } from 'modules/LoanList/LoanListActions';
import { uploadLoanAttachment } from 'library/common/commonActions/LoanAttachmentsActions';
import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import {
  fetchMasterDataService,
  getDataByCriteriaService,
  fetchMasterDataServicePost,
} from 'library/api/master-data.service';
import { scrollToTop } from 'library/utilities/scrollActions';
import {
  FETCH_USERS_LIST,
  ADD_REASON_FOR_LOAN_DATA,
  LOAN_LIST_ALTERNATE_DATE_MESSAGE,
  SET_AUTHENTICATION,
} from 'library/common/commonConstants/storeConstants';

import { FETCH_OPPORTUNITY_LIST, FETCH_REFURBISHMENT_FEES } from './KitLoan.constants';
import { checkBookingDatesAvailabilityService } from '../Calendar/calendar-services';

export const fetchDataForKitLoan = () => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const { data } = await fetchMasterDataService(URLS.reasonForLoans);
    dispatch(
      batchActions([
        dispatch({
          type: ADD_REASON_FOR_LOAN_DATA,
          payload: data,
        }),
        dispatch(changeLoader(false)),
      ]),
    );
    await dispatch(fetchUserList());
    return data;
  } catch (e) {
    dispatch(
      batchActions([
        dispatch({
          type: ADD_REASON_FOR_LOAN_DATA,
          payload: [],
        }),
        dispatch(changeLoader(false)),
      ]),
    );
  }
};

export const fetchUserList = () => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.userList);
    dispatch({
      type: FETCH_USERS_LIST,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: FETCH_USERS_LIST,
      payload: [],
    });
  }
};

export const fetchAdaptedLoanReasonForStock = (stockId) => async dispatch => {
  try{
    dispatch(changeLoader(true));
    const result = await fetchMasterDataService(URLS.getAdaptedLoanReasons(stockId))
    if(result && result.status === 200){
      return {success: true, data: result.data}
    }
  }catch(e){
    return {success: false, data: []}
  }
  finally{
    dispatch(changeLoader(false));
  }
} 

export const fetchAdditionalLoanRequest = selectedKits => async dispatch => {
  try {
    const kitInformationIds = [];
    selectedKits.forEach(item => kitInformationIds.push(item.resourceId));
    if (kitInformationIds.length) {
      const url = `${URLS.additionalLoanRequest}${kitInformationIds.join()}`;
      const { data } = await fetchMasterDataService(url);
      return data;
    }
    return {};
  } catch (e) {
    return {};
  }
};

export const createDeliveryAddressDropdownValue = addresses => {
  let arrayOfDropdownValues = [
    {
      id: 'new',
      name: strings.newDeliveryAddress,
    },
  ];
  const filteredAddresses = [];
  addresses.forEach(address => {
    if (!filteredAddresses.includes(address.userAddressId)) {
      filteredAddresses.push(address.userAddressId);
      const objectOfAddress = {
        ...address,
        id: address.userAddressId,
        name: address.addressType,
      };
      arrayOfDropdownValues.push(objectOfAddress);
    }
  });
  return arrayOfDropdownValues;
};

export const createKitLoan =
  (formData, loanAttachments, selectedKits, history, kitLoanMaterialNumberAndDescription) => async dispatch => {    
    if (!selectedKits.length) return;
    let selectedKitsToSend = [];
    const reservationFrom = getDateInFormat('YYYY-MM-DD', selectedKits[0].start);
    const reservationTo = getDateInFormat('YYYY-MM-DD', selectedKits[0].end);
    const sapErp = formData.sapErp ? formData.sapErp : '';
    const deliveryComment = formData.deliveryComment ? formData.deliveryComment : '';
    const loanValue = formData.loanValue ? formData.loanValue : '';
    selectedKits.forEach(item => {
      selectedKitsToSend.push({
        kitId: item.resourceId,
      });
    });
    let transactionMaterialNumber = {};
    const transactionMaterialNumbers =
      kitLoanMaterialNumberAndDescription &&
      kitLoanMaterialNumberAndDescription.length > 0 &&
      kitLoanMaterialNumberAndDescription.map(item => ({
        transactionMaterialNumberId: null,
        materialNumber: item.materialNumber,
        description: item.descriptionText,
        quantity: item.quantity,
      }));
    transactionMaterialNumber = { transactionMaterialNumbers };
    const bulkTwentyFourHoursReservations = selectedKits.map(item => item.transactionsId).filter(item => item > 0)
    const valueToSend = {
      selectedKits: selectedKitsToSend,
      transactionsId: selectedKits[0].transactionsId || 0,
      reservationFrom,
      reservationTo,
      twentyFourHourReservation: selectedKits[0].isTwentyFourReservationAllowed,
      sapErp,
      deliveryComment,
      loanValue,
      transactionMaterialNumber: transactionMaterialNumber,
      bulkTwentyFourHoursReservations
    };
    Object.keys(formData).forEach(key => {
      if (!key.includes('Error') && !key.includes('FullValue')) {
        valueToSend[key] = formData[key];
      }
    });
    if (valueToSend.countryId === 0) {
      scrollToTop(500);
      dispatch(toggleActionMessage(true, 'error', 'noCountryAdded'));
    } else {
      try {
        dispatch(changeLoader(true));
        const { data } = await getDataByCriteriaService(URLS.kitLoans, valueToSend);
        if (loanAttachments && loanAttachments.length) {
          await dispatch(uploadLoanAttachment(data, loanAttachments));
        }
        dispatch(changeLoader(false));
        selectedKits.forEach(item => dispatch(removeItemFromCart(item)));
        saveToStorage(identifiers.crmParams, null);
        history.push('/my-bookings?type=dla');
        scrollToTop(500);
        dispatch(toggleActionMessage(true, 'success', 'kitLoanBookedSuccessfully'));
      } catch (e) {
        scrollToTop(500);
        dispatch(changeLoader(false));
        if (e.hasOwnProperty('response')) {
          if (e.response.hasOwnProperty('status') && e.response.status === 422) {
            if (e.response.data.hasOwnProperty('message')) {
              dispatch(toggleActionMessage(true, 'error', e.response.data.message, true));
            } else {
              if (e.response.data.suggestions[0]) {
                dispatch({
                  type: LOAN_LIST_ALTERNATE_DATE_MESSAGE,
                  payload: {
                    displayMessage: true,
                    to: e.response.data.suggestions[0].reservationTo,
                    from: e.response.data.suggestions[0].reservationFrom,
                  },
                });
              } else if (e.response.data.maxLoanDurationFound) {
                dispatch(toggleActionMessage(true, 'info', e.response.data.kitAvailableToCheckMsg, true));
              }
              setTimeout(() => {
                dispatch({
                  type: LOAN_LIST_ALTERNATE_DATE_MESSAGE,
                  payload: {
                    displayMessage: false,
                    to: '',
                    from: '',
                  },
                });
              }, 1000 * 15);
            }
          } else if (e.response.status === 404) {
            dispatch(toggleActionMessage(true, 'error', e.response.data.message, true));
          } else if (e.response.status === 403) {
            dispatch(toggleActionMessage(true, 'error', e.response.data.message, true));
          }else if(e.response.data && e.response.data.length > 0 && e.response.data[0].name){
            return e.response.data;
          } else {
            dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
          }
        }
      }
    }
  };

export const reserveKit = (selectedKits, history) => async dispatch => {
  if (!selectedKits.length) return;

  let selectedKitsToSend = [];
  const reservationFrom = getDateInFormat('YYYY-MM-DD', selectedKits[0].start);
  const reservationTo = getDateInFormat('YYYY-MM-DD', selectedKits[0].end);
  selectedKits.forEach(item => {
    selectedKitsToSend.push({
      kitId: item.kit.kitInformationId,
    });
  });
  const valueToSend = {
    selectedKits: selectedKitsToSend,
    reservationFrom,
    reservationTo,
    isTwentyFourHourReservation: true,
  };
  dispatch(changeLoader(true));

  try {
    await getDataByCriteriaService(URLS.kitReservation, valueToSend);
    dispatch(changeLoader(false));
    selectedKits.forEach(item => dispatch(removeItemFromCart(item)));
    history.push('/my-bookings?type=dla');
    dispatch(toggleActionMessage(true, 'success', 'kitReservedSuccessfully'));
  } catch (e) {
    scrollToTop(500);
    dispatch(changeLoader(false));
    if (e.hasOwnProperty('response')) {
      if (e.response.status === 422) {
        dispatch({
          type: LOAN_LIST_ALTERNATE_DATE_MESSAGE,
          payload: {
            displayMessage: true,
            to: e.response.data.suggestions[0].reservationTo,
            from: e.response.data.suggestions[0].reservationFrom,
          },
        });
      } else if (e.response.status === 404) {
        dispatch(toggleActionMessage(true, 'error', e.response.data.message, true));
      } else {
        dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
      }
    }
  }
};

export const fetchBorrowerAddresses = id => (dispatch, getState) => {
  const url = URLS.userAddress.replace('{id}', id);
  let { user } = getState().authReducer;
  let loggedInUserAddresses = fetchFromStorage(identifiers.userAddress);
  return fetchMasterDataService(url).then(({ status, data }) => {
    if (
      loggedInUserAddresses === null ||
      (JSON.stringify(loggedInUserAddresses) !== JSON.stringify(data) && id === user.accountId)
    ) {
      saveToStorage(identifiers.userAddress, data);
    }
    const updatedaddress = fetchFromStorage(identifiers.userAddress);
    if (status === 200) {
      user = {
        ...user,
        userAddresses: data,
      };
      dispatch({
        type: SET_AUTHENTICATION,
        payload: user,
      });
      const userAddress = data.find(item => item.addressType === 'default') || {};
      return {
        userAddress,
        addressesForDropdown: createDeliveryAddressDropdownValue([
          ...updatedaddress,
          ...createBorrowerAddressTitle(data),
        ]),
      };
    }
  });
};

function createBorrowerAddressTitle(addresses) {
  let modifiedAddresses = [];
  addresses.forEach(address => {
    const singleAddress = {
      ...address,
      addressType: `(Borrower) - ${address.addressType}`,
    };
    modifiedAddresses.push(singleAddress);
  });
  return modifiedAddresses;
}

export const checkAvailabilityOfKit = (countryId, stateId) => (dispatch, getState) => {
  const cart = getState().loanList.cart;
  if (countryId && countryId !== '') {
    cart.forEach(item => {
      if (moment(item.end).isAfter(item.start)) {
        const dataToSend = {
          kitIds: [item.resourceId],
          from: moment(item.start).format('YYYY-MM-DD'),
          to: moment(item.end).format('YYYY-MM-DD'),
          shipToCountryId: countryId,
          transactionsId: item.transactionsId,
          shipToStateId : stateId
        };

        dispatch(changeLoader(true));

        checkBookingDatesAvailabilityService(dataToSend)
          .then(res => {
            if (res.data.hasOwnProperty('requestedPeriodValid') && !res.data.requestedPeriodValid) {
              dispatch(
                batchActions([
                  dispatch({
                    type: LOAN_LIST_ALTERNATE_DATE_MESSAGE,
                    payload: {
                      displayMessage: true,
                      to: res.data.suggestions[0].reservationTo,
                      from: res.data.suggestions[0].reservationFrom,
                    },
                  }),
                  dispatch(changeLoader(false)),
                ]),
              );

              setTimeout(() => {
                dispatch({
                  type: LOAN_LIST_ALTERNATE_DATE_MESSAGE,
                  payload: {
                    displayMessage: false,
                    to: '',
                    from: '',
                  },
                });
              }, 1000 * 15);
              scrollToTop(500);
              return false;
            }
            dispatch(changeLoader(false));
          })
          .catch(err => {
            dispatch(changeLoader(false));
            dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
          });
      } else {
        scrollToTop(500);
        dispatch(toggleActionMessage(true, 'error', 'startDateGreaterThanEndDate'));
      }
    });
  }
};

export const createCourseLoan = (formData, loanAttachments, selectedKits, history) => async dispatch => {
  const selectedProducts = selectedKits.map(item => ({
    productId: item.productId,
    stockId: item.stock.id,
    quantity: item.quantity,
    systemMainComponentId: item.categories.systemMainComponentId,
  }));
  const valueToSend = { selectedProducts };
  Object.keys(formData).forEach(key => {
    if (!key.includes('Error') && !key.includes('FullValue')) {
      if (key === 'reservationFrom') {
        valueToSend[key] = getDateInFormat('YYYY-MM-DD', selectedKits[0][key]);
      } else if (key === 'reservationTo') {
        valueToSend[key] = getDateInFormat('YYYY-MM-DD', selectedKits[0][key]);
      } else {
        valueToSend[key] = formData[key];
      }
    }
  });
  if (valueToSend.countryId === 0) {
    scrollToTop(500);
    dispatch(toggleActionMessage(true, 'error', 'noCountryAdded'));
  } else {
    dispatch(changeLoader(true));
    try {
      const { data } = await getDataByCriteriaService(URLS.courseLoans, valueToSend);
      if (loanAttachments && loanAttachments.length) {
        await dispatch(uploadLoanAttachment(data, loanAttachments));
      }
      dispatch(changeLoader(false));
      history.push({ pathname: '/my-bookings', search: 'type=dla', state: { loanType: 3 } });
      scrollToTop(500);
      dispatch(toggleActionMessage(true, 'success', 'courseLoanBookedSuccessfully'));
    } catch (e) {
      scrollToTop(500);
      dispatch(changeLoader(false));
      if (e.hasOwnProperty('response')) {
        if (e.response.status === 404 || e.response.status === 403) {
          dispatch(toggleActionMessage(true, 'error', e.response.data.message, true));
        } else {
          dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
        }
      }
    }
  }
};

export const createSingleLoan = (formData, loanAttachments, history, selectedKits) => async dispatch => {
  const valueToSend = {};
  let transactionMaterialNumber = {};
  const transactionMaterialNumbers = selectedKits.map(item => ({
    transactionMaterialNumberId: null,
    materialNumber: item.materialNumber,
    description: item.descriptionText,
    quantity: item.quantity,
  }));
  transactionMaterialNumber = { transactionMaterialNumbers };
  valueToSend.transactionMaterialNumber = transactionMaterialNumber;
  for (let key in formData) {
    if (!key.includes('Error') && !key.includes('FullValue') && !key.includes('additionalLoanRequest')) {
      if (key === 'reservationFrom') {
        valueToSend[key] = getDateInFormat('YYYY-MM-DD', formData[key]);
      } else if (key === 'reservationTo') {
        valueToSend[key] = getDateInFormat('YYYY-MM-DD', formData[key]);
      } else {
        valueToSend[key] = formData[key];
      }
    }
  }
  if (!valueToSend['helpForBuildingAndDismantling']) {
    valueToSend['helpForBuildingAndDismantling'] = false;
  }
  if (valueToSend.countryId === 0) {
    scrollToTop(500);
    dispatch(toggleActionMessage(true, 'error', 'noCountryAdded'));
  } else {
    dispatch(changeLoader(true));
    try {
      valueToSend.component = null;
      const { data } = await getDataByCriteriaService(URLS.singleLoans, valueToSend);

      if (loanAttachments && loanAttachments.length) {
        await dispatch(uploadLoanAttachment([data], loanAttachments));
      }
      dispatch(changeLoader(false));

      history.push({
        pathname: '/my-bookings',
        search: 'type=dla',
        state: { loanType: 2 },
      });
      scrollToTop(500);
      dispatch(toggleActionMessage(true, 'success', 'createNewSingleLoan'));
    } catch (e) {
      scrollToTop(500);
      dispatch(changeLoader(false));
      if (e.hasOwnProperty('response')) {
        if (e.response.status === 404 || e.response.status === 403) {
          dispatch(toggleActionMessage(true, 'error', e.response.data.message, true));
        } else {
          dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
        }
      }
    }
  }
};

export const fetchOpportunitiesList = dataToSend => async dispatch => {
  try {
    const res = await fetchMasterDataServicePost(URLS.crmOpportunities, dataToSend);

    if (res.status === 200) {
      dispatch({
        type: FETCH_OPPORTUNITY_LIST,
        payload: res.data,
      });

      return { success: true };
    }

    return { success: false };
  } catch (err) {
    dispatch({
      type: FETCH_OPPORTUNITY_LIST,
      payload: [],
    });

    return { success: false };
  }
};

export const fetchValuesForRefurbishmentFess = (selectedKits, loanType, stockId) => async dispatch => {
  try {
    let dataToSend = {};
    const kitInformationIds = [];
    selectedKits && selectedKits.length && selectedKits.map(item => kitInformationIds.push(item.resourceId));
    if (loanType === 'kitLoan') {
      dataToSend.kitIds = kitInformationIds;
      dataToSend.type = 'kit loan';
    } else if (loanType === 'singleLoan') {
      dataToSend.stockId = stockId.stockId;
      dataToSend.type = 'single loan';
    }
    const res = await fetchMasterDataServicePost(URLS.expectedLoanFees, dataToSend);
    if (res.status === 200) {
      dispatch({
        type: FETCH_REFURBISHMENT_FEES,
        payload: res.data,
      });
      return { success: true };
    }
    return { success: false };
  } catch (error) {
    dispatch({
      type: FETCH_REFURBISHMENT_FEES,
      payload: [],
    });

    return { success: false };
  }
};

export const getSearchedAddress = searchText => async dispatch => {
  try {
    const { data } = await fetchMasterDataServicePost(URLS.getSearchedArress, { searchText: searchText });
    return data;
  } catch (error) {
    return { success: false };
  }
};
