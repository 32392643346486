import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MyQueries from './MyQueries.component';
import { fetchMyQueriesList, createMyQuery, deleteMyQuery } from 'modules/Booking/BookingActions';

const mapStateToProps = ({ authReducer, languageReducer, appActionsReducer, bookingReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
  appActions: appActionsReducer,
  safeSearchList: bookingReducer.safeSearchList
});

export default withRouter(connect(mapStateToProps, {fetchMyQueriesList, createMyQuery, deleteMyQuery})(MyQueries));
