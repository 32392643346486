import React from 'react';

import Button from 'library/common/commonComponents/Button/Button';
import Icon from 'library/common/commonComponents/Icon/Icon';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import strings from 'resources/locales/Translate';

import { useAddCommentPopup } from './AddCommentPopup.hook';

const AddCommentPopup = ({
  id,
  createComment,
  cardInfo,
  setCardInfo,
  onOpenConfirmDeletionModal,
  onOpenAddOrEditCardPopup,
  user,
}) => {
  const {
    isLoading,
    inputValue,
    error,
    budget,
    countryName,
    comments,
    currencyName,
    description,
    systemMainComponentName,
    systemClassName,
    validity,
    handleSaving,
    handleClosePopup,
    handleInput,
    handleClickOnEditButton,
    handleClickOnDeleteButton,
  } = useAddCommentPopup({
    id,
    createComment,
    cardInfo,
    setCardInfo,
    onOpenConfirmDeletionModal,
    onOpenAddOrEditCardPopup,
  });
  const isCreator = (user && user.accountId) === (cardInfo.createdBy && cardInfo.createdBy.accountId);
  const isHavePermissionForEditing = (user && user.admin) || isCreator;

  return (
    <div
      className='modal fade modal-container'
      id={id}
      tabIndex='-1'
      role='dialog'
      aria-labelledby={id}
      aria-hidden='true'
    >
      <div className='modal-background' />
      <div className='modal-dialog' role='document'>
        <div className='modal-content rounded-0 '>
          <div className='modal-body p-0'>
            <div className='content'>
              <button
                type='button'
                className='close modal-close'
                data-dismiss='modal'
                aria-label='Close'
                onClick={handleClosePopup}
                data-test={`${id}-close-button`}
              >
                <Icon name='cross' width={24} height={24} />
              </button>
              <h4 className='mb-3'>{strings.haveRequestFor} {description}</h4>
              <div className='mb-4'>
                <table className='purchase-requests-table mb-4'>
                  <tbody>
                    <tr>
                      <td>{strings.mainComponent}:</td>
                      <td>{systemMainComponentName}</td>
                      <td>
                        {isHavePermissionForEditing && 
                          <>
                            <button className='edit-button' onClick={handleClickOnEditButton}>
                              <Icon name='edit' height={20} width={20} fill='#0088d0' />
                            </button>
                            <button className='delete-button' onClick={handleClickOnDeleteButton}>
                              <Icon name='delete' height={20} width={20} fill='#737678' />
                            </button>
                          </>
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>{strings.systemClassText}:</td>
                      <td>{systemClassName}</td>
                    </tr>
                    <tr>
                      <td>{strings.budget}:</td>
                      <td>{budget} {currencyName}</td>
                    </tr>
                    <tr>
                      <td>{strings.validity}:</td>
                      <td>{validity} {strings.daysLeft}</td>
                    </tr>
                    <tr>
                      <td>{strings.countryLand}:</td>
                      <td>{countryName}</td>
                    </tr>
                  </tbody>
                </table>
                <h5 className='mb-3'>{strings.comments}</h5>
                {comments && comments.length > 0 && comments.map(comment => (
                    <div className='mb-3' key={comment.id}>
                      <p className='text-gray'>
                        {comment.createdBy ? `By ${comment.createdBy.firstName} ${comment.createdBy.lastName}` : '-'}
                      </p>
                      <p>{comment.text}</p>
                    </div>
                  )
                )}
              </div>
              <div className='row mb-3'>
                <div className='col-md-8'>
                  <InputField
                    isRequired
                    label={strings.writeComment}
                    onChange={handleInput}
                    value={inputValue.value}
                    error={inputValue.error}
                  />
                </div>
                <div className='col-md-4 mt-auto'>
                  <Button
                    iconName='save'
                    value={strings.save}
                    onClick={handleSaving}
                    loading={isLoading}
                    disabled={!inputValue.value}
                  />
                </div>
              </div>
              {error && <div className='error-block'>{strings[error]}</div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCommentPopup;
