import { batchActions } from 'redux-batched-actions';
import store from 'main/store/configureStore';

import { logNetworkError } from 'library/utilities/logError';
import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import * as actionTypes from 'library/common/commonConstants/storeConstants';

import {
  createStockRoleService,
  getStockEntitlementsService,
  requestAcceptRejectService,
  requestAccessListService,
} from '../../CreateStock.services';
import { CAN_EDIT_STOCK, NEW_STOCK_ROLES } from '../../CreateStock.constants';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { fetchMasterDataService } from 'library/api/master-data.service';
import strings from 'resources/locales/Translate';

export const setMasterDataToAddUserFormModel = () => dispatch => {
  const masterData = store.getState().masterDataReducer;
  const entitlementData = store.getState().entitlementReducer;

  let roles = masterData.rolesList.filter(item => {
    return item.role.toLowerCase() !== 'admin';
  });

  entitlementData.addUserFormModel[0].options = masterData.usersList;
  entitlementData.addUserFormModel[1].options = roles;
  entitlementData.alreadyAddedUserFormModel[0].options = masterData.usersList;
  entitlementData.alreadyAddedUserFormModel[1].options = roles;
  entitlementData.requestStockAccessForm[0].options = masterData.usersList;
  entitlementData.requestStockAccessForm[1].options = roles;
  entitlementData.filterFormModel[1].options = roles;

  dispatch({
    type: actionTypes.SET_MASTER_DATA_TO_ADD_USERS_FORM,
    payload: entitlementData,
  });
};

export const createStockRole = (userList, deletedIds, stockId) => dispatch => {
  const user = store.getState().authReducer.user;
  const body = { stockId, userList, deletedIds };
  dispatch(changeLoader(true));
  let canEditStock = !!user.admin;
  userList.forEach(stock => {
    if (stock.accountId === user.accountId) {
      canEditStock = canEditStock || stock.roleId === 1;
    }
  });
  return createStockRoleService(body)
    .then(res => {
      dispatch(changeLoader(false));
      if (res.status === 200) {
        batchActions([
          dispatch(toggleActionMessage(true, 'success', 'stockInformationSavedSuccessfully')),
          dispatch(fetchStockEntitlements(stockId, false)),
          dispatch({
            type: CAN_EDIT_STOCK,
            payload: canEditStock,
          }),
        ]);
        return { success: true };
      }
      return { success: false };
    })
    .catch(err => {
      logNetworkError(err);
      dispatch(changeLoader(false));

      if (err && err.response && err.response.status === 500) {
        dispatch(toggleActionMessage(true, 'error', 'errorOccuredText'));
      }

      if(err?.response?.data?.status == 422){
        dispatch(toggleActionMessage(true, 'error', strings.operatorHaveActiveTransactionMessage(err?.response?.data?.message), true));
        dispatch(fetchStockEntitlements(stockId, true))
        return { success: 0 };
      }
      return { success: false };
    });
};

export const fetchRequestAccess = stockId => dispatch => {
  return requestAccessListService(stockId)
    .then(({ status, data }) => {
      if (status === 200) {
        return { success: true, data };
      }
      return { success: false };
    })
    .catch(err => {
      logNetworkError(err);
      return { success: false };
    });
};

export const fetchStockEntitlements =
  (stockId, load = true) =>
  dispatch => {
    if (load) {
      dispatch(changeLoader(true));
    }
    return getStockEntitlementsService(stockId)
      .then(({ status, data }) => {
        if (status === 200) {
          dispatch({
            type: actionTypes.FETCH_STOCK_ENTITLEMENTS,
            payload: excludeUsersWithNoAccountId(data),
          });
        } else {
          dispatch({ type: actionTypes.FETCH_STOCK_ENTITLEMENTS, payload: [] });
        }
        if (load) {
          dispatch(changeLoader(false));
        }
      })
      .catch(err => {
        logNetworkError(err);
        if (load) {
          dispatch(changeLoader(false));
        }
        if (err && err.response && err.response.status === 500) {
          dispatch(toggleActionMessage(true, 'error', 'errorOccuredText'));
        }
        dispatch({ type: actionTypes.FETCH_STOCK_ENTITLEMENTS, payload: [] });
      });
  };

const excludeUsersWithNoAccountId = users => users.filter(user => user.accountId);

export const requestAcceptReject = (data, stockId) => dispatch => {
  dispatch(changeLoader(true));
  const message = data.requestAccessStatus === 'approved' ? 'requestAccepted' : 'requestRejected';
  return requestAcceptRejectService(data)
    .then(({ status, data }) => {
      dispatch(changeLoader(false));
      if (status === 200) {
        batchActions([
          dispatch(fetchStockEntitlements(stockId, false)),
          dispatch(toggleActionMessage(true, 'success', message)),
        ]);
        return { success: true };
      }
      return { success: false };
    })
    .catch(err => {
      logNetworkError(err);
      if (err && err.response && err.response.data && err.response.data.message) {
        dispatch(toggleActionMessage(true, 'error', err.response.data.message, true));
      } else if (err && err.data && err.data.message) {
        dispatch(toggleActionMessage(true, 'error', err.data.message, true));
      }
      dispatch(changeLoader(false));
      return { success: false };
    });
};

export const getNewRoles = stockId => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.getRoles(stockId));
    dispatch({
      type: NEW_STOCK_ROLES,
      payload: data,
    });
    return {success:true}
  } catch (error) {
    dispatch({
      type: NEW_STOCK_ROLES,
      payload: [],
    });
    return {success:false}
  }
};


