import React, { useState } from 'react';
import get from 'lodash/get';

import strings from 'resources/locales/Translate';
import Modal from 'library/common/commonComponents/Modal';

const DeletePermissionsModal = ({ id, state, closePopup, deleteAllPermissionsForAllStocks, setGlobalRole }) => {
  const [loading, setLoading] = useState(false);
  const businessUnit = get(state, 'filters.businessUnitFullValue.name');
  const country = get(state, 'filters.countryFullValue.name') || '-';
  const lastName = get(state, 'filters.userFullValue.lastName');
  const firstName = get(state, 'filters.userFullValue.firstName');
  const email = get(state, 'filters.userFullValue.email');
  const user = `${lastName}, ${firstName} (${email})`;

  const handleDelete = async () => {
    setLoading(true);
    await deleteAllPermissionsForAllStocks(state);
    setGlobalRole(null);
    setLoading(false);
    closePopup();
  };

  return (
    <Modal
      id={id}
      title={strings.deleteAllPermissions}
      confirmAction={handleDelete}
      titleOfCancel={strings.cancel}
      confirmTitle={strings.deleteAllPermissions}
      loading={loading}
      onCloseClick={closePopup}
      disableBackgroundClose
      confirmIcon='delete'
      buttonStyles='deletePermissions-button w-100'
      isGhost={true}
      bgFill={false}
      className='delete-all-permision-adjust-width'
    >
      <h6 className='font-bold mb-4'>{strings.doYouWantToDelete}</h6>
      <div className='d-flex mb-1'>
        <div className='w-50'>{strings.businessUnit}:</div>
        <div className='w-50'>{businessUnit}</div>
      </div>
      <div className='d-flex mb-1'>
        <div className='w-50'>{strings.country}:</div>
        <div className='w-50'>{country}</div>
      </div>
      <div className='d-flex mb-1'>
        <div className='w-50'>{strings.userLabelText}:</div>
        <div className='w-50'>{user}</div>
      </div>
    </Modal>
  );
};

export default DeletePermissionsModal;
