import strings from 'resources/locales/Translate';

export const itemsPerPage = {
  10: { id: 10, pages: 10 },
  20: { id: 20, pages: 20 },
  50: { id: 50, pages: 50 },
};

export const dusdTaskListBreadcrumbData = [
  {
    name:'homepage',
    url: '/home'
  },
  {
    name:'Demo Unit Status Tool',
    url: '/administration/dusdTask'
  },
  {
    name:'dusdTask',
  },
];

export const statusOptions = [
  {
    id: 'ASSIGNED',
    name: strings.assigned,
  },
  {
    id: 'COMPLETED',
    name: strings.completed,
  },
];

export const GET_DUSD_TASKS_LIST = 'GET_DUSD_TASKS_LIST';
export const GET_DUSD_POPUP_SYSTEM_CLASSES  = 'GET_DUSD_POPUP_SYSTEM_CLASSES';

