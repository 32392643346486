import React from 'react';

import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import CreateStockActionButtons from 'library/common/commonComponents/CreateStockActionButtons';

import InRefurbishment from '../../../CreateKit/Tabs/Information/InRefurbishmentPopup';
import { useInformationTab } from './InformationTab.hook';
import { IQS_SSC_DE_DESIGNATION } from 'library/utilities/constants';

const CreateZmccKitInformation = props => {
  const {
    informationForm,
    kitStatusForm,
    cancelCreateKit,
    saveKit,
    handleForm,
    conditions,
    updateForm,
    iqsIdentifierForm,
    iqsStatusForm,
    iqsLoanInformationForm,
    fetchUserList,
    getKitInfoByIbase
  } = props;
  const {
    informationModel,
    kitConditionModel,
    iqsIdentifierModel,
    iqsStatusModel,
    iqsLoanInformationModel
  } = useInformationTab(props);

  const buDesignation = informationForm.formData?.businessUnitFullValue?.designation;
  return (
    <div className='information-container mb-5'>
      <div className='container-fluid form-container'>
        <div className='row'>
        {(buDesignation !== IQS_SSC_DE_DESIGNATION) && 
          <div className='col-sm-12 p-1'>
              <h3 className='mt-2 zmcc-title-heading'>{strings.kitStatus}</h3>
              <div className='p-2 mt-2 section-border-kit'>
              <FormComponent
                model={kitConditionModel}
                formName='kitStatusForm'
                formValue={kitStatusForm.formData}
                onChange={handleForm}
              />
              </div>
          </div>
        }
          <div className='col-sm-12 p-1'>
            <h3 className='mt-2 mb-2 zmcc-title-heading'>{buDesignation === IQS_SSC_DE_DESIGNATION ? strings.generalInformation : strings.information}</h3>
            <div className='p-2 mt-2 section-border-kit'>
            <FormComponent
              model={informationModel}
              formName='informationForm'
              formValue={informationForm.formData}
              onChange={handleForm}
              onClickingIbaseRefreshIcon={(value) => getKitInfoByIbase(value)}
            />
            </div>
          </div>
          {(buDesignation === IQS_SSC_DE_DESIGNATION) &&
          <>
          <div className='col-sm-12 p-1'>
            <h3 className='mt-2 mb-2 zmcc-title-heading'>{strings.identifyer}</h3>
            <div className='p-2 mt-2 section-border-kit'>
            <FormComponent
              model={iqsIdentifierModel}
              formName='iqsIdentifierForm'
              formValue={iqsIdentifierForm.formData}
              onChange={handleForm}
            />
            </div>
          </div>

          <div className='col-sm-12 p-1'>
            <h3 className='mt-2 mb-2 zmcc-title-heading'>{strings.status}</h3>
            <div className='p-2 mt-2 section-border-kit'>
            <FormComponent
              model={iqsStatusModel}
              formName='iqsStatusForm'
              formValue={iqsStatusForm.formData}
              onChange={handleForm}
            />
            </div>
          </div>

          <div className='col-sm-12 p-1'>
            <h3 className='mt-2 mb-2 zmcc-title-heading'>{strings.loanInformation}</h3>
            <div className='p-2 mt-2 section-border-kit'>
            <FormComponent
              model={iqsLoanInformationModel}
              formName='iqsLoanInformationForm'
              formValue={iqsLoanInformationForm.formData}
              onChange={handleForm}
            />
            </div>
          </div>
          </>
          }

        </div>
        <CreateStockActionButtons onSave={saveKit} onCancel={cancelCreateKit} styleClass={'create-kit-button mt-3'} />
      </div>
      <InRefurbishment
        id='inRefurbishmentStatus'
        conditions={conditions}
        kitStatusForm={kitStatusForm}
        updateFormCreateKit={updateForm}
      />
    </div>
  );
};

export default CreateZmccKitInformation;
