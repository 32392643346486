import React from 'react';

import strings from 'resources/locales/Translate';

import Button from 'library/common/commonComponents/Button/Button';
import Icon from 'library/common/commonComponents/Icon/Icon';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import { useShopManagers } from './ShopManagers.hook';

const ShopManagers = ({
  canEditStock,
  usersList,
  addShopManagers,
  removeShopManagers,
  shopManagers,
  shopManagersIds,
  error,
}) => {
  const menegersList = usersList.filter(user => !shopManagersIds.includes(user.accountId));
  const {
    value,
    handleClickOnAddButton,
    handleClickOnDeleteButton,
    handleValueChange,
  } = useShopManagers({ addShopManagers, removeShopManagers });

  return (
    <div className='shop-managers-container'>
      {canEditStock && (
        <div className='form'>
          <div className='row'>
            <div className='col-sm-9'>
              <Dropdown
                data={menegersList}
                displayValue={[
                  { key: 'lastName', separator: ', ' },
                  { key: 'firstName', separator: ' (' },
                  { key: 'email', separator: ')' },
                ]}
                filter
                isRequired
                idKey='accountId'
                field='shopManagers'
                label={strings.shopManager}
                placeholder={strings.pleaseSelect}
                onChange={handleValueChange}
                value={value.selected}
                fullValue={value.selectedFullValue}
                error={error || value.error}
              />
            </div>
            <div className='col-sm-3'>
              <Button
                value={strings.addButtonText}
                onClick={handleClickOnAddButton}
                bgFill={false}
                styleClass='add-button'
                iconName='plus'
                disabled={shopManagers && shopManagers.length >=3}
              />
            </div>
          </div>
        </div>
      )}

      {shopManagers && !!shopManagers.length && (
        <div className='shop-managers-list'>
          {shopManagers.map((manager, key) => (
            <div key={key} className='shop-managers-list-item'>
              <p>
                {manager.lastName}, {manager.firstName} ({manager.email})
              </p>
              {canEditStock && (
                <button
                  data-test='delete-button'
                  type='button'
                  className='delete-button'
                  onClick={() => handleClickOnDeleteButton(manager.accountId)}
                >
                  <Icon name='delete' width={24} height={24} fill='#0088d0' className='mr-2' />
                  {strings.delete}
                </button>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ShopManagers;
