import { ADD_REMOVE_DOCUMENTS, FETCH_DOCUMENTS, RESET_DOCUMENTS_AND_FOLDERS } from 'library/common/commonConstants/storeConstants';

const initialState = {
  files:[],
  folders: [],
};

const DocumentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DOCUMENTS:
      return {
        ...state,
        files: action.payload.files,
        folders: action.payload.folders,
      };
    case ADD_REMOVE_DOCUMENTS:
      return {
        ...state,
        files: action.payload
      };
    case RESET_DOCUMENTS_AND_FOLDERS:
      return {
        ...state,
        files: action.payload.files,
        folders: action.payload.folders,
      };
    default:
      return state;
  }
};

export default DocumentsReducer;
