import React from 'react';

import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import FileUploader from 'library/common/commonComponents/FileUploader/FileUploader';
import Button from 'library/common/commonComponents/Button/Button';
import IconButton from 'library/common/commonComponents/IconButton';
import Icon from 'library/common/commonComponents/Icon/Icon';

import OpportunityForm from '../OpportunityIds';
import SelectedKits from '../SelectedKits';
import CourseLoanCart from '../CourseLoanCart';
import SingleLoanCart from '../SingleLoanCart';
import KitloanMaterialNumberAndDescription from '../KitloanMaterialNumberAndDescription/KitLoanMaterialNumberAndDescription.component';
import { campaignIdModelForm } from 'modules/KitLoan/kitLoanFormModel';
import { LOAN_TYPES } from 'modules/KitLoan/KitLoan.constants';

export const AssignmentStep = ({
  addLoanAttachmentFile,
  removeLoanAttachmentFile,
  descriptionModel,
  descriptionForm,
  updateShortDescription,
  disableSave,
  history,
  handleForm,
  loanAttachments,
  isShowCheckbox,
  helpForBuildingAndDismantlingModel,
  helpForBuildingAndDismantlingForm,
  reservationPeriodForm,
  componentsModel,
  componentsForm,
  reservationPeriodModel,
  selectedKits,
  updateDateOfCartItem,
  loanType,
  onSelectCourseItem,
  goToSecondStep,
  setOpportunity,
  setOpportunities,
  removeOpportunity,
  opportunities,
  setIsOpportunityIdCrmError,
  isOpportunityIdCrmError,
  isRequiredOpportunityID,
  enteredOpportunityId,
  handleEnteredOpportunityId,
  overtakenDataForCourseLoan,
  isShowLoanValueField,
  currency,
  showLoanValueFieldModel,
  isShowLoanValueFieldForm,
  handleWorkflowData,
  overtakenDataForSingleLoan,
  onSelectSingleItem,
  additionalLoanRequestIsPossible,
  onAddKitloanMaterialNumber,
  kitLoanMaterialNumberAndDescription,
  showFormAdditionalSingleActivated,
  isFormOpen,
  checkMedusStock,
  updateCustomerForm,
  checkMedTaiwanWarehouse,
  campaignIdModelForm,
  campaignIdForm,
  showCampaignIdFormBasedOnReasonForLoan = false,
  showCampaignIdIfWorkflowEnabled = false
}) => {
  const goToNextStep = () => {
    const formsToValidate = [{ model: descriptionModel, form: descriptionForm, formName: 'descriptionForm' }];
    if(loanType === LOAN_TYPES.COURSE_LOAN || loanType === LOAN_TYPES.KIT_LOAN){
      formsToValidate.push({
        model: campaignIdModelForm,
        form: campaignIdForm,
        formName: 'campaignIdForm'
      })
    }
    if (loanType === 'singleLoan' || loanType === 'courseLoan') {
      formsToValidate.push({
        model: reservationPeriodModel,
        form: reservationPeriodForm,
        formName: 'reservationPeriodForm',
      });
    }
    if (loanType === 'singleLoan') {
      formsToValidate.push({
        model: componentsModel,
        form: componentsForm,
        formName: 'componentsForm',
      });
    }
    formsToValidate.push({
      model: showLoanValueFieldModel,
      form: isShowLoanValueFieldForm,
      formName: 'isShowLoanValueFieldForm',
    });
    goToSecondStep(formsToValidate);
  };

  const goToLoanListPage = () => {
    history.push('/loan-list');
  };

  const goToCalendar = () => {
    history.push('/calendar');
  };

  const renderCart = () => {
    if (loanType === 'kitLoan') {
      return (
        <>
          <SelectedKits
            selectedKits={selectedKits}
            goToCalendar={goToCalendar}
            handleDateChange={updateDateOfCartItem}
          />
          {additionalLoanRequestIsPossible && (
            <div className='mt-4'>
              <KitloanMaterialNumberAndDescription
                kitLoanMaterialNumberAndDescription={kitLoanMaterialNumberAndDescription}
                onSelect={onAddKitloanMaterialNumber}
                isFormOpened={showFormAdditionalSingleActivated}
                isFormOpenFunction={isFormOpen}
              />
            </div>
          )}
        </>
      );
    } else if (loanType === 'courseLoan') {
      return (
        <CourseLoanCart
          opened
          onSelect={onSelectCourseItem}
          selectedKits={selectedKits}
          overtakenDataForCourseLoan={overtakenDataForCourseLoan}
          handleWorkflowData={handleWorkflowData}
          checkMedusStock={checkMedusStock}
          checkMedTaiwanWarehouse={checkMedTaiwanWarehouse}
        />
      );
    } else if (loanType === 'singleLoan') {
      return (
        <SingleLoanCart
          opened
          overtakenDataForSingleLoan={overtakenDataForSingleLoan}
          handleWorkflowData={handleWorkflowData}
          selectedKits={selectedKits}
          onSelect={onSelectSingleItem}
          model={componentsModel}
          form={componentsForm}
          formName='componentsForm'
          onChange={handleForm}
        />
      );
    }
  };

  const renderReservationPeriod = () => {
    if (loanType === 'singleLoan' || loanType === 'courseLoan') {
      return (
        <>
          <h4 className='subtitle-loan mb-3'>{strings.reservationPeriod}</h4>
          <FormComponent
            formName={'reservationPeriodForm'}
            model={reservationPeriodModel}
            formValue={reservationPeriodForm.formData}
            onChange={handleForm}
          />
          <div className='d-flex mb-4'>
            <Icon name='info' width={32} height={32} className='mr-2' />
            <div>
              <p className='note-text title'>{strings.pleaseNote}</p>
              <p className='note-text'>{strings.pleaseNoteText}</p>
            </div>
          </div>
        </>
      );
    }
  };

  const renderCampaignIdForm = () => {
    const showCampaignIdField = loanType === LOAN_TYPES.COURSE_LOAN || loanType === LOAN_TYPES.KIT_LOAN
    if(!showCampaignIdField || !showCampaignIdFormBasedOnReasonForLoan || !showCampaignIdIfWorkflowEnabled){
      return null;
    }
    return(
      <FormComponent
        formName={'campaignIdForm'}
        model={campaignIdModelForm}
        formValue={campaignIdForm.formData}
        onChange={handleForm}
      />
    )
  }

  return (
    <div>
      <div className='row'>
        <div className='col-12 col-md-6'>
          {renderReservationPeriod()}
          <div className='mb-3'>
            <h4 className='subtitle-loan mb-3'>{strings.descriptionText}</h4>
            <FormComponent
              formName={'descriptionForm'}
              model={descriptionModel}
              formValue={descriptionForm.formData}
              onChange={handleForm}
            />
            {(loanType === 'singleLoan' || loanType === 'kitLoan') && (
              <OpportunityForm
                setOpportunity={setOpportunity}
                setOpportunities={setOpportunities}
                removeOpportunity={removeOpportunity}
                opportunities={opportunities}
                isRequired={isRequiredOpportunityID}
                setIsOpportunityIdCrmError={setIsOpportunityIdCrmError}
                isOpportunityIdCrmError={isOpportunityIdCrmError}
                state={enteredOpportunityId}
                handleEnteredOpportunityId={handleEnteredOpportunityId}
                descriptionForm={descriptionForm}
                updateShortDescription={updateShortDescription}
                selectedKits={selectedKits}
                componentWarehouseForm={componentsForm}
                loanType={loanType}
                updateCustomerForm={updateCustomerForm}
              />
            )}
            {renderCampaignIdForm()}
            {isShowLoanValueField && (
              <FormComponent
                formName='isShowLoanValueFieldForm'
                model={showLoanValueFieldModel.map(item => ({ ...item, currency }))}
                formValue={isShowLoanValueFieldForm.formData}
                onChange={handleForm}
              />
            )}
            {isShowCheckbox && (
              <FormComponent
                formName='helpForBuildingAndDismantlingForm'
                model={helpForBuildingAndDismantlingModel}
                formValue={helpForBuildingAndDismantlingForm.formData}
                onChange={handleForm}
              />
            )}
          </div>

          <div className='mb-4'>
            <h4 className='subtitle-loan mb-3'>{strings.attachments}</h4>
            <FileUploader
              mimeType='image/*,.pdf,.doc,.docx,.xls,.xlsx'
              files={loanAttachments}
              onFileAdd={addLoanAttachmentFile}
              onRemove={removeLoanAttachmentFile}
              errorMessage={strings.allowedFormats}
            />
          </div>
        </div>
        <div className='col-12 col-md-6'>{renderCart()}</div>
        <div className='col-12 col-md-6'>
          <div className='d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between'>
            <div className='col-auto p-0 mt-3 mt-md-0 pt-3 pb-3 pt-md-0 pb-md-0'>
              <IconButton
                data-test='back-to-loan-list-button'
                iconName='arrow'
                value={strings.backToLoanList}
                styles='arrow-left'
                onClick={goToLoanListPage}
              />
            </div>
            <div className='col-auto p-0'>
              <Button
                data-test='go-to-next-step-button'
                bgFill={true}
                iconName='arrow'
                value={strings.continueBooking}
                styleClass='mt-0'
                disabled={disableSave}
                onClick={goToNextStep}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignmentStep;
