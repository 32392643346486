import { useState, useEffect } from 'react';
import $ from 'jquery';

export function useAnswerPopup({
  id,
  kitDetail,
  onSave,
  clearSelectedAnswer,
  selectedAnswer,
  actionTypes,
}) {
  const [loading, setLoading] = useState(false);
  const [answer, setAnswer] = useState({
    value: '',
    error: '',
  });
  const [error, setError] = useState('');

  useEffect(() =>  {
    setAnswer({
      value: (selectedAnswer && selectedAnswer.answerText) || '',
      error: '',
    });
  }, [selectedAnswer]);

  const onCloseClick = () => {
    const popupComponent = $(`#${id}`);
    if (popupComponent && popupComponent.modal) {
      popupComponent.modal('hide');
    }
    clearSelectedAnswer && clearSelectedAnswer();
    setError('');
    setAnswer({
      value: '',
      error: '',
    });
  };

  const onSaveClick = async () => {
    if (!answer.value) {
      return setAnswer(prevState => ({
        ...prevState,
        error: 'requiredErrorMessage',
      }));
    }

    setError('');
    setLoading(true);

    const dataToSend = {
      text: answer.value,
      kitInformationId: kitDetail.kitInformationId,
      questionId: selectedAnswer && selectedAnswer.questionId,
      answerId: selectedAnswer && selectedAnswer.answerId,
      actionTypes,
    };
    const { success } = await onSave(dataToSend);

    setLoading(false);
    if (success) {
      onCloseClick();
    } else {
      setError('somethingWentWrongMessage');
    }
  };

  const handleForm = value => {
    setAnswer({ value, error: '' });
  };

  return {
    answer,
    loading,
    error,
    onSaveClick,
    handleForm,
    onCloseClick,
  };
};
