import { useEffect, useState, useCallback } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import Validators from "library/utilities/Validators";
import { validateForm } from 'library/utilities/ValidateForm.util';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';

import { countries_En } from './country-list';
import {
  SystemClassModel,
  SystemMainComponentModel,
  CountryModel,
} from './MasterData.constants';
import { name } from 'dayjs/locale/de';

const SPECIAL = 'SPECIAL';
const MED = 'MED';

export const useMasterData = ({ masterData, getInitialData, saveMasterData }) => {
  const [formName, setFormName] = useState({
    value: null,
    fullValue: null,
    error: null,
  });
  const [form, setForm] = useState({
    formData: {},
  });

  useEffect(() => {
    if (form.formData && form.formData.idFullValue) {
      getInitialData(form.formData.idFullValue.businessUnitGroup);
    } else {
      getInitialData();
    }
  }, [getInitialData, form.formData.idFullValue]);

  const getSystemClassModel = useCallback(() => {
    const newSystemClassModel = cloneDeep(SystemClassModel);
    if (masterData.businessUnit && masterData.businessUnit.length) {
      newSystemClassModel[0].options = masterData.businessUnit.filter(
        item => item.businessUnitType !== SPECIAL && item.businessUnitGroup !== MED,
      );
    }
    if (masterData.productHierarchy && masterData.productHierarchy.length) {
      newSystemClassModel[1].options = masterData.productHierarchy;
    }
    const enableProductHierarchyIds = ['RMS','MED_SUR','MED_OPT', 'IQS_SSC_DE']
    const designation =form.formData.idFullValue&& form.formData.idFullValue.designation
    if (designation && (enableProductHierarchyIds.includes(designation))) {
      newSystemClassModel[1].disabled = false;
      newSystemClassModel[1].required = true;
      newSystemClassModel[1].validators = [{ check: Validators.required, message: 'requiredErrorMessage' }];
    } else {
      newSystemClassModel[1].disabled = true;
      newSystemClassModel[1].required = false;
      newSystemClassModel[1].validators = [];
    }

    return newSystemClassModel;
  }, [masterData.businessUnit, masterData.productHierarchy, form.formData.id]);

  const modelForSystemClass = getSystemClassModel();

  const getSystemMainComponentModel = useCallback(() => {
    if (masterData.categories && masterData.categories.length) {
      SystemMainComponentModel[0].options = masterData.categories;
    }

    return SystemMainComponentModel;
  }, [masterData.categories]);

  const modelForSystemMainComponent = getSystemMainComponentModel();

  const handleDropdown = useCallback((value, field, fullValue) => {
    setFormName({
      value,
      fullValue,
      error: null,
    });
    setForm({
      formData: {},
    });
  }, []);

  const handleChangeSystemMainComponentOrCountryForm = useCallback(
    ({ values }) => {
      setForm({
        ...form,
        formData: {
          ...form.formData,
          ...values,
        },
      });
    },
    [form],
  );

  const handleChangeSystemClassForm = useCallback(
    ({ values }) => {
      if (values.id && values.id !== 1) {
        return setForm({
          ...form,
          formData: {
            ...form.formData,
            ...values,
            productHierarchy: null,
            productHierarchyFullValue: null,
            productHierarchyError: null,
          },
        });
      }

      setForm({
        ...form,
        formData: {
          ...form.formData,
          ...values,
        },
      });
    },
    [form],
  );

  const getModelForValidation = useCallback(
    formName => {
      switch (formName.value) {
        case 'systemClass':
          return modelForSystemClass;
        case 'systemMainComponent':
          return modelForSystemMainComponent;
        case 'countryOfOrigin':
          return CountryModel;
        default:
          return [];
      }
    },
    [modelForSystemClass, modelForSystemMainComponent],
  );

  const addDataToMasterList = useCallback(() => {
    const model = getModelForValidation(formName);
    const validated = validateForm({ form, model });

    if (!formName.value) {
      return setFormName({
        value: null,
        fullValue: null,
        error: 'requiredErrorMessage',
      });
    }

    if (!validated.isFormValid) {
      return setForm(validated);
    }

    switch (formName.value) {
      case 'systemClass':
        const systemClassData = {
          systemClass: form.formData.systemClass,
          crmId: form.formData.crmId,
          productHierarchyId: form.formData.productHierarchy,
        };
        saveMasterData(URLS.systemClass.replace('{id}', form.formData.id), systemClassData, 'systemClass');
        break;
      case 'systemMainComponent':
        const systemMainComponentData = {
          systemMainComponent: form.formData.systemMainComponent,
          crmId: form.formData.crmId,
        };
        saveMasterData(
          URLS.systemMainComponent.replace('{id}', form.formData.systemClassId),
          systemMainComponentData,
          'systemMainComponent',
        );
        break;
      case 'countryOfOrigin':
        const country = countries_En.find(country => country.shortName === form.formData.shortName);
        saveMasterData(URLS.saveCountry, country, 'country');
        break;
      default:
        break;
    }
  }, [form, formName, getModelForValidation, saveMasterData]);

  return {
    formName,
    form,
    modelForSystemClass,
    modelForSystemMainComponent,
    handleDropdown,
    handleChangeSystemMainComponentOrCountryForm,
    handleChangeSystemClassForm,
    addDataToMasterList,
    getSystemClassModel,
    getSystemMainComponentModel,
  };
};
