import React from 'react';
import { Route, Switch } from 'react-router';

import { checkUserRoles } from 'library/utilities/checkRoles';
import { permissions } from 'library/common/commonConstants/permissionsConstants';

import SalesOffers from './Tabs/SalesOffers';
import Observations from './Tabs/Observations';
import Archive from './Tabs/Archive';
import Resrvation from './Tabs/Reservation';
import Statistics from './Tabs/Statistics';
import PurchaseRequests from './Tabs/PurchaseRequests';
import Order from './Tabs/Order';

export default function Shop({ user }) {
  const isShowOrderList = checkUserRoles(permissions.orderList, user);

  return (
    <>
      <Switch>
        <Route path='/demo-sale/observations' component={Observations} />
        <Route path='/demo-sale/reservation' component={Resrvation} />
        <Route path='/demo-sale/archive' component={Archive} />
        <Route path='/demo-sale/statistics' component={Statistics} />
        <Route path='/demo-sale/purchase-requests' component={PurchaseRequests} />
        {isShowOrderList && 
          <Route path='/demo-sale/order' component={Order} />
        }
        <Route exact path='/demo-sale' component={SalesOffers} />
      </Switch>
    </>
  );
}
