import { FETCH_ACTIVE_SYSTEM_LIST } from './ActiveSystems.constants';

const initialState = {
  activeSystemsList: {},
};

const ActiveSystemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACTIVE_SYSTEM_LIST:
      return {
        ...state,
        activeSystemsList: action.payload
      };
    default:
      return state;
  }
};

export default ActiveSystemsReducer;
