import React from 'react';
import toArray from 'lodash/toArray';

import strings from 'resources/locales/Translate';
import ItemsPerPage from 'library/common/commonComponents/ItemsPerPage/ItemsPerPage';
import Pagination from 'library/common/commonComponents/Pagination/Pagination';
import Button from 'library/common/commonComponents/Button/Button';
import Table from 'library/common/commonComponents/Table/Table';

import DeductPointsPopup from '../DeductPointsPopup';
import { itemsPerPage } from '../../LLManagement.constants';
import AccountStatementFilter from '../AccountStatementFilter';
import { useAccountStatementTab } from './AccountStatementTab.hook';
import './AccountStatementTab.styles.scss';

const AccountStatementTab = props => {
  const { stocks, accountStatementList, user } = props;
  const {
    state,
    cols,
    isShowTable,
    isLoading,
    handleFilterChange,
    handleItemsPerPage,
    handlePageChange,
    exportTable,
  } = useAccountStatementTab(props);
  return (
    <>
      <AccountStatementFilter stocks={stocks} filter={state.filter} onChange={handleFilterChange} user={user}/>
      <div className='container-fluid mb-3'>
        {isShowTable ? (
          <div className='account-statement-table'>
            <Table cols={cols} rows={accountStatementList.content} />
          </div>
        ) : (
          <div className='d-flex justify-content-center my-5'>
            <strong>{strings.noDataFoundText}</strong>
          </div>
        )}
        <div className='row no-gutters mt-3'>
          {isShowTable && (
            <>
              <div className='col-md-auto'>
                <Button
                  dataTest='export-list-button'
                  bgFill={false}
                  iconName='download'
                  value={strings.exportList}
                  styleClass='mt-0'
                  onClick={exportTable}
                  loading={isLoading}
                />
              </div>
              <div className='col d-flex justify-content-end'>
                <ItemsPerPage
                  itemsPerPage={toArray(itemsPerPage)}
                  handleItemsPerPage={handleItemsPerPage}
                  value={itemsPerPage[state.size]}
                />
                <Pagination
                  currentPage={state.page - 1}
                  totalPages={accountStatementList.totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </>
          )}
        </div>
        <DeductPointsPopup id='deduct-points-modal' state={state} />
      </div>
    </>
  );
};

export default AccountStatementTab;
