import React from 'react';

import strings from 'resources/locales/Translate';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import BarChart from 'library/common/commonComponents/BarChart';
import Button from 'library/common/commonComponents/Button/Button';

import StatisticsFilter from './components/StatisticsFilter';
import { statisticsBreadcrumbs } from './Statistics.constants';
import { useStatistics } from './Statistics.hook';

const Statistics = ({
  countries,
  fetchMasterData,
  getStatistics,
  statisticsData,
  resetStatistics,
  language,
}) => {
  const {
    isShowFilter,
    filter,
    setFilter,
    showOrHideFilter,
    data,
    options,
  } = useStatistics({ countries, fetchMasterData, statisticsData,language });

  return (
    <div>
      <div className='container-fluid mb-3'>
        <Breadcrumbs data={statisticsBreadcrumbs} />
        <div className='row no-gutters align-items-center'>
          <h1 className='col kit-title'>{strings.statistics}</h1>
          <div className='col-auto'>
            <Button
              bgFill={false}
              iconName='filter'
              onClick={showOrHideFilter}
              value={isShowFilter ? strings.hideFilter : strings.showFilter}
              styleClass='filter-button'
            />
          </div>
        </div>
      </div>
      <StatisticsFilter
        isShowFilter={isShowFilter}
        countries={countries}
        filter={filter}
        setFilter={setFilter}
        getStatistics={getStatistics}
        resetStatistics={resetStatistics}
      />
      <div className='container-fluid mb-3'>
        <h3 className='mb-3'>{strings.overview}</h3>
        <div className='col-md-8'>
          <BarChart data={data} defaultOptions={options} />
        </div>
      </div>
    </div>
  );
}

export default Statistics;
