export function saveToStorage(identifier, value) {
  localStorage.setItem(identifier, JSON.stringify(value));
}

export function fetchFromStorage(identifier) {
const item = localStorage. getItem(identifier);
if(identifier === "shouldShowLoginPopup" && (item === null || item=== undefined)) {
return "false";
}
if (item === null || item === undefined) {
  return null;
}
try {
  return JSON.parse(localStorage.getItem(identifier));
}
catch (error) {
  return null;
}
}

export function removeFromStorage(identifier) {
  localStorage.removeItem(identifier);
}
