import moment from 'moment';

import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { deleteMasterDataService, fetchMasterDataService, fetchMasterDataServicePost } from 'library/api/master-data.service';
import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { scrollToTop } from 'library/utilities/scrollActions';
import { validateForm } from 'library/utilities/ValidateForm.util';

import services from '../CreateKit/CreateKit.service';
import { uploadKitImage, uploadKitAttachment } from '../CreateKit/CreateKit.actions';
import {
    UPDATE_ZMCC_KIT_FORM,
    FETCH_WAREHOUSES_FOR_ZMCC_BU,
    FETCH_SYSTEM_CLASSES_ZMCC_BU,
    FETCH_SYSTEM_MAIN_COMPONENT_ZMCC_BU,
    FETCH_ZMCC_KIT_DETAILS,
    RESET_ZMCC_BU_KIT_FORM,
    FETCH_WAREHOUSE_ROOMS_ZMCC_BU,
    FETCH_WAREHOUSE_USERS_ZMCC_BU,
    generalInformationIQSModel,
    generalInformationZMCCModel,
    FETCH_RESOURCE_FUNCTION_VALUES,
    FETCH_RESOURSE_TYPE_VALUES,
    FETCH_PRODUCT_HEIRARCHY_VALUES,
    FETCH_RESOURCE_STATUS_VALUES,
    FETCH_NEW_COMPONENTS_LIST,
    FETCH_KIT_MESSAGES_LIST,
    FETCH_KIT_RESOURCE_ATTACHEMNT_CATEGORIES,
    KIT_NEW_ATTACHMENT_LIST,
    MARKETPLACE_ACTIVE_IN_LOCATION,
    IBASE_NEW_COMPONENTS_LIST
  } from './CreateZmccKit.constants';

import { informationFormModel, informationFormIQSModel, iqsIdentifierModel, iqsStatusFormModel, iqsLoanInformationModel, resourceTypeSystemModelValues, resourceTypeNonSystemModelValues } from './Components/InformationTab/InformationTab.constants';
import { VrDetailsModel } from '../KitDetails/Components/VrDetails/VrDetails.constants';
import { IQS_SSC_DE_DESIGNATION, kitCompoennetUseStatusOptions, RESOURCE_TYPE_SYSTEM } from 'library/utilities/constants';
import { marketplaceInformationModel, marketplacePriceModel } from './Components/KitMarketplace/KitMarketplace.constants';
import { formatKitComponentID, formatKitProductID } from 'library/utilities/kitComponentsUtils';

export const updateForm = payload => ({
    type: UPDATE_ZMCC_KIT_FORM,
    payload,
});

export const resetForm = () => ({
    type: RESET_ZMCC_BU_KIT_FORM,
});

export const getKitInfoByIbase = (iBase) => (dispatch, getState) => {
  let { createZmccKitReducer } = getState();
  dispatch(changeLoader(true));

  return fetchMasterDataService(URLS.getKitInfoWithIbase(1,iBase))
  .then(({ status, data }) => {
    if (status === 200) {
      const formData = createKitWithIBaseData(data, createZmccKitReducer);
      dispatch(updateForm(formData));
      dispatch(changeLoader(false));
      dispatch({
        type: IBASE_NEW_COMPONENTS_LIST,
        payload:
        {
          content: data.Components
          .filter(component => component.ProductID && component.Description)
          .map((component, index) => ({
              id: index + 1,
              componentName: component.Description,
              componentNumber: formatKitComponentID(component.ComponentNumber),
              materialNumber: formatKitProductID(component.ProductID),
              serialNumber: component.SerialNumber,
              isMainComponent : component.IsMainComponent,
              userStatus: component.UserStatus,
              userStatusFullValue: kitCompoennetUseStatusOptions.find(option => option.value === component.UserStatus),
              newStateComponent: true
            }))
            .sort((a, b) => b.isMainComponent - a.isMainComponent)
            ,
          ibaseComponents: true
        }
      });
      return data;
    }
  })
  .catch(err => {
    dispatch(changeLoader(false));
    dispatch(toggleActionMessage(true, 'error', 'errorOnGettingIbaseData'));
    return {};
  });
}

const createKitWithIBaseData = (ibaseData, createZmccKitReducer) =>  {

  const generalInformationForm = {
    formData: {
      ...createZmccKitReducer.generalInformationForm.formData,
      kitName: createZmccKitReducer.generalInformationForm.formData.kitName ? createZmccKitReducer.generalInformationForm.formData.kitName :ibaseData.IC2Description,
      hideKit: ibaseData.UserStatus == 'ACTV' ? false : true,
    },
    isFormValid: false,
  };

  const informationForm = {
    formData: {
      ...createZmccKitReducer.informationForm.formData,
      serialNumber : createZmccKitReducer.informationForm.formData.serialNumber ? createZmccKitReducer.informationForm.formData.serialNumber : ibaseData.SerialNumber
    },
    isFormValid: false,
  }

  return {
    generalInformationForm,
    informationForm
  }

}


export const fetchStocks = id => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(`${URLS.countryList}/business-unit?busunessUnitId=${id}`);

    dispatch({
      type: FETCH_WAREHOUSES_FOR_ZMCC_BU,
      payload: data.map(item => ({ ...item, systemClassText: item.systemClassName })),
    });
  } catch (err) {
    dispatch({
      type: FETCH_WAREHOUSES_FOR_ZMCC_BU,
      payload: [],
    });
  }
};

export const fetchUsersOfBusinessUnit = businessUnitId => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.getUsersFromBU(businessUnitId));
    dispatch({
      type: FETCH_WAREHOUSE_USERS_ZMCC_BU,
      payload: data.body,
    });
    return { success: true };
  } catch (error) {
    return { success: false, error };
  }
};

export const fetchSystemClasses = businessUnitId => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.systemClass.replace('{id}', businessUnitId));

    dispatch({
      type: FETCH_SYSTEM_CLASSES_ZMCC_BU,
      payload: data.map(item => ({ ...item, systemClassName: item.systemClassText })),
    });
  } catch (err) {
    dispatch({
      type: FETCH_SYSTEM_CLASSES_ZMCC_BU,
      payload: [],
    });
  }
};

export const fetchRoomsListForWarehouse = warehouseId => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.roomsList(warehouseId));

    dispatch({
      type: FETCH_WAREHOUSE_ROOMS_ZMCC_BU,
      payload: data,
    });
    return { success: true };
  } catch (error) {
    return { success: false, error };
  }
};


export const fetchSystemMainComponent = systemClassId => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.systemMainComponent.replace('{id}', systemClassId));

    dispatch({
      type: FETCH_SYSTEM_MAIN_COMPONENT_ZMCC_BU,
      payload: data.map(item => ({ ...item, systemMainComponentName: item.systemMainComponentText })),
    });
  } catch (err) {
    dispatch({
      type: FETCH_SYSTEM_MAIN_COMPONENT_ZMCC_BU,
      payload: [],
    });
  }
};
export const fetchZMCCKitDetails = (id, isCopy) => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const { data } = await services.fetchKitDetailService(id);
    let kitDetails = data;
    if (isCopy) {
      kitDetails = {
        ...data,
        kitInformationId: null,
        serialNumber: '',
        dateOfManufacture: '',
        owner: null,
        warehouseRoom: null,
        isMobileOrExternal: '',
        windowsUserPassword: null,
        vrDetailsUserName: null,
        vrDetailsPassword: null
      };
    }
    const formData = createFormData(kitDetails);

    dispatch({
      type: FETCH_ZMCC_KIT_DETAILS,
      payload: { kitDetails, ...formData },
    });
    if (isCopy){
      dispatch({
        type: FETCH_NEW_COMPONENTS_LIST,
        payload: {content: []}
      });
    }
    dispatch(changeLoader(false));
  } catch (err) {
    dispatch(changeLoader(false));
  }
};

export const createZmccKit = (isCopy, tabId) => async (dispatch, getState) => {
  const { createZmccKitReducer } = getState();
  const {
    generalInformationForm,
    informationForm,
    furtherImportantComponentForm,
    salesComponentForm,
    freeAccessoriesForm,
    kitStatusForm,
    kitImagesToDelete,
    kitAttachmentsToDelete,
    kitImages,
    kitAttachments,
    kitInformationId,
    vrDetalisForm,
    iqsIdentifierForm,
    iqsStatusForm,
    iqsLoanInformationForm,
    newComponentsList,
    marketplaceInformation,
    marketplacePrice,
    componentsFetchedFromIbase,
  } = createZmccKitReducer;

  const IQS_SSC_DE_DESIGNATION_VALUE = informationForm.formData?.businessUnitFullValue?.designation == IQS_SSC_DE_DESIGNATION;

  const validatedGeneralForm = validateForm(
    {
      form: generalInformationForm,
      model: IQS_SSC_DE_DESIGNATION_VALUE ? generalInformationIQSModel: generalInformationZMCCModel
    });
  if (!validatedGeneralForm.isFormValid) {
    dispatch(toggleActionMessage(true, 'error', 'fillKitHeaderInformation'));
    dispatch(updateForm({ generalInformationForm: validatedGeneralForm }));
    scrollToTop(500);

    return { success: false };
  }

  const isResourceTypeSYSTEM =  informationForm.formData?.resourceType  === RESOURCE_TYPE_SYSTEM;
  if (IQS_SSC_DE_DESIGNATION_VALUE){
    if (isResourceTypeSYSTEM){
      const validatedInformationForm = validateForm(
        { form: informationForm,
          model: [...informationFormIQSModel, ...resourceTypeSystemModelValues]
        });
      if (!validatedInformationForm.isFormValid) {
        dispatch(toggleActionMessage(true, 'error', 'formIsInvalid'));
        dispatch(updateForm({ informationForm: validatedInformationForm }));
        scrollToTop(500);

        return { success: false };
      }
    } else {
      const validatedInformationForm = validateForm(
        { form: informationForm,
          model:  [...informationFormIQSModel, ...resourceTypeNonSystemModelValues]
        });
      if (!validatedInformationForm.isFormValid) {
        dispatch(toggleActionMessage(true, 'error', 'formIsInvalid'));
        dispatch(updateForm({ informationForm: validatedInformationForm }));
        scrollToTop(500);

        return { success: false };
      }
    }
  } else{
    const validatedInformationForm = validateForm(
      { form: informationForm,
        model: informationFormModel
      });
    if (!validatedInformationForm.isFormValid) {
      dispatch(toggleActionMessage(true, 'error', 'formIsInvalid'));
      dispatch(updateForm({ informationForm: validatedInformationForm }));
      scrollToTop(500);

      return { success: false };
    }
  }

  if (IQS_SSC_DE_DESIGNATION_VALUE){
    const validatedIqsIdentifierModelForm = validateForm(
      { form: iqsIdentifierForm,
        model:  iqsIdentifierModel
      });
    if (!validatedIqsIdentifierModelForm.isFormValid) {
      dispatch(toggleActionMessage(true, 'error', 'formIsInvalid'));
      dispatch(updateForm({ iqsIdentifierForm: validatedIqsIdentifierModelForm }));
      scrollToTop(500);

      return { success: false };
    }

    const validatedIqsStatusModelForm = validateForm(
      { form: iqsStatusForm,
        model:  iqsStatusFormModel
      });
    if (!validatedIqsStatusModelForm.isFormValid) {
      dispatch(toggleActionMessage(true, 'error', 'formIsInvalid'));
      dispatch(updateForm({ iqsStatusForm: validatedIqsStatusModelForm }));
      scrollToTop(500);

      return { success: false };
    }

    const validatedIqsLoanInformationModelForm = validateForm(
      { form: iqsLoanInformationForm,
        model:  iqsLoanInformationModel
      });
    if (!validatedIqsLoanInformationModelForm.isFormValid) {
      dispatch(toggleActionMessage(true, 'error', 'formIsInvalid'));
      dispatch(updateForm({ iqsLoanInformationForm: validatedIqsLoanInformationModelForm }));
      scrollToTop(500);

      return { success: false };
    }

    if (!kitInformationId){
      if (!newComponentsList?.length >0) {
        dispatch(toggleActionMessage(true, 'error', 'pleaseAddAtlestOneComponents'));
        scrollToTop(500);
        return { success: false };
      }
    }
  }

  const validatedVrDetailsForm = validateForm({ form: vrDetalisForm, model: VrDetailsModel });

  if (!validatedVrDetailsForm.isFormValid) {
    dispatch(toggleActionMessage(true, 'error', 'urlErrorMessage'));
    dispatch(updateForm({ vrDetalisForm: validatedVrDetailsForm }));
    scrollToTop(500);
    return { success: false };
  }

  if (IQS_SSC_DE_DESIGNATION_VALUE && tabId === 5) {
    const validatedMarketplaceInformationForm = validateForm({ form: marketplaceInformation, model: marketplaceInformationModel });
    const validatedMarketplacePriceForm = validateForm({ form: marketplacePrice, model: marketplacePriceModel });
  if (!validatedMarketplaceInformationForm.isFormValid || !validatedMarketplacePriceForm.isFormValid) {
      dispatch(updateForm({ marketplaceInformation: validatedMarketplaceInformationForm }));
      dispatch(updateForm({ marketplacePrice: validatedMarketplacePriceForm }));
      dispatch(toggleActionMessage(true, 'error', 'formIsInvalid'));
      scrollToTop(500);
    return { success: false };
  }

  }



  let valuesToSend = {
    ...generalInformationForm.formData,
    ...informationForm.formData,
    ...kitStatusForm.formData,
    ...furtherImportantComponentForm.formData,
    ...salesComponentForm.formData,
    ...freeAccessoriesForm.formData,
    deleteImageIds: kitImagesToDelete,
    deleteAttachmentIds: kitAttachmentsToDelete,
    ...vrDetalisForm.formData,
    ...(IQS_SSC_DE_DESIGNATION_VALUE ? {
      ...iqsLoanInformationForm.formData,
      ...iqsStatusForm.formData,
      ...iqsIdentifierForm.formData
    } : {})
  };

  let kitMarketplaceData = {
    ...marketplaceInformation?.formData,
    ...marketplacePrice?.formData,
  }

  Object.keys(kitMarketplaceData).forEach(key => {
    if (key.includes('Error') || key.includes('FullValue')) {
      delete kitMarketplaceData[key];
    }
  });

  Object.keys(valuesToSend).forEach(key => {
    if (key.includes('Error') || key.includes('FullValue')) {
      delete valuesToSend[key];
    }
    if (key === 'stockInformation' || key === 'businessUnit' || key === 'country' || key === 'resourceCountry' || key === 'productHierarchy') {
      valuesToSend[key] = { id: valuesToSend[key] };
    }
    if (key === 'systemClass') {
      valuesToSend[key] = { systemClassId: valuesToSend[key] };
    }
    if (key === 'room') {
      valuesToSend[key] = { warehouseRoomId: valuesToSend[key] ? valuesToSend[key] : null};
    }
    if (key === 'owner') {
      valuesToSend[key] = { userId: valuesToSend[key] };
    }
    if (key === 'systemMainComponent') {
      valuesToSend[key] = { systemMainComponentId: valuesToSend[key] };
    }
    if (key === 'kitCondition') {
      valuesToSend[key] = valuesToSend[key] ? { kitConditionId: valuesToSend[key] } : null;
    }
    if (key === 'dateOfManufacture') {
      valuesToSend[key] = valuesToSend[key] ? valuesToSend[key] : null;
    }
    if (key === 'lastMaintenanceDate') {
      valuesToSend[key] = valuesToSend[key] ? valuesToSend[key] : null;
    }

    if (key === 'zmccSystemCategory') {
      valuesToSend[key] = { zmccSystemCategoryId: valuesToSend[key] ? valuesToSend[key] : null};
    }

    if (valuesToSend.availabilityFrom) {
      valuesToSend = { ...valuesToSend, availabilityFrom: moment(valuesToSend.availabilityFrom).format('YYYY-MM-DD') };
    }
    if (valuesToSend.availabilityTo) {
      valuesToSend = { ...valuesToSend, availabilityTo: moment(valuesToSend.availabilityTo).format('YYYY-MM-DD') };
    }
    if (valuesToSend.dateOfManufacture) {
      valuesToSend = { ...valuesToSend, dateOfManufacture: moment(valuesToSend.dateOfManufacture).format('YYYY-MM-DD') };
    }
    if (valuesToSend.lastMaintenanceDate) {
      valuesToSend = { ...valuesToSend, lastMaintenanceDate: moment(valuesToSend.lastMaintenanceDate).isValid() ?  moment(valuesToSend.lastMaintenanceDate).format('YYYY-MM-DD') : null};
    }

    if (key === 'refurbishmentFrom') {
      valuesToSend[key] =
        kitStatusForm.formData.kitCondition === 5
          ? moment(kitStatusForm.formData.refurbishmentFrom).format('YYYY-MM-DD')
          : null;
    }
    if (key === 'refurbishmentTo') {
      valuesToSend[key] =
        kitStatusForm.formData.kitCondition === 5
          ? moment(kitStatusForm.formData.refurbishmentTo).format('YYYY-MM-DD')
          : null;
    }
    if (key === 'ibase') {
      valuesToSend[key] = valuesToSend[key] ? valuesToSend[key] : '';
    }
  });

  valuesToSend.deliveryDays = valuesToSend.deliveryDays ? parseInt(valuesToSend.deliveryDays, 10) : null;
  valuesToSend.returnDeliveryDays = valuesToSend.returnDeliveryDays
    ? parseInt(valuesToSend.returnDeliveryDays, 10)
    : null;
  valuesToSend.reconditioningDeliveryDays = valuesToSend.reconditioningDeliveryDays
    ? parseInt(valuesToSend.reconditioningDeliveryDays, 10)
    : null;
  valuesToSend.maxLoanDuration = valuesToSend.maxLoanDuration
    ? parseInt(valuesToSend.maxLoanDuration, 10)
    : null;

  valuesToSend.kitInformationId = kitInformationId || '';
  if ((!kitInformationId || componentsFetchedFromIbase)){
    const modifiedComponentsList = newComponentsList.map(component => {
      if (component.newStateComponent) {
        const { newStateComponent, ...rest } = component;
        return { ...rest, id: null };
      }
      return component;
    });

    valuesToSend.iqsResourceComponents = modifiedComponentsList;
  }

  if (isCopy) {
    valuesToSend.kitImages = kitImages
      .filter(image => image.kitImagesId)
      .map(image => ({ ...image, kitImagesId: 0, preview: image.actualImageUrl }));
    valuesToSend.kitAttachments = kitAttachments
      .filter(file => file.kitAttachmentsId)
      .map(file => ({ ...file, kitAttachmentsId: 0 }));
  }

  valuesToSend.sellOffInformation = kitMarketplaceData;

  const imagesToUpload = kitImages.filter(image => !image.kitImagesId);
  const attachmentsToUpload = kitAttachments.filter(file => !file.kitAttachmentsId);

  try {
    dispatch(changeLoader(true));
    const { data } = await fetchMasterDataServicePost(URLS.kit, valuesToSend);
    const message = isCopy
      ? 'kitCopySuccessMessage'
      : (!informationForm.formData.room && !IQS_SSC_DE_DESIGNATION_VALUE)
      ? 'roomNotAddedMesage'
      : 'kitSavedSuccessMessage';
    const type = (!informationForm.formData.room && !IQS_SSC_DE_DESIGNATION_VALUE) ? 'info' : 'success';
    if (data){
      if (imagesToUpload.length) {
        await dispatch(uploadKitImage(imagesToUpload, data.kitInformationId));
      }

      if (attachmentsToUpload.length) {
        await dispatch(uploadKitAttachment(attachmentsToUpload, data.kitInformationId));
      }
    }

    dispatch(changeLoader(false));
    dispatch(toggleActionMessage(true, type, message));
    return { success: true, kitInformationId: data.kitInformationId , isIqsSscDeKit:data.isIqsSscDeKit };
  } catch (err) {
    const response = err && err.response && err.response.status;
    dispatch(changeLoader(false));
    scrollToTop(500);
    if (response === 422 || response === 403) {
      let message = err.response.data.message.replace("Stock Controller", "ZMCC Manager");
      dispatch(toggleActionMessage(true, 'error', message, true));
    } else {
      dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    }

    return { success: false };
  }
};

  export const createFormData = kitDetails => {
    const {
      kitReparation,
      kitInformationId,
      kitName,
      description,
      furtherImportantComponents,
      salesComponents,
      stockInformation,
      businessUnit,
      systemClass,
      systemMainComponent,
      ibase,
      serialNumber,
      dateOfManufacture,
      availabilityFrom,
      availabilityTo,
      kitCondition,
      storageLocation,
      comment,
      freeAccessories,
      kitImages,
      kitAttachments,
      warehouseRoom,
      owner,
      isMobileOrExternal,
      resourceFunction,
      resourceCountry,
      resourceType,
      productHierarchy,
      resourceCategory,
      marketingMaterial,
      supportingAsset,
      fixedAsset,
      sapPlant,
      sapStorageLocation,
      materialNumber,
      inventoryNumber,
      lastMaintenanceDate,
      maintenanceInterval,
      resourceStatus,
      deliveryDays,
      returnDeliveryDays,
      reconditioningDeliveryDays,
      maxLoanDuration,
      packageDimensions,
      purpose,
      zmccSystemCategory,
      sellOffPlatformInformation,
      sapERP
    } = kitDetails || {};
    
    const reparation = (kitReparation && kitReparation.length && kitReparation.find(item => item.isReparationTimeActive)) || {};
    const generalInformationForm = {
      formData: {
        kitInformationId: kitInformationId,
        kitName: kitName,
        description: description,
        resourceFunction: resourceFunction
      },
      isFormValid: false,
    };
    const furtherImportantComponentForm = {
      formData: {
        furtherImportantComponents: furtherImportantComponents,
      },
      isFormValid: false,
    };
    const salesComponentForm = {
      formData: {
        salesComponents: salesComponents,
      },
      isFormValid: false,
    };
    const informationForm = {
      formData: {
        stockInformation: stockInformation && stockInformation.id,
        stockInformationFullValue: stockInformation,
        businessUnit: businessUnit && businessUnit.id,
        businessUnitFullValue: businessUnit,
        systemClass: systemClass && systemClass.systemClassId,
        systemClassFullValue: systemClass,
        systemMainComponent: systemMainComponent && systemMainComponent.systemMainComponentId,
        systemMainComponentFullValue: systemMainComponent,
        ibase: ibase,
        serialNumber: serialNumber,
        dateOfManufacture: moment(dateOfManufacture).isValid() ? new Date(dateOfManufacture) : '',
        availabilityFrom: moment(availabilityFrom).isValid() ? new Date(availabilityFrom) : '',
        availabilityTo: moment(availabilityTo).isValid() ? new Date(availabilityTo) : '',
        storageLocation,
        room: warehouseRoom && warehouseRoom.id,
        roomFullValue: warehouseRoom,
        owner: owner && owner.id,
        ownerFullValue: owner,
        isMobileOrExternal: isMobileOrExternal ? 'Yes' : isMobileOrExternal === false ? 'No' : '',
        resourceCountry: resourceCountry && resourceCountry.id,
        resourceCountryFullValue: resourceCountry,
        resourceType,
        productHierarchy: productHierarchy && productHierarchy.id,
        productHierarchyFullValue: productHierarchy,
        resourceCategory,
        marketingMaterial: marketingMaterial,
        supportingAsset: supportingAsset,
        fixedAsset: fixedAsset,
        zmccSystemCategory: zmccSystemCategory && zmccSystemCategory.zmccSystemCategoryId,
        zmccSystemCategoryFullValue: zmccSystemCategory
      },
      isFormValid: false,
    };
    const kitStatusForm = {
      formData: {
        kitCondition: kitCondition && kitCondition.kitConditionId,
        kitConditionFullValue: kitCondition && {
          kitConditionId: kitCondition.kitConditionId,
          kitStatusId: kitCondition.kitConditionId,
          translatedStatus:
            kitCondition.kitConditionTranslated &&
            kitCondition.kitConditionTranslated[0] &&
            kitCondition.kitConditionTranslated[0].translatedCondition,
        },
        comment,
        refurbishmentFrom: reparation.reparationFrom ? new Date(reparation.reparationFrom) : null,
        refurbishmentTo: reparation.reparationTo ? new Date(reparation.reparationTo) : null,
      },
      isFormValid: false,
    };
    const freeAccessoriesForm = {
      formData: {
        freeAccessories,
      },
      isFormValid: false,
    };
    const vrDetalisForm = {
      formData: {
        windowsUserPassword: kitDetails && kitDetails.windowsUserPassword,
        vrDetailsUserName: kitDetails && kitDetails.vrDetailsUserName,
        vrDetailsPassword: kitDetails && kitDetails.vrDetailsPassword,
        linkToOnboardingMaterial: kitDetails && kitDetails.linkToOnboardingMaterial,
      },
      isFormValid: false,
    }
    const images = kitImages ? kitImages.map(image => ({ ...image, name: image.file })) : [];
    const attachments = kitAttachments ? kitAttachments.map(file => ({ ...file, name: file.file })) : [];

    const iqsIdentifierForm = {
      formData: {
        storageLocation,
        sapPlant,
        sapStorageLocation,
        materialNumber,
        serialNumber,
        inventoryNumber,
        dateOfManufacture: dateOfManufacture && new Date(dateOfManufacture),
      },
      isFormValid: false,
    }

    const iqsStatusForm = {
      formData: {
        lastMaintenanceDate: moment(lastMaintenanceDate).isValid() ? new Date(lastMaintenanceDate) : null,
        maintenanceInterval,
        availabilityFrom: moment(availabilityFrom).isValid() ? new Date(availabilityFrom) : null,
        availabilityTo: moment(availabilityTo).isValid() ? new Date(availabilityTo) : null,
        resourceStatus: resourceStatus,
        purpose
      },
      isFormValid: false,
    }

    const iqsLoanInformationForm ={
      formData: {
        deliveryDays,
        returnDeliveryDays,
        reconditioningDeliveryDays,
        maxLoanDuration,
        packageDimensions
      },
      isFormValid: false,
    }

    const marketplaceInformation = {
      formData:{
        availableOnMarketplace: sellOffPlatformInformation?.availableOnMarketplace || '',
        firstSalesOrder: sellOffPlatformInformation?.firstSalesOrder || '',
        loanOrder: sapERP,
        condition: sellOffPlatformInformation?.sellOffCondition ? sellOffPlatformInformation?.sellOffCondition?.id : '',
        conditionFullValue: sellOffPlatformInformation?.sellOffCondition ? {id: sellOffPlatformInformation?.sellOffCondition?.id, value: sellOffPlatformInformation?.sellOffCondition?.sellOffKitConditionTranslated[0]?.name} : '',
        saleDate:sellOffPlatformInformation?.saleDate && moment(sellOffPlatformInformation?.saleDate).isValid() ? new Date(sellOffPlatformInformation?.saleDate) : '',
        deliveryTimeInWeeks: sellOffPlatformInformation?.deliveryTimeInWeeks || '',
        saleComment: sellOffPlatformInformation?.saleComment || ''
      }
    }

    const marketplacePrice = {
      formData: {
        localSalePrice: sellOffPlatformInformation?.localSalePrice || '',
        currency: sellOffPlatformInformation?.currency?.id || '',
        currencyFullValue: sellOffPlatformInformation?.currency ? {id: sellOffPlatformInformation?.currency?.id, value: sellOffPlatformInformation?.currency?.name} : '',
        priceTypeDynamic: sellOffPlatformInformation?.priceTypeDynamic || '',
        priceFactor: sellOffPlatformInformation?.priceFactor || '',
        priceFactorFullValue: sellOffPlatformInformation?.priceFactor ? {value: sellOffPlatformInformation?.priceFactor} : '',
        transferPrice: sellOffPlatformInformation?.transferPrice || '',
      }
    }

    return {
      generalInformationForm,
      informationForm,
      kitStatusForm,
      salesComponentForm,
      furtherImportantComponentForm,
      freeAccessoriesForm,
      kitImages: images,
      kitImagesToDelete: [],
      kitAttachments: attachments,
      kitAttachmentsToDelete: [],
      vrDetalisForm,
      iqsIdentifierForm,
      iqsStatusForm,
      iqsLoanInformationForm,
      marketplaceInformation,
      marketplacePrice
    };
  };

  export const fetchFunctionDropdownValues = () => async dispatch => {
    try {
      const { data } = await fetchMasterDataService(URLS.resourceFunctions);
      dispatch({
        type: FETCH_RESOURCE_FUNCTION_VALUES,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: FETCH_RESOURCE_FUNCTION_VALUES,
        payload: [],
      });
    }
  };


  export const fetchResourceTypesDropdownValues = () => async dispatch => {
    try {
      const { data } = await fetchMasterDataService(URLS.resourceTypes);
      dispatch({
        type: FETCH_RESOURSE_TYPE_VALUES,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: FETCH_RESOURSE_TYPE_VALUES,
        payload: [],
      });
    }
  };


  export const fetchProductHeirarchyDropdownValues = (businessGroup) => async dispatch => {
    try {
      const { data } = await fetchMasterDataService(URLS.productHierarchy(businessGroup));
      dispatch({
        type: FETCH_PRODUCT_HEIRARCHY_VALUES,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: FETCH_PRODUCT_HEIRARCHY_VALUES,
        payload: [],
      });
    }
  };


  export const fetchResourceStatusesDropdownValues = () => async dispatch => {
    try {
      const { data } = await fetchMasterDataService(URLS.resourceStatuses);
      dispatch({
        type: FETCH_RESOURCE_STATUS_VALUES,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: FETCH_RESOURCE_STATUS_VALUES,
        payload: [],
      });
    }
  };

  export const fetchNewComponentsList = (kitId, page =0, size = 20) => async dispatch => {
    try {
      const { data } = await fetchMasterDataService(URLS.newComponentsList(kitId, page, size));
      dispatch({
        type: FETCH_NEW_COMPONENTS_LIST,
        payload: {content : data, ibaseComponents : false},
      });
      return {success: true, response: data};
    } catch (err) {
      dispatch({
        type: FETCH_NEW_COMPONENTS_LIST,
        payload: {},
      });
      return {success: false};
    }
  };

  export const addUpdateNewComponent = (valuesToSend, isNewComponent = false) => async dispatch => {    
    try{
      dispatch(changeLoader(true));
      const { data, status } = await fetchMasterDataServicePost(URLS.createNewComponent, valuesToSend);
      if (status === 200){
        dispatch(fetchNewComponentsList(valuesToSend.kitInformationId));
        dispatch(changeLoader(false));
        if (isNewComponent){
          dispatch(toggleActionMessage(true, 'success','newComponentAddeddSuccesfully'));
        } else{
          dispatch(toggleActionMessage(true, 'success','kitComponentUpdatedSuccesfully'));
        }
        scrollToTop(500);
        return {success : true}
      }
    } catch (error) {
      dispatch(changeLoader(false));
      dispatch(toggleActionMessage(true, 'error','somethingWentWrongMessage'));
      scrollToTop(500);
      return {success : false}
    }
  };

  export const deleteNewComponent = (componentId, kitId) => async dispatch => {
    try{
      dispatch(changeLoader(true));
      const { data , status} = await deleteMasterDataService(URLS.deleteComponent(componentId));
      if (status === 200){
        dispatch(fetchNewComponentsList(kitId));
        dispatch(changeLoader(false));
        dispatch(toggleActionMessage(true, 'success','newComponentDeletedSuccesfully'));
        scrollToTop(500);
        return {success : true}
      }
    } catch (error) {
      dispatch(changeLoader(false));
      dispatch(toggleActionMessage(true, 'error','somethingWentWrongMessage'));
      scrollToTop(500);
      return {success : false}
    }
  };

  export const fetchKitMessagesList = (kitId, page, size) => async dispatch => {
    const valuesToSend ={
      "page": page,
      "size": size,
      "graphql": "id,text,createdAt,createdBy[id,accountId,email,firstName,lastName,userAddresses[id,phone]]"
    }
    try {
      const { data } = await fetchMasterDataServicePost(URLS.kitMessagesList(kitId), valuesToSend);
      dispatch({
        type: FETCH_KIT_MESSAGES_LIST,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: FETCH_KIT_MESSAGES_LIST,
        payload: [],
      });
    }
  };


  export const createKitMessage = (kitId, text) => async dispatch => {
    try{
      const valuesToSend ={"text":text}
      dispatch(changeLoader(true));
      const { data , status} = await fetchMasterDataServicePost(URLS.createKitMessage(kitId), valuesToSend);
      if (status === 200){
        dispatch(changeLoader(false));
        dispatch(toggleActionMessage(true, 'success','messageAddedSuccesfully'));
        scrollToTop(500);
        return {success : true}
      }
    } catch (error) {
      dispatch(changeLoader(false));
      dispatch(toggleActionMessage(true, 'error','somethingWentWrongMessage'));
      scrollToTop(500);
      return {success : false}
    }
  };


  export const uploadNewKitAttachment = (dataToSend) => async dispatch => {
    try {
      const formData = new FormData();
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
    dataToSend.attachment.forEach((file) => {
      formData.append('attachment', file);
      formData.append('evaluation', dataToSend.evaluation === 'YES' ? true : dataToSend.evaluation === 'NO' ? false : null);
      formData.append('comment', dataToSend.comment);
      formData.append('fileName', dataToSend.fileName);
      formData.append('attachmentCategory', dataToSend.category);
      if(!dataToSend.comment){
        formData.delete("comment")
      }
      if(dataToSend.evaluation !== "YES" && dataToSend.evaluation !== "NO"){
        formData.delete("evaluation")
      }
    });
      await fetchMasterDataServicePost(URLS.kitNewAttachhmentsUpload(dataToSend.kitId), formData, config);
      dispatch(toggleActionMessage(true, 'success','iqsAttachmentUploadMessage'));
      scrollToTop(500);
      dispatch(getKitAttachmentList(dataToSend.kitId));
      return { success: true };
    } catch (error) {
      dispatch(changeLoader(false));
      dispatch(toggleActionMessage(true, 'error','somethingWentWrongMessage'))
      scrollToTop(500);
      return { success: false, error };
    }
  }

  export const fetchKitResourceAttachmentCategories= () => async dispatch => {
    try {
      const { data } = await fetchMasterDataService(URLS.getKitResourceAttachmentCategories);
      dispatch({
        type: FETCH_KIT_RESOURCE_ATTACHEMNT_CATEGORIES,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: FETCH_KIT_RESOURCE_ATTACHEMNT_CATEGORIES,
        payload: [],
      });
    }
  };

  const handleDataToSend = (data) => {
    let sendData = {
      fromDate: data?.dateRange ? data.dateRange[0] : null,
      toDate: data?.dateRange ? data.dateRange[1] : null,
      pageSize: 30,
      pageNumber: 0,
      searchText: data?.searchText ? data?.searchText : '',
      attachmentCategory: data?.attachmentCategory ? data.attachmentCategory : null
    }

    return sendData;
  }

  export const getKitAttachmentList = (kitId, dataToSend) => async dispatch => {
    try {
      const sendData = handleDataToSend(dataToSend)
      dispatch(changeLoader(true));
      const {data, status} = await fetchMasterDataServicePost(URLS.getAllKitAttachments(kitId), sendData);
      dispatch(changeLoader(false));
      if (status == 200){
        dispatch({
          type: KIT_NEW_ATTACHMENT_LIST,
          payload: data,
        });
        return { success: true };
      }
    } catch (error) {
      dispatch(changeLoader(false));
      dispatch({
        type: KIT_NEW_ATTACHMENT_LIST,
        payload: [],
      });
      return { success: false };
    }
  }

  export const deleteKitAttachment = (kitId, id) => async dispatch => {
    try {
      dispatch(changeLoader(true));
      await deleteMasterDataService(URLS.deleteKitAttchment(id));
      dispatch(changeLoader(false));
      dispatch(toggleActionMessage(true, 'success','deleteAttachmentMessage'));
      dispatch(getKitAttachmentList(kitId))
      scrollToTop(500);
      return { success: true };

    } catch (error) {
      dispatch(changeLoader(false));
      dispatch(toggleActionMessage(true, 'error','somethingWentWrongMessage'))
      scrollToTop(500);
      return { success: false };
    }
  };

  export const getIsMarketplaceActive = stockId => async dispatch => {
    try {
      const { data } = await fetchMasterDataService(URLS.marketplaceActive(stockId));
      dispatch({
        type: MARKETPLACE_ACTIVE_IN_LOCATION,
        payload: data,
      });
      return { success: true };
    } catch (error) {
      dispatch({
        type: MARKETPLACE_ACTIVE_IN_LOCATION,
        payload: null,
      });
      return { success: false };
    }
  };

  export const getSystemClassByProductHierarchy = (productHierarchyId) => async dispatch => {
    try {
      const res = await fetchMasterDataServicePost(URLS.systemClassByProductHierarchy, {ids: productHierarchyId});   
      dispatch({
        type: FETCH_SYSTEM_CLASSES_ZMCC_BU,
        payload: res?.data.map(item => ({ ...item, systemClassName: item.systemClassText })),
      });
      return { success: true };
    } catch (error) {
      dispatch({
        type: FETCH_SYSTEM_CLASSES_ZMCC_BU,
        payload: [],
      });
      return { success: false };
    }
  }
