import moment from 'moment';

import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import zeissInstance from 'library/utilities/AxiosInstance';
import { handlingSendData } from './CalendarActions';

export async function fetchKitListService(data) {
  const url = URLS.kitsList;
  return await zeissInstance.post(url, data);
}

export async function fetchStocksForCurrentUserService() {
  return await zeissInstance.get(URLS.countryList);
}

export async function checkBookingDatesAvailabilityService(data) {
  return await zeissInstance.post(URLS.transactionsSuggest, data);
}

export const fetchBookingsForKit = (data, startRangeDate) => {
  const sendData = handlingSendData(data);

  return zeissInstance.post(
    `${URLS.kitsList}?calendarSearch=true&startRange=${moment(startRangeDate).subtract(3, 'months').valueOf()}&endRange=${moment(
      startRangeDate,
    )
      .add(3, 'months')
      .valueOf()}`,
    sendData,
  );
};

export const fetchBookingsForLabsLocationKit = (data, startRangeDate) => {
  const location = {
    pathname: 'labs-location-calendar',
  };
  const sendData = handlingSendData(data, location);

  return zeissInstance.post(
    `${URLS.labLocationСalendar}?calendarSearch=true&startRange=${moment(startRangeDate)
      .subtract(3, 'months')
      .valueOf()}&endRange=${moment(startRangeDate).add(3, 'months').valueOf()}`,
    sendData,
  );
};

export const fetchBookingsForZmccKit = (data, startRangeDate) => {
  
  const sendData = handlingSendData(data);

  return zeissInstance.post(
    `${URLS.zmccCalendarSearch}?calendarSearch=true&startRange=${moment(startRangeDate)
      .subtract(3, 'months')
      .valueOf()}&endRange=${moment(startRangeDate).add(3, 'months').valueOf()}`,
    sendData,
  );
};

export const fetchZmccKitsBookingAndOperatorSchedules = (url, data) => {
  const sendData = handlingSendData(data);

  return zeissInstance.post(
    `${url}`,
    sendData,
  );
}