import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import strings from 'resources/locales/Translate';
import Button from 'library/common/commonComponents/Button/Button';
import Icon from 'library/common/commonComponents/Icon/Icon';
import Loader from 'library/common/commonComponents/Loader';
import { getChangeHistory } from '../../TransactionDetailsActions';
import ExpandableTable from 'library/common/commonComponents/Table/AppExpandableTable';
import { dataTable } from './dataTable';
import ItemsPerPage from 'library/common/commonComponents/ItemsPerPage/ItemsPerPage';
import toArray from 'lodash/toArray';
import { itemsPerPage } from '../../../../Booking/BookingConstants';
import Pagination from 'library/common/commonComponents/Pagination/Pagination';
import { changeLoader } from 'library/common/commonActions/AppActionsActions';

export const ChangeHistory = ({ changeLog, getChangeHistory, transactionDetails, transactionsId , language, match, transactionIdDummy, changeLoader }) => {
  const [loading, setLoading] = useState(false);
  const isZmccTrans = match && match.url.includes('isZmcc');
  const zmccTransactionId = match && match.params.id;
  const [localPage, setLocalPage] = useState(1);
  const [localSize, setLocalSize] = useState(20);
  const url = match.url;
  const localTranId =  url.match(/\/(\d+)(\/|\?|$)/);
  const [localTransId, setLocalTransId] = useState(isZmccTrans ? zmccTransactionId : localTranId &&  localTranId[1]);

  useEffect(() => {
    setLoading(true);
   
    if (isZmccTrans) {
      getChangeHistory({ page: 0, transactionsId: localTransId, size: 20 }).then(res => {
        setLoading(false);
      });
    }else{
      getChangeHistory({ page: 0, transactionsId: localTransId, size: 20 }).then(res => {
        setLoading(false);
      });
    }
    
  }, [language]);

  const calculateDate = date => {
    let diff = '';
    if (moment(date).isValid) {
      diff = `${moment.duration(moment().diff(moment(date))).humanize()} ago`;
    }
    return diff;
  };

  const onLoadMore = () => {
    setLoading(true);
    getChangeHistory({ transactionsId: localTransId, page: changeLog.number + 1 }).then(res => {
      setLoading(false);
    });
  };

  const makeBoldToHeaders = (text) => {
    text = text.replace(/([^,]+?):/g, '<b>$1:</b>');
    return text;
  }

  const handleItemsPerPage = async (size) => {
    setLocalSize(size);
    setLoading(true);
    changeLoader(true);
   await getChangeHistory({ transactionsId: localTransId, page: localPage, size: size }).then(res => {
      setLoading(false);
    });
    changeLoader(false);
  };

  const handlePageChange = async (page) => {
    setLocalPage(page+1);
    setLoading(true);
    changeLoader(true);
   await getChangeHistory({ transactionsId: localTransId, page: page+1, size: localSize  }).then(res => {
      setLoading(false);
    });
    changeLoader(false);
  };

  const renderChangeLog = () => {
    return (
      <>
        <ExpandableTable
            cols={dataTable(calculateDate, makeBoldToHeaders)}
            rows={changeLog.content}
            dontShowExpandArrow ={true}
        />
        {changeLog.content && changeLog.content.length &&
          <div className='col d-flex justify-content-center mb-3'>
              <ItemsPerPage
                itemsPerPage={toArray(itemsPerPage)}
                handleItemsPerPage={handleItemsPerPage}
                value={itemsPerPage[localSize]}
              />
              <Pagination
                currentPage={localPage-1}
                totalPages={changeLog.totalPages}
                onPageChange={handlePageChange}
              />
            </div>
        }
      </>
    );
  };

  return (
    <>
      {transactionDetails && transactionDetails.c2cLoan && (
        <div className={'blue-bg-message'}>
          <div className='container-fluid'>{strings.c2cLoanProcessText(transactionDetails.c2cId)}</div>
        </div>
      )}
      <div className='container-fluid pt-3 pb-3'>{changeLog.content || !loading ? renderChangeLog() : <Loader />}</div>
    </>
  );
};

const mapStateToProps = ({ appActionsReducer, authReducer, languageReducer, transactionDetailsReducer }) => ({
  changeLog: transactionDetailsReducer.changeLog,
  language: languageReducer,
  transactionDetails: transactionDetailsReducer.transactionDetails,
  transactionsId:
    transactionDetailsReducer.transactionDetails && transactionDetailsReducer.transactionDetails.transactionsId,
});

export default connect(
  mapStateToProps,
  { getChangeHistory, changeLoader },
)(ChangeHistory);
