import React, { useState } from 'react';
import cn from 'classnames';

import strings from 'resources/locales/Translate';
import Button from 'library/common/commonComponents/Button/Button';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';

import AttachmentsBlock from './AttachmentsBlock.component';
import styles from './KitAttachments.module.scss';

const Attachments = props => {
  const { kitDetailsAttachments, transactionAttachments, hasTransactionAttachmentsSection } = props;
  const [viewType, setViewType] = useState('list');
  const listClasses = cn('w-auto p-0 mr-3', {
    [styles.iconBlack]: viewType === 'list',
    [styles.iconGray]: viewType !== 'list',
  });
  const gridClasses = cn('w-auto p-0', {
    [styles.iconBlack]: viewType === 'grid',
    [styles.iconGray]: viewType !== 'grid',
  });

  const toggleToListViewType = () => {
    setViewType('list');
  };

  const toggleToGridViewType = () => {
    setViewType('grid');
  };

  return (
    <div className='kit-attachments-container'>
      <div className='container-fluid form-container col-12 d-flex'>
        <div className='col-6'>
          <AttachmentsBlock
            id='kitAttachmentsId'
            title={hasTransactionAttachmentsSection ? strings.kitAttachments : null}
            viewType={viewType}
            files={kitDetailsAttachments}
            downloadUrl={process.env.REACT_APP_BASE_URL + URLS.downloadAttachment}
          />
        </div>

        <div className='col-6'>
          {hasTransactionAttachmentsSection && (
            <AttachmentsBlock
              id='transactionAttachmentsId'
              title={strings.transactionAttachmentForKit}
              viewType={viewType}
              files={transactionAttachments}
              downloadUrl={process.env.REACT_APP_BASE_URL + URLS.downloadLoanAttachment}
            />
          )}
        </div>

      </div>
    </div>
  );
};

export default Attachments;
