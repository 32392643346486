import React from 'react';

import strings from 'resources/locales/Translate';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import Button from 'library/common/commonComponents/Button/Button';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import { fetchMasterDataService } from 'library/api/master-data.service';
import { dataExportBreadcrumbData } from './ExportData.constants';
import { useExportData } from './ExportData.hook';

const ExportData = ({
  appActions,
  businessUnit,
  fetchMasterData,
  exportTransactionList,
  exportKitList,
  exportKitListWithCountry,
  exportTransactionListWithCountry,
  language
}) => {
  document.title = 'Administration';
  const {
    displayActionMessage,
    type,
    message,
  } = appActions;
  const {
    exportDataForm,
    model,
    isTransactionLoading,
    isKitLoading,
    handleForm,
    handleExportTransactionData,
    handleExportKitData,
  } = useExportData({
    businessUnit,
    fetchMasterData,
    exportTransactionList,
    exportKitList,
    exportKitListWithCountry,
    fetchMasterDataService,
    exportTransactionListWithCountry,
    language
  });

  return (
    <div className='export-data-container'>
      {displayActionMessage && <ActionMessage type={type} message={strings[message]} />}
      <div className='container-fluid mb-3'>
        <Breadcrumbs data={dataExportBreadcrumbData} />
        <FormComponent
          model={model}
          formName='exportDataForm'
          formValue={exportDataForm.formData}
          onChange={handleForm}
          className='mb-3 d-flex'
          clear
          rowStyle='flex-column w-100'
        />
        <div className='row no-gutters'>
          <div className='col-md-auto mr-md-3'>
            <Button
              bgFill={false}
              iconName='download'
              onClick={handleExportTransactionData}
              value={strings.exportTransactionData}
              styleClass='mb-1'
              loading={isTransactionLoading}
            />
          </div>
          <div className='col-md-auto'>
            <Button
              bgFill={false}
              iconName='download'
              onClick={handleExportKitData}
              value={strings.exportKitData}
              styleClass='mb-1'
              loading={isKitLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExportData;
