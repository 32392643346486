import React from 'react';

import strings from 'resources/locales/Translate';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';

const CalculationBlock = props => {
  const { handleInput, copyCalculationList, isEditMode } = props;
  return (
    <div className='mb-5'>
      <div className='d-flex mb-3'>
        <p className='text-bold mr-3'>{strings.pointsPerHour}</p>
        <p className='text-bold mr-3'>{strings.operator} = ZEISS</p>
        <p className='text-bold mr-3'>{strings.operator} = Partner</p>
      </div>
      {copyCalculationList.length > 0 &&
        copyCalculationList.map((item, index) => (
          <>
            {isEditMode ? (
              <div className='d-flex mb-1'>
                <p className='mr-3 set-title-cursor-style' title={item.typesOfJobsResponseDTO.toolTipValue !== null ? item.typesOfJobsResponseDTO.toolTipValue : '' }>{item.typesOfJobsResponseDTO.name}</p>
                <InputField
                  dataTest={`operatorZeiss-input-field${index}`}
                  isRequired
                  onChange={value => handleInput(index, value, 'operatorZeiss')}
                  value={`${item.operatorZeiss.value}`}
                  className='mr-3'
                />
                <InputField
                  dataTest={`operatorPartner-input-field${index}`}
                  isRequired
                  onChange={value => handleInput(index, value, 'operatorPartner')}
                  value={`${item.operatorPartner.value}`}
                  className='mr-3'
                />
              </div>
            ) : (
              <div className='d-flex mb-3'>
                <p className='mr-3 set-title-cursor-style' title={item.typesOfJobsResponseDTO.toolTipValue !== null ? item.typesOfJobsResponseDTO.toolTipValue : '' }>{item.typesOfJobsResponseDTO.name}</p>
                <p className='mr-3'>{item.operatorZeiss.value}</p>
                <p className='mr-3'>{item.operatorPartner.value}</p>
              </div>
            )}
          </>
        ))}
    </div>
  );
};

export default CalculationBlock;
