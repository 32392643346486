import React from 'react';

import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import Modal from 'library/common/commonComponents/Modal';
import strings from 'resources/locales/Translate';

import { searchModel } from './OpportunitySearchPopup.constants';
import { useOpportunitySearchPopup } from './OpportunitySearchPopup.hook';

const OPPORTUNITY_SEARCH_ID_POPUP = 'opportunitySearchPopup';

const OpportunitySearchPopup = ({
  fetchOpportunitiesList,
  selectedKits,
  componentWarehouseForm,
  loanType,
  testing,
}) => {
  const { isLoading, error, opportunitySearchForm, handleForm, onCloseClick, onSaveClick } = useOpportunitySearchPopup({
    fetchOpportunitiesList,
    selectedKits,
    componentWarehouseForm,
    loanType,
  });

  return (
    <Modal
      id={OPPORTUNITY_SEARCH_ID_POPUP}
      title={strings.searchWithCustomerName}
      confirmAction={onSaveClick}
      confirmDataTest='system-class-button-subscribe'
      confirmTitle={strings.search}
      confirmIcon='search'
      loading={isLoading}
      disableBackgroundClose
      onCloseClick={onCloseClick}
    >
      <FormComponent
        model={searchModel}
        formName='opportunitySearchForm'
        formValue={opportunitySearchForm}
        onChange={handleForm}
      />
      {error && <div className='error-block'>{strings[error]}</div>}
    </Modal>
  );
};

export default OpportunitySearchPopup;
