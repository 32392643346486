import Validators from "library/utilities/Validators";

export const arrivalAndUnPackingModel = [
    {
        label: 'unpackedFrom',
        value: '',
        type: 'dropdown',
        placeholder: 'pleaseSelect',
        field: 'unpackedFrom',
        validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
        required: true,
        styleClass: 'col-12',
        options: [],
        hasSection: false,
        multiSelect: false,
        filter: true,
        idKey: 'userId',
        displayValue: [{ key: 'firstName', separator: ' ,' },{ key: 'lastName', separator: ' ' }],
      },

      {
        label: 'abnormalities',
        value: '',
        type: 'radio-button',
        placeholder: '',
        field: 'arrivalAbnormalities',
        validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
        required: true,
        styleClass: 'col-sm-12',
        options: [
          {
            id: 'yes',
            value: 'yes',
          },
          {
            id: 'no',
            value: 'no',
          },
        ],
      },

      {
        label: 'commentText',
        value: '',
        type: 'text',
        placeholder: '',
        field: 'arrivalAbnormalitiesComment',
        validators: [],
        required: false,
        styleClass: 'col-sm-12',
        isDisplayed: true
      },
      {
        label: 'onTimeDelivery',
        value: '',
        type: 'radio-button',
        placeholder: '',
        field: 'onTimeDelivery',
        validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
        required: true,
        styleClass: 'col-sm-12',
        options: [
          {
            id: 'yes',
            value: 'yes',
          },
          {
            id: 'no',
            value: 'no',
          },
        ],
      },
];

export const installationModel = [
    {
        label: 'setUpBy',
        value: '',
        type: 'dropdown',
        placeholder: 'pleaseSelect',
        field: 'setUpBy',
        validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
        required: true,
        styleClass: 'col-12',
        options: [],
        hasSection: false,
        multiSelect: false,
        filter: true,
        idKey: 'userId',
        displayValue: [{ key: 'firstName', separator: ' ,' },{ key: 'lastName', separator: ' ' }],
      },

      {
        label: 'completeness',
        value: '',
        type: 'radio-button',
        placeholder: '',
        field: 'arrivalCompleteness',
        validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
        required: true,
        styleClass: 'col-sm-12',
        options: [
          {
            id: 'yes',
            value: 'yes',
          },
          {
            id: 'no',
            value: 'no',
          },
        ],
      },

      {
        label: 'commentText',
        value: '',
        type: 'text',
        placeholder: '',
        field: 'arrivalCompletenessComment',
        validators: [],
        required: false,
        styleClass: 'col-sm-12',
        isDisplayed: true
      },

      {
        label: 'abnormalities',
        value: '',
        type: 'radio-button',
        placeholder: '',
        field: 'arrivalInstallationAbnormalities',
        validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
        required: true,
        styleClass: 'col-sm-12',
        options: [
          {
            id: 'yes',
            value: 'yes',
          },
          {
            id: 'no',
            value: 'no',
          },
        ],
      },

      {
        label: 'commentText',
        value: '',
        type: 'text',
        placeholder: '',
        field: 'arrivalInstallationAbnormalitiesComment',
        validators: [],
        required: false,
        styleClass: 'col-sm-12',
        isDisplayed: true
      },

];