import Validators from 'library/utilities/Validators';
import strings from 'resources/locales/Translate';

export const refurbishmentFeesBreadcrumbData = [
  {
    name: 'homepage',
    url: '/home',
  },
  {
    name: 'refurbishmentFees',
  },
];

export const FETCH_REFURBISHMENT_FEES_COUNTRY_LIST = 'FETCH_REFURBISHMENT_FEES_COUNTRY_LIST';

export const refurbishmentFeesModel = [
  {
    label: 'year',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'year',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3 mt-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'value', separator: '' }],
  },
  {
    label: 'month',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'month',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3 mt-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'value', separator: '' }],
  },
  {
    label: 'stock',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'stockIds',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3 mt-3',
    options: [],
    hasSection: true,
    multiSelect: true,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'locationName', separator: '' }],
    optionsArrayKey: 'stockInformation',
    titleDisplay: [
      { key: 'shortName', separator: ' ' },
      { key: 'name', separator: '' },
    ],
  },
  {
    label: 'loanType',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'type',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-3 mt-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'value', separator: '' }],
  },
];

