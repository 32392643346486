import moment from "moment";
import { useEffect, useState } from 'react';
import * as $ from 'jquery';
import strings from "resources/locales/Translate";

export const useAddEditSlotPopup = ({
  id,
  saveCreateAvailabilty,
  stockId,
  editingAvailabilitySlotData,
  editAvailabiltySlot,
  isEditMode,
  onEditClose,
  isPopupFromKit,
  kitData,
  warehouseId,
  editKitData
}) => {
  const test = editingAvailabilitySlotData && editingAvailabilitySlotData.kits;
  const [selectedKits, setSelectedKits] = useState({ value: null, fullValue: null});
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [recurring, setRecurring] = useState(false);
  const [selectedDays, setSelectedDays] = useState({
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
  });
  const [selectedKitsError, setSelectedKitsError] = useState('');
  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');
  const [selectedDaysError, setSelectedDaysError] = useState('')
  useEffect(() => {
    if(isEditMode){
      setDataForEditing();
    }

    if (isPopupFromKit) {
      setSelectedKits({value: [kitData.kitInformationId], fullValue: [kitData]})
      setDataForEditing();
    }

    
  }, [editingAvailabilitySlotData, isEditMode]);

  const setDataForEditing = () => {
    
    if (editingAvailabilitySlotData && editingAvailabilitySlotData.zmccWarehouseSlotId) {
      if (
        editingAvailabilitySlotData &&
        editingAvailabilitySlotData.kits &&
        editingAvailabilitySlotData.kits.length > 0
      ) {
        let kitIdValues = []; 
        let kitFullvalues = [];
        if (isPopupFromKit) {
          kitIdValues = [kitData?.kitInformationId];
          kitFullvalues = [kitData];
        }
        editingAvailabilitySlotData.kits.forEach(
          item => {
            if (item.kitInformationId !== kitData?.kitInformationId && isPopupFromKit) {
              kitIdValues.push(item.kitInformationId)
              kitFullvalues.push(item);
            } else if (!isPopupFromKit) {
              kitIdValues.push(item.kitInformationId);
              kitFullvalues.push(item)
            }{
              
            }
          }
        );
        setSelectedKits({ value: kitIdValues, fullValue: kitFullvalues });
      }
      const addedStartDate = moment(editingAvailabilitySlotData.startDate).format('YYYY-MM-DD');
      const addedEndDate = moment(editingAvailabilitySlotData.endDate).format('YYYY-MM-DD');
      setStartDate(new Date(addedStartDate));
      setEndDate(new Date(addedEndDate));
      setRecurring(editingAvailabilitySlotData.recurring);
      const initialDaysArray =
        editingAvailabilitySlotData.daysSelected &&
        editingAvailabilitySlotData.daysSelected.split(',').map(day => day.trim());
      if (initialDaysArray) {
        const updatedSelectedDays = { ...selectedDays };
        Object.keys(updatedSelectedDays).forEach(day => {
          updatedSelectedDays[day] = initialDaysArray.includes(day);
        });
        setSelectedDays(updatedSelectedDays);
      }
    }
  };

  const onChangeDropdown = (value, field, fullValue) => {
    setSelectedKitsError('');
    return setSelectedKits({ value, fullValue });
  };

  const onChangeDate = (date, type) => {
    if (type === 'start') {
      setStartDate(date);
      setStartDateError('')
    } else {
      setEndDate(date);
      setEndDateError('');
    }
  };

  const onChangeRecurring = () => {
    setRecurring(prevSate => !prevSate);
    setSelectedDaysError('')
  };

  const vaildateFields = () => {
    let isValid = true;
    if (selectedKits.value === null || selectedKits.value === "") {
      setSelectedKitsError('requiredErrorMessage');
      isValid = false;
    }
    if (startDate === '' || startDate === null) {
      setStartDateError('requiredErrorMessage');
      isValid = false;
    }
    if (endDate === '' || endDate === null) {
      setEndDateError('requiredErrorMessage');
      isValid = false;
    }
    return isValid
  }

  const onSaveClick = async () => {
    if (!vaildateFields()) {
      return;
    }
    const selectedDaysString = Object.keys(selectedDays)
      .filter(day => selectedDays[day])
      .map(day => day)
      .join(', ');

    if (recurring && selectedDaysString === '') {
      setSelectedDaysError(strings.slecteDaysError);
      return
    }
    const dataToSend = {
      warehouseId: (warehouseId !== undefined && warehouseId !== null )? warehouseId : stockId,
      kitIds: selectedKits.value,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      recurring: recurring,
      daysSelected: selectedDaysString ? selectedDaysString : '',
    };
    await (isEditMode && editingAvailabilitySlotData.zmccWarehouseSlotId)
      ? editAvailabiltySlot(dataToSend, editingAvailabilitySlotData.zmccWarehouseSlotId, isPopupFromKit)
      : saveCreateAvailabilty(dataToSend);

    onCloseClick();
  };

  const handleOnDayClick = day => {
    setSelectedDays(prevSelectedDays => ({
      ...prevSelectedDays,
      [day]: !prevSelectedDays[day],
    }));
    setSelectedDaysError('')
  };

  const onCloseClick = () => {
      setSelectedKits({value: null, fullValue: null});
      setEndDate('');
      setStartDate('');
      setRecurring(false);
      setSelectedDays({
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
      });
      setSelectedKitsError('');
      setSelectedDaysError('');
      setStartDateError('');
      setEndDateError('');
      onEditClose(false);
    $(`#${id}`).modal('hide');
  }

  return {
    onChangeDropdown,
    selectedKits,
    onChangeDate,
    startDate,
    endDate,
    onChangeRecurring,
    recurring,
    onSaveClick,
    handleOnDayClick,
    selectedDays,
    onCloseClick,
    selectedKitsError,
    startDateError,
    endDateError,
    selectedDaysError
  };
};