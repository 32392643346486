import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

const BarChart = ({ data, defaultOptions, height }) => {
  const options = {
    responsive: true,
    type:'bar',
    ...defaultOptions,
  };

  return (
    <Bar
      data={data}
      width={null}
      height={height ? height : null}
      options={options}
    />
  );
}

export default BarChart;
