import { validateForm } from 'library/utilities/ValidateForm.util';
import React, { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import strings from 'resources/locales/Translate';
import { emailModel, loanOrderModel } from './CreateNewLoanOrderPopup.constants';
import $ from 'jquery';
import Checkbox from 'library/common/commonComponents/Checkbox/Checkbox';
import Validators from 'library/utilities/Validators';

export const useLoanOrderPopup = props => {
  const {
    odertypeList,
    billingList,
    distributionChannelList,
    divisionsList,
    transactionDetails,
    loanType,
    saveCreateLoanorderData,
    id,
    transactionsType,
    detailPage,
    deliveryBlockList,
    getSapErpMappingData,
    getSapErpCustomerAccounts,
    customerGroupList,
    showReturnLoanOrderPopup,
    transactionMaterialNumber,
    getMedUsSearchCusmoter,
    user,
    isEditView,
    accountIdFromOppInterface
  } = props;
  const [model, setModal] = useState(loanOrderModel);
  const [loanOrderForm, setLoanOrderForm] = useState({
    formData: {},
  });

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [emailForm, setEmailForm] = useState({
    formData: {},
  });
  const [showEmailField, setShowEmailField] = useState(false);
  const [searchLoading, setSearchloading] = useState(false);
  const [selected, setSelected] = useState('');
  const [serachCustomerFeildsForm, setSerachCustomerFeildsForm] = useState({
    formData: {},
  });
  const [medUsCustomerData, setMedUsCustomerData] = useState(null);
  let { sapErpMappingData } = props;

  const checkBusinessUnit = () => {
    const businessUnits = ['MED', 'MED-OPT', 'MED-SUR'];
    const singleAndCourseLoanBusinessUnit = transactionDetails && transactionDetails.stockInformation;
    const kitLoanBusinessUnit =
      transactionDetails && transactionDetails.transactionKit && transactionDetails.transactionKit;
    const showCustomerGroupField =
      kitLoanBusinessUnit && kitLoanBusinessUnit.stockInformation
        ? businessUnits.includes(kitLoanBusinessUnit.stockInformation.businessUnit.name)
        : businessUnits.includes(singleAndCourseLoanBusinessUnit.businessUnit.name);
    return showCustomerGroupField;
  };

  const checkStockCountry = () => {
    const country = ['United States'];
    const singleAndCouserLoanCounty = transactionDetails && transactionDetails.stockInformation;
    const kitLoanCountry =
      transactionDetails && transactionDetails.transactionKit && transactionDetails.transactionKit;
    const UsCountry =
      kitLoanCountry && kitLoanCountry.stockInformation
        ? country.includes(kitLoanCountry.stockInformation.country.name)
        : country.includes(singleAndCouserLoanCounty.country.name);
    return UsCountry;
  };

  const isBusinessUnitMed = checkBusinessUnit();
  const usCountryStock = checkStockCountry();
  const showExtraSearchCustomerFileds = isBusinessUnitMed && usCountryStock;
  const viewDeatilesValues = () => {
    const {
      sapOrderType,
      distributionChannel,
      billing,
      ziPartner,
      sapZaPartner,
      divisions,
      sapItemNumber,
      customerIdSoldTo,
      customerIdShipTo,
      deliveryBlock,
      textsFormHeader,
      testxShippingInstructions,
      customerGroup,
    } = transactionDetails;
    setLoanOrderForm(prevState => ({
      formData: {
        ...prevState.formData,
        orderType: sapOrderType && sapOrderType.orderTypeId,
        orderTypeFullValue: sapOrderType && sapOrderType.orderTypeTranslated[0],
        distributionChannel: distributionChannel && distributionChannel.distributionChannelId,
        distributionChannelFullValue: distributionChannel && distributionChannel.distributionChannelTranslated[0],
        billing: billing && billing.billinglId,
        billingFullValue: billing && billing.billingTranslated[0],
        partner: ziPartner,
        orderData: sapZaPartner,
        division: divisions && divisions.divisionsId,
        divisionFullvalue: divisions && divisions.divisionsTranslated[0],
        itemNumber: sapItemNumber,
        customerId: customerIdSoldTo,
        goodsRecipientId: customerIdShipTo,
        deliveryBlock: deliveryBlock ? deliveryBlock.deliveryBlockId : '',
        deliveryBlockFullValue: deliveryBlock ? deliveryBlock.deliveryBlockTranslated[0] : '',
        formularHeaderText: textsFormHeader,
        shippingInstructions: testxShippingInstructions,
        customerGroup: customerGroup ? customerGroup.customerGroupId : '',
        customerGroupFullvalue: customerGroup ? customerGroup.billingTranslated[0] : '',
      },
    }));
  };
  useEffect(() => {
    const newModel = [...model];
    newModel[0].options = odertypeList;
    newModel[1].options = distributionChannelList;
    newModel[2].options = billingList;
    newModel[9].options = deliveryBlockList;
    newModel[0].disabled = showReturnLoanOrderPopup ? true : false;
    if (loanType === 'kit loan' || loanType === 'kitLoan') {
      newModel[5].options = divisionsList;
      newModel[5].disabled = true;
      newModel[6].disabled = true;
      newModel[6].isDisplayed = false;
      newModel[6].required = true;
      newModel[6].validators = [{ check: Validators.required, message: 'requiredErrorMessage' }];

      let preSelecteddivisionId;
      let preSelecteddivisionFullValue;
      if (
        transactionDetails &&
        transactionDetails.transactionKit.kitInformation &&
        transactionDetails.transactionKit.kitInformation.divisions
      ) {
        preSelecteddivisionId = transactionDetails.transactionKit.kitInformation.divisions.divisionsId;
        preSelecteddivisionFullValue = transactionDetails.transactionKit.kitInformation.divisions.divisionsTranslated;
      }

      if (!isEditView) {
        setLoanOrderForm(prevState => ({
          formData: {
            itemNumber:
              transactionDetails &&
              transactionDetails.transactionKit.kitInformation &&
              transactionDetails.transactionKit.kitInformation.itemNumber,
            division: preSelecteddivisionId,
            divisionFullvalue: preSelecteddivisionFullValue,
            formularHeaderText: transactionDetails && transactionDetails.comment,
            shippingInstructions: transactionDetails && transactionDetails.deliveryComment,
            orderType: showReturnLoanOrderPopup ? 3 : '',
            orderTypeFullValue: showReturnLoanOrderPopup ? { id: 3, name: 'ZLA' } : '',
          },
        }));
      }
    } else {
      newModel[5].options = divisionsList;
      newModel[5].disabled = false;
      newModel[6].disabled = false;
      newModel[6].isDisplayed = true;
      newModel[6].required = false;
      newModel[6].validators = [];
      newModel[0].disabled = showReturnLoanOrderPopup ? true : false;

      setLoanOrderForm(prevState => ({
        formData: {
          formularHeaderText: transactionDetails && transactionDetails.comment,
          shippingInstructions: transactionDetails && transactionDetails.deliveryComment,
          orderType: showReturnLoanOrderPopup ? 3 : '',
          orderTypeFullValue: showReturnLoanOrderPopup ? { id: 3, name: 'ZLA' } : '',
        },
      }));
    }

    if (isBusinessUnitMed) {
      newModel[12].options = customerGroupList;
      newModel[12].isDisplayed = false;
      if (customerGroupList && customerGroupList.length > 0) {
        setLoanOrderForm(prevState => ({
          formData: {
            ...prevState.formData,
            customerGroup: customerGroupList[0].id,
            customerGroupFullvalue: customerGroupList[0].name,
          },
        }));
      }
    } else {
      newModel[12].isDisplayed = true;
    }
    setModal(newModel);

    setEmailForm({
      formData: {
        email: transactionDetails && transactionDetails.email,
      },
    });
    if(accountIdFromOppInterface && accountIdFromOppInterface != false) {
      setLoanOrderForm(prevState => ({
        formData: {
          ...prevState.formData,
          customerId: accountIdFromOppInterface,
          goodsRecipientId: accountIdFromOppInterface,
        },
      }));
    }
    else if (sapErpMappingData && sapErpMappingData.length === 1) {
      setLoanOrderForm(prevState => ({
        formData: {
          ...prevState.formData,
          customerId: sapErpMappingData[0].accountId,
          goodsRecipientId: sapErpMappingData[0].accountId,
        },
      }));
    }
    if (isEditView) {
      if (transactionDetails) {
        viewDeatilesValues();
      }
    }
  }, [
    odertypeList,
    billingList,
    distributionChannelList,
    divisionsList,
    loanType,
    deliveryBlockList,
    sapErpMappingData,
    customerGroupList,
    showReturnLoanOrderPopup,
    isEditView,
    transactionDetails,
    accountIdFromOppInterface
  ]);

  useEffect(() => {
    if (transactionDetails) {
      fetchSapErpMappingData();
    }
  }, []);

  const handleForm = useCallback(({ values, field, formName }) => {
    if (field == 'partner') {
      values['partner'] = values['partner'].toUpperCase();
      setLoanOrderForm(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          ...values,
        },
      }));
    }
    if (field == 'orderData') {
      values['orderData'] = values['orderData'].toUpperCase();
      setLoanOrderForm(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          ...values,
        },
      }));
    }
    if (formName === 'emailForm') {
      setEmailForm(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          ...values,
        },
      }));
    }
    if (formName === 'searchCustomerFeildsForm') {
      setSerachCustomerFeildsForm(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          ...values,
        },
      }));
    }
    return setLoanOrderForm(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        ...values,
      },
    }));
  }, []);

  const onSave = useCallback(async () => {
    setError('');
    const validForm = validateForm({
      form: loanOrderForm,
      model,
    });

    if (!validForm.isFormValid) {
      return setLoanOrderForm(validForm);
    }
    setIsLoading(true);

    const {
      orderType,
      orderTypeFullValue,
      distributionChannelFullValue,
      partner,
      distributionChannel,
      billingFullValue,
      billing,
      orderData,
      division,
      itemNumber,
      customerId,
      goodsRecipientId,
      deliveryBlockFullValue,
      formularHeaderText,
      shippingInstructions,
      customerGroup,
    } = validForm.formData;
    let page;
    const dataToSend = {
      orderTypeId: orderType,
      distributionChannelId: distributionChannel,
      billingId: billing,
      partner: partner,
      orderData: orderData,
      divisionId: division,
      itemNumber: itemNumber ? itemNumber : '',
      transactionsId: transactionDetails.transactionsId,
      orderTypeName: orderTypeFullValue.name,
      distributionChannelName: distributionChannelFullValue.name,
      billingName: billingFullValue.name,
      customerId,
      goodsRecipientId,
      deliveryBlockId: deliveryBlockFullValue.id,
      deliveryBlockName: deliveryBlockFullValue.name,
      formularHeaderText,
      shippingInstructions,
      customerGroupId: customerGroup,
    };
    const manualLoanOderDataToSend = {
      orderTypeId: null,
      distributionChannelId: distributionChannel,
      billingId: billing,
      partner: partner,
      orderData: orderData,
      divisionId: division,
      itemNumber: itemNumber ? itemNumber : '',
      transactionsId: transactionDetails.transactionsId,
      customerId,
      goodsRecipientId,
      formularHeaderText,
      shippingInstructions,
      customerGroupId: customerGroup,
    };
    const { success, err } = await saveCreateLoanorderData(
      dataToSend,
      transactionDetails.transactionsId,
      detailPage,
      transactionsType,
      page,
      showReturnLoanOrderPopup,
      manualLoanOderDataToSend,
      transactionMaterialNumber,
    );

    setIsLoading(false);

    if (!success) {
      if (err && err.response && err.response.status === 422) {
        onCloseClick();
      } else {
        setError(strings.somethingWentWrongMessage);
      }
    } else {
      onCloseClick();
    }
  }, [loanOrderForm, saveCreateLoanorderData]);

  const onCloseClick = () => {
    if (loanType === 'kit loan' || loanType === 'kitLoan') {
      let preSelecteddivisionId;
      let preSelecteddivisionFullValue;
      if (
        transactionDetails &&
        transactionDetails.transactionKit.kitInformation &&
        transactionDetails.transactionKit.kitInformation.divisions
      ) {
        preSelecteddivisionId = transactionDetails.transactionKit.kitInformation.divisions.divisionsId;
        preSelecteddivisionFullValue = transactionDetails.transactionKit.kitInformation.divisions.divisionsTranslated;
      }

      setLoanOrderForm(prevState => ({
        formData: {
          itemNumber:
            transactionDetails &&
            transactionDetails.transactionKit.kitInformation &&
            transactionDetails.transactionKit.kitInformation.itemNumber,
          division: preSelecteddivisionId,
          divisionFullvalue: preSelecteddivisionFullValue,
          orderType: showReturnLoanOrderPopup ? 3 : '',
          orderTypeFullValue: showReturnLoanOrderPopup ? { id: 3, name: 'ZLA' } : '',
        },
      }));
      if (accountIdFromOppInterface && accountIdFromOppInterface != false) {
        setLoanOrderForm(prevState => ({
          formData: {
            ...prevState.formData,
            customerId: accountIdFromOppInterface,
            goodsRecipientId: accountIdFromOppInterface,
          },
        }));
      }
      else if (sapErpMappingData && sapErpMappingData.length === 1) {
        setLoanOrderForm(prevState => ({
          formData: {
            ...prevState.formData,
            customerId: sapErpMappingData[0].accountId,
            goodsRecipientId: sapErpMappingData[0].accountId,
          },
        }));
      }
      if (isBusinessUnitMed) {
        if (customerGroupList && customerGroupList.length > 0) {
          setLoanOrderForm(prevState => ({
            formData: {
              ...prevState.formData,
              customerGroup: customerGroupList[0].id,
              customerGroupFullvalue: customerGroupList[0].name,
            },
          }));
        }
      }
    } else {
      if (sapErpMappingData && sapErpMappingData.length === 1) {
        setLoanOrderForm(prevState => ({
          formData: {
            customerId: sapErpMappingData[0].accountId,
            goodsRecipientId: sapErpMappingData[0].accountId,
          },
        }));
      }
      if (isBusinessUnitMed) {
        if (customerGroupList && customerGroupList.length > 0) {
          setLoanOrderForm(prevState => ({
            formData: {
              ...prevState.formData,
              customerGroup: customerGroupList[0].id,
              customerGroupFullvalue: customerGroupList[0].name,
              orderType: showReturnLoanOrderPopup ? 3 : '',
              orderTypeFullValue: showReturnLoanOrderPopup ? { id: 3, name: 'ZLA' } : '',
            },
          }));
        }
      } else {
        setLoanOrderForm(prevState => ({
          formData: {
            ...prevState.formData,
            orderType: showReturnLoanOrderPopup ? 3 : '',
            orderTypeFullValue: showReturnLoanOrderPopup ? { id: 3, name: 'ZLA' } : '',
          },
        }));
      }
    }
    if (isEditView) {
      if (transactionDetails) {
        viewDeatilesValues();
      }
    }
    setSerachCustomerFeildsForm({
      formData: {},
    });
    setShowEmailField(false);
    setError('');
    closeLoanOrderPopup();
    setSelected('');
  };

  const closeLoanOrderPopup = useCallback(() => {
    $(`#${id}`).modal('hide');
  }, []);

  const fetchSapErpMappingData = async () => {
    const { country, postalCode, city, street, houseNumber } = transactionDetails;
    const dataToSend = {
      countryId: country.id,
      postalCode: postalCode,
      city: city,
      street: street,
      houseNumber: houseNumber,
    };
    await getSapErpMappingData(dataToSend);
  };

  const handleClick = (accountId, e) => {
    if (e) {
      setLoanOrderForm(prevState => ({
        formData: {
          ...prevState.formData,
          customerId: accountId,
          goodsRecipientId: accountId,
        },
      }));
      setSelected(accountId);
    } else {
      setSelected('');
      setLoanOrderForm(prevState => ({
        formData: {
          ...prevState.formData,
          customerId: '',
          goodsRecipientId: '',
        },
      }));
    }
  };

  const searchWithCustomer = () => {
    setShowEmailField(true);
    setError('');
  };

  const searchForSapCustomerAccounts = async () => {
    const validForm = validateForm({
      form: emailForm,
      model: emailModel,
    });

    if (!validForm.isFormValid) {
      return setEmailForm(validForm);
    }
    setSearchloading(true);
    const { email } = validForm.formData;
    const result = await getSapErpCustomerAccounts(email, sapErpMappingData);

    if (!result.success) {
      setError('No result found');
    }
    setSearchloading(false);
    setShowEmailField(false);
  };

  const getTableRows = () => [
    {
      title: '',
      name: '',
      isSort: false,
      render: item => {
        return (
          <Checkbox
            onClick={e => handleClick(item.accountId, e)}
            selected={selected === item.accountId}
            customeStyledClass='sap-erp-table-checkbox'
            small
          />
        );
      },
    },
    {
      title: strings.accountId,
      name: 'accountId',
      isSort: false,
      render: item => {
        return <span style={{ cursor: 'pointer' }}>{item.accountId}</span>;
      },
    },
    {
      title: strings.accountName,
      name: 'accountName',
      isSort: false,
      render: item => {
        return <span style={{ cursor: 'pointer' }}>{item.accountName || ''}</span>;
      },
    },
  ];
  const checkEnableSerchButton = () => {
    if (
      (serachCustomerFeildsForm.formData.accountName !== undefined &&
        serachCustomerFeildsForm.formData.accountName !== '') ||
      (serachCustomerFeildsForm.formData.email !== undefined && serachCustomerFeildsForm.formData.email !== '') ||
      (serachCustomerFeildsForm.formData.contactFirstName !== undefined &&
        serachCustomerFeildsForm.formData.contactFirstName !== '') ||
      (serachCustomerFeildsForm.formData.contactLastName !== undefined &&
        serachCustomerFeildsForm.formData.contactLastName !== '') ||
      (serachCustomerFeildsForm.formData.city !== undefined && serachCustomerFeildsForm.formData.city !== '')
    ) {
      return false;
    } else {
      return true;
    }
  };
  const enableButton = checkEnableSerchButton();
  const medUsSearchCustomer = async () => {
    setSearchloading(true);
    const { accountName, city, contactFirstName, contactLastName, email } = serachCustomerFeildsForm.formData;
    const dataToSend = {
      email: email ? email : null,
      customerName: accountName ? accountName : null,
      customerFirstName: contactFirstName ? contactFirstName : null,
      customerLastName: contactLastName ? contactLastName : null,
      city: city ? city : null,
    };
    const currentLoggedInUser = user && user.email;
    const { success, data } = await getMedUsSearchCusmoter(dataToSend, currentLoggedInUser);
    if (success) {
      setMedUsCustomerData(data.CustomerRecord);
    } else if (!success) {
      setError('Customer does not exist or is not valid. Create the user in SAP ERP first.');
      setShowEmailField(false);
      setMedUsCustomerData(null);
    }
    setShowEmailField(false);
    setSearchloading(false);
    setSerachCustomerFeildsForm({ formData: {} });
  };
  const getmedUsCustomerDataRows = () => [
    {
      title: strings.customerName,
      name: 'customerName',
      isSort: false,
      render: item => {
        return <span style={{ cursor: 'pointer' }}>{item.customerName || ''}</span>;
      },
    },
    {
      title: strings.customerType,
      name: 'customerType',
      isSort: false,
      render: item => {
        return <span style={{ cursor: 'pointer' }}>{item.customerType || ''}</span>;
      },
    },
    {
      title: strings.street,
      name: 'street',
      isSort: false,
      render: item => {
        return <span style={{ cursor: 'pointer' }}>{item.customerStreet || ''}</span>;
      },
    },
    {
      title: strings.state,
      name: 'state',
      isSort: false,
      render: item => {
        return <span style={{ cursor: 'pointer' }}>{item.customerState || ''}</span>;
      },
    },
    {
      title: strings.city,
      name: 'city',
      isSort: false,
      render: item => {
        return <span style={{ cursor: 'pointer' }}>{item.customerCity || ''}</span>;
      },
    },
    {
      title: strings.country,
      name: 'country',
      isSort: false,
      render: item => {
        return <span style={{ cursor: 'pointer' }}>{item.customerCountry || ''}</span>;
      },
    },
    {
      title: strings.accountIdSap,
      name: 'accountId',
      isSort: false,
      render: item => {
        return <span style={{ cursor: 'pointer' }}>{item.customerNumberSAP || ''}</span>;
      },
    },
  ];
  return {
    model,
    handleForm,
    loanOrderForm,
    onSave,
    isLoading,
    onCloseClick,
    sapErpMappingData,
    handleClick,
    emailForm,
    searchWithCustomer,
    showEmailField,
    searchForSapCustomerAccounts,
    getTableRows,
    searchLoading,
    fetchSapErpMappingData,
    searchForSapCustomerAccounts,
    error,
    showExtraSearchCustomerFileds,
    serachCustomerFeildsForm,
    enableButton,
    medUsSearchCustomer,
    getmedUsCustomerDataRows,
    medUsCustomerData,
  };
};
