import { useState, useEffect } from 'react';

import strings from 'resources/locales/Translate';

export const useStatistics = ({ getInitialData, language, getDataOnLanguageChange }) => {
  const getLoanTypes = () => [
    {
      id: 1,
      name: strings.kitLoan,
      valueToSend: 'kit loan',
    },
    {
      id: 2,
      name: strings.singleLoan,
      valueToSend: 'single loan',
    },
    {
      id: 3,
      name: strings.courseLoan,
      valueToSend: 'course loan',
    },
  ];
  const [isShowFilter, setIsShowFilter] = useState(true);

  useEffect(() => {
    document.title = `${strings.statistics}`
    getInitialData();
  }, [getInitialData]);

  useEffect(() => {
    getDataOnLanguageChange();
  }, [language, getDataOnLanguageChange]);

  const showOrHideFilter = () => {
    setIsShowFilter(prevState => !prevState);
  };

  const loanTypes = getLoanTypes();

  return {
    isShowFilter,
    loanTypes,
    showOrHideFilter,
  };
}
