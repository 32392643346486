import moment from "moment";
import { useState, useEffect } from "react";
import * as $ from 'jquery';
import { saveToStorage } from "library/utilities/storage";
import { identifiers } from "library/common/commonConstants/IdentifiersConstants";

export const useBulkConfirmationPopup = ({
  id,
  data,
  bundleLoans,
  cartItems,
  addItemToCart,
  history,
  removeItemFromCart,
  removeItemFromCartForBulk,
  setSelectedData,
  setShowBulkConfirmPopup,
}) => {
  const [transactionsId, setTransactionsId] = useState('');
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  useEffect(() => {
    if (data !== null) {
      setTransactionsId([data.transactionsId]);
      createCartData(data);
    }
  }, [data]);
  const filteredBundleLoans =
    bundleLoans && bundleLoans.length > 0 && bundleLoans.filter(item => item.twentyFourHoursReservation);
  const handleSelect = data => () => {
    const ids = [...transactionsId];
    const id = data.transactionsId;
    if (ids.includes(id)) {
      ids.splice(ids.indexOf(id), 1);
    } else {
      ids.push(id);
    }
    createCartData(data);
    const isAllCheckbox = ids.length === filteredBundleLoans.length + 1;
    if (isAllCheckbox) {
      setIsSelectedAll(true);
    } else if (isSelectedAll && !isAllCheckbox) {
      setIsSelectedAll(false);
    }
    setTransactionsId(ids);
  };

  const handleSelectAll = () => {
    if (isSelectedAll) {
      setTransactionsId([data.transactionsId]);
    } else {
      const ids = filteredBundleLoans.map(item => item.transactionsId);
      filteredBundleLoans.forEach(element => {
        createCartData(element);
      });
      setTransactionsId([data.transactionsId, ...ids]);
    }
    setIsSelectedAll(prevState => !prevState);
  };

  const createCartData = (data, removedDataOnClosePopup) => {
    const item = {};
    let index = -1;
    index =
      cartItems && cartItems.length ? cartItems.findIndex(item => item.transactionsId === data.transactionsId) : -1;

    item.id = Math.floor(Math.random() * 20);
    item.title = '';
    item.isSelected = true;
    item.isBooked = false;
    item.hideOnCalendar = true;
    item.start = moment(data.targetReservationFrom).format('YYYY-MM-DD 00:00:00');
    item.end = moment(data.targetReservationTo).format('YYYY-MM-DD 23:59:59');
    item.resourceId = data.transactionKit ? data.transactionKit.kitInformation.kitInformationId : data.kitId;
    item.kit = {
      id: data.transactionKit ? data.transactionKit.kitInformation.kitInformationId : data.kitId,
      kitImages: [],
      kitInformationId: data.transactionKit ? data.transactionKit.kitInformation.kitInformationId : data.kitId,
      kitName: data.transactionKit ? data.transactionKit.kitInformation.kitName : data.kitName,
      name: data.transactionKit ? data.transactionKit.kitInformation.kitName : data.kitName,
      stockInformation: data.transactionKit
        ? data.transactionKit.stockInformation
        : { id: data.stockId, locationName: data.stockLocationName, locationShortName: data.stockLocationShortName },
      transactionKit: [
        {
          transactions: {
            targetReservationFrom: data.targetReservationFrom,
            targetReservationTo: data.targetReservationTo,
          },
        },
      ],
      twentyFourHourReservation: true,
    };
    item.transactionsId = data.transactionsId;
    item.isAvailabilityChecked = true;
    item.isLabsLocation = false;
    if (index === -1 && !removedDataOnClosePopup) {
      addItemToCart([item]);
    } else {
      removeItemFromCartForBulk([item]);
    }
  };

  const onCloseClick = () => {
    $(`#${id}`).modal('hide');
    setSelectedData(null);
    filteredBundleLoans.forEach(element => {
      createCartData(element, true);
    });
    createCartData(data, true);
    setShowBulkConfirmPopup(false);
  };

  const onCirfirmClick = () => {
    $(`#${id}`).modal('hide');
    setSelectedData(null);
    setShowBulkConfirmPopup(false);
    history.push('/kit-loan');
  };

  return {
    handleSelect,
    handleSelectAll,
    transactionsId,
    isSelectedAll,
    onCirfirmClick,
    onCloseClick,
    filteredBundleLoans,
  };
};