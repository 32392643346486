import React from 'react';
import KitPictureListView from './KitPictureListView.component';

function KitPictureUploader(props) {
  async function handleRemove(index) {
    const { files, maxFiles, onMaxFileReached, onRemove } = props;
    await onRemove(index);
    if ((files && files.length - 1) < maxFiles) {
      onMaxFileReached(false);
    }
  }

  const {
    files,
    onFileAdd,
    viewType,
    mimeType,
    maxSize,
    isUploadAllowed,
    isDownloadable,
    downloadStream,
    downloadUrl,
    downloadIdField,
    onReject,
    maxFiles,
    onMaxFileReached,
    availableTypes,
    errorMessage,
    isNotRemoved,
    isUploader,
    uploaderStyle,
    newVersionUi,
    maxFilesError
  } = props;
  let disableOnlyUpload = true;
  if (maxFiles > -1) {
    disableOnlyUpload = files && files.length < maxFiles;
  }
  if (!disableOnlyUpload) {
    onMaxFileReached(true);
  }

  return (
    <div className='file-uploader'>
      <KitPictureListView
        files={files}
        maxSize={maxSize}
        maxFiles={maxFiles}
        mimeType={mimeType}
        viewType={viewType}
        onFileAdd={onFileAdd}
        downloadUrl={downloadUrl}
        downloadStream={downloadStream}
        isDownloadable={isDownloadable}
        downloadIdField={downloadIdField}
        isUploadAllowed={isUploadAllowed}
        onMaxFileReached={onMaxFileReached}
        disableOnlyUpload={disableOnlyUpload}
        onReject={onReject}
        onRemove={index => handleRemove(index)}
        availableTypes={availableTypes}
        errorMessage={errorMessage}
        isNotRemoved={isNotRemoved}
        isUploader={isUploader}
        uploaderStyle={uploaderStyle}
        newVersionUi={newVersionUi}
        maxFilesError={maxFilesError} 
      />
    </div>
  );
}

export default KitPictureUploader;
