import React from 'react';
import Attachments from 'library/common/commonComponents/AttachmentsForm';
import strings from 'resources/locales/Translate';
import Button from 'library/common/commonComponents/Button/Button';

export const ZmccAttachements = ({formValues,updateSEMForm, updateZmccTransaction, formType, showAllButton}) =>{
  const onSaveClick = () =>{
    updateZmccTransaction(formType);
  }
  return(
    <div className='container-fluid'>
      <Attachments
      text='Attach File'
      id='kitAttachmentsId'
      formValue={formValues}
      hasVideo={true}
      handleForm={updateSEMForm}
      actionFrom='zmccAttachement'
      isDownloadable
      downloadStream
      downloadIdField='kitAttachmentsId'
      isUploadAllowed={false}
      uploaderStyle={"height-increased-dropzone"}
      newVersionUi={true}
      showHeading={false}
    />
      <div className='mt-4'>
        <Button
          value={strings.save}
          onClick={onSaveClick}
          bgFill={true}
          disabled={!showAllButton}
          styleClass='save-button-zmcc-attachments'
        />
      </div>
    </div>
  ) 
}

export default ZmccAttachements;