import React, { useEffect, useState } from 'react';
import strings from 'resources/locales/Translate';
import Icon from 'library/common/commonComponents/Icon/Icon';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import moment from 'moment';
import $ from 'jquery';
import { paths } from './MyQueries.constants';

export const useMyQueries = ({fetchMyQueriesList, deleteMyQuery}) => {
  const [data, setData] = useState({
    searchKey: '',
    page: 0,
    size: 10,
    sort: { createdAt: 'desc' },
  });

  const [iseditClicked, setIsEditClicked] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  useEffect(() => {
    fetchMyQueriesList(data);
  },[data])
  const handleSort = name => {
    setData(prevState => {
      const newData = cloneDeep(prevState);
      Object.keys(newData.sort).forEach(key => (newData.sort[key] = null));
      newData.sort[name] = prevState.sort[name] ? (prevState.sort[name] === 'asc' ? 'desc' : 'asc') : 'asc';
      return newData;
    });
  };

  const handleItemsPerPage = size => {
    setData(prevState => {
      const newData = { ...prevState };
      newData.size = size;

      return newData;
    });
  };

  const handlePageChange = page => {
    setData(prevState => {
      const newData = { ...prevState };
      newData.page = page ;

      return newData;
    });
  };

  const handleSearchChange = e => {
    const { value } = e.target;
    debounceUpdateData({ value });
  };

  const debounceUpdateData = debounce(({ value }) => {
    setData(prevState => {
      const newData = { ...prevState };
      newData.page = 0;
      newData.searchKey = value;
      return { ...newData };
    });
  }, 1000);

  const openEditQuery = (item) => {
    setSelectedData(item)
    $('#edit-save-query').modal('show');
    setIsEditClicked(true);
  }

  const onDeleteClick = async (id) => {
    await deleteMyQuery(id)
  }

  const getTableRows = () => [
    {
      title: strings.dateCreated,
      name: 'createdAt',
      isSort: true,
      render: item => {
        return (
          <span>
            <NavLink
              to={`${paths[item.searchType]}?safeSearch=${item.safeSearchId}`}
              className='launch-filter-text-color'
            >
              {moment(item.createdAt).format('YYYY-MM-DD') || ''}
            </NavLink>
          </span>
        );
      },
    },
    {
      title: strings.name,
      name: 'searchName',
      isSort: true,
      render: item => {
        return (
          <NavLink
            to={`${paths[item.searchType]}?safeSearch=${item.safeSearchId}`}
            className='launch-filter-text-color'
          >
            <span>{item.searchName || ''}</span>
          </NavLink>
        );
      },
    },
    {
      title: strings.descriptionText,
      name: 'searchDescription',
      isSort: true,
      render: item => {
        return (
          <NavLink
            to={`${paths[item.searchType]}?safeSearch=${item.safeSearchId}`}
            className='launch-filter-text-color'
          >
            <span>{item.searchDescription || ''}</span>
          </NavLink>
        );
      },
    },
    {
      title: '',
      name: 'edit',
      isSort: false,
      render: item => {
        return (
          <>
            <button className='button-blue' onClick={() => openEditQuery(item)}>
              <Icon name='edit' width={21} height={21} fill='#0088d0' />
              <span className='ml-1'>{strings.edit}</span>
            </button>
          </>
        );
      },
    },
    {
      title: '',
      name: 'delete',
      isSort: false,
      render: item => {
        return (
          <button className='button-blue' onClick={() => onDeleteClick(item.safeSearchId)}>
            <Icon name='newDeleteIcon' width={18} height={18} fill='#0088d0' viewBox='0 0 21 21' />
            <span className='ml-1'>{strings.delete}</span>
          </button>
        );
      },
    },
  ];

  return {
    getTableRows,
    data,
    handleSort,
    handleItemsPerPage,
    handlePageChange,
    handleSearchChange,
    selectedData,
    iseditClicked,
    setIsEditClicked,
  };
};
