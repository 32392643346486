import React from 'react';

import ModalPortal from 'library/common/commonComponents/ModalPortal';
import Button from 'library/common/commonComponents/Button/Button';
import Tabs from 'library/common/commonComponents/Tabs/Tabs';

import MonthBlock from '../MonthBlock';

const FullScreen = ({
  isOpenFullScreen,
  onCloseFullScreen,
  tabs,
  activeTabName,
  updateActiveTabName,
}) => {
  if (!isOpenFullScreen) return null;

  return (
    <ModalPortal onClose={onCloseFullScreen} className='fullScreen'>
      <div className='stock-dashboard-container fullScreen'>
        <div className='fullScreen-container'>
          <Button
            dataTest='fullScreen-close-button'
            bgFill={false}
            isGhost={true}
            iconName='cross'
            onClick={onCloseFullScreen}
            styleClass='w-auto ml-auto'
            iconWidth={32}
            iconHeight={32}
          />
          <div className='fullScreen-content'>
            <MonthBlock />
            <Tabs tabs={tabs} currentTab={activeTabName} saveCurrentTab={updateActiveTabName} />
          </div>
        </div>
      </div>
    </ModalPortal>
  );
};

export default FullScreen;
