import {URLS} from '../common/commonConstants/ApiUrlConstants';
import { fetchFromStorage } from './storage';
import { fetchMasterDataService } from 'library/api/master-data.service';
import { saveToStorage } from 'library/utilities/storage';
import { identifiers } from '../common/commonConstants/IdentifiersConstants';

let isAccessTokenCheckCalled = false;
export function checkSessionExpiry(data) {
  localStorage.clear();
  window.location.reload();
}
export async function checkAccessTokenExpiry() {
  if(!isAccessTokenCheckCalled) {
    isAccessTokenCheckCalled = true;
    saveToStorage("accessTokenCheckCalled", "true");
    const loginId = fetchFromStorage(identifiers.login_id);
    const { data } = await fetchMasterDataService(URLS.getRefreshToken+"?loginId="+loginId);
    saveToStorage(identifiers.access_token, data.access_token);
    saveToStorage(identifiers.access_token_expiry, data.access_token_expiry);
    saveToStorage("accessTokenCheckCalled", "false");
    isAccessTokenCheckCalled = false;
  }
}
