import { useEffect, useState, useCallback } from 'react';

export const useManageKitPictures = ({
  systemClasses,
  getBusinessUnits,
  getSystemClassesByBU,
  fetchKitsPictures,
  uploadKitsPicture,
  deleteKitsPicture,
  clearKitPictures,
}) => {
  const [filters, setFilters] = useState({
    businessUnit: null,
    businessUnitFullValue: null,
    systemClass: null,
    systemClassFullValue: null,
  });
  const [mainComponent, setMainComponent] = useState(null);
  const [isShowFilter, setIsShowFilter] = useState(true);
  const [deletePendingId, setDeletePendingId] = useState(null);

  useEffect(() => {
    getBusinessUnits();

    return () => clearKitPictures();
  }, [getBusinessUnits, clearKitPictures]);

  useEffect(() => {
    if (filters.businessUnitFullValue) {
      getSystemClassesByBU(filters.businessUnitFullValue.name);
    }
  }, [filters.businessUnitFullValue, getSystemClassesByBU]);

  useEffect(() => {
    if (filters.businessUnit && filters.systemClass && mainComponent) {
      fetchKitsPictures(filters.businessUnit, filters.systemClass, mainComponent);
    }
  }, [filters, mainComponent, fetchKitsPictures]);

  const showOrHideFilter = () => {
    setIsShowFilter(prevState => !prevState);
  };

  const handleFilter = useCallback((value, field, fullValue) => {
    if (field === 'businessUnit') {
      setFilters(prevState => ({
        ...prevState,
        [field]: value,
        [`${field}FullValue`]: fullValue,
        systemClass: null,
        systemClassFullValue: null,
      }));
      setMainComponent(null)
      return clearKitPictures();
    }

    return setFilters(prevState => ({
      ...prevState,
      [field]: value,
      [`${field}FullValue`]: fullValue,
    }));
  } , [setFilters, clearKitPictures]);

  const handleUploadImage = useCallback(file => {
    if (mainComponent) {
      uploadKitsPicture(filters.businessUnit, filters.systemClass, mainComponent, file);
    }
  }, [uploadKitsPicture, filters, mainComponent]);

  const handleDeleteImage = useCallback(async imgId => {
    setDeletePendingId(imgId);
    await deleteKitsPicture(filters.businessUnit, filters.systemClass, mainComponent, imgId);
    setDeletePendingId(null);
  }, [deleteKitsPicture, filters, mainComponent]);

  const systemClassesByBU = systemClasses.systemClasses ? systemClasses.systemClasses.filter(item => item.systemMainComponent.length) : [];

  const systemClassFullValue = filters.systemClassFullValue;
  const systemMainComponents = (systemClassFullValue && systemClassFullValue.systemMainComponent) || [];

  const hasShowList = filters.businessUnit && filters.systemClass;
  const hasShowImages = filters.businessUnit && filters.systemClass && mainComponent;

  return {
    isShowFilter,
    deletePendingId,
    filters,
    mainComponent,
    setMainComponent,
    handleFilter,
    handleUploadImage,
    handleDeleteImage,
    systemClassesByBU,
    systemMainComponents,
    hasShowList,
    hasShowImages,
    showOrHideFilter,
  };
}
