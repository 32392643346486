import React, { useState, useEffect } from 'react';
import $ from 'jquery';

import { validateForm } from 'library/utilities/ValidateForm.util';
import Modal from 'library/common/commonComponents/Modal';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import strings from 'resources/locales/Translate';
import { scrollToTop } from 'library/utilities/scrollActions';

export const ReturnGoodsBooking = ({
  formModel,
  returnGoodsDetail,
  returnGoodsBookingSave,
  id,
  transactionDetails,
}) => {
  const [loading, setLoading] = useState(false);
  const [returnGoodsDetailForm, setReturnGoodsDetailForm] = useState({ formData: {}, isFormValid: false });
  const [error, setError] = useState('');

  useEffect(() => {
    if (returnGoodsDetail) {
      let returnBookingDate = '';
      if (returnGoodsDetail.returnBookingDate) {
        returnBookingDate = new Date(returnGoodsDetail.returnBookingDate);
      }
      setReturnGoodsDetailForm(prevState => ({
        ...prevState,
        formData: {
          ...returnGoodsDetail,
          date: new Date(returnGoodsDetail.date),
          returnBookingDate,
        },
      }));
    }
  }, [returnGoodsDetail]);

  const onCloseClick = () => {
    if ($(`#${id}`) && $(`#${id}`).modal) {
      $(`#${id}`).modal('hide');
    }
    let returnBookingDate = '';
    if (returnGoodsDetail.returnBookingDate) {
      returnBookingDate = new Date(returnGoodsDetail.returnBookingDate);
    }
    setReturnGoodsDetailForm({
      ...returnGoodsDetailForm,
      formData: {
        ...returnGoodsDetail,
        date: new Date(returnGoodsDetail.date),
        returnBookingDate,
      },
    });
    setError('');
  };

  const onSaveClick = async () => {
    const validForm = validateForm({
      form: returnGoodsDetailForm,
      model: formModel || [],
    });

    if (!validForm.isFormValid) {
      return setReturnGoodsDetailForm(validForm);
    }

    setError('');
    setLoading(true);
    const { success, error } = await returnGoodsBookingSave({
      transactionDetails,
      dataToSend: validForm.formData,
    });
    setLoading(false);

    if (success) {
      onCloseClick();
      scrollToTop(500);
    }
    if (error) {
      setError(error);
    }
  };

  const handleForm = ({ field, values }) => {
    setReturnGoodsDetailForm(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        ...values,
      },
    }));
  };

  return (
    <Modal
      id={id}
      className='mt-5'
      title={strings.returnGoodsBooking}
      confirmTitle={strings.save}
      titleOfCancel={strings.cancel}
      confirmAction={onSaveClick}
      confirmDataTest='return-goods-booking-save'
      loading={loading}
      onCloseClick={onCloseClick}
    >
      <FormComponent
        model={formModel || []}
        formName='returnGoodsDetailForm'
        formValue={returnGoodsDetailForm.formData}
        onChange={handleForm}
      />
      {error && <div className='error-block'>{error}</div>}
    </Modal>
  );
};

export default ReturnGoodsBooking;
