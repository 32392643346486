export const observationsListBreadcrumbs = [
  {
    name: 'homepage',
    url: '/home',
  },
  {
    name: 'demoSale',
    url: '/demo-sale',
  },
  {
    name: 'myObservations',
  },
];