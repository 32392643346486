import { connect } from 'react-redux';

import { fetchMasterData } from 'library/common/commonActions/MasterDataActions';

import { getInitialPermissionsData, updateDemoUnitPermission } from './Permissions.actions';
import Permissions from './Permissions.component';

const mapStateToProps = ({ authReducer, languageReducer, appActionsReducer, masterDataReducer, permissionsReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
  appActions: appActionsReducer,
  permissionsList: permissionsReducer.permissionsList,
  usersList: masterDataReducer.usersList,
});

export default connect(mapStateToProps, {
  fetchMasterData,
  getInitialPermissionsData,
  updateDemoUnitPermission,
})(Permissions);
