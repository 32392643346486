import {
  fetchMasterDataService,
  fetchMasterDataServicePost,
  fetchMasterDataServicePut,
} from 'library/api/master-data.service';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { logNetworkError } from 'library/utilities/logError';

export const fetchQuestionsList = (id, actionTypes) => async dispatch => {
  try {
    const res = await fetchMasterDataService(URLS.getAllQuestions(id));
    if (res.status === 200) {
      dispatch({
        type: actionTypes.FETCH_KIT_QUESTIONS,
        payload: res.data,
      });
    }
  } catch (err) {
    logNetworkError(err);
    dispatch({
      type: actionTypes.FETCH_KIT_QUESTIONS,
      payload: [],
    });
  }
};

export const createQuestion = ({ text, kitInformationId, actionTypes }) => async dispatch => {
  try {
    const res = await fetchMasterDataServicePost(URLS.createQuestion, { text, kitInformationId });
    if (res.status === 200) {
      await dispatch(fetchQuestionsList(kitInformationId, actionTypes));
      return { success: true };
    }
  } catch (err) {
    logNetworkError(err);
    return { success: false };
  }
};

export const createAnswer = ({ text, questionId, kitInformationId, actionTypes }) => async dispatch => {
  try {
    const res = await fetchMasterDataServicePost(URLS.createAnswer, { text, questionId });
    if (res.status === 200) {
      await dispatch(fetchQuestionsList(kitInformationId, actionTypes));
      return { success: true };
    }
  } catch (err) {
    logNetworkError(err);
    return { success: false };
  }
};

export const editAnswer = ({ text, answerId, kitInformationId, questionId, actionTypes }) => async dispatch => {
  try {
    const res = await fetchMasterDataServicePut(`${URLS.createAnswer}/${answerId}`, { text, questionId });
    if (res.status === 200) {
      await dispatch(fetchQuestionsList(kitInformationId, actionTypes));
      return { success: true };
    }
  } catch (err) {
    logNetworkError(err);
    return { success: false };
  }
};
