import React from 'react';

import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import Modal from 'library/common/commonComponents/Modal';
import strings from 'resources/locales/Translate';

import { useAddPurchaseRequests } from './AddPurchaseRequestsPopup.hook';

const AddPurchaseRequestsPopup = ({
  id,
  countries,
  rmsSystemClass,
  sellOffCurrency,
  addPurchaseRequests,
  fetchSystemMainComponentData,
  cardInfo,
  setCardInfo,
  updatePurchaseRequests,
}) => {
  const {
    purchaseRequestsForm,
    isLoading,
    error,
    purchaseRequestsModel,
    handleForm,
    handleClickOnSave,
    handleClose,
  } = useAddPurchaseRequests({
    id,
    countries,
    rmsSystemClass,
    sellOffCurrency,
    addPurchaseRequests,
    fetchSystemMainComponentData,
    cardInfo,
    setCardInfo,
    updatePurchaseRequests,
  });

  return (
    <Modal
      id={id}
      title={strings.addPurchaseRequest}
      confirmAction={handleClickOnSave}
      confirmDataTest='notify-button-subscribe'
      confirmTitle={strings.save}
      loading={isLoading}
      disableBackgroundClose
      onCloseClick={handleClose}
    >
      <FormComponent
        model={purchaseRequestsModel}
        formName='purchaseRequestsForm'
        formValue={purchaseRequestsForm.formData}
        onChange={handleForm}
      />
      {error && <div className='error-block'>{strings[error]}</div>}
    </Modal>
  );
};

export default AddPurchaseRequestsPopup;
