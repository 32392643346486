
import { batchActions } from 'redux-batched-actions';

import { logNetworkError } from 'library/utilities/logError';
import {
    deleteMasterDataService,
    fetchMasterDataServicePost,
    fetchMasterDataServicePut,
    fetchMasterDataService,
} from 'library/api/master-data.service';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { scrollToTop } from 'library/utilities/scrollActions';
import { GET_SAP_ERP_MAPPING_LIST } from './SapErpMapping.constants';

export const handlingDataToSend = data => {
    const {
        filter: { searchTerm }
    } = data;
    const filter = [];
    const sort = [];
    const {
        sort: { accountName },
    } = data;

    const sendData = { ...data };

    if (searchTerm) {
        let searchArray = [];
        searchArray.push(`accountId:eq:${searchTerm}`);
        searchArray.push(`or`);
        searchArray.push(`comment:cic:${searchTerm}`);
        searchArray.push(`or`);
        searchArray.push(`accountName:cic:${searchTerm}`);
        searchArray.push(`or`);
        searchArray.push(`city:cic:${searchTerm}`);
        searchArray.push(`or`);
        searchArray.push(`street:cic:${searchTerm}`);
        searchArray.push(`or`);
        searchArray.push(`country.name:cic:${searchTerm}`);
        searchArray.push(`or`);
        searchArray.push(`postalCode:eq:${searchTerm}`);
        searchArray.push(`or`);
        searchArray.push(`houseNumber:eq:${searchTerm}`);
        filter.push(searchArray);
    }

    if (accountName) {
        sort.push('accountName');
        sort.push(`${accountName}`);
    }

    sendData.filter = filter;
    sendData.sort = sort;
    return sendData;
}

export const fetchSapErpMappingList = data => async dispatch => {
    try {
        dispatch(changeLoader(true));
        const dataToSend = handlingDataToSend(data);
        const response = await fetchMasterDataServicePost(URLS.sapErpMappingList, dataToSend);
        dispatch(
            batchActions([
                dispatch({
                    type: GET_SAP_ERP_MAPPING_LIST,
                    payLoad: response.data,
                }),
                dispatch(changeLoader(false))
            ]),
        );

    } catch (err) {
        dispatch(
            batchActions([
                dispatch(changeLoader(false)),
                dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage')),
            ]),
        );
        scrollToTop(500);
        logNetworkError(err);
    }

}
export const createOrEditMapping = (dataToSend, data, hasEditMode) => async dispatch => {
    try {
        const request = hasEditMode ? fetchMasterDataServicePut : fetchMasterDataServicePost;
        const textMessage = hasEditMode ? 'mappingWasUpdated' : 'mappingWasAdded';
        const url = hasEditMode ? URLS.sapErpMappingEdit : URLS.sapErpMappingCreate;

        await request(url, dataToSend);
        dispatch(batchActions([
            dispatch(fetchSapErpMappingList(data)),
            dispatch(changeLoader(false)),
            dispatch(toggleActionMessage(true, 'success', textMessage)),
        ]),
        );
        return { success: true };
    } catch (err) {
        let errorMessage = 'somethingWentWrongMessage';
        let showAsIs = false;
        if (err.response && err.response.status === 409) {
            errorMessage = 'accountAlreadyExists';
            showAsIs = true
        }
        dispatch(
            batchActions([
                dispatch(changeLoader(false)),
                dispatch(toggleActionMessage(true, 'error', errorMessage, showAsIs)),
            ]),
        );
        scrollToTop(500);
        logNetworkError(err);
        return { success: false };
    }

};

export const deleteMapping = (accountId, data) => async dispatch => {
    try {
        await deleteMasterDataService(URLS.sapErpMappingDelete(accountId));
        dispatch(
            batchActions([
                dispatch(fetchSapErpMappingList(data)),
                dispatch(toggleActionMessage(true, 'success', 'mappingWasDeleted')),
            ]),
        );
        return { success: true };
    } catch (err) {

        dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
        scrollToTop(500);
        logNetworkError(err);

        return { success: false };
    }

};


