import React from 'react';
import strings from 'resources/locales/Translate';
import Icon from 'library/common/commonComponents/Icon/Icon';
import $ from 'jquery';
import moment from 'moment';
import ConfirmDeletionPopup from 'library/common/commonComponents/ConfirmationBox/ConfirmationBox.component';

export const AvailabilitySlotList = ({
  availabilitySlotList,
  onEditClickOFAvailabilitySlot,
  language,
  warehouseId,
  deleteAvailabiltySlot
}) => {
 const handleDelete = async(id) => {
    //call the api
   await deleteAvailabiltySlot(id,warehouseId )
 }

 const showDeleteModel = (id) => {
        $(`#delete-slot${id}`).modal('show');
 }

 const translatedDays = (days) => {
  if(!days){
    return "";
  }
  days = days.toString();
  const daysArray = days.split(",");
  let translatedDays = "";
  daysArray.forEach((day, index) => {
    day = day.trim().toLowerCase();
    translatedDays = index != daysArray.length-1 ? translatedDays + strings[day] + ", ": translatedDays + strings[day];
  })
  return translatedDays;
 }

  return (
    <div className='mb-2'>
      {availabilitySlotList &&
        availabilitySlotList.length > 0 &&
        availabilitySlotList.map((item, index) => (
          <>
            <div className='d-flex'>
              <div className='mb-2 col-3 p-0'>
                <div title={item.kits.map(each => each.kitName).join(', ')} style={{ cursor: 'pointer' }}>
                  {item.kits.length > 0 && (
                    <React.Fragment>
                      {item.kits[0].kitName} {item.kits.length >= 2 && `+ ${item.kits.length - 1}`}
                    </React.Fragment>
                  )}
                </div>
                <div className='row'>
                  {' '}
                  <div className='col-6 font-bold'> {strings.listLabelRecurring}</div>{' '}
                  <div className='col-6'>{item.daysSelected ? translatedDays(item.daysSelected) : strings.no}</div>
                </div>
                {language === 'en' && (
                  <div>
                    {moment(item.startDate).format('DD/MM/YYYY')} to {moment(item.endDate).format('DD/MM/YYYY')}
                  </div>
                )}
                {language === 'de' && (
                  <div>
                    {moment(item.startDate).format('DD.MM.YYYY')} to {moment(item.endDate).format('DD.MM.YYYY')}
                  </div>
                )}
              </div>
              <div>
                <button
                  onClick={() => onEditClickOFAvailabilitySlot(item)}
                  style={{ border: '1px solid #0088D0', backgroundColor: '#fff' }}
                >
                  <Icon name={'edit'} width={24} height={30} fill={'#0088d0'} />
                </button>
              </div>
              <div>
                <button
                  onClick={() => showDeleteModel(item.zmccWarehouseSlotId)}
                  style={{ border: '1px solid #0088D0', marginLeft: '10px', backgroundColor: '#fff' }}
                >
                  <Icon name={'delete'} width={24} height={30} fill={'#0088d0'} />
                </button>
                <ConfirmDeletionPopup
                  id={`delete-slot${item.zmccWarehouseSlotId}`}
                  onConfirm={() => handleDelete(item.zmccWarehouseSlotId)}
                  title={strings.deleteSlot}
                  subtitle={strings.deletAvailablitySlotMessage}
                />
              </div>
            </div>
            <div className='divider-line-slot-list mb-2'></div>
          </>
        ))}
    </div>
  );
};

export default AvailabilitySlotList
