import React from 'react';

import Icon from 'library/common/commonComponents/Icon/Icon';
import ConfirmDeletionPopup from 'library/common/commonComponents/ConfirmationBox';
import strings from 'resources/locales/Translate';
import './DeleteButton.styles.scss';

const DeleteButton = ({ confirmMessage, dataTest, extraModalInformation, itemId, onConfirm, showExtraInformation, confirmationTest, label }) => (
  <>
    <button
      className='button-blue'
      data-toggle='modal'
      data-target={`#confirmDeleteModal${itemId}`}
      data-test={dataTest}
    >
      <Icon name='delete' width={16} height={16} fill='#0088d0' />
      <span className='ml-1 mt-1'>{label ? strings[label] : strings.delete}</span>
    </button>
    <ConfirmDeletionPopup
      id={`confirmDeleteModal${itemId}`}
      onConfirm={onConfirm}
      title={strings.confirmRemoveC2C}
      subtitle={confirmMessage ? strings[confirmMessage] : 'confirmDeleteStockMessage'}
    >
      {showExtraInformation && extraModalInformation()}
    </ConfirmDeletionPopup>
  </>
);

export default DeleteButton;
