import React, { useState } from 'react';
import { FormComponent } from 'library/common/commonComponents/FormComponent/Optimized';
import strings from 'resources/locales/Translate';
import IconButton from 'library/common/commonComponents/IconButton';

import { checkStockForRole } from 'library/utilities/checkStockForRole';
import Button from 'library/common/commonComponents/Button/Button';
import { validateForm } from 'library/utilities/ValidateForm.util';
import { materialNumberAndDescriptionModel } from './KitloanMaterialNumberAndDescription.constant';

export const KitloanMaterialNumberAndDescription = ({ kitLoanMaterialNumberAndDescription, onSelect, isFormOpened, isFormOpenFunction }) => {
  const [materialNumberForm, setMaterialNumberForm] = useState({ formData: {quantity:1} });
  const disableButton = kitLoanMaterialNumberAndDescription && kitLoanMaterialNumberAndDescription.length >= 20;

  const handleForm = ({ values, field, formName }) => {
    if (field === 'materialNumber') {
      values['materialNumber'] = values['materialNumber'].replace(/\s/g, '');
      setMaterialNumberForm(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          ...values,
        },
      }));
    }
    setMaterialNumberForm(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        ...values,
      },
    }));
  };

  const renderKit = (item, index) => (
    <div className='selected-kit-container' key={`${item.materialNumber}-${index}`}>
      <p>
        {strings.positionText} {index + 1}
      </p>
      <h5 className='kit-info'>{`${strings.materialNumber}: ${item.materialNumber}`}</h5>
      <p className='kit-info'>{`${strings.descriptionText}: ${item.descriptionText}`}</p>
      <p className='kit-info'>{`${strings.quantity}: ${item.quantity}`}</p>
    </div>
  );
  const onClickOnShowForm = () => {
    isFormOpenFunction(true);
  };

  const onSaveItem = () => {
    const newFormData = validateForm({
      form: materialNumberForm,
      formName: 'materialNumberAndDescriptionModel',
      model: materialNumberAndDescriptionModel,
    });
    if (newFormData.isFormValid) {
      const { formData } = newFormData;
      onSelect({
        materialNumber: formData.materialNumber,
        descriptionText: formData.descriptionText,
        quantity: formData.quantity
      });
      setMaterialNumberForm({ formData: {quantity:1} });
      isFormOpenFunction(false);
    } else {
      setMaterialNumberForm( newFormData );
    }
  };

  return (
    <>
      {kitLoanMaterialNumberAndDescription &&
        !!kitLoanMaterialNumberAndDescription.length &&
        kitLoanMaterialNumberAndDescription.map((kit, i) => renderKit(kit, i))}
      {!isFormOpened && (
        <Button
          bgFill={false}
          iconName='plus'
          value={strings.addSingleLoan}
          styleClass='w-auto mt-0'
          onClick={onClickOnShowForm}
          disabled={disableButton}
        />
      )}
      {isFormOpened && (
        <>
          <FormComponent
            formName=' materialNumberAndDescriptionModel'
            model={materialNumberAndDescriptionModel}
            formValue={materialNumberForm.formData}
            onChange={handleForm}
          />
          <Button
            bgFill={false}
            iconName='plus'
            value={strings.add}
            styleClass='w-auto mt-0'
            onClick={onSaveItem}
            disabled={disableButton}
          />
          <p className='mt-2'>{strings.addSingleItemInfo}</p>
        </>
      )}
    </>
  );
};

export default KitloanMaterialNumberAndDescription;
