export const kitForSaleDetailsBreadcrumb = [
  {
    name: 'homepage',
    url: '/home',
  },
  {
    name: 'demoSale',
    url: '/demo-sale',
  },
];

export const FETCH_KIT_DETAILS_ATTACHMENTS = '@@KIT_FOR_SALE_DETAILS/FETCH_KIT_DETAILS_ATTACHMENTS';
export const FETCH_KIT_DETAILS_DAMAGE_LOSS_REPORT = '@@KIT_FOR_SALE_DETAILS/FETCH_KIT_FOR_SALE_DETAILS_DAMAGE_LOSS_REPORT';
export const GET_KIT_FOR_SALE_DETAILS = '@@KIT_FOR_SALE_DETAILS/GET_KIT_FOR_SALE_DETAILS';
export const SAVE_CURRENT_TAB = '@@KIT_FOR_SALE_DETAILS/SAVE_CURRENT_TAB';
export const CLEAR_KIT_FOR_SALE_DETAILS_DATA = '@@KIT_FOR_SALE_DETAILS/CLEAR_KIT_FOR_SALE_DETAILS_DATA';
export const FETCH_KIT_DETAILS_COMMENTS = '@@KIT_FOR_SALE_DETAILS/FETCH_KIT_FOR_SALE_DETAILS_COMMENTS';
export const FETCH_KIT_QUESTIONS = '@@KIT_FOR_SALE_DETAILS/FETCH_KIT_FOR_SALE_QUESTIONS';
export const FETCH_KIT_TRANSACTION_ATTACHMENTS = '@@KIT_FOR_SALE_DETAILS/FETCH_KIT_TRANSACTION_ATTACHMENTS';
