import React, { useEffect } from 'react';
import MySchedule from './MySchedule.component';
import { connect } from 'react-redux';
import { getAllScheduledEventList, getScheduledDetailes, deleteSchedule } from 'modules/Homepage/HomepageActions';

export const MainMySchedule = ({ scheduledEventsList, user, appActions, getAllScheduledEventList, language, getScheduledDetailes, scheduledDetails, deleteSchedule }) => {
  useEffect(() => {
    getAllScheduledEventList(user?.accountId)
  },[getAllScheduledEventList]);
  return (
    <MySchedule
      scheduledEventsList={scheduledEventsList}
      user={user}
      appActions={appActions}
      getAllScheduledEventList={getAllScheduledEventList}
      language={language}
      getScheduledDetailes={getScheduledDetailes}
      scheduledDetails={scheduledDetails}
      deleteSchedule={deleteSchedule}
    />
  );
};

const mapStateToProps = ({ authReducer, homepage, languageReducer, appActionsReducer }) => ({
  user: authReducer.user,
  scheduledEventsList: homepage.scheduledEventsList,
  appActions: appActionsReducer,
  language: languageReducer.language,
  scheduledDetails: homepage.scheduledDetails,
});

export default connect(mapStateToProps, { getAllScheduledEventList, getScheduledDetailes, deleteSchedule })(MainMySchedule);
