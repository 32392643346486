import { connect } from 'react-redux';

import { fetchStatusListData } from './StatusList.actions';
import StatusList from './StatusList.component';

const mapStateToProps = ({ languageReducer, authReducer, statusListReducer }) => ({
  language: languageReducer,
  user: authReducer.user,
  statusList: statusListReducer.statusList,
});

export default connect(mapStateToProps, {
  fetchStatusListData,
})(StatusList);
