import React from "react";
import strings from "resources/locales/Translate";
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import Modal from "library/common/commonComponents/Modal";
import { model } from "./SaveQueryPopup.constants";
import { useSaveQueryPopup } from "./SaveQueryPopup.hook";


export const SaveQueryPopup = ({id,createMyQuery,transactionType, zmccData, selectedData, isEditClicked, setIsEditClicked }) => {

  const {getModelData, handleForm, formData, handleSave, onCloseClick, error, loading} = useSaveQueryPopup({id,createMyQuery,transactionType,zmccData, selectedData, isEditClicked, setIsEditClicked});
    return(
        <Modal
        id={id} 
        confirmAction={handleSave} 
        loading={loading} 
        disableBackgroundClose 
        onCloseClick={onCloseClick} 
        title={strings.saveQuery}>
        <div className='mt-2'>
          <FormComponent
            model={getModelData()}
            formName='saveQueryForm'
            formValue={formData.formData}
            onChange={handleForm}
          />
        </div>
        {error && <div className='error-block'>{error}</div>}
      </Modal>
    )
}