import { useEffect, useState } from "react";
import { model, transactionTypes } from './SaveQueryPopup.constants';
import { validateForm } from "library/utilities/ValidateForm.util";
import strings from "resources/locales/Translate";
import $ from 'jquery';

export const useSaveQueryPopup = ({id,createMyQuery,transactionType, zmccData, selectedData, isEditClicked, setIsEditClicked}) => {
   
    const [formData, setFormData] = useState({ formData: {}, isFormValid: false });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [newtransactionType, setTransactionType] = useState(transactionType);
    useEffect(() => {
      if (selectedData?.safeSearchId && isEditClicked) {
        setFormData({
          formData: {
            searchName: selectedData.searchName,
            searchDescription: selectedData.searchDescription,
          },
        });

        setTransactionType(selectedData.searchType);
      }
    }, [isEditClicked, transactionType]);
    
    const getModelData = () => {
        const newModel = [...model];
        if (selectedData && selectedData.searchType && selectedData.safeSearchId) {
            const type = transactionTypes[selectedData.searchType];
            model[0].inputGroupText = `${strings[type]} >`;
        }else{
            model[0].inputGroupText = `${strings[transactionType]} >`;
        }
        
        return newModel;
    }

    const handleForm = ({ values }) => {
        setError('');
        setFormData({
            ...formData,
            formData: {
                ...formData.formData,
                ...values,
            },
        });

    };

    const handleSave = async() => {
        const validForm = validateForm({
            form: formData,
            model: model,
        });
        if (!validForm.isFormValid) {
            return setFormData(validForm);
        }
        const { searchName, searchDescription } = validForm.formData
        const dataToSend = {
          safeSearchId: null,
          searchName: searchName,
          searchDescription: searchDescription,
          searchType:
            newtransactionType === 'openProcesses'
              ? 'OPEN_PROCESS'
              : newtransactionType === 'myBookings'
              ? 'MY_BOOKINGS'
              : newtransactionType === 'loanRequests'
              ? 'DEMO_REQUEST'
              : newtransactionType === 'cancellationApproval'
              ? 'CANCELLATION_REQUESTS'
              : newtransactionType === 'cancelledProcesses'
              ? 'CANCELLATION_PROCESS'
              : newtransactionType === 'archiveProcesses'
              ? 'ARCHIVED_REQUESTS'
              : newtransactionType,
          search: selectedData?.search ? selectedData.search : JSON.stringify(zmccData.filter),
        };
    setLoading(true);
      const res = await createMyQuery(dataToSend, isEditClicked, selectedData?.safeSearchId );
      setLoading(false);
      if (res.success) {
        onCloseClick()
      }else{
        setError(strings.somethingWentWrongMessage);
      }

    }

    const onCloseClick = () => {
        $(`#${id}`).modal('hide');
        setFormData({formData: {}, isFormValid: false})
        if (selectedData?.safeSearchId) {
            setIsEditClicked(false);  
        }
        setError('')
    }

    return {
        getModelData,
        handleForm,
        formData,
        handleSave,
        onCloseClick,
        error, 
        loading
    }
}