import store from 'main/store/configureStore';

import { URLS } from 'library/common/commonConstants/ApiUrlConstants';

import { getDataByCriteriaService } from 'library/api/master-data.service';

import * as actionTypes from 'library/common/commonConstants/storeConstants';

export const addLoanAttachmentFile = files => dispatch => {
  const loanAttachmentState = [...store.getState().loanAttachmentsReducer.loanAttachments];
  loanAttachmentState.unshift(...files);
  dispatch({
    type: actionTypes.ADD_OR_REMOVE_LOAN_ATTACHMENTS,
    payload: loanAttachmentState,
  });
};

export const removeLoanAttachmentFile = index => dispatch => {
  const loanAttachmentState = [...store.getState().loanAttachmentsReducer.loanAttachments];
  loanAttachmentState.splice(index, 1);
  dispatch({
    type: actionTypes.ADD_OR_REMOVE_LOAN_ATTACHMENTS,
    payload: loanAttachmentState,
  });
};

export const clearLoanAttachmentFile = () => dispatch => {
  dispatch({
    type: actionTypes.ADD_OR_REMOVE_LOAN_ATTACHMENTS,
    payload: [],
  });
};

export const uploadLoanAttachment = (ids, attachments) => async dispatch => {
  let loanAttachmentState = attachments;
  if (!attachments) {
    loanAttachmentState = [...store.getState().loanAttachmentsReducer.loanAttachments];
  }
  if (loanAttachmentState.length) {
    const formData = new FormData();
    loanAttachmentState.forEach(file => {
      formData.append('files', file);
    });
    try {
      await Promise.all(
        ids.map(id => {
          const url = URLS.loanAttachments.replace('{transactionsId}', id.transactionsId);
          getDataByCriteriaService(url, formData);
        }),
      );
      dispatch(clearLoanAttachmentFile());
    } catch (e) {}
  }
};
