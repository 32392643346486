import React, { useCallback } from 'react';
import cn from 'classnames';

import Icon from 'library/common/commonComponents/Icon/Icon';
import strings from 'resources/locales/Translate';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import Button from 'library/common/commonComponents/Button/Button';
import EnhancedCircleRadioButton from 'library/common/commonComponents/FormComponent/Optimized/EnhancedCircleRadioButton';
import { getCheckBoxOptions } from 'library/utilities/formUtils';
const Filter = ({
  isShowFilter,
  countries,
  categoriesAndSystems,
  loanTypes,
  handleFilterChange,
  selected,
  resetFilter,
  isLabsLocation,
  showMapModal,
  isZMCCCalendar,
  systemCategoriesList,
  isIqsCalendar,
  resourceTypes = [],
  productHeirarchies = [],
  iqsSystemClasses = [],
  iqsSystemComponents = [],
}) => {
  const fieldStyle = 'col-lg-2';
  const getPlaceholder = (selectedValues, displayValue, multiple) => {
    let displayPlaceholder = '';
    if (!multiple && selectedValues) {
      displayPlaceholder = displayValue
        .map(display => {
          if (display.name) {
            return selectedValues[display.key] + display.separator + selectedValues[display.name];
          }
          return selectedValues[display.key] + display.separator;
        })
        .join('');
    }
    if (selectedValues && selectedValues.length > 0) {
      const displayFormat = selectedValues.map(item => {
        return displayValue
          .map(display => {
            return item[display.key] + display.separator;
          })
          .join('');
      });
      displayPlaceholder =
        selectedValues.length === 1 ? displayFormat[0] : `${displayFormat[0]} + ${displayFormat.length - 1}`;
    }
    return displayPlaceholder;
  };

  const currentResetFilter = key => () => {
    resetFilter(key);
  };

  const renderFilters = () => {
    const newFilters = [];
    const filters = {
      stock: { displayValue: [{ key: 'locationName', separator: '' }], multiple: true },
      category: {
        displayValue: [{ key: 'systemMainComponentName', separator: '', optionsArrayKey: 'systemMainComponent' }],
        multiple: true,
      },
      systemCategory: {
        displayValue: [{ key: 'name', separator: ''}],
        multiple: true,
      },
    };
    Object.keys(filters).forEach(key => {
      if (selected[key] && (typeof selected[key] !== 'object' || selected[key].length)) {
        newFilters.push(
          <div className={(!isZMCCCalendar && !isLabsLocation) ? 'new-filter-chips-container' : 'filter-chips-container'} key='key'>
            {getPlaceholder(selected[`${key}FullValue`], filters[key].displayValue, filters[key].multiple)}
            <span onClick={currentResetFilter(key)} style={{color: (!isZMCCCalendar && !isLabsLocation) ? 'red' :'' }}>&times;</span>
          </div>,
        );
      }
    });
    return newFilters;
  };

  const handleFilter = (value, field, fullValue) => {
    if (field !== 'loanType') {
      handleFilterChange(field, value, fullValue);
    }
  };

  const filters = renderFilters();

  const showMap = useCallback(() => {
    showMapModal();
  }, []);

  const labsAndLocationLoanValue = { id: 1, name: strings.labsAndLocationResvationBooking };
  const zmccFullValue = { id: 2, name: 'ZMCC Reservation Booking' };  

  return (
    <>
      <div className={cn((!isZMCCCalendar && !isLabsLocation) ? '' : 'filter-wrapper', { 'd-block': isShowFilter, 'd-none': !isShowFilter })}>
        <div className={ (!isZMCCCalendar && !isLabsLocation) ? 'new-filter-body' : 'container-fluid' } style={{margin:  (!isZMCCCalendar && !isLabsLocation) ? '30px': ''}}>
          <div className='row'>
            {!isIqsCalendar && <Dropdown
              data={loanTypes}
              idKey='id'
              field='loanType'
              disabled
              displayValue={[{ key: 'name', separator: '' }]}
              optionsArrayKey='stockInformation'
              placeholder={strings.kitLoan + '...'}
              label={isZMCCCalendar ? "Demo Process" : strings.loanProcess}
              onChange={handleFilter}
              mainContainerStyle={isLabsLocation ? 'col-lg-3' : (!isZMCCCalendar && !isLabsLocation) ? 'col-lg-2' : 'col-lg-2'}
              value={isLabsLocation ? labsAndLocationLoanValue.id : isZMCCCalendar ? zmccFullValue.id : selected.loanType}
              fullValue={isLabsLocation ? labsAndLocationLoanValue : isZMCCCalendar ? zmccFullValue : selected.loanTypeFullValue}
              newDropdownStyle={(!isZMCCCalendar && !isLabsLocation) ? true : false}
            />}
            <Dropdown
              data={countries}
              hasSection
              multiSelect
              filter
              selectAllOption
              selectAllPlaceholder={strings.selectAll}
              idKey='id'
              field='stock'
              displayValue={[{ key: 'locationName', separator: '' }]}
              optionsArrayKey='stockInformation'
              titleDisplay={[
                { key: 'shortName', separator: ' ' },
                { key: 'name', separator: '' },
              ]}
              placeholder={strings.pleaseSelect + '...'}
              label={isLabsLocation ? strings.labsLocationPartner : isZMCCCalendar ? "ZEISS Microscopy Customer Center" : strings.stock}
              onChange={handleFilter}
              mainContainerStyle={isIqsCalendar ? "col-lg-2": "col-lg-3"}
              value={selected.stock}
              fullValue={selected.stockFullValue}
              newDropdownStyle={(!isZMCCCalendar && !isLabsLocation) ? true : false}
            />
            {isIqsCalendar && 
              <Dropdown
                data={resourceTypes}
                multiSelect
                filter
                selectAllOption
                selectAllPlaceholder={strings.selectAll}
                field='resourceType'
                idKey='staticCode'
                displayValue={[{ key: 'name', separator: ' ' }]}
                placeholder={strings.pleaseSelect}
                label={strings.scheduleType}
                onChange={handleFilter}
                mainContainerStyle={'col-lg-2'}
                value={selected.resourceType}
                fullValue={selected.resourceTypeFullValue}
                newDropdownStyle={true}
              />
            }
            {isIqsCalendar && 
              <Dropdown
                data={productHeirarchies}
                multiSelect
                filter
                selectAllOption
                selectAllPlaceholder={strings.selectAll}
                field='productHierarchy'
                idKey='id'
                displayValue={[{ key: 'name', separator: ' ' }]}
                placeholder={strings.pleaseSelect}
                label={strings.category}
                onChange={handleFilter}
                mainContainerStyle={'col-lg-2'}
                value={selected.productHierarchy}
                fullValue={selected.productHierarchyFullValue}
                newDropdownStyle={true}
              />
            }
            {isIqsCalendar && 
              <Dropdown
                data={iqsSystemClasses}
                multiSelect
                filter
                selectAllOption
                selectAllPlaceholder={strings.selectAll}
                field='systemClass'
                idKey='systemClassId'
                displayValue={[{ key: 'systemClassName', separator: ' ' }]}
                placeholder={strings.pleaseSelect}
                label={strings.iqsSystemClassLabel}
                onChange={handleFilter}
                mainContainerStyle={'col-lg-2'}
                value={selected.systemClass}
                fullValue={selected.systemClassFullValue}
                newDropdownStyle={true}
              />
            }
            {isIqsCalendar && 
              <Dropdown
                data={iqsSystemComponents}
                multiSelect
                filter
                selectAllOption
                selectAllPlaceholder={strings.selectAll}
                field='systemMainComponent'
                idKey='systemComponentId'
                displayValue={[{ key: 'systemMainComponentName', separator: ' ' }]}
                placeholder={strings.pleaseSelect}
                label={strings.iqsSystemMainComppoenetLabel}
                onChange={handleFilter}
                mainContainerStyle={'col-lg-2'}
                value={selected.systemMainComponent}
                fullValue={selected.systemMainComponentFullValue}
                newDropdownStyle={true}
              />
            }
            {isLabsLocation && (
              <div className={fieldStyle + ' d-flex align-items-end'}>
                <Button bgFill={false} value={strings.showMap} onClick={showMap} />
              </div>
            )}
            {!isIqsCalendar && <Dropdown
              data={categoriesAndSystems}
              hasSection
              multiSelect
              filter
              selectAllOption
              selectAllPlaceholder={strings.selectAll}
              field='category'
              idKey='systemMainComponentId'
              displayValue={[{ key: 'systemMainComponentName', separator: '' }]}
              optionsArrayKey='systemMainComponent'
              titleDisplay={[{ key: 'systemClassName', separator: '' }]}
              placeholder={strings.pleaseSelect + '...'}
              label={strings.categorySystem}
              onChange={handleFilter}
              mainContainerStyle={!isIqsCalendar ? "col-lg-3" : "col-lg-2"}
              value={selected.category}
              fullValue={selected.categoryFullValue}
              newDropdownStyle={(!isZMCCCalendar && !isLabsLocation) ? true : false}
            />}
          {isZMCCCalendar && 
            <Dropdown
              data={systemCategoriesList}
              multiSelect
              filter
              selectAllOption
              selectAllPlaceholder={strings.selectAll}
              field='systemCategory'
              idKey='id'
              displayValue={[{ key: 'name', separator: ' ' }]}
              placeholder={strings.pleaseSelect}
              label={strings.systemCategory}
              onChange={handleFilter}
              mainContainerStyle={'col-lg-3'}
              value={selected.systemCategory}
              fullValue={selected.systemCategoryFullValue}
              newDropdownStyle={(!isZMCCCalendar && !isLabsLocation) ? true : false}
            />
          }
          {isIqsCalendar && 
              <EnhancedCircleRadioButton
                data={getCheckBoxOptions(true)}
                selectAllPlaceholder={strings.selectAll}
                field='showOnlySelectedResources'
                label={strings.showOnlySelectedResources}
                onChange={handleFilter}
                mainContainerStyle={'col-lg-2'}
                value={selected.showOnlySelectedResources}
              />
            }
          </div>
          {filters && !!filters.length && !isZMCCCalendar && !isLabsLocation && !isIqsCalendar &&  (
          <div className='mt-3 d-flex flex-direction-row align-items-center'>
            {filters}
            <div className='filter-chips-delete-all' onClick={currentResetFilter()}>
              <Icon name='' width={24} height={24} fill='#0088d0' /> {strings.resetFilters}
            </div>
          </div>
        )}
        </div>
      </div>
      {(filters && !!filters.length && (isZMCCCalendar || isLabsLocation))&& (
        <div className='container-fluid mb-4 d-flex flex-direction-row align-items-center'>
          <div className='mr-2'>
            <strong>Filters:</strong>
          </div>
          {filters}
          <div className='filter-chips-delete-all' onClick={currentResetFilter()}>
            <Icon name='delete' width={24} height={24} fill='#0088d0' /> {strings.deleteAllFilters}
          </div>
        </div>
      )}
    </>
  );
};

export default Filter;
