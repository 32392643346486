import { DUST_MANAGER, DUST_USER } from 'library/common/commonConstants/roles';

export const checkUserRoles = (roles, user) => {
  if (!user) return false;

  if (user.admin && roles.includes('Admin')) return true;

  if ((user.globalRoles.includes(DUST_MANAGER) && roles.includes(DUST_MANAGER)) || (user.globalRoles.includes(DUST_USER) && roles.includes(DUST_USER))) return true;

  if (user && user.stockRoles && user.stockRoles.length > 0) {
    return roles.some(role => {
      return !!user.stockRoles.some(stockRole => stockRole.roleName.toLowerCase() === role.toLowerCase());
    });
  }

  return false;
};

export const checkStockRoles = (roles, user, stock) => {
  if (user && user.stockRoles && user.stockRoles.length > 0) {
    return user.stockRoles.find(role => (role.stockId === stock.id) && roles.includes(role.roleName));
  }
  return false;
};

export const checkHasMedUsWarehouseAccess = (user) =>{
  
  if (user && user.stockRoles && user.stockRoles.length > 0) {
    return user.stockRoles.find(role => role.roleName === 'Stock Controller' && role.businessUnitName === 'MED' && role.countryShortName === 'US');
  }
  return false;
}

export const checkZmccUserRoles = (roles, user) => {
  if (user.admin && roles.includes('Admin')) return true;

  if (user && user.stockRoles && user.stockRoles.length > 0) {
    return roles.some(role => {
      return user.stockRoles.some(stockRole => (stockRole.businessUnitName === 'RMS (ZMCC)' && stockRole.roleName.toLowerCase() === role.toLowerCase()));
    });
  }

}
