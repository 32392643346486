import React from 'react';
import strings from 'resources/locales/Translate';
import Button from 'library/common/commonComponents/Button/Button';
import InputField from 'library/common/commonComponents/FormComponent/Optimized/InputField';
import Dropdown from 'library/common/commonComponents/FormComponent/Optimized/Dropdown';
import ZmccRadioButton from 'library/common/commonComponents/FormComponent/Optimized/ZMCCRadioButton';
import { useLogisticAndCordination } from './LogiscticAndCordination.hook';
import Validators from 'library/utilities/Validators';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import EditAddttendeePopup from '../EditAttendePopup/EditAttendeePopup.component';
import classNames from 'classnames';
import { DeleteIcon, EditOutlineIcon } from 'library/common/commonComponents/Icon/SvgIcons/Icons';

export const LogiscticAndCordination = props => {
  const { formValue, shippingAddress, transactionsAddress, isZmcc, zmccXrmBooking, zmccLmBooking, zmccXbBooking, zmccSemBooking, updateZmccTransaction } = props;
  const { logisticsAndCoordinationForm, generalInformationForm, transactionsId, todosListForm, deleteTodoIds } = formValue;

  const {
    attendeeName,
    attendeeEmail,
    attendeesList,
    functionData,
    descisionMaker,
    skillLevel,
    addIntoAttendeesListList,
    handleNonFormComponent,
    onChangeInput,
    onChangeCheckbox,
    accomodationNeeded,
    flightNeeded,
    visitMuseumOfOptics,
    accomodationNeededData,
    flightNeededData,
    visitMuseumOfOpticsData,
    isEditng,
    onEditClick,
    saveEditAttendee,
    onChangeExistingValue,
    handleEditNonFormComponent,
    editingFiledId,
    handleEditNonFormComponentForTodoList,
    deleteAttendeById,
    showCheckboxes
  } = useLogisticAndCordination(props);
  const disableFileds = logisticsAndCoordinationForm?.formData?.zmccAttendeesList?.length === 5;
  return (
    <>
      {transactionsAddress && Object.keys(transactionsAddress).length > 0 && transactionsAddress.firstName != null ? (
        <>
          <h2 className='mt-3 zmcc-title-heading'>{strings.shippingSamples}</h2>
          <div className='d-flex p-2 section-border'>
            <div>
              {transactionsAddress.firstName} {transactionsAddress.lastName},
            </div>
            <div className='ml-2'>
              {transactionsAddress.organization}, {transactionsAddress.department},
            </div>
            <div className='ml-2'>
              {transactionsAddress.street} {transactionsAddress.houseNumber}
            </div>
            <div>
              {transactionsAddress.postalCode} {transactionsAddress.city} {transactionsAddress.stateName},
            </div>
            <div className='ml-2'>{transactionsAddress.phone && `Cell: ${transactionsAddress.phone}`}</div>
          </div>
        </>
      ) : (
        generalInformationForm.formData.stockInformationId &&
        Object.keys(shippingAddress).length > 0 && (
          <>
            <h2 className='mt-3 zmcc-title-heading'>{strings.shippingSamples}</h2>
            <div className='d-flex p-2 section-border'>
              <div>
                {shippingAddress.firstName} {shippingAddress.lastName},
              </div>
              <div className='ml-2'>
                {shippingAddress.organization}, {shippingAddress.department},
              </div>
              <div className='ml-2'>
                {shippingAddress.street} {shippingAddress.houseNumber}
              </div>
              <div>
                {shippingAddress.postalCode} {shippingAddress.city} {shippingAddress.stateName},
              </div>
              <div className='ml-2'>Cell: {shippingAddress.phone}</div>
            </div>
          </>
        )
      )}

      <h2 className='mt-3 zmcc-title-heading'>{strings.demoAttendees}</h2>
      <div className='d-flex flex-wrap p-2 section-border col-12'>
      {logisticsAndCoordinationForm.formData.zmccAttendeesList &&
        logisticsAndCoordinationForm.formData.zmccAttendeesList.map((item, index) => (
              <>
                <div className='mt-3 col-4' key={item.zmccAttendeesId} style={{width: '380px'}}>
                <h3 className='mt-2 demo-attendee-title'> {strings.attendeeName} {index + 1} </h3>
                  <div className='demo-attendee-content mt-3'>
                    <div className='zmcc-add-edit'>
                      <p>
                        {strings.name} : 
                        <span className='adjust-long-text'> {item.attendeeName}{' '}</span>
                      </p>
                    </div>

                    <p>
                      <span>{strings.email} : </span>
                      {item.attendeeEmail}
                    </p>
                    <p>
                      {strings.function} :
                      <span className='adjust-long-text'>{item.functionData}</span>
                    </p>
                    <p>
                      <span>{strings.descisionMaker} : </span>
                      {item.decisionMaker ? 'yes' : item.decisionMaker === false ? 'no' : '-'}
                    </p>
                    <p>
                      <span>{strings.skillLevel} : </span>
                      {logisticsAndCoordinationForm.formData.zmccAttendeesList &&
                      logisticsAndCoordinationForm.formData.zmccAttendeesList[index]['skillLevel']
                        ? logisticsAndCoordinationForm.formData.zmccAttendeesList[index]['skillLevel'][
                            'skillLevelTranslated'
                          ][0]['name']
                        : props.skillLevel &&
                          props.skillLevel.find(
                            item =>
                              item.id === logisticsAndCoordinationForm.formData.zmccAttendeesList[index].skillLevelId,
                          ) &&
                          props.skillLevel.find(
                            item =>
                              item.id === logisticsAndCoordinationForm.formData.zmccAttendeesList[index].skillLevelId,
                          ).name}
                    </p>
                    {showCheckboxes && <p>
                      {strings.accomodationNeeded} :
                     <span className='adjust-long-text'>{item.accomodationNeededData}</span>
                    </p>}
                    {showCheckboxes && <p>
                      {strings.flightNeeded} :
                      <span className='adjust-long-text'>{item.flightNeededData}</span>
                    </p>}
                    {showCheckboxes && <p>
                      {strings.visitMuseum} :
                      <span className='adjust-long-text'>{item.visitMuseumOfOpticsData}</span>
                    </p>}
                    <button onClick={() => onEditClick(index)} className='logistic-edit p-0'>
                      <EditOutlineIcon />
                      <span className='ml-2 mt-2 color-blue'>{strings.edit}</span>
                    </button>
                    <button onClick={() => deleteAttendeById(item)} className='logistic-edit ml-3'>
                      <DeleteIcon />
                      <span className='ml-2 mt-2' style={{color: 'rgba(201, 24, 3, 1)'}}>{strings.delete}</span>
                    </button>
                  </div>
                </div>
              </>
        ))}
        </div>
        <EditAddttendeePopup
                  id='edit-Attendee-modal'
                  data={logisticsAndCoordinationForm.formData?.zmccAttendeesList?.length > 0 && logisticsAndCoordinationForm.formData.zmccAttendeesList[editingFiledId]}
                  skillLevelData={props.skillLevel}
                  handleEditNonFormComponent={handleEditNonFormComponent}
                  todosListForm={todosListForm}
                  user={props.user}
                  handleNonFormComponent={handleNonFormComponent}
                  editingFiledId={editingFiledId}
                  handleEditNonFormComponentForTodoList={handleEditNonFormComponentForTodoList}
                  saveZmccSemData={props.saveZmccSemData}
                  deleteTodoIds={deleteTodoIds}
                  isZmcc= {isZmcc}
                  zmccXrmBooking={zmccXrmBooking}
                  zmccLmBooking={zmccLmBooking}
                  zmccXbBooking={zmccXbBooking}
                  zmccSemBooking={zmccSemBooking}
                  updateZmccTransaction={updateZmccTransaction}
                  showCheckBoxes={showCheckboxes}
                />
      <div className='row mt-3 p-2 section-border'>
        <div className='col-sm-3'>
          <InputField
            label={strings.name}
            field='attendeeName'
            formName='logisticsAndCoordinationForm'
            placeholder=''
            type='text'
            validators={[]}
            value={logisticsAndCoordinationForm.formData.attendeeName}
            error={logisticsAndCoordinationForm.formData.attendeeNameError}
            onChange={onChangeInput}
            isRequired={true}
            disabled={disableFileds}
          />
        </div>
        <div className='col-sm-3'>
          <InputField
            label={strings.email}
            field='attendeeEmail'
            formName='logisticsAndCoordinationForm'
            placeholder=''
            type='text'
            validators={[{ check: Validators.email, message: 'invalidEmailError' }]}
            value={logisticsAndCoordinationForm.formData.attendeeEmail}
            error={logisticsAndCoordinationForm.formData.attendeeEmailError}
            onChange={onChangeInput}
            isRequired={true}
            disabled={disableFileds}
          />
        </div>
        <div className='col-sm-3'>
          <InputField
            label={strings.function}
            field='function'
            formName='logisticsAndCoordinationForm'
            placeholder=''
            type='text'
            validators={[]}
            value={logisticsAndCoordinationForm.formData.function}
            error={logisticsAndCoordinationForm.formData.functionError}
            onChange={onChangeInput}
            isRequired={true}
            disabled={disableFileds}
          />
        </div>
        <div className='col-sm-3'>
          <Dropdown
            label={strings.skillLevel}
            placeholder='Please select'
            idKey='id'
            field='skillLevelId'
            data={props.skillLevel || []}
            hasSection={false}
            multiSelect={false}
            filter={false}
            isRequired={false}
            validators={[]}
            displayValue={[{ key: 'name', separator: '' }]}
            onChange={onChangeInput}
            value={skillLevel && skillLevel.value}
            fullValue={skillLevel && skillLevel.fullValue}
            disabled={disableFileds}
          />
        </div>
          <div className={classNames('col-sm-3 logisctic-checkbox-adjust',{'d-none': !showCheckboxes})}>
            <Checkbox
              label={strings.accomodationNeeded}
              field='accomodationNeeded'
              isRequired={false}
              selected={logisticsAndCoordinationForm.formData.accomodationNeeded}
              onChange={onChangeCheckbox}
              //   disabled={!editing}
              newuiVersionStyle={true}
              disabled={disableFileds}
            />
          </div>
          <div className={classNames('col-sm-3 logisctic-checkbox-adjust',{'d-none': !showCheckboxes})}>
            <Checkbox
              label={strings.flightNeeded}
              field='flightNeeded'
              isRequired={false}
              selected={logisticsAndCoordinationForm.formData.flightNeeded}
              onChange={onChangeCheckbox}
              //   disabled={!editing}
              newuiVersionStyle={true}
              disabled={disableFileds}
            />
          </div>
          <div className={classNames('col-sm-3 logisctic-checkbox-adjust',{'d-none': !showCheckboxes})}>
            <Checkbox
              label={strings.visitMuseum}
              field='visitMuseumOfOptics'
              isRequired={false}
              selected={logisticsAndCoordinationForm.formData.visitMuseumOfOptics}
              onChange={onChangeCheckbox}
              //   disabled={!editing}
              newuiVersionStyle={true}
              disabled={disableFileds}
            />
        </div>
        <div className='col-sm-3 mt-4'>
          <ZmccRadioButton
            label={strings.descisionMaker}
            value={logisticsAndCoordinationForm.formData.descisionMaker}
            // error={}
            placeholder=''
            formName='logisticsAndCoordinationForm'
            field='descisionMaker'
            validators={[]}
            required={false}
            data={[
              {
                id: 'yes',
                value: 'yes',
              },
              {
                id: 'no',
                value: 'no',
              },
            ]}
            onChange={onChangeInput}
            disabled={disableFileds}
          />
        </div>
        {(accomodationNeeded || logisticsAndCoordinationForm.formData.accomodationNeeded) ? (
            <div className='col-sm-3 mt-2 mb-2'>
              <InputField
                label={strings.specifyDetails}
                field='accomodationNeededData'
                formName='logisticsAndCoordinationForm'
                placeholder=''
                type='text'
                validators={[]}
                value={logisticsAndCoordinationForm.formData.accomodationNeededData}
                error={logisticsAndCoordinationForm.formData.accomodationNeededDataError}
                onChange={onChangeInput}
                isRequired={true}
                className='applicationTab-input-box'
                disabled={disableFileds}
              />
            </div>
          ): <div className='col-sm-3 mt-2 mb-2'></div>}
          {(flightNeeded  || logisticsAndCoordinationForm.formData.flightNeeded) ? (
            <div className='col-sm-3 mt-2 mb-2 '>
              <InputField
                label={strings.specifyDetails}
                field='flightNeededData'
                formName='logisticsAndCoordinationForm'
                placeholder=''
                type='text'
                validators={[]}
                value={logisticsAndCoordinationForm.formData.flightNeededData}
                error={logisticsAndCoordinationForm.formData.flightNeededDataError}
                onChange={onChangeInput}
                isRequired={true}
                className='applicationTab-input-box'
                disabled={disableFileds}
              />
            </div>
          ): <div className='col-sm-3 mt-2 mb-2 '></div>}
          {(visitMuseumOfOptics ||  logisticsAndCoordinationForm.formData?.visitMuseumOfOptics) ? (
            <div className='col-sm-3 mt-2 mb-2 '>
              <InputField
                label={strings.specifyDetails}
                field='visitMuseumOfOpticsData'
                formName='logisticsAndCoordinationForm'
                placeholder=''
                type='text'
                validators={[]}
                value={logisticsAndCoordinationForm.formData.visitMuseumOfOpticsData}
                error={logisticsAndCoordinationForm.formData.visitMuseumOfOpticsDataError}
                onChange={onChangeInput}
                isRequired={true}
                className='applicationTab-input-box'
                disabled={disableFileds}
              />
            </div>
          ): <div className='col-sm-3 mt-2 mb-2'></div>}
      </div>
      <div class='row'>
        <div className='col-auto mt-4'>
            <Button
              value={strings.addMoreButtonText}
              onClick={() =>
                addIntoAttendeesListList(
                  attendeeName,
                  attendeeEmail,
                  functionData,
                  descisionMaker,
                  skillLevel ? skillLevel.value : null,
                  accomodationNeededData,
                  flightNeededData,
                  visitMuseumOfOpticsData,
                )
              }
              bgFill={false}
              iconName='plus'
              disabled={disableFileds}
              newVersionUi={true}
              styleClass="button-without-border mt-3 p-0"
            />
          </div>
        </div>
    </>
  );
};

export default LogiscticAndCordination;
