export const images = {
  checkboxSelected: require('resources/icons/selected.svg'),
  checkboxDeselected: require('resources/icons/deselected.svg'),
  fileIcon: require('resources/icons/insert-drive-file.svg'),
  imagePlaceholder: require('resources/images/placeholder.png'),
  instagramIcon: require('resources/icons/instagram.svg'),
  yammerIcon: require('resources/icons/yammer.svg'),
  tickMark: require('resources/icons/check-symbol.png'),
  returnToStock: require('resources/icons/return-to-stock.png'),
  returnToStockUnconfirmed: require('resources/icons/return-to-stock-unconfirmed.png'),
  lmImage: require('resources/images/LM_LSM980 Airyscan 2.jpg'),
  semImage: require('resources/images/SEM.jpg'),
  xbImage: require('resources/images/Xb-Image.png'),
  xrmImage: require('resources/images/XRM_7944_ZEISS-Xradia-520-Versa.jpg'),
  MED_1_Image: require('resources/images/MED.png'),
  MED_2_Image: require('resources/images/MED_2.png'),
  MED_3_Image: require('resources/images/MED_3.png'),
  MED_4_Image: require('resources/images/MED_4.png'),
  MED_5_Image: require('resources/images/MED_5.png'),
  IQS_1_Image: require('resources/images/IQS_1.png'),
  IQS_2_Image: require('resources/images/IQS_2.jpg'),
  IQS_3_Image: require('resources/images/IQS_3.jpg'),
  IQS_4_Image: require('resources/images/IQS_4.jpg'),
  RMS_1_Image: require('resources/images/RMS_1.png'),
  RMS_2_Image: require('resources/images/RMS_2.png'),
  RMS_3_Image: require('resources/images/RMS_3.png'),
  RMS_4_Image: require('resources/images/RMS_4.png'),
  RMS_5_Image: require('resources/images/RMS_5.png'),
  homepageCartIcon: require('resources/images/cart.png'),
  bellIcon: require('resources/images/bell.png'),
  person: require('resources/images/person.png'),
  cradCart: require('resources/images/cardCart.png'),
  cardList: require('resources/images/listIcon.png'),
  updateZEISSLogo: require('resources/images/UpdateZeissLogo.svg'),
  welcomeImage: require('resources/images/welcome.png')
};
