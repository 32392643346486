import { useState, useCallback, useEffect }  from 'react';
import $ from 'jquery';

import { validateForm } from 'library/utilities/ValidateForm.util';

import { model, validityOptions } from './AddPurchaseRequestsPopup.constants';

export const useAddPurchaseRequests = ({
  id,
  countries,
  rmsSystemClass,
  sellOffCurrency,
  addPurchaseRequests,
  fetchSystemMainComponentData,
  cardInfo,
  setCardInfo,
  updatePurchaseRequests,
}) => {
  const [purchaseRequestsForm, setPurchaseRequestsForm] = useState({ formData: {} });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [purchaseRequestsModel, setPurchaseRequestsModel] = useState(model);
  const {
    budget,
    countryId,
    currencyId,
    description,
    systemMainComponentId,
    systemClassId,
    purchaseId,
    numberOfMonths,
  } = cardInfo;

  useEffect(() => {
    const newModel = [ ...model ];

    newModel[1].options = rmsSystemClass;
    newModel[4].options = sellOffCurrency;
    newModel[5].options = validityOptions();
    newModel[6].options = countries;

    setPurchaseRequestsModel(newModel);
  }, [rmsSystemClass, countries, sellOffCurrency]);

  const currentSystemClassId = purchaseRequestsForm.formData && purchaseRequestsForm.formData.systemClassId;

  const getMainComponents = useCallback(async () => {
    const newModel = [ ...model ];
    const mainComponents = await fetchSystemMainComponentData(currentSystemClassId);
    newModel[2].options = mainComponents || [];

    setPurchaseRequestsModel(newModel);
  }, [fetchSystemMainComponentData, currentSystemClassId]);

  useEffect(() => {
    if (currentSystemClassId) {
      getMainComponents();
    }
  }, [currentSystemClassId, fetchSystemMainComponentData, getMainComponents]);

  useEffect(() => {
    setPurchaseRequestsForm({
      formData: {
        budget,
        countryId,
        currencyId,
        description,
        systemClassId,
        systemMainComponentId,
        validity: numberOfMonths,
      },
    });
  }, [cardInfo, budget, countryId, currencyId, description, numberOfMonths, systemClassId, systemMainComponentId]);

  const handleForm = useCallback(({ values, field }) => {
    if (field === 'systemClassId') {
      setPurchaseRequestsForm(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          ...values,
          systemMainComponentId: null,
          systemMainComponentIdFullValue: null,
          systemMainComponentIdError: null,
        },
      }));
    }
    setPurchaseRequestsForm(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        ...values,
      },
    }));
  }, []);

  const handleClose = useCallback(() => {
    $(`#${id}`).modal('hide');
    setPurchaseRequestsForm({ formData: {} });
    setIsLoading(false);
    setError('');
    setCardInfo({});
  }, [id, setCardInfo]);

  const handleClickOnSave = useCallback(async () => {
    const validForm = validateForm({
      form: purchaseRequestsForm,
      model: purchaseRequestsModel,
    });

    if (!validForm.isFormValid) {
      return setPurchaseRequestsForm(validForm);
    }

    setIsLoading(true);
    const request = purchaseId ? updatePurchaseRequests : addPurchaseRequests;
    const { success } = await request(validForm.formData, purchaseId);
    setIsLoading(false);

    if (!success) {
      setError('somethingWentWrongMessage');
    } else {
      handleClose();
    }
  }, [purchaseRequestsForm, purchaseRequestsModel, updatePurchaseRequests, addPurchaseRequests, handleClose, purchaseId]);

  return {
    purchaseRequestsForm,
    isLoading,
    error,
    purchaseRequestsModel,
    handleForm,
    handleClickOnSave,
    handleClose,
  };
}
