export const menu = [
  {
    name: 'Homepage',
    path: '/home',
    child: false,
    languageKey: 'homepage'
  },
  {
    name: 'Calendar',
    path: '/calendar',
    child: false,
    languageKey: 'calendar'
  },
  {
    name: 'Loan',
    path: '/loan',
    child: true,
    languageKey: 'loan',
    children: [
      {
        name: 'Kit Loan',
        path: '/kit-loan',
        child: false,
        languageKey: 'kitLoan'
      },
      {
        name: 'Course Loan',
        path: '/course-loan',
        child: false,
        languageKey: 'courseLoan'
      },
      {
        name: 'Single Loan',
        path: '/single-loan',
        child: false,
        languageKey: 'singleLoan'
      },
    ]
  },
  {
    name: 'Transaction',
    path: '/transaction',
    child: true,
    languageKey: 'transaction',
    children: [
      {
        name: 'My Bookings',
        path: '/my-bookings',
        child: false,
        languageKey: 'myBookings',
      },
      {
        name: 'Open Transactions',
        path: '/open-processes',
        child: false,
        languageKey: 'openProcesses',
      },
      {
        name: 'Loan Requests',
        path: '/loan-requests',
        child: false,
        languageKey: 'loanRequests',
      },
      {
        name: 'Sap Order Creation',
        path: '/sap-order-creation',
        child: false,
        languageKey: 'sapOrderCreation',
      },
      {
        name: 'Outgoing Goods',
        path: '/outgoing-goods',
        child: false,
        languageKey: 'outgoingGoods',
      },
      {
        name: 'Loans In Demo',
        path: '/list-receiving',
        child: false,
        languageKey: 'listReceiving',
      },
      {
        name: 'Incoming Goods',
        path: '/incoming-goods',
        child: false,
        languageKey: 'incomingDemoKits',
      },
      {
        name: 'Incoming Single/Course Loans',
        path: '/good-receipt',
        child: false,
        languageKey: 'goodReceipt',
      },
      {
        name: 'Kit Reconditioning',
        path: '/list-reconditioning',
        child: false,
        languageKey: 'listReconditioning',
      },
      {
        name: 'Cancellation Request',
        path: '/cancellation-approval',
        child: false,
        languageKey: 'cancellationApproval',
      },
      {
        name: 'Cancelled Transactions',
        path: '/cancelled-processes',
        child: false,
        languageKey: 'cancelledProcesses',
      },
      {
        name: 'Archive Transactions',
        path: '/archive-processes',
        child: false,
        languageKey: 'archiveProcesses',
      },
      {
        name: 'C2C Loans',
        path: '/c2c',
        child: false,
        languageKey: 'c2cLoans',
      },
    ]
  },
  {
    name: 'Kits',
    path: '/kits',
    child: false,
    languageKey: 'kits'
  },
  {
    name: 'Stocks',
    path: '/stocks',
    child: false,
    languageKey: 'stocks'
  },
  {
    name: 'Shop',
    path: '/demo-sale',
    child: true,
    languageKey: 'demoSale',
    children: [
      {
        name: 'Sales Offers',
        path: '/demo-sale',
        child: false,
        languageKey: 'salesOffers'
      },
    ]
  },
];

export const menuWithTeamBookings = {
  name: 'Transaction',
  path: '/transaction',
  child: true,
  languageKey: 'transaction',
  children: [
    {
      name: 'Team Bookings',
      path: '/team-bookings',
      child: false,
      languageKey: 'teamBookings',
    },
    {
      name: 'My Bookings',
      path: '/my-bookings',
      child: false,
      languageKey: 'myBookings',
    },
    {
      name: 'Open Transactions',
      path: '/open-processes',
      child: false,
      languageKey: 'openProcesses',
    },
    {
      name: 'Loan Requests',
      path: '/loan-requests',
      child: false,
      languageKey: 'loanRequests',
    },
    {
      name: 'Sap Order Creation',
      path: '/sap-order-creation',
      child: false,
      languageKey: 'sapOrderCreation',
    },
    {
      name: 'Outgoing Goods',
      path: '/outgoing-goods',
      child: false,
      languageKey: 'outgoingGoods',
    },
    {
      name: 'Loans In Demo',
      path: '/list-receiving',
      child: false,
      languageKey: 'listReceiving',
    },
    {
      name: 'Incoming Goods',
      path: '/incoming-goods',
      child: false,
      languageKey: 'incomingDemoKits',
    },
    {
      name: 'Incoming Single/Course Loans',
      path: '/good-receipt',
      child: false,
      languageKey: 'goodReceipt',
    },
    {
      name: 'Kit Reconditioning',
      path: '/list-reconditioning',
      child: false,
      languageKey: 'listReconditioning',
    },
    {
      name: 'Cancellation Request',
      path: '/cancellation-approval',
      child: false,
      languageKey: 'cancellationApproval',
    },
    {
      name: 'Cancelled Transactions',
      path: '/cancelled-processes',
      child: false,
      languageKey: 'cancelledProcesses',
    },
    {
      name: 'Archive Transactions',
      path: '/archive-processes',
      child: false,
      languageKey: 'archiveProcesses',
    },
    {
      name: 'C2C Loans',
      path: '/c2c',
      child: false,
      languageKey: 'c2cLoans',
    },
  ]
};
