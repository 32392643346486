import { CLICKED_ADD_IN_DEMO_CENTRE_TAB, CLICKED_ADD_IN_TODO_TAB, UPDATE_CREATE_SEM_FORM } from 'modules/ZmccRequest/SEMBooking/SemBooking.constants';
import {
  FETCH_CONNECTED_TRANSACTIONS_LIST,
  FETCH_RMS_ZMCC_USER,
  FETCH_ZMCC_PRIORITES,
  FETCH_ZMCC_TRANSACTION_DETAILS_STATUSES,
  GET_WHOLE_ZMCC_TRANSACTION_DETAILS,
  SET_SEM_FORM_VALUES,
  SET_INFORMATION_TAB_DATA,
  FETCH_ZMCC_OPERATOR_FOR_STOCK,
  FETCH_ZMCC_SUBSCRIBERS_FOR_STOCK,
  FETCH_ZMCC_OPERATOR_SCHEDULES,
  FETCH_SAMPLE_AT,
} from './ZmccTransactionDetails.constants';

const EMPTY_FORM = {
  formData: {},
  isFormValid: false,
};

export const INITIAL_STATE_ZMCCTRANSACTIONDETILS_REDUCER = {
  schedulingInformationForm: EMPTY_FORM,
  generalInformationForm: EMPTY_FORM,
  projectDetailsForm: EMPTY_FORM,
  customerDetilsForm: EMPTY_FORM,
  primaryPointOfContactForm: EMPTY_FORM,
  customerBackgroundForm: EMPTY_FORM,
  mainApplicationChecklistForm: EMPTY_FORM,
  sampleDeatilsFrom: EMPTY_FORM,
  demoCenterInformationForm: EMPTY_FORM,
  additionalInformationForm: EMPTY_FORM,
  logisticsAndCoordinationForm: EMPTY_FORM,
  todosListForm: EMPTY_FORM,
  zmccTransactionDetails: {},
  rmsZmccUser: [],
  zmccPrioritesArray: [],
  kitAttachments: [],
  kitAttachmentsToDelete: [],
  transactionsId: null,
  zmccFullInformationId: null,
  zmccTransactionDetailsStatuses: [],
  connectedTransactionArray: [],
  zmccInformationTabData: {},
  xbMainApplicationChecklistForm: EMPTY_FORM,
  xbSampleDeatilsFrom: EMPTY_FORM,
  xrmDemoDetailsForm: EMPTY_FORM,
  xrmApplicationInformationForm: EMPTY_FORM,
  xrmSamplesDetailsForm: EMPTY_FORM,
  xrmSampleDescriptionForm: EMPTY_FORM,
  xrmSampleSpecsForm: EMPTY_FORM,
  lmApplicationAndSamplesForm: EMPTY_FORM,
  deleteTodoIds:EMPTY_FORM,
  zmccOperators: [],
  zmccSubscriberUsers: [],
  onAddMoreClickedINDemoCentreTab: false,
  onAddMorreClickedInTodoTab: false,
  sampleAt: []
};

export const ZmccTransactionDetailsReducer = (state = INITIAL_STATE_ZMCCTRANSACTIONDETILS_REDUCER, action) => {
  switch (action.type) {
    case GET_WHOLE_ZMCC_TRANSACTION_DETAILS:
      return { ...state, zmccTransactionDetails: action.payload };
    
      case UPDATE_CREATE_SEM_FORM:
        return { ...state, ...action.payload };

    case FETCH_RMS_ZMCC_USER:
      return { ...state, rmsZmccUser: action.payload };
    case FETCH_ZMCC_PRIORITES:
      return { ...state, zmccPrioritesArray: action.payload };
    case SET_SEM_FORM_VALUES:
      return { ...state, ...action.payload };
    case FETCH_ZMCC_TRANSACTION_DETAILS_STATUSES:
      return { ...state, zmccTransactionDetailsStatuses: action.payload };
    case FETCH_CONNECTED_TRANSACTIONS_LIST:
      return { ...state, connectedTransactionArray: action.payload };
    case SET_INFORMATION_TAB_DATA:
      return {...state, zmccInformationTabData: action.payload};
    case FETCH_ZMCC_OPERATOR_FOR_STOCK:
      return {...state, zmccOperators: action.payload};
    case FETCH_ZMCC_SUBSCRIBERS_FOR_STOCK:
      return {...state, zmccSubscriberUsers: action.payload}
    case FETCH_ZMCC_OPERATOR_SCHEDULES:
      return {...state, zmccOperatorSchedules: action.payload}
      case CLICKED_ADD_IN_DEMO_CENTRE_TAB:
        return { ...state, onAddMoreClickedINDemoCentreTab: action.payload };
        case CLICKED_ADD_IN_TODO_TAB:
          return { ...state, onAddMorreClickedInTodoTab: action.payload };
          case FETCH_SAMPLE_AT:
            return { ...state, sampleAt: action.payload };
    default:
      return state;
  }
};

export default ZmccTransactionDetailsReducer;
