import React from 'react';

import strings from 'resources/locales/Translate';
import ConfirmDeletionPopup from 'library/common/commonComponents/ConfirmationBox';
import Button from 'library/common/commonComponents/Button/Button';

import './addressItemStyles.scss';

const AddressItem = ({address, containerClass, editAddressText, onEditAddress, deleteAddress, openAddressDeleteModel}) => {
  return (
    <div className={containerClass}>
      <div className='address-item'>
        <div className='d-flex align-items-cemter'>
          <p className='address-title text-capitalize'>{address.addressType}</p>
          {address.deletionPossible && (
            <Button
              dataTest='open-delete-address-modal-button'
              bgFill={false}
              isGhost={true}
              iconWidth={24}
              iconHeight={24}
              iconName='delete'
              onClick={() => openAddressDeleteModel(address.userAddressId)}
              styleClass='delete-button'
            />
          )}
        </div>
        <p className='name'>{address.firstName} {address.lastName}</p>
        <p className='organization'>{address.organization}</p>
        <p className='street'>{address.street} {address.houseNumber}</p>
        <p className='city'>{address.postalCode} {address.city}</p>
        <p className='country'>{address.countryName}</p>

        <Button
          dataTest='edit-address-button'
          bgFill={false}
          isGhost={true}
          iconWidth={24}
          iconHeight={24}
          iconName='edit'
          iconPosition='left'
          value={editAddressText}
          onClick={() => onEditAddress(address)}
          styleClass='edit-button'
        />
      </div>

      <ConfirmDeletionPopup
        id={`confirmAddressDeleteModal${address.userAddressId}`}
        onConfirm={() => deleteAddress(address.userAddressId)}
        title={strings.deleteDeliveryAddress}
        subtitle={strings.deleteTheAddress}
      />
    </div>
  )
};

export default AddressItem;
