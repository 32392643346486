import { useState } from 'react';

export const useMaintainStatus = ({ searchKitBySerialNumber, history }) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = e => {
    const { value } = e.target;

    if (error) {
      setError(null);
    }

    setValue(value);
  };

  const handleSearchKitBySerialNumber = async () => {
    if (!value.length) {
      return setError('requiredErrorMessage');
    }

    setIsLoading(true);
    const { success, kitId, systemClassActive } = await searchKitBySerialNumber(value);
    setIsLoading(false);

    if (!systemClassActive) {
      return setError('notPossibleToAddDemoUnitStatusForThisDemoKit');
    }

    if (!success || !kitId) {
      return setError('noResultFound');
    }

    setValue('');
    history.push({
      pathname: `/kit_details/${kitId}`,
      state: { tab: 'demoUnitStatus' }
    });
  };

  return {
    value,
    error,
    isLoading,
    handleSearch,
    handleSearchKitBySerialNumber,
    setError,
  };
};
