export const reservationListBreadcrumbs = [
  {
    name:'homepage',
    url: '/home',
  },
  {
    name:'demoSale',
    url: '/demo-sale',
  },
  {
    name:'reservation',
  },
];
