import strings from 'resources/locales/Translate';
import Validators from 'library/utilities/Validators';

export const createKitBreadcrumbsForSale = isEditView => [
  { name: 'homepage', url: '/home' },
  { name: 'demoSale', url: '/demo-sale' },
  { name: isEditView ? 'maintainKits' : 'createKit' },
];

export const FETCH_CREATE_KIT_FOR_SALE_DETAILS = 'FETCH_CREATE_KIT_FOR_SALE_DETAILS';
export const UPDATE_CREATE_KIT_FOR_SALE_FORM = 'UPDATE_CREATE_KIT_FOR_SALE_FORM';
export const RESET_CREATE_KIT_FOR_SALE = 'RESET_CREATE_KIT_FOR_SALE';
export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';

export const generalInformationKitForSaleModel = [
  {
    label: 'kitName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'kitName',
    validators: [{check: Validators.required, message: 'requiredErrorMessage'}],
    required: true,
    styleClass: 'col-sm-7'
  },
  {
    label: 'ibaseText',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'ibase',
    validators: [],
    required: false,
    styleClass: 'col-sm-5',
  },
  {
    label: 'descriptionText',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'description',
    validators: [],
    required: false,
    styleClass: 'col-sm-12'
  },
  {
    label: 'grossListPrice',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'localPrice',
    validators: [
      { check: Validators.required, message: 'requiredErrorMessage' },
      { check: Validators.number, message: 'invalidNumberError' },
    ],
    required: true,
    styleClass: 'col-sm-4',
    extraProps: {
      maxLength: 15,
    },
  },
  {
    label: 'currency',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'currencyId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-4',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'value', separator: '' }],
    selectAllPlaceholder: 'pleaseSelect',
  },
  {
    label: 'hideKitText',
    selected: false,
    type: 'checkbox',
    field: 'hideKit',
    styleClass: 'col-sm-4 mt-4',
  },
  {
    label: 'transferPrice',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'transferPrice',
    validators: [
      { check: Validators.required, message: 'requiredErrorMessage' },
      { check: Validators.number, message: 'invalidNumberError' },
    ],
    required: true,
    styleClass: 'col-sm-4',
    extraProps: {
      maxLength: 15,
    },
  },
  {
    label: 'currency',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'currencyForTransferPrice',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-4',
    disabled: true,
  },
];

export const generalInformationSingleItemModel = [
  {
    label: 'singleItemName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'kitName',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-12'
  },
  {
    label: 'descriptionText',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'description',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-12'
  },
  {
    label: 'grossListPrice',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'localPrice',
    validators: [
      { check: Validators.required, message: 'requiredErrorMessage' },
      { check: Validators.number, message: 'invalidNumberError' },
    ],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 15,
    },
  },
  {
    label: 'currency',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'currencyId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'value', separator: '' }],
    selectAllPlaceholder: 'pleaseSelect',
  },
  {
    label: 'transferPrice',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'transferPrice',
    validators: [
      { check: Validators.required, message: 'requiredErrorMessage' },
      { check: Validators.number, message: 'invalidNumberError' },
    ],
    required: true,
    styleClass: 'col-sm-6',
    extraProps: {
      maxLength: 15,
    },
  },
  {
    label: 'currency',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'currencyForTransferPrice',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    disabled: true,
  },
];

export const kitForSaleInformationModel = [
  {
    label: 'location',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'stockInformationId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-12',
    options: [],
    hasSection: true,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'locationName', separator: '' }],
    optionsArrayKey: 'stockInformation',
    titleDisplay: [
      { key: 'shortName', separator: ' ' },
      { key: 'name', separator: '' },
    ],
  },
  {
    label: 'businessUnit',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'businessUnitId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: '' }],
  },
  {
    label: 'systemClassText',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'systemClassId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'systemClassId',
    displayValue: [{ key: 'systemClassName', separator: '' }],
    disabled: false,
  },
  {
    label: 'systemMainComponent',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'systemMainComponentId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'systemComponentId',
    displayValue: [{ key: 'systemMainComponentName', separator: '' }],
  },
  {
    label: 'serialNumber',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'serialNumber',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-12',
  },
  {
    label: 'loanOrder',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'sapERP',
    validators: [],
    required: false,
    styleClass: 'col-sm-12',
  },
  {
    label: 'dateOfManufacture',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'dateOfManufacture',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-12',
    dateFormat: 'MM/YYYY',
  },
  {
    label: 'conditionText',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'sellOffConditionId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'value', separator: '' }],
    selectAllPlaceholder: 'pleaseSelect',
  },
  {
    label: 'storageLocation',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'storageLocation',
    validators: [],
    required: false,
    styleClass: 'col-12',
  },
  {
    label: 'internalIdentifier',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'internalIdentifier',
    validators: [],
    required: false,
    styleClass: 'col-6',
  },
  {
    label: 'visibility',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'sellOffVisibilityId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'value', separator: '' }],
    selectAllPlaceholder: 'pleaseSelect',
  },
  {
    label: 'salesDate',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'saleDate',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    dateFormat: 'DD.MM.YYYY',
    activeStartDate: null,
  },
  {
    label: 'deliveryTimeInWeeksNumberField',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'deliveryTimeInWeeks',
    validators: [
      { check: Validators.required, message: 'requiredErrorMessage' },
      { check: Validators.number, message: 'invalidNumberError' },
    ],
    required: true,
    styleClass: 'col-sm-12',
  },
  {
    label: 'quantity',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'quantity',
    validators: [
      { check: Validators.required, message: 'requiredErrorMessage' },
      { check: Validators.number, message: 'invalidNumberError' },
    ],
    required: true,
    styleClass: 'col-sm-12',
  },
  {
    label: 'commentText',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'salesComment',
    validators: [],
    required: false,
    styleClass: 'col-sm-12',
  },
  {
    label: 'contactPerson',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'contactPerson',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
  },
];

export const kitStatusModel = [
  {
    label: 'conditionText',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'kitConditionId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'kitStatusId',
    displayValue: [{ key: 'translatedStatus', separator: '' }],
    selectAllPlaceholder: 'pleaseSelect',
  },
];

export const singleItemForSaleInformationModel = [
  {
    label: 'location',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'stockInformationId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-12',
    options: [],
    hasSection: true,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'locationName', separator: '' }],
    optionsArrayKey: 'stockInformation',
    titleDisplay: [
      { key: 'shortName', separator: ' ' },
      { key: 'name', separator: '' },
    ],
  },
  {
    label: 'loanOrder',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'sapERP',
    validators: [],
    required: false,
    styleClass: 'col-sm-12',
  },
  {
    label: 'components',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'salesComponents',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-12',
  },
  {
    label: 'conditionText',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'sellOffConditionId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'value', separator: '' }],
    selectAllPlaceholder: 'pleaseSelect',
  },
  {
    label: 'dateOfManufacture',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'dateOfManufacture',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-12',
    dateFormat: 'MM/YYYY',
  },
  {
    label: 'storageLocation',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'storageLocation',
    validators: [],
    required: false,
    styleClass: 'col-12',
  },
  {
    label: 'internalIdentifier',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'internalIdentifier',
    validators: [],
    required: false,
    styleClass: 'col-6',
  },
  {
    label: 'visibility',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'sellOffVisibilityId',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-sm-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'id',
    displayValue: [{ key: 'value', separator: '' }],
    selectAllPlaceholder: 'pleaseSelect',
  },
  {
    label: 'salesDate',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'saleDate',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    dateFormat: 'DD.MM.YYYY',
    activeStartDate: null,
  },
  {
    label: 'deliveryTimeInWeeksNumberField',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'deliveryTimeInWeeks',
    validators: [
      { check: Validators.required, message: 'requiredErrorMessage' },
      { check: Validators.number, message: 'invalidNumberError' },
    ],
    required: true,
    styleClass: 'col-sm-12',
  },
  {
    label: 'quantity',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'quantity',
    validators: [
      { check: Validators.required, message: 'requiredErrorMessage' },
      { check: Validators.number, message: 'invalidNumberError' },
    ],
    required: true,
    styleClass: 'col-sm-12',
  },
  {
    label: 'commentText',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'salesComment',
    validators: [],
    required: false,
    styleClass: 'col-sm-12',
  },
  {
    label: 'contactPerson',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'contactPerson',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
  },
];

export const salesDetailsModel = [
  {
    label: 'internalNotes',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'internalNotes',
    validators: [],
    required: false,
    styleClass: 'col-12 internal-notes-container',
    extraProps: {
      maxLength: 5000,
    },
  },
  {
    label: 'crmQuoteId',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'crmQuoteId',
    validators: [
      { check: (value) => Validators.crmIdFormat( value, 'CRMQuoteIDShouldContainOnlyNumbersOrQUAndHyphen')},
      { check: (value) => Validators.numberOfCharacters( value, 'CRMQuoteIDShouldBeBetween7And10Characters', 7, 10 )},
    ],
    required: false,
    styleClass: 'col-12',
    extraProps: {
      maxLength: 10,
    },
  },
  {
    label: 'customer',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'customerId',
    validators: [],
    required: false,
    styleClass: 'col-12',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: false,
    idKey: 'accountId',
    displayValue: [
      { key: 'lastName', separator: ', ' },
      { key: 'firstName', separator: '' },
    ],
    selectAllPlaceholder: 'pleaseSelect',
  },
  {
    label: 'deliveryAddress',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'deliveryAddress',
    validators: [],
    required: false,
    styleClass: 'col-12 text-break delivery-address-container',
    extraProps: {
      maxLength: 1000,
    },
  },
  {
    label: 'purchaseOrder',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'purchaseOrder',
    validators: [
      { check: Validators.number, message: 'invalidNumberError' },
      { check: Validators.numberOfcharacters, message: strings.pleaseMindThatFieldNeedsToHave(10), strLength: 10 },
    ],
    required: false,
    styleClass: 'col-12',
    extraProps: {
      maxLength: 10,
    },
  },
  {
    label: 'salesOrder',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'salesOrder',
    validators: [
      { check: Validators.number, message: 'invalidNumberError' },
      { check: Validators.numberOfcharacters, message: strings.pleaseMindThatFieldNeedsToHave(10), strLength: 10 },
    ],
    required: false,
    styleClass: 'col-12',
    extraProps: {
      maxLength: 10,
    },
  },
  {
    label: 'technicalControl',
    value: '',
    type: 'checkbox',
    placeholder: '',
    field: 'technicalControl',
    validators: [],
    required: true,
    styleClass: 'col-12',
  },
  {
    label: 'internalOrder',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'internalOrder',
    validators: [
      { check: Validators.number, message: 'invalidNumberError' },
      { check: Validators.numberOfcharacters, message: strings.pleaseMindThatFieldNeedsToHave(10), strLength: 10 },
    ],
    required: false,
    styleClass: 'col-12',
    extraProps: {
      maxLength: 10,
    },
  },
  {
    label: 'desinfection',
    value: '',
    type: 'checkbox',
    placeholder: '',
    field: 'desinfection',
    validators: [],
    required: false,
    styleClass: 'col-12',
  },
  {
    label: 'completenessCheck',
    value: '',
    type: 'checkbox',
    placeholder: '',
    field: 'completenessCheck',
    validators: [],
    required: false,
    styleClass: 'col-12',
  },
  {
    label: 'productionOrderReparationOrder',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'productionOrder',
    validators: [
      { check: Validators.number, message: 'invalidNumberError' },
      { check: Validators.numberOfcharacters, message: strings.pleaseMindThatFieldNeedsToHave(12), strLength: 12 },
    ],
    required: false,
    styleClass: 'col-12',
    extraProps: {
      maxLength: 12,
    },
  },
  {
    label: 'internalStockOrder',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'internalStockOrder',
    validators: [
      { check: Validators.number, message: 'invalidNumberError' },
      { check: Validators.numberOfcharacters, message: strings.pleaseMindThatFieldNeedsToHave(10), strLength: 10 },
    ],
    required: false,
    styleClass: 'col-12',
    extraProps: {
      maxLength: 10,
    },
  },
  {
    label: 'deliveryDate',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'deliveryDate',
    validators: [],
    required: false,
    styleClass: 'col-12',
    dateFormat: 'DD.MM.YYYY',
    activeStartDate: null,
  },
];
