import React, { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import CreateStockActionButtons from 'library/common/commonComponents/CreateStockActionButtons';

import { commentModel, getDemoUnitStatusOptions } from './CreatingOrEditingComment.constants';

const CreatingOrEditingComment = ({
  commentForm,
  isLoading,
  handleCancel,
  handleSave,
  handleCommentForm,
  language,
}) => {
  const [model, setModel] = useState(commentModel);

  useEffect(() => {
    const newModel = cloneDeep(commentModel);
    newModel[3].options = getDemoUnitStatusOptions();
    setModel(newModel);
  }, [language]);

  return (
    <div className='mb-5'>
      <FormComponent
        model={model}
        formName='commentForm'
        formValue={commentForm.formData}
        onChange={handleCommentForm}
      />
      <CreateStockActionButtons onSave={handleSave} onCancel={handleCancel} isLoading={isLoading} styleClass={'save-next-pev-button'} />
    </div>
  );
};

export default CreatingOrEditingComment;
