import { connect } from 'react-redux';

import DemosBlock from './DemosBlock.component';

const mapStateToProps = ({ authReducer, languageReducer, statisticsReducer }) => ({
  user: authReducer.user,
  language: languageReducer,
  demosChartData: statisticsReducer.demosChartData,
});

export default connect(mapStateToProps)(DemosBlock);
