import React, { useState, useEffect } from 'react';

import strings from 'resources/locales/Translate';
import cloneDeep from 'lodash/cloneDeep';
import Icon from "library/common/commonComponents/Icon/Icon";
import Button from 'library/common/commonComponents/Button/Button';
import ConfirmDeletionPopup from 'library/common/commonComponents/ConfirmationBox/ConfirmationBox.component';
import debounce from 'lodash/debounce';
import $ from 'jquery';

const INITIAL_FILTER = {
    searchTerm: null,
}

export const useSapErpMapping = ({ fetchSapErpMappingList, deleteMapping }) => {
    const filter = { ...INITIAL_FILTER };
    const [selectedMapping, setSelectedMapping] = useState(null);
    const [data, setData] = useState({
        page: 1,
        size: 10,
        sort: {
            accountName: 'asc',
        },
        filter,
    });

    useEffect(() => {
        fetchSapErpMappingList(data);
    }, [data, fetchSapErpMappingList]);


    const getTableRows = () => [
        {
            title: strings.accountId,
            name: 'accountId',
            isSort: false,
            render: item => {
                return (
                    <span>{item.accountId}</span>
                )
            },
        },
        {
            title: strings.accountName,
            name: 'accountName',
            isSort: true,
            render: item => {
                return (
                    <span>{item.accountName || ''}</span>
                )
            },
        },
        {
            title: strings.street,
            name: 'street',
            isSort: false,
            render: item => {
                return (
                    <span>{item.street || '-'}</span>
                )
            },
        },
        {
            title: strings.houseNumber,
            name: 'houseNumber',
            isSort: false,
            render: item => {
                return (
                    <span>{item.houseNumber || '-'}</span>
                )
            },
        },
        {
            title: strings.postalCode,
            name: 'postalCode',
            isSort: false,
            render: item => {
                return (
                    <span>{item.postalCode || '-'}</span>
                )
            },
        },
        {
            title: strings.city,
            name: 'city',
            isSort: false,
            render: item => {
                return (
                    <span>{item.city}</span>
                )
            },
        },
        {
            title: strings.countryLand,
            name: 'country',
            isSort: false,
            render: item => {
                return (
                    <span>{item.country.name}</span>
                )
            },
        },
        {
            title: strings.comment,
            name: 'comment',
            isSort: false,
            render: item => {
                return (
                    <span>{item.comment}</span>
                )
            },
        },
        {
            title: '',
            name: 'edit',
            render: item => {
                return (<button className='button-blue' onClick={() => openEditTaskPopup(item)}>
                    <Icon
                        name='edit'
                        width={16}
                        height={16}
                        fill='#0088d0'
                    />
                    <span className='ml-1'>{strings.edit}</span>
                </button>);
            }
        },
        {
            title: '',
            name: 'delete',
            render: item => {
                return (
                    <div>
                        <Button
                            bgFill={false}
                            isGhost={true}
                            iconWidth={16}
                            iconHeight={16}
                            iconPosition='left'
                            iconStyle='mr-1'
                            iconName='delete'
                            onClick={() => openPopup(item.accountId)}
                            value={strings.delete}
                            styleClass='small-blue-button'
                        />
                        <ConfirmDeletionPopup
                            id={`confirmDeleteModal${item.accountId}`}
                            onConfirm={() => deleteMapping(item.accountId, data)}
                            title={strings.confirmDeleteMapping}
                            subtitle={strings.confirmDeleteMappingMessage}
                        />
                    </div>
                )
            },
        }
    ];

    const openPopup = id => {
        if ($(`confirmDeleteModal${id}`) && $(`confirmDeleteModal${id}`).modal) {
            $(`#confirmDeleteModal${id}`).modal('show');
        }
    };

    const handleSort = name => {
        setData(prevState => {
            const newData = cloneDeep(prevState);
            Object.keys(newData.sort).forEach(key => (newData.sort[key] = null));
            newData.sort[name] = prevState.sort[name] ? (prevState.sort[name] === 'asc' ? 'desc' : null) : 'asc';
            return newData;
        });
    };

    const handleItemsPerPage = size => {
        setData(prevState => {
            const newData = { ...prevState };
            newData.size = size;

            return newData;
        });
    };

    const handlePageChange = page => {
        setData(prevState => {
            const newData = { ...prevState };
            newData.page = page + 1;

            return newData;
        });
    };

    const openCreateTaskPopup = () => {
        $('#create-mapping-modal').modal('show');
    };

    const openEditTaskPopup = selectedMapping => {
        setSelectedMapping(selectedMapping);
        $('#edit-mapping-modal').modal('show');
    };

    const handleSearchChange = e => {
        const { value } = e.target;
        debounceUpdateData({ value });
    };

    const debounceUpdateData = debounce(({ value }) => {
        setData(
            prevState => {
                const newData = { ...prevState };
                newData.page = 1;
                newData.filter.searchTerm = value;
                return { ...newData };
            });
    }, 1000);

    return { data, getTableRows, handleSort, handleItemsPerPage, handlePageChange, openCreateTaskPopup, openEditTaskPopup, selectedMapping, setSelectedMapping, handleSearchChange };

};