import React from 'react';

import SystemMainComponentItem from '../SystemMainComponentItem';
import './SystemMainComponentList.styles.scss';

const SystemMainComponentList = ({ systemMainComponents, activeComponent, onClick }) => {

  if (!systemMainComponents) return null;

  return (
    <ul className='list mr-5'>
      {systemMainComponents.map(item => (
        <SystemMainComponentItem
          key={item.systemMainComponentId}
          item={item}
          onClick={onClick}
          isActive={item.systemMainComponentId === activeComponent}
        />
      ))}
    </ul>
  );
};

export default SystemMainComponentList;
