import { useState } from 'react';
import $ from 'jquery';

import strings from 'resources/locales/Translate';

export function useSubscriptionsPopup({ id, removeSubscription }) {
  const [isLoading, setIsLoading] = useState([]);
  const [error, setError] = useState('');

  const onCloseClick = () => {
    const popup = $(`#${id}`);
    if (popup && popup.modal) {
      popup.modal('hide');
    }
    setIsLoading([]);
    setError('');
  };

  const handleRemovingSubscription = async id => {
    if (!isLoading.includes(id)) {
      setIsLoading(prevState => [ ...prevState, id ]);
    }
    const { success } = await removeSubscription(id);
    setIsLoading(isLoading.filter(item => item !== id));
    if (!success) {
      setError(strings.somethingWentWrongMessage)
    }
  };

  return {
    onCloseClick,
    handleRemovingSubscription,
    isLoading,
    error,
  };
};
