import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import zeissInstance from 'library/utilities/AxiosInstance';
import { getTransactionDetails } from '../TransactionDetails/TransactionDetailsActions';
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { CHANGE_LOADER } from 'library/common/commonConstants/storeConstants';

export const createC2Loans = (transactionsId, arrayIds, save = false) => (dispatch) => {
  return zeissInstance
    .post(URLS.createC2CTransactions, arrayIds)
    .then(async ({ data, status }) => {
      const res = await getTransactionDetails(transactionsId, 1, 'transactions')(dispatch);
      if (status === 200) {
        dispatch(toggleActionMessage(true, 'success', save ? 'c2CLoansSaved' : 'c2CLoansCreated'));
      }
      return res;
    })
    .catch(err => {
      if (err && err.response && err.response.data && err.response.data.status === 422) {
        if (err.response.data.message == 'Max allowed c2c chain length is 5'){
          dispatch(toggleActionMessage(true, 'error', 'maxc2cLengthIs5'));
          return { success: false };
        }
      }
      dispatch(toggleActionMessage(true, 'error', 'errorOccuredText'));
      return { success: false };
    });
};

export const clearC2CLoans = (arrayIds, showLoader = true) => (dispatch) => {
  if (showLoader) {
    dispatch({ type: CHANGE_LOADER, payload: true });
  }
  return zeissInstance
    .post(URLS.clearC2CTransactions, arrayIds)
    .then(async ({ data, status }) => {
      if (showLoader) {
        dispatch({ type: CHANGE_LOADER, payload: false });
      }
      window.location.reload();
      return { success: true };
    })
    .catch(error => {
      if (showLoader) {
        dispatch({ type: CHANGE_LOADER, payload: false });
      }
      return { success: false };
    });
};
