import React from 'react';
import $ from 'jquery';
import moment from 'moment';

import strings from 'resources/locales/Translate';
import SmallButton from 'library/common/commonComponents/SmallButton/SmallButton.component';

export default function QuestionAnswerBlock({ id, answers, text, createdBy, updatedOn, setSelectedAnswer, isShowButtons }) {
  const isShowAnswer = answers && answers.length > 0;

  const openCreateAnswerPopup = () => {
    setSelectedAnswer({
      answerText: '',
      questionId: id,
      answerId: '',
    });
    $('#create-answer-popup').modal('show');
  };

  const openEditAnswerPopup = () => {
    setSelectedAnswer({
      answerText: answers[0].text,
      questionId: id,
      answerId: answers[0].id,
    });
    $('#edit-answer-popup').modal('show');
  };

  return (
    <div className='answerQuestion-block' key={id}>
      <p className='mb-2'>
        <strong>Q: {text}</strong>
        <span className='text-gray ml-3'>By {`${createdBy.firstName} ${createdBy.lastName}`}</span>
        <span className='text-gray ml-3'>{moment(updatedOn).format('DD.MM.YYYY')}</span>
      </p>
      <div>
        {isShowAnswer ? (
          <>
            <p className='mb-2'>
              <strong>A: </strong>
              <span>{answers[0].text}</span>
              <span className='text-gray ml-3'>
                By {`${answers[0].createdBy.firstName} ${answers[0].createdBy.lastName}`}
              </span>
              <span className='text-gray ml-3'>{moment(answers[0].updatedOn).format('DD.MM.YYYY')}</span>
            </p>
            {isShowButtons && (
              <SmallButton
                dataTest='edit-answer-popup'
                value={strings.editAnswer}
                onClick={openEditAnswerPopup}
                className='answer-button'
              />
            )}
          </>
        ) : (
          <>
          {isShowButtons && (
            <SmallButton
              dataTest='create-answer-popup'
              value={strings.answerQuestion}
              onClick={openCreateAnswerPopup}
              className='answer-button'
            />
          )}
          </>
        )}
      </div>
    </div>
  );
};
