import * as actionTypes from '../commonConstants/storeConstants';

const initialState = {
  categories: [],
  loanTypes: [],
  countries: [],
  countriesOfOrigin: [],
  businessUnit: [],
  usersList: [],
  rolesList: [],
  kitStatus: [],
  systemClass: [],
  systemMainComponent: [],
  conditions: [],
  categoriesAndSystems: [],
  reasonForLoan: [],
  activeAndNonDeletedStocks: [],
  systemClassWithStock: [],
  stockViewDuration: [],
  sellOffConditions: [],
  sellOffVisibility: [],
  sellOffCurrency: [],
  sellOffCountry: [],
  productHierarchy: [],
  activeProvinceCountries :[]
};

const MasterDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_COUNTRY_LIST:
      return {
        ...state,
        countries: action.payload
      };

      case actionTypes.FETCH_LABS_LOCATION_COUNTRIES:
        return {
          ...state,
          countries: action.payload
        };
      case actionTypes.FETCH_RMS_ZMCC_COUNTRIES:
        return {
          ...state,
          countries: action.payload
          };
    case actionTypes.FETCH_SELL_OFF_COUNTRY_LIST:
      return {
        ...state,
        sellOffCountry: action.payload
      };
    case actionTypes.SET_MASTER_DATA_COUNTRY_OF_ORIGIN:
      return {
        ...state,
        countriesOfOrigin: action.payload
      };
    case actionTypes.FETCH_BUSINESS_UNIT_LIST:
      return {
        ...state,
        businessUnit: action.payload
      };
    case actionTypes.FETCH_USERS_LIST:
      return {
        ...state,
        usersList: action.payload
      };
    case actionTypes.FETCH_CATEGORY_LIST:
      return {
        ...state,
        categories: action.payload
      };
    case actionTypes.FETCH_LOAN_TYPE_LIST:
      return {
        ...state,
        loanTypes: action.payload
      };
    case actionTypes.FETCH_ROLES_LIST:
      return {
        ...state,
        rolesList: action.payload
      };
    case actionTypes.FETCH_SYSTEM_CLASS_MASTER_DATA:
      return {
        ...state,
        systemClass: action.payload
      };
    case actionTypes.FETCH_SYSTEM_COMPONENT_MASTER_DATA:
      return {
        ...state,
        systemMainComponent: action.payload
      };
    case actionTypes.FETCH_KIT_STATUS:
      return {
        ...state,
        kitStatus: action.payload
      };
    case actionTypes.FETCH_CONDITIONS:
      return {
        ...state,
        conditions: action.payload
      };
    case actionTypes.FETCH_CATEGORY_AND_SYSTEM:
      return {
        ...state,
        categoriesAndSystems: action.payload
      };
    case actionTypes.ADD_REASON_FOR_LOAN_DATA:
        return {
          ...state,
          reasonForLoan: action.payload
        };
    case actionTypes.FETCH_ACTIVE_AND_NON_DELETED_STOCKS:
      return {
        ...state,
        activeAndNonDeletedStocks: action.payload
      };
    case actionTypes.FETCH_SYSTEM_CLASS_WITH_STOCKS:
      return {
        ...state,
        systemClassWithStock: action.payload
      };
    case actionTypes.FETCH_STOCK_VIEW_DURATION:
      return {
        ...state,
        stockViewDuration: action.payload,
      };
    case actionTypes.FETCH_SELL_OFF_CONDITION:
      return {
        ...state,
        sellOffConditions: action.payload,
      };
    case actionTypes.FETCH_SELL_OFF_VISIBILITY:
      return {
        ...state,
        sellOffVisibility: action.payload,
      };
    case actionTypes.FETCH_SELL_OFF_CURRENCY:
      return {
        ...state,
        sellOffCurrency: action.payload,
      };
    case actionTypes.FETCH_PRODUCT_HIERARCHY:
      return {
        ...state,
        productHierarchy: action.payload,
      };
      case actionTypes.FETCH_STOCKS_LIST:
      return {
        ...state,
        activeAndNonDeletedStocks: action.payload
      };
    case actionTypes.ACTIVE_PROVINCE_COUNTRIES:
      return {
        ...state,
        activeProvinceCountries: action.payload
      }
    default:
      return state;
  }
};

export default MasterDataReducer;
