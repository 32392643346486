import React from 'react';

import strings from 'resources/locales/Translate';
import HorizontalBarChart from 'library/common/commonComponents/HorizontalBarChart';

const DemosBlock = ({ demosChartData, language }) => {
  const hasDataForChart = !!demosChartData.length;
  const dates = demosChartData.map(item => ({ value: item.date }));
  const bookingData = demosChartData.map(item => ({ value: item.bookingValue, planned: item.planned }));
  const performedData = demosChartData.map(item => ({ value: item.performedValue, planned: item.planned }));

  return (
    <div>
      <h2>{strings.demos}</h2>
      {hasDataForChart ? (
        <div className='demos-wrapper'>
          <HorizontalBarChart
            dateArr={dates}
            bookingsArr={bookingData}
            performedArr={performedData}
            locale={language.language === 'de' ? 'de' : 'en'}
          />
        </div>
      ) : (
        <div className='d-flex justify-content-center my-5'>
          <strong>{strings.noDataFoundText}</strong>
        </div>
      )}
    </div>
  );
}

export default DemosBlock;
