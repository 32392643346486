import React, { useEffect } from 'react';
import { useMedUsWarehouseAddress } from './MedUsWarehouseAddress.hook';
import strings from 'resources/locales/Translate';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/container/BreadcrumbContainer';
import ActionMessage from 'library/common/commonComponents/ActionMessage/container/ActionMessageContainer';
import Table from 'library/common/commonComponents/Table/Table';
import SearchField from 'library/common/commonComponents/SearchField/SearchField';
import ItemsPerPage from 'library/common/commonComponents/ItemsPerPage/ItemsPerPage';
import Pagination from 'library/common/commonComponents/Pagination/Pagination';
import { itemsPerPage } from './MedUsWarehouseAddress.constants';
import toArray from 'lodash/toArray';
import EditMedAddressPopup from './EditMedAddressPopup/EditMedAddressPopup.component';

export const MeusWarehousAddress = (props) =>{

    const { medAddressArray, masterData, editMedUsAddress } = props;

    const { getTableRows, data, handleSearchChange, handleItemsPerPage, handlePageChange, selectedAddress } =
      useMedUsWarehouseAddress(props);
    
    return (
      <div>
        <div className='container-fluid mb-3 form-container'>
          <div className='row no-gutters align-items-center'>
            <h4 className='col-md col-12 mb-2'>{strings.maintainDeliveryAddress}</h4>
            <div className='col-md-auto col mr-3'>
              <div className='input-wrapper'>
                <SearchField onChange={handleSearchChange} initialValue={data.filter.searchTerm} />
              </div>
            </div>
          </div>
        </div>
        <div className='task-table'>
          <div className='container-fluid mb-3'>
            {medAddressArray && medAddressArray.content ? (
              <Table cols={getTableRows()} rows={medAddressArray.content} rowId='meditechUsAddressId' />
            ) : (
              <div className='d-flex justify-content-center'>
                <strong>{strings.noDataFoundText}</strong>
              </div>
            )}
          </div>
        </div>
        <div className='container-fluid'>
          <div className='row no-gutters'>
            {medAddressArray ? (
              <div className='col d-flex justify-content-end mb-3'>
                <ItemsPerPage
                  itemsPerPage={toArray(itemsPerPage)}
                  handleItemsPerPage={handleItemsPerPage}
                  value={itemsPerPage[data.size]}
                />
                <Pagination
                  currentPage={data.page - 1}
                  totalPages={medAddressArray.totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            ) : null}
          </div>
        </div>

        <EditMedAddressPopup
          id='edit-med-address'
          selectedAddress={selectedAddress}
          countries={masterData.countries}
          editMedUsAddress={editMedUsAddress}
          addressListPayloadData={data}
        />
      </div>
    );
}

export default MeusWarehousAddress

