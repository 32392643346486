import { connect } from 'react-redux';

import AddEditSlotPopup from './AddEditSlotPopup.component';

import { saveCreateAvailabilty, editAvailabiltySlot } from '../Workflows/Workflows.actions';


const mapStateToProps = ({ createStockReducer, languageReducer }) => ({
  stockId: createStockReducer.stockDetails.id,
  language: languageReducer,
  kitsListByWarehouse: createStockReducer.kitsListByWarehouse,
})

export default connect(mapStateToProps, {saveCreateAvailabilty, editAvailabiltySlot})(AddEditSlotPopup);