import React, { useState } from 'react';
import strings from 'resources/locales/Translate';

const VRDetails = ({ kitDetail, history }) => {

  return (
    <div className='information-container overflow-wrap: break-word'>
      <div className='container-fluid' >
        <div className='row mb-3 mt-3'>
          <div className='col-12 col-md-6'>
            <div className='row mb-3'>
              <div className='col-6'>{`${strings.windowsUserPassword}:`}</div>
              <div className='col-6'>{(kitDetail.windowsUserPassword && kitDetail.windowsUserPassword) || '-'}</div>
            </div>
            <div className='row mb-3'>
              <div className='col-6'>{`${strings.userName}:`}</div>
              <div className='col-6'>{(kitDetail.vrDetailsUserName && kitDetail.vrDetailsUserName) || '-'}</div>
            </div>
            <div className='row mb-3'>
              <div className='col-6'>{`${strings.password}:`}</div>
              <div className='col-6'>{(kitDetail.vrDetailsPassword && kitDetail.vrDetailsPassword) || '-'}</div>
            </div>
            <div className='row mb-3'>
              <div className='col-6'>{`${strings.linkToClickableURL}:`}</div>
              <div className='col-6'>
                {(kitDetail.linkToOnboardingMaterial && <a href={kitDetail.linkToOnboardingMaterial} target='_blank'>{kitDetail.linkToOnboardingMaterial} </a>) || '-'}
              </div>
            </div>
          </div>
      </div>
      </div>
    </div>
  );
};

export default VRDetails;
