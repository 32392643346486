import { SET_LANGUAGE } from '../commonConstants/storeConstants';

const initialState = {
  language: 'en',
  locale: 'en-US'
};

const LanguageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload.language,
        locale: action.payload.locale
      };
    default:
      return state;
  }
};

export default LanguageReducer;
