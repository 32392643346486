import { connect } from 'react-redux';

import { fetchLLKitStatistics } from '../../KitDetailsAction';
import LLStatistics from './LLStatistics.component';

const mapStateToProps = ({ languageReducer }) => ({
  language: languageReducer.language,
});

export default connect(mapStateToProps, { fetchLLKitStatistics })(LLStatistics);
