import React from 'react';

import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import Modal from 'library/common/commonComponents/Modal';
import strings from 'resources/locales/Translate';

import { useAddActiveSystemPopup } from './AddActiveSystemPopup.hook';

const AddActiveSystemPopup = props => {
  const {  id, systemClasses, saveActiveSystem, page, size } = props;
  const {
    activeSystemForm,
    loading,
    error,
    onSaveClick,
    handleForm,
    onCloseClick,
    model,
  } = useAddActiveSystemPopup({ id, systemClasses, saveActiveSystem, page, size });

  return (
    <Modal
      id={id}
      title={strings.activateSystemAndEnableForDUSTTracking}
      confirmAction={onSaveClick}
      confirmTitle={strings.add}
      titleOfCancel={strings.cancel}
      loading={loading}
      disableBackgroundClose
      onCloseClick={onCloseClick}
    >
    <p className='font-weight-bold my-3'>{strings.pleaseAddSystemClassAndSystemMainComponent}</p>
    <FormComponent
      model={model}
      formName='activeSystemForm'
      formValue={activeSystemForm.formData}
      onChange={handleForm}
    />
    {error && <div className='error-block'>{error}</div>}
  </Modal>
  );
};

export default AddActiveSystemPopup;
