import { connect } from 'react-redux';

import StockDashboardFilter from './StockDashboardFilter.component';
import { getStockByCountry } from '../../StockDashboard.actions';

const mapStateToProps = ({ languageReducer, stockDashboardReducer }) => ({
  language: languageReducer,
  businessUnits: stockDashboardReducer.businessUnits,
  countries: stockDashboardReducer.countries,
  stocks: stockDashboardReducer.stocks,
});

export default connect(mapStateToProps, { getStockByCountry })(StockDashboardFilter);
