import Modal from "library/common/commonComponents/Modal";
import React from "react";
import strings from "resources/locales/Translate";
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import { useBulkCancelTwentyFourHourReservation } from "./BulkCancelTwentyFourHourReservation.hook";

export const BulkCancelTwentyFourHourReservation = ({
  id,
  data,
  bundleLoans,
  cancelBulkResevations,
  setSelectedData,
  fetchTransListData,
  transactionType,
  setShowBulkCancelPopup,
  removeItemFromCartForBulk
}) => {
  const {
    transactionsId,
    isSelectedAll,
    onCloseClick,
    handleSelect,
    handleSelectAll,
    filteredBundleLoans,
    onClickSave,
    isLoading,
    error,
  } = useBulkCancelTwentyFourHourReservation({
    id,
    data,
    bundleLoans,
    cancelBulkResevations,
    setSelectedData,
    fetchTransListData,
    transactionType,
    setShowBulkCancelPopup,
    removeItemFromCartForBulk
  });
  return (
    <Modal
      id={id}
      className='mt-5 modal-lg'
      title={strings.adoptChangeForSubsequentReservation}
      confirmAction={onClickSave}
      confirmTitle={strings.relatedTransactionsConfirm}
      titleOfCancel={strings.relatedTransactionsCancel}
      loading={isLoading}
      disableBackgroundClose
      onCloseClick={onCloseClick}
    >
      <Checkbox selected={isSelectedAll} field='0' onChange={handleSelectAll} label={strings.selectAll} />
      <Checkbox
        selected={transactionsId.includes(data && data.transactionsId)}
        onChange={handleSelect(data)}
        label={`#${data && data.transactionNumber} (${strings.currentTransaction})`}
        disabled={true}
      />
      {filteredBundleLoans &&
        filteredBundleLoans.map(loans => (
          <Checkbox
            selected={transactionsId.includes(loans.transactionsId)}
            onChange={handleSelect(loans)}
            label={`#${loans.transactionNumber}`}
          />
        ))}
      {error && <span className='text-danger error-block'>{error}</span>}
    </Modal>
  );
};

export default BulkCancelTwentyFourHourReservation;