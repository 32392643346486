import React from 'react';

import DotLoader from 'library/common/commonComponents/DotLoader';

import { useShowMore } from './ShowMore.hook';

const ShowMore = props => {
  const { load, pending = false, hasMoreItems } = props;
  const buttonRef = useShowMore();

  if (!hasMoreItems) return null;

  return (
    <>
      {pending && (
        <div className='col-12'><DotLoader /></div>
      )}
      <div style={{ width: '100%' }} ref={buttonRef} onClick={load}/>
    </>
  );
}

export default ShowMore;
