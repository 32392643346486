import React from 'react';
import strings from 'resources/locales/Translate';
import Modal from 'library/common/commonComponents/Modal';
import Checkbox from 'library/common/commonComponents/FormComponent/Optimized/Checkbox';
import { useSelectTypePopup } from './SelectTypePopup.hook';

export const SelectTypePopup = ({id, setFormType, setShowModal, title = strings.adoptChangeForSubsequentReservation, onSubmit = null, errorMessage = ""}) => {
    const {onChange, type, onCloseClick, onSaveClick} = useSelectTypePopup({id, setFormType, setShowModal, onSubmit});
    return(
        <Modal
        id={id}
        className='mt-5'
        title={title}
        confirmAction={onSaveClick}
        disableBackgroundClose
        onCloseClick={onCloseClick}
      >
        <Checkbox
          selected={type === 'lm'}
          onChange={() => onChange('lm')}
          label={strings.lmLabel}
        />
        <Checkbox
          selected={type === 'sem'}
          onChange={() => onChange('sem')}
          label={strings.semLabel}
        />
        <Checkbox
          selected={type === 'xb'}
          onChange={() => onChange('xb')}
          label={strings.xbLabel}
        />
        <Checkbox
          selected={type=== 'xrm'}
          onChange={() => onChange('xrm')}
          label={strings.xrmLabel}
        />
        {/* {error && <span className='text-danger error-block'>{error}</span>} */}
        {errorMessage && <span className='text-danger error-block'>{errorMessage}</span>}
      </Modal>
    )
}

export default SelectTypePopup;
