import { useState, useCallback } from 'react';
import $ from 'jquery';

const OPPORTUNITY_LIST_ID_POPUP = 'opportunityListPopup';

export const useOpportunityListPopup = ({
  opportunityList,
  setOpportunities,
  updateShortDescription,
  updateCustomerForm,
}) => {
  const [selectedOpportunities, setSelectedOpportunities] = useState([]);
  const hasMaxOpportunities = selectedOpportunities.length > 10;
  const noOpportunities = selectedOpportunities.length === 0;

  const handleChange = useCallback((status, id) => {
    if (selectedOpportunities.includes(id)) {
      const newSelectedOpportunities = selectedOpportunities.filter(item => item !== id);

      return setSelectedOpportunities(newSelectedOpportunities);
    }

    const newSelectedOpportunities = [ ...selectedOpportunities ];
    newSelectedOpportunities.push(id);

    return setSelectedOpportunities(newSelectedOpportunities);
  }, [selectedOpportunities]);

  const onCloseClick = useCallback(() => {
    const popup = $(`#${OPPORTUNITY_LIST_ID_POPUP}`);

    if (popup && popup.modal) {
      popup.modal('hide');
    }
    setSelectedOpportunities([]);
  }, []);

  const onSaveClick = useCallback(() => {
    setOpportunities(selectedOpportunities);

    const {
      opportunityName,
      contactFirstName,
      contactLastName,
      organization,
      department,
      street,
      zip,
      city,
      countryId,
      phone,
      email,
      houseNumber,
      customerName,
    } = opportunityList.find(item => item.opportunityId === selectedOpportunities[selectedOpportunities.length - 1]) || {};
    const deliveryAddressForm = {
      firstName: contactFirstName,
      lastName: contactLastName,
      organization,
      department,
      street,
      houseNumber,
      postalCode: zip,
      city,
      countryId,
      phone,
      email,
    };
    const descriptionName = selectedOpportunities.length === 1 ? opportunityName : null;

    updateShortDescription(descriptionName, deliveryAddressForm);
    updateCustomerForm(customerName, `${contactFirstName ? contactFirstName : ""} ${contactLastName ? contactLastName : "" }`);
    
    onCloseClick();
  }, [selectedOpportunities, onCloseClick, opportunityList, setOpportunities, updateShortDescription]);

  return {
    selectedOpportunities,
    hasMaxOpportunities,
    noOpportunities,
    handleChange,
    onSaveClick,
    onCloseClick,
  };
};
