import cloneDeep from 'lodash/cloneDeep';

import {
  SET_IS_OPEN_NOTIFICATIONS_BAR,
  FETCH_ALL_NOTIFICATIONS,
  ADD_NOTIFICATION,
  SET_IS_NOTIFICATIONS_LOADING,
} from '../commonConstants/storeConstants';

const initialState = {
  notifications: [],
  isOpenNotificationBar: false,
  isNotificationsLoading: false,
};

const NotificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_OPEN_NOTIFICATIONS_BAR:
      return {
        ...state,
        isOpenNotificationBar: action.payload,
      };
    case SET_IS_NOTIFICATIONS_LOADING:
      return {
        ...state,
        isNotificationsLoading: action.payload,
      };
    case FETCH_ALL_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case ADD_NOTIFICATION:
      const copyNotificationsArray = cloneDeep(state.notifications);
      copyNotificationsArray.unshift(action.payload);

      return {
        ...state,
        notifications: copyNotificationsArray,
      };
    default:
      return state;
  }
};

export default NotificationsReducer;
