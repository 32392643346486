import React, { Component } from 'react';

import strings from 'resources/locales/Translate';
import FileUploader from 'library/common/commonComponents/FileUploader/FileUploader';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import Button from 'library/common/commonComponents/Button/Button';
import FilesListView from 'library/common/commonComponents/FileList/FilesListView';
import AddressInfo from 'library/common/commonComponents/AddressInfo/AddressInfo';
import IconButton from 'library/common/commonComponents/IconButton';
import { scrollToTop } from 'library/utilities/scrollActions';
import { getSelectedCountry } from 'library/utilities/getSelectedValues';

import OpportunityForm from '../OpportunityIds';
import SelectedKits from '../SelectedKits';
import CourseLoanCart from '../CourseLoanCart';
import SingleLoanCart from '../SingleLoanCart';
import KitloanMaterialNumberAndDescription from '../KitloanMaterialNumberAndDescription/KitLoanMaterialNumberAndDescription.component';
import AsyncSelect from 'react-select/async';
import BlockingCalendarMessagPopup from 'modules/Calendar/component/BlockingCalendarMessagPopup/BlockingCalendarMessagPopup.component';
import { LOAN_TYPES } from 'modules/KitLoan/KitLoan.constants';

class ConfirmationStep extends Component {
  state = {
    isEditDescription: false,
    isEditAttachments: false,
    isEditTypeOfDeliveryAddress: false,
    isEditBorrower: false,
    isEditUserAddress: false,
    isEditDeliveryAddressBlock: false,
    isEditDeliveryAddress: false,
    isEditDeliveryComment: false,
    isEditPickUpComment: false,
    isValidForm: false,
  };

  // componentDidMount() {
  //   scrollToTop(500);
  // }

  executeBooking = () => {
    const { loanType } = this.props;
    const formsToValidate = [
      { model: this.props.descriptionModel, form: this.props.descriptionForm, formName: 'descriptionForm' },
      { model: this.props.borrowerModel, form: this.props.borrowerForm, formName: 'borrowerForm' },
      {
        model: this.props.deliveryAddressModel,
        form: this.props.deliveryAddressForm,
        formName: 'deliveryAddressForm',
      },
      {model: this.props.campaignIdModelForm, form: this.props.campaignIdForm, formName: "campaignIdForm"}
    ];
    if (loanType === 'singleLoan' || loanType === 'courseLoan') {
      formsToValidate.push({
        model: this.props.reservationPeriodModel,
        form: this.props.reservationPeriodForm,
        formName: 'reservationPeriodForm',
      });
    }
    this.props.executeBooking(formsToValidate);
  };

  goToPrevStepKitLoan = () => {
    this.props.changeActiveStep(2);
  };

  changeIsEditTypeFlag = type => () => {
    this.setState(prevState => ({ [type]: !prevState[type] }));
  };

  goToCalendar = () => {
    this.props.history.push('/calendar');
  };

  renderCart = () => {
    const {
      handleForm,
      componentsForm,
      componentsModel,
      loanType,
      onSelectCourseItem,
      selectedKits,
      updateDateOfCartItem,
      overtakenDataForSingleLoan,
      onSelectSingleItem,
      handleWorkflowData,
      expectedLoanFees,
      activeStep,
      additionalLoanRequestIsPossible,
      onAddKitloanMaterialNumber,
      kitLoanMaterialNumberAndDescription,
      showFormAdditionalSingleActivated,
      isFormOpen,
      checkMedusStock,
      checkMedTaiwanWarehouse
    } = this.props;
    for (let i = 0; i < selectedKits.length; i++) {
      let kit = selectedKits[i];
      for (let j = 0; j < expectedLoanFees.length; j++) {
        let message = expectedLoanFees[j];
        if (message.kitId == kit.resourceId) {
          kit['expectedLoanFees'] = message;
          selectedKits[i] = kit;
          break;
        }
      }
    }
    if (loanType === 'kitLoan') {
      return (
        <>
          <SelectedKits
            selectedKits={selectedKits}
            goToCalendar={this.goToCalendar}
            handleDateChange={updateDateOfCartItem}
            activeStep={activeStep}
          />
          {additionalLoanRequestIsPossible && (
            <div className='mt-4'>
              <KitloanMaterialNumberAndDescription
                kitLoanMaterialNumberAndDescription={kitLoanMaterialNumberAndDescription}
                onSelect={onAddKitloanMaterialNumber}
                isFormOpened={showFormAdditionalSingleActivated}
                isFormOpenFunction={isFormOpen}
              />
            </div>
          )}
        </>
      );
    } else if (loanType === 'courseLoan') {
      return (
        <CourseLoanCart
          handleDateChange={updateDateOfCartItem}
          onSelect={onSelectCourseItem}
          selectedKits={selectedKits}
          checkMedusStock={checkMedusStock}
          checkMedTaiwanWarehouse={checkMedTaiwanWarehouse}
          handleWorkflowData={handleWorkflowData}
        />
      );
    } else if (loanType === 'singleLoan') {
      return (
        <SingleLoanCart
          overtakenDataForSingleLoan={overtakenDataForSingleLoan}
          selectedKits={selectedKits}
          onSelect={onSelectSingleItem}
          handleWorkflowData={handleWorkflowData}
          model={componentsModel}
          form={componentsForm}
          formName='componentsForm'
          onChange={handleForm}
          activeStep={activeStep}
          expectedLoanFees={expectedLoanFees}
        />
      );
    }
  };

  renderDescription = () => {
    const {
      additionalData,
      descriptionForm,
      descriptionModel,
      handleForm,
      setOpportunity,
      removeOpportunity,
      opportunities,
      loanType,
      setIsOpportunityIdCrmError,
      isOpportunityIdCrmError,
      isRequiredOpportunityID,
      enteredOpportunityId,
      handleEnteredOpportunityId,
      setOpportunities,
      updateShortDescription,
      campaignIdModelForm,
      campaignIdForm,
      showCampaignIdFormBasedOnReasonForLoan,
      showCampaignIdIfWorkflowEnabled
    } = this.props;
    const { isEditDescription } = this.state;
    const selectedReason =
      descriptionForm.formData &&
      descriptionForm.formData.reasonForLoanIdFullValue &&
      descriptionForm.formData.reasonForLoanIdFullValue;

    const showCampaignIdField = (loanType === LOAN_TYPES.KIT_LOAN || loanType === LOAN_TYPES.COURSE_LOAN) && showCampaignIdFormBasedOnReasonForLoan && showCampaignIdIfWorkflowEnabled;
    return (
      <>
        <div className='d-flex justify-content-between align-items-start mb-3'>
          <h4 className='subtitle-loan mb-3'>{strings.descriptionTextStep3}</h4>
          {!isEditDescription && (
            <IconButton iconName='edit' value={strings.edit} onClick={this.changeIsEditTypeFlag('isEditDescription')} />
          )}
        </div>
        {isEditDescription ? (
          <>
            <FormComponent
              formName={'descriptionForm'}
              model={descriptionModel}
              formValue={descriptionForm.formData}
              onChange={handleForm}
            />
            {(loanType === 'singleLoan' || loanType === 'kitLoan') && (
              <OpportunityForm
                setOpportunity={setOpportunity}
                setOpportunities={setOpportunities}
                removeOpportunity={removeOpportunity}
                opportunities={opportunities}
                isRequired={isRequiredOpportunityID}
                setIsOpportunityIdCrmError={setIsOpportunityIdCrmError}
                isOpportunityIdCrmError={isOpportunityIdCrmError}
                state={enteredOpportunityId}
                handleEnteredOpportunityId={handleEnteredOpportunityId}
                descriptionForm={descriptionForm}
                updateShortDescription={updateShortDescription}
              />
            )}
            {
              showCampaignIdField && <FormComponent
                formName={'campaignIdForm'}
                model={campaignIdModelForm}
                formValue={campaignIdForm.formData}
                onChange={handleForm}
              />
            }
          </>
        ) : (
          <div className='row mb-3'>
            <div className='col-12'>
              <div className='label-field'>{strings.reasonForLoan}</div>
              <div className='mb-3'>{(selectedReason && selectedReason.name) || 'No reason for loan'}</div>
            </div>
            <div className='col-12'>
              <div className='label-field'>{strings.commentText}</div>
              <div className='mb-3'>
                {descriptionForm.formData && descriptionForm.formData.comment
                  ? descriptionForm.formData.comment
                  : 'No comment'}
              </div>
            </div>
            <div className='col-12'>
              <div className='label-field'>{strings.nameShortDescription}</div>
              <div className='mb-3'>
                {descriptionForm.formData && descriptionForm.formData.name ? descriptionForm.formData.name : 'No name'}
              </div>
            </div>

            <div className='col-12'>
              <div className='label-field'>{strings.opportunityIdCRM}</div>
              <div className='mb-3'>
                {opportunities && opportunities.length
                  ? opportunities.map(opportunity => <div className='mb-1'>{opportunity.opportunityNumber}</div>)
                  : 'No opportunity ID CRM'}
              </div>
            </div>
            {showCampaignIdField === true ?
            <div className='col-12'>
              <div className='label-field'>{strings.campaignId}</div>
              <div className='mb-3'>
                {campaignIdForm?.formData?.campaignId ? `C-${campaignIdForm?.formData?.campaignId}` : "No Campaign Id" }
              </div>
            </div>: null}

            {additionalData && additionalData.sapERPIsPossible && (
              <div className='col-12'>
                <div className='label-field'>{strings.kitLoanOrderText}</div>
                <div className='mb-3'>
                  {descriptionForm.formData && descriptionForm.formData.sapErp ? descriptionForm.formData.sapErp : '-'}
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  renderAttachments = () => {
    const { isEditAttachments } = this.state;
    const { addLoanAttachmentFile, loanAttachments, removeLoanAttachmentFile } = this.props;
    return (
      <div className='mb-3'>
        <div className='d-flex justify-content-between align-items-start'>
          <h4 className='subtitle-loan mb-3'>{strings.attachments}</h4>
          {!isEditAttachments && (
            <IconButton iconName='edit' value={strings.edit} onClick={this.changeIsEditTypeFlag('isEditAttachments')} />
          )}
        </div>
        {isEditAttachments ? (
          <div className='mb-3'>
            <FileUploader
              mimeType='image/*,.pdf,.doc,.docx,.xls,.xlsx'
              files={loanAttachments}
              onRemove={removeLoanAttachmentFile}
              onFileAdd={addLoanAttachmentFile}
              errorMessage={strings.allowedFormats}
            />
          </div>
        ) : (
          <div className='mb-3'>
            {loanAttachments.length ? (
              <FilesListView
                mimeType='image/*,.pdf,.doc,.docx,.xls,.xlsx'
                files={loanAttachments}
                onRemove={removeLoanAttachmentFile}
                isUploader={false}
              />
            ) : (
              'No attachments'
            )}
          </div>
        )}
      </div>
    );
  };

  renderBorrower = () => {
    const { isEditBorrower } = this.state;
    const { borrowerForm, borrowerModel, handleForm, masterData } = this.props;
    const selectedBorrower =
      borrowerForm.formData &&
      borrowerForm.formData.borrowerId &&
      borrowerForm.formData.borrowerIdFullValue &&
      borrowerForm.formData.borrowerIdFullValue;
    const yourAddressCountry =
      borrowerForm &&
      borrowerForm.formData &&
      borrowerForm.formData.userAddress &&
      borrowerForm.formData.userAddress.countryId
        ? getSelectedCountry(borrowerForm.formData.userAddress.countryId, masterData.countries)
        : '';
    return (
      <div className='mb-3'>
        <div className='d-flex justify-content-between align-items-start'>
          <h4 className='subtitle-loan mb-3'>{strings.borrower}</h4>
          {!isEditBorrower && (
            <IconButton iconName='edit' value={strings.edit} onClick={this.changeIsEditTypeFlag('isEditBorrower')} />
          )}
        </div>
        <div className='mb-3'>
          {isEditBorrower ? (
            <FormComponent
              formName='borrowerForm'
              model={borrowerModel}
              formValue={borrowerForm && borrowerForm.formData}
              onChange={handleForm}
            />
          ) : (
            <div>{selectedBorrower ? `${selectedBorrower.lastName} ${selectedBorrower.firstName}` : 'No borrower'}</div>
          )}
          {borrowerForm && borrowerForm.formData && borrowerForm.formData.userAddress && (
            <AddressInfo
              formData={borrowerForm.formData.userAddress}
              changeIsEditTypeFlag={this.changeIsEditTypeFlag}
              addressCountry={yourAddressCountry}
              flag='UserAddress'
              isEditable={false}
            />
          )}
        </div>
      </div>
    );
  };

  renderDeliveryAddress = () => {
    const { isEditDeliveryAddressBlock } = this.state;
    const { deliveryAddressForm, masterData } = this.props;
    const deliveryAddressCountry = deliveryAddressForm.formData.countryId
      ? getSelectedCountry(deliveryAddressForm.formData.countryId, masterData.countries)
      : null;
    return (
      <AddressInfo
        formData={deliveryAddressForm.formData}
        changeIsEditTypeFlag={this.changeIsEditTypeFlag(
          isEditDeliveryAddressBlock ? 'isEditDeliveryAddress' : 'isEditDeliveryAddressBlock',
        )}
        addressCountry={deliveryAddressCountry}
        flag='isEditDeliveryAddress'
        isEditable
      />
    );
  };

  renderDeliveryAddressBlock = () => {
    const {
      typeOfDeliveryAddressModel,
      deliveryAddressModel,
      deliveryCommentModel,
      typeOfDeliveryAddressForm,
      deliveryAddressForm,
      deliveryCommentForm,
      handleForm,
      loadOptions,
      handleSelectDropdownChanges,
      isMedUsStock,
      courseLoanMedStock,
      pickUpComment,
      pickUpCommentModel,
      pickUpCommentForm
    } = this.props;
    const { isEditDeliveryAddressBlock, isEditDeliveryComment, isEditTypeOfDeliveryAddress, isEditDeliveryAddress, isEditPickUpComment } =
      this.state;

    const deliveryComment =
      (deliveryCommentForm.formData && deliveryCommentForm.formData.deliveryComment) || 'No delivery comment';
      const pickUpCommentvalue =
      (pickUpCommentForm.formData && pickUpCommentForm.formData.pickUpComment) || 'No pick-up comment';

    const selectedDeliveryAddress =
      typeOfDeliveryAddressForm &&
      typeOfDeliveryAddressForm.formData &&
      (typeOfDeliveryAddressForm.formData.deliveryAddressFullValue ||
        typeOfDeliveryAddressForm.formData.deliveryAddress);

    const setDefaultValueOfDropdown =
      (typeOfDeliveryAddressForm && typeOfDeliveryAddressForm.formData) && typeOfDeliveryAddressForm.formData.name
        ? { title: typeOfDeliveryAddressForm.formData.name }
        : typeOfDeliveryAddressForm.formData.name ? {
            title: `${typeOfDeliveryAddressForm.formData.organization}, ${typeOfDeliveryAddressForm.formData.postalCode}`,
          }:'';

    return (
      <div className='mb-3'>
        <div className='d-flex justify-content-between align-items-start'>
          <h4 className='subtitle-loan mb-3'>{strings.deliveryAddress}</h4>
        </div>

        {isEditTypeOfDeliveryAddress && !(isMedUsStock || courseLoanMedStock) ? (
          <FormComponent
            formName='typeOfDeliveryAddressForm'
            model={typeOfDeliveryAddressModel}
            formValue={typeOfDeliveryAddressForm && typeOfDeliveryAddressForm.formData}
            onChange={handleForm}
          />
        ) : (
          <>
            <div className='d-flex justify-content-between align-items-start'>
              {!(isMedUsStock || courseLoanMedStock) && (
                <div className='mb-3'>
                  <p>{selectedDeliveryAddress ? selectedDeliveryAddress.name : 'No delivery address'}</p>
                </div>
              )}
              {!(isMedUsStock || courseLoanMedStock) && isEditDeliveryAddressBlock && (
                <IconButton
                  iconName='edit'
                  value={strings.edit}
                  onClick={this.changeIsEditTypeFlag('isEditTypeOfDeliveryAddress')}
                />
              )}
            </div>
          </>
        )}
        {!(isMedUsStock || courseLoanMedStock) && (
          <div>
            {isEditDeliveryAddressBlock && isEditDeliveryAddress ? (
              <FormComponent
                formName='deliveryAddressForm'
                model={deliveryAddressModel}
                formValue={deliveryAddressForm && deliveryAddressForm.formData}
                onChange={handleForm}
              />
            ) : (
              this.renderDeliveryAddress()
            )}
          </div>
        )}

        {isEditTypeOfDeliveryAddress && (isMedUsStock || courseLoanMedStock) ? (
          <>
            <p style={{ fontSize: '12px' }}>{strings.deliveryAddress}</p>
            <AsyncSelect
              defaultOptions
              loadOptions={loadOptions}
              getOptionLabel={e => e.title}
              onChange={handleSelectDropdownChanges}
              components={{
                IndicatorSeparator: () => null,
              }}
              styles={{
                menu: provided => ({ ...provided, zIndex: 9999 })
              }}
              placeholder={strings.pleaseSelect}
              defaultValue={setDefaultValueOfDropdown}
            />
          </>
        ) : (
          <>
            {(isMedUsStock || courseLoanMedStock) && (
              <div className='d-flex justify-content-between align-items-start'>
                <div className='mb-3'>
                  <p>{setDefaultValueOfDropdown ? setDefaultValueOfDropdown.title : 'No delivery address'}</p>
                </div>
                {isEditDeliveryAddressBlock && (
                  <IconButton
                    iconName='edit'
                    value={strings.edit}
                    onClick={this.changeIsEditTypeFlag('isEditTypeOfDeliveryAddress')}
                  />
                )}
              </div>
            )}
          </>
        )}
        {(isMedUsStock || courseLoanMedStock) && (
          <div>
            {isEditDeliveryAddressBlock && isEditDeliveryAddress ? (
              <FormComponent
                formName='deliveryAddressForm'
                model={deliveryAddressModel}
                formValue={deliveryAddressForm && deliveryAddressForm.formData}
                onChange={handleForm}
              />
            ) : (
              this.renderDeliveryAddress()
            )}
          </div>
        )}
        {isEditDeliveryComment && (
          <FormComponent
            formName='deliveryCommentForm'
            model={deliveryCommentModel}
            formValue={deliveryCommentForm && deliveryCommentForm.formData}
            onChange={handleForm}
          />
        )}
        {!isEditDeliveryComment && (
          <>
            <div className='d-flex justify-content-between align-items-start'>
              <div className='mb-3'>
                <p className='label-field'>{strings.deliveryComment}</p>
                <p>{deliveryComment}</p>
              </div>
              {isEditDeliveryAddressBlock && (
                <IconButton
                  iconName='edit'
                  value={strings.edit}
                  onClick={this.changeIsEditTypeFlag('isEditDeliveryComment')}
                />
              )}
            </div>
          </>
        )}
        {pickUpComment &&
        <div>
        {isEditPickUpComment && (
          <FormComponent
          formName='pickUpCommentForm'
          model={pickUpCommentModel}
          formValue={pickUpCommentForm && pickUpCommentForm.formData}
          onChange={handleForm}
        />
        )}
        {!isEditPickUpComment && (
          <>
            <div className='d-flex justify-content-between align-items-start'>
              <div className='mb-3'>
                <p className='label-field'>{strings.pickupComment}</p>
                <p>{pickUpCommentvalue}</p>
              </div>
              {isEditDeliveryAddressBlock && (
                <IconButton
                  iconName='edit'
                  value={strings.edit}
                  onClick={this.changeIsEditTypeFlag('isEditPickUpComment')}
                />
              )}
            </div>
          </>
        )}
        </div>}
      </div>
    );
  };

  render() {
    const { disableSave, showCalenderBlockPopup, blockTransactionList } = this.props;
    return (
      <div>
        <h2 className='title-loan mb-4'>{strings.confirmationStep3}</h2>
        <div className='row'>
          <div className='col-12 col-md-6'>
            {this.renderDescription()}
            {this.renderAttachments()}
            {this.renderBorrower()}
            {this.renderDeliveryAddressBlock()}
          </div>

          <div className='col-12 col-md-6'>{this.renderCart()}</div>
        </div>

        <div className='col-12 col-md-6'>
          <div className='d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between'>
            <div className='col-auto p-0 mt-3 mt-md-0 pt-3 pb-3 pt-md-0 pb-md-0'>
              <IconButton
                data-test='go-to-prev-step-button'
                iconName='arrow'
                value={strings.backToCustomerData}
                styles='arrow-left'
                onClick={this.goToPrevStepKitLoan}
              />
            </div>
            <div className='col-auto p-0'>
              <Button
                data-test='go-to-next-step-button'
                bgFill={true}
                iconName='arrow'
                value={strings.executeBooking}
                styleClass='mt-0'
                disabled={disableSave}
                onClick={this.executeBooking}
              />
            </div>
            {showCalenderBlockPopup &&<BlockingCalendarMessagPopup
                id = 'calendar-block'
                 tranactionDelayedList={blockTransactionList}
              />}
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmationStep;
