
export const header = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>email</title>
    <style>
        *{
            margin: 0;
            padding: 0;
            box-sizing: border-box;
        }
        body{
      font-size: 20px;
        }
        @font-face {
      font-family: ZEISSFrutigerNextUI;
      src: url(${process.env.REACT_APP_BASE_URL}assets/ZEISSFrutigerNextW1G-Light.ttf);
    }
        .Email-Template1 {
      padding: 63px 165px 62px 164px;
      background-color: #f0f0f0;
    }
        .Rectangle {
      margin: 0 0 30px 1px;
      padding: 50px 95px;
      background-color: #fff;
    }
    .content-1{
        margin: 15px 0 20px;
      font-family: ZEISSFrutigerNextUI;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000;
      font-size:15px
    }
    .content-2{
        /* display: flex; */
        /* margin: 50px 0 20px; */
        margin-left: 100px;
      font-family: ZEISSFrutigerNextUI;
      font-weight: lighter;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000;
      border-spacing: 20px 0;
    }
    .footer-1{
        display: flex;
        justify-content: space-evenly;
      margin: 50px 15px 10px 0;
      font-family: ZEISSFrutigerNextUI;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #00053a;
    }
    .Line {
      width: 1px;
      height: 190px;
      margin-left: 20px;
      border: solid 2px #cacde5;
      border-style: dashed;
      margin-right: 20px;
    }
    .link{
        margin-left: 100px;
    }
    .email{
        display: none;
    }
    @media (max-width:1000px) {
        .Email-Template1{
            padding: 63px 100px 62px 100px;
        }
        .Rectangle{
            padding: 50px 40px;
        }
        body{
      font-size: 16px;
        }
        .footer-1{
      font-size: 14px;
        }
        .content-2{
            margin-left: 50px;
        }
        .link{
            margin-left: 50px;
        }

    }
    @media (max-width:900px) {
        .Email-Template1{
            padding: 63px 50px 62px 50px;
        }
    }
    @media (max-width:700px) {
        .footer-b{
            margin-left: 10px;
        }
    }

    @media (max-width:600px) {
        body{
            font-size: 14px;
        }
        .Email-Template1{
            padding: 63px 20px 62px 20px;
        }
        .content-2{
            margin-left: 0px;
        }
        .link{
            margin-left: 0px;
        }
    }
    @media (max-width:1200px) {
        .Rectangle{
            padding: 50px 10px;
        }
    }
    @media (max-width:460px) {
        .Email-Template1{
            padding: 63px 0px 62px 0px;
        }
        .Rectangle{
            padding: 50px 5px;
        }
    }
    @media (max-width:412px) {
        .email{
            display: block;
        }
    }
    .table-content {
    font-family: ZEISSFrutigerNextUI;
    border-collapse: collapse;
    width: 100%;
    }

    .table-content td, .table-content th {
    border: 1px solid #ddd;
    padding: 8px;
    }
    table{
        font-size: 16px;
    }
    .table-content tr:nth-child(odd){background-color: #f0f0f0;}
    .table-content th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: rgba(18, 30, 146, 0.2);
    color: white;
    font-weight: 100;
    color: #121e92;
    font-size: 16px;
    }
    </style>
</head>
​
<body>
    <div class="Email-Template1" >
        <div class="Rectangle" >
            <div>
                <img src="${process.env.REACT_APP_BASE_URL}/assets/bitmap.png" alt="" style="width: 70px">
            </div>
            <br>
            <div class="content-1" >`


export const footer = `</div>
</div>
<div class="footer-1">
    <div style="flex:1;text-align: right;">
        <p >ZEISS Demo Management App, Business Group Microscopy <br>
            Carl Zeiss Microscopy GmbH, ZEISS Gruppe</p>
            <br>
        <p>
            Carl-Zeiss-Promenade 10, <br>
            07745 Jena, <br>
            Germany <br>
        </p>
        <br>
        <a href="demoloanapp@zeiss.com">demoloanapp@zeiss.com</a> <br>
        <a href="www.zeiss.com">www.zeiss.com</a>
    </div>
    <div >
        <div class="Line"></div>
    </div>
    <div class="footer-b" style="flex:1;">
        <p >Carl Zeiss Microscopy GmbH <br>
            Headquarters: Jena, Germany</p>
            <br>
        <p>
            Chairman of the Supervisory Board: Dr. Jochen Peter <br>
            Management Board: Dr. Michael Albiez (President & CEO), <br>
            Sandro Förster <br>
        </p>
         <br>
        <p>Commercial register, Jena: HRB 210 536, <br>
            VAT REG No: DE 814 503 774</p>
    </div>
</div>
</div>
</body>
</html>`







