import { logoutService, sendAuthenticationToServerService } from 'library/api/authentication.service';
import { getUserLocationInfo } from 'library/api/location.service';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import { saveToStorage } from 'library/utilities/storage';
import { DUST_USER } from 'library/common/commonConstants/roles';

import { setLanguage } from './LanguageActions';
import {
  REMOVE_AUTHENTICATION,
  SET_AUTHENTICATION,
  SET_AUTHENTICATION_LOADING,
} from '../commonConstants/storeConstants';
import { fetchMasterDataService } from 'library/api/master-data.service';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { Redirect, Route, withRouter, Switch } from 'react-router-dom';
import Homepage from 'modules/Homepage/HomepageContainer';
import { FETCH_REFURBISHMENT_FEES_COUNTRY_LIST } from 'modules/Administration/RefurbishmentFees/RefurbishmenFess.constants';
import { fetchSubMenuOfZmccRequest } from 'modules/Homepage/HomepageActions';
import moment from 'moment';

/**
 * This action will save token in sessionStorage and send the token to server and mark user as logged in
 * @param token
 * @param user
 * @returns {Function}
 */
export const setAuthentication =
  (token, user = null) =>
  async dispatch => {
    if (!user && !token) {
      dispatch(setAuthenticationLoading(false));

      return;
    }

    saveToStorage(identifiers.token, token);
    dispatch(setAuthenticationLoading(true));

    if (user) {
      const isAdmin = user.admin;
      const hasNoStockRoles = !user.stockRoles.length;
      const isDustUser = user.globalRoles.includes(DUST_USER);
      const isOnlyDustUser = !isAdmin && hasNoStockRoles && isDustUser;

      dispatch({
        type: SET_AUTHENTICATION,
        payload: { ...user, dustUser: isOnlyDustUser },
      });
      saveToStorage(identifiers.user, user);
      dispatch(setAuthenticationLoading(false));

      return;
    }

    const dataToSend = await getUserLocationInfo();

    try {
      const res = await sendAuthenticationToServerService(dataToSend);
      if (res.status === 200) {
        const isAdmin = res.data.admin;
        const hasNoStockRoles = !res.data.stockRoles.length;
        const isDustUser = res.data.globalRoles.includes(DUST_USER);
        const isOnlyDustUser = !isAdmin && hasNoStockRoles && isDustUser;

        dispatch({
          type: SET_AUTHENTICATION,
          payload: { ...res.data, dustUser: isOnlyDustUser },
        });
        saveToStorage(identifiers.user, res.data);
        dispatch(setLanguage(res.data.language));
        dispatch(setAuthenticationLoading(false));
      }
    } catch (err) {
      dispatch(setAuthenticationLoading(false));
    }
  };

export const setOAuthentication =
  (token, user = null, loginId) =>
  async dispatch => {
    if (!user && !token && !loginId) {
      dispatch(setAuthenticationLoading(false));

      return;
    }

    if (!token && loginId) {
      const { data } = await fetchMasterDataService(URLS.getToken + '?loginId=' + loginId);
      saveToStorage(identifiers.access_token, data.access_token);
      saveToStorage(identifiers.access_token_expiry, data.access_token_expiry);
      saveToStorage(identifiers.login_id, data.login_id);
      saveToStorage(identifiers.token, data.id_token);
      saveToStorage(identifiers.login_session_expiry, data.login_session_expiry);
      saveToStorage(identifiers.shouldShowLoginPopup,"false")
      window.location.reload();
    }

    dispatch(setAuthenticationLoading(true));

    if (user) {
      const isAdmin = user.admin;
      const hasNoStockRoles = !user.stockRoles.length;
      const isDustUser = user.globalRoles.includes(DUST_USER);
      const isOnlyDustUser = !isAdmin && hasNoStockRoles && isDustUser;

      dispatch({
        type: SET_AUTHENTICATION,
        payload: { ...user, dustUser: isOnlyDustUser },
      });
      saveToStorage(identifiers.user, user);
      dispatch(setAuthenticationLoading(false));

      return;
    }

    const dataToSend = await getUserLocationInfo();

    try {
      const res = await sendAuthenticationToServerService(dataToSend);
      if (res.status === 200) {
        const isAdmin = res.data.admin;
        const hasNoStockRoles = !res.data.stockRoles.length;
        const isDustUser = res.data.globalRoles.includes(DUST_USER);
        const isOnlyDustUser = !isAdmin && hasNoStockRoles && isDustUser;
        dispatch({
          type: SET_AUTHENTICATION,
          payload: { ...res.data, dustUser: isOnlyDustUser },
        });
        saveToStorage(identifiers.user, res.data);
        const showDate = process.env.REACT_APP_WELCOME_POPUP_DATE;
        const currentDate = new Date();
          if (moment(currentDate).isSameOrBefore(showDate) && res?.data?.dontShowPopup) {
            saveToStorage(identifiers.stockPopup, false)
        }
        dispatch(setLanguage(res.data.language));
        dispatch(setAuthenticationLoading(false));
        dispatch(fetchSubMenuOfZmccRequest())
      }
    } catch (err) {
      dispatch(setAuthenticationLoading(false));
    }
  };

export const setAuthenticationLoading = isLoading => dispatch => {
  dispatch({
    type: SET_AUTHENTICATION_LOADING,
    payload: isLoading,
  });
};

export const logoutUser = routeHistory => dispatch => {
  return logoutService()
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: REMOVE_AUTHENTICATION,
        });
      }
      localStorage.clear();
      routeHistory.push('/home');
      window.location.reload();
    })
    .catch(() => {
      dispatch({
        type: REMOVE_AUTHENTICATION,
      });
    });
};
