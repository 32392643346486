import React from 'react';
import strings from 'resources/locales/Translate';
import $ from 'jquery';
import Button from 'library/common/commonComponents/Button/Button';
import Icon from 'library/common/commonComponents/Icon/Icon';
import { NavLink } from 'react-router-dom';

export const BlockingCalendarMessagPopup = ({ id, tranactionDelayedList }) => {
  const onCloseClick = () => {
    $(`#${id}`).modal('hide');
  };

  return (
    <div
      className='modal fade modal-container'
      id={id}
      tabIndex='-1'
      role='dialog'
      aria-labelledby={id}
      aria-hidden='true'
      data-backdrop='static'
    >
      <div className='modal-dialog stock-popup' role='document'>
        <div className='modal-content rounded-0 '>
          <div className='modal-body p-0'>
            <div className='content' id='app-scheduler-wrapper'>
              <button
                type='button'
                className='close modal-close position-absolute'
                data-dismiss='modal'
                aria-label='Close'
                onClick={onCloseClick}
              >
                <Icon name='cross' width={24} height={24} />
              </button>
              <div className='description mt-4' style={{ whiteSpace: 'pre-wrap' }}>
                {strings.calendarBlockingMessage}
                <ul className='transaction-list mt-2'>
                  {tranactionDelayedList &&
                    tranactionDelayedList.length > 0 &&
                    tranactionDelayedList.map(item => (
                      <li className='mb-2'>
                        <NavLink to={`/transactions/${item.transactionsId}`} target='_blank'>
                          {item.transactionNumber} {item.name}
                        </NavLink>
                      </li>
                    ))}
                </ul>
              </div>
              <div className='d-flex justify-content-center align-content-center mt-4'>
                <Button styleClass='btn-max-width-300' value={'Close'} bgFill={true} onClick={onCloseClick} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockingCalendarMessagPopup;
